/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Breadcrumb from 'components/Breadcrumb';
import Panel from 'components/Panel';
import PanelBody from 'components/Panel/components/PanelBody';
import InputSelect from 'components/Inputs/inputSelect';
import StatCard from 'components/Statistics/StatCard';
import PanelHeader from 'components/Panel/components/PanelHeader';
import SkeletonDoughnutChart from 'components/Loading/skeletonDoughnutChart';
import CaptableSummary from 'routes/Captable/components/CaptableSummary/CaptableSummary';
import ReactECharts from 'echarts-for-react';
import { useSearchParams } from 'react-router-dom';
import { ROUTE_STARTUP_PATH } from 'routes';
import { initializeCaptable } from 'routes/Captable/modules/actions';
import { fetchCaptableSummary } from 'routes/Captable/components/routes/Securities/modules/actions';
import { formatNumber, getColorRange } from 'utils/functions';
import { colorGraphDown, colorGraphUp } from 'constants/colors';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { fetchStartupOperations } from '../../../../modules/actions';
import './styles.scss';

const defaultStateOperation = {
  id: null,
  date: null,
  data: null,
  stats: null,
  loading: false,
};

const CaptableOperationsComparator = (props) => {
  const {
    literals,
    match,
    currency,
    fetchCaptable,
    captable,
  } = props;

  const [operations, setOperations] = useState(null);
  const [operation1, setOperation1] = useState(defaultStateOperation);
  const [operation2, setOperation2] = useState(defaultStateOperation);

  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const simulation = searchParams.get('simulation') || null;

  const loadOperationInfo = async (number, id) => {
    if (!id || !operations) return null;

    const operation = operations.find(op => op.id === id);
    if (!operation) return null;

    const obj = {
      id,
      date: operation.date,
      loading: true,
      data: null,
    };
    const stats = await fetchCaptableSummary(match.params.id, 'all', id, { captable: captableId });
    if (number === 1) {
      setOperation1({ ...obj, stats, loading: false });
    } else {
      setOperation2({ ...obj, stats, loading: false });
    }

    return true;
  };

  const loadOperations = async () => {
    const auxOperations = await fetchStartupOperations(match.params.id, {
      page: 0, size: 0, captable: captableId, simulation,
    });
    setOperations(auxOperations.items);
  };

  useEffect(() => {
    initializeCaptable(fetchCaptable, captable, match.params.id, captableId);
    loadOperations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (operations?.length) {
      let paramOpPos = 0;
      if (match.params.operation !== 'all' && !operation1?.id) {
        paramOpPos = operations.findIndex(op => op.id === match.params.operation);
        if (paramOpPos !== -1) {
          loadOperationInfo(1, match.params.operation);
        }
      } else {
        loadOperationInfo(1, operations[0].id);
      }
      const prevOperation = operations.find((op, i) => i > paramOpPos);
      if (prevOperation) {
        loadOperationInfo(2, prevOperation.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operations]);

  const handleChangeOperation = (id) => {
    loadOperationInfo(1, id);
    const operationPos = operations.findIndex(op => op.id === id);
    const nextOperations = operations.filter((op, i) => !op.draft && i > operationPos).map(obj => obj.id);
    if (!nextOperations.includes(operation2.id)) {
      if (nextOperations.length) {
        loadOperationInfo(2, nextOperations[0]);
      } else {
        setOperation2(defaultStateOperation);
      }
    }
  };

  const renderStats = () => {
    const stats1 = operation1.stats;
    const stats2 = operation2.stats;

    const diff = {
      capital: stats1?.capital - stats2?.capital,
      shareholders: stats1?.shareholders - stats2?.shareholders,
      valuation: stats1?.valuation - stats2?.valuation,
      pps: stats1?.pps - stats2?.pps,
      debt: stats1?.debt - stats2?.debt,
      debtholders: stats1?.debtholders - stats2?.debtholders,
    };

    const diffIcons = {
      up: <CaretUpFill color={colorGraphUp} />,
      down: <CaretDownFill color={colorGraphDown} />,
      cero: null,
    };

    return (
      <div className='row'>
        <div className='col-12 col-md-4'>
          <StatCard
            title={literals.totalCapitalRaised}
            value={formatNumber(stats1?.capital, '-', { symbol: currency.symbol, dec: 2 })}
            diff={diff.capital !== 0 ? (
              <>
                {diffIcons[diff.capital > 0 ? 'up' : (diff.capital < 0 ? 'down' : 'cero')]}
                {formatNumber(Math.abs(diff.capital), '-', { dec: 2 })}
              </>
            ) : '-'}
            valueSize='h4'
            loading={!stats1}
          />
        </div>
        <div className='col-12 col-md-4'>
          <StatCard
            title={literals.totalInvestors}
            value={formatNumber(stats1?.shareholders, '-')}
            diff={diff.shareholders !== 0 ? (
              <>
                {diffIcons[diff.shareholders > 0 ? 'up' : (diff.shareholders < 0 ? 'down' : 'cero')]}
                {formatNumber(Math.abs(diff.shareholders), '-')}
              </>
            ) : '-'}
            valueSize='h4'
            loading={!stats1}
          />
        </div>
        <div className='col-12 col-md-4'>
          <StatCard
            title={literals.valuation}
            value={formatNumber(stats1?.valuation, '-', { symbol: currency.symbol, dec: 2 })}
            diff={diff.valuation !== 0 ? (
              <>
                {diffIcons[diff.valuation > 0 ? 'up' : (diff.valuation < 0 ? 'down' : 'cero')]}
                {formatNumber(Math.abs(diff.valuation), '-', { dec: 2 })}
              </>
            ) : '-'}
            valueSize='h4'
            loading={!stats1}
          />
        </div>
        <div className='col-12 col-md-4'>
          <StatCard
            title={literals.ppsLastReport}
            value={formatNumber(stats1?.pps, '-', { symbol: currency.symbol, dec: 2 })}
            diff={diff.pps !== 0 ? (
              <>
                {diffIcons[diff.pps > 0 ? 'up' : (diff.pps < 0 ? 'down' : 'cero')]}
                {formatNumber(Math.abs(diff.pps), '-', { dec: 2 })}
              </>
            ) : '-'}
            valueSize='h4'
            loading={!stats1}
          />
        </div>
        <div className='col-12 col-md-4'>
          <StatCard
            title={literals.totalDebtOutstanding}
            value={formatNumber(stats1?.debt, '-', { symbol: currency.symbol, dec: 2 })}
            diff={diff.debt !== 0 ? (
              <>
                {diffIcons[diff.debt > 0 ? 'up' : (diff.debt < 0 ? 'down' : 'cero')]}
                {formatNumber(Math.abs(diff.debt), '-', { dec: 2 })}
              </>
            ) : '-'}
            valueSize='h4'
            loading={!stats1}
          />
        </div>
        <div className='col-12 col-md-4'>
          <StatCard
            title={literals.debtHolders}
            value={formatNumber(stats1?.debtholders, '-')}
            diff={diff.debtholders !== 0 ? (
              <>
                {diffIcons[diff.debtholders > 0 ? 'up' : (diff.debtholders < 0 ? 'down' : 'cero')]}
                {formatNumber(Math.abs(diff.debtholders), '-')}
              </>
            ) : '-'}
            valueSize='h4'
            loading={!stats1}
          />
        </div>
      </div>
    );
  };

  const getPieOptions = (data, dataColors) => {
    const newData = data.sort((a, b) => b.value - a.value).slice(0, 4);
    const others = {
      id: 'others',
      name: literals.others,
      value: newData.reduce((acumulador, actual) => acumulador - actual.value, 100).toFixed(2),
    };
    if (others.value > 0.02) {
      newData.push(others);
    }
    const colors = [];
    if (dataColors) {
      newData.forEach((s) => {
        colors.push(dataColors[s.id]);
      });
    }

    return {
      color: colors,
      tooltip: {
        trigger: 'item',
        valueFormatter: value => `${value}%`,
      },
      series: [
        {
          type: 'pie',
          radius: ['50%', '70%'],
          data: newData,
          label: {
            alignTo: 'edge',
            formatter: '{name|{b}}\n{percent|{c}%}',
            edgeDistance: 20,
            fontSize: 14,
            fontWeight: 'bold',
            rich: {
              percent: {
                fontSize: 12,
                color: '#999',
              },
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
  };

  const renderCharts = () => {
    const stats1 = operation1.stats;
    const stats2 = operation2.stats;
    let seriesSH1 = null;
    let seriesSH2 = null;
    let seriesSC1 = null;
    let seriesSC2 = null;
    let shareholderColors = null;
    let shareClassColors = null;

    if (stats1 && stats2) {
      shareholderColors = {};
      const shareholders = [...new Set([
        ...stats1.aggregations.sharesByShareholder.map(v => v.id),
        ...stats2.aggregations.sharesByShareholder.map(v => v.id),
        'others',
      ])];

      const shGraphColors = getColorRange(shareholders.length);
      shareholders.forEach((shareholder, i) => {
        shareholderColors[shareholder] = shGraphColors[i];
      });

      seriesSH1 = stats1 ? stats1.aggregations.sharesByShareholder.map(item => (
        { id: item.id, name: item.name, value: Number(item.value.toFixed(2)) }
      )) : [];
      seriesSH2 = stats2 ? stats2.aggregations.sharesByShareholder.map(item => (
        { id: item.id, name: item.name, value: Number(item.value.toFixed(2)) }
      )) : [];

      shareClassColors = {};
      const shareClasses = [...new Set([
        ...stats1.aggregations.sharesByShareClass.map(v => v.id),
        ...stats2.aggregations.sharesByShareClass.map(v => v.id),
        'others',
      ])];

      const scGraphColors = getColorRange(shareClasses.length);
      shareClasses.forEach((shareClass, i) => {
        shareClassColors[shareClass] = scGraphColors[i];
      });

      seriesSC1 = stats1 ? stats1.aggregations.sharesByShareClass.map(item => (
        { id: item.id, name: item.name, value: Number(item.value.toFixed(2)) }
      )) : [];
      seriesSC2 = stats2 ? stats2.aggregations.sharesByShareClass.map(item => (
        { id: item.id, name: item.name, value: Number(item.value.toFixed(2)) }
      )) : [];
    }

    return (
      <div className='row'>
        <div className='col-12 col-md-6'>
          <Panel margin={0} clean>
            <PanelHeader title={`${literals.byShareholder} ${operation2.date || ''}`} />
            {
              seriesSH2
                ? <ReactECharts option={getPieOptions(seriesSH2, shareholderColors)} />
                : <SkeletonDoughnutChart className='my-5' size={200} />
            }
            <PanelBody />
          </Panel>
        </div>
        <div className='col-12 col-md-6'>
          <Panel margin={0} clean>
            <PanelHeader title={`${literals.byShareholder} ${operation1.date}`} />
            {
              seriesSH1
                ? <ReactECharts option={getPieOptions(seriesSH1, shareholderColors)} />
                : <SkeletonDoughnutChart className='my-5' size={200} />
            }
            <PanelBody />
          </Panel>
        </div>
        <div className='col-12 col-md-6'>
          <Panel margin={0} clean>
            <PanelHeader title={`${literals.byShareClass} ${operation2.date || ''}`} />
            {
              seriesSC2
                ? <ReactECharts option={getPieOptions(seriesSC2, shareClassColors)} />
                : <SkeletonDoughnutChart className='my-5' size={200} />
            }
            <PanelBody />
          </Panel>
        </div>
        <div className='col-12 col-md-6'>
          <Panel margin={0} clean>
            <PanelHeader title={`${literals.byShareClass} ${operation1.date}`} />
            {
              seriesSC1
                ? <ReactECharts option={getPieOptions(seriesSC1, shareClassColors)} />
                : <SkeletonDoughnutChart className='my-5' size={200} />
            }
            <PanelBody />
          </Panel>
        </div>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <Panel margin={0}>
        <PanelHeader title={literals.captable} />
        <PanelBody>
          <CaptableSummary
            startup={match.params.id}
            currency={currency}
            literals={literals}
            captable={captable}
            date={operation1?.id}
            compare={operation2?.id}
          />
        </PanelBody>
      </Panel>
    );
  };

  let options = null;
  if (operations) {
    const operationPos = operations?.findIndex(op => op.id === operation1.id);
    options = operations.map((op, i) => ({
      id: op.id,
      name: `${op.date} | ${op.name} ${op.draft ? '*' : ''}`,
      opt2: i > operationPos,
    }));
  }

  return (
    <Tool className='operation-wrapper'>
      <Breadcrumb
        routes={[
          { route: ROUTE_STARTUP_PATH.setCapTable(match.params.id, captableId), name: literals.captable },
          { route: ROUTE_STARTUP_PATH.setCapTableOperations(match.params.id, captableId), name: literals.operations },
          { name: literals.comparator },
        ]}
      />
      <Panel>
        <PanelBody>
          <div className='row'>
            <div className='col-12 col-md-4'>
              <InputSelect
                className='mb-0'
                preText={literals.previousOperation}
                options={options?.filter(opt => opt.opt2) || []}
                value={operation2.id}
                onChange={v => loadOperationInfo(2, v)}
              />
            </div>
            <div className='col-12 col-md-4 offset-md-4'>
              <InputSelect
                className='mb-0'
                preText={literals.operation}
                options={options || []}
                value={operation1.id}
                onChange={v => handleChangeOperation(v)}
              />
            </div>
          </div>
        </PanelBody>
      </Panel>
      { renderStats() }
      { renderCharts() }
      { renderTable() }
    </Tool>
  );
};

CaptableOperationsComparator.propTypes = {
  literals: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  fetchCaptable: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
};

export default CaptableOperationsComparator;
