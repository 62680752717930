export const FETCH_KPIS_RELATIONS_INIT = 'FETCH_KPIS_RELATIONS_INIT';
export const FETCH_KPIS_RELATIONS_SUCCESS = 'FETCH_KPIS_RELATIONS_SUCCESS';
export const FETCH_KPIS_RELATIONS_FAILURE = 'FETCH_KPIS_RELATIONS_FAILURE';

export const POST_KPI_INIT = 'POST_KPI_INIT';
export const POST_KPI_SUCCESS = 'POST_KPI_SUCCESS';
export const POST_KPI_FAILURE = 'POST_KPI_FAILURE';

export const PUT_KPI_INIT = 'PUT_KPI_INIT';
export const PUT_KPI_SUCCESS = 'PUT_KPI_SUCCESS';
export const PUT_KPI_FAILURE = 'PUT_KPI_FAILURE';

export const POST_KPI_RELATION_INIT = 'POST_KPI_RELATION_INIT';
export const POST_KPI_RELATION_SUCCESS = 'POST_KPI_RELATION_SUCCESS';
export const POST_KPI_RELATION_FAILURE = 'POST_KPI_RELATION_FAILURE';

export const EDIT_KPI_RELATION_INIT = 'EDIT_KPI_RELATION_INIT';
export const EDIT_KPI_RELATION_SUCCESS = 'EDIT_KPI_RELATION_SUCCESS';
export const EDIT_KPI_RELATION_FAILURE = 'EDIT_KPI_RELATION_FAILURE';

export const POST_KPIS_RELATIONS_INIT = 'POST_KPIS_RELATIONS_INIT';
export const POST_KPIS_RELATIONS_SUCCESS = 'POST_KPIS_RELATIONS_SUCCESS';
export const POST_KPIS_RELATIONS_FAILURE = 'POST_KPIS_RELATIONS_FAILURE';

export const DELETE_KPI_RELATION_INIT = 'DELETE_KPI_RELATION_INIT';
export const DELETE_KPI_RELATION_SUCCESS = 'DELETE_KPI_RELATION_SUCCESS';
export const DELETE_KPI_RELATION_FAILURE = 'DELETE_KPI_RELATION_FAILURE';

export const POST_KPI_VALUE_INIT = 'POST_KPI_VALUE_INIT';
export const POST_KPI_VALUE_SUCCESS = 'POST_KPI_VALUE_SUCCESS';
export const POST_KPI_VALUE_FAILURE = 'POST_KPI_VALUE_FAILURE';

export const POST_KPI_VALUES_INIT = 'POST_KPI_VALUES_INIT';
export const POST_KPI_VALUES_SUCCESS = 'POST_KPI_VALUES_SUCCESS';
export const POST_KPI_VALUES_FAILURE = 'POST_KPI_VALUES_FAILURE';

export const DELETE_KPI_VALUE_INIT = 'DELETE_KPI_VALUE_INIT';
export const DELETE_KPI_VALUE_SUCCESS = 'DELETE_KPI_VALUE_SUCCESS';
export const DELETE_KPI_VALUE_FAILURE = 'DELETE_KPI_VALUE_FAILURE';

export const POST_KPI_BOOKMARK_INIT = 'POST_KPI_BOOKMARK_INIT';
export const POST_KPI_BOOKMARK_SUCCESS = 'POST_KPI_BOOKMARK_SUCCESS';
export const POST_KPI_BOOKMARK_FAILURE = 'POST_KPI_BOOKMARK_FAILURE';

export const DELETE_KPI_BOOKMARK_INIT = 'DELETE_KPI_BOOKMARK_INIT';
export const DELETE_KPI_BOOKMARK_SUCCESS = 'DELETE_KPI_BOOKMARK_SUCCESS';
export const DELETE_KPI_BOOKMARK_FAILURE = 'DELETE_KPI_BOOKMARK_FAILURE';

export const ORDER_KPIS_RELATIONS_SUCCESS = 'ORDER_KPIS_RELATIONS_SUCCESS';
