import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadingAction } from '../../../store/globalState/global';
import SignupComponents from '../components';
import { fetchSocialSignupAction } from '../modules/actions';

function mapStateToProps(state) {
  return {
    isAuthenticated: state.session.authenticated,
    literals: state.i18n.literals.signup,
    signupData: state.signup,
    userLang: state.i18n.language,
    platform: state.global.platform,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showLoading: bindActionCreators(loadingAction, dispatch),
    socialSignup: bindActionCreators(fetchSocialSignupAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupComponents);
