import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import InputText from 'components/Inputs/inputText';
import InputDate from 'components/Inputs/inputDate';
import InputSelect from 'components/Inputs/inputSelect';
import InputNumber from 'components/Inputs/inputNumber';
import InputRichText from 'components/Inputs/inputRichText';
import InputFile from 'components/Inputs/inputFile';
import Separator from 'components/Separator';

const FormValuation = (props) => {
  const {
    literals,
    literalsCommon,
    captable,
    currency,
    view,
    editable,
    data,
    date,
    onConfirm,
    onDelete,
    totalShares,
    totalOptions,
  } = props;

  const [id] = useState(data?.id ?? `new.${new Date().getTime()}`);
  const [name, setName] = useState(data?.name);
  const [type, setType] = useState(data.type || 'pps');
  const [pps, setPPS] = useState(data?.pps || 0);
  const [valuation, setValuation] = useState(data?.valuation || 0);
  const [analyst, setAnalyst] = useState(data?.analyst || '');
  const [notes, setNotes] = useState(data?.notes || '');
  const [inputDate, setInputDate] = useState(date);
  const [documents, setDocuments] = useState([]);

  const handleConfirm = () => {
    if (view === 'shares') {
      onConfirm({
        operationType: 'valuation',
        id,
        name,
        date: inputDate,
        analyst,
        type,
        valuation: valuation !== '' ? parseFloat(valuation) : null,
        pps: pps !== '' ? parseFloat(pps) : null,
        notes,
        documents,
      });
    } else {
      onConfirm({
        operationType: 'valuation',
        id,
        analyst,
        type,
        valuation: valuation !== '' ? parseFloat(valuation) : null,
        pps: pps !== '' ? parseFloat(pps) : null,
      });
    }
  };

  const handleChangeType = (newType) => {
    setType(newType);
    if (newType === 'valuation') {
      setPPS(Number(valuation / (totalShares + totalOptions)));
    } else {
      setValuation(Number(pps * (totalShares + totalOptions)));
    }
  };

  const handleChangeValue = (val, newType) => {
    if (newType === 'valuation') {
      setValuation(val);
      setPPS(Number(val / (totalShares + totalOptions)));
    } else {
      setPPS(val);
      setValuation(Number(val * (totalShares + totalOptions)));
    }
  };

  return (
    <>
      <div className='operation-form-shares'>
        <div className='mb-lb'>
          {view === 'shares' && (
            <>
              <div className='row'>
                <div className='col-12 col-md-8'>
                  <InputText
                    className='m-0'
                    preText={literals.operationName}
                    placeholder={literals.operationName}
                    value={name}
                    onChange={v => setName(v)}
                    isDisabled={!editable}
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <InputDate
                    className='m-0'
                    preText={literalsCommon.date}
                    value={inputDate}
                    onChange={v => setInputDate(v)}
                    isDisabled={!editable}
                    min={captable?.operations?.length ? captable?.operations[0].date : null}
                  />
                </div>
              </div>
              <Separator />
            </>
          )}
          <div className='row'>
            <div className='col-12'>
              <InputText
                preText={literals.analyst}
                value={analyst}
                onChange={v => setAnalyst(v)}
                isDisabled={!editable}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-4'>
              <InputSelect
                preText={literalsCommon.type}
                placeholder={literals.selectValuation}
                options={[
                  { id: 'pps', name: literals.ppsValuation },
                  { id: 'valuation', name: literals.totalValuation },
                ]}
                value={type}
                onChange={handleChangeType}
                isDisabled={!editable}
              />
            </div>
            <div className='col-12 col-md-4'>
              <InputNumber
                preText={literals.ppsValuation}
                value={pps}
                onChange={v => handleChangeValue(v, 'pps')}
                minValue={0}
                isDisabled={!editable || type !== 'pps'}
                symbol={currency.symbol}
                step='any'
                decimals={10}
              />
            </div>
            <div className='col-12 col-md-4'>
              <InputNumber
                preText={literals.totalValuation}
                value={valuation}
                onChange={v => handleChangeValue(v, type)}
                minValue={1}
                isDisabled={!editable || type === 'pps'}
                symbol={currency.symbol}
                step='any'
                decimals={2}
              />
            </div>
          </div>
        </div>
        {view === 'shares' && (
          <>
            <Separator />
            <div className='row'>
              <div className='col-12 '>
                <InputRichText
                  minHeight='120px'
                  preText={literals.additionalDocumentation}
                  value={notes}
                  onChange={v => setNotes(v)}
                  isDisabled={!editable}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <InputFile
                  preText={literalsCommon.documents}
                  value={documents}
                  isPublic={false}
                  onChange={v => setDocuments(v)}
                  multiple
                  isDisabled={!editable}
                />
              </div>
            </div>
          </>
        )}
        {
          editable && (
            <div className='buttons'>
              <Button text={literalsCommon.confirm} onClick={handleConfirm} />
              {
                data?.id && (
                  <Button text={literalsCommon.delete} color='danger' onClick={() => onDelete(data.id)} />
                )
              }
            </div>
          )
        }
      </div>
    </>
  );
};

FormValuation.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  view: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  totalShares: PropTypes.number.isRequired,
  totalOptions: PropTypes.number.isRequired,
  editable: PropTypes.bool,
  data: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

FormValuation.defaultProps = {
  editable: true,
  onDelete: () => {},
};

export default FormValuation;
