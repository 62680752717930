import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SetPasswordComponents from '../components';
import { initPlatformAction, loadingAction } from '../../../store/globalState/global';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.setPassword,
    isAuthenticated: state.session.authenticated,
    platform: state.global.platform,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showLoading: bindActionCreators(loadingAction, dispatch),
    initPlatform: bindActionCreators(initPlatformAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPasswordComponents);
