import React from 'react';
import PropTypes from 'prop-types';
import InputNumber from 'components/Inputs/inputNumber';

const PopupFormShareClassConvert = (props) => {
  const {
    convert, onChange, literals, isDisabled,
  } = props;

  return (
    <div className='col-md-9'>
      <InputNumber
        name='ratio'
        preText={literals.ratio}
        value={convert.ratio}
        minValue={0}
        onChange={value => onChange('ratio', value)}
        symbol='X'
        isDisabled={isDisabled}
      />
    </div>
  );
};

PopupFormShareClassConvert.propTypes = {
  convert: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  literals: PropTypes.object.isRequired,
  isDisabled: PropTypes.object.isRequired,
};

export default PopupFormShareClassConvert;
