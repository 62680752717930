import React from 'react';
import {
  CircleCheck,
  Clock,
  StopCircle,
  XCircle,
} from 'lucide-react';

export const statusTag = (literals, status) => {
  let icon = '';
  const text = literals.status[status];
  switch (status) {
    case 'accepted':
      icon = <CircleCheck size={14} className='mr-1' />;
      break;
    case 'rejected':
      icon = <XCircle size={14} className='mr-1' />;
      break;
    case 'finished':
      icon = <StopCircle size={14} className='mr-1' />;
      break;
    default:
      icon = <Clock size={14} className='mr-1' />;
      break;
  }

  return (
    <span>
      {icon}
      {text}
    </span>
  );
};
