import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'utils/withRouter';
import {
  addNotificationsAction,
  deleteNotificationAction,
  fetchNotificationsAction,
  readNotificationAction,
  unreadNotificationAction,
} from 'store/globalState/reducers/conf02';
import TopBarComponents from '../components';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.topBar,
    literalsCommon: state.i18n.literals.common,
    platform: state.global.platform,
    notifications: state.notifications,
    startup: state.global.startup,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addNotification: bindActionCreators(addNotificationsAction, dispatch),
    fetchNotifications: bindActionCreators(fetchNotificationsAction, dispatch),
    readNotification: bindActionCreators(readNotificationAction, dispatch),
    unreadNotification: bindActionCreators(unreadNotificationAction, dispatch),
    deleteNotification: bindActionCreators(deleteNotificationAction, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopBarComponents));
