import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Table from 'components/Table';
import Button from 'components/Buttons/Button';
import { getFullName } from 'utils/functions';
import { Plus } from 'lucide-react';
import { fetchStartupUsers } from 'routes/StartupUsers/modules/actions';
import OutputValue from './outputValue';
import './styles.scss';

const InputUsers = ({
  literals = {},
  match,
  preText = '',
  users = [],
  setUsers,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedRows, setSelectedRows] = useState(users);

  const columns = [
    {
      field: 'avatar',
      type: 'avatar',
      width: 45,
      title: (_, row) => getFullName(row),
    },
    { field: 'firstname', grow: 1, label: literals.name },
    { field: 'lastname', grow: 3, label: literals.lastName },
    { field: 'email', grow: 5, label: literals.email },
    { field: 'role', width: 100, label: literals.role },
  ];

  const fetchUsers = (page, size, search, filters) => {
    return fetchStartupUsers(match.params.id, {
      page, size, search, filters,
    });
  };

  const handleSelect = () => {
    setUsers(selectedRows);
    setShowPopup(false);
  };

  return (
    <div>
      <div className='inputUsers-box' onClick={() => setShowPopup(true)}>
        <OutputValue
          preText={preText}
          value={`${users.length} ${preText}`}
        />
        <span className='inputUsers-button'>
          <Button className='btn-icon-square' icon={Plus} />
        </span>
      </div>

      {showPopup && (
        <Popup
          size='large'
          title={`${literals.add} ${preText.toLowerCase()}`}
          onClose={() => setShowPopup(false)}
        >
          <Table
            columns={columns}
            fetch={fetchUsers}
            selectRows={selectedRows}
            onSelectRows={setSelectedRows}
          />
          <div className='d-flex justify-content-end'>
            <Button
              text={`seleccionar (${selectedRows.length})`}
              onClick={handleSelect}
            />
          </div>
        </Popup>
      )}
    </div>
  );
};

InputUsers.propTypes = {
  literals: PropTypes.object,
  match: PropTypes.object.isRequired,
  preText: PropTypes.string,
  users: PropTypes.array,
  setUsers: PropTypes.func,
};

export default InputUsers;
