import FundsInstance from 'modules/funds/repository';

export const getFunds = (page, size, search, filters, sort) => {
  return FundsInstance.getFunds({
    page, size, search, filters, sort,
  });
};

export const createFund = (fund) => {
  return FundsInstance.postFund({ ...fund }).catch((err) => { throw err; });
};

export const editFund = (id, fund) => {
  return FundsInstance.putFund(id, { ...fund }).catch((err) => { throw err; });
};

export const deleteFund = (fund) => {
  return FundsInstance.deleteFund(fund);
};
