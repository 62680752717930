import { connect } from 'react-redux';
import FundraiseAddComponent from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.fundraise,
  literalsPerformance: state.i18n.literals.performance,
  literalsCommon: state.i18n.literals.common,
  lang: state.i18n.language,
  currency: state.global.currency,
  startup: state.global.startup,
});

export default connect(mapStateToProps)(FundraiseAddComponent);
