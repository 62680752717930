import { createInstance } from '@amplitude/analytics-browser';
import { isEmbed } from 'routes';

const amplitude = createInstance();
const globalEventProperties = {
  startup: null,
};

const initializeAmplitude = () => {
  const API_KEY = process.env.REACT_APP_AMPLITUDE_KEY || 'dfff3d60eb6aca59e235b13a81ac6505';
  amplitude.init(API_KEY, {
    defaultTracking: {
      pageViews: false,
      formInteractions: false,
    },
  });
};

const AmplitudeApi = {
  identify: identify => amplitude.identify(identify),
  setGlobalEventProperties: (properties) => {
    Object.assign(globalEventProperties, properties);
  },
  setUserId: (user) => {
    amplitude.setUserId(user);
    AmplitudeApi.setGlobalEventProperties({ user });
  },
  logEvent: (eventName, eventProperties = {}) => {
    amplitude.track(eventName, {
      ...globalEventProperties,
      ...eventProperties,
      embed: isEmbed(),
    });
  },
};

AmplitudeApi.successEvent = (eventName, eventProperties = {}) => {
  AmplitudeApi.logEvent(eventName, { ...eventProperties, success: true });
};

AmplitudeApi.errorEvent = (eventName, eventProperties = {}) => {
  AmplitudeApi.logEvent(eventName, { ...eventProperties, success: false });
};

export { AmplitudeApi, initializeAmplitude };
