import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import StatCard from 'components/Statistics/StatCard';
import SkeletonBarChart from 'components/Loading/skeletonBarChart';
import ReactECharts from 'echarts-for-react';
import { formatNumber, getColorRange } from 'utils/functions';
import { Skeleton } from '@mui/material';

const SummaryAdminMentorings = (props) => {
  const {
    literals,
    mentorings,
  } = props;

  let mentoringConfigDonut = false;
  let mentoringConfigLine = false;
  let columnsGroups = false;

  if (mentorings) {
    const { mentorings: { status: mentoringsStatus } } = mentorings;
    const graphColors = getColorRange(Object.keys(mentoringsStatus).length);
    const totalMentoring = mentoringsStatus.accepted + mentoringsStatus.pending + mentoringsStatus.rejected + mentoringsStatus.finished;
    mentoringConfigDonut = {
      color: graphColors,
      legend: {
        top: 'bottom',
      },
      tooltip: {
        trigger: 'item',
        valueFormatter: value => `${value}%`,
      },
      series: [
        {
          type: 'pie',
          data: Object.keys(mentoringsStatus).map(status => ({
            name: `${literals[status]} (${mentoringsStatus[status]})`,
            value: (mentoringsStatus[status] * 100 / totalMentoring).toFixed(2),
          })),
          top: '-40px',
          left: 0,
          right: 0,
          itemStyle: { borderColor: '#fff', borderWidth: 2 },
          label: {
            position: 'inside',
            formatter: '{c}%',
            fontSize: 14,
            color: '#fff',
            fontWeight: 'bold',
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };

    mentoringConfigLine = {
      series: [{
        name: literals.mentorings,
        type: 'line',
        symbol: 'none',
        data: [],
      }, {
        name: literals.meetings,
        type: 'line',
        symbol: 'none',
        data: [],
      }, {
        name: literals.documents,
        type: 'line',
        symbol: 'none',
        data: [],
      }],

      tooltip: {
        trigger: 'axis',
        valueFormatter: v => formatNumber(v),
      },
      legend: {
        data: [literals.mentorings, literals.meetings, literals.documents],
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: [],
        axisLabel: { rotate: 45 },
      },
      yAxis: {
        type: 'value',
      },
    };

    mentorings.mentorings.histogram.forEach((item, i) => {
      mentoringConfigLine.xAxis.data.push(item.date.substr(5, 6));
      mentoringConfigLine.series[0].data.push(item.value);
      mentoringConfigLine.series[1].data.push(mentorings.meetings.histogram[i].value);
      mentoringConfigLine.series[2].data.push(mentorings.documents.histogram[i].value);
    });

    const { mentors: { groups: mentorsGroups }, startups: { groups: startupGroups } } = mentorings;

    columnsGroups = {
      legend: {},
      tooltip: { valueFormatter: v => formatNumber(v) },
      dataset: {
        source: [
          ['Options', literals.startups, literals.mentors],
          [literals.group1,
            startupGroups['1'],
            mentorsGroups['1'],
          ],
          [literals.group2,
            startupGroups['2'],
            mentorsGroups['2'],
          ],
          [literals.group3,
            startupGroups['3'],
            mentorsGroups['3'],
          ],
        ],
      },
      xAxis: { type: 'category' },
      yAxis: { },
      series: [{ type: 'bar' }, { type: 'bar' }],
      grid: {
        left: '4%', right: '4%', bottom: '4%', containLabel: true,
      },
    };
  }

  return (
    <>
      <h4 className='fw-b'>{literals.mentorings}</h4>
      <div className='admin-summary-mentorings row mb-4'>
        <div className='col-12 col-md-5 col-lg-4 col-xl-3'>
          <StatCard title={literals.mentorings} value={mentorings?.mentorings?.total} loading={!mentorings} />
          <Panel>
            <PanelBody className='panel-chart' noHeader>
              {
                !mentorings ? (
                  <div className='d-flex align-items-center justify-content-center mt-5'>
                    <Skeleton animation='wave' width={250} height={250} variant='circular' />
                  </div>
                ) : (
                  <div id='chart' className='pie-chart'>
                    <div className='h4 fw-b pt-3 ml-4'>{literals.mentoringStatus}</div>
                    <ReactECharts id='chart' option={mentoringConfigDonut} style={{ height: '100%' }} />
                  </div>
                )
              }
            </PanelBody>
          </Panel>
        </div>

        <div className='col-12 col-md-7 col-lg-8 col-xl-9'>
          <Panel>
            <PanelHeader title={literals.lastMonthActivity} />
            <PanelBody>
              {
                !mentorings ? (
                  <div className='d-flex align-items-center justify-content-center'>
                    <SkeletonBarChart className='mb-5' height='355px' />
                  </div>
                ) : (
                  <div id='chart'>
                    <ReactECharts option={mentoringConfigLine} style={{ height: 415 }} />
                  </div>
                )
              }
            </PanelBody>
          </Panel>
        </div>

        <div className='col-12 col-md-8'>
          <Panel>
            <PanelHeader title={literals.groups} />
            <PanelBody>
              {
                !mentorings ? (
                  <div className='d-flex align-items-center justify-content-center'>
                    <SkeletonBarChart height='200px' />
                  </div>
                ) : (
                  <ReactECharts option={columnsGroups} style={{ height: 222 }} />
                )
              }
            </PanelBody>
          </Panel>
        </div>

        <div className='col-12 col-md-4'>
          <StatCard title={literals.donatedHours} value={mentorings?.mentors?.hours} loading={!mentorings} />
          <StatCard title={literals.mentorsMentoring} value={mentorings?.mentors?.mentored} loading={!mentorings} />
          <StatCard title={literals.startupMentored} value={mentorings?.startups?.mentored} loading={!mentorings} />
        </div>

        <div className='col-12 col-md-4'>
          <StatCard
            title={literals.meetingsFinished}
            subtitle={literals.totalHours}
            value={`${mentorings?.meetings?.status.finished} / ${mentorings?.meetings?.hours.finished}`}
            loading={!mentorings}
          />
        </div>

        <div className='col-12 col-md-4'>
          <StatCard
            title={literals.meetingsScheduled}
            subtitle={literals.totalHours}
            value={`${mentorings?.meetings?.status.scheduled} / ${mentorings?.meetings?.hours.scheduled}`}
            loading={!mentorings}
          />
        </div>

        <div className='col-12 col-md-4'>
          <StatCard
            title={literals.documents}
            subtitle={literals.uploadedToMentorings}
            value={mentorings?.documents?.total}
            loading={!mentorings}
          />
        </div>
      </div>
    </>
  );
};

SummaryAdminMentorings.propTypes = {
  literals: PropTypes.object.isRequired,
  mentorings: PropTypes.object,
};

SummaryAdminMentorings.defaultProps = {
  mentorings: null,
};

export default SummaryAdminMentorings;
