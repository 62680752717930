import React from 'react';
import PropTypes from 'prop-types';
import ButtonsDotsMenu from 'components/Buttons/ButtonsDotsMenu';
import SkeletonBarChart from 'components/Loading/skeletonBarChart';
import { Skeleton } from '@mui/material';
import { Bookmark, Eye } from 'lucide-react';
import { getTranslation } from 'utils/functions';
import { formatKpiDiff, formatKpiValue } from '../utils/functions';
import KpiRelationValuesGraph from './KpiRelationValuesGraph';
import './styles.scss';

const KpiRelationCard = ({
  literals,
  literalsCommon,
  currency,
  data,
  lang,
  onClick,
  onDeleteBookmark,
  loading = false,
  canEdit,
}) => {
  if (loading) {
    return (
      <div className='kpi-relation-card' onClick={onClick}>
        <Skeleton animation='wave' width='50%' height='26px' />
        <Skeleton animation='wave' width='40%' height='26px' />
        <SkeletonBarChart height='100px' />
        <div className='kpi-relation-card-date'>
          <Skeleton animation='wave' width='80px' height='26px' />
        </div>
      </div>
    );
  }

  return (
    <div className='kpi-relation-card' onClick={onClick}>
      <div className='kpi-relation-card-name' title={getTranslation(data?.kpi?.name, lang)}>
        {getTranslation(data?.kpi?.name, lang)}
      </div>
      <div className='kpi-relation-card-value'>
        <span className='value mr-2'>{formatKpiValue(data.value.actual, data.kpi, { abbreviation: true }, currency)}</span>
        {formatKpiDiff(data.value.difference)}
      </div>
      <div className='kpi-graph'>
        <KpiRelationValuesGraph
          relation={data}
          height={170}
          literals={literals}
          literalsCommon={literalsCommon}
          currency={currency}
          canEdit={canEdit}
          isSimple
        />
      </div>
      <div className='kpi-relation-card-menu'>
        <ButtonsDotsMenu
          offset={{ left: 25 }}
          size={25}
          buttons={[
            { icon: Eye, text: literalsCommon.view, onClick },
            { icon: Bookmark, text: literals.unbookmark, onClick: onDeleteBookmark },
          ]}
        />
      </div>
      <div className='kpi-relation-card-date'>
        {data.value.date}
      </div>
    </div>
  );
};

KpiRelationCard.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onDeleteBookmark: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default KpiRelationCard;
