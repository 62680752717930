import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Portal from 'components/Portal';
import { useRect } from 'utils/customHooks';
import { normalizeText } from 'utils/functions';
import { connect } from 'react-redux';
import { X } from 'lucide-react';
import './styles.scss';

const GenericSearcher = ({
  className = '',
  value = '',
  onChange,
  preText = '',
  postText = '',
  placeholder = '',
  isRequired = false,
  isDisabled = false,
  error = null,
  fetchItems,
  renderRow,
  addOther = false,
  literalsCommon,
}) => {
  const [search, setSearch] = useState({ value, normalized: value });
  const [options, setOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bbox, inputRef, visible] = useRect(showOptions || loading);

  const timeout = useRef(null);
  useEffect(() => {
    if (search.value.length >= 2) {
      timeout.current = setTimeout(async () => {
        setLoading(true);
        const rows = await fetchItems(search.normalized);
        setOptions(rows.items);
        setShowOptions(true);
        setLoading(false);
      }, 750);
    }
    return () => clearTimeout(timeout.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);


  const selectValue = (id) => {
    const foundValue = id ? options.find(elem => elem.id === id) : null;
    if (foundValue) {
      onChange(foundValue);
    } else {
      onChange(id ? { id: 'other', name: search.value } : null);
    }
    setSearch({ value: '', normalized: '' });
    setShowOptions(false);
  };

  const renderOptions = () => {
    const auxOptions = options.map(elem => (
      <div
        key={elem.id}
        className='input-select-item'
        onClick={() => selectValue(elem.id)}
      >
        {renderRow(elem)}
      </div>
    ));

    if (addOther) {
      auxOptions.push(
        <div
          key='other'
          className='input-select-item'
          onClick={() => selectValue('other')}
        >
          {renderRow({ id: 'other', name: search.value })}
        </div>,
      );
    } else if (!options.length) {
      auxOptions.push(
        <div
          key='empty'
          className='input-select-item'
        >
          {literalsCommon.noResults}
        </div>,
      );
    }

    return auxOptions;
  };

  const styles = { visible };
  if (bbox) {
    styles.top = bbox.bottom;
    styles.left = bbox.left;
    styles.width = bbox.width;
  }

  return (
    <div className={`input-select-wrapper value-inside generic-searcher ${className} ${isDisabled ? 'disabled' : ''}`}>
      {preText && (
        <div className={`pre_text ${error ? 'text_error' : ''}`}>
          {preText}
          {isRequired && <span className='is_required'>*</span>}
        </div>
      )}
      <div
        className={`input-select-select ${error ? 'input_error' : ''} ${showOptions ? 'no-border' : ''}`}
        ref={inputRef}
        onClick={() => inputRef.current.focus()}
      >
        <div className='selected-item'>
          { !value ? (
            <input
              className='input-select-text'
              placeholder={placeholder}
              type='text'
              value={search.value}
              onClick={e => e.stopPropagation()}
              onChange={e => setSearch({ value: e.target.value, normalized: normalizeText(e.target.value) })}
            />
          ) : (
            <div className='generic-selected'>
              <div className='d-flex align-items-center flex-grow-1'>
                {renderRow(value)}
              </div>
              <div style={{ width: '20px', marginLeft: '10px', cursor: 'pointer' }}>
                <X size={20} onClick={() => selectValue(null)} />
              </div>
            </div>
          )}
        </div>
      </div>
      {(loading || showOptions) ? (
        <Portal styles={styles} onClose={() => setShowOptions(false)}>
          <div className='input-select-item-options simple-scrollbar'>
            {loading ? (
              <div
                key='loading'
                className='input-select-item'
              >
                {literalsCommon.loading}
              </div>
            ) : (
              renderOptions()
            )}
          </div>
        </Portal>
      ) : null}
      { postText && (<div className='post_text' dangerouslySetInnerHTML={{ __html: postText }} />) }
    </div>
  );
};

GenericSearcher.propTypes = {
  preText: PropTypes.string,
  postText: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.object,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  addOther: PropTypes.bool,
  fetchItems: PropTypes.func.isRequired,
  renderRow: PropTypes.func.isRequired,
  literalsCommon: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    literals: state.i18n.literals.input,
    literalsCommon: state.i18n.literals.common,
  };
};

export default connect(mapStateToProps, {})(GenericSearcher);
