

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import InputText from 'components/Inputs/inputText';
import InputEmail from 'components/Inputs/inputEmail';
import InputPassword from 'components/Inputs/inputPassword';
import OutputErrors from 'components/Inputs/outputErrors';
import Button from 'components/Buttons/Button';
import useDialog from 'components/Dialog/components/useDialog';
import Dots from 'assets/svg/dots.svg';
import { ArrowLeft } from 'lucide-react';
import {
  Link, Navigate, useSearchParams, useLocation,
} from 'react-router-dom';
import { ROUTE_PATH, ROUTE_STARTUP_PATH } from 'routes';
import { FILES_BASE_URL, STATUS_CODE } from 'constants/apiConf';
import { DIMENSIONS } from 'utils/browser';
import { useIsWidthDown } from 'utils/customHooks';
import './styles.scss';

const Login = (props) => {
  const { dialog } = useDialog();
  const [email, setEmail] = useState(window?.LOCAL_USER?.email || '');
  const [password, setPassword] = useState(window?.LOCAL_USER?.password || '');
  const [inputToken, setInputToken] = useState('');
  const [showRecoveryForm, setShowRecoveryForm] = useState(false);
  const [token2AF, setToken2AF] = useState();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const isSmall = useIsWidthDown(DIMENSIONS.SM);

  const {
    platform,
    literals,
    literalsCommon,
    isAuthenticated,
    onLogin,
    onRecovery,
    cleanErrors,
    loginError,
    recoveryError,
  } = props;

  const [searchParams] = useSearchParams();
  const invite = searchParams.get('invite');
  const inviteFundraise = searchParams.get('inviteFundraise');
  const referral = searchParams.get('referral');
  const startup = searchParams.get('startup');
  const external = searchParams.get('external');

  const socialLogin = (provider, token) => {
    const params = {
      oauth: {
        type: provider,
        id: token,
      },
      invite: invite || null,
    };
    setLoading(true);
    onLogin(params).catch(() => {
      setPassword('');
      setLoading(false);
    });
  };

  useEffect(() => {
    if (platform.oauth.google && window.google) {
      /* global google */
      google.accounts.id.initialize({
        client_id: platform.oauth.google,
        callback: response => socialLogin('google', response.credential),
      });
      google.accounts.id.renderButton(
        document.getElementById('signInDiv'),
        {
          theme: 'outline',
          size: 'large',
          text: literals.login,
          height: 44,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token2AF, showRecoveryForm]);

  const checkIsDisabled = () => {
    return showRecoveryForm ? email === '' : email === '' || password === '';
  };

  const logIn = (e) => {
    e.preventDefault();

    if (!checkIsDisabled()) {
      const body = {
        email,
        password,
        invite: invite || null,
        second_factor: token2AF ? {
          type: 'otp',
          value: inputToken,
        } : null,
      };
      setLoading(true);
      onLogin(body)
        .then(({ status, data }) => {
          if (!token2AF && status === STATUS_CODE.ACCEPTED) {
            setToken2AF(data);
          }
          setLoading(false);
        }).catch(() => {
          setPassword('');
          setLoading(false);
        });
    }
  };

  const recovery = async (e) => {
    e.preventDefault();

    onRecovery(email).then((success) => {
      if (success) {
        dialog({
          type: 'alert',
          text: literals.recovery.successMessage,
        });
      }
    });
  };

  const onEnterKeyPress = (e) => {
    if (e.charCode === 13 || e.keyCode === 13) {
      if (showRecoveryForm) {
        recovery(e);
      } else {
        logIn(e);
      }
    }
  };

  if (isAuthenticated) {
    let redirectTo = ROUTE_PATH.PREHOME;
    let search = '';
    if (startup) {
      if (external || startup.length) {
        redirectTo = ROUTE_STARTUP_PATH.ONBOARDING;
        search = `?startup=${startup}${external ? '&external=1' : ''}`;
      } else {
        redirectTo = ROUTE_STARTUP_PATH.setHome(startup);
      }
    } else if (location.state && ![ROUTE_PATH.BASEROUTE, ROUTE_PATH.LOGOUT].includes(location.state.from)) {
      redirectTo = location.state.from;
      if (inviteFundraise) {
        search = `?invite=${inviteFundraise}`;
      }
      search = referral ? `?referral=${referral}` : '';
    }

    return (
      <Navigate to={{ pathname: redirectTo, state: location.state, search }} />
    );
  }

  let backgroundImage = null;
  if (isSmall) {
    if (platform.images?.bgPublicMobile?.path) {
      backgroundImage = FILES_BASE_URL + platform.images?.bgPublicMobile?.path;
    }
  } else if (platform.images?.bgPublicDesktop?.path) {
    backgroundImage = FILES_BASE_URL + platform.images?.bgPublicDesktop?.path;
  }

  const renderLoginForm = () => {
    if (token2AF) {
      return (
        <>
          <div className='form-header'>
            <h4 className='fw-b mb-3'>{literals.title}</h4>
            <span>{literals.tokenMsg}</span>
          </div>

          <form className='form' onSubmit={logIn} onKeyPress={onEnterKeyPress}>
            <InputText
              preText={literals.token}
              placeholder={literals.token}
              value={inputToken}
              onChange={setInputToken}
              isRequired
            />
            <OutputErrors errors={loginError ? [literals.invalidToken] : null} />
            <div className='form-actions'>
              <Button
                text={literalsCommon.send}
                type='submit'
                disabled={!inputToken}
                loading={loading}
              />
            </div>
            <div className='d-flex align-items-center' onClick={() => { setToken2AF(null); setInputToken(''); cleanErrors(); }}>
              <ArrowLeft size={14} className='mr-2' />
              {literals.recovery.back}
            </div>
          </form>
        </>
      );
    }

    return (
      <>
        <div className='form-header'>
          <h4 className='fw-b mb-3'>{literals.title}</h4>
        </div>
        {
          platform.oauth.google && window.google ? (
            <div className='social-actions'>
              <div className='social-buttons'>
                <div style={{ height: 44 }} id='signInDiv' className='pb-2' />
              </div>
              <div className='separator'>
                <span>or Sign with Email</span>
              </div>
            </div>
          ) : null
        }
        <form className='form' onSubmit={logIn} onKeyPress={onEnterKeyPress}>
          <InputEmail
            preText={literals.email}
            placeholder={literals.email}
            isRequired
            value={email}
            onChange={value => setEmail(value)}
          />
          <InputPassword
            preText={literals.password}
            placeholder={literals.password}
            isRequired
            value={password}
            onChange={value => setPassword(value)}
          />
          <div className='d-flex justify-content-end'>
            <span className='goto-recovery-password link-effect' onClick={() => setShowRecoveryForm(true)}>
              {literals.recoveryButton}
            </span>
          </div>
          <OutputErrors errors={loginError ? [literals.invalidLogin] : null} />
          <div className='form-actions'>
            <Button
              text={literals.login}
              type='submit'
              disabled={checkIsDisabled()}
              loading={loading}
            />
          </div>
        </form>
        {(platform.properties.signup.public || inviteFundraise || referral) && (
          <div className='goto-link'>
            <p>
              {literals.questionNotRegistered}
              <Link
                className='link-effect'
                to={{
                  pathname: ROUTE_PATH.SIGNUP,
                  state: location.state,
                  search: location?.search,
                }}
              >
                {literals.createAccount}
              </Link>
            </p>
          </div>
        )}
      </>
    );
  };

  const renderRecoveryForm = () => (
    <>
      <div className='form-header'>
        <h4 className='fw-b'>{literals.recovery.title}</h4>
        <p>{literals.recovery.description}</p>
      </div>
      <form className='form' onSubmit={recovery} onKeyPress={onEnterKeyPress}>
        <InputEmail
          preText={literals.email}
          placeholder={literals.email}
          isRequired
          value={email}
          onChange={value => setEmail(value)}
        />
        <OutputErrors errors={recoveryError} literals={literals} />
        <div className='form-actions'>
          <Button
            text={literals.recovery.button}
            type='submit'
            disabled={checkIsDisabled()}
            loading={loading}
          />
          <div onClick={() => { setShowRecoveryForm(false); cleanErrors(); }}>
            <ArrowLeft size={14} className='mr-2' />
            {literals.recovery.back}
          </div>
        </div>
      </form>
    </>
  );

  return (
    <>
      <div className='form-login form-wrapper'>
        <div className={`form-box box ${backgroundImage ? 'form-box-opacity' : ''}`}>
          {
            platform.images.logo?.path && (
              <img className='form-logo' src={FILES_BASE_URL + platform.images.logo.path} alt={platform.name} />
            )
          }
          {
            !showRecoveryForm
              ? renderLoginForm()
              : renderRecoveryForm()
          }
        </div>
      </div>
      {
        backgroundImage ? (
          <div className='background-image'>
            <img src={backgroundImage} alt={platform.name} />
          </div>
        ) : (
          <img className='background-dots' src={Dots} alt='dots' />
        )
      }
    </>
  );
};

Login.propTypes = {
  platform: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  onLogin: PropTypes.func.isRequired,
  onRecovery: PropTypes.func.isRequired,
  loginError: PropTypes.bool,
  recoveryError: PropTypes.object,
  cleanErrors: PropTypes.func.isRequired,
};

Login.defaultProps = {
  loginError: null,
  recoveryError: null,
};

export default Login;
