import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Panel from 'components/Panel/components';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelTab from 'components/Panel/components/PanelTab';
import InputToggle from 'components/Inputs/inputToggle';
import Alert from 'components/Alert';
import Popup from 'components/Popup';
import Button from 'components/Buttons/Button';
import EmptyGraph from 'assets/svg/empty_graph.svg';
import Loading from 'components/Loading';
import IOFileLoading from 'components/IOFileLoading/components';
import {
  Sheet, Pencil,
  CirclePlus, Upload, Download, LineChart, Mail, ArrowUpAZ,
} from 'lucide-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import { TOOL_PERFORMANCE } from 'constants/tools';
import { KPI_CATEGORY, KPI_PERIODICITY } from 'constants/kpis';
import { formatDate, startupCanManage } from 'utils/functions';
import { useCounter } from 'utils/customHooks';
import { PERMISSION_LEVELS } from 'constants/permissions';
import { downloadExcelKpis, initializeKpiRelations } from '../modules/actions';
import KpiRelationCard from './KpiRelationCard';
import KpiRelationDetails from './KpiRelationDetails';
import PopupUploadExcelKpis from './PopupUploadExcel';
import PopupEmailBotKpis from './PopupEmailBotKpis';
import PerformanceFill from './PerformanceFillTable';
import PerformanceSummaryTable from './PerformanceSummaryTable';
import OrderKpisPopup from './OrderKpisPopup';

import 'swiper/css';
import 'swiper/css/pagination';
import './styles.scss';

const Performance = (props) => {
  const {
    literals,
    literalsCommon,
    match,
    performance,
    platform,
    startup,
    lang,
    currency,
    fetchKpiRelations,
    postKpiBookmark,
    deleteKpiBookmark,
    orderKpis,
  } = props;

  const [detailKpiRelation, setDetailKpiRelation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadPopup, setUploadPopup] = useState(false);
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const [emailBotPopup, setEmailBotPopup] = useState(false);
  const [summaryMode, setSummaryMode] = useState(false);
  const [orderKpisPopup, setOrderKpisPopup] = useState(false);
  const tableRefreshFlag = useCounter(0);
  const onEditRefreshFlag = useCounter(0);
  const navigate = useNavigate();
  const canManagePerformance = startupCanManage(startup, TOOL_PERFORMANCE);
  const isGuest = !startup.relation.id;

  useEffect(() => {
    initializeKpiRelations(fetchKpiRelations, performance, 'startup', match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    tableRefreshFlag.increase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performance.relations]);

  const pendingQuarter = (month, year) => {
    if (month < 3) {
      return `${+year - 1}-12`;
    }
    if (month < 6) {
      return `${year}-03`;
    }
    if (month < 9) {
      return `${year}-06`;
    }
    if (month < 12) {
      return `${year}-09`;
    }
    return `${year}-${month}`;
  };

  const pendingValuesNum = () => {
    const [year, month] = formatDate(new Date(), { format: 'Y-m-d' }).split('-');
    return performance.relations.filter(relation => (relation.permission.level >= PERMISSION_LEVELS.EDIT && (!relation.value.date || !relation.value.actual
      || (relation.kpi.periodicity === KPI_PERIODICITY.MONTHLY && relation.value.date !== `${year}-${month}`)
      || (relation.kpi.periodicity === KPI_PERIODICITY.ANNUAL && (relation.value.date !== (month !== '12' ? (+year - 1).toString() : year)))
      || (relation.kpi.periodicity === KPI_PERIODICITY.QUARTERLY && relation.value.date !== pendingQuarter(month, year)))
    )).length;
  };

  const handleClickBookmark = async (relation, bookmark) => {
    setLoading(relation.id);
    if (!bookmark) {
      await postKpiBookmark(relation.kpi.id, 'startup', match.params.id, relation.id);
    } else {
      await deleteKpiBookmark(relation.kpi.id, 'startup', match.params.id, relation.id);
    }
    setLoading(false);
  };

  const handleDownloadExcel = async () => {
    setDownloadingExcel(true);
    await downloadExcelKpis('startup', match.params.id, startup.name);
    setDownloadingExcel(false);
  };

  const relation = detailKpiRelation ? performance.relations.find(rel => rel.id === detailKpiRelation) : null;

  if (!performance.loaded && !performance.relations.length) {
    return <Loading hide={false} mode='tool' />;
  }

  const tabs = Object.values(KPI_CATEGORY).map(categ => ({
    key: categ,
    name: literals[categ],
    hidden: !performance.relations.some(kpiRelation => kpiRelation.kpi.category === categ),
  }));
  tabs.unshift({ key: 'all', name: literalsCommon.all });

  return (
    <Tool className='performance-wrapper'>
      {
        performance.relations.length ? (
          <>
            {pendingValuesNum() ? (
              <Link to={embedView(ROUTE_STARTUP_PATH.setPerformancePending(match.params.id))}>
                <Alert type='warning' text={`${literals.pendingKpis} (${pendingValuesNum()})`} className='shadow-light' />
              </Link>
            ) : null}
            <Swiper
              className='swiper-pagination-below mb-3'
              slidesPerView={1}
              spaceBetween={15}
              breakpoints={{
                768: { slidesPerView: 2 },
                1280: { slidesPerView: 3 },
                1750: { slidesPerView: 4 },
              }}
              pagination={{ clickable: true }}
              modules={[Pagination]}
            >
              { performance.relations.filter(rel => rel.bookmark)
                .sort((a, b) => (a.order > b.order ? 1 : -1))
                .map(rel => (
                  <SwiperSlide key={rel.id}>
                    <KpiRelationCard
                      literals={literals}
                      literalsCommon={literalsCommon}
                      currency={currency}
                      lang={lang}
                      data={rel}
                      onClick={() => setDetailKpiRelation(rel.id)}
                      onDeleteBookmark={() => handleClickBookmark(rel, true)}
                      loading={!!loading}
                      canEdit={canManagePerformance}
                    />
                  </SwiperSlide>
                ))
              }
            </Swiper>

            <Panel active='all'>
              <PanelHeader
                title={literals.kpi}
                tabs={tabs}
                actions={[{
                  text: literals.actions,
                  buttons: [
                    {
                      icon: CirclePlus,
                      text: literals.addKpis,
                      onClick: () => navigate(embedView(ROUTE_STARTUP_PATH.setPerformanceAdd(match.params.id))),
                    },
                    {
                      icon: ArrowUpAZ,
                      text: literals.orderKpis,
                      onClick: () => setOrderKpisPopup(true),
                      hidden: !isGuest && !canManagePerformance,
                    },
                    {
                      icon: LineChart,
                      text: literals.comparator,
                      onClick: () => navigate(embedView(ROUTE_STARTUP_PATH.setPerformanceComparator(match.params.id))),
                    },
                    {
                      icon: Download,
                      text: literals.downloadExcel,
                      onClick: handleDownloadExcel,
                    },
                    {
                      icon: Upload,
                      text: literals.uploadExcel,
                      onClick: () => setUploadPopup(true),
                    },
                    {
                      icon: Mail,
                      text: literals.emailBot.emailBot,
                      onClick: () => setEmailBotPopup(true),
                      hidden: !canManagePerformance || !platform?.emailBot,
                    },
                  ],
                }]}
              />
              <PanelBody>
                { !window?.location?.pathname?.startsWith('/embed/') && (
                  <div className='toggle-table'>
                    <Pencil size={16} />
                    <InputToggle
                      value={summaryMode}
                      onChange={setSummaryMode}
                    />
                    <Sheet size={16} />
                  </div>
                )}
                {
                  tabs.map(tab => (
                    <PanelTab key={tab.key}>
                      {
                        summaryMode ? (
                          <PerformanceSummaryTable
                            {...props}
                            setDetailKpiRelation={setDetailKpiRelation}
                            category={tab.key}
                            mainRefreshFlag={onEditRefreshFlag.value}
                          />
                        ) : (
                          <PerformanceFill
                            {...props}
                            setDetailKpiRelation={setDetailKpiRelation}
                            category={tab.key}
                            mainRefreshFlag={onEditRefreshFlag.value}
                          />
                        )
                      }
                    </PanelTab>
                  ))
                }
              </PanelBody>
            </Panel>
          </>
        ) : (
          <Panel>
            <PanelHeader title={literals.title} />
            <PanelBody>
              <div className='d-flex justify-content-end'>
                <Button
                  onClick={() => navigate(embedView(ROUTE_STARTUP_PATH.setPerformanceAdd(match.params.id)))}
                  text={literals.addKpis}
                  icon={CirclePlus}
                />
              </div>
              <div className='panel-empty-message'>
                <img src={EmptyGraph} alt={literals.noKpis} />
                <p>{literals.noKpis}</p>
              </div>
            </PanelBody>
          </Panel>
        )
      }
      {
        relation && (
          <Popup title={literals[relation.kpi?.category]} size='large' onClose={() => setDetailKpiRelation(false)}>
            <KpiRelationDetails
              key={relation.id}
              relation={relation}
              startup={startup}
              onDeleteRelation={() => setDetailKpiRelation(false)}
              onClose={() => setDetailKpiRelation(false)}
            />
          </Popup>
        )
      }
      {uploadPopup && (
        <PopupUploadExcelKpis
          literals={literals}
          literalsCommon={literalsCommon}
          startup={startup}
          relations={performance.relations}
          lang={lang}
          fetchKpiRelations={fetchKpiRelations}
          onClose={() => setUploadPopup(false)}
          match={match}
          refreshTable={onEditRefreshFlag.increase}
        />
      )}
      {emailBotPopup && (
        <PopupEmailBotKpis
          literals={literals.emailBot}
          startup={startup}
          email={platform?.emailBot}
          onClose={() => setEmailBotPopup(false)}
        />
      )}
      {orderKpisPopup && (
        <OrderKpisPopup
          literals={literals}
          literalsCommon={literalsCommon}
          startup={startup.id}
          lang={lang}
          relations={performance.relations}
          orderKpis={orderKpis}
          onClose={() => setOrderKpisPopup(false)}
        />
      )}
      { downloadingExcel && <IOFileLoading mode='download' file={{ name: `KPIs_${startup.name}.xlsx` }} /> }
    </Tool>
  );
};

Performance.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  performance: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  currency: PropTypes.object.isRequired,
  fetchKpiRelations: PropTypes.func.isRequired,
  postKpiBookmark: PropTypes.func.isRequired,
  deleteKpiBookmark: PropTypes.func.isRequired,
  orderKpis: PropTypes.func.isRequired,
};

export default Performance;
