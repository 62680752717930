import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const StartupsInstance = ApiFetch();

export const URLS = {
  STARTUPS: 'startups',
  STARTUPS_SEARCHER: 'startups/searcher',
  STARTUP: 'startups/:id',
  STARTUP_JOIN: 'startups/:id/join',
  STARTUP_SHARE: 'startups/:id/share',
  STARTUP_LEAVE: 'startups/:id/leave',
  STARTUP_REQUEST: 'startups/:id/request',
  STARTUP_USERS: 'startups/:id/users',
  STARTUP_GUESTS: 'startups/:id/guests',
  STARTUP_USER: 'startups/:id/users/:user',
  STARTUP_USER_GROUPS: 'startups/:id/users/:user/groups',
  STARTUP_USER_STATUS: 'startups/:id/users/:user/status/:status',
  STARTUP_LOGO: 'startups/:id/documents/logo',
  STARTUP_EVENT: 'startups/:id/events',
  STARTUP_MESSAGES: 'startups/:id/messages',
};

async function startupsGet(params = {}) {
  const path = preparePath(URLS.STARTUPS, {}, params);
  return StartupsInstance.get(path);
}

async function startupCreate(data) {
  return StartupsInstance.post(URLS.STARTUPS, data);
}

async function getStartupsSearcher(params = {}) {
  const path = preparePath(URLS.STARTUPS_SEARCHER, {}, params);
  return StartupsInstance.get(path);
}

async function getStartupInfo(id, params = {}) {
  const path = preparePath(URLS.STARTUP, { id }, params);
  return StartupsInstance.get(path);
}

async function startupEdit(id, data) {
  const path = preparePath(URLS.STARTUP, { id });
  return StartupsInstance.put(path, data);
}

async function startupDelete(id) {
  const path = preparePath(URLS.STARTUP, { id });
  return StartupsInstance.delete(path);
}

async function joinToStartup(id, data) {
  const path = preparePath(URLS.STARTUP_JOIN, { id });
  return StartupsInstance.post(path, data);
}

async function shareStartup(id, data) {
  const path = preparePath(URLS.STARTUP_SHARE, { id });
  return StartupsInstance.post(path, data);
}

async function leaveStartup(id) {
  const path = preparePath(URLS.STARTUP_LEAVE, { id });
  return StartupsInstance.post(path);
}

async function requestStartupAccess(id, opts) {
  const path = preparePath(URLS.STARTUP_REQUEST, { id }, opts);
  return StartupsInstance.post(path);
}

async function getStartupUsers(id, params = {}) {
  const path = preparePath(URLS.STARTUP_USERS, { id }, params);
  return StartupsInstance.get(path);
}

async function startupAddUser(id, data) {
  const path = preparePath(URLS.STARTUP_USERS, { id });
  return StartupsInstance.post(path, data);
}

async function getStartupGuestsUsers(id, params = {}) {
  const path = preparePath(URLS.STARTUP_GUESTS, { id }, params);
  return StartupsInstance.get(path);
}

async function startupEditUser(id, user, data) {
  const path = preparePath(URLS.STARTUP_USER, { id, user });
  return StartupsInstance.put(path, data);
}

async function deleteAddUser(id, user) {
  const path = preparePath(URLS.STARTUP_USER, { id, user });
  return StartupsInstance.delete(path);
}

async function getUserGroups(id, user) {
  const path = preparePath(URLS.STARTUP_USER_GROUPS, { id, user });
  return StartupsInstance.get(path);
}

async function changeStartupInviteStatus(id, user, status) {
  const path = preparePath(URLS.STARTUP_USER_STATUS, { id, user, status });
  return StartupsInstance.put(path);
}

export default {
  startupsGet,
  startupCreate,
  getStartupsSearcher,
  getStartupInfo,
  startupEdit,
  startupDelete,
  joinToStartup,
  shareStartup,
  leaveStartup,
  requestStartupAccess,
  getStartupUsers,
  startupAddUser,
  getStartupGuestsUsers,
  startupEditUser,
  deleteAddUser,
  getUserGroups,
  changeStartupInviteStatus,
};
