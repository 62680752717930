import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PanelHeader from 'components/Panel/components/PanelHeader';
import Panel from 'components/Panel';
import PanelBody from 'components/Panel/components/PanelBody';
import Table from 'components/Table';
import useDialog from 'components/Dialog/components/useDialog';
import { UserMinus, UserPlus } from 'lucide-react';
import { getFullName, startupCanEdit } from 'utils/functions';
import { TOOL_USERS } from 'constants/tools';
import { useCounter } from 'utils/customHooks';
import { fetchStartupUsers } from 'routes/StartupUsers/modules/actions';
import { getShareHolder, unbindUserToShareholder } from '../../../../modules/actions';
import PopupInviteShareholder from '../../../PopupInviteShareholder';

const ShareholderUsers = (props) => {
  const {
    literals,
    literalsShareholder,
    literalsCommon,
    match,
    startup,
    lang,
  } = props;

  const [shareholder, setShareHolder] = useState();
  const [inviteShareholder, setInviteShareholder] = useState(false);

  const tableRefreshFlag = useCounter(0);
  const { dialog } = useDialog();

  const getShareHolderInfo = async () => {
    const sh = await getShareHolder(match.params.id, match.params.shareholder);
    setShareHolder(sh);
  };

  useEffect(() => {
    getShareHolderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRefreshFlag.value]);

  const fetchUsers = (page, size, search) => {
    return fetchStartupUsers(match.params.id, {
      page,
      size,
      search,
      filters: { shareholders: [match.params.shareholder] },
    });
  };

  const onUnbindUser = async (unbindUser) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literals.unbindWarning,
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await unbindUserToShareholder(match.params.id, match.params.shareholder, unbindUser.id)
            .then(() => tableRefreshFlag.increase())
            .catch(async () => {
              await dialog({
                type: 'error',
                text: literals.bindingError,
              });
            });
        },
      });
    }
  };

  const handleClosePopup = () => {
    setInviteShareholder(null);
    tableRefreshFlag.increase();
  };

  const columns = [
    {
      field: 'avatar',
      width: 45,
      type: 'avatar',
      label: '',
      title: (_, row) => getFullName(row),
    },
    { field: 'firstname', grow: 1, label: literals.name },
    { field: 'lastname', grow: 3, label: literals.lastname },
    { field: 'email', grow: 5, label: literals.email },
    {
      field: 'role',
      width: 100,
      label: literals.role,
    },
  ];


  if (startupCanEdit(startup, TOOL_USERS)) {
    columns.push({
      type: 'button',
      button: {
        text: literalsShareholder.unbindUser,
        icon: UserMinus,
        onClick: onUnbindUser,
      },
    });
  }

  return (
    <Panel>
      <PanelHeader title={literals.usersLinked} />
      <PanelBody>
        <Table
          columns={columns}
          fetch={fetchUsers}
          forceFetch={tableRefreshFlag.value}
          actions={[
            { icon: UserPlus, text: literals.inviteUser, onClick: () => setInviteShareholder(shareholder) },
          ]}
        />
        {inviteShareholder && (
          <PopupInviteShareholder
            literals={literalsShareholder}
            literalsCommon={literalsCommon}
            match={match}
            tableRefreshFlag={tableRefreshFlag}
            shareholder={inviteShareholder}
            lang={lang}
            onClose={handleClosePopup}
          />
        )}
      </PanelBody>
    </Panel>
  );
};

ShareholderUsers.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  literalsShareholder: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};

export default ShareholderUsers;
