import InvestmentsInstance from 'modules/investments/repository';
import { apiDownloadFile } from 'modules/actions';
import { API_INVESTMENTS_EXCEL, API_INVESTMENTS_KPIS_EXCEL } from 'modules/apiEndpoints';


export function getInvestments(taxId, date, page, size, search, sort) {
  return InvestmentsInstance.getInvestments({
    taxId, page, size, date, search, sort,
  });
}

export function getInvestmentsStats(taxId, date) {
  return InvestmentsInstance.getInvestmentsStats({ taxId, date });
}

export function getInvestmentsChart(taxId, date) {
  return InvestmentsInstance.getInvestmentsChart({ taxId, date });
}

export function getInvestmentsKpis(taxId, date) {
  return InvestmentsInstance.getInvestmentsKpis({ taxId, date });
}

export function editInvestmentsShareholders(shareholders) {
  return InvestmentsInstance.editInvestmentsShareholders({ shareholders });
}

export function getInvestmentsShareholders(opts) {
  return InvestmentsInstance.getInvestmentsShareholders(opts);
}

export function downloadInvestmentsExcel(sh = null, name = null) {
  return apiDownloadFile(API_INVESTMENTS_EXCEL(sh), name || 'invetements_portfolio.xlsx');
}

export function downloadInvestmentsKpisExcel(sh = null, currency, name = null) {
  const params = new URLSearchParams();

  if (sh) {
    params.append('taxId', sh);
  }

  if (currency) {
    params.append('currency', currency);
  }

  return apiDownloadFile(API_INVESTMENTS_KPIS_EXCEL(params.toString()), name || 'investments_analysis.xlsx');
}

export function getFundraises(opts) {
  return InvestmentsInstance.getFundraises(opts);
}
