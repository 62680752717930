import AccountsInstance from 'modules/accounts/repository';
import MentoringsInstance from 'modules/mentorings/repository';
import AdminInstance from 'modules/admin/repository';

import { apiDownloadFile } from 'modules/actions';
import { API_ADMIN_USERS_EXCEL, API_ADMIN_USERS_EXCEL_TEMPLATE } from 'modules/apiEndpoints';
import { SessionService } from 'modules/session/sessionService';


export function fetchUsers(page, size, filters) {
  return AdminInstance.getUsers({
    page, size, ...filters,
  });
}

export function createUser(data) {
  return AdminInstance.postUsers(data);
}

export function editUser(id, data) {
  return AccountsInstance.editProfile(id, data);
}

export function deleteUser(id) {
  return AdminInstance.deleteUser(id);
}

export function putMentorGroup(mentor, group) {
  return AdminInstance.setMentorGroup(mentor, group);
}

export function fetchMentor(id) {
  return MentoringsInstance.getMentoringMentor(id);
}

export function putMentor(id, object) {
  return MentoringsInstance.putMentoringMentor(id, object);
}

export function getStartups(id, pageAux, sizeAux, searchAux) {
  const opts = {
    page: pageAux,
    size: sizeAux,
    search: searchAux,
    user: id,
  };
  return AdminInstance.getStartups(opts);
}

export function downloadExcelUsers(filename) {
  return apiDownloadFile(API_ADMIN_USERS_EXCEL, filename);
}

export function downloadExcelUsersTemplate(filename) {
  return apiDownloadFile(API_ADMIN_USERS_EXCEL_TEMPLATE, filename);
}

export function createUsersMassive(file) {
  return AdminInstance.createUsersMassive(file);
}

export async function impersonateUser(id) {
  const user = await AdminInstance.impersonateUser(id);
  await SessionService.saveUser(user);
  return user;
}
