import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Button from 'components/Buttons/Button';
import InputText from 'components/Inputs/inputText';
import InputSelect from 'components/Inputs/inputSelect';
import InputTextArea from 'components/Inputs/inputTextArea';
import InputRichText from 'components/Inputs/inputRichText';
import InputAvatar from 'components/Inputs/inputAvatar';
import InputToggle from 'components/Inputs/inputToggle';
import OutputErrors from 'components/Inputs/outputErrors';
import Separator from 'components/Separator';
import { Building } from 'lucide-react';
import { EN_LANGUAGE, ES_LANGUAGE } from 'utils/language';
import { createService, editService } from '../modules/actions';
import './styles.scss';

const defaultServiceData = {
  logo: null,
  visible: true,
  type: { es: '', en: '' },
  company: { es: '', en: '' },
  shortDescription: { es: '', en: '' },
  description: { es: '', en: '' },
};

const FormServices = (props) => {
  const {
    literals,
    literalsCommon,
    service,
    refreshTable,
    onDelete,
    onClose,
    canEdit,
  } = props;

  const [serviceData, setServiceData] = useState(service || defaultServiceData);
  const [lang, setLang] = useState(ES_LANGUAGE);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleSubmitForm = async () => {
    setErrors([]);
    setLoading(true);
    try {
      if (service) {
        const { id, ...otherData } = serviceData;
        await editService(id, { ...otherData, logo: otherData?.logo?.id });
      } else {
        await createService({ ...serviceData, logo: serviceData?.logo?.id });
      }
      refreshTable();
      onClose();
    } catch (err) {
      setErrors(err.body);
      setLoading(false);
    }
  };

  const title = service ? literals.editService : literals.createService;
  return (
    <Popup title={title} onClose={onClose}>
      <div className='row'>
        <div className='col-12 offset-md-8 col-md-4'>
          <InputSelect
            className='mb-0'
            preText={literalsCommon.language}
            onChange={v => setLang(v)}
            value={lang}
            options={[
              { id: ES_LANGUAGE, name: literalsCommon.languages.es },
              { id: EN_LANGUAGE, name: literalsCommon.languages.en },
            ]}
          />
        </div>
      </div>
      <Separator />
      <InputToggle
        preText={literals.visible}
        value={serviceData.visible}
        onChange={v => setServiceData(prev => ({ ...prev, visible: v }))}
        isDisabled={!canEdit}
      />
      <InputAvatar
        preText={literalsCommon.avatar}
        onChange={v => setServiceData(prev => ({ ...prev, logo: v }))}
        value={serviceData.logo}
        image={<Building size={50} className='file-icon' />}
        isDisabled={!canEdit}
      />
      <div className='row'>
        <div className='col-12 col-md-7'>
          <InputText
            preText={literals.company}
            onChange={v => setServiceData(prev => ({ ...prev, company: { ...prev.company, [lang]: v } }))}
            value={serviceData.company[lang]}
            isRequired
            isDisabled={!canEdit}
          />
        </div>
        <div className='col-12 col-md-5'>
          <InputText
            preText={literalsCommon.type}
            onChange={v => setServiceData(prev => ({ ...prev, type: { ...prev.type, [lang]: v } }))}
            value={serviceData.type[lang]}
            isDisabled={!canEdit}
            isRequired
          />
        </div>
      </div>
      <InputTextArea
        preText={literals.shortDescription}
        onChange={v => setServiceData(prev => ({ ...prev, shortDescription: { ...prev.shortDescription, [lang]: v } }))}
        value={serviceData.shortDescription[lang]}
        isDisabled={!canEdit}
        isRequired
      />
      <InputRichText
        key={`description-${lang}`}
        preText={literals.detailedDescription}
        onChange={v => setServiceData(prev => ({ ...prev, description: { ...prev.description, [lang]: v } }))}
        value={serviceData.description[lang]}
        minHeight='200px'
        isDisabled={!canEdit}
        isRequired
      />
      <OutputErrors literals={literalsCommon} errors={errors} />
      <div className='buttons'>
        {canEdit ? (
          <>
            { service && (
              <Button
                color='danger'
                text={literalsCommon.delete}
                onClick={() => { onDelete(service); onClose(); }}
                loading={loading}
              />
            )}
            <Button
              text={service ? literalsCommon.edit : literalsCommon.create}
              onClick={handleSubmitForm}
              loading={loading}
            />
          </>

        ) : (
          <Button
            text={literalsCommon.close}
            onClick={onClose}
          />
        )}

      </div>
    </Popup>
  );
};

FormServices.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  service: PropTypes.object,
  refreshTable: PropTypes.func,
  onDelete: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
};

FormServices.defaultProps = {
  canEdit: true,
  service: null,
  refreshTable: null,
  onDelete: null,
};

export default FormServices;
