import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelTab from 'components/Panel/components/PanelTab';
import Button from 'components/Buttons/Button';
import InputText from 'components/Inputs/inputText';
import InputTextArea from 'components/Inputs/inputTextArea';
import InputEmail from 'components/Inputs/inputEmail';
import InputSelect from 'components/Inputs/inputSelect';
import InputFileZone from 'components/Inputs/inputFileZone';
import OutputErrors from 'components/Inputs/outputErrors';
import useDialog from 'components/Dialog/components/useDialog';
import IOFileLoading from 'components/IOFileLoading/components';
import { ChevronLeft, CloudUpload, Plus } from 'lucide-react';
import { formatErrors } from 'utils/functions';
import { EN_LANGUAGE, ES_LANGUAGE, literalTemplate } from 'utils/language';
import { createStartup, createStartupMassive, downloadExcelStartupsTemplate } from '../modules/actions';

const initialManualData = {
  form: {
    name: '',
    description: '',
    group: '',
    userFirstname: '',
    userLastname: '',
    userEmail: '',
    userLang: '',
    sendEmail: false,
    checkName: false,
  },
  errors: [],
  loading: false,
  response: null,
};

const initialExcelData = {
  form: {
    file: null,
    checkName: true,
    sendEmail: false,
  },
  loading: false,
  response: null,
};

const FormStartups = (props) => {
  const { literals, onCreate, platform } = props;

  const [manualData, setManualData] = useState(initialManualData);
  const [excelData, setExcelData] = useState(initialExcelData);
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const { dialog } = useDialog();

  const setManualDataField = (value, property) => {
    setManualData({
      ...manualData,
      form: {
        ...manualData.form,
        [property]: value,
      },
    });
  };

  const submitForm = async (event) => {
    event.preventDefault();
    setManualData({ ...initialManualData, loading: true, errors: [] });
    try {
      const data = { ...manualData.form };

      const response = await createStartup(data);
      setManualData({ ...initialManualData, response });
      dialog({
        type: 'success',
        text: 'Creada!',
      });
      if (onCreate) {
        onCreate();
      }
    } catch (e) {
      const errors = formatErrors(e);
      setManualData({ ...excelData, loading: false, errors });
    }
  };

  const onFileUpload = (file) => {
    setExcelData({
      ...excelData, form: { ...excelData.form, file },
    });
  };

  const setExcelField = (value, property) => {
    setExcelData({
      ...excelData, form: { ...excelData.form, [property]: value },
    });
  };

  const submitFormExcel = async () => {
    setExcelData({ ...excelData, loading: true, errors: [] });
    const response = await createStartupMassive(excelData.form.file, excelData.form.checkName, excelData.form.sendEmail);
    setExcelData({ ...excelData, loading: false, response });
  };

  const handleDownloadExcelTemplate = async () => {
    setDownloadingExcel(true);
    await downloadExcelStartupsTemplate('Startups_template.xlsx');
    setDownloadingExcel(false);
  };

  const renderExcelForm = () => {
    if (excelData.response) {
      const numSuccess = excelData?.response?.success?.length ?? 0;
      const successMessage = literalTemplate(`<span>${numSuccess !== 1 ? literals.successCreate : literals.successCreateOne}</span>`, {
        SUCCESS: `<span class='fw-b'>${numSuccess}</span>`,
      });

      return (
        <div className='massiveExcelResponse'>
          <div dangerouslySetInnerHTML={{ __html: successMessage }} />
          <div className='fw-b mt-3'>{literals.rowErrors}</div>
          <ul>
            {excelData.response.errors && excelData.response.errors.map((error) => {
              let errorMessage = '';
              switch (error.error) {
                case 'empty_name':
                  errorMessage = literals.emptyName;
                  break;
                default:
                  errorMessage = error.error;
              }

              return (
                <li className='ml-2'>
                  <span className='fw-b'>{`${literals.row} ${error.row}: `}</span>
                  <span>{errorMessage}</span>
                </li>
              );
            })}
          </ul>
          <div className='buttons mt-3'>
            <Button text='Atras' icon={ChevronLeft} onClick={() => setExcelData(initialExcelData)} />
          </div>
        </div>
      );
    }

    return (
      <>
        <div className='row'>
          <div className='col-12'>
            <span className='h6 fw-b link-effect' onClick={handleDownloadExcelTemplate}>{literals.exportExcel}</span>
            <span className='h6 fw-b'>{` ${literals.andUpload}`}</span>
            <InputFileZone onChange={onFileUpload} value={excelData.form.file} />
          </div>
          <div className='col-12 col-md-7'>
            <InputSelect
              preText={literals.validateDuplicatesName}
              value={excelData.form.checkName}
              onChange={v => setExcelField(v, 'checkName')}
              options={[
                { id: 'nothing', name: literals.nothing },
                { id: 'edit', name: literals.edit },
                { id: 'duplicate', name: literals.duplicate },
              ]}
            />
          </div>
          <div className='col-12 col-md-5'>
            <InputSelect
              preText={literals.sendEmail}
              value={excelData.form.sendEmail}
              onChange={v => setExcelField(v, 'sendEmail')}
              options={[
                { id: false, name: literals.no },
                { id: true, name: literals.yes },
              ]}
            />
          </div>
        </div>
        <div className='buttons'>
          <Button text={literals.send} icon={CloudUpload} onClick={submitFormExcel} disabled={!excelData.form.file} loading={excelData.loading} />
        </div>
      </>
    );
  };

  return (
    <Panel>
      <PanelHeader tabs={[{ key: 'manual', name: 'Manual' }, { key: 'excel', name: 'Excel' }]} />
      <PanelBody>
        <PanelTab key='manual'>
          <form onSubmit={submitForm}>
            <div className='row'>
              <div className='col-9'>
                <InputText
                  preText={literals.startupName}
                  value={manualData.form.name}
                  onChange={v => setManualDataField(v, 'name')}
                />
              </div>
              {
                platform.mentorings?.groups?.length ? (
                  <div className='col-3'>
                    <InputSelect
                      preText={literals.group}
                      value={manualData.form.group}
                      onChange={v => setManualDataField(v, 'group')}
                      options={platform.mentorings.groups.map(g => ({ id: g, name: g }))}
                    />
                  </div>
                ) : null
              }
              <div className='col-12'>
                <InputTextArea
                  preText={literals.description}
                  value={manualData.form.description}
                  onChange={v => setManualDataField(v, 'description')}
                />
              </div>

              <div className='col-12 col-md-4'>
                <InputText
                  preText={literals.name}
                  value={manualData.form.userFirstname}
                  onChange={v => setManualDataField(v, 'userFirstname')}
                />
              </div>
              <div className='col-12 col-md-8'>
                <InputText
                  preText={literals.lastname}
                  value={manualData.form.userLastname}
                  onChange={v => setManualDataField(v, 'userLastname')}
                />
              </div>
              <div className='col-6'>
                <InputEmail
                  preText={literals.email}
                  value={manualData.form.userEmail}
                  onChange={v => setManualDataField(v, 'userEmail')}
                />
              </div>
              <div className='col-3'>
                <InputSelect
                  preText={literals.language}
                  options={[{ id: ES_LANGUAGE, name: ES_LANGUAGE }, { id: EN_LANGUAGE, name: EN_LANGUAGE }]}
                  value={manualData.form.userLang}
                  onChange={v => setManualDataField(v, 'userLang')}
                />
              </div>
              <div className='col-3'>
                <InputSelect
                  preText={literals.sendEmail}
                  options={[
                    { id: false, name: literals.no },
                    { id: true, name: literals.yes },
                  ]}
                  value={manualData.form.sendEmail}
                  onChange={v => setManualDataField(v, 'sendEmail')}
                />
              </div>
              <div className='col-12'>
                <OutputErrors errors={manualData.errors} />
                <div className='buttons'>
                  <Button icon={Plus} text={literals.send} type='submit' loading={manualData.loading} />
                </div>
              </div>
            </div>
          </form>
        </PanelTab>
        <PanelTab key='excel'>
          {renderExcelForm()}
          { downloadingExcel && <IOFileLoading mode='download' file={{ name: 'Startups_template.xlsx' }} /> }
        </PanelTab>
      </PanelBody>
    </Panel>
  );
};

FormStartups.propTypes = {
  literals: PropTypes.object.isRequired,
  onCreate: PropTypes.func.isRequired,
  platform: PropTypes.object.isRequired,
};

export default FormStartups;
