import initialState from 'store/globalState/initialState';

import {
  FETCH_LOGOUT_INIT,
  FETCH_LOGOUT_FAILURE,
} from './types';

export default function logoutReducer(state = initialState.logout, action) {
  switch (action.type) {
    case FETCH_LOGOUT_INIT:
      return {
        inProgress: true,
      };
    case FETCH_LOGOUT_FAILURE:
      return {
        inProgress: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
