import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Button from 'components/Buttons/Button';
import InputText from 'components/Inputs/inputText';
import InputFile from 'components/Inputs/inputFile';
import InputRichText from 'components/Inputs/inputRichText';
import OutputErrors from 'components/Inputs/outputErrors';
import { putStartupOperation } from '../modules/actions';
import './styles.scss';

const PopupEditInfo = (props) => {
  const {
    literals,
    literalsCommon,
    match,
    row,
    onSubmit,
    onClose,
  } = props;

  const [name, setName] = useState(row.name);
  const [notes, setNotes] = useState(row.notes);
  const [documents, setDocuments] = useState(row.documents);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const handleSubmit = () => {
    setLoading(true);
    putStartupOperation(match.params.id, row.id, {
      name, notes, documents, basicInfo: true, captable: row?.captable,
    }).then(() => {
      onSubmit();
      onClose();
    }).catch((err) => {
      setLoading(false);
      setError(err);
    });
  };

  return (
    <Popup title={literals.editOperation} onClose={onClose}>
      <InputText preText={literalsCommon.name} value={name} onChange={v => setName(v)} />
      <InputRichText minHeight='120px' preText={literalsCommon.notes} value={notes} onChange={v => setNotes(v)} />
      <InputFile
        preText={literalsCommon.documents}
        value={documents}
        isPublic={false}
        onChange={v => setDocuments(v)}
        multiple
      />
      { error && <OutputErrors errors={error} /> }
      <div className='d-flex justify-content-end'>
        <Button text={literalsCommon.confirm} onClick={handleSubmit} loading={loading} />
      </div>
    </Popup>
  );
};

PopupEditInfo.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupEditInfo;
