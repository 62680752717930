import initialState from '../../../store/globalState/initialState';

import {
  FETCH_STARTUP_QUESTIONS_INIT,
  FETCH_STARTUP_QUESTIONS_FAILURE,
  FETCH_STARTUP_QUESTIONS_SUCCESS,
  FETCH_MENTOR_QUESTIONS_INIT,
  FETCH_MENTOR_QUESTIONS_SUCCESS,
  FETCH_MENTOR_QUESTIONS_FAILURE,
} from './types';

export default function editInfoReducer(state = initialState.editInfo, action) {
  switch (action.type) {
    case FETCH_MENTOR_QUESTIONS_INIT:
    case FETCH_STARTUP_QUESTIONS_INIT:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MENTOR_QUESTIONS_SUCCESS:
    case FETCH_STARTUP_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        extraQuestions: action.payload,
      };
    case FETCH_MENTOR_QUESTIONS_FAILURE:
    case FETCH_STARTUP_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
