import i18n from '../../../i18n';
import { ALLOWED_LANGUAGES, getBrowserLanguage } from '../../../utils/language';

import {
  SET_PLATFORM_LANGUAGE,
} from './types';

const USER_LANG = getBrowserLanguage();

const INITIAL_LANG_CONF = {
  language: USER_LANG,
  literals: i18n[USER_LANG],
};

const isObjectMDP = (item) => {
  return (item && typeof item === 'object' && !Array.isArray(item));
};

const mergeDeepLang = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObjectMDP(target) && isObjectMDP(source)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in source) {
      if (isObjectMDP(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        mergeDeepLang(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return mergeDeepLang(target, ...sources);
};

export default function i18nReducer(state = INITIAL_LANG_CONF, { type, payload }) {
  switch (type) {
    case SET_PLATFORM_LANGUAGE: {
      const auxLang = ALLOWED_LANGUAGES.includes(payload.lang) ? payload.lang : null;
      const userLang = auxLang || state.language || USER_LANG;

      let literals = i18n[userLang];
      if (payload.literals && payload.literals[userLang]) {
        literals = mergeDeepLang(i18n[userLang], payload.literals[userLang]);
      }
      return {
        language: userLang,
        literals,
      };
    }
    default:
      return state;
  }
}
