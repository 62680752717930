import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import InputText from 'components/Inputs/inputText';
import InputEmail from 'components/Inputs/inputEmail';
import InputSelect from 'components/Inputs/inputSelect';
import InputRichText from 'components/Inputs/inputRichText';
import Button from 'components/Buttons/Button';
import { CloudUpload } from 'lucide-react';
import { formatErrors, stripTags } from 'utils/functions';
import { EN_LANGUAGE, ES_LANGUAGE } from 'utils/language';
import { editUser } from '../modules/actions';

const FormEditUser = (props) => {
  const {
    literals,
    literalsAccount,
    user,
    tableRefreshFlag,
    onClose,
  } = props;

  const [loading, setLoading] = useState(false);
  const [editError, setEditError] = useState(false);
  const [data, setData] = useState({
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    role: user.role,
    lang: user.lang,
    extra: JSON.stringify(user.extra || []),
  });

  const optionsRole = [
    { id: 'user', name: literalsAccount.roles.user },
    { id: 'mentor', name: literalsAccount.roles.mentor },
    { id: 'admin', name: literalsAccount.roles.admin },
  ];
  const optionsLang = [
    { id: ES_LANGUAGE, name: literalsAccount.languages.es },
    { id: EN_LANGUAGE, name: literalsAccount.languages.en },
  ];

  const handleOnChange = (property, value) => {
    setData({ ...data, [property]: value });
  };

  const submitUser = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const userData = { ...data, extra: stripTags(data.extra) };
      await editUser(user.id, userData);
      onClose();
      tableRefreshFlag.increase();
    } catch (error) {
      setLoading(false);
      setEditError(formatErrors(error));
    }
    return true;
  };

  return (
    <Popup title={literals.createUser} onClose={() => onClose(false)} size='large'>
      <form onSubmit={submitUser}>
        <div className='row'>
          <div className='col-md-5'>
            <InputText
              name='firstname'
              preText={literalsAccount.name}
              onChange={v => handleOnChange('firstname', v)}
              value={data.firstname}
              errors={editError?.firstname}
            />
          </div>
          <div className='col-md-7'>
            <InputText
              name='lastname'
              preText={literalsAccount.lastname}
              onChange={v => handleOnChange('lastname', v)}
              value={data.lastname}
              errors={editError?.lastname}
            />
          </div>
          <div className='col-6'>
            <InputEmail
              name='email'
              preText={literalsAccount.email}
              onChange={v => handleOnChange('email', v)}
              value={data.email}
              errors={editError?.email}
              isDisabled
            />
          </div>
          <div className='col-3'>
            <InputSelect
              name='role'
              preText={literalsAccount.role}
              options={optionsRole}
              onChange={v => handleOnChange('role', v)}
              value={data.role}
              errors={editError?.role}
            />
          </div>
          <div className='col-3'>
            <InputSelect
              name='lang'
              preText={literalsAccount.language}
              options={optionsLang}
              onChange={v => handleOnChange('lang', v)}
              value={data.lang}
              errors={editError?.lang}
            />
          </div>
          <div className='col-12'>
            <InputRichText
              name='extra'
              preText={literals.extra}
              value={data.extra}
              onChange={v => handleOnChange('extra', v)}
            />
          </div>
        </div>
        <div className='buttons'>
          <Button
            type='submit'
            icon={CloudUpload}
            text={literalsAccount.submit}
            loading={loading}
            disabled={Object.keys(data).some(key => !data[key])}
          />
        </div>
      </form>

    </Popup>
  );
};

FormEditUser.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsAccount: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  tableRefreshFlag: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FormEditUser;
