import session from 'modules/session';
import i18n from 'components/LanguageSelector/modules';
import login from 'routes/Login/modules';
import logout from 'routes/Logout/modules';
import signup from 'routes/Signup/modules';
import companies from 'routes/PreHome/modules';
import company from 'routes/Home/modules';
import mentoring from 'routes/Mentoring/modules';
import editInfo from 'routes/EditInfo/modules';
import forms from 'components/InternalForm/modules';
import performance from 'routes/Performance/modules';
import captable from 'routes/Captable/modules';
import notifications from './conf02';
import global from '../global';

export default {
  session,
  i18n,
  login,
  logout,
  signup,
  global,
  notifications,
  companies,
  company,
  mentoring,
  forms,
  performance,
  editInfo,
  captable,
};
