import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Button from 'components/Buttons/Button';
import InputRichText from 'components/Inputs/inputRichText';

const MentoringFormReport = (props) => {
  const {
    literals,
    report,
    onClose,
    onSubmit,
  } = props;

  const [text, setText] = useState(report?.text);
  const [loading, setLoading] = useState(false);

  const submit = (event) => {
    event.preventDefault();
    setLoading(true);
    onSubmit(report.id, { text });
  };

  return (
    <Popup
      title={report.id ? literals.reportForm.popupTitleEdit : literals.reportForm.popupTitle}
      onClose={onClose}
      size='large'
    >
      <form onSubmit={submit}>
        <InputRichText name='text' minHeight='50vh' preText={literals.reportForm.text} onChange={v => setText(v)} value={text} maxLength={null} />
        <div className='buttons'>
          <Button type='submit' text={literals.reportForm.send} loading={loading} />
        </div>
      </form>
    </Popup>
  );
};

MentoringFormReport.propTypes = {
  literals: PropTypes.object.isRequired,
  report: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default MentoringFormReport;
