import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import useDialog from 'components/Dialog/components/useDialog';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import { ROUTE_STARTUP_PATH } from 'routes';
import { useCounter } from 'utils/customHooks';
import { PlusCircle } from 'lucide-react';
import { formatDate, startupUserPermissionLevel } from 'utils/functions';
import { TOOL_REPORTING } from 'constants/tools';
import IOFileLoading from 'components/IOFileLoading/components';
import DisabledTool from 'components/DisabledTool';
import { deleteReport, downloadReport, getReports } from '../modules/actions';
import ReportingRow from './ReportingRow';

export const getReportFilename = (report, _formatDate) => {
  let filename = '';
  if (report) {
    filename = report.subject.length > 25 ? `${report.subject.substring(0, 25)}` : report.subject;
    filename = filename.replace(/[^a-zA-Z1-9]/g, '-');
    filename = filename.replace(/-*$/g, '');
    filename = `${_formatDate(report.createdAt, { format: 'Y-m-d' })}. ${filename}.pdf`;
  }
  return filename;
};

const Reporting = (props) => {
  const {
    literals,
    literalsCommon,
    match,
    startup,
  } = props;

  const tableRefreshFlag = useCounter(0);
  const { dialog } = useDialog();
  const [downloading, setDownloading] = useState(false);
  const permissionLevel = startupUserPermissionLevel(startup, TOOL_REPORTING);

  const fetchReports = async (page, size, search, filters) => {
    return getReports(match.params.id, {
      page, size, search, filters,
    });
  };

  const handleDeleteReport = async (id) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literalsCommon.confirmDelete,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteReport(match.params.id, id);
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const handleDownloadReport = async (report) => {
    const filename = getReportFilename(report, formatDate);
    setDownloading(filename);
    await downloadReport(match.params.id, report.id, filename);
    setDownloading(false);
  };

  if (permissionLevel === false) {
    return <DisabledTool />;
  }

  return (
    <Panel>
      <PanelHeader title={literals.title} />
      <PanelBody>
        <Table
          mode='components'
          component={ReportingRow}
          componentProps={{
            match,
            literals,
            onDelete: handleDeleteReport,
            onDownload: handleDownloadReport,
          }}
          pageSize={10}
          fetch={fetchReports}
          forceFetch={tableRefreshFlag.value}
          actions={[
            {
              type: 'Link',
              icon: PlusCircle,
              text: literals.newReport,
              to: ROUTE_STARTUP_PATH.setReportingTemplates(match.params.id),
            },
          ]}
        />

        { downloading && <IOFileLoading mode='download' file={{ name: downloading }} /> }
      </PanelBody>
    </Panel>
  );
};

Reporting.propTypes = {
  match: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
};

export default Reporting;
