import ReportsTemplatesInstance from 'modules/reportsTemplates/repository';

export function getReportTemplates(page, size, search, filters, sort) {
  return ReportsTemplatesInstance.getReportTemplates({
    page, size, search, filters, sort,
  });
}

export function createReportTemplate(data) {
  return ReportsTemplatesInstance.postReportTemplate(data);
}

export function getReportTemplate(id) {
  return ReportsTemplatesInstance.getReportTemplate(id);
}

export function editReportTemplate(id, data) {
  return ReportsTemplatesInstance.putReportTemplate(id, data);
}

export function deleteReportTemplate(id) {
  return ReportsTemplatesInstance.deleteReportTemplate(id);
}
