import { RETURN_TYPES } from 'constants/apiConf';
import { preparePath } from 'utils/preparePath';
import ApiFetch from 'utils/apiFetch';

const AccountsInstance = ApiFetch();

export const URLS = {
  SESSION: 'accounts/session',
  NOTIFICATIONS: 'accounts/notifications',
  DELETE_NOTIFICATION: 'accounts/notifications/:id',
  PUSH_TOKEN: 'accounts/:id/pushTokens/:token',
  EDIT_PASSWORD: 'accounts/:id/password',
  EDIT_PROFILE: 'accounts/:id',
  LOGIN: 'accounts/signin',
  RECOVERY_PASSWORD: 'accounts/recoveryPassword',
  SAVE_PASSWORD: 'accounts/savePassword',
  SUPPORT: 'accounts/support',
  SIGNUP: 'accounts/signup',
};

async function getSession() {
  return AccountsInstance.get(URLS.SESSION);
}

async function deleteNotification(id) {
  const path = preparePath(URLS.DELETE_NOTIFICATION, { id });
  return AccountsInstance.delete(path);
}

async function deletePushToken(id, token) {
  const path = preparePath(URLS.PUSH_TOKEN, { id, token });
  return AccountsInstance.delete(path);
}

async function deleteSession(params = {}) {
  const path = preparePath(URLS.SESSION, {}, params);
  return AccountsInstance.delete(path);
}

async function editPassword(id, data) {
  const path = preparePath(URLS.EDIT_PASSWORD, { id });
  return AccountsInstance.put(path, data);
}

async function editProfile(id, data) {
  const path = preparePath(URLS.EDIT_PROFILE, { id });
  return AccountsInstance.put(path, data);
}

async function getNotifications(params = {}) {
  const path = preparePath(URLS.NOTIFICATIONS, {}, params);
  return AccountsInstance.get(path);
}

async function loginUser(data) {
  return AccountsInstance.post(URLS.LOGIN, data, { return: RETURN_TYPES.RESPONSE });
}

async function postPushToken(id, token) {
  const path = preparePath(URLS.PUSH_TOKEN, { id, token });
  return AccountsInstance.post(path);
}

async function readNotification(id) {
  const path = preparePath(URLS.DELETE_NOTIFICATION, { id });
  return AccountsInstance.put(path);
}

async function recoveryPassword(data) {
  return AccountsInstance.post(URLS.RECOVERY_PASSWORD, data);
}

async function savePasswordUser(data) {
  return AccountsInstance.post(URLS.SAVE_PASSWORD, data);
}

async function sendSupportMessage(data) {
  return AccountsInstance.post(URLS.SUPPORT, data);
}

async function signupUser(data) {
  return AccountsInstance.post(URLS.SIGNUP, data);
}

async function unreadNotification(id) {
  const path = preparePath(URLS.DELETE_NOTIFICATION, { id });
  return AccountsInstance.put(path);
}

export default {
  getSession,
  deleteNotification,
  deletePushToken,
  deleteSession,
  editPassword,
  editProfile,
  getNotifications,
  loginUser,
  postPushToken,
  readNotification,
  recoveryPassword,
  savePasswordUser,
  sendSupportMessage,
  signupUser,
  unreadNotification,
};
