import React, { cloneElement, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useDialog from 'components/Dialog/components/useDialog';
import IOFileLoading from 'components/IOFileLoading/components';

const ButtonUploadFile = ({
  literals,
  children,
  className = '',
  onSubmit,
  _public = false,
  setAttachedFiles = () => {},
  setLoading = () => {},
}) => {
  const { dialog } = useDialog();
  const refInputFile = useRef(null);
  const [uploading, setUploading] = useState(false);

  const submitDocument = async (e) => {
    const target = e.target.files[0];
    setUploading(target);
    setLoading(true);
    const file = await onSubmit(target, _public);
    if (file.error) {
      await dialog({
        type: 'error',
        text: literals.uploadError,
      });
    } else {
      setAttachedFiles(prevAttached => [...prevAttached, file]);
    }
    setUploading(false);
    setLoading(false);
  };

  return (
    <div className={className}>
      {cloneElement(children, { onClick: () => refInputFile.current.click() })}
      <input
        ref={refInputFile}
        type='file'
        name='file'
        onChange={submitDocument}
        style={{
          visibility: 'hidden',
          position: 'absolute',
          top: 0,
          width: '1px',
          height: '1px',
        }}
      />
      {
        uploading && <IOFileLoading file={uploading} />
      }
    </div>
  );
};

ButtonUploadFile.propTypes = {
  literals: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  _public: PropTypes.bool,
  setAttachedFiles: PropTypes.func,
  setLoading: PropTypes.func,
};

export default ButtonUploadFile;
