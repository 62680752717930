/* eslint-disable no-underscore-dangle */
export const fetchLocal = (data, otherData, page, size, search, filters, sort) => {
  const normalizeText = (str) => {
    return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '');
  };

  const getValue = (item, valuePath) => {
    if (valuePath.length === 1) {
      return item[valuePath[0]];
    }
    const [path, ...others] = valuePath;
    return item[path] ? getValue(item[path], others) : null;
  };

  const auxData = { ...data };

  if (search) {
    if (typeof otherData.search === 'object') {
      const valuePath = [];
      otherData.search.forEach(value => valuePath.push(value.split('.')));
      auxData.items = auxData.items.map(item => ({ ...item, _search: valuePath.map(value => getValue(item, value)) }));
      auxData.items = auxData.items.filter(item => normalizeText(item._search.join('')).includes(normalizeText(search)));
    } else {
      const valuePath = otherData.search.split('.');
      auxData.items = auxData.items.map(item => ({ ...item, _search: getValue(item, valuePath) }));
      auxData.items = auxData.items.filter(item => item._search.toLowerCase().includes(search.toLowerCase()));
    }
  }

  if (filters) {
    Object.keys(filters).forEach((path) => {
      const valuePath = path.split('.');
      auxData.items = auxData.items.filter(item => filters[path].includes(getValue(item, valuePath)));
    });
  }

  if (sort && (sort.charAt(0) === '+' || sort.charAt(0) === '-')) {
    const valuePath = sort.slice(1).split('.');
    auxData.items = auxData.items.map(item => ({ ...item, _sort: getValue(item, valuePath) }));
    auxData.items.sort((a, b) => (a._sort > b._sort ? 1 : -1));
    if (sort[0] === '-') {
      auxData.items.reverse();
    }
  }

  auxData.total = auxData.items.length;
  if (size) {
    const startIndex = page * size;
    const endIndex = startIndex + size;
    auxData.items = auxData.items.slice(startIndex, endIndex);
  }
  return auxData;
};
