import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Loading from 'components/Loading';
import Table from 'components/Table';
import { useParams, useSearchParams } from 'react-router-dom';
import { formatNumber } from 'utils/functions';
import { fetchCaptableShares } from '../modules/actions';

const PopupOwnersShares = (props) => {
  const {
    literals,
    literalsCommon,
    date,
    onClose,
  } = props;

  const [shares, setShares] = useState(false);
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const params = useParams();

  useEffect(() => {
    (async () => {
      const data = await fetchCaptableShares(params.id, date, {
        page: 0,
        size: 0,
        captable: captableId,
      });
      data.items.sort((a, b) => a.numeration.from - b.numeration.from);
      setShares(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      field: 'numeration',
      grow: 1,
      label: literals.numeration,
      preRender: v => `${formatNumber(v.from, 0)} - ${formatNumber(v.to, 0)}`,
    },
    {
      field: 'shareholder',
      grow: 3,
      label: literals.shareholder,
      preRender: v => v.name,
    },
    {
      field: 'shareClass',
      grow: 3,
      label: literals.shareClass,
      preRender: v => v.name,
    },
    {
      field: 'date',
      width: 125,
      label: literalsCommon.date,
    },
  ];

  return (
    <Popup title={literals.sharesNumeration} onClose={onClose} size='large'>
      {
        shares ? (
          <Table
            searcher={false}
            columns={columns}
            fetch={() => ({ total: shares.items.length, items: shares.items })}
            pageSize={shares.items.length}
          />
        ) : <Loading hide={false} mode='panel' />
      }
    </Popup>
  );
};

PopupOwnersShares.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupOwnersShares;
