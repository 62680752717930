export const STARTUP_RELATION_STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};

export const STARTUP_ACCESS_STATUS = {
  USER_ALREADY_EXIST: 'user_already_exist',
  ACCESS_GRANTED: 'access_granted',
  ACCESS_EXTERNAL_GRANTED: 'access_external_granted',
  EXIST_OWNER: 'exist_owner',
  ACCESS_REQUEST_PENDING: 'access_request_pending',
  ACCESS_REQUEST_REJECTED: 'access_request_rejected',
  REQUIRE_VALIDATION: 'require_validation',
};
