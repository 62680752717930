/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import ShareholdersSelect from 'components/ShareholdersSelect';
import CurrenciesSelect from 'components/CurrencySelect';
import { ROLE_INVESTOR } from 'constants/roles';
import { ROUTE_POSITION_PATH } from 'routes';
import { Award, Clipboard, User, LineChart } from 'lucide-react';
import RenderRow from './RenderRow';

const NavPosition = (props) => {
  const { literals, toggleMenu, user, route } = props;

  const navRows = [
    {
      path: ROUTE_POSITION_PATH.PORTFOLIO,
      to: ROUTE_POSITION_PATH.PORTFOLIO,
      name: literals.portfolio,
      icon: Clipboard,
    },
    {
      path: ROUTE_POSITION_PATH.ANALYSIS,
      to: ROUTE_POSITION_PATH.ANALYSIS,
      name: literals.analysis,
      icon: LineChart,
    },
    {
      path: ROUTE_POSITION_PATH.OPPORTUNITIES,
      to: ROUTE_POSITION_PATH.OPPORTUNITIES,
      name: literals.opportunities,
      icon: Award,
    },
    {
      canView: user.role.includes(ROLE_INVESTOR),
      path: ROUTE_POSITION_PATH.EDIT_INVESTOR,
      to: ROUTE_POSITION_PATH.EDIT_INVESTOR,
      name: literals.profile,
      icon: User,
    },
  ];

  return (
    <>
      <div className='investments-select'>
        <ShareholdersSelect />
        <CurrenciesSelect mode='left-menu' />
      </div>
      { navRows.map(row => (
        <RenderRow key={row.name} toggleMenu={toggleMenu} route={route} {...row} />
      ))}
    </>
  );
};

NavPosition.propTypes = {
  literals: PropTypes.object.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

export default NavPosition;
