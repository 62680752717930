import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const KpisInstance = ApiFetch();

export const URLS = {
  CREATE_KPI: 'kpis',
  GET_KPIS: 'kpis',
  GET_KPI: 'kpis/:id',
  EDIT_KPI: 'kpis/:id',
  DELETE_KPI: 'kpis/:id',
  GET_KPI_RELATIONS: 'kpis/:kpi/:scopeType/:scopeOwner/relations',
  CREATE_KPI_RELATION: 'kpis/:kpi/:scopeType/:scopeOwner/relations',
  EDIT_KPI_RELATION: 'kpis/:kpi/:scopeType/:scopeOwner/relations/:relation',
  DELETE_KPI_RELATION: 'kpis/:kpi/:scopeType/:scopeOwner/relations/:relation',
  GET_KPI_RELATION_VALUES: 'kpis/:kpi/:scopeType/:scopeOwner/relations/:relation/values',
  GET_KPI_RELATION_DATE_VALUE: 'kpis/:kpi/:scopeType/:scopeOwner/relations/:relation/values/:date',
  CREATE_KPI_RELATION_DATE_VALUE: 'kpis/:kpi/:scopeType/:scopeOwner/relations/:relation/values/:date',
  DELETE_KPI_RELATION_DATE_VALUE: 'kpis/:kpi/:scopeType/:scopeOwner/relations/:relation/values/:date',
  BOOKMARK_KPI_RELATION: 'kpis/:kpi/:scopeType/:scopeOwner/relations/:relation/bookmark',
  UNBOOKMARK_KPI_RELATION: 'kpis/:kpi/:scopeType/:scopeOwner/relations/:relation/bookmark',
  GET_KPIS_RELATIONS: 'kpis/:scopeType/:scopeOwner/relations',
  CREATE_KPIS_RELATIONS: 'kpis/:scopeType/:scopeOwner/relations',
  ORDER_KPI_RELATION: 'kpis/:scopeType/:scopeOwner/relations/order',
  CREATE_KPIS_VALUES: 'kpis/:scopeType/:scopeOwner/values',
  GET_KPIS_VALUES: 'kpis/:scopeType/:scopeOwner/values',
  UPLOAD_KPIS_EXCEL: 'kpis/:scopeType/:scopeOwner/excel',
  DOWNLOAD_KPIS_EXCEL: 'kpis/:scopeType/:scopeOwner/excel',
  CREATE_KPIS_MULTIPLE_VALUES: 'kpis/:scopeType/:scopeOwner/excel/values',
  GET_KPIS_SUMMARY_TABLE: 'kpis/:scopeType/:scopeOwner/summary',
  CREATE_KPIS_TEMPLATE: 'kpis/templates',
  GET_KPIS_TEMPLATE: 'kpis/templates/:id',
  EDIT_KPIS_TEMPLATE: 'kpis/templates/:id',
  DELETE_KPIS_TEMPLATE: 'kpis/templates/:id',
  GET_KPIS_TEMPLATES: 'kpis/templates',
};

async function createKpi(data) {
  return KpisInstance.post(URLS.CREATE_KPI, data);
}

async function getKpis(params = {}) {
  const path = preparePath(URLS.GET_KPIS, {}, params);
  return KpisInstance.get(path);
}

async function getKpi(id) {
  const path = preparePath(URLS.GET_KPI, { id });
  return KpisInstance.get(path);
}

async function editKpi(id, data) {
  const path = preparePath(URLS.EDIT_KPI, { id });
  return KpisInstance.put(path, data);
}

async function deleteKpi(id) {
  const path = preparePath(URLS.DELETE_KPI, { id });
  return KpisInstance.delete(path);
}

async function getKpiRelations(kpi, scopeType, scopeOwner) {
  const path = preparePath(URLS.GET_KPI_RELATIONS, { kpi, scopeType, scopeOwner });
  return KpisInstance.get(path);
}

async function createKpiRelation(kpi, scopeType, scopeOwner, data) {
  const path = preparePath(URLS.CREATE_KPI_RELATION, { kpi, scopeType, scopeOwner });
  return KpisInstance.post(path, data);
}

async function editKpiRelation(kpi, scopeType, scopeOwner, relation, data) {
  const path = preparePath(URLS.EDIT_KPI_RELATION, {
    kpi, scopeType, scopeOwner, relation,
  });
  return KpisInstance.put(path, data);
}

async function deleteKpiRelation(kpi, scopeType, scopeOwner, relation) {
  const path = preparePath(URLS.DELETE_KPI_RELATION, {
    kpi, scopeType, scopeOwner, relation,
  });
  return KpisInstance.delete(path);
}

async function getKpiRelationValues(kpi, scopeType, scopeOwner, relation, params = {}) {
  const path = preparePath(URLS.GET_KPI_RELATION_VALUES, {
    kpi, scopeType, scopeOwner, relation,
  }, params);
  return KpisInstance.get(path);
}

async function getKpiRelationDateValue(kpi, scopeType, scopeOwner, relation, date) {
  const path = preparePath(URLS.GET_KPI_RELATION_DATE_VALUE, {
    kpi, scopeType, scopeOwner, relation, date,
  });
  return KpisInstance.get(path);
}

async function createKpiRelationDateValue(kpi, scopeType, scopeOwner, relation, date, data) {
  const path = preparePath(URLS.CREATE_KPI_RELATION_DATE_VALUE, {
    kpi, scopeType, scopeOwner, relation, date,
  });
  return KpisInstance.post(path, data);
}

async function deleteKpiRelationDateValue(kpi, scopeType, scopeOwner, relation, date) {
  const path = preparePath(URLS.DELETE_KPI_RELATION_DATE_VALUE, {
    kpi, scopeType, scopeOwner, relation, date,
  });
  return KpisInstance.delete(path);
}

async function bookmarkKpiRelation(kpi, scopeType, scopeOwner, relation) {
  const path = preparePath(URLS.BOOKMARK_KPI_RELATION, {
    kpi, scopeType, scopeOwner, relation,
  });
  return KpisInstance.post(path);
}

async function unbookmarkKpiRelation(kpi, scopeType, scopeOwner, relation) {
  const path = preparePath(URLS.UNBOOKMARK_KPI_RELATION, {
    kpi, scopeType, scopeOwner, relation,
  });
  return KpisInstance.delete(path);
}

async function getKpisRelations(scopeType, scopeOwner, params = {}) {
  const path = preparePath(URLS.GET_KPIS_RELATIONS, { scopeType, scopeOwner }, params);
  return KpisInstance.get(path);
}

async function createKpisRelations(scopeType, scopeOwner, data) {
  const path = preparePath(URLS.CREATE_KPIS_RELATIONS, { scopeType, scopeOwner });
  return KpisInstance.post(path, data);
}

async function orderKpiRelation(scopeType, scopeOwner, data) {
  const path = preparePath(URLS.ORDER_KPI_RELATION, { scopeType, scopeOwner });
  return KpisInstance.post(path, data);
}

async function createKpisValues(scopeType, scopeOwner, data) {
  const path = preparePath(URLS.CREATE_KPIS_VALUES, { scopeType, scopeOwner });
  return KpisInstance.post(path, data);
}

async function getKpisValues(scopeType, scopeOwner, params = {}) {
  const path = preparePath(URLS.GET_KPIS_VALUES, { scopeType, scopeOwner }, params);
  return KpisInstance.get(path);
}

async function uploadKpisExcel(scopeType, scopeOwner, file) {
  const path = preparePath(URLS.UPLOAD_KPIS_EXCEL, { scopeType, scopeOwner });
  return KpisInstance.postForm(path, { file });
}

async function downloadKpisExcel(scopeType, scopeOwner) {
  const path = preparePath(URLS.DOWNLOAD_KPIS_EXCEL, { scopeType, scopeOwner });
  return KpisInstance.get(path);
}

async function createKpisMultipleValues(scopeType, scopeOwner, data) {
  const path = preparePath(URLS.CREATE_KPIS_MULTIPLE_VALUES, { scopeType, scopeOwner });
  return KpisInstance.post(path, data);
}

async function getKpisSummaryTable(scopeType, scopeOwner, params = {}) {
  const path = preparePath(URLS.GET_KPIS_SUMMARY_TABLE, { scopeType, scopeOwner }, params);
  return KpisInstance.get(path);
}

async function createKpisTemplate(data) {
  return KpisInstance.post(URLS.CREATE_KPIS_TEMPLATE, data);
}

async function getKpisTemplate(id) {
  const path = preparePath(URLS.GET_KPIS_TEMPLATE, { id });
  return KpisInstance.get(path);
}

async function editKpisTemplate(id, data) {
  const path = preparePath(URLS.EDIT_KPIS_TEMPLATE, { id });
  return KpisInstance.put(path, data);
}

async function deleteKpiTemplate(id) {
  const path = preparePath(URLS.DELETE_KPIS_TEMPLATE, { id });
  return KpisInstance.delete(path);
}

async function getKpisTemplates(params = {}) {
  const path = preparePath(URLS.GET_KPIS_TEMPLATES, {}, params);
  return KpisInstance.get(path);
}

export default {
  bookmarkKpiRelation,
  createKpi,
  createKpiRelation,
  createKpiRelationDateValue,
  createKpisMultipleValues,
  createKpisRelations,
  createKpisTemplate,
  createKpisValues,
  deleteKpi,
  deleteKpiRelation,
  deleteKpiRelationDateValue,
  deleteKpiTemplate,
  downloadKpisExcel,
  editKpi,
  editKpiRelation,
  editKpisTemplate,
  getKpi,
  getKpiRelationDateValue,
  getKpiRelationValues,
  getKpiRelations,
  getKpis,
  getKpisRelations,
  getKpisSummaryTable,
  getKpisTemplate,
  getKpisTemplates,
  getKpisValues,
  orderKpiRelation,
  unbookmarkKpiRelation,
  uploadKpisExcel,
};
