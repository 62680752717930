import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputText from 'components/Inputs/inputText';
import InputDate from 'components/Inputs/inputDate';
import Separator from 'components/Separator';
import InputRichText from 'components/Inputs/inputRichText';
import InputFile from 'components/Inputs/inputFile';
import Button from 'components/Buttons/Button';
import { formatDate } from 'utils/functions';
import './styles.scss';

const FormOperation = ({
  children,
  literals,
  literalsCommon,
  buttons,
  standalone,
  minDate,
  onConfirm,
}) => {
  const [name, setName] = useState('');
  const [inputDate, setInputDate] = useState('');
  const [notes, setNotes] = useState('');
  const [documents, setDocuments] = useState([]);

  const handleConfirm = (e) => {
    e.preventDefault();
    onConfirm({
      name, notes, documents, date: inputDate,
    });
  };

  return (
    <form onSubmit={handleConfirm}>
      {standalone && (
        <>
          <div className='row'>
            <div className='col-12 col-md-8 mb-4 mb-md-0'>
              <InputText
                className='m-0'
                preText={literals.operationName}
                placeholder={literals.operationName}
                value={name}
                onChange={v => setName(v)}
              />
            </div>
            <div className='col-12 col-md-4'>
              <InputDate
                className='m-0'
                preText={(
                  <div>
                    {literalsCommon.date}
                    { minDate && (
                      <span className='fs-sm'>
                        {` (${literals.lastOperation}: ${formatDate(minDate)})`}
                      </span>
                    )}
                  </div>
                )}
                value={inputDate}
                onChange={v => setInputDate(v)}
                min={minDate}
              />
            </div>
          </div>
          <Separator />
        </>
      )}
      {children}
      {standalone && (
        <>
          <Separator />
          <div className='row'>
            <div className='col-12 '>
              <InputRichText
                minHeight='120px'
                preText={literals.additionalDocumentation}
                value={notes}
                onChange={v => setNotes(v)}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <InputFile
                preText={literalsCommon.documents}
                value={documents}
                isPublic={false}
                onChange={v => setDocuments(v)}
                multiple
              />
            </div>
          </div>
        </>
      )}
      <div className='buttons'>
        { buttons.map(button => (
          <Button {...button} key={button.key} />
        ))}
      </div>
    </form>
  );
};

FormOperation.propTypes = {
  children: PropTypes.node.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  standalone: PropTypes.bool.isRequired,
  buttons: PropTypes.array.isRequired,
  minDate: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default FormOperation;
