import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import {
  Info,
  AlertOctagon,
  AlertTriangle,
  CheckCircle,
} from 'lucide-react';
import './styles.scss';

const Alert = (props) => {
  const {
    type,
    text,
    className = '',
    buttons = null,
    onClick = null,
  } = props;

  const renderIcon = () => {
    switch (type) {
      case 'success':
        return <CheckCircle />;
      case 'warning':
        return <AlertTriangle />;
      case 'danger':
        return <AlertOctagon />;
      case 'info':
        return <Info />;
      default: return null;
    }
  };

  return (
    <div
      className={`alert-component alert-${type} ${onClick ? 'pointer' : ''} ${className}`}
      onClick={onClick}
    >
      <h5 className='fw-b mb-0'>
        {renderIcon()}
        {text}
      </h5>
      {buttons?.length ? (
        <div className='buttons mt-3'>
          {buttons.map(button => <Button key={button.text} className='btn-sm' {...button} />)}
        </div>
      ) : null}
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  buttons: PropTypes.array,
  onClick: PropTypes.func,
};

export default Alert;
