export const preparePath = (path, pathParams = {}, queryParams = {}) => {
  let newPath = path;

  Object.keys(pathParams).forEach((key) => {
    newPath = newPath.replace(`:${key}`, pathParams[key]);
  });

  const filteredQueryParams = Object.fromEntries(
    Object.entries(queryParams).filter(([, value]) => value !== null && value !== undefined),
  );

  const queryString = Object.keys(filteredQueryParams).map((key) => {
    const value = typeof filteredQueryParams[key] === 'object'
      ? encodeURIComponent(JSON.stringify(filteredQueryParams[key]))
      : encodeURIComponent(filteredQueryParams[key]);
    return `${key}=${value}`;
  }).join('&');

  if (queryString.length > 0) {
    newPath += `?${queryString}`;
  }

  return newPath;
};
