import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputText from 'components/Inputs/inputText';
import InputNumber from 'components/Inputs/inputNumber';
import InputSelect from 'components/Inputs/inputSelect';
import InputDate from 'components/Inputs/inputDate';
import OutputValue from 'components/Inputs/outputValue';
import FormOperation from 'components/FormOperation';
import { PlusCircle } from 'lucide-react';
import { formatNumber } from 'utils/functions';
import ShareholdersAssignments from './ShareholdersAssignments';

const FormOptions = (props) => {
  const {
    editable,
    data,
    captable,
    literals,
    literalsCommon,
    currency,
    unassigned,
    index,
    standalone,
    onConfirm,
    onDelete,
  } = props;

  const oldOption = data.old ? unassigned.options.filter(op => op.option === data.old)[0] : null;
  const [id] = useState(data.create?.id ?? `new.${new Date().getTime()}`);
  const [pool, setPool] = useState(data.create === null ? oldOption.option : 'new');
  const [type, setType] = useState(data.create?.type || oldOption?.type || 'stockOption');
  const [name, setName] = useState(data.create?.name || oldOption?.name || '');
  const [number, setNumber] = useState(data.create?.number || oldOption?.number || 1);
  const [price, setPrice] = useState(data.create?.price || oldOption?.price || 0);
  const [exercisePrice, setExercisePrice] = useState(data.create?.exercisePrice || oldOption?.exercisePrice || 1);
  const [ratio, setRatio] = useState(data.create?.ratio || oldOption?.ratio || 1);
  const [newShares] = useState(true);
  const [expirationDate, setExpirationDate] = useState(data.create?.expirationDate || oldOption?.expirationDate || null);
  const [assignments, setAssignments] = useState(data?.assign || []);

  const optionsType = [
    { id: 'stockOption', name: literals.stockOption },
    { id: 'phantom', name: literals.phantom },
  ];

  const handleChangePool = (v) => {
    const option = unassigned.options.filter(op => op.option === v)[0];
    setName(option?.name);
    setType(option?.type);
    setNumber(option?.number);
    setPrice(option?.price);
    setExpirationDate(option?.expirationDate);
    setPool(v);

    const newId = v === 'new' ? id : v;
    setAssignments(assignments.map(a => ({ ...a, option: newId, old: v !== 'new' })));
  };

  const handleConfirm = (extra) => {
    const params = {
      create: {
        id,
        type,
        name,
        number: number !== '' ? parseInt(number, 10) : null,
        price: price !== '' ? parseFloat(price) : null,
        exercisePrice: exercisePrice !== '' ? parseFloat(exercisePrice) : null,
        ratio: ratio !== '' ? parseInt(ratio, 10) : null,
        newShares,
        expirationDate,
      },
      assign: assignments,
    };

    if (pool !== 'new') {
      params.create = null;
      params.option = unassigned.options.filter(op => op.option === pool)[0];
      params.assign.forEach((assign, i) => {
        params.assign[i].option = pool;
        params.assign[i].old = true;
      });
    } else {
      params.assign.forEach((assign, i) => {
        params.assign[i].option = params.create.id;
        params.assign[i].old = false;
      });
    }
    onConfirm(standalone ? {
      ...extra, options: { ...params, create: [params.create] },
    } : params);
  };

  const cols = type === 'phantom' ? 3 : 4;

  const buttons = editable ? [{
    text: literalsCommon.confirm,
    type: 'submit',
  }] : [];

  if (editable && index !== false) {
    buttons.push({
      color: 'danger',
      text: literalsCommon.delete,
      onClick: onDelete,
    });
  }

  return (
    <FormOperation
      literals={literals}
      literalsCommon={literalsCommon}
      standalone={standalone}
      minDate={captable?.operations?.length ? captable?.operations[0].date : null}
      buttons={buttons}
      onConfirm={handleConfirm}
    >
      <div className='operation-form-shares'>
        <div className='mb-lb'>
          <div className='row'>
            <div className='col-xs-12 col-md-3'>
              <InputSelect
                options={[
                  { id: 'new', name: 'new', icon: <PlusCircle /> },
                  ...(unassigned.options.map(op => ({ id: op.option, name: `${op.name} (${formatNumber(op.number)})` }))),
                ]}
                preText={literals.pool}
                value={pool}
                onChange={v => handleChangePool(v)}
                isDisabled={!editable}
              />
            </div>
            <div className='col-xs-12 col-md-3'>
              <InputSelect options={optionsType} preText={literalsCommon.type} value={type} onChange={v => setType(v)} isDisabled={!editable || pool !== 'new'} />
            </div>
            <div className='col-xs-12 col-md-6'>
              <InputText preText={literalsCommon.name} value={name} onChange={v => setName(v)} isDisabled={!editable || pool !== 'new'} />
            </div>
            <div className={`col-xs-12 col-md-${cols}`}>
              <InputNumber preText={literals.number} value={number} onChange={v => setNumber(v ? parseInt(v, 10) : 0)} isDisabled={!editable || pool !== 'new'} decimals={0} />
            </div>
            <div className={`col-xs-12 col-md-${cols}`}>
              <InputNumber preText={literals.price} value={price} onChange={v => setPrice(v)} isDisabled={!editable || pool !== 'new'} symbol={currency.symbol} step='any' decimals={10} />
            </div>
            <div className={`col-xs-12 col-md-${cols}`}>
              <OutputValue
                preText={literalsCommon.total}
                value={price && number ? formatNumber(parseFloat(price) * parseFloat(number), 0, { dec: 0 }) : 0}
                isDisabled={!editable}
                symbol={currency.symbol}
              />
            </div>
            {
              type === 'stockOption' && (
                <>
                  <div className='col-xs-12 col-md-4'>
                    <InputNumber preText={literals.exercisePrice} value={exercisePrice} onChange={v => setExercisePrice(v)} isDisabled={!editable || pool !== 'new'} symbol={currency.symbol} step='any' decimals={2} />
                  </div>
                  <div className='col-xs-12 col-md-4'>
                    <InputNumber preText={literals.ratio} value={ratio} onChange={v => setRatio(parseInt(v, 10))} isDisabled={!editable || pool !== 'new'} symbol='X' step={1} decimals={0} />
                  </div>
                </>
              )
            }
            <div className={`col-xs-12 col-md-${cols}`}>
              <InputDate preText={literals.expirationDate} value={expirationDate} onChange={v => setExpirationDate(v)} isDisabled={!editable || pool !== 'new'} />
            </div>
          </div>
        </div>

        <div className='mb-sp'>
          <ShareholdersAssignments
            mode={{ type: 'options', subtype: 'assign' }}
            id={id}
            assignments={assignments}
            number={number}
            pps={price}
            captable={captable}
            currency={currency}
            literals={literals}
            literalsCommon={literalsCommon}
            onChange={v => setAssignments(v)}
            editable={editable}
          />
        </div>
      </div>
    </FormOperation>
  );
};

FormOptions.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  data: PropTypes.object,
  currency: PropTypes.object.isRequired,
  unassigned: PropTypes.object,
  index: PropTypes.any,
  standalone: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

FormOptions.defaultProps = {
  editable: true,
  index: false,
  standalone: false,
  data: {},
  unassigned: { shares: [], debts: [], options: [] },
};

export default FormOptions;
