import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const TablesInstance = ApiFetch();

export const URLS = {
  DELETE_TABLE: 'tables/:id/personalization',
  GET_TABLE: 'tables/:id/personalization',
  POST_TABLE: 'tables/:id/personalization',
};

async function deleteTable(id) {
  const path = preparePath(URLS.DELETE_TABLE, { id });
  return TablesInstance.delete(path);
}

async function getTable(id) {
  const path = preparePath(URLS.GET_TABLE, { id });
  return TablesInstance.get(path);
}

async function postTable(id, data) {
  const path = preparePath(URLS.POST_TABLE, { id });
  return TablesInstance.post(path, data);
}

export default {
  deleteTable,
  getTable,
  postTable,
};
