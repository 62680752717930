import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCaptableAction } from 'routes/Captable/modules/actions';
import CaptableOperationsComparator from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.captable,
  literalsCommon: state.i18n.literals.common,
  user: state.session.user,
  captable: state.captable,
  currency: state.global.currency,
});

const mapDispatchToProps = dispatch => ({
  fetchCaptable: bindActionCreators(fetchCaptableAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaptableOperationsComparator);
