import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import MessagesMenu from 'components/MessagesMenu';
import StartupsSelect from 'components/StartupsSelect';
import ShareholdersSelect from 'components/ShareholdersSelect';
import CurrenciesSelect from 'components/CurrencySelect';
import { FILES_BASE_URL } from 'constants/apiConf';
import {
  MessageCircleMore, Menu, Wallet, X,
} from 'lucide-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ROUTE_PATH, ROUTE_POSITION_PATH } from 'routes';
import { startupCanView } from 'utils/functions';
import { TOOL_MESSAGES } from 'constants/tools';
import { setBrowserPreferences } from 'utils/browser';
import Notifications from './Notifications';
import Profile from './Profile';
import './styles.scss';

function TopBar({
  user,
  startup = null,
  toggleMenu,
  platform,
  literals,
  literalsCommon,
  pathId = null,
  showAside,
  leftmenuDisabled,
  width,
  notifications,
  fetchNotifications,
  addNotification,
  readNotification,
  unreadNotification,
  deleteNotification,
}) {
  useEffect(() => {
    setBrowserPreferences('menuVisible', showAside);
  }, [showAside]);

  const navigate = useNavigate();
  const location = useLocation();
  const [showMessages, setShowMessages] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const canViewMessages = pathId && startupCanView(startup, TOOL_MESSAGES);

  const isPrehome = location.pathname === ROUTE_PATH.PREHOME;

  return (
    <>
      <nav className={`topbar ${user.impersonate ? 'impersonate' : ''}`}>
        <div className='topbar-left-section'>
          {
            showAside && ['xs', 'sm'].includes(width) ? (
              <X className='mobile-button' onClick={toggleMenu} />
            ) : (
              <>
                {!leftmenuDisabled ? <Menu className='mobile-button' onClick={toggleMenu} /> : null}
              </>
            )
          }
          {platform?.images?.logo?.path ? (
            <Link to='/'>
              <img src={`${FILES_BASE_URL}${platform.images.logo.path}`} alt='logo' className='topbar-logo' />
              {' '}
            </Link>
          ) : null}
          <StartupsSelect literals={literals} user={user} startup={startup} platform={platform} pathId={pathId} />
          {location.pathname.startsWith(ROUTE_POSITION_PATH.BASE) && (
            <div className='investments-select'>
              <ShareholdersSelect />
              <CurrenciesSelect />
            </div>
          )}
        </div>
        <div className='topbar-right-section'>
          {
            isPrehome && (user.investor || user.shareholder) && (
              <Button
                className='viewMyPosition-button'
                text={literals.viewMyPosition}
                icon={Wallet}
                onClick={() => navigate(ROUTE_PATH.MY_POSITION)}
              />
            )
          }
          <Notifications
            user={user}
            data={notifications}
            literals={literals}
            literalsCommon={literalsCommon}
            notifications={notifications}
            fetchNotifications={fetchNotifications}
            addNotification={addNotification}
            readNotification={readNotification}
            unreadNotification={unreadNotification}
            deleteNotification={deleteNotification}
          />
          {
            canViewMessages ? (
              <div className='topbar-action'>
                <div className='topbar-icon' onClick={() => setShowMessages(!showMessages)}>
                  <MessageCircleMore size={35} />
                  {
                    unreadMessages > 0
                      && (
                        <span className='topbar-icon-number'>
                          {unreadMessages > 99 ? '+99' : unreadMessages}
                        </span>
                      )
                  }
                </div>
              </div>
            ) : null
          }
          <Profile user={user} literals={literals} />
        </div>
      </nav>
      {
        canViewMessages ? (
          <MessagesMenu show={showMessages} setUnreadMessages={setUnreadMessages} />
        ) : null
      }
    </>
  );
}

TopBar.propTypes = {
  showAside: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  notifications: PropTypes.object.isRequired,
  addNotification: PropTypes.func.isRequired,
  fetchNotifications: PropTypes.func.isRequired,
  readNotification: PropTypes.func.isRequired,
  unreadNotification: PropTypes.func.isRequired,
  deleteNotification: PropTypes.func.isRequired,
  startup: PropTypes.object,
  pathId: PropTypes.string,
  leftmenuDisabled: PropTypes.bool.isRequired,
  width: PropTypes.string.isRequired,
};

export default TopBar;
