import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Button from 'components/Buttons/Button';
import InputSelect from 'components/Inputs/inputSelect';
import InputRichText from 'components/Inputs/inputRichText';
import InputFile from 'components/Inputs/inputFile';
import Avatar from 'components/Avatar';
import Loading from 'components/Loading';
import PopupProfile from 'components/PopupProfile';
import { getTranslation } from 'utils/functions';
import { fetchStartups } from 'routes/Admin/Startups/modules/actions';
import { Plus } from 'lucide-react';
import { createServiceRequests, editServicesRequest, getServices } from '../modules/actions';
import FormServices from './FormServices';
import './styles.scss';

const defaultRequestData = {
  service: '',
  startup: '',
  status: '',
  notes: '',
};

const FormRequest = (props) => {
  const {
    literals,
    literalsCommon,
    lang,
    request,
    refreshTable,
    onDelete,
    onClose,
  } = props;

  const [requestData, setRequestData] = useState(request || defaultRequestData);
  const [servicePopup, setServicePopup] = useState(false);
  const [startupPopup, setStartupPopup] = useState(false);
  const [startups, setStartups] = useState([]);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!request) {
      setLoading('fetching');
      Promise.all([
        fetchStartups(0, 0),
        getServices(0, 0),
      ]).then(([auxStartups, auxServices]) => {
        setStartups(auxStartups.items);
        setServices(auxServices.items);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitForm = async () => {
    setLoading(true);
    try {
      if (request) {
        await editServicesRequest(request.id, requestData);
      } else {
        await createServiceRequests(requestData);
      }
      refreshTable();
      onClose();
    } catch (err) {
      setLoading(false);
    }
  };

  const title = request ? literals.editRequest : literals.createRequest;
  return (
    <Popup title={title} onClose={onClose}>
      { loading === 'fetching'
        ? <Loading mode='panel' hide={false} />
        : (
          <>
            { request && (
              <>
                <div className='request-service'>
                  <div className='company_header'>
                    <div className='d-flex align-items-center'>
                      <Avatar
                        title={request.type === 'external' ? getTranslation(request.service.company, lang) : literals[request.service]}
                        src={request.service?.logo?.path}
                        size={45}
                      />
                      <div className='d-flex flex-column ml-3'>
                        <span className='company_name'>{request.type === 'external' ? getTranslation(request.service.company, lang) : literals[request.service]}</span>
                        <div className='company_type fc-secondary'>{request.type === 'external' ? getTranslation(request.service.type, lang) : literals.internalService}</div>
                      </div>
                    </div>
                    { request.type === 'external' && (
                      <Button
                        text={literals.details}
                        icon={Plus}
                        className='btn-sm'
                        color='secondary'
                        onClick={() => setServicePopup(request.service)}
                        loading={loading}
                      />
                    )}
                  </div>
                  { request.type === 'external' && (
                    <div className='pt-2'>{getTranslation(request.service.shortDescription, lang)}</div>
                  )}
                </div>
                <div className='request-startup my-4'>
                  <div className='company_header'>
                    <div className='d-flex align-items-center'>
                      <Avatar
                        title={request.createdBy.firstname}
                        src={request.createdBy.avatar?.path}
                        size={45}
                      />
                      <div className='fw-b ml-3'>{`${request.createdBy.firstname} ${request.createdBy.lastname || ''}`}</div>
                    </div>
                  </div>
                </div>
                <div className='request-startup my-4'>
                  <div className='company_header'>
                    <div className='d-flex align-items-center'>
                      <Avatar
                        title={request.startup.name}
                        src={request.startup.logo?.path}
                        size={45}
                      />
                      <div className='fw-b ml-3'>{request.startup.name}</div>
                    </div>
                    <Button
                      text={literals.details}
                      icon={Plus}
                      className='btn-sm'
                      color='secondary'
                      onClick={() => setStartupPopup(request.startup.id)}
                      loading={loading}
                    />
                  </div>
                  <div className='company_info'>
                    <span className='company_description'>{request.startup.description}</span>
                  </div>
                </div>
              </>
            )}
            <div className='row'>
              { !request && (
                <>
                  <div className='col-12 col-md-4'>
                    <InputSelect
                      preText={literals.service}
                      onChange={v => setRequestData(prev => ({ ...prev, service: v }))}
                      value={requestData.service}
                      options={services.map(service => ({
                        id: service.id, name: getTranslation(service.company, lang),
                      }))}
                    />
                  </div>
                  <div className='col-12 col-md-4'>
                    <InputSelect
                      preText={literalsCommon.startup}
                      onChange={v => setRequestData(prev => ({ ...prev, startup: v }))}
                      value={requestData.startup}
                      options={startups.map(startup => ({
                        id: startup.id, name: startup.name,
                      }))}
                    />
                  </div>
                </>
              )}
              <div className='col-12 col-md-4'>
                <InputSelect
                  preText={literals.status}
                  onChange={v => setRequestData(prev => ({ ...prev, status: v }))}
                  value={requestData.status}
                  options={[
                    { id: 'lead', name: literals.lead },
                    { id: 'appointment', name: literals.appointment },
                    { id: 'pending', name: literals.pending },
                    { id: 'rejected', name: literals.rejected },
                    { id: 'done', name: literals.done },
                  ]}
                />
              </div>
            </div>
            {
              request?.type === 'internal' && (
                <>
                  <InputFile
                    preText={literalsCommon.documents}
                    value={requestData.documents}
                    isPublic={false}
                    onChange={v => setRequestData(prev => ({ ...prev, documents: v }))}
                    multiple
                  />
                  <InputRichText
                    preText={literals.message}
                    value={requestData.message}
                    onChange={v => setRequestData(prev => ({ ...prev, message: v }))}
                    minHeight='150px'
                  />
                </>
              )
            }
            <InputRichText
              preText={literals.internalNotes}
              onChange={v => setRequestData(prev => ({ ...prev, notes: v }))}
              value={requestData.notes}
              minHeight='150px'
            />
            <div className='buttons'>
              { request && (
                <Button
                  color='danger'
                  text={literalsCommon.delete}
                  onClick={() => { onDelete(request); onClose(); }}
                  loading={loading}
                />
              )}
              <Button
                text={request ? literalsCommon.edit : literalsCommon.create}
                onClick={handleSubmitForm}
                loading={loading}
              />
            </div>
          </>
        )}
      {servicePopup && (
        <FormServices
          literals={literals}
          literalsCommon={literalsCommon}
          service={servicePopup}
          onClose={() => setServicePopup(null)}
          canEdit={false}
        />
      )}
      {startupPopup && (
        <PopupProfile id={startupPopup} type='startup' onClose={() => setStartupPopup(false)} />
      )}

    </Popup>
  );
};

FormRequest.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  request: PropTypes.object.isRequired,
  refreshTable: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FormRequest;
