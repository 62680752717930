import { connect } from 'react-redux';
import AdminReporting from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.reporting,
  literalsCommon: state.i18n.literals.common,
  lang: state.session.user.lang,
//   langs: state.global.platform.langs,
});

export default connect(mapStateToProps)(AdminReporting);
