import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { onClickOut } from 'utils/functions';
import './styles.scss';

const Portal = ({
  type = '',
  styles = {},
  children,
  onClose = null,
}) => {
  const ref = useRef();
  const [zIndex, setZIndex] = useState(8);
  const functionRef = useRef();
  const [randomClass] = useState(`portal-wrapper-${Math.floor(Math.random() * 1000)}`);

  useEffect(() => {
    if (onClose && !styles.visible) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [styles.visible]);

  useEffect(() => {
    window.portal = window.portal || { zIndex: 10, popup: 0 };
    if (type === 'popup') {
      window.portal.popup += 1;
      window.portal.zIndex += 1;
    }
    if (window.portal.popup) {
      setZIndex(window.portal.zIndex);
    }
    if (onClose) {
      const [promise, funct] = onClickOut(randomClass, true);
      functionRef.current = funct;
      promise.then(onClose);
    }
    return () => {
      if (type === 'popup' && window.portal.popup > 0) {
        window.portal.popup -= 1;
      }
      if (functionRef.current) {
        functionRef.current();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return createPortal(
    <div ref={ref} className={`portal-wrapper ${randomClass}`} style={{ zIndex, ...styles }}>
      {children}
    </div>,
    document.getElementById('portal-root'),
  );
};

Portal.propTypes = {
  type: PropTypes.string,
  styles: PropTypes.object,
  onClose: PropTypes.func,
};

export default Portal;
