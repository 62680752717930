import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Tool from 'components/Tool';
import ToolTab from 'components/Tool/components/ToolTab';
import EmptyStreet from 'assets/svg/empty_street.svg';
import { initPermission } from 'utils/functions/initPermissions';
import { PERMISSION_LEVELS, PERMISSION_SCOPES } from 'constants/permissions';
import { TOOL_FUNDRAISE } from 'constants/tools';
import { useSearchParams } from 'react-router-dom';
import { getFundraise } from '../modules/actions';
import FundraiseInfoTab from './FundraiseInfoTab';
import FundraiseInvestorsTab from './FundraiseInvestorsTab';
import FundraiseInvitesTab from './FundraiseInvitesTab';
import './styles.scss';

const Fundraise = (props) => {
  const {
    literals,
    literalsPerformance,
    literalsCommon,
    match,
    lang,
    user,
  } = props;

  const [fundraise, setFundraise] = useState();
  const [loading, setLoading] = useState(true);
  const [permission, setPermission] = useState();
  const [searchParams] = useSearchParams();
  const invite = searchParams.get('invite');
  const referral = searchParams.get('referral');
  const symbol = literalsCommon.currenciesSymbols[fundraise?.startup?.currency] || '';

  useEffect(() => {
    (async () => {
      try {
        const auxFundraise = await getFundraise(match.params.id, { invite, referral });
        setFundraise(auxFundraise);
        setPermission(initPermission(
          auxFundraise,
          PERMISSION_SCOPES.STARTUP,
          auxFundraise.startup,
          TOOL_FUNDRAISE,
        ));
      } catch (err) {
        if (err.status === 401) {
          setFundraise('unauthorized');
        }
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const tabs = [
    { key: 'info', name: literals.info },
    { key: 'investors', name: literals.commitments },
    { key: 'invites', name: literals.invites },
  ];

  const commonProps = {
    literals,
    literalsCommon,
    loading,
    symbol,
    permission,
  };

  if (!loading && !fundraise?.id) {
    return (
      <Panel>
        <PanelHeader title={literals.title} />
        <PanelBody>
          <div className='panel-empty-message'>
            <img src={EmptyStreet} alt='' />
            <p>{fundraise === 'unauthorized' ? literals.unauthorized : literalsCommon.genericError}</p>
          </div>
        </PanelBody>
      </Panel>
    );
  }

  return (
    <Tool
      key='startup-fundraise-view'
      className='startup-fundraise-view'
      tabs={tabs}
      showTabs={permission?.level >= PERMISSION_LEVELS.VIEW}
      tabsStyle='panel'
    >
      <ToolTab key='info'>
        <FundraiseInfoTab
          {...commonProps}
          literalsPerformance={literalsPerformance}
          lang={lang}
          fundraise={fundraise}
          setFundraise={setFundraise}
          invite={invite}
          user={user}
        />
      </ToolTab>
      <ToolTab key='investors'>
        <FundraiseInvestorsTab
          {...commonProps}
          fundraise={fundraise}
          setFundraise={setFundraise}
          user={user}
          match={match}
        />
      </ToolTab>
      <ToolTab key='invites'>
        <FundraiseInvitesTab
          {...commonProps}
          match={match}
        />
      </ToolTab>
    </Tool>
  );
};

Fundraise.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  literalsPerformance: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

export default Fundraise;
