/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Check } from 'lucide-react';
import './styles.scss';

const InputRadio = ({
  preText = '',
  postText = '',
  isRequired = false,
  isDisabled = false,
  error = null,
  options = [],
  onChange,
  value,
  name = `input-radio-${Math.floor(Math.random() * 10000)}`,
}) => {
  return (
    <div className='input_radio'>
      <div className={`pre_text ${error ? 'text_error' : ''}`}>
        {preText}
        {isRequired && <span className='is_required'>*</span>}
      </div>
      <div className={error ? 'input_options input_error' : 'input_options'}>
        {options.map((elem, index) => {
          return (
            <div key={index} className='input_option'>
              <input
                className='stype'
                name={name}
                type='radio'
                checked={value === elem.id}
                value={elem.id}
                disabled={isDisabled}
                onChange={e => onChange(e.target.value, e)}
              />
              <div className='radio-custom'><Check /></div>
              <label htmlFor={elem.id.toString()}>{elem.name}</label>
            </div>
          );
        })}
      </div>
      {postText && (<div className='post_text' dangerouslySetInnerHTML={{ __html: postText }} />)}
    </div>
  );
};

InputRadio.propTypes = {
  preText: PropTypes.string,
  postText: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  error: PropTypes.object,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string,
};

export default InputRadio;
