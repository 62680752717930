import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Loading from 'components/Loading';
import Avatar from 'components/Avatar';
import Button from 'components/Buttons/Button';
import { Building } from 'lucide-react';
import {
  getFullName, getStartupVerifiedIcon, getTranslation, startupCanEdit,
} from 'utils/functions';
import { TOOL_PROFILE } from 'constants/tools';
import { Link } from 'react-router-dom';
import { ROUTE_STARTUP_PATH } from 'routes';
import { FILES_BASE_URL } from 'constants/apiConf';
import { fetchMentorProfile, fetchStartupProfile } from '../modules/actions';
import './styles.scss';

const PopupProfile = (props) => {
  const {
    id,
    literalsCommon,
    lang,
    data,
    type,
    onClose,
  } = props;

  const [profile, setProfile] = useState(data);

  const fetchProfile = async () => {
    switch (type) {
      case 'mentor': {
        const response = await fetchMentorProfile(id);
        setProfile(response);
        break;
      }
      case 'startup': {
        const response = await fetchStartupProfile(id);
        setProfile(response);
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (!profile) {
      fetchProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderProfileHeader = () => {
    if (type === 'startup') {
      return (
        <div className='popup-profile-header'>
          <div>
            <Avatar
              title={profile.name}
              src={profile.logo?.path}
              size={100}
            />
          </div>
          <div className='pl-2'>
            <h3>
              {profile.name}
              {getStartupVerifiedIcon(profile.verified)}
            </h3>
            <p>
              {profile.description}
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className='popup-profile-header'>
        <div>
          <Avatar
            title={getFullName(profile.user)}
            src={profile.avatar?.path}
            size={100}
          />
        </div>
        <div>
          <h3>{getFullName(profile.user)}</h3>
          <h4>
            <Building size={20} />
            {profile.company}
          </h4>
          <p>
            {profile.headline}
          </p>
        </div>
      </div>
    );
  };

  const renderProfileExtra = () => {
    const getQuestionValue = (value) => {
      if (value) {
        if (Array.isArray(value)) {
          return value.map((val) => {
            if (typeof val === 'object') {
              return getTranslation(val, lang);
            }
            return val;
          }).join(', ');
        }
        if (typeof value === 'object') {
          if (value?.path) {
            return (
              <a href={FILES_BASE_URL + value.path} target='_blank' rel='noopener noreferrer'>{value.name}</a>
            );
          }
          return getTranslation(value, lang);
        }

        return value;
      }
      return '-';
    };

    const extra = Object.keys(profile.extra)
      .map(key => ({ id: key, ...profile.extra[key] }))
      // eslint-disable-next-line no-nested-ternary
      .sort((a, b) => ((a.order > b.order) ? 1 : (a.order < b.order ? -1 : 0)));

    return (
      <div className='popup-profile-extra'>
        {
          extra.map(field => (
            <div key={field.id}>
              <h6 className='fw-b'>{getTranslation(field?.title, lang)}</h6>
              <p>
                { getQuestionValue(field?.value)}
              </p>
            </div>
          ))
        }
      </div>
    );
  };

  return (
    <Popup onClose={onClose}>
      {
        profile === null ? (
          <Loading hide={false} mode='tool' />
        ) : (
          <div className='popup-profile'>
            { renderProfileHeader() }
            { renderProfileExtra() }
            {
              type === 'startup' && startupCanEdit(profile, TOOL_PROFILE) ? (
                <div className='buttons'>
                  <Link to={ROUTE_STARTUP_PATH.setEditStartup(profile.id)}>
                    <Button text={literalsCommon.edit} />
                  </Link>
                </div>
              ) : null
            }
          </div>
        )
      }
    </Popup>
  );
};

PopupProfile.propTypes = {
  type: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  id: PropTypes.string,
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

PopupProfile.defaultProps = {
  id: '',
  data: null,
};

export default PopupProfile;
