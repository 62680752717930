import { combineReducers } from 'redux';
import { FETCH_LOGOUT_SUCCESS } from 'routes/Logout/modules/types';
import Reducers from './reducers';
import initialState from './initialState';


const appReducer = combineReducers({ ...Reducers });

const rootReducer = (state = initialState, action) => {
  let newState = state;

  /* Set state to initial state on logout */
  if (action.type === FETCH_LOGOUT_SUCCESS) {
    newState = {
      ...state,
      logout: {
        inProgress: false,
        success: true,
      },
      session: {
        ...state.session,
        checked: true,
      },
    };
  }

  return appReducer(newState, action);
};

export default rootReducer;
