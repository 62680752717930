import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const FundsInstance = ApiFetch();

export const URLS = {
  FUNDS: 'funds',
  FUND: 'funds/:id',
};

async function fetchFund(id) {
  const path = preparePath(URLS.FUND, { id });
  return FundsInstance.get(path);
}

async function getFunds(params = {}) {
  const path = preparePath(URLS.FUNDS, {}, params);
  return FundsInstance.get(path);
}

async function postFund(data) {
  return FundsInstance.post(URLS.FUNDS, data);
}

async function putFund(id, data) {
  const path = preparePath(URLS.FUND, { id });
  return FundsInstance.put(path, data);
}

async function deleteFund(id) {
  const path = preparePath(URLS.FUND, { id });
  return FundsInstance.delete(path);
}

export default {
  getFunds,
  fetchFund,
  postFund,
  putFund,
  deleteFund,
};
