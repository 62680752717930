import { connect } from 'react-redux';
import { fetchCaptableAction } from 'routes/Captable/modules/actions';
import { bindActionCreators } from 'redux';
import StartupsComponents from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.adminStartups,
  literalsCommon: state.i18n.literals.common,
  literalsOnboarding: state.i18n.literals.onboarding,
  literalsUsers: state.i18n.literals.startupUsers,
  literalsCaptable: state.i18n.literals.captable,
  platform: state.global.platform,
  currency: state.global.currency,
  captable: state.captable,
  lang: state.i18n.language,
  user: state.session.user,
});

function mapDispatchToProps(dispatch) {
  return {
    fetchCaptable: bindActionCreators(fetchCaptableAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StartupsComponents);
