import { connect } from 'react-redux';
import MyInvestmentsPortfolioComponents from '../components';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.myInvestments,
    literalsCaptable: state.i18n.literals.captable,
    literalsCommon: state.i18n.literals.common,
    user: state.session.user,
    shareholders: state.global?.investments?.shareholders,
    currency: state.global?.investments?.currency,
    currencies: state.global?.investments?.currencies,
  };
}

export default connect(mapStateToProps)(MyInvestmentsPortfolioComponents);
