import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelTab from 'components/Panel/components/PanelTab';
import InputText from 'components/Inputs/inputText';
import InputRichText from 'components/Inputs/inputRichText';
import InputNumber from 'components/Inputs/inputNumber';
import InputSelect from 'components/Inputs/inputSelect';
import Button from 'components/Buttons/Button';
import Table from 'components/Table';
import Loading from 'components/Loading';
import useDialog from 'components/Dialog/components/useDialog';
import IOFileLoading from 'components/IOFileLoading/components';
import AddData from 'assets/svg/add_data.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchLocal } from 'components/Table/components/TableFunctions';
import { formatNumber } from 'utils/functions';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import InputPermission from 'components/Inputs/InputPermission';
import { PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import { initPermission } from 'utils/functions/initPermissions';
import { TOOL_CAP_TABLE } from 'constants/tools';
import { fetchStartupOperations } from 'routes/Captable/components/routes/Operations/modules/actions';
import { CloudDownload, Trash } from 'lucide-react';
import { useCounter } from 'utils/customHooks';
import CollapsePayouts from './CollapsePayouts';
import CollapseConvertibles from './CollapseConvertibles';
import CollapseOptions from './CollapseOptions';
import CollapseShares from './CollapseShares';
import WaterfallComparatorTable from '../../waterfallComparator/components/waterfallComparatorTable';
import { downloadWaterfallExcel } from '../../../../modules/actions';
import {
  deleteCaptableWaterfall, getCaptableWaterfall, postCaptableWaterfalls, putCaptableWaterfall,
} from '../modules/actions';
import './styles.scss';

export const overwriteValue = (obj, prop, value) => {
  // eslint-disable-next-line no-prototype-builtins
  if (obj.overwrite && obj.overwrite.hasOwnProperty(prop)) {
    return obj.overwrite[prop];
  }
  return typeof value === 'number' ? Number(value.toFixed(2)) : value;
};

const WaterfallAdd = (props) => {
  const {
    literals,
    literalsCommon,
    currency,
    match,
    startup,
  } = props;

  const [data, setData] = useState({});
  const [results, setResults] = useState({});
  const [operations, setOperations] = useState(null);
  const [permission, setPermission] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const [haveChanges, setHaveChanges] = useState(false);
  const [activeTab, setActiveTab] = useState('table');
  const { canEdit } = permission || {};
  const tableRefreshFlag = useCounter(0);
  const navigate = useNavigate();
  const timeout = useRef();
  const { dialog } = useDialog();

  const id = match.params.waterfallId !== 'new' ? match.params.waterfallId : null;
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const simulation = searchParams.get('simulation') || null;

  const fetchNewData = useCallback(async (page = 0, size = 0, search = 0, filters = {}, sort = '') => {
    clearTimeout(timeout.current);
    const timeoutTime = !haveChanges ? 0 : 3000;
    return new Promise((resolve) => {
      timeout.current = setTimeout(async () => {
        let newData;
        setLoading('distribution');
        const auxData = {
          captable: captableId,
          name: data.name || 'new',
          description: data.description,
          operation: data.operation,
          exit: data.exit,
          otherExits: data.otherExits,
          pending: data.pending,
          payouts: data.payouts,
          shares: data.shares,
          debts: data.debts,
          options: data.options,
          loadOthers: activeTab === 'sensitivity',
          save: false,
        };

        const auxNewData = await postCaptableWaterfalls(startup.id, {
          ...auxData, operation: auxData?.operation?.id,
        });
        setResults({
          shares: auxNewData.shares.map(share => share.items),
          debts: auxNewData.debts.map(debt => debt.results),
          options: auxNewData.options.map(option => option.results),
          distribution: auxNewData.distribution,
          otherDistributions: auxNewData.otherDistributions,
        });
        setLoading(false);
        tableRefreshFlag.increase();
        if (activeTab === 'table') {
          newData = fetchLocal(
            { items: auxNewData.distribution, total: auxNewData.distribution.length },
            { search: ['shareholder.firstname', 'shareholder.lastname'] },
            page, size, search, filters, sort || '-shares',
          );
        }
        if (!haveChanges) {
          setHaveChanges(true);
        }
        resolve(newData);
      }, timeoutTime);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, activeTab]);

  useEffect(() => {
    if (activeTab === 'sensitivity' && data.otherExits.length) {
      fetchNewData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, activeTab]);

  useEffect(() => {
    (async () => {
      const params = new URLSearchParams(window.location.search);
      Promise.all([
        getCaptableWaterfall(startup.id, id || 'new', { captable: captableId, simulation, operation: params.get('operation') }),
        fetchStartupOperations(startup.id, { size: 0, captable: captableId, simulation }),
      ]).then(([newData, newOperations]) => {
        setData(newData);
        setResults({
          shares: newData.shares.map(share => share.items),
          debts: newData.debts.map(debt => debt.results),
          options: newData.options.map(debt => debt.results),
          distributions: newData.distributions,
          otherDistributions: newData.otherDistributions,
        });
        setOperations(newOperations.items);
        setPermission(initPermission(id ? newData : null, PERMISSION_SCOPES.STARTUP, startup, TOOL_CAP_TABLE));
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveWaterfall = async (redirect = true) => {
    const payload = {
      captable: captableId,
      name: data.name || '',
      description: data.description,
      operation: data.operation?.id,
      exit: data.exit,
      otherExits: data.otherExits,
      pending: data.pending,
      payouts: data.payouts,
      shares: data.shares,
      debts: data.debts,
      options: data.options,
      save: true,
      permission,
    };

    if (payload.name && payload.operation && payload.exit) {
      await dialog({
        type: 'loading',
        execute: async () => {
          if (!id) {
            await postCaptableWaterfalls(startup.id, payload);
          } else {
            await putCaptableWaterfall(startup.id, id, payload);
          }

          if (redirect) {
            navigate(embedView(ROUTE_STARTUP_PATH.setCaptableWaterfall(startup.id, captableId)));
          } else {
            setHaveChanges(false);
          }
        },
      });
    } else {
      await dialog({
        type: 'error',
        text: literalsCommon.checkFormErrors,
      });
    }
  };

  const handleDeleteWaterfall = async () => {
    const confirm = await dialog({
      text: literals.deleteWaterfall,
      type: 'confirmDanger',
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteCaptableWaterfall(match.params.id, id);
          navigate(embedView(ROUTE_STARTUP_PATH.setCaptableWaterfall(startup.id, captableId)));
        },
      });
    }
  };

  const handleEditProperty = (prop, value) => {
    if (prop === 'operation') {
      // eslint-disable-next-line no-param-reassign
      value = operations.find(operation => operation.id === value);
      setLoading('operation');
    }
    setData({ ...data, [prop]: value });
  };

  const columns = [
    {
      field: 'shareholder',
      label: literals.shareholder,
      preRender: sh => `${sh.firstname} ${sh.lastname || ''}`,
    },
    {
      field: 'shares',
      label: literals.shares,
      preRender: val => formatNumber(val, 0, { dec: 0 }),
      width: 100,
      sortable: true,
    },
    {
      field: 'capital',
      label: literals.capital,
      preRender: total => formatNumber(total, 0, { dec: 2, symbol: currency.symbol }),
      width: 100,
      sortable: true,
    },
    {
      field: 'liquidation',
      label: literals.liquidation,
      preRender: val => formatNumber(val, 0, { dec: 2, symbol: currency.symbol }),
      width: 125,
      sortable: true,
    },
    {
      field: 'exit',
      label: literals.exit,
      preRender: val => formatNumber(val, 0, { dec: 2, symbol: currency.symbol }),
      width: 125,
      sortable: true,
    },
    {
      field: 'total',
      label: literals.total,
      preRender: total => formatNumber(total, 0, { dec: 2, symbol: currency.symbol }),
      width: 125,
      sortable: true,
    },
    {
      field: 'multiplo',
      label: literals.multiple,
      preRender: total => formatNumber(total, 0, { dec: 2, symbol: 'X' }),
      width: 100,
      sortable: true,
    },
  ];

  const breadcrumb = [
    { route: ROUTE_STARTUP_PATH.setCaptableWaterfall(match.params.id, captableId), name: literals.waterfall },
    { route: ROUTE_STARTUP_PATH.CAP_TABLE_WATERFALL_ADD, name: match.params.waterfallId === 'new' ? literalsCommon.new : literalsCommon.edit },
  ];

  const handleDownloadExcel = async () => {
    if (haveChanges) {
      const confirm = await dialog({
        text: literals.saveWaterfallForExcel,
        type: 'confirm',
      });

      if (confirm) {
        await dialog({
          type: 'loading',
          execute: async () => {
            await handleSaveWaterfall(false);
          },
        });
      }
    }
    setDownloadingExcel(true);
    await downloadWaterfallExcel(match.params.id, id, `waterfall_${data.name}.xlsx`);
    setDownloadingExcel(false);
  };

  const formatData = (type) => {
    if (type === 'shares') {
      return data[type].map((obj, i) => ({
        ...obj,
        items: obj.items.map((item, j) => ({ ...item, results: results[type][i][j].results })),
      }));
    }
    return data[type].map((obj, i) => ({ ...obj, results: results[type][i] }));
  };

  return (
    <Panel>
      <PanelHeader breadcrumb={breadcrumb} />
      <PanelBody>
        {data.shares && operations && permission ? (
          <div className='waterfall-wrapper'>
            <div className='row'>
              <div className='col-12 col-md-8'>
                <InputText
                  preText={literalsCommon.name}
                  value={data.name}
                  onChange={v => setData({ ...data, name: v })}
                  isDisabled={!canEdit || loading}
                  isRequired
                />
              </div>
              <div className='col-12 col-md-4'>
                <InputPermission
                  scope={{ type: PERMISSION_SCOPES.STARTUP, id: startup.id }}
                  element={{ type: PERMISSION_TYPES.REPORT, id: captableId }}
                  value={permission}
                  onChange={setPermission}
                />
              </div>
              <div className='col-12'>
                <InputRichText
                  preText={literalsCommon.description}
                  value={data.description}
                  onChange={v => setData({ ...data, description: v })}
                  minHeight='150px'
                  isDisabled={!canEdit || loading}
                />
              </div>
              <div className='col-12 col-md-6'>
                <InputSelect
                  preText={literals.operation}
                  value={data.operation?.id}
                  onChange={v => handleEditProperty('operation', v)}
                  options={operations.map(op => ({
                    id: op.id,
                    name: `${op.date} | ${op.name} ${op.draft ? '*' : ''}`,
                  }))}
                  isDisabled={!canEdit || loading}
                  isRequired
                />
              </div>

              <div className='col-12 col-md-6'>
                <InputNumber
                  preText={literals.debtsToRepay}
                  value={data.pending}
                  onChange={v => handleEditProperty('pending', v)}
                  minValue={0}
                  decimals={2}
                  symbol={currency.symbol}
                  isDisabled={!canEdit || loading}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-5'>
                <InputNumber
                  preText={literals.exitValue}
                  value={data.exit}
                  onChange={v => handleEditProperty('exit', v)}
                  minValue={0}
                  decimals={2}
                  symbol={currency.symbol}
                  isDisabled={!canEdit || loading}
                  isRequired
                />
              </div>
              <div className='col-12 col-md-7'>
                <InputNumber
                  preText={literals.otherValuesSensitivity}
                  value={data.otherExits}
                  onChange={v => handleEditProperty('otherExits', v)}
                  minValue={0}
                  decimals={2}
                  symbol={currency.symbol}
                  isDisabled={!canEdit || loading}
                  multiple
                />
              </div>
            </div>
            {
              loading === 'operation' ? (<Loading mode='panel' hide={false} />) : (
                <>
                  <CollapsePayouts
                    literals={literals}
                    literalsCommon={literalsCommon}
                    match={match}
                    currency={currency}
                    data={data.payouts}
                    setData={setData}
                    isDisabled={!canEdit || loading}
                  />
                  {data?.debts?.length ? (
                    <CollapseConvertibles
                      literals={literals}
                      literalsCommon={literalsCommon}
                      match={match}
                      currency={currency}
                      data={formatData('debts')}
                      setData={setData}
                      isDisabled={!canEdit || loading}
                      forceFetch={tableRefreshFlag.value}
                    />
                  ) : null}
                  {data?.options?.length ? (
                    <CollapseOptions
                      literals={literals}
                      literalsCommon={literalsCommon}
                      match={match}
                      currency={currency}
                      data={formatData('options')}
                      setData={setData}
                      isDisabled={!canEdit || loading}
                      forceFetch={tableRefreshFlag.value}
                    />
                  ) : null}
                  <CollapseShares
                    literals={literals}
                    literalsCommon={literalsCommon}
                    match={match}
                    currency={currency}
                    data={formatData('shares')}
                    setData={setData}
                    isDisabled={!canEdit || loading}
                    forceFetch={tableRefreshFlag.value}
                  />
                </>
              )}
            <Panel className='mt-5' onTabSelect={setActiveTab}>
              <PanelHeader
                title={literals.distribution}
                tabs={[
                  { key: 'table', name: literals.table },
                  { key: 'sensitivity', name: literals.sensitivity },
                ]}
              />
              <PanelBody>
                <PanelTab key='table'>
                  <Table
                    key='table_waterfall'
                    columns={columns}
                    fetch={fetchNewData}
                    forceFetch={data}
                  />
                </PanelTab>
                <PanelTab key='sensitivity'>
                  { loading || data.otherExits?.length ? (
                    <WaterfallComparatorTable
                      literals={literals}
                      currency={currency}
                      waterfalls={[
                        { exit: data.exit, distribution: results.distribution },
                        ...results.otherDistributions,
                      ].sort((a, b) => (a.exit > b.exit ? 1 : -1))}
                      loading={loading === 'distribution'}
                    />
                  ) : (
                    <div className='panel-empty-message m-0'>
                      <img src={AddData} alt='' />
                      <p>{literals.noOtherExitValues}</p>
                    </div>
                  )}
                </PanelTab>
              </PanelBody>
            </Panel>
            <div className='buttons'>
              {canEdit ? (
                <>
                  {id && (
                    <>
                      <Button
                        color='danger'
                        title={literalsCommon.delete}
                        text={literalsCommon.delete}
                        onClick={handleDeleteWaterfall}
                        icon={Trash}
                      />
                      <Button
                        color='secondary'
                        text='Excel'
                        onClick={handleDownloadExcel}
                        icon={CloudDownload}
                      />
                    </>
                  )}
                  <Button
                    title={literalsCommon.save}
                    text={literalsCommon.save}
                    onClick={handleSaveWaterfall}
                  />
                </>
              ) : (
                <Button
                  color='secondary'
                  text='Excel'
                  onClick={handleDownloadExcel}
                  icon={CloudDownload}
                />
              )}
            </div>
            { downloadingExcel && <IOFileLoading mode='download' file={{ name: `waterfall_${data.name}.xlsx` }} /> }
          </div>
        ) : (
          <Loading mode='panel' hide={false} />
        )}
      </PanelBody>
    </Panel>
  );
};

WaterfallAdd.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
};

export default WaterfallAdd;
