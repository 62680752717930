import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getShareholderPosition } from 'routes/MyPosition/modules/actions';
import StatCard from 'components/Statistics/StatCard';
import { formatNumber } from 'utils/functions';

const SummaryStartupMyPosition = (props) => {
  const {
    match,
    currency,
    literalsMyPosition,
  } = props;

  const [stats, setStats] = useState(null);

  const fetchPosition = async () => {
    return getShareholderPosition(match.params.id, '');
  };

  useEffect(() => {
    fetchPosition().then(
      res => setStats(res),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  return (
    <div className='summary-myPosition'>
      <StatCard title={literalsMyPosition.myShares} value={formatNumber(stats?.shares?.number, 0)} loading={!stats} />
      <StatCard title={literalsMyPosition.totalValue} value={`${formatNumber(stats?.shares?.value, 0)}${currency.symbol}`} loading={!stats} />
      <StatCard title={literalsMyPosition.ownership} value={`${formatNumber(stats?.shares?.ownership, 0, { dec: 2 })}%`} loading={!stats} />
      <StatCard title={literalsMyPosition.convertibles} value={`${formatNumber(stats?.debts?.pending, 0)}${currency.symbol}`} loading={!stats} />
      <StatCard title={literalsMyPosition.options} value={`${formatNumber(stats?.options?.assigned + stats?.options?.assigned, 0)}`} loading={!stats} />
    </div>
  );
};

SummaryStartupMyPosition.propTypes = {
  match: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  literalsMyPosition: PropTypes.object.isRequired,
};

export default SummaryStartupMyPosition;
