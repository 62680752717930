/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Button from 'components/Buttons/Button';
import useDialog from 'components/Dialog/components/useDialog';
import { Skeleton } from '@mui/material';
import { ChevronDown, ChevronRight, Folder } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import { editDocument, editFolder, getFolderContents } from '../modules/actions';

const PopupMove = (props) => {
  const {
    match,
    literals,
    onClose,
    elemToMove,
    foldersProp,
    setFoldersProp,
    actualFolder,
    rootFolder,
    setChange,
    setSubfolders,
    setContent,
  } = props;

  const DEFAULT_FOLDER = elemToMove?.related?.type;

  const [loading, setLoading] = useState(false);
  const [loadingTree, setLoadingTree] = useState(false);
  const [folders, setFolders] = useState(foldersProp);
  const [selectedFolder, setSelectedFolder] = useState(actualFolder);
  const { dialog } = useDialog();
  const location = useLocation();
  const rolePath = location.pathname.split('/').filter(p => p !== 'embed')[1];

  const fetchChildsFolders = async (folder) => {
    return getFolderContents(rolePath, match.params.id, folder.id, {
      page: 0, size: 0, sort: '-created_at', filters: { 'element.type': 'folder' },
    });
  };

  const handleChildsFolder = (newFolder, auxFolders, childs, toggle) => {
    return auxFolders.map((folder) => {
      if (folder.id === newFolder.id) {
        setLoadingTree(false);
        if (folder.childs || !childs) {
          return { ...folder, showChilds: toggle ? !folder.showChilds : true };
        }
        return { ...folder, childs, showChilds: true };
      }
      if (folder.id === newFolder?.info?.parent) {
        return { ...folder, showChilds: true, childs: handleChildsFolder(newFolder, folder.childs, childs, toggle) };
      }
      return folder.childs
        ? { ...folder, childs: handleChildsFolder(newFolder, folder.childs, childs, toggle) }
        : folder;
    });
  };

  const renderTree = (auxFolders) => {
    return auxFolders.map(folder => (
      <li key={folder.id}>
        <div
          className={`li-container ${folder.id === selectedFolder?.id && 'selected'} ${folder.id === elemToMove?.id && 'moving'}`}
          onClick={() => {
            if (!folder.childs && (!folder.stats || folder.stats.folders > 0)) {
              setLoadingTree(folder.id);
              fetchChildsFolders(folder).then((childs) => {
                setFolders(prev => handleChildsFolder(folder, prev, childs.items, false));
                setLoadingTree(false);
              });
            } else {
              setFolders(prev => handleChildsFolder(folder, prev, null, false));
            }
            setSelectedFolder(folder.id !== elemToMove.id ? folder : null);
          }}
        >
          <span
            className='d-flex align items-center mr-2'
            onClick={(e) => {
              e.stopPropagation();
              if (folder.childs || folder?.stats?.folders === 0) {
                setFolders(prev => handleChildsFolder(folder, prev, null, true));
              } else {
                setLoadingTree(folder.id);
                fetchChildsFolders(folder).then((childs) => {
                  setFolders(prev => handleChildsFolder(folder, prev, childs.items, true));
                  setLoadingTree(false);
                });
              }
            }}
          >
            {folder.showChilds ? <ChevronDown size={12} /> : <ChevronRight size={12} />}
          </span>
          <Folder size={16} className='mr-2' />
          <span className='folder-name' title={folder.name}>{folder.name}</span>
        </div>
        {loadingTree === folder.id && (
          <div className='pl-2'>
            {[...Array(Math.min(folder.stats.folders, 5))].map(() => (
              <span className='d-flex align-items-center justify-content-start'>
                <Skeleton animation='wave' width='35px' height={44} className='mr-2' />
                <Skeleton animation='wave' width='40%' height={44} />
              </span>
            ))}
          </div>
        )}
        {folder?.childs?.length > 0 && folder.showChilds && (
          <ul className='pl-2'>
            {renderTree(folder.childs)}
          </ul>
        )}
      </li>
    ));
  };

  const moveFolder = (newFolder, auxFolders) => {
    return auxFolders.map((folder) => {
      if (folder.id === selectedFolder.id) {
        setChange({ type: 'move', folder: newFolder });
        return {
          ...folder,
          childs: folder.childs ? moveFolder(newFolder, [...folder.childs, newFolder]) : null,
          stats: { ...folder.stats, folders: folder.stats.folders + 1 },
        };
      }
      if (folder.id === elemToMove.parent) {
        setChange({ type: 'move', folder: newFolder });
        return {
          ...folder,
          childs: folder.childs ? moveFolder(newFolder, folder.childs).filter(child => child.id !== newFolder.id) : null,
          stats: { ...folder.stats, folders: folder.stats.folders - 1 },
        };
      }

      return folder.childs
        ? { ...folder, childs: moveFolder(newFolder, folder.childs) }
        : folder;
    });
  };

  const moveDocument = (newDocument, auxFolders) => {
    return auxFolders.map((folder) => {
      if (folder.id === selectedFolder.id) {
        return {
          ...folder,
          stats: { ...folder.stats, documents: folder.stats.documents + 1 },
          childs: folder.childs ? moveDocument(newDocument, folder.childs) : null,
        };
      }
      if (folder.id === actualFolder.id) {
        return {
          ...folder,
          childs: folder.childs ? moveDocument(newDocument, folder.childs) : null,
          stats: { ...folder.stats, documents: folder.stats.documents - 1 },
        };
      }

      return folder.childs
        ? { ...folder, childs: moveDocument(newDocument, folder.childs) }
        : folder;
    });
  };

  // eslint-disable-next-line consistent-return
  const handleMove = async () => {
    setLoading(true);
    try {
      if (elemToMove._type === 'folder') {
        return editFolder(rolePath, match.params.id, elemToMove.id, { name: elemToMove.name, parent: selectedFolder.id })
          .then((newFolder) => {
            let auxFolders = [...folders];
            if (elemToMove.parent === rootFolder?.id) {
              auxFolders = auxFolders.filter(fldr => newFolder.id !== fldr.id);
            }
            if (newFolder.parent === rootFolder?.id) {
              setChange({ type: 'move', folder: newFolder });
              auxFolders.push(newFolder);
            }
            setFoldersProp(moveFolder(newFolder, auxFolders));
            onClose();
          });
      }

      return editDocument(elemToMove.id, {
        folder: selectedFolder.id, name: elemToMove.name, description: elemToMove.description, return: 'object',
      })
        .then((newDocument) => {
          setFoldersProp(moveDocument(newDocument, folders));
          setContent(prev => ({ items: prev.items.filter(child => child.id !== newDocument.id), total: prev.total - 1 }));
          setSubfolders(prev => prev.map(
            folder => (folder.id === selectedFolder.id
              ? { ...folder, stats: { ...folder.stats, documents: folder.stats.documents + 1 } }
              : folder),
          ));
          onClose();
        });
    } catch (error) {
      let errorMessage = '';
      if (error?.status === 401) {
        errorMessage = literals.noPermissions;
      }
      await dialog({
        type: 'error',
        text: (
          <>
            <strong>{literals.error}</strong>
            <br />
            {errorMessage}
          </>
        ),
      });
      setLoading(false);
    }
  };


  return (
    <Popup title={`${literals.move} ${literals[elemToMove._type]}`} onClose={onClose}>
      <>
        <div className='dataroom-container'>
          <div className='folders-tree move-tree simple-scrollbar'>
            {rootFolder && (
              <h6
                className={`root cursor-pointer ${selectedFolder?.id === rootFolder.id ? 'selected' : ''}`}
                onClick={() => setSelectedFolder(rootFolder)}
              >
                <Folder size={16} className='mr-2' />
                <span className='folder-name' title={rootFolder.name}>{rootFolder.name}</span>
              </h6>
            )}
            <ul className='container-list'>
              {loadingTree === 'root' ? (
                <div>
                  {[...Array(5)].map(() => (
                    <span className='d-flex align-items-center justify-content-start'>
                      <Skeleton animation='wave' width='17%' height={44} className='mr-2' />
                      <Skeleton animation='wave' width='73%' height={44} />
                    </span>
                  ))}
                </div>
              ) : (
                renderTree(folders)
              )}
            </ul>
          </div>
        </div>
        {
          !DEFAULT_FOLDER ? (
            <div className='d-flex justify-content-end mt-4'>
              <Button text={literals.move} onClick={handleMove} loading={loading} disabled={!selectedFolder} />
            </div>
          ) : null
        }
      </>
    </Popup>
  );
};

PopupMove.propTypes = {
  match: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  elemToMove: PropTypes.object.isRequired,
  actualFolder: PropTypes.object.isRequired,
  rootFolder: PropTypes.object.isRequired,
  foldersProp: PropTypes.array.isRequired,
  setFoldersProp: PropTypes.func.isRequired,
  setChange: PropTypes.func,
  setSubfolders: PropTypes.func,
  setContent: PropTypes.func,
};

PopupMove.defaultProps = {
  setChange: () => {},
  setSubfolders: () => {},
  setContent: () => {},
};

export default PopupMove;
