
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Avatar from 'components/Avatar';
import Button from 'components/Buttons/Button';
import useDialog from 'components/Dialog/components/useDialog';
import InputTextArea from 'components/Inputs/inputTextArea';
import { getFullName, getStartupVerifiedIcon } from 'utils/functions';
import { Skeleton } from '@mui/material';
import {
  Building,
  CheckCircle,
  Trash,
  XCircle,
} from 'lucide-react';

const MentoringInvitationRow = (props) => {
  const {
    id,
    view,
    mentor,
    startup,
    message,
    status,
    startedBy,
    loading,
    literals,
    canEdit,
    rejectionMessage,
    onChangeStatus,
  } = props;

  const { dialog } = useDialog();
  const [rejectMetoring, setRejectMentoring] = useState(false);
  const [messageReject, setMessageReject] = useState();
  const [loadingReject, setLoadingReject] = useState(false);

  if (loading) {
    return (
      <div key={id} className='box box-padding d-flex align-items-center box-mb'>
        <Skeleton
          animation='wave'
          variant='circular'
          height={70}
          width={70}
        />
        <div className='flex-grow-1 p-1 pl-4'>
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='100%' />
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='100%' />
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='100%' />
        </div>
      </div>
    );
  }

  const handleClickStatus = async (pendingStatus) => {
    if (pendingStatus === 'rejected') {
      setRejectMentoring(true);
    } else {
      const confirm = await dialog({
        text: literals.confirms[pendingStatus],
        type: 'confirm',
      });

      if (confirm) {
        await dialog({
          type: 'loading',
          execute: async () => {
            await onChangeStatus(id, pendingStatus, null);
          },
        });
      }
    }
  };

  const rejectMentoringHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoadingReject(true);
    await onChangeStatus(id, 'rejected', messageReject);
    setLoadingReject(false);
  };

  const renderActions = () => {
    if (view.type === startedBy) {
      return (
        <Button icon={Trash} text={literals.cancel} color='danger' onClick={() => handleClickStatus('cancel')} />
      );
    }
    return (
      <>
        <Button icon={CheckCircle} text={literals.accept} color='primary' onClick={() => handleClickStatus('accepted')} />
        <Button icon={XCircle} text={literals.reject} color='danger' onClick={() => handleClickStatus('rejected')} />
      </>
    );
  };

  const renderByStatus = () => {
    switch (status) {
      case 'rejected':
        return (
          <>
            <div>
              <XCircle size={24} />
              {literals.rejected}
            </div>
          </>
        );
      default:
        return (
          <div className='mentorings-actions-buttons'>
            { renderActions() }
          </div>
        );
    }
  };

  let image = '';
  let name = '';
  let title = '';
  let info = '';
  const special = {};

  if (view.type === 'startup') {
    image = mentor.avatar?.path;
    name = getFullName(mentor.user);
    title = name;
    info = mentor.headline;
    special.icon = <Building size={24} />;
    special.text = mentor.company;
  } else {
    image = startup.logo?.path;
    name = (
      <span className='d-flex align-items-center'>
        {startup.name}
        {getStartupVerifiedIcon(startup.verified)}
      </span>
    );
    title = startup.name;
    info = startup.description;
    special.icon = <Avatar title={title} src='' size={24} />;
    special.text = '';
  }

  return (
    <div className='mentoring-row box' key={id}>
      <div className='mentoring-info'>
        <div className='mentoring-avatar'>
          <Avatar title={title} src={image} size={70} />
        </div>
        <div className='mentoring-basic'>
          <h4>
            <Avatar title={title} src={image} size={45} className='mentoring-avatar-mobile' />
            {name}
          </h4>
          <p>{info}</p>
          <div className='mentoring-message simple-scrollbar'>
            <div>
              {literals.invitationMessage}
              <div className='message'>{message}</div>
            </div>
            {status === 'rejected' && (
              <div className='mt-3'>
                {literals.rejectionReason}
                <div className='message'>{rejectionMessage || '-'}</div>
              </div>
            )}
          </div>
        </div>
        <div className='mentoring-special'>
          {
            special.text && (
              <>
                <span>{special.text}</span>
                {special.icon}
              </>
            )
          }
        </div>
      </div>
      {
        canEdit && (
          <div className='mentoring-actions'>
            {renderByStatus()}
          </div>
        )
      }
      {
        rejectMetoring && (
          <Popup title={literals.rejectMentoring} description={literals.confirms.rejected} onClose={() => setRejectMentoring(false)}>
            <form onSubmit={rejectMentoringHandler}>
              <InputTextArea
                name='rejectMentoring'
                preText={`${literals.explainTheReasonTo} ${name}`}
                onChange={v => setMessageReject(v)}
                value={messageReject}
                isRequired
              />
              <div className='buttons'>
                <Button color='danger' type='submit' text={literals.confirm} loading={loadingReject} />
                <Button text={literals.cancel} color='secondary' loading={loadingReject} onClick={() => setRejectMentoring(false)} />
              </div>
            </form>
          </Popup>
        )
      }
    </div>
  );
};

MentoringInvitationRow.propTypes = {
  id: PropTypes.string.isRequired,
  view: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  status: PropTypes.string,
  mentor: PropTypes.object,
  startup: PropTypes.object,
  startedBy: PropTypes.string,
  message: PropTypes.string,
  rejectionMessage: PropTypes.string,
  loading: PropTypes.bool,
  canEdit: PropTypes.bool.isRequired,
  onChangeStatus: PropTypes.func,
};

MentoringInvitationRow.defaultProps = {
  mentor: {},
  startup: {},
  startedBy: '',
  message: '',
  rejectionMessage: '',
  loading: false,
  onChangeStatus: null,
  status: null,
};

export default MentoringInvitationRow;
