import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel/components/';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Table from 'components/Table';
import useDialog from 'components/Dialog/components/useDialog';
import IOFileLoading from 'components/IOFileLoading/components';
import { useCounter } from 'utils/customHooks';
import { formatDate, getStartupVerifiedIcon } from 'utils/functions';
import {
  PlusCircle, Upload, Download,
  BadgeCheck, Pencil, Trash,
} from 'lucide-react';
import FundPopup from './FundPopup';
import VerifyFundPopup from './VerifyFundPopup';
import FundUploadExcelPopup from './FundUploadExcelPopup';
import { getFunds, deleteFund, downloadExcelFunds } from '../modules/actions';
import './styles.scss';

const Funds = ({ literals, literalsCommon }) => {
  const [fundPopup, setFundPopup] = useState();
  const [fundUploadPopup, setFundUploadPopup] = useState();
  const [downloading, setDownloading] = useState(false);
  const [verifiedFundPopup, setVerifiedFundPopup] = useState();
  const tableRefreshFlag = useCounter(0);
  const { dialog } = useDialog();

  const handleDeleteFund = async (fund) => {
    const confirm = await dialog({
      type: 'confirmDanger', text: literals.confirmDeleteFund,
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteFund(fund.id);
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const columns = [
    {
      field: 'logo',
      type: 'avatar',
      width: 45,
      title: (v, row) => row.name,
    },
    {
      field: 'name',
      label: literalsCommon.name,
      preRender: (name, row) => (
        <div className='d-flex align-items-center'>
          {name}
          {getStartupVerifiedIcon(row.verified)}
        </div>
      ),
    },
    {
      field: 'createdAt',
      width: '180px',
      label: literalsCommon.createdAt,
      preRender: date => formatDate(date, { time: true }),
    },
    {
      field: 'info',
      type: 'menu',
      buttons: [
        {
          icon: Pencil,
          text: literalsCommon.edit,
          onClick: setFundPopup,
        },
        {
          icon: Trash,
          text: literalsCommon.delete,
          onClick: handleDeleteFund,
        },
        {
          icon: BadgeCheck,
          text: literals.verification,
          onClick: setVerifiedFundPopup,
        },
      ],
    },
  ];

  const actions = [
    {
      icon: PlusCircle,
      text: literalsCommon.create,
      onClick: () => setFundPopup({ logo: null, name: '', verified: 'non' }),
    },
    {
      icon: Download,
      text: literals.downloadExcel,
      onClick: async () => {
        setDownloading('funds_excel.xls');
        await downloadExcelFunds();
        setDownloading(false);
      },
    },
    {
      icon: Upload,
      text: literals.uploadExcel,
      onClick: () => setFundUploadPopup(true),
    },
  ];

  return (
    <Panel>
      <PanelHeader title={literals.title} />
      <PanelBody>
        <Table
          key='table_funds'
          columns={columns}
          pageSize={10}
          onClickRow={setFundPopup}
          fetch={getFunds}
          forceFetch={tableRefreshFlag.value}
          actions={actions}
        />
      </PanelBody>

      { fundPopup && (
        <FundPopup
          literals={literals}
          literalsCommon={literalsCommon}
          fund={fundPopup}
          onSubmit={tableRefreshFlag.increase}
          onClose={() => setFundPopup(false)}
        />
      )}
      { fundUploadPopup && (
        <FundUploadExcelPopup
          literals={literals}
          onClose={() => setFundUploadPopup(false)}
        />
      )}
      { verifiedFundPopup && (
        <VerifyFundPopup
          literals={literals}
          literalsCommon={literalsCommon}
          fund={verifiedFundPopup}
          onSubmit={tableRefreshFlag.increase}
          onClose={() => setVerifiedFundPopup(false)}
        />
      )}
      { downloading && <IOFileLoading mode='download' file={{ name: downloading }} /> }
    </Panel>
  );
};

Funds.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
};

export default Funds;
