import StartupsCaptablesInstance from 'modules/startupsCaptables/repository';
import { editCaptableOperationFailure, editCaptableOperationInit, editCaptableOperationSuccess } from 'routes/Captable/modules';
import { formatErrors } from 'utils/functions';

export function fetchStartupOperations(id, opts) {
  return StartupsCaptablesInstance
    .getStartupOperations(id, opts)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw formatErrors(error);
    });
}

export function putStartupOperation(id, operation, data) {
  return StartupsCaptablesInstance.putStartupOperation(id, operation, data)
    .then(response => response)
    .catch((error) => {
      throw formatErrors(error);
    });
}

export function fetchOperation(startup, operation) {
  return StartupsCaptablesInstance.getStartupOperation(startup, operation);
}

export function editOperationTimelineAction(startup, operation, status) {
  return (dispatch) => {
    dispatch(editCaptableOperationInit());
    return StartupsCaptablesInstance.putStartupOperationTimeline(startup, operation, { status }).then((response) => {
      dispatch(editCaptableOperationSuccess(response));
      return response;
    }).catch((error) => {
      const formatError = formatErrors(error);
      dispatch(editCaptableOperationFailure(formatError));
      throw formatError;
    });
  };
}

export function editOperationDraftAction(startup, operation, status) {
  return (dispatch) => {
    dispatch(editCaptableOperationInit());
    return StartupsCaptablesInstance.putStartupOperationDraft(startup, operation, { status }).then((response) => {
      dispatch(editCaptableOperationSuccess(response));
      return response;
    }).catch((error) => {
      const formatError = formatErrors(error);
      dispatch(editCaptableOperationFailure(formatError));
      throw formatError;
    });
  };
}

export const getCaptableSimulations = (id, opts) => {
  return StartupsCaptablesInstance.getStartupCaptableSimulations(id, opts);
};

export const getCaptableSimulation = (id, simulation, params = {}) => {
  return StartupsCaptablesInstance.getStartupCaptableSimulation(id, simulation, params);
};

export const postCaptableSimulation = (id, data) => {
  return StartupsCaptablesInstance.postStartupCaptableSimulations(id, data);
};

export const putCaptableSimulation = (id, simulation, data) => {
  return StartupsCaptablesInstance.putStartupCaptableSimulations(id, simulation, data);
};

export const deleteCaptableSimulation = (id, simulation) => {
  return StartupsCaptablesInstance.deleteStartupCaptableSimulation(id, simulation);
};
