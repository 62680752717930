import StartupsCaptablesInstance from 'modules/startupsCaptables/repository';
import { apiDownloadFile } from 'modules/actions';
import { API_WATERFALL_COMPARATOR_EXCEL } from 'modules/apiEndpoints';

export const getCaptableWaterfalls = (id, opts) => {
  return StartupsCaptablesInstance.getStartupCaptableWaterfalls(id, opts);
};

export const getCaptableWaterfall = (id, waterfall, params = {}) => {
  return StartupsCaptablesInstance.getStartupCaptableWaterfall(id, waterfall, params);
};

export function downloadExcelWaterfallComparator(id, waterfalls) {
  return apiDownloadFile(API_WATERFALL_COMPARATOR_EXCEL(id, waterfalls), 'waterfall_comparator.xlsx');
}
