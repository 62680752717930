import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import { Skeleton } from '@mui/material';
import { PlusCircle, XCircle } from 'lucide-react';
import useDialog from 'components/Dialog/components/useDialog';
import EmptyData from 'assets/svg/empty_data.svg';
import Button from 'components/Buttons/Button';
import Meeting from './Meeting';
import MeetingForm from './MeetingForm';
import MeetingInfo from './MeetingInfo';
import './styles.scss';

const Meetings = ({
  literals,
  literalsCommon,
  meetings,
  onSubmit = false,
  onDelete,
  view = 'default',
  user,
}) => {
  const [viewMeeting, setViewMeeting] = useState(false);
  const [editMeeting, setEditMeeting] = useState(false);
  const { dialog } = useDialog();

  const submitMeeting = onSubmit ? async (id, info) => {
    try {
      await onSubmit(id, info);
      setEditMeeting(false);
    } catch (err) {
      throw err;
    }
  } : null;

  const deleteMeeting = onDelete ? async (meeting) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literals.confirmDelete,
    });
    if (confirm && onDelete) {
      await onDelete(meeting.id);
    }
  } : null;

  const renderMeetings = () => {
    if (meetings && meetings.items.length > 0) {
      return meetings.items.map(meeting => (
        <Meeting
          key={meeting.id}
          literals={literals}
          literalsCommon={literalsCommon}
          view={view}
          meeting={meeting}
          onClick={m => setViewMeeting(m)}
          onEdit={submitMeeting ? () => setEditMeeting(meeting) : null}
          onDelete={deleteMeeting ? () => deleteMeeting(meeting) : null}
        />
      ));
    }

    return (
      <li className='panel-empty-message'>
        <img src={EmptyData} alt='' />
        <p>{literals.emptyMeetings}</p>
      </li>
    );
  };

  const renderLoading = (size) => {
    return Array.from(new Array(size)).map((_, i) => (
      <div key={i} className='d-flex align-items-center listItemBox'>
        <Skeleton
          animation='wave'
          height={120}
          style={{ marginBottom: 2 }}
          width='20%'
        />
        <div className='flex-grow-1 p-2'>
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='100%' />
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='100%' />
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='100%' />
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className='meetings'>
        {onSubmit && (
          <div className='panel-fix-rigth-button'>
            <Button
              mode='icon'
              onClick={() => setEditMeeting({})}
              text={literals.createMeeting}
              icon={editMeeting !== false ? XCircle : PlusCircle}
            />
          </div>
        )}
        <ul className='meetings-list'>
          {meetings ? renderMeetings() : renderLoading(5)}
        </ul>
      </div>
      {viewMeeting !== false && (
        <Popup onClose={() => setViewMeeting(false)} size='large'>
          <MeetingInfo literals={literals} meeting={viewMeeting} />
        </Popup>
      )}
      {editMeeting !== false && (
        <Popup
          onClose={() => setEditMeeting(false)}
          title={literals.meetingForm.popupTitle}
          description={literals.meetingForm.popupDescription}
          size='large'
        >
          <MeetingForm user={user} literals={literals} meeting={editMeeting} onSubmit={submitMeeting} />
        </Popup>
      )}
    </>
  );
};

Meetings.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  meetings: PropTypes.object,
  onSubmit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  view: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default Meetings;
