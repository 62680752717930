/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import Popup from 'components/Popup';
import useDialog from 'components/Dialog/components/useDialog';
import { formatNumber } from 'utils/functions';
import { useParams } from 'react-router-dom';
import { Eye, ArrowLeftRight, CircleArrowOutDownLeft } from 'lucide-react';
import { getCaptableSummaryTable } from './CaptableSummary/modules/actions';
import FormSecondary from './routes/Operation/components/FormSecondary';
import FormChangeClass from './routes/Operation/components/FormChangeClass';
import PopupInfoShares from './routes/Securities/components/PopupInfoShares';
import './styles.scss';

export const getCommonColumns = (type, literals, currency) => {
  const isShareholder = type === 'shareholder';

  return [
    {
      field: 'name',
      label: isShareholder ? literals.shareholder : literals.syndicate,
      grow: 2,
      preRender: v => literals[v] || v,
    },
    {
      field: 'shareClasses',
      label: literals.shareClasses,
      grow: 2,
      preRender: v => (
        <div className='cell-keywords'>
          {v?.map(shareClass => (
            <div key={shareClass.id} title={shareClass.name}>
              {shareClass.code || shareClass.name}
            </div>
          ))}
        </div>
      ),
    },
    {
      field: 'shares',
      label: literals.shares,
      format: {},
      minWidth: 100,
      id: 'position.shares.number',
      sortable: true,
      preRender: v => formatNumber(v, 0, { dec: 0 }),
    },
    {
      field: 'options',
      label: literals.options,
      minWidth: 100,
      id: 'position.options.number',
      sortable: true,
      preRender: v => formatNumber(v, 0, { dec: 0 }),
    },
    {
      field: 'ownership',
      label: literals.ownership,
      minWidth: 100,
      preRender: v => formatNumber(v, 0, { dec: 4, symbol: '%' }),
      id: 'position.ownership.nonFullyDiluted',
      sortable: true,
    },
    {
      field: 'fullyDiluted',
      label: literals.fullyDiluted,
      minWidth: 100,
      preRender: v => formatNumber(v, 0, { dec: 4, symbol: '%' }),
      id: 'position.ownership.fullyDiluted',
      sortable: true,
    },
    {
      field: 'votes',
      label: literals.vote,
      minWidth: 100,
      preRender: v => formatNumber(v, 0, { dec: 4, symbol: '%' }),
      id: 'position.shares.votes',
      sortable: true,
    },
    {
      field: 'capital',
      label: literals.capital,
      minWidth: 100,
      preRender: v => formatNumber(v, 0, { dec: 2, symbol: currency?.symbol }),
      id: 'position.shares.capital',
      sortable: true,
    },
    {
      field: 'value',
      label: literals.value,
      minWidth: 100,
      preRender: v => formatNumber(v, 0, { dec: 2, symbol: currency?.symbol }),
      id: 'position.shares.value',
      sortable: true,
    },
  ];
};

const PanelCaptableShareholders = (props) => {
  const {
    literals, literalsCommon, captable, currency, date, submitOperation, canEdit,
  } = props;

  const [showDetails, setShowDetails] = useState(false);
  const [showSecondary, setShowSecondary] = useState(false);
  const [showChangeClasses, setShowChangeClasses] = useState(false);
  const [unassigned, setUnassigned] = useState(false);
  const params = useParams();
  const { dialog } = useDialog();

  const loadSummaryTable = useCallback(date ? async (page, size, search, _, sort) => {
    const rows = await getCaptableSummaryTable(params.id, date, {
      group: 'shareholder',
      captable: captable.selected?.id,
      search,
      sort,
    });
    setUnassigned(rows.items.length - 2 >= rows.total);
    return rows;
  } : null, [date]);

  const handleConfirm = async (op) => {
    const [confirm, draft] = await dialog({
      text: literals.confirmSubmit,
      type: 'custom',
      actions: [
        {
          text: literalsCommon.yes,
          color: 'success',
          onClick: () => [true, false],
        },
        {
          text: literals.draft,
          onClick: () => [true, true],
        },
        {
          text: literalsCommon.cancel,
          color: 'secondary',
          onClick: () => [false, false],
        },
      ],
    });

    if (confirm) {
      const operation = {
        notes: op.notes,
        documents: op.documents,
        draft,
        timeline: true,
        date: op.date,
        captable: captable.selected?.id,
      };
      const operationType = op.operationType || 'secondary';
      if (op.operationType) {
        // eslint-disable-next-line no-param-reassign
        delete op.operationType;
      }

      switch (operationType) {
        case 'stockSplit':
          operation.name = op.name || `${literals.issue.stockSplit}`;
          operation.stockSplit = op;
          break;
        case 'valuation':
          operation.name = op.name || `${literals.valuation}: ${formatNumber(op?.valuation, '-')}`;
          operation.valuation = op;
          break;
        case 'dividend':
          operation.name = op.name || `${literals.issue.dividend}: ${op?.totalDividend}`;
          operation.dividend = op;
          break;
        case 'changeClasses':
          operation.name = op.name || `${literals.changeClasses}: ${captable.shareClass.find(sc => sc.id === op?.shareClass)?.name}`;
          operation.changeClasses = [{
            ...op.data,
            shares: Object.keys(op.data.shares).map(share => ({ id: share, ...op.data.shares[share] })),
          }];
          break;
        case 'reduce':
          operation.name = op.name || `${literals.reduce}: ${captable.shareClass.find(sc => sc.id === op?.shareClass)?.name}`;
          operation.reduces = [{
            ...op.data,
            shares: Object.values(op.data.shares),
          }];
          break;
        default:
          operation.name = op.name || `${literals.secondarySale}: ${captable.shareholders.find(sc => sc.id === op?.shareholder)?.name}`;
          operation.secondaries = [{
            ...op.data,
            shares: Object.keys(op.data.shares).map(share => ({ id: share, ...op.data.shares[share] })),
          }];
          break;
      }

      await dialog({
        type: 'loading',
        execute: async () => {
          await submitOperation(params.id, operation);
        },
      });

      setShowSecondary(false);
      setShowChangeClasses(false);
    }
  };

  const columns = getCommonColumns('shareholder', literals, currency);
  if (!canEdit) {
    columns.push({
      field: 'id',
      type: 'button',
      hidden: id => ['total', 'unassigned', 'other'].includes(id),
      button: {
        icon: Eye,
        text: literalsCommon.view,
        onClick: row => setShowDetails({ type: 'shareholder', data: row }),
      },
    });
  } else {
    columns.push({
      field: 'id',
      type: 'menu',
      hidden: id => ['total', 'unassigned', 'other'].includes(id),
      buttons: [
        {
          icon: Eye,
          text: literalsCommon.view,
          onClick: row => setShowDetails({ type: 'shareholder', data: row }),
        },
        {
          icon: ArrowLeftRight,
          text: literals.secondary,
          onClick: row => setShowSecondary(row.id),
        },
        {
          icon: CircleArrowOutDownLeft,
          text: literals.changeClasses,
          onClick: row => setShowChangeClasses(row.id),
        },
      ],
    });
  }

  return (
    <>
      <Table
        className={`captable-summary-table ${unassigned ? 'unassigned' : ''}`}
        columns={columns}
        fetch={loadSummaryTable}
        forceFetch={date}
        onClickRow={row => (!['total', 'unassigned', 'other'].includes(row.id)
          ? setShowDetails({ type: 'shareholder', data: row })
          : null)
        }
      />
      {
        showDetails && (
          <PopupInfoShares
            {...showDetails}
            date={date}
            literals={literals}
            literalsCommon={literalsCommon}
            currency={currency}
            onClose={() => setShowDetails(false)}
          />
        )
      }
      {
        showSecondary && (
          <Popup title={literals.issue.secondaries} onClose={() => setShowSecondary(false)} size='large'>
            <FormSecondary
              id={showSecondary}
              literals={literals}
              literalsCommon={literalsCommon}
              captable={captable}
              currency={currency}
              onConfirm={handleConfirm}
            />
          </Popup>
        )
      }
      {
        showChangeClasses && (
          <Popup title={literals.issue.changeClasses} onClose={() => setShowChangeClasses(false)} size='large'>
            <FormChangeClass
              id={showChangeClasses}
              literals={literals}
              literalsCommon={literalsCommon}
              captable={captable}
              currency={currency}
              onConfirm={handleConfirm}
            />
          </Popup>
        )
      }
    </>
  );
};

PanelCaptableShareholders.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  date: PropTypes.string,
  canEdit: PropTypes.bool.isRequired,
  submitOperation: PropTypes.func.isRequired,
};

PanelCaptableShareholders.defaultProps = {
  date: new Date().toISOString().split('T')[0],
};

export default PanelCaptableShareholders;
