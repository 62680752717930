import StartupsInstance from 'modules/startups/repository';
import MentoringsInstance from 'modules/mentorings/repository';
import FormsInstance from 'modules/forms/repository';
import StartupsFundraisesInstance from 'modules/startupsFundraises/repository';

import { SessionService } from 'modules/session/sessionService';
import {
  EDIT_COMPANY_INIT,
  EDIT_COMPANY_SUCCESS,
  EDIT_COMPANY_FAILURE,
  EDIT_MENTOR_INIT,
  EDIT_MENTOR_SUCCESS,
  EDIT_MENTOR_FAILURE,
} from 'routes/Home/modules/types';
import {
  FETCH_STARTUP_QUESTIONS_INIT,
  FETCH_STARTUP_QUESTIONS_FAILURE,
  FETCH_STARTUP_QUESTIONS_SUCCESS,
  FETCH_MENTOR_QUESTIONS_INIT,
  FETCH_MENTOR_QUESTIONS_SUCCESS,
  FETCH_MENTOR_QUESTIONS_FAILURE,
} from './types';
import { loadingAction } from '../../../store/globalState/global';


export function editStartup(id, data) {
  return (dispatch) => {
    dispatch({ type: EDIT_COMPANY_INIT });
    return StartupsInstance
      .startupEdit(id, data)
      .then((response) => {
        dispatch({
          type: EDIT_COMPANY_SUCCESS,
          payload: response,
        });
        return true;
      })
      .catch((err) => {
        dispatch({
          type: EDIT_COMPANY_FAILURE,
          payload: err,
        });
        return false;
      });
  };
}

export function editMentor(data, user) {
  return (dispatch) => {
    dispatch({ type: EDIT_MENTOR_INIT });
    return MentoringsInstance
      .saveMentoringOnboarding(data)
      .then((response) => {
        dispatch({ type: EDIT_MENTOR_SUCCESS });
        SessionService.saveUser({ ...user, mentor: response }).then(() => {
          return true;
        });
      })
      .catch((err) => {
        dispatch({ type: EDIT_MENTOR_FAILURE });
        console.error('err', err);
        return false;
      });
  };
}


export function editInvestor(data, user) {
  return () => StartupsFundraisesInstance.saveInvestorOnboarding(data)
    .then((response) => {
      SessionService.saveUser({ ...user, investor: response }).then(() => true);
    })
    .catch(() => false);
}

export function startupGetFormAction() {
  return (dispatch) => {
    dispatch(loadingAction(true));
    dispatch({
      type: FETCH_STARTUP_QUESTIONS_INIT,
    });
    return FormsInstance
      .getFormStartups()
      .then((response) => {
        dispatch({
          type: FETCH_STARTUP_QUESTIONS_SUCCESS,
          payload: response,
        });
        dispatch(loadingAction(false));
      })
      .catch((err) => {
        dispatch({
          type: FETCH_STARTUP_QUESTIONS_FAILURE,
          payload: err,
        });
        dispatch(loadingAction(false));
      });
  };
}

export function mentorGetFormAction() {
  return (dispatch) => {
    dispatch(loadingAction(true));
    dispatch({
      type: FETCH_MENTOR_QUESTIONS_INIT,
    });
    return FormsInstance
      .getFormMentor()
      .then((response) => {
        dispatch({
          type: FETCH_MENTOR_QUESTIONS_SUCCESS,
          payload: response,
        });
        dispatch(loadingAction(false));
      })
      .catch((err) => {
        dispatch({
          type: FETCH_MENTOR_QUESTIONS_FAILURE,
          payload: err,
        });
        dispatch(loadingAction(false));
      });
  };
}
