import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelTab from 'components/Panel/components/PanelTab';
import IOFileLoading from 'components/IOFileLoading/components';
import { canEditCaptable } from 'utils/functions/canEditCaptable';
import { useNavigate, useParams } from 'react-router-dom';
import { AmplitudeApi } from 'utils/amplitude';
import {
  PieChart, List, BookOpen, FileText, Clipboard,
} from 'lucide-react';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import { downloadCaptableExcel, downloadCaptableReport } from './routes/Securities/modules/actions';
import PopupOwnersShares from './routes/Securities/components/PopupOwnersShares';
import PopupVoteDetails from './routes/Securities/components/PopupVoteDetails';
import PanelCaptableShareholders from './PanelCaptableShareholders';
import PanelCaptableSyndicates from './PanelCaptableSyndicates';
import PanelCaptableShareClasses from './PanelCaptableShareClasses';
import './styles.scss';

const PanelCaptable = (props) => {
  const {
    literals, literalsCommon, captable, currency, date, submitOperation,
  } = props;

  const [showOwners, setShowOwners] = useState(false);
  const [showVoteDetails, setShowVoteDetails] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  let canEdit = canEditCaptable(captable);
  if (date) {
    canEdit = canEdit && captable?.operations[0]?.date === date;
  }

  const handleDownload = async (doc) => {
    setDownloading(doc);
    switch (doc) {
      case 'captable.xls':
        await downloadCaptableExcel(params.id, captable?.selected?.id, date);
        AmplitudeApi.logEvent('startup.captable.summary.click.downloadButton', {
          captable: captable?.selected?.id,
        });
        break;
      case 'captable-report.pdf':
        await downloadCaptableReport(params.id, captable?.selected?.id, date);
        AmplitudeApi.logEvent('startup.captable.summary.click.downloadReport', {
          captable: captable?.selected?.id,
        });
        break;
      default:
    }
    setDownloading(false);
  };

  const actions = useMemo(() => {
    const aux = [
      {
        text: literalsCommon.actions,
        buttons: [
          {
            icon: List,
            text: `${literalsCommon.view} ${literals.sharesNumeration.toLowerCase()}`,
            onClick: () => {
              AmplitudeApi.logEvent('startup.captable.summary.click.numeration', {
                captable: captable?.selected?.id,
              });
              setShowOwners(true);
            },
          },
          {
            icon: PieChart,
            text: `${literalsCommon.view} ${literals.voteDistribution.toLowerCase()}`,
            onClick: () => {
              setShowVoteDetails(true);
              AmplitudeApi.logEvent('startup.captable.summary.click.voteDistribution', {
                captable: captable?.selected?.id,
              });
            },
          },
          {
            icon: PieChart,
            text: `${literalsCommon.view} ${literals.sharesDistribution.toLowerCase()}`,
            onClick: () => {
              navigate(embedView(`${ROUTE_STARTUP_PATH.setCapTableSharesDistribution(params.id, captable?.selected?.id)
              }${captable?.selected?.id ? '&' : '?'}date=${date}`));
              AmplitudeApi.logEvent('startup.captable.summary.click.shareDistribution', {
                captable: captable?.selected?.id,
              });
            },
          },
          {
            icon: BookOpen,
            text: `${literalsCommon.view} ${literals.statutoryBook.toLowerCase()}`,
            onClick: () => {
              navigate(embedView(`${ROUTE_STARTUP_PATH.setCaptableStatutoryBook(params.id, captable?.selected?.id)
              }${captable?.selected?.id ? '&' : '?'}date=${date}`));
              AmplitudeApi.logEvent('startup.captable.summary.click.statutoryBook', {
                captable: captable?.selected?.id,
              });
            },
          },
          {
            icon: Clipboard,
            text: `${literalsCommon.download} ${literals.captable.toLowerCase()} (.xls)`,
            onClick: () => handleDownload('captable.xls'),
          },
          {
            icon: FileText,
            text: `${literalsCommon.download} ${literalsCommon.report.toLowerCase()} (.pdf)`,
            onClick: () => handleDownload('captable-report.pdf'),
          },
        ],
      },
    ];
    return aux;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, canEdit]);

  const tabs = [
    { key: 'shareholder', name: literals.byShareholder },
    { key: 'shareClass', name: literals.byShareClass },
    { key: 'syndicate', name: literals.bySyndicate },
    { key: 'role', name: literals.byRole },
  ];

  return (
    <Panel className='panel-summary-captable'>
      <PanelHeader title={literals.captable} actions={actions} tabs={tabs} />
      <PanelBody>
        <PanelTab key='shareholder'>
          <PanelCaptableShareholders
            literals={literals}
            literalsCommon={literalsCommon}
            captable={captable}
            currency={currency}
            date={date}
            canEdit={canEdit}
            submitOperation={submitOperation}
          />
        </PanelTab>
        <PanelTab key='shareClass'>
          <PanelCaptableShareClasses
            literals={literals}
            literalsCommon={literalsCommon}
            captable={captable}
            currency={currency}
            date={date}
            canEdit={canEdit}
            submitOperation={submitOperation}
          />
        </PanelTab>
        <PanelTab key='syndicate'>
          <PanelCaptableSyndicates
            literals={literals}
            literalsCommon={literalsCommon}
            captable={captable}
            currency={currency}
            date={date}
          />
        </PanelTab>
        <PanelTab key='role'>
          <PanelCaptableSyndicates
            type='role'
            literals={literals}
            literalsCommon={literalsCommon}
            captable={captable}
            currency={currency}
            date={date}
          />
        </PanelTab>
      </PanelBody>
      {
        showOwners && (
          <PopupOwnersShares
            literals={literals}
            literalsCommon={literalsCommon}
            date={date}
            onClose={() => setShowOwners(false)}
          />
        )
      }
      {
        showVoteDetails && (
          <PopupVoteDetails
            date={date}
            literals={literals}
            literalsCommon={literalsCommon}
            currency={currency}
            onClose={() => setShowVoteDetails(false)}
          />
        )
      }
      { downloading && <IOFileLoading mode='download' file={{ name: downloading }} /> }
    </Panel>
  );
};

PanelCaptable.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  date: PropTypes.string,
  submitOperation: PropTypes.func.isRequired,
};

PanelCaptable.defaultProps = {
  date: new Date().toISOString().split('T')[0],
};

export default PanelCaptable;
