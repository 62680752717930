import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCaptableAction } from 'routes/Captable/modules/actions';
import SharesDistribution from '../components';

const mapStateToProps = state => ({
  literalsCommon: state.i18n.literals.common,
  literals: state.i18n.literals.captable,
  startup: state.global.startup,
  currency: state.global.currency,
  captable: state.captable,
});

function mapDispatchToProps(dispatch) {
  return {
    fetchCaptable: bindActionCreators(fetchCaptableAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SharesDistribution);
