import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputText from 'components/Inputs/inputText';
import InputSelect from 'components/Inputs/inputSelect';
import InputTextArea from 'components/Inputs/inputTextArea';
import Button from 'components/Buttons/Button';
import InputEmail from 'components/Inputs/inputEmail';
import InputRichText from 'components/Inputs/inputRichText';
import useDialog from 'components/Dialog/components/useDialog';
import Collapse from 'components/Collapse/Collapse';
import { TriangleAlert } from 'lucide-react';
import { DEFAULT_LANGUAGE } from 'utils/language';
import { getTranslation } from 'utils/functions';
import { sendNotificationsSummary } from '../modules/actions';

const FormEmails = (props) => {
  const {
    literals,
    emails,
    langs,
    onSubmit,
  } = props;

  const [data, setData] = useState(emails);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);
  const { dialog } = useDialog();

  const submitData = (event) => {
    event.preventDefault();
    setLoading(true);
    const ndata = { ...data };
    onSubmit({ emails: ndata });
  };

  const submitSummary = async (event) => {
    event.preventDefault();
    setLoading(true);
    const response = await sendNotificationsSummary();
    await dialog({
      text: `${Object.keys(response).length} sent`,
      type: 'success',
    });
    setLoading(false);
  };

  const setAttr = (value, event) => {
    const field = event.target.name.split('.');
    const updateData = { ...data };

    if (field.length > 2) {
      updateData[field[0]][field[1]][field[2]][field[3]] = value;
    } else if (field.length > 1) {
      if (!updateData[field[0]]) {
        updateData[field[0]] = {};
      }
      updateData[field[0]][field[1]] = value;
    } else {
      updateData[field[0]] = value;
    }

    setData(updateData);
  };

  const checkEmailEmpty = (key) => {
    const aux1 = Object.values(data.templates[key].body);
    const aux2 = Object.values(data.templates[key].subject);
    let empty = false;
    aux1.forEach((element) => {
      if (!element || element === '') {
        empty = true;
      }
    });
    aux2.forEach((element) => {
      if (!element || element === '') {
        empty = true;
      }
    });
    return empty ? TriangleAlert : null;
  };

  const changeContent = (value, key, lang) => {
    const updateData = { ...data };
    updateData.templates[key].body[lang] = value;
    setData(updateData);
  };

  const mergetagsTemplate = {
    inviteToStartupExist: ['FIRSTNAME', 'LASTNAME', 'EMAIL', 'STARTUP_NAME', 'FROM_FIRSTNAME', 'FROM_LASTNAME'],
    inviteToStartupNew: ['TOKEN', 'FIRSTNAME', 'LASTNAME', 'EMAIL', 'STARTUP_NAME', 'FROM_FIRSTNAME', 'FROM_LASTNAME'],
    mentoringAccepted: ['FIRSTNAME', 'LASTNAME', 'MENTOR_NAME', 'STARTUP_NAME', 'FROM_NAME', 'FROM_FIRSTNAME', 'FROM_LASTNAME'],
    mentoringDocument: ['DOCUMENT_NAME', 'FIRSTNAME', 'LASTNAME', 'MENTOR_NAME', 'STARTUP_NAME', 'FROM_NAME', 'FROM_FIRSTNAME', 'FROM_LASTNAME'],
    mentoringFinished: ['FIRSTNAME', 'LASTNAME', 'MENTOR_NAME', 'STARTUP_NAME', 'FROM_NAME', 'FROM_FIRSTNAME', 'FROM_LASTNAME'],
    mentoringInviteMentor: ['FIRSTNAME', 'LASTNAME', 'MENTOR_NAME', 'STARTUP_NAME', 'FROM_FIRSTNAME', 'FROM_LASTNAME', 'MESSAGE'],
    mentoringInviteStartup: ['FIRSTNAME', 'LASTNAME', 'MENTOR_NAME', 'STARTUP_NAME', 'FROM_FIRSTNAME', 'FROM_LASTNAME', 'MESSAGE'],
    mentoringMeeting: ['MEETING_TITLE', 'MEETING_DESCRIPTION', 'MEETING_DATE', 'MEETING_TIME', 'FIRSTNAME', 'LASTNAME', 'MENTOR_NAME', 'STARTUP_NAME', 'FROM_NAME', 'FROM_FIRSTNAME', 'FROM_LASTNAME'],
    mentoringMeetingDelete: ['MEETING_TITLE', 'MEETING_DESCRIPTION', 'MEETING_DATE', 'MEETING_TIME', 'FIRSTNAME', 'LASTNAME', 'MENTOR_NAME', 'STARTUP_NAME', 'FROM_NAME', 'FROM_FIRSTNAME', 'FROM_LASTNAME'],
    mentoringMeetingUpdate: ['MEETING_TITLE', 'MEETING_DESCRIPTION', 'MEETING_DATE', 'MEETING_TIME', 'MEETING_OLD_DATE', 'MEETING_OLD_TIME', 'FIRSTNAME', 'LASTNAME', 'MENTOR_NAME', 'STARTUP_NAME', 'FROM_NAME', 'FROM_FIRSTNAME', 'FROM_LASTNAME'],
    mentoringMessage: ['MESSAGE', 'FIRSTNAME', 'LASTNAME', 'MENTOR_NAME', 'STARTUP_NAME', 'FROM_NAME', 'FROM_FIRSTNAME', 'FROM_LASTNAME'],
    mentoringRejected: ['FIRSTNAME', 'LASTNAME', 'MENTOR_NAME', 'STARTUP_NAME', 'FROM_NAME', 'FROM_FIRSTNAME', 'FROM_LASTNAME', 'MESSAGE'],
    notificationsSummary: ['FIRSTNAME', 'LASTNAME', 'NOTIFICATIONS'],
    passwordChanged: ['FIRSTNAME', 'LASTNAME', 'EMAIL'],
    recoveryPassword: ['TOKEN', 'FIRSTNAME', 'LASTNAME', 'EMAIL'],
    welcomeAdmin: ['TOKEN', 'FIRSTNAME', 'LASTNAME', 'EMAIL'],
    welcomeMentor: ['TOKEN', 'FIRSTNAME', 'LASTNAME', 'EMAIL'],
    welcomeUser: ['TOKEN', 'FIRSTNAME', 'LASTNAME', 'EMAIL'],
    secondFactor: ['TOKEN', 'FIRSTNAME', 'LASTNAME', 'EMAIL'],
    fundraiseInvite: ['FROM_FIRSTNAME', 'FROM_LASTNAME', 'STARTUP_NAME', 'MESSAGE', 'FUNDRAISE', 'INVITE'],
    startupAccessRequest: ['FROM_FIRSTNAME', 'FROM_LASTNAME', 'STARTUP_NAME', 'STARTUP_ID'],
    startupAccessRequestAccepted: ['FROM_FIRSTNAME', 'FROM_LASTNAME', 'STARTUP_NAME', 'STARTUP_ID'],
    startupAccessRequestRejected: ['FROM_FIRSTNAME', 'FROM_LASTNAME', 'STARTUP_NAME', 'STARTUP_ID'],
    startupInviteAccepted: ['FROM_FIRSTNAME', 'FROM_LASTNAME', 'STARTUP_NAME', 'STARTUP_ID'],
    startupInviteRejected: ['FROM_FIRSTNAME', 'FROM_LASTNAME', 'STARTUP_NAME', 'STARTUP_ID'],
    startupInviteFounder: ['FROM_FIRSTNAME', 'FROM_LASTNAME', 'STARTUP_NAME', 'MESSAGE', 'URL'],
    newElementPermission: ['STARTUP_ID', 'STARTUP_NAME', 'TYPE', 'ID'],
    confirmInitializedCaptable: ['FROM_FIRSTNAME', 'FROM_LASTNAME', 'CAPTABLE_NAME', 'STARTUP_NAME', 'URL'],
  };

  const renderTemplates = () => {
    return Object.keys(data.templates).sort().map(key => literals.emails.templates[key] && (
      <Collapse key={key} title={literals.emails.templates[key].title} state={false} icon={checkEmailEmpty(key)}>
        <div>
          <div className='row'>
            <span className='px-3 mb-3'>{literals.emails.templates[key].description}</span>
          </div>
          <div className='row'>
            <div className='col-md-8'>
              <div className='row'>
                <div className='col-12 col-md-3 offset-md-9'>
                  <InputSelect
                    preText={literals.lang}
                    onChange={v => setLanguage(v)}
                    value={language}
                    isRequired
                    options={langs.map((elem) => {
                      return {
                        id: elem,
                        name: literals.emails.languages[elem],
                      };
                    })}
                  />
                </div>
              </div>
              <InputText
                preText={`${literals.emails.subject} (${language})`}
                value={data.templates[key].subject[language]}
                key={`templates.${key}.subject.${language}`}
                name={`templates.${key}.subject.${language}`}
                onChange={setAttr}
              />
              <InputRichText
                key={`templates.${key}.body.${language}`}
                preText={`${literals.emails.body} (${language})`}
                value={data.templates[key].body[language]}
                onChange={value => changeContent(value, key, language)}
                minHeight='150px'
              />
            </div>
            <div className='col-md-4'>
              <div className='emailMergetags columnTags'>
                {mergetagsTemplate[key].map(tag => (
                  <div key={tag} className='mb-2'>
                    <div className='font-weight-bold'>{`%${tag}%`}</div>
                    <div>{literals.emails.mergetags[tag]}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    ));
  };

  return (
    <div className='tabEmails'>
      <div className='buttons mb-4'>
        <Button text='Enviar Recordatorios' onClick={submitSummary} color='outline-primary' loading={loading} />
      </div>
      <form className='formEmails' onSubmit={submitData}>
        <div key='general' className='row'>
          <div className='col-md-6'>
            <InputText preText={literals.emails.fromName} key='from.name' name='from.name' onChange={setAttr} value={data.from.name} />
          </div>
          <div className='col-md-6'>
            <InputEmail preText={literals.emails.fromAdress} key='from.address' name='from.address' onChange={setAttr} value={data.from.address} />
          </div>
          {langs.map(lang => (
            <div key={lang} className='col-md-6'>
              <InputTextArea
                maxLength={99999}
                preText={`${literals.emails.mainTemplate} (${lang})`}
                key={`template.${lang}`}
                name={`template.${lang}`}
                onChange={setAttr}
                value={getTranslation(data.template, lang)}
                placeholder='%CONTENT%'
              />
            </div>
          ))}
          <div className='col-12 col-md-6'>
            <InputEmail preText={literals.emails.supportAddress} key='support' name='support' onChange={setAttr} value={data.support} />
          </div>
          <div className='col-12 col-md-6'>
            <InputEmail preText={literals.emails.servicesAddress} key='services' name='services' onChange={setAttr} value={data.services} />
          </div>
          <div className='col-12 col-md-6'>
            <InputEmail preText={literals.emails.webhooksAddress} key='webhooks' name='webhooks' onChange={setAttr} value={data.webhooks} />
          </div>
        </div>
        <div>
          <p className='fw-sb'>Plantillas</p>
          {renderTemplates()}
        </div>
        <div className='buttons'>
          <Button key='submit' text='Enviar' type='submit' loading={loading} />
        </div>
      </form>
    </div>
  );
};

FormEmails.propTypes = {
  literals: PropTypes.object.isRequired,
  langs: PropTypes.array.isRequired,
  emails: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FormEmails;
