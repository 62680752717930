import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel/components';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Table from 'components/Table';
import StatCard from 'components/Statistics/StatCard';
import IOFileLoading from 'components/IOFileLoading/components';
import EmptyParticipation from 'assets/svg/empty_participation.svg';
import InputDate from 'components/Inputs/inputDate';
import SkeletonBarChart from 'components/Loading/skeletonBarChart';
import InputToggle from 'components/Inputs/inputToggle';
import Loading from 'components/Loading';
import ReactECharts from 'echarts-for-react';
import { useCounter } from 'utils/customHooks';
import { useNavigate } from 'react-router-dom';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import { Download, LineChart, Sheet } from 'lucide-react';
import {
  cloneObject, formatDate, formatNumber, getColorRange,
} from 'utils/functions';
import {
  downloadInvestmentsExcel, getInvestments, getInvestmentsChart, getInvestmentsStats,
} from 'routes/MyInvestments/modules/actions';
import './styles.scss';

const MyInvestmentsPortfolio = (props) => {
  const {
    literals,
    literalsCommon,
    literalsCaptable,
    user,
    shareholders,
    currency,
    currencies,
  } = props;

  const [stats, setStats] = useState();
  const [chartData, setChartData] = useState();
  const [chartMode, setChartMode] = useState(false);
  const [date, setDate] = useState({
    current: formatDate(new Date(), { format: 'Y-m-d' }),
    input: formatDate(new Date(), { format: 'Y-m-d' }),
  });
  const timeoutRef = useRef(null);
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const tableRefreshFlag = useCounter();
  const currencySymbol = literalsCommon.currenciesSymbols[currency];
  const isEmbed = window?.location?.pathname?.startsWith('/embed/');
  const navigate = useNavigate();

  const exchangeCurrency = (value, from, exchange = currencies) => {
    return formatNumber(+value / exchange[from], '-', { symbol: currencySymbol, dec: 2 });
  };

  const loadStats = async () => {
    if (stats) {
      setStats(null);
    }

    const defaultStats = {
      capital: 0,
      value: 0,
      multiplo: 0,
    };

    const auxStats = await getInvestmentsStats(shareholders.join(), date.current);

    const newStats = {
      ...auxStats,
      exchange: cloneObject(defaultStats),
    };
    Object.keys(newStats.capital).forEach((curr) => {
      newStats.exchange.capital += newStats.capital[curr] / currencies[curr];
    });
    Object.keys(newStats.value).forEach((curr) => {
      newStats.exchange.value += newStats.value[curr] / currencies[curr];
    });
    newStats.exchange.multiplo = newStats.exchange.capital > 0
      ? newStats.exchange.value / newStats.exchange.capital
      : 0;

    setStats(newStats);
  };

  const loadChart = async () => {
    setChartData(null);
    const sh = shareholders.join() || '';
    const auxValues = await getInvestmentsChart(sh, date.current);
    const sharesGraphData = {
      date: [],
      value: [],
      capital: [],
    };

    auxValues.forEach((elem) => {
      sharesGraphData.date.push(formatDate(elem.date, 'm/Y'));
      let auxCapital = 0;
      let auxValue = 0;
      Object.keys(elem.value).forEach((curr) => {
        auxCapital += elem.capital[curr] / currencies[curr];
        auxValue += elem.value[curr] / currencies[curr];
      });
      sharesGraphData.capital.push(auxCapital);
      sharesGraphData.value.push(auxValue);
    });

    setChartData({
      color: getColorRange(2),
      tooltip: {
        trigger: 'axis',
        valueFormatter: v => formatNumber(v),
      },
      legend: {
        data: [literalsCaptable.value, literals.numberOfShares],
      },
      xAxis: {
        type: 'category',
        data: sharesGraphData?.date,
        axisLabel: { rotate: 60 },
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          name: literalsCaptable.value,
          data: sharesGraphData?.capital,
          type: 'line',
          tooltip: {
            valueFormatter: v => `${formatNumber(v)}${currencySymbol}`,
          },
        },
        {
          name: literals.numberOfShares,
          data: sharesGraphData?.value,
          type: 'line',
          tooltip: {
            valueFormatter: v => `${formatNumber(v)}${currencySymbol}`,
          },
        },
      ],
    });
  };

  useEffect(() => {
    if (currencies) {
      if (stats) {
        tableRefreshFlag.increase();
      }
      loadStats();
      loadChart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencies, shareholders, date.current]);

  const fetchStartups = async (page, size, search, _, sort) => {
    const shs = shareholders.join() || '';
    const response = await getInvestments(shs, date.current, page, size, search, sort || '-capital');

    const newInvestments = { items: [], total: response.total };
    newInvestments.items = response.items.map(startup => ({
      ...startup,
      exchange: {
        capital: exchangeCurrency(startup.capital, startup.currency, currencies),
        value: exchangeCurrency(startup.value, startup.currency, currencies),
        costShares: exchangeCurrency(startup.shares.capital, startup.currency, currencies),
        costDebts: exchangeCurrency(startup.debts.capital, startup.currency, currencies),
      },
    }));

    return newInvestments;
  };

  const handleChangeDate = (newDate) => {
    setDate(prev => ({ ...prev, input: newDate }));
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      if (newDate) {
        setDate(prev => ({ ...prev, current: newDate }));
      }
    }, 750);
  };

  const handleDownloadExcel = async () => {
    setDownloadingExcel(true);
    await downloadInvestmentsExcel(shareholders.join(), 'myInvestments.xlsx');
    setDownloadingExcel(false);
  };

  const columns = [
    {
      field: 'logo', type: 'avatar', width: 35, title: (_, { name }) => name,
    },
    { field: 'name', label: literalsCommon.name, sortable: true },
    {
      field: 'entryDate',
      label: literals.entryDate,
      preRender: v => (v ? formatDate(v) : '-'),
      width: 120,
      sortable: true,
    },
    {
      id: 'capital',
      field: 'exchange',
      label: 'Capital',
      preRender: v => v.capital,
      width: 120,
      sortable: true,
    },
    {
      id: 'value',
      field: 'exchange',
      label: 'Value',
      preRender: v => v.value,
      width: 120,
      sortable: true,
    },
    {
      id: 'ownershipNFD',
      field: 'ownership',
      label: literalsCaptable.ownership,
      preRender: v => formatNumber(v.nonFullyDiluted, '-', { symbol: '%', dec: 2 }),
      width: 85,
      sortable: true,
    },
    {
      id: 'ownershipFD',
      field: 'ownership',
      label: literalsCaptable.fullyDilutedAcronym,
      title: literalsCaptable.fullyDiluted,
      preRender: v => formatNumber(v.fullyDiluted, '-', { symbol: '%', dec: 2 }),
      width: 75,
      sortable: true,
    },
    {
      id: 'sharesVotes',
      field: 'shares',
      label: 'Votes',
      preRender: v => formatNumber(v.votes, '-', { symbol: '%', dec: 2 }),
      width: 75,
      sortable: true,
    },
    {
      id: 'sharesNumber',
      field: 'shares',
      label: 'Shares',
      preRender: v => (<span title={`${v.votes}% ${literalsCaptable.vote}`}>{formatNumber(v.number, '-')}</span>),
      width: 100,
      sortable: true,
    },
    {
      id: 'sharesCapital',
      field: 'exchange',
      label: 'C.Shares',
      preRender: v => v.costShares,
      width: 100,
      sortable: true,
    },
    {
      id: 'debtsCapital',
      field: 'exchange',
      label: 'C.Debts',
      preRender: v => v.costDebts,
      width: 100,
      sortable: true,
    },
    {
      id: 'optionsNumber',
      field: 'options',
      label: literalsCaptable.options,
      title: `${literalsCaptable.stockOption} | ${literalsCaptable.phantom}`,
      preRender: v => `${formatNumber(v.types.stockOption, '-')} | ${formatNumber(v.types.phantom, '-')}`,
      width: 100,
      sortable: true,
    },
    {
      field: 'multiplo',
      label: 'Multiplo',
      preRender: v => formatNumber(v, '-', { symbol: 'X', dec: 2 }),
      width: 75,
    },
  ];

  const renderPanel = () => {
    if (!user.shareholders?.length) {
      return (
        <div className='no-results'>
          <img src={EmptyParticipation} alt={literals.noResults} />
          <p>{literals.noResults}</p>
        </div>
      );
    }

    return (
      <>
        <div className='row'>
          <div className='offset-6 col-6 offset-md-9 offset-lg-10 col-md-3 col-lg-2'>
            <InputDate value={date.input} onChange={handleChangeDate} />
          </div>
        </div>
        <div className='row myInvestments-stats'>
          <div className='col-12 col-md-3'>
            <StatCard
              title={literals.investments}
              loading={!stats}
              value={formatNumber(stats?.startups)}
            />
          </div>
          <div className='col-12 col-md-3'>
            <StatCard
              title={literals.totalInvested}
              loading={!stats}
              value={formatNumber(stats?.exchange?.capital, 0, { symbol: currencySymbol, dec: 2 })}
            />
          </div>
          <div className='col-12 col-md-3'>
            <StatCard
              title={literals.totalValue}
              loading={!stats}
              value={formatNumber(stats?.exchange?.value, 0, { symbol: currencySymbol, dec: 2 })}
            />
          </div>
          <div className='col-12 col-md-3'>
            <StatCard
              title={literals.multiplo}
              loading={!stats}
              value={formatNumber(+stats?.exchange?.multiplo, 0, { symbol: 'X', dec: 2 })}
            />
          </div>
        </div>

        <div className='toggle-table'>
          <Sheet />
          <InputToggle
            value={chartMode}
            onChange={setChartMode}
          />
          <LineChart />
        </div>

        { !chartMode ? (
          <Table
            key='current-portfolio'
            columns={columns}
            pageSize={10}
            fetch={fetchStartups}
            onClickRow={row => (!isEmbed
              ? window.open(ROUTE_STARTUP_PATH.setMyPosition(row.id, shareholders[0]), '_blank')
              : navigate(embedView(ROUTE_STARTUP_PATH.setMyPosition(row.id, shareholders[0]))))
            }
            forceFetch={tableRefreshFlag.value}
            configColumns='portfolio'
            actions={[{
              color: 'secondary',
              text: 'Excel',
              icon: Download,
              onClick: handleDownloadExcel,
            }]}
          />
        ) : (
          <>
            {chartData
              ? <ReactECharts option={chartData} style={{ height: '500px' }} />
              : <SkeletonBarChart height='500px' />
            }
          </>
        )
        }


        { downloadingExcel && <IOFileLoading mode='download' file={{ name: 'myInvestments.xlsx' }} /> }
      </>
    );
  };

  const breadcrumb = [
    { name: literals.title },
    { name: literals.portfolio },
  ];

  return (
    <div className='myInvestments-wrapper'>
      <Panel>
        <PanelHeader breadcrumb={breadcrumb} />
        <PanelBody>
          {currencies ? renderPanel() : <Loading mode='tool' hide={false} />}
        </PanelBody>
      </Panel>
    </div>
  );
};

MyInvestmentsPortfolio.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCaptable: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  shareholders: PropTypes.array.isRequired,
  currency: PropTypes.string.isRequired,
  currencies: PropTypes.object,
};

MyInvestmentsPortfolio.defaultProps = {
  currencies: null,
};

export default MyInvestmentsPortfolio;
