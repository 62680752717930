import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import Button from 'components/Buttons/Button';
import GenericSearcher from 'components/GenericSearcher';
import { getPermissionGroupName } from 'utils/functions/getPermissionGroupName';
import { Plus } from 'lucide-react';
import { fetchScopeMembers } from './modules/actions';
import './styles.scss';

const PermissionsSearcher = ({
  literals,
  preText,
  placeholder,
  isDisabled,
  scope,
  exclude,
  type = ['users', 'groups'],
  onAdd,
  confirm = false,
}) => {
  const [value, setValue] = useState('');

  const handleSearch = async (search) => {
    const members = await fetchScopeMembers(scope, search, { exclude, type: type.join(',') });

    members.items = members.items.reduce((acc, member) => {
      const name = getPermissionGroupName(member, literals);
      if (member.type === 'group' && member.default) {
        if (!name.toLowerCase().includes(search.toLowerCase())) {
          return acc;
        }
      }
      acc.push({ ...member, name });
      return acc;
    }, []);

    members.total = members.items.length;

    return members;
  };

  const handleOnChange = (v) => {
    setValue('');
    onAdd(v);
  };

  const renderRow = (row) => {
    const email = row.type === 'group' ? literals.group : row.email || '';

    return (
      <>
        <Avatar size={25} src={row.avatar?.path} title={row.name} />
        <div className='ml-3'>
          <div>{row.name}</div>
          <div className='fc-secondary fs-sm'>{email}</div>
        </div>
      </>
    );
  };

  return (
    <div className='permissions-searcher'>
      <div className='pre_text'>{preText}</div>
      <div className='inputs'>
        <GenericSearcher
          className='input-height'
          value={value}
          onChange={v => (!confirm ? onAdd(v) : setValue(v))}
          placeholder={placeholder}
          isDisabled={isDisabled}
          renderRow={renderRow}
          fetchItems={handleSearch}
        />
        {confirm && (
          <Button
            className='btn-icon-square'
            icon={Plus}
            onClick={() => handleOnChange(value)}
            isDisabled={!value}
          />
        )}
      </div>
    </div>
  );
};

PermissionsSearcher.propTypes = {
  literals: PropTypes.object.isRequired,
  preText: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  scope: PropTypes.object.isRequired,
  exclude: PropTypes.object.isRequired,
  type: PropTypes.array,
  confirm: PropTypes.bool,
  onAdd: PropTypes.func.isRequired,
};

export default PermissionsSearcher;
