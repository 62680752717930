import React from 'react';

const PopupFormShareClassParticipation = () => {
  return (
    <>
      <div className='col-md-9' />
    </>
  );
};

PopupFormShareClassParticipation.propTypes = {
};

export default PopupFormShareClassParticipation;
