import StartupsCaptablesInstance from 'modules/startupsCaptables/repository';
import { apiDownloadFile } from 'modules/actions';
import { API_CAPTABLE_REPORT } from 'modules/apiEndpoints';

export function downloadCaptableReport(id, captable, date, name = false) {
  return apiDownloadFile(API_CAPTABLE_REPORT(id, captable, date), `${name || 'captable_report'}.pdf`);
}

export function getCaptableSummaryTable(id, date = null, opts) {
  return StartupsCaptablesInstance.getStartupCaptableSummaryTable(id, date, opts);
}
