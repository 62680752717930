import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Loading = ({ hide = true, mode = 'container' }) => (
  <div className={`app-loader ${hide ? 'hidden' : `${mode}--loading`}`}>
    <div className='loading' />
  </div>
);

Loading.propTypes = {
  mode: PropTypes.string,
  hide: PropTypes.bool,
};

export default Loading;
