import StartupsFundraisesInstance from 'modules/startupsFundraises/repository';
import AdminInstance from 'modules/admin/repository';

export const getFundraises = (page, size, search, filters, sort) => {
  return AdminInstance.getAdminFundraises({
    page, size, search, filters, sort,
  });
};

export const deleteFundraise = (startup, fundraise) => {
  return StartupsFundraisesInstance.deleteStartupFundraise(startup, fundraise);
};
