import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const StartupsFundraisesInstance = ApiFetch();

export const URLS = {
  FUNDRAISE: 'fundraise',
  FUNDRAISE_SINGLE: 'fundraise/:fundraise',
  FUNDRAISE_INVESTMENT: 'fundraise/:fundraise/investment',
  FUNDRAISE_INVESTMENT_SINGLE: 'fundraise/:fundraise/investment/:investment',
  FUNDRAISE_INVITE: 'fundraise/:fundraise/invite',
  FUNDRAISE_INVITE_SINGLE: 'fundraise/:fundraise/invite/:invite',
  STARTUP_FUNDRAISE: 'startups/:id/fundraise',
  STARTUP_FUNDRAISE_SINGLE: 'startups/:id/fundraise/:fundraise',
  INVESTOR_ONBOARDING: 'fundraise/investor',
};

async function getFundraises(params = {}) {
  const path = preparePath(URLS.FUNDRAISE, {}, params);
  return StartupsFundraisesInstance.get(path);
}

async function getFundraise(fundraise, params = {}) {
  const path = preparePath(URLS.FUNDRAISE_SINGLE, { fundraise }, params);
  return StartupsFundraisesInstance.get(path);
}

async function getFundraiseInvestments(fundraise, params = {}) {
  const path = preparePath(URLS.FUNDRAISE_INVESTMENT, { fundraise }, params);
  return StartupsFundraisesInstance.get(path);
}

async function getFundraiseInvestment(fundraise, investment) {
  const path = preparePath(URLS.FUNDRAISE_INVESTMENT_SINGLE, { fundraise, investment });
  return StartupsFundraisesInstance.get(path);
}

async function getFundraiseInvites(fundraise, params = {}) {
  const path = preparePath(URLS.FUNDRAISE_INVITE, { fundraise }, params);
  return StartupsFundraisesInstance.get(path);
}

async function postFundraiseInvestment(fundraise, data) {
  const path = preparePath(URLS.FUNDRAISE_INVESTMENT, { fundraise });
  return StartupsFundraisesInstance.post(path, data);
}

async function putFundraiseInvestment(fundraise, investment, data) {
  const path = preparePath(URLS.FUNDRAISE_INVESTMENT_SINGLE, { fundraise, investment });
  return StartupsFundraisesInstance.put(path, data);
}

async function postFundraiseInvite(fundraise, data) {
  const path = preparePath(URLS.FUNDRAISE_INVITE, { fundraise });
  return StartupsFundraisesInstance.post(path, data);
}

async function deleteFundraiseInvestment(fundraise, investment) {
  const path = preparePath(URLS.FUNDRAISE_INVESTMENT_SINGLE, { fundraise, investment });
  return StartupsFundraisesInstance.delete(path);
}

async function deleteFundraiseInvite(fundraise, invite) {
  const path = preparePath(URLS.FUNDRAISE_INVITE_SINGLE, { fundraise, invite });
  return StartupsFundraisesInstance.delete(path);
}

async function getStartupFundraises(id, params = {}) {
  const path = preparePath(URLS.STARTUP_FUNDRAISE, { id }, params);
  return StartupsFundraisesInstance.get(path);
}

async function getStartupFundraise(id, fundraise) {
  const path = preparePath(URLS.STARTUP_FUNDRAISE_SINGLE, { id, fundraise });
  return StartupsFundraisesInstance.get(path);
}

async function postStartupFundraise(id, data) {
  const path = preparePath(URLS.STARTUP_FUNDRAISE, { id });
  return StartupsFundraisesInstance.post(path, data);
}

async function putStartupFundraise(id, fundraise, data) {
  const path = preparePath(URLS.STARTUP_FUNDRAISE_SINGLE, { id, fundraise });
  return StartupsFundraisesInstance.put(path, data);
}

async function deleteStartupFundraise(id, fundraise) {
  const path = preparePath(URLS.STARTUP_FUNDRAISE_SINGLE, { id, fundraise });
  return StartupsFundraisesInstance.delete(path);
}

async function saveInvestorOnboarding(data) {
  return StartupsFundraisesInstance.put(URLS.INVESTOR_ONBOARDING, data);
}

export default {
  getFundraises,
  getFundraise,
  getFundraiseInvestments,
  getFundraiseInvestment,
  getFundraiseInvites,
  postFundraiseInvestment,
  putFundraiseInvestment,
  postFundraiseInvite,
  deleteFundraiseInvestment,
  deleteFundraiseInvite,
  getStartupFundraises,
  getStartupFundraise,
  postStartupFundraise,
  putStartupFundraise,
  deleteStartupFundraise,
  saveInvestorOnboarding,
};
