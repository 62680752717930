import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import Table from 'components/Table';
import { useCounter } from 'utils/customHooks';
import { getTranslation } from 'utils/functions';
import { fetchKpis } from 'routes/Performance/modules/actions';
import { Plus } from 'lucide-react';
import PopupAddKpis from './PopupAddKpis';

const PerformanceAddTabKpisStartup = (props) => {
  const {
    literals,
    literalsCommon,
    match,
    currency,
    lang,
    performance,
    postKpisRelations,
    startup,
  } = props;

  const tableRefreshFlag = useCounter(0);
  const [showRelatePopup, setShowRelatePopup] = useState(false);

  const columnsKpis = [
    {
      type: 'html',
      field: 'name',
      label: literalsCommon.name,
      preRender: (name, { description }) => (
        `<h6>${getTranslation(name, lang)}</h6><p class="fc-secondary fs-small mb-0">${getTranslation(description, lang)}</p>`
      ),
      grow: 2,
      minWidth: 250,
    },
    {
      field: 'category',
      label: literals.category,
      preRender: v => literals[v],
      width: 150,
    },
    {
      field:
      'periodicity',
      label: literals.periodicity,
      preRender: v => literals[v],
      width: 125,
    },
    {
      field: 'unit',
      label: literals.unit,
      preRender: v => literals[v],
      width: 125,
    },
    {
      field: 'min',
      label: literals.minMax,
      preRender: (min, row) => (`${row.min ?? '-'} | ${row.max ?? '-'}`),
      width: 125,
    },
    {
      field: 'id',
      label: literalsCommon.add,
      preRender: (id, row) => {
        const exists = performance?.kpisRelated.includes(id);
        return (
          <Button
            icon={Plus}
            color={exists ? 'outline-primary' : 'primary'}
            text={exists ? literalsCommon.added : literalsCommon.add}
            onClick={() => setShowRelatePopup(row)}
          />
        );
      },
      width: 150,
    },
  ];

  const fecthKpisTable = useCallback((page, size, search) => {
    return fetchKpis(page, size, search, {}, 'startup', match.params.id);
  }, [match]);

  return (
    <>
      <Table
        key='tableKpis'
        columns={columnsKpis}
        pageSize={10}
        fetch={fecthKpisTable}
        forceFetch={tableRefreshFlag.value}
      />

      {
        showRelatePopup && (
          <PopupAddKpis
            currency={currency}
            literals={literals}
            literalsCommon={literalsCommon}
            lang={lang}
            match={match}
            kpis={[showRelatePopup]}
            onClose={() => setShowRelatePopup(false)}
            postKpisRelations={postKpisRelations}
            startup={startup}
          />
        )
      }
    </>
  );
};

PerformanceAddTabKpisStartup.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  performance: PropTypes.object.isRequired,
  postKpisRelations: PropTypes.func.isRequired,
  startup: PropTypes.object.isRequired,
};

export default PerformanceAddTabKpisStartup;
