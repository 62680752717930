import React from 'react';
import CurrenciesSelect from 'components/CurrencySelect';
import ShareholdersSelect from 'components/ShareholdersSelect';

const NavPosition = () => {
  return (
    <>
      <div className='embed-nav-item'>
        <ShareholdersSelect />
      </div>
      <div className='embed-nav-item'>
        <CurrenciesSelect mode='position' />
      </div>
    </>
  );
};

NavPosition.propTypes = {
};

export default NavPosition;
