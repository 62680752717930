import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import { useContainerDimensions } from 'utils/customHooks';
import './styles.scss';

const SkeletonBarChart = ({ className = '', height = '300px' }) => {
  const divRef = useRef(null);
  const { width } = useContainerDimensions(divRef);

  const sizeClass = useMemo(() => {
    if (width > 540) {
      return '';
    }
    if (width > 346) {
      return 'skeletonBarChart-md';
    }
    return 'skeletonBarChart-sm';
  }, [width]);

  return (
    <div ref={divRef} className={`skeletonBarChart ${sizeClass} ${className}`} style={{ height }}>
      <Skeleton animation='wave' className='chart1' width='30px' height='67%' />
      <Skeleton animation='wave' className='chart2' width='30px' height='30%' />
      <Skeleton animation='wave' className='chart3' width='30px' height='104%' />
      <Skeleton animation='wave' className='chart4' width='30px' height='79%' />
      <Skeleton animation='wave' className='chart5' width='30px' height='43%' />
      <Skeleton animation='wave' className='chart6' width='30px' height='128%' />
      <Skeleton animation='wave' className='chart7' width='30px' height='55%' />
      <Skeleton animation='wave' className='chart8' width='30px' height='30%' />
      <Skeleton animation='wave' className='chart9' width='30px' height='92%' />
      <Skeleton animation='wave' className='chart10' width='30px' height='55%' />
      <Skeleton animation='wave' className='chart11' width='30px' height='67%' />
    </div>
  );
};

SkeletonBarChart.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
};

export default SkeletonBarChart;
