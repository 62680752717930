import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Button from 'components/Buttons/Button';
import InputMonth from 'components/Inputs/inputMonth';
import InputToggle from 'components/Inputs/inputToggle';
import OutputValue from 'components/Inputs/outputValue';
import { Calendar } from 'lucide-react';
import OutputErrors from 'components/Inputs/outputErrors';

const InputMonthRange = ({
  literals, literalsCommon, value, onChange,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [enableCompare, setEnableCompare] = useState(false);
  const [selectedDates, setSelectedDates] = useState(value);
  const [compareDates, setCompareDates] = useState(value.comparator);
  const [errors, setErrors] = useState(null);

  const formatOutputValue = ({ startDate, endDate }) => {
    if (!startDate || !endDate) {
      return '';
    }

    const [startYear, startMonth] = startDate.split('-');
    const [endYear, endMonth] = endDate.split('-');
    const startMonthName = literalsCommon.months[+startMonth] || '';
    const endMonthName = literalsCommon.months[+endMonth] || '';

    return `${startMonthName} ${startYear} - ${endMonthName} ${endYear}`;
  };

  const handleToogleCompare = (enable) => {
    setEnableCompare(enable);
    setCompareDates(enable ? selectedDates : { startDate: null, endDate: null });
  };

  const handleOnClose = () => {
    const { startDate, endDate, comparator } = value;
    setSelectedDates({ startDate, endDate });
    setCompareDates(comparator);
    setShowPopup(false);
  };

  function getMonthDifference(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const yearDiff = end.getFullYear() - start.getFullYear();
    const monthDiff = end.getMonth() - start.getMonth();

    return yearDiff * 12 + monthDiff;
  }

  const saveDateRanges = () => {
    const { startDate, endDate } = selectedDates;
    if (new Date(startDate) > new Date(endDate) || (enableCompare && new Date(compareDates.startDate) > new Date(compareDates.endDate))) {
      setErrors(literals.lessDateErr);
    } else if (enableCompare && getMonthDifference(startDate, endDate) !== getMonthDifference(compareDates.startDate, compareDates.endDate)) {
      setErrors(literals.diffRangeErr);
    } else {
      onChange({ startDate, endDate, comparator: compareDates });
      setShowPopup(false);
      setErrors(null);
    }
  };

  const renderCalendar = (comparator = false) => {
    const dates = comparator ? compareDates : selectedDates;
    const setDates = comparator ? setCompareDates : setSelectedDates;
    return (
      <div className='row'>
        <InputMonth
          className='col-12 col-md-6'
          value={dates.startDate}
          onChange={v => setDates(prev => ({ ...prev, startDate: v }))}
        />
        <InputMonth
          className='col-12 col-md-6'
          min={dates.startDate}
          value={dates.endDate}
          onChange={v => setDates(prev => ({ ...prev, endDate: v }))}
        />
      </div>
    );
  };

  return (
    <div className='w-100'>
      <OutputValue
        className='m-0'
        onClick={() => setShowPopup(true)}
        value={(
          <div className='d-flex justify-content-between align-items-center'>
            <div>
              <div>{formatOutputValue(selectedDates)}</div>
              {enableCompare && <div>{formatOutputValue(compareDates)}</div>}
            </div>
            <Calendar size={16} />
          </div>
        )}
      />

      {showPopup && (
        <Popup title='' onClose={handleOnClose}>
          {renderCalendar()}
          <InputToggle
            className='mb-0'
            preText='Comparador'
            value={enableCompare}
            onChange={handleToogleCompare}
          />
          {enableCompare && renderCalendar(true)}
          <div className='buttons'>
            <Button
              text={literalsCommon.save}
              onClick={saveDateRanges}
              disabled={!selectedDates.startDate || !selectedDates.endDate || (enableCompare && (!compareDates.startDate || !compareDates.endDate))}
            />
          </div>
          <OutputErrors className='m-0 mt-3' errors={errors} />
        </Popup>
      )}
    </div>
  );
};

InputMonthRange.propTypes = {
  value: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    comparator: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
};

export default InputMonthRange;
