import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import ShareholdersTab from './ShareholdersTab';
import { getSyndicateShareHolders } from '../modules/actions';

const PopupInfoSyndicates = (props) => {
  const {
    literals,
    literalsCommon,
    literalsShareholder,
    syndicate,
    match,
    startup,
    lang,
    onClose,
  } = props;

  const getShareholders = (page, size, search, filters, sort) => {
    return getSyndicateShareHolders(match.params.id, syndicate.id, page, size, { search, filters, sort: sort || '+firstname' });
  };

  return (
    <Popup title={`${literals.infoSyndicate} ${syndicate.name}`} onClose={onClose} size='large'>
      <div className='box syndicate-name'>
        <h6 className='fw-b'>{literalsCommon.name}</h6>
        <p className='mb-4'>{syndicate.name}</p>
        <h6 className='fw-b'>{literalsCommon.description}</h6>
        <span dangerouslySetInnerHTML={{ __html: syndicate.description }} />
      </div>

      <ShareholdersTab
        literals={literals}
        literalsCommon={literalsCommon}
        literalsShareholder={literalsShareholder}
        startup={startup}
        match={match}
        lang={lang}
        getShareholdersProp={getShareholders}
      />
    </Popup>
  );
};

PopupInfoSyndicates.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  literalsShareholder: PropTypes.object.isRequired,
  syndicate: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupInfoSyndicates;
