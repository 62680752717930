import { connect } from 'react-redux';
import FundraiseComponent from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.fundraise,
  literalsPerformance: state.i18n.literals.performance,
  literalsCommon: state.i18n.literals.common,
  lang: state.i18n.language,
  user: state.session.user,
});

export default connect(mapStateToProps)(FundraiseComponent);
