import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
// import { bindActionCreators } from 'redux';
import LeftMenuComponents from '../components';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.leftMenu,
    literalsCommon: state.i18n.literals.common,
    user: state.session.user,
    platform: state.global.platform,
    startup: state.global.startup,
  };
}


export default withRouter(connect(mapStateToProps, {})(LeftMenuComponents));
