import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Button from 'components/Buttons/Button';
import InputText from 'components/Inputs/inputText';
import InputSelect from 'components/Inputs/inputSelect';
import InputAvatar from 'components/Inputs/inputAvatar';
import InputToggle from 'components/Inputs/inputToggle';
import OutputErrors from 'components/Inputs/outputErrors';
import InputEmail from 'components/Inputs/inputEmail';
import OutputValue from 'components/Inputs/outputValue';
import Separator from 'components/Separator';
import useDialog from 'components/Dialog/components/useDialog';
import InputPermission from 'components/Inputs/InputPermission';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import { bindActionCreators } from 'redux';
import { User, UserPlus, Trash } from 'lucide-react';
import { postShareholderAction, putShareholderAction } from 'routes/Captable/modules/actions';
import { fetchStartupUsers } from 'routes/StartupUsers/modules/actions';
import { Skeleton } from '@mui/material';
import { initPermission } from 'utils/functions/initPermissions';
import { PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import { SHAREHOLDER_ROLES } from 'constants/captable';
import { TOOL_CAP_TABLE, TOOL_USERS } from 'constants/tools';
import { getSyndicates } from '../modules/actions';
import BindShareholderToUser from './BindShareholderToUser';
import './styles.scss';

const countries = [
  'AFG', 'ALA', 'ALB', 'DZA', 'ASM', 'AND', 'AGO', 'AIA', 'ATA', 'ATG', 'ARG', 'ARM', 'ABW', 'AUS', 'AUT', 'AZE', 'BHS', 'BHR', 'BGD', 'BRB',
  'BLR', 'BEL', 'BLZ', 'BEN', 'BMU', 'BTN', 'BOL', 'BES', 'BIH', 'BWA', 'BVT', 'BRA', 'IOT', 'BRN', 'BGR', 'BFA', 'BDI', 'KHM', 'CMR', 'CAN',
  'CPV', 'CYM', 'CAF', 'TCD', 'CHL', 'CHN', 'CXR', 'CCK', 'COL', 'COM', 'COG', 'COD', 'COK', 'CRI', 'CIV', 'HRV', 'CUB', 'CUW', 'CYP', 'CZE',
  'DNK', 'DJI', 'DMA', 'DOM', 'ECU', 'EGY', 'SLV', 'GNQ', 'ERI', 'EST', 'ETH', 'FLK', 'FRO', 'FJI', 'FIN', 'FRA', 'GUF', 'PYF', 'ATF', 'GAB',
  'GMB', 'GEO', 'DEU', 'GHA', 'GIB', 'GRC', 'GRL', 'GRD', 'GLP', 'GUM', 'GTM', 'GGY', 'GIN', 'GNB', 'GUY', 'HTI', 'HMD', 'VAT', 'HND', 'HKG',
  'HUN', 'ISL', 'IND', 'IDN', 'IRN', 'IRQ', 'IRL', 'IMN', 'ISR', 'ITA', 'JAM', 'JPN', 'JEY', 'JOR', 'KAZ', 'KEN', 'KIR', 'PRK', 'KOR', 'KWT',
  'KGZ', 'LAO', 'LVA', 'LBN', 'LSO', 'LBR', 'LBY', 'LIE', 'LTU', 'LUX', 'MAC', 'MKD', 'MDG', 'MWI', 'MYS', 'MDV', 'MLI', 'MLT', 'MHL', 'MTQ',
  'MRT', 'MUS', 'MYT', 'MEX', 'FSM', 'MAR', 'MDA', 'MCO', 'MNG', 'MNE', 'MSR', 'MOZ', 'MMR', 'NAM', 'NRU', 'NPL', 'NLD', 'NCL', 'NZL', 'NIC',
  'NER', 'NGA', 'NIU', 'NFK', 'MNP', 'NOR', 'OMN', 'PAK', 'PLW', 'PSE', 'PAN', 'PNG', 'PRY', 'PER', 'PHL', 'PCN', 'POL', 'PRT', 'PRI', 'QAT',
  'REU', 'ROU', 'RUS', 'RWA', 'BLM', 'SHN', 'KNA', 'LCA', 'MAF', 'SPM', 'VCT', 'WSM', 'SMR', 'STP', 'SAU', 'SEN', 'SRB', 'SYC', 'SLE', 'SGP',
  'SXM', 'SVK', 'SVN', 'SLB', 'SOM', 'ZAF', 'SGS', 'SSD', 'ESP', 'LKA', 'SDN', 'SUR', 'SJM', 'SWZ', 'SWE', 'CHE', 'SYR', 'TWN', 'TJK', 'TZA',
  'THA', 'TLS', 'TGO', 'TKL', 'TON', 'TTO', 'TUN', 'TUR', 'TKM', 'TCA', 'TUV', 'UGA', 'UKR', 'ARE', 'GBR', 'USA', 'UMI', 'URY', 'UZB', 'VUT',
  'VEN', 'VNM', 'VGB', 'VIR', 'WLF', 'ESH', 'YEM', 'ZMB', 'ZWE'];

const PopupFormShareholder = (props) => {
  const {
    literals,
    literalsCountries,
    shareholder,
    startup,
    onClose,
    onSubmit,
    literalsCommon,
    match,
    user,
    postShareholder,
    putShareholder,
  } = props;

  const [data, setData] = useState({ type: 'individual', ...shareholder, contactInfo: { ...shareholder.contactInfo } });
  const [permission, setPermission] = useState({});
  const [usersPermission, setUsersPermission] = useState({});
  const [savingForm, setSavingForm] = useState(false);
  const [inviteUser, setInviteUser] = useState({ inviteUser: !!shareholder?.users?.length, selfShareholder: shareholder?.users?.includes(user.id) });
  const [errors, setErrors] = useState({});
  const [newUsers, setNewUsers] = useState(shareholder?.users || []);
  const [syndicates, setSyndicates] = useState([]);
  const [bindUserPopup, setBindUserPopup] = useState(false);
  const { dialog } = useDialog();
  const { canEdit } = permission;
  const canInvite = canEdit && permission.toolLevel === 3 && usersPermission.toolLevel === 3;

  const getBindedUsers = async () => {
    if (shareholder.id) {
      const auxBindedUsers = await fetchStartupUsers(match.params.id, {
        page: 0,
        size: 10,
        filters: { shareholders: [shareholder.id] },
      });
      setNewUsers(auxBindedUsers.items.map(aux => ({
        id: aux.id, firstname: aux.firstname, lastname: aux.lastname, email: aux.email, type: 'existing',
      })));
    }
  };

  const fetchSyndicates = async () => {
    const auxSindicates = await getSyndicates(match.params.id, 0, 0);
    setSyndicates([{ id: null, name: literals.noSyndicate }, ...auxSindicates.items]);
  };

  useEffect(() => {
    fetchSyndicates();
    getBindedUsers();
    setPermission(initPermission(data?.id ? data : null, PERMISSION_SCOPES.STARTUP, startup, TOOL_CAP_TABLE));
    setUsersPermission(initPermission(null, PERMISSION_SCOPES.STARTUP, startup, TOOL_USERS));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const typeOptions = [{ id: 'individual', name: literals.individual }, { id: 'organization', name: literals.organization }];
  const roleTypes = [
    { id: SHAREHOLDER_ROLES.ROLE_ADVISOR, name: literals.advisor },
    { id: SHAREHOLDER_ROLES.ROLE_DIRECTOR, name: literals.director },
    { id: SHAREHOLDER_ROLES.ROLE_EMPLOYEE, name: literals.employee },
    { id: SHAREHOLDER_ROLES.ROLE_FOUNDER, name: literals.founder },
    { id: SHAREHOLDER_ROLES.ROLE_INVESTOR, name: literals.investor },
    { id: SHAREHOLDER_ROLES.ROLE_SECRETARY, name: literals.secretary },
    { id: SHAREHOLDER_ROLES.ROLE_BANK, name: literals.bank },
    { id: SHAREHOLDER_ROLES.ROLE_CORPORATE, name: literals.corporate },
    { id: SHAREHOLDER_ROLES.ROLE_BOARD_MEMBER, name: literals.boardMember },
    { id: SHAREHOLDER_ROLES.ROLE_ANGEL_INVESTOR, name: literals.angelInvestor },
    { id: SHAREHOLDER_ROLES.ROLE_PRIVATE_EQUITY, name: literals.privateEquity },
    { id: SHAREHOLDER_ROLES.ROLE_VENTURE_CAPITAL, name: literals.ventureCapital },
    { id: SHAREHOLDER_ROLES.ROLE_FRIENDS_AND_FAMILY, name: literals.friendsAndFamily },
    { id: SHAREHOLDER_ROLES.ROLE_CORPORATE_VENTURE_CAPITAL, name: literals.corporateVentureCapital },
    { id: SHAREHOLDER_ROLES.ROLE_OTHER, name: literals.other },
  ];

  const submit = async (event) => {
    event.preventDefault();
    if (data.type === '') {
      data.type = 'individual';
    }

    const emptyData = canInvite ? newUsers.some((newUser) => {
      return data.type === 'individual'
        ? !newUser.firstname || !newUser.lastname || !newUser.email
        : !newUser.firstname || !newUser.email;
    }) : false;

    if (inviteUser.inviteUser && emptyData) {
      await dialog({ type: 'error', text: literals.emptyFields });
    } else {
      setSavingForm(true);
      try {
        const opts = {
          ...data,
          avatar: data?.avatar?.id,
          users: canInvite ? newUsers : null,
          permission,
          selfShareholder: inviteUser.selfShareholder,
        };

        const response = data.id
          ? await putShareholder(match.params.id, opts)
          : await postShareholder(match.params.id, opts);

        if (typeof onSubmit === 'function') {
          onSubmit(response);
        }
        setSavingForm(false);
        onClose();
      } catch (exception) {
        setErrors(exception);
        setSavingForm(false);
      }
    }
  };

  const changeElement = (value, atribute) => {
    const aux = { ...data };
    if (atribute === 'phone') {
      const info = { ...aux.contactInfo };
      info[atribute] = value.replace(/[^0-9 ()+-]/g, '');
      aux.contactInfo = { ...info };
    } else if (atribute === 'email') {
      const info = { ...aux.contactInfo };
      info[atribute] = value;
      aux.contactInfo = { ...info };
    } else {
      aux[atribute] = value;
    }
    setData({ ...aux });
  };

  const handleToogle = (v) => {
    setInviteUser(prev => ({ ...prev, inviteUser: v }));
    if (v && !newUsers.length) {
      setNewUsers([{
        id: `new.${new Date().getTime()}`, firstname: '', lastname: '', email: '', type: 'new',
      }]);
    }
  };

  return (
    <Popup title={literals.formShareholder} onClose={onClose}>
      <div className='form_shareholder'>
        <form onSubmit={submit}>
          <InputAvatar
            preText={literals.avatar}
            onChange={v => changeElement(v, 'avatar')}
            value={data.avatar}
            image={<User size={70} className='file-icon' />}
            isDisabled={!canEdit}
          />
          <div className='row'>
            <div className='col-12 col-md-4'>
              <InputSelect
                preText={literals.types}
                options={typeOptions}
                onChange={v => changeElement(v, 'type')}
                value={data.type ? data.type : 'individual'}
                isRequired
                isDisabled={!canEdit}
              />
            </div>
            <div className='col-12 col-md-4'>
              <InputSelect
                preText={literals.role}
                options={roleTypes}
                onChange={v => changeElement(v, 'role')}
                value={data.role ? data.role : ''}
                isDisabled={!canEdit}
                isRequired
              />
            </div>
            <div className='col-12 col-md-4'>
              <InputSelect
                preText={literals.syndicates}
                placeholder={literals.noSyndicate}
                value={data.syndicate}
                onChange={v => changeElement(v, 'syndicate')}
                options={syndicates}
                isDisabled={!syndicates.length || !canEdit}
              />
            </div>
          </div>
          <div className='row'>
            <div className={`${data.type === 'individual' ? 'col-md-4' : 'col-md-12'}`}>
              <InputText
                name='firstname'
                preText={literals.name}
                value={data.firstname ? data.firstname : ''}
                onChange={v => changeElement(v, 'firstname')}
                isDisabled={!canEdit}
                isRequired
              />
            </div>
            { data.type === 'individual' ? (
              <div className='col-md-8'>
                <InputText
                  name='lastname'
                  preText={literals.lastname}
                  value={data.lastname ? data.lastname : ''}
                  onChange={v => changeElement(v, 'lastname')}
                  isDisabled={!canEdit}
                  isRequired
                />
              </div>
            ) : null}
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <InputSelect
                preText={literals.country}
                value={data.country || ''}
                onChange={v => changeElement(v, 'country')}
                options={countries.map(country => (
                  { id: country, name: literalsCountries[country] }
                ))}
                isDisabled={!canEdit}
              />
            </div>
            <div className='col-md-6'>
              <InputText
                name='TAXID'
                preText={literals.taxid}
                value={data.taxId || ''}
                onChange={v => changeElement(v, 'taxId')}
                isDisabled={!canEdit}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-8'>
              <InputText
                name='Address'
                preText={literals.address}
                value={data.address || ''}
                onChange={v => changeElement(v, 'address')}
                isDisabled={!canEdit}
              />
            </div>
            <div className='col-md-4'>
              <InputText
                name='Phone'
                preText={literals.phone}
                value={data.contactInfo ? data.contactInfo.phone : ''}
                onChange={v => changeElement(v, 'phone')}
                isDisabled={!canEdit}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-8'>
              <InputPermission
                scope={{ type: PERMISSION_SCOPES.STARTUP, id: startup.id }}
                element={{ type: PERMISSION_TYPES.SHAREHOLDER, id: data.id }}
                value={permission}
                onChange={setPermission}
                isDisabled={!canEdit}
              />
            </div>
            {canInvite && (!data.id || !shareholder?.users?.length) && (
              <div className='col-12 col-md-4 d-flex'>
                <InputToggle
                  preText={literals.inviteUser}
                  onChange={handleToogle}
                  value={inviteUser.inviteUser}
                  options={[
                  ]}
                />
              </div>
            )}
            {!canInvite && (
              <div className='col-12 col-md-4 d-flex'>
                <InputToggle
                  preText={literals.linkToMyUser}
                  onChange={selfShareholder => setInviteUser(prev => ({ ...prev, selfShareholder }))}
                  value={inviteUser.selfShareholder}
                />
              </div>
            )}
          </div>
          { canInvite && (shareholder?.users?.length || inviteUser.inviteUser) ? (
            <div className='users'>
              {(inviteUser.inviteUser || shareholder?.users?.length) ? (
                <>
                  {newUsers.map((newUser, i) => (
                    <div className='new-user'>
                      <div className='row'>
                        <div className='col-md-4 col-12'>
                          <InputSelect
                            className='m-0'
                            isDisabled={!canEdit}
                            value={newUser.type}
                            onChange={type => setNewUsers(prev => prev.map((auxUser, index) => (
                              index === i ? {
                                ...auxUser, type, firstname: '', lastname: '', email: '',
                              } : auxUser)))}
                            options={[
                              { id: 'new', name: literals.newUser },
                              { id: 'existing', name: literals.existingUser },
                            ]}
                          />
                        </div>
                        {(newUser.type === 'new' || newUser.email) ? (
                          <div className='col-md-7 col-12'>
                            <InputEmail
                              className='m-0'
                              placeholder={literalsCommon.email}
                              value={newUser.email}
                              onChange={email => setNewUsers(prev => prev.map((auxUser, index) => (index === i ? { ...auxUser, email } : auxUser)))}
                              isDisabled={!canEdit || newUser.type !== 'new'}
                            />
                          </div>
                        ) : (
                          <Button
                            className='btn-icon-square'
                            icon={UserPlus}
                            onClick={() => setBindUserPopup(i)}
                          />
                        )}
                      </div>
                      {(newUser.type === 'new' || newUser.email) && (
                        <div className='row mt-3'>
                          <div className='col-12 col-md-4'>
                            <InputText
                              className='m-0'
                              name='firstname'
                              preText={literalsCommon.name}
                              placeholder={literalsCommon.name}
                              value={newUser.firstname}
                              onChange={firstname => setNewUsers(prev => prev.map((auxUser, index) => (index === i ? { ...auxUser, firstname } : auxUser)))}
                              isDisabled={!canEdit || newUser.type !== 'new'}
                            />
                          </div>
                          <div className='col-12 col-md-8'>
                            <InputText
                              className='m-0'
                              name='lastname'
                              preText={literalsCommon.lastName}
                              placeholder={literalsCommon.lastName}
                              value={newUser.lastname}
                              onChange={lastname => setNewUsers(prev => prev.map((auxUser, index) => (index === i ? { ...auxUser, lastname } : auxUser)))}
                              isDisabled={!canEdit || newUser.type !== 'new'}
                            />
                          </div>
                        </div>
                      )}
                      <Separator />
                      {canEdit && (
                        <Trash className='trash-icon' onClick={() => setNewUsers(prev => prev.filter(auxUser => auxUser.id !== newUser.id))} />
                      )}
                    </div>
                  ))}
                  {canEdit && (
                    <Button
                      text={literals.addUser}
                      icon={UserPlus}
                      color='secondary'
                      onClick={() => setNewUsers(prev => [...prev, {
                        id: `new.${new Date().getTime()}`, firstname: '', lastname: '', email: '', type: 'new',
                      }])}
                    />
                  )}
                </>
              ) : shareholder.users.map(() => (
                <div className='row'>
                  {data.type === 'organization' && (
                    <>
                      <div className='col-12 col-md-4'>
                        <OutputValue
                          preText={literalsCommon.name}
                          value={<Skeleton animation='wave' width='50%' />}
                        />
                      </div>
                      <div className='col-12 col-md-8'>
                        <OutputValue
                          preText={literalsCommon.lastName}
                          value={<Skeleton animation='wave' width='50%' />}
                        />
                      </div>
                    </>
                  )}
                  <div className='col-12'>
                    <OutputValue
                      className='m-0'
                      preText={literalsCommon.email}
                      value={<Skeleton animation='wave' width='50%' />}
                    />
                  </div>
                  <Separator />
                </div>
              ))}
            </div>
          ) : null}
          <OutputErrors literals={literals} errors={errors} />
          {canEdit && (
            <div className='buttons'>
              <Button text={literals.save} type='submit' loading={savingForm} />
            </div>
          )}
          {(bindUserPopup || bindUserPopup === 0) && (
            <Popup onClose={() => setBindUserPopup(false)}>
              <BindShareholderToUser
                literals={literals}
                literalsCommon={literalsCommon}
                match={match}
                shareholder={shareholder}
                onSubmit={(bindUser) => {
                  setNewUsers(prev => prev.map((aux, i) => (i === bindUserPopup ? bindUser : aux)));
                  setBindUserPopup(false);
                }}
              />
            </Popup>
          )}
        </form>
      </div>
    </Popup>
  );
};

const mapStateToProps = state => ({
  literals: state.i18n.literals.shareholder,
  literalsCommon: state.i18n.literals.common,
  literalsCountries: state.i18n.literals.onboarding.countries,
  startup: state.global.startup,
  user: state.session.user,
});

const mapDispatchToProps = dispatch => ({
  postShareholder: bindActionCreators(postShareholderAction, dispatch),
  putShareholder: bindActionCreators(putShareholderAction, dispatch),
});

PopupFormShareholder.propTypes = {
  onClose: PropTypes.func.isRequired,
  shareholder: PropTypes.object,
  startup: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCountries: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  postShareholder: PropTypes.func.isRequired,
  putShareholder: PropTypes.func.isRequired,
};

PopupFormShareholder.defaultProps = {
  shareholder: {},
  onSubmit: null,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PopupFormShareholder));
