
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Table from 'components/Table';
import Panel from 'components/Panel/components/';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelTab from 'components/Panel/components/PanelTab';
import PanelBody from 'components/Panel/components/PanelBody';
import Popup from 'components/Popup';
import useDialog from 'components/Dialog/components/useDialog';
import PopupProfile from 'components/PopupProfile';
import InternalForm from 'components/InternalForm';
import StartupUserForm from 'components/InviteUsers/StartupUserForm';
import IOFileLoading from 'components/IOFileLoading';
import PermissionsUser from 'components/PermissionsPopup/PermissionUser';
import {
  PersonPlus, PersonDash, Envelope, Check, ClipboardData,
} from 'react-bootstrap-icons';
import {
  Eye, Pencil, PlusCircle, Trash, Users, BadgeCheck,
  Download, X, Tag, CircleHelp, Merge,
} from 'lucide-react';
import { useCounter } from 'utils/customHooks';
import { STARTUP_ROLES, STARTUP_ROLE_OWNER, STARTUP_ROLE_SHAREHOLDER } from 'constants/roles';
import { createDefaultQuestionsStartup } from 'constants/defaultQuestionsOnboarding';
import { formatNumber, getStartupVerifiedIcon, getTranslation } from 'utils/functions';
import { STARTUP_RELATION_STATUS } from 'constants/startup';
import { useNavigate } from 'react-router-dom';
import { fetchPlatform, fetchQuestions } from '../../Platform/modules/actions';
import {
  fetchStartups, deleteStartup, fetchStartupUsers, editStartup,
  deleteUserFromStartup, downloadExcelStartups, changeInviteStatus, fetchStartupGuestsUsers, switchStartupPhantom,
} from '../modules/actions';
import FormStartups from './FormStartups';
import PopupInfoCaptable from './PopupInfoCaptable';
import PopupChoosePlan from './PopupChoosePlan';
import PopupVerifyStartup from './PopupVerifyStartup';
import PopupMergeStartups from './PopupMergeStartups';
import './styles.scss';

export const formatName = (row) => {
  return (
    <div>
      <div className='d-flex align-items-center'>
        {row.name}
        {getStartupVerifiedIcon(row.verified)}
      </div>
      <p className='d-block text-secondary font-weight-light mb-0'>
        <em>{row.description?.length > 75 ? `${row.description.substr(0, 72)}...` : row.description}</em>
      </p>
    </div>
  );
};

const Startups = (props) => {
  const {
    literals,
    literalsCommon,
    literalsOnboarding,
    literalsUsers,
    literalsCaptable,
    user,
    platform,
    currency,
    captable,
    fetchCaptable,
    lang,
  } = props;

  const [popupInfo, setPopupInfo] = useState(false);
  const [popupNew, setPopupNew] = useState(false);
  const [startupIdUsers, setStartupIdUsers] = useState(null);
  const [startupEdit, setStartupEdit] = useState(null);
  const [showCaptable, setShowCaptable] = useState(null);
  const [inviteUser, setInviteUser] = useState(false);
  const [showUserPermissions, setShowUserPermissions] = useState(false);
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const tableRefreshFlag = useCounter(0);
  const [forms, setForms] = useState([]);
  const [showChoosePlan, setShowChoosePlan] = useState(false);
  const [showVerifyStartup, setShowVerifyStartup] = useState(false);
  const [showMergeStartups, setShowMergeStartups] = useState(false);
  const [selectedStartups, setSelectedStartups] = useState([]);

  const { dialog } = useDialog();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const auxQuestions = await fetchQuestions(0, 0);
      const auxPlatform = await fetchPlatform();
      setForms(auxQuestions.items.filter(question => auxPlatform.forms.startup.find(q => q.id === question.id)));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStartups = (page, size, search, filters, sort, fields) => {
    return fetchStartups(page, size, {
      search,
      filters,
      sort,
      fields,
    });
  };

  const deleteStartupHandler = async (id) => {
    const confirm = await dialog({
      text: `¿Seguro ${id}?`,
      type: 'confirm',
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteStartup(id);
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const onSubmitUser = () => {
    if (inviteUser) {
      setInviteUser(false);
    }
    // if (showUserPermissions) {
    //   setShowUserPermissions(false);
    // }
    tableRefreshFlag.increase();
  };

  const onCreateStartup = () => {
    setPopupNew(false);
    tableRefreshFlag.increase();
  };

  const defaultFilters = createDefaultQuestionsStartup(literalsOnboarding, literalsCommon, platform.properties.defaultQuestions.startup)
    .filter(question => question.info.type !== 'text')
    .map(question => ({
      id: question.info.id,
      type: question.info.type,
      title: getTranslation(question.info.title, lang),
      options: question?.info?.answers?.map(answ => ({ id: answ.id, name: getTranslation(answ.text, lang) })),
    }));

  const getType = (type) => {
    if (type === 'date') {
      return 'dateRange';
    }
    if (type === 'number') {
      return 'numberRange';
    }
    return type;
  };

  const extraFilters = forms.map(form => ({
    id: `extra.${form.id}`,
    type: getType(form.type),
    title: getTranslation(form.title, lang),
    options: form?.options?.map(op => ({ id: op.id, name: getTranslation(op.text, lang) }))
    || form?.answers?.map(answ => ({ id: answ.id, name: getTranslation(answ.text, lang) })),
  }));

  const filters = [
    {
      id: 'verified',
      type: 'select',
      title: literals.startupVerify.verified,
      options: [
        { id: 'non', name: literalsCommon.no },
        { id: 'team', name: literals.startupVerify.team },
        { id: 'full', name: literals.startupVerify.full },
      ],
    },
    {
      id: 'plan',
      type: 'select',
      title: literals.startupPlan.choosePlan,
      options: [
        { id: 'free', name: literals.startupPlan.free },
        { id: 'basic', name: literals.startupPlan.basic },
        { id: 'premium', name: literals.startupPlan.premium },
      ],
    },
    {
      id: 'phantom',
      type: 'select',
      title: literals.phantoms,
      options: [
        { id: true, name: literalsCommon.yes },
        { id: false, name: literalsCommon.no },
      ],
    },
    ...defaultFilters,
    ...extraFilters,
  ];

  const updateInfo = async (answers) => {
    await editStartup(startupEdit.id, answers);
    await dialog({
      text: 'Perfil actualizado',
      type: 'alert',
    });
    tableRefreshFlag.increase();
    setStartupEdit(null);
  };

  const onRemoveUser = async (data) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literalsUsers.questionRemoveUser,
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteUserFromStartup(startupIdUsers, data.id);
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const handleDownloadExcel = async () => {
    setDownloadingExcel(true);
    await downloadExcelStartups('Startups.xlsx');
    setDownloadingExcel(false);
  };

  const fetchUsers = (page, size, search) => {
    return fetchStartupUsers(startupIdUsers, {
      page,
      size,
      search,
      filters: {
        type: STARTUP_ROLES.filter(rol => rol !== STARTUP_ROLE_SHAREHOLDER),
        status: STARTUP_RELATION_STATUS.ACCEPTED,
      },
    });
  };

  const fetchShareholders = (page, size, search) => {
    return fetchStartupUsers(startupIdUsers, {
      page,
      size,
      search,
      filters: { type: 'shareholder' },
    });
  };

  const fetchRequests = (page, size, search) => {
    return fetchStartupUsers(startupIdUsers, {
      page,
      size,
      search,
      filters: { status: STARTUP_RELATION_STATUS.PENDING },
    });
  };

  const fetchGuests = (page, size, search) => {
    return fetchStartupGuestsUsers(startupIdUsers, {
      page, size, search,
    });
  };

  const handleChangeStatus = async (auxUser, newStatus) => {
    const confirm = await dialog({
      type: newStatus === STARTUP_RELATION_STATUS.REJECTED ? 'confirmDanger' : 'confirm',
      text: newStatus === STARTUP_RELATION_STATUS.REJECTED ? literalsUsers.confirmReject : literalsUsers.confirmAccept,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await changeInviteStatus(startupIdUsers, auxUser.id, newStatus);
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const handleChangePhantom = async (startups, type) => {
    await dialog({
      type: 'loading',
      execute: async () => {
        await switchStartupPhantom(startups, type);
        tableRefreshFlag.increase();
      },
    });
  };

  const columns = [
    {
      field: 'logo', type: 'avatar', title: (n, row) => row.name, width: 45, label: 'Logo',
    },
    {
      field: 'name', label: 'Name', preRender: (n, row) => formatName(row), sortable: true,
    },
    {
      field: 'plan', label: 'Plan', width: '100px', preRender: plan => literals.startupPlan[plan], sortable: true,
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [{
        icon: Eye,
        text: literalsOnboarding.see,
        onClick: startup => setPopupInfo(startup),
      },
      {
        icon: Pencil,
        text: literalsOnboarding.modify,
        onClick: startup => setStartupEdit(startup),
      },
      {
        icon: Users,
        text: literalsOnboarding.users,
        onClick: startup => setStartupIdUsers(startup.id),
      },
      {
        icon: ClipboardData,
        text: 'Captable',
        onClick: startup => setShowCaptable(startup.id),
        hidden: !platform.properties.tools.capTable,
      },
      {
        icon: BadgeCheck,
        text: (id, row) => (row.verified === 'non' ? literals.startupVerify.verify : literals.startupVerify.editVerified),
        onClick: startup => setShowVerifyStartup(startup.id),
      },
      {
        icon: (id, row) => <CircleHelp fill={row.phantom ? 'currentColor' : 'none'} />,
        text: (id, row) => (row.phantom ? literals.unmarkAsPhantom : literals.markAsPhantom),
        onClick: startup => handleChangePhantom(startup.id, !startup.phantom),
      },
      {
        icon: Tag,
        text: literals.startupPlan.choosePlan,
        onClick: startup => setShowChoosePlan(startup.id),
      },
      {
        icon: Trash,
        text: literalsOnboarding.delete,
        onClick: startup => deleteStartupHandler(startup.id),
      },
      ],
    },
  ];

  if (platform.properties.tools.capTable) {
    const newColumns = [
      {
        id: 'captable.capital',
        field: 'captable',
        label: literalsCaptable.capitalRaised,
        preRender: v => (v?.capital ? formatNumber(v.capital, '-', { symbol: currency.symbol }) : '-'),
        width: 140,
        sortable: true,
      },
      {
        id: 'captable.shareholders',
        field: 'captable',
        label: 'Shareholders',
        preRender: v => formatNumber(v?.shareholders, '-'),
        width: 120,
        sortable: true,
      },
      {
        id: 'captable.valuation',
        field: 'captable',
        label: 'Valuation',
        preRender: v => (v?.valuation ? formatNumber(v.valuation, '-', { symbol: currency.symbol }) : '-'),
        width: 120,
        sortable: true,
      },
      {
        id: 'captable.pps',
        field: 'captable',
        label: 'PPS',
        preRender: v => (v?.pps ? formatNumber(v.pps, '-', { symbol: currency.symbol }) : '-'),
        width: 120,
        sortable: true,
      },
    ];

    columns.splice(3, 0, ...newColumns);
  }

  const basicUserColumns = [
    {
      field: 'avatar', type: 'avatar', title: (_, row) => row.firstname, width: 45, label: 'Avatar',
    },
    { field: 'firstname', label: literalsOnboarding.firstname, grow: 1 },
    { field: 'lastname', label: literalsOnboarding.lastname, grow: 3 },
    { field: 'email', label: 'Email', grow: 5 },
  ];

  const columnsUser = [
    ...basicUserColumns,
    { field: 'role', label: 'role', grow: 1 },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Pencil,
          text: literalsUsers.editPermissions,
          onClick: (row) => { setShowUserPermissions(row); },
          disabled: (id, row) => (user.id === row.id),
        },
        {
          icon: PersonDash,
          text: literalsUsers.removeUser,
          onClick: onRemoveUser,
          disabled: (id, row) => row.role === STARTUP_ROLE_OWNER,
          hidden: (id, row) => row.status === 'pending',
        },
        {
          icon: Check,
          text: literalsCommon.accept,
          onClick: id => handleChangeStatus(id, 'accepted'),
          hidden: (id, row) => row.status !== 'pending',
        },
        {
          icon: X,
          text: literalsCommon.reject,
          onClick: id => handleChangeStatus(id, 'rejected'),
          hidden: (id, row) => row.status !== 'pending',
        },
      ],
    },
  ];

  const pendingColumns = [...columnsUser];
  pendingColumns.splice(5, 0, {
    field: 'startedBy',
    width: 100,
    preRender: v => (
      <div className='cell-keywords m-0 p-0'>
        <div className='d-flex align-items-center m-0'>
          { v === 'startup'
            ? <PersonPlus width={12} className='mr-1' />
            : <Envelope width={12} className='mr-1' />
          }
          { v === 'startup' ? literalsUsers.invite : literalsUsers.request}
        </div>
      </div>
    ),
  });

  const formButtons = [
    { type: 'submit', onClick: updateInfo, text: 'Enviar' },
  ];

  return (
    <Tool>
      <Panel>
        <PanelHeader title={literals.startups} />
        <PanelBody>
          {
            popupNew && (
              <Popup title='Crear startups' size='large' onClose={() => setPopupNew(false)}>
                <FormStartups
                  literals={literals}
                  literalsOnboarding={literalsOnboarding}
                  onCreate={() => onCreateStartup()}
                  platform={platform}
                />
              </Popup>
            )
          }
          <Table
            key='startups'
            filters={filters}
            columns={columns}
            pageSize={10}
            fetch={getStartups}
            forceFetch={tableRefreshFlag.value}
            selectRows={selectedStartups}
            onSelectRows={setSelectedStartups}
            actions={[
              { icon: PlusCircle, text: literals.invite, onClick: () => setPopupNew(true) },
              { icon: Download, text: 'Excel', onClick: handleDownloadExcel },
            ]}
            rowSelection={[
              {
                icon: BadgeCheck,
                text: literals.startupVerify.verify,
                onClick: () => setShowVerifyStartup(selectedStartups),
              },
              {
                icon: CircleHelp,
                text: literals.markAsPhantom,
                onClick: () => handleChangePhantom(selectedStartups, true),
              },
              {
                icon: CircleHelp,
                text: literals.unmarkAsPhantom,
                onClick: () => handleChangePhantom(selectedStartups, false),
              },
              {
                icon: Merge,
                text: literals.mergeStartups,
                onClick: () => setShowMergeStartups(selectedStartups),
              },
            ]}
          />
          {
            popupInfo && (
              <PopupProfile data={popupInfo} type='startup' onClose={() => setPopupInfo(false)} />
            )
          }
          {startupIdUsers && !inviteUser && (
            <Popup
              key='users'
              title='Usuarios de la startup'
              size='xlarge'
              onClose={() => { setStartupIdUsers(null); setInviteUser(null); setShowUserPermissions(null); }}
            >
              {!showUserPermissions && (
                <>
                  <Panel>
                    <PanelHeader
                      tabs={[
                        { key: 'users', name: literalsUsers.users },
                        { key: 'shareholders', name: literalsUsers.shareholders },
                        { key: 'pendings', name: literalsUsers.pendings },
                        { key: 'guests', name: literalsUsers.guests },
                      ]}
                    />
                    <PanelBody>
                      <PanelTab key='users'>
                        <Table
                          columns={columnsUser}
                          fetch={fetchUsers}
                          forceFetch={tableRefreshFlag.value}
                          actions={[{
                            icon: PersonPlus,
                            text: literalsUsers.inviteUser,
                            onClick: () => setInviteUser(true),
                          }]}
                        />
                      </PanelTab>
                      <PanelTab key='shareholders'>
                        <Table
                          columns={columnsUser}
                          fetch={fetchShareholders}
                          forceFetch={tableRefreshFlag.value}
                          actions={[{
                            icon: PersonPlus,
                            text: literalsUsers.inviteUser,
                            onClick: () => setInviteUser(true),
                          }]}
                        />
                      </PanelTab>
                      <PanelTab key='pendings'>
                        <Table
                          columns={pendingColumns}
                          fetch={fetchRequests}
                          forceFetch={tableRefreshFlag.value}
                          actions={[{
                            icon: PersonPlus,
                            text: literalsUsers.inviteUser,
                            onClick: () => setInviteUser(true),
                          }]}
                        />
                      </PanelTab>
                      <PanelTab key='guests'>
                        <Table
                          columns={basicUserColumns}
                          fetch={fetchGuests}
                          forceFetch={tableRefreshFlag.value}
                        />
                      </PanelTab>
                    </PanelBody>
                  </Panel>
                </>
              )}
              {showUserPermissions && (
                <PermissionsUser
                  literals={literalsUsers}
                  literalsCommon={literalsCommon}
                  user={showUserPermissions}
                  scope={{ type: 'startup', id: showUserPermissions }}
                  onSubmit={onSubmitUser}
                  onClose={() => setShowUserPermissions(false)}

                />
              )}
            </Popup>
          )}
          {(inviteUser && !showUserPermissions) && (
            <StartupUserForm
              literals={literalsUsers}
              startup={startupIdUsers}
              onSubmit={onSubmitUser}
              onClose={() => { setInviteUser(null); setShowUserPermissions(null); }}
            />
          )}
          {startupEdit && (
            <Popup key='editStartup' title='Editar Perfil' onClose={() => setStartupEdit(null)}>
              <InternalForm
                type='startup'
                answers={startupEdit}
                buttons={formButtons}
                admin
              />
            </Popup>
          )}
          {showCaptable && (
            <PopupInfoCaptable
              literals={literalsCaptable}
              literalsCommon={literalsCommon}
              startup={showCaptable}
              onClose={() => {
                setShowCaptable(null);
                navigate({ search: '' }, { replace: true });
              }}
              currency={currency}
              captable={captable}
              fetchCaptable={fetchCaptable}
            />
          )}
          {
            showChoosePlan !== false && (
              <PopupChoosePlan
                literals={literals.startupPlan}
                startup={showChoosePlan}
                tableRefreshFlag={tableRefreshFlag}
                onClose={() => setShowChoosePlan(false)}
              />
            )
          }
          {
            showVerifyStartup !== false && (
              <PopupVerifyStartup
                literals={literals.startupVerify}
                startup={showVerifyStartup}
                tableRefreshFlag={tableRefreshFlag}
                onClose={() => setShowVerifyStartup(false)}
              />
            )
          }
          {
            showMergeStartups !== false && (
              <PopupMergeStartups
                literals={literals}
                literalsCommon={literalsCommon}
                startups={showMergeStartups}
                tableRefreshFlag={tableRefreshFlag}
                onClose={() => setShowMergeStartups(false)}
              />
            )
          }
          { downloadingExcel && <IOFileLoading mode='download' file={{ name: 'Startups.xlsx' }} /> }
        </PanelBody>
      </Panel>
    </Tool>
  );
};

Startups.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  literalsOnboarding: PropTypes.object.isRequired,
  literalsUsers: PropTypes.object.isRequired,
  literalsCaptable: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  fetchCaptable: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};

export default Startups;
