import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import useDialog from 'components/Dialog/components/useDialog';
import { getFullName } from 'utils/functions';
import { fetchStartupUsers } from 'routes/StartupUsers/modules/actions';
import { UserMinus, UserPlus } from 'lucide-react';
import { useCounter } from 'utils/customHooks';
import { bindUserToShareholder, unbindUserToShareholder } from '../modules/actions';
import './styles.scss';

const BindShareholderToUser = ({
  literals,
  literalsCommon,
  match,
  shareholder,
  onSubmit = null,
}) => {
  const { dialog } = useDialog();
  const [users, setUsers] = useState(shareholder?.users || []);
  const tableRefreshFlag = useCounter(0);

  const handleOnClick = async (userId) => {
    let error = false;
    const action = !users.includes(userId) ? 'bind' : 'unbind';
    await dialog({
      type: 'loading',
      execute: async () => {
        try {
          if (action === 'bind') {
            await bindUserToShareholder(match.params.id, shareholder.id, userId);
          } else {
            await unbindUserToShareholder(match.params.id, shareholder.id, userId);
          }
        } catch {
          error = true;
        }
      },
    });
    if (error) {
      await dialog({
        type: 'error',
        text: literals.bindingError,
      });
    } else if (action === 'bind') {
      setUsers(prev => [...prev, userId]);
    } else {
      setUsers(prev => prev.filter(auxUser => auxUser !== userId));
    }
    tableRefreshFlag.increase();
  };

  const columns = [
    {
      field: 'avatar',
      width: 45,
      type: 'avatar',
      label: '',
      title: (_, row) => getFullName(row),
    },
    { field: 'firstname', grow: 1, label: literalsCommon.name },
    { field: 'lastname', grow: 3, label: literalsCommon.lastname },
    { field: 'email', grow: 5, label: literalsCommon.email },
    {
      field: 'role',
      width: 100,
      label: literalsCommon.role,
    },
    {
      field: 'exists',
      preRender: (exists, row) => {
        return (
          <button
            className='table-row-button'
            onClick={() => (!onSubmit ? handleOnClick(row.id) : onSubmit({ ...row, type: 'existing' }))}
            type='button'
          >
            {exists
              ? <UserMinus title={literals.unbindUser} />
              : <UserPlus title={literals.bindUser} />}
          </button>
        );
      },
      width: 50,
    },
  ];

  const fetchUsers = async (page, size, search, filters) => {
    const response = await fetchStartupUsers(match.params.id, {
      page,
      size,
      search,
      filters,
    });

    response.items = response.items.map((user) => {
      return {
        ...user,
        exists: users.includes(user.id),
      };
    });

    return response;
  };

  return (
    <Table
      columns={columns}
      pageSize={10}
      fetch={fetchUsers}
      forceFetch={tableRefreshFlag.value}
    />
  );
};

BindShareholderToUser.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  shareholder: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
};

export default BindShareholderToUser;
