import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Popup from 'components/Popup';

const WebhookDetails = ({
  literals, literalsCommon, webhook, onClose,
}) => {
  return (
    <Popup title={literals.webhookDetails} onClose={onClose}>
      <div className='row'>
        <div className='col-12 col-md-6 mb-4'>
          <h6>{literalsCommon.type}</h6>
          <span className='fw-sb'>{literals[webhook.type]}</span>
        </div>
        <div className='col-12 col-md-6 mb-4'>
          <h6>{literalsCommon.status}</h6>
          <span className='fw-sb'>{literals[webhook.status]}</span>
        </div>
        <div className='col-12 mb-4'>
          <h6>{literals.sentTo}</h6>
          <span className='fw-sb'>{webhook.to}</span>
        </div>
        <div className='col-12 mb-4'>
          <h6>{literals.data}</h6>
          <span className='fw-sb text-break'>{JSON.stringify(webhook.data)}</span>
        </div>
        <div className='col-12 mb-4'>
          <h6>{literals.response}</h6>
          <span className='fw-sb text-break'>{JSON.stringify(webhook.response)}</span>
        </div>
      </div>
    </Popup>
  );
};

WebhookDetails.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  webhook: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default WebhookDetails;
