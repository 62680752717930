import { connect } from 'react-redux';
import Reporting from '../components';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.reporting,
    literalsCommon: state.i18n.literals.common,
    startup: state.global.startup,
    lang: state.i18n.language,
  };
}

export default connect(mapStateToProps)(Reporting);
