export const ROLE_ADMIN = 'admin';
export const ROLE_USER = 'user';
export const ROLE_MENTOR = 'mentor';
export const ROLE_INVESTOR = 'investor';

export const STARTUP_ROLE_OWNER = 'owner';
export const STARTUP_ROLE_ADMIN = 'admin';
export const STARTUP_ROLE_USER = 'user';
export const STARTUP_ROLE_SHAREHOLDER = 'shareholder';
export const STARTUP_ROLE_ESOPS = 'ESOPs';
export const STARTUP_ROLE_OTHER = 'other';
export const STARTUP_USER_STATUS_ACCEPTED = 'accepted';
export const STARTUP_USER_STATUS_REJECTED = 'rejected';
export const STARTUP_USER_STATUS_PENDING = 'pending';

export const STARTUP_ROLES = [
  STARTUP_ROLE_OWNER,
  STARTUP_ROLE_ADMIN,
  STARTUP_ROLE_USER,
  STARTUP_ROLE_SHAREHOLDER,
  STARTUP_ROLE_ESOPS,
  STARTUP_ROLE_OTHER,
];

export default {
  ROLE_ADMIN,
  ROLE_USER,
  ROLE_MENTOR,
  ROLE_INVESTOR,
  STARTUP_ROLE_OWNER,
};
