import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowDown } from 'lucide-react';
import './styles.scss';

const Collapse = ({
  title,
  state,
  children,
  preIcon = null,
  icon = null,
  className = '',
}) => {
  const [show, setShow] = useState(state);

  const handlerShowChange = () => {
    setShow(prevShow => !prevShow);
  };

  return (
    <div className={`ccollapse ${show ? 'open' : ''} ${className}`}>
      <div className='ccollapse-header' onClick={handlerShowChange}>
        {typeof title === 'string' ? (
          <h5>
            {preIcon && React.createElement(preIcon, { size: 18, className: 'pre-icon' })}
            {title}
            {icon && React.createElement(icon, { size: 18, className: 'post-icon' })}
          </h5>
        ) : title}
        <div className='arrow-right'>
          <ArrowDown size={17} className={`arrow-input-select ${show ? 'rotate' : ''}`} />
        </div>
      </div>
      <div className='ccollapse-body'>
        {children}
      </div>
    </div>
  );
};

Collapse.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  state: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  preIcon: PropTypes.any,
  icon: PropTypes.any,
  className: PropTypes.string,
};

export default Collapse;
