import StartupsFundraisesInstance from 'modules/startupsFundraises/repository';
import KpisInstance from 'modules/kpis/repository';

export const getStartupFundraise = (startup, fundraise) => {
  return StartupsFundraisesInstance.getStartupFundraise(startup, fundraise);
};

export const createStartupFundraise = (startup, data) => {
  return StartupsFundraisesInstance.postStartupFundraise(startup, data);
};

export const editStartupFundraise = (startup, fundraise, data) => {
  return StartupsFundraisesInstance.putStartupFundraise(startup, fundraise, data);
};

export const deleteStartupFundraise = (startup, fundraise) => {
  return StartupsFundraisesInstance.deleteStartupFundraise(startup, fundraise);
};

export function fetchKpiRelations(modeltype, modelId, opts) {
  return KpisInstance.getModelKpis(modeltype, modelId, opts);
}
