import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import Panel from 'components/Panel';
import PanelBody from 'components/Panel/components/PanelBody';
import Tool from 'components/Tool';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTE_STARTUP_PATH } from 'routes';
import { cleanCaptableError } from 'routes/Captable/modules';
import { useNavigate } from 'react-router-dom';
import SetUpCaptable from 'assets/svg/set_up_captable.svg';
import './styles.scss';


const CaptableUnauthorized = ({ match }) => {
  const error = useSelector(state => state.captable?.error);
  const literals = useSelector(state => ({
    ...state.i18n.literals.captable,
    common: state.i18n.literals.common,
  }));
  const startup = match.params.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!error) {
      navigate(ROUTE_STARTUP_PATH.setHome(startup));
    }
    return () => {
      if (error) {
        dispatch(cleanCaptableError());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    navigate(ROUTE_STARTUP_PATH.setHome(startup));
  };

  return (
    <Tool className='captable-unauthorized'>
      <Panel>
        <PanelBody>
          <div className='text-center py-3'>
            <h3 className='fw-b'>{literals.noCaptablePermission}</h3>
            <img src={SetUpCaptable} alt='' />
            <Button onClick={handleBack} text={literals.common.goBack} />
          </div>
        </PanelBody>
      </Panel>
    </Tool>
  );
};

CaptableUnauthorized.propTypes = {
  match: PropTypes.object.isRequired,
};

export default CaptableUnauthorized;
