import StartupsCaptablesInstance from 'modules/startupsCaptables/repository';
import { createStartupCaptableSuccess, deleteStartupCaptableSuccess, editStartupCaptableSuccess } from 'routes/Captable/modules';
import { formatErrors } from 'utils/functions';

export function postStartupCaptableAction(id, data) {
  return (dispatch) => {
    return StartupsCaptablesInstance.postStartupCaptable(id, data).then((response) => {
      dispatch(createStartupCaptableSuccess(response));
      return response;
    }).catch((error) => {
      throw formatErrors(error);
    });
  };
}

export function putStartupCaptableAction(id, captable, data) {
  return (dispatch) => {
    return StartupsCaptablesInstance.putStartupCaptable(id, captable, data).then((response) => {
      dispatch(editStartupCaptableSuccess(response));
      return response;
    }).catch((error) => {
      throw formatErrors(error);
    });
  };
}

export function markStartupCaptableAsFavoriteAction(id, captable) {
  return (dispatch) => {
    return StartupsCaptablesInstance.markStartupCaptableAsFavorite(id, captable).then((response) => {
      dispatch(editStartupCaptableSuccess(response));
      return response;
    }).catch((error) => {
      throw formatErrors(error);
    });
  };
}

export function deleteStartupCaptableAction(id, captable) {
  return (dispatch) => {
    return StartupsCaptablesInstance.deleteStartupCaptable(id, captable).then((response) => {
      dispatch(deleteStartupCaptableSuccess(response));
      return response;
    }).catch((error) => {
      throw formatErrors(error);
    });
  };
}

export function postStartupCaptable(id, data) {
  return StartupsCaptablesInstance.postStartupCaptable(id, data);
}
