
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel/components';
import Tool from 'components/Tool';
import Table from 'components/Table';
import { useCounter } from 'utils/customHooks';
import { formatErrors, startupCanEdit } from 'utils/functions';
import PopupProfile from 'components/PopupProfile';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import useDialog from 'components/Dialog/components/useDialog';
import { TOOL_MENTORINGS } from 'constants/tools';
import Loading from 'components/Loading';
import Alert from 'components/Alert';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ROUTE_MENTOR_PATH } from 'routes';
import { createMentoring, getMentoringsMentors, getMentoringsStartups } from '../../../../modules/actions';
import MentoringSearchRow from './MentoringSearchRow';

const MentoringsSearch = (props) => {
  const {
    match,
    user,
    forms,
    startup,
    literals,
    fetchFormStartup,
    fetchFormMentor,
  } = props;

  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [filters, setFilters] = useState(null);
  const tableRefreshFlag = useCounter(0);
  const location = useLocation();
  const navigate = useNavigate();
  const { dialog } = useDialog();

  const view = {
    type: location.pathname.startsWith('/startup') ? 'startup' : 'mentor',
    id: location.pathname.startsWith('/startup') ? match.params.id : user.mentor.id,
  };

  const fetchSearchMentorings = async (page, size, search, query) => {
    const params = {
      page,
      size,
      search,
      filters: query,
    };
    if (view.type === 'startup') {
      return getMentoringsMentors(view.id, params);
    }
    return getMentoringsStartups(view.id, params);
  };

  const generateTableFilters = (fields) => {
    const auxFilters = [];
    fields.forEach((field) => {
      if (field.type === 'question' && field.filter) {
        auxFilters.push({
          id: `extra.${field.info.id}`,
          type: field.info.type,
          title: field.info.title[user.lang],
          options: field.info.answers?.map(answer => ({ id: answer.id, name: answer.text[user.lang] })),
        });
      }
    });
    setFilters(auxFilters);
  };

  useEffect(() => {
    const formType = view.type === 'startup' ? 'mentor' : 'startup';
    if (forms[formType]) {
      if (!forms[formType].loaded && !forms[formType].loading) {
        switch (formType) {
          case 'mentor': fetchFormMentor(); break;
          case 'startup': fetchFormStartup(); break;
          default:
            break;
        }
      }
      if (forms[formType].loaded) {
        generateTableFilters(forms[formType].questions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);

  const handleSubmitInvite = async (id, message) => {
    const startupID = view.type === 'startup' ? view.id : id;
    const mentorID = view.type === 'startup' ? id : '';
    try {
      await createMentoring(startupID, mentorID, message);
      await dialog({
        text: literals.invitationSent,
        type: 'alert',
      });
      tableRefreshFlag.increase();
      return true;
    } catch (errors) {
      return formatErrors(errors);
    }
  };

  const componentProps = {
    view,
    lang: user.lang,
    literals,
  };

  const canEdit = view.type !== 'startup' || startupCanEdit(startup, TOOL_MENTORINGS);

  if (!canEdit) {
    return navigate(-1);
  }

  return (
    <Tool className='mentorings-wrapper'>
      <Panel>
        <PanelHeader
          title={literals.title}
          breadcrumb
        />
        <PanelBody>
          {
            view.type === 'mentor' && user.mentor?.visible === false ? (
              <Link to={ROUTE_MENTOR_PATH.EDIT_MENTOR}>
                <Alert
                  type='warning'
                  text={literals.mentorNotVisible}
                />
              </Link>
            ) : null
          }
          {
            filters === null ? (
              <Loading mode='tool' hide={false} />
            ) : (
              <Table
                filters={filters}
                mode='components'
                component={MentoringSearchRow}
                componentProps={{ ...componentProps, onInvite: handleSubmitInvite, onMoreInfo: id => setShowMoreInfo(id) }}
                pageSize={10}
                fetch={fetchSearchMentorings}
                forceFetch={tableRefreshFlag.value}
              />
            )
          }
          {
            showMoreInfo && (
              <PopupProfile
                type={view.type === 'startup' ? 'mentor' : 'startup'}
                id={showMoreInfo}
                onClose={() => setShowMoreInfo(false)}
              />
            )
          }
        </PanelBody>
      </Panel>
    </Tool>
  );
};

MentoringsSearch.propTypes = {
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  startup: PropTypes.object,
  literals: PropTypes.object.isRequired,
  forms: PropTypes.object.isRequired,
  fetchFormStartup: PropTypes.func.isRequired,
  fetchFormMentor: PropTypes.func.isRequired,
};

MentoringsSearch.defaultProps = {
  startup: null,
};

export default MentoringsSearch;
