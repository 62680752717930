import React from 'react';
import PropTypes from 'prop-types';
import {
  CircleArrowDown, Eye, Pencil, Trash,
} from 'lucide-react';
import { Skeleton } from '@mui/material';
import { formatDate, stripTags } from 'utils/functions';
import { Link } from 'react-router-dom';
import { ROUTE_STARTUP_PATH } from 'routes';
import './styles.scss';
import { PERMISSION_LEVELS } from 'constants/permissions';

const ReportingRow = ({
  loading = false,
  match,
  id,
  subject = '',
  message = '',
  draft = false,
  permission = null,
  createdAt = 0,
  onDelete,
  onDownload,
}) => {
  const handleDelete = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    onDelete(id);
  };

  const handleDownload = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    onDownload({ id, subject, createdAt });
  };

  if (loading) {
    return (
      <div key={id} className='box box-padding d-flex align-items-center box-mb'>
        <div className='flex-grow-1 p-1 pl-4'>
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='50%' />
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='100%' />
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='100%' />
        </div>
      </div>
    );
  }
  const canEdit = permission?.level >= PERMISSION_LEVELS.EDIT;
  const route = draft ? ROUTE_STARTUP_PATH.setReportingAdd(match.params.id, id) : ROUTE_STARTUP_PATH.setReportingInfo(match.params.id, id);

  return (
    <Link to={route} className='reporting-row box' key={id}>
      <div className='reporting-info'>
        <h6 className='fw-b mb-3'>{subject}</h6>
        <p className='text-justify'>
          {stripTags(message).length > 100
            ? `${stripTags(message).substring(0, 100)}...`
            : stripTags(message)}
        </p>
      </div>
      <div className='reporting-actions'>
        <b>{formatDate(createdAt, { time: true })}</b>
        <div className='buttons'>
          {draft ? (
            <>
              {canEdit ? <Pencil className='report-icon' size={22} /> : <Eye className='report-icon' size={22} />}
            </>
          ) : (
            <>
              <Eye className='report-icon' size={22} />
              <CircleArrowDown className='report-icon' onClick={ev => handleDownload(ev)} size={22} />
            </>
          )}
          {canEdit && <Trash className='report-icon' onClick={ev => handleDelete(ev)} size={22} />}
        </div>
      </div>
    </Link>
  );
};

ReportingRow.propTypes = {
  loading: PropTypes.bool,
  match: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  subject: PropTypes.string,
  message: PropTypes.string,
  permission: PropTypes.object,
  createdAt: PropTypes.number,
  onDelete: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  draft: PropTypes.bool,
};

export default ReportingRow;
