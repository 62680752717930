import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Loading from 'components/Loading';
import { setStartupPlan } from '../modules/actions';


const PopupChoosePlan = (props) => {
  const {
    literals,
    startup,
    tableRefreshFlag,
    onClose,
  } = props;

  const [loading, setLoading] = useState(false);

  const changePlanHandler = (plan) => {
    setLoading(true);
    setStartupPlan(startup, plan).then(() => {
      tableRefreshFlag.increase();
      onClose();
    });
  };

  return (
    <Popup title={literals.choosePlan} onClose={onClose} size='small'>
      {loading
        ? <Loading mode='panel' hide={false} />
        : (
          <>
            <h6 className='fw-b mb-2'>{literals.chooseStartupPlan}</h6>
            <ul className='startupPlans-list'>
              <div className='box box-padding' onClick={() => changePlanHandler('free')}>
                <h6 className='fw-b'>{literals.free}</h6>
                <span className='fs-sm'>{literals.freeDescription}</span>
              </div>
              <div className='box box-padding' onClick={() => changePlanHandler('basic')}>
                <h6 className='fw-b'>{literals.basic}</h6>
                <span className='fs-sm'>{literals.basicDescription}</span>
              </div>
              <div className='box box-padding' onClick={() => changePlanHandler('premium')}>
                <h6 className='fw-b'>{literals.premium}</h6>
                <span className='fs-sm'>{literals.premiumDescription}</span>
              </div>
            </ul>
          </>
        )
      }
    </Popup>
  );
};

PopupChoosePlan.propTypes = {
  literals: PropTypes.object.isRequired,
  startup: PropTypes.string.isRequired,
  tableRefreshFlag: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupChoosePlan;
