import { connect } from 'react-redux';
import ReportingTemplates from '../components';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.reporting,
    literalsPerformance: state.i18n.literals.performance,
    literalsCommon: state.i18n.literals.common,
    startup: state.global.startup,
    currency: state.global.currency,
    lang: state.i18n.language,
  };
}

export default connect(mapStateToProps)(ReportingTemplates);
