import StartupsCaptablesInstance from 'modules/startupsCaptables/repository';
import { apiDownloadFile } from 'modules/actions';
import { API_SHARECLASSES_EXCEL } from 'modules/apiEndpoints';

export function fetchShareClasses(id, page, size, others = {}) {
  return StartupsCaptablesInstance.getStartupShareClasses(id, { page, size, ...others });
}

export function postShareClass(id, data) {
  return StartupsCaptablesInstance.postStartupShareClass(id, data);
}

export function putShareClass(id, shareClass, data) {
  return StartupsCaptablesInstance.putStartupShareClass(id, shareClass, data);
}

export function getShareClass(id, shareclass) {
  return StartupsCaptablesInstance.getStartupShareClass(id, shareclass);
}

export function deleteShareClass(id, shareClass) {
  return StartupsCaptablesInstance.deleteStartupShareClass(id, shareClass);
}

export function fetchCaptableShares(startup, date, params) {
  return StartupsCaptablesInstance.getStartupCaptableShares(startup, date, params);
}

export function unifyShareClasses(startup, shareClass, shareClasses) {
  return StartupsCaptablesInstance.unifyShareClasses(startup, shareClass, { shareClasses });
}

export const downloadExcelShareClasses = (startup) => {
  return apiDownloadFile(API_SHARECLASSES_EXCEL(startup), 'shareClasses.xlsx');
};
