/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'components/Pagination';
import Button from 'components/Buttons/Button';
import TableRow from 'components/Table/components/TableRow';
import EmptyStreet from 'assets/svg/empty_street.svg';
import { ChevronDown, ChevronUp, Search } from 'lucide-react';
import './styles.scss';

const SimpleTable = (props) => {
  const {
    className = '',
    mode = 'rows',
    searcher = true,
    columns = [],
    component = null,
    componentProps = null,
    fetch = null,
    pageSize = 10,
    onClickRow = null,
    selectedRow = null,
  } = props;

  const [rows, setRows] = useState(null);
  const [search, setSearch] = useState('');
  const [fetching, setFetching] = useState(true);
  const [actualPage, setActualPage] = useState(0);
  const [sortedColumn, setSortedColumn] = useState({ name: '', sort: '' });

  const sortTable = async (column) => {
    const columnName = column.sortableField || column.field;
    let sort = '+';
    if (sortedColumn && sortedColumn.name === columnName) {
      if (sortedColumn.sort === '') {
        sort = '+';
      } else if (sortedColumn.sort === '+') {
        sort = '-';
      } else {
        sort = '';
      }
    }
    setSortedColumn({ name: columnName, sort });
    setFetching(true);
    const response = await fetch(actualPage, pageSize, search, {}, `${sort}${columnName}`);
    setRows(response);
    setFetching(false);
  };

  const fetchPage = async (page) => {
    setFetching(true);
    const response = await fetch(page, pageSize, search, {}, `${sortedColumn.sort}${sortedColumn.name}`);
    setRows(response);
    setFetching(false);
  };

  const applyFilters = () => {
    if (actualPage === 0) {
      fetchPage(0);
    } else {
      setActualPage(0);
    }
  };

  const inputKeyPress = (e) => {
    if (e.key === 'Enter') {
      applyFilters();
    }
  };

  const renderFilters = () => {
    if (!searcher) {
      return null;
    }

    return (
      <div className='table-top'>
        <div className='table-bar'>
          <div className='table-filters-basic'>
            {
              searcher ? (
                <>
                  <div className='input_text'>
                    <input
                      className='input'
                      type='text'
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                      onKeyPress={inputKeyPress}
                    />
                    <Button className='btn-search-mobile' icon={Search} onClick={applyFilters} />
                  </div>
                  <div className='table-filter-submit'>
                    <Button className='btn-search-desktop' icon={Search} onClick={applyFilters} />
                  </div>
                </>
              ) : null
            }
          </div>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    const newColumns = [...columns];
    const headerRows = newColumns.map((header, index) => {
      const column = header.sortableField ? { ...header } : { ...header, sortableField: '' };
      const styles = {};
      let classes = '';
      if (column.type === 'button' || column.type === 'link' || column.type === 'checkbox') {
        column.width = 36;
        classes += ' align-center';
      } else if (column.type === 'menu') {
        column.width = 24;
      }
      if (!column.width) {
        classes += ' fluid';
      }
      if (column.align) {
        classes += ` align-${column.align}`;
      }
      if (column.width) {
        styles.width = column.width;
      } else {
        styles.flex = `${column.grow ?? 1} 0 0px`;
      }
      if (column.minWidth) {
        styles.minWidth = column.minWidth;
      }

      return (
        <div
          key={`${column.field}-${index}`}
          onClick={column.sortable ? () => sortTable(column) : null}
          title={column.title ?? column.label}
          className={`table-header-cell ${classes} ${column.sortable && 'cursor-pointer'}`}
          style={styles}
        >
          {
            (column.type === 'button' && column.button?.icon)
              ? (
                React.isValidElement(column.button.icon) ? column.button.icon : <column.button.icon />
              ) : (
                <>
                  {
                    column.icon ? (
                      React.isValidElement(column.icon) ? column.icon : <column.icon />
                    ) : (
                      column.label && column.label.length > 25 ? `${column.label.substring(0, 25)}...` : column.label
                    )
                  }
                </>
              )
          }
          {column.sortable && (
            [column.field, column.sortableField].includes(sortedColumn.name) ? (
              <div className='d-flex flex-column pl-2'>
                <ChevronUp fill='currentColor' size={10} className={sortedColumn.sort !== '+' && 'disabled'} />
                <ChevronDown fill='currentColor' className={sortedColumn.sort !== '-' && 'disabled'} />
              </div>
            ) : (
              <div className='d-flex flex-column pl-2'>
                <ChevronUp fill='currentColor' size={10} className='disabled' />
                <ChevronDown fill='currentColor' className='disabled' />
              </div>
            )
          )}
        </div>
      );
    });

    return <div className='table-header'>{headerRows}</div>;
  };

  const renderRows = () => {
    const newColumns = [...columns];
    if (rows.items?.length > 0) {
      return rows.items.map((row, i) => {
        if (mode === 'rows') {
          return (
            <TableRow
              key={row.id}
              row={row}
              columns={newColumns}
              onClick={onClickRow}
              selected={selectedRow === row.id}
            />
          );
        }
        let rowProps = {
          key: row.id,
          ...row,
          ...componentProps,
          _tableRow: { index: i, page: actualPage },
        };
        if (componentProps) {
          rowProps = Object.assign(rowProps, componentProps);
        }
        return React.createElement(component, rowProps);
      });
    }
    return (
      <div className='no-results'>
        <img src={EmptyStreet} alt='' />
      </div>
    );
  };

  const renderLoading = () => {
    const numRows = rows !== null && rows.total > 0 && rows.total < pageSize ? rows.total : pageSize;
    if (!numRows) {
      return null;
    }
    if (mode === 'rows') {
      const newColumns = [...columns];
      return Array.from(new Array(Math.max(numRows, 1))).map((e, i) => (
        <TableRow key={`row-${i}`} row={{ id: i.toString() }} columns={newColumns} loading />
      ));
    }

    return Array.from(new Array(Math.max(numRows, 1))).map((e, i) => React.createElement(component, {
      loading: true,
      key: i.toString(),
      id: i.toString(),
      ...componentProps,
    }));
  };

  useEffect(() => {
    if (fetch) {
      fetchPage(actualPage);
    } else {
      setFetching(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualPage]);

  return (
    <div className={`table-component table-mode-${mode} ${className}`}>
      {renderFilters()}
      <div className='table-overflow'>
        <div className='table-fullwidth'>
          {mode === 'rows' && renderHeader()}
          <div className={`table-rows listItemBox ${onClickRow ? 'rows-click' : ''}`}>
            {fetching ? renderLoading() : renderRows()}
          </div>
        </div>
      </div>
      <div className='table-pagination justify-content-center'>
        {rows !== null && rows.total > rows.items?.length && (
          <Pagination
            pages={Math.ceil(rows.total / pageSize)}
            actualPage={actualPage}
            onChange={setActualPage}
          />
        )}
      </div>
    </div>
  );
};

SimpleTable.propTypes = {
  searcher: PropTypes.bool,
  className: PropTypes.string,
  mode: PropTypes.string,
  columns: PropTypes.array,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  componentProps: PropTypes.object,
  fetch: PropTypes.func,
  pageSize: PropTypes.number,
  onClickRow: PropTypes.func,
  selectedRow: PropTypes.string,
};

export default SimpleTable;
