/* eslint-disable object-curly-newline */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Table from 'components/Table';
import Button from 'components/Buttons/Button';
import ROLES from 'constants/roles';
import { useNavigate } from 'react-router-dom';
import { useCounter } from 'utils/customHooks';
import { PlusCircle, Wallet } from 'lucide-react';
import { ROUTE_ADMIN_PATH, ROUTE_MENTOR_PATH, ROUTE_PATH, ROUTE_POSITION_PATH, ROUTE_STARTUP_PATH } from 'routes';
import { fetchUserStartups } from '../modules/actions';
import StartupRow from './StartupRow';
import './styles.scss';

const PreHome = (props) => {
  const {
    literals,
    literalsOnBoarding,
    user,
    platform,
  } = props;

  const [pendingStartups, setPendingStartups] = useState(false);
  const [loaded, setLoaded] = useState({ firstLoad: false, pending: false, accepted: false });
  const tableRefreshFlag = useCounter(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.role?.includes(ROLES.ROLE_USER)) {
      (async () => {
        const auxPendingStartups = await fetchUserStartups(0, 0, '', { status: 'pending', startedBy: 'startup' });
        setPendingStartups(auxPendingStartups.items);
        setLoaded(prev => ({
          ...prev, firstLoad: !!prev.accepted, pending: { total: auxPendingStartups.total },
        }));
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && user.id) {
      if (user.role.length === 1 && user.role[0] === ROLES.ROLE_INVESTOR) {
        navigate(ROUTE_POSITION_PATH.OPPORTUNITIES);
      } else if (user.role.includes(ROLES.ROLE_USER)) {
        // if user is startup then redirect to onboarding if no startups, if at least 1 then let user select a startup already created or do another onboarding
        if (loaded.firstLoad && !loaded.pending.total) {
          if (loaded.accepted.total === 0) {
            navigate(ROUTE_STARTUP_PATH.ONBOARDING);
          } else if (loaded.accepted.total === 1) {
            if (user.role.length === 1 && !platform?.properties?.signup?.prehome) {
              navigate(ROUTE_STARTUP_PATH.setHome(loaded.accepted.firstId));
            }
          }
        }
      } else if (user.role.includes(ROLES.ROLE_MENTOR)) {
        // if !headline redirect to onboarding mentor because it is the first mandatory question
        if (user.mentor.headline) {
          navigate(ROUTE_MENTOR_PATH.HOME);
        } else {
          navigate(ROUTE_MENTOR_PATH.ONBOARDING);
        }
      } else if (user.role.includes(ROLES.ROLE_ADMIN)) {
        navigate(ROUTE_ADMIN_PATH.HOME);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loaded.firstLoad]);

  const tableActions = useMemo(() => {
    if (!platform?.properties?.signup?.prehome) {
      return [];
    }
    return [
      {
        icon: PlusCircle,
        text: literals.createStartup,
        onClick: () => navigate(ROUTE_STARTUP_PATH.ONBOARDING),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform]);

  if (!user?.id || !user.role.includes(ROLES.ROLE_USER)) {
    return null;
  }

  const fetchStartups = async (page, size, search, filters) => {
    const acceptedStartups = await fetchUserStartups(page, size, search, { ...filters, status: 'accepted' }, '+name');
    if (!loaded.firstLoad) {
      const firstId = acceptedStartups.total ? acceptedStartups.items[0].id : null;
      setLoaded(prev => ({ ...prev, firstLoad: !!prev.pending, accepted: { firstId, total: acceptedStartups.total } }));
    }
    return acceptedStartups;
  };

  const componentProps = {
    literals,
    literalsOnBoarding,
    user,
    setPendingStartups,
    refreshTable: tableRefreshFlag.increase,
  };

  return (
    <Tool className='prehome_container'>
      { (user.investor || user.shareholder) && (
        <div className='buttons mb-4'>
          <Button
            className='viewMyPosition-button'
            text={literals.viewMyPosition}
            icon={Wallet}
            onClick={() => navigate(ROUTE_PATH.MY_POSITION)}
          />
        </div>
      )}
      { pendingStartups.length ? (
        <>
          <h4 className='fw-b mb-3'>{`${literals.pendingApproval} (${pendingStartups.length})`}</h4>
          <div className='prehome_startups_content pending mb-4'>
            <Table
              searcher={false}
              mode='components'
              component={StartupRow}
              componentColumns={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
              componentProps={componentProps}
              pageSize={3}
              fetch={pendingStartups ? () => ({ items: pendingStartups, total: pendingStartups.length }) : null}
              forceFetch={pendingStartups}
            />
          </div>
        </>
      ) : null}
      <h4 className='fw-b mb-3'>{literals.myStartups}</h4>
      <div className='prehome_startups_content'>
        <Table
          mode='components'
          component={StartupRow}
          componentColumns={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
          componentProps={componentProps}
          pageSize={12}
          fetch={fetchStartups}
          forceFetch={tableRefreshFlag.value}
          actions={tableActions}
        />

      </div>
    </Tool>
  );
};

PreHome.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsOnBoarding: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
};
export default PreHome;
