import { connect } from 'react-redux';
import DataRoom from '../components';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.dataRoom,
    literalsCommon: state.i18n.literals.common,
    lang: state.i18n.language,
    startup: state.global.startup,
  };
}

function mapDispatchToProps(/* dispatch */) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DataRoom);
