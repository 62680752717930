import MessagesInstance from 'modules/messages/repository';
import { diffBetweenDates, formatDate, formatErrors } from 'utils/functions';

const fillFormatDate = (data) => {
  const now = new Date();
  // eslint-disable-next-line no-param-reassign
  data.fDate = {
    format: formatDate(data.createdTimestamp, { time: true }),
    diff: diffBetweenDates(data.createdTimestamp, now),
  };
  if (data.answers.length) {
    data.answers.forEach((answer) => {
      fillFormatDate(answer);
    });
  }
};

export function fetchMessages(type, id, page, size) {
  return MessagesInstance.getMessages(type, id, { page, size })
    .then((response) => {
      response.items.forEach((message) => {
        fillFormatDate(message);
      });
      return response;
    })
    .catch((error) => {
      console.error(error);
      throw formatErrors(error);
    });
}

export function postMessage(type, id, message, parent, attachments) {
  return MessagesInstance.postMessage(type, id, { message, parent, attachments })
    .then((response) => {
      fillFormatDate(response);
      return response;
    })
    .catch((error) => {
      console.error(error);
      formatErrors(error.message);
    });
}

export function readMessages(type, id, messages) {
  return MessagesInstance.readMessages(type, id, { messages })
    .catch((error) => {
      console.error(error);
      formatErrors(error.message);
    });
}
