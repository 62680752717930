import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import Tool from 'components/Tool';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import InputSelect from 'components/Inputs/inputSelect';
import EmptyGraph from 'assets/svg/empty_graph.svg';
import { ROUTE_POSITION_PATH } from 'routes';
import { getKpiStartupsComparator } from 'routes/Performance/modules/actions';
import { LineChart, Sheet } from 'lucide-react';
import { getColorRange, getTranslation } from 'utils/functions';
import { KPI_PERIODICITY } from 'constants/kpis';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getInvestmentsKpis } from 'routes/MyInvestments/modules/actions';
import InputMonthRange from '../../PerformanceComparator/components/inputMonthRange';
import PerformanceComparatorChart from '../../PerformanceComparator/components/PerformanceComparatorChart';
import PerformanceComparatorTable from '../../PerformanceComparator/components/PerformanceComparatorTable';
import './styles.scss';

const PerformanceStartupsComparator = (props) => {
  const {
    lang,
    literals,
    literalsInvestment,
    shareholders,
    literalsCommon,
    currency = null,
  } = props;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const kpiParam = searchParams.get('kpi') || null;

  const actualDate = new Date();
  const [dates, setDates] = useState({ actual: [], prev: [] });
  const [mode, setMode] = useState('chart');
  const [tableType, setTableType] = useState(KPI_PERIODICITY.MONTHLY);
  const [rows, setRows] = useState([]);
  const [kpi, setKpi] = useState(kpiParam);
  const [kpis, setKpis] = useState([]);
  const [startups, setStartups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState({ value: true, forecast: true });
  const [interval, setInterval] = useState({
    startDate: `${actualDate.getFullYear() - 1}-${String(actualDate.getMonth() + 1).padStart(2, '0')}`,
    endDate: `${actualDate.getFullYear()}-${String(actualDate.getMonth() + 1).padStart(2, '0')}`,
    comparator: { startDate: null, endDate: null },
  });

  const isMonthly = tableType === KPI_PERIODICITY.MONTHLY;
  const commonProps = {
    show,
    isMonthly,
    graphData: rows,
    dates,
    lang,
    literals,
    literalsCommon,
    loading,
    interval,
    tableType,
    currency,
  };

  const getDates = (comparator = false) => {
    const { startDate, endDate } = comparator ? interval.comparator : interval;
    const [fromYear, fromMonth] = startDate.split('-').map(Number);
    const [toYear, toMonth] = endDate.split('-').map(Number);

    let months = Array.from(
      { length: (toYear - fromYear) * 12 + (toMonth - fromMonth) + 1 },
      (_, i) => {
        const year = Math.floor((i + fromMonth - 1) / 12) + fromYear;
        const month = ((i + fromMonth - 1) % 12) + 1;
        const paddedMonth = String(month).padStart(2, '0');
        return `${year}-${paddedMonth}`;
      },
    );

    if (tableType === KPI_PERIODICITY.QUARTERLY) {
      months = months.filter(month => [3, 6, 9, 12].includes(+month.split('-')[1]));
    } else if (tableType === KPI_PERIODICITY.ANNUAL) {
      months = months.filter(month => month.split('-')[1] === '12');
    }

    setDates(prev => ({ ...prev, [comparator ? 'prev' : 'actual']: months }));
  };


  const fetchValues = async () => {
    if (!kpi) {
      setRows([]);
    } else if (kpiParam) {
      setLoading(true);
      const auxStartups = await getKpiStartupsComparator(kpiParam, {
        periodicity: tableType,
        from: `${interval.startDate}-01`,
        to: `${interval.endDate}-01`,
      });

      let compareValues = null;
      if (interval.comparator.startDate && interval.comparator.endDate) {
        compareValues = await getKpiStartupsComparator(kpiParam, {
          periodicity: tableType,
          from: `${interval.comparator.startDate}-01`,
          to: `${interval.comparator.endDate}-01`,
        });
      }

      const auxRows = {};
      startups.forEach((startup) => {
        const auxEmpty = { data: {}, values: [], forecast: [] };
        auxRows[startup.id] = {
          ...startup, ...auxEmpty, prev: { ...auxEmpty },
        };
      });

      auxStartups.items.forEach((startup) => {
        if (auxRows[startup.id]) {
          const data = {};
          const values = startup.values.map((kpiValue) => {
            const auxValue = isMonthly ? kpiValue.value : kpiValue.aggregate.value;
            data[kpiValue.date] = { ...kpiValue, value: auxValue };
            return { x: kpiValue.date, y: auxValue };
          });

          let forecast = [];
          if (tableType === KPI_PERIODICITY.MONTHLY) {
            forecast = startup.values.map(kpiValue => ({ x: kpiValue.date, y: kpiValue.forecast }));
          }

          auxRows[startup.id].values = values;
          auxRows[startup.id].forecast = forecast;
          auxRows[startup.id].data = data;
        }
      });


      if (compareValues) {
        compareValues.items.forEach((startup) => {
          if (auxRows[startup.id]) {
            const data = {};
            const values = startup.values.map((kpiValue) => {
              const auxValue = isMonthly ? kpiValue.value : kpiValue.aggregate.value;
              data[kpiValue.date] = { ...kpiValue, value: auxValue };
              return { x: kpiValue.date, y: auxValue };
            });

            let forecast = [];
            if (tableType === KPI_PERIODICITY.MONTHLY) {
              forecast = startup.values.map(kpiValue => ({ x: kpiValue.date, y: kpiValue.forecast }));
            }

            auxRows[startup.id].prev = { values, forecast, data };
          }
        });
        getDates(true);
      }

      setRows(Object.values(auxRows));
      getDates();
    }
    setLoading(false);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      if (rows.length) {
        fetchValues();
      }
    }, 500);
    return () => clearTimeout(delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval]);

  useEffect(() => {
    fetchValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpi, tableType]);

  useEffect(() => {
    (async () => {
      const shs = shareholders.join();
      const data = await getInvestmentsKpis(shs);
      setKpis(data.kpis || []);
      setStartups(data.startups.items || []);
      setKpi(null);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareholders]);

  const auxKpiName = kpis.find(aux => aux.id === kpiParam)?.name;
  const breadcrumb = [
    { route: ROUTE_POSITION_PATH.ANALYSIS, name: literalsInvestment.title },
    { route: ROUTE_POSITION_PATH.ANALYSIS, name: literalsInvestment.analysis },
    { name: getTranslation(auxKpiName, lang) },
  ];

  const handleVisibleRow = (type) => {
    if (type === 'value') {
      setShow({
        value: !show.value,
        forecast: show.value || show.forecast,
      });
    } else {
      setShow({
        forecast: !show.forecast,
        value: show.forecast || show.value,
      });
    }
  };

  const renderContent = () => {
    if (loading) {
      return <Loading mode='panel' hide={false} />;
    }

    const graphColors = getColorRange(2);
    return rows?.length ? (
      <>
        <div className='d-flex justify-content-between align-items-center mb-4'>
          <InputSelect
            className='w-25 m-0'
            onChange={v => setTableType(v)}
            value={tableType}
            options={[
              { id: KPI_PERIODICITY.MONTHLY, name: literals.monthly },
              { id: KPI_PERIODICITY.QUARTERLY, name: literals.quarterly },
              { id: KPI_PERIODICITY.ANNUAL, name: literals.annual },
            ]}
          />
          { isMonthly && mode === 'table' && (
            <div className='performance-Comparator-legend'>
              <span className='performance-legend-item'>
                <div className='performance-legend--circle' style={{ background: graphColors[0] }} />
                <span
                  className={`performance-legend--name ${!show.value ? 'disabled' : ''}`}
                  onClick={() => handleVisibleRow('value')}
                >
                  {literals.value}
                </span>
              </span>
              <span className='performance-legend-item'>
                <div className='performance-legend--circle' style={{ background: graphColors[1] }} />
                <span
                  className={`performance-legend--name ${!show.forecast ? 'disabled' : ''}`}
                  onClick={() => handleVisibleRow('forecast')}
                >
                  {literals.forecast}
                </span>
              </span>
            </div>
          )}
        </div>
        { mode === 'chart'
          ? <PerformanceComparatorChart {...commonProps} />
          : <PerformanceComparatorTable {...commonProps} />
        }
      </>
    ) : (
      <div className='panel-empty-message'>
        <img src={EmptyGraph} alt={literals.emptyKpis} />
        <p>{literals.emptyKpis}</p>
      </div>
    );
  };


  return (
    <Tool className='PerformanceComparator-wrapper'>
      <Panel>
        <PanelHeader breadcrumb={breadcrumb} />
        <PanelBody>
          <div className='krg-header'>
            <div className='krg-mode'>
              <div onClick={() => setMode('chart')} className={mode === 'chart' ? 'active' : ''}><LineChart size={18} /></div>
              <div onClick={() => setMode('table')} className={mode === 'table' ? 'active' : ''}><Sheet size={18} /></div>
            </div>
            <div className='krg-dates'>
              <InputMonthRange value={interval} onChange={setInterval} literals={literals} literalsCommon={literalsCommon} />
            </div>
          </div>

          <InputSelect
            className='mb-4'
            preText={literals.kpi}
            placeholder={literals.kpi}
            value={kpi}
            options={kpis.map(auxKpi => ({ name: getTranslation(auxKpi.name, lang), id: auxKpi.id }))}
            onChange={(val) => {
              setKpi(val);
              navigate({ search: `kpi=${val}` }, { replace: true });
            }}
          />
          {renderContent()}
        </PanelBody>
      </Panel>
    </Tool>
  );
};

PerformanceStartupsComparator.propTypes = {
  lang: PropTypes.string.isRequired,
  literals: PropTypes.object.isRequired,
  literalsInvestment: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  shareholders: PropTypes.array.isRequired,
  currency: PropTypes.object,
};

export default PerformanceStartupsComparator;
