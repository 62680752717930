import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelTab from 'components/Panel/components/PanelTab';
import PermissionsGroup from 'components/PermissionsPopup/PermissionGroup';
import PermissionsUser from 'components/PermissionsPopup/PermissionUser';
import { useCounter } from 'utils/customHooks';
import {
  Eye, Pencil, Trash, CircleSlash, Crown,
  Clipboard, Landmark, BarChart3, FileText, MessageCircleMore,
  Printer, Users, Building, Puzzle, CirclePlus, MessageSquareMore,
} from 'lucide-react';
import useDialog from 'components/Dialog/components/useDialog';
import { getPermissionGroupName } from 'utils/functions/getPermissionGroupName';
import { deletePermissionGroup } from 'components/PermissionsPopup/modules/actions';
import { getFullName } from 'utils/functions';
import { fetchMembersPermissions, fetchPermissionGroups } from '../modules/actions';
import './styles.scss';

const PermissionsTool = (props) => {
  const { literals, literalsCommon, match } = props;

  const [showUserPopup, setShowUserPopup] = useState(false);
  const [showGroupPopup, setShowGroupPopup] = useState(false);
  const tableRefreshFlag = useCounter(0);
  const { dialog } = useDialog();

  const fetchGroups = async (page, size, search, filters) => {
    return fetchPermissionGroups('startup', match.params.id, {
      page, size, search, filters, sort: '-levels',
    });
  };

  const fetchUsers = async (page, size, search, filters) => {
    return fetchMembersPermissions('startup', match.params.id, {
      page, size, search, filters,
    });
  };

  const toolIcons = {
    capTable: Clipboard,
    fundraise: Landmark,
    performance: BarChart3,
    dataRoom: FileText,
    mentoring: MessageSquareMore,
    reporting: Printer,
    users: Users,
    profile: Building,
    services: Puzzle,
    messages: MessageCircleMore,
  };

  const levelIcons = [
    CircleSlash,
    Eye,
    Pencil,
    Crown,
  ];

  const handleDeleteGroup = async (group) => {
    const confirm = await dialog({
      type: 'confirmDanger', text: literalsCommon.confirmDelete,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          const response = await deletePermissionGroup({ type: 'startup', id: match.params.id }, group.id);
          if (response) {
            tableRefreshFlag.increase();
          }
        },
      });
    }
  };

  const columns = Object.keys(toolIcons).map(tool => (
    {
      field: tool,
      width: '40px',
      title: literals.tools[tool],
      label: React.createElement(toolIcons[tool], { size: 18 }),
      preRender: (_, row) => React.createElement(levelIcons[row?.permissions[tool] ?? 0], { size: 18, title: literals.levels[row?.permissions[tool] ?? 0] }),
    }
  ));

  const usersColumns = [
    {
      field: 'avatar',
      type: 'avatar',
      width: 45,
      label: '',
      title: (_, row) => getFullName(row),
    },
    {
      field: 'firstname',
      label: literalsCommon.name,
      grow: 1,
      preRender: (_, row) => getFullName(row),
    },
    ...columns,
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Pencil,
          text: literalsCommon.edit,
          onClick: setShowUserPopup,
        },
      ],
    },
  ];

  const groupsColumns = [
    {
      field: 'name',
      grow: 1,
      label: literalsCommon.name,
      preRender: (_, row) => getPermissionGroupName({ ...row, type: 'group' }, literals),
    },
    {
      field: 'users',
      width: 90,
      label: literalsCommon.users,
      preRender: users => users.length,
    },
    ...columns,
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Pencil,
          text: literalsCommon.edit,
          onClick: setShowGroupPopup,
        },
        {
          icon: Trash,
          text: literalsCommon.delete,
          onClick: handleDeleteGroup,
          disabled: (_, row) => row.default,
        },
      ],
    },
  ];

  return (
    <Panel>
      <PanelHeader
        title={literals.permissions}
        tabs={[
          { key: 'groups', name: literals.groups },
          { key: 'users', name: literals.users },
        ]}
      />
      <PanelBody>
        <PanelTab key='groups'>
          <Table
            columns={groupsColumns}
            fetch={fetchGroups}
            forceFetch={tableRefreshFlag.value}
            onClickRow={row => setShowGroupPopup(row)}
            actions={[{
              text: literalsCommon.create,
              icon: CirclePlus,
              onClick: () => setShowGroupPopup(true),
            }]}
          />
        </PanelTab>
        <PanelTab key='users'>
          <Table
            columns={usersColumns}
            fetch={fetchUsers}
            forceFetch={tableRefreshFlag.value}
            onClickRow={row => setShowUserPopup(row)}
          />
        </PanelTab>
        {
          showUserPopup && (
            <PermissionsUser
              id={showUserPopup.id || ''}
              literals={literals}
              literalsCommon={literalsCommon}
              user={showUserPopup}
              scope={{ type: 'startup', id: match.params.id }}
              onSubmit={tableRefreshFlag.increase}
              onClose={() => setShowUserPopup(false)}
            />
          )
        }
        {
          showGroupPopup && (
            <PermissionsGroup
              id={showGroupPopup.id || ''}
              literals={literals}
              literalsCommon={literalsCommon}
              scope={{ type: 'startup', id: match.params.id }}
              onSubmit={() => { setShowGroupPopup(false); tableRefreshFlag.increase(); }}
              onClose={() => setShowGroupPopup(false)}
            />
          )
        }
      </PanelBody>
    </Panel>
  );
};

PermissionsTool.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default PermissionsTool;
