import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/Alert';
import Popup from 'components/Popup';
import Button from 'components/Buttons/Button';
import InputFileZone from 'components/Inputs/inputFileZone';
import IOFileLoading from 'components/IOFileLoading/components';
import { ArrowLeft } from 'lucide-react';
import { literalTemplate } from 'utils/language';
import { formatDate } from 'utils/functions';
import { downloadImportExcelTemplate, inicializeCaptableExcel } from '../../routes/Captable/modules/actions';

const ImportExcelPopup = ({
  literals,
  literalsCommon,
  startup,
  captable,
  onSubmit,
  onClose,
}) => {
  const [document, setDocument] = useState();
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleDownloadTemplate = async () => {
    setDownloadingExcel(true);
    await downloadImportExcelTemplate(startup);
    setDownloadingExcel(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
          const err = new Error('timeout');
          err.code = 408;
          reject(err);
        }, 30000);
      });

      const res = await Promise.race([
        timeoutPromise,
        inicializeCaptableExcel(startup, captable, document),
      ]);

      onSubmit(res.id);
      onClose();
    } catch (err) {
      if (err?.code === 408) {
        setError(err);
      } else {
        const code = err.error[0].code;
        const message = JSON.parse(err.error[0].message);
        setError({
          code,
          operation: message.operation,
          date: message.date,
          cell: message.cell,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const renderPopup = () => {
    if (error) {
      if (error.code === 408) {
        return (
          <>
            <Alert type='info' text={`${literals.operationTimeout}. ${literals.emailWhenComplete}`} />
            <div className='buttons'>
              <Button
                text={literalsCommon.close}
                onClick={onClose}
              />
            </div>
          </>
        );
      }

      return (
        <>
          <p>
            {literalTemplate(literals.errors.initializeCaptable, {
              OPERATION: error.operation,
              DATE: formatDate(error.date),
            })}
          </p>
          <p>
            {error.cell ? `${literals.cell} ${error.cell}: ` : ''}
            {literals.errors[error.code] || error.code}
          </p>

          <div className='buttons'>
            <Button
              icon={ArrowLeft}
              text={literalsCommon.back}
              onClick={() => setError(null)}
              color='secondary'
            />
          </div>
        </>
      );
    }

    return (
      <>
        <p>
          <span className='link-effect fw-b' onClick={handleDownloadTemplate}>
            {literals.downloadTheTemplate}
          </span>
          <span>&nbsp;</span>
          {literals.inicializeCaptableExcelDescription}
        </p>
        <InputFileZone value={document} onChange={setDocument} />

        <div className='buttons'>
          <Button
            text={literalsCommon.send}
            onClick={handleSubmit}
            loading={loading}
          />
        </div>
      </>
    );
  };


  return (
    <Popup
      title={literals.uploadExcelTemplates}
      onClose={onClose}
    >
      { renderPopup() }
      { downloadingExcel && <IOFileLoading mode='download' file={{ name: 'captable_template.xlsx' }} /> }
    </Popup>
  );
};

ImportExcelPopup.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  startup: PropTypes.string.isRequired,
  captable: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImportExcelPopup;
