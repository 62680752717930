/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import EmptyFolder from 'assets/svg/empty_folder.svg';
import PopupInfoFile from 'routes/DataRoom/components/PopupInfoFile';
import { Skeleton } from '@mui/material';
import { ROUTE_STARTUP_PATH } from 'routes';
import { addBookmark, deleteBookmark, getFolderContents } from 'routes/DataRoom/modules/actions';
import { getFolderName } from 'routes/DataRoom/components';
import { getFolderIcon, selectType } from 'routes/DataRoom/components/FolderContent';
import { useLocation, useNavigate } from 'react-router-dom';
import { Folder } from 'lucide-react';

const SummaryStartupDataroom = (props) => {
  const {
    literalsDataroom,
    literalsCommon,
    match,
  } = props;

  const [documents, setDocuments] = useState();
  const [loading, setLoading] = useState(true);
  const [showDocumentPopup, setShowDocumentPopup] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const rolePath = location.pathname.split('/')[1];
  const startup = match.params.id;

  useEffect(() => {
    (async () => {
      const bookmarks = await getFolderContents(rolePath, startup, 'bookmark', {
        page: 0, size: 6, sort: '-created_at',
      });
      setDocuments(bookmarks.items);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleItemClick = (item) => {
    return item._type === 'folder'
      ? navigate(ROUTE_STARTUP_PATH.setDataRoom(startup, item.id))
      : setShowDocumentPopup(item);
  };

  const handleBookmark = async (item) => {
    const { id, _type: type, _bookmark: bookmark } = item;
    const element = { id, type };

    if (bookmark) {
      await deleteBookmark(rolePath, startup, element);
      setDocuments(prev => prev.filter(bm => bm.id !== element.id));
    } else {
      await addBookmark(rolePath, startup, element);
      setDocuments(prev => [item, ...prev]);
    }
  };

  const renderBookmarks = () => {
    if (loading) {
      return [...Array(6)].map((_, i) => (
        <div key={`skeleton-bookmark-${i}`} className='bookmark-box p-2 col-12 col-md-4'>
          <Skeleton className='mr-3' animation='wave' width='20px' />
          <Skeleton animation='wave' width='100%' />
        </div>
      ));
    }

    if (!loading && !documents.length) {
      return (
        <div className='empty-folder'>
          <img src={EmptyFolder} alt={literalsDataroom.emptyFolder} />
          <p className='m-0'>{literalsDataroom.emptyBookmarks}</p>
        </div>
      );
    }

    return documents.map(item => (
      <div
        key={`bookmark-${item.id}`}
        className={`bookmark-box col-12 ${documents.length > 4 ? 'col-md-4' : 'col-md-6'}`}
        onClick={() => handleItemClick(item)}
      >
        {item._type === 'folder' ? (
          <>
            <div className='folder-icon'>
              <Folder size={24} className='mr-2' />
              {item.related && (
                <span className='folder-icon--type'>{getFolderIcon(item.related.type, 10)}</span>
              )}
            </div>
            <div className='text-overflow'>{getFolderName(item, literalsDataroom)}</div>
          </>
        ) : (
          <>
            <div className='mr-2'>
              {selectType(item.type || item._type, 24)}
            </div>
            <div className='text-overflow'>{item.name}</div>
          </>
        )}
      </div>
    ));
  };

  return (
    <Panel className='summary-dataroom'>
      <PanelHeader
        title={literalsDataroom.dataRoom}
        actions={documents?.length > 5 ? [{
          text: literalsCommon.viewMore,
          onClick: () => navigate(ROUTE_STARTUP_PATH.setDataRoom(startup, 'bookmark')),
        }] : null}
      />
      <PanelBody>
        <div className='summary-dataroom-grid row'>
          {renderBookmarks()}
        </div>
        {
          showDocumentPopup && (
            <PopupInfoFile
              literals={literalsDataroom}
              literalsCommon={literalsCommon}
              onClose={() => setShowDocumentPopup(null)}
              file={showDocumentPopup}
              setContent={null}
              handleBookmark={handleBookmark}
            />
          )
        }
      </PanelBody>
    </Panel>
  );
};

SummaryStartupDataroom.propTypes = {
  literalsDataroom: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default SummaryStartupDataroom;
