import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import Popup from 'components/Popup';
import useDialog from 'components/Dialog/components/useDialog';
import { literalTemplate } from 'utils/language';
import { fetchStartups, mergeStartups } from '../modules/actions';
import { formatName } from '.';

const PopupMergeStartups = (props) => {
  const {
    literals,
    literalsCommon,
    startups,
    tableRefreshFlag,
    onClose,
  } = props;

  const { dialog } = useDialog();

  const getStartups = (page, size) => {
    return fetchStartups(page, size, {
      filters: { id: startups },
    });
  };

  const mergeStartupsHandler = async (main) => {
    const confirm = await dialog({
      text: literalTemplate(literals.confirmMergeStartups, {
        STARTUPS: startups.length,
        STARTUP: main.name,
      }),
      type: 'confirmDanger',
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            const others = startups.filter(startup => startup !== main.id);
            await mergeStartups(main.id, others);
            tableRefreshFlag.increase();
            onClose();
          } catch (errors) {
            await dialog({
              text: literalsCommon.error, type: 'error',
            });
          }
        },
      });
    }
  };

  const columns = [
    {
      field: 'logo', type: 'avatar', title: (_, row) => row.name, width: 45, label: 'Logo',
    },
    {
      field: 'name', label: 'Name', preRender: (_, row) => formatName(row), sortable: true,
    },
  ];

  return (
    <Popup title={literals.mergeStartups} onClose={onClose} size='small'>
      <h6 className='fw-b'>{literals.mergeStartupsInfo}</h6>
      <Table
        searcher={false}
        columns={columns}
        fetch={getStartups}
        onClickRow={mergeStartupsHandler}
        pageSize={3}
      />
    </Popup>
  );
};

PopupMergeStartups.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  startups: PropTypes.array.isRequired,
  tableRefreshFlag: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupMergeStartups;
