import MentoringsInstance from 'modules/mentorings/repository';
import FormsInstance from 'modules/forms/repository';


export const fetchMentorings = (type, id, params) => {
  return MentoringsInstance.getMentorings({ [type]: id, ...params });
};

export const saveMentoringStatus = (id, status, message) => {
  if (message) {
    return MentoringsInstance.mentoringStatus(id, { status, message });
  }
  return MentoringsInstance.mentoringStatus(id, { status });
};

export const saveMentoringFeedback = (id, data) => {
  return MentoringsInstance.createFeedback(id, data);
};

export const deleteMentoring = (id) => {
  return MentoringsInstance.deleteMentoring(id);
};

export const getMentoringsStartups = (id, params) => {
  return MentoringsInstance.getMentoringStartups({ mentor: id, ...params });
};

export const getMentoringsMentors = (id, params) => {
  return MentoringsInstance.getMentoringMentors({ startup: id, ...params });
};

export const createMentoring = (startup, mentor, message) => {
  return MentoringsInstance.createMentoring({ startup, mentor, message });
};

export function fetchMentoringsSummary(type, id) {
  const data = {};
  data[type] = id;
  return MentoringsInstance.getMentoringsSummary(data);
}

export function startupGetFormAction() {
  return FormsInstance.getFormStartups();
}

export function mentorGetFormAction() {
  return FormsInstance.getFormMentor();
}
