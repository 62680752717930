import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import './styles.scss';

const SkeletonDoughnutChart = ({ className = '', size = 200 }) => (
  <div className={`doughnutChart-wrapper ${className}`} style={{ width: `${size}px`, height: `${size}px` }}>
    <div className='white-circle' />
    <Skeleton animation='wave' variant='circular' width={size} height={size} />
  </div>
);

SkeletonDoughnutChart.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

export default SkeletonDoughnutChart;
