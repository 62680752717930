import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import useDialog from 'components/Dialog/components/useDialog';
import { useCounter } from 'utils/customHooks';
import {
  Eye, EyeOff, Pencil, PlusCircle, Trash,
} from 'lucide-react';
import { getTranslation } from 'utils/functions';
import { getServices, deleteService } from '../modules/actions';
import FormServices from './FormServices';
import './styles.scss';

const ServicesTab = (props) => {
  const {
    literals,
    literalsCommon,
    lang,
  } = props;

  const [showFormService, setShowFormService] = useState(false);
  const tableRefreshFlag = useCounter(0);
  const { dialog } = useDialog();


  const handleRemoveService = async (service) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literals.questionRemoveService,
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteService(service.id);
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const columns = [
    {
      field: 'logo',
      type: 'avatar',
      width: 45,
      title: (_, row) => getTranslation(row.company, lang),
    },
    {
      field: 'company',
      label: literals.company,
      grow: 1,
      preRender: v => getTranslation(v, lang),
    },
    {
      field: 'type',
      label: literalsCommon.type,
      width: '200px',
      preRender: v => getTranslation(v, lang),
    },
    {
      field: 'shortDescription',
      label: literalsCommon.description,
      grow: 2,
      preRender: v => getTranslation(v, lang),
    },
    {
      field: 'visible',
      label: <Eye />,
      width: '40px',
      align: 'center',
      preRender: v => (v ? <Eye size={20} /> : <EyeOff size={20} />),
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Pencil,
          text: literalsCommon.edit,
          onClick: setShowFormService,
        },
        {
          icon: Trash,
          text: literalsCommon.delete,
          onClick: handleRemoveService,
        },
      ],
    },
  ];

  const filters = [
    {
      id: 'visible',
      type: 'select',
      title: literals.visible,
      options: [
        { id: true, name: literalsCommon.yes },
        { id: false, name: literalsCommon.no },
      ],
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        filters={filters}
        pageSize={10}
        fetch={getServices}
        forceFetch={tableRefreshFlag.value}
        onClickRow={row => setShowFormService(row)}
        actions={[{
          icon: PlusCircle,
          text: literals.createService,
          onClick: () => setShowFormService('new'),
        }]}
      />
      { showFormService && (
        <FormServices
          literals={literals}
          literalsCommon={literalsCommon}
          service={showFormService !== 'new' ? showFormService : null}
          onDelete={handleRemoveService}
          refreshTable={tableRefreshFlag.increase}
          onClose={() => setShowFormService(null)}
        />
      )}
    </>
  );
};

ServicesTab.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};

export default ServicesTab;
