import conf01 from './conf01';
import conf02 from './conf02';
import conf03 from './conf03';
import conf04 from './conf04';
import conf05 from './conf05';
import conf06 from './conf06';
import conf07 from './conf07';
import conf08 from './conf08';
import conf09 from './conf09';
import conf10 from './conf10';

export default {
  ...conf01,
  ...conf02,
  ...conf03,
  ...conf04,
  ...conf05,
  ...conf06,
  ...conf07,
  ...conf08,
  ...conf09,
  ...conf10,
};
