import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from 'components/Breadcrumb';
import InputSelect from 'components/Inputs/inputSelect';
import { useNavigate, useLocation } from 'react-router-dom';
import './styles.scss';

const Tool = ({
  className = '',
  children = null,
  breadcrumb = false,
  literals,
  tabs = [],
  showTabs = true,
  tabsJustify = '',
  tabsStyle = '',
  hash = true,
}) => {
  const [activeTab, setActiveTab] = useState(tabs.length ? tabs[0]?.key : '');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (hash) {
      const hashValue = location?.hash?.slice(1);
      if (hashValue && tabs.find(tab => tab.key === hashValue)) {
        setActiveTab(hashValue);
      } else if (activeTab) {
        navigate({ hash: activeTab }, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);

  const handleClickTab = (tab) => {
    setActiveTab(tab);
    if (hash) {
      navigate({ hash: tab, search: location.search });
    }
  };

  return (
    <div className={`tool ${className}`}>
      {
        breadcrumb && (
          <Breadcrumb literals={literals} routes={Array.isArray(breadcrumb) ? breadcrumb : []} />
        )
      }
      {
        (tabs.length && showTabs) ? (
          <>
            <div className={`tool-tabs ${tabsJustify ? `justify-${tabsJustify}` : ''} ${tabsStyle ? `style-${tabsStyle}` : ''}`}>
              {
                tabs.map(tab => !tab.hidden && (
                  <div
                    className={`tool-tab ${activeTab === tab.key ? 'active' : ''}`}
                    key={tab.key}
                    onClick={() => handleClickTab(tab.key)}
                  >
                    {tab.name}
                  </div>
                ))
              }
            </div>
            <div className='tool-tabs-selector'>
              <InputSelect
                className='w-100'
                onChange={tab => handleClickTab(tab)}
                value={activeTab}
                isRequired
                options={tabs.map(tab => ({
                  id: tab.key,
                  name: tab.name,
                }))}
              />
            </div>
          </>
        ) : null
      }
      <div className='tool-content'>
        {
          Array.isArray(children) ? (
            children.map((child, i) => {
              if (child?.type?.displayName === 'ToolTab' && ![child.key, i].includes(activeTab)) {
                return null;
              }
              return child;
            })
          ) : (
            children
          )
        }
      </div>
    </div>
  );
};

Tool.propTypes = {
  breadcrumb: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  className: PropTypes.string,
  children: PropTypes.any,
  literals: PropTypes.object.isRequired,
  tabs: PropTypes.array,
  showTabs: PropTypes.bool,
  tabsJustify: PropTypes.string,
  tabsStyle: PropTypes.string,
  hash: PropTypes.bool,
};

export default Tool;
