import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import InputText from 'components/Inputs/inputText';
import InputNumber from 'components/Inputs/inputNumber';
import { Trash } from 'lucide-react';

const PopupFormVestingPlanMilestones = (props) => {
  const {
    data,
    onChange,
    literals,
    totalPercentage,
    isDisabled,
  } = props;

  return (
    <div className='vestingPlan-milestone'>
      <h5 className='mb-0'>{literals.milestones}</h5>
      <hr />
      <div className='milestone-box'>
        <ul className='list-separator'>
          {data.milestones.map((elem, index) => {
            return (
              <li key={`milestone-${index}`}>
                <div className='row row-elem'>
                  <div className='col-md-8'>
                    <InputText
                      className='mb-md-0'
                      name='title'
                      preText={literals.title}
                      value={elem.title ? elem.title : ''}
                      onChange={value => onChange('title', index, value)}
                      isDisabled={isDisabled}
                      isRequired
                    />
                  </div>
                  <div className='col'>
                    <InputNumber
                      className='mb-md-0'
                      name='percentage'
                      preText={literals.percentage}
                      value={elem.percentage ? elem.percentage : 0}
                      onChange={value => onChange('percentage', index, value)}
                      minValue={0}
                      maxValue={100 - totalPercentage + elem.percentage}
                      isDisabled={isDisabled}
                    />
                  </div>
                  <div className='col-md-1 vestingPlan-trash'>
                    {!isDisabled && <Trash size={18} onClick={() => onChange('delete', index)} />}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      {!isDisabled && (
        <div className='addButton mb-3'>
          <Button
            color='outline-primary'
            text={literals.addMilestone}
            onClick={() => onChange('add')}
          />
        </div>
      )}
    </div>
  );
};

PopupFormVestingPlanMilestones.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  literals: PropTypes.object.isRequired,
  totalPercentage: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default PopupFormVestingPlanMilestones;
