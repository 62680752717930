import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import Popup from 'components/Popup';
import { Plus } from 'lucide-react';
import { trendIcon } from 'routes/Admin/Performance/components';
import { getTranslation } from 'utils/functions';

const AddKpiPopup = (props) => {
  const {
    literals,
    literalsCommon,
    lang,
    kpis,
    setKpis,
    fetchKpis,
    onClose,
  } = props;

  const addKpiHandler = (kpi) => {
    setKpis(prev => [...prev, kpi]);
    onClose();
  };

  const columnsKpis = [
    {
      field: 'name', label: literalsCommon.name, preRender: name => getTranslation(name, lang), width: '200px',
    },
    {
      field: 'category', label: literals.category, preRender: v => literals[v], width: '130px',
    },
    {
      field: 'periodicity', label: literals.periodicity, preRender: v => literals[v], width: '130px',
    },
    {
      field: 'positive', label: literals.positive, preRender: positive => trendIcon(positive), width: 55, align: 'center',
    },
    {
      field: 'unit', label: literals.unit, preRender: v => literals[v], width: '130px',
    },
    {
      field: 'min', label: literals.minMax, preRender: (min, row) => (`${row.min ?? '-'} | ${row.max ?? '-'}`), width: '100px',
    },
    {
      field: 'id',
      type: 'button',
      button: {
        text: literalsCommon.add,
        icon: Plus,
        onClick: kpi => addKpiHandler(kpi),
        disabled: _id => kpis.some(kpi => kpi.id === _id),
      },
    },
  ];

  return (
    <Popup size='large' onClose={onClose}>
      <Table
        columns={columnsKpis}
        pageSize={10}
        fetch={fetchKpis}
      />
    </Popup>
  );
};

AddKpiPopup.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  kpis: PropTypes.array.isRequired,
  setKpis: PropTypes.func.isRequired,
  fetchKpis: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddKpiPopup;
