import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Avatar from 'components/Avatar';
import Button from 'components/Buttons/Button';
import Alert from 'components/Alert';
import Loading from 'components/Loading';
import InputSelect from 'components/Inputs/inputSelect';
import InputToggle from 'components/Inputs/inputToggle';
import OutputErrors from 'components/Inputs/outputErrors';
import { connect } from 'react-redux';
import {
  PERMISSION_EXTERNAL, PERMISSION_LEVELS, PERMISSION_MEMBERS_TYPES, PERMISSION_MODES,
} from 'constants/permissions';
import { getFullName } from 'utils/functions';
import { getPermissionGroupName } from 'utils/functions/getPermissionGroupName';
import { initPermission } from 'utils/functions/initPermissions';
import { Building, Globe, Lock } from 'lucide-react';
import { editElementPermission, fetchElementPermission, fetchScopeMembers } from './modules/actions';
import PermissionsSearcher from './PermissionsSearcher';
import './styles.scss';

const PermissionsPopup = (props) => {
  const {
    literals,
    literalsCommon,
    title,
    scope,
    element,
    value,
    onSubmit,
    onClose,
  } = props;

  const [loading, setLoading] = useState(true);
  const [newValue, setNewValue] = useState({});
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);
  const [warningMessage, setWarningMessage] = useState(null);
  const [withAccess, setWithAccess] = useState({
    users: [], groups: [],
  });

  const {
    mode,
    owner,
    canShare,
    level,
  } = newValue;

  const isExternal = newValue.toolLevel === PERMISSION_LEVELS.EXTERNAL;

  const formatMember = (member) => {
    const newMember = {
      ...member,
    };
    if (member.type === PERMISSION_MEMBERS_TYPES.USER) {
      if (!member.name && newMember?.firstname) {
        newMember.name = getFullName(member);
        delete newMember.firstname;
        delete newMember.lastname;
      }
    } else {
      newMember.name = getPermissionGroupName(member, literals);
    }
    return newMember;
  };

  const loadPermission = async () => {
    let newNewValue = null;
    const newWithAccess = { users: [], groups: [] };
    if (value) {
      const include = { users: [], groups: [] };
      const access = value.access || {};

      if (!access[`user_${value.owner}`]) {
        access[`user_${value.owner}`] = PERMISSION_LEVELS.MANAGE;
      }

      Object.keys(access).forEach((elem) => {
        const aux = elem.split('_');
        const member = {
          type: aux[0],
          id: aux[1] ?? null,
        };
        if (member.type && member.id) {
          switch (member.type) {
            case PERMISSION_MEMBERS_TYPES.USER:
              include.users.push(member.id);
              break;
            case PERMISSION_MEMBERS_TYPES.GROUP:
              include.groups.push(member.id);
              break;
            default:
              break;
          }
        }
      });

      let members = null;
      if (include.users.length > 0 || include.groups.length > 0) {
        if (include.users.length === 0) {
          include.users.push('none');
        }
        if (include.groups.length === 0) {
          include.groups.push('none');
        }
        members = await fetchScopeMembers(scope, '', { include });
      }

      if (members?.items) {
        members.items.forEach((member) => {
          const key = `${member.type}${member.id ? `_${member.id}` : ''}`;
          const newMember = {
            ...formatMember(member),
            level: value?.access[key] || PERMISSION_LEVELS.UNAUTHORIZED,
          };
          switch (member.type) {
            case PERMISSION_MEMBERS_TYPES.USER:
              newWithAccess.users.push(newMember);
              break;
            case PERMISSION_MEMBERS_TYPES.GROUP:
              newWithAccess.groups.push(newMember);
              break;
            default:
              break;
          }
        });
      }
      newNewValue = value;
    } else if (element?.id) {
      try {
        const permission = await fetchElementPermission(scope, element);

        if (permission?.access) {
          Object.keys(permission.access).forEach((elem) => {
            const member = formatMember(permission.access[elem]);
            switch (member.type) {
              case PERMISSION_MEMBERS_TYPES.USER:
                newWithAccess.users.push(member);
                break;
              case PERMISSION_MEMBERS_TYPES.GROUP:
                newWithAccess.groups.push(member);
                break;
              default:
                break;
            }
          });
        }
        newNewValue = initPermission({ permission }, scope.type, null, null, permission.toolLevel);
      } catch (e) {
        setError(e);
      }
    }
    if (newNewValue !== null) {
      setNewValue(newNewValue);
      setWithAccess(newWithAccess);
      if (!newNewValue.canShare) {
        switch (newNewValue.toolLevel) {
          case PERMISSION_LEVELS.EXTERNAL:
            setWarningMessage(literals.cantShareExternal);
            break;
          case PERMISSION_LEVELS.UNAUTHORIZED:
          case PERMISSION_LEVELS.VIEW:
          case PERMISSION_LEVELS.EDIT:
            if (newNewValue.owner === window.USER_ID) {
              setWarningMessage(literals.cantShareUnauthorized);
            }
            break;
          default:
            break;
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    loadPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePerms = (member, newLevel) => {
    const type = member.type === PERMISSION_MEMBERS_TYPES.USER ? 'users' : 'groups';
    let aux = [...withAccess[type]];
    if (newLevel) {
      const index = aux.findIndex(mem => mem.id === member.id);
      if (index !== -1) {
        aux[index].level = newLevel;
      }
    } else {
      aux = aux.filter(mem => mem.id !== member.id);
    }

    setWithAccess(prev => ({ ...prev, [type]: aux }));
  };

  const handleAddUser = async (member) => {
    const newMember = {
      ...formatMember(member),
      level: PERMISSION_LEVELS.VIEW,
    };
    const type = member.type === PERMISSION_MEMBERS_TYPES.USER ? 'users' : 'groups';

    if (withAccess[type].findIndex(mem => mem.id === member.id) === -1) {
      setWithAccess(prev => ({ ...prev, [type]: [...prev[type], newMember] }));
    }
  };

  const handleSubmit = async () => {
    const newPermissions = {
      ...newValue,
      access: {
        ...withAccess.users.reduce((acc, aux) => ({ ...acc, [`${PERMISSION_MEMBERS_TYPES.USER}_${aux.id}`]: aux.level }), {}),
        ...withAccess.groups.reduce((acc, aux) => ({ ...acc, [`${PERMISSION_MEMBERS_TYPES.GROUP}_${aux.id}`]: aux.level }), {}),
      },
    };
    setSaving(true);
    if (onSubmit) {
      onSubmit(newPermissions);
      onClose(true);
    } else {
      try {
        await editElementPermission(scope, element, newPermissions);
        onClose(true);
      } catch (e) {
        setError(e);
      }
    }
  };

  const renderRows = (rows) => {
    if (!rows.length) {
      return (
        <div className='permissions-empty'>
          {literals.empty}
        </div>
      );
    }
    return (
      <div className='permissions-rows'>
        {rows.map(row => (
          <div key={`${row.type}_${row.id}`} className='permissions-row'>
            <div className='permissions-row-name'>
              <Avatar size={35} src={row?.avatar?.src} title={row.name} />
              <div>
                {row.name}
              </div>
            </div>
            {
              row.type === 'user' && row.id === owner ? (
                <InputSelect
                  value={PERMISSION_LEVELS.MANAGE}
                  className='mb-0'
                  options={[
                    { id: PERMISSION_LEVELS.MANAGE, name: literals.owner },
                  ]}
                  isDisabled
                />
              ) : (
                <InputSelect
                  value={row.level}
                  className='mb-0'
                  onChange={v => handleChangePerms(row, v)}
                  options={[
                    { id: PERMISSION_LEVELS.UNAUTHORIZED, name: literals.removePermissions },
                    { id: PERMISSION_LEVELS.VIEW, name: literalsCommon.view },
                    { id: PERMISSION_LEVELS.EDIT, name: literalsCommon.edit },
                    { id: PERMISSION_LEVELS.MANAGE, name: literals.manage },
                  ]}
                  isDisabled={!canShare || (row.type === 'user' && row.id === owner)}
                />
              )
            }
          </div>
        ))}
      </div>
    );
  };

  if (error && loading) {
    return (
      <Popup title={title || literals?.permissions} onClose={onClose}>
        <OutputErrors errors={error} />
      </Popup>
    );
  }

  return (
    <>
      <Popup title={title || literals?.permissions} onClose={onClose}>
        {
          loading ? (
            <Loading hide={false} mode='panel' />
          ) : (
            <div className='permissions-popup'>
              { warningMessage && (
                <Alert type='warning' text={warningMessage} />
              )}
              <InputSelect
                className='mb-0'
                preText={literals.privacity}
                value={mode}
                options={[
                  { id: PERMISSION_MODES.PRIVATE, name: literals.privateDetails, icon: Lock },
                  { id: PERMISSION_MODES.RESTRICTED, name: literals.restrictedDetails, icon: Building },
                  { id: PERMISSION_MODES.PUBLIC, name: literals.publicDetails, icon: Globe },
                ]}
                onChange={v => setNewValue(prev => ({ ...prev, mode: v }))}
                isDisabled={!canShare}
              />
              { isExternal && (
                <InputToggle
                  className='mb-0 mt-3'
                  preText={literals.shareWithManagers}
                  value={newValue.external === PERMISSION_EXTERNAL.SHARED}
                  onChange={v => setNewValue(prev => ({
                    ...prev,
                    external: v ? PERMISSION_EXTERNAL.SHARED : PERMISSION_EXTERNAL.PRIVATE,
                  }))}
                />
              )}
              <hr className='mb-sm' />
              {
                canShare && (
                  <div className='permissions-addUser'>
                    <PermissionsSearcher
                      preText={literals.addPersonOrGroup}
                      literals={literals}
                      placeholder={literals.inputPersonOrGroup}
                      scope={scope}
                      isDisabled={loading}
                      onAdd={handleAddUser}
                      exclude={{
                        users: withAccess.users.map(aux => aux.id),
                        groups: withAccess.groups.map(aux => aux.id),
                      }}
                    />
                  </div>
                )
              }

              {level >= PERMISSION_LEVELS.EDIT ? (
                <>
                  <div className='fw-b mb-3'>{literals.usersWithAccess}</div>
                  {renderRows(withAccess.users)}

                  {(level >= PERMISSION_LEVELS.MANAGE && !isExternal) || withAccess.groups.length > 0 ? (
                    <>
                      <div className='fw-b mb-3'>{literals.groupsWithAccess}</div>
                      {renderRows(withAccess.groups)}
                    </>
                  ) : null}

                  {error && <OutputErrors errors={error} />}

                  {(canShare || isExternal) && (
                    <div className='buttons mt-3'>
                      <Button
                        text={value ? literalsCommon.confirm : literalsCommon.save}
                        onClick={handleSubmit}
                        loading={saving}
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className='text-center py-5'>
                  {literals.noPermissionsToSeeMembers}
                </div>
              )}
            </div>
          )
        }
      </Popup>
    </>
  );
};

PermissionsPopup.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  title: PropTypes.string,
  scope: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  value: PropTypes.object,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

PermissionsPopup.defaultProps = {
  title: '',
  value: null,
  onSubmit: null,
};

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.permissions,
    literalsCommon: state.i18n.literals.common,
  };
}

export default connect(mapStateToProps)(PermissionsPopup);
