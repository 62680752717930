export const KPI_PERIODICITY = {
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  ANNUAL: 'annual',
};

export const KPI_AGGREGATION = {
  EOP: 'eop',
  ACC: 'acc',
  AVG: 'avg',
};

export const KPI_CATEGORY = {
  BUSINESS: 'business',
  FINANCIAL: 'financial',
  ESG: 'esg',
};

export const KPI_UNIT = {
  FLOAT: 'float',
  PERCENT: 'percent',
  INTEGER: 'integer',
  CURRENCY: 'currency',
};

export const KPI_SCOPE = {
  ALL: 'all',
  STARTUP: 'startup',
  MENTOR: 'mentor',
};

export const KPI_TREND = {
  UP: 'up',
  DOWN: 'down',
};
