import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  deleteStartupCaptableAction, markStartupCaptableAsFavoriteAction, postStartupCaptableAction, putStartupCaptableAction,
} from 'components/StartupCaptableDate/modules/actions';
import CaptableCaptablesComponent from '../components';

const mapStateToProps = state => ({
  literalsCommon: state.i18n.literals.common,
  literals: state.i18n.literals.captable,
  currency: state.global.currency,
  startup: state.global.startup,
  user: state.session.user,
});

function mapDispatchToProps(dispatch) {
  return {
    postStartupCaptable: bindActionCreators(postStartupCaptableAction, dispatch),
    putStartupCaptable: bindActionCreators(putStartupCaptableAction, dispatch),
    markStartupCaptableAsFavorite: bindActionCreators(markStartupCaptableAsFavoriteAction, dispatch),
    deleteStartupCaptable: bindActionCreators(deleteStartupCaptableAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CaptableCaptablesComponent);
