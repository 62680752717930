import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputNumber from 'components/Inputs/inputNumber';
import InputSelect from 'components/Inputs/inputSelect';
import Table from 'components/Table';
import Collapse from 'components/Collapse/Collapse';
import PopupShareholderShares from 'routes/Captable/components/routes/shareHolders/components/routes/shareholderInfo/components/PopupShareholderShares';
import { fetchLocal } from 'components/Table/components/TableFunctions';
import { useCounter } from 'utils/customHooks';
import { formatNumber } from 'utils/functions';
import { SquareCheck, Square } from 'lucide-react';
import { getCaptableShare } from '../modules/actions';
import { overwriteValue } from '.';
import './styles.scss';

const CollapseShares = (props) => {
  const {
    literals,
    literalsCommon,
    match,
    currency,
    data,
    setData,
    isDisabled,
    forceFetch,
  } = props;

  const [shareDetails, setShareDetails] = useState(false);
  const [groupedClasses, setGroupedClasses] = useState({});
  const tableRefreshFlag = useCounter(0);

  useEffect(() => {
    const aux = {};
    data.forEach((sc) => {
      // eslint-disable-next-line no-param-reassign
      sc.items = sc.items.map(item => ({
        ...item,
        className: sc.name,
        ratio: overwriteValue(sc, 'ratio', sc.ratio),
        multiple: overwriteValue(sc, 'multiple', sc.multiple),
        participating: overwriteValue(sc, 'participating', sc.participating),
      }));
      let key = '';
      if (sc.type === 'common') {
        key = 'common';
      } else {
        key = sc.seniority;
      }
      if (!aux[key]) {
        aux[key] = [sc];
      } else {
        aux[key].push(sc);
      }
    });
    setGroupedClasses(aux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceFetch, tableRefreshFlag.value]);

  const fetchShares = (shares, page, size, search, filters, sort) => {
    return fetchLocal(
      { items: shares, total: shares.lenght },
      { search: ['shareholder.firstname', 'shareholder.lastname'] },
      page, size, search, filters, sort,
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getGeneralResult = (classes) => {
    const shares = [];
    classes.forEach(sc => shares.push(...sc.items));
    if (shares.every(share => share?.results?.liquidation?.selected && share?.results?.conversion?.selected)) {
      return 'both';
    }
    if (shares.every(share => share?.results?.liquidation?.selected)) {
      return 'liquidation';
    }
    if (shares.every(share => share?.results?.conversion?.selected)) {
      return 'conversion';
    }
    return 'custom';
  };

  const handleResults = (id, result) => {
    const other = result === 'liquidation' ? 'conversion' : 'liquidation';
    const auxGrouped = { ...groupedClasses };

    Object.keys(auxGrouped).forEach((seniority) => {
      auxGrouped[seniority] = auxGrouped[seniority].map(sc => ({
        ...sc,
        items: sc.items.map(item => (id === item.id ? {
          ...item,
          results: {
            [result]: item?.results ? { ...item.results[result], selected: true } : { value: 0, selected: true },
            [other]: item?.results ? { ...item.results[other], selected: false } : { value: 0, selected: false },
          },
        } : item)),
      }));
    });
    setGroupedClasses(auxGrouped);


    setData(prev => ({
      ...prev,
      shares: prev.shares.map(share => ({
        ...share,
        items: share.items.map(item => (id === item.id ? {
          ...item,
          results: {
            [result]: item?.results ? { ...item.results[result], selected: true } : { value: 0, selected: true },
            [other]: item?.results ? { ...item.results[other], selected: false } : { value: 0, selected: false },
          },
        } : item)),
      }
      )),
    }));
  };

  const handleSeniorityResults = (seniority, result) => {
    const other = result === 'liquidation' ? 'conversion' : 'liquidation';
    const auxGrouped = { ...groupedClasses };
    if (auxGrouped[seniority]) {
      auxGrouped[seniority] = auxGrouped[seniority].map((sc) => {
        return {
          ...sc,
          items: sc.items.map(item => (!overwriteValue(item, 'participating', item.participating) ? ({
            ...item,
            results: {
              [result]: item?.results ? { ...item.results[result], selected: true } : { value: 0, selected: true },
              [other]: item?.results ? { ...item.results[other], selected: false } : { value: 0, selected: false },
            },
          }) : item)),
        };
      });
      setGroupedClasses(auxGrouped);
    }

    const auxShares = data.map(share => (share.seniority === +seniority && !share?.overwrite?.participating ? ({
      ...share,
      items: share.items.map(item => ({
        ...item,
        results: {
          [result]: item?.results ? { ...item.results[result], selected: true } : { value: 0, selected: true },
          [other]: item?.results ? { ...item.results[other], selected: false } : { value: 0, selected: false },
        },
      })),
    }) : share));

    setData(prev => ({ ...prev, shares: auxShares }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handlePopupShare = async (row) => {
    setShareDetails({ loading: true });
    await getCaptableShare(match.params.id, row.id)
      .then(res => setShareDetails(res))
      .catch(() => setShareDetails(false));
  };

  const handleOnBlur = (id, rowId, field, value) => {
    setData(prev => ({
      ...prev,
      shares: prev.shares.map((shareclass) => {
        if (shareclass.id === id) {
          return {
            ...shareclass,
            items: shareclass.items.map((share) => {
              if (share.id === rowId) {
                if (value !== '') {
                  return { ...share, overwrite: { ...share.overwrite || {}, [field]: value } };
                }
                // eslint-disable-next-line no-param-reassign
                delete share.overwrite[field];
              }
              return share;
            }),
          };
        }
        return shareclass;
      }),
    }), true);
    if (field === 'participating') {
      tableRefreshFlag.increase();
    }
  };

  const getColumns = (id, type) => {
    let columns = [
      {
        field: 'shareholder',
        label: literals.shareholder,
        preRender: sh => (sh ? `${sh.firstname} ${sh.lastname || ''}` : 'Undefined'),
        onClick: handlePopupShare,
      },
      {
        field: 'className',
        label: literals.className,
        width: 150,
      },
      {
        field: 'number',
        label: literals.shares,
        preRender: val => formatNumber(val, 0, { dec: 0 }),
        width: 100,
      },
      {
        field: 'capital',
        label: literals.capital,
        preRender: val => formatNumber(val, 0, { dec: 2, symbol: currency.symbol }),
        width: 100,
      },
    ];
    if (type !== 'common') {
      columns = [...columns, ...[
        {
          field: 'ratio',
          label: literals.conversionRatio,
          preRender: (v, row) => (
            <InputNumber
              placeholder={formatNumber(v)}
              value={overwriteValue(row, 'ratio', v)}
              symbol='X'
              onBlur={nv => handleOnBlur(id, row.id, 'ratio', nv)}
              state
              stateTimeout={600}
              isDisabled={isDisabled}
            />
          ),
          width: 100,
        },
        {
          field: 'participating',
          label: literals.participating,
          preRender: (v, row) => (
            <InputSelect
              className='mb-0 w-100'
              value={overwriteValue(row, 'participating', v)}
              onChange={nv => handleOnBlur(id, row.id, 'participating', nv)}
              options={[
                { id: true, name: literalsCommon.yes },
                { id: false, name: literalsCommon.no },
              ]}
              isDisabled={isDisabled}
            />
          ),
        },
        {
          field: 'multiple',
          label: literals.liquidationMultiple,
          preRender: (v, row) => (
            <InputNumber
              placeholder={formatNumber(v)}
              value={overwriteValue(row, 'multiple', v)}
              symbol='X'
              onBlur={nv => handleOnBlur(id, row.id, 'multiple', nv)}
              state
              stateTimeout={600}
              isDisabled={isDisabled}
            />
          ),
          width: 100,
        },
        {
          field: 'results',
          label: 'Liq. Result',
          onClick: (row) => {
            const participating = overwriteValue(row, 'participating', row.participating);
            return !participating && !isDisabled && handleResults(row.id, 'liquidation');
          },
          preRender: (results, row) => {
            const participating = overwriteValue(row, 'participating', row.participating);
            return (
              <div className={participating || isDisabled ? 'disabled-checkbox' : ''}>
                { participating || results?.liquidation?.selected ? <SquareCheck size={16} /> : <Square size={16} />}
                <span className='pl-2'>{formatNumber(results?.liquidation?.value, 0, { dec: 2, symbol: currency.symbol })}</span>
              </div>
            );
          },
          width: 100,
        },
        {
          field: 'results',
          label: 'Conv. Result',
          onClick: (row) => {
            const participating = overwriteValue(row, 'participating', row.participating);
            return !participating && !isDisabled && handleResults(row.id, 'conversion');
          },
          preRender: (results, row) => {
            const participating = overwriteValue(row, 'participating', row.participating);
            return (
              <div className={participating || isDisabled ? 'disabled-checkbox' : ''}>
                { participating || results?.conversion?.selected ? <SquareCheck size={16} /> : <Square size={16} />}
                <span className='pl-2'>{formatNumber(results?.conversion?.value, 0, { dec: 2 })}</span>
              </div>
            );
          },
          width: 100,
        },
      ]];
    }

    return columns;
  };

  return (
    Object.keys(groupedClasses).map(seniority => (
      <Collapse
        className='shares-collapse'
        title={(
          <div className='collapse-header--flex'>
            <h5>{`${seniority === 'common' ? literals.common : seniority}. ${groupedClasses[seniority].map(sc => sc.name).join(', ')}`}</h5>
            {seniority !== 'common' && (
              <div onClick={e => e.stopPropagation()}>
                <InputSelect
                  onChange={v => handleSeniorityResults(seniority, v)}
                  value={getGeneralResult(groupedClasses[seniority])}
                  isRequired
                  options={getGeneralResult(groupedClasses[seniority]) === 'both' ? [
                    { id: 'both', name: literals.both },
                  ] : [
                    { id: 'liquidation', name: literals.liquidation },
                    { id: 'conversion', name: literals.conversion },
                    { id: 'custom', name: literals.custom, hide: true },
                  ]}
                  isDisabled={isDisabled}
                />
              </div>
            )}
          </div>
        )}
        state={false}
      >
        {groupedClasses[seniority].map(sc => (
          <Table
            key={`table_shares_${sc.id}`}
            columns={getColumns(sc.id, sc.type)}
            fetch={(page, size, search, filter, sort) => fetchShares(sc.items, page, size, search, filter, sort)}
            forceFetch={groupedClasses}
            fetchLocal
          />
        ))}
        {shareDetails && (
          <PopupShareholderShares
            literals={literals}
            literalsCommon={literalsCommon}
            currency={currency}
            match={match}
            onClose={() => setShareDetails(false)}
            row={shareDetails}
          />
        )}
      </Collapse>
    ))
  );
};

CollapseShares.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  forceFetch: PropTypes.bool.isRequired,
};

export default CollapseShares;
