import { getFullName } from 'utils/functions';

export const getPermissionGroupName = (item, literals) => {
  if (item.type === 'group') {
    if (item.default) {
      return literals.defaultGroups[item.name] || item.name;
    }
    return item.name;
  }

  return getFullName(item);
};
