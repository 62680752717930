import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputDate from 'components/Inputs/inputDate';
import Table from 'components/Table';
import {
  Eye, Pencil, Trash,
  LineChart, Info, Sheet,
} from 'lucide-react';
import { useCounter } from 'utils/customHooks';
import { KPI_PERIODICITY } from 'constants/kpis';
import { fetchModelKpiValues } from '../modules/actions';
import KpiRelationValuesGraph from './KpiRelationValuesGraph';
import KpiValueEditPopup from './KpiValueEditPopup';
import { formatKpiValue } from '../utils/functions';

const KpiRelationValues = (props) => {
  const {
    literals,
    literalsCommon,
    relation,
    relation: { kpi },
    currency,
    forceFetch,
    refreshFillTableFlag,
    onDelete,
    canEdit,
  } = props;

  const [mode, setMode] = useState('graph');
  const [kpiValueEditPopup, setKpiValueEditPopup] = useState(false);
  const intervalRefreshFlag = useCounter(0);
  const [interval, setInterval] = useState({
    from: (() => {
      const date = new Date();
      switch (kpi.periodicity) {
        case KPI_PERIODICITY.MONTHLY:
        case KPI_PERIODICITY.QUARTERLY:
          date.setDate(date.getDate() - 365);
          break;
        case KPI_PERIODICITY.ANNUAL:
          date.setDate(date.getDate() - (365 * 5));
          break;
        default:
          date.setDate(date.getDate() - 90);
          break;
      }
      return date.toISOString().split('T')[0];
    })(),
    to: new Date().toISOString().split('T')[0],
  });

  const columns = [
    { field: 'date', label: literalsCommon.date },
    {
      field: 'forecast',
      label: literals.forecast,
      grow: 1,
      preRender: v => formatKpiValue(v, kpi, null, currency),
    },
    {
      field: 'value',
      label: literals.value,
      grow: 1,
      preRender: v => formatKpiValue(v, kpi, null, currency),
    },
    {
      field: 'notes',
      width: '50px',
      preRender: (v) => {
        const notes = v.replace(/<[^>]+>/g, '');
        return notes
          ? <Info size={20} title={notes.length > 30 ? `${notes.substr(0, 29)}...` : notes} />
          : null;
      },
    },
  ];

  if (canEdit) {
    columns.push({
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Pencil, text: literalsCommon.edit, onClick: row => setKpiValueEditPopup(row),
        },
        {
          icon: Trash, text: literalsCommon.delete, onClick: row => onDelete(row), hidden: typeof onDelete !== 'function',
        },
      ],
    });
  } else {
    columns.push({
      field: 'id',
      type: 'button',
      button: { icon: <Eye />, text: literalsCommon.view, onClick: row => setKpiValueEditPopup(row) },
    });
  }

  const onChangeDate = (field, v) => {
    setInterval({ ...interval, [field]: v });
    intervalRefreshFlag.increase();
  };

  const fetchTableValues = (page, size) => {
    return fetchModelKpiValues(kpi.id, relation.scope.type, relation.scope.owner, relation.id, page, size, interval);
  };

  return (
    <div className='kpi-relation-graph' key={`graph-${relation.id}`}>
      <div className='krg-header'>
        <div className='krg-mode'>
          <div onClick={() => setMode('graph')} className={mode === 'graph' ? 'active' : ''}><LineChart size={18} /></div>
          <div onClick={() => setMode('table')} className={mode === 'table' ? 'active' : ''}><Sheet size={18} /></div>
        </div>
        <div className='krg-dates'>
          <div>
            <InputDate value={interval.from} onChange={v => onChangeDate('from', v)} />
          </div>
          <div>
            <InputDate value={interval.to} onChange={v => onChangeDate('to', v)} />
          </div>
        </div>
      </div>
      {
        mode === 'graph' ? (
          <KpiRelationValuesGraph
            relation={relation}
            currency={currency}
            from={interval.from}
            to={interval.to}
            forceFetch={forceFetch}
            literals={literals}
            literalsCommon={literalsCommon}
            onDelete={onDelete}
            canEdit={canEdit}
          />
        ) : (
          <Table
            columns={columns}
            searcher={false}
            pageSize={10}
            fetch={fetchTableValues}
            forceFetch={forceFetch + intervalRefreshFlag.value}
            onClickRow={row => setKpiValueEditPopup(row)}
          />
        )
      }

      { kpiValueEditPopup && (
        <KpiValueEditPopup
          kpiValue={kpiValueEditPopup}
          kpi={kpi}
          relation={relation}
          onClose={() => setKpiValueEditPopup(false)}
          refreshTable={() => {
            intervalRefreshFlag.increase();
            if (refreshFillTableFlag) {
              refreshFillTableFlag.increase();
            }
          }}
          onDelete={onDelete}
          canEdit={canEdit}
        />
      )}
    </div>
  );
};

KpiRelationValues.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  relation: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  forceFetch: PropTypes.number,
  refreshFillTableFlag: PropTypes.object,
  canEdit: PropTypes.bool.isRequired,
};

KpiRelationValues.defaultProps = {
  forceFetch: 0,
  onDelete: null,
  refreshFillTableFlag: null,
};

export default KpiRelationValues;
