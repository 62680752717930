import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import IOFileLoading from 'components/IOFileLoading/components';
import Button from 'components/Buttons/Button';
import Loading from 'components/Loading';
import { formatDate } from 'utils/functions';
import { connect } from 'react-redux';
import { downloadSignedDocument, fetchDocumentSignStatus } from './modules/actions';
import './styles.scss';

const DocumentStatusPopup = (props) => {
  const {
    literals,
    type,
    id,
    onClose,
  } = props;

  const [document, setDocuments] = useState();
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    (async () => {
      const auxDocument = await fetchDocumentSignStatus(type, id);
      setDocuments(auxDocument.documents[0]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownload = async (doc) => {
    let filename = document.file.name;
    if (document.status === 'completed') {
      filename = `${type}_${filename}`;
    }
    setDownloading(filename);
    await downloadSignedDocument(type, id, doc, filename);
    setDownloading(false);
  };

  return (
    <Popup title={literals.signatureStatus} onClose={onClose}>
      { document ? (
        <>
          <ul className='events-timeline'>
            {document.events.map(event => (
              <li key={event.created_at} className='file-previous-version'>
                <span>{literals.status[event.type]}</span>
                <span className='ml-3 fc-secondary fs-sm'>{formatDate(event.created_at, { time: true })}</span>
              </li>
            ))}
          </ul>
          <div className='buttons mt-3'>
            {
              document.status === 'completed' && (
                <Button
                  text={literals.downloadAudit}
                  color='secondary'
                  onClick={() => handleDownload('audit')}
                />
              )
            }
            <Button
              text={literals.downloadDocument}
              onClick={() => handleDownload('signed')}
            />
          </div>
          { downloading && <IOFileLoading mode='download' file={{ name: downloading }} /> }
        </>
      ) : <Loading mode='panel' hide={false} />}
    </Popup>
  );
};

DocumentStatusPopup.propTypes = {
  literals: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    literals: state.i18n.literals.signature,
  };
};

export default connect(mapStateToProps, {})(DocumentStatusPopup);
