export const FETCH_MENTORING = 'FETCH_MENTORING';
export const FETCH_MENTORING_SUCCESS = 'FETCH_MENTORING_SUCCESS';
export const FETCH_MENTORING_FAILURE = 'FETCH_MENTORING_FAILURE';

export const FETCH_MENTORING_MESSAGES = 'FETCH_MENTORING_MESSAGES';
export const FETCH_MENTORING_MESSAGES_SUCCESS = 'FETCH_MENTORING_MESSAGES_SUCCESS';
export const FETCH_MENTORING_MESSAGES_FAILURE = 'FETCH_MENTORING_MESSAGES_FAILURE';
export const CREATE_MENTORING_MESSAGE = 'CREATE_MENTORING_MESSAGE';
export const CREATE_MENTORING_MESSAGE_SUCCESS = 'CREATE_MENTORING_MESSAGE_SUCCESS';
export const CREATE_MENTORING_MESSAGE_FAILURE = 'CREATE_MENTORING_MESSAGE_FAILURE';

export const FETCH_MENTORING_MEETINGS = 'FETCH_MENTORING_MEETINGS';
export const FETCH_MENTORING_MEETINGS_SUCCESS = 'FETCH_MENTORING_MEETINGS_SUCCESS';
export const FETCH_MENTORING_MEETINGS_FAILURE = 'FETCH_MENTORING_MEETINGS_FAILURE';
export const CREATE_MENTORING_MEETINGS = 'CREATE_MENTORING_MEETINGS';
export const CREATE_MENTORING_MEETINGS_SUCCESS = 'CREATE_MENTORING_MEETINGS_SUCCESS';
export const CREATE_MENTORING_MEETINGS_FAILURE = 'CREATE_MENTORING_MEETINGS_FAILURE';
export const EDIT_MENTORING_MEETINGS = 'EDIT_MENTORING_MEETINGS';
export const EDIT_MENTORING_MEETINGS_SUCCESS = 'EDIT_MENTORING_MEETINGS_SUCCESS';
export const EDIT_MENTORING_MEETINGS_FAILURE = 'EDIT_MENTORING_MEETINGS_FAILURE';
export const DELETE_MENTORING_MEETINGS = 'DELETE_MENTORING_MEETINGS';
export const DELETE_MENTORING_MEETINGS_SUCCESS = 'DELETE_MENTORING_MEETINGS_SUCCESS';
export const DELETE_MENTORING_MEETINGS_FAILURE = 'DELETE_MENTORING_MEETINGS_FAILURE';

export const FETCH_MENTORING_DOCUMENTS = 'FETCH_MENTORING_DOCUMENTS';
export const FETCH_MENTORING_DOCUMENTS_SUCCESS = 'FETCH_MENTORING_DOCUMENTS_SUCCESS';
export const FETCH_MENTORING_DOCUMENTS_FAILURE = 'FETCH_MENTORING_DOCUMENTS_FAILURE';
export const CREATE_MENTORING_DOCUMENTS = 'CREATE_MENTORING_DOCUMENTS';
export const CREATE_MENTORING_DOCUMENTS_SUCCESS = 'CREATE_MENTORING_DOCUMENTS_SUCCESS';
export const CREATE_MENTORING_DOCUMENTS_FAILURE = 'CREATE_MENTORING_DOCUMENTS_FAILURE';
export const DELETE_MENTORING_DOCUMENTS = 'DELETE_MENTORING_DOCUMENTS';
export const DELETE_MENTORING_DOCUMENTS_SUCCESS = 'DELETE_MENTORING_DOCUMENTS_SUCCESS';
export const DELETE_MENTORING_DOCUMENTS_FAILURE = 'DELETE_MENTORING_DOCUMENTS_FAILURE';

export const FETCH_MENTORING_REPORTS = 'FETCH_MENTORING_REPORTS';
export const FETCH_MENTORING_REPORTS_SUCCESS = 'FETCH_MENTORING_REPORTS_SUCCESS';
export const FETCH_MENTORING_REPORTS_FAILURE = 'FETCH_MENTORING_REPORTS_FAILURE';
export const CREATE_MENTORING_REPORTS = 'CREATE_MENTORING_REPORTS';
export const CREATE_MENTORING_REPORTS_SUCCESS = 'CREATE_MENTORING_REPORTS_SUCCESS';
export const CREATE_MENTORING_REPORTS_FAILURE = 'CREATE_MENTORING_REPORTS_FAILURE';
export const EDIT_MENTORING_REPORTS = 'EDIT_MENTORING_REPORTS';
export const EDIT_MENTORING_REPORTS_SUCCESS = 'EDIT_MENTORING_REPORTS_SUCCESS';
export const EDIT_MENTORING_REPORTS_FAILURE = 'EDIT_MENTORING_REPORTS_FAILURE';
export const DELETE_MENTORING_REPORTS = 'DELETE_MENTORING_REPORTS';
export const DELETE_MENTORING_REPORTS_SUCCESS = 'DELETE_MENTORING_REPORTS_SUCCESS';
export const DELETE_MENTORING_REPORTS_FAILURE = 'DELETE_MENTORING_REPORTS_FAILURE';
