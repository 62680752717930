import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactECharts from 'echarts-for-react';
import SkeletonBarChart from 'components/Loading/skeletonBarChart';
import { formatNumber, getColorRange } from 'utils/functions';
import './styles.scss';

const MyPositionValuation = (props) => {
  const {
    literals,
    valuations,
    lastValuation,
    lastPPS,
    currency,
    loading,
  } = props;

  const colors = getColorRange(0);
  const series = useMemo(() => {
    const aux = { valuation: [], pps: [], equityGrowth: [] };

    if (valuations?.length > 0) {
      valuations.forEach((val, i) => {
        const valuation = val.valuation;
        const prevValuation = i > 0 ? valuations[i - 1].valuation : 0;

        aux.valuation.push({ value: valuation, itemStyle: { color: colors[0] } });
        aux.pps.push({ value: val.pps });
        aux.equityGrowth.push({ value: prevValuation > 0 ? valuation / prevValuation : 0 });
      });

      aux.valuation.push({ value: lastValuation, itemStyle: { color: colors[1] } });
      aux.pps.push({ value: lastPPS });
      aux.equityGrowth.push({ value: lastValuation / valuations[valuations.length - 1].valuation });
    }

    return aux;
  }, [colors, lastPPS, lastValuation, valuations]);

  if (!valuations) return null;

  const chartOption = {
    grid: {
      left: '15%', bottom: '5%', right: '5%', top: '5%',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' },
    },
    xAxis: {
      type: 'category',
      data: [...valuations.map(val => val.name), literals.simulation],
      axisLabel: { show: false },
      z: 10,
    },
    yAxis: { type: 'value' },
    series: [
      {
        type: 'bar',
        name: literals.valuation,
        data: series.valuation,
        barGap: 1,
        tooltip: { valueFormatter: v => formatNumber(v, '-', { symbol: currency.symbol }) },
      },
      {
        type: 'bar',
        name: literals.pps,
        data: series.pps,
        itemStyle: { opacity: 0 },
        barGap: '-100%',
        tooltip: { valueFormatter: v => formatNumber(v, '-', { symbol: currency.symbol }) },
      },
      {
        type: 'bar',
        name: literals.equityGrowth,
        data: series.equityGrowth,
        itemStyle: { opacity: 0 },
        barGap: '-100%',
        tooltip: { valueFormatter: v => formatNumber(v, '-', { symbol: 'x' }) },
      },
    ],
  };

  return (
    <>
      <h4 className='fw-b '>{literals.valuation}</h4>
      { !loading
        ? <ReactECharts option={chartOption} style={{ height: 202, marginTop: 25 }} />
        : <SkeletonBarChart height='215px' className='mb-2' />
      }
    </>
  );
};

MyPositionValuation.propTypes = {
  literals: PropTypes.object.isRequired,
  valuations: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  lastValuation: PropTypes.string.isRequired,
  lastPPS: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default MyPositionValuation;
