import { connect } from 'react-redux';
import AdminServices from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.services,
  literalsCommon: state.i18n.literals.common,
  lang: state.i18n.language,
});

export default connect(mapStateToProps)(AdminServices);
