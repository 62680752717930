import StartupsCaptablesInstance from 'modules/startupsCaptables/repository';
import StartupsInstance from 'modules/startups/repository';

import { apiDownloadFile } from 'modules/actions';
import { API_SHAREHOLDER_REPORT } from 'modules/apiEndpoints';


export function getShareholderPositionResource(startup, shareholders, resource, params) {
  return StartupsCaptablesInstance.getStartupShareholderPositionResource(startup, resource, shareholders, params);
}

export function getShareholderPosition(startup, opts) {
  return StartupsCaptablesInstance.getStartupShareholderPosition(startup, opts);
}

export function fetchStartupProfile(id) {
  return StartupsInstance.getStartupInfo(id);
}

export function downloadReportShareholder(startup, shareholder, filename) {
  return apiDownloadFile(API_SHAREHOLDER_REPORT(startup, shareholder), filename);
}
