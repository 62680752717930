import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import { formatNumber } from 'utils/functions';
import { colorGraphDown, colorGraphUp } from 'constants/colors';
import { ChevronUp, ChevronDown, ChevronRight } from 'lucide-react';
import './styles.scss';

const CaptableSummaryTable = (props) => {
  const {
    literals,
    currency,
    loading,
    groupBy,
    rows,
    sortedColumn,
    setSortedColumn,
  } = props;

  const [closedGroups, setClosedGroups] = useState([]);

  const columns = [
    {
      field: 'shares',
      name: literals.shares,
      format: {},
      small: false,
      id: 'position.shares.number',
    },
    {
      field: 'options',
      name: literals.options,
      small: false,
      id: 'position.options.number',
    },
    {
      field: 'ownership',
      name: literals.ownership,
      format: { dec: 4, symbol: '%' },
      small: true,
      id: 'position.ownership.nonFullyDiluted',
    },
    {
      field: 'fullyDiluted',
      name: literals.fullyDiluted,
      format: { dec: 4, symbol: '%' },
      small: true,
      id: 'position.ownership.fullyDiluted',
    },
    {
      field: 'votes',
      name: literals.vote,
      format: { dec: 4, symbol: '%' },
      small: false,
      id: 'position.shares.votes',
    },
    {
      field: 'capital',
      name: literals.capital,
      format: { dec: 2, symbol: currency.symbol },
      small: false,
      id: 'position.shares.capital',
    },
    {
      field: 'value',
      name: literals.value,
      format: { dec: 2, symbol: currency.symbol },
      small: false,
      id: 'position.shares.value',
    },
  ];

  const diffIcons = {
    up: <ChevronUp fill={colorGraphUp} color={colorGraphUp} />,
    down: <ChevronDown fill={colorGraphDown} color={colorGraphDown} />,
  };

  const sortTable = (col) => {
    setSortedColumn((prev) => {
      let sort = '-';
      if (prev && prev.name === col) {
        if (prev.sort === '') {
          sort = '-';
        } else if (prev.sort === '-') {
          sort = '+';
        } else {
          sort = '';
        }
      }
      return { name: sort ? col : '', sort };
    });
  };

  const handleClosedGroups = (group) => {
    if (closedGroups.includes(group)) {
      setClosedGroups(prev => prev.filter(closedGroup => closedGroup !== group));
    } else {
      setClosedGroups(prev => [...prev, group]);
    }
  };

  const renderRow = (row) => {
    const prev = row.prev || null;
    const isGroup = !!row.shareholders;
    const name = row.name === 'total' ? '' : literals[row.name] || row.name;

    return (
      <div
        key={row.id}
        onClick={isGroup ? () => handleClosedGroups(row.id) : null}
        className={`
          table-row
          ${row.id === 'unassigned' ? 'tr-unassigned' : ''}
          ${row.id === 'total' ? 'tr-total' : ''}
          ${isGroup && row.id !== 'unassigned' ? 'pointer' : ''}
        `}
      >
        <div className={`cell-name ${row.id === 'unassigned' ? 'font-italic' : ''}`}>
          {isGroup && row.id !== 'unassigned' && (
            closedGroups.includes(row.id)
              ? <ChevronRight size={13} className='mr-1' />
              : <ChevronDown size={13} className='mr-1' />
          )}
          <span className={`${isGroup ? 'fw-b' : ''} ${groupBy !== 'shareholder' && !isGroup ? 'pl-3' : ''}`}>{name}</span>
          {prev && prev.isNew ? (<span className='row-status row-new' />) : null}
          {prev && prev.isDeleted ? (<span className='row-status row-deleted' />) : null}
        </div>

        <div className='cell-columns'>
          {
            columns.map((column) => {
              const diff = prev ? row[column.field] - prev[column.field] : null;
              // eslint-disable-next-line no-nested-ternary
              const diffIcon = prev ? (diff > 0 ? 'up' : (diff < 0 ? 'down' : null)) : null;
              return (
                <div key={`cell-${row.id}-${column.field}`} className='cell-value'>
                  {formatNumber(row[column.field], 0, column.format)}
                  { diff ? (
                    <span>
                      {diffIcons[diffIcon]}
                      {formatNumber(Math.abs(diff), 0, { dec: column?.format?.dec })}
                    </span>
                  ) : ''}
                </div>
              );
            })
          }
        </div>
      </div>
    );
  };

  const renderRows = () => {
    if (loading) {
      return [...Array(5)].map((_, i) => (
        <div key={`loading-row-${i}`} className='table-row'>
          <div className='cell-name'>
            {i < 4 && <Skeleton animation='wave' className='mb-3' height={18} width='80%' />}
          </div>
          <div className='cell-columns'>
            { columns.map((__, j) => (
              <div key={`loading-column-${j}`} className='cell-value d-flex justify-content-end'>
                <Skeleton animation='wave' className='mb-3' height={18} width='80%' />
              </div>
            ))}
          </div>
        </div>
      ));
    }

    if (groupBy === 'shareholder' && Array.isArray(rows)) {
      return rows.map(renderRow);
    }

    if (groupBy === 'role' || groupBy === 'syndicate') {
      return (
        <>
          {rows.map(group => (
            <Fragment key={group.id}>
              { renderRow(group)}
              { !closedGroups.includes(group.id) && group?.shareholders?.map(renderRow) }
            </Fragment>
          ))}
        </>
      );
    }
    return null;
  };

  return (
    <div className='table'>
      <div className='table-overflow'>
        <div className='table-header'>
          <div className='cell-name'>{literals.shareholder}</div>
          <div className='cell-columns pointer'>
            {
              columns.map(column => (
                <div
                  className='d-flex justify-content-start cell-value'
                  key={`header-${column.id}`}
                  onClick={() => sortTable(column.id)}
                >
                  {column.name}
                  {sortedColumn.name === column.id ? (
                    <div className='d-flex flex-column pl-2'>
                      <ChevronUp fill='currentColor' size={10} className={sortedColumn.sort !== '-' && 'disabled'} />
                      <ChevronDown fill='currentColor' size={10} className={sortedColumn.sort !== '+' && 'disabled'} />
                    </div>
                  ) : (
                    <div className='d-flex flex-column pl-2'>
                      <ChevronUp fill='currentColor' size={10} className='disabled' />
                      <ChevronDown fill='currentColor' size={10} className='disabled' />
                    </div>
                  )}
                </div>
              ))
            }
          </div>
        </div>
        <div className='table-rows'>
          {renderRows()}
        </div>
      </div>
    </div>
  );
};

CaptableSummaryTable.propTypes = {
  currency: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  groupBy: PropTypes.string.isRequired,
  rows: PropTypes.array,
  sortedColumn: PropTypes.object.isRequired,
  setSortedColumn: PropTypes.func.isRequired,
};

CaptableSummaryTable.defaultProps = {
  rows: [],
};

export default CaptableSummaryTable;
