import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputText from 'components/Inputs/inputText';
import Button from 'components/Buttons/Button';
import InputEmail from 'components/Inputs/inputEmail';
import InputFile from 'components/Inputs/inputFile';
import InputToggles from 'components/Inputs/inputToggles';
import InputTextArea from 'components/Inputs/inputTextArea';
import InputPassword from 'components/Inputs/inputPassword';
import { Settings } from 'lucide-react';
import {
  TOOL_CAP_TABLE, TOOL_DATA_ROOM, TOOL_MENTORINGS,
  TOOL_PERFORMANCE, TOOL_PROFILE, TOOL_REPORTING,
  TOOL_SERVICES, TOOL_USERS, TOOL_MESSAGES, TOOL_FUNDRAISE,
} from 'constants/tools';
import ChangePermissionsPopup from './ChangePermissionsPopup';
import EmailBotPopup from './EmailBotPopup';

const sortedTools = [
  TOOL_CAP_TABLE,
  TOOL_FUNDRAISE,
  TOOL_PERFORMANCE,
  TOOL_MENTORINGS,
  TOOL_REPORTING,
  TOOL_DATA_ROOM,
  TOOL_SERVICES,
  TOOL_USERS,
  TOOL_PROFILE,
  TOOL_MESSAGES,
];

const FormGeneral = (props) => {
  const {
    literals,
    literalsCommon,
    general,
    onSubmit,
  } = props;

  const [data, setData] = useState(general);
  const [loading, setLoading] = useState(false);
  const [invalidJson, setInvalidJson] = useState(false);
  const [changePermissionsPopup, setChangePermissionsPopup] = useState(false);
  const [emailBotPopup, setEmailBotPopup] = useState(false);

  const submitData = (event) => {
    event.preventDefault();
    setLoading(true);
    const formatData = { ...data };

    if (formatData.logo && typeof formatData.logo === 'object') {
      formatData.logo = formatData.logo.id;
    }
    if (formatData.favicon && typeof formatData.favicon === 'object') {
      formatData.favicon = formatData.favicon.id;
    }
    if (formatData.bgPublicDesktop && typeof formatData.bgPublicDesktop === 'object') {
      formatData.bgPublicDesktop = formatData.bgPublicDesktop.id;
    }
    if (formatData.bgPublicMobile && typeof formatData.bgPublicMobile === 'object') {
      formatData.bgPublicMobile = formatData.bgPublicMobile.id;
    }
    let errorLiterals = false;
    setInvalidJson(false);
    if (typeof formatData.properties.literals !== 'object') {
      try {
        formatData.properties.literals = JSON.parse(formatData.properties.literals);
      } catch (e) {
        errorLiterals = true;
      }
    }
    if (typeof formatData.analytics.ga4.credentials !== 'object') {
      try {
        formatData.analytics.ga4.credentials = JSON.parse(formatData.analytics.ga4.credentials || '{}');
      } catch (e) {
        errorLiterals = true;
      }
    }
    if (!errorLiterals) {
      onSubmit(formatData);
    } else {
      setLoading(false);
      setInvalidJson(true);
    }
  };

  const setAttr = (value, event) => {
    const field = event.target.name.split('.');
    const updateData = { ...data };

    let currentField = updateData;
    field.forEach((prop, index) => {
      if (index === field.length - 1) {
        currentField[prop] = value;
      } else {
        currentField[prop] = currentField[prop] || {};
        currentField = currentField[prop];
      }
    });

    setData(updateData);
  };

  const setFieldValue = (value, field) => {
    setData({ ...data, [field]: value });
  };

  const setPropertiesField = (value, field) => {
    setFieldValue({ ...data.properties, [field]: value }, 'properties');
  };

  const openChangePermissionsPopup = (e, tool) => {
    e.stopPropagation();
    setChangePermissionsPopup(tool);
  };

  return (
    <div className='tabStartupOptions'>
      <form className='startupOptions' onSubmit={submitData}>
        <div className='questions-wrapper'>
          <InputText preText={literals.name} name='name' onChange={setAttr} value={data.name} />
          <div className='row'>
            <div className='col-12 col-md-6'>
              <InputFile preText={literals.favicon} onChange={v => setFieldValue(v, 'favicon')} value={data.favicon} isPublic />
            </div>
            <div className='col-12 col-md-6'>
              <InputFile preText={literals.logo} name='logo' onChange={v => setFieldValue(v, 'logo')} value={data.logo} isPublic />
            </div>
            <div className='col-12 col-md-6'>
              <InputFile preText={literals.bgPublicDesktop} name='logo' onChange={v => setFieldValue(v, 'bgPublicDesktop')} value={data.bgPublicDesktop} isPublic />
            </div>
            <div className='col-12 col-md-6'>
              <InputFile preText={literals.bgPublicMobile} name='logo' onChange={v => setFieldValue(v, 'bgPublicMobile')} value={data.bgPublicMobile} isPublic />
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <InputText preText={literals.mainColor} name='colors.primary' placeholder='#333...' onChange={setAttr} value={data.colors.primary} />
            </div>
            <div className='col-12 col-md-6'>
              <InputText preText='OneTrust' name='cmp.oneTrust' placeholder='XXXX-XXXXXXX-XX-XXXXX' onChange={setAttr} value={data?.cmp?.oneTrust} />
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <InputText preText='OAuth Google' name='oauth.google' placeholder='' onChange={setAttr} value={data?.oauth?.google} />
            </div>
            <div className='col-12 col-md-6'>
              <InputText preText={literals.signaturitKey} name='signaturit.key' placeholder={literals.signaturitKey} onChange={setAttr} value={data?.signaturit?.key} />
            </div>
            <div className='col-12 col-md-6'>
              <InputEmail
                preText={(
                  <>
                    {`${literals.emails.emailBot} `}
                    <span className='link-effect' onClick={() => setEmailBotPopup(true)}>{`(${literals.seeLogs})`}</span>
                  </>
                )}
                key='emailBot.email'
                name='emailBot.email'
                onChange={setAttr}
                value={data.emailBot?.email}
              />
            </div>
            <div className='col-12 col-md-6'>
              <InputPassword preText={literals.emails.emailBotPassword} key='emailBot.password' name='emailBot.password' onChange={setAttr} value={data.emailBot?.password} />
            </div>
            <div className='col-12 col-md-4'>
              <InputText preText={literals.googleAnalytics} name='analytics.ga4.measurement' placeholder='G-XXXXXXXXXX' onChange={setAttr} value={data.analytics.ga4.measurement} />
            </div>
            <div className='col-12 col-md-4'>
              <InputText preText={literals.googleAnalyticsProperty} name='analytics.ga4.property' placeholder='000000000' onChange={setAttr} value={data.analytics.ga4.property} />
            </div>
            <div className='col-12 col-md-4'>
              <InputText preText={literals.googleAnalyticsCredentials} name='analytics.ga4.credentials' placeholder='{}' maxLength={99999} onChange={setAttr} value={data.analytics.ga4.credentials} />
            </div>
          </div>
          <InputToggles
            preText={literals.configSignups}
            onChange={v => setPropertiesField(v, 'signup')}
            value={data.properties.signup}
            options={[
              { id: 'public', label: literals.signupExternal },
              { id: 'prehome', label: literals.signupInternal },
            ]}
          />
          <InputToggles
            preText={literals.configTools}
            onChange={v => setPropertiesField(v, 'tools')}
            value={data.properties.tools}
            options={Object.keys(data.properties.tools)
              .map(tool => ({
                id: tool,
                label: (
                  <>
                    {literals.tools[tool]}
                    <Settings onClick={e => openChangePermissionsPopup(e, tool)} className='gear-svg' />
                  </>
                ),
              }))
              .sort((a, b) => sortedTools.indexOf(a.id) - sortedTools.indexOf(b.id))
            }
          />

          <InputTextArea
            preText={literals.jsonLiterals}
            error={invalidJson}
            onChange={v => setPropertiesField(v, 'literals')}
            value={typeof data.properties.literals === 'object' ? JSON.stringify(data.properties.literals) : data.properties.literals}
          />
        </div>
        <div className='buttons'>
          <Button text={literals.save} type='submit' loading={loading} />
        </div>
      </form>
      {
        changePermissionsPopup && (
          <ChangePermissionsPopup
            literals={literals}
            tool={changePermissionsPopup}
            onClose={() => setChangePermissionsPopup(false)}
          />
        )
      }
      {
        emailBotPopup && (
          <EmailBotPopup
            literals={literals}
            literalsCommon={literalsCommon}
            onClose={() => setEmailBotPopup(false)}
          />
        )
      }
    </div>
  );
};

FormGeneral.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  general: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FormGeneral;
