import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import Portal from 'components/Portal';
import { Link } from 'react-router-dom';
import { getFullName } from 'utils/functions';
import { useRect } from 'utils/customHooks';
import { SquareUser, DoorClosed, Info } from 'lucide-react';
import { ROLE_ADMIN, ROLE_USER } from 'constants/roles';
import {
  ROUTE_PATH, ROUTE_MENTOR_PATH, ROUTE_ADMIN_PATH, ROUTE_STARTUP_PATH,
} from 'routes';

const Profile = ({ user, literals }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [bbox, ref] = useRect(showOptions);

  let helpRoute = ROUTE_MENTOR_PATH.HELP;
  if (user?.role?.includes(ROLE_USER)) {
    helpRoute = ROUTE_STARTUP_PATH.HELP;
  } else if (user?.role?.includes(ROLE_ADMIN)) {
    helpRoute = ROUTE_ADMIN_PATH.HELP;
  }

  const styles = { visible: true, zIndex: 12 };
  if (bbox) {
    styles.top = bbox.bottom - 10;
    styles.left = bbox.left - 235;
  }

  if (window.innerWidth < 600) {
    styles.top = 95;
    styles.left = 'auto';
    styles.right = 15;
  }

  return (
    <div ref={ref} className='default-profile-image' onClick={() => setShowOptions(!showOptions)}>
      <Avatar
        title={getFullName(user)}
        src={user?.avatar?.path}
        size={45}
      />

      {showOptions && (
        <Portal type='top-bar' styles={styles} onClose={() => setShowOptions(false)}>
          <div className='topbar-popup profile-options-wrapper'>
            <Link to={ROUTE_PATH.EDIT_ACCOUNT} className='option-item'>
              <SquareUser size={26} className='option-icon' />
              {literals.account}
            </Link>
            <Link to={helpRoute} className='option-item'>
              <Info size={26} className='option-icon' />
              {literals.help}
            </Link>
            <Link to={ROUTE_PATH.LOGOUT} className='option-item'>
              <DoorClosed size={26} className='option-icon' />
              {literals.logout}
            </Link>
          </div>
        </Portal>
      )}
    </div>
  );
};

Profile.propTypes = {
  user: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
};

export default Profile;
