import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const MentoringsInstance = ApiFetch();

export const URLS = {
  CREATE_FEEDBACK: 'mentorings/:id/feedbacks',
  CREATE_MEETING: 'mentorings/:id/meetings',
  CREATE_MENTORING: 'mentorings',
  CREATE_MENTORING_DOCUMENT: 'mentorings/:id/documents',
  CREATE_MENTORING_REPORT: 'mentorings/:id/reports',
  CREATE_MESSAGE: 'mentorings/:id/messages',
  DELETE_MEETING: 'mentorings/:id/meetings/:meeting',
  DELETE_MENTORING: 'mentorings/:id',
  DELETE_MENTORING_DOCUMENT: 'mentorings/:id/documents/:document',
  DELETE_MENTORING_REPORT: 'mentorings/:id/reports/:report',
  EDIT_MEETING: 'mentorings/:id/meetings/:meeting',
  EDIT_MENTORING_REPORT: 'mentorings/:id/reports/:report',
  GET_FEEDBACK: 'mentorings/:id/feedbacks/:feedback',
  GET_MENTORING: 'mentorings/:id',
  GET_MENTORING_CERTIFICATE: 'mentorings/:id/certificates',
  GET_MENTORING_DOCUMENTS: 'mentorings/:id/documents',
  GET_MENTORING_EVENTS: 'mentorings/events',
  GET_MENTORING_MEETINGS: 'mentorings/:id/meetings',
  GET_MENTORING_MENTOR: 'mentorings/mentors/:id',
  GET_MENTORING_MENTORS: 'mentorings/mentors',
  GET_MENTORING_MESSAGES: 'mentorings/:id/messages',
  GET_MENTORING_PROFILE: 'mentorings/profile',
  GET_MENTORING_REPORT: 'mentorings/:id/reports/:report',
  GET_MENTORING_REPORTS: 'mentorings/:id/reports',
  GET_MENTORING_STARTUPS: 'mentorings/startups',
  GET_MENTORINGS: 'mentorings',
  GET_MENTORINGS_DOCUMENTS: 'mentorings/documents',
  GET_MENTORINGS_MESSAGES: 'mentorings/messages',
  GET_MENTORINGS_SUMMARY: 'mentorings/summary',
  MENTORING_STATUS: 'mentorings/:id/status',
  PUT_MENTORING_MENTOR: 'mentorings/mentors/:id',
  SAVE_MENTORING_ONBOARDING: 'mentorings/mentors',
  SAVE_MENTORING_PROFILE: 'mentorings/profile',
};

async function createFeedback(id, data) {
  const path = preparePath(URLS.CREATE_FEEDBACK, { id });
  return MentoringsInstance.post(path, data);
}

async function createMeeting(id, data) {
  const path = preparePath(URLS.CREATE_MEETING, { id });
  return MentoringsInstance.post(path, data);
}

async function createMentoring(data) {
  return MentoringsInstance.post(URLS.CREATE_MENTORING, data);
}

async function createMentoringDocument(id, file) {
  const path = preparePath(URLS.CREATE_MENTORING_DOCUMENT, { id });
  return MentoringsInstance.postForm(path, { file });
}

async function createMentoringReport(id, data) {
  const path = preparePath(URLS.CREATE_MENTORING_REPORT, { id });
  return MentoringsInstance.post(path, data);
}

async function createMessage(id, data) {
  const path = preparePath(URLS.CREATE_MESSAGE, { id });
  return MentoringsInstance.post(path, data);
}

async function deleteMeeting(id, meeting) {
  const path = preparePath(URLS.DELETE_MEETING, { id, meeting });
  return MentoringsInstance.delete(path);
}

async function deleteMentoring(id) {
  const path = preparePath(URLS.DELETE_MENTORING, { id });
  return MentoringsInstance.delete(path);
}

async function deleteMentoringDocument(id, document) {
  const path = preparePath(URLS.DELETE_MENTORING_DOCUMENT, { id, document });
  return MentoringsInstance.delete(path);
}

async function deleteMentoringReport(id, report) {
  const path = preparePath(URLS.DELETE_MENTORING_REPORT, { id, report });
  return MentoringsInstance.delete(path);
}

async function editMeeting(id, meeting, data) {
  const path = preparePath(URLS.EDIT_MEETING, { id, meeting });
  return MentoringsInstance.put(path, data);
}

async function editMentoringReport(id, report, data) {
  const path = preparePath(URLS.EDIT_MENTORING_REPORT, { id, report });
  return MentoringsInstance.put(path, data);
}

async function getFeedback(id, feedback) {
  const path = preparePath(URLS.GET_FEEDBACK, { id, feedback });
  return MentoringsInstance.get(path);
}

async function getMentoring(id) {
  const path = preparePath(URLS.GET_MENTORING, { id });
  return MentoringsInstance.get(path);
}

async function getMentoringCertificate(id) {
  const path = preparePath(URLS.GET_MENTORING_CERTIFICATE, { id });
  return MentoringsInstance.get(path);
}

async function getMentoringDocuments(id) {
  const path = preparePath(URLS.GET_MENTORING_DOCUMENTS, { id });
  return MentoringsInstance.get(path);
}

async function getMentoringEvents() {
  return MentoringsInstance.get(URLS.GET_MENTORING_EVENTS);
}

async function getMentoringMeetings(id, params = {}) {
  const path = preparePath(URLS.GET_MENTORING_MEETINGS, { id }, params);
  return MentoringsInstance.get(path);
}

async function getMentoringMentor(id) {
  const path = preparePath(URLS.GET_MENTORING_MENTOR, { id });
  return MentoringsInstance.get(path);
}

async function getMentoringMentors(params = {}) {
  const path = preparePath(URLS.GET_MENTORING_MENTORS, {}, params);
  return MentoringsInstance.get(path);
}

async function getMentoringMessages(id, params = {}) {
  const path = preparePath(URLS.GET_MENTORING_MESSAGES, { id }, params);
  return MentoringsInstance.get(path);
}

async function getMentoringProfile() {
  return MentoringsInstance.get(URLS.GET_MENTORING_PROFILE);
}

async function getMentoringReport(id, report) {
  const path = preparePath(URLS.GET_MENTORING_REPORT, { id, report });
  return MentoringsInstance.get(path);
}

async function getMentoringReports(id, params = {}) {
  const path = preparePath(URLS.GET_MENTORING_REPORTS, { id }, params);
  return MentoringsInstance.get(path);
}

async function getMentoringStartups(params = {}) {
  const path = preparePath(URLS.GET_MENTORING_STARTUPS, {}, params);
  return MentoringsInstance.get(path);
}

async function getMentorings(params = {}) {
  const path = preparePath(URLS.GET_MENTORINGS, {}, params);
  return MentoringsInstance.get(path);
}

async function getMentoringsDocuments() {
  return MentoringsInstance.get(URLS.GET_MENTORINGS_DOCUMENTS);
}

async function getMentoringsMessages() {
  return MentoringsInstance.get(URLS.GET_MENTORINGS_MESSAGES);
}

async function getMentoringsSummary(params = {}) {
  const path = preparePath(URLS.GET_MENTORINGS_SUMMARY, {}, params);
  return MentoringsInstance.get(path);
}

async function mentoringStatus(id, data) {
  const path = preparePath(URLS.MENTORING_STATUS, { id });
  return MentoringsInstance.put(path, data);
}

async function putMentoringMentor(id, data) {
  const path = preparePath(URLS.PUT_MENTORING_MENTOR, { id });
  return MentoringsInstance.put(path, data);
}

async function saveMentoringOnboarding(data) {
  return MentoringsInstance.put(URLS.SAVE_MENTORING_ONBOARDING, data);
}

async function saveMentoringProfile(data) {
  return MentoringsInstance.put(URLS.SAVE_MENTORING_PROFILE, data);
}

export default {
  createFeedback,
  createMeeting,
  createMentoring,
  createMentoringDocument,
  createMentoringReport,
  createMessage,
  deleteMeeting,
  deleteMentoring,
  deleteMentoringDocument,
  deleteMentoringReport,
  editMeeting,
  editMentoringReport,
  getFeedback,
  getMentoring,
  getMentoringCertificate,
  getMentoringDocuments,
  getMentoringEvents,
  getMentoringMeetings,
  getMentoringMentor,
  getMentoringMentors,
  getMentoringMessages,
  getMentoringProfile,
  getMentoringReport,
  getMentoringReports,
  getMentoringStartups,
  getMentorings,
  getMentoringsDocuments,
  getMentoringsMessages,
  getMentoringsSummary,
  mentoringStatus,
  putMentoringMentor,
  saveMentoringOnboarding,
  saveMentoringProfile,
};
