import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function InputDate({
  className = '',
  preText = '',
  postText = '',
  placeholder = '',
  isRequired = false,
  isDisabled = false,
  value = '',
  min = '',
  max = '',
  onChange,
  onBlur = () => {},
  error,
}) {
  return (
    <div className={`input_text ${className}`}>
      {preText && (
        <div className={`pre_text ${error ? 'text_error' : ''}`}>
          {preText}
          {isRequired && <span className='is_required'>*</span>}
        </div>
      )}
      <input
        className={`input ${error ? 'input_error' : ''}`}
        type='date'
        placeholder={placeholder}
        disabled={isDisabled}
        min={min}
        max={max}
        value={value}
        onChange={e => onChange(e.target.value)}
        onBlur={() => onBlur(value)}
      />
      {postText && <div className='post_text'>{postText}</div>}
    </div>
  );
}

InputDate.propTypes = {
  className: PropTypes.string,
  preText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  postText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  error: PropTypes.object,
};

export default InputDate;
