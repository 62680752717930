import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Button from 'components/Buttons/Button';
import InputText from 'components/Inputs/inputText';
import OutputErrors from 'components/Inputs/outputErrors';
import EDITOR_JS_TOOLS from 'components/EditorJS/Constants';
import Loading from 'components/Loading';
import InputPermission from 'components/Inputs/InputPermission';
import useDialog from 'components/Dialog/components/useDialog';
import { KPI } from 'components/EditorJS/KPI';
import { createReactEditorJS } from 'react-editor-js';
import { ROUTE_STARTUP_PATH } from 'routes';
import { useNavigate } from 'react-router-dom';
import { PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import { TOOL_REPORTING } from 'constants/tools';
import { initPermission } from 'utils/functions/initPermissions';
import {
  createReportTemplate, deleteReportTemplate, editReportTemplate, getReportTemplate,
} from '../../../../modules/actions';
import './styles.scss';

const ReportingTemplatesAdd = (props) => {
  const {
    literals,
    literalsPerformance,
    literalsCommon,
    match,
    currency,
    langs,
    lang,
    startup,
  } = props;

  const id = match.params.template !== 'new' ? match.params.template : null;
  const [name, setName] = useState({});
  const [permission, setPermission] = useState({});
  const [content, setContent] = useState({});
  const [loading, setLoading] = useState('fetching');
  const [errors, setErrors] = useState(null);
  const { dialog } = useDialog();
  const navigate = useNavigate();
  const { canEdit } = permission;

  useEffect(() => {
    if (id) {
      setLoading('fetching');
      getReportTemplate(match.params.template)
        .then((response) => {
          setName(response.name);
          setContent(response.content);
          setPermission(response.permission);
          setPermission(initPermission(response, PERMISSION_SCOPES.STARTUP, startup, TOOL_REPORTING));
          setLoading(false);
        })
        .catch(() => {
          dialog({
            type: 'error',
            text: literalsCommon.genericResourceNotFound,
          }).then(() => {
            navigate(ROUTE_STARTUP_PATH.setReportingTemplates(match.params.id));
          });
        });
    } else {
      setPermission(initPermission(null, PERMISSION_SCOPES.STARTUP, startup, TOOL_REPORTING));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadcrumb = [
    { route: ROUTE_STARTUP_PATH.setReportingTemplates(match.params.id), name: literals.title },
    { name: id ? literals.editTemplate : literals.newTemplate },
  ];

  const ReactEditorJS = createReactEditorJS();
  const editorCore = useRef(null);

  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = useCallback(async () => {
    return editorCore.current?.save()
      .then(response => setContent(() => {
        const aux = {};
        aux[lang] = response;
        return aux;
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const handleDelete = async (ev) => {
    ev.preventDefault();
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literalsCommon.confirmDelete,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            await deleteReportTemplate(id);
            navigate(ROUTE_STARTUP_PATH.setReportingTemplates(match.params.id));
          } catch (error) {
            setErrors(error);
          }
        },
      });
    }
  };

  const handleSumbit = async (e) => {
    e.preventDefault();
    setLoading('submit');
    const auxErrors = {};
    if (!Object.keys(name).length) {
      auxErrors.name = literals.fieldEmpty;
    }

    if (!Object.keys(content).length) {
      auxErrors.content = literals.fieldEmpty;
    }

    if (Object.keys(auxErrors).length) {
      setErrors(auxErrors);
      setLoading(false);
    } else {
      const auxName = {};
      const auxContent = {};
      langs.forEach((auxLang) => {
        auxName[auxLang] = name[lang];
        auxContent[auxLang] = content[lang];
      });
      const data = {
        name: auxName,
        content: auxContent,
        owner: match.params.id,
        scope: 'startup',
        permission,
      };

      try {
        if (id) {
          await editReportTemplate(id, data);
        } else {
          await createReportTemplate(data);
        }
        navigate(ROUTE_STARTUP_PATH.setReportingTemplates(match.params.id));
      } catch (error) {
        setErrors(error);
        setLoading(false);
      }
    }
  };

  return (
    <Tool>
      <Panel margin={0} clean>
        <PanelHeader breadcrumb={breadcrumb} />
        <PanelBody>
          <form onSubmit={handleSumbit} className='reporting-new-template'>
            <div className='row my-3'>
              <div className='col-12 col-md-6'>
                <InputText
                  preText={literalsCommon.name}
                  value={name[lang]}
                  onChange={v => setName(() => {
                    const aux = {};
                    aux[lang] = v;
                    return aux;
                  })}
                  isDisabled={!canEdit}
                />
              </div>
              <div className='col-12 col-md-6'>
                <InputPermission
                  scope={{ type: PERMISSION_SCOPES.STARTUP, id: startup.id }}
                  element={{ type: PERMISSION_TYPES.REPORT_TEMPLATE, id }}
                  value={permission}
                  onChange={setPermission}
                />
              </div>
            </div>
            <div className='row my-3'>
              <div className='col-12'>
                <span className='title'>{literals.template}</span>
                <div className='editor-wrapper'>
                  {(id && loading === 'fetching') ? (
                    <Loading mode='dialog' hide={false} />
                  ) : (
                    <ReactEditorJS
                      key={`template-${match.params.id}`}
                      defaultValue={content[lang]}
                      tools={{
                        ...EDITOR_JS_TOOLS,
                        KPI: {
                          class: KPI,
                          config: {
                            literalsPerformance, literalsCommon, currency, lang, match,
                          },
                        },
                      }}
                      onChange={handleSave}
                      onInitialize={handleInitialize}
                      disabled={!canEdit}
                    />
                  )}
                </div>
              </div>
            </div>
            <OutputErrors literals={literals} errors={errors} />
            {
              canEdit && (
                <div className='buttons'>
                  <Button
                    text={literals.save}
                    type='submit'
                    loading={loading === 'submit'}
                  />
                  {id && (
                    <Button
                      color='danger'
                      text={literalsCommon.delete}
                      type='submit'
                      onClick={handleDelete}
                      loading={loading === 'submit'}
                    />
                  )}
                </div>
              )
            }
          </form>
        </PanelBody>
      </Panel>
    </Tool>
  );
};

ReportingTemplatesAdd.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsPerformance: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  langs: PropTypes.array.isRequired,
  lang: PropTypes.string.isRequired,
  startup: PropTypes.object.isRequired,
};

export default ReportingTemplatesAdd;
