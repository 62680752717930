import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputSlider from 'components/Inputs/inputSlider';
import Table from 'components/Table';
import Collapse from 'components/Collapse/Collapse';
import InputNumber from 'components/Inputs/inputNumber';
import PopupShareholderOptions from 'routes/Captable/components/routes/shareHolders/components/routes/shareholderInfo/components/PopupShareholderOptions';
import { fetchLocal } from 'components/Table/components/TableFunctions';
import { formatNumber } from 'utils/functions';
import { Skeleton } from '@mui/material';
import { getCaptableOption } from '../modules/actions';
import { overwriteValue } from '.';
import './styles.scss';

const CollapseOptions = (props) => {
  const {
    literals,
    literalsCommon,
    match,
    currency,
    data,
    setData,
    isDisabled,
    forceFetch,
  } = props;

  const [optionDetails, setOptionDetails] = useState(false);
  const [slider, setSlider] = useState(50);
  const [loadingSlider, setLoadingSlider] = useState(true);

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoadingSlider(true);
      setData(prev => ({
        ...prev,
        options: prev.options.map((option) => {
          const auxOption = option;
          const optionsNum = (option.vested + option.assigned) * slider / 100;
          auxOption.results.exercise.options = optionsNum;
          return auxOption;
        }),
      }));
    }, 300);

    return () => clearTimeout(delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slider]);

  const fetchOptions = (page, size, search, filters, sort) => {
    setLoadingSlider(false);
    return fetchLocal(
      { items: data, total: data.lenght },
      { search: ['shareholder.firstname', 'shareholder.lastname'] },
      page, size, search, filters, sort,
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handlePopupOption = async (row) => {
    setOptionDetails({ loading: true });
    await getCaptableOption(match.params.id, row.id)
      .then(res => setOptionDetails(res))
      .catch(() => setOptionDetails(false));
  };

  const handleOptions = (id, field, value) => {
    setData(prev => ({
      ...prev,
      options: prev.options.map((option) => {
        const auxOption = option;
        if (auxOption.id === id) {
          if (field === 'exercise') {
            auxOption.results.exercise.options = value;
          } else if (value !== '') {
            auxOption.overwrite = { ...option.overwrite || {}, [field]: value };
          } else {
            delete auxOption.overwrite[field];
          }
        }
        return auxOption;
      }),
    }), true);
  };

  const optionsColumns = [
    {
      field: 'shareholder',
      label: literals.shareholder,
      preRender: sh => (sh ? `${sh.firstname} ${sh.lastname || ''}` : 'Undefined'),
      width: 300,
      onClick: handlePopupOption,
    },
    {
      field: 'assigned',
      label: literals.assigned,
      preRender: mul => formatNumber(mul, 0, { dec: 2 }),
      width: 100,
    },
    {
      field: 'vested',
      label: literals.vested,
      preRender: val => formatNumber(val, 0, { dec: 2 }),
      width: 100,
    },
    {
      field: 'ratio',
      label: literals.conversion,
      width: 120,
      preRender: (v, row) => (
        <InputNumber
          className='w-100'
          placeholder={formatNumber(v)}
          value={overwriteValue(row, 'ratio', v)}
          onBlur={nv => handleOptions(row.id, 'ratio', nv)}
          symbol='X'
          state
          stateTimeout={600}
          isDisabled={isDisabled}
        />
      ),
    },
    {
      label: literals.exerciseStock,
      field: 'results',
      preRender: (v, row) => (
        <InputNumber
          className='w-100'
          value={v.exercise.options}
          maxValue={row.assigned + row.vested}
          onBlur={nv => handleOptions(row.id, 'exercise', nv)}
          decimals={0}
          state
          stateTimeout={600}
          isDisabled={isDisabled || loadingSlider}
        />
      ),
      width: 120,
    },
    {
      field: 'results',
      label: literals.shares,
      preRender: val => (loadingSlider ? (<Skeleton width={100} />) : formatNumber(val.exercise.shares, 0, { dec: 2 })),
      width: 120,
    },
  ];

  return (
    <Collapse
      className='options-collapse'
      title={(
        <div className='collapse-header--flex'>
          <h5>{`${literals.options} (${data.length})`}</h5>
          <div onClick={e => e.stopPropagation()}>
            <InputSlider
              className='options-slider'
              value={slider}
              onChange={setSlider}
              symbol='%'
              isDisabled={isDisabled}
            />
          </div>
        </div>
      )}
      state={false}
    >
      <Table
        key='table_options'
        columns={optionsColumns}
        fetch={fetchOptions}
        forceFetch={forceFetch}
        fetchLocal
      />
      {optionDetails && (
        <PopupShareholderOptions
          literals={literals}
          literalsCommon={literalsCommon}
          currency={currency}
          match={match}
          onClose={() => setOptionDetails(false)}
          row={optionDetails}
        />
      )}
    </Collapse>
  );
};

CollapseOptions.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  forceFetch: PropTypes.bool.isRequired,
};

export default CollapseOptions;
