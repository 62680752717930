import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Button from 'components/Buttons/Button';
import { FileSpreadsheet } from 'lucide-react';
import IOFileLoading from 'components/IOFileLoading/components';
import { downloadExcelKpis } from '../modules/actions';

const PopupEmailBotKpis = (props) => {
  const {
    literals,
    startup,
    email,
    onClose,
  } = props;

  const [downloadingExcel, setDownloadingExcel] = useState(false);

  const handleDownloadExcel = async () => {
    setDownloadingExcel(true);
    await downloadExcelKpis('startup', startup.id, startup.name, 'template');
    setDownloadingExcel(false);
  };

  return (
    <Popup title={literals.updateValues} onClose={onClose}>
      <h6 className='fw-b'>{literals.updateValuesQuestion}</h6>
      <span>{literals.updateValuesDesc}</span>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>{`${literals.address}: ${email || ''}`}</li>
        <li>{`${literals.subject} ${startup.name}`}</li>
        <li>{literals.attachments}</li>
      </ul>
      <div className='mt-3'>{literals.requirement}</div>
      <div className='buttons mt-3'>
        <Button
          icon={FileSpreadsheet}
          text={literals.downloadTemplate}
          onClick={handleDownloadExcel}
        />
      </div>
      { downloadingExcel && <IOFileLoading mode='download' file={{ name: `KPIs_${startup.name}_template.xlsx` }} /> }
    </Popup>
  );
};

PopupEmailBotKpis.propTypes = {
  literals: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  startup: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupEmailBotKpis;
