export const API_BASE_URL = window.MAIN_API_URL; // process.env.REACT_APP_API_URL;
export const FILES_BASE_URL = `${API_BASE_URL}/api/v1`; // process.env.REACT_APP_API_URL;
export const STATUS_CODE = {
  OK: 200,
  ACCEPTED: 202,
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
};
export const RETURN_TYPES = {
  RESPONSE: 'response',
  DATA: 'data',
};
