import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Avatar from 'components/Avatar';
import OutputValue from 'components/Inputs/outputValue';
import Loading from 'components/Loading';
import SocialIcon from 'components/SocialIcon';
import { createDefaultQuestionsStartup } from 'constants/defaultQuestionsOnboarding';
import { getStartupVerifiedIcon, getTranslation } from 'utils/functions';
import './styles.scss';

const PopupStartupDetails = (props) => {
  const {
    literals,
    literalsCommon,
    startup,
    lang,
    forms,
    platform,
    fetchFormStartup,
    onClose,
  } = props;

  useEffect(() => {
    if (forms.startup && !forms.startup.loaded && !forms.startup.loading) {
      fetchFormStartup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms.startup]);

  const renderQuestions = () => {
    const platformQuestions = platform.properties.defaultQuestions.startup;
    const questions = [
      ...createDefaultQuestionsStartup(literals, literalsCommon, platformQuestions)
        .filter((question) => {
          const startupQuestion = startup[question.info.id];
          return startupQuestion && (!Array.isArray(startupQuestion) || startupQuestion.length > 0);
        }),
      ...forms.startup.questions
        .filter(question => startup.extra[question.info.id]?.answer),
    ];
    const formAnswers = { extra: {} };

    questions.forEach((question) => {
      if (question.default) {
        formAnswers[question.info.id] = startup[question.info.id];
      } else {
        formAnswers.extra[question.info.id] = startup.extra[question.info.id].answer;
      }
    });

    return (
      <>
        <div className='text-start'>
          <div className='d-flex justify-content-start align-items-center'>
            <Avatar
              title={startup.name}
              src={formAnswers?.logo?.path}
              size={60}
              className='mr-4'
            />
            <span className='fw-b'>
              {startup.name}
              {getStartupVerifiedIcon(startup.verified)}
            </span>
          </div>
          { (startup.url || Object.values(startup.social || {}).some(Boolean)) && (
            <div className='startup-social'>
              {startup.url && <SocialIcon type='url' link={startup.url} />}
              {Object.keys(startup.social || {})
                .sort((a, b) => (b === 'other' ? -1 : 1))
                .map(social => startup.social[social] && <SocialIcon key={social} type={social} link={startup.social[social]} />)
              }
            </div>
          )}
          <div className='mt-3 mb-5'>
            {formAnswers.description}
          </div>
        </div>
        <div className='row'>
          {questions
            .filter(question => !['logo', 'name', 'description', 'social'].includes(question.info.id))
            .map((question) => {
              return (
                <div key={question.id} className={`col-12 ${!['textarea', 'richtext'].includes(question.info.type) ? 'col-md-6' : ''}`}>
                  <OutputValue
                    type={question.info.type}
                    preText={getTranslation(question.info.title, lang)}
                    value={question.default ? formAnswers[question.info.id] : formAnswers.extra[question.info.id]}
                    answers={question.info.answers}
                    lang={lang}
                  />
                </div>
              );
            })}
        </div>
      </>
    );
  };

  return (
    <Popup onClose={onClose}>
      { forms.startup.loaded
        ? renderQuestions()
        : <Loading hide={false} mode='tool' />
      }
    </Popup>
  );
};

PopupStartupDetails.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  forms: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  fetchFormStartup: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupStartupDetails;
