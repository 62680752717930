import StartupsCaptablesInstance from 'modules/startupsCaptables/repository';

export const getCaptableShare = (id, share) => {
  return StartupsCaptablesInstance.getStartupCaptableShare(id, share);
};

export const getCaptableDebt = (id, debt) => {
  return StartupsCaptablesInstance.getStartupCaptableDebt(id, debt);
};

export const getCaptableOption = (id, option) => {
  return StartupsCaptablesInstance.getStartupCaptableOption(id, option);
};

export const postCaptableWaterfalls = (id, data) => {
  return StartupsCaptablesInstance.postStartupCaptableWaterfalls(id, data);
};

export const getCaptableWaterfall = (id, waterfall, params = {}) => {
  return StartupsCaptablesInstance.getStartupCaptableWaterfall(id, waterfall, params);
};

export const putCaptableWaterfall = (id, waterfall, data) => {
  return StartupsCaptablesInstance.putStartupCaptableWaterfalls(id, waterfall, data);
};

export const deleteCaptableWaterfall = (id, waterfall) => {
  return StartupsCaptablesInstance.deleteStartupCaptableWaterfalls(id, waterfall);
};
