import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Loading from 'components/Loading';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { formatDate, formatNumber } from 'utils/functions';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import './styles.scss';

const PopupShareholderShares = (props) => {
  const {
    literals,
    literalsCommon,
    currency,
    row,
    onClose,
  } = props;

  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const params = useParams();

  if (row.loading) {
    return (
      <Popup title={literals.shareDetails} onClose={onClose}>
        <Loading mode='panel' hide={false} />
      </Popup>
    );
  }

  return (
    <Popup title={literals.shareDetails} onClose={onClose}>
      <table className='table-captable-details'>
        <tbody>
          <tr>
            <td>{literalsCommon.date}</td>
            <td>{formatDate(row.originalOperation.date)}</td>
          </tr>
          <tr>
            <td>{literals.operation}</td>
            <td><Link to={embedView(ROUTE_STARTUP_PATH.setCapTableOperation(params.id, captableId, row.operation.id))}>{row.operation.name}</Link></td>
          </tr>
          <tr>
            <td>{literals.shareholder}</td>
            <td>{row.shareholder.name}</td>
          </tr>
          <tr>
            <td>{literals.number}</td>
            <td>{row.number}</td>
          </tr>
          <tr>
            <td>{literals.shareClass}</td>
            <td>{row.shareClass.name}</td>
          </tr>
          <tr>
            <td>{literals.numeration}</td>
            <td>{`${row.numeration.from} - ${row.numeration.to}`}</td>
          </tr>
          <tr>
            <td>{literals.contributedCapital}</td>
            <td>{`${formatNumber(row.capital, 0)}${currency.symbol}`}</td>
          </tr>
          <tr>
            <td>{literals.pps}</td>
            <td>{`${formatNumber(row.pps, 0)}${currency.symbol}`}</td>
          </tr>
          <tr>
            <td>{literals.value}</td>
            <td>{`${formatNumber(row.value, 0)}${currency.symbol}`}</td>
          </tr>
          <tr>
            <td>{`${literals.value} - ${literals.capital}`}</td>
            <td>{`${formatNumber(row.value - row.capital, 0)}${currency.symbol}`}</td>
          </tr>
          <tr>
            <td>{literals.ownership}</td>
            <td>{`${formatNumber(row.ownership, 0, { dec: 2 })}%`}</td>
          </tr>
          <tr>
            <td>{literals.fullyDiluted}</td>
            <td>{`${formatNumber(row.fullyDiluted, 0, { dec: 2 })}%`}</td>
          </tr>
          <tr>
            <td>{literals.vote}</td>
            <td>{`${formatNumber(row.votingPercentage, 0, { dec: 2 })}%`}</td>
          </tr>
        </tbody>
      </table>
    </Popup>
  );
};

PopupShareholderShares.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupShareholderShares;
