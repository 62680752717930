import StartupsCaptablesInstance from 'modules/startupsCaptables/repository';
import { apiDownloadFile } from 'modules/actions';
import { API_STATUTORY_BOOK_EXCEL } from 'modules/apiEndpoints';
import { formatErrors } from 'utils/functions';

export function fetchStartupOperations(id, opts) {
  return StartupsCaptablesInstance
    .getStartupOperations(id, opts)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw formatErrors(error);
    });
}

export function fetchOperation(startup, operation) {
  return StartupsCaptablesInstance.getStartupOperation(startup, operation);
}

export function getStatutoryBook(id, date, opts) {
  return StartupsCaptablesInstance.getStartupCaptableStatutoryBook(id, date, opts);
}

export function downloadStatutoryBookExcel(id, captable, date) {
  return apiDownloadFile(API_STATUTORY_BOOK_EXCEL(id, captable, date), 'statutory_book.xlsx');
}
