import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PERMISSION_LEVELS, PERMISSION_MEMBERS_TYPES, PERMISSION_MODES } from 'constants/permissions';
import {
  Building, Globe, Lock, Users, User,
} from 'lucide-react';
import PermissionPopup from 'components/PermissionsPopup/PermissionPopup';
import OutputValue from './outputValue';

const InputPermission = (props) => {
  const {
    scope,
    element,
    value,
    onChange,
  } = props;

  const [sharedPopup, setSharedPopup] = useState(false);
  const literals = useSelector(state => state.i18n.literals.permissions);
  const {
    level,
    access,
    mode,
    toolLevel,
    owner,
    canShare,
  } = value;

  const sharedWith = useMemo(() => {
    const auxAccess = access || {};
    const types = {
      group: 0,
      user: 0,
    };
    Object.keys(auxAccess).forEach((key) => {
      const auxKey = key.split('_');
      const member = {
        type: auxKey[0],
        id: auxKey[1] ?? null,
      };
      if ([PERMISSION_MEMBERS_TYPES.USER, PERMISSION_MEMBERS_TYPES.GROUP].includes(member.type)) {
        if (!(member.type === PERMISSION_MEMBERS_TYPES.USER && member.id === owner)) {
          types[member.type] += 1;
        }
      }
    });
    return types;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const renderIcon = () => {
    switch (mode) {
      case PERMISSION_MODES.PUBLIC:
        return <Globe size={16} />;
      case PERMISSION_MODES.PRIVATE:
        return <Lock size={16} />;
      default:
        return <Building size={16} />;
    }
  };

  return (
    <div className='input_permission'>
      <div className='pre_text'>
        {literals.usersWithAccess}
      </div>
      <div className='input_permission-container' onClick={() => setSharedPopup(true)}>
        <OutputValue
          className='mb-0'
          value={(
            <div className='d-flex align-items-center'>
              {renderIcon()}
              {`${literals[mode]}`}
              {level >= PERMISSION_LEVELS.VIEW && toolLevel !== PERMISSION_LEVELS.EXTERNAL && (
                <div className='shared-with'>
                  {sharedWith.user > 0 ? (
                    <>
                      <User size={16} className='ml-2' title={literals.users} />
                      {' '}
                      {sharedWith.user}
                    </>
                  ) : null}
                  {sharedWith.group > 0 ? (
                    <>
                      <Users size={16} className='ml-2' title={literals.groups} />
                      {' '}
                      {sharedWith.group}
                    </>
                  ) : null}
                </div>
              )}
            </div>
          )}
          isDisabled={!canShare}
        />
      </div>
      { sharedPopup && (
        <PermissionPopup
          scope={scope}
          element={element}
          value={value}
          onClose={() => setSharedPopup(null)}
          onSubmit={v => onChange(v)}
        />
      )}
    </div>
  );
};

InputPermission.propTypes = {
  scope: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default InputPermission;
