import initialState from '../../../store/globalState/initialState';

import {
  FETCH_COMPANIES_INIT,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
} from './types';

export default function companiesReducer(state = initialState.companies, action) {
  switch (action.type) {
    case FETCH_COMPANIES_INIT:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: '',
      };
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: '',
        companiesList: action.payload,
      };
    case FETCH_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
        companiesList: [],
      };
    default:
      return state;
  }
}
