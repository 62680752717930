import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Button from 'components/Buttons/Button';
import InputSelect from 'components/Inputs/inputSelect';
import {
  Eye, Pencil, Crown, CircleSlash,
} from 'lucide-react';
import { STARTUP_ROLES } from 'constants/roles';
import { editPlatformToolPermissions } from '../modules/actions';

const ChangePermissionsPopup = (props) => {
  const {
    literals,
    tool,
    onClose,
  } = props;

  const DEFAULT_GROUPS = [...STARTUP_ROLES, 'startup'];
  const [loading, setLoading] = useState(false);
  const [toolPermissions, setToolPermissions] = useState(DEFAULT_GROUPS.reduce((prev, curr) => {
    const aux = { ...prev };
    aux[curr] = null;
    return aux;
  }, {}));

  const handleChangePermissions = async () => {
    setLoading(true);
    try {
      await editPlatformToolPermissions(tool, toolPermissions);
      onClose();
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Popup title={literals.tools[tool]} onClose={onClose} size='small'>
      {DEFAULT_GROUPS.map(type => (
        <InputSelect
          key={`${tool}-${type}`}
          placeholder={literals.permissions.current}
          preText={literals.roles[type]}
          value={toolPermissions[type]}
          onChange={v => setToolPermissions(prev => ({ ...prev, [type]: v }))}
          options={[
            { name: `- ${literals.permissions.current} -`, id: null },
            { name: literals.permissions.noPermission, id: 0, icon: <CircleSlash size={16} /> },
            { name: literals.permissions.see, id: 1, icon: <Eye size={16} /> },
            { name: literals.permissions.edit, id: 2, icon: <Pencil size={16} /> },
            { name: literals.permissions.manage, id: 3, icon: <Crown size={16} /> },
          ]}
        />
      ))}
      <div className='buttons'>
        <Button
          text={literals.send}
          onClick={handleChangePermissions}
          loading={loading}
        />
      </div>
    </Popup>
  );
};

ChangePermissionsPopup.propTypes = {
  literals: PropTypes.object.isRequired,
  tool: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ChangePermissionsPopup;
