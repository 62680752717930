import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Loading from 'components/Loading';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { formatDate, formatNumber } from 'utils/functions';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import './styles.scss';

const PopupShareholderDebts = (props) => {
  const {
    literals,
    literalsCommon,
    currency,
    row,
    onClose,
  } = props;

  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const params = useParams();

  if (row.loading) {
    return (
      <Popup title={literals.debtDetails} onClose={onClose}>
        <Loading mode='panel' hide={false} />
      </Popup>
    );
  }

  const interests = row.repayments.reduce(
    (previousValue, currentValue) => previousValue + currentValue.interests,
    0,
  );

  return (
    <Popup title={literals.debtDetails} onClose={onClose}>
      <table className='table-captable-details'>
        <tbody>
          <tr>
            <td>{literalsCommon.date}</td>
            <td>{formatDate(row.originalOperation.date)}</td>
          </tr>
          <tr>
            <td>{literals.operation}</td>
            <td><Link to={embedView(ROUTE_STARTUP_PATH.setCapTableOperation(params.id, captableId, row.originalOperation.id))}>{row.originalOperation.name}</Link></td>
          </tr>
          <tr>
            <td>{literals.shareholder}</td>
            <td>{row?.shareholder?.name}</td>
          </tr>
          <tr>
            <td>{literalsCommon.name}</td>
            <td>{row.name}</td>
          </tr>
          <tr>
            <td>{literals.status}</td>
            <td>{literals[row.status]}</td>
          </tr>
          <tr>
            <td>{literals.accrualFrequency}</td>
            <td>{literalsCommon[row.accrualFrequency] || '-'}</td>
          </tr>
          <tr>
            <td>{literals.startDate}</td>
            <td>{formatDate(row.startDate)}</td>
          </tr>
          <tr>
            <td>{literals.expirationDate}</td>
            <td>{formatDate(row.expirationDate)}</td>
          </tr>
          <tr>
            <td>{literals.principal}</td>
            <td>{`${formatNumber(row.principal, 0)}${currency.symbol}`}</td>
          </tr>
          <tr>
            <td>{literals.pending}</td>
            <td>{`${formatNumber(row.pending, 0)}${currency.symbol}`}</td>
          </tr>
          <tr>
            <td>{literals.capitalized}</td>
            <td>{`${formatNumber(row.capitalized, 0)}${currency.symbol}`}</td>
          </tr>
          <tr>
            <td>{literals.repaid}</td>
            <td>
              <div>
                {
                  `${literals.paid} ${formatNumber(row.repaid + interests, 0)}${currency.symbol} 
                  (${formatNumber(row.repaid, 0)}${currency.symbol} ${literals.debt} 
                  + ${formatNumber(interests, 0)}${currency.symbol} ${literals.interest})`
                }
              </div>
              {row.repayments.map((repayment, i) => (
                <div key={`repayment-${i}`}>
                  {
                    `- ${formatDate(row.originalOperation.date)}: ${literals.paid} ${formatNumber(repayment.total, 0)}${currency.symbol} 
                  (${formatNumber(repayment.amount, 0)}${currency.symbol} ${literals.debt} 
                  + ${formatNumber(repayment.interests, 0)}${currency.symbol} ${literals.interest})`
                  }
                </div>
              ))}
            </td>
          </tr>
          <tr>
            <td>{literals.interestRate}</td>
            <td>{`${formatNumber(row.interestRate, 0)}%`}</td>
          </tr>
        </tbody>
      </table>
    </Popup>
  );
};

PopupShareholderDebts.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupShareholderDebts;
