import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import MeetingsComponents from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.meetings,
  literalsCommon: state.i18n.literals.common,
  user: state.session.user,
});

export default withRouter(connect(mapStateToProps)(MeetingsComponents));
