import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Button from 'components/Buttons/Button';
import StatCard from 'components/Statistics/StatCard';
import SkeletonDoughnutChart from 'components/Loading/skeletonDoughnutChart';
import ReactECharts from 'echarts-for-react';
import EmptyCaptable from 'assets/svg/empty_captable.svg';
import { Link, useSearchParams } from 'react-router-dom';
import { formatNumber, getColorRange, startupCanView } from 'utils/functions';
import { initializeCaptable } from 'routes/Captable/modules/actions';
import { fetchCaptableSummary } from 'routes/Captable/components/routes/Securities/modules/actions';
import { ROUTE_STARTUP_PATH } from 'routes';
import { PlusCircle } from 'lucide-react';
import { TOOL_MY_POSITION } from 'constants/tools';
import { getShareholderPosition } from 'routes/MyPosition/modules/actions';

const SummaryStartupCapTable = (props) => {
  const {
    literalsCaptable,
    literalsMyPosition,
    currency,
    startup,
    captable,
    fetchCaptable,
  } = props;

  const [stats, setStats] = useState(null);
  const [myStats, setMyStats] = useState(null);
  const [date, setDate] = useState(null);

  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const canViewPosition = startupCanView(startup, TOOL_MY_POSITION);

  useEffect(() => {
    setDate(null);
    initializeCaptable(fetchCaptable, captable, startup.id, captableId);
    if (captable.loaded) {
      if (!date && captable.operations.length > 0) {
        setDate(captable.operations[0].date);
      } else {
        setDate(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startup.id, captable]);

  useEffect(() => {
    if (canViewPosition) {
      getShareholderPosition(startup.id, '').then(res => setMyStats(res));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startup.id]);

  useEffect(() => {
    setStats(null);
    (async () => {
      if (date) {
        const response = await fetchCaptableSummary(startup.id, 'all', date, { captable: captableId });
        const newStats = { ...response };

        if (newStats.aggregations?.sharesByShareholder) {
          newStats.aggregations.sharesByShareholder = newStats.aggregations.sharesByShareholder
            .map(item => ({ name: item.name, value: Number(item.value.toFixed(2)) }))
            .sort((a, b) => {
              return b.value - a.value;
            });
        }
        if (newStats.aggregations?.sharesByShareholder.length > 5) {
          newStats.aggregations.sharesByShareholder = newStats.aggregations.sharesByShareholder.slice(0, 5);
          newStats.aggregations.sharesByShareholder.push({
            id: 'others',
            name: literalsCaptable.others,
            value: 100 - newStats.aggregations.sharesByShareholder.reduce((acc, curr) => acc + curr.value, 0),
          });
        }
        if (newStats.aggregations?.sharesByShareClass) {
          newStats.aggregations.sharesByShareClass = newStats.aggregations.sharesByShareClass
            .map(item => ({ name: item.name, value: Number(item.value.toFixed(2)) }))
            .sort((a, b) => {
              return b.value - a.value;
            });
        }
        if (newStats.aggregations?.sharesByShareClass.length > 5) {
          newStats.aggregations.sharesByShareClass = newStats.aggregations.sharesByShareClass.slice(0, 5);
          newStats.aggregations.sharesByShareClass.push({
            id: 'others',
            name: literalsCaptable.others,
            value: 100 - newStats.aggregations.sharesByShareClass.reduce((acc, curr) => acc + curr.value, 0),
          });
        }
        setStats(newStats);
      } else {
        setStats({});
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, captableId]);

  const chartOptions = {
    tooltip: {
      trigger: 'item',
      formatter: `<strong>{a}</strong><br/>
                    {b}: {d}%`,
    },
    series: [
      {
        color: getColorRange(stats?.aggregations?.sharesByShareholder.length),
        name: literalsCaptable.shareholders,
        type: 'pie',
        radius: ['65%', '80%'],
        label: {
          formatter: '{b|{b}}\n{per|{d}%}',
          rich: {
            b: {
              fontSize: 13,
              fontWeight: 'bold',
              lineHeight: 20,
            },
            per: {
              color: '#fff',
              backgroundColor: '#4C5058',
              padding: 3,
              borderRadius: 4,
              align: 'center',
            },
          },
        },
        data: stats?.aggregations?.sharesByShareholder.map(item => (
          { name: item.name, value: Number(item.value.toFixed(2)) }
        )),
      },
      {
        color: getColorRange(stats?.aggregations?.sharesByShareClass.length),
        name: literalsCaptable.shareClasses,
        type: 'pie',
        radius: ['35%', '50%'],
        label: {
          position: 'inner',
          formatter: '{b|{b}}\n{per|{d}%}',
          rich: {
            b: {
              fontSize: 13,
              fontWeight: 'bold',
              lineHeight: 20,
            },
            per: {
              color: '#fff',
              backgroundColor: '#4C5058',
              padding: 3,
              borderRadius: 4,
              align: 'center',
            },
          },
        },
        data: stats?.aggregations?.sharesByShareClass.map(item => (
          { name: item.name, value: Number(item.value.toFixed(2)) }
        )),
      },
    ],
  };

  return (
    <div className='summary-captable'>
      <div className='row'>
        <div className='col-12 col-md-8'>
          <Panel>
            <PanelHeader title={literalsCaptable.title} />
            <PanelBody>
              {
                date === false ? (
                  <div className='panel-empty-message full'>
                    <img className='arrow-search' src={EmptyCaptable} alt='' />
                    <p>{literalsCaptable.emptyCaptable}</p>
                    <Link to={ROUTE_STARTUP_PATH.setCapTable(startup.id, captableId)}>
                      <Button color='secondary' icon={PlusCircle} text={literalsCaptable.initCaptable} />
                    </Link>
                  </div>
                ) : (
                  <>
                    {stats?.aggregations?.sharesByShareholder && (!canViewPosition || myStats) ? (
                      <div className='chart-container'>
                        <div className={canViewPosition && myStats ? 'chart line' : 'chart'}>
                          <ReactECharts
                            option={chartOptions}
                            style={{ height: 308, width: '100%' }}
                          />
                        </div>
                        {canViewPosition && myStats ? (
                          <>
                            <div className='my-position'>
                              <h4 className='fw-b mb-3'>{literalsMyPosition.title}</h4>
                              <div className='my-position-stats'>
                                <div>
                                  <h5 className='fw-b'>{`${literalsMyPosition.myShares}: `}</h5>
                                  {formatNumber(myStats?.shares?.number, 0)}
                                </div>
                                <div>
                                  <h5 className='fw-b'>{`${literalsMyPosition.options}: `}</h5>
                                  {formatNumber(myStats?.options?.number, 0)}
                                </div>
                                <div>
                                  <h5 className='fw-b'>{`${literalsMyPosition.ownership}: `}</h5>
                                  {formatNumber(myStats?.shares?.ownership, 0, { dec: 2, symbol: '%' })}
                                </div>
                                <div>
                                  <h5 className='fw-b'>{`${literalsMyPosition.totalValue}: `}</h5>
                                  {formatNumber(myStats?.shares?.value, 0, { symbol: currency.symbol })}
                                </div>
                                <div>
                                  <h5 className='fw-b'>{`${literalsMyPosition.convertibles}: `}</h5>
                                  {formatNumber(myStats?.debts?.pending, 0, { symbol: currency.symbol })}
                                </div>
                              </div>
                            </div>
                            <div className='my-position-arrow' />
                          </>
                        ) : null}
                      </div>
                    ) : (
                      <div className='my-4'>
                        <SkeletonDoughnutChart size={249} />
                      </div>
                    )}
                  </>
                )
              }
            </PanelBody>
          </Panel>
        </div>
        <div className='col-12 col-md-4'>
          <div className='col-12 px-0'>
            <StatCard title={literalsCaptable.totalCapitalRaised} loading={!stats} value={formatNumber(stats?.capital, '- ', { symbol: currency.symbol, dec: 2 })} />
          </div>
          <div className='col-12 px-0'>
            <StatCard title={literalsCaptable.totalInvestors} loading={!stats} value={formatNumber(stats?.shareholders)} />
          </div>
          <div className='col-12 px-0'>
            <StatCard title={literalsCaptable.valuation} loading={!stats} value={formatNumber(stats?.valuation, '- ', { symbol: currency.symbol, dec: 2 })} />
          </div>
          <div className='col-12 px-0'>
            <StatCard title={literalsCaptable.ppsLastReport} loading={!stats} value={formatNumber(stats?.pps, '- ', { symbol: currency.symbol, dec: 2 })} />
          </div>
        </div>
      </div>
    </div>
  );
};

SummaryStartupCapTable.propTypes = {
  literalsCaptable: PropTypes.object.isRequired,
  literalsMyPosition: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  fetchCaptable: PropTypes.func.isRequired,
};

export default SummaryStartupCapTable;
