import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import Popup from 'components/Popup';
import { fetchPreview, sendReportTest } from 'routes/Reporting/modules/actions';
import useDialog from 'components/Dialog/components/useDialog';
import Button from 'components/Buttons/Button';
import OutputErrors from 'components/Inputs/outputErrors';
import { ROUTE_PATH } from 'routes';
import Loading from 'components/Loading';
import { createPortal } from 'react-dom';
import Panel from 'components/Panel';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelTab from 'components/Panel/components/PanelTab';

const PreviewPopup = (props) => {
  const {
    literals,
    subject,
    content,
    user,
    match,
    onClose,
  } = props;

  const { dialog } = useDialog();
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [preview, setPreviewHtml] = useState({ email: null, html: null });
  const [contentRef, setContentRef] = useState(null);
  const mountNode = contentRef?.contentWindow?.document?.body;

  const fetchHtml = async () => {
    const email = await fetchPreview(match.params.id, content, 'email');
    const html = await fetchPreview(match.params.id, content, 'html');
    setPreviewHtml({ html, email });
  };

  useEffect(() => {
    fetchHtml();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSumbit = async () => {
    setLoading(true);
    setErrors(false);
    try {
      await sendReportTest(match.params.id, {
        subject: `(${literals.test.toUpperCase()}) ${subject}`,
        content,
        filesPath: ROUTE_PATH.DOWNLOAD_FILE_TEMPLATE,
      });
      await dialog({
        type: 'alert',
        text: literals.testSent,
      });
      onClose();
    } catch (err) {
      setErrors(err);
    }
    setLoading(false);
  };

  const tabs = [
    { key: 'email', name: literals.email },
    { key: 'document', name: literals.document },
  ];

  return (
    <Popup title={literals.preview} onClose={onClose} size='large'>
      <Panel>
        <PanelHeader tabs={tabs} />
        <PanelBody>
          <PanelTab key='email'>
            <div className='preview-email-report'>
              <table className='mb-2'>
                <tbody>
                  <tr>
                    <td className='first-column'>{literals.recipients}</td>
                    <td>{user.email}</td>
                  </tr>
                  <tr>
                    <td className='first-column'>{literals.subject}</td>
                    <td>{subject}</td>
                  </tr>
                </tbody>
              </table>

              {preview.email === null ? (
                <Loading mode='panel' hide={false} />
              ) : (
                <iframe title='preview' ref={setContentRef}>
                  {mountNode && createPortal(<div dangerouslySetInnerHTML={{ __html: preview.email }} />, mountNode)}
                </iframe>
              )}

              <OutputErrors errors={errors} />
              <div className='d-flex justify-content-end'>
                <Button
                  text={literals.sendTest}
                  onClick={handleSumbit}
                  loading={loading}
                />
              </div>
            </div>
          </PanelTab>
          <PanelTab key='document'>
            {preview.html === null ? (
              <Loading mode='panel' hide={false} />
            ) : (
              <div className='report-visualizer'>
                <div className='report-visualizer-content'>
                  <div dangerouslySetInnerHTML={{ __html: preview.html }} />
                </div>
              </div>
            )}
          </PanelTab>
        </PanelBody>
      </Panel>
    </Popup>
  );
};

PreviewPopup.propTypes = {
  literals: PropTypes.object.isRequired,
  subject: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,

};

export default PreviewPopup;
