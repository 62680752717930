/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import InputSelect from 'components/Inputs/inputSelect';
import InputNumber from 'components/Inputs/inputNumber';
import OutputValue from 'components/Inputs/outputValue';
import {
  GripVertical, UserPlus, PlusCircle, Trash,
} from 'lucide-react';
import { cloneObject, formatNumber, setObjectValue } from 'utils/functions';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PopupFormShareClass from '../../shareClasses/components/PopupFormShareClass';

const defaultObjectShares = {
  id: null,
  number: null,
  from: 0,
  to: 0,
  shareClass: null,
};

const ShareClassAssignments = (props) => {
  const {
    id,
    literals,
    literalsCommon,
    captable,
    currency,
    editable,
    number,
    assignments,
    pps,
    numeration,
    onChange,
  } = props;

  const [auto, setAuto] = useState(!assignments.some(ass => ass?.from));
  const [popupShareclass, setPopupShareclass] = useState(false);

  const handleAddRow = () => {
    onChange([
      ...assignments,
      { ...cloneObject(defaultObjectShares), id: `new.${new Date().getTime()}` },
    ]);
  };

  const reasignNumeration = (auxAssigments) => {
    let itNumber = parseInt(numeration?.from || 1, 10);
    auxAssigments.forEach((assignment) => {
      const asNumber = parseInt(assignment.number, 10);
      assignment.from = asNumber ? itNumber : 0;
      assignment.to = assignment.number ? (assignment.from + asNumber - 1) : 0;
      itNumber = assignment.to + 1;
    });

    return auxAssigments;
  };

  useEffect(() => {
    if (editable && auto && assignments.length > 0 && number > 0) {
      const numAssigned = assignments.reduce((prev, curr) => prev + (curr.number !== '' ? curr.number : 0), 0);
      if (numAssigned > number) {
        const newAssignments = [...assignments];
        let toReduce = numAssigned - number;
        newAssignments.reverse().forEach((assignment, i) => {
          if (toReduce > 0) {
            const reduce = Math.min(toReduce, assignment.number);
            newAssignments[i].number = assignment.number - reduce;
            toReduce -= reduce;
          }
        });
        newAssignments.reverse();
        onChange(newAssignments);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number]);

  const handleDeleteRow = (i) => {
    const newAssignments = [...assignments];
    newAssignments.splice(i, 1);
    onChange(newAssignments);
  };

  const handleChangeAutomatic = () => {
    setAuto(!auto);
    if (auto) {
      onChange(reasignNumeration([...assignments]));
    } else {
      onChange(assignments.map(assignment => ({
        ...assignment, from: null, to: null,
      })));
    }
  };

  const handleChangeValue = (i, prop, value) => {
    const newAssignments = [...assignments];
    newAssignments[i] = setObjectValue(newAssignments[i], prop, value);
    onChange(newAssignments);
  };

  const handleBlurNumeration = (i) => {
    const newAssignments = [...assignments];
    const auxNumber = newAssignments[i]?.number || 0;
    const from = newAssignments[i].from || 0;
    newAssignments[i].to = auxNumber + from - 1;
    onChange(newAssignments);
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination || source.index === destination.index) {
      return;
    }

    const newAssignments = [...assignments];

    const [removed] = newAssignments.splice(source.index, 1);
    newAssignments.splice(destination.index, 0, removed);

    onChange(newAssignments);
  };

  const pending = number > 0 ? (number - assignments.reduce((prev, curr) => prev + (curr.number !== '' ? curr.number : 0), 0)) : null;

  return (
    <>
      <div className={`shares-assigments ${editable ? 'editable' : ''}`}>
        <div className='shares-assigments-header'>
          <span>{`${formatNumber(pending, 0)} ${literals.pending}`}</span>
          {
            editable && (
              <div className='sh-automatic'>
                <input
                  type='checkbox'
                  checked={auto}
                  onChange={handleChangeAutomatic}
                />
                <span>{literals.automaticNumeration}</span>
              </div>
            )
          }
        </div>
        <div className='shares-assigments-table'>
          <div className='sat-overflow'>
            <div className='sh-header'>
              <div className='cell-sh'><span>{literals.shareClassTo}</span></div>
              <div><span>{literals.number}</span></div>
              { !auto && (
                <>
                  <div><span>{literals.from}</span></div>
                  <div><span>{literals.to}</span></div>
                </>
              )}
              <div><span>{literalsCommon.total}</span></div>
            </div>
            <DragDropContext className='sh-list' onDragEnd={handleDragEnd}>
              <Droppable key={`assignments-${id}`} droppableId={`assignments-${id}`} isDropDisabled={!editable}>
                {provider => (
                  <>
                    <ul {...provider.droppableProps} ref={provider.innerRef}>
                      {
                        assignments.map((assignment, i) => (
                          <Draggable key={assignment.id} draggableId={assignment.id} index={i} isDragDisabled={!editable}>
                            {
                              draggableProvider => (
                                <li
                                  className='li-shareholder'
                                  ref={draggableProvider.innerRef}
                                  {...draggableProvider.draggableProps}
                                  {...draggableProvider.dragHandleProps}
                                >
                                  {
                                    editable && (
                                      <GripVertical className='move-row' />
                                    )
                                  }
                                  <div className='cell-sh'>
                                    <InputSelect
                                      options={captable.shareClasses}
                                      className='mb-0'
                                      placeholder={`#${i + 1} ${literals.shareClass}`}
                                      value={assignment.shareClass}
                                      onChange={v => handleChangeValue(i, 'shareClass', v)}
                                      isDisabled={!editable}
                                      button={(
                                        <Button
                                          text={literalsCommon.create}
                                          icon={PlusCircle}
                                          onClick={() => setPopupShareclass(i)}
                                        />
                                      )}
                                    />
                                  </div>
                                  <div>
                                    <InputNumber
                                      className='mb-0'
                                      preText={literals.number}
                                      value={assignment.number}
                                      placeholder='0'
                                      minValue={0}
                                      maxValue={pending !== null ? (pending + assignment.number) : null}
                                      onChange={v => handleChangeValue(i, 'number', v ? parseInt(v, 10) : null)}
                                      onBlur={() => handleBlurNumeration(i)}
                                      isDisabled={!editable}
                                      decimals={0}
                                    />
                                  </div>
                                  { !auto && (
                                    <>
                                      <div>
                                        <InputNumber
                                          className='mb-0'
                                          minValue={0}
                                          preText={`#${literals.from}`}
                                          value={assignment?.from}
                                          onChange={v => handleChangeValue(i, 'from', v)}
                                          onBlur={() => handleBlurNumeration(i)}
                                          isDisabled={!editable}
                                          decimals={0}
                                        />
                                      </div>
                                      <div>
                                        <InputNumber
                                          className='mb-0'
                                          minValue={0}
                                          preText={`#${literals.to}`}
                                          value={assignment?.to}
                                          onChange={v => handleChangeValue(i, 'to', v)}
                                          isDisabled
                                          decimals={0}
                                        />
                                      </div>
                                    </>
                                  )}
                                  <div>
                                    <OutputValue
                                      className='mb-0'
                                      preText={literalsCommon.total}
                                      value={formatNumber(assignment.number * pps, 0, { dec: 2 })}
                                      symbol={currency.symbol}
                                    />
                                  </div>
                                  {
                                    editable && (
                                      <Trash className='delete-row' onClick={() => handleDeleteRow(i)} />
                                    )
                                  }
                                </li>
                              )
                            }
                          </Draggable>
                        ))
                      }
                    </ul>
                    {provider.placeholder}
                  </>
                )}
              </Droppable>
            </DragDropContext>
            {
              editable && (
                <div className='add-shareholder' onClick={handleAddRow}>
                  <UserPlus size={16} />
                  {literals.shareClass}
                </div>
              )
            }
          </div>
        </div>
      </div>
      {
        (popupShareclass !== false) && (
          <PopupFormShareClass
            captable={captable?.selected?.id}
            onClose={() => setPopupShareclass(false)}
            onSubmit={sh => handleChangeValue(popupShareclass, 'shareClass', sh.id)}
          />
        )
      }
    </>
  );
};

ShareClassAssignments.propTypes = {
  id: PropTypes.string.isRequired,
  mode: PropTypes.string,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  assignments: PropTypes.array,
  editable: PropTypes.bool,
  number: PropTypes.number,
  pps: PropTypes.number,
  numeration: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

ShareClassAssignments.defaultProps = {
  mode: 'shares',
  assignments: [],
  number: 0,
  pps: 0,
  editable: true,
  numeration: { from: null, to: null },
};

export default ShareClassAssignments;
