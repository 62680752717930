import React from 'react';
import Button from 'components/Buttons/Button';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './styles.scss';

const DisabledTool = () => {
  const literals = useSelector(state => state.i18n.literals.common);
  const navigate = useNavigate();

  return (
    <div className='tool-disabled'>
      <h2>{literals.toolNotFound}</h2>
      <Button text={literals.goBack} onClick={() => navigate(-1)} />
    </div>
  );
};

export default DisabledTool;
