import React, { useCallback, useMemo } from 'react';
import Panel from 'components/Panel';
import PropTypes from 'prop-types';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelTab from 'components/Panel/components/PanelTab';
import Table from 'components/Table';
import { fetchLocal } from 'components/Table/components/TableFunctions';
import ReactECharts from 'echarts-for-react';
import EmptyParticipation from 'assets/svg/empty_participation.svg';
import {
  formatDate, formatNumber, getColorRange, getFullName,
} from 'utils/functions';
import { Check, Hourglass } from 'lucide-react';
import { getShareholderPositionResource } from '../modules/actions';
import './styles.scss';

const MyPositionResources = (props) => {
  const {
    literals,
    literalsCommon,
    resource,
    startup,
    shareholders,
    position,
    currency,
  } = props;

  const actualDate = formatDate(new Date(), { format: 'Y-m-d' });

  const graphData = useMemo(() => {
    const historic = position?.[resource]?.historic;
    if (!historic?.length) return {};

    const aux = {};
    Object.keys(historic[0]).forEach((key) => {
      aux[key] = [];
    });

    historic.forEach((elem) => {
      Object.keys(aux).forEach((key) => {
        aux[key].push(elem[key]);
      });
    });

    return aux;
  }, [position, resource]);

  const series = {
    shares: [
      {
        name: literals.value,
        data: graphData?.value,
        type: 'line',
        tooltip: { valueFormatter: v => `${formatNumber(v)}${currency.symbol}` },
      },
      {
        name: literals.numberOfShares,
        data: graphData.number,
        type: 'line',
      },
    ],
    debts: [
      {
        name: literals.pending,
        data: graphData?.pending,
        type: 'line',
      },
      {
        name: literals.capitalized,
        data: graphData?.capitalized,
        type: 'line',
      },
      {
        name: literals.repaid,
        data: graphData?.repaid,
        type: 'line',
      },
    ],
    options: [
      {
        name: literals.assigned,
        data: graphData?.assigned,
        type: 'line',
      },
      {
        name: literals.vested,
        data: graphData?.vested,
        type: 'line',
      },
      {
        name: literals.exercised,
        data: graphData?.exercised,
        type: 'line',
      },
    ],
  };

  const columns = {
    shares: [
      {
        field: 'numeration',
        grow: 1,
        label: literals.numeration,
        preRender: v => `${formatNumber(v?.from, 0)} - ${formatNumber(v?.to, 0)}`,
      },
      {
        field: 'number',
        type: 'html',
        grow: 2,
        label: literals.shares,
        preRender: v => formatNumber(v, 0),
      },
      {
        field: 'shareClass',
        grow: 2,
        label: literals.shareClass,
        preRender: v => v?.name,
      },
      {
        field: 'value',
        type: 'html',
        label: literals.value,
        grow: 2,
        preRender: v => `${formatNumber(v, 0)}${currency.symbol}`,
      },
      {
        field: 'capital',
        grow: 2,
        label: literals.capital,
        preRender: v => `${formatNumber(v, 0, { dec: 2 })}${currency.symbol}`,
      },
    ],
    debts: [
      {
        field: 'name',
        grow: 1,
        label: literalsCommon.name,
      },
      {
        field: 'principal',
        width: 100,
        label: literals.principal,
        preRender: v => `${formatNumber(v, 0, { dec: 2 })}${currency.symbol}`,
      },
      {
        field: 'pending',
        width: 100,
        label: literals.pending,
        preRender: v => `${formatNumber(v, 0, { dec: 2 })}${currency.symbol}`,
      },
      {
        field: 'interestRate',
        type: 'html',
        width: 80,
        label: literals.interest,
        preRender: v => `${formatNumber(v, 0, { dec: 2 })}%`,
      },
      {
        field: 'startDate',
        width: 150,
        label: literals.startDate,
        preRender: v => `${v ? formatDate(v) : '-'}`,
      },
      {
        field: 'expirationDate',
        width: 150,
        label: literals.expirationDate,
        preRender: v => `${v ? formatDate(v) : '-'}`,
      },
    ],
    options: [
      {
        field: 'name',
        growth: 1,
        label: literalsCommon.name,
      },
      {
        field: 'type',
        type: 'html',
        width: 50,
        preRender: v => `<span class='cell-keywords'>
        <div>${v === 'phantom' ? 'PS' : 'SO'}</div>
      </span>`,
      },
      {
        field: 'number',
        width: 125,
        label: literals.number,
        preRender: v => `${formatNumber(v, 0)}`,
      },
      {
        field: 'capital',
        width: 100,
        label: literals.capital,
        preRender: v => `${formatNumber(v, 0, { dec: 2 })}${currency.symbol}`,
      },
      {
        field: 'assigned',
        width: 100,
        label: literals.assigned,
        preRender: v => formatNumber(v, 0),
      },
      {
        field: 'vested',
        width: 100,
        label: literals.vested,
        preRender: v => formatNumber(v, 0),
      },
      {
        field: 'exercised',
        width: 100,
        label: literals.exercised,
        preRender: (v, row) => `${row.type === 'phantom' ? '-' : formatNumber(v, 0)} `,
      },
    ],
    calendar: [
      {
        field: 'date',
        label: literalsCommon.date,
        width: 120,
        preRender: v => formatDate(v),
      },
      {
        field: 'shareholder',
        label: literals.shareholder,
        preRender: v => getFullName(v),
      },
      {
        field: 'option',
        // label: literalsCaptable.option,
        preRender: ({ name }) => name,
      },
      {
        field: 'percentage',
        label: literalsCommon.percentage,
        width: 100,
        preRender: v => formatNumber(v, '-', { symbol: '%' }),
      },
      {
        field: 'date',
        label: literalsCommon.status,
        align: 'center',
        width: 100,
        preRender: v => (
          <div className='operation-status'>
            <div className={`timeline ${actualDate.localeCompare(v) > 0 ? 'confirmed' : ''}`}>
              {React.createElement(actualDate.localeCompare(v) < 0 ? Hourglass : Check, { size: 11 })}
            </div>
          </div>
        ),
      },
    ],
    milestones: [
      {
        field: 'name',
        label: literals.milestones,
      },
      {
        field: 'shareholder',
        label: literals.shareholder,
        preRender: v => getFullName(v),
      },
      {
        field: 'percentage',
        label: literalsCommon.percentage,
        preRender: v => formatNumber(v, '-', { symbol: '%' }),
      },
      {
        field: 'option',
        label: literals.option,
        preRender: ({ name }) => name,
      },
    ],
  };

  const fetchData = useCallback(async (page, size) => {
    return getShareholderPositionResource(startup, shareholders, resource, {
      page, size,
    });
  }, [startup, shareholders, resource]);

  const fetchVestedData = useCallback(async (type) => {
    const aux = await getShareholderPositionResource(startup, shareholders, 'vestedOptions');
    return fetchLocal({ items: aux[type] });
  }, [startup, shareholders]);

  if (!(position?.shares.historic.length || position?.debts.historic.length || position?.options.historic.length)) {
    return (
      <Panel>
        <PanelBody>
          <li className='panel-empty-message'>
            <img src={EmptyParticipation} alt={literals.noParticipation} />
            <span className='mt-4'>{literals.noParticipation}</span>
          </li>
        </PanelBody>
      </Panel>
    );
  }

  let tabs = [
    { key: 'table', name: literalsCommon.table },
    { key: 'chart', name: literalsCommon.chart },
  ];

  if (resource === 'options') {
    tabs = tabs.concat([
      { key: 'calendar', name: literals.calendar },
      { key: 'milestones', name: literals.milestones },
    ]);
  }


  return (
    <Panel>
      <PanelHeader title={literals[resource]} tabs={tabs} />
      <PanelBody>
        <PanelTab key='table'>
          <Table
            searcher={false}
            columns={columns[resource]}
            fetch={resource ? fetchData : null}
            forceFetch={resource}
            pageSize={5}
          />
        </PanelTab>
        <PanelTab key='chart'>
          <ReactECharts
            option={{
              color: getColorRange(series[resource].length),
              tooltip: { trigger: 'axis', valueFormatter: v => formatNumber(v) },
              legend: { data: series[resource].map(s => s.name) },
              xAxis: { type: 'category', data: graphData.date, axisLabel: { rotate: 60 } },
              yAxis: { type: 'value' },
              series: series[resource],
            }}
            style={{ height: '350px' }}
          />
        </PanelTab>
        { resource === 'options' && (
          <PanelTab key='calendar'>
            <Table
              searcher={false}
              columns={columns.calendar}
              fetch={() => fetchVestedData('calendar')}
            />
          </PanelTab>
        )}
        { resource === 'options' && (
          <PanelTab key='milestones'>
            <Table
              searcher={false}
              columns={columns.milestones}
              fetch={() => fetchVestedData('milestones')}
            />
          </PanelTab>
        )}
      </PanelBody>
    </Panel>
  );
};

MyPositionResources.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  historic: PropTypes.array.isRequired,
  resource: PropTypes.string.isRequired,
  startup: PropTypes.string.isRequired,
  shareholders: PropTypes.string.isRequired,
};

export default MyPositionResources;
