import AdminInstance from 'modules/admin/repository';
import StartupsInstance from 'modules/startups/repository';
import { apiDownloadFile } from 'modules/actions';
import { API_ADMIN_STARTUPS_EXCEL, API_ADMIN_STARTUPS_EXCEL_TEMPLATE } from 'modules/apiEndpoints';


export function fetchStartups(page, size, filters) {
  return AdminInstance.getStartups({
    page, size, ...filters,
  });
}

export function createStartup(data) {
  return AdminInstance.createStartup(data);
}
export function deleteStartup(id) {
  return AdminInstance.deleteStartup(id);
}

export function createStartupMassive(file, checkName, sendEmail) {
  return AdminInstance.createStartupMassive(file, { checkName, sendEmail });
}
export function fetchStartupUsers(id, opts) {
  return StartupsInstance.getStartupUsers(id, opts);
}

export function fetchStartupGuestsUsers(id, opts) {
  return StartupsInstance.getStartupGuestsUsers(id, opts);
}

export function changeInviteStatus(id, user, status) {
  return StartupsInstance.changeStartupInviteStatus(id, user, status);
}

export function downloadExcelStartups(filename) {
  return apiDownloadFile(API_ADMIN_STARTUPS_EXCEL, filename);
}

export function downloadExcelStartupsTemplate(filename) {
  return apiDownloadFile(API_ADMIN_STARTUPS_EXCEL_TEMPLATE, filename);
}

export function editStartup(id, data) {
  return StartupsInstance.startupEdit(id, data);
}

export function switchStartupVerified(startups, verified) {
  return typeof startups === 'string'
    ? AdminInstance.setStartupVerified(startups, verified)
    : AdminInstance.setStartupsVerified(verified, { startups });
}

export function switchStartupPhantom(startup, phantom) {
  const startups = typeof startup === 'string' ? [startup] : startup;
  return AdminInstance.setStartupsPhantom({ startups, phantom });
}

export function mergeStartups(main, others) {
  return AdminInstance.mergeStartups(main, others);
}

export function deleteUserFromStartup(id, user) {
  return StartupsInstance
    .deleteAddUser(id, user)
    .then(() => {
      return true;
    });
}

export function setStartupPlan(id, plan) {
  return AdminInstance.setStartupPlan(id, plan);
}
