import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Panel from 'components/Panel/components/';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Table from 'components/Table';
import { useCounter } from 'utils/customHooks';
import { getServices } from '../modules/actions';
import ServiceRow from './ServiceRow';
import PopupInfoService from './PopupInfoService';
import './styles.scss';

const Services = (props) => {
  const {
    literals, literalsCommon, match,
  } = props;

  const tableRefreshFlag = useCounter(0);
  const [showServiceInfo, setShowServiceInfo] = useState(false);

  const getStartupServices = (page, size, search, filters, sort) => {
    return getServices(match.params.id, page, size, search, filters, sort);
  };

  const handleChangeRequest = (v) => {
    tableRefreshFlag.increase();
    setShowServiceInfo(prev => ({ ...prev, request: v }));
  };

  return (
    <Tool>
      <Panel>
        <PanelHeader title={literals.title} />
        <PanelBody className='services-wrapper'>
          <Table
            mode='components'
            component={ServiceRow}
            componentProps={{ ...props, setShowServiceInfo }}
            componentColumns={{
              xs: 1, sm: 2, md: 3, lg: 3, xl: 3,
            }}
            pageSize={10}
            fetch={getStartupServices}
            forceFetch={tableRefreshFlag.value}
          />
          {
            showServiceInfo && (
              <PopupInfoService
                literals={literals}
                literalsCommon={literalsCommon}
                {...showServiceInfo}
                handleChangeRequest={handleChangeRequest}
                onClose={() => setShowServiceInfo(false)}
              />
            )
          }
        </PanelBody>
      </Panel>
    </Tool>
  );
};

Services.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
};

export default Services;
