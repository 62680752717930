import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { formatDate, getColorRange } from 'utils/functions';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import './styles.scss';

const WaterfallComparatorValues = ({
  match = null,
  loading,
  selectWaterfalls,
  setSelectWaterfalls = null,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const graphColors = getColorRange(Object.keys(selectWaterfalls).length);

  return Object.keys(selectWaterfalls).length ? (
    <div className='box box-padding box-mb'>
      <DragDropContext
        onDragEnd={async ({ draggableId, destination }) => {
          if (destination) {
            const waterfallsArray = Object.entries(selectWaterfalls);
            const [, draggableWaterfall] = waterfallsArray.find(([id]) => id === draggableId);

            const orderedWaterfalls = Object.fromEntries(
              waterfallsArray.filter(([id]) => id !== draggableId)
                .slice(0, destination.index)
                .concat([[draggableId, draggableWaterfall]])
                .concat(waterfallsArray.slice(destination.index)),
            );
            setSelectWaterfalls(orderedWaterfalls);
            navigate({ search: Object.keys(orderedWaterfalls).length ? `ids=${Object.keys(orderedWaterfalls).join(',')}` : '' }, { replace: true });
          }
        }}
      >
        <Droppable droppableId='order-waterfalls'>
          {droppableProvider => (
            <div
              {...droppableProvider.droppableProps}
              ref={droppableProvider.innerRef}
            >
              {Object.values(selectWaterfalls).map((waterfall, i) => (
                <Draggable key={waterfall.id} draggableId={waterfall.id} index={i}>
                  {draggableProvider => (
                    <div
                      {...draggableProvider.draggableProps}
                      ref={draggableProvider.innerRef}
                      {...draggableProvider.dragHandleProps}
                      className='row waterfall-comparator-info'
                    >
                      <div className='col-12 col-md-6 mb-3'>
                        <span className='waterfall-name'>
                          <div className='waterfall-name--circle' style={{ background: graphColors[i] }} />
                          <span
                            className='waterfall-name--name'
                            onClick={() => navigate(embedView(ROUTE_STARTUP_PATH.setCaptableWaterfallAdd(match.params.id, waterfall.id, captableId)))}
                          >
                            {waterfall.name}
                          </span>
                        </span>
                      </div>
                      <div className='col-12 col-md-6 mb-3'>
                        <span>{`${waterfall.operation.name} (${formatDate(waterfall.operation.date)})`}</span>
                      </div>
                      <div className='col-12'>
                        <span className='waterfall-description' dangerouslySetInnerHTML={{ __html: waterfall.description }} />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {droppableProvider.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {loading > 0 && [...Array(loading)].map((_, index) => (
        <div key={index} className='row waterfall-info'>
          <div className='col-12 col-md-6 mb-3'>
            <span className='waterfall-name'>
              <Skeleton animation='wave' variant='circular' width={13} height={13} />
              <Skeleton className='ml-2' animation='wave' width='50%' height={20} />
            </span>
          </div>
          <div className='col-12 col-md-6 mb-3'>
            <Skeleton animation='wave' width='70%' height={20} />
          </div>
          <div className='col-12'>
            <Skeleton animation='wave' width='100%' height={20} />
            <Skeleton animation='wave' width='100%' height={20} />
          </div>
        </div>
      ))}
    </div>
  ) : null;
};

WaterfallComparatorValues.propTypes = {
  loading: PropTypes.bool.isRequired,
  selectWaterfalls: PropTypes.object.isRequired,
  match: PropTypes.object,
  setSelectWaterfalls: PropTypes.func,
};

export default WaterfallComparatorValues;
