import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import Popup from 'components/Popup';
import useDialog from 'components/Dialog/components/useDialog';
import { formatDate } from 'utils/functions';
import { literalTemplate } from 'utils/language';
import { useSelector } from 'react-redux';
import { unifyShareClasses } from '../modules/actions';

const PopupUnifyShareClasses = (props) => {
  const {
    literalsCommon,
    startup,
    shareClasses,
    onSubmit,
    onClose,
  } = props;

  const literals = useSelector(state => state.i18n.literals.shareClass);
  const { dialog } = useDialog();

  const handleUnifyShareClasses = async (sc) => {
    const confirm = await dialog({
      text: literalTemplate(literals.confirmUnifyShareClasses, {
        SHARECLASSES: shareClasses.length,
        SHARECLASS: sc.name,
      }),
      type: 'confirmDanger',
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            await unifyShareClasses(startup, sc.id, shareClasses.map(aux => aux.id));
            onSubmit();
            onClose();
          } catch (errors) {
            await dialog({
              text: literalsCommon.error, type: 'error',
            });
          }
        },
      });
    }
  };

  const columns = [
    {
      field: 'name',
      grow: 3,
      label: literalsCommon.name,
      sortable: true,
    },
    {
      field: 'type',
      label: literalsCommon.type,
      width: 150,
      preRender: (v, row) => (
        <div className='cell-keywords'>
          <div key={`role-${row.id}`}>{literals[v] || v}</div>
        </div>
      ),
    },
    {
      field: 'createdAt',
      grow: 3,
      label: literalsCommon.createdAt,
      sortable: true,
      preRender: formatDate,
    },
  ];

  return (
    <Popup title={literals.unifyShareClasses} onClose={onClose}>
      <Table
        searcher={false}
        columns={columns}
        fetch={() => ({ items: shareClasses, total: shareClasses.length })}
        onClickRow={handleUnifyShareClasses}
      />
    </Popup>
  );
};

PopupUnifyShareClasses.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  startup: PropTypes.string.isRequired,
  shareClasses: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupUnifyShareClasses;
