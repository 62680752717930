import {
  TOOL_LEVEL_EDIT, TOOL_LEVEL_VIEW, TOOL_MENTORINGS, TOOL_PROFILE, TOOL_USERS, TOOL_DATA_ROOM, TOOL_INVESTMENTS, TOOL_PERFORMANCE, TOOL_CAP_TABLE,
} from 'constants/tools';
import { API_BASE_URL } from 'constants/apiConf';
import { ROLE_INVESTOR } from 'constants/roles';

import NotFound from './NotFound';
import Login from './Login';
import Signup from './Signup';
import SetPassword from './SetPassword';
import Onboarding from './Onboarding';
import PreHome from './PreHome';
import Home from './Home';
import Mentoring from './Mentoring';
import Logout from './Logout';
import Mentorings from './Mentorings';
import EditInfo from './EditInfo';
import EditAccount from './EditAccount';
import Users from './Admin/Users';
import Startups from './Admin/Startups';
import Platform from './Admin/Platform';
import StartupUsers from './StartupUsers';
import AdminMentorings from './Admin/Mentorings';
import Help from './Help';
import Legal from './Legal';
import MentoringsSearch from './Mentorings/components/routes/MentoringsSearch';
import AdminPerformance from './Admin/Performance';
import AdminReporting from './Admin/Reporting';
import Support from './Admin/Support';
import Reporting from './Reporting';
import ReportingAdd from './Reporting/components/routes/ReportingAdd';
import ReportingInfo from './Reporting/components/routes/ReportingInfo';
import ReportingTemplates from './Reporting/components/routes/ReportingTemplates';
import DataRoom from './DataRoom';
import ShareHolders from './Captable/components/routes/shareHolders';
import ShareHolderInfo from './Captable/components/routes/shareHolders/components/routes/shareholderInfo';
import VestingsPlans from './Captable/components/routes/vestingPlans';
import Performance from './Performance';
import PerformanceAdd from './Performance/components/routes/PerformanceAdd';
import PerformancePending from './Performance/components/routes/PerformancePending';
import CapTable from './Captable';
import CaptableOperations from './Captable/components/routes/Operations';
import CaptableOperation from './Captable/components/routes/Operation';
import CaptableSecurities from './Captable/components/routes/Securities';
import CaptableStatutoryBooks from './Captable/components/routes/StatutoryBooks';
import CaptableSimulationOperations from './Captable/components/routes/Operations/components/routes/SimulationOperations';
import CaptableWaterfall from './Captable/components/routes/waterfall';
import CaptableWaterfallAdd from './Captable/components/routes/waterfall/components/routes/waterfallAdd';
import CaptableWaterfallComparator from './Captable/components/routes/waterfall/components/routes/waterfallComparator';
import ShareClasses from './Captable/components/routes/shareClasses';
import SharesDistribution from './Captable/components/routes/SharesDistribution';
import CaptableOperationsComparator from './Captable/components/routes/Operations/components/routes/Comparator';
import DownloadFile from './DownloadFile';
import MyPosition from './MyPosition';
import AdminReportingNewTemplate from './Admin/Reporting/components/routes/ReportingNewTemplate';
import ReportingTemplatesAdd from './Reporting/components/routes/ReportingTemplates/components/routes/ReportingTemplatesAdd';
import ViewInfo from './ViewInfo';
import PerformanceComparator from './Performance/components/routes/PerformanceComparator';
import AdminServices from './Admin/Services';
import Services from './Services';
import MyInvestmentsPortfolio from './MyInvestments/components/routes/MyInvestmentsPortfolio';
import MyInvestmentsAnalysis from './MyInvestments/components/routes/MyInvestmentsAnalysis';
import MyInvestmentsOpportunities from './MyInvestments/components/routes/MyInvestmentsOpportunities';
import StartupFundraise from './StartupFundraise';
import StartupFundraiseAdd from './StartupFundraise/components/routes/StartupFundraiseAdd';
import Fundraise from './Fundraise';
import AdminFundraises from './Admin/Fundraises';
import AdminAnalytics from './Admin/Analytics';
import CaptableCaptables from './Captable/components/routes/Captables';
import AdminFunds from './Admin/Funds';
import PerformanceStartupsComparator from './Performance/components/routes/PerformanceStartupsComparator';
import PermissionTools from './PermissionTools';
import CaptableUnauthorized from './Captable/components/routes/Unauthorized';
import AdminWebhooks from './Admin/Webhooks';
import AdminScripts from './Admin/Scripts';
import AdminCrons from './Admin/Crons';

export const isEmbed = () => {
  return window?.location?.pathname?.startsWith('/embed/');
};

export const embedView = (path) => {
  return `${isEmbed() ? '/embed' : ''}${path}`;
};

const addSearchParams = (params) => {
  const filteredParams = Object.entries(params).filter(([, value]) => value);
  const searchParams = new URLSearchParams(filteredParams);
  return searchParams.toString() ? `?${searchParams.toString()}` : '';
};

export const ROUTE_TYPE_REDIRECT = 'redirect';
export const ROUTE_PATH = Object.freeze({
  BASEROUTE: '/',
  LOGIN: '/access',
  SIGNUP: '/register',
  LOGOUT: '/logout',
  PREHOME: '/prehome',
  PASSWORD: '/password/:id',
  LEGAL: '/legal/:type/:lang',
  EDIT_ACCOUNT: '/edit-account',
  DOWNLOAD_FILE: '/file/:hash/:filename',
  DOWNLOAD_FILE_TEMPLATE: `${window.location.origin}/file/{id}/{name}`,
  DOWNLOAD_PUBLIC_FILE: '/file/public/:hash/:filename',
  MY_POSITION: '/my-position',
  NOT_FOUND: '/404',
  FUNDRAISE: '/fundraise/:id',
  FUNDRAISE_ADD: '/fundraise/:id',
  setPassword: token => `/password/${token}`,
  setLegal: (type, lang) => `/legal/${type}/${lang}`,
  setDownloadFile: (hash, filename, _public = false, params = '') => `/file${_public ? '/public' : ''}/${hash}/${filename}${params}`,
  setFundraise: id => `/fundraise/${id}`,
});

export const ROUTE_POSITION_PATH = Object.freeze({
  BASE: '/my-position',
  PORTFOLIO: '/my-position/portfolio',
  ANALYSIS: '/my-position/analysis',
  KPI_STARTUPS_COMPARATOR: '/my-position/analysis/comparator',
  OPPORTUNITIES: '/my-position/opportunities',
  EDIT_INVESTOR: '/my-position/edit',

  setKpiStartupsComparator: kpi => `/my-position/analysis/comparator${kpi ? `?kpi=${kpi}` : ''}`,
});

export const ROUTE_STARTUP_PATH = Object.freeze({
  BASE: '/startup',
  ONBOARDING: '/startup/onboarding',
  ONBOARDING_PHANTOM: '/startup/:id/onboarding',
  HOME: '/startup/:id',
  PROFILE: '/startup/:id/profile',
  PERFORMANCE: '/startup/:id/performance',
  PERFORMANCE_ADD: '/startup/:id/performance/add',
  PERFORMANCE_COMPARATOR: '/startup/:id/performance/comparator',
  PERFORMANCE_PENDING: '/startup/:id/performance/pending',
  MENTORINGS: '/startup/:id/mentorings',
  MENTORINGS_SEARCH: '/startup/:id/mentorings/search',
  MENTORING: '/startup/:id/mentorings/:mentoring',
  USERS: '/startup/:id/users/:mentoring',
  USERS_STARTUP: '/startup/:id/users',
  USERS_STARTUP_PERMISSIONS: '/startup/:id/users/permissions',
  VIEW_STARTUP: '/startup/:id/view',
  EDIT_STARTUP: '/startup/:id/edit',
  HELP: '/startup/help',
  REPORTING: '/startup/:id/reporting',
  REPORTING_ADD: '/startup/:id/reporting/:report',
  REPORTING_TEMPLATES: '/startup/:id/reporting/templates',
  REPORTING_TEMPLATES_ADD: '/startup/:id/reporting/templates/:template',
  REPORTING_INFO: '/startup/:id/reporting/:reporting/info',
  DATA_ROOM: '/startup/:id/data-room',
  CAP_TABLE: '/startup/:id/captable',
  CAP_TABLE_UNAUHTORIZED: '/startup/:id/captable/unauthorized',
  CAP_TABLE_CAP_TABLES: '/startup/:id/captable/captables',
  CAP_TABLE_SECURITIES: '/startup/:id/captable/securities',
  CAP_TABLE_OPERATIONS: '/startup/:id/captable/operations',
  CAP_TABLE_OPERATIONS_COMPARATOR: '/startup/:id/captable/operations/comparator/:operation',
  CAP_TABLE_OPERATION: '/startup/:id/captable/operations/:operation',
  CAP_TABLE_SIMULATION: '/startup/:id/captable/simulations/:simulation',
  CAP_TABLE_SHAREHOLDERS: '/startup/:id/captable/shareholders',
  CAP_TABLE_SHAREHOLDER_INFO: '/startup/:id/captable/shareholders/:shareholder',
  CAP_TABLE_VESTING_PLANS: '/startup/:id/captable/vesting-plans',
  CAP_TABLE_SHARE_CLASSES: '/startup/:id/captable/share-classes',
  CAP_TABLE_SHARES_DISTRIBUTION: '/startup/:id/captable/shares-distribution',
  CAP_TABLE_STATUTORY_BOOK: '/startup/:id/captable/statutory-book',
  CAP_TABLE_WATERFALL: '/startup/:id/captable/waterfall',
  CAP_TABLE_WATERFALL_ADD: '/startup/:id/captable/waterfall/:waterfallId',
  CAP_TABLE_WATERFALL_COMPARATOR: '/startup/:id/captable/waterfall/comparator/',
  MY_POSITION: '/startup/:id/my-position',
  SERVICES: '/startup/:id/services',
  FUNDRAISE: '/startup/:id/fundraise',
  FUNDRAISE_ADD: '/startup/:id/fundraise/:fundraise',
  EMBED: '/embed/:route',

  setHome: id => `/startup/${id}`,
  setMentorings: id => `/startup/${id}/mentorings`,
  setPerformance: id => `/startup/${id}/performance`,
  setPerformanceAdd: id => `/startup/${id}/performance/add`,
  setPerformanceComparator: id => `/startup/${id}/performance/comparator`,
  setPerformancePending: id => `/startup/${id}/performance/pending`,
  setMentoringsSearch: id => `/startup/${id}/mentorings/search`,
  setMentoring: (id, mentoring) => `/startup/${id}/mentorings/${mentoring}`,
  setViewStartup: id => `/startup/${id}/view`,
  setEditStartup: id => `/startup/${id}/edit`,
  setUsersStartup: id => `/startup/${id}/users`,
  setUsersStartupPermissions: id => `/startup/${id}/users/permissions`,
  setOnboardingPhantom: id => `/startup/${id}/onboarding`,
  setReporting: id => `/startup/${id}/reporting`,
  setReportingAdd: (id, report) => `/startup/${id}/reporting/${report}`,
  setReportingTemplates: id => `/startup/${id}/reporting/templates`,
  setReportingTemplatesAdd: (id, template) => `/startup/${id}/reporting/templates/${template}`,
  setReportingInfo: (id, reporting) => `/startup/${id}/reporting/${reporting}/info`,
  setReportingPreview: (id, message, filesPath = '') => `${API_BASE_URL}/api/v1/startups/${id}/reports/preview?message=${message}&filesPath=${filesPath}`,
  setDataRoom: (id, folder = '') => `/startup/${id}/data-room${folder ? `#${folder}` : ''}`,
  setCapTable: (id, captable) => `/startup/${id}/captable${captable ? `?captable=${captable}` : ''}`,
  setCapTableUnauthorized: (id, captable) => `/startup/${id}/captable/unauthorized${captable ? `?captable=${captable}` : ''}`,
  setCapTableCaptables: id => `/startup/${id}/captable/captables`,
  setCapTableSecurities: (id, captable) => `/startup/${id}/captable/securities${captable ? `?captable=${captable}` : ''}`,
  setCapTableOperations: (id, captable) => `/startup/${id}/captable/operations${captable ? `?captable=${captable}` : ''}`,
  setCapTableSimulations: (id, captable) => `/startup/${id}/captable/operations${captable ? `?captable=${captable}` : ''}#simulations`,
  setCapTableOperationsComparator: (id, captable, operation = 'all', simulation = null) => `/startup/${id}/captable/operations/comparator/${operation}${addSearchParams({ captable, simulation })}`,
  setCapTableOperation: (id, captable, operation, simulation = null) => `/startup/${id}/captable/operations/${operation}${addSearchParams({ captable, simulation })}`,
  setCapTableSimulation: (id, captable, simulation) => `/startup/${id}/captable/simulations/${simulation}${captable ? `?captable=${captable}` : ''}`,
  setCapTableShareHolders: (id, captable) => `/startup/${id}/captable/shareholders${captable ? `?captable=${captable}` : ''}`,
  setCapTableShareHolderInfo: (id, captable, shareholder) => `/startup/${id}/captable/shareholders/${shareholder}${captable ? `?captable=${captable}` : ''}`,
  setCapTableVestingPlans: (id, captable) => `/startup/${id}/captable/vesting-plans${captable ? `?captable=${captable}` : ''}`,
  setCapTableShareClasses: (id, captable) => `/startup/${id}/captable/share-classes${captable ? `?captable=${captable}` : ''}`,
  setCapTableSharesDistribution: (id, captable) => `/startup/${id}/captable/shares-distribution${captable ? `?captable=${captable}` : ''}`,
  setCaptableStatutoryBook: (id, captable) => `/startup/${id}/captable/statutory-book${captable ? `?captable=${captable}` : ''}`,
  setCaptableWaterfall: (id, captable) => `/startup/${id}/captable/waterfall${captable ? `?captable=${captable}` : ''}`,
  setCaptableWaterfallAdd: (id, waterfallId, captable, operation, simulation) => `/startup/${id}/captable/waterfall/${waterfallId}${addSearchParams({ captable, operation, simulation })}`,
  setCaptableWaterfallComparator: (id, captable) => `/startup/${id}/captable/waterfall/comparator${captable ? `?captable=${captable}` : ''}`,
  setMyPosition: (id, taxId) => `/startup/${id}/my-position${taxId ? `?taxId=${taxId}` : ''}`,
  setServices: id => `/startup/${id}/services`,
  setFundraise: id => `/startup/${id}/fundraise`,
  setFundraiseAdd: (id, fundraise) => `/startup/${id}/fundraise/${fundraise}`,
  setEmbed: route => `/embed${route}`,
});

export const ROUTE_MENTOR_PATH = Object.freeze({
  ONBOARDING: '/mentor/onboarding',
  BASEROUTE: '/mentor/onboarding',
  HOME: '/mentor',
  PROFILE: '/mentor/profile',
  MENTORINGS: '/mentor/mentorings',
  MENTORINGS_SEARCH: '/mentor/mentorings/search',
  MENTORING: '/mentor/mentorings/:mentoring',
  EDIT_MENTOR: '/mentor/:id/edit',
  HELP: '/mentor/help',

  setMentoring: id => `/mentor/mentorings/${id}`,
  setEditMentor: id => `/mentor/${id}/edit`,
});

export const ROUTE_FUND_PATH = Object.freeze({
  ONBOARDING: '/fund/onboarding',
  ONBOARDING_PHANTOM: '/fund/:id/onboarding',

  setOnboardingPhantom: id => `/fund/${id}/onboarding`,

});

export const ROUTE_ADMIN_PATH = Object.freeze({
  HOME: '/admin',
  ANALYTICS: '/admin/analytics',
  PLATFORM: '/admin/platform',
  USERS: '/admin/users',
  STARTUPS: '/admin/startups',
  MENTORINGS: '/admin/mentorings',
  MENTORING: '/admin/mentorings/:mentoring',
  PERFORMANCE: '/admin/performance',
  REPORTING: '/admin/reporting',
  REPORTING_TEMPLATE: '/admin/reporting/templates/:template',
  FUND: '/admin/fund',
  FUNDRAISE: '/admin/fundraise',
  SERVICES: '/admin/services',
  WEBHOOKS: '/admin/webhooks',
  SCRIPTS: '/admin/scripts',
  CRONS: '/admin/crons',
  HELP: '/admin/help',
  EDIT_STARTUP: '/admin/startup/:id/edit',
  SUPPORT: '/admin/support',
  WZ_EMAIL_LOGS: '/wz-admin/email/logs',
  setMentoring: id => `/admin/mentorings/${id}`,
  setEditStartup: id => `/admin/startup/${id}/edit`,
  setReportingTemplate: template => `/admin/reporting/templates/${template}`,
});

export const routes = [
  {
    type: ROUTE_TYPE_REDIRECT,
    path: ROUTE_PATH.BASEROUTE,
    exact: true,
    ignoreSession: false,
    to: ROUTE_PATH.PREHOME,
  },
  {
    path: ROUTE_PATH.PREHOME,
    component: PreHome,
    exact: true,
    header: true,
    aside: false,
    ignoreSession: false,
  },
  {
    path: ROUTE_PATH.LOGIN,
    component: Login,
    exact: false,
    header: false,
    aside: false,
    className: 'public-form',
    ignoreSession: true,
  },
  {
    path: ROUTE_PATH.SIGNUP,
    component: Signup,
    exact: true,
    header: false,
    aside: false,
    className: 'public-form',
    ignoreSession: true,
  },
  {
    path: ROUTE_PATH.LOGOUT,
    component: Logout,
    exact: false,
    header: false,
    aside: false,
    ignoreSession: false,
  },
  {
    path: ROUTE_PATH.PASSWORD,
    component: SetPassword,
    exact: true,
    header: false,
    aside: false,
    className: 'public-form',
    ignoreSession: true,
  },
  {
    path: ROUTE_PATH.LEGAL,
    component: Legal,
    exact: true,
    header: false,
    aside: false,
    ignoreSession: true,
  },
  {
    path: ROUTE_PATH.EDIT_ACCOUNT,
    component: EditAccount,
    exact: true,
    header: true,
    aside: false,
    ignoreSession: false,
  },
  {
    path: ROUTE_PATH.DOWNLOAD_FILE,
    component: DownloadFile,
    exact: true,
    header: false,
    aside: false,
    className: 'public-form',
    ignoreSession: true,
  },
  {
    path: ROUTE_PATH.DOWNLOAD_PUBLIC_FILE,
    component: DownloadFile,
    exact: true,
    header: false,
    aside: false,
    className: 'public-form',
    ignoreSession: true,
  },
  {
    path: ROUTE_PATH.FUNDRAISE,
    component: Fundraise,
    exact: true,
    header: true,
    aside: false,
    ignoreSession: true,
  },
  {
    path: ROUTE_PATH.NOT_FOUND,
    component: NotFound,
    header: false,
  },
];

export const routesPosition = [
  {
    type: ROUTE_TYPE_REDIRECT,
    path: ROUTE_POSITION_PATH.BASE,
    exact: true,
    to: ROUTE_POSITION_PATH.PORTFOLIO,
  },
  {
    path: ROUTE_POSITION_PATH.PORTFOLIO,
    component: MyInvestmentsPortfolio,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_INVESTMENTS,
  },
  {
    path: ROUTE_POSITION_PATH.ANALYSIS,
    component: MyInvestmentsAnalysis,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_INVESTMENTS,
  },
  {
    path: ROUTE_POSITION_PATH.KPI_STARTUPS_COMPARATOR,
    component: PerformanceStartupsComparator,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_INVESTMENTS,
  },
  {
    path: ROUTE_POSITION_PATH.OPPORTUNITIES,
    component: MyInvestmentsOpportunities,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_POSITION_PATH.EDIT_INVESTOR,
    component: EditInfo,
    props: { type: ROLE_INVESTOR },
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
];

export const routesStartup = [
  {
    path: ROUTE_STARTUP_PATH.ONBOARDING,
    component: Onboarding,
    props: {
      type: 'startup',
    },
    exact: true,
    header: false,
    aside: false,
    ignoreSession: false,
    embed: 'onboarding',
  },
  {
    path: ROUTE_STARTUP_PATH.ONBOARDING_PHANTOM,
    component: Onboarding,
    props: {
      type: 'startup',
    },
    exact: true,
    header: false,
    aside: false,
    ignoreSession: false,
  },
  {
    path: ROUTE_STARTUP_PATH.HELP,
    component: Help,
    props: {
      type: 'startup',
    },
    exact: true,
    header: true,
    aside: false,
    ignoreSession: false,
  },
  {
    path: ROUTE_STARTUP_PATH.HOME,
    component: Home,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_STARTUP_PATH.USERS_STARTUP,
    component: StartupUsers,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    permission: {
      tool: TOOL_USERS,
      level: TOOL_LEVEL_VIEW,
    },
  },
  {
    path: ROUTE_STARTUP_PATH.USERS_STARTUP_PERMISSIONS,
    component: PermissionTools,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    permission: {
      tool: TOOL_USERS,
      level: TOOL_LEVEL_VIEW,
    },
  },
  {
    path: ROUTE_STARTUP_PATH.PERFORMANCE,
    component: Performance,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_PERFORMANCE,
  },
  {
    path: ROUTE_STARTUP_PATH.PERFORMANCE_ADD,
    component: PerformanceAdd,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_PERFORMANCE,
  },
  {
    path: ROUTE_STARTUP_PATH.PERFORMANCE_COMPARATOR,
    component: PerformanceComparator,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_PERFORMANCE,
  },
  {
    path: ROUTE_STARTUP_PATH.PERFORMANCE_PENDING,
    component: PerformancePending,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_PERFORMANCE,
  },
  {
    path: ROUTE_STARTUP_PATH.MENTORINGS,
    component: Mentorings,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    permission: {
      tool: TOOL_MENTORINGS,
      level: TOOL_LEVEL_VIEW,
    },
  },
  {
    path: ROUTE_STARTUP_PATH.MENTORINGS_SEARCH,
    component: MentoringsSearch,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    permission: {
      tool: TOOL_MENTORINGS,
      level: TOOL_LEVEL_EDIT,
    },
  },
  {
    path: ROUTE_STARTUP_PATH.MENTORING,
    component: Mentoring,
    props: {
      type: 'startup',
    },
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    permission: {
      tool: TOOL_MENTORINGS,
      level: TOOL_LEVEL_VIEW,
    },
  },
  {
    path: ROUTE_STARTUP_PATH.VIEW_STARTUP,
    component: ViewInfo,
    props: {
      type: 'startup',
    },
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_STARTUP_PATH.EDIT_STARTUP,
    component: EditInfo,
    props: {
      type: 'startup',
    },
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    permission: {
      tool: TOOL_PROFILE,
      level: TOOL_LEVEL_EDIT,
    },
  },
  {
    path: ROUTE_STARTUP_PATH.REPORTING,
    component: Reporting,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_STARTUP_PATH.REPORTING_TEMPLATES,
    component: ReportingTemplates,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_STARTUP_PATH.REPORTING_TEMPLATES_ADD,
    component: ReportingTemplatesAdd,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_STARTUP_PATH.REPORTING_ADD,
    component: ReportingAdd,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_STARTUP_PATH.REPORTING_INFO,
    component: ReportingInfo,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_STARTUP_PATH.DATA_ROOM,
    component: DataRoom,
    props: {
      type: 'startup',
    },
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_DATA_ROOM,
    permission: {
      tool: TOOL_DATA_ROOM,
      level: TOOL_LEVEL_VIEW,
    },
  },
  {
    path: ROUTE_STARTUP_PATH.CAP_TABLE,
    component: CapTable,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_CAP_TABLE,
  },
  {
    path: ROUTE_STARTUP_PATH.CAP_TABLE_UNAUHTORIZED,
    component: CaptableUnauthorized,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_CAP_TABLE,
  },
  {
    path: ROUTE_STARTUP_PATH.CAP_TABLE_CAP_TABLES,
    component: CaptableCaptables,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_CAP_TABLE,
  },
  {
    path: ROUTE_STARTUP_PATH.CAP_TABLE_SECURITIES,
    component: CaptableSecurities,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_CAP_TABLE,
  },
  {
    path: ROUTE_STARTUP_PATH.CAP_TABLE_OPERATIONS,
    component: CaptableOperations,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_CAP_TABLE,
  },
  {
    path: ROUTE_STARTUP_PATH.CAP_TABLE_SIMULATION,
    component: CaptableSimulationOperations,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_CAP_TABLE,
  },
  {
    path: ROUTE_STARTUP_PATH.CAP_TABLE_OPERATIONS_COMPARATOR,
    component: CaptableOperationsComparator,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_CAP_TABLE,
  },
  {
    path: ROUTE_STARTUP_PATH.CAP_TABLE_OPERATION,
    component: CaptableOperation,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_CAP_TABLE,
  },
  {
    path: ROUTE_STARTUP_PATH.CAP_TABLE_SHAREHOLDERS,
    component: ShareHolders,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_CAP_TABLE,
  },
  {
    path: ROUTE_STARTUP_PATH.CAP_TABLE_SHAREHOLDER_INFO,
    component: ShareHolderInfo,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_CAP_TABLE,
  },
  {
    path: ROUTE_STARTUP_PATH.CAP_TABLE_SHARES_DISTRIBUTION,
    component: SharesDistribution,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_CAP_TABLE,
  },
  {
    path: ROUTE_STARTUP_PATH.CAP_TABLE_VESTING_PLANS,
    component: VestingsPlans,
    props: {
      type: 'startup',
    },
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_CAP_TABLE,
  },
  {
    path: ROUTE_STARTUP_PATH.CAP_TABLE_SHARE_CLASSES,
    component: ShareClasses,
    props: {
      type: 'startup',
    },
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_CAP_TABLE,
  },
  {
    path: ROUTE_STARTUP_PATH.CAP_TABLE_STATUTORY_BOOK,
    component: CaptableStatutoryBooks,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_CAP_TABLE,
  },
  {
    path: ROUTE_STARTUP_PATH.CAP_TABLE_WATERFALL,
    component: CaptableWaterfall,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_CAP_TABLE,
  },
  {
    path: ROUTE_STARTUP_PATH.CAP_TABLE_WATERFALL_COMPARATOR,
    component: CaptableWaterfallComparator,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_CAP_TABLE,
  },
  {
    path: ROUTE_STARTUP_PATH.CAP_TABLE_WATERFALL_ADD,
    component: CaptableWaterfallAdd,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: TOOL_CAP_TABLE,
  },
  {
    path: ROUTE_STARTUP_PATH.MY_POSITION,
    component: MyPosition,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    embed: 'startupPosition',
  },
  {
    path: ROUTE_STARTUP_PATH.SERVICES,
    component: Services,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_STARTUP_PATH.FUNDRAISE,
    component: StartupFundraise,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_STARTUP_PATH.FUNDRAISE_ADD,
    component: StartupFundraiseAdd,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
];

export const routesMentor = [
  {
    path: ROUTE_MENTOR_PATH.HOME,
    component: Home,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_MENTOR_PATH.ONBOARDING,
    component: Onboarding,
    props: {
      type: 'mentor',
    },
    exact: true,
    header: false,
    aside: false,
    ignoreSession: false,
  },
  {
    path: ROUTE_MENTOR_PATH.MENTORINGS,
    component: Mentorings,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_MENTOR_PATH.MENTORINGS_SEARCH,
    component: MentoringsSearch,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_MENTOR_PATH.MENTORING,
    component: Mentoring,
    props: {
      type: 'mentor',
    },
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_MENTOR_PATH.EDIT_MENTOR,
    component: EditInfo,
    props: {
      type: 'mentor',
    },
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_MENTOR_PATH.HELP,
    component: Help,
    props: {
      type: 'mentor',
    },
    exact: true,
    header: true,
    aside: false,
    ignoreSession: false,
  },
];

export const routesFund = [
  {
    path: ROUTE_FUND_PATH.ONBOARDING,
    component: Onboarding,
    props: {
      type: 'fund',
    },
    exact: true,
    header: false,
    aside: false,
    ignoreSession: false,
    embed: 'onboarding',
  },
  {
    path: ROUTE_FUND_PATH.ONBOARDING_PHANTOM,
    component: Onboarding,
    props: {
      type: 'fund',
    },
    exact: true,
    header: false,
    aside: false,
    ignoreSession: false,
    embed: 'onboarding',
  },
];

export const routesAdmin = [
  {
    path: ROUTE_ADMIN_PATH.HOME,
    component: Home,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_ADMIN_PATH.PLATFORM,
    component: Platform,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_ADMIN_PATH.ANALYTICS,
    component: AdminAnalytics,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_ADMIN_PATH.USERS,
    component: Users,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_ADMIN_PATH.STARTUPS,
    component: Startups,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_ADMIN_PATH.MENTORINGS,
    component: AdminMentorings,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_ADMIN_PATH.MENTORING,
    component: Mentoring,
    props: {
      type: 'admin',
    },
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_ADMIN_PATH.PERFORMANCE,
    component: AdminPerformance,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_ADMIN_PATH.REPORTING,
    component: AdminReporting,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_ADMIN_PATH.REPORTING_TEMPLATE,
    component: AdminReportingNewTemplate,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_ADMIN_PATH.SERVICES,
    component: AdminServices,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_ADMIN_PATH.WEBHOOKS,
    component: AdminWebhooks,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_ADMIN_PATH.SCRIPTS,
    component: AdminScripts,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_ADMIN_PATH.CRONS,
    component: AdminCrons,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_ADMIN_PATH.HELP,
    component: Help,
    exact: true,
    header: true,
    aside: false,
    ignoreSession: false,
  },
  {
    path: ROUTE_ADMIN_PATH.EDIT_STARTUP,
    component: EditInfo,
    props: {
      type: 'startup',
    },
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
    permission: {
      tool: TOOL_PROFILE,
      level: TOOL_LEVEL_EDIT,
    },
  },
  {
    path: ROUTE_ADMIN_PATH.SUPPORT,
    component: Support,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_ADMIN_PATH.FUNDRAISE,
    component: AdminFundraises,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
  {
    path: ROUTE_ADMIN_PATH.FUND,
    component: AdminFunds,
    exact: true,
    header: true,
    aside: true,
    ignoreSession: false,
  },
];
