import React from 'react';
import PropTypes from 'prop-types';
import { ROUTE_POSITION_PATH } from 'routes';
import { useLocation } from 'react-router-dom';
import NavAdmin from './navAdmin';
import NavMentor from './navMentor';
import NavStartup from './navStartup';
import NavPosition from './navPosition';
import './styles.scss';

function LeftMenu(props) {
  const {
    literals,
    user,
    platform,
    showAside,
    toggleMenu,
    startup = null,
    route,
  } = props;

  const location = useLocation();
  const rolePath = location.pathname.split('/')[1];

  const commonProps = {
    literals, route, toggleMenu,
  };

  return (
    <div className={`left-menu simple-scrollbar${showAside ? ' show' : ''}${user.impersonate ? ' impersonate' : ''}`}>
      <div className='left-menu-sections'>
        <ul className='left-menu-nav-items'>
          {rolePath === 'startup' && (
            <NavStartup user={user} platform={platform} startup={startup} {...commonProps} />
          )}
          {rolePath === 'mentor' && (
            <NavMentor user={user} {...commonProps} />
          )}
          {rolePath === 'admin' && (
            <NavAdmin platform={platform} {...commonProps} />
          )}
          {location.pathname.startsWith(ROUTE_POSITION_PATH.BASE) && (
            <NavPosition user={user} {...commonProps} />
          )}
        </ul>
      </div>
    </div>
  );
}

LeftMenu.propTypes = {
  literals: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
  showAside: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
  startup: PropTypes.object,
};

export default LeftMenu;
