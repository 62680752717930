import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DroppableComponent from 'components/DragAndDrop/Droppable';
import DraggableComponent from 'components/DragAndDrop/Draggable';
import Button from 'components/Buttons/Button';
import PermissionLabel from 'components/PermissionsPopup/PermissionLabel';
import {
  ArrowDown, ArrowUp, GripVertical, Trash,
} from 'lucide-react';
import { getTranslation } from 'utils/functions';
import { DragDropContext } from 'react-beautiful-dnd';
import { fetchKpis } from 'routes/Admin/Performance/modules/actions';
import AddKpiPopup from './addKpiPopup';
import './styles.scss';

const SortKpis = ({
  literals,
  literalsCommon,
  lang,
  kpis = [],
  setKpis,
  addKpis = false,
}) => {
  const [addKpi, setAddKpi] = useState(false);

  const handleDeleteform = (event) => {
    setKpis(prev => prev.filter(item => item.id !== event.currentTarget.id));
  };

  const handleOnDragEnd = (source, destination) => {
    if ((!destination && destination !== 0) || source === destination) return;

    setKpis((prev) => {
      const aux = [...prev];
      aux.splice(destination, 0, ...aux.splice(source, 1));
      return aux;
    });
  };

  const handleFetchKpis = async (page, size, search) => {
    const filters = { 'scope.owner': 'all' };
    return fetchKpis(page, size, search, filters);
  };

  if (addKpi) {
    return (
      <AddKpiPopup
        literals={literals}
        literalsCommon={literalsCommon}
        lang={lang}
        kpis={kpis}
        setKpis={setKpis}
        fetchKpis={handleFetchKpis}
        onClose={() => setAddKpi(false)}
      />
    );
  }

  return (
    <DragDropContext onDragEnd={({ source, destination }) => handleOnDragEnd(source?.index, destination?.index)}>
      <DroppableComponent id='template-kpis'>
        {kpis.map((elem, index) => (
          <DraggableComponent key={elem.id} id={elem.id} index={index} className='form-body-rowKpi'>
            <>
              <GripVertical size={18} className='mr-2' />
              <div className='form-body-rowKpi-content'>
                <div className='form-body-rowKpi-content-header'>
                  <div className='form-body-rowKpi-content-title'>
                    {getTranslation(elem?.name, lang)}
                  </div>
                  <div className='d-flex justify-content-center align-items-center'>
                    <div className='form-body-rowKpi-content-category ml-2'>
                      <div>
                        <span>{literals[elem.category]}</span>
                        {elem.permission && (
                          <PermissionLabel permission={elem.permission} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='form-body-rowKpi-content-description'>
                  {getTranslation(elem?.description, lang)}
                </div>
              </div>
              <div className='form-body-rowKpi-icons'>
                <ArrowUp
                  size={16}
                  className={index === 0 ? 'disabled mb-2' : 'mb-2'}
                  onClick={() => handleOnDragEnd(index, index - 1)}
                />
                { addKpis && <Trash id={elem.id} size={16} onClick={handleDeleteform} />}
                <ArrowDown
                  size={16}
                  className={index === kpis.length - 1 ? 'disabled mt-2' : 'mt-2'}
                  onClick={() => handleOnDragEnd(index, index + 1)}
                />
              </div>
            </>
          </DraggableComponent>
        ))}
      </DroppableComponent>
      { addKpis && (
        <div className='form-button'>
          <Button
            color='outline-primary'
            text={literals.addKpi}
            onClick={() => setAddKpi(true)}
          />
        </div>
      )}
    </DragDropContext>
  );
};

SortKpis.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  kpis: PropTypes.array,
  lang: PropTypes.string.isRequired,
  setKpis: PropTypes.func.isRequired,
  addKpis: PropTypes.bool,
};

export default SortKpis;
