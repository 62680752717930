import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import PopupProfileComponents from '../components';

const mapStateToProps = state => ({
  lang: state.session.user.lang,
  user: state.session.user,
  literalsCommon: state.i18n.literals.common,
});

export default withRouter(connect(mapStateToProps)(PopupProfileComponents));
