import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputText from 'components/Inputs/inputText';
import InputNumber from 'components/Inputs/inputNumber';
import Button from 'components/Buttons/Button';
import InputSelect from 'components/Inputs/inputSelect';
import InputDate from 'components/Inputs/inputDate';
import { PlusCircle } from 'lucide-react';
import PopupFormShareholder from '../../shareHolders/components/PopupFormShareholder';
import PopupFormShareClass from '../../shareClasses/components/PopupFormShareClass';

const FormDebts = (props) => {
  const {
    editable,
    data,
    captable,
    currency,
    literals,
    literalsCommon,
    onConfirm,
    onDelete,
  } = props;


  const [id] = useState(data.id ?? `new.${new Date().getTime()}`);
  const [name, setName] = useState(data.name ?? '');
  const [startDate, setStartDate] = useState(data.startDate ?? '');
  const [expirationDate, setExpirationDate] = useState(data.expirationDate ?? '');
  const [principal, setPrincipal] = useState(data.principal ?? 0);
  const [interestRate, setInterestRate] = useState(data.interestRate ?? 0);
  const [accrualFrequency, setAccrualFrequency] = useState(data.accrualFrequency ?? '');
  const [shareholder, setShareholder] = useState(data.shareholder ?? '');
  const [conversion, setConversion] = useState(data.conversion ?? {
    shareClass: '',
    triggerAmount: '',
    valuationCap: '',
    earlyExitMultiple: '',
    discount: '',
  });
  const [popupShareholder, setPopupShareholder] = useState(false);
  const [popupShareClass, setPopupShareClass] = useState(false);

  const optionsAccrualFrequency = [
    { id: 'annual', name: literalsCommon.annual },
    { id: 'monthly', name: literalsCommon.monthly },
    { id: 'daily', name: literalsCommon.daily },
  ];

  const handleConfirm = () => {
    onConfirm({
      id,
      name,
      startDate: startDate !== '' ? startDate : null,
      expirationDate: expirationDate !== '' ? expirationDate : null,
      principal: principal !== '' ? parseFloat(principal) : null,
      interestRate: interestRate !== '' ? parseFloat(interestRate) : null,
      accrualFrequency,
      shareholder,
      conversion: {
        ...conversion,
        triggerAmount: conversion.triggerAmount !== '' ? parseFloat(conversion.triggerAmount) : null,
        valuationCap: conversion.valuationCap !== '' ? parseFloat(conversion.valuationCap) : null,
        earlyExitMultiple: conversion.earlyExitMultiple !== '' ? parseFloat(conversion.earlyExitMultiple) : null,
        discount: conversion.discount !== '' ? parseFloat(conversion.discount) : null,
      },
    });
  };

  return (
    <>
      <div className='operation-form-shares'>
        <div className='mb-lb'>
          <div className='row'>
            <div className='col-xs-12 col-md-4'>
              <InputSelect
                options={captable.shareholders}
                preText={literals.shareholder}
                value={shareholder}
                onChange={v => setShareholder(v)}
                button={(
                  <Button
                    text={literalsCommon.create}
                    icon={PlusCircle}
                    onClick={() => setPopupShareholder(true)}
                  />
                )}
                isDisabled={!editable}
              />
            </div>
            <div className='col-xs-12 col-md-8'>
              <InputText preText={literalsCommon.name} value={name} onChange={v => setName(v)} isDisabled={!editable} />
            </div>
            <div className='col-xs-12 col-md-4'>
              <InputNumber
                preText={literals.principal}
                value={principal}
                onChange={v => setPrincipal(v)}
                isDisabled={!editable}
                symbol={currency.symbol}
                step='any'
                decimals={2}
              />
            </div>
            <div className='col-xs-12 col-md-4'>
              <InputNumber preText={literals.interest} value={interestRate} onChange={v => setInterestRate(v)} isDisabled={!editable} symbol='%' />
            </div>
            <div className='col-xs-12 col-md-4'>
              <InputSelect
                options={optionsAccrualFrequency}
                preText={literals.accrualFrequency}
                value={accrualFrequency}
                onChange={v => setAccrualFrequency(v)}
                isDisabled={!editable}
              />
            </div>
            <div className='col-xs-12 col-md-4'>
              <InputDate preText={literalsCommon.date} value={startDate} onChange={v => setStartDate(v)} isDisabled={!editable} />
            </div>
            <div className='col-xs-12 col-md-4'>
              <InputDate preText={literals.expirationDate} value={expirationDate} onChange={v => setExpirationDate(v)} isDisabled={!editable} />
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-xs-12 col-md-4'>
              <InputSelect
                options={[{ id: null, name: `- ${literalsCommon.no} -` }, ...captable.shareClasses]}
                preText={literals.convertsTo}
                value={conversion.shareClass}
                onChange={v => setConversion({ ...conversion, shareClass: v })}
                button={(
                  <Button
                    text={literalsCommon.create}
                    icon={PlusCircle}
                    onClick={() => setPopupShareClass(true)}
                  />
                )}
                isDisabled={!editable}
              />
            </div>
            {
              conversion.shareClass && (
                <>
                  <div className='col-xs-12 col-md-4'>
                    <InputNumber
                      preText={literals.conversionTriggerAmount}
                      value={conversion.triggerAmount}
                      onChange={v => setConversion({ ...conversion, triggerAmount: v })}
                      isDisabled={!editable}
                      symbol={currency.symbol}
                      decimals={2}
                    />
                  </div>
                  <div className='col-xs-12 col-md-4'>
                    <InputNumber
                      preText={literals.valuationCap}
                      value={conversion.valuationCap}
                      onChange={v => setConversion({ ...conversion, valuationCap: v })}
                      isDisabled={!editable}
                      symbol={currency.symbol}
                      decimals={2}
                    />
                  </div>
                  <div className='col-xs-12 col-md-4'>
                    <InputNumber
                      preText={literals.earlyExitMultiple}
                      value={conversion.earlyExitMultiple}
                      onChange={v => setConversion({ ...conversion, earlyExitMultiple: v })}
                      isDisabled={!editable}
                      symbol='x'
                    />
                  </div>
                  <div className='col-xs-12 col-md-4'>
                    <InputNumber
                      preText={literals.discount}
                      value={conversion.discount}
                      onChange={v => setConversion({ ...conversion, discount: v })}
                      isDisabled={!editable}
                      symbol='%'
                    />
                  </div>
                </>
              )
            }
          </div>

        </div>
        {
          editable && (
            <div className='buttons'>
              <Button text={literalsCommon.confirm} onClick={handleConfirm} />
              {
                data?.id && (
                  <Button text={literalsCommon.delete} color='danger' onClick={() => onDelete(data.id)} />
                )
              }
            </div>
          )
        }
      </div>
      {
        (popupShareholder !== false) && (
          <PopupFormShareholder
            onClose={() => setPopupShareholder(false)}
            onSubmit={sh => setShareholder(sh.id)}
          />
        )
      }
      {
        (popupShareClass) && (
          <PopupFormShareClass
            captable={captable?.selected?.id}
            onClose={() => setPopupShareClass(false)}
            onSubmit={sc => setConversion({ ...conversion, shareClass: sc.id })}
          />
        )
      }
    </>
  );
};

FormDebts.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  data: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

FormDebts.defaultProps = {
  editable: true,
};

export default FormDebts;
