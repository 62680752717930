import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactECharts from 'echarts-for-react';
import SkeletonBarChart from 'components/Loading/skeletonBarChart';
import EmptyGraph from 'assets/svg/empty_graph.svg';
import { formatNumber } from 'utils/functions';
import { KPI_PERIODICITY, KPI_UNIT } from 'constants/kpis';
import { fetchModelKpiValues } from '../modules/actions';
import KpiValueEditPopup from './KpiValueEditPopup';

const KpiRelationValuesGraph = (props) => {
  const {
    literals,
    literalsCommon,
    relation,
    forceFetch,
    from,
    to,
    currency,
    height,
    legend,
    isSimple,
    symbolSize,
    onDelete,
    canEdit,
  } = props;

  const [graphData, setGraphData] = useState({ loading: false, loaded: false, data: [] });
  const [minMax, setMinMax] = useState();
  const [targetValues, setTargetValues] = useState([]);
  const [kpiValueEditPopup, setKpiValueEditPopup] = useState(false);
  const kpi = relation?.kpi;
  let symbol = '';
  if (kpi?.unit === KPI_UNIT.CURRENCY) {
    symbol = ` (${currency.symbol})`;
  } else if (kpi?.unit === KPI_UNIT.PERCENT) {
    symbol = ' %';
  }

  const {
    values,
    forecast,
    notes,
    loaded,
    loading,
  } = graphData;

  const fetchValues = async () => {
    if (kpi) {
      setGraphData({ loading: true, data: [], values: [] });
      const data = await fetchModelKpiValues(
        kpi.id,
        relation.scope.type,
        relation.scope.owner,
        relation.id,
        0,
        0,
        {
          from,
          to,
          format: 'graph',
        },
      );
      const newGrapValues = [];
      const newGrapForecast = [];
      const newGrapNotes = [];
      const newTargetValues = [];
      data.reverse().forEach((value) => {
        newGrapValues.push({ x: value.date, y: value.value });
        newGrapForecast.push({ x: value.date, y: value.forecast });
        newGrapNotes.push({ x: value.date, y: value.notes });
        if (relation.target) {
          newTargetValues.push({ x: value.date, y: relation.target });
        }
      });
      if (isSimple && newGrapValues.length < 2) {
        if (newGrapValues.length === 1) {
          newGrapValues.unshift({ x: '', y: newGrapValues[0].y });
          setMinMax({ min: newGrapValues[0].y - 1, max: newGrapValues[0].y + 1 });
        } else {
          newGrapValues.push({ x: '', y: 0 });
          newGrapValues.push({ x: '', y: 0 });
          setMinMax({ min: -1, max: 1 });
        }
      }
      if (isSimple && newTargetValues.length === 1) {
        newTargetValues.unshift({ x: '', y: newTargetValues[0].y });
        setMinMax({
          min: Math.min(newTargetValues[0].y / 2, Math.max(newGrapValues[0].y, 0)),
          max: Math.max(newTargetValues[0].y + (newTargetValues[0].y / 2), newGrapValues[0].y),
        });
      }
      setGraphData({
        loading: false, data, values: newGrapValues, forecast: newGrapForecast, notes: newGrapNotes,
      });
      setTargetValues(newTargetValues);
    }
  };

  useEffect(() => {
    setGraphData({ loading: true, data: [], values: [] });
    const delay = setTimeout(() => {
      fetchValues();
    }, 500);
    return () => clearTimeout(delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relation, relation?.value, from, to]);

  useEffect(() => {
    if (forceFetch) {
      fetchValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceFetch]);

  if (loading) {
    return (
      <div style={{ height: `${height}px`, position: isSimple ? 'relative' : '', top: isSimple ? '36px' : '' }}>
        <SkeletonBarChart height={isSimple ? '100px' : `${height - 50}px`} />
      </div>
    );
  }

  if (!kpi || (loaded && values.length === 0)) {
    return (
      <div className='krg-empty panel-empty-message m-0' style={{ height: `${height + 50}px` }}>
        <img src={EmptyGraph} alt='' />
        <p>{literalsCommon.noData}</p>
      </div>
    );
  }

  const getColor = (color) => {
    return getComputedStyle(document.documentElement)
      .getPropertyValue(color);
  };

  const series = [{
    type: 'line',
    name: literalsCommon.value,
    data: values ? values.map(value => value.y) : [],
    color: getColor('--primary-color'),
    symbol: 'circle',
    symbolSize: symbolSize || 4,
    lineStyle: { width: 4 },
    areaStyle: !isSimple ? { color: getColor('--primary-color-15') } : null,
    showSymbol: !isSimple,
    smooth: isSimple,
  },
  {
    type: 'line',
    name: literals.forecast,
    data: forecast && !isSimple ? forecast.map(value => value.y) : [],
    color: getColor('--secondary-color'),
    symbol: 'triangle',
    symbolSize: symbolSize || 2,
    lineStyle: { width: 2 },
  },
  {
    type: 'line',
    name: literalsCommon.notes,
    data: notes && !isSimple ? notes.map(value => value.y) : [],
    color: 'white',
  }];

  if ((targetValues && targetValues.length > 0)) {
    series.unshift({
      type: 'line',
      name: literalsCommon.target,
      data: targetValues ? targetValues.map(value => value.y) : [],
      color: getColor('--success-color'),
      symbol: 'none',
      lineStyle: { width: isSimple ? 2 : 4 },
      z: 5,
    });
  }

  const grid = {
    containLabel: true,
    bottom: 25,
    left: 'left',
  };
  if (!legend) {
    grid.top = 10;
  }

  return (
    <>
      <ReactECharts
        className={!isSimple ? 'ml-5' : 'ml-3'}
        option={{
          tooltip: {
            trigger: 'axis',
            valueFormatter: (v) => {
              if (typeof v === 'undefined') {
                return '-';
              }
              if (typeof v === 'string') {
                const auxNotes = v.replace(/<[^>]+>/g, '');
                return auxNotes.length ? literalsCommon.yes : literalsCommon.no;
              }
              return formatNumber(v, 0, { symbol });
            },
          },
          responsive: true,
          legend: legend && !isSimple ? {
            data: targetValues && targetValues.length > 0 ? [literalsCommon.target, literalsCommon.value, literals.forecast] : [literalsCommon.value, literals.forecast],
          } : { show: false, display: false },
          grid,
          xAxis: {
            show: !isSimple,
            type: 'category',
            boundaryGap: false,
            data: values ? values.map(value => value.x) : [],
            inverse: false,
            axisLabel: {
              show: !isSimple,
              fontSize: '15px',
              formatter(value) {
                const [year, month] = value.split('-');
                return kpi.periodicity === KPI_PERIODICITY.ANNUAL
                  ? `${year}`
                  : `${literalsCommon.months[+month].slice(0, 3)} '${year.slice(-2)}`;
              },
            },
          },
          yAxis: {
            show: !isSimple,
            type: 'value',
            min: isSimple && values ? (minMax?.min || Math.min(...values.map(value => value.y), targetValues[0])) : null,
            max: isSimple && values ? (minMax?.max || Math.max(...values.map(value => value.y), targetValues[0])) : null,
            axisLabel: {
              show: !isSimple,
            },
          },
          series,
        }}
        onEvents={{ click: param => setKpiValueEditPopup(graphData.data[param.dataIndex]) }}
        style={{ height }}
      />
      { kpiValueEditPopup && (
        <KpiValueEditPopup
          kpiValue={kpiValueEditPopup}
          kpi={kpi}
          relation={relation}
          onClose={() => setKpiValueEditPopup(false)}
          onDelete={onDelete}
          canEdit={canEdit}
        />
      )}
    </>
  );
};

KpiRelationValuesGraph.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  relation: PropTypes.object,
  from: PropTypes.string,
  to: PropTypes.string,
  forceFetch: PropTypes.number,
  height: PropTypes.number,
  legend: PropTypes.bool,
  symbolSize: PropTypes.number,
  isSimple: PropTypes.bool,
  onDelete: PropTypes.func,
  canEdit: PropTypes.bool.isRequired,
};

KpiRelationValuesGraph.defaultProps = {
  relation: {},
  from: '',
  to: '',
  forceFetch: 0,
  height: 350,
  symbolSize: 12,
  legend: true,
  isSimple: false,
  onDelete: null,
};

export default KpiRelationValuesGraph;
