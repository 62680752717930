/* eslint-disable no-underscore-dangle */
import React, {
  useState, useEffect, useRef, Fragment,
} from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import Button from 'components/Buttons/Button';
import ButtonsDotsMenu from 'components/Buttons/ButtonsDotsMenu';
import useDialog from 'components/Dialog/components/useDialog';
import Loading from 'components/Loading';
import EmptyFolder from 'assets/svg/empty_folder.svg';
import { withRouter } from 'utils/withRouter';
import {
  formatDate, formatErrors, getFullName, startupCanView,
} from 'utils/functions';
import { Skeleton } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import {
  Eye, Pencil, Trash, Move, History, CircleSlash, Search, Bookmark,
  CloudUpload, ChevronRight, ArrowRight, Folder, FolderPlus, FolderSymlink,
  Clipboard, Landmark, Briefcase, AlertOctagon, Users,
  FileJson, FileArchive, FileText, FileImage, Calendar, Link, File,
} from 'lucide-react';
import { initPermission } from 'utils/functions/initPermissions';
import { TOOL_DATA_ROOM } from 'constants/tools';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { PERMISSION_LEVELS, PERMISSION_SCOPES } from 'constants/permissions';
import PermissionLabel from 'components/PermissionsPopup/PermissionLabel';
import { useLocation } from 'react-router-dom';
import PopupUploadFile from './PopupUploadFile';
import PopupCreateRename from './PopupCreateRename';
import PopupInfoFile from './PopupInfoFile';
import PopupEditFile from './PopupEditFile';
import PopupViewsFile from './PopupViewsFile';
import PopupMove from './PopupMove';
import { getFolderName } from '.';

import 'swiper/css';
import 'swiper/css/pagination';
import {
  addBookmark,
  deleteBookmark,
  deleteDocument, deleteFolder, editDocument, editFolder, getFolderContents,
  deleteLink,
  editLink,
} from '../modules/actions';
import PopupFileVersions from './PopupFileVersions';

export const getFolderIcon = (type, size = 15) => {
  switch (type) {
    case 'reporting': return <AlertOctagon height={size} width={size} />;
    case 'captable': return <Clipboard height={size} width={size} />;
    case 'captable_operations': return <Briefcase height={size} width={size} />;
    case 'captable_shareholders': return <Users height={size} width={size} />;
    case 'fundraise': return <Landmark height={size} width={size} />;
    default: return null;
  }
};

export const selectType = (elem, size = 35) => {
  const type = elem?.split('/')[0];
  const subType = elem?.split('/')[1];
  switch (type) {
    case 'application':
      switch (subType) {
        case 'pdf':
        case 'msword':
          return <div className='file-icon'><FileText size={size} /></div>;
        case 'java-archive':
        case 'json':
          return <div className='file-icon'><FileJson size={size} /></div>;
        default:
          return <div className='file-icon'><FileArchive size={size} /></div>;
      }
    case 'image':
      return <div className='file-icon'><FileImage size={size} /></div>;
    case 'text':
      switch (subType) {
        case 'css':
        case 'csv':
        case 'html':
          return <div className='file-icon'><FileJson size={size} /></div>;
        case 'calendar':
          return <div className='file-icon'><Calendar size={size} /></div>;
        default:
          return <div className='file-icon'><FileText size={size} /></div>;
      }
    case 'link':
      return <div className='file-icon'><Link size={size} /></div>;
    default:
      return <div className='file-icon'><File size={size} /></div>;
  }
};

const FolderContent = (props) => {
  const {
    literals,
    literalsCommon,
    match,
    selectedFolder,
    setSelectedFolder,
    setShowTree,
    folders,
    setFolders,
    change,
    setFetching,
    auxBreadcrumb,
    startup,
    editable,
    bookmarks: showBookmarks,
    canBookmark,
  } = props;

  const [search, setSearch] = useState('');
  const [showInfoPopup, setShowInfoPopup] = useState(null);
  const [showEditPopup, setShowEditPopup] = useState(null);
  const [showCreateOrRename, setShowCreateOrRename] = useState(null);
  const [showViewersPopup, setShowViewersPopup] = useState(null);
  const [showVersionsPopup, setShowVersionsPopup] = useState(null);
  const [renamedFolder, setRenamedFolder] = useState();
  const [showUploadPopup, setShowUploadPopup] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState();
  const [actualPage, setActualPage] = useState(0);
  const [isFinalPage, setIsFinalPage] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const location = useLocation();

  const canView = startupCanView(startup, TOOL_DATA_ROOM, true);
  const canEdit = editable && (selectedFolder?.permission?.level >= PERMISSION_LEVELS.EDIT);
  const PAGE_SIZE = 15;

  const [actualFolder, setActualFolder] = useState(selectedFolder);
  const [loading, setLoading] = useState(true);
  const [showMovePopup, setShowMovePopup] = useState(null);
  const scrollRef = useRef();

  const [subfolders, setSubfolders] = useState([]);
  const [content, setContent] = useState([]);
  const [bookmarks, setBookmarks] = useState([]);
  const { dialog } = useDialog();

  const rolePath = location.pathname.split('/').filter(p => p !== 'embed')[1];
  const rootFolder = {
    id: `root.${rolePath}.${match.params.id}`,
    name: startup.name,
    parent: null,
    parents: [],
  };
  const recentFolder = {
    id: 'recent',
    name: literals.recents,
    parent: null,
    parents: [],
  };
  const bookmarkFolder = {
    id: 'bookmark',
    name: literals.bookmarks,
    parent: null,
    parents: [],
  };

  const fetchFolderContent = async (auxSearch = '') => {
    if (!loading) {
      setLoading(true);
      setFetching(true);
      setUnauthorized(false);
    }
    try {
      let auxSubfolders;
      if (selectedFolder.id !== recentFolder.id) {
        auxSubfolders = await getFolderContents(rolePath, match.params.id, actualFolder.id, {
          page: 0, size: 0, sort: '-created_at', search: auxSearch, filters: { 'element.type': 'folder' },
        });
      }
      const auxContent = await getFolderContents(rolePath, match.params.id, actualFolder.id, {
        page: 0, size: PAGE_SIZE, sort: '-created_at', search: auxSearch, filters: { 'element.type': ['document', 'link'] },
      });

      let auxBookmarks = null;
      if (showBookmarks) {
        auxBookmarks = await getFolderContents(rolePath, match.params.id, 'bookmark', {
          page: 0, size: 4, sort: '-created_at', search: auxSearch,
        });
      }

      if ((selectedFolder.id === recentFolder.id || auxSubfolders) && auxContent) {
        setSubfolders(auxSubfolders?.items || []);
        setContent(auxContent);
        setLoading(false);
        setFetching(false);
        if (auxContent.total / actualPage <= PAGE_SIZE) {
          setIsFinalPage(true);
        }
      }

      setBookmarks(auxBookmarks?.items || []);
    } catch (e) {
      const error = formatErrors(e);
      if (error?.unauthorized) {
        setUnauthorized(true);
      }
      setLoading(false);
      setFetching(false);
    }
  };

  useEffect(() => {
    setActualFolder(selectedFolder);
    setActualPage(0);
    setIsFinalPage(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFolder]);

  useEffect(() => {
    setLoading(true);
    setFetching(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);

  useEffect(() => {
    if (actualFolder) {
      setSearch('');
      setSubfolders([]);
      setContent([]);
      fetchFolderContent();
      if (actualFolder.parents) {
        setBreadcrumb([...actualFolder.parents, { id: actualFolder.id, name: actualFolder.name }]
          .map(folder => ({ id: folder.id, name: literals.defaultFolders[folder.name] || folder.name })));
      } else if (auxBreadcrumb.length) {
        setBreadcrumb([rootFolder, ...auxBreadcrumb.filter(folder => folder.id !== rootFolder.id)]
          .map(folder => ({ id: folder.id, name: literals.defaultFolders[folder.name] || folder.name })));
      } else if (!breadcrumb) {
        setBreadcrumb([{ id: selectedFolder.id, name: selectedFolder.name }]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualFolder]);

  const changeStatsFile = (auxFolders, folderId, type) => {
    const aux = type === 'document' ? 'documents' : 'links';
    return auxFolders.map((folder) => {
      if (folder.id === actualFolder.id) {
        return {
          ...folder,
          stats: { ...folder.stats, [aux]: folder.stats[aux] - 1 },
          childs: folder.childs ? changeStatsFile(folder.childs, folderId, type) : null,
        };
      }
      if (folder.id === folderId) {
        return {
          ...folder,
          stats: { ...folder.stats, [aux]: folder.stats[aux] + 1 },
          childs: folder.childs ? changeStatsFile(folder.childs, folderId, type) : null,
        };
      }
      if (folder.childs) {
        return { ...folder, childs: changeStatsFile(folder.childs, folderId, type) };
      }
      return folder;
    });
  };

  const removeFile = (auxFolders, type) => {
    const aux = type === 'document' ? 'documents' : 'links';
    return auxFolders.map((folder) => {
      if (folder.id === actualFolder.id) {
        return {
          ...folder,
          stats: { ...folder.stats, [aux]: folder.stats[aux] - 1 },
        };
      }
      if (folder.childs) {
        return { ...folder, childs: removeFile(folder.childs, type) };
      }
      return folder;
    });
  };

  const moveContent = async (id, folder) => {
    await dialog({
      type: 'loading',
      execute: async () => {
        try {
          const auxContent = content.items.find(cont => cont.id === id);
          const type = auxContent._type === 'document' ? 'documents' : 'links';
          if (auxContent._type === 'document') {
            await editDocument(auxContent.id, {
              folder, name: auxContent.name, description: auxContent.description, return: 'object', permission: JSON.stringify(auxContent.permission),
            });
          } else {
            await editLink(auxContent.id, {
              name: auxContent.name,
              description: auxContent.description,
              path: auxContent.path,
              folder,
              permission: auxContent.permission,
            });
          }
          setContent(prev => ({ items: prev.items.filter(cont => (cont.id !== id)), total: prev.total - 1 }));
          setFolders(prev => changeStatsFile(prev, folder, auxContent._type));
          setSubfolders(prev => prev.map(
            auxFolder => (auxFolder.id === folder
              ? { ...auxFolder, stats: { ...auxFolder.stats, [type]: auxFolder.stats[type] + 1 } }
              : auxFolder),
          ));
        } catch (error) {
          let errorMessage = '';
          if (error?.status === 401) {
            errorMessage = literals.noPermissions;
          }
          await dialog({
            type: 'error',
            text: (
              <>
                <strong>{literals.error}</strong>
                <br />
                {errorMessage}
              </>
            ),
          });
        }
      },
    });
  };

  useEffect(() => {
    if (change) {
      if (change.type === 'rename') {
        setSubfolders(subfolders.map(subfolder => (subfolder.id === change.folder.id ? change.folder : subfolder)));
      } else if (change.type === 'delete') {
        setSubfolders(subfolders.filter(subfolder => subfolder.id !== change.folder.id));
      } else if (change.type === 'move') {
        if (change.folder.parent === actualFolder.id) {
          setSubfolders(prev => [...prev, change.folder]);
        } else {
          setSubfolders(subfolders.filter(subfolder => subfolder.id !== change.folder.id).map(
            folder => (folder.id === change.folder.parent
              ? { ...folder, stats: { ...folder.stats, folders: folder.stats.folders + 1 } }
              : folder),
          ));
        }
      } else if (change.type === 'moveContent') {
        moveContent(change.file, change.folder);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change]);

  const renameObject = (auxFolders, newItem) => {
    return auxFolders.map((folder) => {
      if (folder.id === newItem.id) {
        return { ...folder, ...newItem };
      }
      return folder.childs
        ? { ...folder, childs: renameObject(folder.childs, newItem) }
        : folder;
    });
  };

  const handleRenameFolder = async () => {
    await dialog({
      type: 'loading',
      execute: async () => {
        await editFolder(rolePath, match.params.id, renamedFolder.id, {
          name: renamedFolder.name, parent: renamedFolder.parent, permission: renamedFolder.permission,
        })
          .then(() => {
            setFolders(prev => renameObject(prev, renamedFolder));
            setSubfolders(prev => prev.map(cont => (cont.id === renamedFolder.id ? renamedFolder : cont)));
          })
          .catch(async (error) => {
            let errorMessage = '';
            if (error?.status === 401) {
              errorMessage = literals.noPermissions;
            }
            await dialog({
              type: 'error',
              text: (
                <>
                  <strong>{literals.error}</strong>
                  <br />
                  {errorMessage}
                </>
              ),
            });
          });
      },
    });
  };

  useEffect(() => {
    if (renamedFolder) {
      handleRenameFolder();
      setRenamedFolder(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renamedFolder]);

  const removeFolder = (auxFolders, folderDeleted) => {
    if (actualFolder.id === rootFolder.id) {
      return auxFolders.filter(folder => folder.id !== folderDeleted.id);
    }
    return auxFolders.map((folder) => {
      if (folder.id === actualFolder.id) {
        return {
          ...folder,
          stats: { ...folder.stats, folders: folder.stats.folders - 1 },
          childs: folder.childs && folder.childs.filter(child => child.id !== folderDeleted.id),
        };
      }
      if (folder.childs) {
        return { ...folder, childs: removeFolder(folder.childs, folderDeleted) };
      }
      return folder;
    });
  };

  const handleDelete = async (item, conf = false) => {
    let confirm = conf;
    if (!confirm) {
      confirm = await dialog({
        type: 'confirmDanger',
        text: literalsCommon.confirmDelete,
      });
    }

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            if (item._type === 'folder') {
              const response = await deleteFolder(rolePath, match.params.id, item.id, conf);
              if (response.deleted) {
                setSubfolders(prev => prev.filter(subfolder => subfolder.id !== item.id));
                setFolders(prev => removeFolder(prev, item));
                if (bookmarks?.length) {
                  setBookmarks(prev => prev.filter(bm => bm.id !== item.id));
                }
              } else {
                const confirmDelete = await dialog({
                  type: 'confirmDanger',
                  text: literals.confirmDeleteFolder,
                });

                if (confirmDelete) {
                  handleDelete(item, true);
                }
              }
            } else {
              const updateContentList = () => {
                setContent(prev => ({ items: prev.items.filter(cont => cont.id !== item.id), total: prev.total - 1 }));
                setFolders(prev => removeFile(prev, item._type));
                if (bookmarks?.length) {
                  setBookmarks(prev => prev.filter(bm => bm.id !== item.id));
                }
              };

              if (item._type === 'document') {
                await deleteDocument(item.id).then(updateContentList);
              } else {
                await deleteLink(item.id).then(updateContentList);
              }
            }
          } catch (error) {
            let errorMessage = '';
            if (error?.status === 401) {
              errorMessage = literals.noPermissions;
            }
            await dialog({
              type: 'error',
              text: (
                <>
                  <strong>{literals.error}</strong>
                  <br />
                  {errorMessage}
                </>
              ),
            });
          }
        },
      });
    }
  };

  const applyFilters = () => {
    setActualPage(0);
    setIsFinalPage(false);
    fetchFolderContent(search);
  };

  const getRowActions = (type, item) => {
    const permission = initPermission(item, PERMISSION_SCOPES.STARTUP, startup.id, TOOL_DATA_ROOM);
    if (type === 'folder') {
      if (item?.related?.type || !permission.canEdit) {
        return [
          {
            icon: CircleSlash,
            text: literals.folderNotEditable,
            onClick: () => {},
            disabled: true,
          },
        ];
      }
      return [
        { icon: Pencil, text: literalsCommon.edit, onClick: () => setShowCreateOrRename(item) },
        { icon: Move, text: literals.move, onClick: () => setShowMovePopup(item) },
        { icon: Trash, text: literalsCommon.delete, onClick: () => handleDelete(item) },
      ];
    }
    return [
      {
        icon: Eye, text: literals.details, onClick: () => setShowInfoPopup(item),
      },
      {
        icon: Users, text: literals.views, onClick: () => setShowViewersPopup(item), hidden: type !== 'document',
      },
      {
        icon: Pencil, text: literalsCommon.edit, onClick: () => setShowEditPopup(item), hidden: !permission.canEdit,
      },
      {
        icon: History, text: literals.versions, onClick: () => setShowVersionsPopup(item), hidden: !permission.canEdit || type !== 'document',
      },
      {
        icon: Move, text: literals.move, onClick: () => setShowMovePopup(item), hidden: !permission.canEdit,
      },
      {
        icon: Trash, text: literalsCommon.delete, onClick: () => handleDelete(item), hidden: !permission.canEdit,
      },
    ];
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
    if (bottom && !isFinalPage && !loadingPage) {
      setLoadingPage(true);
      getFolderContents(rolePath, match.params.id, actualFolder.id, {
        page: actualPage + 1,
        size: PAGE_SIZE,
        sort: '-created_at',
        search,
        filters: { 'element.type': ['document', 'link'] },
      })
        .then((auxContent) => {
          const newContent = { items: [...content.items, ...auxContent.items], total: auxContent.total };
          setContent(newContent);
          if (newContent.items.length >= auxContent.total) {
            setIsFinalPage(true);
          } else {
            setActualPage(prev => prev + 1);
          }
          setLoadingPage(false);
        })
        .catch(() => {
          setLoadingPage(false);
        });
    }
  };

  const handleBookmark = async (item) => {
    const setBookmark = (type, bookmark, del = false) => {
      if (type === 'folder') {
        setSubfolders(prev => (del && !bookmark
          ? prev.filter(fold => (fold.id !== item.id))
          : prev.map(fold => (fold.id === item.id ? { ...fold, _bookmark: bookmark } : fold))));
      } else {
        setContent(prev => ({
          items: (del && !bookmark
            ? prev.items.filter(cont => (cont.id !== item.id))
            : prev.items.map(cont => (cont.id === item.id ? { ...cont, _bookmark: bookmark } : cont))),
          total: prev.total,
        }));
      }
    };
    const { id, _type: type, _bookmark: bookmark } = item;
    const element = { id, type };
    setBookmark(type, 'loading');

    try {
      if (bookmark) {
        await deleteBookmark(rolePath, match.params.id, element);
        setBookmarks(prev => prev.filter(bm => bm.id !== element.id));
      } else {
        await addBookmark(rolePath, match.params.id, element);
        setBookmarks(prev => [item, ...prev]);
      }
      setBookmark(type, !bookmark, selectedFolder.id === bookmarkFolder.id);
    } catch {
      setBookmark(type, bookmark);
    }
  };

  const handleDragEnd = async (result) => {
    const type = result.draggableId.substring(0, result.draggableId.indexOf('-'));
    const originId = result.draggableId.substring(result.draggableId.indexOf('-') + 1, result.draggableId.length);
    const destinantionId = result?.destination?.droppableId?.substring(result?.destination?.droppableId.indexOf('-') + 1, result?.destination?.droppableId?.length)
      || result?.combine?.draggableId.substring(result?.combine?.draggableId.indexOf('-') + 1, result?.combine?.draggableId.length);

    if (originId && destinantionId && originId !== destinantionId) {
      if (type === 'content') {
        moveContent(originId, destinantionId);
      } else {
        const folder = subfolders.find(subfolder => subfolder.id === originId);
        await dialog({
          type: 'loading',
          execute: async () => {
            return editFolder(rolePath, match.params.id, folder.id, { name: folder.name, parent: destinantionId })
              .then((newFolder) => {
                setSubfolders(prev => prev.filter(subfolder => subfolder.id !== newFolder.id));
              })
              .catch(async (error) => {
                let errorMessage = '';
                if (error?.status === 401) {
                  errorMessage = literals.noPermissions;
                }
                await dialog({
                  type: 'error',
                  text: (
                    <>
                      <strong>{literals.error}</strong>
                      <br />
                      {errorMessage}
                    </>
                  ),
                });
              });
          },
        });
      }
    }
  };

  const setFolder = (auxFolder) => {
    if (setSelectedFolder) {
      setSelectedFolder(auxFolder);
    } else {
      setActualFolder(auxFolder);
      setActualPage(0);
      setIsFinalPage(false);
    }
  };

  const handleClickItem = (item) => {
    if (item._type === 'folder') {
      setBreadcrumb(prev => [...prev, { id: item.id, name: literals.defaultFolders[item.name] || item.name }]);
      setFolder(item);
    } else {
      setShowInfoPopup(item);
    }
  };

  const renderDraggableRow = (type, item, index) => {
    const rowContent = (
      <>
        <div
          className='position-relative d-flex align-items-center cursor-pointer'
          onClick={() => handleClickItem(item)}
        >
          {type === 'folder' ? (
            <>
              <div className='folder-icon'>
                <Folder size={35} className='mr-3' />
                {item.related && (
                  <span className='folder-icon--type'>{getFolderIcon(item.related.type)}</span>
                )}
              </div>
              {getFolderName(item, literals)}
              <PermissionLabel permission={item.permission} />
            </>
          ) : (
            <div className='content-left' onClick={() => setShowInfoPopup(item)}>
              <div className='mr-3'>
                {selectType(item?.type || item?._type)}
              </div>
              <div className='content-info'>
                <span>
                  {item.name}
                  <PermissionLabel permission={item.permission} />
                </span>
                <span className='fs-small fc-secondary'>
                  {item?.updatedAt
                    ? `${literals.updatedAt} ${formatDate(item.updatedAt, { time: true })}`
                    : `${literals.createdAt} ${formatDate(item.createdAt, { time: true })}`}
                  {item?.previousVersions?.length ? (
                    <span className='pl-3 fs-small fc-secondary'>
                      <History size={12} className='mr-1' />
                      {item.previousVersions.length}
                    </span>
                  ) : null}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className='folder-right'>
          {item?._folder?.id && item._folder.id !== actualFolder.id && (
            <div className='cursor-pointer' onClick={() => setFolder(item._folder)}>
              <FolderSymlink size={22} className='mx-2' title={item._folder.name} />
            </div>
          )}
          {canBookmark && (
            <div
              className={`bookmark-icon ${item?._bookmark === 'loading' ? 'bookmark-icon-loading' : ''}`}
              onClick={() => handleBookmark(item)}
            >
              <Bookmark size={18} fill={item?._bookmark ? 'currentColor' : 'none'} />
            </div>
          )}
          <Avatar
            title={getFullName(item?.createdBy) || startup.name}
            src={item?.createdBy?.avatar?.path}
            size={35}
            className='mx-2'
          />
          <div className='dots-menu'>
            <ButtonsDotsMenu
              size={20}
              offset={{ top: 1, left: 20 }}
              buttons={getRowActions(type, item)}
              scrollRef={scrollRef}
            />
          </div>
        </div>
      </>
    );
    return (
      <Draggable draggableId={`content-${item.id}`} index={index} isDragDisabled={!canEdit || ['recent', 'bookmark'].includes(selectedFolder?.id)}>
        {
          (draggableProvider, draggableSnapshot) => (
            <>
              <li
                ref={draggableProvider.innerRef}
                {...draggableProvider.draggableProps}
                {...draggableProvider.dragHandleProps}
                className={`li-container ${item.id === actualFolder?.id && 'selected'} ${loading ? 'disabled' : ''}`}
                style={{
                  ...draggableProvider.draggableProps.style,
                  transform: draggableSnapshot.isDragging ? draggableProvider.draggableProps.style.transform : '',
                }}
              >
                { rowContent }
              </li>
              {draggableSnapshot.isDragging && (
                <li className='li-container folder-placeholder item'>
                  { rowContent }
                </li>
              )}
            </>
          )
        }
      </Draggable>
    );
  };

  const renderRecentFolder = () => {
    const now = new Date();
    const startOfDay = new Date(now);
    startOfDay.setHours(0, 0, 0, 0);

    const today = Math.floor(startOfDay.getTime() / 1000);
    const oneWeekAgo = today - 7 * 24 * 60 * 60; // Una semana atrás
    const oneMonthAgo = today - 30 * 24 * 60 * 60; // Un mes atrás

    const recentElements = {
      today: { items: [], total: 0 },
      lastWeek: { items: [], total: 0 },
      lastMonth: { items: [], total: 0 },
      previousContent: { items: [], total: 0 },
    };

    content.items.forEach((element) => {
      const uploadedAt = element.uploadedAt;

      if (uploadedAt >= today) {
        recentElements.today.items.push(element);
        recentElements.today.total += 1;
      } else if (uploadedAt >= oneWeekAgo) {
        recentElements.lastWeek.items.push(element);
        recentElements.lastWeek.total += 1;
      } else if (uploadedAt >= oneMonthAgo) {
        recentElements.lastMonth.items.push(element);
        recentElements.lastMonth.total += 1;
      } else {
        recentElements.previousContent.items.push(element);
        recentElements.previousContent.total += 1;
      }
    });

    return Object.keys(recentElements).map(date => (recentElements[date].total ? (
      <Fragment key={date}>
        <div className='d-flex justify-content-between'>
          <h5>{literals[date]}</h5>
          <span className='pr-3'>{`${recentElements[date].total} ${literals.files.toLowerCase()}`}</span>
        </div>
        <hr />
        <ul className='mb-3'>
          { recentElements[date].items.map((cont, i) => (
            <Droppable key={cont.id} droppableId={`droppable-${cont.id}`} isDropDisabled>
              {(provider) => {
                return (
                  <div ref={provider.innerRef}>
                    {renderDraggableRow('document', cont, i)}
                    <div className='folder-dropzone'>
                      <span className='fc-secondary'>{literals.dropInside}</span>
                      {provider.placeholder}
                    </div>
                  </div>
                );
              } }
            </Droppable>
          ))}
        </ul>
      </Fragment>
    ) : null));
  };

  const renderFolderContent = () => {
    return (
      <div className='selected-folder'>
        <div className='folder'>
          {setShowTree && (
            <div className='open-tree' onClick={() => setShowTree(prev => !prev)}>
              <span className='arrow-icon right'><ArrowRight size={18} /></span>
              <Folder size={40} />
            </div>
          )}
          {loading && !search && !['recent', 'bookmark'].includes(selectedFolder?.id) ? (
            <div className='buttons buttons-skeleton'>
              <Skeleton animation='wave' width='183px' height={70} className='mr-2' />
              <Skeleton animation='wave' width='194px' height={70} className='mx-2' />
            </div>
          ) : (
            <div className='buttons'>
              {canEdit && !['recent', 'bookmark'].includes(selectedFolder?.id) && (
                <>
                  <Button
                    className='btn-md-icon mr-2'
                    text={literals.createFolder}
                    icon={FolderPlus}
                    color='secondary'
                    onClick={() => setShowCreateOrRename(true)}
                  />
                  {actualFolder && (
                    <Button
                      className='btn-md-icon mr-2'
                      text={literals.upload}
                      icon={CloudUpload}
                      onClick={() => setShowUploadPopup(true)}
                    />
                  )}
                </>
              )}
            </div>
          )}
          {showBookmarks && !['recent', 'bookmark'].includes(selectedFolder?.id) && bookmarks.length && !loading ? (
            <Swiper
              className='swiper-bookmark swiper-pagination-below'
              slidesPerView={1}
              spaceBetween={20}
              pagination={{ clickable: true }}
              modules={[Pagination]}
              breakpoints={{
                0: { slidesPerView: 1 },
                500: { slidesPerView: 2 },
                1000: { slidesPerView: 3 },
                1280: { slidesPerView: 4 },
              }}
            >
              {canView && bookmarks.map(item => (
                <SwiperSlide key={item.id}>
                  <div className='bookmark-box' onClick={() => handleClickItem(item)}>
                    {item._type === 'folder' ? (
                      <>
                        <div className='folder-icon'>
                          <Folder size={24} className='mr-2' />
                          {item.related && (
                            <span className='folder-icon--type'>{getFolderIcon(item.related.type, 10)}</span>
                          )}
                        </div>
                        <div className='text-overflow'>{getFolderName(item, literals)}</div>
                      </>
                    ) : (
                      <>
                        <div className='mr-2'>
                          {selectType(item.type || item._type, 24)}
                        </div>
                        <div className='text-overflow'>{item.name}</div>
                      </>
                    )}
                    <Bookmark size={16} fill='currentColor' className='bookmark-icon' />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : null}
          { actualFolder || loading ? (
            <div className={showBookmarks ? 'mt-3' : ''}>
              {loading && !search ? (
                <>
                  <div className='folder-breadcrumb breadcrumb-skeleton'>
                    <Skeleton animation='wave' width='30%' />
                  </div>
                  <div className='folder-searcher searcher-skeleton'>
                    <Skeleton animation='wave' width='520px' height='75px' />
                  </div>
                </>
              ) : (
                <>
                  <div className='folder-breadcrumb'>
                    {breadcrumb && (
                      <>
                        {breadcrumb.length > 2 && (
                          <>
                            <Folder className='mr-2' />
                            <span>...</span>
                            <ChevronRight size={14} className='mx-2' />
                          </>
                        )}
                        {breadcrumb.length && (
                          breadcrumb.slice(-2).map((folder, i) => (
                            <div key={`breadcrumb-${folder.id}`} className='d-flex align-items-center'>
                              {i > 0 && <ChevronRight size={14} className='mx-2' />}
                              <Folder className='mr-2' />
                              <span onClick={() => {
                                if (breadcrumb.length > 1 && folder.id !== actualFolder.id) {
                                  setFolder(folder);
                                  setBreadcrumb(prev => prev.slice(0, breadcrumb.length - 1 + i));
                                }
                              }}
                              >
                                <span title={folder.name} className='cursor-pointer'>
                                  {folder.name.length > 15
                                    ? `${folder.name.slice(0, 15)}...`
                                    : folder.name}
                                </span>
                              </span>
                            </div>
                          ))

                        )}
                      </>
                    )}
                  </div>
                  <div className='folder-searcher'>
                    <div className='input_text'>
                      <input
                        className='input'
                        type='text'
                        value={search}
                        placeholder={`${literals.search}...`}
                        onChange={e => setSearch(e.target.value)}
                        onKeyPress={e => e.key === 'Enter' && applyFilters()}
                      />
                      <Button className='btn-search-mobile' icon={Search} onClick={applyFilters} />
                    </div>
                    <div className='search-submit'>
                      <Button className='btn-search-desktop' text={literals.search} icon={Search} onClick={applyFilters} />
                    </div>
                  </div>
                </>
              )}
              {loading ? (
                <>
                  {
                    ((!actualFolder?.stats && actualFolder?.id !== recentFolder.id) || actualFolder?.stats?.folders > 0) && (
                      <div className={`folder-folders ${!search ? 'folders-skeleton' : ''}`}>
                        <Skeleton animation='wave' width='70px' height={22} />
                        <hr />
                        {[...Array(actualFolder?.stats?.folders || 3)].map((_, i) => (
                          <span key={`folder-loading-${i}`} className='d-flex align-items-center justify-content-start'>
                            <Skeleton animation='wave' width='30px' height={49} className='mr-2' />
                            <Skeleton animation='wave' width='40%' />
                          </span>
                        ))}
                      </div>
                    )
                  }
                  {
                    (!actualFolder?.stats || actualFolder?.stats?.documents + actualFolder?.stats?.links > 0) && (
                      <div className={`folder-content ${!search ? 'folders-content' : ''}`}>
                        <Skeleton animation='wave' width='70px' height={22} />
                        <hr />
                        {[...Array(actualFolder?.stats?.documents + actualFolder?.stats?.links || 3)].map((_, i) => (
                          <span key={`document-loading-${i}`} className='d-flex align-items-center justify-content-between'>
                            <div className='flex-grow-1 d-flex justify-content-start align-items-center'>
                              <Skeleton animation='wave' width='30px' height={60} className='mr-2' />
                              <Skeleton animation='wave' width='40%' />
                            </div>
                            <div className='flex-grow-0'>
                              <Skeleton animation='wave' width={30} height={30} variant='circular' className='mr-4' />
                            </div>
                          </span>
                        ))}
                      </div>
                    )
                  }
                </>
              ) : (
                <>
                  {subfolders.length > 0 || content?.items?.length > 0 ? (
                    <div ref={scrollRef} className='content-container simple-scrollbar' onScroll={handleScroll}>
                      {
                        subfolders.length > 0 && (
                          <div className='folder-folders'>
                            <h5>{literals.folders}</h5>
                            <hr />

                            <ul>
                              {subfolders.map((subfolder, i) => (
                                <Droppable key={`subfolder-${subfolder.id}`} droppableId={`subfolder-${subfolder.id}`} isCombineEnabled ignoreContainerClipping>
                                  {(provider, snapshot) => {
                                    const isDraggingOver = snapshot.isDraggingOver && `subfolder-${subfolder.id}` !== snapshot.draggingOverWith;
                                    return (
                                      <div ref={provider.innerRef} className={`droppable ${isDraggingOver ? 'droppable-selected' : ''}`}>
                                        { renderDraggableRow('folder', subfolder, i) }
                                        <div className={`folder-dropzone ${isDraggingOver ? 'active' : ''}`}>
                                          <span className='fc-secondary'>{literals.dropInside}</span>
                                          {provider.placeholder}
                                        </div>
                                      </div>
                                    );
                                  }}
                                </Droppable>
                              ))}
                            </ul>

                          </div>
                        )
                      }
                      {
                        content?.items?.length > 0 && (
                          <div className='folder-folders'>
                            { selectedFolder.id !== recentFolder.id ? (
                              <>
                                <div className='d-flex justify-content-between'>
                                  <h5>{literals.content}</h5>
                                  <span className='pr-3'>{`${content.total} ${literals.files.toLowerCase()}`}</span>
                                </div>
                                <hr />
                                <ul>
                                  { content.items.map((cont, i) => (
                                    <Droppable key={cont.id} droppableId={`droppable-${cont.id}`} isDropDisabled>
                                      {(provider) => {
                                        return (
                                          <div ref={provider.innerRef}>
                                            {renderDraggableRow(cont?._type, cont, i)}
                                            <div className='folder-dropzone'>
                                              <span className='fc-secondary'>{literals.dropInside}</span>
                                              {provider.placeholder}
                                            </div>
                                          </div>
                                        );
                                      } }
                                    </Droppable>
                                  ))}
                                </ul>
                              </>
                            ) : (
                              renderRecentFolder()
                            )}
                          </div>
                        )
                      }
                      {
                        loadingPage && (
                          <div className='loading-page'>
                            <Loading mode='dialog' hide={false} />
                            <span className='pl-3'>{`${content.items.length} / ${content.total}`}</span>
                          </div>
                        )
                      }
                    </div>
                  ) : (
                    <div className='empty-folder'>
                      <img src={EmptyFolder} alt={literals.emptyFolder} />
                      <p>
                        {unauthorized ? literals.noFolderPermissions : literals.emptyFolder}
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className='empty-folder'>
              <img src={EmptyFolder} alt={literals.noFolderSelected} />
              <p>{literals.noFolderSelected}</p>
            </div>
          )}
          {
            showCreateOrRename && (
              <PopupCreateRename
                rolePath={rolePath}
                folder={showCreateOrRename}
                parent={actualFolder}
                literals={literals}
                startup={startup}
                setFolders={setFolders}
                setSubfolders={setSubfolders}
                setRenamedFolder={setRenamedFolder}
                onClose={() => setShowCreateOrRename(null)}
              />
            )
          }
          {
            showUploadPopup && (
              <PopupUploadFile
                literals={literals}
                startup={startup}
                onClose={() => setShowUploadPopup(null)}
                parent={actualFolder}
                setContent={setContent}
                setFolders={setFolders}
              />
            )
          }
          {
            showInfoPopup && (
              <PopupInfoFile
                literals={literals}
                literalsCommon={literalsCommon}
                onClose={() => setShowInfoPopup(null)}
                file={showInfoPopup}
                setContent={setContent}
                handleBookmark={handleBookmark}
              />
            )
          }
          {
            showEditPopup && (
              <PopupEditFile
                literals={literals}
                literalsCommon={literalsCommon}
                startup={startup}
                folder={actualFolder}
                onClose={() => setShowEditPopup(null)}
                content={showEditPopup}
                setContent={setContent}
              />
            )
          }
          {showViewersPopup && (
            <PopupViewsFile
              literals={literals}
              file={showViewersPopup}
              onClose={() => setShowViewersPopup(null)}
            />
          )}
          {showMovePopup && (
            <PopupMove
              literals={literals}
              elemToMove={showMovePopup}
              onClose={() => setShowMovePopup(false)}
              foldersProp={folders || [selectedFolder]}
              setFoldersProp={setFolders}
              rootFolder={rootFolder}
              match={match}
              actualFolder={actualFolder}
              setSubfolders={setSubfolders}
              setContent={setContent}
            />
          )}
          {showVersionsPopup && (
            <PopupFileVersions
              literals={literals}
              literalsCommon={literalsCommon}
              onClose={() => setShowVersionsPopup(null)}
              content={showVersionsPopup}
              setContent={setContent}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    folders ? (
      renderFolderContent()
    ) : (
      <DragDropContext onDragEnd={handleDragEnd}>
        { renderFolderContent() }
      </DragDropContext>
    )
  );
};

FolderContent.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  selectedFolder: PropTypes.object,
  setSelectedFolder: PropTypes.func,
  setShowTree: PropTypes.func,
  editable: PropTypes.bool,
  folders: PropTypes.array,
  setFolders: PropTypes.func,
  change: PropTypes.object,
  setFetching: PropTypes.func,
  auxBreadcrumb: PropTypes.array,
  startup: PropTypes.object.isRequired,
  bookmarks: PropTypes.bool,
  canBookmark: PropTypes.bool,
};

FolderContent.defaultProps = {
  folders: null,
  setFolders: () => {},
  setFetching: () => {},
  change: null,
  setShowTree: null,
  editable: true,
  selectedFolder: null,
  setSelectedFolder: null,
  auxBreadcrumb: [],
  bookmarks: false,
  canBookmark: false,
};

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.dataRoom,
    literalsCommon: state.i18n.literals.common,
    startup: state.global.startup,
  };
}

export default withRouter(connect(mapStateToProps)(FolderContent));
