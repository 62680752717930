export default {
  en: {
    login: {
      title: 'Login in to your account',
      email: 'Email',
      password: 'Password',
      login: 'Login',
      noAccount: "Don't have an account?",
      questionNotRegistered: 'No registered yet? ',
      createAccount: 'Create an Account',
      register: 'If you do not have an account yet, you can create one here',
      invalidLogin: 'Incorrect username or password',
      invalidToken: 'Incorrect token',
      recoveryButton: 'Did you forgot your password?',
      recovery: {
        title: 'Recover your password',
        description: 'We will send you an email with a link to reset your password',
        button: 'Submit',
        successMessage: "If the email exists, we'll sent you an email with instructions on how to set your new password.",
        back: 'Go back to login',
      },
      email_error: 'Invalid email format',
      token: 'Token',
      tokenMsg: 'Enter the code we have sent to your email',
    },
    legal: {
      home: 'Home',
    },
    tool: {
      startup: 'Home',
      mentor: 'Home',
      mentorings: 'Mentorings',
    },
    setPassword: {
      title: 'Set password',
      description: 'Set your password below, it must be at least 10 characters long and include upper and lower case letters.',
      password: 'Password',
      repeatPassword: 'Repeat password',
      register: 'Confirm',
      passwordRules: 'The password must be between at least 10 characters and have at least one upper and one lower case letter',
      passwordEqualError: "Previous passwords can't be repeated",
      nonRepeatedPassword: 'Passwords entered do not match',
      invalidToken: 'The link is invalid, please request a new password',
      passwordSuccess: 'Your password has been saved correctly, log in below to enter the platform',
    },
    signup: {
      title: 'Signup',
      description: 'Coming soon',
      firstname: 'Firstname',
      lastname: 'Lastname',
      email: 'Email',
      language: 'Language',
      role: 'Role',
      signup: 'Signup',
      createAccount: 'Create account',
      incorrectEmail: 'The email entered is invalid',
      haveAnAccount: 'I\'ve an account',
      doLogin: 'Log in with it',
      questionRegistered: 'Are you already registered? ',
      es: 'Spanish',
      en: 'English',
      it: 'Italian',
      mentor: 'Mentor',
      user: 'User',
      notFound: 'Registration is not enabled',
      goToLogin: 'Go to login',
      signupSuccess: 'Account created, you will shortly receive an email with login instructions',
      signupError: 'Check your data and make sure you don\'t already have an account created',
      socialSignupError: 'Check that you have given permission to know your email and try again',
      or: 'or',
    },
    downloadFile: {
      unauthenticated: 'Login to download the file',
      noPermissions: 'You do not have permissions to download the file',
      noExists: 'The file does not exist',
      login: 'Login',
      downloadingFile: 'Downloading file',
      downloadedFile: 'File downloaded',
      genericError: 'An error has occurred, confirm that the url is correct and try again',
    },
    topBar: {
      account: 'Account / Profile',
      settings: 'Settings',
      logout: 'Logout',
      loading: 'Loading',
      noNotifications: 'No notifications',
      markAsRead: 'Mark as read',
      markAsUnread: 'Mark as unread',
      delete: 'Delete',
      help: 'Help',
      addCompany: 'ADD COMPANY',
      prehome: 'Pre home',
      pushNotifications: 'Push Notifications',
      viewMyPosition: 'View my position',
      youAreImpersonating: 'You are impersonating',
      notifications: {
        all: 'All',
        unread: 'Unread',
        mentoring: {
          mentoringInviteMentor: '%FROM_NAME% has requested to start a mentorship with you',
          mentoringInviteStartup: '%FROM_NAME% has requested to start a mentorship with %STARTUP_NAME%',
          mentoringAccepted: '%FROM_NAME% has accepted the application for mentoring',
          mentoringRejected: '%FROM_NAME% has rejected the application for mentoring',
          mentoringFinished: '%FROM_NAME% has finished the mentoring',
          mentoringMessage: 'New message from %FROM_NAME% in the mentoring',
          mentoringMessages: '%COUNT% new messages from %FROM_NAME% in the mentoring',
          mentoringMeeting: '%FROM_NAME% has registered a new meeting',
          mentoringMeetingUpdate: '%FROM_NAME% has updated a meeting',
          mentoringMeetingDelete: '%FROM_NAME% has deleted a meeting',
        },
        startup: {
          invite: '%FROM_NAME% has invited you to join %STARTUP_NAME%',
          inviteAccepted: '%USER_NAME% has accepted your invitation to join %STARTUP_NAME%',
          inviteRejected: '%USER_NAME% has rejected your invitation to join %STARTUP_NAME%',
        },
        acceptPermissions: 'Accept the browser permissions',
        notificationsActivated: 'Notifications activated',
        notificationsActivatedMessage: 'From now on you will receive notifications in the browser',
        notificationsDeactivated: 'Notifications disabled',
        notificationsDeactivatedMessage: 'From now on you will stop receiving notifications in the browser',
      },
    },
    leftMenu: {
      summary: 'Home',
      myPosition: 'My position',
      capTable: 'Cap table',
      fundraise: 'Fundraise',
      funds: 'Funds',
      performance: 'Performance',
      mentoring: 'Mentoring',
      mentorings: 'Mentorings',
      reporting: 'Reporting',
      services: 'Services',
      dataRoom: 'Documents',
      logs: 'Logs',
      webhooks: 'Webhooks',
      scripts: 'Scripts',
      crons: 'Cron jobs',
      settings: 'Settings',
      userAccess: 'Users',
      companies: 'Companies',
      platform: 'Platform',
      profile: 'Profile',
      help: 'Help',
      analytics: 'Analytics',
      emailLogs: 'Email logs',
      profileStartup: 'Startup',
      capTableSummary: 'Summary',
      capTableSecurities: 'Securities',
      capTableOperations: 'Operations',
      capTableTransactions: 'Transactions',
      capTableShareholders: 'Shareholders',
      capTableVestingPlans: 'Vesting Plans',
      capTableShareClasses: 'Share classes',
      capTableWaterfall: 'Waterfall',
      addCompany: 'ADD COMPANY',
      portfolio: 'Portfolio',
      analysis: 'Analysis',
      opportunities: 'Opportunities',
      permissions: 'Permissions',
    },
    dialog: {
      accept: 'Accept',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },
    permissions: {
      permissions: 'Permissions',
      addPersonOrGroup: 'Add person or group',
      addPerson: 'Add person',
      addGroup: 'Add group',
      inputPersonOrGroup: 'Input the name of the person or group',
      inputGroupName: 'Input the name of the group',
      members: 'Members',
      usersWithAccess: 'Users with access',
      groupsWithAccess: 'Groups with access',
      noPermissionsToSeeMembers: 'You do not have permissions to see the members',
      group: 'Group',
      groups: 'Groups',
      users: 'Users',
      manage: 'Manage',
      owner: 'Owner',
      removePermissions: 'Remove permissions',
      createGroup: 'Create group',
      editGroup: 'Edit group',
      editUserPermissions: 'Edit user permissions',
      giveMorePermissions: 'Give more permissions',
      permissionPrevailsInfo: 'The highest permission defined by the groups and the user\'s permission prevails.',
      searchUsers: 'Search users',
      searchGroups: 'Search groups',
      searchUsersAndGroups: 'Search users and groups',
      noUsers: 'There is no user yet',
      noGroups: 'There is no groups yet',
      private: 'Private',
      privacity: 'Privacity',
      sharedWith: 'Shared with',
      empty: 'No elements',
      ifNotPrivate: 'If it is not private, it will be shared with all users depending on the permissions on the tool',
      sharedNo: 'Not shared',
      itsPrivate: 'It is private',
      sharedUsers: 'Shared with %users% users',
      sharedGroups: 'Shared with %groups% groups',
      sharedBoth: 'Shared with %users% users and %groups% groups',
      privateDetails: 'Private: Only users with permissions can access this element',
      restrictedDetails: 'Restricted: All users with access to the tool can access to this element',
      publicDetails: 'Public: All users can access to this element',
      public: 'Public',
      restricted: 'Restricted',
      cantShareExternal: 'This resource is private because you don\'t have access to the startup and you will only be able to see it',
      cantShareUnauthorized: 'This resource is private because you don\'t have enough permissions in the tool, you will only be able to see it',
      shareWithManagers: 'Share with managers if I am accepted into the startup',
      tools: {
        capTable: 'Cap Table',
        performance: 'KPIs',
        dataRoom: 'Documents',
        profile: 'Perfil',
        mentoring: 'Mentorias',
        reporting: 'Reportes',
        users: 'Gestor de usuarios',
        messages: 'Mensajes',
        services: 'Servicios',
        fundraise: 'Recaudar fondos',
      },
      levels: {
        0: 'No permissions',
        1: 'See',
        2: 'Edit',
        3: 'Manage',
      },
      defaultGroups: {
        owner: 'Owners (default)',
        admin: 'Admins (default)',
        user: 'Users (default)',
        shareholder: 'Shareholders (default)',
        other: 'Others (default)',
        ESOPs: 'ESOPs (default)',
        startup: 'All users (default)',
      },
    },
    signature: {
      uploadDocumentToBeSigned: 'Upload the document to be signed',
      subject: 'Subject',
      message: 'Message',
      signatureStatus: 'Signature status',
      downloadDocument: 'Download document',
      downloadAudit: 'Download audit',
      signatureZone: 'Signature zone',
      topLeft: 'Top - Left',
      topRight: 'Top - Right',
      bottomLeft: 'Bottom - Left',
      bottomRight: 'Bottom - Right',
      sendTo: 'Send to',
      status: {
        email_processed: 'The email has been processed',
        email_delivered: 'The email has been delivered',
        email_opened: 'The email has been opened',
        email_bounced: 'The server cannot deliver the message. Bounces often are caused by outdated or incorrectly entered email addresses.',
        email_deferred: 'The email cannot immediately be delivered, but it hasn’t been completely rejected. Sometimes called a soft bounce, it will be retried for 72 hours.',
        reminder_email_processed: 'The reminder email has been processed',
        reminder_email_delivered: 'The reminder email has been delivered',
        reminder_email_opened: 'The reminder email has been opened',
        sms_processed: 'The SMS has been processed',
        sms_delivered: 'The SMS has been delivered',
        password_sms_processed: 'The password SMS has been processed',
        password_sms_delivered: 'The password SMS has been delivered',
        document_opened: 'The document has been opened',
        document_signed: 'The document has been signed',
        document_completed: 'The document has been completed and ready to be downloaded',
        audit_trail_completed: 'The audit trail has been completed and ready to be downloaded',
        document_declined: 'The document has been declined',
        document_expired: 'The document has expired',
        document_canceled: 'The document has been canceled',
        photo_added: 'The signer has attached a photo in the process',
        voice_added: 'The signer has attached an audio in the process',
        file_added: 'The signer has attached a file in the process',
        photo_id_added: 'The signer has attached a photo ID in the process',
        terms_and_conditions_accepted: 'The signer has accepted the terms and conditions',
      },
    },
    onboarding: {
      welcome: 'Welcome to ',
      subtitle: 'From this platform you will be able to connect with mentors who can help you to boost your project',
      subtitleMentor: 'From this platform, you will be able to connect with startups to help them boost their project',
      subtitleFund: '',
      letsStart: 'Let’s start :)',
      companyData: 'Startup data',
      mentorData: 'Mentor data',
      fundData: 'Fund data',
      presentation: 'Presentation',
      preferences: 'Preferences',
      companyName: 'Company name',
      name: 'Name',
      startupName: 'Startup name',
      externalId: 'External ID',
      taxid: 'Tax ID',
      url: 'URL',
      type: 'Type',
      select: 'Select',
      sectors: 'Sectors',
      describeWhatYourCompanyDoes: 'Describe what your startup does',
      inviteColleages: 'Invite colleages',
      back: 'Back',
      headline: 'Bio',
      whatDoYouSpendYourTimeOn: 'What do you spend your time on? CEO, COO, ... Investor, Business angel, ...',
      company: 'Company',
      country: 'Country',
      city: 'City',
      legalForm: 'Legal form',
      legalName: 'Legal name',
      constitutionDate: 'Constitution date',
      hoursToDonate: 'Hours to donate',
      hours: 'Hours',
      knowledgeSector: 'Knowledge sector',
      knowledgeSectorMentor: 'Knowledge sector (where you can help)',
      areasOfExpertise: 'Areas',
      areasOfExpertiseMentor: 'Areas (where you can help)',
      businessTypes: 'Business Type',
      businessTypesMentor: 'Business Type (where you can help)',
      advancedMaterials: 'Advanced materials',
      advancedMaterialsMentor: 'Advanced materials (where you can help)',
      agrotech: 'Agrotech',
      api: 'API',
      artificialIntelligence: 'Artificial Intelligence',
      augmentedReality: 'Augmented Reality',
      autonomousMachines: 'Autonomous machines',
      bigData: 'Big Data',
      blockchain: 'Blockchain',
      businessIntelligence: 'Business Intelligence',
      cloud: 'Cloud',
      communities: 'Communities',
      connectivity: 'Connectivity',
      consumerGoods: 'Consumer Goods',
      crm: 'CRM',
      crowdsourcing: 'Crowdsourcing',
      customerService: 'Customer service',
      cybersecurity: 'Cybersecurity',
      design: 'Design',
      developmentTools: 'Development Tools',
      domotics: 'Domotics',
      drones: 'Drones',
      eCommerce: 'E-commerce (marketplace for products or services)',
      education: 'Education',
      eHealth: 'eHealth',
      enablers: 'Enablers',
      engineering: 'Engineering',
      entertainment: 'Entertainment',
      erp: 'ERP',
      finTech: 'FinTech',
      fishing: 'Fishing',
      fitness: 'Fitness',
      games: 'Games',
      hospitality: 'Hospitality',
      hr: 'HR',
      'industry4.0': 'Industry 4.0',
      insurtech: 'Insurtech',
      intelligentMobility: 'Intelligent mobility',
      iot: 'IoT',
      legalTech: 'Legaltech',
      lifestyle: 'Lifestyle',
      location: 'Location',
      logisticsAndTransportation: 'Logistics & Transportation(Goods)',
      loyalty: 'Loyalty',
      m2m: 'M2M',
      machineLearning: 'Machine learning',
      nanotechnology: 'Nanotechnology',
      'rv-ra-rm': 'RV-RA-RM',
      robotics: 'Robotics',
      others: '+ Otros',
      b2c: 'B2C',
      b2b: 'B2B',
      c2b: 'C2B',
      c2c: 'C2C',
      b2g: 'B2G',
      d2c: 'D2C',
      generalDirection: 'Dirección general',
      humanResources: 'Recursos humanos',
      FinanceAndAccounting: 'Finanzas y contabilidad',
      MarketingAndSales: 'Márketing y ventas',
      production: 'Producción',
      itTechnology: 'Informática y tecnología',
      innovation: 'Innovación',
      start: 'Start!',
      previous: 'Previous',
      next: 'Next',
      uploadLogo: 'Upload your logo',
      uploadAvatar: 'Upload your avatar',
      logo: 'Logo',
      description: 'Short description',
      avatar: 'Avatar',
      lastname: 'Last name',
      founders: 'Founders',
      foundedDate: 'Founded date',
      firstname: 'firstname',
      see: 'See',
      modify: 'Modify',
      delete: 'Delete',
      users: 'Users',
      searchStartup: 'Discover if your startup is already in our database, enter the name below, and in case it doesn\'t exist, you can proceed to register it with all the information.',
      searchFund: 'Discover if your fund is already in our database, enter the name below, and in case it doesn\'t exist, you can proceed to register it with all the information.',
      writeStartupName: 'Write the name of your startup',
      writeFundName: 'Write the name of your fund',
      messageOwnerExist: 'The startup %STARTUP_NAME% already has an owner. You can request access by clicking the button below.',
      accessRequestSent: 'Request sent successfully. The startup owner will respond to your request as soon as possible.',
      accessRequestPending: 'There is already a request for access to %STARTUP_NAME% pending. The startup owner will respond to your request as soon as possible.',
      accessRequestRejected: 'Sorry, the owner of startup %STARTUP_NAME% has denied your access request.',
      request: 'Request',
      requested: 'Requested',
      requestToAdmin: 'Request to admin',
      requestSentSuccessfully: 'Your access request has been successfully submitted. In the meantime, you can continue browsing as a guest.',
      user_already_exist: 'This user already belongs to the startup',
      request_already_exist: 'There is already a request',
      verifyProperty: 'Verify property',
      requestAccess: 'Request access',
      messageValidationRequired: 'We need to verify that the startup %STARTUP_NAME% is owned by you. To do this you have three options: access with an email from the startup, perform a DNS verification or request access to a platform administrator.',
      messageValidationRequiredExternal: 'We need to verify that the startup %STARTUP_NAME% is owned by you. To do this you have two options: access with an email from the startup or perform a DNS verification.',
      requestAccessToAdmin: 'We were unable to verify that the startup %STARTUP_NAME% is owned by you. Request access to a platform administrator.',
      messageValidationDNS: 'Create a TXT type DNS record in the domain %DOMAIN% with value "%DNS_VALUE%" and try again',
      accessWithoutPermission: 'Continue as guest',
      retry: 'Retry',
      couldNotVerify: 'Could not verify',
      deleteStartup: 'Are you sure you want to delete the startup? If you delete the startup all data will be permanently deleted',
      fundManager: 'Fund manager name',
      address: 'Address',
      managementCompanyCountry: 'Management Company Country',
      managementCompanyAddress: 'Management Company Address',
      fundRegulator: 'Fund regulator',
      subfund: 'Subfund',
      lieAlreadyExist: 'The fund you are trying to create has already been registered and is under review',
      lieAlreadyExistVerified: 'A fund with the same identifier already exists',
      institutionType: {
        title: 'Institution type',
        opened: 'Opened',
        closed: 'Closed',
      },
      investmentPolicy: {
        title: 'Investment policy',
        ventureCapital: 'Venture capital',
        privateEquity: 'Private equity',
        corporateVentureCapital: 'Corporate venture capital',
        realEstate: 'Real estate',
        privateDebt: 'Private debt',
      },
      countries: {
        AFG: 'Afghanistan',
        ALA: 'Åland Islands',
        ALB: 'Albania',
        DZA: 'Algeria',
        ASM: 'American Samoa',
        AND: 'Andorra',
        AGO: 'Angola',
        AIA: 'Anguilla',
        ATA: 'Antarctica',
        ATG: 'Antigua and Barbuda',
        ARG: 'Argentina',
        ARM: 'Armenia',
        ABW: 'Aruba',
        AUS: 'Australia',
        AUT: 'Austria',
        AZE: 'Azerbaijan',
        BHS: 'Bahamas',
        BHR: 'Bahrain',
        BGD: 'Bangladesh',
        BRB: 'Barbados',
        BLR: 'Belarus',
        BEL: 'Belgium',
        BLZ: 'Belize',
        BEN: 'Benin',
        BMU: 'Bermuda',
        BTN: 'Bhutan',
        BOL: 'Bolivia (Plurinational State of)',
        BES: 'Bonaire, Sint Eustatius and Saba',
        BIH: 'Bosnia and Herzegovina',
        BWA: 'Botswana',
        BVT: 'Bouvet Island',
        BRA: 'Brazil',
        IOT: 'British Indian Ocean Territory',
        BRN: 'Brunei Darussalam',
        BGR: 'Bulgaria',
        BFA: 'Burkina Faso',
        BDI: 'Burundi',
        KHM: 'Cambodia',
        CMR: 'Cameroon',
        CAN: 'Canada',
        CPV: 'Cabo Verde',
        CYM: 'Cayman Islands',
        CAF: 'Central African Republic',
        TCD: 'Chad',
        CHL: 'Chile',
        CHN: 'China',
        CXR: 'Christmas Island',
        CCK: 'Cocos (Keeling) Islands',
        COL: 'Colombia',
        COM: 'Comoros',
        COG: 'Congo',
        COD: 'Congo, Democratic Republic of the',
        COK: 'Cook Islands',
        CRI: 'Costa Rica',
        CIV: "Côte d'Ivoire",
        HRV: 'Croatia',
        CUB: 'Cuba',
        CUW: 'Curaçao',
        CYP: 'Cyprus',
        CZE: 'Czechia',
        DNK: 'Denmark',
        DJI: 'Djibouti',
        DMA: 'Dominica',
        DOM: 'Dominican Republic',
        ECU: 'Ecuador',
        EGY: 'Egypt',
        SLV: 'El Salvador',
        GNQ: 'Equatorial Guinea',
        ERI: 'Eritrea',
        EST: 'Estonia',
        ETH: 'Ethiopia',
        FLK: 'Falkland Islands (Malvinas)',
        FRO: 'Faroe Islands',
        FJI: 'Fiji',
        FIN: 'Finland',
        FRA: 'France',
        GUF: 'French Guiana',
        PYF: 'French Polynesia',
        ATF: 'French Southern Territories',
        GAB: 'Gabon',
        GMB: 'Gambia',
        GEO: 'Georgia',
        DEU: 'Germany',
        GHA: 'Ghana',
        GIB: 'Gibraltar',
        GRC: 'Greece',
        GRL: 'Greenland',
        GRD: 'Grenada',
        GLP: 'Guadeloupe',
        GUM: 'Guam',
        GTM: 'Guatemala',
        GGY: 'Guernsey',
        GIN: 'Guinea',
        GNB: 'Guinea-Bissau',
        GUY: 'Guyana',
        HTI: 'Haiti',
        HMD: 'Heard Island and McDonald Islands',
        VAT: 'Holy See',
        HND: 'Honduras',
        HKG: 'Hong Kong',
        HUN: 'Hungary',
        ISL: 'Iceland',
        IND: 'India',
        IDN: 'Indonesia',
        IRN: 'Iran (Islamic Republic of)',
        IRQ: 'Iraq',
        IRL: 'Ireland',
        IMN: 'Isle of Man',
        ISR: 'Israel',
        ITA: 'Italy',
        JAM: 'Jamaica',
        JPN: 'Japan',
        JEY: 'Jersey',
        JOR: 'Jordan',
        KAZ: 'Kazakhstan',
        KEN: 'Kenya',
        KIR: 'Kiribati',
        PRK: "Korea (Democratic People's Republic of)",
        KOR: 'Korea, Republic of',
        KWT: 'Kuwait',
        KGZ: 'Kyrgyzstan',
        LAO: "Lao People's Democratic Republic",
        LVA: 'Latvia',
        LBN: 'Lebanon',
        LSO: 'Lesotho',
        LBR: 'Liberia',
        LBY: 'Libya',
        LIE: 'Liechtenstein',
        LTU: 'Lithuania',
        LUX: 'Luxembourg',
        MAC: 'Macao',
        MKD: 'North Macedonia',
        MDG: 'Madagascar',
        MWI: 'Malawi',
        MYS: 'Malaysia',
        MDV: 'Maldives',
        MLI: 'Mali',
        MLT: 'Malta',
        MHL: 'Marshall Islands',
        MTQ: 'Martinique',
        MRT: 'Mauritania',
        MUS: 'Mauritius',
        MYT: 'Mayotte',
        MEX: 'Mexico',
        FSM: 'Micronesia (Federated States of)',
        MAR: 'Morocco',
        MDA: 'Moldova, Republic of',
        MCO: 'Monaco',
        MNG: 'Mongolia',
        MNE: 'Montenegro',
        MSR: 'Montserrat',
        MOZ: 'Mozambique',
        MMR: 'Myanmar',
        NAM: 'Namibia',
        NRU: 'Nauru',
        NPL: 'Nepal',
        NLD: 'Netherlands',
        NCL: 'New Caledonia',
        NZL: 'New Zealand',
        NIC: 'Nicaragua',
        NER: 'Niger',
        NGA: 'Nigeria',
        NIU: 'Niue',
        NFK: 'Norfolk Island',
        MNP: 'Northern Mariana Islands',
        NOR: 'Norway',
        OMN: 'Oman',
        PAK: 'Pakistan',
        PLW: 'Palau',
        PSE: 'Palestine, State of',
        PAN: 'Panama',
        PNG: 'Papua New Guinea',
        PRY: 'Paraguay',
        PER: 'Peru',
        PHL: 'Philippines',
        PCN: 'Pitcairn',
        POL: 'Poland',
        PRT: 'Portugal',
        PRI: 'Puerto Rico',
        QAT: 'Qatar',
        REU: 'Réunion',
        ROU: 'Romania',
        RUS: 'Russian Federation',
        RWA: 'Rwanda',
        BLM: 'Saint Barthélemy',
        SHN: 'Saint Helena, Ascension and Tristan da Cunha',
        KNA: 'Saint Kitts and Nevis',
        LCA: 'Saint Lucia',
        MAF: 'Saint Martin (French part)',
        SPM: 'Saint Pierre and Miquelon',
        VCT: 'Saint Vincent and the Grenadines',
        WSM: 'Samoa',
        SMR: 'San Marino',
        STP: 'Sao Tome and Principe',
        SAU: 'Saudi Arabia',
        SEN: 'Senegal',
        SRB: 'Serbia',
        SYC: 'Seychelles',
        SLE: 'Sierra Leone',
        SGP: 'Singapore',
        SXM: 'Sint Maarten (Dutch part)',
        SVK: 'Slovakia',
        SVN: 'Slovenia',
        SLB: 'Solomon Islands',
        SOM: 'Somalia',
        ZAF: 'South Africa',
        SGS: 'South Georgia and the South Sandwich Islands',
        SSD: 'South Sudan',
        ESP: 'Spain',
        LKA: 'Sri Lanka',
        SDN: 'Sudan',
        SUR: 'Suriname',
        SJM: 'Svalbard and Jan Mayen',
        SWZ: 'Eswatini',
        SWE: 'Sweden',
        CHE: 'Switzerland',
        SYR: 'Syrian Arab Republic',
        TWN: 'Taiwan, Province of China',
        TJK: 'Tajikistan',
        TZA: 'Tanzania, United Republic of',
        THA: 'Thailand',
        TLS: 'Timor-Leste',
        TGO: 'Togo',
        TKL: 'Tokelau',
        TON: 'Tonga',
        TTO: 'Trinidad and Tobago',
        TUN: 'Tunisia',
        TUR: 'Turkey',
        TKM: 'Turkmenistan',
        TCA: 'Turks and Caicos Islands',
        TUV: 'Tuvalu',
        UGA: 'Uganda',
        UKR: 'Ukraine',
        ARE: 'United Arab Emirates',
        GBR: 'United Kingdom of Great Britain and Northern Ireland',
        USA: 'United States of America',
        UMI: 'United States Minor Outlying Islands',
        URY: 'Uruguay',
        UZB: 'Uzbekistan',
        VUT: 'Vanuatu',
        VEN: 'Venezuela (Bolivarian Republic of)',
        VNM: 'Viet Nam',
        VGB: 'Virgin Islands (British)',
        VIR: 'Virgin Islands (U.S.)',
        WLF: 'Wallis and Futuna',
        ESH: 'Western Sahara',
        YEM: 'Yemen',
        ZMB: 'Zambia',
        ZWE: 'Zimbabwe',
      },
      language: 'Language',
      role: 'Rol',
      email: 'Email',
      languages: {
        es: 'Spanish',
        en: 'English',
        it: 'Italian',
      },
      mainCurrency: 'Main currency',
      mentorVisible: 'Available to receive mentoring requests',
      yes: 'Yes',
      no: 'No',
      venture_capital: 'Venture capital',
      funds_of_funds: 'Funds of Funds',
      debt: 'Debt',
      other: 'Other',
      minPerProject: 'Minimum investment per project',
      maxPerProject: 'Maximum investment per project',
      phase: 'Fase',
      preseed: 'PRE-SEED',
      seed: 'SEED',
      early: 'EARLY',
      growth: 'GROWTH',
      expansion: 'EXPANSION',
      industries: 'Industries',
      health: 'Health',
      fintech: 'Fintech',
      food: 'Food',
      media: 'Media',
      marketing: 'Marketing',
      enterpriseSoftware: 'Enterprise Software',
      transportation: 'Transportation',
      fashion: 'Fashion',
      realEstate: 'Real Estate',
      homeLiving: 'Home Living',
      kids: 'Kids',
      energy: 'Energy',
      wellnessBeauty: 'Wellness Beauty',
      sports: 'Sports',
      hosting: 'Hosting',
      gaming: 'Gaming',
      travel: 'Travel',
      semiconductors: 'Semiconductors',
      music: 'Music',
      eventTech: 'Event Tech',
      jobsRecruitment: 'Jobs Recruitment',
      security: 'Security',
      legal: 'Legal',
      telecom: 'Telecom',
      dating: 'Dating',
      serviceProvider: 'Service Provider',
      engineeringAndManufacturingEquipment: 'Engineering and Manufacturing Equipment',
      space: 'Space',
      consumerElectronics: 'Consumer Electronics',
      chemicals: 'Chemicals',
      subindustries: 'Subindustries',
      healthPlatform: 'Health platform',
      biotechnology: 'Biotechnology',
      medicalDevices: 'Medical devices',
      pharmaceutical: 'Pharmaceutical',
      financialManagementSolutions: 'Financial management solutions',
      wealthManagement: 'Wealth management',
      payments: 'Payments',
      mortgagesAndLending: 'Mortgages & lending',
      insurance: 'Insurance',
      cryptoAndDeFi: 'Crypto and DeFi',
      banking: 'Banking',
      regtech: 'Regtech',
      innovativeFood: 'Innovative food',
      agritech: 'Agritech',
      foodLogisticsAndDelivery: 'Food logistics & delivery',
      inStoreRetailAndRestaurantTech: 'In-store retail & restaurant tech',
      kitchenAndCookingTech: 'Kitchen & cooking tech',
      contentProduction: 'Content production',
      publishing: 'Publishing',
      socialMedia: 'Social media',
      streaming: 'Streaming',
      adtech: 'Adtech',
      cRMAndSales: 'CRM & sales',
      ecommerceSolutions: 'Ecommerce solutions',
      marketingAnalytics: 'Marketing analytics',
      logisticsAndDelivery: 'Logistics & delivery',
      mobility: 'Mobility',
      vehicleProduction: 'Vehicle production',
      searchBuyAndRent: 'Search, buy & rent',
      navigationAndMapping: 'Navigation & mapping',
      maintenance: 'Maintenance',
      autonomousAndSensorTech: 'Autonomous & sensor tech',
      apparel: 'Apparel',
      accessories: 'Accessories',
      luxury: 'Luxury',
      footwear: 'Footwear',
      construction: 'Construction',
      realEstateServices: 'Real estate services',
      realEstateSoftware: 'Real estate software',
      workspaces: 'Workspaces',
      learningToolsAndResources: 'Learning tools and resources',
      educationProviders: 'Education providers',
      educationManagement: 'Education management',
      cleanEnergy: 'Clean energy',
      energyEfficiency: 'Energy efficiency',
      oilAndGas: 'Oil & gas',
      wasteSolution: 'Waste solution',
      water: 'Water',
      energyProviders: 'Energy providers',
      energyStorage: 'Energy storage',
      sportPlatformAndApplication: 'Sport platform & application',
      sportingEquipment: 'Sporting equipment',
      sportLeagueAndClub: 'Sport league & club',
      sportMedia: 'Sport media',
      sportSupplements: 'Sport supplements',
      consoleAndPCGaming: 'Console & PC gaming',
      mobileGaming: 'Mobile gaming',
      bettingAndGambling: 'Betting & gambling',
      esports: 'Esports',
      boardGames: 'Board games',
      onlineTravelAgency: 'Online travel agency',
      accommodation: 'Accommodation',
      bookingAndSearch: 'Booking & search',
      travelAnalyticsAndSoftware: 'Travel analytics & software',
      businessTravel: 'Business travel',
      publicSafety: 'Public safety',
      cloudAndInfrastructure: 'Cloud & infrastructure',
      dataProtection: 'Data protection',
      identityAndAccess: 'Identity & access',
      deviceSecurityAndAntivirus: 'Device security & antivirus',
      consulting: 'Consulting',
      incomeStreams: 'Income streams',
      subscription: 'Subscription',
      advertising: 'Advertising',
      commission: 'Commission',
      sellingOwnInventory: 'Selling own inventory',
      revenues: 'Ingresos',
      saas: 'SaaS',
      marketplaces: 'Marketplaces & eCommerce',
      manufacturing: 'Manufacturing (Physical Products)',
      launchYear: 'Launch year',
      growthStage: 'Growth stage',
      clientFocus: 'Client focus',
      earlyGrowth: 'Early growth',
      lateGrowth: 'Late growth',
      mature: 'Mature',
      shareWithFounder: 'Share with the founder',
      inviteFounder: 'Invite founder',
      invitationManageStartup: 'Invitation to manage the startup',
      invitationManageStartupMessage: 'You have been invited to manage the startup %STARTUP%',
      logout: 'Logout',
    },
    mainpage: {
      wellcome: 'Welcome',
    },
    mentoring: {
      title: 'Mentoring', // Mentoring
      mentoring: 'Mentoring',
      mentorings: 'Mentorings',
      startups: 'Startups',
      mentors: 'Mentors',
      yourMentorings: 'Your mentorings',
      searchMentors: 'Search mentors',
      searchStartups: 'Search startups',
      finished: 'Finished',
      pending: 'Invitations',
      seekingMentors: 'Find mentors',
      seekingStartups: 'Find startups',
      startMentoringsStartups: 'Start sending requests to startups to begin your mentoring process.',
      startMentoringsMentors: 'Start sending requests to mentors to begin your mentoring process.',
      consumerHours: 'Consumer Hours',
      nextMeeting: 'Next Meeting',
      lastInteraction: 'Last interaction',
      knowledgeSectors: 'Knowledge sectors',
      confirm: 'Confirm',
      accept: 'Accept',
      accepted: 'Accepted',
      reject: 'Reject',
      rejected: 'Rejected',
      enter: 'Enter',
      moreInfo: 'Info',
      invite: 'Invite',
      cancel: 'Cancel',
      canceled: 'Canceled',
      soon: 'Coming soon',
      letsGo: "Let's go!",
      invitationSent: 'Your request has been sent',
      emptyInviteMessage: 'You must write a message',
      whatCanYouContribute: 'What can you contribute to a startup?',
      howCanTheMentorHelpYou: 'How can the mentor help you?',
      rejectMentoring: 'Reject mentoring',
      confirms: {
        cancel: 'Are you sure you want to cancel the mentoring?',
        accepted: 'Are you sure you want to accept the mentoring?',
        rejected: 'Are you sure you want to reject the mentoring?',
      },
      explainTheReasonTo: 'Explain the reason to',
      invitationMessage: 'Invitation message:',
      rejectionReason: 'Rejection reason:',
      lessInfo: 'Less Info',
      closeInvite: 'Close Invite',
      filter: 'Filter',
      recommended: 'Recommended',
      noResults: 'No results found',
      areasOfExpertise: 'Areas of expertise',
      preferredLanguage: 'Preferred language',
      businessType: 'Business type',
      socialImpact: 'Social impact',
      startupCountry: 'Startup country',
      startupDevelopmentStage: 'Startup development stage',
      sectors: 'Sectors',
      companyAreas: 'Startup areas',
      resetFilters: 'Reset Filters',
      applyFilters: 'Apply Filters',
      appliedFilters: 'Applied Filters',
      select: 'Select',
      search: 'Search',
      next: 'Next',
      events: 'Events',
      messages: 'Messages',
      documents: 'Documents',
      reports: 'Reports',
      addMessage: 'Add message',
      createMeeting: 'Register event',
      meetingTime: 'Consumer hours',
      editMeeting: 'Edit event',
      uploadDocument: 'Upload document',
      emptyDocuments: 'No documents',
      createReport: 'Create new report',
      editReport: 'Edit report',
      emptyReports: 'No reports',
      finish: 'Finish mentoring',
      giveFeedback: 'Give feedback',
      feedbackSent: 'Feedback sent',
      finishButtonTitle: 'End mentoring and give feedback',
      startedBy: 'Started by',
      feedbackForm: {
        title: 'Mentoring feedback',
        titleEnd: 'End mentoring',
        description: '',
        descriptionEnd: '',
        reason: 'Which option do you want to do?',
        cancel: 'Cancel the mentoring (not completed/finished)',
        cancelReason: 'Why?',
        finish: 'Finish the mentoring (mentoring completed)',
        finishComment: 'How was it?',
        score: 'Rate the mentoring (5 stars is the best)',
        send: 'Send',
      },
      reportForm: {
        popupTitle: 'Register report',
        popupTitleEdit: 'Edit report',
        popupDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac dui convallis, suscipit quam sed, ornare felis. Mauris convallis ipsum ac iaculis lacinia. Quisqu',
        text: 'Describe about your current vision of the project',
        send: 'Send',
      },
      mentoringStatus: 'Mentoring status',
      status: {
        status: 'Status',
        accepted: 'Accepted',
        finished: 'Finished',
        pending: 'Pending',
        rejected: 'Rejected',
        finish: 'Finished',
        cancel: 'Canceled',
      },
      infoPopUp: {
        info: 'Mentoring information',
        status: 'Status',
        creationDate: 'Creation date',
        finishDate: 'Finish Date',
        creator: 'Creator',
        guest: 'Guest',
        invitationMessage: 'Invitation message',
        rejectionReason: 'Rejection reason',
      },
      edit: 'Edit',
      delete: 'Delete',
      deleteReport: 'Are you sure?',
      numberMessages: 'Messages sent',
      numberEvents: 'Events created',
      numberDocuments: 'Documents uploaded',
      numberReports: 'Number of reports',
      mentorNotVisible: 'You have configured in your profile that you cannot receive mentoring request',
    },
    shareholder: {
      modify: 'Modify',
      deleteShareholder: 'Delete shareholder?',
      shareholders: 'Shareholders',
      cannotDeleteShareholder: 'You can\'t delete a shareholder who is involved in operations',
      shareholdersCreated: 'shareholders created',
      downloadTemplate: 'Download template',
      bulkShareholders: 'Bulk shareholders',
      inviteUser: 'Invite user',
      unbindSyndicate: 'Unbind syndicate',
      unbindSyndicateWarning: 'Are you sure you want to unbind the syndicate?',
      bindingSyndicateError: 'An error occurred while binding/unbinding the syndicate',
      bindingError: 'An error occurred while binding/unbinding the user.',
      email: 'Email',
      name: 'Name',
      lastname: 'Last name',
      permissions: 'Permissions',
      errorEmptyPerms: 'You haven\'t selected permissions',
      lang: 'Language',
      languages: {
        es: 'Spanish',
        en: 'English',
        it: 'Italian',
      },
      createUser: 'Create user',
      bindUser: 'Bind user',
      unbindUser: 'Unbind user',
      shareholderHaveShares: 'If the shareholder has shares, the user will have permissions on:',
      summary: 'Home',
      summaryDescription: 'Overview of all tools',
      myPosition: 'My position',
      myPositionDescription: 'Information about your investment in the Startup',
      capTable: 'Cap table',
      capTableDescription: 'Capitalization table',
      capTableSummary: 'Summary',
      capTableSummaryDescription: 'View captable summary details',
      capTableSecurities: 'Securities',
      capTableSecuritiesDescription: 'View capitalization table values',
      capTableOperations: 'Operations',
      capTableOperationsDescription: 'See detail of all confirmed operations',
      performance: 'Performance',
      performanceDescription: 'View all key performance indicators',
      reporting: 'Reporting',
      reportingDescription: 'Access to all reports you have received',
      dataRoom: 'Documents',
      dataRoomDescription: 'Access to all documents except those of other investors',
      syndicates: 'Syndicates',
      syndicate: 'Sindicato',
      infoSyndicate: 'Information syndicate',
      createSyndicate: 'Create syndicate',
      editSyndicate: 'Edit syndicate',
      unifyShareholders: 'Unify shareholders',
      confirmUnifyShareholders: 'Are you sure you want to unify the %SHAREHOLDERS% investors into the %SHAREHOLDER% investor?',
      founder: 'Founder',
      director: 'Director',
      boardMember: 'Board Member',
      investor: 'Investor',
      employee: 'Employee',
      secretary: 'Secretary',
      advisor: 'Advisor',
      bank: 'Bank',
      corporate: 'Corporate',
      angelInvestor: 'Angel investor',
      privateEquity: 'Private equity',
      ventureCapital: 'Venture capital',
      friendsAndFamily: 'Friends and family',
      corporateVentureCapital: 'Corporate venture capital',
      other: 'Other',
      downloadExcel: 'Download excel',
      formShareholder: 'Form shareholders',
      infoShareholder: 'Shareholder information',
      types: 'Types',
      role: 'Role',
      individual: 'Individual',
      organization: 'Organization',
      firstname: 'Name',
      phone: 'Phone',
      address: 'Address',
      country: 'Country',
      taxid: 'TAXID',
      save: 'Save',
      shares: 'Shares',
      debts: 'Debts',
      options: 'Options',
      pps: 'PPS',
      numeration: 'Numeration',
      shareClass: 'Share class',
      capital: 'Capital',
      contributedCapital: 'Contributed capital',
      principal: 'Principal',
      capitalized: 'Capitalized',
      repaid: 'Repaid',
      interestRate: 'Interest',
      accrualFrequency: 'Accrual',
      issueDate: 'Issue Date',
      startDate: 'Start date',
      expirationDate: 'Expiration date',
      number: 'Number',
      assigned: 'Assigned',
      vested: 'Vested',
      exercised: 'Exercised',
      pending: 'Pending',
      vestingPlan: 'Vesting Plan',
      vestingDate: 'Vesting Date',
      phantom: 'Phantom shares',
      stockOption: 'Stock options',
      since: 'Since',
      partner: 'Partner',
      transactions: 'Transactions',
      users: 'Users',
      documents: 'Documents',
      captable: 'Cap Table',
      shareholder: 'Shareholder',
      ownership: 'Ownership',
      fullyDiluted: 'Fully diluted',
      historicalOperations: 'Historical Operations',
      usersLinked: 'Users Linked',
      avatar: 'Avatar',
      value: 'Value',
      linkToMyUser: 'Link to my user',
      unbindWarning: 'Are you sure you want to unbind the user?',
      noParticipation: 'The shareholder has no participation in the captable',
      from: 'From',
      to: 'To',
      optionsIn: 'Options in',
      operation: 'Operation',
      vote: 'Vote',
      status: 'Status',
      closed: 'Closed',
      shareDetails: 'Share details',
      debtDetails: 'Debt details',
      optionDetails: 'Option details',
      price: 'Price',
      exercisePrice: 'Exercise price',
      ratio: 'Exercise ratio',
      numberOfShares: 'Number of shares',
      historial: 'Historial',
      paid: 'Paid',
      debt: 'Debt',
      interest: 'Interest',
      addUser: 'Add user',
      invite: 'Invite',
      emptyFilds: 'Fill in all the fields of the new users.',
      errUserCreate: 'Error in the creation of the user with email:',
      investmentEvolution: 'Investment evolution',
      debtsEvolution: 'Debts evolution',
      optionsEvolution: 'Options evolution',
      noSyndicate: 'No syndicate',
      certificate: 'Certificate',
      newUser: 'New user',
      existingUser: 'Existing user',
    },
    vestingsPlans: {
      addVestingPlan: 'Add vestingPlan',
      name: 'Name',
      type: 'Type',
      createdAt: 'Created at',
      questionDelete: 'Delete VestingPlan?',
      questionDeleteError: 'It is not possible to delete a vesting plan with options',
      vestingsPlans: 'VestingsPlans',
      basic: 'Basic',
      time: 'Time',
      milestones: 'Milestones',
      hybrid: 'Hybrid',
      calendar: 'Calendar',
      shareholder: 'Shareholder',
      confirmMilestones: 'Confirm milestones',
      showCalendar: 'Show calendar',
      vestingPlanCalendar: 'Vesting Plan Calendar',
      title: 'Title',
      percentage: 'Percentage',
      addMilestone: 'Add milestone',
      period: 'Period',
      cliff: 'Cliff',
      intervals: 'Intervals',
      day: 'Day',
      month: 'Month',
      year: 'Year',
      start: 'Start',
      startDate: 'Start date',
      exact: 'Exact',
      end: 'End',
      length: 'Length',
      frequency: 'Frequency',
      schedule: 'Schedule',
      addTime: 'Add Time',
      periods: 'Periods',
      overallPercentage: 'Overall percentage',
      save: 'Save',
      formVestingsPlans: 'Form Vesting plan',
      infoVestingsPlans: 'Info Vesting plan',
      capital: 'Capital',
      expirationDate: 'Expirantion date',
      number: 'Number',
      vested: 'Vested',
      exercised: 'Exercised',
      phantom: 'Phantom shares',
      stockOption: 'Stock options',
      modifyWarning: 'Vesting Plan with options. Some fields cannot be modified',
    },
    shareClass: {
      deleteClass: 'Delete class?',
      deleteClassError: 'It is not possible to delete a class with shares',
      name: 'Name',
      code: 'Code',
      type: 'Type',
      modify: 'Modify',
      delete: 'Delete',
      createClass: 'Create class',
      shareClasses: 'Share classes',
      vote: 'Vote',
      unifyShareClasses: 'Unify shareClasses',
      confirmUnifyShareClasses: 'Are you sure you want to unify the %SHARECLASSES% shareclasses into the %SHARECLASS% shareClass?',
      preferred: 'Preferred',
      common: 'Common',
      downloadExcel: 'Download excel',
      save: 'Save',
      yes: 'Yes',
      no: 'No',
      liquidity: 'Liquidity',
      participation: 'Participation',
      dividend: 'Dividend',
      convert: 'Convert',
      ratio: 'Ratio',
      price: 'Price',
      day: 'Day',
      month: 'Month',
      year: 'Year',
      cash: 'Cash',
      stock: 'Stock',
      value: 'Value',
      frequency: 'Frequency',
      compounding: 'Compounding',
      seniority: 'Seniority',
      multiple: 'Multiple',
      cap: 'Cap',
      FormShareClass: 'Form Share Class',
      votesPerShare: 'Votes per share',
      InfoShareClass: 'Info Share Class',
      shares: 'Shares',
      pps: 'PPS',
      numeration: 'Numeration',
      capital: 'Capital',
      phantom: 'Phantom shares',
      stockOption: 'Stock options',
      modifyWarning: 'Class with shares. Please note that any changes to the attributes of the class will have a effect on the captable timeline.',
    },
    meetings: {
      confirmDelete: 'Are you sure?',
      createMeeting: 'Create meeting',
      edit: 'Edit',
      delete: 'Delete',
      meetingTime: 'Duration',
      emptyMeetings: 'No hay ningun meeting',
      meetingForm: {
        popupTitle: 'Register event',
        popupDescription: 'Fill in the information with the details of the event/meeting that you have held or are going to hold and that you have previously agreed with your mentor/mentee so that it is recorded on the platform. Once saved, you will be able to edit/delete the event. WE REMIND YOU THAT THIS IS NOT AN INVITATION TO AN EVENT.',
        title: 'Title',
        description: 'Description',
        date: 'Date',
        hour: 'Hour',
        timezone: 'Timezone',
        time: 'Duration (minutes)',
        send: 'Send',
        submitError: 'Event creation/edit error',
      },
      yourHours: 'Your hours',
      scheduledHours: 'Scheduled hours',
      finishedHours: 'Finished hours',
      donatedHours: 'Donated hours',
    },
    legalConditions: {
      title: 'We have updated our terms',
      subTitle: 'You must accept the new conditions before continuing.',
      actionBtn: 'Continue',
    },
    prehome: {
      selectStartup: 'Select a startup',
      createStartup: 'New startup',
      viewStartup: 'View startup',
      accept: 'Accept',
      reject: 'Reject',
      confirmAccept: 'Are you sure you want to accept the invitation?',
      confirmReject: 'Are you sure you want to reject the invitation?',
      user: 'User',
      mentor: 'Mentor',
      shareholder: 'Shareholder',
      owner: 'Owner',
      other: 'Other',
      admin: 'Admin',
      guest: 'Guest',
      pendingApproval: 'Pending approval',
      myStartups: 'My startups',
      viewMyPosition: 'View my position',
    },
    myInvestments: {
      title: 'My position',
      portfolio: 'Portfolio',
      analysis: 'Analysis',
      opportunities: 'Opportunities',
      base: 'Base',
      currencies: 'Currencies',
      investments: 'Investments',
      totalInvested: 'Total Invested',
      totalValue: 'Total value',
      multiplo: 'Multiplo',
      country: 'Country',
      currency: 'Currency',
      industry: 'Industry',
      currentPortfolio: 'Current portfolio',
      divestedPortfolio: 'Divested portfolio',
      ownership: 'Ownership',
      cEquity: 'C. Equity',
      cDebt: 'C. Debt',
      options: 'Options',
      votes: 'Vote',
      noExchangeNecessary: 'The currencies of your investments are the same as the selected',
      noResults: 'You are not shareholder in any startup',
      shares: 'Acciones',
      kpis: 'KPIs',
      shareholder: 'Shareholder',
      knowMore: 'Know more',
      round: 'Round',
      referredBy: 'Referred by',
      'pre-seed': 'PRE-SEED',
      seed: 'SEED',
      early: 'EARLY',
      growth: 'GROWTH',
      expansion: 'EXPANSION',
      entryDate: 'Entry date',
      comparator: 'Comparator',
      numberOfShares: 'Number of shares',
    },
    input: {
      uploadFileError: 'File failed to upload',
      uploadFilezone: 'Click here to upload file',
      uploadFile: 'Upload file',
      noOptions: 'No options found',
      enterToAdd: 'Press ↵ to add',
      noneAbove: 'None of the above',
      selected: 'Selected',
      of: 'of',
      private: 'Private',
      share: 'Share',
      sharedWith: 'Shared with',
      users: 'users',
    },
    outputErrors: {
      required_error: 'The field is required',
      requiredwith_error: 'The field is required for this condition',
      requiredunless_error: 'The field is required',
      max_error: 'The field exceeds the maximum',
      unauthorized_error: 'You do not have permission to perform this action',
      unauthorized: 'You do not have permission to perform this action',
      internal: 'An unexpected error has occurred',
    },
    home: {
      summary: 'Summary',
      companyDetails: 'Startup Details',
      editCompany: 'Edit profile',
      viewCompany: 'View profile',
      name: 'Name',
      lastname: 'Last Name',
      founders: 'Founders',
      operatingStatus: 'Operating Status',
      active: 'Active',
      foundedDate: 'Founded Date',
      lastFundingType: 'Last Funding Type',
      headQuartersRegion: 'Headquarters Region',
      description: 'Description',
      mentors: 'Mentors',
      mentorsSubtitle: 'These are the active mentorships of your startup',
      noMentorings: 'You don\'t have any active mentoring',
      noMeetings: 'You don\'t have scheduled meetings',
      mentorDetails: 'Mentor details',
      mentorMoreHours: 'You have exceeded the number of hours you indicated you were going to donate, if you want to continue registering meetings you need to increase them.',
      mentorMoreHoursSoon: 'Soon you will exceed the number of hours you indicated you were going to donate, if you want to continue to register meetings you need to increase them.',
      mentorMoreHoursProfile: 'Go to profile',
      mentorMoreHoursCancel: 'Cancel',
      editProfile: 'Edit profile',
      mentoredCompanies: 'Mentored startups',
      donatedHours: 'Donated hours',
      donatedHoursLeft: 'Donated hours remaining / total',
      events: 'Events',
      nextMeetings: 'Next meetings',
      mentoringsEvents: 'Events mentorings',
      mentoringsList: 'Active mentorings',
      companyName: 'Company',
      consumerHours: 'Consumer Hours:',
      nextMeeting: 'Next Meeting:',
      lastComments: 'Last Comments',
      readMore: 'Read More',
      documents: 'Documents',
      lastDocuments: 'Last documents',
      lastRound: 'Last round',
      totalFunding: 'Total funding',
      preMoneyValuation: 'Pre-money valuation',
      postMoneyValuation: 'Post-money valuation',
      investorUpdate: 'Investor update',
      kpis: 'KPIs',
      country: 'Country',
      headline: 'Headline',
      sectors: 'Sectors',
      areasOfExpertise: 'Areas of expertise',
      mentoringsEmpty: 'No active mentorings',
      documentsEmpty: 'No documents',
      eventsEmpty: 'No upcoming events',
      value: 'Value',
      targetValue: 'Target Value',
      previousValue: 'Previous Value',
      lastUpdate: 'Last Update',
      noTools: 'You don\'t have access to any tools, contact the owner',
      startupGuestInfo: 'You are accessing the startup as a guest. ',
      requestAccess: 'Request access',
      addToPrehome: 'Add to prehome',
      removeFromPrehome: 'Remove from prehome',
      pendingApprovalRequest: 'Your request is pending approval.',
      requestSubmittedSuccessfully: 'request submitted successfully',
    },
    homeAdmin: {
      accepted: 'Accepted',
      pending: 'Pending',
      rejected: 'Rejected',
      finished: 'Finished',
      mentorings: 'Mentorings',
      meetings: 'Meetings',
      documents: 'Documents',
      startups: 'Startups',
      mentors: 'Mentors',
      groups: 'Groups',
      group1: 'Group 1',
      group2: 'Group 2',
      group3: 'Group 3',
      users: 'Users',
      entrepreneurs: 'Entrepreneurs',
      mentoringStatus: 'Mentorings status',
      lastMonthActivity: 'Last month activity',
      donatedHours: 'Donated hours',
      mentorsMentoring: 'Mentors mentorizing',
      startupMentored: 'Startps mentored',
      meetingsFinished: 'Meetings held',
      meetingsScheduled: 'Meetings scheduled',
      totalHours: 'Total / Number of hours',
      uploadedToMentorings: 'Uploaded to mentorings',
      captable: 'Captable',
      operations: 'Operations',
      shareholders: 'Shareholders',
      shareholdersWithAccess: 'Shareholders with access',
      startupsWithCaptable: 'Startups with captable',
      syndicates: 'Syndicates',
      waterfalls: 'Waterfalls',
      performance: 'Performance',
      values: 'Values',
      createdKpis: 'Created kpis',
      usedKpis: 'Used kpis',
      valuesPerDay: 'Added values ​​per day',
      dataroom: 'Documents',
      folders: 'Folders',
      reports: 'Reports',
      emails: 'Emails',
    },
    editInfo: {
      viewProfile: 'View profile',
      editProfile: 'Edit profile',
      companyData: 'Startup data',
      profileUpdated: 'Profile updated',
      submit: 'Submit',
      cancel: 'Cancel',
      save: 'Save',
    },
    editAccount: {
      home: 'Home',
      editAccount: 'Edit Account',
      accountData: 'Account data',
      avatar: 'Avatar',
      name: 'Name',
      lastname: 'Last Name',
      email: 'Email',
      submit: 'Submit',
      cancel: 'Cancel',
      password: 'Password',
      oldPassword: 'Actual password',
      newPassword: 'New password',
      newPasswordRepeat: 'Repeat new password',
      passwordNotMatch: 'Repeat password not match',
      accountSaved: 'Profile saved correctly',
      language: 'Language',
      role: 'Role',
      languages: {
        es: 'Spanish',
        en: 'English',
        it: 'Italian',
      },
      roles: {
        admin: 'Admin',
        user: 'User',
        mentor: 'Mentor',
      },
      profile: 'Profile',
      numericFormat: 'Numeric Format',
      numericFormats: {
        decimalsComma: 'Thousands separated by periods, decimals separated by commas (1.000.000,00)',
        decimalsPeriod: 'Thousands separated by commas, decimals separated by periods (1,000,000.00)',
      },
    },
    table: {
      search: 'Search',
      filters: 'Filters',
      noResults: 'No results found',
      cleanFilters: 'Clean',
      applyFilters: 'Apply',
      actions: 'Actions',
      rowsAction: 'Rows action',
      selectPage: 'Select page',
      selectAll: 'Select all',
      unselectAll: 'Unselect all',
      showingTableResults: 'Showing %FIRST% to %LAST% of %TOTAL% results',
      goTo: 'Go to',
      configColumns: 'Config columns',
      columns: 'Columns',
      edited: 'Edited',
      confirm: 'Confirm',
      reset: 'Reset',
      errorFetchingGeneric: 'An error occurred while fetching the data',
      errorFetchingUnauthorized: 'You do not have permission to perform this action',
    },
    admin: {
      platform: {
        title: 'Plataform settings',
        generals: 'General',
        email: 'Emails',
        forms: 'Forms',
        legal: 'Legal',
        id: 'Id',
        text: 'Title',
        type: 'Type',
        mandatory: 'mandatory',
        afirmative: 'Yes',
        negative: 'No',
        answers: 'Answers',
        startupCoreQuestions: 'Startup core questions',
        mentorCoreQuestions: 'Mentor core questions',
        fundCoreQuestions: 'Fund core questions',
        types: {
          text: 'Text',
          textArea: 'Text area',
          select: 'Select',
          radiobutton: 'Unique option',
          checkbox: 'Check option',
          list: 'List',
          document: 'Document',
          date: 'Date',
          number: 'Number',
        },
        lang: 'Language',
        order: 'Order',
        answer: 'Answer',
        public: 'Public',
        filter: 'Filter',
        theField: 'The field',
        theAnswer: 'The answer',
        inLanguage: 'in the language',
        isEmpty: 'is empty',
        confirmSave: 'Are you sure you want to save the question?',
        emails: {
          fromName: 'Email from name',
          fromAdress: 'Email from address',
          mainTemplate: 'Base template',
          supportAddress: 'Support form receipt email',
          servicesAddress: 'Service requests receipt email',
          webhooksAddress: 'Webhook requests receipt emails',
          emailBot: 'Email bot',
          emailBotPassword: 'Email bot password',
          subject: 'Subject',
          sender: 'Sender',
          body: 'Body',
          languages: {
            es: 'Spanish',
            en: 'English',
            it: 'Italian',
          },
          templates: {
            inviteToStartupExist: {
              title: 'User invitation to statup (already registered user)',
              description: '',
            },
            inviteToStartupNew: {
              title: 'User invitation to statup',
              description: '',
            },
            mentoringAccepted: {
              title: 'Accepted mentoring',
              description: '',
            },
            mentoringDocument: {
              title: 'New document in a mentoring',
              description: '',
            },
            mentoringFinished: {
              title: 'Finished metoring',
              description: '',
            },
            mentoringInviteMentor: {
              title: 'Invitation to mentoring (startup to mentor)',
              description: '',
            },
            mentoringInviteStartup: {
              title: 'Invitation to mentoring (mentor to startup)',
              description: '',
            },
            mentoringMeeting: {
              title: 'New event in a mentoring',
              description: '',
            },
            mentoringMeetingDelete: {
              title: 'Cancel event in a mentoring',
              description: '',
            },
            mentoringMeetingUpdate: {
              title: 'Update event in a mentoring',
              description: '',
            },
            mentoringMessage: {
              title: 'New message in a mentoring',
              description: '',
            },
            mentoringRejected: {
              title: 'Rejected Mentoring',
              description: '',
            },
            notificationsSummary: {
              title: 'Unread notification notice',
              description: '',
            },
            passwordChanged: {
              title: 'Password changed',
              description: '',
            },
            recoveryPassword: {
              title: 'Recover password',
              description: '',
            },
            welcomeAdmin: {
              title: 'Register administrator',
              description: '',
            },
            welcomeMentor: {
              title: 'Register mentor',
              description: '',
            },
            welcomeUser: {
              title: 'Register user',
              description: '',
            },
            secondFactor: {
              title: 'Second factor authentication',
              description: '',
            },
            fundraiseInvite: {
              title: 'Invitation to fundraise',
              description: '',
            },
            startupAccessRequest: {
              title: 'Startup access request',
              description: '',
            },
            startupAccessRequestAccepted: {
              title: 'Startup access request accepted',
              description: '',
            },
            startupAccessRequestRejected: {
              title: 'Startup access request rejected',
              description: '',
            },
            startupInviteAccepted: {
              title: 'Startup invite accepted',
              description: '',
            },
            startupInviteRejected: {
              title: 'Startup invite rejected',
              description: '',
            },
            startupInviteFounder: {
              title: 'Invite founder to manage',
              description: '',
            },
            newElementPermission: {
              title: 'Nuevo permiso sobre elemento',
              description: '',
            },
            confirmInitializedCaptable: {
              title: 'Confirm initialized captable',
              description: '',
            },
          },
          mergetags: { // Añadir descripciones
            CONTENT: 'This is the description',
            FIRSTNAME: 'This is the description',
            LASTNAME: 'This is the description',
            EMAIL: 'This is the description',
            FROM_FIRSTNAME: 'This is the description',
            FROM_LASTNAME: 'This is the description',
            TOKEN: 'This is the description',
            STARTUP_NAME: 'This is the description',
            MENTOR_NAME: 'This is the description',
            FROM_NAME: 'This is the description',
            MEETING_TITLE: 'This is the description',
            MEETING_DESCRIPTION: 'This is the description',
            MEETING_DATE: 'This is the description',
            MEETING_TIME: 'This is the description',
            MEETING_OLD_DATE: 'This is the description',
            MEETING_OLD_TIME: 'This is the description',
            DOCUMENT_NAME: 'This is the description',
            MESSAGE: 'This is the description',
            NOTIFICATIONS: 'This is the description',
            FUNDRAISE: 'This is the description',
            INVITE: 'This is the description',
            CAPTABLE_NAME: 'This is the description',
            CAPTABLE_ID: 'This is the description',
            STARTUP_ID: 'This is the description',
          },
        },
        name: 'Name',
        logo: 'Logo',
        favicon: 'Favicon',
        bgPublicDesktop: 'Background public view (desktop)',
        bgPublicMobile: 'Background public view (mobile)',
        mainColor: 'Main color',
        googleAnalytics: 'GA measurement ID',
        googleAnalyticsProperty: 'GA property ID',
        googleAnalyticsCredentials: 'GA credentials (JSON)',
        configSignups: 'Forms signup configuration',
        configTools: 'Active tools',
        tools: {
          capTable: 'Cap Table',
          performance: 'KPIs',
          dataRoom: 'Documents',
          profile: 'Profile',
          mentoring: 'Mentorings',
          reporting: 'Reporting',
          users: 'Users manager',
          messages: 'Messages',
          services: 'Services',
          fundraise: 'Fundraise',
        },
        jsonLiterals: 'JSON literals languages (example: {"en":{"common":{"add":"Add"}},"es":{"common":{"add":"Añadir"}}}',
        signupExternal: 'Signup public',
        signupInternal: 'Startup signup on prehome',
        save: 'Save',
        send: 'Send',
        permissions: {
          current: 'Current (Do not modify)',
          noPermission: 'No permissions',
          see: 'See',
          edit: 'Edit',
          manage: 'Manage',
        },
        owner: 'Owner',
        user: 'User ',
        shareholder: 'Shareholder',
        log: 'Log',
        seeLogs: 'See the logs',
        botEmails: 'Emails received by the bot',
        emailDetails: 'Email details',
        signaturitKey: 'Signaturit Key',
        roles: {
          owner: 'Owners',
          admin: 'Admins',
          user: 'Users',
          shareholder: 'Shareholders',
          startup: 'All users',
          other: 'Others',
        },
      },
      analytics: {
        views: 'Visitas',
        sessions: 'Sesiones',
        users: 'Usuarios',
        averageSession: 'Media sesión',
        chartsPerDay: 'Charts per day',
        mostVisitedStartups: 'Most visited startups',
        mostVisitedPages: 'Most visited pages',
        mostUsedTools: 'Herramientas mas usadas',
        mostActiveUsers: 'Most active users',
        startup: 'Startup',
        tool: 'Tool',
      },
      support: {
        title: 'Help Comments',
        firstname: 'Firstname',
        lastname: 'Lastname',
        message: 'Message',
        date: 'Date',
        question: 'Question',
        user: 'User',
      },
      scripts: {
        title: 'Scripts log',
        cronLogs: 'Cron jobs log',
        method: 'Method',
        input: 'Input',
        output: 'Output',
        runtime: 'Runtime',
        scriptDetail: 'Script details',
      },
    },
    startupUsers: {
      userDatabase: 'User Database',
      inviteUser: 'Add member',
      email: 'Email',
      name: 'Name',
      lastname: 'Last name',
      role: 'Role',
      avatar: 'Avatar',
      type: 'Type',
      permissions: 'Permissions',
      permissionsByType: 'Permissions according to user type',
      capTable: 'Cap Table',
      performance: 'KPIs',
      dataRoom: 'Documents',
      profile: 'Profile',
      mentoring: 'Mentoring',
      reporting: 'Reporting',
      users: 'Users',
      admin: 'Admin',
      user: 'User',
      shareholder: 'Shareholder',
      groups: 'Groups',
      messages: 'Messages',
      noPermission: 'No permission',
      none: 'None',
      errorEmptyPerms: 'You haven\'t selected permissions',
      see: 'See',
      edit: 'Edit',
      manage: 'Manage',
      lang: 'Language',
      types: {
        owner: 'Owner',
        admin: 'Admin',
        user: 'User',
        shareholder: 'Shareholder',
        ESOPs: 'ESOPs',
        other: 'Other',
      },
      languages: {
        es: 'Spanish',
        en: 'English',
        it: 'Italian',
      },
      inviteUserSuccess: 'User invited correctly, you will soon receive an email to complete the invitation. You can see its status in the pending tab, and once you accept it we will notify you.',
      acceptUserSuccess: 'User accepted correctly',
      removeUser: 'Remove User',
      questionRemoveUser: 'Are you sure you want to delete the user?',
      questionCancelInviteUser: 'Are you sure you want to cancel the user invitation?',
      questionRejectUser: 'Are you sure you want to reject the user request?',
      editPermissions: 'Edit permissions',
      leaveStartup: 'Leave startup',
      leaveStartupQuestion: 'Are you sure you want to leave the startup?',
      remove: 'Remove',
      send: 'Send',
      pending: 'Pending',
      guests: 'Guests',
      admins: 'Admins',
      shareholders: 'Shareholders',
      pendings: 'Pendings',
      invite: 'Invite',
      request: 'Request',
      confirmAccept: 'Are you sure you want to accept the invitation?',
      confirmReject: 'Are you sure you want to reject the invitation?',
      confirm: 'Confirm',
      user_already_exist: 'This user is already part of the startup',
    },
    adminUsers: {
      createUser: 'Create user',
      users: 'Users',
      invite: 'Invite',
      send: 'Send',
      avatar: 'Avatar',
      fullname: 'Fullname',
      email: 'Email',
      role: 'Role',
      date: 'Date',
      group: 'Group',
      lastAccess: 'Last access',
      lastModification: 'Last modification',
      extra: 'Other information (JSON)',
      view: 'View',
      delete: 'Delete',
      exportExcel: 'Download the template',
      andUpload: 'and upload it',
      successCreate: 'Successfully created %SUCCESS% users',
      successCreateOne: 'Successfully created %SUCCESS% user',
      row: 'Row',
      rowErrors: 'Errors in the next rows',
      requiredField: 'The %FIELD% field is required',
      existUserErr: 'The user already exist',
    },
    adminStartups: {
      startups: 'Startups',
      startupName: 'Startup name',
      group: 'Group',
      description: 'Description',
      name: 'Name',
      lastname: 'Last name',
      email: 'Email',
      language: 'Language',
      exportExcel: 'Download the template',
      andUpload: 'and upload it',
      validateDuplicatesName: 'What to do with duplicates?',
      nothing: 'Nothing',
      edit: 'Edit',
      duplicate: 'Duplicate',
      sendEmail: 'Send email',
      send: 'Send',
      no: 'No',
      yes: 'Yes',
      invite: 'Invite',
      successCreate: 'Successfully created %SUCCESS% startups',
      successCreateOne: 'Successfully created %SUCCESS% startup',
      row: 'Row',
      rowErrors: 'Errors in the next rows',
      emptyName: 'The NAME field is required',
      verified: 'Verified',
      phantoms: 'Phantoms',
      markAsPhantom: 'Mark as phantom',
      unmarkAsPhantom: 'Unmark as phantom',
      mergeStartups: 'Merge startups',
      mergeStartupsInfo: 'Select the main startup',
      confirmMergeStartups: 'Are you sure you want to unify this %STARTUPS% startups into the %STARTUP% startup?',
      startupPlan: {
        choosePlan: 'Choose plan',
        chooseStartupPlan: 'Choose a plan for the startup',
        free: 'Free',
        freeDescription: 'Free plan',
        basic: 'Basic',
        basicDescription: 'Basic plan',
        premium: 'Premium',
        premiumDescription: 'Premium plan',
      },
      startupVerify: {
        verify: 'Verify',
        verified: 'Verified',
        editVerified: 'Edit verified',
        chooseStartupVerify: 'Choose the verification level for the startup',
        notVerified: 'Not verified',
        notVerifiedDescription: 'The startup is not verified',
        team: 'Team',
        full: 'Full',
      },
    },
    help: {
      help: 'Help',
      home: 'Home',
      submit: 'Submit',
      cancel: 'Cancel',
      form: 'Write us a message',
      sent: 'Sent',
    },
    reporting: {
      title: 'Reporting',
      newReport: 'New Report',
      reportDetail: 'Report detail',
      add: 'Add',
      subject: 'Subject',
      recipients: 'Recipients',
      message: 'Message',
      attachDocuments: 'Attach documents',
      attached: 'Attached documents',
      uploadedDate: 'Uploaded date',
      uploadedBy: 'Uploaded by',
      details: 'Details',
      emails: 'Emails',
      search: 'Search',
      upload: 'Upload',
      lastName: 'LastName',
      preview: 'Preview',
      sendTest: 'Send test',
      test: 'Test',
      testSent: 'Test report sent',
      noAttachments: 'No attachments yet',
      lastReports: 'Last Reports',
      noReports: 'No report has been sent yet',
      templates: 'Templates',
      template: 'Template',
      newTemplate: 'New template',
      editTemplate: 'Edit template',
      blankTemplate: 'Blank template',
      noImage: 'No image',
      language: 'Language',
      questionDeleteTemplate: 'Are you sure you want to remove the template?',
      fieldEmpty: 'The field is empty',
      draft: 'Draft',
      documents: 'Documents',
      email: 'Email',
      document: 'Document',
      downloadPdf: 'Download PDF',
      reportingNotFound: 'Reporting not found',
      reportTemplateNotFound: 'Report template not found',
      name: 'Name',
      content: 'Content',
      save: 'Save',
      or: 'or',
      scope: 'Scope',
      owner: 'Owner',
    },
    services: {
      title: 'Services',
      requests: 'Requests',
      service: 'Service',
      request: 'Request',
      createService: 'Create service',
      editService: 'Edit service',
      createRequest: 'Create request',
      editRequest: 'Edit request',
      serviceInfo: 'Service information',
      company: 'Company',
      shortDescription: 'Short description',
      detailedDescription: 'Detailed description',
      questionRemoveService: 'Are you sure you want to delete the service?',
      questionRemoveRequest: 'Are you sure you want to delete the request?',
      questionContactManager: 'Do you want a manager to get in touch with you?',
      confirmAnswer: 'Thank you, we will contact you shortly',
      cancelRequestAnswer: 'The request has been successfully canceled',
      visible: 'Visible',
      status: 'Status',
      lead: 'Lead',
      appointment: 'Appointmennt',
      pending: 'Pending',
      rejected: 'Rejected',
      done: 'Done',
      requestInfo: 'Request information',
      creationDate: 'Creation date',
      internalNotes: 'Internal notes (not visible to the customer)',
      details: 'Details',
      message: 'Menssage',
      internalService: 'Internal service',
      externalService: 'External service',
      import_captable: 'Import captable',
      upload_captable: 'Upload captable',
      request_startup_access: 'Startup access',
    },
    webhooks: {
      title: 'Webhooks',
      captable_operation: 'Captable operation',
      sent: 'Sent',
      success: 'Success',
      error: 'Error',
      sentTo: 'Sent to',
      webhookDetails: 'Webhook details',
      data: 'Data',
      response: 'Response',
    },
    myPosition: {
      title: 'My position',
      shares: 'Shares',
      debts: 'Debts',
      options: 'Options',
      value: 'Value',
      ownership: 'Ownership',
      fullyDiluted: 'Fully diluted',
      entryDate: 'Entry date',
      entryValuation: 'Entry valuation',
      numeration: 'Numeration',
      capital: 'Capital',
      principal: 'Principal',
      pending: 'Pending',
      interest: 'Interest',
      from: 'From',
      to: 'To',
      number: 'Number',
      assigned: 'Assigned',
      vested: 'Vested',
      exercised: 'Exercised',
      noParticipation: 'The shareholder has no participation in the captable',
      shareholder: 'Shareholder',
      shareholders: 'Shareholders',
      investmentEvolution: 'Investment evolution',
      debtsEvolution: 'Debts evolution',
      optionsEvolution: 'Options evolution',
      operation: 'Operation',
      shareClass: 'Share class',
      contributedCapital: 'Contributed capital',
      pps: 'PPS',
      vote: 'Vote',
      status: {
        status: 'Status',
        pending: 'Pending',
        closed: 'Closed',
      },
      accrualFrequency: 'Accrual',
      startDate: 'Start date',
      expirationDate: 'Expiration date',
      capitalized: 'Capitalized',
      repaid: 'Repaid',
      interestRate: 'Interest',
      exercisePrice: 'Exercise price',
      ratio: 'Exercise ratio',
      vestingPlan: 'Vesting plan',
      shareDetails: 'Share details',
      debtDetails: 'Debt details',
      optionDetails: 'Option details',
      numberOfShares: 'Number of shares',
      myShares: 'My shares',
      myDebts: 'My debts',
      myOptions: 'My options',
      convertibles: 'Convertibles',
      totalValue: 'Total value',
      latestCompanyValuation: 'Latest company valuation',
      totalPotentialValue: 'Total potential value',
      positionOverview: 'Position overview',
      totalInvested: 'Total invested',
      multiplo: 'Multiple',
      postMoney: 'Post-money',
      valuation: 'Valuation',
      equityGrowth: 'Equity growth',
      vestedOptions: 'Vested options',
      pendingOptions: 'Pending options',
      totalOptions: 'Total options',
      milestones: 'Milestones',
      calendar: 'Calendar',
      simulation: 'Simulation',
    },
    emailStats: {
      email: 'Email',
      status: 'Status',
      registered: 'Registered',
      processed: 'Processed',
      delivered: 'Delivered',
      open: 'Opened',
      click: 'Clicked',
      error: 'Error',
      reporting: 'Reports',
      attached: 'Attached',
      sendingDate: 'Sending Date',
      statusInfo: 'Status information',
      timeline: 'Timeline',
      errorReason: 'Error reason',
      link: 'Link',
      response: 'Response',
    },
    dataRoom: {
      dataRoom: 'Documents',
      download: 'Download',
      open: 'Open',
      root: 'Root',
      file: 'File',
      files: 'Files',
      date: 'Date',
      creator: 'Creator',
      viewers: 'Viewers',
      seenBy: 'Seen by',
      see: 'See',
      avatar: 'Avatar',
      user: 'User',
      rename: 'Rename',
      upload: 'Upload content',
      fileName: 'File name',
      fileDescription: 'File description',
      link: 'Link',
      selectFile: 'Select a file',
      uploadNewVersion: 'Upload new version',
      versions: 'Versions',
      previousVersions: 'Previous versions',
      noPrevVersions: 'There are not previous versions',
      actualVersion: 'Actual version',
      createFolder: 'Create folder',
      createNewFolder: 'Create a new folder',
      renameFolder: 'Rename folder',
      editFolder: 'Edit folder',
      folderName: 'Folder name',
      emptyFolder: 'There is no content in this folder yet',
      noFolderPermissions: 'You do not have permissions to view this folder',
      emptyBookmarks: 'There is no content saved as bookmark',
      noFolderSelected: 'No folder selected yet',
      folders: 'Folders',
      folder: 'Folder',
      content: 'Content',
      viewed: 'Viewed',
      title: 'Title',
      description: 'Description',
      createdBy: 'Created by',
      updatedBy: 'Update por',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      infoFile: 'File information',
      fileViews: 'File views',
      details: 'Details',
      views: 'Views',
      move: 'Move',
      error: 'An error has occurred',
      noPermissions: 'You do not have permissions to perform this action',
      dropInside: 'drop inside',
      folderNotEditable: 'Can\'t edit this folder',
      cantEditDefaultFolders: 'You can\'t edit the default folders',
      defaultFolders: {
        captable: 'Captable',
        captable_operations: 'Operations',
        captable_shareholders: 'Shareholders',
        reporting: 'Reporting',
        fundraise: 'Fundraise',
      },
      search: 'Search',
      actual: 'Actual',
      version: 'Version',
      uploadedBy: 'Uploaded by',
      uploadedAt: 'Uploaded at',
      recents: 'Recents',
      bookmarks: 'Bookmarks',
      today: 'Today',
      lastWeek: 'Last week',
      lastMonth: 'Last month',
      previousContent: 'Previous content',
      confirmDeleteFolder: 'Are you sure you want to delete this folder? All the folders and documents inside will be deleted',
    },
    footer: {
      contact: 'Contact',
      privacy: 'Privacy policy',
      cookies: 'Cookies policy',
      terms: 'Terms & conditions',
    },
    breadcrumb: {
      home: 'Home',
      goBack: 'Go back',
      startup: 'Startup',
      mentorings: 'Mentorings',
      editAccount: 'Edit Account',
      search: 'Search',
      users: 'Users',
    },
    mentoringCard: {
      nextMeeting: 'Next Meeting',
      noNextMeeting: 'Sin planificar',
      lastInteraction: 'Last interaction',
      founderIn: 'Founder in',
    },
    performance: {
      add: 'Add',
      addKpi: 'Add kpi',
      title: 'Performance',
      kpi: 'KPI',
      kpis: 'KPIs',
      startupKpis: 'Kpis de la startups',
      userKpis: 'Kpis creados por ti en otras startups',
      templates: 'Templates',
      createKpi: 'Create Kpi',
      createTemplate: 'Create Template',
      category: 'Category',
      periodicity: 'Periodicity',
      positive: 'Positive',
      scope: 'Scope',
      unit: 'Unit',
      minMax: 'Min|Max',
      notes: 'Notes',
      questionDeleteKpi: '¿Are you sure to delete kpi?',
      questionDeleteTemplate: '¿Are you sure to delete template?',
      questionUnlinkKpi: '¿Are you sure to unlink kpi?',
      questionDeleteKpiValue: '¿Are you sure to delete kpi value?',
      questionConfirmChangeTable: '¿Are you sure to change the table? You will lose the values that you haven\'t saved yet.',
      questionScopeToAll: 'Are you sure you want to change the KPI scope to global? The owner will lose editing permissions.',
      changeScopeToAll: 'Change to global scope',
      daily: 'Daily',
      weekly: 'Weekly',
      biweekly: 'Biweekly',
      monthly: 'Monthly',
      quarterly: 'Quarterly',
      biannual: 'Biannual',
      annual: 'Annual',
      business: 'Business',
      financial: 'Financial',
      esg: 'ESG',
      integer: 'Integer',
      float: 'Float',
      currency: 'Currency',
      percent: 'Porcent',
      up: 'Up',
      down: 'Down',
      equal: 'Equal',
      min: 'Minimum',
      max: 'Maximum',
      infinitePositive: '+∞',
      infiniteNegative: '-∞',
      custom: 'Custom',
      fill: 'Fill',
      noKpis: 'No KPIs',
      addKpis: 'Add KPIs',
      orderKpis: 'Order KPIs',
      fillValues: 'Fill values',
      viewValues: 'View values',
      addNewValue: 'Add new value',
      configuration: 'Configuration',
      autoSave: 'Auto save',
      saving: 'Saving...',
      withoutChanges: 'Without changes',
      dataIncomplete: 'Cells with *: the data is incomplete',
      previous: 'Previous',
      date: 'Date',
      lastUpdate: 'Last update',
      target: 'Target',
      diffTarget: 'Diff. target',
      value: 'Value',
      values: 'Values',
      diffValue: 'Diff. value',
      questionSetTarget: 'Do you want to define the kpi target?',
      questionSetTargetMultiple: 'Do you want to define the target of each kpi?',
      selectTemplate: 'Select one template',
      alreadyAdded: 'Already added',
      noResultsFill: 'No values ​​found to fill',
      noResults: 'No values ​​found',
      noKPIs: 'No KPIs has been added yet',
      lastValue: 'Last value',
      newValue: 'New value',
      pendingKpis: 'You have kpis without updating',
      pending: 'Pending',
      noPending: 'Congratulations, you don\'t have any pending KPIs to update',
      updated: 'Updated',
      bookmark: 'Bookmark',
      unbookmark: 'Unbookmark',
      selectKpi: 'Click here to select KPI',
      select: 'Select',
      kpiValue: 'KPI value',
      editorPlaceholder: 'Type TAB to insert',
      order: 'Order',
      mode: 'Mode',
      chart: 'Chart',
      table: 'Table',
      comparator: 'Comparator',
      emptyKpis: 'Select the kpis to compare',
      downloadExcel: 'Download excel',
      uploadExcel: 'Upload excel',
      actions: 'Actions',
      confirmChanges: 'Are you sure you want to make the changes?',
      successChanges: 'Changes made successfully',
      errorChanges: 'Error making changes',
      newValues: 'New values',
      editedValues: 'Edited values',
      deletedValues: 'Deleted values',
      exportExcel: 'Export the excel',
      uploadChanges: 'and upload it with the modifications you want',
      excelErrors: 'The following errors have been detected, correct them and upload the excel again',
      excelConfirm: 'Below are the changes detected in Excel, review them and confirm the upload',
      kpiValueErr: 'The kpi %KPI% %ERROR% on the date %DATE%',
      errMax: 'has a value above the maximum',
      errMin: 'has a value below the minimum',
      errInteger: 'has to be an integer',
      errNumber: 'has to be a number',
      errKpi: 'Row %ROW%, KPI %KPI% no exist',
      back: 'Back',
      editKpi: 'Edit kpi',
      ajustValues: 'The following kpi values ​​need to be adjusted',
      errors: {
        value_less_than_min: 'will be set to the minimum',
        value_greater_than_max: 'will be set to the maximum',
        value_must_be_an_integer: 'will become an integer',
        invalid_date_format: 'will move to the next date if it is empty or will be removed',
      },
      kpiRelations: 'Kpi relations',
      relationValues: 'Relation historical values',
      forecast: 'Forecast',
      addNotes: 'Add notes',
      removeNotes: 'Remove notes',
      updateKpiValue: 'Update KPI value',
      showValues: 'Show values',
      hideValues: 'Hide values',
      showForecast: 'Show forecast',
      hideForecast: 'Hide forecast',
      card: 'Card',
      representKpi: 'How do you want to represent it?',
      selectAKpi: 'Select a KPI',
      aggregate: 'Aggregate',
      endOfPeriod: 'End of period',
      accumulative: 'Accumulative',
      average: 'Average',
      eop: 'EOP',
      acc: 'ACC',
      avg: 'AVG',
      emailBot: {
        emailBot: 'Email bot',
        updateValues: 'Update values using the bot',
        updateValuesQuestion: 'How can I update my KPI values using the bot?',
        updateValuesDesc: 'To do this, you can send an email containing the following information',
        address: 'Address',
        subject: 'Subject: Update KPIs',
        attachments: 'Attachments: Template filled with the values to add or update',
        downloadTemplate: 'Download template',
        requirement: 'Note: The email must be sent from an account with editing permissions in the "Performance" tool.',
      },
      permissions: 'Permisos',
      lessDateErr: 'The end date must be greater than the start date',
      diffRangeErr: 'The range of months compared must be the same as the base range',
      owner: 'Owner',
    },
    messages: {
      yourMessage: 'Write your message',
      emptyChat: 'No hay mensajes',
      sendMessage: 'Send',
      readMore: 'Read more',
      readLess: 'Read less',
      loadMore: 'Load more',
      loading: 'Loading...',
      answer: 'Reply',
      reply: 'Reply',
      replies: 'Replies',
      comment: 'Comment',
      cancel: 'Cancel',
      showReplies: 'Show replies',
      hideReplies: 'Hide replies',
      unread: 'Unread',
      unreads: 'Unreads',
      uploadError: 'File upload error',
      attachFile: 'Attach file',
      days: 'days',
      hours: 'hours',
      minutes: 'min',
      seconds: 'sec',
      justNow: 'just now',
      new: 'new',
      close: 'Close',
    },
    messagesMenu: {
      startupWall: 'Messages feed',
      startupWallDescription: 'Private startup communications',
    },
    captable: {
      title: 'Cap Table',
      captables: 'Captables',
      createCaptable: 'Crear captable',
      editCaptable: 'Edit captable',
      setupCaptable: 'Set up your cap table',
      operations: 'Operations',
      operation: 'Operation',
      shares: 'Shares',
      debts: 'Debts',
      options: 'Options',
      convertibles: 'Convertibles',
      shareClasses: 'Share classes',
      capital: 'Capital',
      contributedCapital: 'Contributed capital',
      ownership: 'Ownership',
      fullyDiluted: 'Fully diluted',
      fullyDilutedAcronym: 'FD',
      secondary: 'Secondary',
      secondarySale: 'Secondary sale',
      changeClasses: 'Change classes',
      reduce: 'Reduce',
      updateValuation: 'Update valuation company',
      newOperation: 'New operation',
      emptyCaptable: 'There is no operation in the captable',
      noSharesOperation: 'There is no operation with shares in the captable',
      createOperation: 'Create operation',
      createFirstOperation: 'Create first operation',
      publishSomeOperation: 'Publish some operation',
      initCaptable: 'Init captable',
      operationName: 'Operation name',
      editOperation: 'Edit operation',
      pending: 'Pending',
      vested: 'Vested',
      vest: 'Vest',
      exercised: 'Exercised',
      issueDate: 'Issue Date',
      captable: 'Cap Table',
      questionDelete: 'Are you sure?',
      issueDebt: 'issues debt',
      receives: 'receives',
      fromSomeone: 'from',
      toSomeone: 'to',
      change: 'change',
      forValueOf: 'for a value of',
      madeAValuationOf: 'made a valuation of',
      hasBeenA: 'Has been a',
      paid: 'Paid',
      payoutTimeline: 'Paid %total% (%amount% debt + %interests% interests) of the debt %debt%',
      for: 'for',
      of: 'of',
      official: 'Official',
      favorite: 'Favorite',
      valuationDiluted: 'Valuation diluted',
      officialConfirm: 'Are you sure you want to make this the official captable?',
      favoriteConfirm: 'Are you sure you want to make this the favorite captable?',
      deleteCaptableConfirm: 'Are you sure you want to delete this captable?',
      noCaptables: 'No captables',
      issue: {
        shares: 'Issue shares',
        sharesHelp: 'Issues new shares for the company',
        secondaries: 'Secondary sale',
        secondariesHelp: 'Sale of shares between investors',
        changeClasses: 'Change classes',
        changeClassesHelp: 'Change the class of an investor\'s shares',
        reduce: 'Reduce holdings',
        reduceHelp: 'Reducing an investor\'s holdings in the company',
        stockSplit: 'Stock split',
        stockSplitHelp: 'Divide the shares to increase the total number',
        valuation: 'Valuation',
        valuationHelp: 'Records a valuation of the company',
        debt: 'Issue debt',
        debtHelp: 'Issues new convertibles for the company',
        convertDebt: 'Convert debt',
        convertDebtHelp: 'Converts outstanding debt into equity',
        payout: 'Payout',
        payoutHelp: 'Records the partial or total payment of a debt',
        options: 'Issue options',
        issueAssignOptions: 'Issue new options or assign pending',
        optionsHelp: 'Assign a new/existing pool of options for the company',
        vestOptions: 'Vest options',
        vestOptionsHelp: 'Vest options assigned to an investor',
        exerciseOptions: 'Exercise options',
        exerciseOptionsHelp: 'Exercises vested options into equity',
        dividend: 'Dividend Distribution',
        dividendHelp: 'Distribution of dividends among the shareholders',
        operationName: 'Operation name',
      },
      lastOperation: 'Last operation',
      lastConfirmed: 'Last confirmed',
      referenceOperationInfo: 'The reference operation is used in operations to define the starting parameters. For example, the maximum number of shares that each investor can sell in a secondary or the maximum number from which to start distributing. This will be updated each time a new date is selected so that the platform can perform all necessary checks to ensure data consistency.',
      lastIssue: 'Last issue',
      securities: 'Securities',
      shareClass: 'Share class',
      shareClassFrom: 'Share class from',
      shareClassTo: 'Share class to',
      shareCode: 'Code',
      amount: 'Amount',
      left: 'left',
      pps: 'PPS',
      repurchasePrice: 'Repurchase price',
      numeration: 'Numeration',
      numerations: 'Numerations',
      sharesNumeration: 'Shares numeration',
      from: 'From',
      to: 'To',
      shareholders: 'Shareholders',
      issuingShareholder: 'Issuing shareholder',
      shareholder: 'Shareholder',
      debt: 'Debt',
      selectType: 'Select the type',
      selectDebt: 'Select a debt',
      option: 'Option',
      selectOption: 'Select option',
      selectDebtOrOption: 'Select a debt or a option',
      convertsTo: 'Convertible to',
      conversionTriggerAmount: 'Trigger amount',
      valuationCap: 'Valuation cap',
      earlyExitMultiple: 'Early exit multiple',
      discount: 'Discount',
      interestRate: 'Interest rate',
      interest: 'Interest',
      principal: 'Principal',
      accrualFrequency: 'Accrual',
      phantom: 'Phantom shares',
      stockOption: 'Stock options',
      stockOptions: 'Stock options',
      price: 'Price',
      number: 'Number',
      newShares: 'New shares',
      vestingPlan: 'Vesting plan',
      vestingStart: 'Plan Start Date',
      vestingPlans: 'Vesting Plans',
      vestedByMilestone: 'Vested upon achieving a milestone',
      vestingTimeline: 'Vesting timeline',
      startDate: 'Start date',
      expirationDate: 'Expiration date',
      errorEmptyName: 'The name field cannot be empty',
      errorConfirmOverDraftOperation: 'It can only be confirmed if it refers to the latest operation.',
      errorDateBeforeLastOperation: 'The date must be after the date of the last confirmed transaction.',
      confirmSubmit: 'Are you sure to confirm the operation?',
      confirmSubmitDraft: 'Are you sure to save the operation as a draft?',
      saveAsDraft: 'Save as draft',
      saveAsSimulation: 'Save as simulation',
      confirm: 'Confirm',
      status: 'Status',
      closed: 'Closed',
      draft: 'Draft',
      confirmed: 'Confirmed',
      timeline: 'Timeline',
      totalCashRaised: 'Total cash raised',
      totalCapitalRaised: 'Total capital raised',
      capitalRaised: 'Capital raised',
      totalInvestors: 'Total investors',
      valuation: 'Valuation',
      selectValuation: 'Select a valuation',
      totalValuation: 'Total valuation',
      ppsValuation: 'PPS valuation',
      ppsLastReport: 'PPS last report',
      totalDebtOutstanding: 'Debt outstanding',
      outstandingInterest: 'Outstanding interest',
      interestAvg: 'Interest (avg)',
      totalDebtHolders: 'Total Debt Holders',
      outstanding: 'Outstanding',
      capitalized: 'Capitalized',
      repaid: 'Repaid',
      debtHolders: 'Acreedores',
      syndicates: 'Syndicates',
      syndicate: 'Syndicate',
      byShareholder: 'By shareholder',
      byRole: 'By role',
      bySyndicate: 'By syndicate',
      byShareClass: 'By share class',
      noSyndicate: 'No syndicate',
      optionsStatus: 'Options status',
      createOption: 'Create option',
      createShareClass: 'Create share class',
      showChart: 'Show chart',
      showTable: 'Show table',
      ppsEvolution: 'PPS evolution',
      types: 'Types',
      assignOptions: 'Assign',
      vestOptions: 'Vest options',
      errorDeleteConfirmedOperation: 'You can\'t delete a confirmed operation if it is not the last one.',
      pay: 'Pay',
      payouts: 'Payouts',
      payout: 'Payout',
      secondaries: 'Secondaries',
      capitalize: 'Capitalize',
      transform: 'Transform',
      repaymentsTimeline: 'Repayments timeline',
      capitalizationsTimeline: 'Capitalizations timeline',
      repayDebt: 'Repay debt',
      unassigned: 'Unassigned',
      errors: {
        notShareholder: 'Shareholder not selected',
        optionsAssignedLimit: 'You have assigned more options than available',
        invalid_number: 'Invalid number',
        invalid_pps: 'Invalid PPS',
        invalid_share_class: 'Share class not selected or doesn\'t exist',
        invalid_shareholder: 'Shareholder not selected or doesn\'t exist',
        invalid_numeration: 'Invalid shares numeration',
        invalid_shares: 'Invalid shares',
        invalid_option: 'Invalid options',
        invalid_debt: 'Invalid debt',
        invalid_vesting_plan: 'Invalid vesting plan',
        share_assigned_greather_than_issue: 'Number of assigned shares greather than issue',
        share_assigned_less_than_issue: 'Number of assigned shares less than issue',
        assign_greather_than_available: 'Number of assigned shares greather than issue',
        invalid_principal: 'Invalid debt principal',
        invalid_start_date: 'Invalid start date',
        invalid_expiration_date: 'Invalid expiration date',
        invalid_name: 'Invalid name',
        invalid_type: 'Invalid type',
        invalid_price: 'Invalid price',
        invalid_amount: 'Invalid amount',
        payout_amount_greater_than_pending: 'The payment is greater than the outstanding debt',
        invalid_sales_number: 'The number of shares is not valid',
        invalid_sales_shareholder: 'The shareholder of the sale has not been assigned',
        invalid_sales_numeration: 'The number of shares in the sale isn\'t valid',
        sales_numeration_out_of_range: 'Share assign numeration out of sales numeration',
        invalid_conversion_type: 'Invalid conversion type',
        invalid_conversion_id: 'Invalid conversion',
        numeration_not_consecutive: 'Shares numeration isn\'t consecutive',
        numeration_out_of_range: 'Share numeration out of range',
        numeration_repeated: 'There are shares with the same numeration',
        invalid_source: 'The source operation is not the last committed operation',
        initializeCaptable: 'The following error occurred in the %OPERATION% operation for the date %DATE%',
        empty_value: 'The cell is empty',
        invalid_shareClass: 'The share class does not exist',
        origin_not_found: 'Origin not found',
        debt_not_found: 'Debt not found',
        option_not_found: 'Option not found',
        invalidRange: 'Invalid range',
        operation_above_with_errors: 'The operation could not be confirmed because some of the previous drafts have errors',
        empty_operation: 'You cannot save an empty operation',
      },
      column: 'Column',
      cell: 'Cell',
      downloadTheTemplate: 'Download the template',
      inicializeCaptableExcelDescription: 'and fill out the operations on the corresponding sheet. For each date a new operation will be created',
      assignPoolOptions: 'Assign pool options',
      pool: 'Pool',
      vote: 'Vote',
      votes: 'Votes',
      votingPercentage: 'Voting percentage',
      viewVoting: 'View voting',
      votingDetails: 'Voting details',
      shareholdersNumber: 'Shareholders',
      rolesNumber: 'Roles',
      votesNumber: 'Votes',
      information: 'Information',
      notShareholderVotes: 'There is no shareholders with voting rights',
      value: 'Value',
      optionsPool: 'Options pool',
      others: 'Others',
      new: 'New',
      previousOperation: 'Previous operation',
      comparator: 'Comparator',
      selectFirstPayoutType: 'Selecciona primero un tipo de pago',
      conversions: 'Conversions',
      conversion: 'Conversion',
      convert: 'Convert',
      exerciseStock: 'Exercise stock options',
      capitalizeDebt: 'Capitalize debt',
      noOperations: 'You haven\'t added operations',
      addShareholder: 'Add shareholder',
      automaticNumeration: 'Automatic numeration',
      preMoneyValuation: 'Pre-money valuation',
      preMoneyValue: 'Pre-money (value)',
      preMoneyValuationValue: 'Automatic numeration',
      prorataDistribution: 'Prorata distribution',
      nominalValue: 'Nominal value',
      sharePremium: 'Share premium',
      noResults: 'No results found',
      questionStatusToConfirm: 'Are you sure to confirm the operation?',
      questionStatusToDraft: 'Are you sure you want to change the operation to draft?',
      questionStatusToDraftMultiple: 'Are you sure you want to change this operation to draft  and all subsequent ones?',
      errorDraftOperation: 'An operation cannot be changed to draft if it\'s not the last one.',
      errorUndraftOperation: 'An operation cannot be confirmed if it has errors',
      setAsDraft: 'Change status to draft',
      addTimeline: 'Add to the timeline',
      removeTimeline: 'Remove from the timeline',
      cannotConfirmOperation: 'The operation has been saved but can\'t be confirmed because it has errors, please check them and try again',
      saveWithErrors: 'The operation has been saved correctly, but it has errors that need to be fixed before confirm',
      canNotSave: 'The operation has errors and cannot be saved.',
      saveAgainToValidateChanges: 'To update the errors, save the operation again.',
      operationNotFound: 'Operation not found or deleted',
      assigned: 'Assigned',
      optionsToVest: 'Options to vest',
      exercisePrice: 'Exercise price',
      ratio: 'Exercise ratio',
      stockSplit: 'Stock split',
      multiple: 'Multiple',
      liquidation: 'Liquidation',
      exit: 'Exit',
      both: 'Both',
      participating: 'Participating',
      moreDetails: 'More details',
      table: 'Table',
      chart: 'Chart',
      distribution: 'Distribution',
      nCurrentShares: 'Number of current shares',
      nNewShares: 'Number of new shares',
      nCurrentOptions: 'Number of current assigned options',
      nNewOptions: 'Number of new assigned options',
      nCurrentUnassignedOptions: 'Number of current unassigned options',
      nNewUnassignedOptions: 'Number of new unassigned options',
      analyst: 'Analyst',
      conflictMessage: 'Several values ​​have been found that affect the PPS of the company. Select which is the correct valuation for this date.',
      role: 'Role',
      founder: 'Founder',
      director: 'Director',
      boardMember: 'Board Member',
      investor: 'Investor',
      employee: 'Employee',
      secretary: 'Secretary',
      advisor: 'Advisor',
      bank: 'Bank',
      corporate: 'Corporate',
      angelInvestor: 'Angel investor',
      privateEquity: 'Private equity',
      ventureCapital: 'Venture capital',
      friendsAndFamily: 'Friends and family',
      corporateVentureCapital: 'Corporate venture capital',
      shareDetails: 'Share details',
      debtDetails: 'Debt details',
      optionDetails: 'Option details',
      optionsIn: 'Options in',
      additionalDocumentation: 'Additional documentation',
      totalDividend: 'Total dividend',
      dividends: 'Dividends',
      custom: 'Custom',
      voteDistribution: 'Vote distribution',
      addRow: 'Add row',
      sharesDistribution: 'Shares distribution',
      statutoryBook: 'Statutory book',
      total: 'Total',
      transactions: 'Transactions',
      simulations: 'Simulations',
      waterfall: 'Waterfall',
      deleteWaterfall: 'Are you sure you want to delete this waterfall?',
      exitValue: 'Exit value',
      debtsToRepay: 'Debts to repay',
      preferredShares: 'Preferred shares',
      preferred: 'Preferred',
      common: 'Common',
      cap: 'Cap',
      className: 'Class name',
      conversionRatio: 'C.Ratio',
      liquidationMultiple: 'L.Multiplier',
      waterfallToCompare: 'Select the waterfalls to compare',
      emptyOptionAssign: 'Select an option for each shareholder',
      full: 'Full',
      totalSummary: 'Total summary',
      multipleSummary: 'Multiple summary',
      operationsWillBeDeleted: 'The operations will be deleted',
      savedDataMayHaveErrors: 'Saved data may have errors',
      pendingShares: '%SHARES% shares remain to be distributed',
      saveWaterfallForExcel: 'To download the excel you have to save the changes first. Do you want to save the waterfall?',
      deleteErasedItem: 'This operation does not exist. Do you want to delete it?',
      certificate: 'Certificate',
      uploadExcelTemplates: 'Upload documentation',
      chooseOption: 'Choose one of the following options to start using the tool',
      importFrom: 'Import from',
      invitePlatform: 'Invite %PLATFORM% to your cap table software',
      features: {
        operations: 'Operations',
        operationsDescription: 'Issues shares, convertible, manages options, secondary sales, etc.',
        summary: 'Summary',
        summaryDescription: 'Easily visualize who is part of the cap table',
        timeline: 'Timeline',
        timelineDescription: 'Access previous states at any time',
        simulations: 'Simulations',
        simulationsDescription: 'See what happens under new scenarios or what the waterfall will be for each of them',
        export: 'Export',
        exportDescription: 'Export all information to Excel or generate reports for your investors in PDF',
        invite: 'Invite',
        inviteDescription: 'Give investors access so they can see their position',
      },
      operationNotTheLast: 'CAUTION: This operation is not the last',
      confirmMultipleOperations: 'Are you sure you want to confirm this operation and all the previous ones necessary until the last confirmed one?',
      uploadExcelDescription: 'Please attach all the documentation you have available about your cap table and we will take care of uploading this information in a structured way to the platform. If possible, include an Excel file with the current list of shareholders, details of preferred shares privileges, as well as convertible and/or stock option contracts. If we have any questions, we will contact you.',
      additionalDetailsRequest: 'Do you want to leave us more details?',
      questionRemoveCaptableRequest: 'Are you sure you want to delete the request to create the cap table?',
      questionRemoveImportCaptableRequest: 'Are you sure you want to delete the request to import the cap table from %PAGE%',
      markCompleted: 'Mark as completed',
      cancelRequest: 'Cancel request',
      importCaptable: {
        login: 'Login in to your %PAGE% account',
        navigateCarta: 'Navigate to Company > Permissions & Roles',
        navigateCapdesk: 'Navigate to "Settings" > "User Access"',
        navigateLedgy: 'Click "Invite collaborator" in the right hand side of the platform',
        addEmail: 'Add team@startups.land as a Legal Administartor',
        markCompleted: 'Let us know you added us by clicking "Mark as completed"',
        notification: 'We will let you know when your info is ready in startups.land',
      },
      lead: 'Lead',
      appointment: 'Appointmennt',
      rejected: 'Rejected',
      done: 'Done',
      taxid: 'TAXID',
      editShareholders: 'Edit shareholders',
      otherValuesSensitivity: 'Other values for sensitivity analysis',
      sensitivity: 'Sensitivity',
      noOtherExitValues: 'You haven\'t provided any additional exit value',
      repay: 'Repay',
      subject: 'Subject',
      message: 'Message',
      user: 'User',
      uploadDocument: 'Upload document',
      uploadSignature: 'Upload signature',
      signatureStatus: 'Signature status',
      uploaded: 'Uploaded',
      signed: 'Signed',
      filterByShareholder: 'Select a shareholder/s',
      maxCharacters: 'Maximum %NUMBER% characters',
      noCaptablePermission: 'You don\'t have permission to view this captable',
      other: 'Other',
      operationTimeout: 'This operation may take a few minutes',
      emailWhenComplete: 'You will receive an email when is completed.',
    },
    fundraise: {
      title: 'Fundraise',
      info: 'Information',
      investor: 'Investor',
      investors: 'Investors',
      amount: 'Amount',
      postMoney: 'Pre-Money',
      ownership: 'Ownership',
      investments: 'Investments',
      status: 'Status',
      round: 'Round',
      investmentRound: 'Investment Round',
      minTicket: 'Minimum ticket',
      minTicketPhase: 'Minimum ticket',
      maxTicket: 'Maximum ticket',
      maxTicketPhase: 'Maximum ticket',
      newRound: 'New round',
      editRound: 'Edit round',
      public: 'Public',
      draft: 'Draft',
      in_progress: 'In progress',
      closed: 'Closed',
      viewed: 'Viewed',
      sent: 'Sent',
      invested: 'Invested',
      commitedInvestor: 'Do you have a commited lead-investor?',
      kpis: 'KPIs',
      currentAllocation: 'Current allocation',
      invest: 'Invest',
      pending: 'Pending',
      amountToInvest: 'Amount to invest',
      share: 'Share',
      unauthorized: 'You don\'t have permission to view this collection',
      myInvestment: 'My investment',
      addEmail: 'Add email',
      invite: 'Invite',
      message: 'Message',
      deleteFundraise: 'Do you want to delete this startup fundraise?',
      deleteInvestment: 'Would you like to stop investing in this fundraise?',
      deleteInvite: 'Do you want to remove this invitation to the fundraise?',
      investmentAmount: 'The amount you have invested in this fundraising is',
      invites: 'Invites',
      preseed: 'PRE-SEED',
      seed: 'SEED',
      early: 'EARLY',
      growth: 'GROWTH',
      expansion: 'EXPANSION',
      enterToAdd: 'Press ↵ to add a new email',
      link: 'Link',
      linkDescription: 'Share this link with the investors you want to have access to invest in your fundraise. Click the link to copy to clipboard.',
      inviteDescription: 'Add the email of all the investors you want to have access to your fundraise',
      textCopied: 'Texto copiado!',
      commitment: 'Commitment',
      commitments: 'Commitments',
      addCommitment: 'Add commitment',
      whoCanInvest: 'Who can invest?',
      anyoneCanInvest: 'Anyone can invest',
      byInvitationOnly: 'By invitation only',
    },
    funds: {
      title: 'Funds',
      verification: 'Verification',
      notVerified: 'Not verified',
      team: 'Team',
      full: 'Full',
      confirmDeleteFund: 'Are you sure you want to delete this fund?',
      downloadExcel: 'Download excel',
      uploadExcel: 'Upload excel',
      successCreate: 'Successfully created %SUCCESS% funds',
      successCreateOne: 'Successfully created %SUCCESS% fund',
      row: 'Row',
      rowErrors: 'Errors in the next rows',
      send: 'Send',
      exportExcel: 'Download the template',
      andUpload: 'and upload it',
      emptyName: 'The field NAME is required',
      emptyCountry: 'The field COUNTRY is required',
    },
    notFound: {
      title: 'Page not found',
      goBack: 'Go to home',
    },
    common: {
      all: 'All',
      startup: 'Startup',
      mentor: 'Mentor',
      name: 'Name',
      description: 'Description',
      document: 'Document',
      documents: 'Documents',
      send: 'Send',
      save: 'Save',
      add: 'Add',
      new: 'New',
      create: 'Create',
      download: 'Download',
      upload: 'Upload',
      added: 'Added',
      user: 'User',
      users: 'Users',
      view: 'View',
      viewMore: 'View more',
      viewLess: 'View less',
      duration: 'Duration',
      viewDetail: 'View detail',
      viewDetails: 'View details',
      edit: 'Edit',
      update: 'Update',
      confirm: 'Confirm',
      delete: 'Delete',
      modify: 'Modify',
      accept: 'Accept',
      reject: 'Reject',
      cancel: 'Cancel',
      close: 'Close',
      year: 'Year',
      lastName: 'Last name',
      role: 'Role',
      avatar: 'Avatar',
      invite: 'Invite',
      email: 'Email',
      loading: 'Loading...',
      confirmDelete: 'Are you sure you want to delete it?',
      checkFormErrors: 'You must fill in all required fields',
      genericError: 'An error has occurred, check that the data is correct and try again',
      genericResourceNotFound: 'The resource does not exist, does not have permissions or has been deleted',
      error: 'Error',
      errors: 'Errors',
      other: 'Other',
      status: 'Status',
      back: 'Back',
      goBack: 'Go back',
      toolNotFound: 'Tool not found',
      permissions: 'Permissions',
      table: 'Table',
      chart: 'Chart',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'Jun',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
      },
      date: 'Date',
      value: 'Value',
      target: 'Target',
      percentage: 'Percentage',
      noData: 'No data',
      noResults: 'No results',
      currency: 'Currency',
      notes: 'Notes',
      type: 'Type',
      types: 'Types',
      yes: 'Yes',
      no: 'No',
      to: 'to',
      annual: 'Annual',
      monthly: 'Monthly',
      daily: 'Daily',
      total: 'Total',
      pending: 'Pending',
      createdAt: 'Created at',
      excel: 'Excel',
      both: 'Both',
      report: 'Report',
      stop: 'Stop',
      notFound: 'Not found',
      actions: 'Actions',
      auto: 'Automatic',
      input: 'Input',
      currencies: {
        currencies: 'Currencies',
        USD: 'United States dollar ($)',
        EUR: 'Euro (€)',
        JPY: 'Japanese yen (¥)',
        AUD: 'Australian dollar (A$)',
        CAD: 'Canadian dollar (C$)',
        GBP: 'Pound sterling (£)',
        CNY: 'Renminbi (元/¥)',
        HKD: 'Hong Kong dollar (HK$)',
        CHF: 'Swiss franc (CHF)',
        NZD: 'New Zealand dollar (NZ$)',
      },
      currenciesSymbols: {
        USD: '$',
        EUR: '€',
        JPY: '¥',
        AUD: 'A$',
        CAD: 'C$',
        GBP: '£',
        CNY: '元/¥',
        HKD: 'HK$',
        CHF: 'CHF',
        NZD: 'NZ$',
      },
      time: {
        seconds: 'Seconds',
        minutes: 'Minutes',
        hours: 'Hours',
        days: 'Days',
        weeks: 'Weeks',
        months: 'Months',
        years: 'Years',
        ago: '%number% %time% ago',
      },
      language: 'Language',
      languages: {
        es: 'Spanish',
        en: 'English',
        it: 'Italian',
      },
    },
  },
};
