import React from 'react';
import PropTypes from 'prop-types';

class PanelTab extends React.Component {
  static displayName = 'PanelTab';

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  };

  render() {
    const { children } = this.props;

    return children;
  }
}

export default PanelTab;
