/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown, ChevronUp, X } from 'lucide-react';
import { connect } from 'react-redux';
import { formatNumber, getDecimalSeparator } from 'utils/functions';
import './styles.scss';

const InputNumber = (props) => {
  const {
    className, preText, postText, placeholder,
    minValue, maxValue, step, isRequired, isDisabled,
    error, symbol, onBlur, onChange, decimals,
    state, stateTimeout, value: extValue, multiple, literals, raw,
  } = props;

  const separator = getDecimalSeparator();

  const thousandsSeparator = separator === ',' ? '.' : ',';
  const [prevValue, setPrevValue] = useState({ value: 0, selectionStart: 0 });
  const [auxDecimals, setAuxDecimals] = useState(0);
  const [showSep, setShowSep] = useState(false);
  const inputRef = useRef();
  const [isEmpty, setIsEmpty] = useState(!isDisabled && extValue === null);
  const [isNegative, setIsNegative] = useState(false);
  const [localValue, setLocalValue] = useState();
  const stateTimeoutRef = useRef();

  const auxMinValue = minValue !== null ? minValue : -Infinity;
  const auxMaxValue = maxValue !== null ? maxValue : Infinity;

  useEffect(() => {
    if (state) {
      setLocalValue(extValue);
    }
    if (multiple) {
      setLocalValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extValue]);

  useEffect(() => {
    if (localValue && state && stateTimeout > 0 && localValue !== extValue) {
      clearTimeout(stateTimeoutRef?.current);
      stateTimeoutRef.current = setTimeout(async () => {
        onBlur(localValue);
      }, stateTimeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localValue]);
  const value = state || multiple ? localValue : extValue;


  const handleOnChange = (v) => {
    if (!multiple && typeof onChange === 'function') {
      onChange(isNaN(v) ? null : v);
    }
    if (state || multiple) {
      setLocalValue(v);
    }
  };

  const formatValue = (number) => {
    if (value === '' || isEmpty) {
      return '';
    }
    if (isNegative) {
      return '-';
    }
    const splitted = Math.abs(number).toFixed(isNaN(decimals) ? 2
      : +Math.min(decimals, Math.max(number ? number.toString().split('.')[1]?.length || 0 : 0, auxDecimals))).split('.');
    const getSignPart = num => (num < 0 ? ['-'] : []);
    const getDecPart = (decPartStr, decimalPoint) => (decPartStr ? [decimalPoint, decPartStr] : []);
    const getIntPart = (intPartStr, thousandsSep) => {
      const res = intPartStr.split('');
      for (let idx = intPartStr.length - 3; idx > 0; idx -= 3) {
        res.splice(idx, 0, thousandsSep);
      }
      return res;
    };

    return getSignPart(number)
      .concat(getIntPart(splitted[0], separator === '.' ? ',' : '.'))
      .concat(showSep && decimals > 0 ? separator : '')
      .concat(getDecPart(splitted[1], separator))
      .join('');
  };

  const rawValue = (val) => {
    let rawVal = val[0] === '-' && auxMinValue < 0 ? '-' : '';
    rawVal += val.replace(separator === ',' ? /[^0-9,]+/g : /[^0-9.]+/g, '');
    rawVal = rawVal.replace(',', separator === ',' ? '.' : '');
    return !isNaN(rawVal)
      ? Math.max(Number(rawVal), auxMinValue)
      : 0;
  };

  useEffect(() => {
    if (value) {
      const dec = value.toString().split('.')[1]?.length || 0;
      if (+dec !== auxDecimals) {
        setAuxDecimals(dec);
        setShowSep(false);
      }
      if (minValue !== null && minValue > value) {
        handleOnChange(minValue);
      } else if (maxValue !== null && maxValue < value) {
        handleOnChange(maxValue);
      } else if (isEmpty) {
        setIsEmpty(false);
      } else if (isNegative && value !== '-') {
        setIsNegative(false);
      } else if (!raw) {
        let newPosition;
        if ((prevValue.value < value && value) > 0 || (value < 0 && prevValue.value > value)) {
          const newSep = value ? Math.abs(value).toFixed(0).length % 3 === 1 : false;
          newPosition = prevValue.selectionStart + (newSep ? 1 : 0);
        } else {
          let removeSep = value ? Math.abs(value).toFixed(0).length % 3 === 0 && Math.abs(prevValue.value).toFixed(0).length % 3 !== 0 : false;
          removeSep = removeSep || inputRef.current.value.split('')[prevValue.selectionStart - 1] === thousandsSeparator;
          newPosition = prevValue.selectionStart - (removeSep ? 1 : 0);
        }
        inputRef.current.selectionStart = newPosition;
        inputRef.current.selectionEnd = newPosition;
      }
    } else if (value === '') {
      setIsEmpty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const changeValue = (e) => {
    if (handleOnChange) {
      const newVal = e.target.value;
      const newRawValue = rawValue(newVal);
      if (newVal === '') {
        setIsEmpty(true);
        handleOnChange('');
      } else if (newVal === '-') {
        if (auxMinValue < 0) {
          setIsEmpty(false);
          setIsNegative(true);
          handleOnChange('');
        }
      } else {
        setIsNegative(false);
        setIsEmpty(false);
        setAuxDecimals(newVal.split(separator)[1]?.length || 0);
        if (newVal.slice(-1) === separator) {
          setShowSep(true);
        }
        if (showSep && e.target.selectionStart === newVal.length) {
          setShowSep(false);
          e.target.value = newVal.slice(0, -1) + newVal.slice(-1);
        }
        const auxValue = value || '';
        setPrevValue({ value: auxValue, selectionStart: e.target.selectionStart });
        const removedSeparator = (prevValue.value % 1 !== 0 || showSep) && !newVal.split('').find(char => char === separator);
        const changeDecimals = auxValue.toString().split(separator)[1] !== newVal.split(separator)[1];
        if (auxValue === newRawValue && !removedSeparator && !changeDecimals) {
          handleOnChange(rawValue(
            inputRef.current.value.slice(0, e.target.selectionStart - 1)
            + inputRef.current.value.slice(e.target.selectionStart, inputRef.current.value.length),
          ));
        } else {
          handleOnChange(newRawValue);
        }
      }
    }
  };

  const handleKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      handleOnChange(Math.min(value + (isNaN(step) ? 1 : step), auxMaxValue));
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      handleOnChange(Math.max(value - (isNaN(step) ? 1 : step), auxMinValue));
    } else if (['.', ','].includes(e.key) && val.split('').filter(char => char === separator).length) {
      e.preventDefault();
    } else if (e.key === thousandsSeparator) {
      e.preventDefault();
      e.target.value = val.substring(0, e.target.selectionStart) + separator + val.substring(e.target.selectionEnd);
      changeValue(e);
    } else if (multiple && e.key === 'Enter') {
      const rawVal = rawValue(val);
      const aux = [...extValue];
      const posicion = aux.findIndex(n => n > rawVal);
      aux.splice(posicion === -1 ? aux.length : posicion, 0, rawVal);
      onChange(aux);
      setLocalValue('');
    } else if (multiple && e.key === 'Backspace' && !val) {
      const aux = [...extValue];
      aux.pop();
      onChange(aux);
      setLocalValue('');
    } else {
      const newValue = val.substring(0, e.target.selectionStart) + e.key + val.substring(e.target.selectionEnd);
      if (rawValue(newValue) > auxMaxValue) {
        e.preventDefault();
      }
    }
  };

  return (
    <div className={`input_number ${className}`}>
      <div className={`pre_text ${error ? 'text_error' : ''}`}>
        {preText}
        {isRequired && <span className='is_required'>*</span>}
      </div>
      <div className={`input-container ${isDisabled ? 'disabled' : ''} ${symbol ? 'symbol' : ''}`}>
        { multiple && (
          extValue.map((val, i) => (isDisabled ? (
            <span className='selected-numbers'>{formatNumber(val, 0, { symbol })}</span>
          ) : (
            <span
              className='selected-numbers cursor-pointer'
              onClick={() => {
                const auxValues = [...extValue];
                auxValues.splice(i, 1);
                onChange(auxValues);
              }}
            >
              {formatNumber(val, 0, { symbol })}
              <X size={12} className='mx-1' />
            </span>
          )))
        )}
        <input
          ref={inputRef}
          className={`input ${error ? 'input_error' : ''}`}
          type='text'
          placeholder={`${placeholder} ${multiple ? `(${literals.enterToAdd})` : ''}`}
          step={step}
          min={auxMinValue}
          max={auxMaxValue}
          disabled={isDisabled}
          value={raw ? value : formatValue(value)}
          onChange={changeValue}
          onBlur={onBlur ? () => onBlur(value) : null}
          onKeyDown={handleKeyDown}
        />
        { !isDisabled && (
          <div className={`input-step ${symbol ? 'symbol' : ''}`}>
            <ChevronUp
              size={10}
              fill='currentColor'
              onClick={() => {
                const newValue = Math.min(+value + (isNaN(step) ? 1 : step), auxMaxValue);
                handleOnChange(newValue);
                if (onBlur) {
                  onBlur(newValue);
                }
              }}
            />
            <ChevronDown
              size={10}
              fill='currentColor'
              onClick={() => {
                const newValue = Math.max(+value - (isNaN(step) ? 1 : step), auxMinValue);
                handleOnChange(newValue);
                if (onBlur) {
                  onBlur(newValue);
                }
              }}
            />
          </div>
        )}
        {symbol && <span className='input-symbol'>{symbol}</span>}
      </div>
      { postText && (<div className='post_text' dangerouslySetInnerHTML={{ __html: postText }} />) }
    </div>
  );
};


InputNumber.propTypes = {
  literals: PropTypes.object.isRequired,
  className: PropTypes.string,
  preText: PropTypes.string,
  postText: PropTypes.string,
  placeholder: PropTypes.string,
  minValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.object,
  symbol: PropTypes.string,
  decimals: PropTypes.number,
  state: PropTypes.bool,
  stateTimeout: PropTypes.number,
  multiple: PropTypes.bool,
  raw: PropTypes.bool,
};

InputNumber.defaultProps = {
  className: '',
  preText: '',
  postText: '',
  placeholder: '0',
  minValue: -Infinity,
  maxValue: Infinity,
  step: 1,
  isRequired: false,
  isDisabled: false,
  value: '',
  error: null,
  onChange: null,
  onBlur: null,
  symbol: '',
  decimals: 10,
  state: false,
  stateTimeout: 0,
  multiple: false,
  raw: false,
};

const mapStateToProps = (state) => {
  return {
    literals: state.i18n.literals.input,
  };
};

export default connect(mapStateToProps, {})(InputNumber);
