import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ROUTE_PATH } from 'routes';
import { ES_LANGUAGE } from 'utils/language';
import './styles.scss';

const Footer = ({
  platform,
  user,
  literals,
  className = '',
}) => {
  const lang = user?.lang ?? ES_LANGUAGE;

  const legalPages = useMemo(() => {
    return Object.keys(platform?.legal || {}).filter(type => platform.legal[type]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!legalPages.length) {
    return null;
  }

  return (
    <footer className={`legal-footer ${className}`}>
      {legalPages.map(type => (
        <Link key={type} to={ROUTE_PATH.setLegal(type, lang)}>
          {literals[type] ?? type}
        </Link>
      ))}
    </footer>
  );
};

Footer.propTypes = {
  platform: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default Footer;
