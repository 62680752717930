import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const MessagesInstance = ApiFetch();

export const URLS = {
  GET_MESSAGES: 'messages/:type/:id',
  POST_MESSAGE: 'messages/:type/:id',
  READ_MESSAGES: 'messages/:type/:id/read',
};

async function getMessages(type, id, params) {
  const path = preparePath(URLS.GET_MESSAGES, { type, id }, params);
  return MessagesInstance.get(path);
}

async function postMessage(type, id, data) {
  const path = preparePath(URLS.POST_MESSAGE, { type, id });
  return MessagesInstance.post(path, data);
}

async function readMessages(type, id, data) {
  const path = preparePath(URLS.READ_MESSAGES, { type, id });
  return MessagesInstance.post(path, data);
}

export default {
  getMessages,
  postMessage,
  readMessages,
};
