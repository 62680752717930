import { connect } from 'react-redux';
import MyInvestmentsOpportunitiesComponents from '../components';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.myInvestments,
    literalsCommon: state.i18n.literals.common,
    literalsOnBoarding: state.i18n.literals.onboarding,
    currency: state.global.currency,
  };
}

export default connect(mapStateToProps)(MyInvestmentsOpportunitiesComponents);
