import { connect } from 'react-redux';
import PreHomeComponents from '../components';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.prehome,
    literalsOnBoarding: state.i18n.literals.onboarding,
    user: state.session.user,
    platform: state.global.platform,
  };
}

export default connect(mapStateToProps)(PreHomeComponents);
