import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import { formatDate } from 'utils/functions';

const PopupInfoStatus = (props) => {
  const {
    literals,
    row,
    onClose,
    getIcon,
  } = props;

  let lastDay = null;

  const renderEvents = (event) => {
    const [day, month, , hour] = formatDate(+event.timestamp).split(/\/| /);
    const formatDay = `${day}/${month}`;
    const render = (
      <Fragment key={event.id}>
        {formatDay !== lastDay && (
          <li className='timeline-row'>
            <div className='date year'>{formatDay}</div>
            <div className='event event-year year' />
          </li>
        )}
        <li key={event.id} className='timeline-row'>
          <div className='date'>{hour}</div>
          <div className='event box box-padding box-mb' onClick={() => {}}>
            <h6 className='event-name' title={event.event}>
              <span className='pr-3 d-flex align-content-center'>
                {getIcon(event.event)}
                {`${literals[event.event]}`}
              </span>
            </h6>
            <ul className='event-info'>
              {(event.event === 'open' || event.event === 'click') && (
                <li>
                  <span className='highlight'>IP: </span>
                  <span>{event.ip}</span>
                </li>
              )}
              {event.url && (
                <li>
                  <span className='highlight'>{`${literals.link}: `}</span>
                  <a href={event.url} target='_blank' rel='noopener noreferrer'>{event.url}</a>
                </li>
              )}
              {event.response && (
                <li>
                  <span className='highlight'>{`${literals.response}: `}</span>
                  <span>{event.response}</span>
                </li>
              )}
              {event.reason && (
                <li>
                  <span className='highlight'>{`${literals.errorReason}: `}</span>
                  <span>{event.reason}</span>
                </li>
              )}
            </ul>
          </div>
        </li>
      </Fragment>
    );
    lastDay = formatDay;
    return render;
  };


  return (
    <Popup title={literals.statusInfo} onClose={onClose}>
      <div className='row'>
        <div className='col-12 col-sm-8'>
          <h6 className='fw-b'>{literals.email}</h6>
          <p>{row.to}</p>
        </div>
        <div className='col-12 col-sm-4'>
          <h6 className='fw-b'>{literals.sendingDate}</h6>
          <p>{formatDate(row.createAt, { time: true })}</p>
        </div>
        <div className='col-12'>
          <h6 className='fw-b my-2'>{literals.timeline}</h6>
          <div className='timeline-container mt-4'>
            {row.events.reverse().map(event => (
              renderEvents(event)
            ))}
          </div>
        </div>
      </div>
    </Popup>
  );
};

PopupInfoStatus.propTypes = {
  literals: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  getIcon: PropTypes.func.isRequired,
};

export default PopupInfoStatus;
