import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import MessagesMenuComponents from '../components/MessagesMenu';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.messagesMenu,
    startup: state.global.startup,
  };
}

export default withRouter(connect(mapStateToProps)(MessagesMenuComponents));
