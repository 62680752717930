import React from 'react';
import PropTypes from 'prop-types';
import InputNumber from 'components/Inputs/inputNumber';
import { KPI_UNIT } from 'constants/kpis';

const KpiInputValue = ({
  kpi,
  currency,
  preText = '',
  value = null,
  onChange,
  onBlur = null,
  isDisabled = false,
}) => {
  let placeholder;
  if (!kpi.min && !kpi.max) {
    switch (kpi.unit) {
      case KPI_UNIT.FLOAT:
        placeholder = '0.00';
        break;
      default:
        placeholder = '0';
    }
  } else {
    placeholder = kpi.min != null ? kpi.min : '-∞';
    placeholder += '...';
    placeholder += kpi.max != null ? kpi.max : '+∞';
  }

  let symbol = null;
  switch (kpi.unit) {
    case KPI_UNIT.PERCENT: symbol = '%'; break;
    case KPI_UNIT.CURRENCY: symbol = currency?.symbol ? currency.symbol : ''; break;
    default: symbol = '';
  }

  const getDecimalsNum = () => {
    switch (kpi.unit) {
      case KPI_UNIT.INTEGER:
        return 0;
      case KPI_UNIT.CURRENCY:
        return 2;
      default:
        return 10;
    }
  };

  return (
    <InputNumber
      preText={preText}
      value={value}
      minValue={kpi.min}
      maxValue={kpi.max}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      symbol={symbol}
      isDisabled={isDisabled}
      step={kpi.unit === KPI_UNIT.INTEGER ? '1' : 'any'}
      decimals={getDecimalsNum()}
    />
  );
};

KpiInputValue.propTypes = {
  currency: PropTypes.object,
  kpi: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  preText: PropTypes.string,
  onBlur: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default KpiInputValue;
