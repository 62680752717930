/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel/components';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Table from 'components/Table';
import InputMonth from 'components/Inputs/inputMonth';
import EmptyParticipation from 'assets/svg/empty_participation.svg';
import KpiChartTable from 'components/KpiChartTable';
import IOFileLoading from 'components/IOFileLoading/components';
import { fetchLocal } from 'components/Table/components/TableFunctions';
import { useCounter } from 'utils/customHooks';
import { KPI_UNIT } from 'constants/kpis';
import { Download, LineChart } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_POSITION_PATH, ROUTE_STARTUP_PATH, embedView } from 'routes';
import { downloadInvestmentsKpisExcel, getInvestmentsKpis } from 'routes/MyInvestments/modules/actions';
import { formatDate, formatNumber, getTranslation } from 'utils/functions';
import './styles.scss';

const MyInvestmentsAnalysis = (props) => {
  const {
    literals,
    literalsPerformance,
    literalsCommon,
    shareholders,
    user,
    lang,
    currency,
    currencies,
  } = props;

  const [loadedCurrencies, setLoadedCurrencies] = useState(Object.keys(currencies || {}).length);
  const [investments, setInvestments] = useState(null);
  const [date, setDate] = useState(formatDate(new Date(), { format: 'Y-m' }));
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const tableRefreshFlag = useCounter(-1);
  const currencySymbol = literalsCommon.currenciesSymbols[currency];
  const isEmbed = window?.location?.pathname?.startsWith('/embed/');
  const navigate = useNavigate();

  const getExchangedCurrencies = () => {
    const kpisUnit = {};
    investments.kpis.forEach((kpi) => { kpisUnit[kpi.id] = kpi.unit; });

    let newStartups = investments.startups?.items || [];
    newStartups = newStartups.map((startup) => {
      const auxKpis = {};
      Object.keys(startup.kpis).forEach((kpi) => {
        let symbol = '';
        let value = startup.kpis[kpi].value;
        if (kpisUnit[kpi] === KPI_UNIT.PERCENT) {
          symbol = '%';
        } else if (kpisUnit[kpi] === KPI_UNIT.CURRENCY) {
          symbol = currencySymbol;
          value /= currencies[startup.currency];
        }
        auxKpis[kpi] = {
          ...startup.kpis[kpi], value: formatNumber(value, '-', { symbol }),
        };
      });
      return { ...startup, kpis: auxKpis };
    });

    return { items: newStartups, total: newStartups.length };
  };


  const fetchStartups = useCallback((investments !== null ? (page, size, search, filters, sort) => {
    const startups = getExchangedCurrencies();

    return fetchLocal(
      startups, { search: 'name' },
      page, size, search, filters, sort || '-name',
    );
  } : null), [getExchangedCurrencies, investments, currencies]);

  useEffect(() => {
    if (loadedCurrencies) {
      (async () => {
        setInvestments(null);
        const data = await getInvestmentsKpis(shareholders.join(), date);
        setInvestments(data);
      })();
    }
  }, [shareholders, date, loadedCurrencies]);

  useEffect(() => {
    if (Object.keys(currencies || {}).length) {
      tableRefreshFlag.increase();
    }
  }, [investments, currencies]);

  useEffect(() => {
    if (!loadedCurrencies && Object.keys(currencies || {})) {
      setLoadedCurrencies(true);
    }
  }, [currencies]);

  const handleDownloadExcel = async () => {
    setDownloadingExcel(true);
    const auxCurrency = { name: currency, symbol: currencySymbol, exchanges: currencies };
    await downloadInvestmentsKpisExcel(shareholders.join(), JSON.stringify(auxCurrency), 'investments_analysis.xlsx');
    setDownloadingExcel(false);
  };

  const columns = [
    {
      field: 'logo', type: 'avatar', width: 35, title: (_, { name }) => name,
    },
    { field: 'name', label: literalsCommon.name, sortable: true },
    {
      field: 'currency',
      width: 35,
      preRender: curr => (
        <div className='cell-keywords sm'>
          <div>{literalsCommon.currenciesSymbols[curr]}</div>
        </div>
      ),
    },
  ];

  if (investments?.kpis) {
    investments.kpis.forEach((kpi) => {
      columns.push({
        id: `kpis.${kpi.id}.value`,
        field: 'kpis',
        label: getTranslation(kpi.name, lang),
        preRender: ({ [kpi.id]: v }) => (v ? (
          <span title={v.date}>{v.value}</span>
        ) : '-'),
        width: 150,
        sortable: true,
      });
    });
  }

  const breadcrumb = [
    { name: literals.title },
    { name: literals.analysis },
  ];

  return (
    <div className='myInvestments-wrapper'>
      <Panel>
        <PanelHeader breadcrumb={breadcrumb} />
        <PanelBody>
          <div className='row'>
            <div className='offset-6 col-6 offset-md-9 offset-lg-10 col-md-3 col-lg-2'>
              <InputMonth value={date} onChange={setDate} />
            </div>
          </div>
          { !user.shareholders?.length ? (
            <div className='no-results'>
              <img src={EmptyParticipation} alt={literals.noResults} />
              <p>{literals.noResults}</p>
            </div>
          ) : (
            <Table
              columns={columns}
              pageSize={10}
              fetch={fetchStartups}
              onClickRow={!isEmbed
                ? row => window.open(ROUTE_STARTUP_PATH.setMyPosition(row.id, shareholders[0]), '_blank')
                : null
              }
              rowDetails={row => (
                <KpiChartTable
                  lang={user.lang}
                  literalsPerformance={literalsPerformance}
                  literalsCommon={literalsCommon}
                  currency={{ name: currency, symbol: currencySymbol }}
                  currencyExchange={currencies[row.currency]}
                  startup={row}
                  bookmark={false}
                  pageSize={10}
                  table='investments-analysis'
                />
              )}
              forceFetch={tableRefreshFlag.value}
              configColumns='investments-analysis'
              actions={[{
                text: 'Excel',
                icon: Download,
                onClick: handleDownloadExcel,
              }, {
                text: literals.comparator,
                icon: LineChart,
                onClick: () => navigate(embedView(ROUTE_POSITION_PATH.setKpiStartupsComparator(investments.kpis[0]?.id || null))),
              }]}
            />
          )}
        </PanelBody>
      </Panel>
      { downloadingExcel && <IOFileLoading mode='download' file={{ name: 'investments_analysis.xlsx' }} /> }
    </div>
  );
};

MyInvestmentsAnalysis.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsPerformance: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  shareholders: PropTypes.array.isRequired,
  currency: PropTypes.string.isRequired,
  currencies: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};

export default MyInvestmentsAnalysis;
