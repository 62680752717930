import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const ProgressBar = ({
  stepNumber,
  numberOfSteps,
  showPercentage = true,
  className = '',
}) => {
  const progressPercentage = (stepNumber * 100) / numberOfSteps;
  const style = {
    width: `${progressPercentage}%`,
  };
  return (
    <div className={`${'progress-bar-component'} ${className}`}>
      {showPercentage && !progressPercentage && (
        <span className='percentage zero'>0%</span>)
      }
      <div className='progress' style={style}>
        {showPercentage && progressPercentage && (
          <span className='percentage'>{`${progressPercentage.toFixed(0)}%`}</span>)
        }
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  numberOfSteps: PropTypes.number.isRequired,
  showPercentage: PropTypes.bool,
  className: PropTypes.string,
};

export default ProgressBar;
