import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  EditorState, convertToRaw, convertFromHTML, ContentState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './styles.scss';

const InputRichText = ({
  className = '',
  options = {},
  preText = '',
  value = '',
  minHeight = 150,
  error = false,
  onChange = null,
  isRequired = false,
  isDisabled = false,
  customButtons = null,
}) => {
  const getInitialContent = () => {
    if (value) {
      const block = convertFromHTML(value);
      return EditorState.createWithContent(ContentState.createFromBlockArray(block.contentBlocks, block.entityMap));
    }
    return EditorState.createEmpty();
  };
  const [editorState, setEditorState] = useState(getInitialContent());

  const onChangeEditor = (contentState) => {
    if (onChange) {
      onChange(draftToHtml(convertToRaw(contentState.getCurrentContent())));
    }
    setEditorState(contentState);
  };

  const editorStyle = { cursor: 'text' };
  if (minHeight) {
    editorStyle.minHeight = minHeight;
  }

  return (
    <div className={`input_richtext ${isDisabled ? 'disabled' : ''} ${className}`}>
      <div className={`pre_text ${error ? 'text_error' : ''}`}>
        {preText}
        {isRequired && <span className='is_required'>*</span>}
      </div>
      <Editor
        editorState={editorState}
        editorStyle={editorStyle}
        wrapperClassName='wrapper-class'
        toolbarClassName='toolbar-class'
        onEditorStateChange={onChangeEditor}
        stripPastedStyles
        readOnly={isDisabled}
        toolbarCustomButtons={customButtons}
        toolbar={{
          options: ['inline', 'list', 'link', 'fontSize'].concat(Object.keys(options)),
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline'],
          },
          list: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['unordered', 'ordered'],
          },
          fontSize: {
            options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
          },
          link: {
            defaultTargetOption: '_blank',
          },
          ...options,
        }}
      />
    </div>
  );
};

InputRichText.propTypes = {
  options: PropTypes.object,
  value: PropTypes.string,
  preText: PropTypes.string,
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  customButtons: PropTypes.array,
  className: PropTypes.string,
};

export default InputRichText;
