/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Table from 'components/Table';
import useDialog from 'components/Dialog/components/useDialog';
import { useParams } from 'react-router-dom';
import { formatNumber } from 'utils/functions';
import { Eraser, Eye, User } from 'lucide-react';
import { fetchCaptableShares } from './routes/Securities/modules/actions';
import PopupInfoShares from './routes/Securities/components/PopupInfoShares';
import FormReduce from './routes/Operation/components/FormReduce';
import './styles.scss';

const PanelCaptableShareClasses = (props) => {
  const {
    literals, literalsCommon, captable, currency, date, submitOperation,
  } = props;

  const [showDetails, setShowDetails] = useState(false);
  const [showReduce, setShowReduce] = useState(false);
  const params = useParams();
  const { dialog } = useDialog();

  const fetchSharesByShareClass = (page, size) => {
    return fetchCaptableShares(params.id, date, {
      page, size, captable: captable.selected?.id, group: 'shareClass',
    });
  };

  const handleConfirm = async (op) => {
    const [confirm, draft] = await dialog({
      text: literals.confirmSubmit,
      type: 'custom',
      actions: [
        {
          text: literalsCommon.yes,
          color: 'success',
          onClick: () => [true, false],
        },
        {
          text: literals.draft,
          onClick: () => [true, true],
        },
        {
          text: literalsCommon.cancel,
          color: 'secondary',
          onClick: () => [false, false],
        },
      ],
    });

    if (confirm) {
      const operation = {
        notes: op.notes,
        documents: op.documents,
        draft,
        timeline: true,
        date: op.date,
        captable: captable.selected?.id,
      };

      operation.name = op.name || `${literals.reduce}: ${captable.shareClass.find(sc => sc.id === op?.shareClass)?.name}`;
      operation.reduces = [{
        ...op.data,
        shares: Object.values(op.data.shares),
      }];

      await dialog({
        type: 'loading',
        execute: async () => {
          await submitOperation(operation);
        },
      });
    }
  };

  const columnsShareClasses = [
    {
      field: 'shareClass',
      grow: 2,
      label: literalsCommon.name,
      preRender: v => v.name,
    },
    {
      field: 'shareholders',
      width: 40,
      label: <User size={15} className='mb-1' title={literals.shareholders} />,
      preRender: v => v.length,
    },
    {
      field: 'number',
      width: 100,
      label: literals.shares,
      preRender: v => formatNumber(v, 0),
    },
    {
      field: 'capital',
      width: 100,
      label: literals.capital,
      preRender: v => `${formatNumber(v, 0, { dec: 2 })}${currency.symbol}`,
    },
    {
      field: 'ownership',
      width: 100,
      label: literals.ownership,
      preRender: v => `${formatNumber(v, 0, { dec: 2 })}%`,
    },
    {
      field: 'fullyDiluted',
      width: 100,
      label: literals.fullyDiluted,
      preRender: v => `${formatNumber(v, 0, { dec: 2 })}%`,
    },
    {
      field: 'votingPercentage',
      width: 100,
      label: literals.vote,
      preRender: v => `${formatNumber(v, 0, { dec: 2 })}%`,
    },
    {
      field: 'value',
      width: 100,
      label: literals.value,
      preRender: v => `${formatNumber(v, 0, { dec: 2 })}${currency.symbol}`,
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Eye,
          text: literals.fullyDiluted,
          onClick: row => setShowDetails({ type: 'shareClass', data: row }),
        },
        {
          icon: Eraser,
          text: literals.reduce,
          onClick: row => setShowReduce(row.id),
        },
      ],
    },
  ];

  return (
    <>
      <Table
        columns={columnsShareClasses}
        fetch={fetchSharesByShareClass}
        onClickRow={row => setShowDetails({ type: 'shareClass', data: row })}
      />
      {
        showDetails && (
          <PopupInfoShares
            {...showDetails}
            date={date}
            literals={literals}
            literalsCommon={literalsCommon}
            currency={currency}
            onClose={() => setShowDetails(false)}
          />
        )
      }
      {
        showReduce && (
          <Popup title={literals.issue.reduce} onClose={() => setShowReduce(false)} size='large'>
            <FormReduce
              id={showReduce}
              literals={literals}
              literalsCommon={literalsCommon}
              captable={captable}
              currency={currency}
              onConfirm={handleConfirm}
            />
          </Popup>
        )
      }
    </>
  );
};

PanelCaptableShareClasses.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  date: PropTypes.string,
  canEdit: PropTypes.bool.isRequired,
  submitOperation: PropTypes.func.isRequired,
};

PanelCaptableShareClasses.defaultProps = {
  date: new Date().toISOString().split('T')[0],
};

export default PanelCaptableShareClasses;
