import React from 'react';
import PropTypes from 'prop-types';
import InputText from 'components/Inputs/inputText';
import InputTextArea from 'components/Inputs/inputTextArea';
import InputSelect from 'components/Inputs/inputSelect';
import InputMultiSelect from 'components/Inputs/inputMultiSelect';
import InputNumber from 'components/Inputs/inputNumber';
import InputAvatar from 'components/Inputs/inputAvatar';
import InputFile from 'components/Inputs/inputFile';
import InputDate from 'components/Inputs/inputDate';
import InputRadio from 'components/Inputs/inputRadio';
import InputCheckbox from 'components/Inputs/inputCheckbox';
import InputMultiValues from 'components/Inputs/inputMultiValues';
import { getTranslation } from 'utils/functions';
import './styles.scss';

function Question(props) {
  const {
    question,
    lang,
    value,
    onChange,
    isPublic,
    errors,
    disabled,
  } = props;

  const onChangeValue = (newValue) => {
    onChange(question.id, newValue);
  };

  const {
    type, title, mandatory, answers, placeholder,
    tooltip, min, max, image, icon,
  } = question;

  const commonProps = {
    preText: getTranslation(title, lang),
    placeholder: getTranslation(placeholder, lang),
    postText: getTranslation(tooltip, lang),
    value,
    isRequired: mandatory,
    onChange: onChangeValue,
    error: errors,
    isDisabled: disabled,
  };

  const options = answers
    ?.sort((a, b) => a.order - b.order)
    .map(answer => ({ id: answer.id, name: getTranslation(answer.text, lang) }));

  let questionWrapper = null;
  switch (type) {
    case 'text':
      questionWrapper = (
        <InputText
          leftIcon={icon}
          {...commonProps}
        />
      );
      break;

    case 'textarea':
      questionWrapper = (
        <InputTextArea
          {...commonProps}
        />
      );
      break;

    case 'select':
      questionWrapper = (
        <InputSelect
          {...commonProps}
          options={options}
        />
      );
      break;

    case 'number':
      questionWrapper = (
        <InputNumber
          {...commonProps}
          minValue={min}
          maxValue={max}
        />
      );
      break;

    case 'list':
      questionWrapper = (
        <InputMultiSelect
          {...commonProps}
          options={options}
        />
      );
      break;

    case 'document':
    case 'file':
      questionWrapper = (
        <InputFile
          {...commonProps}
          isPublic={isPublic}
        />
      );
      break;

    case 'avatar':
      questionWrapper = (
        <InputAvatar
          {...commonProps}
          image={image}
          isPublic
        />
      );
      break;

    case 'date':
      questionWrapper = (
        <InputDate
          {...commonProps}
        />
      );
      break;

    case 'radiobutton':
      questionWrapper = (
        <InputRadio
          {...commonProps}
          options={options}
        />
      );
      break;

    case 'checkbox':
      questionWrapper = (
        <InputCheckbox
          {...commonProps}
          options={options}
        />
      );
      break;
    case 'multiValue':
      questionWrapper = (
        <InputMultiValues
          {...commonProps}
        />
      );
      break;

    default:
      questionWrapper = null;
      break;
  }

  return <div className='question-wrapper'>{questionWrapper}</div>;
}

Question.propTypes = {
  question: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  errors: PropTypes.array,
  isPublic: PropTypes.bool,
  disabled: PropTypes.bool,
};

Question.defaultProps = {
  value: null,
  isPublic: false,
  onChange: () => {},
  errors: null,
  disabled: false,
};

export default Question;
