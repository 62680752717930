import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StartupsSelect from 'components/StartupsSelect';
import { startupCanView } from 'utils/functions';
import { ROUTE_STARTUP_PATH } from 'routes';
import { tools } from 'routes/Home/components/homeStartup';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  TOOL_MENTORINGS,
  TOOL_PERFORMANCE,
  TOOL_PROFILE,
  TOOL_USERS,
  TOOL_DATA_ROOM,
  TOOL_CAP_TABLE,
  TOOL_REPORTING,
  TOOL_MESSAGES,
  TOOL_SERVICES,
  TOOL_FUNDRAISE,
} from 'constants/tools';
import {
  BarChart3, FileText, Home, Landmark, MessageSquareText,
  PrinterIcon, PuzzleIcon, Settings, Wallet, PieChart,
} from 'lucide-react';
import RenderRow from './RenderRow';

export const captableSubMenu = (literals, startup, captableId) => {
  return [
    {
      path: ROUTE_STARTUP_PATH.CAP_TABLE,
      to: ROUTE_STARTUP_PATH.setCapTable(startup.id, captableId),
      name: literals.capTableSummary,
    },
    {
      path: ROUTE_STARTUP_PATH.CAP_TABLE_OPERATIONS,
      to: ROUTE_STARTUP_PATH.setCapTableOperations(startup.id, captableId),
      name: literals.capTableTransactions,
    },
    {
      path: ROUTE_STARTUP_PATH.CAP_TABLE_SHAREHOLDERS,
      to: ROUTE_STARTUP_PATH.setCapTableShareHolders(startup.id, captableId),
      name: literals.capTableShareholders,
    },
  ];
};

export const settingsSubMenu = (literals, startup) => {
  return [
    {
      canView: startupCanView(startup, TOOL_USERS),
      path: ROUTE_STARTUP_PATH.USERS_STARTUP,
      to: ROUTE_STARTUP_PATH.setUsersStartup(startup.id),
      name: literals.userAccess,
    },
    {
      canView: startupCanView(startup, TOOL_USERS),
      path: ROUTE_STARTUP_PATH.USERS_STARTUP_PERMISSIONS,
      to: ROUTE_STARTUP_PATH.setUsersStartupPermissions(startup.id),
      name: literals.permissions,
    },
    {
      canView: startupCanView(startup, TOOL_PROFILE),
      path: [ROUTE_STARTUP_PATH.VIEW_STARTUP, ROUTE_STARTUP_PATH.EDIT_STARTUP],
      to: ROUTE_STARTUP_PATH.setViewStartup(startup.id),
      name: literals.profileStartup,
    },
  ];
};

const NavStartup = (props) => {
  const {
    user,
    platform,
    literals,
    toggleMenu,
    startup,
    route,
  } = props;

  const [subMenu, setSubMenu] = useState('');
  const [searchParams] = useSearchParams();

  const params = useParams();
  const startupId = params.id;
  const captableId = searchParams.get('captable') || null;
  const canView = tool => startupCanView(startup, tool);
  const handleShowSubmenu = submenu => setSubMenu(prev => (prev === submenu ? null : submenu));

  const auxTools = tools.filter(tool => startupCanView(startup, tool) && tool !== 'profile');
  const navRows = [
    {
      canView: (user?.role?.length >= 1 && user?.role[0] !== 'user') || auxTools.length !== 1 || [TOOL_MESSAGES].includes(auxTools),
      path: ROUTE_STARTUP_PATH.HOME,
      to: ROUTE_STARTUP_PATH.setHome(startupId),
      name: literals.summary,
      icon: Home,
      exact: true,
    },
    {
      canView: startup.relation?.shareholders?.length > 0,
      path: ROUTE_STARTUP_PATH.MY_POSITION,
      to: ROUTE_STARTUP_PATH.setMyPosition(startupId),
      name: literals.myPosition,
      icon: Wallet,
    },
    {
      canView: canView(TOOL_CAP_TABLE),
      path: ROUTE_STARTUP_PATH.CAP_TABLE,
      name: literals.capTable,
      icon: PieChart,
      subMenu: captableSubMenu(literals, startup, captableId),
      showSubmenu: subMenu === 'captable',
      handleShowSubmenu: () => handleShowSubmenu('captable'),
    },
    {
      canView: canView(TOOL_FUNDRAISE),
      path: ROUTE_STARTUP_PATH.FUNDRAISE,
      to: ROUTE_STARTUP_PATH.setFundraise(startupId),
      name: literals.fundraise,
      icon: Landmark,
    },
    {
      canView: canView(TOOL_PERFORMANCE),
      path: ROUTE_STARTUP_PATH.PERFORMANCE,
      to: ROUTE_STARTUP_PATH.setPerformance(startupId),
      name: literals.performance,
      icon: BarChart3,
    },
    {
      canView: canView(TOOL_MENTORINGS),
      path: ROUTE_STARTUP_PATH.MENTORINGS,
      to: ROUTE_STARTUP_PATH.setMentorings(startupId),
      name: literals.mentorings,
      icon: MessageSquareText,
    },
    {
      canView: canView(TOOL_REPORTING),
      path: ROUTE_STARTUP_PATH.REPORTING,
      to: ROUTE_STARTUP_PATH.setReporting(startupId),
      name: literals.reporting,
      icon: PrinterIcon,
    },
    {
      canView: canView(TOOL_DATA_ROOM),
      path: ROUTE_STARTUP_PATH.DATA_ROOM,
      to: ROUTE_STARTUP_PATH.setDataRoom(startupId),
      name: literals.dataRoom,
      icon: FileText,
    },
    {
      canView: canView(TOOL_SERVICES),
      path: ROUTE_STARTUP_PATH.SERVICES,
      to: ROUTE_STARTUP_PATH.setServices(startupId),
      name: literals.services,
      icon: PuzzleIcon,
    },
    {
      canView: canView(TOOL_USERS) || startupCanView(startup, TOOL_PROFILE),
      path: [ROUTE_STARTUP_PATH.USERS_STARTUP, ROUTE_STARTUP_PATH.VIEW_STARTUP, ROUTE_STARTUP_PATH.EDIT_STARTUP],
      name: literals.settings,
      icon: Settings,
      subMenu: settingsSubMenu(literals, startup, captableId),
      showSubmenu: subMenu === 'settings',
      handleShowSubmenu: () => handleShowSubmenu('settings'),
    },
  ];

  return (
    <>
      <div className='startups-select'>
        <StartupsSelect literals={literals} user={user} platform={platform} startup={startup} />
      </div>
      { navRows.map(row => (
        <RenderRow key={row.name} toggleMenu={toggleMenu} route={route} {...row} />
      ))}
    </>
  );
};

NavStartup.propTypes = {
  user: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
};

export default NavStartup;
