import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import { fetchCaptableAction } from 'routes/Captable/modules/actions';
import {
  deleteStartupCaptableAction, markStartupCaptableAsFavoriteAction, postStartupCaptableAction, putStartupCaptableAction,
} from '../modules/actions';
import CaptableSelector from './CaptableSelector';
import DateSelector from './DateSelector';
import '../styles.scss';

const StartupCaptableDate = (props) => {
  const {
    literals,
    literalsCommon,
    match,
    user,
    startup,
    fetchCaptable,
    captable,
    postStartupCaptable,
    putStartupCaptable,
    markStartupCaptableAsFavorite,
    deleteStartupCaptable,
    date,
    onChange,
    noDate,
    noMargin,
    editable,
  } = props;

  const [popup, setPopup] = useState();
  const showDate = !noDate && captable?.operations?.length;

  return (
    <div className={`d-flex ${!noMargin ? 'mb-4' : ''}`}>
      <CaptableSelector
        literals={literals}
        literalsCommon={literalsCommon}
        user={user}
        captable={captable}
        postStartupCaptable={postStartupCaptable}
        putStartupCaptable={putStartupCaptable}
        markStartupCaptableAsFavorite={markStartupCaptableAsFavorite}
        deleteStartupCaptable={deleteStartupCaptable}
        match={match}
        noDate={!showDate}
        startup={startup}
        popup={popup}
        setPopup={setPopup}
        fetchCaptable={fetchCaptable}
        editable={editable}
      />
      { showDate ? (
        <DateSelector
          literals={literals}
          captable={captable}
          date={date}
          popup={popup}
          setPopup={setPopup}
          onChange={onChange}
        />
      ) : null}
    </div>
  );
};

StartupCaptableDate.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  fetchCaptable: PropTypes.func.isRequired,
  postStartupCaptable: PropTypes.func.isRequired,
  putStartupCaptable: PropTypes.func.isRequired,
  markStartupCaptableAsFavorite: PropTypes.func.isRequired,
  deleteStartupCaptable: PropTypes.func.isRequired,
  date: PropTypes.string,
  onChange: PropTypes.func,
  noDate: PropTypes.bool,
  noMargin: PropTypes.bool,
  editable: PropTypes.bool,
};

StartupCaptableDate.defaultProps = {
  date: '',
  onChange: () => {},
  noDate: false,
  noMargin: false,
  editable: true,
};

const mapStateToProps = (state) => {
  return {
    literals: state.i18n.literals.captable,
    literalsCommon: state.i18n.literals.common,
    user: state.session.user,
  };
};

const mapDispatchToProps = dispatch => ({
  postStartupCaptable: bindActionCreators(postStartupCaptableAction, dispatch),
  putStartupCaptable: bindActionCreators(putStartupCaptableAction, dispatch),
  markStartupCaptableAsFavorite: bindActionCreators(markStartupCaptableAsFavoriteAction, dispatch),
  deleteStartupCaptable: bindActionCreators(deleteStartupCaptableAction, dispatch),
  fetchCaptable: bindActionCreators(fetchCaptableAction, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StartupCaptableDate));
