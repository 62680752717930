import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import useDialog from 'components/Dialog/components/useDialog';
import {
  UserPlus, Users, Eye, Pencil, Trash,
} from 'lucide-react';
import { useCounter } from 'utils/customHooks';
import PermissionLabel from 'components/PermissionsPopup/PermissionLabel';
import { PERMISSION_LEVELS, PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import { deleteSyndicate, getSyndicates } from '../modules/actions';
import PopupInfoSyndicate from './PopupInfoSyndicate';
import PopupCreateSyndicate from './PopupCreateSyndicate';

const SyndicatesTab = (props) => {
  const {
    literals,
    literalsCommon,
    literalsShareholder,
    startup,
    match,
    lang,
  } = props;

  const [showSyndicateInfo, setShowSyndicateInfo] = useState(null);
  const [createSyndicate, setCreateSyndicate] = useState(null);
  const tableRefreshFlag = useCounter(0);
  const { dialog } = useDialog();

  const fetchSyndicates = (page, size, search, filters, sort) => {
    return getSyndicates(match.params.id, page, size, { search, filters, sort: sort || '+name' });
  };

  const deleteElem = async (elem) => {
    const confirm = await dialog({
      text: literals.deleteShareholder, type: 'confirm',
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteSyndicate(match.params.id, elem)
            .then(() => tableRefreshFlag.increase())
            .catch(() => dialog({
              text: literals.cannotDeleteShareholder, type: 'error',
            }));
        },
      });
    }
  };

  const columns = [
    {
      field: 'name',
      grow: 3,
      label: literalsCommon.name,
      preRender: (name, row) => (
        <>
          {name}
          <PermissionLabel permission={row.permission} />
        </>
      ),
    },
    {
      field: 'description',
      grow: 3,
      label: literalsCommon.description,
      type: 'html',
    },
    {
      field: 'shareholders',
      width: 50,
      icon: Users,
      preRender: shareholders => shareholders.length,
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Eye,
          text: literalsCommon.view,
          onClick: syndicate => setShowSyndicateInfo(syndicate),
        },
        {
          icon: Pencil,
          text: literalsCommon.modify,
          onClick: syndicate => setCreateSyndicate(syndicate),
          hidden: (_, row) => row.permission.level < PERMISSION_LEVELS.EDIT,
        },
        {
          type: 'permission',
          scope: { type: PERMISSION_SCOPES.STARTUP, id: startup.id },
          element: PERMISSION_TYPES.SYNDICATE,
        },
        {
          icon: Trash,
          text: literalsCommon.delete,
          onClick: syndicate => deleteElem(syndicate.id),
          hidden: (_, row) => row.permission.level < PERMISSION_LEVELS.EDIT,
        },
      ],
    },
  ];

  const tableActions = [
    { icon: UserPlus, text: literalsCommon.create, onClick: () => setCreateSyndicate({ id: null }) },
  ];

  return (
    <div className='syndicates-tab'>
      <Table
        columns={columns}
        fetch={fetchSyndicates}
        pageSize={10}
        actions={tableActions}
        forceFetch={tableRefreshFlag.value}
        onClickRow={row => setShowSyndicateInfo(row)}
      />

      {showSyndicateInfo && (
        <PopupInfoSyndicate
          onClose={() => setShowSyndicateInfo(false)}
          id={showSyndicateInfo}
          literals={literals}
          literalsCommon={literalsCommon}
          literalsShareholder={literalsShareholder}
          syndicate={showSyndicateInfo}
          match={match}
          startup={startup}
          lang={lang}
        />
      )}

      {createSyndicate && (
        <PopupCreateSyndicate
          literals={literals}
          literalsCommon={literalsCommon}
          literalsShareholder={literalsShareholder}
          syndicate={createSyndicate}
          startup={startup}
          tableRefreshFlag={tableRefreshFlag}
          onClose={() => setCreateSyndicate(null)}
        />
      )}
    </div>
  );
};

SyndicatesTab.propTypes = {
  startup: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  literalsShareholder: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};

export default SyndicatesTab;
