import StartupsInstance from 'modules/startups/repository';
import MentoringsInstance from 'modules/mentorings/repository';
import FundsInstance from 'modules/funds/repository';
import AccountsInstance from 'modules/accounts/repository';
import { SessionService } from 'modules/session/sessionService';

const reloadSession = async () => {
  const user = await AccountsInstance.getSession();
  window.USER_ID = user.id;
  return SessionService.saveUser(user);
};

export function startupCreateAction(data) {
  return StartupsInstance
    .startupCreate(data)
    .then((response) => {
      return reloadSession()
        .then(() => {
          return response;
        })
        .catch((e) => {
          console.error(e, 'error startupCreate');
        });
    })
    .catch((e) => {
      console.error(e, 'error startupCreate');
    });
}

export function editStartup(id, data) {
  return StartupsInstance
    .startupEdit(id, data)
    .then((response) => {
      return reloadSession()
        .then(() => {
          return response;
        })
        .catch((e) => {
          console.error(e, 'error startupEdit');
        });
    })
    .catch((e) => {
      console.error(e, 'error startupEdit');
    });
}

export function fetchStartup(id, external = false) {
  return StartupsInstance.getStartupInfo(id, { external });
}

export function joinToStartup(id, external = false, guest = false) {
  return StartupsInstance.joinToStartup(id, { external, guest });
}

export function leaveStartup(id) {
  return StartupsInstance.leaveStartup(id);
}

export function requestStartupAccess(id, type) {
  return StartupsInstance.requestStartupAccess(id, { type });
}

export function mentorCreateAction(data) {
  return MentoringsInstance.saveMentoringOnboarding(data);
}

export function getFund(id) {
  return FundsInstance.fetchFund(id);
}
