import StartupsCaptablesInstance from 'modules/startupsCaptables/repository';
import { OPTION_TYPE } from 'constants/captable';
import {
  createCaptableOperationFailure,
  createCaptableOperationInit,
  createCaptableOperationSuccess,
  deleteCaptableOperationFailure,
  deleteCaptableOperationInit,
  deleteCaptableOperationSuccess,
  editCaptableOperationFailure,
  editCaptableOperationInit,
  editCaptableOperationSuccess,
} from 'routes/Captable/modules';
import { formatErrors } from 'utils/functions';

export function submitOperation(startup, data, id = false) {
  if (id) {
    return StartupsCaptablesInstance.putStartupOperation(startup, id, data);
  }
  return StartupsCaptablesInstance.postStartupOperation(startup, data);
}

export function submitOperationAction(startup, data, id = false, auxData) {
  return (dispatch) => {
    if (id) {
      dispatch(editCaptableOperationInit());
      return StartupsCaptablesInstance.putStartupOperation(startup, id, data).then((response) => {
        dispatch(editCaptableOperationSuccess({ ...response, ...auxData }));
        return response;
      }).catch((error) => {
        const formatError = formatErrors(error);
        dispatch(editCaptableOperationFailure(formatError));
        throw formatError;
      });
    }

    dispatch(createCaptableOperationInit());
    return StartupsCaptablesInstance.postStartupOperation(startup, data).then((response) => {
      dispatch(createCaptableOperationSuccess(response));
      return response;
    }).catch((error) => {
      const formatError = formatErrors(error);
      dispatch(createCaptableOperationFailure(formatError));
      throw formatError;
    });
  };
}

export function deleteOperationAction(startup, id) {
  return (dispatch) => {
    dispatch(deleteCaptableOperationInit());
    return StartupsCaptablesInstance.deleteStartupOperation(startup, id).then(() => {
      dispatch(deleteCaptableOperationSuccess(id));
      return id;
    }).catch((error) => {
      const formatError = formatErrors(error);
      dispatch(deleteCaptableOperationFailure(formatError));
      throw formatError;
    });
  };
}

export function fetchOperation(startup, operation) {
  return StartupsCaptablesInstance.getStartupOperation(startup, operation).then((response) => {
    return response;
  }).catch((error) => {
    throw formatErrors(error);
  });
}

export function fetchStartupOperations(id, opts) {
  return StartupsCaptablesInstance.getStartupOperations(id, opts)
    .then(response => response)
    .catch((error) => {
      throw formatErrors(error);
    });
}

export async function fetchCaptableVariables(startup, date, captable = null, filters = {}, config = { convertibles: true, unassigned: true, assigned: true }) {
  const params = {
    page: 0,
    size: 0,
    filters: {},
    captable,
  };
  return Promise.all([
    config?.convertibles ? StartupsCaptablesInstance.getStartupCaptableDebts(startup, date, { ...params, filters: { ...filters, pending: { gt: 0 } } }) : null,
    config?.convertibles ? StartupsCaptablesInstance.getStartupCaptableOptions(startup, date, { ...params, filters: { ...filters, vested: { gt: 0 }, type: OPTION_TYPE.STOCK_OPTION } }) : null,
    config?.assigned ? StartupsCaptablesInstance.getStartupCaptableShares(startup, date, { ...params, fields: 'id,number' }) : null,
    config?.unassigned ? StartupsCaptablesInstance.getStartupCaptableOptions(startup, date, { ...params, filters: { ...filters, shareholder: null } }) : null,
    config?.assigned ? StartupsCaptablesInstance.getStartupCaptableOptions(startup, date, { ...params, filters: { ...filters, assigned: { gt: 0 } } }) : null,
  ]).then(([convDebts, convOptions, assiShares, unnaOptions, assiOptions]) => {
    return {
      convertibles: {
        debts: convDebts?.items || [],
        options: convOptions?.items || [],
      },
      unassigned: {
        options: unnaOptions?.items || [],
      },
      assigned: {
        shares: assiShares?.items || [],
        options: assiOptions?.items || [],
      },
    };
  });
}

export function fetchCaptableShares(startup, date, params) {
  return StartupsCaptablesInstance.getStartupCaptableShares(startup, date, params);
}

export function fetchCaptableDebts(startup, date, params) {
  return StartupsCaptablesInstance.getStartupCaptableDebts(startup, date, params);
}

export function fetchCaptableOptions(startup, date, params) {
  return StartupsCaptablesInstance.getStartupCaptableOptions(startup, date, params);
}

export function fetchCaptableSummary(startup, type, date, opts) {
  return StartupsCaptablesInstance.getStartupCaptableSummary(startup, type, date, opts);
}
