/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputText from 'components/Inputs/inputText';
import InputEmail from 'components/Inputs/inputEmail';
import InputSelect from 'components/Inputs/inputSelect';
import Button from 'components/Buttons/Button';
import useDialog from 'components/Dialog/components/useDialog';
import Dots from 'assets/svg/dots.svg';
import { ROUTE_PATH } from 'routes';
import {
  Link, Navigate, useSearchParams, useNavigate, useLocation,
} from 'react-router-dom';
import { FILES_BASE_URL } from 'constants/apiConf';
import { useIsWidthDown } from 'utils/customHooks';
import { DIMENSIONS } from 'utils/browser';
import { signUp } from '../modules/actions';
import '../../Login/components/styles.scss';
import './styles.scss';

const Signup = (props) => {
  const {
    literals,
    platform,
    userLang,
    socialSignup,
  } = props;

  const [valueName, setValueName] = useState('');
  const [valueLastName, setValueLastName] = useState('');
  const [valueEmail, setValueEmail] = useState('');
  const [valueLang, setValueLang] = useState(userLang);
  const [signed, setSigned] = useState(false);
  const [loading, setLoading] = useState(false);
  const { dialog } = useDialog();
  const navigate = useNavigate();
  const location = useLocation();
  const isSmall = useIsWidthDown(DIMENSIONS.SM);

  const [searchParams] = useSearchParams();
  const invite = searchParams.get('invite');
  const inviteFundraise = searchParams.get('inviteFundraise');
  const referral = searchParams.get('referral');
  const fundraise = searchParams.get('fundraise');
  const startup = searchParams.get('startup');
  const external = searchParams.get('external');

  const getReferral = () => {
    let auxReferral = null;
    if (inviteFundraise) {
      auxReferral = {
        type: 'inviteFundraise',
        id: inviteFundraise,
        element: fundraise ? { type: 'fundraise', id: fundraise } : null,
      };
    } else if (referral) {
      if (fundraise) {
        auxReferral = {
          type: 'fundraise',
          id: referral,
          element: fundraise ? { type: 'fundraise', id: fundraise } : null,
        };
      } else if (startup) {
        auxReferral = {
          type: 'startup',
          id: referral,
          element: startup ? { type: 'startup', id: startup } : null,
        };
      }
    }
    return auxReferral;
  };

  const goToLogin = {
    pathname: ROUTE_PATH.LOGIN,
    state: location.state,
    search: location.search,
  };

  const handleSocialSignup = async (provider, token) => {
    const params = {
      oauth: {
        type: provider,
        id: token,
      },
      invite: invite || null,
      referral: getReferral(),
    };
    const success = await socialSignup(params);
    if (success) {
      navigate(goToLogin);
    } else {
      await dialog({
        type: 'error',
        text: literals.socialSignupError,
      });
    }
  };

  useEffect(() => {
    if (platform.oauth.google && google) {
      /* global google */
      google.accounts.id.initialize({
        client_id: platform.oauth.google,
        callback: response => handleSocialSignup('google', response.credential),
      });
      google.accounts.id.renderButton(
        document.getElementById('signInDiv'),
        {
          theme: 'outline',
          size: 'large',
          text: literals.login,
          height: 44,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValueChange = (value, elem) => {
    if (elem === 'name') {
      setValueName(value);
    }
    if (elem === 'lastName') {
      setValueLastName(value);
    }
    if (elem === 'email') {
      setValueEmail(value);
    }
  };

  const handleLangChange = (value) => {
    setValueLang(value);
  };

  const checkIsDisabled = () => {
    return valueName === '' || valueLastName === '' || valueEmail === '';
  };

  const optionsLangs = platform.langs.map((elem) => {
    return { id: elem, name: elem };
  });

  const submitForm = async (event) => {
    event.preventDefault();
    const form = {
      firstname: valueName,
      lastname: valueLastName,
      email: valueEmail,
      lang: valueLang,
      role: 'user',
      invite: invite || null,
      referral: getReferral(),
      external,
    };

    setLoading(true);
    try {
      await signUp(form);
      setLoading(false);
      await dialog({
        type: 'success',
        text: literals.signupSuccess,
      });
      setSigned(true);
    } catch (e) {
      setLoading(false);
      await dialog({
        type: 'error',
        text: literals.signupError,
      });
    }
  };

  let backgroundImage = null;
  if (isSmall) {
    if (platform.images?.bgPublicMobile?.path) {
      backgroundImage = FILES_BASE_URL + platform.images?.bgPublicMobile?.path;
    }
  } else if (platform.images?.bgPublicDesktop?.path) {
    backgroundImage = FILES_BASE_URL + platform.images?.bgPublicDesktop?.path;
  }

  return (
    <>
      {
        (platform.properties.signup.public || inviteFundraise || referral) ? (
          <div className='form-wrapper form-signup'>
            <div className={`form-box box ${backgroundImage ? 'form-box-opacity' : ''}`}>
              <div className='form-header'>
                <h4 className='fw-b'>{literals.title}</h4>
              </div>
              {
                platform.oauth.google ? (
                  <div className='social-actions'>
                    <div className='social-buttons'>
                      <div id='signInDiv' className='pb-2' style={{ height: 44 }} />
                    </div>
                    <div className='separator'>
                      <span>or Signup with Email</span>
                    </div>
                  </div>
                ) : null
              }
              <form onSubmit={submitForm}>
                <InputText
                  preText={literals.firstname}
                  name={literals.firstname}
                  placeholder={literals.firstname}
                  value={valueName}
                  maxLength={99999}
                  onChange={value => handleValueChange(value, 'name')}
                />
                <InputText
                  preText={literals.lastname}
                  name={literals.lastname}
                  placeholder={literals.lastname}
                  value={valueLastName}
                  maxLength={99999}
                  onChange={value => handleValueChange(value, 'lastName')}
                />
                <InputEmail
                  preText={literals.email}
                  placeholder={literals.email}
                  isRequired
                  value={valueEmail}
                  onChange={value => handleValueChange(value, 'email')}
                />
                <InputSelect
                  preText={literals.language}
                  name={literals.language}
                  value={valueLang}
                  options={optionsLangs}
                  onChange={handleLangChange}
                />
                <div className='form-actions'>
                  <Button
                    text={literals.createAccount}
                    type='submit'
                    disabled={checkIsDisabled()}
                    loading={loading}
                  />
                </div>
              </form>
              <div className='goto-link'>
                <p>
                  {literals.questionRegistered}
                  <Link className='link-effect' to={goToLogin}>{literals.goToLogin}</Link>
                </p>
              </div>
              {signed && <Navigate to={goToLogin} />}
            </div>
          </div>
        ) : (
          <div className='form-wrapper form-signup'>
            <div className='form-box box form-wrapper-notFound'>
              <h4 className='fw-b mb-4 text-center'>{literals.notFound}</h4>
              <div className='goto-login'>
                <Link to={goToLogin}>
                  <Button text={literals.goToLogin} color='secondary' />
                </Link>
              </div>
            </div>
          </div>
        )
      }
      {
        backgroundImage ? (
          <div className='background-image'>
            <img src={backgroundImage} alt={platform.name} />
          </div>
        ) : (
          <img className='background-dots' src={Dots} alt='dots' />
        )
      }
    </>
  );
};

Signup.propTypes = {
  literals: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
  userLang: PropTypes.string.isRequired,
  socialSignup: PropTypes.func.isRequired,
};

export default Signup;
