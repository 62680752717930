import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Popup from 'components/Popup';
import OutputValue from 'components/Inputs/outputValue';
import Loading from 'components/Loading';
import Button from 'components/Buttons/Button';
import useDialog from 'components/Dialog/components/useDialog';
import EmptyGraph from 'assets/svg/empty_graph.svg';
import ProgressBar from 'components/ProgressBar';
import { cloneObject, formatDate, getTranslation } from 'utils/functions';
import { KPI_CATEGORY, KPI_PERIODICITY } from 'constants/kpis';
import { useNavigate } from 'react-router-dom';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import { initializeKpiRelations } from 'routes/Performance/modules/actions';
import KpiRelationDetails from 'routes/Performance/components/KpiRelationDetails';
import KpiInputValue from 'routes/Performance/components/KpiInputValue';
import { CloudUpload } from 'lucide-react';
import { PERMISSION_LEVELS } from 'constants/permissions';

const PerformancePending = (props) => {
  const {
    lang,
    literals,
    literalsCommon,
    currency,
    match,
    startup,
    performance,
    fetchKpiRelations,
    postKpiValue,
    deleteKpiValue,
  } = props;

  const [kpiRelations, setKpiRelations] = useState(null);
  const [values, setValues] = useState([]);
  const [detailKpiRelation, setDetailKpiRelation] = useState(false);
  const [loading, setLoading] = useState({});
  const { dialog } = useDialog();
  const navigate = useNavigate();

  useEffect(() => {
    initializeKpiRelations(fetchKpiRelations, performance, 'startup', match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pendingQuarter = (month, year) => {
    if (month < 3) {
      return `${+year - 1}-12`;
    }
    if (month < 6) {
      return `${year}-03`;
    }
    if (month < 9) {
      return `${year}-06`;
    }
    if (month < 12) {
      return `${year}-09`;
    }
    return `${year}-${month}`;
  };

  const havePendingValue = (relation) => {
    const [year, month] = formatDate(new Date(), { format: 'Y-m-d' }).split('-');
    return !relation.value.date || !relation.value.actual
    || (relation.kpi.periodicity === KPI_PERIODICITY.MONTHLY && relation.value.date !== `${year}-${month}`)
    || (relation.kpi.periodicity === KPI_PERIODICITY.ANNUAL && (relation.value.date !== (month !== '12' ? (+year - 1).toString() : year)))
    || (relation.kpi.periodicity === KPI_PERIODICITY.QUARTERLY && relation.value.date !== pendingQuarter(month, year));
  };

  useEffect(() => {
    if (performance.loaded && !kpiRelations) {
      const auxKpiRelation = { business: [], financial: [], esg: [] };
      performance.relations.forEach((rel) => {
        if (rel.permission.level >= PERMISSION_LEVELS.EDIT && havePendingValue(rel)) {
          auxKpiRelation[rel.kpi.category] = [...auxKpiRelation[rel.kpi.category], rel];
        }
      });
      setKpiRelations(cloneObject(auxKpiRelation));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performance.loaded]);

  const breadcrumb = [
    { route: ROUTE_STARTUP_PATH.setPerformance(match.params.id), name: literals.title },
    { route: ROUTE_STARTUP_PATH.setPerformancePending(match.params.id), name: literals.pending },
  ];

  const getNextDate = (periodicity) => {
    const [year, month] = formatDate(new Date(), { format: 'Y-m-d' }).split('-');
    if (periodicity === KPI_PERIODICITY.MONTHLY) {
      return `${year}-${month}`;
    } if (periodicity === KPI_PERIODICITY.ANNUAL) {
      return month === '12' ? year : (+year - 1).toString();
    }
    return pendingQuarter(month, year);
  };

  const updateValue = async (id, relation, periodicity, value) => {
    setLoading(prev => ({ ...prev, [id]: true }));
    let success = false;
    if (value === '' || value === null || value === undefined) {
      success = await deleteKpiValue(id, 'startup', match.params.id, relation, getNextDate(periodicity));
    } else {
      success = await postKpiValue(id, 'startup', match.params.id, relation, getNextDate(periodicity), { value });
    }
    setLoading(prev => ({ ...prev, [id]: false }));

    if (!success) {
      await dialog({
        type: 'error',
        text: literalsCommon.genericError,
      });
      const newValues = values.map((rel) => {
        if (rel.id === relation) {
          return { ...rel, value: null };
        }
        return rel;
      });
      setValues(newValues);
    }
  };

  const handleShowDetail = (kpiRelationId) => {
    const kpiRelation = performance.relations.find(rel => rel.id === kpiRelationId);
    setDetailKpiRelation(kpiRelation);
  };

  if (!kpiRelations) {
    return (
      <Tool className='performancePending-wrapper' breadcrumb={breadcrumb}>
        <Loading hide={false} mode='panel' />
      </Tool>
    );
  }

  return (
    <Tool className='performancePending-wrapper' breadcrumb={breadcrumb}>
      { kpiRelations.business.length + kpiRelations.financial.length + kpiRelations.esg.length ? (
        <div className='progress-bar-wrapper sticky-top'>
          <ProgressBar
            stepNumber={values.length}
            numberOfSteps={kpiRelations.business.length + kpiRelations.financial.length + kpiRelations.esg.length}
          />
        </div>
      ) : null }
      {kpiRelations.business.length || kpiRelations.financial.length || kpiRelations.esg.length ? (
        <>
          {[KPI_CATEGORY.BUSINESS, KPI_CATEGORY.FINANCIAL, KPI_CATEGORY.ESG].map(relationType => (kpiRelations[relationType].length ? (
            <Panel key={relationType}>
              <PanelHeader title={literals[relationType]} />
              <PanelBody>
                {kpiRelations[relationType].map(relation => (
                  <div className='row' key={relation.id}>
                    <div className='col-12 col-md-6'>
                      <OutputValue
                        preText={`${literals.kpi} ${literals[relation.kpi.periodicity].toLowerCase()}`}
                        value={getTranslation(relation.kpi.name, lang) || '-'}
                        onClick={() => handleShowDetail(relation.id)}
                      />
                    </div>
                    <div className='col-6 col-md-3'>
                      <OutputValue
                        preText={`${literals.lastValue} ${relation.value.date ? `(${relation.value.date})` : ''}`}
                        value={relation.value.actual || '-'}
                      />
                    </div>
                    <div className='col-6 col-md-3'>
                      <KpiInputValue
                        preText={`${literals.newValue} (${getNextDate(relation.kpi.periodicity)})`}
                        value={values.find(val => val.id === relation.id)?.value || null}
                        kpi={relation.kpi}
                        currency={currency}
                        isDisabled={loading[relation.kpi.id] || false}
                        onChange={val => setValues(prev => (val
                          ? [...prev.filter(value => value.id !== relation.id), { id: relation.id, value: val }]
                          : [...prev.filter(value => value.id !== relation.id)]))}
                        onBlur={() => updateValue(relation.kpi.id, relation.id, relation.kpi.periodicity, values.find(val => val.id === relation.id)?.value || null)}
                      />
                    </div>
                  </div>
                ))}
                <div className='text-right'>
                  <CloudUpload className='mr-2' size={16} />
                  {literals.autoSave}
                </div>
              </PanelBody>
            </Panel>
          ) : null))}
        </>
      ) : (
        <Panel>
          <div className='no-results'>
            <img src={EmptyGraph} alt={literals.noPending} />
            <p>{literals.noPending}</p>
          </div>
        </Panel>
      )}
      <div className='buttons'>
        <Button
          text={literalsCommon.accept}
          onClick={() => navigate(embedView(ROUTE_STARTUP_PATH.setPerformance(match.params.id)))}
        />
      </div>
      {
        detailKpiRelation && (
          <Popup title={literals[detailKpiRelation.kpi?.category]} size='large' onClose={() => setDetailKpiRelation(false)}>
            <KpiRelationDetails
              startup={startup}
              relation={detailKpiRelation}
              onDeleteRelation={() => setDetailKpiRelation(false)}
              onClose={() => setDetailKpiRelation(false)}
            />
          </Popup>
        )
      }
    </Tool>
  );
};

PerformancePending.propTypes = {
  lang: PropTypes.string.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  performance: PropTypes.object.isRequired,
  fetchKpiRelations: PropTypes.func.isRequired,
  postKpiValue: PropTypes.func.isRequired,
  deleteKpiValue: PropTypes.func.isRequired,
};

export default PerformancePending;
