/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import Portal from 'components/Portal';
import { useRect } from 'utils/customHooks';
import { getStartupVerifiedIcon, normalizeText } from 'utils/functions';
import { connect } from 'react-redux';
import { PlusSquare, X } from 'lucide-react';
import { fetchStartups } from './modules/actions';
import './styles.scss';

const StartupSearcher = (props) => {
  const {
    className,
    literals,
    value,
    onChange,
    preText,
    postText,
    placeholder,
    isRequired,
    isDisabled,
    error,
  } = props;

  const [search, setSearch] = useState({ value, normalized: value });
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [bbox, inputRef, visible] = useRect(showOptions || loading);

  const timeout = useRef(null);
  useEffect(() => {
    if (search.value.length >= 2) {
      timeout.current = setTimeout(async () => {
        setLoading(true);
        const startups = await fetchStartups(0, 25, search.normalized, {
          verified: ['team', 'full'],
        });
        setOptions(startups);
        setShowOptions(true);
        setLoading(false);
      }, 750);
    }
    return () => clearTimeout(timeout.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);


  const selectValue = (id) => {
    const foundValue = id ? options.find(elem => elem.id === id || elem.externalId === id) : null;
    if (foundValue) {
      onChange(foundValue);
    } else {
      onChange(id ? { id: 'other', name: search.value } : null);
    }
    setSearch({ value: '', normalized: '' });
    setShowOptions(false);
  };

  const renderRow = (item) => {
    if (item?.id === 'other') {
      return (
        <>
          <PlusSquare strokeWidth={1.5} size={30} className='ml-1' />
          <div className='ml-3'>{item?.name || literals.noneAbove}</div>
        </>
      );
    }

    return (
      <>
        <Avatar size={40} src={item.logo?.path} title={item.name} />
        <div className='ml-3'>
          <div>{item.name}</div>
          <div className='fc-secondary fs-sm'>{item.url}</div>
        </div>
        { getStartupVerifiedIcon(item.verified) }
      </>
    );
  };

  const renderOptions = () => {
    const auxOptions = options.map(elem => (
      <div
        key={elem?.id || elem?.externalId}
        className='input-select-item'
        onClick={() => selectValue(elem.id || elem.externalId)}
      >
        {renderRow(elem)}
      </div>
    ));

    auxOptions.push(
      <div
        key='other'
        className='input-select-item'
        onClick={() => selectValue('other')}
      >
        {renderRow({ id: 'other' })}
      </div>,
    );

    return auxOptions;
  };

  const styles = { visible };
  if (bbox) {
    styles.top = bbox.bottom;
    styles.left = bbox.left;
    styles.width = bbox.width;
  }

  return (
    <div className={`input-select-wrapper value-inside startup-searcher ${className} ${isDisabled ? 'disabled' : ''}`}>
      {preText && (
        <div className={`pre_text ${error ? 'text_error' : ''}`}>
          {preText}
          {isRequired && <span className='is_required'>*</span>}
        </div>
      )}
      <div
        className={`input-select-select ${error ? 'input_error' : ''} ${showOptions ? 'no-border' : ''}`}
        ref={inputRef}
        onClick={() => inputRef.current.focus()}
      >
        <div className='selected-item'>
          { !value ? (
            <input
              className='input-select-text'
              placeholder={placeholder}
              type='text'
              value={search.value}
              onClick={e => e.stopPropagation()}
              onChange={e => setSearch({ value: e.target.value, normalized: normalizeText(e.target.value) })}
            />
          ) : (
            <div className='startup-selected'>
              <div className='d-flex align-items-center flex-grow-1'>
                {renderRow(value)}
              </div>
              <div style={{ width: '20px', marginLeft: '10px', cursor: 'pointer' }}>
                <X size={20} onClick={() => !isDisabled && selectValue(null)} />
              </div>
            </div>
          )}
        </div>
      </div>
      {(showOptions || loading) && (
        <Portal styles={styles} onClose={() => setShowOptions(false)}>
          <div className='input-select-item-options simple-scrollbar'>
            {loading ? (
              <div key='loading' className='input-select-item'>
                {literals.common.loading}
              </div>
            ) : renderOptions()}
          </div>
        </Portal>
      )}
      { postText && (<div className='post_text' dangerouslySetInnerHTML={{ __html: postText }} />) }
    </div>
  );
};

StartupSearcher.propTypes = {
  literals: PropTypes.object.isRequired,
  preText: PropTypes.string, // Texto situado antes del input
  postText: PropTypes.string, // Texto situado despues del input
  isRequired: PropTypes.bool, // Indica si es obligatorio (visualmente nada más)
  isDisabled: PropTypes.bool, // Indica si el input está deshabilitado
  onChange: PropTypes.func.isRequired, // Qué se hace cuando se cambia la selección
  error: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

StartupSearcher.defaultProps = {
  className: '',
  preText: '',
  postText: '',
  isRequired: false,
  isDisabled: false,
  error: null,
  value: '',
  placeholder: '',
};

const mapStateToProps = (state) => {
  return {
    literals: {
      ...state.i18n.literals.input,
      common: state.i18n.literals.common,
    },
  };
};


export default connect(mapStateToProps, {})(StartupSearcher);
