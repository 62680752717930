import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import useDialog from 'components/Dialog/components/useDialog';
import PermissionLabel from 'components/PermissionsPopup/PermissionLabel';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  UserPlus, Pencil, Trash, Eye,
} from 'lucide-react';
import { useCounter } from 'utils/customHooks';
import { formatDate } from 'utils/functions';
import { useSelector } from 'react-redux';
import { PERMISSION_LEVELS, PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import { getVestingPlans, deleteVestingPlans, fetchCaptableOptions } from '../modules/actions';
import PopupInfoVestingsPlans from './PopupInfoVestingPlan';
import PopupFormVestingsPlans from './PopupFormVestingPlan';

const VestingsPlans = ({
  literals, literalsCommon, startup, captable,
}) => {
  const literalsVestingPlans = useSelector(state => state.i18n.literals.vestingsPlans);

  const [showRowInfo, setShowRowInfo] = useState(null);
  const [rowSelected, setRowSelected] = useState(null);
  const tableRefreshFlag = useCounter(0);
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const { dialog } = useDialog();
  const params = useParams();

  const deleteVestingPlan = async (elem) => {
    await dialog({
      type: 'loading',
      execute: async () => {
        const capOptions = await fetchCaptableOptions(
          params.id,
          formatDate(new Date(), { format: 'Y-m-d' }),
          { filters: { vesting_plan: elem }, captable: captableId },
        );
        if (capOptions.total > 0) {
          await dialog({
            type: 'error',
            text: literals.questionDeleteError,
          });
        } else {
          const confirm = await dialog({
            text: literals.questionDelete,
            type: 'confirm',
          });
          if (confirm) {
            await dialog({
              type: 'loading',
              execute: async () => {
                await deleteVestingPlans(params.id, elem);
                tableRefreshFlag.increase();
              },
            });
          }
        }
      },
    });
  };

  const columns = [
    {
      field: 'name',
      label: literals.name,
      grow: 3,
      sortable: true,
      preRender: (v, row) => (
        <>
          {v}
          <PermissionLabel permission={row.permission} />
        </>
      ),
    },
    {
      field: 'type',
      label: literals.type,
      preRender: v => literalsVestingPlans[v],
    },
    {
      id: 'created_at',
      field: 'createdAt',
      label: literalsCommon.createdAt,
      preRender: elem => formatDate(elem),
      width: 100,
      sortable: true,
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Eye,
          text: literalsCommon.view,
          onClick: row => setShowRowInfo(row),
        },
        {
          icon: Pencil,
          text: literalsCommon.modify,
          onClick: row => setRowSelected(row),
          hidden: (_, row) => row.permission.level < PERMISSION_LEVELS.EDIT,
        },
        {
          type: 'permission',
          scope: { type: PERMISSION_SCOPES.STARTUP, id: startup.id },
          element: PERMISSION_TYPES.VESTING_PLAN,
        },
        {
          icon: Trash,
          text: literalsCommon.delete,
          onClick: vendingPlan => deleteVestingPlan(vendingPlan.id),
          hidden: (_, row) => row.permission.level < PERMISSION_LEVELS.EDIT,
        },
      ],
    },
  ];

  const fecthVestingPlans = useCallback((page, size, search, filters, sort) => {
    return getVestingPlans(params.id, page, size, { search, filters, sort });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Table
        fetch={fecthVestingPlans}
        forceFetch={tableRefreshFlag.value}
        columns={columns}
        onClickRow={row => setShowRowInfo(row)}
        actions={[
          { icon: UserPlus, text: literals.addVestingPlan, onClick: () => setRowSelected({}) },
        ]}
      />
      {showRowInfo && <PopupInfoVestingsPlans startup={params.id} date={captable?.summary?.operation} onClose={() => setShowRowInfo(null)} id={showRowInfo} />}
      {rowSelected && <PopupFormVestingsPlans startup={params.id} onClose={() => setRowSelected(false)} vestingPlan={rowSelected} onSubmit={tableRefreshFlag.increase} />}
    </>
  );
};

VestingsPlans.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
};

export default VestingsPlans;
