import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import Avatar from 'components/Avatar';
import SocialIcon from 'components/SocialIcon';
import useDialog from 'components/Dialog/components/useDialog';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { ROUTE_STARTUP_PATH } from 'routes';
import { Check, MapPin, X } from 'lucide-react';
import { STARTUP_RELATION_STATUS } from 'constants/startup';
import { getStartupVerifiedIcon } from 'utils/functions';
import { changeInviteStatus } from '../modules/actions';
import './styles.scss';

const StartupRow = ({
  id,
  name = '',
  description = '',
  logo = null,
  verified = '',
  legalName = '',
  country = '',
  city = '',
  url = '',
  social = null,
  relation = null,
  loading = false,
  literals = {},
  literalsOnBoarding = {},
  user = {},
  refreshTable = null,
  setPendingStartups = null,
}) => {
  const { dialog } = useDialog();
  const navigate = useNavigate();

  const handleChangeStatus = async (e, newStatus) => {
    e.preventDefault();
    const confirm = await dialog({
      type: newStatus === STARTUP_RELATION_STATUS.REJECTED ? 'confirmDanger' : 'confirm',
      text: newStatus === STARTUP_RELATION_STATUS.REJECTED ? literals.confirmReject : literals.confirmAccept,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await changeInviteStatus(id, user.id, newStatus);
          if (typeof refreshTable === 'function') {
            setPendingStartups(prev => prev.filter(startup => startup.id !== id));
            if (newStatus === STARTUP_RELATION_STATUS.ACCEPTED) {
              refreshTable();
            }
          }
        },
      });
    }
  };

  if (loading) {
    return (
      <div key={id}>
        <div className='startup loading'>
          <div className='company_header'>
            <Skeleton animation='wave' variant='circular' width={60} height={60} />
            <div className='company_info'>
              <span className='company_name'>
                <Skeleton animation='wave' width={150} height={30} />
              </span>
            </div>
          </div>
          <div>
            <Skeleton animation='wave' height={20} />
            <Skeleton animation='wave' height={20} />
          </div>
          <div className='d-flex'>
            <Skeleton animation='wave' width={50} height={15} />
            <Skeleton animation='wave' width={50} height={15} className='ml-2' />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div key={id} onClick={() => (relation?.status === 'accepted' ? navigate(ROUTE_STARTUP_PATH.setHome(id)) : null)}>
      <div className={`startup ${relation?.status === STARTUP_RELATION_STATUS.ACCEPTED ? STARTUP_RELATION_STATUS.ACCEPTED : STARTUP_RELATION_STATUS.PENDING}`}>
        <div className='company_header'>
          <Avatar title={name} src={logo?.path} size={50} />
          <div className='company_info'>
            <span className='company_name'>
              {name}
              {getStartupVerifiedIcon(verified)}
            </span>
            {legalName && (
              <span className='company_legal_name'>{legalName}</span>
            )}
            {(country || city) && (
              <span className='company_country'>
                <MapPin size={12} />
                {[city, literalsOnBoarding.countries[country]].filter(Boolean).join(', ')}
              </span>
            )}
          </div>
        </div>
        { (url || (social && Object.values(social || {}).some(Boolean))) && (
          <div className='company_social'>
            {url && <SocialIcon type='url' link={url} />}
            {Object.keys(social || {})
              .sort((a, b) => (b === 'other' ? -1 : 1))
              .map(s => social[s] && <SocialIcon key={s} type={s} link={social[s]} />)
            }
          </div>
        )}
        <div className='company_description'>{description}</div>
        { relation?.status === 'pending' ? (
          <div className='company_buttons'>
            <Button className='btn-sm' text={literals.accept} icon={Check} onClick={e => handleChangeStatus(e, STARTUP_RELATION_STATUS.ACCEPTED)} />
            <Button className='btn-sm ml-2' text={literals.reject} icon={X} color='danger' onClick={e => handleChangeStatus(e, STARTUP_RELATION_STATUS.REJECTED)} />
          </div>
        ) : (
          <div className='company_keywords'>
            <div className='fc-secondary fs-sm'>
              {`${literals[relation?.type]}${relation?.shareholders?.length ? `, ${literals.shareholder}` : ''}`}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

StartupRow.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  logo: PropTypes.object,
  verified: PropTypes.string,
  legalName: PropTypes.string,
  country: PropTypes.string,
  city: PropTypes.string,
  url: PropTypes.string,
  social: PropTypes.object,
  relation: PropTypes.object,
  loading: PropTypes.bool,
  literals: PropTypes.object.isRequired,
  literalsOnBoarding: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  refreshTable: PropTypes.func,
  setPendingStartups: PropTypes.func,
};

export default StartupRow;
