import React from 'react';
import PropTypes from 'prop-types';
import InputNumber from 'components/Inputs/inputNumber';
import InputSelect from 'components/Inputs/inputSelect';
import Button from 'components/Buttons/Button';
import { Trash } from 'lucide-react';

const PopupFormVestingPlanTime = ({
  data, onChange, literals, totalPercentage, isDisabled,
}) => {
  // const optionsFrecuency = [
  //   { id: 'day', name: literals.day },
  //   { id: 'month', name: literals.month },
  //   { id: 'year', name: literals.year },
  // ];
  const optionsSchedule = [
    { id: 'start', name: literals.start },
    { id: 'exact', name: literals.exact },
    { id: 'end', name: literals.end },
  ];

  return (
    <div className='vestingPlan-time'>
      <h5 className='mb-0'>{literals.periods}</h5>
      <hr />
      <div className='time-box'>
        <ul className='list-separator'>
          {data.periods.map((elem, index) => {
            return (
              <li key={`period-${index}`}>
                <div className='row row-elem'>
                  {/* <div className='col-md-3'>
                    <InputSelect
                      className='mb-md-0'
                      preText={literals.frequency}
                      value={elem.frequency ? elem.frequency : ''}
                      options={optionsFrecuency}
                      onChange={value => onChange('frequency', index, value)}
                      isDisabled={isDisabled}
                      isRequired
                      decimals={0}
                    />
                  </div> */}
                  <div className='col-md-4'>
                    <InputSelect
                      className='mb-md-0'
                      preText={literals.schedule}
                      value={elem.schedule ? elem.schedule : ''}
                      options={optionsSchedule}
                      onChange={value => onChange('schedule', index, value)}
                      isDisabled={isDisabled}
                      isRequired
                    />
                  </div>
                  <div className='col-md-4'>
                    <InputNumber
                      name='length'
                      className='mb-md-0'
                      preText={literals.length}
                      value={elem.length ? elem.length : 0}
                      onChange={value => onChange('length', index, value)}
                      minValue={0}
                      isDisabled={isDisabled}
                      decimals={0}
                    />
                  </div>
                  <div className='col-md-3'>
                    <InputNumber
                      className='mb-md-0'
                      name='percentage'
                      preText={literals.percentage}
                      value={elem.percentage ? elem.percentage : 0}
                      minValue={0}
                      maxValue={100 - totalPercentage + elem.percentage}
                      onChange={value => onChange('percentage', index, value)}
                      isDisabled={isDisabled}
                    />
                  </div>
                  <div className='col-md-1 vestingPlan-trash'>
                    {!isDisabled && <Trash size={18} onClick={() => onChange('delete', index)} />}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      {!isDisabled && (
        <div className='addButton mb-3'>
          <Button
            color='outline-primary'
            text={literals.addTime}
            onClick={() => onChange('add')}
          />
        </div>
      )}
    </div>
  );
};
PopupFormVestingPlanTime.propTypes = {
  literals: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  totalPercentage: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};


export default PopupFormVestingPlanTime;
