import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import InputText from 'components/Inputs/inputText';
import InputEmail from 'components/Inputs/inputEmail';
import InputPassword from 'components/Inputs/inputPassword';
import InputSelect from 'components/Inputs/inputSelect';
import Tool from 'components/Tool';
import Panel from 'components/Panel';
import PanelTab from 'components/Panel/components/PanelTab';
import OutputErrors from 'components/Inputs/outputErrors';
import useDialog from 'components/Dialog/components/useDialog';
import InputAvatar from 'components/Inputs/inputAvatar';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelHeader from 'components/Panel/components/PanelHeader';
import Breadcrumb from 'components/Breadcrumb';
import { User } from 'lucide-react';
import { EN_LANGUAGE, ES_LANGUAGE, IT_LANGUAGE } from 'utils/language';
import { ROUTE_PATH } from 'routes';
import { useNavigate } from 'react-router-dom';
import { editProfile, editPassword } from '../modules/actions';
import './styles.scss';

function EditAccount(props) {
  const {
    user,
    literals,
    setPlatformLanguageAction,
    platform,
  } = props;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [userInfo, setUserInfo] = useState(user);
  const [password, setPassword] = useState({ oldPassword: '', newPassword: '', newPasswordRepeat: '' });
  const { dialog } = useDialog();
  const navigate = useNavigate();

  const goBack = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate(ROUTE_PATH.PREHOME);
    }
  };

  const onChangeValue = (prop, value) => {
    setUserInfo({
      ...userInfo,
      [prop]: value,
    });
  };

  const onChangePassword = (value, event) => {
    setPassword({ ...password, [event.target.name]: value });
  };

  const submitProfile = async () => {
    setLoading(true);
    try {
      await editProfile(userInfo.id, {
        avatar: userInfo.avatar,
        firstname: userInfo.firstname,
        lastname: userInfo.lastname,
        lang: userInfo.lang,
        decimalSeparator: userInfo.decimalSeparator,
      });
      setPlatformLanguageAction(userInfo.lang, platform?.properties?.literals);
      window.DECIMAL_SEPARATOR = userInfo.decimalSeparator;
      setLoading(false);
      await dialog({
        type: 'success',
        text: literals.accountSaved,
      });
      goBack();
    } catch (e) {
      setErrors(e);
      setLoading(false);
    }
  };

  const submitPassword = async () => {
    if (password.newPassword !== password.newPasswordRepeat) {
      setErrors({
        newPasswordRepeat: [
          {
            code: 'not_match',
            message: literals.passwordNotMatch,
          },
        ],
      });
    } else {
      setLoading(true);
      try {
        await editPassword(userInfo.id, {
          oldPassword: password.oldPassword,
          newPassword: password.newPassword,
        });

        await dialog({
          type: 'success',
          text: literals.accountSaved,
        });
        goBack();
      } catch (e) {
        setErrors(e);
      }
      setLoading(false);
    }
  };
  const fileIcon = <User size={70} className='file-icon' />;

  const tabs = [
    { key: 'profile', name: literals.profile },
    { key: 'password', name: literals.password },
  ];

  return (
    <Tool noAside>
      <Breadcrumb goBack />
      <Panel>
        <PanelHeader
          title={literals.editAccount}
          tabs={tabs}
        />
        <PanelBody>
          <PanelTab key='profile'>
            <div className='questions-wrapper'>
              <InputAvatar
                preText={literals.avatar}
                onChange={v => onChangeValue('avatar', v)}
                value={userInfo.avatar}
                image={fileIcon}
              />
              <div className='row'>
                <div className='col-12'>
                  <InputEmail preText={literals.email} onChange={() => {}} value={userInfo.email} isDisabled />
                </div>
                <div className='col-12 col-md-4'>
                  <InputText
                    preText={literals.name}
                    placeholder={literals.name}
                    onChange={v => onChangeValue('firstname', v)}
                    value={userInfo.firstname}
                    errors={errors?.firstname}
                  />
                </div>
                <div className='col-12 col-md-8'>
                  <InputText
                    preText={literals.lastname}
                    placeholder={literals.lastname}
                    onChange={v => onChangeValue('lastname', v)}
                    value={userInfo.lastname}
                    errors={errors?.lastname}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <InputSelect
                    preText={literals.language}
                    placeholder={literals.language}
                    onChange={v => onChangeValue('lang', v)}
                    value={userInfo.lang}
                    options={[
                      {
                        label: literals.languages.es,
                        value: ES_LANGUAGE,
                        name: literals.languages.es,
                        id: ES_LANGUAGE,
                      },
                      {
                        label: literals.languages.en,
                        value: EN_LANGUAGE,
                        name: literals.languages.en,
                        id: EN_LANGUAGE,
                      },
                      {
                        label: literals.languages.it,
                        value: IT_LANGUAGE,
                        name: literals.languages.it,
                        id: IT_LANGUAGE,
                      },
                    ]}
                    errors={errors?.lang}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <InputSelect
                    preText={literals.numericFormat}
                    placeholder={literals.numericFormat}
                    onChange={v => onChangeValue('decimalSeparator', v)}
                    value={userInfo.decimalSeparator}
                    options={[
                      { id: 'period', name: literals.numericFormats.decimalsPeriod },
                      { id: 'comma', name: literals.numericFormats.decimalsComma },
                    ]}
                    errors={errors?.lang}
                  />
                </div>
              </div>
              <div className='buttons'>
                <Button text={literals.submit} onClick={submitProfile} loading={loading} />
                <Button text={literals.cancel} color='secondary' onClick={goBack} />
              </div>
            </div>
          </PanelTab>
          <PanelTab key='password'>
            <div className='questions-wrapper'>
              <InputPassword
                name='oldPassword'
                preText={literals.oldPassword}
                placeholder={literals.oldPassword}
                onChange={onChangePassword}
                value={password.oldPassword}
              />
              <InputPassword
                name='newPassword'
                preText={literals.newPassword}
                placeholder={literals.newPassword}
                onChange={onChangePassword}
                value={password.newPassword}
              />
              <InputPassword
                name='newPasswordRepeat'
                preText={literals.newPasswordRepeat}
                placeholder={literals.newPasswordRepeat}
                onChange={onChangePassword}
                value={password.newPasswordRepeat}
              />
              {errors && <OutputErrors literals={literals} errors={errors} />}
              <div className='buttons'>
                <Button
                  text={literals.submit}
                  onClick={submitPassword}
                  loading={loading}
                  disabled={!password.oldPassword || !password.newPassword || !password.newPasswordRepeat}
                />
                <Button text={literals.cancel} mode='cancel' onClick={goBack} />
              </div>
            </div>
          </PanelTab>
        </PanelBody>
      </Panel>
    </Tool>
  );
}

EditAccount.propTypes = {
  user: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  setPlatformLanguageAction: PropTypes.func.isRequired,
  platform: PropTypes.object.isRequired,
};

export default EditAccount;
