import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Button from 'components/Buttons/Button';
import InputText from 'components/Inputs/inputText';
import InputSelect from 'components/Inputs/inputSelect';
import OutputErrors from 'components/Inputs/outputErrors';
import Loading from 'components/Loading';
import EDITOR_JS_TOOLS from 'components/EditorJS/Constants';
import { useNavigate } from 'react-router-dom';
import { createReactEditorJS } from 'react-editor-js';
import { ROUTE_ADMIN_PATH } from 'routes';
import { useLang } from 'utils/customHooks';
import { KPI } from 'components/EditorJS/KPI';
import { createReportTemplate, editReportTemplate, getReportTemplate } from 'routes/Admin/Reporting/modules/actions';
import './styles.scss';

const AdminReportingNewTemplate = (props) => {
  const {
    literals,
    literalsPerformance,
    literalsCommon,
    match,
    currency,
  } = props;

  const lang = useLang();
  const [name, setName] = useState({});
  const [content, setContent] = useState({});
  const [loading, setLoading] = useState('fetching');
  const [errors, setErrors] = useState(null);
  const edit = match.params.template !== 'new';
  const navigate = useNavigate();

  useEffect(() => {
    if (edit) {
      setLoading('fetching');
      getReportTemplate(match.params.template)
        .then((response) => {
          setName(response.name);
          setContent(response.content);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadcrumb = [
    { route: ROUTE_ADMIN_PATH.REPORTING, name: literals.title },
    { name: edit ? literals.editTemplate : literals.newTemplate },
  ];

  const setLangValue = (prop, value) => {
    switch (prop) {
      case 'name':
        setName(lang.setObjValue(name, value));
        break;
      case 'content':
        setContent(lang.setObjValue(content, value));
        break;
      default:
        break;
    }
  };

  const ReactEditorJS = createReactEditorJS();
  const editorCore = useRef(null);

  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = useCallback(async () => {
    return editorCore.current?.save().then(response => setLangValue('content', response));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang.value, content]);

  const handleSumbit = (e) => {
    setLoading('submit');
    e.preventDefault();
    const data = {
      name, content, owner: 'all', scope: 'startup',
    };
    const validateLangs = lang.validateLangs(data, ['name', 'content']);
    if (validateLangs === true) {
      if (edit) {
        editReportTemplate(match.params.template, data)
          .then(() => navigate(ROUTE_ADMIN_PATH.REPORTING));
      } else {
        createReportTemplate(data)
          .then(() => navigate(ROUTE_ADMIN_PATH.REPORTING));
      }
    } else {
      setErrors(validateLangs);
      setLoading(false);
    }
  };

  return (
    <Tool>
      <Panel margin={0} clean>
        <PanelHeader breadcrumb={breadcrumb} />
        <PanelBody>
          <form onSubmit={handleSumbit} className='reporting-new-template'>
            <div className='row my-3'>
              <div className='col-12 col-md-7'>
                <InputText
                  preText={lang.getTitleInput(literalsCommon.name)}
                  value={lang.getObjValue(name)}
                  onChange={v => setLangValue('name', v)}
                />

              </div>
              <div className='col-12 offset-md-1 col-md-4'>
                <InputSelect
                  preText={literals.language}
                  value={lang.value}
                  options={lang.getLangs().map(l => ({ id: l, name: l }))}
                  onChange={lang.change}
                />
              </div>
            </div>
            <div className='row my-3'>
              <div className='col-12'>
                <span className='title'>{literals.template}</span>
                <div className='editor-wrapper'>
                  {(edit && loading === 'fetching') ? (
                    <Loading mode='dialog' hide={false} />
                  ) : (
                    <ReactEditorJS
                      key={`template-${lang.value}`}
                      defaultValue={lang.getObjValue(content)}
                      tools={{
                        ...EDITOR_JS_TOOLS,
                        KPI: {
                          class: KPI,
                          config: {
                            literalsPerformance, currency, lang: lang.value, match,
                          },
                        },
                      }}
                      onChange={handleSave}
                      onInitialize={handleInitialize}
                    />
                  )}
                </div>
              </div>
            </div>
            <OutputErrors errors={errors} />
            <div className='d-flex justify-content-end'>
              <Button
                text={literalsCommon.send}
                type='submit'
                loading={loading === 'submit'}
              />
            </div>
          </form>
        </PanelBody>
      </Panel>
    </Tool>
  );
};

AdminReportingNewTemplate.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsPerformance: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default AdminReportingNewTemplate;
