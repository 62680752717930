import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import InputFileZone from 'components/Inputs/inputFileZone';
import Loading from 'components/Loading';
import OutputErrors from 'components/Inputs/outputErrors';
import useDialog from 'components/Dialog/components/useDialog';
import IOFileLoading from 'components/IOFileLoading/components';
import { downloadExcelTemplate, uploadExcelShareholders } from '../modules/actions';

const PopupBulkShareholders = (props) => {
  const {
    literals,
    onSubmit,
    match,
    onClose,
  } = props;

  const [errors, setErrors] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const { dialog } = useDialog();

  const handleDownloadExcel = async () => {
    setDownloadingExcel(true);
    await downloadExcelTemplate(match.params.id);
    setDownloadingExcel(false);
  };

  const handleUpload = async (file) => {
    setErrors(null);
    setUploading(file);
    try {
      const response = await uploadExcelShareholders(match.params.id, file);
      await dialog({
        text: `${response.length} ${literals.shareholdersCreated}`,
        type: 'alert',
      });
      onSubmit();
    } catch (error) {
      setErrors(error);
    }
    setUploading(null);
  };

  return (
    <div>
      <Popup title={literals.bulkShareholders} onClose={onClose}>
        <p onClick={handleDownloadExcel} className='cursor-pointer'>
          {`${literals.downloadTemplate} `}
          <span style={{ textDecoration: 'underline' }}>(shareholders_template.xlsx)</span>
        </p>
        {
          uploading ? (
            <Loading hide={false} mode='dialog' />
          ) : (
            <InputFileZone
              isDisabled={uploading}
              onChange={v => handleUpload(v)}
            />
          )
        }
        <OutputErrors errors={errors} />
      </Popup>
      { downloadingExcel && <IOFileLoading mode='download' file={{ name: 'shareholders_template.xlsx' }} /> }
    </div>
  );
};

PopupBulkShareholders.propTypes = {
  onClose: PropTypes.func.isRequired,
  literals: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

export default PopupBulkShareholders;
