import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Table from 'components/Table';
import Loading from 'components/Loading';
import PermissionLabel from 'components/PermissionsPopup/PermissionLabel';
import { KPI_PERIODICITY, KPI_TREND } from 'constants/kpis';
import { formatDate, getTranslation, startupCanManage } from 'utils/functions';
import { useCounter } from 'utils/customHooks';
import { fetchLocal } from 'components/Table/components/TableFunctions';
import {
  Eye, Pencil, Bookmark, AlertTriangle,
} from 'lucide-react';
import { TOOL_PERFORMANCE } from 'constants/tools';
import { initializeKpiRelations } from '../modules/actions';
import { formatKpiDiff, formatKpiValue } from '../utils/functions';
import PerformanceAddTabCustom from './routes/PerformanceAdd/components/PerformanceAddTabCustom';
import './styles.scss';

const PerformanceSummaryTable = (props) => {
  const {
    literals,
    literalsCommon,
    startup,
    user,
    match,
    performance,
    lang,
    currency,
    fetchKpiRelations,
    postKpiBookmark,
    deleteKpiBookmark,
    setDetailKpiRelation,
    putKpi,
    category,
    mainRefreshFlag = 0,
  } = props;

  const [loading, setLoading] = useState(false);
  const [editKpiPopup, setEditKpiPopup] = useState(false);
  const tableRefreshFlag = useCounter(0);
  const canManagePerformance = startupCanManage(startup, TOOL_PERFORMANCE);

  useEffect(() => {
    initializeKpiRelations(fetchKpiRelations, performance, 'startup', match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    tableRefreshFlag.increase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performance.relations]);

  useEffect(() => {
    if (mainRefreshFlag) {
      tableRefreshFlag.increase();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainRefreshFlag]);

  const pendingQuarter = (month, year) => {
    if (month < 3) {
      return `${+year - 1}-12`;
    }
    if (month < 6) {
      return `${year}-03`;
    }
    if (month < 9) {
      return `${year}-06`;
    }
    if (month < 12) {
      return `${year}-09`;
    }
    return `${year}-${month}`;
  };

  const havePendingValue = (relation) => {
    const [year, month] = formatDate(new Date(), { format: 'Y-m-d' }).split('-');
    return !relation.value.date
      || (relation.kpi.periodicity === KPI_PERIODICITY.MONTHLY && relation.value.date !== `${year}-${month}`)
      || (relation.kpi.periodicity === KPI_PERIODICITY.ANNUAL && (relation.value.date !== (month !== '12' ? (+year - 1).toString() : year)))
      || (relation.kpi.periodicity === KPI_PERIODICITY.QUARTERLY && relation.value.date !== pendingQuarter(month, year));
  };

  const fetchKpis = (page, size, search, filters) => {
    if (category !== 'all') {
      // eslint-disable-next-line no-param-reassign
      filters['kpi.category'] = category;
    } else {
      // eslint-disable-next-line no-param-reassign
      size = 0;
    }

    return fetchLocal(
      { items: performance.relations, total: performance.relations.length },
      { search: ['kpi.name.es', 'kpi.name.en'] },
      page, size, search, filters,
    );
  };

  const handleClickBookmark = async (row, bookmark) => {
    setLoading(row.kpi.id);
    if (!bookmark) {
      await postKpiBookmark(row.kpi.id, row.scope.type, row.scope.owner, row.id);
    } else {
      await deleteKpiBookmark(row.kpi.id, row.scope.type, row.scope.owner, row.id);
    }
    setLoading(false);
  };

  const getDiffValue = (value, vs, kpi) => {
    let diff = value && vs ? ((value - vs) / vs) * 100 : 0;
    if (kpi.positive === KPI_TREND.DOWN) {
      diff *= -1;
    }
    return (
      <div>
        <span>{formatKpiValue(vs, kpi, null, currency)}</span>
        <div>{diff ? formatKpiDiff(diff) : null}</div>
      </div>
    );
  };

  const columns = [
    {
      field: 'kpi',
      label: literals.kpi,
      preRender: (kpi, row) => (
        <>
          {getTranslation(kpi.name, lang)}
          <PermissionLabel permission={row.permission} />
          {row.notes && (
            <p className='fs-extra-small fc-light mb-0 mt-1'>{row.notes}</p>
          )}
        </>
      ),
    },
    {
      field: 'kpi',
      title: kpi => literals[kpi.periodicity],
      width: 33,
      preRender: kpi => (
        <div className='cell-keywords'>
          <div className='d-flex align-items-center'>
            {literals[kpi.periodicity].charAt(0)}
          </div>
        </div>
      ),
    },
    {
      id: 'value.date',
      field: 'value',
      label: literals.lastValue,
      width: 120,
      sortable: true,
      preRender: (value, row) => (
        <div>
          <span className='mr-2'>{value.date || '-'}</span>
          {havePendingValue(row) && <AlertTriangle size={15} />}
        </div>
      ),
    },
    {
      id: 'value.actual',
      field: 'value',
      label: literals.value,
      width: 120,
      sortable: true,
      preRender: (value, row) => formatKpiValue(value.actual, row.kpi, null, currency),
    },
    {
      id: 'value.difference',
      field: 'value',
      label: literals.previous,
      width: 120,
      sortable: true,
      preRender: (value, row) => getDiffValue(value.actual, value.previous, row.kpi),
    },
    {
      field: 'value',
      label: literals.forecast,
      width: 120,
      preRender: (value, row) => getDiffValue(value.actual, value.forecast, row.kpi),
    },
    {
      field: 'target',
      label: literals.target,
      width: 120,
      preRender: (target, row) => getDiffValue(row.value.actual, target, row.kpi),
    },
    {
      field: 'bookmark',
      type: 'button',
      button: {
        text: 'Bookmark',
        icon: row => (
          <Bookmark
            size={20}
            className={loading === row?.kpi?.id ? 'disabled' : ''}
            fill={row.bookmark || loading === row?.kpi?.id ? 'currentColor' : 'none'}
          />
        ),
        onClick: row => handleClickBookmark(row, row.bookmark),
      },
      hidden: () => !canManagePerformance,
    },
    {
      field: 'kpi',
      type: 'menu',
      buttons: [
        {
          icon: Eye,
          text: literalsCommon.view,
          onClick: row => setDetailKpiRelation(row.id),
        },
        {
          icon: Pencil,
          text: literalsCommon.edit,
          onClick: row => setEditKpiPopup(row.kpi),
          hidden: kpi => kpi.scope.owner !== startup.id,
        },
      ],
    },
  ];

  if (!performance.loaded && !performance.relations.length) {
    return <Loading hide={false} mode='tool' />;
  }

  return (
    <div style={{ minHeight: '500px' }}>
      <Table
        key='table_kpi'
        className='table_kpi'
        columns={columns}
        pageSize={10}
        fetch={fetchKpis}
        forceFetch={tableRefreshFlag.value}
        onClickRow={row => setDetailKpiRelation(row.id)}
      />
      {
        editKpiPopup && (
          <Popup title={literals.editKpi} size='large' onClose={() => setEditKpiPopup(false)}>
            <PerformanceAddTabCustom
              match={match}
              startup={startup}
              literals={literals}
              literalsCommon={literalsCommon}
              kpi={editKpiPopup}
              lang={user.lang}
              onSubmit={putKpi}
              onClose={() => setEditKpiPopup(false)}
            />
          </Popup>
        )
      }
    </div>
  );
};

PerformanceSummaryTable.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  performance: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  currency: PropTypes.object.isRequired,
  fetchKpiRelations: PropTypes.func.isRequired,
  postKpiBookmark: PropTypes.func.isRequired,
  deleteKpiBookmark: PropTypes.func.isRequired,
  setDetailKpiRelation: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  putKpi: PropTypes.func.isRequired,
  mainRefreshFlag: PropTypes.number,
};

export default PerformanceSummaryTable;
