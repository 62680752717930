import React from 'react';
import PropTypes from 'prop-types';
import InputNumber from 'components/Inputs/inputNumber';

const PopupFormShareClassLiquidity = (props) => {
  const {
    liquidity,
    onChange,
    literals,
    isDisabled,
  } = props;

  return (
    <>
      <div className='col-md-5'>
        <InputNumber
          name='seniority'
          preText={literals.seniority}
          value={liquidity.seniority}
          minValue={0}
          onChange={value => onChange('seniority', value)}
          isDisabled={isDisabled}
        />
      </div>
      <div className='col-md-4'>
        <InputNumber
          name='multiple'
          preText={literals.multiple}
          value={liquidity.multiple}
          minValue={1}
          onChange={value => onChange('multiple', value)}
          isDisabled={isDisabled}
        />
      </div>
    </>
  );
};

PopupFormShareClassLiquidity.propTypes = {
  liquidity: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  literals: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default PopupFormShareClassLiquidity;
