import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import DocumentsComponents from '../components';

const mapStateToProps = state => ({
  user: state.session.user,
  literals: state.i18n.literals.mentoring,
  literalsCommon: state.i18n.literals.common,
});

export default withRouter(connect(mapStateToProps)(DocumentsComponents));
