import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { fetchCaptableAction } from 'routes/Captable/modules/actions';
import { setGlobalPropertiesAction } from 'store/globalState/global';
import { fetchCompanyAction, fetchMentor } from '../modules/actions';
import HomeComponents from '../components';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.home,
    literalsAdmin: state.i18n.literals.homeAdmin,
    literalsOnboarding: state.i18n.literals.onboarding,
    literalsPerformance: state.i18n.literals.performance,
    literalsMyPosition: state.i18n.literals.myPosition,
    literalsCaptable: state.i18n.literals.captable,
    literalsDataroom: state.i18n.literals.dataRoom,
    literalsReporting: state.i18n.literals.reporting,
    literalsCommon: state.i18n.literals.common,
    user: state.session.user,
    lang: state.session.user.lang,
    startup: state.global.startup,
    companies: state.companies, // Si no están cargadas todas las compañías, las cargamos
    company: state.company,
    platform: state.global.platform,
    currency: state.global.currency,
    captable: state.captable,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCompany: bindActionCreators(fetchCompanyAction, dispatch),
    fetchCaptable: bindActionCreators(fetchCaptableAction, dispatch),
    fetchMentor: bindActionCreators(fetchMentor, dispatch),
    setGlobalProperties: bindActionCreators(setGlobalPropertiesAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponents);
