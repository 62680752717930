import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Table from 'components/Table';
import useDialog from 'components/Dialog/components/useDialog';
import { useNavigate } from 'react-router-dom';
import { PERMISSION_LEVELS } from 'constants/permissions';
import { formatDate, getFullName } from 'utils/functions';
import { useCounter } from 'utils/customHooks';
import { Trash } from 'lucide-react';
import { deleteFundraiseInvite, getFundraiseInvites } from '../modules/actions';
import './styles.scss';

const FundraiseInvitesTab = ({
  literals,
  literalsCommon,
  match,
  loading,
  permission = null,
}) => {
  const { dialog } = useDialog();
  const tableRefreshFlag = useCounter(0);
  const navigate = useNavigate();

  if (!loading && permission?.level < PERMISSION_LEVELS.VIEW) {
    navigate({ hash: 'info' }, { replace: true });
  }

  const fetchFundraiseInvites = () => {
    return getFundraiseInvites(match.params.id);
  };

  const handleDeleteInvite = async (invite) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literals.deleteInvite,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteFundraiseInvite(invite.fundraise, invite.id);
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const columns = [
    {
      field: 'user',
      type: 'avatar',
      width: 45,
      preRender: user => user?.avatar,
      title: (user, row) => (user ? getFullName(user) : row.email),
    },
    {
      field: 'user',
      grow: 3,
      label: literalsCommon.name,
      preRender: (user, row) => (user ? getFullName(user) : row.email),
    },
    {
      field: 'status',
      label: literals.status,
      preRender: status => literals[status],
    },
    {
      field: 'createdAt',
      label: literalsCommon.createdAt,
      preRender: date => formatDate(date),
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Trash,
          text: literalsCommon.delete,
          onClick: row => handleDeleteInvite(row),
        },
      ],
    },
  ];

  return (
    <Panel>
      <PanelHeader title={literals.commitments} />
      <PanelBody>
        <Table
          columns={columns}
          fetch={fetchFundraiseInvites}
          forceFetch={tableRefreshFlag.value}
        />
      </PanelBody>
    </Panel>
  );
};

FundraiseInvitesTab.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  permission: PropTypes.object,
};

export default FundraiseInvitesTab;
