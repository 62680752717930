import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel/components';
import PanelBody from 'components/Panel/components/PanelBody';
import OutputValue from 'components/Inputs/outputValue';
import Loading from 'components/Loading';
import Avatar from 'components/Avatar';
import Button from 'components/Buttons/Button';
import SocialIcon from 'components/SocialIcon';
import useDialog from 'components/Dialog/components/useDialog';
import { createDefaultQuestionsStartup } from 'constants/defaultQuestionsOnboarding';
import { TOOL_PROFILE } from 'constants/tools';
import { getStartupVerifiedIcon, getTranslation, startupCanEdit } from 'utils/functions';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTE_PATH, ROUTE_STARTUP_PATH } from 'routes';
import { STARTUP_ROLE_OWNER } from 'constants/roles';
import { Pencil, Trash } from 'lucide-react';
import { deleteStartup } from '../modules/actions';
import './styles.scss';

function ViewInfo({
  literals,
  literalsCommon,
  match,
  lang,
  forms,
  platform,
  company = null,
  type,
  startup,
  fetchCompany = () => {},
  fetchFormStartup = () => {},
}) {
  const { dialog } = useDialog();
  const navigate = useNavigate();

  useEffect(() => {
    if (!company?.loading) {
      (async () => {
        if (!company || company.company.id !== match.params.id) {
          await fetchCompany(match.params.id);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, match.params.id]);

  useEffect(() => {
    if (forms[type] && !forms[type].loaded && !forms[type].loading) {
      fetchFormStartup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms[type]]);

  const renderQuestions = () => {
    const platformQuestions = platform.properties.defaultQuestions.startup;
    const questions = [
      ...createDefaultQuestionsStartup(literals, literalsCommon, platformQuestions)
        .filter((question) => {
          const startupQuestion = company.company[question.info.id];
          return startupQuestion && (!Array.isArray(startupQuestion) || startupQuestion.length > 0);
        }),
      ...forms[type].questions
        .filter(question => company.company.extra[question.info.id]?.answer),
    ];
    const formAnswers = { ...company.company, extra: {} };

    questions.forEach((question) => {
      if (!question.default) {
        formAnswers.extra[question.info.id] = company.company.extra[question.info.id].answer;
      }
    });

    const handleDeleteStartup = async () => {
      const confirm = await dialog({
        type: 'confirmDanger', text: literals.deleteStartup,
      });

      if (confirm) {
        await dialog({
          type: 'loading',
          execute: () => deleteStartup(match.params.id).then(() => navigate(ROUTE_PATH.PREHOME)),
        });
      }
    };

    return (
      <div className='view-startup'>
        <div className='text-start'>
          <div className='d-flex justify-content-start align-items-center'>
            <Avatar
              title={formAnswers?.name}
              src={formAnswers?.logo?.path}
              className='mr-4'
              size={60}
            />
            <span className='fw-b'>
              {formAnswers.name}
              {getStartupVerifiedIcon(formAnswers.verified)}
            </span>
          </div>
          <div className='my-3'>
            {formAnswers.description}
          </div>
        </div>
        { (formAnswers?.url || Object.values(formAnswers?.social || []).some(Boolean)) && (
          <div className='startup-social-icons'>
            {formAnswers.url && <SocialIcon type='url' link={formAnswers.url} />}
            {Object.keys(formAnswers?.social || {})
              .sort((a, b) => (b === 'other' ? -1 : 1))
              .map(social => formAnswers.social[social] && <SocialIcon key={social} type={social} link={formAnswers.social[social]} />)
            }
          </div>
        )}
        <div className='row'>
          {questions
            .filter(question => !['logo', 'name', 'description', 'social'].includes(question.info.id))
            .map((question) => {
              return (
                <div key={question.id} className={`col-12 ${!['textarea', 'richtext'].includes(question.info.type) ? 'col-md-6' : ''}`}>
                  <OutputValue
                    type={question.info.type}
                    preText={getTranslation(question.info.title, lang)}
                    value={question.default ? formAnswers[question.info.id] : formAnswers.extra[question.info.id]}
                    answers={question.info.answers}
                    lang={lang}
                  />
                </div>
              );
            })}
        </div>
        {
          startupCanEdit(startup, TOOL_PROFILE) ? (
            <div className='buttons'>
              {startup.relation.type === STARTUP_ROLE_OWNER && (
                <Button
                  text={literalsCommon.delete}
                  icon={Trash}
                  color='danger'
                  onClick={handleDeleteStartup}
                />
              )}
              <Link to={ROUTE_STARTUP_PATH.setEditStartup(match.params.id)}>
                <Button text={literalsCommon.edit} icon={Pencil} />
              </Link>
            </div>
          ) : null
        }
      </div>
    );
  };

  return (
    <Panel>
      <PanelBody noHeader>
        { company.loaded && forms[type]?.loaded
          ? renderQuestions()
          : <Loading hide={false} mode='tool' />
        }
      </PanelBody>
    </Panel>
  );
}

ViewInfo.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  forms: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
  company: PropTypes.object,
  type: PropTypes.string.isRequired,
  startup: PropTypes.object.isRequired,
  fetchCompany: PropTypes.func,
  fetchFormStartup: PropTypes.func,
};

export default ViewInfo;
