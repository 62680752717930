import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import Popup from 'components/Popup';
import useDialog from 'components/Dialog/components/useDialog';
import { getFullName } from 'utils/functions';
import { literalTemplate } from 'utils/language';
import { unifyShareholders } from '../modules/actions';
import './styles.scss';

const PopupUnifyShareholders = (props) => {
  const {
    literals,
    literalsCommon,
    startup,
    shareholders,
    onSubmit,
    onClose,
  } = props;

  const { dialog } = useDialog();

  const handleUnifyShareholders = async (sh) => {
    const confirm = await dialog({
      text: literalTemplate(literals.confirmUnifyShareholders, {
        SHAREHOLDERS: shareholders.length,
        SHAREHOLDER: getFullName(sh),
      }),
      type: 'confirmDanger',
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            await unifyShareholders(startup, sh.id, shareholders.map(aux => aux.id));
            onSubmit();
            onClose();
          } catch (errors) {
            await dialog({
              text: literalsCommon.error, type: 'error',
            });
          }
        },
      });
    }
  };

  const columns = [
    {
      field: 'avatar',
      width: 45,
      type: 'avatar',
      label: '',
      title: (_, row) => getFullName(row),
    },
    {
      field: 'firstname',
      grow: 3,
      label: literalsCommon.name,
      preRender: (_, row) => getFullName(row),
      sortable: true,
    },
    {
      field: 'role',
      label: literalsCommon.role,
      width: 150,
      preRender: (v, row) => (
        <div className='cell-keywords'>
          <div key={`role-${row.id}`}>{literals[v]}</div>
        </div>
      ),
    },
  ];

  return (
    <Popup title={literals.unifyShareholders} onClose={onClose}>
      <Table
        searcher={false}
        columns={columns}
        fetch={() => ({ items: shareholders, total: shareholders.length })}
        onClickRow={handleUnifyShareholders}
      />
    </Popup>
  );
};

PopupUnifyShareholders.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  startup: PropTypes.string.isRequired,
  shareholders: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupUnifyShareholders;
