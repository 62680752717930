/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Avatar from 'components/Avatar';
import Button from 'components/Buttons/Button';
import useDialog from 'components/Dialog/components/useDialog';
import { formatDate, getFullName, stripTags } from 'utils/functions';
import { ROUTE_PATH } from 'routes';
import { useNavigate } from 'react-router-dom';
import { Bookmark, CloudDownload, Link } from 'lucide-react';
import { selectType } from './FolderContent';
import { downloadLink } from '../modules/actions';
import PrevVersionsTimeline from './PrevVersionsTimeline';

const PopupInfoFile = (props) => {
  const {
    literals, literalsCommon, onClose, file: auxFile, setContent, handleBookmark,
  } = props;

  const [file, setFile] = useState(auxFile);
  const [loading, setLoading] = useState(false);
  const { dialog } = useDialog();
  const navigate = useNavigate();

  const { name, description } = file;

  const uploadedAt = file.uploadedAt || file.createdAt;
  const uploadedBy = file.uploadedBy || file.createdBy;

  const toogleBookmark = async (item) => {
    setLoading(true);
    try {
      await handleBookmark(item);
      setFile(prev => ({ ...prev, _bookmark: !prev._bookmark }));
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const downloadContent = async () => {
    try {
      if (file._type === 'document') {
        navigate(ROUTE_PATH.setDownloadFile(file.hash, name));
      } else {
        await downloadLink(file.path, name);
      }
    } catch (e) {
      dialog({
        type: 'error',
        text: 'File not found',
      });
    }
  };

  return (
    <Popup title={literals.infoFile} onClose={onClose}>
      <div className='file-info'>
        <div className='row'>
          <div className='col-12 col-md-9 mb-4'>
            <h6 className='fw-b'>{literals.uploadedBy}</h6>
            <div className='d-flex align-items-center'>
              <Avatar
                title={getFullName(uploadedBy)}
                src={uploadedBy?.avatar?.path}
                size={35}
                className='mx-2'
              />
              <span>{getFullName(uploadedBy)}</span>
            </div>
          </div>
          <div className='col-12 col-md-3 mb-4'>
            <h6 className='fw-b'>{literals.uploadedAt}</h6>
            <span>{formatDate(uploadedAt, { time: true })}</span>
          </div>
        </div>
        <div className='file-name mb-4'>
          <h6 className='fw-b'>{literals.title}</h6>
          <div className='d-flex align-items-center'>
            {selectType(file.type || file._type)}
            <div>
              <div>{name}</div>
              {file._type === 'link' && <div className='fc-secondary'>{file.path}</div>}
            </div>
          </div>
        </div>
        <div className='mb-4'>
          <h6 className='fw-b'>{literals.description}</h6>
          <p dangerouslySetInnerHTML={{
            __html: stripTags(description || '').trim()
              ? description : "<p style='margin-left: 20px'>&ndash;</p>",
          }}
          />
        </div>
        {file.previousVersions?.length ? (
          <PrevVersionsTimeline literals={literals} literalsCommon={literalsCommon} file={file} setFile={setFile} setContent={setContent} />
        ) : null}
        <div className='buttons mt-4'>
          <Button
            color='secondary'
            icon={<Bookmark fill={file._bookmark ? 'currentColor' : 'none'} />}
            text={file._bookmark ? literalsCommon.delete : literals.bookmarks}
            onClick={() => toogleBookmark(file)}
            loading={loading}
          />
          <Button
            text={file._type === 'link' ? literals.open : literals.download}
            icon={file._type === 'link' ? Link : CloudDownload}
            onClick={file._type === 'link'
              ? () => window.open(file.path)
              : downloadContent
            }
          />

        </div>
      </div>
    </Popup>
  );
};

PopupInfoFile.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  setContent: PropTypes.func.isRequired,
  handleBookmark: PropTypes.func.isRequired,
};

export default PopupInfoFile;
