import React from 'react';
import PropTypes from 'prop-types';

function ProgressBar({ stepNumber, numberOfSteps }) {
  const progressPercentage = (stepNumber * 100) / numberOfSteps;
  const style = {
    width: `${progressPercentage}%`,
  };
  return (
    <div className='progress-bar'>
      <div className='progress' style={style} />
    </div>
  );
}

ProgressBar.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  numberOfSteps: PropTypes.number.isRequired,
};

export default ProgressBar;
