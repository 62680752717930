import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import EditInfo from '../components';
import {
  editStartup, editMentor, editInvestor,
} from '../modules/actions';
import { fetchCompanyAction } from '../../Home/modules/actions';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.editInfo,
    user: state.session.user,
    company: state.company,
    lang: state.i18n.language,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editStartup: bindActionCreators(editStartup, dispatch),
    editMentor: bindActionCreators(editMentor, dispatch),
    editInvestor: bindActionCreators(editInvestor, dispatch),
    fetchCompany: bindActionCreators(fetchCompanyAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditInfo);
