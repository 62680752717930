import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Loading from 'components/Loading';
import NotFound from 'routes/NotFound';
import Breadcrumb from 'components/Breadcrumb';
import Panel from 'components/Panel/components';
import PanelBody from 'components/Panel/components/PanelBody';
import { getPlatformLegal } from '../modules/actions';
import './styles.scss';

const Legal = ({ match }) => {
  const [legal, setLegal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { type, lang } = match.params;
        const auxLegal = await getPlatformLegal(type, lang);
        setLegal(auxLegal);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params]);

  if (loading) {
    return <Loading hide={false} />;
  }

  if (legal) {
    return (
      <Tool noAside noHeader>
        <Breadcrumb goBack />
        <Panel>
          <PanelBody noHeader>
            <div className='legal-document' dangerouslySetInnerHTML={{ __html: legal }} />
          </PanelBody>
        </Panel>
      </Tool>
    );
  }

  return <NotFound />;
};

Legal.propTypes = {
  match: PropTypes.object.isRequired,
};
export default Legal;
