import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Bookmark } from 'lucide-react';
import { getTranslation } from 'utils/functions';
import { formatKpiDiff, formatKpiValue } from '../utils/functions';

const KpiRelationBox = ({
  relation,
  relation: { kpi },
  lang,
  match,
  currency,
  onClick = null,
  postKpiBookmark = null,
  deleteKpiBookmark = null,
}) => {
  const [loading, setLoading] = useState(false);
  const canEdit = typeof postKpiBookmark === 'function';

  const handleClickBookmark = async (ev, active) => {
    if (canEdit && !loading) {
      ev.stopPropagation();
      setLoading(true);
      if (active) {
        await postKpiBookmark(kpi.id, 'startup', match.params.id, relation.id);
      } else {
        await deleteKpiBookmark(kpi.id, 'startup', match.params.id, relation.id);
      }
      setLoading(false);
    }
  };

  const handleOnClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <div
      key={relation.id}
      className={`kpi-relation-box ${typeof onClick === 'function' ? 'clickable' : ''} ${loading ? 'saving' : ''}`}
      onClick={handleOnClick}
    >
      <h4 className={`d-flex align-items-center ${canEdit ? 'clickable' : ''}`}>
        <Bookmark
          size={18}
          fill={relation.bookmark ? 'currentColor' : 'none'}
          onClick={ev => (canEdit ? handleClickBookmark(ev, !relation.bookmark) : null)}
        />
        {getTranslation(kpi?.name, lang)}
      </h4>
      <div className='kpi-value'>
        <h3>{formatKpiValue(relation.value.actual, kpi, null, currency)}</h3>
        {
          relation.value.difference !== null ? (
            <div className='kpi-arrow'>
              {
                relation.value.difference !== 0
                  ? formatKpiDiff(relation.value.difference)
                  : null
              }
            </div>
          ) : null
        }
      </div>
      <h6 className='kpi-date fs-sm'>{relation.value.date ?? '-'}</h6>
    </div>
  );
};

KpiRelationBox.propTypes = {
  relation: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  currency: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  postKpiBookmark: PropTypes.func,
  deleteKpiBookmark: PropTypes.func,
};

export default KpiRelationBox;
