import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import useDialog from 'components/Dialog/components/useDialog';
import EmptyCaptable from 'assets/svg/empty_captable.svg';
import { formatDate } from 'utils/functions';
import { useCounter } from 'utils/customHooks';
import { canEditCaptable } from 'utils/functions/canEditCaptable';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import { initializeCaptable } from 'routes/Captable/modules/actions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Eye, Pencil, PlusCircle, Trash,
} from 'lucide-react';
import { deleteCaptableSimulation, getCaptableSimulations } from '../modules/actions';
import SimulationPopup from './createSimulationPopup';
import './styles.scss';

const SimulationsTab = (props) => {
  const {
    literals,
    literalsCommon,
    captable,
    fetchCaptable,
    startup,
  } = props;

  const [createPopup, setCreatePopup] = useState(false);
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const tableRefreshFlag = useCounter(0);
  const navigate = useNavigate();
  const { dialog } = useDialog();
  const canEdit = canEditCaptable(captable);

  useEffect(() => {
    initializeCaptable(fetchCaptable, captable, startup.id, captableId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSimulations = useCallback((page, size, search, filters, sort) => {
    return getCaptableSimulations(startup.id, {
      page, size, search, filters, sort, captable: captableId,
    });
  }, [captableId, startup.id]);

  const deleteSimulation = async (simulation) => {
    const confirm = await dialog({
      text: literals.deleteSimulation,
      type: 'confirmDanger',
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteCaptableSimulation(startup.id, simulation);
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const columns = [
    {
      field: 'name',
      label: literalsCommon.name,
      grow: 3,
    },
    {
      field: 'createdAt',
      label: literalsCommon.createdAt,
      preRender: v => formatDate(v, { format: 'Y-m-d' }),
      width: 120,
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Eye,
          text: literalsCommon.view,
          onClick: row => navigate(embedView(ROUTE_STARTUP_PATH.setCapTableSimulation(startup.id, captableId, row.id))),
        },
        {
          icon: Pencil,
          text: literalsCommon.edit,
          onClick: setCreatePopup,
        },
        {
          icon: Trash,
          text: literalsCommon.delete,
          onClick: row => deleteSimulation(row.id),
          hidden: !canEdit,
        },
      ],
    },
  ];

  if (!captable.loading && !captable?.operations?.length) {
    return (
      <div className='panel-empty-message full'>
        <img src={EmptyCaptable} alt='' />
        <p>{literals.emptyCaptable}</p>
      </div>
    );
  }

  return (
    <>
      <Table
        columns={columns}
        fetch={fetchSimulations}
        onClickRow={row => navigate(embedView(ROUTE_STARTUP_PATH.setCapTableSimulation(startup.id, captableId, row.id)))}
        forceFetch={tableRefreshFlag.value}
        actions={[{
          icon: PlusCircle,
          text: literalsCommon.new,
          onClick: () => setCreatePopup({ name: '', lastConfirmed: captable.operations[0].id }),
        }]}
      />
      { createPopup && (
        <SimulationPopup
          literals={literals}
          literalsCommon={literalsCommon}
          simulation={createPopup}
          startup={startup.id}
          captable={captable}
          onClose={() => setCreatePopup(false)}
          onSubmit={tableRefreshFlag.increase}
        />
      )}
    </>
  );
};

SimulationsTab.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  fetchCaptable: PropTypes.func.isRequired,
};

export default SimulationsTab;
