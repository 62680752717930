import StartupsInstance from 'modules/startups/repository';

export function joinToStartup(id, external = false, guest = false) {
  return StartupsInstance.joinToStartup(id, { external, guest });
}

export function requestStartupAccess(id, type) {
  return StartupsInstance.requestStartupAccess(id, { type });
}

export function shareStartup(id, form) {
  return StartupsInstance.shareStartup(id, form);
}
