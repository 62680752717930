import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initPlatformAction } from '../../../store/globalState/global';

import LegalComponents from '../components';

function mapStateToProps(state) {
  return {
    platform: state.global.platform,
    literals: state.i18n.literals.legal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initPlatform: bindActionCreators(initPlatformAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LegalComponents);
