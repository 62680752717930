import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const InvestmentsInstance = ApiFetch();

export const URLS = {
  INVESTMENTS: 'investments',
  INVESTMENTS_CHART: 'investments/chart',
  INVESTMENTS_KPIS: 'investments/kpis',
  INVESTMENTS_SHAREHOLDERS: 'investments/shareholders',
  INVESTMENTS_STATS: 'investments/stats',
  KPI_STARTUPS_COMPARATOR: 'investments/kpis/:kpi',
};

async function editInvestmentsShareholders(data) {
  return InvestmentsInstance.put(URLS.INVESTMENTS_SHAREHOLDERS, data);
}

async function getInvestments(params = {}) {
  const path = preparePath(URLS.INVESTMENTS, {}, params);
  return InvestmentsInstance.get(path);
}

async function getInvestmentsChart(params = {}) {
  const path = preparePath(URLS.INVESTMENTS_CHART, {}, params);
  return InvestmentsInstance.get(path);
}

async function getInvestmentsKpis(params = {}) {
  const path = preparePath(URLS.INVESTMENTS_KPIS, {}, params);
  return InvestmentsInstance.get(path);
}

async function getInvestmentsShareholders(params = {}) {
  const path = preparePath(URLS.INVESTMENTS_SHAREHOLDERS, {}, params);
  return InvestmentsInstance.get(path);
}

async function getInvestmentsStats(params = {}) {
  const path = preparePath(URLS.INVESTMENTS_STATS, {}, params);
  return InvestmentsInstance.get(path);
}

async function getKpiStartupsComparator(kpi, params = {}) {
  const path = preparePath(URLS.KPI_STARTUPS_COMPARATOR, { kpi }, params);
  return InvestmentsInstance.get(path);
}

export default {
  editInvestmentsShareholders,
  getInvestments,
  getInvestmentsChart,
  getInvestmentsKpis,
  getInvestmentsShareholders,
  getInvestmentsStats,
  getKpiStartupsComparator,
};
