import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Loading from 'components/Loading';
import Button from 'components/Buttons/Button';
import Table from 'components/Table/';
import InputFileZone from 'components/Inputs/inputFileZone';
import useDialog from 'components/Dialog/components/useDialog';
import IOFileLoading from 'components/IOFileLoading/components';
import { getTranslation } from 'utils/functions';
import { literalTemplate } from 'utils/language';
import { CloudUpload } from 'lucide-react';
import { createKpisMultipleValues, downloadExcelKpis, processModelKpisExcel } from '../modules/actions';

const PopupUploadExcelKpis = (props) => {
  const {
    match,
    literals,
    literalsCommon,
    startup,
    lang,
    relations,
    fetchKpiRelations,
    onClose,
    refreshTable,
  } = props;

  const [excel, setExcel] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [kpiNames, setKpiNames] = useState({});
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const { dialog } = useDialog();

  useEffect(() => {
    const auxKpiNames = {};
    relations.forEach((relation) => {
      auxKpiNames[relation.kpi.id] = getTranslation(relation.kpi.name, lang);
    });
    setKpiNames(auxKpiNames);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitNewValues = async () => {
    const confirm = await dialog({
      type: 'confirm', text: literals.confirmChanges,
    });

    if (confirm) {
      setLoading(true);
      createKpisMultipleValues('startup', startup.id, { data: data.values })
        .then(async () => {
          await dialog({ type: 'alert', text: literals.successChanges });
          await fetchKpiRelations('startup', startup.id);
          refreshTable();
          onClose();
        })
        .catch(async () => {
          await dialog({ type: 'error', text: literals.errorChanges });
          setLoading(false);
        });
    }
  };

  const onFileUpload = async (file) => {
    if (file) {
      setLoading(true);
      setData(null);
      setExcel(file);
      const response = await processModelKpisExcel('startup', startup.id, file);
      const auxData = {};
      if (!response.errors.length) {
        Object.keys(response.values).forEach((key) => {
          const kpiValue = response.values[key];
          if (!auxData[kpiValue.relation]) {
            auxData[kpiValue.relation] = {
              name: kpiValue.name, newValues: 0, editedValues: 0, deletedValues: 0,
            };
          }
          if (kpiValue.id && kpiValue.value) {
            auxData[kpiValue.relation].editedValues += 1;
          } else if (kpiValue.id && !kpiValue.value) {
            auxData[kpiValue.relation].deletedValues += 1;
          } else {
            auxData[kpiValue.relation].newValues += 1;
          }
        });
        setData({ values: response.values, table: Object.values(auxData) });
      } else {
        setData({ errors: response.errors });
      }
      setLoading(false);
    }
  };

  const columns = [
    { field: 'name', label: literals.name },
    { field: 'newValues', label: literals.newValues, width: '130px' },
    { field: 'editedValues', label: literals.editedValues, width: '130px' },
    { field: 'deletedValues', label: literals.deletedValues, width: '130px' },
  ];

  const handleDownloadExcel = async () => {
    setDownloadingExcel(true);
    await downloadExcelKpis('startup', match.params.id, startup.name);
    setDownloadingExcel(false);
  };

  const renderChanges = () => {
    if (!data) {
      return loading
        ? <Loading mode='panel' hide={false} />
        : (
          <>
            <span className='h6 fw-b link-effect' onClick={handleDownloadExcel}>{literals.exportExcel}</span>
            <span className='h6 fw-b'>{` ${literals.uploadChanges}`}</span>
            <InputFileZone preText={literals.uploadInfo} onChange={onFileUpload} value={excel} />
            { downloadingExcel && <IOFileLoading mode='download' file={{ name: `KPIs_${startup.name}.xlsx` }} /> }
          </>
        );
    }

    if (data?.errors?.length) {
      const errors = data.errors.map((kpiErr) => {
        if (kpiErr.error === 'kpi_not_found') {
          return literalTemplate(`<span>${literals.errKpi}</span>`, {
            ROW: `<span class='fw-b'>${kpiErr.row}</span>`,
            KPI: kpiErr.name ? `<span class='fw-b'>${kpiErr.name}</span>` : ' ',
          });
        }

        let auxError = '';
        if (kpiErr.error === 'value_greater_than_max') {
          auxError = literals.errMax;
        } else if (kpiErr.error === 'value_less_than_min') {
          auxError = literals.errMin;
        } else if (kpiErr.error === 'value_must_be_an_integer') {
          auxError = literals.errInteger;
        } else if (kpiErr.error === 'value_must_be_a_number') {
          auxError = literals.errNumber;
        }

        return literalTemplate(`<span>${literals.kpiValueErr}</span>`, {
          KPI: `<span class='fw-b'>${kpiNames[kpiErr.kpi]}</span>`,
          ERROR: `<span>${auxError}</span>`,
          DATE: `<span class='fw-b'>${kpiErr.date}</span>`,
        });
      });

      return (
        <>
          <h6 className='fw-b mb-4'>{literals.excelErrors}</h6>
          {errors.map(err => <div className='mb-1' dangerouslySetInnerHTML={{ __html: err }} />)}
          <div className='buttons'>
            <Button text={literalsCommon.close} onClick={onClose} loading={loading} />
          </div>
        </>
      );
    }

    return (
      <>
        <h6 className='fw-b mb-1'>{literals.excelConfirm}</h6>
        <Table
          key='table_values'
          searcher={false}
          columns={columns}
          fetch={() => ({ items: data.table, total: data.table.length })}
        />
        <div className='buttons'>
          <Button text={literalsCommon.send} icon={CloudUpload} onClick={submitNewValues} loading={loading} />
        </div>
      </>
    );
  };

  return (
    <Popup title={literals.uploadExcel} onClose={onClose}>
      { renderChanges() }
    </Popup>
  );
};

PopupUploadExcelKpis.propTypes = {
  match: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  relations: PropTypes.array.isRequired,
  fetchKpiRelations: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshTable: PropTypes.func.isRequired,
};

export default PopupUploadExcelKpis;
