import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Table from 'components/Table';
import Button from 'components/Buttons/Button';
import OutputValue from 'components/Inputs/outputValue';
import { KPI_TREND } from 'constants/kpis';
import { Plus } from 'lucide-react';
import { formatNumber, getTranslation } from 'utils/functions';
import { formatKpiDiff } from 'routes/Performance/utils/functions';
import { fetchKpiRelations } from '../modules/actions';
import './styles.scss';

const InputKPIs = (props) => {
  const {
    literals, preText, match, kpis, setKpis, lang, currency,
  } = props;

  const [showPopup, setShowPopup] = useState(false);
  const [selectedRows, setSelectedRows] = useState(kpis);

  const getDiffValue = (value, ref, kpi) => {
    let diff = ref && value ? ((ref - value) * 100 / value) : 0;
    diff = kpi.positive === KPI_TREND.UP ? diff : -diff;
    return (
      <div>
        <span className='mr-2'>{formatNumber(value, '-', { symbol: kpi.unit === 'currency' ? currency.symbol : '' })}</span>
        <br />
        {formatKpiDiff(diff)}
      </div>
    );
  };

  const columns = [
    {
      field: 'kpi',
      label: literals.kpi,
      preRender: kpi => getTranslation(kpi.name, lang),
    },
    {
      field: 'value',
      label: literals.lastValue,
      width: 120,
      preRender: value => (
        <div>
          <span className='mr-3'>{value.date || '-'}</span>
        </div>
      ),
    },
    {
      field: 'value',
      label: literals.value,
      width: 120,
      preRender: (value, row) => (
        `${value.actual ? `${value.actual.toLocaleString()}${row.kpi.unit === 'currency' ? currency.symbol : ''}` : '-'}`
      ),
    },
    {
      field: 'value',
      label: literals.previous,
      width: 120,
      preRender: (value, row) => getDiffValue(value.previous, value.actual, row.kpi),
    },
    {
      field: 'value',
      label: literals.forecast,
      width: 120,
      preRender: (value, row) => getDiffValue(value.forecast, value.actual, row.kpi),
    },
    {
      field: 'target',
      label: literals.target,
      width: 120,
      preRender: (target, row) => getDiffValue(target, row.value.actual, row.kpi),
    },
  ];

  const fetchKpis = (page, size, search, filters, sort) => {
    return fetchKpiRelations('startup', match.params.id, {
      page, size, search, filters, sort,
    });
  };

  const handleSelect = () => {
    setKpis(selectedRows);
    setShowPopup(false);
  };

  return (
    <div>
      <div className='inputKPIs-box' onClick={() => setShowPopup(true)}>
        <OutputValue
          preText={preText}
          value={`${kpis.length} ${preText}`}
        />
        <span className='inputKPIs-button'>
          <Button className='btn-icon-square' icon={Plus} />
        </span>
      </div>

      {showPopup && (
        <Popup
          size='large'
          title={`${literals.add} ${preText.toLowerCase()}`}
          onClose={() => setShowPopup(false)}
        >
          <Table
            className='table_kpi'
            searcher={false}
            columns={columns}
            fetch={fetchKpis}
            selectRows={selectedRows}
            selectFields={['id', { name: 'kpi', field: 'kpi.id' }]}
            onSelectRows={setSelectedRows}
          />
          <div className='d-flex justify-content-end mt-2'>
            <Button
              text={`seleccionar (${selectedRows.length})`}
              onClick={handleSelect}
            />
          </div>
        </Popup>
      )}
    </div>
  );
};

InputKPIs.propTypes = {
  literals: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  preText: PropTypes.string.isRequired,
  kpis: PropTypes.array.isRequired,
  setKpis: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  currency: PropTypes.object.isRequired,
};

export default InputKPIs;
