import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelHeader from 'components/Panel/components/PanelHeader';
// import PanelTab from 'components/Panel/components/PanelTab';
// import PerformanceAddTabKpisUser from './PerformanceAddTabKpisUsers';
import PerformanceAddTabKpisStartup from './PerformanceAddTabKpisStartup';

// const STARTUP = 'startup';
// const USER = 'user';

const PerformanceAddTabKpis = (props) => {
  // const { literals, literalsCommon } = props;

  // const tabs = [
  //   { key: STARTUP, name: literalsCommon.startup },
  //   { key: USER, name: literalsCommon.user },
  // ];

  // const subtitle = {
  //   [STARTUP]: literals.startupKpis,
  //   [USER]: literals.userKpis,
  // };

  return (
    <Panel>
      {/* <PanelHeader title='KPIs' subtitle={subtitle} tabs={tabs} /> */}
      <PanelHeader title='KPIs' />
      <PanelBody>
        {/* <PanelTab key='startup'> */}
        <PerformanceAddTabKpisStartup {...props} />
        {/* </PanelTab>
        <PanelTab key='user'>
          <PerformanceAddTabKpisUser {...props} />
        </PanelTab> */}
      </PanelBody>
    </Panel>
  );
};

PerformanceAddTabKpis.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  performance: PropTypes.object.isRequired,
  postKpi: PropTypes.func.isRequired,
  postKpisRelations: PropTypes.func.isRequired,
  startup: PropTypes.object.isRequired,
};

export default PerformanceAddTabKpis;
