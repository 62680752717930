/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputSelect from 'components/Inputs/inputSelect';
import InputNumber from 'components/Inputs/inputNumber';
import InputDate from 'components/Inputs/inputDate';
import OutputValue from 'components/Inputs/outputValue';
import useDialog from 'components/Dialog/components/useDialog';
import Button from 'components/Buttons/Button';
import { useParams, useSearchParams } from 'react-router-dom';
import { literalTemplate } from 'utils/language';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  GripVertical, UserPlus, PieChart, PlusCircle, Trash,
} from 'lucide-react';
import {
  cloneObject, formatDate, formatNumber, setObjectValue,
} from 'utils/functions';
import PopupFormShareholder from '../../shareHolders/components/PopupFormShareholder';
import PopupFormVestingPlan from '../../vestingPlans/components/PopupFormVestingPlan';
import { fetchCaptableShares } from '../modules/actions';

const defaultObjectShares = {
  id: null,
  shares: null,
  shareholder: null,
  number: null,
  numeration: {
    from: 0,
    to: 0,
  },
};

const defaultObjectOptions = {
  id: null,
  option: null,
  shareholder: null,
  vestingPlan: {},
  number: null,
};

const ShareholdersAssignments = (props) => {
  const {
    id,
    mode,
    literals,
    literalsCommon,
    captable,
    dateOrID,
    currency,
    editable,
    number,
    assignments,
    pps,
    numeration,
    onChange,
    forceAuto,
  } = props;

  const [auto, setAuto] = useState(forceAuto && !assignments.some(ass => ass?.numeration?.from));
  const [firstTime, setFirstTime] = useState(true);
  const [popupShareholder, setPopupShareholder] = useState(false);
  const [popupVestingPlan, setPopupVestingPlan] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const { dialog } = useDialog();
  const params = useParams();

  const reasignNumeration = (auxAssigments) => {
    if (mode.type === 'shares') {
      let itNumber = parseInt(numeration?.from || 1, 10);
      auxAssigments.forEach((assignment) => {
        const asNumber = parseInt(assignment.number, 10);
        // eslint-disable-next-line no-param-reassign
        assignment.numeration.from = asNumber ? itNumber : 0;
        // eslint-disable-next-line no-param-reassign
        assignment.numeration.to = assignment.number ? (assignment.numeration.from + asNumber - 1) : 0;

        itNumber = assignment.numeration.to + 1;
      });
    }
    return auxAssigments;
  };

  useEffect(() => {
    if (mode.type === 'shares') {
      if (firstTime) {
        setFirstTime(false);
      } else {
        onChange(!forceAuto
          ? reasignNumeration([...assignments])
          : assignments.map(assignment => ({
            ...assignment, numeration: { from: null, to: null },
          })));
        setAuto(forceAuto);
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceAuto]);

  const handleAddRow = () => {
    const newShareholder = {
      ...(mode.type === 'shares' ? cloneObject(defaultObjectShares) : cloneObject(defaultObjectOptions)),
      id: `new.${new Date().getTime()}`,
      [mode.type === 'shares' ? 'shares' : 'option']: id,
    };
    onChange([...assignments, newShareholder]);
  };

  const handleDeleteRow = (i) => {
    const newAssignments = [...assignments];
    newAssignments.splice(i, 1);
    onChange(newAssignments);
  };

  const pending = number > 0 ? (number - assignments.reduce((prev, curr) => prev + (curr.number !== '' ? curr.number : 0), 0)) : 0;

  const handlefillProrata = async () => {
    const unsetShareholders = assignments
      .filter(assig => assig.shareholder && assig.number === null)
      .map(assig => assig.shareholder);

    if (number > 0 && unsetShareholders.length) {
      setLoading(true);
      fetchCaptableShares(params.id, dateOrID || formatDate(new Date(), { format: 'Y-m-d' }), {
        group: 'shareholder', size: 0, filters: { shareholder: unsetShareholders }, captable: captableId,
      }).then((auxShareholders) => {
        let totalShares = 0;
        const shareholdersShares = {};
        auxShareholders.items.forEach((sh) => {
          shareholdersShares[sh.id] = sh.number;
          totalShares += sh.number;
        });

        const newAssignments = assignments.map((assig) => {
          if (assig.shareholder && assig.number === null && shareholdersShares[assig.shareholder]) {
            return { ...assig, number: Math.floor(pending * shareholdersShares[assig.shareholder] / totalShares) };
          }
          return assig;
        });

        onChange(newAssignments);
        const pendingsShares = number - newAssignments.reduce((prev, curr) => prev + (curr.number !== '' ? curr.number : 0), 0);
        if (pendingsShares > 0) {
          dialog({
            type: 'alert',
            text: literalTemplate(literals.pendingShares, {
              SHARES: pendingsShares,
            }),
          });
        }
        setLoading(false);
      });
    }
  };

  const handleChangeAutomatic = () => {
    setAuto(!auto);
    if (auto) {
      onChange(reasignNumeration([...assignments]));
    } else {
      onChange(assignments.map(assignment => ({
        ...assignment, numeration: { from: null, to: null },
      })));
    }
  };

  const handleChangeValue = (i, prop, value) => {
    const newAssignments = [...assignments];
    if (prop === 'vestingPlan.id' && !value) {
      newAssignments[i] = { ...newAssignments[i], vestingPlan: null };
    } else {
      newAssignments[i] = setObjectValue(newAssignments[i], prop, value);
    }
    onChange(newAssignments);
  };

  const handleBlurNumeration = (i) => {
    if (mode.type === 'shares') {
      const newAssignments = [...assignments];
      const auxNumber = newAssignments[i]?.number || 0;
      const from = newAssignments[i].numeration?.from || 0;
      newAssignments[i].numeration.to = auxNumber + from - 1;
      onChange(newAssignments);
    }
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination || source.index === destination.index) {
      return;
    }

    const newAssignments = [...assignments];

    const [removed] = newAssignments.splice(source.index, 1);
    newAssignments.splice(destination.index, 0, removed);

    onChange(newAssignments);
  };

  const getTotal = (assignment) => {
    return formatNumber(assignment.number * pps, 0, { dec: 2 });
  };

  const getPending = () => {
    return `${formatNumber(pending, 0)} ${literals.pending}`;
  };

  return (
    <>
      <div className={`shares-assigments ${editable ? 'editable' : ''}`}>
        <div className='shares-assigments-header'>
          <span>{getPending()}</span>
          {
            (editable && mode.type === 'shares') && (
              <>
                <div className='sh-prorata'>
                  <Button
                    className='btn-sm'
                    icon={PieChart}
                    color='secondary'
                    text={literals.prorataDistribution}
                    onClick={handlefillProrata}
                    loading={loading}
                  />
                </div>
                {
                  mode.subType === 'secondaries' && (
                    <div className='sh-automatic'>
                      <input
                        type='checkbox'
                        checked={auto}
                        onChange={handleChangeAutomatic}
                      />
                      <span>{literals.automaticNumeration}</span>
                    </div>
                  )
                }
              </>
            )
          }
        </div>
        <div className='shares-assigments-table'>
          <div className='sat-overflow'>
            <div className='sh-header'>
              <div className='cell-sh'><span>{literals.shareholder}</span></div>
              <div><span>{literals.number}</span></div>
              {
                mode.type === 'shares' ? (
                  <>
                    { !auto && (
                      <>
                        <div><span>{literals.from}</span></div>
                        <div><span>{literals.to}</span></div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className={!assignments.some(ass => ass.vestingPlan?.id) ? 'cell-vp' : ''}>
                      <span>{literals.vestingPlan}</span>
                    </div>
                    {
                      assignments.some(ass => ass.vestingPlan?.id) && (
                        <div><span>{literals.vestingStart}</span></div>
                      )
                    }
                  </>
                )
              }
              <div><span>{literalsCommon.total}</span></div>
            </div>
            <DragDropContext className='sh-list' onDragEnd={handleDragEnd}>
              <Droppable key={`assignments-${id}`} droppableId={`assignments-${id}`} isDropDisabled={!editable}>
                {provider => (
                  <>
                    <ul {...provider.droppableProps} ref={provider.innerRef}>
                      {
                        assignments.map((assignment, i) => (
                          <Draggable key={assignment.id} draggableId={assignment.id} index={i} isDragDisabled={!editable}>
                            {
                              draggableProvider => (
                                <li
                                  className='li-shareholder'
                                  ref={draggableProvider.innerRef}
                                  {...draggableProvider.draggableProps}
                                  {...draggableProvider.dragHandleProps}
                                >
                                  {
                                    editable && (
                                      <GripVertical className='move-row' />
                                    )
                                  }
                                  <div className='cell-sh'>
                                    <InputSelect
                                      options={captable.shareholders}
                                      className='mb-0'
                                      placeholder={`#${i + 1} ${literals.shareholder}`}
                                      value={assignment.shareholder}
                                      onChange={v => handleChangeValue(i, 'shareholder', v)}
                                      isDisabled={!editable}
                                      button={(
                                        <Button
                                          text={literalsCommon.create}
                                          icon={PlusCircle}
                                          onClick={() => setPopupShareholder(i)}
                                        />
                                      )}
                                    />
                                  </div>
                                  <div>
                                    <InputNumber
                                      className='mb-0'
                                      preText={literals.number}
                                      value={assignment.number}
                                      placeholder='0'
                                      minValue={0}
                                      maxValue={pending + assignment.number}
                                      onChange={v => handleChangeValue(i, 'number', v ? parseInt(v, 10) : null)}
                                      onBlur={() => handleBlurNumeration(i)}
                                      isDisabled={!editable}
                                      decimals={0}
                                    />
                                  </div>
                                  {
                                    mode.type === 'shares' ? (
                                      <>
                                        { !auto && (
                                          <>
                                            <div>
                                              <InputNumber
                                                className='mb-0'
                                                minValue={0}
                                                preText={`#${literals.from}`}
                                                value={assignment?.numeration?.from}
                                                onChange={v => handleChangeValue(i, 'numeration.from', v)}
                                                onBlur={() => handleBlurNumeration(i)}
                                                isDisabled={!editable || auto}
                                                decimals={0}
                                              />
                                            </div>
                                            <div>
                                              <InputNumber
                                                className='mb-0'
                                                minValue={0}
                                                preText={`#${literals.to}`}
                                                value={assignment?.numeration?.to}
                                                onChange={v => handleChangeValue(i, 'numeration.to', v)}
                                                isDisabled
                                                decimals={0}
                                              />
                                            </div>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <div className={!assignment.vestingPlan?.id ? 'cell-vp' : ''}>
                                          <InputSelect
                                            className='mb-0'
                                            options={[{ id: false, name: literalsCommon.no }, ...captable.vestingPlans]}
                                            value={assignment.vestingPlan?.id || false}
                                            onChange={v => handleChangeValue(i, 'vestingPlan.id', v)}
                                            button={(
                                              <Button
                                                text={literalsCommon.create}
                                                icon={PlusCircle}
                                                onClick={() => setPopupVestingPlan(i)}
                                              />
                                            )}
                                            isDisabled={!editable}
                                          />
                                        </div>
                                        {
                                          assignment.vestingPlan?.id && (
                                            <div>
                                              <InputDate
                                                className='mb-0'
                                                value={assignment.vestingPlan?.startDate}
                                                onChange={v => handleChangeValue(i, 'vestingPlan.startDate', v)}
                                                isDisabled={!editable}
                                              />
                                            </div>
                                          )
                                        }
                                      </>
                                    )
                                  }
                                  <div>
                                    <OutputValue
                                      className='mb-0'
                                      preText={literalsCommon.total}
                                      value={getTotal(assignment)}
                                      symbol={currency.symbol}
                                    />
                                  </div>
                                  {
                                    editable && (
                                      <Trash size={16} className='delete-row' onClick={() => handleDeleteRow(i)} />
                                    )
                                  }
                                </li>
                              )
                            }
                          </Draggable>
                        ))
                      }
                    </ul>
                    {provider.placeholder}
                  </>
                )}
              </Droppable>
            </DragDropContext>
            {
              editable && (
                <div className='add-shareholder' onClick={handleAddRow}>
                  <UserPlus size={16} />
                  {literals.shareholder}
                </div>
              )
            }
          </div>
        </div>
      </div>
      {
        (popupShareholder !== false) && (
          <PopupFormShareholder
            onClose={() => setPopupShareholder(false)}
            onSubmit={sh => handleChangeValue(popupShareholder, 'shareholder', sh.id)}
          />
        )
      }
      {
        (popupVestingPlan !== false) && (
          <PopupFormVestingPlan
            onClose={() => setPopupVestingPlan(false)}
            onSubmit={sh => handleChangeValue(popupVestingPlan, 'vestingPlan.id', sh.id)}
          />
        )
      }
    </>
  );
};

ShareholdersAssignments.propTypes = {
  id: PropTypes.string.isRequired,
  mode: PropTypes.object,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  assignments: PropTypes.array,
  editable: PropTypes.bool,
  number: PropTypes.number,
  pps: PropTypes.number,
  numeration: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  dateOrID: PropTypes.string,
  forceAuto: PropTypes.bool,

};

ShareholdersAssignments.defaultProps = {
  mode: { type: 'shares', subType: 'issue' },
  assignments: [],
  number: 0,
  pps: 0,
  editable: true,
  numeration: { from: null, to: null },
  dateOrID: formatDate(new Date(), { format: 'Y-m-d' }),
  forceAuto: true,
};

export default ShareholdersAssignments;
