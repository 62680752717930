import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import InputPerms from 'components/Inputs/inputPerms';
import InputToggle from 'components/Inputs/inputToggle';
import OutputValue from 'components/Inputs/outputValue';
import Button from 'components/Buttons/Button';
import Loading from 'components/Loading';
import Table from 'components/Table';
import Alert from 'components/Alert';
import useDialog from 'components/Dialog/components/useDialog';
import OutputErrors from 'components/Inputs/outputErrors';
import { useCounter } from 'utils/customHooks';
import { getFullName } from 'utils/functions';
import { editUserStartup } from 'components/InviteUsers/modules/actions';
import {
  CircleSlash, Eye, Pencil, Crown, Trash, BarChart3,
  FileText, Landmark, Clipboard, Building, Users,
  Puzzle, Printer, MessageCircleMore, MessageSquareText,
} from 'lucide-react';
import { addUserToGroup, getUserGroups, removeUserFromGroup } from './modules/actions';
import PermissionsSearcher from './PermissionsSearcher';
import './styles.scss';

const PermissionsUser = (props) => {
  const {
    scope,
    user,
    onSubmit,
    onClose,
    literals,
    literalsCommon,
  } = props;

  const { extra, ...initialPerms } = user.permissions;
  const [groups, setGroups] = useState([]);
  const [permissions, setPermissions] = useState(initialPerms);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState();
  const [morePermissions, setMorePermission] = useState(extra);
  const tableRefreshFlag = useCounter(-1);
  const { dialog } = useDialog();

  useEffect(() => {
    (async () => {
      const auxGroups = await getUserGroups(scope.id, user.id);
      setGroups(auxGroups);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    tableRefreshFlag.increase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  const toolIcons = {
    capTable: Clipboard,
    fundraise: Landmark,
    performance: BarChart3,
    dataRoom: FileText,
    mentoring: MessageSquareText,
    reporting: Printer,
    users: Users,
    profile: Building,
    services: Puzzle,
    messages: MessageCircleMore,
  };

  const levelIcons = [
    CircleSlash,
    Eye,
    Pencil,
    Crown,
  ];

  const handleSubmit = async () => {
    const emptyPermissions = {};
    Object.keys(toolIcons).forEach((tool) => {
      emptyPermissions[tool] = 0;
    });

    setSaving(true);
    const response = await editUserStartup(scope.id, user.id, {
      permissions: morePermissions ? permissions : emptyPermissions,
    });

    setSaving(false);
    if (response) {
      onSubmit(response);
      onClose();
    }
  };

  const handleAddUser = async (group) => {
    await dialog({
      type: 'loading',
      execute: async () => {
        await addUserToGroup(scope, group.id, user.id)
          .then((newGroup) => {
            setGroups(prev => ([...prev, newGroup]));
          })
          .catch(err => dialog({ text: <OutputErrors errors={err} text />, type: 'error' }));
      },
    });
  };

  const handleDeleteUser = async (group) => {
    await dialog({
      type: 'loading',
      execute: async () => {
        await removeUserFromGroup(scope, group.id, user.id)
          .then(() => {
            setGroups(prev => prev.filter(gr => gr.id !== group.id));
          })
          .catch(err => dialog({ text: <OutputErrors errors={err} text />, type: 'error' }));
      },
    });
  };

  const renderRows = () => {
    const columns = [
      {
        field: 'name',
        grow: 1,
        label: literalsCommon.name,
      },
      ...Object.keys(toolIcons).map(tool => (
        {
          field: tool,
          width: '30px',
          title: literals.tools[tool],
          label: React.createElement(toolIcons[tool], { size: 16 }),
          preRender: (_, row) => React.createElement(levelIcons[row?.permissions[tool] ?? 0], { size: 16, title: literals.levels[row?.permissions[tool] ?? 0] }),
        }
      )),
      {
        field: 'id',
        type: 'menu',
        buttons: [{
          icon: Trash,
          text: literalsCommon.delete,
          onClick: handleDeleteUser,
          disabled: (_, row) => row.default,
        }],
      },
    ];

    return (
      <Table
        columns={columns}
        searcher={false}
        fetch={() => ({ items: groups, total: groups.total })}
        forceFetch={tableRefreshFlag.value}
      />
    );
  };

  if (loading) {
    return (
      <Popup title={literals.editUserPermissions} onClose={onClose}>
        <Loading mode='panel' hide={false} />
      </Popup>
    );
  }

  return (
    <Popup title={literals.editUserPermissions} onClose={onClose}>
      <div className='permissions-popup'>

        <OutputValue
          preText={literalsCommon.name}
          value={getFullName(user)}
        />
        <PermissionsSearcher
          literals={literals}
          preText={literals.addGroup}
          placeholder={literals.searchGroups}
          scope={scope}
          type={['groups']}
          exclude={{ groups: groups.map(aux => aux.id) }}
          isDisabled={loading}
          onAdd={handleAddUser}
          confirm
        />
        <div className='fw-b fc-light mb-2'>{`${literals.groups} (${groups.length})`}</div>
        <div className='permissions-rows'>
          {
            groups.length
              ? renderRows(groups)
              : <p className='font-italic'>{literals.noGroups}</p>
          }
        </div>

        <div className='mt-4'>
          <InputToggle
            preText={literals.giveMorePermissions}
            value={morePermissions}
            onChange={setMorePermission}
          />

          { morePermissions && (
            <>
              <Alert
                type='info'
                text={literals.permissionPrevailsInfo}
              />
              <InputPerms
                preText={literals.permissions}
                value={permissions}
                onChange={setPermissions}
              />
            </>
          )}
        </div>

        <div className='buttons'>
          <Button
            text={literalsCommon.save}
            onClick={handleSubmit}
            loading={saving}
          />
        </div>
      </div>
    </Popup>
  );
};

PermissionsUser.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  scope: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

PermissionsUser.defaultProps = {
  onSubmit: () => {},
};

export default PermissionsUser;
