import React from 'react';
import PropTypes from 'prop-types';
import { renderIcon } from 'utils/functions';
import { LinearProgress } from '@mui/material';
import './styles.scss';

class Button extends React.Component {
  static propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    color: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    size: PropTypes.string,
    mode: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
    icon: PropTypes.any,
    iconRight: PropTypes.any,
    title: PropTypes.string,
  };

  // Por defecto, el button ocupa el 100% del espacio
  // Para ajustar el tamaño, hay que hacerlo desde el componente que llama a este
  render() {
    const {
      text = '',
      color = 'primary',
      type = 'button',
      disabled = false,
      loading = false,
      size = '',
      mode = '',
      onClick = () => {},
      className = '',
      style = null,
      icon = null,
      iconRight = null,
      title = null,
    } = this.props;

    let colorStyle = '';
    let classProp = className ? `${className} ` : '';
    switch (mode) {
      case 'cancel':
        colorStyle = 'outline-secondary';
        break;
      case 'icon':
        classProp += 'btnIcon';
        colorStyle = color;
        break;
      default:
        colorStyle = color;
    }

    const classes = `button-app btn btn-${colorStyle} ${size ? `btn-${size}` : ''} ${classProp} ${icon ? 'btn-icon' : ''} ${iconRight ? 'btn-icon-right' : ''}`;
    const validTypes = ['submit', 'reset', 'button'];

    return (
      <button // eslint-disable-next-line react/button-has-type
        type={validTypes.includes(type) ? type : 'button'}
        className={classes}
        disabled={disabled || loading}
        onClick={onClick}
        style={style}
        title={title || text}
      >
        {icon && renderIcon(icon, { className: 'icon-left', size: 18 })}
        <span>{text}</span>
        {iconRight && renderIcon(iconRight, { className: 'icon-right', size: 18 })}
        {loading && (
          <div className='btn-loader'>
            <LinearProgress />
          </div>
        )}
      </button>
    );
  }
}

export default Button;
