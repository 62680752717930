import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Tool from 'components/Tool';
import ToolTab from 'components/Tool/components/ToolTab';
import Button from 'components/Buttons/Button';
import InputText from 'components/Inputs/inputText';
import InputEmail from 'components/Inputs/inputEmail';
import OutputErrors from 'components/Inputs/outputErrors';
import * as EmailValidator from 'email-validator';
import { addUserToStartup } from 'components/InviteUsers/modules/actions';
import { UserPlus } from 'lucide-react';
import ShareholderPermissionsInfo from './ShareholderPermissionsInfo';
import BindShareholderToUser from './BindShareholderToUser';
import './styles.scss';

const PopupInviteShareholder = (props) => {
  const {
    literals,
    literalsCommon,
    match,
    shareholder,
    onClose,
    tableRefreshFlag,
    lang,
  } = props;

  const [data, setData] = useState({ firstname: '', lastname: '', email: '' });
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [errors, setErrors] = useState(false);

  const createUser = async () => {
    setLoadingCreate(true);
    setErrors(false);
    try {
      await addUserToStartup(match.params.id, {
        firstname: shareholder.type === 'organization' ? data.firstname : shareholder.firstname,
        lastname: shareholder.type === 'organization' ? data.lastname : shareholder.lastname,
        email: data.email,
        perm: null,
        type: 'shareholder',
        lang,
        shareholder: shareholder.id,
      });
      tableRefreshFlag.increase();
      if (typeof onClose === 'function') {
        onClose();
      }
    } catch (e) {
      setErrors(e);
    }
    setLoadingCreate(false);
  };

  return (
    <Popup title={`${shareholder.firstname} ${shareholder.lastname || ''}`} size='large' onClose={onClose}>
      <Tool
        tabs={[
          { key: 'create', name: literals.createUser },
          { key: 'bind', name: literals.bindUser },
        ]}
        tabsJustify='end'
        tabsStyle='panel'
      >
        <ToolTab key='create'>
          { shareholder.type === 'organization' && (
            <div className='row'>
              <div className='col-12 col-md-4'>
                <InputText
                  preText={literalsCommon.name}
                  placeholder={literalsCommon.name}
                  onChange={firstname => setData(prev => ({ ...prev, firstname }))}
                  value={data.firstname}
                  isRequired
                />
              </div>
              <div className='col-12 col-md-8'>
                <InputText
                  preText={literalsCommon.lastName}
                  placeholder={literalsCommon.lastName}
                  onChange={lastname => setData(prev => ({ ...prev, lastname }))}
                  value={data.lastname}
                  isRequired
                />
              </div>
            </div>
          )}
          <InputEmail
            preText={literals.email}
            placeholder={literals.email}
            onChange={email => setData(prev => ({ ...prev, email }))}
            value={data.email}
            isRequired
          />
          <ShareholderPermissionsInfo />
          <OutputErrors literals={literals} errors={errors} />
          <div className='buttons'>
            <Button
              icon={UserPlus}
              text={literals.inviteUser}
              onClick={createUser}
              loading={loadingCreate}
              disabled={!EmailValidator.validate(data.email)}
            />
          </div>
        </ToolTab>
        <ToolTab key='bind'>
          <BindShareholderToUser
            literals={literals}
            literalsCommon={literalsCommon}
            match={match}
            shareholder={shareholder}
            tableRefreshFlag={tableRefreshFlag}
          />
        </ToolTab>
      </Tool>
    </Popup>
  );
};

PopupInviteShareholder.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  shareholder: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  tableRefreshFlag: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};

export default PopupInviteShareholder;
