// import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Help from '../components';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.help,
    user: state.session.user,
    lang: state.i18n.language,
    company: state.company,
  };
}

function mapDispatchToProps(/* dispatch */) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Help);
