import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import Popup from 'components/Popup';
import useDialog from 'components/Dialog/components/useDialog';
import { useParams } from 'react-router-dom';
import { formatNumber } from 'utils/functions';
import { ArrowLeftRight, Eye, Wallet } from 'lucide-react';
import { fetchCaptableDebts } from './routes/Securities/modules/actions';
import FormPayouts from './routes/Operation/components/FormPayouts';
import FormConvertibles from './routes/Operation/components/FormConversions';
import './styles.scss';

const PanelDebtsOutstanding = ({
  literals, literalsCommon, currency, captable, date,
  submitOperation, canEdit, forceFetch, setShowDebtDetails,
}) => {
  const [showDebtPopup, setShowDebtPopup] = useState(false);
  const params = useParams();
  const { dialog } = useDialog();

  const getShareholderName = (sid) => {
    if (sid) {
      const obj = captable.shareholders.find(sc => sc.id === sid);
      return obj ? obj.name : '-';
    }
    return '-';
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDebts = useCallback((date ? (page, size) => {
    const group = 'shareholder';
    const filters = { pending: { gt: 0 } };
    return fetchCaptableDebts(params.id, date, {
      page, size, filters, group, captable: captable.selected?.id,
    });
  } : null), [date]);

  const columnsDebtOutstanding = [
    {
      field: 'shareholder',
      grow: 2,
      label: literals.shareholder,
      preRender: v => v?.name || '-',
    },
    {
      field: 'count',
      width: 100,
      label: literals.debts,
      preRender: v => `${formatNumber(v, 0)}`,
    },
    {
      field: 'principal',
      width: 100,
      label: literals.principal,
      preRender: v => `${formatNumber(v, 0, { dec: 2 })}${currency.symbol}`,
    },
    {
      field: 'pending',
      width: 100,
      label: literals.pending,
      preRender: v => `${formatNumber(v, 0, { dec: 2 })}${currency.symbol}`,
    },
    {
      field: 'interestRate',
      width: 100,
      label: literals.interestAvg,
      preRender: v => `${formatNumber(v, 0, { dec: 2 })}%`,
    },
  ];

  if (!canEdit) {
    columnsDebtOutstanding.push({
      field: 'id',
      type: 'button',
      button: {
        icon: Eye,
        text: literalsCommon.viewDetail,
        onClick: debt => setShowDebtDetails({ shareholder: debt.shareholder, debt: null }),
      },
    });
  } else {
    columnsDebtOutstanding.push(
      {
        field: 'id',
        type: 'menu',
        buttons: [
          {
            icon: Eye,
            text: literalsCommon.viewDetail,
            onClick: debt => setShowDebtDetails({ shareholder: debt.shareholder, debt: null }),
          },
          {
            icon: ArrowLeftRight,
            text: literals.capitalize,
            onClick: debt => setShowDebtPopup({ shareholder: debt.shareholder, view: 'capitalize' }),
            hidden: !canEdit,
          },
          {
            icon: Wallet,
            text: literals.pay,
            onClick: debt => setShowDebtPopup({ shareholder: debt.shareholder, view: 'pay' }),
            hidden: !canEdit,
          },
        ],
      },
    );
  }

  const handleConfirm = async (op) => {
    const [confirm, draft] = await dialog({
      text: literals.confirmSubmit,
      type: 'custom',
      actions: [
        { text: literalsCommon.yes, color: 'success', onClick: () => [true, false] },
        { text: literals.draft, onClick: () => [true, true] },
        { text: literalsCommon.cancel, color: 'secondary', onClick: () => [false, false] },
      ],
    });

    if (confirm) {
      let opName = '';
      switch (op.type) {
        case 'payouts':
          opName = literals.payout;
          break;
        case 'conversions':
          opName = literals.capitalize;
          break;
        default:
      }

      const operation = {
        name: `${opName}: ${getShareholderName(op?.shareholder)} - ${op.name || '-'}`,
        draft,
        timeline: true,
        date: op.date,
        captable: captable.selected?.id,
        [op.type]: [op.data],
      };
      await dialog({
        type: 'loading',
        execute: async () => {
          await submitOperation(params.id, operation);
          setShowDebtPopup(null);
        },
      });
    }
  };

  return (
    <>
      <Table
        searcher={false}
        columns={columnsDebtOutstanding}
        fetch={fetchDebts}
        forceFetch={forceFetch}
        emptyImage={false}
        onClickRow={debt => setShowDebtDetails({ shareholder: debt.shareholder, debt: null })}
      />
      { showDebtPopup.view === 'pay' && (
        <Popup title={`${literals.payout}: ${showDebtPopup.shareholder?.name}`} onClose={() => setShowDebtPopup(false)} size='large'>
          <FormPayouts
            shareholder={showDebtPopup.shareholder?.id}
            data={{ relation: { type: 'debt', id: null } }}
            literals={literals}
            literalsCommon={literalsCommon}
            captable={captable}
            currency={currency}
            onConfirm={handleConfirm}
            type='debt'
            draft
          />
        </Popup>
      )}
      { showDebtPopup.view === 'capitalize' && (
        <Popup title={`${literals.capitalizeDebt}: ${showDebtPopup.shareholder?.name}`} onClose={() => setShowDebtPopup(false)} size='large'>
          <FormConvertibles
            shareholder={showDebtPopup.shareholder?.id}
            data={{ relation: { type: 'debt', id: null } }}
            literals={literals}
            literalsCommon={literalsCommon}
            captable={captable}
            currency={currency}
            onConfirm={handleConfirm}
            lastNumeration={captable.lastNumeration}
            type='debt'
            draft
          />
        </Popup>
      )}
    </>
  );
};

PanelDebtsOutstanding.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  date: PropTypes.string,
  submitOperation: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  forceFetch: PropTypes.number,
  setShowDebtDetails: PropTypes.func.isRequired,
};

PanelDebtsOutstanding.defaultProps = {
  date: new Date().toISOString().split('T')[0],
  forceFetch: 0,
};

export default PanelDebtsOutstanding;
