import AdminInstance from 'modules/admin/repository';

export function fetchAnalyticsSummary(from, to) {
  return AdminInstance.getGoogleAnalyticsSummary({ from, to });
}

export function fetchAnalyticsStartups(page, size, search, filters, sort, from, to) {
  return AdminInstance.getGoogleAnalyticsStartups({
    page, size, search, filters, sort, from, to,
  });
}

export function fetchAnalyticsTools(page, size, search, filters, sort, from, to) {
  return AdminInstance.getGoogleAnalyticsTools({
    page, size, search, filters, sort, from, to,
  });
}

export function fetchAnalyticsPages(page, size, search, filters, sort, from, to) {
  return AdminInstance.getGoogleAnalyticsPages({
    page, size, search, filters, sort, from, to,
  });
}

export function fetchAnalyticsUsers(page, size, search, filters, sort, from, to) {
  return AdminInstance.getGoogleAnalyticsUsers({
    page, size, search, filters, sort, from, to,
  });
}
