import { SessionService } from 'modules/session/sessionService';
import { STATUS_CODE } from 'constants/apiConf';
import { formatErrors } from 'utils/functions';
import { setPlatformLanguageAction } from 'components/LanguageSelector/modules/actions';
import AccountsInstance from 'modules/accounts/repository';

import {
  loadingAction,
} from '../../../store/globalState/global';

import {
  FETCH_LOGIN_INIT,
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGIN_FAILURE,
  FETCH_RECOVERY_INIT,
  FETCH_RECOVERY_SUCCESS,
  FETCH_RECOVERY_FAILURE,
  FETCH_CLEAN_ERRORS,
} from './types';

export const fetchLoginInit = () => ({ type: FETCH_LOGIN_INIT });
export const fetchRecoveryInit = () => ({ type: FETCH_RECOVERY_INIT });

export const fetchLoginSuccess = () => ({
  type: FETCH_LOGIN_SUCCESS,
});

export const fetchRecoverySuccess = () => ({
  type: FETCH_RECOVERY_SUCCESS,
});

export const fetchLoginFailure = error => ({
  type: FETCH_LOGIN_FAILURE,
  payload: error,
});

export const fetchRecoveryFailure = error => ({
  type: FETCH_RECOVERY_FAILURE,
  payload: error,
});


export const fetchCleanErrors = () => ({
  type: FETCH_CLEAN_ERRORS,
});

export function fetchLoginAction(payload) {
  return (dispatch) => {
    dispatch(fetchLoginInit());

    return AccountsInstance.loginUser(payload)
      .then((response) => {
        const { status, data: user } = response;
        if (status === STATUS_CODE.ACCEPTED) {
          return response;
        }
        dispatch(loadingAction(true));
        dispatch(setPlatformLanguageAction(user.lang.toLowerCase(), user.platform?.properties?.literals));
        const { id } = user;
        window.USER_ID = user.id;
        SessionService.saveSession({ token: id })
          .then(() => {
            SessionService.saveUser(user)
              .then(() => {
                dispatch(loadingAction(false));
              })
              .catch(() => {
                window.USER_ID = null;
              });
          });
        return response;
      })
      .catch(() => {
        dispatch(fetchLoginFailure(true));
        dispatch(loadingAction(false));
        return false;
      });
  };
}

export function fetchRecoveryAction(email) {
  return (dispatch) => {
    dispatch(loadingAction(true));
    dispatch(fetchRecoveryInit());

    return AccountsInstance.recoveryPassword({ email })
      .then(() => {
        dispatch(loadingAction(false));
        dispatch(fetchRecoverySuccess());
        return true;
      })
      .catch((error) => {
        dispatch(fetchRecoveryFailure(formatErrors(error)));
        dispatch(loadingAction(false));
        return false;
      });
  };
}

export function fetchCleanErrorsAction() {
  return (dispatch) => {
    dispatch(fetchCleanErrors());
  };
}
