/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputSelect from 'components/Inputs/inputSelect';
import Table from 'components/Table';
import { useCounter } from 'utils/customHooks';
import { formatLargeNumber, formatNumber, getColorRange } from 'utils/functions';
import { fetchLocal } from 'components/Table/components/TableFunctions';
import './styles.scss';

const WaterfallComparatorTable = (props) => {
  const {
    literals,
    currency,
    waterfalls,
    loading,
  } = props;

  const [rows, setRows] = useState([]);
  const [selectedTable, setSelectedTable] = useState('full');
  const tableRefreshFlag = useCounter(0);

  useEffect(() => {
    const numSelectedWaterfalls = Object.keys(waterfalls).length;
    const auxRows = {};

    Object.values(waterfalls).forEach((waterfall, i) => {
      waterfall.distribution.forEach((dist) => {
        if (!auxRows[dist.shareholder.id]) {
          auxRows[dist.shareholder.id] = {
            shareholder: `${dist.shareholder.firstname || ''} ${dist.shareholder.lastname || ''}`,
            shares: new Array(numSelectedWaterfalls).fill('n/a'),
            capital: new Array(numSelectedWaterfalls).fill('n/a'),
            liquidation: new Array(numSelectedWaterfalls).fill('n/a'),
            exit: new Array(numSelectedWaterfalls).fill('n/a'),
            total: new Array(numSelectedWaterfalls).fill('n/a'),
            multiplo: new Array(numSelectedWaterfalls).fill('n/a'),
          };
        }
        auxRows[dist.shareholder.id].shares[i] = dist.shares;
        auxRows[dist.shareholder.id].capital[i] = dist.capital;
        auxRows[dist.shareholder.id].liquidation[i] = dist.liquidation;
        auxRows[dist.shareholder.id].exit[i] = dist.exit;
        auxRows[dist.shareholder.id].total[i] = dist.total;
        auxRows[dist.shareholder.id].multiplo[i] = dist.multiplo;
      });
    });

    const { unnasigned, ...others } = auxRows;
    const newRows = Object.values(others).sort((a, b) => b.shares[0] - a.shares[0]);
    if (unnasigned) {
      newRows.push({ ...unnasigned, id: 'unassigned', shareholder: literals.unassigned });
    }

    tableRefreshFlag.increase();
    setRows(newRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterfalls]);

  const renderCell = (array, symbol = '') => {
    return (
      <div>
        {array.map((item, i) => {
          let val;
          if (typeof share === 'string') {
            val = item;
          } else if (i > 0 && item === array[0]) {
            val = '-';
          } else {
            val = formatNumber(item, 0, { symbol });
          }
          return <div className='cell-value'>{val}</div>;
        })}
      </div>
    );
  };

  const renderTable = () => {
    let columns;
    const graphColors = getColorRange(Object.values(waterfalls).length);
    if (selectedTable === 'full') {
      columns = [
        { field: 'shareholder', label: literals.shareholder },
        {
          label: '',
          width: '20px',
          preRender: () => Object.values(waterfalls).map((val, i) => (
            <div className='cell-circle'>
              <div
                className='circle'
                title={`${val?.name || formatLargeNumber(val?.exit, '', { dec: 1 })}`}
                style={{ background: graphColors[i] }}
              />
            </div>
          )),
        },
        {
          field: 'shares',
          label: literals.shares,
          width: '150px',
          sortable: true,
          preRender: shares => renderCell(shares),
        },
        {
          field: 'capital',
          label: literals.capital,
          width: '150px',
          sortable: true,
          preRender: capital => renderCell(capital, currency.symbol),
        },
        {
          field: 'liquidation',
          label: literals.liquidation,
          width: '150px',
          sortable: true,
          preRender: liquidation => renderCell(liquidation, currency.symbol),
        },
        {
          field: 'exit',
          label: literals.exit,
          width: '150px',
          sortable: true,
          preRender: exit => renderCell(exit, currency.symbol),
        },
        {
          field: 'total',
          label: literals.total,
          width: '150px',
          sortable: true,
          preRender: total => renderCell(total, currency.symbol),
        },
        {
          field: 'multiplo',
          label: literals.multiple,
          width: '100px',
          sortable: true,
          preRender: multiplo => renderCell(multiplo, 'X'),
        },
      ];
    } else {
      columns = [{ field: 'shareholder', label: literals.shareholder }];
      columns.push(...Object.values(waterfalls).map((waterfall, i) => ({
        id: selectedTable === 'totalSummary' ? `total.${i}` : `multiplo.${i}`,
        field: selectedTable === 'totalSummary' ? 'total' : 'multiplo',
        label: waterfall.name || formatLargeNumber(waterfall?.exit, '', { dec: 1 }),
        width: selectedTable === 'totalSummary' ? '150px' : '150px',
        sortable: true,
        preRender: total => (
          <div className={`cell ${selectedTable === 'multipleSummary' ? (total[i] < 1 ? 'cell-red' : 'cell-green') : ''}`}>
            {formatNumber(total[i], 0, { symbol: selectedTable === 'totalSummary' ? currency.symbol : 'X' })}
          </div>
        ),
      })));
    }

    const fetchTable = (page, size, search, filters, sort) => {
      return fetchLocal(
        { items: rows, total: rows.length },
        { search: 'shareholder' },
        page, size, search, filters, sort || '+shares',
      );
    };

    return (
      <div className={`waterfall-table ${loading ? 'loading' : ''} ${selectedTable === 'full' ? 'waterfall-table-full' : 'waterfall-table-summary'}`}>
        <Table
          key={`waterfalls-${loading ? 'loading' : tableRefreshFlag.value}`}
          columns={columns}
          pageSize={10}
          fetch={!loading ? fetchTable : null}
        />
      </div>
    );
  };

  return (
    <div className='waterfall-comparator-table'>
      <div className='input-select-table'>
        <InputSelect
          className='mb-0 w-100'
          onChange={(v) => { tableRefreshFlag.increase(); setSelectedTable(v); }}
          value={selectedTable}
          options={[
            { id: 'full', name: literals.full },
            { id: 'totalSummary', name: literals.totalSummary },
            { id: 'multipleSummary', name: literals.multipleSummary },
          ]}
          isDisabled={loading}
        />
      </div>
      {renderTable()}
    </div>
  );
};

WaterfallComparatorTable.propTypes = {
  literals: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  waterfalls: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default WaterfallComparatorTable;
