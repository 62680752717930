import { primary, secondary } from '../../../constants/colors';

const palette = {
  primary: {
    main: primary,
  },
  secondary: {
    main: secondary,
  },
};

export default palette;
