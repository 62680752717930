import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel/components/';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Table from 'components/Table';
import useDialog from 'components/Dialog/components/useDialog';
import { useCounter } from 'utils/customHooks';
import { formatDate, formatNumber, getStartupVerifiedIcon } from 'utils/functions';
import { Eye, Trash } from 'lucide-react';
import { ROUTE_PATH } from 'routes';
import { useNavigate } from 'react-router-dom';
import { deleteStartupFundraise } from 'routes/StartupFundraise/modules/actions';
import { getFundraises } from '../modules/actions';
import './styles.scss';

const Services = ({ literals, literalsCommon, currency }) => {
  const tableRefreshFlag = useCounter(0);
  const { dialog } = useDialog();
  const navigate = useNavigate();

  const handleDeleteStartupFundraise = async (fundraise) => {
    const confirm = await dialog({
      type: 'confirmDanger', text: literals.deleteFundraise,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteStartupFundraise(fundraise.startup.id, fundraise.id);
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const formatName = (row) => {
    return (
      <div className='d-flex align-items-center'>
        {row.name}
        {getStartupVerifiedIcon(row.verified)}
      </div>
    );
  };

  const columns = [
    {
      field: 'startup',
      type: 'avatar',
      width: 45,
      label: 'Startup',
      title: startup => startup.name,
      preRender: startup => startup.logo,
    },
    { field: 'startup', label: '', preRender: startup => formatName(startup) },
    {
      field: 'amount',
      label: literals.amount,
      preRender: v => formatNumber(v, 0, { symbol: currency.symbol }),
    },
    {
      field: 'postmoney',
      width: 250,
      label: literals.postMoney,
      preRender: v => formatNumber(v, 0, { symbol: currency.symbol }),
    },
    {
      field: 'ownership',
      label: literals.ownership,
      preRender: v => formatNumber(v, 0, { symbol: '%' }),
    },
    {
      field: 'investments',
      label: literals.investments,
      preRender: v => formatNumber(v, 0, { symbol: currency.symbol }),
    },
    {
      field: 'status',
      label: literals.status,
      preRender: v => literals[v.status],
    },
    {
      field: 'createdAt',
      label: literalsCommon.createdAt,
      preRender: date => formatDate(date, { time: true }),
    },
    {
      field: 'info',
      type: 'menu',
      buttons: [
        { text: literalsCommon.view, icon: Eye, onClick: fundraise => navigate(ROUTE_PATH.setFundraise(fundraise.id)) },
        { text: literalsCommon.delete, icon: Trash, onClick: fundraise => handleDeleteStartupFundraise(fundraise) },
      ],
    },
  ];


  return (
    <Panel>
      <PanelHeader title={literals.title} />
      <PanelBody>
        <Table
          key='table_mentorings'
          columns={columns}
          pageSize={10}
          onClickRow={fundraise => navigate(ROUTE_PATH.setFundraise(fundraise.id))}
          fetch={getFundraises}
          forceFetch={tableRefreshFlag.value}
        />
      </PanelBody>
    </Panel>
  );
};

Services.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
};

export default Services;
