import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import InputNumber from 'components/Inputs/inputNumber';
import Button from 'components/Buttons/Button';
import './styles.scss';

const PopupCurrencies = (props) => {
  const {
    literalsCommon,
    currency,
    currencies,
    onClose,
    onChange,
  } = props;

  const [newCurrencies, setNewCurrencies] = useState({ ...currencies });

  return (
    <Popup title={literalsCommon.currencies.currencies} onClose={onClose}>
      <span className='fw-b'>{`1 ${currency} = `}</span>
      <div className='row currencies-popup'>
        {
          Object.keys(currencies).map(curr => (curr !== currency ? (
            <div key={curr} className='col-12 col-md-3 col-sm-4'>
              <InputNumber
                className='my-2'
                onChange={v => setNewCurrencies(prev => ({ ...prev, [curr]: v }))}
                value={newCurrencies[curr]}
                symbol={literalsCommon.currenciesSymbols[curr]}
              />
            </div>
          ) : null))
        }
      </div>
      <div className='buttons'>
        <Button text={literalsCommon.confirm} onClick={() => onChange(newCurrencies)} />
      </div>
    </Popup>
  );
};

PopupCurrencies.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  currencies: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PopupCurrencies;
