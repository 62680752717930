import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ROUTE_PATH, ROUTE_STARTUP_PATH } from 'routes';
import InputText from 'components/Inputs/inputText';
import Button from 'components/Buttons/Button';
import Panel from 'components/Panel';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelHeader from 'components/Panel/components/PanelHeader';
import InputUsers from 'components/Inputs/inputUsers';
import OutputErrors from 'components/Inputs/outputErrors';
import EDITOR_JS_TOOLS from 'components/EditorJS/Constants';
import Loading from 'components/Loading';
import useDialog from 'components/Dialog/components/useDialog';
import InputPermission from 'components/Inputs/InputPermission';
import { KPI } from 'components/EditorJS/KPI';
import { getReport, postReport, putReport } from 'routes/Reporting/modules/actions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createReactEditorJS } from 'react-editor-js';
import { Mail, Eye, Hourglass } from 'lucide-react';
import { PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import { getTranslation } from 'utils/functions';
import { initPermission } from 'utils/functions/initPermissions';
import { TOOL_REPORTING } from 'constants/tools';
import { getReportTemplate } from '../../ReportingTemplates/modules/actions';
import PreviewPopup from './PreviewPopup';
import './styles.scss';

const ReportingAdd = (props) => {
  const {
    literals,
    literalsPerformance,
    literalsCommon,
    match,
    user,
    startup,
    currency,
    lang,
  } = props;

  const [params] = useSearchParams();
  const template = params.get('tmpl');
  const id = match.params.report !== 'new' ? match.params.report : null;
  const { dialog } = useDialog();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(!!template || !!id);
  const [errors, setErrors] = useState(null);
  const [showPreviewPopup, setShowPreviewPopup] = useState(null);

  const [subject, setSubject] = useState('');
  const [recipients, setRecipients] = useState([]);
  const [defaultValue, setDefaultValue] = useState(loading ? null : {});
  const [permission, setPermission] = useState({});
  const { canEdit } = permission;

  const breadcrumb = [
    { route: ROUTE_STARTUP_PATH.setReporting(match.params.id), name: literals.title },
    { route: ROUTE_STARTUP_PATH.setReportingAdd(match.params.id, match.params.report), name: literals.add },
  ];

  useEffect(() => {
    if (id) {
      getReport(match.params.id, id)
        .then((response) => {
          setSubject(response.subject);
          setRecipients(response.recipients);
          setDefaultValue(response.content);
          setPermission(initPermission(response, PERMISSION_SCOPES.STARTUP, startup, TOOL_REPORTING));
          setLoading(false);
        }).catch(() => {
          dialog({
            type: 'error',
            text: literals.reportingNotFound,
          }).then(() => {
            navigate(ROUTE_STARTUP_PATH.setReporting(match.params.id));
          });
        });
    } else {
      setPermission(initPermission(null, PERMISSION_SCOPES.STARTUP, startup, TOOL_REPORTING));
      if (template) {
        getReportTemplate(template)
          .then((response) => {
            setDefaultValue(getTranslation(response.content, lang));
            setLoading(false);
          }).catch(() => {
            setDefaultValue({});
            dialog({
              type: 'error',
              text: literals.reportTemplateNotFound,
            });
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenPreview = (response) => {
    if (subject) {
      setShowPreviewPopup(response);
    } else {
      setErrors({ subject: { code: 'required_error' } });
    }
  };

  const ReactEditorJS = createReactEditorJS();
  const editorCore = React.useRef(null);

  const handleInitialize = React.useCallback((instance) => {
    editorCore.current = instance;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSumbit = async (e, draft = false) => {
    e.preventDefault();
    setLoading(true);
    setErrors(false);
    editorCore.current.save().then(async (response) => {
      try {
        const data = {
          subject,
          content: response,
          recipients,
          filesPath: ROUTE_PATH.DOWNLOAD_FILE_TEMPLATE,
          draft,
          permission,
        };
        if (id) {
          await putReport(match.params.id, id, data);
        } else {
          await postReport(match.params.id, data);
        }
        navigate(ROUTE_STARTUP_PATH.setReporting(match.params.id));
      } catch (err) {
        setErrors(err);
        setLoading(false);
      }
    });
  };

  return (
    <Panel>
      <PanelHeader breadcrumb={breadcrumb} />
      <PanelBody>
        <form onSubmit={handleSumbit} className='reporting-add'>
          <div className='row my-3'>
            <div className='col-12'>
              <InputText
                preText={literals.subject}
                placeholder={literals.subject}
                onChange={v => setSubject(v)}
                value={subject}
                isDisabled={loading || !canEdit}
                isRequired
              />
            </div>
            <div className='col-12 col-md-6'>
              <InputUsers
                literals={literalsCommon}
                match={match}
                preText={literals.recipients}
                users={recipients}
                setUsers={setRecipients}
                isDisabled={loading || !canEdit}
              />
            </div>
            <div className='col-12 col-md-4'>
              <InputPermission
                scope={{ type: PERMISSION_SCOPES.STARTUP, id: startup.id }}
                element={{ type: PERMISSION_TYPES.REPORT, id }}
                value={permission}
                onChange={setPermission}
              />
            </div>
          </div>
          <div className='row my-3'>
            <div className='col-12'>
              {defaultValue !== null ? (
                <div className='background-light'>
                  <ReactEditorJS
                    defaultValue={defaultValue}
                    tools={{
                      ...EDITOR_JS_TOOLS,
                      KPI: {
                        class: KPI,
                        config: {
                          literalsPerformance, literalsCommon, currency, lang, match,
                        },
                      },
                    }}
                    onInitialize={handleInitialize}
                    disabled={loading || !canEdit}
                  />
                </div>
              ) : (
                <Loading hide={false} mode='panel' />
              )}
            </div>
          </div>
          <OutputErrors literals={literals} errors={errors} />
          <div className='buttons'>
            {canEdit && (
              <>
                <Button
                  text={literalsCommon.send}
                  type='submit'
                  loading={loading}
                  icon={Mail}
                />
                <Button
                  text={literals.draft}
                  onClick={e => handleSumbit(e, true)}
                  color='secondary'
                  icon={Hourglass}
                  loading={loading}
                />
              </>
            )}
            <Button
              text={literals.preview}
              onClick={() => {
                editorCore.current.save()
                  .then(response => handleOpenPreview(response));
              }}
              icon={Eye}
              color='secondary'
              loading={loading}
            />
          </div>
          {showPreviewPopup && (
            <PreviewPopup
              literals={literals}
              subject={subject}
              content={showPreviewPopup}
              match={match}
              user={user}
              onClose={() => setShowPreviewPopup(false)}
            />
          )}
        </form>
      </PanelBody>
    </Panel>
  );
};

ReportingAdd.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsPerformance: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};

export default ReportingAdd;
