import StartupsCaptablesInstance from 'modules/startupsCaptables/repository';
import { apiDownloadFile } from 'modules/actions';
import { API_SHARES_DISTRIBUTION_EXCEL } from 'modules/apiEndpoints';

export function fetchCaptableShares(startup, date, params) {
  return StartupsCaptablesInstance.getStartupCaptableShares(startup, date, params);
}

export function fetchCaptableOptions(startup, date, params) {
  return StartupsCaptablesInstance.getStartupCaptableOptions(startup, date, params);
}

export function fetchShareClasses(id, page, size, others = {}) {
  return StartupsCaptablesInstance.getStartupShareClasses(id, { page, size, ...others });
}

export function getSharesDistribution(id, date, opts) {
  return StartupsCaptablesInstance.getStartupCaptableSharesDistribution(id, date, opts);
}

export function downloadSharesDistributionExcel(id, captable, date) {
  return apiDownloadFile(API_SHARES_DISTRIBUTION_EXCEL(id, captable, date), 'shares_distribution.xlsx');
}
