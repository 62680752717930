import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const InputTextArea = ({
  preText = '',
  postText = '',
  placeholder = '',
  isRequired = false,
  isDisabled = false,
  value = '',
  maxLength = 500,
  onChange,
  error = null,
  name = '',
  minHeight = '',
  height = '',
  onFocus,
  onBlur,
}) => {
  const length = `${value ? value.length : 0}${maxLength ? `/${maxLength}` : ''}`;

  const styles = {};
  if (minHeight) {
    styles.minHeight = minHeight;
  }
  if (height) {
    styles.height = height;
  }

  const handleChange = (e) => {
    if (typeof onChange === 'function') {
      onChange(e.target.value, e);
    }
  };

  const handleFocus = () => {
    if (typeof onFocus === 'function') {
      onFocus();
    }
  };

  const handleBlur = () => {
    if (typeof onBlur === 'function') {
      onBlur();
    }
  };

  return (
    <div className='input_textarea'>
      <div className={`pre_text ${error ? 'text_error' : ''}`}>
        <div className='left'>
          {preText}
          {isRequired && <span className='is_required'>*</span>}
        </div>
        {maxLength !== 0 && <div className='right'>{length}</div>}
      </div>
      <textarea
        className={`textarea ${error ? 'input_error' : ''}`}
        placeholder={placeholder}
        disabled={isDisabled}
        maxLength={maxLength > 0 ? maxLength : ''}
        value={value}
        onChange={handleChange}
        name={name}
        style={styles}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      { postText && (<div className='post_text' dangerouslySetInnerHTML={{ __html: postText }} />) }
    </div>
  );
};

InputTextArea.propTypes = {
  preText: PropTypes.string,
  postText: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.bool]),
  name: PropTypes.string,
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default InputTextArea;
