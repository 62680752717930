import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import InputText from 'components/Inputs/inputText';
import InputNumber from 'components/Inputs/inputNumber';
import InputRichText from 'components/Inputs/inputRichText';
import Button from 'components/Buttons/Button';
import OutputErrors from 'components/Inputs/outputErrors';
import useDialog from 'components/Dialog/components/useDialog';
import { formatNumber } from 'utils/functions';
import { deleteFundraiseInvestment, postFundraiseInvestment, putFundraiseInvestment } from '../modules/actions';
import './styles.scss';

const defaultInvestForm = {
  amount: 0,
  notes: '',
  investor: '',
};

const FundraiseInvestPopup = ({
  literals,
  literalsCommon,
  investment = null,
  fundraise,
  setFundraise = null,
  symbol,
  manual = false,
  onSubmit = () => {},
  onClose,
}) => {
  const [investForm, setInvestForm] = useState(investment || defaultInvestForm);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { dialog } = useDialog();

  const handleInvest = async () => {
    setLoading(true);
    try {
      let response;
      let investments;
      if (investment?.id) {
        response = await putFundraiseInvestment(fundraise.id, investment.id, { ...investForm });
        investments = fundraise.investments - investment.amount + investForm.amount;
      } else {
        response = await postFundraiseInvestment(fundraise.id, { ...investForm, invite: '' });
        investments = fundraise.investments + investForm.amount;
      }
      if (setFundraise) {
        setFundraise({ ...fundraise, investments, investment: manual ? fundraise.investment : response });
      }
      onSubmit();
      onClose();
    } catch (err) {
      setErrors(err.body);
      setLoading(false);
    }
  };

  const deleteDeleteInvestment = async () => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literals.deleteInvestment,
    });
    if (confirm) {
      setLoading(true);
      try {
        await deleteFundraiseInvestment(fundraise.id, investment.id);
        const investments = fundraise.investments - investForm.amount;
        setFundraise({ ...fundraise, investments, investment: null });
        onClose();
      } catch (err) {
        setErrors(err.body);
        setLoading(false);
      }
    }
  };

  return (
    <Popup title={manual ? literals.addCommitment : literals.invest} onClose={onClose}>
      <div className='row text-center'>
        <div className='col-12 col-sm-4 mb-4'>
          <h6 className='fw-b'>{literals.pending}</h6>
          <span>{formatNumber(fundraise.amount - fundraise.investments, 0, { symbol })}</span>
        </div>
        <div className='col-12 col-sm-4 mb-4'>
          <h6 className='fw-b'>{literals.minTicket}</h6>
          <span>{formatNumber(fundraise.minInvestment, 0, { symbol })}</span>
        </div>
        <div className='col-12 col-sm-4 mb-4'>
          <h6 className='fw-b'>{literals.maxTicket}</h6>
          <span>{formatNumber(fundraise.maxInvestment, 0, { symbol })}</span>
        </div>
      </div>
      {
        manual && (
          <InputText
            preText={literals.investor}
            value={investForm.name}
            onChange={v => setInvestForm(prev => ({ ...prev, name: v }))}
          />
        )
      }
      <InputNumber
        preText={literals.amountToInvest}
        value={investForm.amount}
        onChange={v => setInvestForm(prev => ({ ...prev, amount: v }))}
        symbol={symbol}
      />
      <InputRichText
        preText={literalsCommon.notes}
        value={investForm.notes}
        onChange={v => setInvestForm(prev => ({ ...prev, notes: v }))}
      />
      <OutputErrors errors={errors} literals={literals} />
      <div className='buttons'>
        { investment?.id && (
          <Button
            text={literalsCommon.delete}
            color='danger'
            onClick={deleteDeleteInvestment}
            loading={loading}
          />
        )}
        <Button
          text={investment?.id ? literalsCommon.edit : literals.invest}
          onClick={handleInvest}
          loading={loading}
          disabled={manual && !investForm.name}
        />
      </div>
    </Popup>
  );
};

FundraiseInvestPopup.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  investment: PropTypes.object,
  fundraise: PropTypes.object.isRequired,
  setFundraise: PropTypes.func,
  symbol: PropTypes.string.isRequired,
  manual: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

export default FundraiseInvestPopup;
