import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Table from 'components/Table';
import StatCard from 'components/Statistics/StatCard';
import InputSelect from 'components/Inputs/inputSelect';
import { useParams, useSearchParams } from 'react-router-dom';
import { formatDate, formatNumber } from 'utils/functions';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import { Eye, LineChart } from 'lucide-react';
import { fetchCaptableShares } from '../modules/actions';
import PopupShareholderShares from '../../shareHolders/components/routes/shareholderInfo/components/PopupShareholderShares';

const PopupInfoShares = (props) => {
  const {
    type,
    data,
    date,
    literals,
    literalsCommon,
    onClose,
    currency,
  } = props;

  const [stats, setStats] = useState(false);
  const [shares, setShares] = useState(false);
  const [shareDetails, setShareDetails] = useState(false);
  const [groupBy, setGroupBy] = useState('shareholder');
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const params = useParams();

  useEffect(() => {
    (async () => {
      const filters = type === 'syndicate'
        ? { shareholder: data.shareholders.map(sh => sh.id) }
        : { [type]: data.id };

      let group = '';
      if (type === 'syndicate') {
        group = 'shareClass';
      } else if (type === 'shareClass' && groupBy === 'syndicate') {
        group = 'syndicate';
      }

      const auxShares = await fetchCaptableShares(params.id, date, {
        page: 0, size: 0, filters, group, captable: captableId,
      });

      const auxStats = {
        shares: 0,
        capital: 0,
        ownership: 0,
        fullyDiluted: 0,
      };
      auxShares.items.forEach((item) => {
        auxStats.shares += item.number;
        auxStats.capital += item.capital;
        auxStats.ownership += item.ownership;
        auxStats.fullyDiluted += item.fullyDiluted;
      });
      setStats(auxStats);

      if (type !== 'syndicate' && groupBy !== 'syndicate') {
        auxShares.items.sort((a, b) => {
          return b.originalOperation.date.localeCompare(a.originalOperation.date);
        });
      }
      setShares(auxShares);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupBy]);

  const columns = [];
  if (type === 'shareholder' || type === 'syndicate') {
    columns.push({
      field: 'shareClass',
      grow: 3,
      label: literals.shareClass,
      preRender: v => v.name,
    });
  } else if (type === 'shareClass' && groupBy === 'shareholder') {
    columns.push({
      field: 'shareholder',
      grow: 3,
      label: literals.shareholder,
      preRender: v => v.name,
    });
  } else {
    columns.push({
      field: 'name',
      grow: 3,
      label: literals.syndicate,
      preRender: v => literals[v] || v,
    });
  }

  columns.push({
    field: 'number',
    width: type === 'syndicate' ? 175 : 125,
    label: literals.shares,
    preRender: v => formatNumber(v, 0),
  },
  {
    field: 'pps',
    width: type === 'syndicate' ? 125 : 75,
    label: literals.pps,
    preRender: (v, row) => `${formatNumber(v || (row.capital / row.number), 0)}${currency.symbol}`,
  },
  {
    field: 'capital',
    width: type === 'syndicate' ? 175 : 120,
    label: literals.capital,
    preRender: v => `${formatNumber(v, 0, { dec: 2 })}${currency.symbol}`,
  });

  if (type !== 'syndicate' && groupBy !== 'syndicate') {
    columns.push({
      field: 'numeration',
      width: 120,
      label: literals.numeration,
      preRender: v => `${formatNumber(v.from, 0)} - ${formatNumber(v.to, 0)}`,
    },
    {
      field: 'originalOperation',
      width: 100,
      label: literals.operation,
      preRender: v => <span title={v.name}>{formatDate(v.date)}</span>,
    },
    {
      field: 'originalOperation',
      type: 'menu',
      buttons: [
        {
          icon: Eye,
          text: 'Ver',
          onClick: row => setShareDetails(row),
        },
        {
          icon: LineChart,
          text: literals.operation,
          to: operation => embedView(ROUTE_STARTUP_PATH.setCapTableOperation(params.id, captableId, operation.id)),
          type: 'Link',
        },
      ],
    });
  }

  return (
    <Popup title={literals[data.name] || data.name} onClose={onClose} size='large'>
      <>
        <div className='row'>
          <div className='col-12 col-md-3'>
            <StatCard title={literals.shares} value={formatNumber(stats.shares, 0)} loading={!stats} />
          </div>
          <div className='col-12 col-md-3'>
            <StatCard title={literals.capital} value={`${formatNumber(stats.capital, 0)}${currency.symbol}`} loading={!stats} />
          </div>
          <div className='col-12 col-md-3'>
            <StatCard title={literals.ownership} value={`${formatNumber(stats.ownership, 0, { dec: 2 })}%`} loading={!stats} />
          </div>
          <div className='col-12 col-md-3'>
            <StatCard title={literals.fullyDiluted} value={`${formatNumber(stats.fullyDiluted, 0, { dec: 2 })}%`} loading={!stats} />
          </div>
        </div>

        { type === 'shareClass' && (
          <div className='d-flex justify-content-end'>
            <InputSelect
              className='mb-0 w-50'
              preText={literals.lang}
              options={[
                { id: 'shareholder', name: literals.shareholders },
                { id: 'syndicate', name: literals.syndicates },
              ]}
              value={groupBy}
              onChange={(v) => { setShares(null); setGroupBy(v); }}
            />
          </div>
        )}

        {shares ? (
          <Table
            key='table-shares-details'
            searcher={false}
            columns={columns}
            fetch={() => ({ items: shares.items, total: shares.total })}
            onClickRow={op => (type !== 'syndicate' && groupBy !== 'syndicate' ? setShareDetails(op) : {})}
          />
        ) : (
          <Table
            searcher={false}
            columns={columns}
            fetch={null}
          />
        )}

        {shareDetails && (
          <PopupShareholderShares
            literals={literals}
            literalsCommon={literalsCommon}
            currency={currency}
            onClose={() => setShareDetails(false)}
            row={shareDetails}
          />
        )}
      </>
    </Popup>
  );
};

PopupInfoShares.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  currency: PropTypes.object.isRequired,
};

PopupInfoShares.defaultProps = {
  type: 'shareholder',
};

export default PopupInfoShares;
