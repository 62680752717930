import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Table from 'components/Table';
import Panel from 'components/Panel/components';
import PanelTab from 'components/Panel/components/PanelTab';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Popup from 'components/Popup';
import useDialog from 'components/Dialog/components/useDialog';
import PopupProfile from 'components/PopupProfile';
import IOFileLoading from 'components/IOFileLoading/components';
import {
  Download, Eye, Users, Trash, Calendar,
  XCircle, CircleCheck, StopCircle, Clock,
  MessageCircle, FileUp, FileText, List,
} from 'lucide-react';
import { ROUTE_ADMIN_PATH } from 'routes';
import { fetchPlatform, fetchQuestions } from 'routes/Admin/Platform/modules/actions';
import { useCounter } from 'utils/customHooks';
import {
  formatDate, getFullName, getStartupVerifiedIcon, getTranslation,
} from 'utils/functions';
import { createDefaultQuestionsStartup, createDefaultQuestionsMentor } from 'constants/defaultQuestionsOnboarding';
import { statusTag } from './functions';
import PopUpInfo from './PopUpInfo';
import ListMentorings from './ListMentorings';
import {
  fetchMentorings, fetchMentors, fetchStartups,
  putStartupGroup, putMentorGroup, deleteMentoring,
  fetchFeedBack, downloadExcelMentorings,
} from '../modules/actions';


const AdminMentorings = ({
  literalsMentoring, literalsCommon, literalsOnboarding, platform, lang,
}) => {
  const [detailsList, setDetailsList] = useState({ type: null, data: null });
  const [showPopupProfile, setShowPopupProfile] = useState(false);
  const [info, setInfo] = useState(null);
  const { dialog } = useDialog();
  const tableRefreshFlag = useCounter(0);
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const [forms, setForms] = useState({ startup: [], mentor: [] });

  useEffect(() => {
    (async () => {
      const auxQuestions = await fetchQuestions(0, 0);
      const auxPlatform = await fetchPlatform();
      setForms({
        startup: auxQuestions.items.filter(question => auxPlatform.forms.startup.find(q => q.id === question.id)),
        mentor: auxQuestions.items.filter(question => auxPlatform.forms.mentor.find(q => q.id === question.id)),
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMentorings = (page, size, search, filters) => {
    return fetchMentorings(page, size, { search, filters });
  };

  const getMentors = (page, size, search, filters) => {
    const auxFilters = { ...filters };
    if (auxFilters.group) {
      auxFilters.group = auxFilters.group.map(g => parseInt(g, 10));
    }
    return fetchMentors(page, size, { search, filters: auxFilters });
  };

  const getStartups = (page, size, search, filters) => {
    const auxFilters = { ...filters };
    if (auxFilters.group) {
      auxFilters.group = auxFilters.group.map(g => parseInt(g, 10));
    }
    return fetchStartups(page, size, { search, filters: auxFilters });
  };

  const changeGroup = (type, id, group) => {
    if (type === 'startup') {
      putStartupGroup(id, group);
    } else {
      putMentorGroup(id, group);
    }
  };

  const handleClickDeleteMentoring = async (id) => {
    const confirm = await dialog({
      text: '¿Borrar?',
      type: 'confirm',
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteMentoring(id);
        },
      });
    }
  };

  const handleDownloadExcel = async () => {
    setDownloadingExcel(true);
    await downloadExcelMentorings('Mentorings.xlsx');
    setDownloadingExcel(false);
  };

  const formatGroup = (type, group, row) => {
    return (
      <select className='form-control form-control-sm' onChange={e => changeGroup(type, row.id, e.target.value)}>
        <option value='0'>-</option>
        {
          platform.mentorings?.groups?.map(g => (
            <option selected={group === parseInt(g, 10)} value={g}>
              {g}
            </option>
          ))
        }
      </select>
    );
  };

  const setPopUpInfo = async (row) => {
    let feedBackMentor = null;
    let feedBackStartup = null;

    if (row.feedback.mentor) {
      await fetchFeedBack(row.id, row.feedback.mentor)
        .then((data) => { feedBackMentor = data; });
    }
    if (row.feedback.startup) {
      await fetchFeedBack(row.id, row.feedback.startup)
        .then((data) => { feedBackStartup = data; });
    }
    setInfo({ ...row, feedBackMentor, feedBackStartup });
  };

  const closePopUpInfo = () => {
    setInfo(null);
  };

  const filtersGroups = {
    id: 'group',
    type: 'select',
    title: 'Group',
    options: platform.mentorings?.groups?.map(group => ({ id: group, name: group })),
  };

  const getType = (type) => {
    if (type === 'date') {
      return 'dateRange';
    }
    if (type === 'number') {
      return 'numberRange';
    }
    return type;
  };

  // StartupFilters
  const defaultFiltersStartup = createDefaultQuestionsStartup(literalsOnboarding, literalsCommon, platform.properties.defaultQuestions.startup)
    .filter(question => question.info.type !== 'text')
    .map(question => ({
      id: question.info.id,
      type: question.info.type,
      title: getTranslation(question.info.title, lang),
      options: question?.info?.answers?.map(answ => ({ id: answ.id, name: getTranslation(answ.text, lang) })),
    }));

  const extraStartupFilters = forms.startup.map(form => ({
    id: `extra.${form.id}`,
    type: getType(form.type),
    title: getTranslation(form.title, lang),
    options: form?.options?.map(op => ({ id: op.id, name: getTranslation(op.text, lang) }))
    || form?.answers?.map(answ => ({ id: answ.id, name: getTranslation(answ.text, lang) })),
  }));

  const startupFilters = [filtersGroups, ...defaultFiltersStartup, ...extraStartupFilters];

  // Mentor filters
  const defaultFiltersMentor = createDefaultQuestionsMentor(literalsOnboarding, platform.properties.defaultQuestions.mentor)
    .filter(question => question.info.type !== 'text')
    .map(question => ({
      id: question.info.id,
      type: question.info.type,
      title: getTranslation(question.info.title, lang),
      options: question?.info?.answers?.map(answ => ({ id: answ.id, name: getTranslation(answ.text, lang) })),
    }));

  const extraMentorFilters = forms.mentor.map(form => ({
    id: `extra.${form.id}`,
    type: getType(form.type),
    title: getTranslation(form.title, lang),
    options: form?.options?.map(op => ({ id: op.id, name: getTranslation(op.text, lang) }))
    || form?.answers?.map(answ => ({ id: answ.id, name: getTranslation(answ.text, lang) })),
  }));

  const mentorFilters = [filtersGroups, ...defaultFiltersMentor, ...extraMentorFilters];

  // Mentoring filters
  const mentoringsFilters = [
    {
      id: 'startedBy',
      type: 'select',
      title: literalsMentoring.startedBy,
      options: [
        { id: 'startup', name: literalsCommon.startup },
        { id: 'mentor', name: literalsCommon.mentor },
      ],
    },
    {
      id: 'status',
      type: 'select',
      title: literalsMentoring.status.status,
      options: [
        { id: 'accepted', name: literalsMentoring.status.accepted },
        { id: 'finished', name: literalsMentoring.status.finished },
        { id: 'pending', name: literalsMentoring.status.pending },
        { id: 'rejected', name: literalsMentoring.status.rejected },
      ],
    },
    {
      id: 'createdAt',
      type: 'dateRange',
      title: literalsMentoring.infoPopUp.creationDate,
    },
    {
      id: 'lastInteraction',
      type: 'dateRange',
      title: literalsMentoring.lastInteraction,
    },
  ];

  const formatName = (row) => {
    return (
      <div className='d-flex align-items-center'>
        {row.name}
        {getStartupVerifiedIcon(row.verified)}
      </div>
    );
  };

  const columns = [
    {
      field: 'startup',
      type: 'avatar',
      width: 45,
      label: 'Startup',
      title: startup => startup.name,
      preRender: startup => startup.logo,
    },
    { field: 'startup', label: '', preRender: startup => formatName(startup) },
    {
      field: 'mentor',
      type: 'avatar',
      width: 45,
      label: 'Mentor',
      title: mentor => getFullName(mentor.user),
      preRender: mentor => mentor.avatar,
    },
    {
      field: 'mentor',
      label: '',
      preRender: mentor => getFullName(mentor.user),
    },
    {
      field: 'startedBy', width: 125, label: literalsMentoring.startedBy, preRender: mentor => literalsCommon[mentor],
    },
    {
      field: 'status', width: 125, label: 'Status', preRender: status => statusTag(literalsMentoring, status), align: 'left',
    },
    {
      field: 'stats',
      label: <MessageCircle size={16} />,
      title: literalsMentoring.numberMessages,
      preRender: stats => stats.messages,
      width: 30,
      align: 'center',
    },
    {
      field: 'stats',
      label: <Calendar size={16} />,
      title: literalsMentoring.numberEvents,
      preRender: stats => stats.meetings,
      width: 30,
      align: 'center',
    },
    {
      field: 'stats',
      label: <FileUp size={16} />,
      title: literalsMentoring.numberDocuments,
      preRender: stats => stats.documents,
      width: 30,
      align: 'center',
    },
    {
      field: 'stats',
      label: <FileText size={16} />,
      title: literalsMentoring.numberReports,
      preRender: stats => stats.reports,
      width: 30,
      align: 'center',
    },
    {
      field: 'createdAt', width: 150, label: 'Created at', preRender: createdAt => formatDate(createdAt, { time: true }),
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Eye,
          text: 'Info',
          onClick: row => setPopUpInfo(row),
        },
        {
          icon: Users,
          text: 'Entrar',
          to: id => ROUTE_ADMIN_PATH.setMentoring(id),
          type: 'Link',
        },
        {
          icon: Trash,
          text: 'Borrar',
          onClick: async (row) => {
            await handleClickDeleteMentoring(row.id);
            tableRefreshFlag.increase();
          },
        },
      ],
    },
  ];

  const columnsMentors = [
    {
      field: 'avatar', type: 'avatar', title: (_, row) => `${row.user.firstname} ${row.user.lastname}`, width: 45, label: 'Avatar',
    },
    { field: 'user', label: 'Fullname', preRender: user => `${user.firstname} ${user.lastname}` },
    {
      field: 'visible', width: 75, label: 'visible', preRender: g => (g ? <CircleCheck size={16} /> : <StopCircle size={16} />),
    },
    {
      field: 'group', width: 75, label: 'Group', preRender: (g, row) => formatGroup('mentor', g, row),
    },
    {
      field: 'mentoringStats',
      width: 30,
      label: <Clock size={16} />,
      title: literalsMentoring.status.pending,
      preRender: s => s.pending,
      align: 'center',
    },
    {
      field: 'mentoringStats',
      width: 30,
      label: <CircleCheck size={16} />,
      title: literalsMentoring.status.accepted,
      preRender: s => s.accepted,
      align: 'center',
    },
    {
      field: 'mentoringStats',
      width: 30,
      label: <XCircle size={16} />,
      title: literalsMentoring.status.rejected,
      preRender: s => s.rejected,
      align: 'center',
    },
    {
      field: 'mentoringStats',
      width: 30,
      label: <StopCircle size={16} />,
      title: literalsMentoring.status.finished,
      preRender: s => s.finished,
      align: 'center',
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Eye,
          text: 'Ver',
          onClick: row => setShowPopupProfile({ type: 'mentor', id: row.id }),
        },
        {
          icon: List,
          text: 'Mentorías',
          onClick: row => setDetailsList({ type: 'mentor', data: row }),
        },
      ],
    },
  ];

  const columnsStartups = [
    {
      field: 'logo', type: 'avatar', title: (_, row) => row.name, width: 45, label: 'Logo',
    },
    { field: 'name', label: 'Name', preRender: (_, row) => formatName(row) },
    {
      field: 'group',
      width: 75,
      label: 'Group',
      preRender: (g, row) => formatGroup('startup', g, row),
    },
    {
      field: 'mentoringStats',
      width: 30,
      label: <Clock size={16} />,
      title: literalsMentoring.status.pending,
      preRender: s => s.pending,
      align: 'center',
    },
    {
      field: 'mentoringStats',
      width: 30,
      label: <CircleCheck size={16} />,
      title: literalsMentoring.status.accepted,
      preRender: s => s.accepted,
      align: 'center',
    },
    {
      field: 'mentoringStats',
      width: 30,
      label: <XCircle size={16} />,
      title: literalsMentoring.status.rejected,
      preRender: s => s.rejected,
      align: 'center',
    },
    {
      field: 'mentoringStats',
      width: 30,
      label: <StopCircle size={16} />,
      title: literalsMentoring.status.finished,
      preRender: s => s.finished,
      align: 'center',
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Eye,
          text: 'Ver',
          onClick: row => setShowPopupProfile({ type: 'startup', id: row.id }),
        },
        {
          icon: List,
          text: 'Mentorías',
          onClick: row => setDetailsList({ type: 'startup', data: row }),
        },
      ],
    },
  ];

  const mainTabs = [
    { key: 'mentorings', name: literalsMentoring.mentorings },
    { key: 'mentors', name: literalsMentoring.mentors },
    { key: 'startups', name: literalsMentoring.startups },
  ];
  return (
    <Tool title='Mentorías'>
      <Panel margin={0} clean>
        <PanelHeader
          title='Mentorías'
          tabs={mainTabs}
        />
        <PanelBody>
          <PanelTab key='mentorings'>
            <Table
              key='table_mentorings'
              columns={columns}
              pageSize={10}
              fetch={getMentorings}
              forceFetch={tableRefreshFlag.value}
              filters={mentoringsFilters}
              actions={[
                { icon: Download, text: 'Excel', onClick: handleDownloadExcel },
              ]}
            />
          </PanelTab>
          <PanelTab key='mentors'>
            <Table key='table_mentors' filters={mentorFilters} columns={columnsMentors} pageSize={10} fetch={getMentors} forceFetch={tableRefreshFlag.value} />
          </PanelTab>
          <PanelTab key='startups'>
            <Table key='table_startups' filters={startupFilters} columns={columnsStartups} pageSize={10} fetch={getStartups} forceFetch={tableRefreshFlag.value} />
          </PanelTab>
        </PanelBody>
      </Panel>
      {detailsList.type && (
        <Popup
          title={
            detailsList.type === 'startup'
              ? detailsList.data.name
              : `${detailsList.data.user.firstname} ${detailsList.data.user.lastname}`
          }
          size='xlarge'
          onClose={() => setDetailsList({ type: null, data: null })}
        >
          <ListMentorings view={detailsList} literalsMentoring={literalsMentoring} setPopUpInfo={setPopUpInfo} onDelete={handleClickDeleteMentoring} />
        </Popup>
      )}
      {showPopupProfile && (
        <PopupProfile type={showPopupProfile.type} id={showPopupProfile.id} onClose={() => setShowPopupProfile(false)} />
      )}
      {info && (
        <PopUpInfo onClose={closePopUpInfo} {...info} literals={literalsMentoring} />
      )}
      { downloadingExcel && <IOFileLoading mode='download' file={{ name: 'Mentorings.xlsx' }} /> }
    </Tool>
  );
};

AdminMentorings.propTypes = {
  literalsMentoring: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  literalsOnboarding: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};

export default AdminMentorings;
