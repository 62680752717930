import initialState from '../../../store/globalState/initialState';

import {
  FETCH_COMPANY,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_FAILURE,
  EDIT_COMPANY_SUCCESS,
  EDIT_COMPANY_FAILURE,
} from './types';

export default function companiesReducer(state = initialState.company, action) {
  switch (action.type) {
    case FETCH_COMPANY:
      return {
        ...state,
        loading: true,
        loaded: false,
        company: {},
        error: '',
      };
    case FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: '',
        company: action.payload,
      };
    case EDIT_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: '',
        company: Object.assign({}, action.payload),
      };
    case EDIT_COMPANY_FAILURE:
    case FETCH_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
