import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import { Skeleton } from '@mui/material';
import { getTranslation } from 'utils/functions';
import './styles.scss';

const ServiceRow = ({
  match,
  literals,
  loading = false,
  lang,
  id,
  logo = null,
  company = null,
  shortDescription = null,
  type = null,
  description = null,
  request = null,
  setShowServiceInfo,
}) => {
  if (loading) {
    return (
      <div key={id} className='box box-padding d-flex align-items-center box-mb'>
        <div className='flex-grow-1 p-1 pl-4'>
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='50%' />
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='100%' />
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='100%' />
        </div>
      </div>
    );
  }

  const auxProps = {
    loading,
    lang,
    id,
    startup: match.params.id,
    logo,
    company,
    shortDescription,
    description,
    request,
    type,
  };

  return (
    <div key={id} className='service' onClick={() => setShowServiceInfo(auxProps)}>
      <div className='company_header'>
        <Avatar title={getTranslation(company, lang)} src={logo?.path} size={45} />
        <div className='d-flex flex-column ml-3'>
          <span className='company_name'>{getTranslation(company, lang)}</span>
          <div className='company_type fc-secondary'>{getTranslation(type, lang)}</div>
        </div>
      </div>
      <div className='company_info'>
        <span className='company_description'>{getTranslation(shortDescription, lang)}</span>
      </div>
      {request && (
        <div className='company_status'>
          <span className='fc-secondary fs-sm'>{literals[request.status]}</span>
        </div>
      )}
    </div>
  );
};

ServiceRow.propTypes = {
  literals: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  lang: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  logo: PropTypes.object,
  company: PropTypes.object,
  shortDescription: PropTypes.object,
  description: PropTypes.object,
  type: PropTypes.object,
  request: PropTypes.object,
  setShowServiceInfo: PropTypes.func.isRequired,
};

export default ServiceRow;
