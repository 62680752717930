import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import StatCard from 'components/Statistics/StatCard';
import {
  CircleArrowUp,
  MailOpen,
  OctagonAlert,
  MousePointerClick,
  Inbox,
  Truck,
} from 'lucide-react';
import { formatNumber } from 'utils/functions';
import PopupInfoStatus from './PopupInfoStatus';
import './styles.scss';

const EmailStats = ({
  literals,
  fetchTable,
  stats = null,
}) => {
  const [showInfoPopup, setShowInfoPopup] = useState(false);

  const getIcon = (v) => {
    switch (v) {
      case 'registered': return <CircleArrowUp size={16} />;
      case 'delivered': return <Inbox size={16} />;
      case 'open': return <MailOpen size={16} />;
      case 'click': return <MousePointerClick size={16} />;
      case 'error': return <OctagonAlert size={16} />;
      case 'processed': return <Truck size={16} />;
      default: return null;
    }
  };

  const columns = [
    {
      field: 'to',
      label: literals.email,
      grow: 3,
    },
    {
      field: 'status',
      label: literals.status,
      grow: 1,
      preRender: v => (
        <>
          <span className='pr-2'>{getIcon(v)}</span>
          <span>{literals[v]}</span>
        </>
      ),
    },
  ];

  return (
    <div className='email-stats'>
      <div className='stats-boxes'>
        <div>
          <StatCard icon={CircleArrowUp} title={literals.registered} value={formatNumber(stats?.registered)} loading={stats === null} />
        </div>
        <div>
          <StatCard icon={Inbox} title={literals.delivered} value={formatNumber(stats?.delivered)} loading={stats === false} />
        </div>
        <div>
          <StatCard icon={MailOpen} title={literals.open} value={formatNumber(stats?.open)} loading={stats === false} />
        </div>
        <div>
          <StatCard icon={MousePointerClick} title={literals.click} value={formatNumber(stats?.click)} loading={stats === false} />
        </div>
        <div>
          <StatCard icon={OctagonAlert} title={literals.error} value={formatNumber(stats?.error)} loading={stats === false} />
        </div>
      </div>

      <Table
        key='table_emails'
        columns={columns}
        pageSize={10}
        fetch={fetchTable}
        onClickRow={row => setShowInfoPopup(row)}
      />

      {showInfoPopup && (
        <PopupInfoStatus
          literals={literals}
          row={showInfoPopup}
          onClose={() => setShowInfoPopup(false)}
          getIcon={getIcon}
        />
      )}
    </div>
  );
};

EmailStats.propTypes = {
  literals: PropTypes.object.isRequired,
  stats: PropTypes.object,
  fetchTable: PropTypes.func.isRequired,
};

export default EmailStats;
