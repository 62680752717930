import { getBrowserPreferences } from 'utils/browser';

export default {
  session: {
    user: {},
    authenticated: false,
    checked: false,
  },
  global: {
    loading: true,
    dialog: {
      show: false,
      type: null,
      title: '',
      text: '',
      actions: [],
    },
    modal: { // nombre auxiliar
      show: false,
    },
    platform: {
      loaded: false,
      loading: false,
      name: '',
      images: {},
      colors: {},
      langs: [],
    },
    currency: {
      name: 'EUR',
      symbol: '€',
    },
    startup: null,
    investments: {
      shareholders: [],
      currency: getBrowserPreferences('baseCurrency', 'USD'),
      currencies: null,
    },
  },
  notifications: {
    loading: false,
    loaded: false,
    total: 0,
    unread: 0,
    items: [],
  },
  forms: {
    startup: {
      loading: false,
      loaded: false,
      error: null,
      questions: [],
    },
    mentor: {
      loading: false,
      loaded: false,
      error: null,
      questions: [],
    },
    investor: {
      loading: false,
      loaded: false,
      error: null,
      questions: [],
    },
    fund: {
      loading: false,
      loaded: false,
      error: null,
      questions: [],
    },
  },
  login: {
    loginError: null,
    recoveryError: null,
    isStartup: false, // Si se selecciona un mentor, se pone a false. Si se selecciona una startup, se pone a true
  },
  logout: {
    loading: false,
    loaded: false,
  },
  signup: {
    error: '',
    success: '',
  },
  companies: {
    loading: false,
    loaded: false,
    companiesList: [],
  },
  company: {
    loading: false,
    loaded: false,
    company: {},
    kpis: [],
    startupUsers: [],
  },
  mentoring: {
    mentoring: {},
    messages: null,
    meetings: null,
    documents: null,
    reports: null,
    error: '',
    loading: false,
  },
  editInfo: {
    extraQuestions: null,
    loading: false,
    error: '',
  },
  performance: {
    model: '',
    loading: false,
    loaded: false,
    relations: [],
    kpisRelated: [],
    error: '',
  },
  captable: {
    loading: false,
    loaded: false,
    startup: null,
    captable: null,
    selected: null,
    captables: [],
    shareholders: [],
    shareClasses: [],
    vestingPlans: [],
    error: null,
  },
};
