import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const DocumentsInstance = ApiFetch();

export const URLS = {
  DOCUMENTS: 'documents',
  DOCUMENT: 'documents/:id',
  DOCUMENT_VIEWS: 'documents/:id/views',
};

async function deleteDocument(id, params = {}) {
  const path = preparePath(URLS.DOCUMENT, { id }, params);
  return DocumentsInstance.delete(path);
}

async function editDocument(id, data) {
  const path = preparePath(URLS.DOCUMENT, { id });
  return DocumentsInstance.postForm(path, { ...data });
}

async function getDocument(id) {
  const path = preparePath(URLS.DOCUMENT, { id });
  return DocumentsInstance.get(path);
}

async function getDocumentViews(id, params = {}) {
  const path = preparePath(URLS.DOCUMENT_VIEWS, { id }, params);
  return DocumentsInstance.get(path);
}

async function postDocument(file, _public, data = {}) {
  return DocumentsInstance.postForm(URLS.DOCUMENTS, {
    file, public: _public, ...data,
  });
}

export default {
  deleteDocument,
  editDocument,
  getDocument,
  getDocumentViews,
  postDocument,
};
