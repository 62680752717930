import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import Table from 'components/Table';
import Popup from 'components/Popup';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import useDialog from 'components/Dialog/components/useDialog';
import SkeletonBarChart from 'components/Loading/skeletonBarChart';
import ReactECharts from 'echarts-for-react';
import { formatNumber, getColorRange } from 'utils/functions';
import { AmplitudeApi } from 'utils/amplitude';
import {
  Eye, LineChart, PlusCircle, Sheet, Clipboard,
} from 'lucide-react';
import { fetchCaptableOptions } from './routes/Securities/modules/actions';
import FormOptions from './routes/Operation/components/FormOptions';
import PopupInfoOptions from './routes/Securities/components/PopupInfoOptions';
import VestingsPlans from './routes/vestingPlans';
import './styles.scss';

const PanelOptions = ({
  literals, literalsCommon, stats, startup,
  date, captable, currency, canEdit, submitOperation,
}) => {
  const [viewTable, setViewTable] = useState(false);
  const [createPopup, setCreatePopup] = useState(false);
  const [unassignedOptions, setUnassignedOptions] = useState([]);
  const [showDetails, setShowDetails] = useState(null);
  const [showVestingPlans, setShowVestingPlans] = useState(null);
  const { dialog } = useDialog();

  useEffect(() => {
    (async () => {
      if (captable?.selected?.id && captable?.summary?.operation) {
        const options = await fetchCaptableOptions(startup.id, captable.summary.operation, {
          captable: captable?.selected?.id, filters: { shareholder: null },
        });
        setUnassignedOptions(options.items);
      }
    })();
  }, [startup.id, captable]);

  let graphOptions = null;
  if (stats !== null) {
    graphOptions = [
      [literals.options, literals.unassigned, literals.pending, literals.vested, literals.exercised],
      [literals.stockOption,
        stats.aggregations.options.stockOption.unassigned,
        stats.aggregations.options.stockOption.pending,
        stats.aggregations.options.stockOption.vested,
        stats.aggregations.options.stockOption.exercised],
      [literals.phantom,
        stats.aggregations.options.phantom.unassigned,
        stats.aggregations.options.phantom.pending,
        stats.aggregations.options.phantom.vested,
        stats.aggregations.options.phantom.exercised],
    ];
  }

  const getBarOptions = (source) => {
    return {
      grid: { bottom: '15%' },
      color: getColorRange(4),
      legend: {},
      tooltip: { valueFormatter: v => formatNumber(v) },
      dataset: { source },
      xAxis: { type: 'category' },
      yAxis: { },
      series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }, { type: 'bar' }],
    };
  };

  const fetchOptions = (page, size) => {
    return fetchCaptableOptions(startup.id, date, {
      page,
      size,
      group: 'shareholder',
      captable: captable.selected.id,
    });
  };

  const handleConfirm = async (op) => {
    const [confirm, draft] = await dialog({
      text: literals.confirmSubmit,
      type: 'custom',
      actions: [
        {
          text: literalsCommon.yes,
          color: 'success',
          onClick: () => [true, false],
        },
        {
          text: literals.draft,
          onClick: () => [true, true],
        },
        {
          text: literalsCommon.cancel,
          color: 'secondary',
          onClick: () => [false, false],
        },
      ],
    });

    if (confirm) {
      const operation = {
        ...op,
        draft,
        timeline: true,
        captable: captable.selected.id,
      };

      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            await submitOperation(captable.selected.startup, operation);
            AmplitudeApi.successEvent('startup.captable.summary.options.click.newOption', {
              captable: captable?.selected?.id,
            });
            setCreatePopup(false);
          } catch {
            AmplitudeApi.errorEvent('startup.captable.summary.options.click.newOption', {
              captable: captable?.selected?.id,
            });
          }
        },
      });
    }
  };

  const renderGraph = () => (
    graphOptions
      ? <ReactECharts option={getBarOptions(graphOptions)} />
      : <SkeletonBarChart className='mb-4' height='275px' />
  );

  const renderTable = () => {
    const columnsOptions = [
      {
        field: 'shareholder',
        width: 140,
        label: literalsCommon.name,
        preRender: v => (v?.name?.length >= 18 ? `${v?.name.slice(0, 18)}...` : (v?.name || '-')),
      },
      {
        field: 'number',
        width: 55,
        label: '#',
        preRender: v => formatNumber(v),
      },
      {
        field: 'capital',
        width: 60,
        label: literals.capital,
        preRender: v => `${formatNumber(v, 0, { dec: 2 })}${currency.symbol}`,
      },
      {
        field: 'assigned',
        title: `${literals.assigned}/${literals.vested}/${literals.exercised}`,
        label: `${literals.assigned.slice(0, 3)} / ${literals.vested.slice(0, 3)} / ${literals.exercised.slice(0, 3)}`,
        preRender: (v, row) => (
          `
            ${(row.shareholder ? `${formatNumber(v, 0)}` : '-')} /
            ${(row.shareholder ? `${formatNumber(row.vested, 0)}` : '-')} /
            ${(row.shareholder ? `${formatNumber(row.exercised, 0)}` : '-')}
          `
        ).replace(/\s+/g, ' '),
      },
      {
        field: 'id',
        type: 'button',
        button: {
          icon: Eye,
          text: literalsCommon.viewDetail,
          onClick: row => setShowDetails(row),
        },
      },
    ];

    return (
      <Table
        columns={columnsOptions}
        onClickRow={setShowDetails}
        fetch={fetchOptions}
        pageSize={4}
        searcher={false}
      />
    );
  };

  return (
    <Panel>
      <PanelHeader title={literals.optionsStatus} />
      <PanelBody className='panel-summary-options'>
        <div className='chart-panel-actions'>
          {canEdit && (
            <div title={literals.createOption} onClick={() => setCreatePopup(true)}>
              <PlusCircle size={22} />
            </div>
          )}
          <div title={literals.vestingPlans} onClick={() => setShowVestingPlans(true)}>
            <Clipboard size={22} />
          </div>
          <div title={viewTable ? literals.showChart : literals.showTable}>
            {viewTable
              ? <LineChart title={literals.create} size={22} onClick={() => setViewTable(false)} />
              : <Sheet title={literals.create} size={22} onClick={() => setViewTable(true)} />
            }
          </div>
        </div>
        {viewTable ? renderTable() : renderGraph()}
      </PanelBody>
      {
        createPopup && (
          <Popup onClose={() => setCreatePopup(false)}>
            <FormOptions
              literals={literals}
              literalsCommon={literalsCommon}
              currency={currency}
              captable={captable}
              unassigned={{ options: unassignedOptions }}
              onConfirm={handleConfirm}
              standalone
            />
          </Popup>
        )
      }
      {
        showDetails && (
          <PopupInfoOptions
            id={showDetails.id}
            name={showDetails.shareholder?.name}
            literals={literals}
            literalsCommon={literalsCommon}
            date={date}
            startup={startup}
            currency={currency}
            onClose={() => setShowDetails(false)}
          />
        )
      }
      {
        showVestingPlans && (
          <Popup title={literals.vestingPlans} onClose={() => setShowVestingPlans(false)}>
            <VestingsPlans />
          </Popup>
        )
      }
    </Panel>
  );
};

PanelOptions.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  stats: PropTypes.object,
  startup: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  date: PropTypes.string,
  captable: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
  submitOperation: PropTypes.func.isRequired,
};

PanelOptions.defaultProps = {
  date: new Date().toISOString().split('T')[0],
  stats: null,
};

export default PanelOptions;
