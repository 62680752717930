import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import Button from 'components/Buttons/Button';
import InputDate from 'components/Inputs/inputDate';
import InputNumber from 'components/Inputs/inputNumber';
import InputSelect from 'components/Inputs/inputSelect';
import OutputValue from 'components/Inputs/outputValue';
import { useParams, useSearchParams } from 'react-router-dom';
import { PlusCircle } from 'lucide-react';
import { cloneObject, formatDate, formatNumber } from 'utils/functions';
import { fetchCaptableVariables } from '../modules/actions';
import PopupFormShareClass from '../../shareClasses/components/PopupFormShareClass';
import PopupInfoVestingsPlans from '../../vestingPlans/components/PopupInfoVestingPlan';

const defaultConversion = {
  relation: {
    type: null,
    id: null,
  },
  shares: 1,
  number: 1,
  shareholder: null,
  valuation: false,
  shareClass: null,
  pps: 1,
  nominalValue: 1,
  sharePremium: 0,
  numeration: {
    from: 0,
    to: 0,
  },
};

const FormConversions = (props) => {
  const {
    type,
    data,
    dateOrID,
    literals,
    literalsCommon,
    lastNumeration,
    captable,
    convertibles: auxConvertibles,
    shareholder,
    currency,
    onConfirm,
    onDelete,
    editable,
  } = props;

  const [id] = useState(data?.id ?? `new.${new Date().getTime()}`);
  const [inputDate, setInputDate] = useState();
  const [conversion, setConversion] = useState({
    ...(cloneObject(defaultConversion)),
    ...(data || {}),
    numeration: {
      from: data?.numeration?.from || null,
      to: data?.numeration?.to || null,
    },
  });
  const [autoNumeration, setAutoNumeration] = useState(!conversion.numeration.from);
  const [convertibles, setConvertibles] = useState(auxConvertibles);
  const [convertible, setConvertible] = useState(null);
  const [popupShareClass, setPopupShareClass] = useState(false);
  const [vestingPlanPopup, setVestingPlanPopup] = useState(false);
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const params = useParams();

  const fetchConvertibles = async () => {
    const auxFilters = {};
    if (shareholder) {
      auxFilters.shareholder = shareholder;
    }
    const response = await fetchCaptableVariables(params.id, dateOrID, captableId, auxFilters, { convertibles: true, unassigned: false });
    setConvertibles(response.convertibles);
  };

  const findConvertible = (convType, convId) => {
    let newConvertible = null;
    if (convId) {
      switch (convType) {
        case 'debt':
          newConvertible = convertibles.debts.find(conv => conv.debt === convId);
          break;
        case 'option':
          newConvertible = convertibles.options.find(conv => conv.assignment === convId);
          break;
        default:
          break;
      }
    }
    return newConvertible;
  };

  useEffect(() => {
    const load = async () => {
      if (!convertibles) {
        await fetchConvertibles();
      }
      if (conversion?.relation?.type && conversion?.relation?.id && !convertible) {
        const newConvertible = findConvertible(conversion.relation.type, conversion.relation.id);
        if (newConvertible) {
          setConvertible(newConvertible);
        } else {
          setConversion({
            ...defaultConversion,
            relation: {
              type: conversion?.relation?.type,
              id: null,
            },
          });
        }
      }
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!convertibles || (conversion.relation?.id && !convertible)) {
    return <Loading hide={false} mode='panel' />;
  }

  const submitForm = async (e) => {
    e.preventDefault();
    if (type === 'operation') {
      onConfirm({ id, ...conversion });
    } else {
      onConfirm({
        type: 'conversions',
        date: inputDate,
        name: convertible?.name,
        shareholder: convertible.shareholder?.id,
        data: { id, ...conversion },
      });
    }
  };

  const renderDebtInfo = () => {
    return (
      <>
        <div className='row'>
          <div className='col-12 col-md-3'>
            <h6 className='fw-b'>{literals.shareholder}</h6>
            <p>{convertible.shareholder?.name || '-'}</p>
          </div>
          <div className='col-12 col-md-5'>
            <h6 className='fw-b'>{literalsCommon.name}</h6>
            <p>{convertible.name || '-'}</p>
          </div>
          <div className='col-12 col-md-4'>
            <h6 className='fw-b'>{literals.principal}</h6>
            <p>{formatNumber(convertible.principal, 0, { dec: 2, symbol: currency.symbol })}</p>
          </div>
          <div className='col-12 col-md-3'>
            <h6 className='fw-b'>{literals.issueDate}</h6>
            <p>{convertible.startDate || '-'}</p>
          </div>
          <div className='col-12 col-md-3'>
            <h6 className='fw-b'>{literals.expirationDate}</h6>
            <p>{convertible.expirationDate || '-'}</p>
          </div>
          <div className='col-12 col-md-3'>
            <h6 className='fw-b'>{literals.interestRate}</h6>
            <p>{`${convertible.interestRate || '-'}%`}</p>
          </div>
          <div className='col-12 col-md-3'>
            <h6 className='fw-b'>{literals.accrualFrequency}</h6>
            <p>{convertible.accrualFrequency || '-'}</p>
          </div>
          <div className='col-12 col-md-4'>
            <h6 className='fw-b'>{literals.pending}</h6>
            <p>{formatNumber(convertible.pending, 0, { dec: 2, symbol: currency.symbol })}</p>
          </div>
          <div className='col-12 col-md-4'>
            <h6 className='fw-b'>{literals.repaid}</h6>
            <p>{formatNumber(convertible.repaid, 0, { dec: 2, symbol: currency.symbol })}</p>
          </div>
          <div className='col-12 col-md-4'>
            <h6 className='fw-b'>{literals.capitalized}</h6>
            <p>{formatNumber(convertible.capitalized, 0, { dec: 2, symbol: currency.symbol })}</p>
          </div>
        </div>
      </>
    );
  };

  const renderOptionInfo = () => {
    return (
      <>
        <div className='row'>
          <div className='col-12 col-md-5'>
            <h6 className='fw-b'>{literals.shareholder}</h6>
            <p>{convertible.shareholder?.name || '-'}</p>
          </div>
          <div className='col-12 col-md-4'>
            <h6 className='fw-b'>{literalsCommon.name}</h6>
            <p>{convertible.name || '-'}</p>
          </div>
          <div className='col-12 col-md-3'>
            <h6 className='fw-b'>{literals.price}</h6>
            <p>{formatNumber(convertible.price, 0, { dec: 2, symbol: currency.symbol })}</p>
          </div>
          <div className='col-12 col-md-3'>
            <h6 className='fw-b'>{literals.number}</h6>
            <p>{convertible.number || '-'}</p>
          </div>
          <div className='col-12 col-md-3'>
            <h6 className='fw-b'>{literals.assigned}</h6>
            <p>{convertible.assigned || 0}</p>
          </div>
          <div className='col-12 col-md-3'>
            <h6 className='fw-b'>{literals.vested}</h6>
            <p>{convertible.vested || 0 }</p>
          </div>
          <div className='col-12 col-md-3'>
            <h6 className='fw-b'>{literals.exercised}</h6>
            <p>{convertible.exercised || 0 }</p>
          </div>
          <div className='col-12 col-md-3'>
            <h6 className='fw-b'>{literals.exercisePrice}</h6>
            <p>{formatNumber(convertible.exercisePrice, 0, { dec: 2, symbol: currency.symbol })}</p>
          </div>
          <div className='col-12 col-md-3'>
            <h6 className='fw-b'>{literals.ratio}</h6>
            <p>{formatNumber(convertible.ratio, 0, { symbol: 'X' })}</p>
          </div>
          <div className='col-12 col-md-3'>
            <h6 className='fw-b'>{literals.expirationDate}</h6>
            <p>{convertible.expirationDate || '-'}</p>
          </div>
          <div className='col-12 col-md-3'>
            <h6 className='fw-b'>{literals.vestingPlan}</h6>
            <p style={{ cursor: 'pointer' }} onClick={() => setVestingPlanPopup(convertible?.vestingPlan?.id)}>
              {convertible?.vestingPlan?.name || '-'}
            </p>
          </div>
        </div>
      </>
    );
  };

  const handleChangeRelation = (attr, value) => {
    const newConversion = { ...conversion };

    if (attr === 'type') {
      newConversion.relation.type = value;
      newConversion.relation.id = null;
      setConvertible(null);
    } else {
      newConversion.relation.id = value;
      let newNumber = 1;
      let newShares = 1;
      let newPPS = 1;
      const newConvertible = findConvertible(newConversion.relation.type, newConversion.relation.id);
      if (newConvertible) {
        newConversion.shareholder = newConvertible.shareholder?.id;
        switch (newConversion.relation.type) {
          case 'debt':
            newNumber = newConvertible.pending;
            newShares = newConvertible.pending;
            break;
          case 'option':
            newNumber = newConvertible.vested;
            newShares = newConvertible.vested * newConvertible.ratio;
            newPPS = newConvertible.exercisePrice;
            break;
          default:
            break;
        }
      }
      newConversion.number = newNumber;
      newConversion.shares = newShares;
      newConversion.pps = newPPS;
      newConversion.nominalValue = newPPS;
      newConversion.sharePremium = 0;
      newConversion.numeration.to = newConversion.numeration.from + newShares - 1;
      setConvertible(newConvertible);
    }
    setConversion(newConversion);
  };

  const handleChangeValue = (attr, value) => {
    const newConversion = { ...conversion };
    let newShares = null;

    switch (attr) {
      case 'shares':
        newConversion.shares = value;
        newConversion.pps = (parseFloat(newConversion.number) / parseInt(newConversion.shares, 10)).toFixed(2);
        newConversion.nominalValue = newConversion.pps;
        newConversion.sharePremium = 0;
        newShares = newConversion.shares;
        break;
      case 'numeration.from':
        newConversion.numeration.from = value;
        newConversion.numeration.to = value + newConversion.shares - 1;
        break;
      case 'pps': {
        const newPPS = Number.isNaN(parseFloat(value)) ? parseInt(value, 10) : parseFloat(value);
        newConversion.pps = value;
        newConversion.shares = parseInt(newConversion.number / newPPS, 10);
        newShares = newConversion.shares;
      }
        break;
      case 'number':
        if (conversion?.relation?.type === 'debt') {
          newConversion.number = parseFloat(value);
          newConversion.shares = parseInt(newConversion.number / parseFloat(newConversion.pps), 10);
        } else {
          newConversion.number = parseInt(value, 10);
          newConversion.shares = newConversion.number * convertible.ratio;
        }
        newShares = newConversion.shares;
        break;
      case 'nominalValue':
        newConversion.nominalValue = value;
        newConversion.pps = parseFloat(value) + parseFloat(newConversion.sharePremium);
        newConversion.shares = parseInt(newConversion.number / newConversion.pps, 10);
        newShares = newConversion.shares;
        break;
      case 'sharePremium':
        newConversion.sharePremium = value;
        newConversion.pps = parseFloat(value) + parseFloat(newConversion.nominalValue);
        newConversion.shares = parseInt(newConversion.number / newConversion.pps, 10);
        newShares = newConversion.shares;
        break;
      default:
        newConversion[attr] = value;
        break;
    }
    if (newShares !== null) {
      newConversion.numeration.to = newConversion.numeration.from + newShares - 1;
    }
    setConversion(newConversion);
  };

  const handleDeleteForm = () => {
    onDelete(id);
  };

  const handleChangeAutoNumeration = (v) => {
    setAutoNumeration(v);
    setConversion(prev => ({
      ...prev,
      numeration: {
        from: !v ? lastNumeration + 1 : 0,
        to: !v ? lastNumeration + prev.shares : 0,
      },
    }));
  };

  return (
    <form onSubmit={submitForm}>
      {
        convertibles ? (
          <>
            <div className='row'>
              {type !== 'operation' ? (
                <>
                  <div className='col-12 col-md-4'>
                    <InputDate
                      preText={literalsCommon.date}
                      value={inputDate}
                      onChange={v => setInputDate(v)}
                      isDisabled={!editable}
                      min={captable?.operations?.length ? captable?.operations[0].date : null}
                    />
                  </div>
                  <div className='col-12 col-md-8'>
                    <InputSelect
                      preText={conversion.relation.type === 'debt' ? literals.selectDebt : literals.selectOption}
                      value={conversion?.relation?.id}
                      onChange={v => handleChangeRelation('id', v)}
                      options={conversion?.relation?.type === 'debt'
                        ? convertibles.debts.map(debt => ({ id: debt.debt, name: `${debt.name} (${formatNumber(debt.pending, 0, { dec: 2, symbol: currency.symbol })})` }))
                        : convertibles.options.map(option => ({ id: option.assignment, name: `${option.name} (${option.vested})` }))
                      }
                      isDisabled={!editable}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className='col-12 col-md-3'>
                    <InputSelect
                      preText={literalsCommon.type}
                      value={conversion?.relation?.type}
                      onChange={v => handleChangeRelation('type', v)}
                      options={[
                        { id: 'debt', name: `${literals.debts} (${convertibles.debts.length})` },
                        { id: 'option', name: `${literals.options} (${convertibles.options.length})` },
                      ]}
                      isDisabled={!editable}
                    />
                  </div>
                  { conversion?.relation?.type && (
                    <div className='col-12 col-md-9'>
                      <InputSelect
                        preText={conversion.relation.type === 'debt' ? literals.selectDebt : literals.selectOption}
                        value={conversion?.relation?.id}
                        onChange={v => handleChangeRelation('id', v)}
                        options={conversion?.relation?.type === 'debt'
                          ? convertibles.debts.map(debt => ({ id: debt.debt, name: `${debt.name} - ${debt.shareholder?.name || '-'} (${formatNumber(debt.pending, 0, { dec: 2, symbol: currency.symbol })})` }))
                          : convertibles.options.map(option => ({ id: option.assignment, name: `${option.shareholder?.name || '-'} - ${option.name} (${option.vested})` }))
                        }
                        isDisabled={!editable}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
            <div className='box box-padding box-mb '>
              {convertible ? (
                <>
                  <h5 className='fw-b mb-0'>
                    {`${literals[conversion?.relation?.type]}: ${convertible.name}`}
                  </h5>
                  <hr />
                  {conversion?.relation?.type === 'debt' ? renderDebtInfo() : renderOptionInfo()}
                  <div className='row'>
                    <div className='col-12 col-md-3'>
                      <InputNumber
                        preText={conversion?.relation?.type === 'debt' ? literals.amount : literals.stockOption}
                        value={conversion.number}
                        onChange={v => handleChangeValue('number', v)}
                        step={conversion?.relation?.type === 'debt' ? 'any' : 1}
                        symbol={conversion?.relation?.type === 'debt' ? currency.symbol : null}
                        decimals={conversion?.relation?.type === 'debt' ? 2 : 0}
                        minValue={1}
                        maxValue={conversion?.relation?.type === 'debt' ? convertible.pending : convertible.vested}
                        isDisabled={!editable}
                      />
                    </div>
                    {
                      conversion?.relation?.type === 'debt' && (
                        <>
                          <div className='col-12 col-md-3'>
                            <InputNumber
                              preText={literals.nominalValue}
                              value={conversion.nominalValue}
                              onChange={v => handleChangeValue('nominalValue', v)}
                              isDisabled={!editable}
                              symbol={currency.symbol}
                              minValue={0}
                              decimals={10}
                              step='any'
                            />
                          </div>
                          <div className='col-12 col-md-3'>
                            <InputNumber
                              preText={literals.sharePremium}
                              value={conversion.sharePremium}
                              onChange={v => handleChangeValue('sharePremium', v)}
                              isDisabled={!editable}
                              symbol={currency.symbol}
                              minValue={0}
                              decimals={10}
                              step='any'
                            />
                          </div>
                        </>
                      )
                    }
                    <div className='col-12 col-md-3'>
                      <InputNumber
                        preText={literals.shares}
                        value={conversion.shares}
                        onChange={v => handleChangeValue('shares', parseInt(v, 10))}
                        minValue={1}
                        decimals={0}
                        isDisabled
                      />
                    </div>
                    <div className={`col-xs-12 col-md-${conversion?.relation?.type === 'debt' ? 3 : 6}`}>
                      <InputSelect
                        options={captable.shareClasses}
                        preText={literals.shareClass}
                        value={conversion.shareClass}
                        onChange={v => setConversion({ ...conversion, shareClass: v })}
                        button={(
                          <Button
                            text={literalsCommon.create}
                            icon={PlusCircle}
                            onClick={() => setPopupShareClass(true)}
                          />
                        )}
                        isDisabled={!editable}
                      />
                    </div>
                    <div className='col-12 col-md-3'>
                      <InputSelect
                        preText={literals.automaticNumeration}
                        value={autoNumeration}
                        onChange={handleChangeAutoNumeration}
                        isDisabled={!editable}
                        options={[
                          { id: true, name: literalsCommon.yes },
                          { id: false, name: literalsCommon.no },
                        ]}
                      />
                    </div>
                    { !autoNumeration && (
                      <>
                        <div className='col-12 col-md-2'>
                          <InputNumber
                            preText={`#${literals.from}`}
                            value={conversion.numeration?.from}
                            onChange={v => handleChangeValue('numeration.from', parseInt(v, 10))}
                            isDisabled={!editable}
                            minValue={1}
                            decimals={0}
                          />
                        </div>
                        <div className='col-12 col-md-2'>
                          <InputNumber
                            preText={`#${literals.to}`}
                            value={conversion.numeration?.to}
                            onChange={null}
                            decimals={0}
                            isDisabled
                          />
                        </div>
                      </>
                    )}
                    {
                      conversion?.relation?.type === 'debt' && (
                        <div className={`col-12 col-md-${autoNumeration ? 3 : 2}`}>
                          <OutputValue
                            preText={literals.payout}
                            value={formatNumber(conversion.number - (conversion.shares * conversion.pps), 0, { dec: 2 })}
                            symbol={currency.symbol}
                          />
                        </div>
                      )
                    }
                  </div>
                </>
              ) : (
                <h5 className='fw-b m-2 text-center'>{literals.selectDebtOrOption}</h5>
              )}
            </div>
            { editable && (
              <div className='buttons'>
                <Button type='submit' text={literalsCommon.confirm} />
                { type === 'operation' && data.id && (
                  <Button
                    type='button'
                    color='danger'
                    className='mr-3'
                    text={literalsCommon.delete}
                    onClick={handleDeleteForm}
                  />
                )}
              </div>
            )}
          </>
        ) : (
          <Loading hide={false} mode='panel' />
        )
      }
      {
        (popupShareClass) && (
          <PopupFormShareClass
            captable={captable?.selected?.id}
            onClose={() => setPopupShareClass(false)}
            onSubmit={sc => setConversion({ ...conversion, shareClass: sc.id })}
          />
        )
      }
      {
        (vestingPlanPopup) && (
          <PopupInfoVestingsPlans startup={params.id} onClose={() => setVestingPlanPopup(null)} id={vestingPlanPopup} />
        )
      }
    </form>
  );
};

FormConversions.propTypes = {
  data: PropTypes.object,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  type: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  editable: PropTypes.bool,
  dateOrID: PropTypes.string,
  convertibles: PropTypes.object,
  shareholder: PropTypes.string,
  lastNumeration: PropTypes.number.isRequired,
};

FormConversions.defaultProps = {
  type: '',
  data: null,
  editable: true,
  dateOrID: formatDate(new Date(), { format: 'Y-m-d' }),
  onDelete: null,
  convertibles: null,
  shareholder: null,
};

export default FormConversions;
