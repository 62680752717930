/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { getIconFile } from 'utils/functions';
import { connect } from 'react-redux';
import './styles.scss';

const InputFileZone = ({
  preText = '',
  postText = '',
  placeholder = '',
  isRequired = false,
  isDisabled = false,
  onChange,
  literals,
  errors = [],
  value = null,
}) => {
  const [fileData, setFileData] = useState(value);
  const fileRef = useRef(null);

  const changeValue = () => {
    const newFile = fileRef.current.files[0];
    setFileData(newFile);
    onChange(newFile);
  };

  return (
    <div className={`input_filezone ${isDisabled ? 'disabled' : ''}`}>
      <div className='pre_text'>
        {preText}
        {isRequired && <span className='is_required'>*</span>}
      </div>
      <label htmlFor='file-upload' className='custom-file-upload'>
        {
          fileData ? (
            <>
              { getIconFile(fileData.name) }
              { fileData.name }
            </>
          ) : (
            literals.uploadFilezone
          )
        }
      </label>
      <input
        id='file-upload'
        type='file'
        ref={fileRef}
        placeholder={placeholder}
        disabled={isDisabled}
        onChange={changeValue}
      />
      { postText && (<div className='post_text' dangerouslySetInnerHTML={{ __html: postText }} />) }
      <div className='errors'>
        {errors.map((err, index) => (
          <span key={index}>
            {typeof err === 'object' ? err.message : err}
            <br />
          </span>
        ))}
      </div>
    </div>
  );
};

InputFileZone.propTypes = {
  preText: PropTypes.string,
  postText: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  literals: PropTypes.object.isRequired,
  errors: PropTypes.array,
  value: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    literals: state.i18n.literals.input,
  };
};
export default connect(mapStateToProps, {})(InputFileZone);
