const { useEffect, useState } = require('react');

export const useUpdateValuation = (subOperations) => {
  const [updateValuation, setUpdateValuation] = useState([]);

  useEffect(() => {
    const updValuation = [];
    subOperations.shares.forEach((share) => {
      if (share.create.valuation) {
        updValuation.push(share.create.id);
      }
    });
    subOperations.secondaries.forEach((scd) => {
      Object.keys(scd.shares).forEach((share) => {
        if (scd.shares[share].valuation) {
          updValuation.push(scd.id);
        }
      });
    });
    setUpdateValuation(updValuation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subOperations.shares, subOperations.secondaries]);

  return updateValuation;
};
