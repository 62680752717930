/* eslint-disable react/jsx-one-expression-per-line */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import Portal from 'components/Portal';
import { useRect } from 'utils/customHooks';
import { normalizeText } from 'utils/functions';
import { X } from 'lucide-react';
import { connect } from 'react-redux';
import './styles.scss';

const InputMultiValues = (props) => {
  const {
    className = '',
    literals,
    options,
    value = [],
    onChange,
    preText = '',
    postText = '',
    placeholder = '',
    isRequired = false,
    isDisabled = false,
    error = null,
  } = props;

  const [showOptions, setShowOptions] = useState(false);
  const [search, setSearch] = useState({ value: '', normalized: '' });
  const [focus, setFocus] = useState(value.length
    ? { index: options?.findIndex(val => val.id === value), id: value }
    : { index: 0, id: options?.[0]?.id });
  const divRef = useRef(null);
  const [bbox, inputRef, visible] = useRect(showOptions);


  const selectValue = (event) => {
    const id = event.id || event.target.getAttribute('data-id');
    if (id) {
      const foundValue = options.find(elem => elem.id === id);
      if (foundValue && !value.includes(id)) {
        const newValue = [...value, id];
        onChange(newValue);
      }
    }
    setSearch({ value: '', normalized: '' });
    setShowOptions(false);
  };

  const selectFocusValue = (val) => {
    if (val && val.index >= 0) {
      const foundValue = options.find(elem => elem.id === val.id);
      if (foundValue && !value.includes(val.id)) {
        const newValue = [...value, val.id];
        onChange(newValue);
      }
    }
    setSearch({ value: '', normalized: '' });
  };

  const removeValue = (id) => {
    const foundValue = value.indexOf(id);
    if (foundValue !== -1) {
      const newValue = [...value];
      newValue.splice(foundValue, 1);
      onChange(newValue);
    }
  };

  function findLastIndex() {
    const count = options.length - 1;
    const index = options.slice().reverse().findIndex(
      (elem, i) => i > count - focus.index && normalizeText(elem.name).startsWith(search.normalized) && !value.includes(elem.id),
    );
    const finalIndex = index >= 0 ? count - index : index;
    return finalIndex;
  }

  const handleKeyPress = useCallback((e) => {
    if (e.keyCode === 40) {
      e.preventDefault();
      if (!focus) {
        setFocus({ index: 0, id: options[0]?.id });
      } else if (focus.index < options.length - 1) {
        setFocus((prev) => {
          const newElem = options.findIndex((elem, i) => i > prev.index && normalizeText(elem.name).startsWith(search.normalized) && !value.includes(elem.id));
          return newElem !== -1 ? { index: newElem, id: options[newElem]?.id } : prev;
        });
      }
    } else if (e.keyCode === 38) {
      e.preventDefault();
      if (!focus) {
        setFocus({ index: 0, id: options[0]?.id });
      } else if (focus.index > 0) {
        setFocus((prev) => {
          const newIndex = findLastIndex();
          return newIndex !== -1 ? { index: newIndex, id: options[newIndex]?.id } : prev;
        });
      }
    } else if (e.keyCode === 13 && focus) {
      e.preventDefault();
      selectFocusValue(focus);
      setShowOptions(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus, search]);

  useEffect(() => {
    if (showOptions) {
      if (divRef && divRef.current) {
        divRef.current.parentNode.scrollTop = divRef.current.offsetTop - 100;
      }
      window.addEventListener('keydown', handleKeyPress, false);
      return () => {
        window.removeEventListener('keydown', handleKeyPress, false);
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOptions, handleKeyPress]);


  const renderOptions = () => {
    const items = [];
    options.forEach((elem) => {
      if (!search.normalized.length || normalizeText(elem.name).startsWith(search.normalized)) {
        if (!value || !value.includes(elem.id)) {
          items.push((
            <div
              ref={focus?.id === elem.id ? divRef : null}
              className={`input-select-item ${focus?.id === elem.id ? 'selected focus' : ''}`}
              data-id={elem.id}
              key={elem.id}
              onClick={selectValue}
            >
              {elem.name}
            </div>
          ));
        }
      }
    });


    return items.length ? items : (
      <div className='input-select-item is-empty text-center font-italic'>{literals.noOptions}</div>
    );
  };

  const renderSelectedItems = () => {
    return value.map((selectedItem) => {
      const chosen = options ? options.find(option => option.id === selectedItem) : true;
      return chosen ? (
        <span
          className='input-select-option-selected'
          key={selectedItem}
          onClick={() => removeValue(selectedItem)}
        >
          {options ? chosen.name : selectedItem}
          <X size={18} />
        </span>
      ) : '';
    });
  };

  const styles = { visible };
  if (bbox) {
    styles.top = bbox.bottom;
    styles.left = bbox.left;
    styles.width = bbox.width;
  }

  return (
    <div className={`input-select-wrapper value-inside ${className} ${isDisabled ? 'disabled' : ''}`}>
      {preText && (
        <div className={`pre_text ${error ? 'text_error' : ''}`}>
          {preText}
          {isRequired && <span className='is_required'>*</span>}
        </div>
      )}
      <div
        className={`input-select-select ${error ? 'input_error' : ''} ${showOptions ? 'no-border' : ''}`}
        ref={inputRef}
        onClick={() => inputRef.current.focus()}
      >
        <div className='selected-item'>
          <div className='selected-items'>
            {value && value.length ? renderSelectedItems() : null}
            <input
              className='input-select-text'
              placeholder={`${placeholder}${!options?.length ? ` (${literals.enterToAdd})` : ''}`}
              type='text'
              value={search.value}
              onClick={e => e.stopPropagation()}
              onChange={e => setSearch({ value: e.target.value, normalized: normalizeText(e.target.value) })}
              onKeyDown={(e) => {
                if (options?.length) {
                  setShowOptions(true);
                } else if (e.key === 'Enter') {
                  e.preventDefault();
                  if (search.value) {
                    onChange([...value, search.value]);
                    setSearch({ value: '', normalized: '' });
                  }
                }
              }}
              onFocus={options?.length ? () => setTimeout(() => { setShowOptions(true); }, 100) : null}
            />
          </div>
          {value.length ? (
            <div style={{ width: '20px', marginLeft: '10px' }}>
              <X className='x-input-select' size={20} onClick={(e) => { e.stopPropagation(); onChange([]); }} />
            </div>
          ) : null}
        </div>
      </div>
      {showOptions && (
        <Portal styles={styles} onClose={() => setShowOptions(false)}>
          <div className='input-select-item-options simple-scrollbar'>
            {renderOptions()}
          </div>
        </Portal>
      )}
      { postText && (<div className='post_text' dangerouslySetInnerHTML={{ __html: postText }} />) }
    </div>
  );
};

InputMultiValues.propTypes = {
  literals: PropTypes.object.isRequired,
  preText: PropTypes.string, // Texto situado antes del input
  postText: PropTypes.string, // Texto situado despues del input
  isRequired: PropTypes.bool, // Indica si es obligatorio (visualmente nada más)
  isDisabled: PropTypes.bool, // Indica si el input está deshabilitado
  options: PropTypes.array.isRequired, // Opciones a escoger en el select
  onChange: PropTypes.func.isRequired, // Qué se hace cuando se cambia la selección
  error: PropTypes.object,
  value: PropTypes.array,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    literals: state.i18n.literals.input,
  };
};


export default connect(mapStateToProps, {})(InputMultiValues);
