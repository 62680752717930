import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import useDialog from 'components/Dialog/components/useDialog';
import StatCard from 'components/Statistics/StatCard';
import { formatDate, getTranslation } from 'utils/functions';
import { useCounter } from 'utils/customHooks';
import {
  Calendar, CircleCheck, Clock, Mail, CircleSlash,
  Pencil, PlusCircle, Trash,
} from 'lucide-react';
import { deleteServicesRequest, getServicesRequests, getServicesRequestsStats } from '../modules/actions';
import FormRequest from './FormRequest';
import './styles.scss';

const RequestTab = ({ literals, literalsCommon, lang }) => {
  const [stats, setStats] = useState({
    lead: null,
    appointment: null,
    pending: null,
    rejected: null,
    done: null,
  });

  const [filterValue, setFilterValue] = useState({});
  const [showFormRequest, setShowFormRequest] = useState(false);
  const tableRefreshFlag = useCounter(0);
  const { dialog } = useDialog();

  useEffect(() => {
    (async () => {
      const auxStats = await getServicesRequestsStats();
      setStats(auxStats);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRefreshFlag.value]);

  const handleRemoveRequest = async (request) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literals.questionRemoveRequest,
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteServicesRequest(request.id);
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const getIcon = (status) => {
    switch (status) {
      case 'lead':
        return <Mail />;
      case 'appointment':
        return <Calendar />;
      case 'pending':
        return <Clock />;
      case 'rejected':
        return <CircleSlash />;
      case 'done':
        return <CircleCheck />;
      default:
        return null;
    }
  };

  const columns = [
    {
      field: 'service',
      label: literals.service,
      preRender: v => (v.company ? getTranslation(v.company, lang) : (literals[v] ?? v)),
    },
    {
      field: 'startup',
      label: 'Startup',
      preRender: v => v.name,
    },
    {
      field: 'status',
      label: literals.status,
      preRender: v => (
        <div className='cell-keywords'>
          <div className='d-flex align-items-center' style={{ gap: '7px' }}>
            {getIcon(v)}
            {literals[v]}
          </div>
        </div>
      ),
    },
    {
      field: 'createdAt',
      label: literals.creationDate,
      preRender: v => formatDate(v, { time: true }),
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Pencil,
          text: literalsCommon.edit,
          onClick: setShowFormRequest,
        },
        {
          icon: Trash,
          text: literalsCommon.delete,
          onClick: handleRemoveRequest,
        },
      ],
    },
  ];

  const filters = [
    {
      id: 'status',
      type: 'select',
      title: literals.status,
      options: [
        { id: 'lead', name: literals.lead },
        { id: 'appointment', name: literals.appointment },
        { id: 'pending', name: literals.pending },
        { id: 'rejected', name: literals.rejected },
        { id: 'done', name: literals.done },
      ],
    },
    {
      id: 'type',
      type: 'select',
      title: literalsCommon.type,
      options: [
        { id: 'internal', name: literals.internalService },
        { id: 'external', name: literals.externalService },
      ],
    },
  ];

  return (
    <div className='request-tab'>
      <div className='status-stats'>
        {Object.keys(stats).map(status => (
          <StatCard
            key={status}
            icon={getIcon(status)}
            title={literals[status]}
            value={stats[status]}
            loading={stats[status] === null}
            onClick={() => setFilterValue({ status: [status] })}
          />
        ))}
      </div>
      <Table
        columns={columns}
        filters={filters}
        externalFilterValue={filterValue}
        pageSize={10}
        fetch={getServicesRequests}
        forceFetch={tableRefreshFlag.value}
        onClickRow={row => setShowFormRequest(row)}
        actions={[{
          icon: PlusCircle,
          text: literals.createRequest,
          onClick: () => setShowFormRequest('new'),
        }]}
      />
      { showFormRequest && (
        <FormRequest
          literals={literals}
          literalsCommon={literalsCommon}
          lang={lang}
          request={showFormRequest !== 'new' ? showFormRequest : null}
          onDelete={handleRemoveRequest}
          refreshTable={tableRefreshFlag.increase}
          onClose={() => setShowFormRequest(null)}
        />
      )}
    </div>
  );
};

RequestTab.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};

export default RequestTab;
