import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadingAction } from '../../../store/globalState/global';
import LoginComponents from '../components/index';
import { fetchCleanErrorsAction, fetchLoginAction, fetchRecoveryAction } from '../modules/actions';

function mapStateToProps(state) {
  return {
    platform: state.global.platform,
    isAuthenticated: state.session.authenticated,
    literals: state.i18n.literals.login,
    literalsCommon: state.i18n.literals.common,
    loginError: state.login.loginError,
    recoveryError: state.login.recoveryError,
    userLang: state.i18n.language,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLogin: bindActionCreators(fetchLoginAction, dispatch),
    onRecovery: bindActionCreators(fetchRecoveryAction, dispatch),
    cleanErrors: bindActionCreators(fetchCleanErrorsAction, dispatch),
    showLoading: bindActionCreators(loadingAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponents);
