import {
  loadingAction,
} from '../../../store/globalState/global';

import {
  SET_PLATFORM_LANGUAGE,
} from './types';

export function setPlatformLanguage(lang, literals) {
  return {
    type: SET_PLATFORM_LANGUAGE,
    payload: {
      lang,
      literals,
    },
  };
}

export function setPlatformLanguageAction(lang, platformLiterals = false) {
  return (dispatch) => {
    dispatch(loadingAction(true));
    dispatch(setPlatformLanguage(lang, platformLiterals));

    setTimeout(() => {
      dispatch(loadingAction(false));
    }, 1000);
  };
}
