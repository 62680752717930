export const API_ADMIN_STARTUPS_EXCEL = '/api/v1/admin/startups/excel';
export const API_ADMIN_STARTUPS_EXCEL_TEMPLATE = '/api/v1/admin/startups/excel/template';
export const API_ADMIN_USERS_EXCEL = '/api/v1/admin/users/excel';
export const API_ADMIN_USERS_EXCEL_TEMPLATE = '/api/v1/admin/users/excel/template';
export const API_ADMIN_FUNDS_EXCEL = '/api/v1/admin/funds/excel';
export const API_ADMIN_FUNDS_EXCEL_TEMPLATE = '/api/v1/admin/funds/excel/template';
export const API_ADMIN_MENTORINGS_EXCEL = '/api/v1/admin/mentorings/excel';
export const API_INVESTMENTS_EXCEL = tid => `/api/v1/investments/excel${tid ? `?taxId=${tid}` : ''}`;
export const API_INVESTMENTS_KPIS_EXCEL = params => `/api/v1/investments/kpis/excel${params ? `?${params}` : ''}`;
export const API_SHAREHOLDERS_EXCEL_TEMPLATE = id => `/api/v1/startups/${id}/captable/shareholders/excel/template`;
export const API_SHAREHOLDERS_EXCEL = id => `/api/v1/startups/${id}/captable/shareholders/excel`;
export const API_SHARECLASSES_EXCEL = id => `/api/v1/startups/${id}/captable/shareClasses/excel`;
export const API_STARTUP_CAPTABLE = (id, captable, date) => `/api/v1/startups/${id}/captable/excel${date ? `/${date}` : ''}${captable ? `?captable=${captable}` : ''}`;
export const API_IMPORT_CAPTABLE_EXCEL = id => `/api/v1/startups/${id}/captables/excel`;
export const API_REPORT_PDF = (id, report) => `/api/v1/startups/${id}/reports/${report}/pdf`;
export const API_KPIS_EXCEL = (scopeType, scopeId, mode = '') => `/api/v1/kpis/${scopeType}/${scopeId}/excel${mode ? `?mode=${mode}` : ''}`;
export const API_WATERFALL_EXCEL = (id, waterfall) => `/api/v1/startups/${id}/captable/waterfalls/${waterfall}/excel`;
export const API_WATERFALL_COMPARATOR_EXCEL = (id, waterfalls) => `/api/v1/startups/${id}/captable/waterfalls/compare/excel?ids=${waterfalls}`;
export const API_SHARES_DISTRIBUTION_EXCEL = (id, captable, date) => `/api/v1/startups/${id}/captable/shares/sharesDistribution/excel/${date}${captable ? `?captable=${captable}` : ''}`;
export const API_STATUTORY_BOOK_EXCEL = (id, captable, date) => `/api/v1/startups/${id}/captable/shares/statutoryBook/excel/${date}${captable ? `?captable=${captable}` : ''}`;
export const API_SHAREHOLDER_REPORT = (id, shareholder) => `/api/v1/startups/${id}/captable/shareholders/${shareholder}/report`;
export const API_CAPTABLE_REPORT = (id, captable, date) => `/api/v1/startups/${id}/captable/report/${date}${captable ? `?captable=${captable}` : ''}`;
export const API_SIGNAURE_DOCUMENT = (type, id, doc = 'signed') => `/api/v1/signature/${type}/${id}/download?doc=${doc}`;
