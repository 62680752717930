import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import Popup from 'components/Popup';
import InputText from 'components/Inputs/inputText';
import InputPerms from 'components/Inputs/inputPerms';
import Button from 'components/Buttons/Button';
import Loading from 'components/Loading';
import { Trash } from 'lucide-react';
import { getFullName } from 'utils/functions';
import OutputValue from 'components/Inputs/outputValue';
import { DEFAULT_TYPE_GROUP } from 'constants/permissions';
import { getPermissionGroupName } from 'utils/functions/getPermissionGroupName';
import { createPermissionGroup, editPermissionGroup, getPermissionGroup } from './modules/actions';
import PermissionsSearcher from './PermissionsSearcher';
import './styles.scss';

const PermissionsGroup = (props) => {
  const {
    id,
    scope,
    onSubmit,
    onClose,
    literals,
    literalsCommon,
  } = props;

  const [name, setName] = useState('');
  const [users, setUsers] = useState([]);
  const [isDefault, setIsDefault] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState();

  useEffect(() => {
    (async () => {
      if (id) {
        const group = await getPermissionGroup(scope, id);
        if (group) {
          setName(group.name);
          setUsers(group.users);
          setPermissions(group.permissions);
          setIsDefault(group.default);
        }
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddUser = async (user) => {
    setUsers(prev => [...prev, user]);
  };

  const handleRemoveUser = (userId) => {
    setUsers(prev => prev.filter(u => u.id !== userId));
  };

  const handleSubmit = async () => {
    const newUsers = [
      ...users.map(auxUser => auxUser.id),
    ];

    const data = {
      name,
      users: newUsers,
      permissions,
    };

    setSaving(true);
    const response = id
      ? await editPermissionGroup(scope, id, data)
      : await createPermissionGroup(scope, data);

    setSaving(false);
    if (response) {
      onSubmit(response);
    }
  };

  const renderRows = rows => rows.map((row) => {
    const rowName = getFullName(row);

    return (
      <div key={row.email} className='permissions-row'>
        <div className='permissions-row--left'>
          <Avatar size={35} src={row.avatar?.path} title={rowName} />
          <div className='ml-3'>
            <div>{rowName}</div>
            {row.email && <div className='fc-secondary fs-sm'>{row.email}</div>}
          </div>
        </div>
        {!isDefault && <Trash onClick={() => handleRemoveUser(row.id)} />}
      </div>
    );
  });

  if (loading) {
    return (
      <Popup title={id ? literals.editGroup : literals.createGroup} onClose={() => onClose(null)}>
        <Loading mode='panel' hide={false} />
      </Popup>
    );
  }

  return (
    <Popup title={id ? literals.editGroup : literals.createGroup} onClose={() => onClose(null)}>
      <div className='permissions-popup'>
        {isDefault ? (
          <OutputValue
            preText={literalsCommon.name}
            value={getPermissionGroupName({ name, type: 'group' }, literals)}
          />
        ) : (
          <>
            <InputText
              preText={literalsCommon.name}
              placeholder={literals.inputGroupName}
              value={name}
              onChange={setName}
              isDisabled={isDefault}
            />
            <div className='permissions-addUser'>
              <PermissionsSearcher
                literals={literals}
                preText={literals.addPerson}
                placeholder={literals.searchUsers}
                scope={scope}
                type={['users']}
                exclude={{ users: users.map(aux => aux.id) }}
                isDisabled={loading}
                onAdd={handleAddUser}
              />
            </div>
          </>
        )}

        <div className='fw-b fc-light mt-4 mb-2'>{`${literals.members} (${users.length})`}</div>
        <div className='permissions-rows'>
          {
            users.length
              ? renderRows(users)
              : <p className='font-italic'>{literals.noUsers}</p>
          }
        </div>

        <div className='mt-4'>
          <InputPerms
            preText={literals.permissions}
            value={permissions}
            onChange={v => setPermissions(v)}
            isDisabled={isDefault && name === DEFAULT_TYPE_GROUP.OWNER}
          />
        </div>

        <div className='buttons'>
          <Button
            text={literalsCommon.save}
            onClick={handleSubmit}
            loading={saving}
          />
        </div>
      </div>
    </Popup>
  );
};

PermissionsGroup.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  scope: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string,
};

PermissionsGroup.defaultProps = {
  id: null,
  onSubmit: () => {},
};

export default PermissionsGroup;
