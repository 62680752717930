import { connect } from 'react-redux';
import WaterfallAddComponents from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.captable,
  literalsCommon: state.i18n.literals.common,
  startup: state.global.startup,
  currency: state.global.currency,
});
export default connect(mapStateToProps)(WaterfallAddComponents);
