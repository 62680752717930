/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputEmail from 'components/Inputs/inputEmail';
import InputSelect from 'components/Inputs/inputSelect';
import InputText from 'components/Inputs/inputText';
import Button from 'components/Buttons/Button';
import Popup from 'components/Popup';
import Loading from 'components/Loading';
import * as EmailValidator from 'email-validator';
import OutputErrors from 'components/Inputs/outputErrors';
import InputPerms from 'components/Inputs/inputPerms';
import { STARTUP_ROLES, STARTUP_ROLE_USER } from 'constants/roles';
import { EN_LANGUAGE } from 'utils/language';
import { Check, UserPlus } from 'lucide-react';
import { fetchPermissionGroups } from 'routes/PermissionTools/modules/actions';
import { addUserToStartup, editUserStartup } from './modules/actions';

const StartupUsersForm = (props) => {
  const {
    literals,
    title,
    startup,
    user,
    popup,
    onClose,
    onSubmit,
  } = props;

  const [email, setEmail] = useState(user?.email || user?.contactInfo?.email || '');
  const [firstname, setFirstname] = useState(user?.firstname || '');
  const [lastname, setLastname] = useState(user?.lastname || '');
  const [lang, setLang] = useState(user?.lang || EN_LANGUAGE);
  const [type, setType] = useState(user?.type || STARTUP_ROLE_USER);
  const [permissions, setPermissions] = useState(user?.permissions || {});
  const [groupsPermissions, setGroupsPermissions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    const loadPermissions = async () => {
      const groups = await fetchPermissionGroups('startup', startup, {
        page: 0,
        size: 0,
        search: '',
        filters: { default: true },
      });

      const auxGroupPermissions = {};
      groups.items.forEach((group) => {
        auxGroupPermissions[group.name] = group.permissions;
      });
      setGroupsPermissions(auxGroupPermissions);
      setPermissions(auxGroupPermissions[type] || {});
    };
    loadPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleType = (v) => {
    setType(v);
    setPermissions(groupsPermissions[v] || {});
  };

  const submitUser = async (ev) => {
    ev.preventDefault();
    const data = {
      firstname,
      lastname,
      email,
      type,
      permissions: null,
      lang,
      shareholder: user?.id || null,
    };

    setLoading(true);
    setErrors(false);

    let response = null;
    try {
      response = user?.id
        ? await editUserStartup(startup, user.id, data)
        : await addUserToStartup(startup, data);
    } catch (e) {
      response = null;
      setErrors(e);
    }
    setLoading(false);

    if (response && typeof onClose === 'function') {
      onSubmit({ ...response, email, type }, !user?.id);
    }
  };

  const fieldsValid = firstname && lastname && EmailValidator.validate(email) && lang;

  const renderForm = () => {
    if (!groupsPermissions) {
      return <Loading hide={false} mode='panel' />;
    }
    return (
      <form className='invite-user-form' onSubmit={submitUser}>
        <div className='row'>
          <div className='col-12 col-md-4'>
            <InputText
              preText={literals.name}
              placeholder={literals.name}
              onChange={v => setFirstname(v)}
              value={firstname}
              isDisabled={!!user?.id}
              isRequired
            />
          </div>
          <div className='col-12 col-md-8'>
            <InputText
              preText={literals.lastname}
              placeholder={literals.lastname}
              onChange={v => setLastname(v)}
              value={lastname}
              isDisabled={!!user?.id}
              isRequired
            />
          </div>
          <div className='col-12 col-md-6'>
            <InputEmail
              preText={literals.email}
              placeholder={literals.email}
              onChange={v => setEmail(v)}
              value={email}
              isDisabled={!!user?.id}
              isRequired
            />
          </div>
          <div className='col-12 col-md-3'>
            <InputSelect
              preText={literals.type}
              placeholder={literals.type}
              onChange={handleType}
              value={type}
              isRequired
              options={STARTUP_ROLES.map(role => ({
                id: role, name: literals.types[role] || role,
              }))}
            />
          </div>
          <div className='col-12 col-md-3'>
            <InputSelect
              preText={literals.lang}
              placeholder={literals.lang}
              onChange={v => setLang(v)}
              value={lang}
              isRequired
              options={Object.keys(literals.languages).map((key) => {
                return {
                  name: literals.languages[key],
                  id: key,
                };
              })}
            />
          </div>
        </div>
        { type && (
          <InputPerms
            preText={literals.permissionsByType}
            value={permissions}
            onChange={v => setPermissions(v)}
            isDisabled
          />
        )}
        <OutputErrors literals={literals} errors={errors} />
        <div className='buttons'>
          <Button
            type='submit'
            icon={user?.id ? (user?.changeStatus ? Check : null) : UserPlus}
            text={user?.id ? (user?.changeStatus ? literals.confirm : literals.edit) : literals.inviteUser}
            loading={loading}
            disabled={!fieldsValid}
          />
        </div>
      </form>
    );
  };

  if (!popup) {
    return renderForm();
  }
  return (
    <Popup
      title={title}
      onClose={onClose}
    >
      { renderForm() }
    </Popup>
  );
};

StartupUsersForm.propTypes = {
  title: PropTypes.string,
  startup: PropTypes.string.isRequired,
  user: PropTypes.object,
  literals: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  popup: PropTypes.bool,
};

StartupUsersForm.defaultProps = {
  title: '',
  user: {},
  popup: true,
  onClose: null,
};

export default StartupUsersForm;
