import SignatureInstance from 'modules/signature/repository';
import { apiDownloadFile } from 'modules/actions';
import { API_SIGNAURE_DOCUMENT } from 'modules/apiEndpoints';

export function fetchDocumentSignStatus(type, id) {
  return SignatureInstance.getDocumentSignStatus(type, id);
}

export function postDocumentToSign(type, id, opts) {
  return SignatureInstance.postDocumentToSign(type, id, opts);
}

export function downloadSignedDocument(type, id, doc = 'signed', filename) {
  return apiDownloadFile(API_SIGNAURE_DOCUMENT(type, id, doc), filename);
}
