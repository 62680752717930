import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from 'utils/functions';
import Popup from 'components/Popup';
import Button from 'components/Buttons/Button';
import { SquareCheck, Square } from 'lucide-react';

const PopupUpdateValuation = (props) => {
  const {
    shares,
    secondaries,
    onConfirm,
    captable,
    currency,
    literals,
    literalsCommon,
    onClose,
  } = props;

  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const auxItems = [];
    shares.forEach((item, index) => {
      auxItems.push({
        key: item.create?.id, type: 'shares', index, item,
      });
    });

    secondaries.forEach((item, index) => {
      Object.keys(item.shares).forEach((share) => {
        auxItems.push({
          key: `${item.id}#${share}`, type: 'secondaries', index, share, item: { shareholder: item.shareholder, ...item.shares[share] },
        });
      });
    });
    setItems(auxItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getShareholderName = (sid) => {
    if (sid) {
      const obj = captable.shareholders.find(sc => sc.id === sid);
      return obj ? obj.name : '-';
    }
    return '-';
  };

  const getShareClassName = (sid) => {
    if (sid) {
      const obj = captable.shareClasses.find(sc => sc.id === sid);
      return obj ? (obj.code || obj.name) : '-';
    }
    return '-';
  };

  const renderTitle = (item) => {
    switch (item.type) {
      case 'shares':
        return `${literals.issue.shares}: ${item.item.create?.number || 0} ${getShareClassName(item.item.create?.shareClass)}`;
      case 'secondaries':
        return `${literals.issue.secondaries}: ${getShareholderName(item.item.shareholder)}`;
      default:
        return null;
    }
  };

  const renderShareInfo = (item) => {
    const { create: share } = item;

    const assignedTo = [];
    let totalAssigned = 0;
    item.assign.forEach((assign) => {
      const assignPercent = (assign.number * 100) / share.number;
      assignedTo.push(`${getShareholderName(assign.shareholder)} (${formatNumber(assignPercent)}%)`);
      totalAssigned += assignPercent;
    });

    return (
      <>
        <div>
          <h6>{literals.shareClass}</h6>
          <span className='fw-sb'>{getShareClassName(share.shareClass)}</span>
        </div>
        <div>
          <h6>{literals.number}</h6>
          <span className='fw-sb'>{formatNumber(share.number, '-')}</span>
        </div>
        <div>
          <h6>{literals.pps}</h6>
          <span className='fw-sb'>{formatNumber(share.pps, 0, { dec: 2, symbol: currency.symbol })}</span>
        </div>
        <div>
          <h6>{literalsCommon.total}</h6>
          <span className='fw-sb'>{formatNumber(share.pps * share.number, 0, { dec: 2, symbol: currency.symbol })}</span>
        </div>
        <div>
          <h6>{literals.numeration}</h6>
          <span className='fw-sb'>{`${formatNumber(share.numeration?.from, '---')} - ${formatNumber(share.numeration?.to, '---')}`}</span>
        </div>
        <div title={assignedTo.join('; ')}>
          <h6>{literals.shareholders}</h6>
          <span className='fw-sb'>{item.assign.length}</span>
        </div>
        <div>
          <h6>{literals.assigned}</h6>
          <span className='fw-sb'>{formatNumber(totalAssigned, 0, { dec: 2, symbol: '%' })}</span>
        </div>
      </>
    );
  };

  const renderSecondariesInfo = (item) => {
    const secondary = {
      number: 0,
      shareClasses: null,
      pps: 0,
      shareholders: [],
      assigned: [],
      total: 0,
    };

    const shareClassName = getShareClassName(item.shareClass);
    secondary.shareClasses = shareClassName;
    secondary.pps = formatNumber(item.pps, 0, { dec: 2, symbol: currency.symbol });
    item.sales.forEach((sh) => {
      if (!secondary.shareholders.includes(sh.shareholder || 'unassigned')) {
        secondary.shareholders.push(sh.shareholder || 'unassigned');
      }
      secondary.number += sh.number;
      secondary.assigned.push(`${getShareholderName(sh.shareholder)} (${formatNumber(sh.number)} ${shareClassName})`);
      secondary.total += sh.number * item.pps;
    });

    return (
      <>
        <div>
          <h6>{literals.shareClass}</h6>
          <span className='fw-sb'>{secondary.shareClasses || '-'}</span>
        </div>
        <div>
          <h6>{literals.number}</h6>
          <span className='fw-sb'>{formatNumber(secondary.number, '-')}</span>
        </div>
        <div>
          <h6>{literals.pps}</h6>
          <span className='fw-sb'>{secondary.pps || '-'}</span>
        </div>
        <div title={secondary.assigned.join('; ')}>
          <h6>{literals.shareholders}</h6>
          <span className='fw-sb'>{formatNumber(secondary.shareholders.length, '-')}</span>
        </div>
        <div>
          <h6>{literalsCommon.total}</h6>
          <span className='fw-sb'>{formatNumber(secondary.total, '-', { dec: 2, symbol: currency.symbol })}</span>
        </div>
      </>
    );
  };

  const renderInfo = (item) => {
    switch (item.type) {
      case 'shares':
        return renderShareInfo(item.item);
      case 'secondaries':
        return renderSecondariesInfo(item.item);
      default:
        return null;
    }
  };

  return (
    <Popup title='Actualizar valor' size='large' onClose={onClose}>
      <h6 className='fw-b'>{literals.conflictMessage}</h6>
      <ul className='box operation-list-valuation mb-sp'>
        {items.map(item => (
          <li className={selected?.key === item.key ? 'selected' : null} onClick={() => setSelected(item)}>
            <div className='operation-info'>
              <h5 className='d-flex align-items-center checkIcon'>
                {selected?.key === item.key ? (<SquareCheck />) : (<Square />)}
                {renderTitle(item)}
              </h5>
              <div className='operation-detail'>{renderInfo(item)}</div>
            </div>
          </li>
        ))}
      </ul>
      <div className='d-flex justify-content-end'>
        <Button
          text={literalsCommon.confirm}
          onClick={() => { onConfirm(selected.type, selected.index, selected.share); onClose(); }}
          disabled={!selected}
        />
      </div>
    </Popup>
  );
};

PopupUpdateValuation.propTypes = {
  shares: PropTypes.array.isRequired,
  secondaries: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupUpdateValuation;
