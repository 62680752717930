import React from 'react';
import { useLocation, useMatch, useParams } from 'react-router-dom';

export function withRouter(Component) {
  return props => (
    <Component
      {...props}
      match={{
        ...useMatch(useLocation().pathname),
        params: useParams(),
      }}
    />
  );
}
