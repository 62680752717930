import initialState from '../../../store/globalState/initialState';

import {
  FETCH_LOGIN_INIT,
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGIN_FAILURE,
  FETCH_RECOVERY_INIT,
  FETCH_RECOVERY_SUCCESS,
  FETCH_RECOVERY_FAILURE,
  FETCH_CLEAN_ERRORS,
} from './types';

export default function loginReducer(state = initialState.login, action) {
  switch (action.type) {
    case FETCH_LOGIN_INIT:
    case FETCH_LOGIN_SUCCESS:
      return {
        ...state,
        loginError: null,
      };
    case FETCH_LOGIN_FAILURE:
      return {
        ...state,
        loginError: action.payload,
      };
    case FETCH_RECOVERY_INIT:
    case FETCH_RECOVERY_SUCCESS:
      return {
        ...state,
        recoveryError: null,
      };
    case FETCH_RECOVERY_FAILURE:
      return {
        ...state,
        recoveryError: action.payload,
      };
    case FETCH_CLEAN_ERRORS:
      return {
        ...state,
        loginError: null,
        recoveryError: null,
      };
    default:
      return state;
  }
}
