import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setPlatformLanguageAction } from 'components/LanguageSelector/modules/actions';
import EditAccount from '../components';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.editAccount,
    user: state.session.user,
    lang: state.i18n.language,
    company: state.company,
    platform: state.global.platform,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPlatformLanguageAction: bindActionCreators(setPlatformLanguageAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAccount);
