import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Loading from 'components/Loading';
import { fetchMentoringsSummary } from 'routes/Mentorings/modules/actions';
import EmptyStreet from 'assets/svg/empty_street.svg';
import EmptyData from 'assets/svg/empty_data.svg';
import MentoringCard from 'components/MentoringCard';
import Meeting from 'components/Meetings/components/Meeting';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

const SummaryStartupMentorings = (props) => {
  const {
    match,
    literals,
    literalsCommon,
  } = props;

  const [mentoringSummary, setMentoringsSummary] = useState({ loading: false, data: null });

  async function loadMentoringsSummary() {
    setMentoringsSummary({ ...mentoringSummary, data: null, loading: true });
    const response = await fetchMentoringsSummary('startup', match.params.id);
    setMentoringsSummary({ data: { id: match.params.id, ...response }, loading: false });
  }

  useEffect(() => {
    if (!mentoringSummary.data && !mentoringSummary.loading) {
      loadMentoringsSummary();
    } else if (mentoringSummary.data && match.params.id !== mentoringSummary.data.id) {
      loadMentoringsSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  return (
    <>
      <div className='summary-mentorings-mentors'>
        <Panel>
          <PanelHeader title={literals.mentors} subtitle={literals.mentorsSubtitle} />
          <PanelBody>
            {
              (mentoringSummary && mentoringSummary.data && mentoringSummary.data.mentorings) ? (
                <div>
                  {
                    mentoringSummary.data.mentorings.length ? (
                      <Swiper
                        className='swiper-pagination-below'
                        slidesPerView={1}
                        spaceBetween={25}
                        breakpoints={{
                          768: { slidesPerView: 2 },
                          1280: { slidesPerView: 3 },
                          1750: { slidesPerView: 4 },
                        }}
                        pagination={{
                          clickable: true,
                        }}
                        modules={[Pagination]}
                      >
                        {
                          mentoringSummary.data.mentorings.map(mentoring => (
                            <SwiperSlide key={mentoring.id}>
                              <MentoringCard view={{ type: 'startup', id: match.params.id }} data={mentoring} />
                            </SwiperSlide>
                          ))
                        }
                      </Swiper>

                    ) : (
                      <div className='panel-empty-message m-0'>
                        <img src={EmptyStreet} alt='' />
                        <p>{literals.noMentorings}</p>
                      </div>
                    )
                  }
                </div>
              ) : (
                <Loading hide={false} mode='panel' />
              )
            }
          </PanelBody>
        </Panel>
      </div>
      <div className='summary-mentorings-meetings'>
        <Panel>
          <PanelHeader title={literals.nextMeetings} />
          <PanelBody>
            {
              (mentoringSummary && mentoringSummary.data && mentoringSummary.data.meetings) ? (
                <div>
                  {
                    mentoringSummary.data.meetings.length ? (
                      <Swiper
                        className='swiper-pagination-below'
                        slidesPerView={1}
                        spaceBetween={25}
                        breakpoints={{
                          768: { slidesPerView: 2 },
                          1280: { slidesPerView: 3 },
                          1750: { slidesPerView: 4 },
                        }}
                        pagination={{
                          clickable: true,
                        }}
                        modules={[Pagination]}
                      >
                        {
                          mentoringSummary.data.meetings.map(meeting => (
                            <SwiperSlide key={meeting.id}>
                              <Meeting view={{ type: 'startup', id: match.params.id }} meeting={meeting} literalsCommon={literalsCommon} />
                            </SwiperSlide>
                          ))
                        }
                      </Swiper>
                    ) : (
                      <div className='panel-empty-message m-0'>
                        <img src={EmptyData} alt='' />
                        <p>{literals.noMeetings}</p>
                      </div>
                    )
                  }
                </div>
              ) : (
                <Loading hide={false} mode='panel' />
              )
            }
          </PanelBody>
        </Panel>
      </div>
    </>
  );
};

SummaryStartupMentorings.propTypes = {
  match: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
};

export default SummaryStartupMentorings;
