import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const RenderRow = ({
  canView = true,
  path = null,
  to = '',
  route,
  toggleMenu,
  name,
  icon,
  subMenu = [],
  showSubmenu = false,
  handleShowSubmenu = () => {},
  exact = false,
  external = false,
}) => {
  const isActualPath = (newRoute, isExact) => {
    let auxRoute = newRoute;
    if (!Array.isArray(newRoute)) {
      auxRoute = [newRoute];
    }
    return auxRoute.some(auxNewRoute => (
      route.path === auxNewRoute || (!isExact && route.path?.startsWith(auxNewRoute))
    ));
  };

  if (!canView || (subMenu.length && subMenu.every(r => r.canView === false))) {
    return null;
  }

  if (external) {
    return (
      <li>
        <a className='nav-item' href={to} target='_blank' rel='noreferrer noopener'>
          <div className='left-menu-icon'>
            {icon && React.createElement(icon, { size: 26 })}
          </div>
          <p>{name}</p>
        </a>
      </li>
    );
  }

  return (
    <li className={isActualPath(path, exact) ? 'active' : ''}>
      {!subMenu.length ? (
        <Link className='nav-item' to={to} onClick={toggleMenu}>
          <div className='left-menu-icon'>
            {icon && React.createElement(icon, { size: 26 })}
          </div>
          <p>{name}</p>
        </Link>
      ) : (
        <>
          <div className='nav-item' onClick={handleShowSubmenu}>
            <div className='left-menu-icon'>
              {icon && React.createElement(icon, { size: 26 })}
            </div>
            <p>{name}</p>
            <div className={`nav-item-submenu-icon ${showSubmenu ? 'rotate' : ''}`}>
              <ChevronDown size={12} />
            </div>
          </div>
          <ul className={`left-menu-nav-subitems ${showSubmenu ? 'show' : ''}`}>
            { subMenu.map(auxRoute => auxRoute.canView !== false && (
              <li key={auxRoute.path} className={`${isActualPath(auxRoute.path, true) ? 'active' : ''} ${auxRoute.hide ? 'hide' : ''}`}>
                <Link to={auxRoute.to} onClick={toggleMenu}>
                  <p>{auxRoute.name}</p>
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </li>
  );
};

RenderRow.propTypes = {
  canView: PropTypes.bool,
  path: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  to: PropTypes.string,
  route: PropTypes.object.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  subMenu: PropTypes.array,
  showSubmenu: PropTypes.bool,
  handleShowSubmenu: PropTypes.func,
  exact: PropTypes.bool,
  external: PropTypes.bool,
};

export default RenderRow;
