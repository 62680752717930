import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import StartupPanel from 'components/StartupPanel';
import StatCard from 'components/Statistics/StatCard';
import InputSlider from 'components/Inputs/inputSlider';
import Button from 'components/Buttons/Button';
import Alert from 'components/Alert';
import { Skeleton } from '@mui/material';
import { FILES_BASE_URL } from 'constants/apiConf';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { ROUTE_PATH, ROUTE_STARTUP_PATH } from 'routes';
import { formatNumber, getIconFile } from 'utils/functions';
import { Pencil, PiggyBank, Share2 } from 'lucide-react';
import PercentageCircleChart from './PercentageCircleChart';
import FundraiseInvestPopup from './FundraiseInvestPopup';
import FundraiseInvitePopup from './FundraiseInvitePopup';
import KPITable from './KPITable';
import './styles.scss';

const FundraiseInfoTab = (props) => {
  const {
    literals,
    literalsPerformance,
    literalsCommon,
    fundraise,
    setFundraise,
    permission,
    lang,
    user,
    loading,
    symbol,
  } = props;

  const [showInvestPopup, setShowInvestPopup] = useState(false);
  const [showInvitePopup, setShowInvitePopup] = useState(false);
  const [searchParams] = useSearchParams();
  const invite = searchParams.get('invite');
  const referral = searchParams.get('referral');
  const navigate = useNavigate();

  const renderRound = () => {
    return (
      <>
        { loading ? (
          <>
            <Skeleton animation='wave' width='50%' className='mb-2' />
            <Skeleton animation='wave' />
            <Skeleton animation='wave' className='mb-3' />
          </>
        ) : (
          <>
            <h4 className='fw-b'>{fundraise?.round}</h4>
            <div className='mb-3' dangerouslySetInnerHTML={{ __html: fundraise?.description }} />
          </>
        )}
        <div className='row'>
          <div className='col-12 col-md-6'>
            <StatCard title={literals.amount} value={formatNumber(fundraise?.amount, 0, { symbol })} valueSize='h4' loading={loading} />
          </div>
          <div className='col-12 col-md-6'>
            <StatCard title={literals.postMoney} value={formatNumber(fundraise?.postmoney, 0, { symbol })} valueSize='h4' loading={loading} />
          </div>
          <div className='col-12 col-md-6'>
            <StatCard title={literals.ownership} value={formatNumber(fundraise?.ownership, 0, { symbol: '%' })} valueSize='h4' loading={loading} />
          </div>
          <div className='col-12 col-md-6'>
            <StatCard title={literals.investments} value={formatNumber(fundraise?.investments, 0)} valueSize='h4' loading={loading} />
          </div>
          <div className='col-12 col-md-6'>
            <StatCard title={literals.minTicketPhase} value={formatNumber(fundraise?.minInvestment, 0, { symbol })} valueSize='h4' loading={loading} />
          </div>
          <div className='col-12 col-md-6'>
            <StatCard title={literals.maxTicketPhase} value={formatNumber(fundraise?.maxInvestment, 0, { symbol })} valueSize='h4' loading={loading} />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      { fundraise?.status?.status === 'in_progress' ? (
        <div className='buttons mb-3 buttons-absolute'>
          {(user.id ? !fundraise?.investment : (invite || referral)) && (
            <Button
              icon={PiggyBank}
              text={literals.invest}
              onClick={() => (user.id
                ? setShowInvestPopup(true)
                : navigate({
                  pathname: ROUTE_PATH.LOGIN,
                  state: { from: window.location.pathname },
                  search: invite
                    ? `?inviteFundraise=${invite}`
                    : `?referral=${referral}&fundraise=${fundraise.id}`,
                })
              )}
            />
          )}
          { permission?.canEdit && (
            <Button icon={Share2} color='secondary' text={literals.share2} onClick={() => setShowInvitePopup(true)} />
          )}
        </div>
      ) : (
        <div className='fundraise-status'>
          <div>{`${literals.status}:`}</div>
          <div className='fundraise-status-keyword'>{literals[fundraise?.status?.status]}</div>
        </div>
      )}
      {fundraise?.investment && (
        <Alert
          type='info'
          text={`${literals.investmentAmount} ${formatNumber(fundraise.investment.amount, 0, { symbol })}`}
          onClick={() => setShowInvestPopup(fundraise.investment)}
        />
      )}
      <StartupPanel startup={fundraise?.startup} loading={loading} />
      { (loading || fundraise?.documents.length) ? (
        <Panel>
          <PanelHeader title={literalsCommon.documents} />
          <PanelBody>
            <div className='row'>
              { loading ? (
                <>
                  {[...Array(4)].map((_, i) => (
                    <div
                      key={`document-loading-${i}`}
                      className='col-12 col-lg-3 col-md-4 col-sm-6 document'
                    >
                      {getIconFile('default')}
                      <Skeleton animation='wave' width='100%' />
                    </div>
                  ))}
                </>
              ) : (
                <>

                  {fundraise?.documents.map(doc => (
                    <div
                      key={doc.id}
                      className='col-12 col-lg-3 col-md-4 col-sm-6 document'
                      onClick={() => window.open(FILES_BASE_URL + doc.path)}
                    >
                      {getIconFile(doc.name)}
                      <span className='document-name'>{doc.name}</span>
                    </div>
                  ))}
                </>
              )}
            </div>
          </PanelBody>
        </Panel>
      ) : null}
      <Panel>
        <PanelBody noHeader>
          <div className='row'>
            <div className='col-12 col-md-7'>
              { renderRound() }
            </div>
            <div className='col-12 offset-md-1 col-md-4'>
              {
                permission?.canEdit && (
                  <div className='buttons mb-4'>
                    <Button
                      text={literalsCommon.edit}
                      icon={Pencil}
                      onClick={() => navigate(ROUTE_STARTUP_PATH.setFundraiseAdd(fundraise.startup.id, fundraise.id))}
                    />
                  </div>
                )
              }
              <h4 className='text-center fw-b'>{literals.currentAllocation}</h4>
              <PercentageCircleChart name='Current Allocation' value={fundraise?.investments / fundraise?.amount} loading={loading} />
              <InputSlider value={fundraise?.investments} maxValue={fundraise?.amount} loading={loading} isDisabled onChange={() => {}} />
            </div>
          </div>
        </PanelBody>
      </Panel>
      <Panel>
        <PanelHeader title={literals.kpis} />
        <PanelBody>
          <KPITable
            literals={literalsPerformance}
            symbol={symbol}
            lang={lang}
            kpis={fundraise?.kpis}
          />
        </PanelBody>
      </Panel>
      { showInvestPopup && (
        <FundraiseInvestPopup
          literals={literals}
          literalsCommon={literalsCommon}
          fundraise={fundraise}
          setFundraise={setFundraise}
          investment={showInvestPopup?.id ? showInvestPopup : null}
          symbol={symbol}
          onClose={() => setShowInvestPopup(false)}
        />
      )}
      { showInvitePopup && (
        <FundraiseInvitePopup
          literals={literals}
          literalsCommon={literalsCommon}
          fundraise={fundraise.id}
          user={user.id}
          onClose={() => setShowInvitePopup(false)}
        />
      )}
    </>
  );
};

FundraiseInfoTab.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  literalsPerformance: PropTypes.object.isRequired,
  fundraise: PropTypes.object,
  permission: PropTypes.object,
  setFundraise: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  symbol: PropTypes.string.isRequired,
};

FundraiseInfoTab.defaultProps = {
  fundraise: null,
  permission: null,
};

export default FundraiseInfoTab;
