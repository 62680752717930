/* eslint-disable object-curly-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-spread */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Panel from 'components/Panel';
import PanelBody from 'components/Panel/components/PanelBody';
import EmptyStreet from 'assets/svg/empty_street.svg';
import Button from 'components/Buttons/Button';
import useDialog from 'components/Dialog/components/useDialog';
import Loading from 'components/Loading';
import Collapse from 'components/Collapse/Collapse';
import { cloneObject } from 'utils/functions';
import { AlertOctagon, PlusCircle } from 'lucide-react';
import FormShares from './FormShares';
import FormOptions from './FormOptions';
import FormDebts from './FormDebts';
import FormPayouts from './FormPayouts';
import FormSecondary from './FormSecondary';
import FormReduce from './FormReduce';
import FormConversions from './FormConversions';
import OperationRow from './OperationRow';
import FormVests from './FormVests';
import FormStockSplit from './FormStockSplit';
import FormValuation from './FormValuation';
import FormDividend from './FormDividend';
import FormChangeClass from './FormChangeClass';
import './styles.scss';

const defaultForm = { type: false, index: false };

const OperationsPanel = (props) => {
  const {
    literals,
    literalsCommon,
    match,
    currency,
    captable,
    loading,
    subOperations,
    setSubOperations,
    captableVariables,
    operationData,
    source,
    editable,
  } = props;

  const [showButtons, setShowButtons] = useState(false);
  const [showForm, setShowForm] = useState(defaultForm);
  const { dialog } = useDialog();

  const handleOpenForm = (type, index = false, defaultData = {}) => {
    setShowButtons(false);
    setShowForm({ type, index, defaultData });
  };

  const handleConfirm = (data) => {
    if (data.operationType) {
      // eslint-disable-next-line no-param-reassign
      delete data.operationType;
    }
    const addNewInfo = (items) => {
      const newInfo = [...items];
      if (showForm.index !== false) {
        newInfo[showForm.index] = data;
      } else {
        newInfo.push(data);
      }
      return newInfo;
    };

    setSubOperations(prev => ({
      ...prev,
      [showForm.type]: !['stockSplit', 'valuation', 'dividend'].includes(showForm.type)
        ? addNewInfo(prev[showForm.type]) : data,
    }));

    setShowForm(defaultForm);
  };

  const handleDelete = async (deletedId = null, type = null) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: !type ? literals.questionDelete : literals.deleteErasedItem,
    });
    if (confirm) {
      const copyShowForm = cloneObject(showForm);
      setShowForm(defaultForm);
      const auxType = type ?? copyShowForm.type;
      switch (auxType) {
        case 'shares':
          setSubOperations(prev => ({
            ...prev, shares: prev.shares.filter(item => item.create.id !== deletedId),
          }));
          break;
        case 'options':
          setSubOperations((prev) => {
            const aux = { ...prev };
            aux.options.splice(copyShowForm.index, 1);
            return aux;
          });
          break;
        case 'debts':
        case 'payouts':
        case 'secondaries':
        case 'changeClasses':
        case 'reduces':
        case 'conversions':
        case 'vests':
          setSubOperations(prev => ({
            ...prev, [auxType]: prev[auxType].filter(item => item.id !== deletedId),
          }));
          break;
        case 'stockSplit':
        case 'valuation':
        case 'dividend':
          setSubOperations(prev => ({
            ...prev, [auxType]: null,
          }));
          break;
        default:
      }
    }
  };

  const renderForm = () => {
    const commonProps = {
      type: 'operation',
      editable,
      literals,
      literalsCommon,
      currency,
      captable,
      match,
      onConfirm: handleConfirm,
      onDelete: handleDelete,
    };

    switch (showForm.type) {
      case 'shares': {
        let data = { create: {}, assign: [] };
        if (showForm.index !== false) {
          data = subOperations.shares[showForm.index];
        }

        return (
          <FormShares
            data={data}
            dateOrID={source}
            lastNumeration={captableVariables?.lastNumeration || captable.lastNumeration}
            conflict={!subOperations.valuation}
            {...commonProps}
          />
        );
      }
      case 'debts': {
        let data = showForm.defaultData || {};
        if (showForm.index !== false) {
          data = subOperations.debts[showForm.index];
        }

        return (
          <FormDebts
            data={data}
            {...commonProps}
          />
        );
      }
      case 'options': {
        let data = { create: {}, assign: [] };
        if (showForm.index !== false) {
          data = subOperations.options[showForm.index];
        }

        return (
          <FormOptions
            data={data}
            unassigned={captableVariables.unassigned}
            index={showForm.index}
            {...commonProps}
          />
        );
      }
      case 'payouts': {
        let data = null;
        if (showForm.index !== false) {
          data = subOperations.payouts[showForm.index];
        } else {
          data = showForm.defaultData || {};
        }
        return (
          <FormPayouts
            data={data}
            dateOrID={source}
            convertibles={captableVariables.convertibles}
            {...commonProps}
          />
        );
      }
      case 'secondaries': {
        let data = null;
        if (showForm.index !== false) {
          data = subOperations.secondaries[showForm.index];
        } else {
          data = showForm.defaultData || {};
        }
        return (
          <FormSecondary
            data={data}
            dateOrID={source}
            {...commonProps}
          />
        );
      }
      case 'changeClasses': {
        let data = null;
        if (showForm.index !== false) {
          data = subOperations.changeClasses[showForm.index];
        } else {
          data = showForm.defaultData || {};
        }
        return (
          <FormChangeClass
            data={data}
            dateOrID={source}
            {...commonProps}
          />
        );
      }
      case 'reduces': {
        let data = showForm.defaultData || {};
        if (showForm.index !== false) {
          data = subOperations.reduces[showForm.index];
        }

        return (
          <FormReduce
            data={data}
            dateOrID={source}
            {...commonProps}
          />
        );
      }
      case 'conversions': {
        let data = showForm.defaultData || {};
        if (showForm.index !== false) {
          data = subOperations.conversions[showForm.index];
        }

        return (
          <FormConversions
            data={data}
            dateOrID={source}
            convertibles={captableVariables.convertibles}
            lastNumeration={captableVariables?.lastNumeration || captable.lastNumeration}
            {...commonProps}
          />
        );
      }
      case 'vests': {
        let data = {};
        if (showForm.index !== false) {
          data = subOperations.vests[showForm.index];
        }

        return (
          <FormVests
            data={data}
            dateOrID={source}
            assigned={captableVariables.assigned}
            lastNumeration={captableVariables?.lastNumeration || captable.lastNumeration}
            {...commonProps}
          />
        );
      }
      case 'stockSplit': {
        return (
          <FormStockSplit
            data={subOperations.stockSplit || {}}
            date={operationData.date}
            shares={captableVariables?.assigned?.shares}
            assignedOptions={captableVariables?.assigned?.options}
            unassignedOptions={captableVariables?.unassigned?.options}
            {...commonProps}
          />
        );
      }
      case 'valuation': {
        return (
          <FormValuation
            data={subOperations.valuation || {}}
            date={operationData.date}
            totalShares={captableVariables?.shares}
            totalOptions={captable.selected.valuationDiluted ? captableVariables?.options : 0}
            {...commonProps}
          />
        );
      }
      case 'dividend': {
        return (
          <FormDividend
            data={subOperations.dividend || {}}
            date={operationData.date}
            {...commonProps}
          />
        );
      }
      default:
        return null;
    }
  };

  const getExistValue = (type, item) => {
    switch (type) {
      case 'conversions':
      case 'payouts':
        return (item.relation.type === 'debt' && captableVariables.convertibles.debts.find(debt => debt.debt === item.relation.id))
          || (item.relation.type === 'option' && captableVariables.convertibles.options.find(option => option.assignment === item.relation.id));
      case 'vests':
        return captableVariables.assigned.options.find(option => option.option === item.option);
      default:
        return true;
    }
  };

  const renderList = () => {
    if (loading) {
      return <Loading mode='panel' hide={false} />;
    }

    const items = [
      ...subOperations.shares.map((item, index) => ({ key: item.create?.id, type: 'shares', index, item, exist: getExistValue('shares', item) })),
      ...subOperations.debts.map((item, index) => ({ key: item.id, type: 'debts', index, item, exist: getExistValue('debts', item) })),
      ...subOperations.options.map((item, index) => ({ key: item.create?.id || item.option?.id, type: 'options', index, item, exist: getExistValue('options', item) })),
      ...subOperations.secondaries.map((item, index) => ({ key: item.id, type: 'secondaries', index, item, exist: getExistValue('secondaries', item) })),
      ...subOperations.changeClasses.map((item, index) => ({ key: item.id, type: 'changeClasses', index, item, exist: getExistValue('changeClasses', item) })),
      ...subOperations.reduces.map((item, index) => ({ key: item.id, type: 'reduces', index, item, exist: getExistValue('reduces', item) })),
      ...subOperations.payouts.map((item, index) => ({ key: item.id, type: 'payouts', index, item, exist: getExistValue('payouts', item) })),
      ...subOperations.conversions.map((item, index) => ({ key: item.id, type: 'conversions', index, item, exist: getExistValue('conversions', item) })),
      ...subOperations.vests.map((item, index) => ({ key: item.id, type: 'vests', index, item, exist: getExistValue('vests', item) })),
    ];

    if (subOperations.stockSplit) {
      items.push({ key: subOperations.stockSplit.id, type: 'stockSplit', index: 0, item: subOperations.stockSplit });
    }
    if (subOperations.valuation) {
      items.push({ key: subOperations.valuation.id, type: 'valuation', index: 0, item: subOperations.valuation });
    }
    if (subOperations.dividend) {
      items.push({ key: subOperations.dividend.id, type: 'dividend', index: 0, item: subOperations.dividend });
    }

    if (!items.length) {
      return (
        <Panel>
          <PanelBody noHeader>
            <div className='panel-empty-message'>
              <img src={EmptyStreet} alt='' />
              <p>{literals.noOperations}</p>
            </div>
          </PanelBody>
        </Panel>
      );
    }

    return (
      <ul className='box operation-list mb-sp'>
        {
          items.map(item => (
            <OperationRow
              {...item}
              errors={operationData.errors.items}
              onClick={() => handleOpenForm(item.type, item.index)}
              onDelete={handleDelete}
              literals={literals}
              literalsCommon={literalsCommon}
              captable={captable}
              currency={currency}
              convertibles={captableVariables.convertibles}
              assigned={captableVariables.assigned}
              unassigned={captableVariables.unassigned}
              editable={editable}
            />
          ))
        }
      </ul>
    );
  };

  return (
    <>
      {
        editable && (
          <div className='btn-operation mb-sp'>
            <Button icon={PlusCircle} text={literalsCommon.add} onClick={() => setShowButtons(!showButtons)} disabled={loading} />
          </div>
        )
      }
      {
        operationData.errors?.total ? (
          <div className='operation-total-errors box mb-sp'>
            <h5 className='fw-b mb-0 d-flex align-items-center'>
              <AlertOctagon />
              {`${operationData.errors.total} ${literalsCommon.errors}. ${literals.saveAgainToValidateChanges}`}
            </h5>
          </div>
        ) : null
      }
      { renderList() }
      {
        showForm.type && (
          <Popup title={literals.issue[showForm.type]} size='large' onClose={() => setShowForm(defaultForm)}>
            { renderForm() }
          </Popup>
        )
      }
      {
        showButtons && (
          <Popup title={literals.operations} onClose={() => setShowButtons()}>
            <Collapse title={literals.shares} state>
              <ul className='suboperations-list'>
                <div className='box box-padding' onClick={() => handleOpenForm('shares')}>
                  <h6 className='fw-b'>{literals.issue.shares}</h6>
                  <span className='fs-sm'>{literals.issue.sharesHelp}</span>
                </div>
                <div className={`box box-padding ${!captable.lastNumeration ? 'disabled' : ''}`} onClick={captable.lastNumeration ? () => handleOpenForm('secondaries') : null}>
                  <h6 className='fw-b'>{literals.issue.secondaries}</h6>
                  <span className='fs-sm'>{literals.issue.secondariesHelp}</span>
                </div>
                <div className={`box box-padding ${!captable.lastNumeration ? 'disabled' : ''}`} onClick={captable.lastNumeration ? () => handleOpenForm('changeClasses') : null}>
                  <h6 className='fw-b'>{literals.issue.changeClasses}</h6>
                  <span className='fs-sm'>{literals.issue.changeClassesHelp}</span>
                </div>
                <div className={`box box-padding ${subOperations.stockSplit ? 'disabled' : ''}`} onClick={!subOperations.stockSplit ? () => handleOpenForm('stockSplit') : null}>
                  <h6 className='fw-b'>{literals.issue.stockSplit}</h6>
                  <span className='fs-sm'>{literals.issue.stockSplitHelp}</span>
                </div>
                <div className={`box box-padding ${subOperations.valuation ? 'disabled' : ''}`} onClick={!subOperations.valuation ? () => handleOpenForm('valuation') : null}>
                  <h6 className='fw-b'>{literals.issue.valuation}</h6>
                  <span className='fs-sm'>{literals.issue.valuationHelp}</span>
                </div>
                {/* <div className={`box box-padding ${dividend ? 'disabled' : ''}`} onClick={!dividend ? () => handleOpenForm('dividend') : null}>
                  <h6 className='fw-b'>{literals.issue.dividend}</h6>
                  <span className='fs-sm'>{literals.issue.DividendHelp}</span>
                </div>
                */}
                <div className={`box box-padding ${!captable.lastNumeration ? 'disabled' : ''}`} onClick={captable.lastNumeration ? () => handleOpenForm('reduces') : null}>
                  <h6 className='fw-b'>{literals.issue.reduce}</h6>
                  <span className='fs-sm'>{literals.issue.reduceHelp}</span>
                </div>
              </ul>
            </Collapse>
            <Collapse title={literals.debts} state>
              <ul className='suboperations-list'>
                <div className='box box-padding' onClick={() => handleOpenForm('debts')}>
                  <h6 className='fw-b'>{literals.issue.debt}</h6>
                  <span className='fs-sm'>{literals.issue.debtHelp}</span>
                </div>
                <div className={`box box-padding ${!captableVariables.convertibles.debts.length ? 'disabled' : ''}`} onClick={captableVariables.convertibles.debts.length ? () => handleOpenForm('conversions', false, { relation: { type: 'debt', id: null } }) : null}>
                  <h6 className='fw-b'>{literals.issue.convertDebt}</h6>
                  <span className='fs-sm'>{literals.issue.convertDebtHelp}</span>
                </div>
                <div className={`box box-padding ${!captableVariables.convertibles.debts.length ? 'disabled' : ''}`} onClick={captableVariables.convertibles.debts.length ? () => handleOpenForm('payouts') : null}>
                  <h6 className='fw-b'>{literals.issue.payout}</h6>
                  <span className='fs-sm'>{literals.issue.payoutHelp}</span>
                </div>
              </ul>
            </Collapse>
            <Collapse title={literals.options} state>
              <ul className='suboperations-list'>
                <div className='box box-padding' onClick={() => handleOpenForm('options')}>
                  <h6 className='fw-b'>{captableVariables.unassigned.options.length ? literals.issue.issueAssignOptions : literals.issue.options }</h6>
                  <span className='fs-sm'>{literals.issue.optionsHelp}</span>
                </div>
                <div className={`box box-padding ${!captableVariables.assigned.options.length ? 'disabled' : ''}`} onClick={captableVariables.assigned.options.length ? () => handleOpenForm('vests') : null}>
                  <h6 className='fw-b'>{literals.issue.vestOptions}</h6>
                  <span className='fs-sm'>{literals.issue.vestOptionsHelp}</span>
                </div>
                <div className={`box box-padding ${!captableVariables.convertibles.options.length ? 'disabled' : ''}`} onClick={captableVariables.convertibles.options.length ? () => handleOpenForm('conversions', false, { relation: { type: 'option', id: null } }) : null}>
                  <h6 className='fw-b'>{literals.issue.exerciseOptions}</h6>
                  <span className='fs-sm'>{literals.issue.exerciseOptionsHelp}</span>
                </div>
              </ul>
            </Collapse>
          </Popup>
        )
      }
    </>
  );
};

OperationsPanel.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subOperations: PropTypes.object.isRequired,
  setSubOperations: PropTypes.func.isRequired,
  captableVariables: PropTypes.object.isRequired,
  operationData: PropTypes.object.isRequired,
  source: PropTypes.string,
  editable: PropTypes.bool.isRequired,
};

OperationsPanel.defaultProps = {
  source: null,
};

export default OperationsPanel;
