/* eslint-disable eqeqeq */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import Portal from 'components/Portal';
import Button from 'components/Buttons/Button';
import Loading from 'components/Loading';
import EmptyStreet from 'assets/svg/empty_street.svg';
import { ArrowDown, Search } from 'lucide-react';
import { renderIcon } from 'utils/functions';
import { useRect } from 'utils/customHooks';
import { withRouter } from 'utils/withRouter';
import { connect } from 'react-redux';
import InputText from './inputText';
import InputCheckbox from './inputCheckbox';
import './styles.scss';

function InputSelect({
  literalsCommon, name, options, value, onChange, preText,
  icon, iconRight, button, className, postText, placeholder, scrollRef,
  zIndex, multiple, isRequired, isDisabled, error, loading,
}) {
  const [values, setValues] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [search, setSearch] = useState({ value: '', normalized: '' });
  const [focus, setFocus] = useState(null);
  const [bbox, ref, visible] = useRect(showOptions, scrollRef);
  const divRef = useRef(null);
  const inputRef = useRef(null);
  const closeRef = useRef(null);

  useEffect(() => {
    if (inputRef.current && showOptions) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOptions]);

  const handleSetValues = (v) => {
    setValues(v);
    closeRef.current = v;
  };

  useEffect(() => {
    if (multiple) {
      handleSetValues(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleShowOptions = () => {
    if (!isDisabled) {
      setShowOptions(!showOptions);
      setSearch({ value: '', normalized: '' });
      if (showOptions && multiple) {
        onChange(values);
      }
    }
  };

  const selectValue = (id) => {
    onChange(id);
    setFocus({ index: options.findIndex(val => val.id === id), id });
    setSearch({ value: '', normalized: '' });
    setShowOptions(false);
  };

  let selected = value;
  if (value == null || value == '' || typeof value !== 'object') {
    selected = options?.find(elem => elem.id == value);
    if (!selected) {
      selected = value == null || value == '' ? value : {
        id: 'Not Found', name: literalsCommon.notFound,
      };
    }
  }

  useEffect(() => {
    if (options && selected) {
      setFocus({ index: options.findIndex(val => val.id === selected?.id), id: selected?.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const normalizeText = (str) => {
    if (typeof str !== 'string') {
      return '';
    }
    return (str || '').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  function findLastIndex() {
    const count = options?.length - 1;
    const index = options.slice().reverse().findIndex(
      (elem, i) => i > count - focus.index && normalizeText(elem.name).includes(search.normalized),
    );
    const finalIndex = index >= 0 ? count - index : index;
    return finalIndex;
  }

  const handleKeyPress = useCallback((e) => {
    if (e.keyCode === 40) {
      e.preventDefault();
      if (!focus) {
        setFocus({ index: 0, id: options[0]?.id });
      } else if (focus.index < options?.length - 1) {
        setFocus((prev) => {
          if (!search.normalized) {
            return { index: prev?.index + 1, id: options[prev.index + 1]?.id };
          }
          const newElem = options.findIndex((elem, i) => i > prev.index && normalizeText(elem.name).includes(search.normalized));
          return newElem !== -1 ? { index: newElem, id: options[newElem]?.id } : prev;
        });
      }
    } else if (e.keyCode === 38) {
      e.preventDefault();
      if (!focus) {
        setFocus({ index: 0, id: options[0]?.id });
      } else if (focus.index > 0) {
        setFocus((prev) => {
          if (!search.normalized) {
            return { index: prev?.index - 1, id: options[prev.index - 1]?.id };
          }
          const newIndex = findLastIndex();
          return newIndex !== -1 ? { index: newIndex, id: options[newIndex]?.id } : prev;
        });
      }
    } else if (e.keyCode === 13 && focus) {
      e.preventDefault();
      selectValue(focus.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus, search]);

  const handleSearch = (v) => {
    setSearch({ value: v, normalized: normalizeText(v) });
    const actFocus = options.find(option => option.id === focus?.id)?.name;
    if (actFocus && !normalizeText(actFocus).includes(normalizeText(v))) {
      const first = options.findIndex(elem => normalizeText(elem.name).includes(normalizeText(v)));
      setFocus({ index: first, id: options[first]?.id });
    }
  };

  useEffect(() => {
    if (showOptions) {
      if (divRef && divRef.current) {
        divRef.current.parentNode.scrollTop = divRef.current.offsetTop - 100;
      }
      window.addEventListener('keydown', handleKeyPress, false);
      return () => {
        window.removeEventListener('keydown', handleKeyPress, false);
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOptions, handleKeyPress]);

  const styles = { visible };
  if (bbox) {
    styles.top = bbox.bottom;
    styles.left = bbox.left;
    styles.width = bbox.width;
  }
  if (zIndex) {
    styles.zIndex = zIndex;
  }

  const filteredOptions = options?.filter(opt => !opt.hide && (
    !search.normalized?.length || normalizeText(opt.name || '').includes(search.normalized)));

  const handleOnClose = (portal) => {
    if (multiple) {
      onChange(portal ? closeRef.current : values);
    }
    setShowOptions(false);
  };

  return (
    <div className={`input-select-wrapper ${className} ${isDisabled ? 'disabled' : ''}`}>
      {preText && (
        <div className={`pre_text ${error ? 'text_error' : ''}`}>
          {preText}
          {isRequired && <span className='is_required'>*</span>}
        </div>
      )}
      <div
        className={`input-select-select ${showOptions ? 'show' : ''} ${error ? 'input_error' : ''}`}
        onClick={handleShowOptions}
        ref={ref}
      >
        <div className='selected-item' title={selected?.name || ''}>
          { !multiple || value === 'loading' ? (
            <span>
              { value !== null && loading ? `- ${literalsCommon.loading} -` : (
                <>
                  {selected ? (
                    <>
                      { (icon || selected.icon) && (
                        <span className='icon'>
                          {renderIcon(icon ?? selected.icon, { size: 18 })}
                        </span>
                      )}
                      {selected.name}
                      { (iconRight || selected.iconRight) && (<span className='icon-right'>{iconRight ?? selected.iconRight}</span>)}
                    </>
                  ) : `- ${placeholder || preText} -`}
                </>
              )}
            </span>
          ) : (
            <span>{value?.length ? `${value.length} ${name.toLowerCase()}` : placeholder}</span>
          )}
          <ArrowDown size={17} className={`arrow-input-select ${showOptions ? 'rotate' : ''}`} />
        </div>
      </div>
      {
        showOptions && (
          <Portal styles={styles} onClose={() => handleOnClose(true)}>
            <div className={`input-select-item-options simple-scrollbar ${preText ? 'extra-top' : ''} ${showOptions ? 'show' : ''}`}>
              {loading && (
                <Loading mode='dialog' hide={false} />
              )}
              {!loading && options?.length > 5 && (
                <div className='input-select-searcher-wrapper'>
                  <div className='input-select-searcher'>
                    <InputText
                      value={search.value}
                      onChange={v => handleSearch(v)}
                      inputRef={(v) => { inputRef.current = v; }}
                    />
                    <Search size={16} className='search-icon' />
                  </div>
                </div>
              )}
              { !loading && multiple && filteredOptions?.length && (
                <>
                  <InputCheckbox
                    name='select-option'
                    options={filteredOptions}
                    value={values}
                    onChange={handleSetValues}
                    all
                  />
                  <div className='buttons'>
                    <Button
                      className='m-2'
                      text={literalsCommon.confirm}
                      onClick={handleOnClose}
                    />
                  </div>
                </>
              )}
              {!loading && !multiple && filteredOptions?.map(elem => (
                <div
                  className={`input-select-item ${selected && selected.id == elem.id ? 'selected' : ''} ${focus?.id === elem.id ? 'focus' : ''}`}
                  data-id={elem.id}
                  key={elem.id}
                  onClick={() => selectValue(elem.id)}
                  ref={focus?.id === elem.id ? divRef : null}
                >
                  { (icon || elem.icon) && (
                    <span className='icon'>
                      {renderIcon(icon ?? elem.icon, { size: 18 })}
                    </span>
                  )}
                  <>{elem.name}</>
                  { (iconRight || elem.iconRight) && (
                    <div className='icon-right'>
                      {renderIcon(iconRight ?? elem.iconRight, { size: 18 })}
                    </div>
                  )}
                </div>
              ))}
              {
                !loading && !filteredOptions?.length ? (
                  <div className='empty-select'>
                    <img src={EmptyStreet} alt='' />
                  </div>
                ) : null
              }
              { !loading && button && (<div className='input-select-button' onClick={handleShowOptions}>{button}</div>)}
            </div>
          </Portal>
        )
      }

      { postText && (<div className='post_text' dangerouslySetInnerHTML={{ __html: postText }} />) }
    </div>
  );
}

InputSelect.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  preText: PropTypes.string, // Texto situado antes del input
  postText: PropTypes.string, // Texto situado despues del input
  isRequired: PropTypes.bool, // Indica si es obligatorio (visualmente nada más)
  isDisabled: PropTypes.bool, // Indica si el input está deshabilitado
  options: PropTypes.array.isRequired, // Opciones a escoger en el select
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.array]),
  error: PropTypes.array,
  icon: PropTypes.object,
  iconRight: PropTypes.object,
  button: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  scrollRef: PropTypes.object,
  zIndex: PropTypes.number,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  loading: PropTypes.bool,
};

InputSelect.defaultProps = {
  preText: '',
  postText: '',
  onChange: () => {},
  isRequired: false,
  isDisabled: false,
  error: null,
  value: null,
  icon: null,
  iconRight: null,
  button: null,
  className: '',
  placeholder: '',
  scrollRef: null,
  zIndex: null,
  multiple: false,
  name: '',
  loading: false,
};

const mapStateToProps = state => ({
  literalsCommon: state.i18n.literals.common,
});

export default withRouter(connect(mapStateToProps)(InputSelect));
