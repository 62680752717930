
import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft, ChevronRight } from 'lucide-react';
import { ROUTE_PATH, embedView } from 'routes';
import './styles.scss';

const Breadcrumb = (props) => {
  const {
    literals,
    routes = [],
    className = '',
    goBack = false,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const previousPage = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate(ROUTE_PATH.PREHOME);
    }
  };

  const renderBreadcrumb = () => {
    if (goBack) {
      return (
        <div className='go-back' onClick={previousPage}>
          <ArrowLeft size={15} />
          {literals.goBack}
        </div>
      );
    }

    if (!routes || routes.length === 0) {
      const toPath = [''];
      return location.pathname.slice(1).split('/').map((path) => {
        toPath.push(path);
        const stringToPath = toPath.join('/');
        return (
          <Link key={stringToPath} className='h4' to={stringToPath}>
            <ChevronRight size='14' />
            { literals[path] ?? path}
          </Link>
        );
      });
    }
    return routes.map((path, i) => (
      path.route ? (
        <Link key={i} className='h4' to={embedView(path.route)}>
          <ChevronRight size='14' />
          { path.name }
        </Link>
      ) : (
        <h4 key={i} className='h4'>
          <ChevronRight size='14' />
          { path.name }
        </h4>
      )
    ));
  };

  return (
    <div className={`main-breadcrumb ${className}`}>
      {
        renderBreadcrumb()
      }
    </div>
  );
};

Breadcrumb.propTypes = {
  routes: PropTypes.array,
  className: PropTypes.string,
  literals: PropTypes.object.isRequired,
  goBack: PropTypes.bool,
};

export default Breadcrumb;
