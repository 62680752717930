/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import InputEmail from 'components/Inputs/inputEmail';
import InputRichText from 'components/Inputs/inputRichText';
import Button from 'components/Buttons/Button';
import { shareStartup } from '../modules/actions';
import './styles.scss';

const SharePopup = (props) => {
  const {
    literals,
    literalsCommon,
    startup,
    onClose,
  } = props;

  const external = !!(!startup.id && startup.externalId);
  const [form, setForm] = useState({ external });
  const [loading, setLoading] = useState(false);

  const shareStartupHandler = async () => {
    setLoading(true);
    try {
      await shareStartup(startup.id || startup.externalId, form);
      onClose(false);
    } catch {
      setLoading(false);
    }
  };

  return (
    <Popup title={literals.shareWithFounder} onClose={onClose}>
      <InputEmail
        preText={literalsCommon.email}
        value={form.email}
        onChange={email => setForm(prev => ({ ...prev, email }))}
      />
      <InputRichText
        preText={literalsCommon.notes}
        value={form.email}
        onChange={notes => setForm(prev => ({ ...prev, notes }))}
      />
      <div className='buttons'>
        <Button
          text={literalsCommon.send}
          onClick={shareStartupHandler}
          loading={loading}
        />
      </div>
    </Popup>
  );
};

SharePopup.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SharePopup;
