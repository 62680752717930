import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Portal from 'components/Portal';
import { useRect } from 'utils/customHooks';
import { SquareCheck, Square } from 'lucide-react';
import './styles.scss';

const DropOptionsIcon = ({
  buttons,
  isEmpty = false,
  pageSelected = false,
  allSelected = false,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [bbox, ref, visible] = useRect(showMenu);

  const handleClick = (func) => {
    setShowMenu(!showMenu);
    if (typeof func === 'function') {
      func();
    }
  };

  const finalButtons = [...buttons].filter(
    button => (!isEmpty || button.id !== 3)
      && (!pageSelected || button.id !== 1)
      && (!allSelected || button.id !== 2),
  );

  const styles = { visible };
  if (bbox) {
    styles.top = bbox.bottom + 5;
    styles.left = bbox.left;
  }

  return (
    <div ref={ref} style={{ cursor: 'pointer' }}>
      {isEmpty ? <Square size={18} onClick={handleClick} /> : <SquareCheck size={18} onClick={handleClick} />}
      {
        showMenu && (
          <Portal styles={styles} onClose={() => setShowMenu(false)}>
            <div className='icon-dropdown-menu'>
              {finalButtons.map(button => (
                <div className='icon-dropdown-item' onClick={() => handleClick(button.onClick)}>
                  {button.icon && React.createElement(button.icon, { className: 'icon-left' })}
                  {button.text}
                </div>
              ))}
            </div>
          </Portal>
        )
      }
    </div>
  );
};

DropOptionsIcon.propTypes = {
  buttons: PropTypes.array.isRequired,
  isEmpty: PropTypes.bool,
  pageSelected: PropTypes.bool,
  allSelected: PropTypes.bool,
};

export default DropOptionsIcon;
