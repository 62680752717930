export const FETCH_CAPTABLE_INIT = 'FETCH_CAPTABLE_INIT';
export const FETCH_CAPTABLE_SUCCESS = 'FETCH_CAPTABLE_SUCCESS';
export const FETCH_CAPTABLE_FAILURE = 'FETCH_CAPTABLE_FAILURE';

export const CREATE_CAPTABLE_OPERATION_INIT = 'CREATE_CAPTABLE_OPERATION_INIT';
export const CREATE_CAPTABLE_OPERATION_SUCCESS = 'CREATE_CAPTABLE_OPERATION_SUCCESS';
export const CREATE_CAPTABLE_OPERATION_FAILURE = 'CREATE_CAPTABLE_OPERATION_FAILURE';
export const EDIT_CAPTABLE_OPERATION_INIT = 'EDIT_CAPTABLE_OPERATION_INIT';
export const EDIT_CAPTABLE_OPERATION_SUCCESS = 'EDIT_CAPTABLE_OPERATION_SUCCESS';
export const EDIT_CAPTABLE_OPERATION_FAILURE = 'EDIT_CAPTABLE_OPERATION_FAILURE';
export const DELETE_CAPTABLE_OPERATION_INIT = 'DELETE_CAPTABLE_OPERATION_INIT';
export const DELETE_CAPTABLE_OPERATION_SUCCESS = 'DELETE_CAPTABLE_OPERATION_SUCCESS';
export const DELETE_CAPTABLE_OPERATION_FAILURE = 'DELETE_CAPTABLE_OPERATION_FAILURE';

export const CREATE_CAPTABLE_SHAREHOLDER_INIT = 'CREATE_CAPTABLE_SHAREHOLDER_INIT';
export const CREATE_CAPTABLE_SHAREHOLDER_SUCCESS = 'CREATE_CAPTABLE_SHAREHOLDER_SUCCESS';
export const CREATE_CAPTABLE_SHAREHOLDER_FAILURE = 'CREATE_CAPTABLE_SHAREHOLDER_FAILURE';
export const EDIT_CAPTABLE_SHAREHOLDER_INIT = 'EDIT_CAPTABLE_SHAREHOLDER_INIT';
export const EDIT_CAPTABLE_SHAREHOLDER_SUCCESS = 'EDIT_CAPTABLE_SHAREHOLDER_SUCCESS';
export const EDIT_CAPTABLE_SHAREHOLDER_FAILURE = 'EDIT_CAPTABLE_SHAREHOLDER_FAILURE';
export const DELETE_CAPTABLE_SHAREHOLDER_INIT = 'DELETE_CAPTABLE_SHAREHOLDER_INIT';
export const DELETE_CAPTABLE_SHAREHOLDER_SUCCESS = 'DELETE_CAPTABLE_SHAREHOLDER_SUCCESS';
export const DELETE_CAPTABLE_SHAREHOLDER_FAILURE = 'DELETE_CAPTABLE_SHAREHOLDER_FAILURE';

export const CREATE_CAPTABLE_SHARECLASS_INIT = 'CREATE_CAPTABLE_SHARECLASS_INIT';
export const CREATE_CAPTABLE_SHARECLASS_SUCCESS = 'CREATE_CAPTABLE_SHARECLASS_SUCCESS';
export const CREATE_CAPTABLE_SHARECLASS_FAILURE = 'CREATE_CAPTABLE_SHARECLASS_FAILURE';
export const EDIT_CAPTABLE_SHARECLASS_INIT = 'EDIT_CAPTABLE_SHARECLASS_INIT';
export const EDIT_CAPTABLE_SHARECLASS_SUCCESS = 'EDIT_CAPTABLE_SHARECLASS_SUCCESS';
export const EDIT_CAPTABLE_SHARECLASS_FAILURE = 'EDIT_CAPTABLE_SHARECLASS_FAILURE';
export const DELETE_CAPTABLE_SHARECLASS_INIT = 'DELETE_CAPTABLE_SHARECLASS_INIT';
export const DELETE_CAPTABLE_SHARECLASS_SUCCESS = 'DELETE_CAPTABLE_SHARECLASS_SUCCESS';
export const DELETE_CAPTABLE_SHARECLASS_FAILURE = 'DELETE_CAPTABLE_SHARECLASS_FAILURE';

export const CREATE_CAPTABLE_VESTINGPLAN_INIT = 'CREATE_CAPTABLE_VESTINGPLAN_INIT';
export const CREATE_CAPTABLE_VESTINGPLAN_SUCCESS = 'CREATE_CAPTABLE_VESTINGPLAN_SUCCESS';
export const CREATE_CAPTABLE_VESTINGPLAN_FAILURE = 'CREATE_CAPTABLE_VESTINGPLAN_FAILURE';
export const EDIT_CAPTABLE_VESTINGPLAN_INIT = 'EDIT_CAPTABLE_VESTINGPLAN_INIT';
export const EDIT_CAPTABLE_VESTINGPLAN_SUCCESS = 'EDIT_CAPTABLE_VESTINGPLAN_SUCCESS';
export const EDIT_CAPTABLE_VESTINGPLAN_FAILURE = 'EDIT_CAPTABLE_VESTINGPLAN_FAILURE';
export const DELETE_CAPTABLE_VESTINGPLAN_INIT = 'DELETE_CAPTABLE_VESTINGPLAN_INIT';
export const DELETE_CAPTABLE_VESTINGPLAN_SUCCESS = 'DELETE_CAPTABLE_VESTINGPLAN_SUCCESS';
export const DELETE_CAPTABLE_VESTINGPLAN_FAILURE = 'DELETE_CAPTABLE_VESTINGPLAN_FAILURE';

export const CREATE_STARTUP_CAPTABLE_SUCCESS = 'CREATE_STARTUP_CAPTABLE_SUCCESS';
export const EDIT_STARTUP_CAPTABLE_SUCCESS = 'EDIT_STARTUP_CAPTABLE_SUCCESS';
export const DELETE_STARTUP_CAPTABLE_SUCCESS = 'DELETE_STARTUP_CAPTABLE_SUCCESS';

export const CLEAN_CAPTABLE_ERROR = 'CLEAN_CAPTABLE_ERROR';
