import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import { getReports } from 'routes/Reporting/modules/actions';
import { formatDate, startupCanEdit, stripTags } from 'utils/functions';
import { ROUTE_STARTUP_PATH } from 'routes';
import { Link } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { FileText, Users, CirclePlus } from 'lucide-react';
import EmptyReporting from 'assets/svg/empty_reporting.svg';
import { TOOL_REPORTING } from 'constants/tools';
import Button from 'components/Buttons/Button';


const SummaryStartupReporting = (props) => {
  const {
    match,
    startup,
    literalsReporting,
  } = props;

  const canEdit = startupCanEdit(startup, TOOL_REPORTING);

  const fetchReports = async (page, size, search, filters) => {
    return getReports(match.params.id, {
      page, size, search, filters,
    });
  };

  const [reports, setReports] = useState(null);
  useEffect(() => {
    fetchReports(0, 2).then(
      response => setReports(response),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  if (!reports) {
    return (
      <div className='summary-reporting'>
        <Panel>
          <PanelHeader title={literalsReporting.title} />
          <PanelBody>
            {
              [...Array(2)].map((_, i) => (
                <div key={`report-loading-${i}`} className='box box-padding d-flex align-items-center box-mb'>
                  <div className='flex-grow-1 p-1 pl-4'>
                    <Skeleton animation='wave' style={{ marginBottom: 2 }} width='50%' />
                    <Skeleton animation='wave' style={{ marginBottom: 2 }} width='100%' />
                    <Skeleton animation='wave' style={{ marginBottom: 2 }} width='100%' />
                  </div>
                </div>

              ))
            }
          </PanelBody>
        </Panel>
      </div>
    );
  }

  return (
    <div className='summary-reporting'>
      <Panel>
        <PanelHeader title={literalsReporting.lastReports} />
        <PanelBody>
          {
            (reports.items.length > 0) ? (
              reports.items.map(report => (
                <Link key={`report-${report.id}`} to={ROUTE_STARTUP_PATH.setReportingInfo(match.params.id, report.id)}>
                  <div className='reporting-row box' key={report.id}>
                    <div className='reporting-info'>
                      <h6 className='fw-b mb-3'>{report.subject}</h6>
                      <p className='text-justify text-break'>
                        {stripTags(report.message).length > 100
                          ? `${stripTags(report.message).substring(0, 100)}...`
                          : stripTags(report.message)}
                      </p>
                    </div>
                    <div className='reporting-actions'>
                      <b>{formatDate(report.createdAt, { time: true })}</b>
                      <div className='d-flex justify-content-center align-items-center mt-2'>
                        <span className='d-flex align-items-center'>
                          <Users size={20} className='mr-2' />
                          {report.recipients.length}
                        </span>
                        <span className='d-flex align-items-center ml-4'>
                          <FileText size={20} className='mr-2' />
                          {report.documents.length}
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <div key='report-empty-panel' className='panel-empty-message m-0'>
                <img src={EmptyReporting} alt={literalsReporting.noReports} />
                <p>{literalsReporting.noReports}</p>
                {canEdit && (
                  <Link to={ROUTE_STARTUP_PATH.setReportingTemplates(match.params.id)}>
                    <Button color='secondary' icon={CirclePlus} text={literalsReporting.newReport} />
                  </Link>
                )}
              </div>
            )
          }
        </PanelBody>
      </Panel>
    </div>
  );
};

SummaryStartupReporting.propTypes = {
  match: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  literalsReporting: PropTypes.object.isRequired,
};

export default SummaryStartupReporting;
