/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputSelect from 'components/Inputs/inputSelect';
import Searcher from 'components/Searcher';
import { useSearchParams } from 'react-router-dom';
import { formatNumber, getColorRange } from 'utils/functions';
import { Skeleton } from '@mui/material';
import { getCaptableSummaryTable } from './modules/actions';
import CaptableSummaryTable from './CaptableSummaryTable';
import './styles.scss';

const CaptableSummary = (props) => {
  const {
    date,
    compare,
    startup,
    literals,
    currency,
  } = props;

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState();
  const [groupBy, setGroupBy] = useState('shareholder');
  const [search, setSearch] = useState('');
  const [sortedColumn, setSortedColumn] = useState({ name: '', sort: '' });
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const simulation = searchParams.get('simulation') || null;

  const loadCaptable = async () => {
    setLoading(true);
    const auxRows = await getCaptableSummaryTable(startup, date, {
      simulation,
      group: groupBy,
      captable: captableId,
      prevDate: compare,
      search,
      sort: `${sortedColumn.sort}${sortedColumn.name}` || null,
    });
    setRows(auxRows.items);
    setLoading(false);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      if (rows) {
        loadCaptable();
      }
    }, 600);

    return () => clearTimeout(delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (date) {
      loadCaptable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, compare, captableId, groupBy, sortedColumn]);


  const renderOwnershipBar = (items, prev = false) => {
    if (loading) {
      return <Skeleton animation='wave' className='mb-3' height={18} width='100%' />;
    }

    let others = 100;
    const filteredItems = items.filter(item => item.id !== 'total');
    const graphColors = getColorRange(Math.min(filteredItems.length, 7));
    return (
      <div className='percent-bar'>
        {
          filteredItems
            .slice(0, 5)
            .map((item, i) => {
              const ownership = prev ? item?.prev?.ownership : item.ownership;
              const name = literals[item.name] || item.name;
              const percent = formatNumber(ownership, 0, { dec: 2, symbol: '%' });
              others -= ownership;

              if (!ownership) {
                return null;
              }

              return (
                <div key={item.id} title={`${name} (${percent})`} style={{ width: `${ownership}%` }}>
                  <div className='pb-line' style={{ backgroundColor: graphColors[i] }} />
                  <p className='fs-small fc-secondary mb-0'>{`${percent} ${name}`}</p>
                </div>
              );
            })
        }
        {others > 0 && (
          <div key='others' title={`${literals.others} (${formatNumber(others, 0, { dec: 2, symbol: '%' })})`} style={{ width: `${others}%` }}>
            <div className='pb-line' style={{ backgroundColor: graphColors[6] }} />
            <p className='fs-small fc-secondary mb-0'>{`${formatNumber(others, 0, { dec: 2, symbol: '%' })} ${literals.others}`}</p>
          </div>
        )}
      </div>
    );
  };

  if (!date) {
    return null;
  }

  return (
    <div className='captable-summary'>
      <div className='row'>
        <div className='col-12 col-md-8'>
          <Searcher value={search} onChange={setSearch} />
        </div>
        <div className='col-12 col-md-4'>
          <InputSelect
            preText={literals.sendEmail}
            value={groupBy}
            onChange={setGroupBy}
            options={[
              { id: 'shareholder', name: literals.byShareholder },
              { id: 'role', name: literals.byRole },
              { id: 'syndicate', name: literals.bySyndicate },
            ]}
          />
        </div>
      </div>
      <div className='percent-bars'>
        { renderOwnershipBar(rows) }
        { compare && renderOwnershipBar(rows, true) }
      </div>
      <CaptableSummaryTable
        literals={literals}
        currency={currency}
        loading={loading}
        groupBy={groupBy}
        rows={rows}
        date={date}
        sortedColumn={sortedColumn}
        setSortedColumn={setSortedColumn}
      />
    </div>
  );
};

CaptableSummary.propTypes = {
  date: PropTypes.string,
  compare: PropTypes.string,
  currency: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  startup: PropTypes.string.isRequired,
};

CaptableSummary.defaultProps = {
  date: null,
  compare: null,
};

export default CaptableSummary;
