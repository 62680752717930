import { connect } from 'react-redux';
import PermissionTools from '../components';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.permissions,
    literalsCommon: state.i18n.literals.common,
  };
}

export default connect(mapStateToProps)(PermissionTools);
