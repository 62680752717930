import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelBody from 'components/Panel/components/PanelBody';
import Avatar from 'components/Avatar';
import Button from 'components/Buttons/Button';
import SocialIcon from 'components/SocialIcon';
import { Skeleton } from '@mui/material';
import { Eye, MapPin } from 'lucide-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchFormStartupAction } from 'components/InternalForm/modules/actions';
import { getStartupVerifiedIcon } from 'utils/functions';
import PopupStartupDetails from './PopupStartupDetails';
import './styles.scss';

const StartupPanel = (props) => {
  const {
    loading,
    startup,
    literalsCommon,
    literalsOnboarding,
    user,
    forms,
    platform,
    lang,
    fetchFormStartup,
  } = props;

  const [popupStartupDetails, setPopupStartupDetails] = useState(false);

  return (
    <div className='startup-panel'>
      <Panel noHeader>
        <PanelBody>
          {loading ? (
            <>
              <div className='d-flex justify-content-between align-items-center mb-3'>
                <div className='startup-panel--title'>
                  <span style={{ width: '60px' }}><Skeleton animation='wave' variant='circular' width={60} height={60} /></span>
                  <span className='startup-panel--name'><Skeleton animation='wave' className='ml-4 w-50' /></span>
                </div>
                <Button className='btn-sm-icon' icon={Eye} text={literalsCommon.viewDetails} color='secondary' isDisabled loading />
              </div>
              <Skeleton animation='wave' width='100%' />
              <Skeleton animation='wave' width='100%' />
            </>
          ) : (
            <>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='startup-panel--header'>
                  <Avatar
                    title={startup?.name}
                    src={startup?.logo?.path}
                    size={60}
                    className='mr-2'
                  />
                  <div className='startup-panel--title'>
                    <div title={startup.name} className='startup-panel--name'>
                      {startup.name}
                      {getStartupVerifiedIcon(startup.verified)}
                    </div>
                    {startup.legalName && (
                      <span className='startup-panel--legalname'>{startup.legalName}</span>
                    )}
                    {(startup.country || startup.city) && (
                      <span className='startup-panel--country'>
                        <MapPin size={12} />
                        {[startup.city, literalsOnboarding.countries[startup.country]].filter(Boolean).join(', ')}
                      </span>
                    )}
                  </div>
                </div>
                <Button
                  className='btn-sm-icon'
                  color='secondary'
                  icon={Eye}
                  text={literalsCommon.viewDetails}
                  onClick={() => setPopupStartupDetails(true)}
                />
              </div>
              { (startup.url || Object.values(startup.social || {}).some(Boolean)) && (
                <div className='startup-panel--social'>
                  {startup.url && <SocialIcon type='url' link={startup.url} />}
                  {Object.keys(startup.social || {})
                    .sort((a, b) => (b === 'other' ? -1 : 1))
                    .map(social => <SocialIcon key={`${social}-${startup.id}`} type={social} link={startup.social[social]} />)
                  }
                </div>
              )}
              <div className='startup-panel--description'>{startup.description}</div>
            </>
          )}
          {popupStartupDetails && (
            <PopupStartupDetails
              literals={literalsOnboarding}
              literalsCommon={literalsCommon}
              startup={startup}
              user={user}
              forms={forms}
              platform={platform}
              lang={lang}
              fetchFormStartup={fetchFormStartup}
              onClose={() => setPopupStartupDetails(false)}
            />
          )}
        </PanelBody>
      </Panel>
    </div>
  );
};

StartupPanel.propTypes = {
  startup: PropTypes.object,
  loading: PropTypes.bool,
  literalsCommon: PropTypes.object.isRequired,
  literalsOnboarding: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  forms: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  fetchFormStartup: PropTypes.func.isRequired,
};

StartupPanel.defaultProps = {
  startup: null,
  loading: false,
};

const mapStateToProps = (state) => {
  return {
    literalsCommon: state.i18n.literals.common,
    literalsOnboarding: state.i18n.literals.onboarding,
    user: state.session.user,
    forms: state.forms,
    platform: state.global.platform,
    lang: state.i18n.language,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchFormStartup: bindActionCreators(fetchFormStartupAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StartupPanel);
