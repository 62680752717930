import ApiFetch from 'utils/apiFetch';

const FormInstance = ApiFetch();

export const URLS = {
  FORM_MENTOR: 'forms/mentor',
  FORM_STARTUPS: 'forms/startup',
};

async function getFormMentor() {
  return FormInstance.get(URLS.FORM_MENTOR);
}

async function getFormStartups() {
  return FormInstance.get(URLS.FORM_STARTUPS);
}

export default {
  getFormMentor,
  getFormStartups,
};
