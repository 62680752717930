import React from 'react';
import PropTypes from 'prop-types';
import InputText from 'components/Inputs/inputText';
import Button from 'components/Buttons/Button';
import { Filter, Search } from 'lucide-react';
import { connect } from 'react-redux';
import './styles.scss';

const Searcher = ({
  literals,
  value = '',
  onChange,
  onSearch = () => {},
  haveFilters = false,
  onFilters,
  className = '',
  isDisabled = false,
}) => {
  return (
    <div
      className={`searcher-component ${isDisabled ? 'disabled' : ''} ${className}`}
    >
      <div className='input_searcher'>
        <Search size={20} className='ml-3 mr-2' />
        <InputText
          className='input'
          placeholder={`${literals.search}...`}
          value={value}
          onChange={onChange}
          onKeyPress={e => e.key === 'Enter' && onSearch()}
          isDisabled={isDisabled}
        />
        {onFilters && (
          <Button
            icon={<Filter fill={haveFilters ? 'currentColor' : 'none'} />}
            className='btn-filters-mobile'
            color='outline-primary'
            onClick={onFilters}
          />
        )}
      </div>
    </div>
  );
};

Searcher.propTypes = {
  literals: PropTypes.object.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  haveFilters: PropTypes.bool,
  onFilters: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    literals: state.i18n.literals.table,
  };
};

export default connect(mapStateToProps, {})(Searcher);
