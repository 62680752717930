/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Check } from 'lucide-react';
import { withRouter } from 'utils/withRouter';
import { connect } from 'react-redux';
import './styles.scss';

function InputCheckbox({
  literals,
  preText = '',
  postText = '',
  isRequired = false,
  isDisabled = false,
  error = null,
  options = [],
  onChange,
  value = [],
  name = `input-radio-${Math.floor(Math.random() * 10000)}`,
  limit = null,
  all = false,
}) {
  const [internalValue, setInternalValue] = useState(value);

  const changeValue = (e) => {
    let newValue = [...internalValue];
    if (newValue.includes(e.target.value)) {
      newValue = newValue.filter(v => v !== e.target.value);
    } else {
      if (limit && (newValue.length + 1 >= limit)) {
        newValue = newValue.slice(-(limit - 1));
      }
      newValue.push(e.target.value);
    }
    setInternalValue(newValue);
    onChange(newValue, e);
  };

  return (
    <div className='input_checkbox'>
      <div className={`pre_text ${error ? 'text_error' : ''}`}>
        {preText}
        {isRequired && <span className='is_required'>*</span>}
      </div>
      <div className={error ? 'input_options input_error' : 'input_options'}>
        {all && (
          <div className={`input_option ${isDisabled ? 'disabled' : ''}`}>
            <input
              id='check-_all'
              className='stype'
              name={name}
              type='checkbox'
              checked={internalValue.length === options.length}
              value='all'
              disabled={isDisabled}
              onChange={() => onChange(
                internalValue.length === options.length
                  ? []
                  : options.map(opt => opt.id),
              )}
            />
            <div className='checkbox-custom'>
              <Check />
            </div>
            <label htmlFor='check-_all' className='f-italic'>{`${literals.selected} (${internalValue.length} ${literals.of} ${options.length})`}</label>
          </div>
        )}
        {options.map((elem, index) => {
          const elementId = `check-${elem.id.toString()}`;
          return (
            <div
              key={index}
              className={`input_option ${
                isDisabled ? 'disabled' : ''
              }`}
            >
              <input
                id={elementId}
                className='stype'
                name={name}
                type='checkbox'
                checked={internalValue.includes(elem.id)}
                value={elem.id}
                disabled={isDisabled}
                onChange={changeValue}
              />
              <div className='checkbox-custom'>
                <Check />
              </div>
              <label htmlFor={elementId}>{elem.name}</label>
            </div>
          );
        })}
      </div>
      {postText && (
        <div
          className='post_text'
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: postText }}
        />
      )}
    </div>
  );
}

InputCheckbox.propTypes = {
  literals: PropTypes.object.isRequired,
  preText: PropTypes.string,
  postText: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  error: PropTypes.object,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
  name: PropTypes.string,
  limit: PropTypes.number,
  all: PropTypes.bool,
};

export default withRouter(
  connect(state => ({
    literals: state.i18n.literals.input,
  }))(InputCheckbox),
);
