import React, { useState } from 'react';
import InputSelect from 'components/Inputs/inputSelect';
import PropTypes from 'prop-types';
import InputText from 'components/Inputs/inputText';
import InputTextArea from 'components/Inputs/inputTextArea';
import Collapse from 'components/Collapse/Collapse';
import Button from 'components/Buttons/Button';
import SortKpis from 'components/SortKpis';
import { KPI_SCOPE } from 'constants/kpis';
import { useLang } from 'utils/customHooks';
import { createTemplate, editTemplate } from '../modules/actions';

const FormKpiTemplate = (props) => {
  const {
    id,
    literals,
    literalsCommon,
    scope: defaultScope,
    name: defaultName,
    description: defaultDescription,
    onSubmit,
    kpis,
  } = props;

  const lang = useLang();
  const [scope, setScope] = useState(defaultScope?.type);
  const [errors, setErrors] = useState(false);
  const [name, setName] = useState(defaultName);
  const [description, setDescription] = useState(defaultDescription);
  const [loading, setLoading] = useState(false);
  const [kpisTemplate, setKpisTemplate] = useState([...kpis]);

  const scopes = [
    { id: KPI_SCOPE.STARTUP, name: literalsCommon.startup },
    { id: KPI_SCOPE.MENTOR, name: literalsCommon.mentor },
  ];

  const setLangValue = (prop, value) => {
    switch (prop) {
      case 'name':
        setName(lang.setObjValue(name, value));
        break;
      case 'description':
        setDescription(lang.setObjValue(description, value));
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrors(false);
    try {
      const data = {
        description,
        name,
        scope,
        kpis: kpisTemplate.map(elem => elem.id),
      };
      const validateLangs = lang.validateLangs(data, ['name', 'description']);
      if (validateLangs === true) {
        if (id) {
          await editTemplate(id, data);
        } else {
          await createTemplate(data);
        }
        onSubmit();
      } else {
        setErrors(validateLangs);
      }
    } catch (error) {
      setErrors(error);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-4 offset-8'>
          <InputSelect value={lang.value} options={lang.getLangs().map(l => ({ id: l, name: l }))} onChange={lang.change} />
        </div>
        <div className='col-6'>
          <InputSelect value={scope} onChange={v => setScope(v)} options={scopes} error={errors?.scope} />
        </div>
        <div className='col-12'>
          <InputText preText={lang.getTitleInput(literalsCommon.name)} value={lang.getObjValue(name)} onChange={v => setLangValue('name', v)} error={errors?.name} />
        </div>
        <div className='col-12'>
          <InputTextArea preText={lang.getTitleInput(literalsCommon.description)} value={lang.getObjValue(description)} onChange={v => setLangValue('description', v)} error={errors?.description} />
        </div>
        <div className='col-12'>
          <Collapse title='Kpis' state>
            <SortKpis
              literals={literals}
              literalsCommon={literalsCommon}
              lang={lang.value}
              kpis={kpisTemplate}
              setKpis={setKpisTemplate}
              addKpis
            />
          </Collapse>
        </div>
      </div>
      <div className='buttons'>
        <Button type='submit' text={literalsCommon.send} loading={loading} />
      </div>
    </form>
  );
};

FormKpiTemplate.propTypes = {
  id: PropTypes.string.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  scope: PropTypes.object,
  name: PropTypes.object,
  description: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  kpis: PropTypes.array,
};

FormKpiTemplate.defaultProps = {
  name: {},
  description: {},
  scope: { type: 'startup', owner: null },
  kpis: [],
};


export default FormKpiTemplate;
