import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import InputText from 'components/Inputs/inputText';
import InputNumber from 'components/Inputs/inputNumber';
import InputDate from 'components/Inputs/inputDate';
import InputSelect from 'components/Inputs/inputSelect';
import InputFile from 'components/Inputs/inputFile';
import InputRichText from 'components/Inputs/inputRichText';
import Separator from 'components/Separator';
import { useParams, useSearchParams } from 'react-router-dom';
import { formatDate } from 'utils/functions';
import { fetchCaptableShares } from '../modules/actions';
import FormReduceTable from './FormReduceTable';

const FormReduce = (props) => {
  const {
    type,
    data,
    dateOrID,
    id,
    literals,
    literalsCommon,
    captable,
    currency,
    onConfirm,
    onDelete,
    editable,
  } = props;

  const [ident] = useState(data.id ?? `new.${new Date().getTime()}`);
  const [inputDate, setInputDate] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [name, setName] = useState(data?.name);
  const [reduceData, setReduceData] = useState({
    shareClass: data?.shareClass || id,
    repurchasePPS: data?.repurchasePPS || 0,
    shares: data?.shares || {},
  });

  const [shareClassShares, setShareClassShares] = useState();
  const [notes, setNotes] = useState(data.notes);
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const params = useParams();

  useEffect(() => {
    setShareClassShares(null);
    if (reduceData.shareClass) {
      (async () => {
        const auxDate = dateOrID || formatDate(new Date(), { format: 'Y-m-d' });
        const response = await fetchCaptableShares(params.id, auxDate, {
          filters: { shareClass: reduceData.shareClass },
          captable: captableId,
          size: 0,
        });

        setShareClassShares(response);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduceData.shareClass]);

  const submitForm = async (e) => {
    e.preventDefault();
    const submitData = {
      id: ident,
      ...reduceData,
    };

    onConfirm(type === 'operation' ? submitData : {
      operationType: 'reduce',
      name,
      data: submitData,
      date: inputDate,
      notes,
      documents,
    });
  };

  return (
    <form onSubmit={submitForm}>
      {type !== 'operation' && (
        <>
          <div className='row'>
            <div className='col-12 col-md-8'>
              <InputText
                className='m-0'
                preText={literals.operationName}
                placeholder={literals.operationName}
                value={name}
                onChange={v => setName(v)}
                isDisabled={!editable}
              />
            </div>
            <div className='col-12 col-md-4'>
              <InputDate
                className='m-0'
                preText={literalsCommon.date}
                value={inputDate}
                onChange={v => setInputDate(v)}
                isDisabled={!editable}
                min={captable?.operations?.length ? captable?.operations[0].date : null}
              />
            </div>
          </div>
          <Separator />
        </>
      )}

      <div className={`row ${type === 'operation' && 'row mb-4'}`}>
        <div className='col-12 col-md-6'>
          <InputSelect
            options={captable.shareClasses}
            className='mb-md-0'
            preText={literals.shareClass}
            value={reduceData.shareClass}
            onChange={shareClass => setReduceData(prev => ({ ...prev, shares: {}, shareClass }))}
            isDisabled={!editable}
          />
        </div>
        {
          reduceData.shareClass && (
            <div className='col-12 col-md-6'>
              <InputNumber
                className='mb-md-0'
                preText={literals.repurchasePrice}
                value={reduceData.repurchasePPS}
                onChange={repurchasePPS => setReduceData(prev => ({ ...prev, repurchasePPS }))}
                minValue={0}
                symbol={currency.symbol}
                isDisabled={!editable}
              />
            </div>
          )
        }
      </div>

      { reduceData.shareClass && (
        <FormReduceTable
          literals={literals}
          currency={currency}
          shareClassShares={shareClassShares}
          reduceShares={reduceData.shares}
          setReduceShares={shares => setReduceData(prev => ({ ...prev, shares }))}
          editable={editable}
        />
      )}

      {type !== 'operation' && (
        <>
          <Separator />
          <div className='row'>
            <div className='col-12 '>
              <InputRichText
                minHeight='120px'
                preText={literals.additionalDocumentation}
                value={notes}
                onChange={v => setNotes(v)}
                isDisabled={!editable}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <InputFile
                preText={literalsCommon.documents}
                value={documents}
                isPublic={false}
                onChange={v => setDocuments(v)}
                multiple
                isDisabled={!editable}
              />
            </div>
          </div>
        </>
      )}
      { editable && (
        <div className='buttons'>
          <Button type='submit' text={literalsCommon.confirm} />
          { type === 'operation' && data.id && (
            <Button
              type='button'
              color='danger'
              className='mr-3'
              text={literalsCommon.delete}
              onClick={() => onDelete(ident)}
            />
          )}
        </div>
      )}
    </form>
  );
};

FormReduce.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  type: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  dateOrID: PropTypes.string,
};

FormReduce.defaultProps = {
  id: '',
  type: '',
  data: {},
  editable: true,
  dateOrID: formatDate(new Date(), { format: 'Y-m-d' }),
};

export default FormReduce;
