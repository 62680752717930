import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Separator from 'components/Separator';
import Table from 'components/Table';
import InputSelect from 'components/Inputs/inputSelect';
import StatCard from 'components/Statistics/StatCard';
import Searcher from 'components/Searcher';
import EmptyVotes from 'assets/svg/empty_votes.svg';
import ReactECharts from 'echarts-for-react';
import SkeletonDoughnutChart from 'components/Loading/skeletonDoughnutChart';
import { useParams, useSearchParams } from 'react-router-dom';
import { formatNumber, getColorRange } from 'utils/functions';
import { useCounter } from 'utils/customHooks';
import { fetchCaptableShares } from '../modules/actions';
import { getShareHolders } from '../../shareHolders/modules/actions';

const PopupVoteDetails = (props) => {
  const {
    literals,
    literalsCommon,
    date,
    activeTab,
    onClose,
  } = props;

  const [shares, setShares] = useState({});
  const [avatars, setAvatars] = useState({});
  const [groupBy, setGroupBy] = useState(activeTab || 'shareholder');
  const [search, setSearch] = useState({ value: '', normalized: '' });
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const tableRefreshFlag = useCounter(0);
  const params = useParams();

  useEffect(() => {
    (async () => {
      const dataShares = await fetchCaptableShares(params.id, date, { group: 'shareholder', size: 0, captable: captableId });
      const groupByShareholders = dataShares.items
        .filter(sh => sh.votes)
        .sort((a, b) => b.votingPercentage - a.votingPercentage);

      const auxGroupByRole = {};
      const auxGroupBySyndicate = {};

      groupByShareholders.forEach((share) => {
        const prev = auxGroupByRole[share.shareholder.role] || { shareholders: 0, votes: 0, votingPercentage: 0 };
        auxGroupByRole[share.shareholder.role] = {
          shareholders: prev.shareholders + 1,
          votes: prev.votes + share.votes,
          votingPercentage: prev.votingPercentage + share.votingPercentage,
        };

        const syndicate = share?.shareholder?.syndicate || { id: 'noSyndicate', name: literals.noSyndicate };
        const prevSyndicate = auxGroupBySyndicate[syndicate.id] || { shareholders: 0, votes: 0, votingPercentage: 0 };
        auxGroupBySyndicate[syndicate.id] = {
          name: syndicate.name,
          shareholders: prevSyndicate.shareholders + 1,
          votes: prevSyndicate.votes + share.votes,
          votingPercentage: prevSyndicate.votingPercentage + share.votingPercentage,
        };
      });

      setShares({
        groupByShareholders,
        groupByRole: Object.keys(auxGroupByRole).map(role => ({ role, ...auxGroupByRole[role] })),
        groupBySyndicate: Object.keys(auxGroupBySyndicate).map(syndicate => ({ syndicate, ...auxGroupBySyndicate[syndicate] })),
      });

      const dataShareholders = await getShareHolders(params.id, 0, 0);
      const auxAvatars = {};
      dataShareholders.items.forEach((sh) => { auxAvatars[sh.id] = sh.avatar; });
      setAvatars(auxAvatars);
      tableRefreshFlag.increase();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPieOptions = (data) => {
    const newData = data.slice(0, 4);
    const others = {
      id: 'others',
      name: literals.others,
      value: newData.reduce((acumulador, actual) => acumulador - actual.value, 100).toFixed(2),
    };
    if (others.value > 0.02) {
      newData.push(others);
    }

    return {
      color: getColorRange(newData.length),
      tooltip: {
        trigger: 'item',
        valueFormatter: value => `${value}%`,
      },
      series: [
        {
          type: 'pie',
          radius: ['50%', '70%'],
          data: newData,
          label: {
            position: 'inner',
            formatter: '{b|{b}}\n{per|{d}%}',
            rich: {
              b: {
                color: '#4C5058',
                fontSize: 13,
                fontWeight: 'bold',
                lineHeight: 20,
              },
              per: {
                color: '#fff',
                backgroundColor: '#4C5058',
                padding: 3,
                borderRadius: 4,
                align: 'center',
              },
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
  };

  const columnsShareholders = [
    {
      field: 'shareholder', width: 45, type: 'avatar', title: v => v.name, preRender: v => avatars[v.id],
    },
    {
      field: 'shareholder', grow: 1, label: literalsCommon.name, preRender: v => v.name,
    },
    {
      field: 'shareholder', width: 150, label: literals.role, preRender: v => literals[v.role],
    },
    {
      field: 'votes', width: 150, label: literals.votes, preRender: v => formatNumber(v, 0),
    },
    {
      field: 'votingPercentage', width: 200, label: literals.votingPercentage, preRender: v => formatNumber(v, 0, { dec: 2, symbol: '%' }),
    },
  ];

  const columnsRoles = [
    {
      field: 'role', grow: 1, label: literals.role, preRender: v => literals[v],
    },
    {
      field: 'shareholders', width: 150, label: literals.number,
    },
    {
      field: 'votes', width: 150, label: literals.votes, preRender: v => formatNumber(v, 0),
    },
    {
      field: 'votingPercentage', width: 150, label: literals.votingPercentage, preRender: v => formatNumber(v, 0, { dec: 2, symbol: '%' }),
    },
  ];

  const columnsSyndicates = [
    {
      field: 'name', grow: 1, label: literals.syndicate, preRender: v => literals[v] || v,
    },
    {
      field: 'shareholders', width: 150, label: literals.number,
    },
    {
      field: 'votes', width: 150, label: literals.votes, preRender: v => formatNumber(v, 0),
    },
    {
      field: 'votingPercentage', width: 150, label: literals.votingPercentage, preRender: v => formatNumber(v, 0, { dec: 2, symbol: '%' }),
    },
  ];

  const normalizeText = (str) => {
    return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const searchTable = () => {
    setSearch(prev => ({ ...prev, normalized: normalizeText(prev.value) }));
    tableRefreshFlag.increase();
  };

  const renderTable = () => {
    if (groupBy === 'shareholder') {
      return (
        <Table
          key='vote-groupByShareholder'
          searcher={false}
          columns={columnsShareholders}
          fetch={shares.groupByShareholders
            ? () => ({
              total: shares.groupByShareholders.length,
              items: shares.groupByShareholders.filter(sh => normalizeText(sh.shareholder.name).startsWith(search.normalized)),
            })
            : null
          }
          forceFetch={tableRefreshFlag.value}
        />
      );
    }

    if (groupBy === 'roles') {
      return (
        <Table
          key='vote-groupByRole'
          searcher={false}
          columns={columnsRoles}
          fetch={shares.groupByShareholders
            ? () => ({
              total: shares.groupByRole.length,
              items: shares.groupByRole.filter(role => normalizeText(literals[role.role]).startsWith(search.normalized)),
            })
            : null
          }
          forceFetch={tableRefreshFlag.value}
        />
      );
    }

    return (
      <Table
        key='vote-groupBySyndicate'
        searcher={false}
        columns={columnsSyndicates}
        fetch={shares.groupByShareholders
          ? () => ({
            total: shares.groupBySyndicate.length,
            items: shares.groupBySyndicate.filter(syndicate => normalizeText(syndicate.syndicate).startsWith(search.normalized)),
          })
          : null
        }
        forceFetch={tableRefreshFlag.value}
      />
    );
  };

  return (
    <Popup title={literals.votingDetails} onClose={onClose} size='large'>
      { !shares.groupByShareholders || shares.groupByShareholders.length ? (
        <>
          <div className='row'>
            <div className='col-12 col-md-4'>
              <StatCard
                title={literals.shareholdersNumber}
                value={formatNumber(shares?.groupByShareholders?.length)}
                loading={!shares.groupByShareholders}
              />
            </div>
            <div className='col-12 col-md-4'>
              <StatCard
                title={literals.rolesNumber}
                value={formatNumber(shares?.groupByRole?.length)}
                loading={!shares.groupByRole}
              />
            </div>
            <div className='col-12 col-md-4'>
              <StatCard
                title={literals.votesNumber}
                value={formatNumber(shares?.groupByShareholders?.reduce((acumulador, actual) => acumulador + actual.votes, 0))}
                loading={!shares.groupByShareholders}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-4'>
              {
                shares.groupByShareholders
                  ? <ReactECharts option={getPieOptions(shares.groupByShareholders.map(share => ({ name: share.shareholder.name, value: share.votingPercentage.toFixed(2) })))} />
                  : <SkeletonDoughnutChart className='my-5' size={204} />
              }
            </div>
            <div className='col-12 col-md-4'>
              {
                shares.groupByShareholders
                  ? <ReactECharts option={getPieOptions(shares.groupByRole.map(role => ({ name: literals[role.role], value: role.votingPercentage.toFixed(2) })))} />
                  : <SkeletonDoughnutChart className='my-5' size={204} />
              }
            </div>
            <div className='col-12 col-md-4'>
              {
                shares.groupByShareholders
                  ? <ReactECharts option={getPieOptions(shares.groupBySyndicate.map(syndicate => ({ name: syndicate.name, value: syndicate.votingPercentage.toFixed(2) })))} />
                  : <SkeletonDoughnutChart className='my-5' size={204} />
              }
            </div>
          </div>
          <Separator />
          <div className='d-flex align-items-center m-2 row'>
            <div className='col-12 col-md-6 p-0'>
              <Searcher
                className='mb-2'
                value={search?.value}
                onChange={v => setSearch(prev => ({ ...prev, value: v }))}
                onSearch={searchTable}
                isDisabled={!shares.groupByShareholders}
              />
            </div>
            <div className='col-12 offset-md-2 col-md-4 p-0'>
              <InputSelect
                className='mb-2'
                preText={literals.lang}
                placeholder={literals.lang}
                onChange={v => setGroupBy(v)}
                value={groupBy}
                options={[
                  { id: 'shareholder', name: literals.byShareholder },
                  { id: 'roles', name: literals.byRole },
                  { id: 'syndicate', name: literals.bySyndicate },
                ]}
                isDisabled={!shares.groupByShareholders}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              {renderTable()}
            </div>
          </div>
        </>
      ) : (
        <div className='text-center'>
          <img src={EmptyVotes} alt={literals.notShareholderVotes} style={{ maxWidth: '350px' }} />
          <p className='fw-b mt-3'>{literals.notShareholderVotes}</p>
        </div>
      )}
    </Popup>
  );
};

PopupVoteDetails.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  activeTab: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

PopupVoteDetails.defaultProps = {
  activeTab: '',
};

export default PopupVoteDetails;
