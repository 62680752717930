import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import useDialog from 'components/Dialog/components/useDialog';
import Loading from 'components/Loading';
import {
  Trash,
  Pencil,
  PlusCircle,
  Eye,
  LineChart,
  Hourglass,
  CheckCircle,
  Clock,
  CloudUpload,
  ScrollText,
} from 'lucide-react';
import { useCounter } from 'utils/customHooks';
import { formatDate } from 'utils/functions';
import { AmplitudeApi } from 'utils/amplitude';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import { initializeCaptable } from 'routes/Captable/modules/actions';
import { canEditCaptable } from 'utils/functions/canEditCaptable';
import ImportExcelPopup from 'components/EmptyCaptable/ImportExcelPopup';
import PopupEditInfo from './PopupEditInfo';
import { fetchStartupOperations } from '../modules/actions';
import './styles.scss';

const OperationsTab = (props) => {
  const {
    literalsCommon,
    literals,
    match,
    captable,
    fetchCaptable,
    editOperationTimeline,
    editOperationDraft,
    deleteOperation,
    startup,
    simulation,
  } = props;

  const tableRefreshFlag = useCounter(0);
  const [operationsTimeline, setOperationsTimeline] = useState({});
  const [savingTimeline, setSavingTimeline] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [importExcelPopup, setImportExcelPopup] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { dialog } = useDialog();

  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const canEdit = canEditCaptable(captable);

  useEffect(() => {
    initializeCaptable(fetchCaptable, captable, match.params.id, captableId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const fetchOperations = useCallback((page, size, search, filters) => {
    return fetchStartupOperations(match.params.id, {
      page,
      size,
      search,
      filters,
      simulation,
      captable: captableId,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captableId]);

  const handleTimeline = async (row, status) => {
    await dialog({
      type: 'loading',
      execute: async () => {
        try {
          setSavingTimeline(true);
          await editOperationTimeline(match.params.id, row.id, status);
          setOperationsTimeline({ ...operationsTimeline, [row.id]: status });
          tableRefreshFlag.increase();
          setSavingTimeline(false);
        } catch (errors) {
          setSavingTimeline(false);
          await dialog({ type: 'error', text: 'Error' });
        }
      },
    });
  };

  const handleDelete = async (row) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literalsCommon.confirmDelete,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            await deleteOperation(match.params.id, row.id);
            AmplitudeApi.successEvent('startup.transactions.main.click.deleteOperation', {
              captable: captable?.selected?.id, stats: row.stats,
            });
            tableRefreshFlag.increase();
          } catch (errors) {
            AmplitudeApi.errorEvent('startup.transactions.main.click.deleteOperation', {
              captable: captable?.selected?.id, stats: row.stats,
            });
            let message = '';
            switch (errors?.error[0]?.code) {
              case 'cannot_delete_operation':
                message = literals.errorDeleteConfirmedOperation;
                break;
              default:
                message = literalsCommon.genericError;
                break;
            }
            await dialog({
              type: 'error',
              text: message,
            });
          }
        },
      });
    }
  };

  const handleChangeStatus = async (row) => {
    const status = !row.draft;
    let dialogText = status ? literals.questionStatusToDraft : literals.questionStatusToConfirm;
    if (status && row.id !== captable.operations[0].id) {
      dialogText = (
        <p>
          <span className='fw-b'>{literals.operationNotTheLast}</span>
          <br />
          {literals.questionStatusToDraftMultiple}
        </p>
      );
    }
    const confirm = await dialog({ type: 'confirm', text: dialogText });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            await editOperationDraft(match.params.id, row.id, status);
            AmplitudeApi.successEvent('startup.transactions.operationDetail.click.movetoDraft', {
              captable: captable?.selected?.id,
            });
            tableRefreshFlag.increase();
          } catch (errors) {
            let message = '';
            switch (errors?.error[0]?.code) {
              case 'cannot_draft_operation':
                message = literals.errorDraftOperation;
                break;
              case 'cannot_confirm_operation':
                message = literals.errorUndraftOperation;
                break;
              default:
                message = literalsCommon.genericError;
                break;
            }
            AmplitudeApi.errorEvent('startup.transactions.operationDetail.click.movetoDraft', {
              captable: captable?.selected?.id,
            });
            await dialog({
              type: 'error',
              text: message,
            });
          }
        },
      });
    }
  };

  const handleClickRow = (row) => {
    navigate(embedView(ROUTE_STARTUP_PATH.setCapTableOperation(match.params.id, captableId, row.id, simulation)));
  };

  const columnTimeline = (row) => {
    let checked = row.timeline && !row.draft;
    if (row.id in operationsTimeline) {
      checked = operationsTimeline[row.id];
    }
    if (row.draft) {
      return '-';
    }

    return (
      <input
        name={`timeline_${row.id}`}
        disabled={!canEdit || row.draft || savingTimeline}
        checked={checked}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onChange={e => handleTimeline(row, e.target.checked)}
        type='checkbox'
      />
    );
  };

  const columnStats = (opStats) => {
    const {
      assignShares,
      issueShares,
      issueDebt,
      issueOptions,
      assignOptions,
      payouts,
      secondaries,
      conversions,
      vestOptions,
      stockSplit,
      valuation,
      dividend,
      changeClasses,
      reduces,
    } = opStats;

    return (
      <>
        <ul className='operation-actions-stats'>
          <li title={literals.shares} className={assignShares || issueShares ? 'active' : ''}>SH</li>
          <li title={literals.debt} className={issueDebt ? 'active' : ''}>DB</li>
          <li title={literals.options} className={assignOptions || issueOptions ? 'active' : ''}>OP</li>
          <li title={literals.payouts} className={payouts ? 'active' : ''}>PO</li>
          <li title={literals.secondaries} className={secondaries ? 'active' : ''}>SE</li>
          <li title={literals.conversions} className={conversions ? 'active' : ''}>CO</li>
          <li title={literals.vestOptions} className={vestOptions ? 'active' : ''}>VO</li>
          <li title={literals.stockSplit} className={stockSplit ? 'active' : ''}>SS</li>
          <li title={literals.valuation} className={valuation ? 'active' : ''}>VL</li>
          <li title={literals.dividend} className={dividend ? 'active' : ''}>DI</li>
          <li title={literals.dividend} className={changeClasses ? 'active' : ''}>CC</li>
          <li title={literals.dividend} className={reduces ? 'active' : ''}>RS</li>
        </ul>
      </>
    );
  };

  const columns = [
    {
      field: 'date',
      width: 90,
      label: literalsCommon.date,
      preRender: formatDate,
    },
    {
      field: 'name',
      grow: 3,
      label: literalsCommon.name,
      type: 'html',
      preRender: (v, row) => {
        const name = v ? v.toString().replace(/(<([^>]+)>)/gi, '') : '';
        const notes = row.notes ? row.notes.replace(/(<([^>]+)>)/gi, '') : '';
        const nameEllipsis = name.length > 150 ? `${name.substr(0, 150)}...` : name;
        const notesEllipsis = notes.length > 150 ? `${notes.substr(0, 150)}...` : notes;
        return `
          <p title='${name}' class='mb-0'>${nameEllipsis}</p>
          ${notesEllipsis ? `<p title='${notes}' class="fc-secondary fs-small d-none d-sm-block mb-0">${notesEllipsis}</p>` : ''}
        `;
      },
    },
    {
      field: 'stats',
      width: 250,
      label: '',
      preRender: s => columnStats(s),
    },
    {
      field: 'draft',
      width: 50,
      label: literals.status,
      preRender: v => (
        <div className='operation-status'>
          <div className={`timeline ${!v ? 'confirmed' : ''}`}>
            {React.createElement(v ? Hourglass : CheckCircle, { size: 11 })}
          </div>
        </div>
      ),
    },
    {
      field: 'id',
      label: literals.timeline,
      width: 100,
      preRender: (v, row) => columnTimeline(row),
      align: 'center',
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: (id, row) => (row.draft && canEdit ? Pencil : Eye),
          text: (id, row) => (row.draft && canEdit ? literalsCommon.edit : literalsCommon.view),
          onClick: (row) => {
            AmplitudeApi.logEvent('startup.transactions.main.click.viewOperation', {
              captable: captable?.selected?.id,
            });
            navigate(
              embedView(ROUTE_STARTUP_PATH.setCapTableOperation(match.params.id, captableId, row.id, simulation)),
            );
          },
        },
        {
          icon: LineChart,
          text: literals.comparator,
          onClick: (row) => {
            AmplitudeApi.logEvent('startup.transactions.main.click.comparador', {
              captable: captable?.selected?.id,
            });
            navigate(
              embedView(ROUTE_STARTUP_PATH.setCapTableOperationsComparator(match.params.id, captableId, row.id, simulation)),
            );
          },
        },
        {
          icon: ScrollText,
          text: literals.waterfall,
          onClick: (row) => {
            AmplitudeApi.logEvent('startup.transactions.main.click.waterfall', {
              captable: captable?.selected?.id,
            });
            navigate(
              embedView(`${ROUTE_STARTUP_PATH.setCaptableWaterfallAdd(match.params.id, 'new', captableId, row.id, simulation)}${captableId ? '&' : '?'}operation=${row.id}`),
            );
          },
        },
      ],
    },
  ];

  if (canEdit) {
    columns[columns.length - 1].buttons.push({
      icon: Pencil,
      text: literalsCommon.edit,
      hidden: (id, row) => row.draft,
      onClick: (row) => {
        AmplitudeApi.logEvent('startup.transactions.main.click.editBasic', {
          captable: captable?.selected?.id,
        });
        setShowEditPopup(row);
      },
    });
    columns[columns.length - 1].buttons.push({
      icon: Hourglass,
      text: literals.setAsDraft,
      hidden: (id, row) => row.draft,
      onClick: row => handleChangeStatus(row)
      ,
    });
    columns[columns.length - 1].buttons.push({
      icon: Clock,
      text: (_, row) => (row.timeline ? literals.removeTimeline : literals.addTimeline),
      h_den: (_, row) => row.draft,
      onClick: (row) => {
        AmplitudeApi.logEvent('startup.transactions.main.click.timeline', {
          captable: captable?.selected?.id,
        });
        handleChangeStatus(row);
      },
    });
    columns[columns.length - 1].buttons.push({
      icon: Trash,
      text: literalsCommon.delete,
      onClick: row => handleDelete(row),
    });
  }

  const filters = [
    {
      id: 'date',
      type: 'dateRange',
      width: { md: 12 },
      title: literalsCommon.date,
    },
    {
      id: 'draft',
      type: 'list',
      title: literals.status,
      options: [
        { id: true, name: literals.draft },
        { id: false, name: literals.confirmed },
      ],
    },
    {
      id: 'timeline',
      type: 'list',
      title: literals.timeline,
      options: [
        { id: true, name: literalsCommon.yes },
        { id: false, name: literalsCommon.no },
      ],
    },
    {
      id: 'stats',
      type: 'list',
      mode: 'click',
      width: { md: 12, lg: 6 },
      title: literals.operations,
      options: [
        { id: 'assignShares', name: literals.shares },
        { id: 'issueDebt', name: literals.debt },
        { id: ['assignOptions', 'issueOptions'], name: literals.options },
        { id: 'payouts', name: literals.payouts },
        { id: 'secondaries', name: literals.secondaries },
        { id: 'conversions', name: literals.conversions },
        { id: 'stockSplit', name: literals.stockSplit },
        { id: 'valuation', name: literals.valuation },
        { id: 'dividend', name: literals.dividends },
        { id: 'changeClasses', name: literals.changeClasses },
        { id: 'vestOptions', name: literals.vestOptions },
      ],
      // default: [
      //   'assignShares', 'issueDebt', 'issueOptions', 'assignOptions', 'payouts', 'secondaries',
      //   'conversions', 'stockSplit', 'valuation', 'dividend', 'changeClasses',
      // ],
    },
  ];

  if (!captable.loaded) {
    return <Loading hide={false} mode='tool' />;
  }

  const tableActions = [
    {
      icon: PlusCircle,
      text: literals.newOperation,
      onClick: () => {
        AmplitudeApi.logEvent('startup.transactions.main.click.newOperation', {
          captable: captable?.selected?.id,
        });
        navigate(
          embedView(ROUTE_STARTUP_PATH.setCapTableOperation(match.params.id, captableId, 'new', simulation)),
        );
      },
    },
    {
      icon: CloudUpload,
      text: `${literals.importFrom} excel`,
      onClick: () => setImportExcelPopup(true),
    },
    {
      type: 'Link',
      icon: LineChart,
      text: literals.comparator,
      to: embedView(ROUTE_STARTUP_PATH.setCapTableOperationsComparator(match.params.id, captableId, 'all', simulation)),
    },
    {
      type: 'Link',
      icon: ScrollText,
      text: literals.waterfall,
      to: embedView(`${ROUTE_STARTUP_PATH.setCaptableWaterfallAdd(match.params.id, 'new', captableId, null, simulation)}`),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        filters={filters}
        fetch={fetchOperations}
        pageSize={10}
        actions={canEdit ? tableActions : null}
        forceFetch={tableRefreshFlag.value}
        onClickRow={handleClickRow}
      />
      {showEditPopup && (
        <PopupEditInfo
          literals={literals}
          literalsCommon={literalsCommon}
          match={match}
          row={showEditPopup}
          onClose={() => setShowEditPopup(false)}
          onSubmit={() => tableRefreshFlag.increase()}
        />
      )}
      {
        importExcelPopup && (
          <ImportExcelPopup
            literals={literals}
            literalsCommon={literalsCommon}
            startup={startup.id}
            captable={captable.selected?.id}
            onClose={() => setImportExcelPopup(false)}
            onSubmit={id => initializeCaptable(fetchCaptable, captable, startup.id, id, true)}
          />
        )
      }
    </>
  );
};

OperationsTab.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  fetchCaptable: PropTypes.func.isRequired,
  editOperationTimeline: PropTypes.func.isRequired,
  editOperationDraft: PropTypes.func.isRequired,
  deleteOperation: PropTypes.func.isRequired,
  simulation: PropTypes.string,
};

OperationsTab.defaultProps = {
  simulation: null,
};

export default OperationsTab;
