import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputText from 'components/Inputs/inputText';
import Popup from 'components/Popup';
import Tool from 'components/Tool';
import ToolTab from 'components/Tool/components/ToolTab';
import Button from 'components/Buttons/Button';
import InputFileZone from 'components/Inputs/inputFileZone';
import InputRichText from 'components/Inputs/inputRichText';
import OutputErrors from 'components/Inputs/outputErrors';
import InputPermission from 'components/Inputs/InputPermission';
import { PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import { TOOL_DATA_ROOM } from 'constants/tools';
import { initPermission } from 'utils/functions/initPermissions';
import { formatErrors } from 'utils/functions';
import { uploadLink, uploadNewFile } from '../modules/actions';

const PopupUploadFile = (props) => {
  const {
    literals, onClose, parent, setContent, setFolders, startup,
  } = props;

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [extension, setExtension] = useState('');
  const [fileDescription, setFileDescription] = useState('');
  const canEditParent = parent.permission.level > 1;

  const [fileNameLink, setFileNameLink] = useState('');
  const [fileDescriptionLink, setFileDescriptionLink] = useState('');
  const [fileURLLink, setFileURLLink] = useState('');
  const [errors, setErrors] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [permission, setPermission] = useState(initPermission(
    canEditParent ? parent : null,
    PERMISSION_SCOPES.STARTUP,
    startup,
    TOOL_DATA_ROOM,
  ));

  useEffect(() => {
    if (file) {
      const name = file.name.split('.');
      const ext = name.pop();
      setFileName(name.join('.'));
      setExtension(`.${ext}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const addStats = (auxFolders, type) => {
    return auxFolders.map((folder) => {
      if (folder.id === parent.id) {
        if (type === 'document') {
          return {
            ...folder,
            stats: { ...folder.stats, documents: folder.stats.documents + 1 },
          };
        }
      }
      if (folder.childs) {
        return { ...folder, childs: addStats(folder.childs) };
      }
      return folder;
    });
  };

  const handleUpload = async (type) => {
    setErrors(null);
    setUploadingFile(true);
    try {
      if (type === 'document') {
        await uploadNewFile(file, false, {
          folder: parent.id,
          name: fileName + extension,
          description: fileDescription,
          return: 'object',
          permission: JSON.stringify(permission),
        }).then((res) => {
          setContent(prev => ({ items: [res, ...prev.items], total: prev.total + 1 }));
          setFolders(prev => addStats(prev, 'document'));
        });
      } else {
        await uploadLink({
          name: fileNameLink,
          description: fileDescriptionLink,
          path: fileURLLink.trim(),
          folder: parent.id,
          permission,
        }).then((res) => {
          setContent(prev => ({ items: [res, ...prev.items], total: prev.total + 1 }));
          setFolders(prev => addStats(prev, 'link'));
        });
      }
      onClose();
    } catch (error) {
      setErrors(formatErrors(error));
      setUploadingFile(false);
    }
  };

  return (
    <Popup title={literals.upload} onClose={onClose}>
      <Tool
        hash={false}
        tabsStyle='panel'
        tabs={[
          { key: 'file', name: literals.file },
          { key: 'link', name: literals.link },
        ]}
      >
        <ToolTab key='file'>
          <InputFileZone
            preText={literals.selectFile}
            placeholder={literals.upload}
            onChange={setFile}
            isRequired
          />
          <div className='row'>
            <div className='col-12'>
              <InputText
                preText={literals.fileName}
                placeholder={literals.fileName}
                onChange={v => setFileName(v)}
                value={fileName}
                isDisabled={!file}
                isRequired
              />
              <span className='ext'>{extension}</span>
            </div>
          </div>
          <InputPermission
            scope={{ type: PERMISSION_SCOPES.STARTUP, id: startup.id }}
            element={{ type: PERMISSION_TYPES.DOCUMENT, id: null }}
            value={permission}
            onChange={setPermission}
          />
          <InputRichText
            preText={literals.fileDescription}
            placeholder={literals.fileDescription}
            onChange={v => setFileDescription(v)}
            value={fileDescription}
            minHeight='120px'
            isDisabled={!file}
          />
          <OutputErrors errors={errors} />
          <div className='d-flex justify-content-end'>
            <Button
              onClick={() => handleUpload('document')}
              text={literals.upload}
              disabled={!file || fileName === ''}
              loading={uploadingFile}
            />
          </div>
        </ToolTab>
        <ToolTab key='link'>
          <InputText
            preText={literals.fileName}
            placeholder={literals.fileName}
            onChange={v => setFileNameLink(v)}
            value={fileNameLink}
            isRequired
          />
          <InputText
            preText='URL'
            placeholder='URL'
            onChange={v => setFileURLLink(v)}
            value={fileURLLink}
            isRequired
          />
          <InputPermission
            scope={{ type: PERMISSION_SCOPES.STARTUP, id: startup.id }}
            element={{ type: PERMISSION_TYPES.LINK, id: null }}
            value={permission}
            onChange={setPermission}
          />
          <InputRichText
            preText={literals.fileDescription}
            placeholder={literals.fileDescription}
            onChange={v => setFileDescriptionLink(v)}
            minHeight='120px'
            value={fileDescriptionLink}
          />
          <OutputErrors errors={errors} />
          <div className='d-flex justify-content-end'>
            <Button
              onClick={() => handleUpload('link')}
              text={literals.upload}
              disabled={fileNameLink === '' || fileURLLink === ''}
              loading={uploadingFile}
            />
          </div>
        </ToolTab>
      </Tool>
    </Popup>
  );
};

PopupUploadFile.propTypes = {
  literals: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  setContent: PropTypes.func.isRequired,
  setFolders: PropTypes.func.isRequired,
  parent: PropTypes.object.isRequired,
};

export default PopupUploadFile;
