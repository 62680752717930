import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRect } from 'utils/customHooks';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import Portal from 'components/Portal';
import Button from './Button';
import './styles.scss';

const ButtonDropdown = (props) => {
  const {
    align = 'right',
    buttons = [],
  } = props;

  const [showMenu, setShowMenu] = useState(false);
  const [bbox, ref, visible] = useRect(showMenu);

  const buttonProps = { ...props };
  delete (buttonProps.buttons);

  const handleClickAction = (func) => {
    setShowMenu(false);
    func();
  };

  const styles = { visible };
  if (bbox) {
    styles.top = bbox.bottom + 5;
    styles.left = bbox.left;
    styles.minWidth = bbox.width;
  }

  return (
    <div ref={ref}>
      <Button {...buttonProps} iconRight={ChevronDown} onClick={() => setShowMenu(!showMenu)} />
      { showMenu && (
        <Portal styles={styles} onClose={() => setShowMenu(false)}>
          <div className={`button-dropdown-menu align-${align}`}>
            {
              buttons.map((button, i) => {
                if (button.hidden) {
                  return null;
                }
                if (button.type === 'Link') {
                  return (
                    <Link to={button.to} key={`button-${i}`} className='button-dropdown-item'>
                      {button.icon && React.createElement(button.icon, { className: 'icon-left', size: 15 })}
                      {button.text}
                    </Link>
                  );
                }
                return (
                  <div key={`button-${i}`} className='button-dropdown-item' onClick={() => handleClickAction(button.onClick)}>
                    {button.icon && React.createElement(button.icon, { className: 'icon-left', size: 15 })}
                    {button.text}
                  </div>
                );
              })
            }
          </div>
        </Portal>
      )}
    </div>
  );
};

ButtonDropdown.propTypes = {
  text: PropTypes.string.isRequired,
  align: PropTypes.string,
  color: PropTypes.string,
  buttons: PropTypes.array,
};

export default ButtonDropdown;
