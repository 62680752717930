import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const AdminInstance = ApiFetch();

export const URLS = {
  CREATE_FUND_MASSIVE: 'admin/funds/massive',
  CREATE_SERVICE: 'admin/services',
  CREATE_SERVICES_REQUESTS_ADMIN: 'admin/services/requests',
  CREATE_STARTUP: 'admin/startups',
  CREATE_STARTUP_MASSIVE: 'admin/startups/massive',
  CREATE_USERS_MASSIVE: 'admin/users/massive',
  DELETE_ADMIN_FUND: 'admin/funds/:id',
  DELETE_QUESTION: 'admin/questions/:id',
  DELETE_SERVICE: 'admin/services/:id',
  DELETE_SERVICES_REQUEST_ADMIN: 'admin/services/requests/:id',
  DELETE_STARTUP: 'admin/startups/:id',
  DELETE_USER: 'admin/users/:id',
  DELETE_WEBHOOK: 'admin/webhooks/:id',
  EDIT_PLATFORM_TOOL_PERMISSIONS: 'admin/platform/tool/permissions/:id',
  EDIT_SERVICE: 'admin/services/:id',
  EDIT_SERVICES_REQUEST: 'admin/services/requests/:id',
  GET_ADMIN_CAPTABLE_SUMMARY: 'admin/captable/summary',
  GET_ADMIN_DATAROOM_SUMMARY: 'admin/dataroom/summary',
  GET_ADMIN_FUNDRAISES: 'admin/fundraise',
  GET_ADMIN_FUNDS: 'admin/funds',
  GET_ADMIN_KPIS_SUMMARY: 'admin/kpis/summary',
  GET_ADMIN_MENTORINGS_SUMMARY: 'admin/mentorings/summary',
  GET_ADMIN_REPORTS_SUMMARY: 'admin/reports/summary',
  GET_ADMIN_SUMMARY: 'admin/summary',
  GET_ALL_KPI_RELATIONS: 'admin/kpis/:kpi/relations',
  GET_FORM: 'admin/forms/:id',
  GET_GOOGLE_ANALYTICS_PAGES: 'admin/analytics/google/pages',
  GET_GOOGLE_ANALYTICS_STARTUPS: 'admin/analytics/google/startups',
  GET_GOOGLE_ANALYTICS_SUMMARY: 'admin/analytics/google',
  GET_GOOGLE_ANALYTICS_TOOLS: 'admin/analytics/google/tools',
  GET_GOOGLE_ANALYTICS_USERS: 'admin/analytics/google/users',
  GET_INTERNAL_MAILS: 'admin/emailbot',
  GET_MENTORINGS_MENTORS: 'admin/mentorings/mentors',
  GET_MENTORINGS_STARTUPS: 'admin/mentorings/startups',
  GET_PLATFORM: 'admin/platform',
  GET_QUESTION: 'admin/questions/:id',
  GET_QUESTIONS: 'admin/questions',
  GET_SCRIPT_LOGS: 'admin/scripts',
  GET_SERVICE: 'admin/services/:id',
  GET_SERVICES: 'admin/services',
  GET_SERVICES_REQUEST: 'admin/services/requests/:id',
  GET_SERVICES_REQUESTS: 'admin/services/requests',
  GET_SERVICES_REQUESTS_STATS: 'admin/services/requests/stats',
  GET_STARTUPS: 'admin/startups',
  GET_SUPPORT_MESSAGE: 'admin/accounts/support/:id',
  GET_SUPPORT_MESSAGES: 'admin/accounts/support',
  GET_USERS: 'admin/users',
  GET_WEBHOOKS: 'admin/webhooks',
  IMPERSONATE_USER: 'admin/users/impersonate/:id',
  POST_ADMIN_FUND: 'admin/funds',
  POST_KPI: 'admin/kpis',
  POST_QUESTIONS: 'admin/questions',
  POST_USERS: 'admin/users',
  PUT_ADMIN_FUND: 'admin/funds/:id',
  PUT_FORM: 'admin/forms/:id',
  PUT_LEGAL: 'admin/legal',
  PUT_PLATFORM: 'admin/platform',
  PUT_QUESTION: 'admin/questions/:id',
  SEND_NOTIFICATIONS_SUMMARY: 'admin/notifications/summary',
  SET_MENTOR_GROUP: 'admin/mentors/:id/groups/:group',
  SET_STARTUP_GROUP: 'admin/startups/:id/groups/:group',
  SET_STARTUP_PLAN: 'admin/startups/:id/plan/:plan',
  SET_STARTUP_VERIFIED: 'admin/startups/:id/verified/:verified',
  SET_STARTUPS_PHANTOM: 'admin/startups/phantom',
  SET_STARTUPS_VERIFIED: 'admin/startups/verified/:verified',
  MERGE_STARTUPS: 'admin/startups/merge',
};

async function createFundMassive(file) {
  return AdminInstance.postForm(URLS.CREATE_FUND_MASSIVE, { file });
}

async function createService(data) {
  return AdminInstance.post(URLS.CREATE_SERVICE, data);
}

async function createServicesRequestsAdmin(data) {
  return AdminInstance.post(URLS.CREATE_SERVICES_REQUESTS_ADMIN, data);
}

async function createStartup(data) {
  return AdminInstance.post(URLS.CREATE_STARTUP, data);
}

async function createStartupMassive(file, params) {
  return AdminInstance.postForm(URLS.CREATE_STARTUP_MASSIVE, {
    file, ...params,
  });
}

async function createUsersMassive(file) {
  return AdminInstance.postForm(URLS.CREATE_USERS_MASSIVE, { file });
}

async function deleteAdminFund(id) {
  const path = preparePath(URLS.DELETE_ADMIN_FUND, { id });
  return AdminInstance.delete(path);
}

async function deleteQuestion(id) {
  const path = preparePath(URLS.DELETE_QUESTION, { id });
  return AdminInstance.delete(path);
}

async function deleteService(id) {
  const path = preparePath(URLS.DELETE_SERVICE, { id });
  return AdminInstance.delete(path);
}

async function deleteServicesRequestAdmin(id) {
  const path = preparePath(URLS.DELETE_SERVICES_REQUEST_ADMIN, { id });
  return AdminInstance.delete(path);
}

async function deleteStartup(id) {
  const path = preparePath(URLS.DELETE_STARTUP, { id });
  return AdminInstance.delete(path);
}

async function deleteUser(id) {
  const path = preparePath(URLS.DELETE_USER, { id });
  return AdminInstance.delete(path);
}

async function deleteWebhook(id) {
  const path = preparePath(URLS.DELETE_WEBHOOK, { id });
  return AdminInstance.delete(path);
}

async function editPlatformToolPermissions(id, data) {
  const path = preparePath(URLS.EDIT_PLATFORM_TOOL_PERMISSIONS, { id });
  return AdminInstance.post(path, data);
}

async function editService(id, data) {
  const path = preparePath(URLS.EDIT_SERVICE, { id });
  return AdminInstance.put(path, data);
}

async function editServicesRequest(id, data) {
  const path = preparePath(URLS.EDIT_SERVICES_REQUEST, { id });
  return AdminInstance.put(path, data);
}

async function getAdminCaptableSummary() {
  return AdminInstance.get(URLS.GET_ADMIN_CAPTABLE_SUMMARY);
}

async function getAdminDataroomSummary() {
  return AdminInstance.get(URLS.GET_ADMIN_DATAROOM_SUMMARY);
}

async function getAdminFundraises(params = {}) {
  const path = preparePath(URLS.GET_ADMIN_FUNDRAISES, {}, params);
  return AdminInstance.get(path);
}

async function getAdminFunds(params = {}) {
  const path = preparePath(URLS.GET_ADMIN_FUNDS, {}, params);
  return AdminInstance.get(path);
}

async function getAdminKpisSummary() {
  return AdminInstance.get(URLS.GET_ADMIN_KPIS_SUMMARY);
}

async function getAdminMentoringsSummary() {
  return AdminInstance.get(URLS.GET_ADMIN_MENTORINGS_SUMMARY);
}

async function getAdminReportsSummary() {
  return AdminInstance.get(URLS.GET_ADMIN_REPORTS_SUMMARY);
}

async function getAdminSummary() {
  return AdminInstance.get(URLS.GET_ADMIN_SUMMARY);
}

async function getAllKpiRelations(kpi, params = {}) {
  const path = preparePath(URLS.GET_ALL_KPI_RELATIONS, { kpi }, params);
  return AdminInstance.get(path);
}

async function getForm(id) {
  const path = preparePath(URLS.GET_FORM, { id });
  return AdminInstance.get(path);
}

async function getGoogleAnalyticsPages(params = {}) {
  const path = preparePath(URLS.GET_GOOGLE_ANALYTICS_PAGES, {}, params);
  return AdminInstance.get(path);
}

async function getGoogleAnalyticsStartups(params = {}) {
  const path = preparePath(URLS.GET_GOOGLE_ANALYTICS_STARTUPS, {}, params);
  return AdminInstance.get(path);
}

async function getGoogleAnalyticsSummary(params = {}) {
  const path = preparePath(URLS.GET_GOOGLE_ANALYTICS_SUMMARY, {}, params);
  return AdminInstance.get(path);
}

async function getGoogleAnalyticsTools(params = {}) {
  const path = preparePath(URLS.GET_GOOGLE_ANALYTICS_TOOLS, {}, params);
  return AdminInstance.get(path);
}

async function getGoogleAnalyticsUsers(params = {}) {
  const path = preparePath(URLS.GET_GOOGLE_ANALYTICS_USERS, {}, params);
  return AdminInstance.get(path);
}

async function getInternalMails(params = {}) {
  const path = preparePath(URLS.GET_INTERNAL_MAILS, {}, params);
  return AdminInstance.get(path);
}

async function getMentoringsMentors(params = {}) {
  const path = preparePath(URLS.GET_MENTORINGS_MENTORS, {}, params);
  return AdminInstance.get(path);
}

async function getMentoringsStartups(params = {}) {
  const path = preparePath(URLS.GET_MENTORINGS_STARTUPS, {}, params);
  return AdminInstance.get(path);
}

async function getPlatform() {
  return AdminInstance.get(URLS.GET_PLATFORM);
}

async function getQuestion(id) {
  const path = preparePath(URLS.GET_QUESTION, { id });
  return AdminInstance.get(path);
}

async function getQuestions(params = {}) {
  const path = preparePath(URLS.GET_QUESTIONS, {}, params);
  return AdminInstance.get(path);
}

async function getScriptLogs(params = {}) {
  const path = preparePath(URLS.GET_SCRIPT_LOGS, {}, params);
  return AdminInstance.get(path);
}

async function getService(id) {
  const path = preparePath(URLS.GET_SERVICE, { id });
  return AdminInstance.get(path);
}

async function getServices(params = {}) {
  const path = preparePath(URLS.GET_SERVICES, {}, params);
  return AdminInstance.get(path);
}

async function getServicesRequest(id) {
  const path = preparePath(URLS.GET_SERVICES_REQUEST, { id });
  return AdminInstance.get(path);
}

async function getServicesRequests(params = {}) {
  const path = preparePath(URLS.GET_SERVICES_REQUESTS, {}, params);
  return AdminInstance.get(path);
}

async function getServicesRequestsStats() {
  return AdminInstance.get(URLS.GET_SERVICES_REQUESTS_STATS);
}

async function getStartups(params = {}) {
  const path = preparePath(URLS.GET_STARTUPS, {}, params);
  return AdminInstance.get(path);
}

async function getSupportMessage(id) {
  const path = preparePath(URLS.GET_SUPPORT_MESSAGE, { id });
  return AdminInstance.get(path);
}

async function getSupportMessages(params = {}) {
  const path = preparePath(URLS.GET_SUPPORT_MESSAGES, {}, params);
  return AdminInstance.get(path);
}

async function getUsers(params = {}) {
  const path = preparePath(URLS.GET_USERS, {}, params);
  return AdminInstance.get(path);
}

async function getWebhooks(params = {}) {
  const path = preparePath(URLS.GET_WEBHOOKS, {}, params);
  return AdminInstance.get(path);
}

async function impersonateUser(id) {
  const path = preparePath(URLS.IMPERSONATE_USER, { id });
  return AdminInstance.post(path);
}

async function postAdminFund(data) {
  return AdminInstance.post(URLS.POST_ADMIN_FUND, data);
}

async function postKPI(data) {
  return AdminInstance.post(URLS.POST_KPI, data);
}

async function postQuestions(data) {
  return AdminInstance.post(URLS.POST_QUESTIONS, data);
}

async function postUsers(data) {
  return AdminInstance.post(URLS.POST_USERS, data);
}

async function putAdminFund(id, data) {
  const path = preparePath(URLS.PUT_ADMIN_FUND, { id });
  return AdminInstance.put(path, data);
}

async function putForm(id, data) {
  const path = preparePath(URLS.PUT_FORM, { id });
  return AdminInstance.put(path, data);
}

async function putLegal(data) {
  return AdminInstance.put(URLS.PUT_LEGAL, data);
}

async function putPlatform(data) {
  return AdminInstance.put(URLS.PUT_PLATFORM, data);
}

async function putQuestion(id, data) {
  const path = preparePath(URLS.PUT_QUESTION, { id });
  return AdminInstance.put(path, data);
}

async function sendNotificationsSummary() {
  return AdminInstance.post(URLS.SEND_NOTIFICATIONS_SUMMARY);
}

async function setMentorGroup(id, group) {
  const path = preparePath(URLS.SET_MENTOR_GROUP, { id, group });
  return AdminInstance.put(path);
}

async function setStartupGroup(id, group) {
  const path = preparePath(URLS.SET_STARTUP_GROUP, { id, group });
  return AdminInstance.put(path);
}

async function setStartupPlan(id, plan) {
  const path = preparePath(URLS.SET_STARTUP_PLAN, { id, plan });
  return AdminInstance.put(path);
}

async function setStartupVerified(id, verified) {
  const path = preparePath(URLS.SET_STARTUP_VERIFIED, { id, verified });
  return AdminInstance.put(path);
}

async function setStartupsPhantom(data) {
  return AdminInstance.put(URLS.SET_STARTUPS_PHANTOM, data);
}

async function setStartupsVerified(verified, data) {
  const path = preparePath(URLS.SET_STARTUPS_VERIFIED, { verified });
  return AdminInstance.put(path, data);
}

async function mergeStartups(main, others) {
  const path = preparePath(URLS.MERGE_STARTUPS);
  return AdminInstance.put(path, { main, others });
}

export default {
  createFundMassive,
  createService,
  createServicesRequestsAdmin,
  createStartup,
  createStartupMassive,
  createUsersMassive,
  deleteAdminFund,
  deleteQuestion,
  deleteService,
  deleteServicesRequestAdmin,
  deleteStartup,
  deleteUser,
  deleteWebhook,
  editPlatformToolPermissions,
  editService,
  editServicesRequest,
  getAdminCaptableSummary,
  getAdminDataroomSummary,
  getAdminFundraises,
  getAdminFunds,
  getAdminKpisSummary,
  getAdminMentoringsSummary,
  getAdminReportsSummary,
  getAdminSummary,
  getAllKpiRelations,
  getForm,
  getGoogleAnalyticsPages,
  getGoogleAnalyticsStartups,
  getGoogleAnalyticsSummary,
  getGoogleAnalyticsTools,
  getGoogleAnalyticsUsers,
  getInternalMails,
  getMentoringsMentors,
  getMentoringsStartups,
  getPlatform,
  getQuestion,
  getQuestions,
  getScriptLogs,
  getService,
  getServices,
  getServicesRequest,
  getServicesRequests,
  getServicesRequestsStats,
  getStartups,
  getSupportMessage,
  getSupportMessages,
  getUsers,
  getWebhooks,
  impersonateUser,
  postAdminFund,
  postKPI,
  postQuestions,
  postUsers,
  putAdminFund,
  putForm,
  putLegal,
  putPlatform,
  putQuestion,
  sendNotificationsSummary,
  setMentorGroup,
  setStartupGroup,
  setStartupPlan,
  setStartupVerified,
  setStartupsPhantom,
  setStartupsVerified,
  mergeStartups,
};
