import StartupsFundraisesInstance from 'modules/startupsFundraises/repository';

export const getFundraise = (fundraise, opts) => {
  return StartupsFundraisesInstance.getFundraise(fundraise, opts);
};

export const postFundraiseInvestment = (fundraise, data) => {
  return StartupsFundraisesInstance.postFundraiseInvestment(fundraise, data);
};

export const putFundraiseInvestment = (fundraise, investment, data) => {
  return StartupsFundraisesInstance.putFundraiseInvestment(fundraise, investment, data);
};

export const deleteFundraiseInvestment = (fundraise, investment) => {
  return StartupsFundraisesInstance.deleteFundraiseInvestment(fundraise, investment);
};

export const getFundraiseInvestments = (fundraise) => {
  return StartupsFundraisesInstance.getFundraiseInvestments(fundraise);
};

export const getFundraiseInvites = (fundraise) => {
  return StartupsFundraisesInstance.getFundraiseInvites(fundraise);
};

export const postFundraiseInvite = (fundraise, data) => {
  return StartupsFundraisesInstance.postFundraiseInvite(fundraise, data);
};

export const deleteFundraiseInvite = (fundraise, invite) => {
  return StartupsFundraisesInstance.deleteFundraiseInvite(fundraise, invite);
};
