/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Eye, EyeOff } from 'lucide-react';
import './styles.scss';

const InputPassword = ({
  preText = '',
  postText = '',
  placeholder = '',
  isRequired = false,
  isDisabled = false,
  value = '',
  maxLength = -1,
  errors = [],
  name = '',
  onChange,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className='input_password'>
      <div className='pre_text'>
        {preText}
        {isRequired && <span className='is_required'>*</span>}
      </div>
      <div className='password_flex'>
        <input
          className='password'
          name={name}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          disabled={isDisabled}
          maxLength={maxLength > 0 ? maxLength : -1}
          value={value}
          onChange={e => onChange(e.target.value, e)}
        />
        <div className='show_password' onClick={() => setShowPassword(prevShowPassword => !prevShowPassword)}>
          {showPassword ? <EyeOff /> : <Eye />}
        </div>
      </div>
      { postText && (<div className='post_text' dangerouslySetInnerHTML={{ __html: postText }} />) }
      <div className='errors'>
        {errors.map((err, index) => (
          <span key={index}>
            {typeof err === 'object' ? err.message : err}
            <br />
          </span>
        ))}
      </div>
    </div>
  );
};

InputPassword.propTypes = {
  preText: PropTypes.string,
  postText: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.array,
  name: PropTypes.string,
};

export default InputPassword;
