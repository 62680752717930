import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import { bindActionCreators } from 'redux';
import InternalFormComponent from '../components';
import {
  fetchFormFundAction, fetchFormInvestorAction, fetchFormMentorAction, fetchFormStartupAction,
} from '../modules/actions';

const mapStateToProps = state => ({
  literals: state.i18n.literals.onboarding,
  lang: state.i18n.language,
  forms: state.forms,
  platform: state.global.platform,
});

const mapDispatchToProps = dispatch => ({
  fetchFormMentor: bindActionCreators(fetchFormMentorAction, dispatch),
  fetchFormInvestor: bindActionCreators(fetchFormInvestorAction, dispatch),
  fetchFormStartup: bindActionCreators(fetchFormStartupAction, dispatch),
  fetchFormFund: bindActionCreators(fetchFormFundAction, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InternalFormComponent));
