import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import StatCard from 'components/Statistics/StatCard';
import Table from 'components/Table';
import { useParams, useSearchParams } from 'react-router-dom';
import { Eye, LineChart } from 'lucide-react';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import { formatDate, formatNumber } from 'utils/functions';
import { fetchCaptableDebts } from '../modules/actions';
import PopupShareholderDebts from '../../shareHolders/components/routes/shareholderInfo/components/PopupShareholderDebts.';

const PopupInfoDebts = (props) => {
  const {
    debt,
    literals,
    literalsCommon,
    currency,
    shareholder,
    date,
    onClose,
  } = props;

  const [stats, setStats] = useState(false);
  const [debts, setDebts] = useState(false);
  const [debtDetails, setDebtDetails] = useState(false);
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const { id: startup } = useParams();

  useEffect(() => {
    (async () => {
      const params = {
        page: 0,
        size: 0,
        filters: {
          shareholder: shareholder?.id || null,
          pending: { gt: 0 },
        },
        captable: captableId,
      };
      if (debt) {
        params.filters.debt = debt;
      }
      const data = await fetchCaptableDebts(startup, date, params);

      const auxStats = {
        principal: 0,
        interestRate: 0,
        pending: 0,
        capitalized: 0,
        repaid: 0,
      };
      data.items.forEach((item) => {
        auxStats.principal += item.principal;
        auxStats.interestRate += item.interestRate;
        auxStats.pending += item.pending;
        auxStats.capitalized += item.capitalized;
        auxStats.repaid += item.repaid;
        auxStats.count += 1;
      });
      auxStats.interestRate /= auxStats.count;

      data.items.sort((a, b) => (
        b.originalOperation.date.localeCompare(a.originalOperation.date)
      ));
      setStats(auxStats);
      setDebts(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      field: 'principal',
      width: 100,
      label: literals.principal,
      preRender: v => `${formatNumber(v, 0, { dec: 2 })}${currency.symbol}`,
    },
    {
      field: 'pending',
      width: 100,
      label: literals.pending,
      preRender: v => `${formatNumber(v, 0, { dec: 2 })}${currency.symbol}`,

    },
    {
      field: 'repaid',
      width: 100,
      label: literals.repaid,
      preRender: v => `${formatNumber(v, 0, { dec: 2 })}${currency.symbol}`,

    },
    {
      field: 'capitalized',
      width: 100,
      label: literals.capitalized,
      preRender: v => `${formatNumber(v, 0, { dec: 2 })}${currency.symbol}`,

    },
    {
      field: 'interestRate',
      type: 'html',
      width: 80,
      label: literals.interest,
      preRender: v => `${formatNumber(v, 0, { dec: 2 })}%`,
    },
    {
      field: 'startDate',
      width: 150,
      label: literalsCommon.date,
      preRender: (v, row) => (
        <>
          <span>{`${literals.from}: ${v ? formatDate(v) : '-'}`}</span>
          <br />
          <span>{`${literals.to}: ${row.expirationDate ? formatDate(row.expirationDate) : '-'}`}</span>
        </>
      ),
    },
    {
      field: 'originalOperation',
      width: 100,
      label: literals.operation,
      preRender: v => <span title={v.name}>{v.date}</span>,
    },
    {
      field: 'originalOperation',
      type: 'menu',
      buttons: [
        {
          icon: Eye,
          text: literalsCommon.view,
          onClick: row => setDebtDetails(row),
        },
        {
          icon: LineChart,
          text: literals.operation,
          to: operation => embedView(ROUTE_STARTUP_PATH.setCapTableOperation(startup, captableId, operation.id)),
          type: 'Link',
        },
      ],
    },
  ];

  return (
    <Popup title={shareholder?.name} onClose={onClose} size='large'>
      <>
        <div className='row'>
          <div className='col-12 col-md-3'>
            <StatCard title={literals.principal} value={formatNumber(stats.principal, 0, { symbol: currency.symbol })} loading={!stats} />
          </div>
          <div className='col-12 col-md-3'>
            <StatCard title={literals.pending} value={formatNumber(stats.pending, 0, { symbol: currency.symbol })} loading={!stats} />
          </div>
          <div className='col-12 col-md-3'>
            <StatCard title={literals.capitalized} value={formatNumber(stats.capitalized, 0, { symbol: currency.symbol })} loading={!stats} />
          </div>
          <div className='col-12 col-md-3'>
            <StatCard title={literals.repaid} value={formatNumber(stats.repaid, 0, { symbol: currency.symbol })} loading={!stats} />
          </div>
        </div>
        {
          debts ? (
            <Table
              key='table-debts-details'
              searcher={false}
              columns={columns}
              fetch={() => ({ items: debts.items, total: debts.total })}
              onClickRow={row => setDebtDetails(row)}
            />
          ) : (
            <Table
              searcher={false}
              columns={columns}
              fetch={null}
            />
          )
        }
        {debtDetails && (
          <PopupShareholderDebts
            literals={literals}
            literalsCommon={literalsCommon}
            currency={currency}
            onClose={() => setDebtDetails(false)}
            row={debtDetails}
          />
        )}
      </>
    </Popup>
  );
};

PopupInfoDebts.propTypes = {
  debt: PropTypes.string,
  shareholder: PropTypes.object,
  date: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
};

PopupInfoDebts.defaultProps = {
  debt: null,
  shareholder: null,
};

export default PopupInfoDebts;
