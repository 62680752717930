import React from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';

const DroppableComponent = ({ id, children }) => {
  return (
    <Droppable droppableId={id}>
      {droppableProvider => (
        <div
          {...droppableProvider.droppableProps}
          ref={droppableProvider.innerRef}
        >
          {children}
          {droppableProvider.placeholder}
        </div>
      )}
    </Droppable>
  );
};

DroppableComponent.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default DroppableComponent;
