import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputNumber from 'components/Inputs/inputNumber';
import InputSelect from 'components/Inputs/inputSelect';
import Button from 'components/Buttons/Button';
import { formatDate, formatNumber } from 'utils/functions';
import { PlusCircle } from 'lucide-react';
import OutputValue from 'components/Inputs/outputValue';
import PopupFormShareClass from '../../shareClasses/components/PopupFormShareClass';
import ShareholdersAssignments from './ShareholdersAssignments';

const FormShares = (props) => {
  const {
    editable,
    data,
    currency,
    captable,
    match,
    dateOrID,
    lastNumeration,
    literals,
    literalsCommon,
    onConfirm,
    onDelete,
    conflict,
  } = props;

  const [id] = useState(data.create?.id ?? `new.${new Date().getTime()}`);
  const [shareClass, setShareClass] = useState(data.create?.shareClass ?? '');
  const [pps, setPPS] = useState(data.create?.pps ?? 1);
  const [number, setNumber] = useState(data.create?.number ?? 1);
  const [nominalValue, setNominalValue] = useState(data.create?.nominalValue ?? (data.create?.pps || 1));
  const [sharePremium, setSharePremium] = useState(data.create?.sharePremium ?? 0);
  const [numeration, setNumeration] = useState({
    from: data.create?.numeration?.from ?? 0,
    to: data.create?.numeration?.to ?? 0,
  });
  const [assignments, setAssignments] = useState(data.assign);
  const [popupShareClass, setPopupShareClass] = useState(false);
  const [valuation, setValuation] = useState(data?.create?.valuation ?? true);
  const [autoNumeration, setAutoNumeration] = useState(!numeration.from);
  // const [preMoneyValuation, setPreMoneyValuation] = useState({
  //   show: !!data?.create?.preMoney,
  //   value: data?.create?.preMoney ?? 0,
  // });

  const handleConfirm = () => {
    onConfirm({
      create: {
        id,
        shareClass,
        pps: pps !== '' ? parseFloat(pps) : null,
        nominalValue: nominalValue !== '' ? parseFloat(nominalValue) : null,
        sharePremium: sharePremium !== '' ? parseFloat(sharePremium) : null,
        number: number !== '' ? parseInt(number, 10) : null,
        numeration: {
          from: numeration.from !== '' ? parseInt(numeration.from, 10) : null,
          to: numeration.to !== '' ? parseInt(numeration.to, 10) : null,
        },
        valuation,
        // preMoney: valuation && preMoneyValuation.show ? preMoneyValuation.value : 0,
      },
      assign: assignments,
    });
  };

  const handleChangeNumber = (value) => {
    setNumber(value);
    setNumeration({ ...numeration, to: numeration.from + value - 1 });
  };

  const handleChangeFrom = (value) => {
    setNumeration({ from: value, to: value + number - 1 });
  };

  const handleChangeValue = (prop, value) => {
    const newValue = parseFloat(value);
    let newPPS = 0;
    if (prop === 'nominalValue') {
      newPPS = newValue + parseFloat(sharePremium);
      setNominalValue(value);
    } else {
      newPPS = newValue + parseFloat(nominalValue);
      setSharePremium(value);
    }
    setPPS(newPPS);
  };

  return (
    <>
      <div className='operation-form-shares'>
        <div className='mb-lb'>
          <div className='row'>
            <div className='col-xs-12 col-md-6'>
              <InputSelect
                options={captable.shareClasses}
                preText={literals.shareClass}
                value={shareClass}
                onChange={v => setShareClass(v)}
                isDisabled={!editable}
                button={(
                  <Button
                    text={literalsCommon.create}
                    icon={PlusCircle}
                    onClick={() => setPopupShareClass(true)}
                  />
                )}
              />
            </div>
            <div className='col-xs-12 col-md-6'>
              <InputNumber preText={literals.number} value={number} onChange={v => handleChangeNumber(v ? parseInt(v, 10) : 0)} minValue={1} isDisabled={!editable} decimals={0} />
            </div>
            <div className='col-xs-12 col-md-4'>
              <InputNumber preText={literals.nominalValue} minValue={0} value={nominalValue} onChange={v => handleChangeValue('nominalValue', v)} isDisabled={!editable} symbol={currency.symbol} step='any' decimals={10} />
            </div>
            <div className='col-xs-12 col-md-4'>
              <InputNumber preText={literals.sharePremium} minValue={0} value={sharePremium} onChange={v => handleChangeValue('sharePremium', v)} isDisabled={!editable} symbol={currency.symbol} step='any' decimals={10} />
            </div>
            <div className='col-xs-12 col-md-4'>
              <OutputValue preText={literalsCommon.total} value={formatNumber(number * pps, 0, { dec: 2 })} symbol={currency.symbol} />
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-4'>
              <InputSelect
                preText={literals.automaticNumeration}
                value={autoNumeration}
                onChange={(v) => {
                  setAutoNumeration(v);
                  setNumeration({
                    from: !v ? lastNumeration + 1 : 0,
                    to: !v ? lastNumeration + number : 0,
                  });
                }}
                options={[
                  { id: true, name: literalsCommon.yes },
                  { id: false, name: literalsCommon.no },
                ]}
                isDisabled={!editable}
              />
            </div>
            { !autoNumeration && (
              <>
                <div className='col-xs-12 col-md-2'>
                  <InputNumber preText={`#${literals.from}`} minValue={1} value={numeration.from} onChange={v => handleChangeFrom(v ? parseInt(v, 10) : 0)} isDisabled={!editable} decimals={0} />
                </div>
                <div className='col-xs-12 col-md-2'>
                  <InputNumber preText={`#${literals.to}`} value={numeration.to} onChange={null} decimals={0} isDisabled />
                </div>
              </>
            )}
            {/* {valuation && (
              <div className='col-12 col-md-4'>
                <InputSelect
                  preText={literals.preMoneyValuation}
                  value={preMoneyValuation.show}
                  onChange={show => setPreMoneyValuation(prev => ({ ...prev, show }))}
                  options={[
                    { id: false, name: literalsCommon.auto },
                    { id: true, name: literalsCommon.input },
                  ]}
                  isDisabled={!editable}
                />
              </div>
            )}
            { valuation && preMoneyValuation.show && (
              <div className='col-xs-12 col-md-4'>
                <InputNumber
                  preText={literals.preMoneyValue}
                  value={preMoneyValuation.value}
                  onChange={value => setPreMoneyValuation(prev => ({ ...prev, value }))}
                  isDisabled={!editable}
                  decimals={0}
                />
              </div>
            )} */}
            {
              conflict && (
                <div className='col-12 col-md-4'>
                  <InputSelect
                    preText={literals.updateValuation}
                    value={valuation}
                    onChange={v => setValuation(v)}
                    options={[
                      { id: true, name: literalsCommon.yes },
                      { id: false, name: literalsCommon.no },
                    ]}
                    isDisabled={!editable}
                  />
                </div>
              )}
          </div>
        </div>
        <div className='mb-sp'>
          <ShareholdersAssignments
            id={id}
            number={number}
            numeration={numeration}
            pps={pps}
            captable={captable}
            match={match}
            dateOrID={dateOrID}
            currency={currency}
            literals={literals}
            literalsCommon={literalsCommon}
            assignments={assignments}
            onChange={v => setAssignments(v)}
            editable={editable}
            forceAuto={autoNumeration}
          />
        </div>
        {
          editable && (
            <div className='buttons'>
              <Button text={literalsCommon.confirm} onClick={handleConfirm} />
              {
                data.create?.id && (
                  <Button text={literalsCommon.delete} color='danger' onClick={() => onDelete(data.create.id)} />
                )
              }
            </div>
          )
        }
      </div>
      {
        (popupShareClass) && (
          <PopupFormShareClass
            captable={captable?.selected?.id}
            onClose={() => setPopupShareClass(false)}
            onSubmit={sc => setShareClass(sc.id)}
          />
        )
      }
    </>
  );
};

FormShares.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  lastNumeration: PropTypes.number.isRequired,
  currency: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  data: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  dateOrID: PropTypes.string,
  conflict: PropTypes.bool,
};

FormShares.defaultProps = {
  dateOrID: formatDate(new Date(), { format: 'Y-m-d' }),
  editable: true,
  conflict: false,
};

export default FormShares;
