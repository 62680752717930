import StartupsInstance from 'modules/startups/repository';
import PermissionsInstance from 'modules/permissions/repository';
import { formatErrors } from 'utils/functions';

export function fetchElementPermission(scope, element) {
  return PermissionsInstance.getElementPermissions(scope.type, scope.id, element.type, element.id)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw formatErrors(error);
    });
}

export function editElementPermission(scope, element, permission) {
  return PermissionsInstance.editElementPermissions(scope.type, scope.id, element.type, element.id, { permission })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw formatErrors(error);
    });
}

export function fetchScopeMembers(scope, search, filters) {
  const {
    exclude,
    include,
    type,
    ...otherFilters
  } = filters;

  return PermissionsInstance.getScopeMembers(scope.type, scope.id, {
    search, filters: otherFilters, exclude, include, type,
  });
}

export function getUserGroups(id, user) {
  return StartupsInstance.getUserGroups(id, user);
}

// Permission Group
export function getPermissionGroup(scope, id) {
  return PermissionsInstance.getPermissionGroup(scope.type, scope.id, id);
}

export function createPermissionGroup(scope, data) {
  return PermissionsInstance.createPermissionGroup(scope.type, scope.id, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw formatErrors(error);
    });
}

export function editPermissionGroup(scope, id, data) {
  return PermissionsInstance.editPermissionGroup(scope.type, scope.id, id, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw formatErrors(error);
    });
}

export function deletePermissionGroup(scope, id) {
  return PermissionsInstance.deletePermissionGroup(scope.type, scope.id, id)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw formatErrors(error);
    });
}

export function addUserToGroup(scope, id, user) {
  return PermissionsInstance.addUserToGroup(scope.type, scope.id, id, user)
    .then(response => response)
    .catch((error) => {
      throw formatErrors(error);
    });
}

export function removeUserFromGroup(scope, id, user) {
  return PermissionsInstance.removeUserFromGroup(scope.type, scope.id, id, user)
    .then(response => response)
    .catch((error) => {
      throw formatErrors(error);
    });
}
