/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import InputText from 'components/Inputs/inputText';
import InputNumber from 'components/Inputs/inputNumber';
import InputSelect from 'components/Inputs/inputSelect';
import Table from 'components/Table';
import Button from 'components/Buttons/Button';
import Collapse from 'components/Collapse/Collapse';
import { UserPlus, Trash } from 'lucide-react';
import { getShareHolders } from 'routes/Captable/components/routes/shareHolders/modules/actions';
import './styles.scss';
import Loading from 'components/Loading';
import { useCounter } from 'utils/customHooks';

const CollapsePayouts = (props) => {
  const {
    literals,
    literalsCommon,
    match,
    currency,
    data,
    setData,
    isDisabled,
  } = props;

  const [shareholders, setShareholders] = useState(false);
  const tableRefreshFlag = useCounter(0);
  const scrollRef = useRef();

  useEffect(() => {
    (async () => {
      const dataShareholders = await getShareHolders(match.params.id, 0, 0);
      const auxShareholders = {};
      dataShareholders.items.forEach((sh) => {
        const { id, firstname, lastname } = sh;
        auxShareholders[sh.id] = { id, firstname, lastname };
      });
      setShareholders(auxShareholders);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (index, field, value) => {
    const auxPayouts = [...data];
    if (field === 'shareholder') {
      auxPayouts[index][field] = shareholders[value];
    } else {
      auxPayouts[index][field] = value;
    }
    setData(prev => ({ ...prev, payouts: auxPayouts }));
  };

  const handleAddRow = () => {
    const auxData = [...data];
    auxData.push({
      index: auxData.length, shareholder: null, number: null, notes: '',
    });
    setData(prev => ({ ...prev, payouts: auxData }));
    tableRefreshFlag.increase();
  };

  const handleDeleteRow = (i) => {
    const auxPayouts = [...data];
    auxPayouts.splice(i, 1);
    setData(prev => ({ ...prev, payouts: auxPayouts.map((p, index) => ({ ...p, index })) }));
    tableRefreshFlag.increase();
  };

  const payoutsColumns = [
    {
      field: 'shareholder',
      label: literals.shareholder,
      grow: 2,
      minWidth: '250px',
      preRender: (v, row) => (
        <InputSelect
          className='m-0 w-100'
          value={v?.id}
          onChange={val => handleChange(row.index, 'shareholder', val)}
          options={Object.values(shareholders).map(sh => ({ id: sh.id, name: `${sh.firstname} ${sh.lastname}` }))}
          scrollRef={scrollRef}
          isDisabled={isDisabled}
        />
      ),
    },
    {
      field: 'number',
      label: literals.number,
      grow: 1,
      minWidth: '150px',
      preRender: (v, row) => (
        <InputNumber
          className='m-0'
          value={v}
          symbol={currency.symbol}
          onBlur={nv => handleChange(row.index, 'number', nv)}
          state
          stateTimeout={600}
          isDisabled={isDisabled}
        />
      ),
    },
    {
      field: 'notes',
      label: literalsCommon.notes,
      grow: 3,
      minWidth: '350px',
      preRender: (v, row) => (
        <InputText
          className='m-0'
          value={v}
          onChange={val => handleChange(row.index, 'notes', val)}
          isDisabled={isDisabled}
        />
      ),
    },
  ];

  if (!isDisabled) {
    payoutsColumns.push(
      {
        field: 'index',
        type: 'button',
        button: {
          icon: Trash,
          text: literalsCommon.delete,
          onClick: row => handleDeleteRow(row.index),
        },
      },
    );
  }

  const fetchData = useCallback(() => {
    return { items: data, total: data.length };
  }, [tableRefreshFlag.value]);

  if (shareholders === false) {
    return <Loading hide={false} mode='panel' />;
  }

  return (
    <div ref={scrollRef}>
      <Collapse
        className='payouts-collapse'
        title={`${literals.payouts} (${data.length})`}
        state={false}
      >
        <Table
          key='table_payouts'
          searcher={false}
          columns={payoutsColumns}
          fetch={fetchData}
          forceFetch={tableRefreshFlag.value}
          fetchLocal
        />
        {!isDisabled && (
          <Button
            className='ml-3'
            color='secondary'
            icon={UserPlus}
            text={literals.addShareholder}
            onClick={handleAddRow}
          />
        )}
      </Collapse>
    </div>
  );
};

CollapsePayouts.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default CollapsePayouts;
