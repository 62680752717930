import FoldersInstance from 'modules/folders/repository';
import DocumentsInstance from 'modules/documents/repository';
import LinksInstance from 'modules/links/repository';
import { apiDownloadFile } from 'modules/actions';
import { formatErrors } from 'utils/functions';

export function createFolder(type, id, data) {
  return FoldersInstance.createFolder(type, id, data); // data = {name, parent}
}

export function deleteFolder(type, id, folder, confirm) {
  return FoldersInstance.deleteFolder(type, id, folder, { confirm });
}

export function editFolder(type, id, folder, data) {
  return FoldersInstance.editFolder(type, id, folder, data); // data = {name, parent}
}

export function getFolder(type, id, folder) {
  return FoldersInstance.getFolder(type, id, folder);
}

export function getFolderContents(type, id, folder, opts) {
  switch (folder) {
    case 'recent':
      return FoldersInstance.getRecentFolder(type, id, opts);
    case 'bookmark':
      return FoldersInstance.getBookmarkFolder(type, id, opts);
    default:
      return FoldersInstance.getFolderContents(type, id, folder, opts);
  }
}

export function addBookmark(type, id, element) {
  return FoldersInstance.postBookmarkContent(type, id, { element });
}

export function deleteBookmark(type, id, element) {
  return FoldersInstance.deleteBookmarkContent(type, id, { element });
}

export async function uploadNewFile(file, _public, opts) {
  try {
    const data = await DocumentsInstance.postDocument(file, _public, opts);
    return data;
  } catch (error) {
    console.error(error);
    throw formatErrors(error);
  }
}

export function deleteDocument(id, opts = {}) {
  return DocumentsInstance.deleteDocument(id, opts);
}

export function editDocument(id, opts) {
  return DocumentsInstance.editDocument(id, opts);
}

export function getDocument(id) {
  return DocumentsInstance.getDocument(id);
}

export function getDocumentViews(id, opts) {
  return DocumentsInstance.getDocumentViews(id, opts);
}

export function postDocument(file, _public, opts) {
  return DocumentsInstance.postDocument(file, _public, opts);
}

export async function uploadLink(data) {
  return LinksInstance.postLink(data);
}

export async function editLink(id, data) {
  return LinksInstance.editLink(id, data);
}

export async function deleteLink(id) {
  return LinksInstance.deleteLink(id);
}

export async function downloadLink(link, name) {
  return apiDownloadFile(link, name, true);
}
