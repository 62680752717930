/* eslint-disable class-methods-use-this */

import ReactDOM from 'react-dom';
import React from 'react';
import KPISummary from './KPISummary';

export class KPI extends React.Component {
  // eslint-disable-next-line react/prop-types
  constructor({ data, api, config }) {
    super();
    this.api = api;
    this.config = config;
    this.data = data;

    this.container = undefined;
  }

  static get toolbox() {
    return {
      title: 'KPI',
      icon: "<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-bar-chart-line-fill' viewBox='0 0 16 16'><path d='M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2z' /></svg>",
    };
  }

  save() {
    return this.data;
  }

  render() {
    const {
      literalsPerformance, literalsCommon,
      lang, currency, match,
    } = this.config;

    if (!this.container) {
      this.container = document.createElement('div');

      ReactDOM.render(
        <KPISummary
          literals={literalsPerformance}
          literalsCommon={literalsCommon}
          lang={lang}
          currency={currency}
          match={match}
          data={this.data}
          setData={(v) => { this.data = v; }}
        />,
        this.container,
      );
    }
    return this.container;
  }
}
