import { connect } from 'react-redux';
import ShareholderInfo from '../components';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.shareholder,
    literalsCountries: state.i18n.literals.onboarding.countries,
    literalsDataroom: state.i18n.literals.dataRoom,
    literalsCommon: state.i18n.literals.common,
    literalsShareholder: state.i18n.literals.shareholder,
    currency: state.global.currency,
    user: state.session.user,
    lang: state.i18n.language,
    startup: state.global.startup,
    captable: state.captable,
  };
}

export default connect(mapStateToProps)(ShareholderInfo);
