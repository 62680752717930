
import React from 'react';
import { colorGraphDown, green } from 'constants/colors';
import { KPI_UNIT } from 'constants/kpis';
import { formatNumber } from 'utils/functions';
import { ChevronUp, ChevronDown } from 'lucide-react';

export const formatKpiValue = (num, kpi = {}, options = {}, currency = null) => {
  if (num === null || num === undefined) {
    return options && Object.keys(options).includes('empty') ? options.empty : '-';
  }
  const abbreviation = options?.abbreviation || false;
  const decimals = options?.decimals || (kpi.unit === KPI_UNIT.FLOAT ? 6 : 2);

  let number = Number(num);
  if (abbreviation) {
    const lookup = [
      { value: 1e3, symbol: 'K' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const absNum = Math.abs(number);
    const item = lookup.reverse().find(it => absNum >= it.value);
    number = formatNumber(item ? (absNum / item.value) : num, 0, { dec: decimals, symbol: item?.symbol || '' });
  } else {
    number = formatNumber(number, 0, { dec: decimals });
  }

  switch (kpi.unit) {
    case KPI_UNIT.CURRENCY:
      if (currency?.symbol) {
        number += currency.symbol;
      }
      break;
    case KPI_UNIT.PERCENT:
      number += '%';
      break;
    default:
      break;
  }

  return number;
};

export const formatKpiDiff = (difference, showArrow = true) => {
  let Icon = null;
  let classColor = '';
  if (difference !== 0) {
    if (difference > 0) {
      Icon = <ChevronUp strokeWidth={0} fill={green} />;
      classColor = green;
    } else {
      Icon = <ChevronDown strokeWidth={0} fill={colorGraphDown} />;
      classColor = colorGraphDown;
    }
  }

  return (
    <span className='kpi-diff' style={{ color: classColor }}>
      {showArrow ? Icon : null}
      {formatNumber(showArrow ? Math.abs(difference) : difference, 0, { dec: 2, symbol: '%' })}
    </span>
  );
};
