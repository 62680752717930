export const TOOL_LEVEL_VIEW = 'view';
export const TOOL_LEVEL_EDIT = 'edit';
export const TOOL_LEVEL_MANAGE = 'manage';

export const TOOL_PROFILE = 'profile';
export const TOOL_MENTORINGS = 'mentoring';
export const TOOL_USERS = 'users';
export const TOOL_PERFORMANCE = 'performance';
export const TOOL_DATA_ROOM = 'dataRoom';
export const TOOL_CAP_TABLE = 'capTable';
export const TOOL_MESSAGES = 'messages';
export const TOOL_REPORTING = 'reporting';
export const TOOL_MY_POSITION = 'myPosition';
export const TOOL_SERVICES = 'services';
export const TOOL_FUNDRAISE = 'fundraise';
export const TOOL_INVESTMENTS = 'investments';

export default {
  TOOL_LEVEL_VIEW,
  TOOL_LEVEL_EDIT,
  TOOL_LEVEL_MANAGE,
  TOOL_PROFILE,
  TOOL_MENTORINGS,
  TOOL_USERS,
  TOOL_MESSAGES,
};
