export default {
  es: {
    login: {
      title: 'Inicia sesión en tu cuenta',
      email: 'Email',
      password: 'Contraseña',
      login: 'Iniciar sesión',
      noAccount: '¿No tienes una cuenta?',
      questionNotRegistered: '¿No tienes cuenta? ',
      createAccount: 'Crear cuenta nueva',
      register: 'Si todavía no tienes cuenta puedes crearla entrando aqui',
      invalidLogin: 'Usuario o contraseña incorrectos',
      invalidToken: 'Token incorrecto',
      recoveryButton: '¿Has olvidado la contraseña?',
      recovery: {
        title: 'Recupera tu contraseña',
        description: 'Te enviaremos al email un link para que puedas volver a configurar tu contraseña',
        button: 'Enviar',
        successMessage: 'Si el email existe, te enviaremos un correo con las instrucciones para configurar tu nueva contraseña',
        back: 'Volver al login',
      },
      email_error: 'El email no tiene un formato válido',
      token: 'Token',
      tokenMsg: 'Introduzca el código que le hemos enviado a su correo electrónico',
    },
    legal: {
      home: 'Inicio',
    },
    tool: {
      startup: 'Inicio',
      mentor: 'Inicio',
      mentorings: 'Mentorías',
    },
    setPassword: {
      title: 'Establecer contraseña',
      description: 'Configura a continuación tú contraseña de acceso, debe tener al menos 10 caracteres e incluir mayúsculas y minúsculas',
      password: 'Contraseña',
      repeatPassword: 'Repite la contraseña',
      register: 'Confirmar',
      passwordRules: 'La contraseña debe tener al menos 10 caracteres y como mínimo una mayúscula y una minúscula',
      passwordEqualError: 'No se pueden utilizar contraseñas anteriores',
      nonRepeatedPassword: 'Las contraseñas introducidas no coinciden',
      invalidToken: 'El link no es válido, solicite uno nuevo',
      passwordSuccess: 'Tu contraseña se ha guardado correctamente, inicia sesión a continuacion para acceder a la plataforma',
    },
    signup: {
      title: 'Registrarse',
      description: 'Próximamente',
      firstname: 'Nombre',
      lastname: 'Apellidos',
      email: 'Correo',
      language: 'Idioma',
      role: 'Rol',
      signup: 'Registrarse',
      createAccount: 'Crear cuenta',
      incorrectEmail: 'El email introducido no es válido',
      haveAnAccount: 'Ya tengo una cuenta',
      doLogin: 'Accede con ella',
      questionRegistered: '¿Ya tienes cuenta? ',
      es: 'Español',
      en: 'Inglés',
      it: 'Italiano',
      mentor: 'Mentor',
      user: 'Usuario',
      notFound: 'El registro no esta habilitado',
      goToLogin: 'Ir al login',
      signupSuccess: 'Cuenta creada correctamente, en breve recibirás un email con las instrucciones de acceso',
      signupError: 'Revisa tus datos y asegúrate que no tengas ya una cuenta creada',
      socialSignupError: 'Revisa que hayas dado permiso para saber tú email e inténtalo de nuevo',
      or: 'o',
    },
    downloadFile: {
      unauthenticated: 'Inicia sesión para descargar el archivo',
      noPermissions: 'No tienes permisos para descargar el archivo',
      noExists: 'No existe el archivo',
      login: 'Iniciar sesión',
      downloadingFile: 'Descargando archivo',
      downloadedFile: 'Fichero descargado',
      genericError: 'Se ha producido un error, confirma que la URL es la correcta e inténtalo de nuevo',
    },
    topBar: {
      account: 'Cuenta / Perfil',
      settings: 'Ajustes',
      logout: 'Salir',
      loading: 'Cargando',
      noNotifications: 'No tienes notificaciones',
      markAsRead: 'Marcar como leída',
      markAsUnread: 'Marcar como no leída',
      delete: 'Borrar',
      help: 'Ayuda',
      addCompany: 'AÑADIR COMPAÑÍA',
      prehome: 'Inicio',
      pushNotifications: 'Notificaciones push',
      viewMyPosition: 'Ver mi posición',
      youAreImpersonating: 'Estas impersonando',
      notifications: {
        all: 'Todas',
        unread: 'No leídas',
        mentoring: {
          mentoringInviteMentor: '%FROM_NAME% ha solicitado iniciar una mentoría contigo',
          mentoringInviteStartup: '%FROM_NAME% ha solicitado iniciar una mentoría con %STARTUP_NAME%',
          mentoringAccepted: '%FROM_NAME% ha aceptado la solicitud de mentoría',
          mentoringRejected: '%FROM_NAME% ha rechazado la solicitud de mentoría',
          mentoringFinished: '%FROM_NAME% ha dado por finalizada la mentoría',
          mentoringMessage: 'Nuevo mensaje de %FROM_NAME% en la mentoría',
          mentoringMessages: '%COUNT% mensajes nuevos de %FROM_NAME% en la mentoría',
          mentoringMeeting: '%FROM_NAME% ha registrado una nueva reunión',
          mentoringMeetingUpdate: '%FROM_NAME% ha actualizado una reunión',
          mentoringMeetingDelete: '%FROM_NAME% ha borrado una reunión',
        },
        startup: {
          invite: '%FROM_NAME% te ha invitado a unirte a %STARTUP_NAME%',
          inviteAccepted: '%USER_NAME% ha aceptado unirse a %STARTUP_NAME%',
          inviteRejected: '%USER_NAME% ha rechazado unirse a %STARTUP_NAME%',
        },
        acceptPermissions: 'Acepta los permisos del navegador',
        notificationsActivated: 'Notificaciones activadas',
        notificationsActivatedMessage: 'A partir de ahora recibirás las notificaciones en el navegador',
        notificationsDeactivated: 'Notificaciones desactivadas',
        notificationsDeactivatedMessage: 'A partir de ahora dejaras de recibir notificaciones en el navegador',
      },
    },
    leftMenu: {
      summary: 'Inicio',
      myPosition: 'Mi posición',
      capTable: 'Cap table',
      fundraise: 'Recaudar fondos',
      funds: 'Fondos',
      performance: 'Rendimiento',
      mentoring: 'Mentoría',
      mentorings: 'Mentorías',
      reporting: 'Reportes',
      services: 'Servicios',
      dataRoom: 'Documentos',
      logs: 'Logs',
      webhooks: 'Webhooks',
      scripts: 'Scripts',
      crons: 'Tareas Cron',
      settings: 'Configuración',
      userAccess: 'Usuarios',
      companies: 'Compañías',
      platform: 'Plataforma',
      profile: 'Perfil',
      help: 'Ayuda',
      analytics: 'Analítica',
      emailLogs: 'Logs email',
      profileStartup: 'Startup',
      capTableSummary: 'Resumen',
      capTableSecurities: 'Valores',
      capTableOperations: 'Operaciones',
      capTableTransactions: 'Transacciones',
      capTableShareholders: 'Inversores',
      capTableVestingPlans: 'Planes de adquisición',
      capTableShareClasses: 'Tipos de acciones',
      capTableWaterfall: 'Waterfall',
      addCompany: 'AÑADIR COMPAÑÍA',
      portfolio: 'Portafolio',
      analysis: 'Análisis',
      opportunities: 'Oportunidades',
      permissions: 'Permisos',
    },
    dialog: {
      accept: 'Aceptar',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
    },
    permissions: {
      permissions: 'Permisos',
      addPersonOrGroup: 'Añadir persona o grupo',
      addPerson: 'Añadir persona',
      addGroup: 'Añadir grupo',
      inputPersonOrGroup: 'Introduce el nombre de la persona o grupo',
      inputGroupName: 'Introduce el nombre de el grupo',
      members: 'Miembros',
      usersWithAccess: 'Usuarios con acceso',
      groupsWithAccess: 'Grupos con acceso',
      noPermissionsToSeeMembers: 'No tienes permisos para ver los miembros',
      group: 'Grupo',
      groups: 'Grupos',
      users: 'Usuarios',
      manage: 'Administar',
      owner: 'Propietario',
      removePermissions: 'Quitar permisos',
      createGroup: 'Crear grupo',
      editGroup: 'Editar grupo',
      editUserPermissions: 'Editar permisos de usuario',
      giveMorePermissions: 'Dar mas permissions',
      permissionPrevailsInfo: 'Se toma en cuenta el permiso de mayor jerarquía entre los definidos por los grupos y el del usuario.',
      searchUsers: 'Buscar usuarios',
      searchGroups: 'Buscar grupos',
      searchUsersAndGroups: 'Buscar usuarios y grupos',
      noUsers: 'No hay usuarios todavia',
      noGroups: 'No hay grupos todavia',
      private: 'Privado',
      privacity: 'Privacidad',
      sharedWith: 'Compartido con',
      empty: 'No hay elementos',
      ifNotPrivate: 'Si no es privado, se compartirá con todos los usuarios en funcion de los permisos sobre la herramienta',
      sharedNo: 'Sin compartir',
      itsPrivate: 'Es privado',
      sharedUsers: 'Compartido con %users% usuarios',
      sharedGroups: 'Compartido con %groups% grupos',
      sharedBoth: 'Compartido con %users% usuarios y %groups% grupos',
      privateDetails: 'Privado: Solo usuarios con permisos pueden acceder a este elemento',
      restrictedDetails: 'Restringido: Todos los usuarios con acceso a la herramienta pueden acceder a este elemento',
      publicDetails: 'Público: Todos los usuarios pueden acceder a este elemento',
      public: 'Publico',
      restricted: 'Restringido',
      cantShareExternal: 'Este recurso es privado dado que no tienes acceso a la startup, unicamente podrás verlo tú',
      cantShareUnauthorized: 'Este recurso es privado dado que no tienes suficientes permisos en la herramienta, unicamente podrás verlo tú',
      shareWithManagers: 'Compartir con los manager si soy aceptado en la startup',
      tools: {
        capTable: 'Cap table',
        performance: 'Rendimiento',
        dataRoom: 'Documentos',
        profile: 'Perfil',
        mentoring: 'Mentorias',
        reporting: 'Reportes',
        users: 'Gestor de usuarios',
        messages: 'Mensajes',
        services: 'Servicios',
        fundraise: 'Recaudar fondos',
      },
      levels: {
        0: 'Sin permisos',
        1: 'Ver',
        2: 'Editar',
        3: 'Administrar',
      },
      defaultGroups: {
        owner: 'Dueños (por defecto)',
        admin: 'Administadores (por defecto)',
        user: 'Usuarios (por defecto)',
        shareholder: 'Inversores (por defecto)',
        other: 'Otros (por defecto)',
        startup: 'Usuarios de la startup (por defecto)',
        ESOPs: 'ESOPs (por defecto)',
      },
    },
    signature: {
      uploadDocumentToBeSigned: 'Subir documento a firmar',
      subject: 'Asunto',
      message: 'Mensaje',
      signatureStatus: 'Estado de la firma',
      downloadDocument: 'Descargar documento',
      downloadAudit: 'Descargar auditoría',
      signatureZone: 'Zona de la firma',
      topLeft: 'Arriba - Izquierda',
      topRight: 'Arriba - Derecha',
      bottomLeft: 'Abajo - Izquierda',
      bottomRight: 'Abajo - Derecha',
      sendTo: 'Enviar a',
      status: {
        email_processed: 'El correo electrónico ha sido procesado',
        email_delivered: 'El correo electrónico ha sido entregado',
        email_opened: 'El correo electrónico ha sido abierto',
        email_bounced: 'El servidor no puede entregar el mensaje. Los rebotes a menudo son causados por direcciones de correo electrónico desactualizadas o incorrectas.',
        email_deferred: 'El correo electrónico no puede ser entregado de inmediato, pero no ha sido completamente rechazado. A veces se le llama rebote suave y se volverá a intentar durante 72 horas.',
        reminder_email_processed: 'El correo electrónico de recordatorio ha sido procesado',
        reminder_email_delivered: 'El correo electrónico de recordatorio ha sido entregado',
        reminder_email_opened: 'El correo electrónico de recordatorio ha sido abierto',
        sms_processed: 'El SMS ha sido procesado',
        sms_delivered: 'El SMS ha sido entregado',
        password_sms_processed: 'El SMS de contraseña ha sido procesado',
        password_sms_delivered: 'El SMS de contraseña ha sido entregado',
        document_opened: 'El documento ha sido abierto',
        document_signed: 'El documento ha sido firmado',
        document_completed: 'El documento ha sido completado y está listo para ser descargado',
        audit_trail_completed: 'El registro de auditoría ha sido completado y está listo para ser descargado',
        document_declined: 'El documento ha sido rechazado',
        document_expired: 'El documento ha expirado',
        document_canceled: 'El documento ha sido cancelado',
        photo_added: 'El firmante ha adjuntado una foto en el proceso',
        voice_added: 'El firmante ha adjuntado un audio en el proceso',
        file_added: 'El firmante ha adjuntado un archivo en el proceso',
        photo_id_added: 'El firmante ha adjuntado una identificación con foto en el proceso',
        terms_and_conditions_accepted: 'El firmante ha aceptado los términos y condiciones',
      },
    },
    onboarding: {
      welcome: 'Bienvenido a ',
      subtitle: 'Desde esta plataforma podrás conectar con mentores que te podrán ayudar a impulsar tu proyecto',
      subtitleMentor: 'Desde esta plataforma podrás conectar con startups para ayudarlas a impulsar su proyecto',
      subtitlefund: '',
      letsStart: 'Comencemos :)',
      companyData: 'Datos de la startup',
      mentorData: 'Datos del mentor',
      fundData: 'Datos del fondo',
      presentation: 'Presentación',
      preferences: 'Preferencias',
      companyName: 'Nombre de la compañía',
      name: 'Nombre',
      startupName: 'Nombre de la startup',
      externalId: 'ID externo',
      taxid: 'Tax ID',
      url: 'URL',
      type: 'Tipo',
      select: 'Selecciona',
      sectors: 'Sectores',
      describeWhatYourCompanyDoes: 'Describe lo que hace tu startup',
      inviteColleages: 'Invitar a los compañeros',
      back: 'Atrás',
      headline: 'Bio',
      whatDoYouSpendYourTimeOn: '¿A qué dedica su tiempo? CEO, COO, ... Inversor, Business angel, ...',
      company: 'Empresa',
      country: 'País',
      city: 'Ciudad',
      legalForm: 'Forma jurídica',
      legalName: 'Nombre legal',
      constitutionDate: 'Fecha de constitución',
      hoursToDonate: 'Horas de donación',
      hours: 'Horas',
      knowledgeSector: 'Sector/es',
      knowledgeSectorMentor: 'Sector/es de conocimiento (en las que puedes ayudar)',
      areasOfExpertise: 'Área/s',
      areasOfExpertiseMentor: 'Áreas de experiencia (en las que puedes ayudar)',
      businessTypes: 'Tipo de negocio',
      businessTypesMentor: 'Tipos de negocio (en las que puedes ayudar)',
      advancedMaterials: 'Materiales avanzados',
      advancedMaterialsMentor: 'Materiales avanzados (donde puedes ayudar)',
      agrotech: 'Agrotech',
      api: 'API',
      artificialIntelligence: 'Inteligencia Artificial',
      augmentedReality: 'Realidad Aumentada',
      autonomousMachines: 'Máquinas autónomas',
      bigData: 'Big Data',
      blockchain: 'Blockchain',
      businessIntelligence: 'Inteligencia empresarial',
      cloud: 'Cloud',
      communities: 'Comunidades',
      connectivity: 'Conectividad',
      consumerGoods: 'Bienes de consumo',
      crm: 'CRM',
      crowdsourcing: 'Crowdsourcing',
      customerService: 'Servicio al cliente',
      cybersecurity: 'Ciberseguridad',
      design: 'Diseño',
      developmentTools: 'Herramientas de desarrollo',
      domotics: 'Domótica',
      drones: 'Drones',
      eCommerce: 'Comercio electrónico (mercado de productos o servicios)',
      education: 'Educación',
      eHealth: 'eSalud',
      enablers: 'Habilitadores',
      engineering: 'Ingeniería',
      entertainment: 'Entretenimiento',
      erp: 'ERP',
      finTech: 'FinTech',
      fishing: 'Pesca',
      fitness: 'Fitness',
      games: 'Juegos',
      hospitality: 'Hospitalidad',
      hr: 'HR',
      'industry4.0': 'Industria 4.0',
      insurtech: 'Insurtech',
      intelligentMobility: 'Mobilidad inteligente',
      iot: 'Internet de las cosas',
      legalTech: 'Legaltech',
      lifestyle: 'Estilo de vida',
      location: 'Ubicación',
      logisticsAndTransportation: 'Logística y transporte (mercancías)',
      loyalty: 'Fidelidad',
      m2m: 'M2M',
      machineLearning: 'Machine learning',
      nanotechnology: 'Nanotecnología',
      'rv-ra-rm': 'RV-RA-RM',
      robotics: 'Robótica',
      others: '+ Otros',
      b2c: 'B2C',
      b2b: 'B2B',
      c2b: 'C2B',
      c2c: 'C2C',
      b2g: 'B2G',
      d2c: 'D2C',
      generalDirection: 'Dirección general',
      humanResources: 'Recursos humanos',
      FinanceAndAccounting: 'Finanzas y contabilidad',
      MarketingAndSales: 'Márketing y ventas',
      production: 'Producción',
      itTechnology: 'Informática y tecnología',
      innovation: 'Innovación',
      start: 'Comenzar!',
      previous: 'Anterior',
      next: 'Siguiente',
      uploadLogo: 'Sube tu logo',
      uploadAvatar: 'Sube tu avatar',
      logo: 'Logo',
      description: 'Descripción breve',
      avatar: 'Avatar',
      lastname: 'Apellidos',
      founders: 'Fundadores',
      foundedDate: 'Fecha de fundación',
      firstname: 'Nombre',
      see: 'Ver',
      modify: 'Modificar',
      delete: 'Borrar',
      users: 'Usuarios',
      searchStartup: 'Descubra si su startup ya está en nuestra base de datos, ingrese el nombre a continuación y en caso de que no exista podrá proceder a darla de alta con toda la información.',
      searchFund: 'Descubra si su fondo ya está en nuestra base de datos, ingrese el nombre a continuación y en caso de que no exista podrá proceder a darlo de alta con toda la información.',
      writeStartupName: 'Escribe el nombre de tu startup',
      writeFundName: 'Escribe el nombre de tu fondo',
      messageOwnerExist: 'La startup %STARTUP_NAME% ya tiene un propietario. Puedes solicitar acceso pulsado el botón inferior.',
      accessRequestSent: 'Solicitud de acceso enviada. El propietario de la startup %STARTUP_NAME% respondera a su solicitud lo antes posible.',
      accessRequestPending: 'Ya existe una solicitud de acceso a %STARTUP_NAME% pendiente. El propietario de la startup respondera a su solicitud lo antes posible.',
      accessRequestRejected: 'Lo siento, el propietario de la startup %STARTUP_NAME% a rechazado su solicitud de acceso.',
      request: 'Solicitar',
      requested: 'Solicitado',
      requestToAdmin: 'Solicitar a administrador',
      requestSentSuccessfully: 'Tu solicitud de acceso se ha enviado correctamente. Mientras tanto, puedes seguir navegando como invitado.',
      user_already_exist: 'Este usuario ya pertenece a la startup',
      request_already_exist: 'Ya existe una solicitud',
      verifyProperty: 'Verificar propiedad',
      requestAccess: 'Solicitar acceso',
      messageValidationRequired: 'Necesitamos verificar que la startup %STARTUP_NAME% es de su propiedad. Para ello tienes tres opciones: acceder con un email de la startup, realizar una verificacion de DNS o solicitar el acceso a un administrador de la plataforma.',
      messageValidationRequiredExternal: 'Necesitamos verificar que la startup %STARTUP_NAME% es de su propiedad. Para ello tienes dos opciones: acceder con un email de la startup o realizar una verificacion de DNS.',
      requestAccessToAdmin: 'No hemos podido verificar que la startup %STARTUP_NAME% sea de su propiedad. Solicita acceso a un administrador de la plataforma.',
      messageValidationDNS: 'Crear un registro DNS de tipo TXT en el dominio %DOMAIN% con valor "%DNS_VALUE%"',
      accessWithoutPermission: 'Continuar como invitado',
      retry: 'Reintentar',
      couldNotVerify: 'No se ha podido verificar',
      deleteStartup: 'Esta seguro que desea borrar la startup? Si elimina la startup se eliminaran permanentemente todos los datos',
      fundManager: 'Nombre de la gestora',
      address: 'Direccion',
      managementCompanyCountry: 'País de la compañía gestora',
      managementCompanyAddress: 'Dirrección de la compañía gestora',
      fundRegulator: 'Regulador del fondo',
      subfund: 'Subfundo',
      lieAlreadyExist: 'El fondo que se intenta crear ya ha sido dado de alta y se encuentra en revisión',
      lieAlreadyExistVerified: 'Ya existe un fondo dado de alta con el mismo identificador',
      institutionType: {
        title: 'Tipo de institución',
        opened: 'Abierta',
        closed: 'Cerrada',
      },
      investmentPolicy: {
        title: 'Política de inversion',
        ventureCapital: 'Capital riesgo',
        privateEquity: 'Capital privado',
        corporateVentureCapital: 'Capital riesgo corporativo',
        realEstate: 'Inversion inmoviliaria',
        privateDebt: 'Deuda privada',
      },
      countries: {
        AFG: 'Afganistán',
        ALA: 'Åland',
        ALB: 'Albania',
        DZA: 'Argelia',
        ASM: 'Samoa Americana',
        AND: 'Andorra',
        AGO: 'Angola',
        AIA: 'Anguila',
        ATA: 'Antártida',
        ATG: 'Antigua y Barbuda',
        ARG: 'Argentina',
        ARM: 'Armenia',
        ABW: 'Aruba',
        AUS: 'Australia',
        AUT: 'Austria',
        AZE: 'Azerbaiyán',
        BHS: 'Bahamas',
        BHR: 'Baréin',
        BGD: 'Bangladés',
        BRB: 'Barbados',
        BLR: 'Bielorrusia',
        BEL: 'Bélgica',
        BLZ: 'Belice',
        BEN: 'Benín',
        BMU: 'Bermudas',
        BTN: 'Bután',
        BOL: 'Bolivia',
        BES: 'Bonaire, San Eustaquio y Saba',
        BIH: 'Bosnia y Herzegovina',
        BWA: 'Botsuana',
        BVT: 'Isla Bouvet',
        BRA: 'Brasil',
        IOT: 'Territorio Británico del Océano Índico',
        BRN: 'Brunéi',
        BGR: 'Bulgaria',
        BFA: 'Burkina Faso',
        BDI: 'Burundi',
        KHM: 'Camboya',
        CMR: 'Camerún',
        CAN: 'Canadá',
        CPV: 'Cabo Verde',
        CYM: 'Islas Caimán',
        CAF: 'República Centroafricana',
        TCD: 'Chad',
        CHL: 'Chile',
        CHN: 'China',
        CXR: 'Isla de Navidad',
        CCK: 'Islas Cocos',
        COL: 'Colombia',
        COM: 'Comoras',
        COG: 'República del Congo',
        COD: 'República Democrática del Congo',
        COK: 'Islas Cook',
        CRI: 'Costa Rica',
        CIV: 'Costa de Marfil',
        HRV: 'Croacia',
        CUB: 'Cuba',
        CUW: 'Curazao',
        CYP: 'Chipre',
        CZE: 'República Checa',
        DNK: 'Dinamarca',
        DJI: 'Yibuti',
        DMA: 'Dominica',
        DOM: 'República Dominicana',
        ECU: 'Ecuador',
        EGY: 'Egipto',
        SLV: 'El Salvador',
        GNQ: 'Guinea Ecuatorial',
        ERI: 'Eritrea',
        EST: 'Estonia',
        ETH: 'Etiopía',
        FLK: 'Islas Malvinas',
        FRO: 'Islas Feroe',
        FJI: 'Fiyi',
        FIN: 'Finlandia',
        FRA: 'Francia',
        GUF: 'Guayana Francesa',
        PYF: 'Polinesia Francesa',
        ATF: 'Tierras Australes y Antárticas Francesas',
        GAB: 'Gabón',
        GMB: 'Gambia',
        GEO: 'Georgia',
        DEU: 'Alemania',
        GHA: 'Ghana',
        GIB: 'Gibraltar',
        GRC: 'Grecia',
        GRL: 'Groenlandia',
        GRD: 'Granada',
        GLP: 'Guadalupe',
        GUM: 'Guam',
        GTM: 'Guatemala',
        GGY: 'Guernsey',
        GIN: 'Guinea',
        GNB: 'Guinea-Bisáu',
        GUY: 'Guyana',
        HTI: 'Haití',
        HMD: 'Islas Heard y McDonald',
        VAT: 'Vaticano, Ciudad del',
        HND: 'Honduras',
        HKG: 'Hong Kong',
        HUN: 'Hungría',
        ISL: 'Islandia',
        IND: 'India',
        IDN: 'Indonesia',
        IRN: 'Irán',
        IRQ: 'Irak',
        IRL: 'Irlanda',
        IMN: 'Isla de Man',
        ISR: 'Israel',
        ITA: 'Italia',
        JAM: 'Jamaica',
        JPN: 'Japón',
        JEY: 'Jersey',
        JOR: 'Jordania',
        KAZ: 'Kazajistán',
        KEN: 'Kenia',
        KIR: 'Kiribati',
        PRK: 'Corea del Norte',
        KOR: 'Corea del Sur',
        KWT: 'Kuwait',
        KGZ: 'Kirguistán',
        LAO: 'Laos',
        LVA: 'Letonia',
        LBN: 'Líbano',
        LSO: 'Lesoto',
        LBR: 'Liberia',
        LBY: 'Libia',
        LIE: 'Liechtenstein',
        LTU: 'Lituania',
        LUX: 'Luxemburgo',
        MAC: 'Macao',
        MKD: 'Macedonia del Norte',
        MDG: 'Madagascar',
        MWI: 'Malaui',
        MYS: 'Malasia',
        MDV: 'Maldivas',
        MLI: 'Malí',
        MLT: 'Malta',
        MHL: 'Islas Marshall',
        MTQ: 'Martinica',
        MRT: 'Mauritania',
        MUS: 'Mauricio',
        MYT: 'Mayotte',
        MEX: 'México',
        FSM: 'Micronesia',
        MAR: 'Marruecos',
        MDA: 'Moldavia',
        MCO: 'Mónaco',
        MNG: 'Mongolia',
        MNE: 'Montenegro',
        MSR: 'Montserrat',
        MOZ: 'Mozambique',
        MMR: 'Birmania',
        NAM: 'Namibia',
        NRU: 'Nauru',
        NPL: 'Nepal',
        NLD: 'Países Bajos',
        NCL: 'Nueva Caledonia',
        NZL: 'Nueva Zelanda',
        NIC: 'Nicaragua',
        NER: 'Níger',
        NGA: 'Nigeria',
        NIU: 'Niue',
        NFK: 'Isla Norfolk',
        MNP: 'Islas Marianas del Norte',
        NOR: 'Noruega',
        OMN: 'Omán',
        PAK: 'Pakistán',
        PLW: 'Palaos',
        PSE: 'Palestina',
        PAN: 'Panamá',
        PNG: 'Papúa Nueva Guinea',
        PRY: 'Paraguay',
        PER: 'Perú',
        PHL: 'Filipinas',
        PCN: 'Islas Pitcairn',
        POL: 'Polonia',
        PRT: 'Portugal',
        PRI: 'Puerto Rico',
        QAT: 'Catar',
        REU: 'Reunión',
        ROU: 'Rumania',
        RUS: 'Rusia',
        RWA: 'Ruanda',
        BLM: 'San Bartolomé',
        SHN: 'Santa Elena, Ascensión y Tristán de Acuña',
        KNA: 'San Cristóbal y Nieves',
        LCA: 'Santa Lucía',
        MAF: 'San Martín',
        SPM: 'San Pedro y Miquelón',
        VCT: 'San Vicente y las Granadinas',
        WSM: 'Samoa',
        SMR: 'San Marino',
        STP: 'Santo Tomé y Príncipe',
        SAU: 'Arabia Saudita',
        SEN: 'Senegal',
        SRB: 'Serbia',
        SYC: 'Seychelles',
        SLE: 'Sierra Leona',
        SGP: 'Singapur',
        SXM: 'San Martín',
        SVK: 'Eslovaquia',
        SVN: 'Eslovenia',
        SLB: 'Islas Salomón',
        SOM: 'Somalia',
        ZAF: 'Sudáfrica',
        SGS: 'Islas Georgias del Sur y Sandwich del Sur',
        SSD: 'Sudán del Sur',
        ESP: 'España',
        LKA: 'Sri Lanka',
        SDN: 'Sudán',
        SUR: 'Surinam',
        SJM: 'Svalbard y Jan Mayen',
        SWZ: 'Suazilandia',
        SWE: 'Suecia',
        CHE: 'Suiza',
        SYR: 'Siria',
        TWN: 'Taiwán (República de China)',
        TJK: 'Tayikistán',
        TZA: 'Tanzania',
        THA: 'Tailandia',
        TLS: 'Timor Oriental',
        TGO: 'Togo',
        TKL: 'Tokelau',
        TON: 'Tonga',
        TTO: 'Trinidad y Tobago',
        TUN: 'Túnez',
        TUR: 'Turquía',
        TKM: 'Turkmenistán',
        TCA: 'Islas Turcas y Caicos',
        TUV: 'Tuvalu',
        UGA: 'Uganda',
        UKR: 'Ucrania',
        ARE: 'Emiratos Árabes Unidos',
        GBR: 'Reino Unido',
        USA: 'Estados Unidos',
        UMI: 'Islas Ultramarinas Menores de los Estados Unidos',
        URY: 'Uruguay',
        UZB: 'Uzbekistán',
        VUT: 'Vanuatu',
        VEN: 'Venezuela',
        VNM: 'Vietnam',
        VGB: 'Islas Vírgenes Británicas',
        VIR: 'Islas Vírgenes de los Estados Unidos',
        WLF: 'Wallis y Futuna',
        ESH: 'República Árabe Saharaui Democrática',
        YEM: 'Yemen',
        ZMB: 'Zambia',
        ZWE: 'Zimbabue',
      },
      language: 'Idioma',
      role: 'Rol',
      email: 'Email',
      languages: {
        es: 'Español',
        en: 'Inglés',
        it: 'Italiano',
      },
      mainCurrency: 'Divisa principal',
      mentorVisible: 'Disponibilidad para recibir nuevas solicitudes de mentoría',
      yes: 'Si',
      no: 'No',
      venture_capital: 'Capital de riesgo',
      funds_of_funds: 'Fondos de fondos',
      debt: 'Deuda',
      other: 'Otros',
      minPerProject: 'Inversion mínima por projecto',
      maxPerProject: 'Inversion máxima por projecto',
      phase: 'Fase',
      preseed: 'PRE-SEED',
      seed: 'SEED',
      early: 'EARLY',
      growth: 'GROWTH',
      expansion: 'EXPANSION',
      industries: 'Industrias',
      health: 'Salud',
      fintech: 'Fintech',
      food: 'Comida',
      media: 'Medios',
      marketing: 'Marketing',
      enterpriseSoftware: 'Software empresarial',
      transportation: 'Transporte',
      fashion: 'Moda',
      realEstate: 'Bienes raíces',
      homeLiving: 'Vida en el hogar',
      kids: 'Niños',
      energy: 'Energía',
      wellnessBeauty: 'Bienestar y belleza',
      sports: 'Deportes',
      hosting: 'Hospedaje',
      gaming: 'Juegos',
      travel: 'Viajes',
      semiconductors: 'Semiconductores',
      music: 'Música',
      eventTech: 'Tecnología de eventos',
      jobsRecruitment: 'Empleos y reclutamiento',
      security: 'Seguridad',
      legal: 'Legal',
      telecom: 'Telecomunicaciones',
      dating: 'Citas',
      serviceProvider: 'Proveedor de servicios',
      engineeringAndManufacturingEquipment: 'Equipos de ingeniería y fabricación',
      space: 'Espacio',
      consumerElectronics: 'Electrónica de consumo',
      chemicals: 'Químicos',
      subindustries: 'Subindustrias',
      healthPlatform: 'Plataforma de salud',
      biotechnology: 'Biotecnología',
      medicalDevices: 'Dispositivos médicos',
      pharmaceutical: 'Farmacéutica',
      financialManagementSolutions: 'Soluciones de gestión financiera',
      wealthManagement: 'Gestión de patrimonio',
      payments: 'Pagos',
      mortgagesAndLending: 'Hipotecas y préstamos',
      insurance: 'Seguros',
      cryptoAndDeFi: 'Cripto y DeFi',
      banking: 'Banca',
      regtech: 'Regtech',
      innovativeFood: 'Alimentos innovadores',
      agritech: 'Agritech',
      foodLogisticsAndDelivery: 'Logística y entrega de alimentos',
      inStoreRetailAndRestaurantTech: 'Tecnología para tiendas y restaurantes',
      kitchenAndCookingTech: 'Tecnología de cocina y cocina',
      contentProduction: 'Producción de contenido',
      publishing: 'Publicación',
      socialMedia: 'Redes sociales',
      streaming: 'Transmisión',
      adtech: 'Publicidad en línea (adtech)',
      cRMAndSales: 'CRM y ventas',
      ecommerceSolutions: 'Soluciones de comercio electrónico',
      marketingAnalytics: 'Analítica de marketing',
      logisticsAndDelivery: 'Logística y entrega',
      mobility: 'Movilidad',
      vehicleProduction: 'Producción de vehículos',
      searchBuyAndRent: 'Búsqueda, compra y alquiler',
      navigationAndMapping: 'Navegación y cartografía',
      maintenance: 'Mantenimiento',
      autonomousAndSensorTech: 'Tecnología autónoma y de sensores',
      apparel: 'Ropa',
      accessories: 'Accesorios',
      luxury: 'Lujo',
      footwear: 'Calzado',
      construction: 'Construcción',
      realEstateServices: 'Servicios inmobiliarios',
      realEstateSoftware: 'Software inmobiliario',
      workspaces: 'Espacios de trabajo',
      learningToolsAndResources: 'Herramientas y recursos de aprendizaje',
      educationProviders: 'Proveedores de educación',
      educationManagement: 'Gestión educativa',
      cleanEnergy: 'Energía limpia',
      energyEfficiency: 'Eficiencia energética',
      oilAndGas: 'Petróleo y gas',
      wasteSolution: 'Soluciones de residuos',
      water: 'Agua',
      energyProviders: 'Proveedores de energía',
      energyStorage: 'Almacenamiento de energía',
      sportPlatformAndApplication: 'Plataforma y aplicación deportiva',
      sportingEquipment: 'Equipamiento deportivo',
      sportLeagueAndClub: 'Ligas y clubes deportivos',
      sportMedia: 'Medios deportivos',
      sportSupplements: 'Suplementos deportivos',
      consoleAndPCGaming: 'Juegos de consola y PC',
      mobileGaming: 'Juegos móviles',
      bettingAndGambling: 'Apuestas y juegos de azar',
      esports: 'Deportes electrónicos',
      boardGames: 'Juegos de mesa',
      onlineTravelAgency: 'Agencia de viajes en línea',
      accommodation: 'Alojamiento',
      bookingAndSearch: 'Reservas y búsqueda',
      travelAnalyticsAndSoftware: 'Analítica y software de viajes',
      businessTravel: 'Viajes de negocios',
      publicSafety: 'Seguridad pública',
      cloudAndInfrastructure: 'Nube e infraestructura',
      dataProtection: 'Protección de datos',
      identityAndAccess: 'Identidad y acceso',
      deviceSecurityAndAntivirus: 'Seguridad de dispositivos y antivirus',
      consulting: 'Consultoría',
      incomeStreams: 'Flujos de ingresos',
      subscription: 'Suscripción',
      advertising: 'Publicidad',
      commission: 'Comisión',
      sellingOwnInventory: 'Venta de inventario propio',
      revenues: 'Ingresos',
      saas: 'SaaS',
      marketplaces: 'Mercados en línea y eCommerce',
      manufacturing: 'Fabricación (Productos físicos)',
      launchYear: 'Año de lanzamiento',
      growthStage: 'Etapa de crecimiento',
      clientFocus: 'Enfoque en el cliente',
      earlyGrowth: 'Crecimiento temprano',
      lateGrowth: 'Crecimiento tardío',
      mature: 'Maduro',
      shareWithFounder: 'Compartir con el fundador',
      inviteFounder: 'Invitar al fundador',
      invitationManageStartup: 'Invitacion a controlar la startup',
      invitationManageStartupMessage: 'Le han invitado a controlar la startup %STARTUP%',
      logout: 'Cerrar sesión',
    },
    mainpage: {
      wellcome: 'Bienvenido',
    },
    mentoring: {
      title: 'Mentorías',
      mentoring: 'Mentoría',
      mentorings: 'Mentorías',
      startups: 'Startups',
      mentors: 'Mentores',
      yourMentorings: 'Tus mentorías',
      searchMentors: 'Buscar mentores',
      searchStartups: 'Buscar startups',
      finished: 'Terminadas',
      pending: 'Invitaciones',
      seekingMentors: 'Buscar mentores',
      seekingStartups: 'Buscar startups',
      startMentoringsMentors: 'Comience a enviar solicitudes a los mentores para iniciar su proceso de tutoría.',
      startMentoringsStartups: 'Comience a enviar solicitudes a las startups para iniciar su proceso de tutoría.',
      consumerHours: 'Horas de consumo',
      nextMeeting: 'Próxima reunión',
      lastInteraction: 'Última interacción',
      knowledgeSectors: 'Sectores de conocimiento',
      confirm: 'Confirmar',
      accept: 'Aceptar',
      accepted: 'Aceptada',
      reject: 'Rechazar',
      rejected: 'Rechazada',
      enter: 'Entrar',
      moreInfo: 'Info',
      invite: 'Invitar',
      cancel: 'Cancelar',
      canceled: 'Cancelada',
      soon: 'Próximamente',
      letsGo: '¡Adelante!',
      invitationSent: 'Tu solicitud ha sido enviada',
      emptyInviteMessage: 'Debes escribir un mensaje',
      whatCanYouContribute: '¿Qué puedes aportar a una startup?',
      howCanTheMentorHelpYou: '¿Cómo puede ayudarte el mentor?',
      rejectMentoring: 'Rechazar la mentoría',
      confirms: {
        cancel: '¿Estas seguro de cancelar la mentoría?',
        accepted: '¿Estas seguro de aceptar la mentoría?',
        rejected: '¿Estas seguro de rechazar la mentoría?',
      },
      explainTheReasonTo: 'Explica el motivo a',
      invitationMessage: 'Mensaje de invitación:',
      rejectionReason: 'Motivo de rechazo:',
      lessInfo: 'Menos información',
      closeInvite: 'Cerrar invitar',
      filter: 'Filtros',
      recommended: 'Recomendada',
      noResults: 'No se han encontrado resultados',
      areasOfExpertise: 'Áreas de experiencia',
      preferredLanguage: 'Idioma preferido',
      businessType: 'Tipo de negocio',
      socialImpact: 'Impacto social',
      startupCountry: 'País de la startup',
      startupDevelopmentStage: 'Estapa de desarrollo de la startup',
      sectors: 'Sectores',
      companyAreas: 'Áreas de la compañía',
      resetFilters: 'Reiniciar filtros',
      applyFilters: 'Aplicar filtros',
      appliedFilters: 'Filtros aplicados',
      select: 'Seleccionar',
      search: 'Buscar',
      next: 'Siguente',
      events: 'Eventos',
      messages: 'Mensajes',
      documents: 'Documentos',
      reports: 'Reportes',
      addMessage: 'Escribir mensaje',
      createMeeting: 'Registrar evento',
      meetingTime: 'Horas consumidas',
      editMeeting: 'Editar evento',
      uploadDocument: 'Subir documento',
      emptyDocuments: 'No hay documentos',
      createReport: 'Crear reporte',
      editReport: 'Editar reporte',
      emptyReports: 'No hay reportes',
      finish: 'Finalizar mentoría',
      giveFeedback: 'Dar feedback',
      feedbackSent: 'Feedback enviado',
      finishButtonTitle: 'Finalizar mentoría y dar feedback',
      startedBy: 'Iniciada por',
      feedbackForm: {
        title: 'Feedback de la mentoría',
        titleEnd: 'Finalizar mentoría',
        description: '',
        descriptionEnd: '',
        reason: '¿Que opcion quieres hacer?',
        cancel: 'Cancelar la mentoría (no se ha llegado a completar/finalizar)',
        cancelReason: '¿Por qué?',
        finish: 'Terminar la mentoría (mentoría completada)',
        finishComment: '¿Que tal ha ido?',
        score: 'Valorala (5 estrellas es lo mejor)',
        send: 'Enviar',
      },
      reportForm: {
        popupTitle: 'Crear reporte',
        popupTitleEdit: 'Editar reporte',
        popupDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac dui convallis, suscipit quam sed, ornare felis. Mauris convallis ipsum ac iaculis lacinia. Quisqu',
        text: 'Realiza un informe de tu visión actual sobre el proyecto',
        send: 'Enviar',
      },
      mentoringStatus: 'Estado de la mentoria',
      status: {
        status: 'Estado',
        accepted: 'Aceptada',
        finished: 'Finalizada',
        pending: 'Pendiente',
        rejected: 'Rechazada',
        finish: 'Terminada',
        cancel: 'Cancelada',
      },
      infoPopUp: {
        info: 'Información de la mentoría',
        status: 'Estado',
        creationDate: 'Fecha de creación',
        finishDate: 'Fecha de finalización',
        creator: 'Creador',
        guest: 'Invitado',
        invitationMessage: 'Mensaje de invitación',
        rejectionReason: 'Motivo del rechazo',
      },
      edit: 'Editar',
      delete: 'Borrar',
      deleteReport: '¿Estas seguro de borrar el reporte?',
      numberMessages: 'Mensajes enviados',
      numberEvents: 'Eventos creados',
      numberDocuments: 'Documentos subidos',
      numberReports: 'Número de reportes',
      mentorNotVisible: 'Tienes configurado en tu perfil que no puedan solicitarte nuevas mentorias',
    },
    shareholder: {
      modify: 'Modificar',
      deleteShareholder: '¿Borrar shareholder?',
      shareholders: 'Inversores',
      cannotDeleteShareholder: 'No se puede borrar un inversor que esté involucrado en alguna operación',
      shareholdersCreated: 'inversores creados',
      downloadTemplate: 'Descargar plantilla',
      bulkShareholders: 'Subir inversores',
      inviteUser: 'Invitar usuario',
      unbindSyndicate: 'Desvincular sindicato',
      unbindSyndicateWarning: '¿Estás seguro de que quieres desvincular el sindicato?',
      bindingSyndicateError: 'Ha ocurrido un error al vincular/desvincular el syndicato',
      bindingError: 'Ha ocurrido un error al vincular/desvincular al usuario',
      email: 'Email',
      name: 'Nombre',
      lastname: 'Apellidos',
      permissions: 'Permisos',
      errorEmptyPerms: 'No has seleccionado ningun permiso',
      lang: 'Lenguaje',
      languages: {
        es: 'Español',
        en: 'Inglés',
        it: 'Italian',
      },
      createUser: 'Crear usuario',
      bindUser: 'Vincular usuario',
      unbindUser: 'Desvincular usuario',
      shareholderHaveShares: 'Si el inversor tiene acciones, el usuario tendrá permisos en:',
      summary: 'Inicio',
      summaryDescription: 'Resumen general de todas las herramientas',
      myPosition: 'Mi posición',
      myPositionDescription: 'Información de la inversión dentro de la Startup',
      capTable: 'Cap table',
      capTableDescription: 'Cap table',
      capTableSummary: 'Resumen',
      capTableSummaryDescription: 'Resumen del captable',
      capTableSecurities: 'Valores',
      capTableSecuritiesDescription: 'Ver detalles de la acciones, deuda y opciones',
      capTableOperations: 'Operaciones',
      capTableOperationsDescription: 'Ver el detalle de todas las operaciones confirmadas',
      performance: 'Rendimiento',
      performanceDescription: 'Ver los indicadores claves de rendimiento',
      reporting: 'Reportes',
      reportingDescription: 'Acceso a todos los reportes que ha recibido',
      dataRoom: 'Documentos',
      dataRoomDescription: 'Acceso a todos los documentos menos los de otros inversores',
      syndicates: 'Sindicatos',
      syndicate: 'Sindicato',
      infoSyndicate: 'Información sindicato',
      createSyndicate: 'Crear sindicato',
      editSyndicate: 'Editar sindicato',
      unifyShareholders: 'Unificar inversores',
      confirmUnifyShareholders: '¿Estas seguro que desea unificar los %SHAREHOLDERS% inversores en el inversor %SHAREHOLDER%?',
      founder: 'Fundador',
      director: 'Director',
      boardMember: 'Miembro de la Junta',
      investor: 'Inversor',
      employee: 'Empleado',
      secretary: 'Secretario',
      advisor: 'Consejero',
      bank: 'Banco',
      corporate: 'Corporate',
      angelInvestor: 'Angel investor',
      privateEquity: 'Private equity',
      ventureCapital: 'Venture capital',
      friendsAndFamily: 'Amigos y familiares',
      corporateVentureCapital: 'Corporate venture capital',
      other: 'Otro',
      downloadExcel: 'Descargar excel',
      formShareholder: 'Formulario de inversor',
      infoShareholder: 'Información del inversor',
      types: 'Tipos',
      role: 'Rol',
      individual: 'Individual',
      organization: 'Organizacion',
      firstname: 'Nombre',
      phone: 'Teléfono',
      address: 'Dirección',
      country: 'País',
      taxid: 'TAXID',
      save: 'Guardar',
      shares: 'Acciones',
      debts: 'Deudas',
      options: 'Opciones',
      pps: 'PPS',
      numeration: 'Numeración',
      shareClass: 'Clase',
      capital: 'Capital',
      contributedCapital: 'Capital Aportado',
      principal: 'Prestamo',
      capitalized: 'Capitalizado',
      repaid: 'Repagado',
      interestRate: 'Intereses',
      accrualFrequency: 'Devengo',
      issueDate: 'F. de Emisión',
      startDate: 'F. de inicio',
      expirationDate: 'F. de Expiración',
      number: 'Cantidad',
      assigned: 'Asignado',
      vested: 'Adquiridas',
      exercised: 'Ejercidas',
      pending: 'Pendientes',
      vestingPlan: 'Plan de adquisición',
      vestingDate: 'F. de adquisición',
      phantom: 'Phantom shares',
      stockOption: 'Opciones sobre acciones',
      since: 'Desde',
      partner: 'Inversor',
      transactions: 'Transacciones',
      users: 'Usuarios',
      documents: 'Documentos',
      captable: 'Cap Table',
      shareholder: 'Inversor',
      ownership: 'Propiedad',
      fullyDiluted: 'Dilución',
      historicalOperations: 'Historial de operaciones',
      usersLinked: 'Usuarios vinculados',
      avatar: 'Avatar',
      value: 'Valor',
      linkToMyUser: 'Vincular a mi usuario',
      unbindWarning: '¿Estás seguro de que quieres desvincular al usuario?',
      noParticipation: 'El inversor no tiene participación en el captable',
      from: 'Desde',
      to: 'Hasta',
      optionsIn: 'Opciones en',
      operation: 'Operacion',
      vote: 'Voto',
      status: 'Estado',
      closed: 'Cerrada',
      shareDetails: 'Detalles de la acción',
      debtDetails: 'Detalles de la deuda',
      optionDetails: 'Detalles de la opción',
      price: 'Precio',
      exercisePrice: 'Precio de conversión',
      ratio: 'Ratio de conversion',
      numberOfShares: 'Número de acciones',
      historial: 'Historial',
      paid: 'Pagado',
      debt: 'Deuda',
      interest: 'Intereses',
      addUser: 'Añadir usuario',
      invite: 'Invitar',
      emptyFields: 'Rellena todos los campos de los nuevos usuarios',
      errUserCreate: 'Error en la creacion del usuario con email:',
      investmentEvolution: 'Evolución de la inversión',
      debtsEvolution: 'Evolución de las deudas',
      optionsEvolution: 'Evolución de las opciones',
      noSyndicate: 'Sin sindicato',
      certificate: 'Certificado',
      newUser: 'Usuario nuevo',
      existingUser: 'Usuario existente',
    },
    vestingsPlans: {
      addVestingPlan: 'Añadir vestingPlan',
      name: 'Nombre',
      type: 'Tipo',
      createdAt: 'Creado en',
      questionDelete: '¿Borrar VestingPlan?',
      questionDeleteError: 'No se puede eliminar una plan de adquisicion con opciones',
      vestingsPlans: 'Planes de adquisicion',
      basic: 'Basico',
      time: 'Tiempo',
      milestones: 'Objetivos',
      hybrid: 'Hibrido',
      calendar: 'Calendario',
      shareholder: 'Inversor',
      confirmMilestones: 'Confirmar objetivos',
      showCalendar: 'Mostrar calendario',
      vestingPlanCalendar: 'Calendario del plan de adquisicion',
      title: 'Titulo',
      percentage: 'Porcentaje',
      addMilestone: 'Añadir Hito',
      period: 'Periodos',
      cliff: 'Cliff',
      intervals: 'Intervalos',
      day: 'Dia',
      month: 'Mes',
      year: 'Año',
      start: 'Inicio',
      startDate: 'F. Inicio',
      exact: 'Dia exacto',
      end: 'Final',
      length: 'Longitud',
      frequency: 'Frecuencia',
      schedule: 'Calendario',
      addTime: 'Añadir Periodo',
      periods: 'Periodos',
      overallPercentage: 'Porcentaje Total',
      save: 'Guardar',
      formVestingsPlans: 'Formulario de planes de adquisicion',
      infoVestingsPlans: 'Información de planes de adquisicion',
      capital: 'Capital',
      expirationDate: 'F. de Expiración',
      number: 'Cantidad',
      vested: 'Adquiridas',
      exercised: 'Ejercidas',
      phantom: 'Phantom shares',
      stockOption: 'Opciones sobre acciones',
      modifyWarning: 'Plan de adquisicion con opciones. Algunos campos no se pueden modificar',
    },
    shareClass: {
      deleteClass: '¿Eliminar clase?',
      deleteClassError: 'No se puede eliminar una clase con acciones',
      name: 'Nombre',
      code: 'Código',
      type: 'Tipo',
      modify: 'Modificar',
      delete: 'Eliminar',
      createClass: 'Crear clase',
      shareClasses: 'Clases de acciones',
      vote: 'Voto',
      unifyShareClasses: 'Unificar clases de acción',
      confirmUnifyShareClasses: '¿Estas seguro que desea unificar las %SHARECLASSES% clases de accion en la clase %SHARECLASS%?',
      preferred: 'Preferentes',
      common: 'Comun',
      downloadExcel: 'Descargar excel',
      save: 'Guardar',
      yes: 'Si',
      no: 'No',
      liquidity: 'Liquidez',
      participation: 'Participacion',
      dividend: 'Dividendo',
      convert: 'Convertible',
      ratio: 'Ratio',
      price: 'Precio',
      day: 'Dia',
      month: 'Mes',
      year: 'Año',
      cash: 'Efectivo',
      stock: 'Stock',
      value: 'Valor',
      frequency: 'Frecuencia',
      compounding: 'Composición',
      seniority: 'Antigüedad',
      multiple: 'Multiple',
      cap: 'Cap',
      FormShareClass: 'Formulario de clase de acciones',
      votesPerShare: 'Votos por acción',
      InfoShareClass: 'Informacion de clase de acciones',
      shares: 'Acciones',
      pps: 'PPS',
      numeration: 'Numeración',
      capital: 'Capital',
      phantom: 'Phantom shares',
      stockOption: 'Opciones sobre acciones',
      modifyWarning: 'Clase con acciones. Tenga en cuenta que cualquier cambio en los atributos de la clase tendrá efecto en el historico del captable.',
    },
    meetings: {
      confirmDelete: '¿Estas seguro de borrarlo?',
      createMeeting: 'Crear meeting',
      edit: 'Editar',
      delete: 'Borrar',
      meetingTime: 'Duración',
      emptyMeetings: 'No hay meetings',
      meetingForm: {
        popupTitle: 'Registrar evento',
        popupDescription: 'Rellena la información con los datos del evento/reunión que has realizado o vas a realizar y que previamente has pactado con tu mentor/mentorado para que quede constancia en la plataforma. Una vez guardado, podrás editar/eliminar le evento. TE RECORDAMOS QUE ESTO NO ES UNA INVITACIÓN DE UN EVENTO.',
        title: 'Título',
        description: 'Descripción',
        date: 'Fecha',
        hour: 'Hora',
        timezone: 'Zona horaria',
        time: 'Duración (minutos)',
        send: 'Enviar',
        submitError: 'Error en la creación/edición del evento',
      },
      yourHours: 'Tus horas',
      scheduledHours: 'Horas programadas',
      finishedHours: 'Horas realizadas',
      donatedHours: 'Horas donadas',
    },
    legalConditions: {
      title: 'Hemos actualizado nuestros términos',
      subTitle: 'Debes aceptar las nuevas condiciones antes de continuar.',
      actionBtn: 'Continuar',
    },
    prehome: {
      selectStartup: 'Selecciona una startup',
      createStartup: 'Nueva startup',
      viewStartup: 'Ver startup',
      accept: 'Aceptar',
      reject: 'Rechazar',
      confirmAccept: '¿Estas seguro de que quieres aceptar la invitacion?',
      confirmReject: '¿Estas seguro de que quieres rechazar la invitacion?',
      user: 'Usuario',
      mentor: 'Mentor',
      shareholder: 'Inversor',
      owner: 'Propietario',
      other: 'Otro',
      admin: 'Administrador',
      guest: 'Invitado',
      pendingApproval: 'Pendientes de aprobación',
      myStartups: 'Mis startups',
      viewMyPosition: 'Ver mi posición',
    },
    myInvestments: {
      title: 'Mi posición',
      portfolio: 'Portafolio',
      analysis: 'Análisis',
      opportunities: 'Oportunidades',
      base: 'Base',
      currencies: 'Monedas',
      investments: 'Inversiones',
      totalInvested: 'Total invertido',
      totalValue: 'Valor total',
      multiplo: 'Multiplo',
      country: 'Pais',
      currency: 'Moneda',
      industry: 'Industria',
      currentPortfolio: 'Porfolio actual',
      divestedPortfolio: 'Divested portfolio',
      ownership: 'Propiedad',
      cEquity: 'C.Acciones',
      cDebt: 'C.Deuda',
      options: 'Opciones',
      votes: 'Voto',
      noExchangeNecessary: 'Las divisas de tus inversiones son las mismas que la seleccionada',
      noResults: 'No eres inversor de ninguna startup',
      shares: 'Acciones',
      kpis: 'KPIs',
      shareholder: 'Inversor',
      knowMore: 'Conocer mas',
      round: 'Ronda',
      referredBy: 'Invitado por',
      'pre-seed': 'PRE-SEED',
      seed: 'SEED',
      early: 'EARLY',
      growth: 'GROWTH',
      expansion: 'EXPANSION',
      entryDate: 'F. entrada',
      comparator: 'Comparador',
      numberOfShares: 'Número de acciones',
    },
    input: {
      uploadFileError: 'El fichero no se pudo cargar',
      uploadFilezone: 'Haz click aquí para subir un fichero',
      uploadFile: 'Subir un fichero',
      noOptions: 'No se han encontrado opciones',
      enterToAdd: 'Pulsa ↵ para añadir',
      noneAbove: 'Ninguna de las anteriores',
      selected: 'Seleccionados',
      of: 'de',
      private: 'Privado',
      share: 'Compartir',
      sharedWith: 'Compartido con',
      users: 'usuarios',
    },
    outputErrors: {
      required_error: 'El campo es requerido',
      requiredwith_error: 'El campo es requerido para esa condicion',
      requiredunless_error: 'El campo es requerido',
      max_error: 'El campo supera el máximo',
      unauthorized_error: 'No tienes permisos para realizar esta acción',
      unauthorized: 'No tienes permisos para realizar esta acción',
      internal: 'Ha ocurrido un error inesperado',
    },
    home: {
      summary: 'Resumen',
      companyDetails: 'Detalles de la compañía',
      editCompany: 'Editar perfil',
      viewCompany: 'Ver perfil',
      name: 'Nombre',
      lastname: 'Apellidos',
      founders: 'Fundadores',
      operatingStatus: 'Estado operacional',
      active: 'Activo',
      foundedDate: 'Fecha de fundación',
      lastFundingType: 'Último tipo de inversión',
      headQuartersRegion: 'Región de la sucursal matriz',
      description: 'Descripción',
      mentors: 'Mentores',
      mentorsSubtitle: 'Estas son las mentorías activas de tu startup',
      noMentorings: 'No tienes ninguna mentoría activa',
      noMeetings: 'No tienes meetings programados',
      mentorDetails: 'Detalles del mentor',
      mentorMoreHours: 'Has superado el número de horas que indicaste que ibas a donar, si quieres continuar registrando reuniones es necesario que las aumentes.',
      mentorMoreHoursSoon: 'Pronto vas a superar el número de horas que indicaste que ibas a donar, si quieres continuar registrando reuniones es necesario que las aumentes.',
      mentorMoreHoursProfile: 'Ir al perfil',
      mentorMoreHoursCancel: 'Cancelar',
      editProfile: 'Editar perfil',
      mentoredCompanies: 'Startups mentorizadas',
      donatedHours: 'Horas donadas',
      donatedHoursLeft: 'Horas donadas restantes / total',
      events: 'Eventos',
      nextMeetings: 'Próximas reuniones',
      mentoringsEvents: 'Eventos mentorías',
      mentoringsList: 'Mentorías Activas',
      companyName: 'Nombre de la compañía',
      consumerHours: 'Horas de consumo',
      nextMeeting: 'Próxima reunión:',
      lastComments: 'Últimos comentarios',
      readMore: 'Leer más',
      documents: 'Documentos',
      lastDocuments: 'Últimos documentos',
      lastRound: 'Última ronda',
      totalFunding: 'Inversión total',
      preMoneyValuation: 'Valoración pre-dinero',
      postMoneyValuation: 'Valoración post-dinero',
      investorUpdate: 'Actualización del inversor',
      kpis: 'KPIs',
      country: 'País',
      headline: 'Titular',
      sectors: 'Sectores',
      areasOfExpertise: 'Áreas de especialización',
      mentoringsEmpty: 'No tienes mentorías activas',
      documentsEmpty: 'No hay documentos',
      eventsEmpty: 'No hay eventos próximos',
      value: 'Valor',
      targetValue: 'Valor objetivo',
      previousValue: 'Valor anterior',
      lastUpdate: 'Última actualización',
      noTools: 'No tienes acceso a ninguna herramienta, ponte en contacto con el propietario',
      startupGuestInfo: 'Estas accediendo a la startup como invitado. ',
      requestAccess: 'Solicitar acceso',
      addToPrehome: 'Añadir al inicio',
      removeFromPrehome: 'Eliminar del inicio',
      pendingApprovalRequest: 'Su solicitud esta pendiente de aprobación',
      requestSubmittedSuccessfully: 'Solicitud enviada correctamente',
    },
    homeAdmin: {
      accepted: 'Aceptadas',
      pending: 'Pendientes',
      rejected: 'Rechazadas',
      finished: 'Finalizadas',
      mentorings: 'Mentorías',
      meetings: 'Reuniones',
      documents: 'Documentos',
      startups: 'Startups',
      mentors: 'Mentores',
      groups: 'Grupos',
      group1: 'Grupo 1',
      group2: 'Grupo 2',
      group3: 'Grupo 3',
      users: 'Usuarios',
      entrepreneurs: 'Emprendedores',
      mentoringStatus: 'Estado de las mentorías',
      lastMonthActivity: 'Actividad del último mes',
      donatedHours: 'Horas donadas',
      mentorsMentoring: 'Mentores mentorizando',
      startupMentored: 'Startups mentorizadas',
      meetingsFinished: 'Reuniones realizadas',
      meetingsScheduled: 'Reuniones programadas',
      totalHours: 'Total / Número de horas',
      uploadedToMentorings: 'Subidos a mentorías',
      captable: 'Captable',
      operations: 'Operaciones',
      shareholders: 'Inversores',
      shareholdersWithAccess: 'Inversores con acceso',
      startupsWithCaptable: 'Startups con captable',
      syndicates: 'Sindicatos',
      waterfalls: 'Waterfalls',
      performance: 'Rendimiento',
      values: 'Valores',
      createdKpis: 'Kpis creados',
      usedKpis: 'Kpis usados',
      valuesPerDay: 'Valores añadidos por día',
      dataroom: 'Documentos',
      folders: 'Carpetas',
      reports: 'Reportes',
      emails: 'Emails',
    },
    editInfo: {
      viewProfile: 'Ver perfil',
      editProfile: 'Editar perfil',
      companyData: 'Datos de la compañía',
      profileUpdated: 'Perfil actualizado',
      submit: 'Enviar',
      cancel: 'Cancelar',
      save: 'Guardar',
    },
    editAccount: {
      home: 'Inicio',
      editAccount: 'Editar Cuenta',
      accountData: 'Datos de la cuenta',
      avatar: 'Avatar',
      name: 'Nombre',
      lastname: 'Apellidos',
      email: 'Email',
      submit: 'Enviar',
      cancel: 'Cancelar',
      password: 'Contraseña',
      oldPassword: 'Contraseña actual',
      newPassword: 'Contraseña nueva',
      newPasswordRepeat: 'Repite la contraseña nueva',
      passwordNotMatch: 'Las contraseñas no coinciden',
      accountSaved: 'Perfil guardado correctamente',
      language: 'Idioma',
      role: 'Rol',
      languages: {
        es: 'Español',
        en: 'Inglés',
        it: 'Italian',
      },
      roles: {
        admin: 'Administrador',
        user: 'Usuario',
        mentor: 'Mentor',
      },
      profile: 'Perfil',
      numericFormat: 'Formato numérico',
      numericFormats: {
        decimalsComma: 'Miles separados por punto, decimales separados por comma (1.000.000,00)',
        decimalsPeriod: 'Miles separados por coma, decimales separados por punto (1,000,000.00)',
      },
    },
    table: {
      search: 'Buscar',
      filters: 'Filtros',
      noResults: 'No se han encontrado resultados',
      cleanFilters: 'Limpiar',
      applyFilters: 'Filtrar',
      actions: 'Acciones',
      rowsAction: 'Acción a filas',
      selectPage: 'Seleccionar página',
      selectAll: 'Seleccionar todo',
      unselectAll: 'Deseleccionar todo',
      showingTableResults: 'Mostrando %FIRST% al %LAST% de %TOTAL% resultados',
      goTo: 'Ir a',
      configColumns: 'Configurar columnas',
      columns: 'Columnas',
      edited: 'Editado',
      confirm: 'Confirmar',
      reset: 'Restablecer',
      errorFetchingGeneric: 'Ha ocurrido un error al obtener los datos',
      errorFetchingUnauthorized: 'No tienes permisos para acceder a los datos',
    },
    admin: {
      platform: {
        title: 'Ajustes de la plataforma',
        generals: 'Generales',
        email: 'Emails',
        forms: 'Formularios',
        legal: 'Legal',
        id: 'id',
        text: 'titulo',
        type: 'tipo',
        mandatory: 'obligatorio',
        afirmative: 'si',
        negative: 'no',
        answers: 'Respuestas',
        startupCoreQuestions: 'Preguntas principales de una startup',
        mentorCoreQuestions: 'Preguntas principales de un mentor',
        fundCoreQuestions: 'Preguntas principales de un fondo',
        types: {
          text: 'Texto',
          textarea: 'Area de texto',
          select: 'Selector',
          radiobutton: 'Opción unica',
          checkbox: 'Opción multiple',
          list: 'Lista',
          document: 'Documento',
          date: 'Fecha',
          number: 'Número',
        },
        lang: 'Idioma',
        order: 'Orden',
        answer: 'Respuesta',
        public: 'Publico',
        filter: 'Filtro',
        theField: 'El campo',
        theAnswer: 'La respuesta',
        inLanguage: 'en el idioma',
        isEmpty: 'esta vacio',
        confirmSave: '¿Esta seguro que desea guardar la pregunta?',
        emails: {
          fromName: 'Nombre cuenta de envío',
          fromAdress: 'Dirección cuenta de envío',
          mainTemplate: 'Plantilla base',
          supportAddress: 'Email de recepcion del formulario de soporte',
          servicesAddress: 'Email de recepcion de las solicitudes de servicios',
          webhooksAddress: 'Email de recepcion de webhooks',
          emailBot: 'Bot correo',
          emailBotPassword: 'Contraseña del bot correo',
          subject: 'Asunto',
          sender: 'Emisor',
          body: 'Cuerpo',
          languages: {
            es: 'Español',
            en: 'Inglés',
            it: 'Italian',
          },
          templates: {
            inviteToStartupExist: {
              title: 'Invitación usuario a startup (usuario ya dado de alta)',
              description: '',
            },
            inviteToStartupNew: {
              title: 'Invitación usuario a startup',
              description: '',
            },
            mentoringAccepted: {
              title: 'Mentoría aceptada',
              description: '',
            },
            mentoringDocument: {
              title: 'Nuevo documento en una mentoría',
              description: '',
            },
            mentoringFinished: {
              title: 'Mentoría finalizada',
              description: '',
            },
            mentoringInviteMentor: {
              title: 'Invitación a mentoria (startup a mentor)',
              description: '',
            },
            mentoringInviteStartup: {
              title: ' Invitación a mentoria (mentor a startup)',
              description: '',
            },
            mentoringMeeting: {
              title: 'Nuevo evento en una mentoría',
              description: '',
            },
            mentoringMeetingDelete: {
              title: 'Cancelación evento en una mentoría',
              description: '',
            },
            mentoringMeetingUpdate: {
              title: 'Actualización evento en una mentoría',
              description: '',
            },
            mentoringMessage: {
              title: 'Nuevo mensaje en una mentoría',
              description: '',
            },
            mentoringRejected: {
              title: 'Mentoría rechazada',
              description: '',
            },
            notificationsSummary: {
              title: 'Aviso notificaciones sin leer',
              description: '',
            },
            passwordChanged: {
              title: 'Contraseña cambiada',
              description: '',
            },
            recoveryPassword: {
              title: 'Recuperar contraseña',
              description: '',
            },
            welcomeAdmin: {
              title: 'Alta administrador',
              description: '',
            },
            welcomeMentor: {
              title: 'Alta mentor',
              description: '',
            },
            welcomeUser: {
              title: 'Alta usuario',
              description: '',
            },
            secondFactor: {
              title: 'Segundo factor de autenticación',
              description: '',
            },
            fundraiseInvite: {
              title: 'Invitar a recaudación de fondos',
              description: '',
            },
            startupAccessRequest: {
              title: 'Solicitud de acceso a startup',
              description: '',
            },
            startupAccessRequestAccepted: {
              title: 'Solicitud de acceso a startup aceptada',
              description: '',
            },
            startupAccessRequestRejected: {
              title: 'Solicitud de acceso a startup rechazada',
              description: '',
            },
            startupInviteAccepted: {
              title: 'Invitación a startup aceptada',
              description: '',
            },
            startupInviteRejected: {
              title: 'Invitación a startup rechazada',
              description: '',
            },
            startupInviteFounder: {
              title: 'Invitar a founder a gestionar',
              description: '',
            },
            newElementPermission: {
              title: 'Nuevo permiso sobre elemento',
              description: '',
            },
            confirmInitializedCaptable: {
              title: 'Confirmar inicialización del captable',
              description: '',
            },
          },
          mergetags: {
            CONTENT: 'Esta es la descripion',
            FIRSTNAME: 'Esta es la descripcion',
            LASTNAME: 'Esta es la descripcion',
            EMAIL: 'Esta es la descripion',
            FROM_FIRSTNAME: 'Esta es la descripion',
            FROM_LASTNAME: 'Esta es la descripion',
            TOKEN: 'Esta es la descripion',
            STARTUP_NAME: 'Esta es la descripcion',
            MENTOR_NAME: 'Esta es la descripion',
            FROM_NAME: 'Esta es la descripion',
            MEETING_TITLE: 'Esta es la descripion',
            MEETING_DESCRIPTION: 'Esta es la descripion',
            MEETING_DATE: 'Esta es la descripion',
            MEETING_TIME: 'Esta es la descripion',
            MEETING_OLD_DATE: 'Esta es la descripion',
            MEETING_OLD_TIME: 'Esta es la descripion',
            DOCUMENT_NAME: 'Esta es la descripion',
            MESSAGE: 'Esta es la descripion',
            NOTIFICATIONS: 'Esta es la descripion',
            FUNDRAISE: 'Esta es la descripion',
            INVITE: 'Esta es la descripion',
            CAPTABLE_NAME: 'Esta es la descripción',
            CAPTABLE_ID: 'Esta es la descripción',
            STARTUP_ID: 'Esta es la descripción',
          },
        },
        name: 'Nombre',
        logo: 'Logo',
        favicon: 'Favicon',
        bgPublicDesktop: 'Fondo vista pública (desktop)',
        bgPublicMobile: 'Fondo vista pública (mobile)',
        mainColor: 'Color principal',
        googleAnalytics: 'GA medición ID',
        googleAnalyticsProperty: 'GA Property ID',
        googleAnalyticsCredentials: 'GA credenciales (JSON)',
        configSignups: 'Configuración de formularios de registro',
        configTools: 'Herramientas activas',
        tools: {
          capTable: 'Cap Table',
          performance: 'KPIs',
          dataRoom: 'Documentos',
          profile: 'Perfil',
          mentoring: 'Mentorias',
          reporting: 'Reportes',
          users: 'Gestor de usuarios',
          messages: 'Mensajes',
          services: 'Servicios',
          fundraise: 'Recaudar fondos',
        },
        jsonLiterals: 'JSON literales traducción (ejemplo: {"en":{"common":{"add":"Add"}},"es":{"common":{"add":"Añadir"}}}',
        signupExternal: 'Registro abierto publicamente',
        signupInternal: 'Creación de startups desde la prehome',
        save: 'Guardar',
        send: 'Enviar',
        permissions: {
          current: 'Actuales (No modificar)',
          noPermission: 'Sin permisos',
          see: 'Ver',
          edit: 'Editar',
          manage: 'Administrar',
        },
        owner: 'Propietario',
        user: 'Usuario',
        shareholder: 'Inversor',
        log: 'Log',
        seeLogs: 'Ver los logs',
        botEmails: 'Emails recibidos por el bot',
        emailDetails: 'Detalles del email',
        signaturitKey: 'Clave de signaturit',
        roles: {
          owner: 'Dueños',
          admin: 'Administradores',
          user: 'Usuarios',
          shareholder: 'Inversores',
          startup: 'Todos los usuarios',
          other: 'Otros',
        },
      },
      analytics: {
        views: 'Views',
        sessions: 'Sessions',
        users: 'Users',
        averageSession: 'Average session',
        chartsPerDay: 'Gráficas por día',
        mostVisitedStartups: 'Startups mas visitadas',
        mostVisitedPages: 'Paginas mas visitadas',
        mostUsedTools: 'Herramientas mas usadas',
        mostActiveUsers: 'Usuarios mas activos',
        startup: 'Startup',
        tool: 'Herramienta',
      },
      support: {
        title: 'Comentarios de ayuda',
        firstname: 'Nombre',
        lastname: 'Apellidos',
        message: 'Mensaje',
        date: 'Fecha',
        question: 'Pregunta',
        user: 'Usuario',
      },
      scripts: {
        title: 'Registro de scripts',
        cronLogs: 'Registro de tareas cron',
        method: 'Metodo',
        input: 'Entrada',
        output: 'Salida',
        runtime: 'T. ejecución',
        scriptDetail: 'Detalles del script',
      },
    },
    startupUsers: {
      userDatabase: 'Base de datos de usuarios',
      inviteUser: 'Invitar miembro',
      email: 'Email',
      name: 'Nombre',
      lastname: 'Apellidos',
      role: 'Rol',
      avatar: 'Avatar',
      type: 'Tipo',
      permissions: 'Permisos',
      permissionsByType: 'Permisos segun el tipo de usuario',
      capTable: 'Cap Table',
      performance: 'KPIs',
      dataRoom: 'Documentos',
      profile: 'Perfil',
      mentoring: 'Mentorias',
      reporting: 'Reportes',
      users: 'Usuarios',
      admin: 'Administrador',
      user: 'Usuario',
      shareholder: 'Inversor',
      groups: 'Grupos',
      messages: 'Mensajes',
      noPermission: 'Sin permisos',
      none: 'Ninguno',
      errorEmptyPerms: 'No has seleccionado ningun permiso',
      see: 'Ver',
      edit: 'Editar',
      manage: 'Administrar',
      lang: 'Lenguaje',
      types: {
        owner: 'Dueño',
        admin: 'Administrador',
        user: 'Usuario',
        shareholder: 'Inversor',
        ESOPs: 'ESOPs',
        other: 'Otro',
      },
      languages: {
        es: 'Español',
        en: 'Inglés',
        it: 'Italian',
      },
      inviteUserSuccess: 'Usuario invitado correctamente, en breve recibirá un email para completar la invitación. Puedes ver su estado en la pestaña de pendientes, y una vez lo acepte te lo notificaremos.',
      acceptUserSuccess: 'Usuario aceptado correctamente',
      removeUser: 'Expulsar Usuario',
      questionRemoveUser: '¿Estas seguro de expulsar al usuario?',
      questionCancelInviteUser: '¿Estas seguro de cancelar la invitación?',
      questionRejectUser: '¿Estas seguro de rechazar la solicitud al usuario?',
      editPermissions: 'Editar permisos',
      leaveStartup: 'Abandonar startup',
      leaveStartupQuestion: '¿Esta seguro que quiere abandonar la startup?',
      remove: 'Expulsar',
      send: 'Enviar',
      pending: 'Pendiente',
      guests: 'Invitados',
      admins: 'Administradores',
      shareholders: 'Inversores',
      pendings: 'Pendientes',
      invite: 'Invitación',
      request: 'Solicitud',
      confirmAccept: '¿Estas seguro de que quieres aceptar la invitacion?',
      confirmReject: '¿Estas seguro de que quieres rechazar la invitacion?',
      confirm: 'Confirmar',
      user_already_exist: 'Este usuario ya forma parte de la startup',
    },
    adminUsers: {
      createUser: 'Crear usuario',
      users: 'Usuarios',
      invite: 'Invitar',
      send: 'Enviar',
      avatar: 'Avatar',
      fullname: 'Nombre completo',
      email: 'Email',
      role: 'Rol',
      date: 'Fecha',
      group: 'Grupo',
      lastAccess: 'Último acceso',
      lastModification: 'Última modificación',
      extra: 'Otra información (JSON)',
      view: 'Ver',
      delete: 'Borrar',
      exportExcel: 'Descarga la plantilla',
      andUpload: 'y súbela',
      successCreate: 'Se han creado correctamente %SUCCESS% usuarios',
      successCreateOne: 'Se ha creado correctamente %SUCCESS% usuario',
      row: 'Fila',
      rowErrors: 'Errores en las siguientes filas',
      requiredField: 'El campo %FIELD% es requerido',
      existUserErr: 'El usuario ya existe',
    },
    adminStartups: {
      startups: 'Startups',
      startupName: 'Nombre de la startup',
      group: 'Grupo',
      description: 'Descripción',
      name: 'Nombre',
      lastname: 'Apellidos',
      email: 'Email',
      language: 'Idioma',
      exportExcel: 'Descarga la plantilla',
      andUpload: 'y súbela',
      validateDuplicatesName: '¿Que hacer con los duplicados?',
      nothing: 'Nada',
      edit: 'Editar',
      duplicate: 'Duplicar',
      sendEmail: 'Enviar email',
      send: 'Enviar',
      no: 'No',
      yes: 'Sí',
      invite: 'Invitar',
      successCreate: 'Se han creado correctamente %SUCCESS% usuarios',
      successCreateOne: 'Se ha creado correctamente %SUCCESS% usuario',
      row: 'Fila',
      rowErrors: 'Errores en las siguientes filas',
      emptyName: 'El campo NAME es requerido',
      verified: 'Verificados',
      phantoms: 'Fantasma',
      markAsPhantom: 'Marcar como fantama',
      unmarkAsPhantom: 'Desmarcar como fantama',
      mergeStartups: 'Unir startups',
      mergeStartupsInfo: 'Seleccione la startup principal',
      confirmMergeStartups: '¿Estas seguro que desea unificar estas %STARTUPS% startups en la startup %STARTUP%?',
      startupPlan: {
        choosePlan: 'Elegir plan',
        chooseStartupPlan: 'Elige un plan para la startup',
        free: 'Gratuito',
        freeDescription: 'Plan gratuito',
        basic: 'Básico',
        basicDescription: 'Plan básico',
        premium: 'Premium',
        premiumDescription: 'Plan premium',
      },
      startupVerify: {
        verify: 'Verificar',
        verified: 'Verificado',
        editVerified: 'Editar verificado',
        chooseStartupVerify: 'Elige el grado de verificación para la startup',
        notVerified: 'Sin verificar',
        notVerifiedDescription: 'La startup no esta verificada',
        team: 'Equipo',
        full: 'Completo',
      },
    },
    help: {
      help: 'Ayuda',
      home: 'Inicio',
      submit: 'Enviar',
      cancel: 'Cancelar',
      form: 'Dejanos un mensaje',
      sent: 'Enviado',
    },
    reporting: {
      title: 'Reportes',
      newReport: 'Nuevo reporte',
      reportDetail: 'Detalle del reporte',
      add: 'Añadir',
      subject: 'Asunto',
      recipients: 'Destinatarios',
      message: 'Mensaje',
      attachDocuments: 'Adjuntar documentos',
      attached: 'Documentos adjuntos',
      uploadedDate: 'Fecha de subida',
      uploadedBy: 'Subido por',
      details: 'Detalles',
      emails: 'Emails',
      search: 'Buscar',
      upload: 'Subir',
      lastName: 'Apellidos',
      preview: 'Previsualizar',
      sendTest: 'Enviar prueba',
      test: 'Prueba',
      testSent: 'Reporte de prueba enviado',
      noAttachments: 'No hay documentos adjuntos en el texto',
      lastReports: 'Últimos reportes',
      noReports: 'No se ha enviado ningun reporte todavia',
      templates: 'Plantillas',
      template: 'Plantilla',
      newTemplate: 'Crear plantilla',
      editTemplate: 'Editar plantilla',
      blankTemplate: 'Plantilla en blanco',
      noImage: 'Sin imagen',
      language: 'Idioma',
      questionDeleteTemplate: '¿Estas seguro de borrar la plantilla?',
      fieldEmpty: 'El campo esta vacio',
      draft: 'Borrador',
      documents: 'Documentos',
      email: 'Email',
      document: 'Documento',
      downloadPdf: 'Descargar PDF',
      reportingNotFound: 'El reporte no existe',
      reportTemplateNotFound: 'La plantilla no se ha encontrado',
      name: 'Nombre',
      content: 'Contenido',
      save: 'Guardar',
      or: 'o',
      scope: 'Ambito',
      owner: 'Propietario',
    },
    services: {
      title: 'Servicios',
      requests: 'Solicitudes',
      service: 'Servicio',
      request: 'Solicitud',
      createService: 'Crear servicio',
      editService: 'Editar servicio',
      createRequest: 'Registrar solicitud',
      editRequest: 'Editar solicitud',
      serviceInfo: 'Información del servicio',
      company: 'Empresa',
      shortDescription: 'Descripcion corta',
      detailedDescription: 'Descripcion detallada',
      questionRemoveService: '¿Estas seguro de eliminar el servicio?',
      questionRemoveRequest: '¿Estas seguro de eliminar la solicitud?',
      questionContactManager: '¿Quieres que un gestor se ponga en contacto contigo?',
      confirmAnswer: 'Gracias, en breve te contactaremos',
      cancelRequestAnswer: 'La solicitud ha sido cancelada correctamente',
      visible: 'Visible',
      status: 'Estado',
      lead: 'Posible cliente',
      appointment: 'Reunión',
      pending: 'Pendiente',
      rejected: 'Rechazado',
      done: 'Terminado',
      requestInfo: 'Solicitar información',
      creationDate: 'Fecha de creación',
      internalNotes: 'Notas internas (No lo ve el cliente)',
      details: 'Detalles',
      message: 'Mensaje',
      internalService: 'Servicio interno',
      externalService: 'Servicio externo',
      import_captable: 'Importar captable',
      upload_captable: 'Subir captable',
      request_startup_access: 'Acceso a startup',
    },
    webhooks: {
      title: 'Webhooks',
      captable_operation: 'Operacion del captable',
      sent: 'Enviado',
      success: 'Completado',
      error: 'Error',
      sentTo: 'Enviado a',
      webhookDetails: 'Detalles del webhook',
      data: 'Datos',
      response: 'Respuesta',
    },
    myPosition: {
      title: 'Mi posición',
      shares: 'Acciones',
      debts: 'Deudas',
      options: 'Opciones',
      value: 'Valor',
      ownership: 'Propiedad',
      fullyDiluted: 'Dilución',
      entryDate: 'Fecha de entrada',
      entryValuation: 'Valoracion de entrada',
      numeration: 'Numeración',
      capital: 'Capital',
      principal: 'Prestamo',
      pending: 'Pendientes',
      interest: 'Intereses',
      from: 'Desde',
      to: 'Hasta',
      number: 'Cantidad',
      assigned: 'Asignadas',
      vested: 'Adquiridas',
      exercised: 'Ejercidas',
      noParticipation: 'El inversor no tiene participación en el captable',
      shareholder: 'Inversor',
      shareholders: 'Inversores',
      investmentEvolution: 'Evolución de la inversión',
      debtsEvolution: 'Evolución de las deudas',
      optionsEvolution: 'Evolución de las opciones',
      operation: 'Operación',
      shareClass: 'Clase',
      contributedCapital: 'Capital Aportado',
      pps: 'PPS',
      vote: 'Voto',
      status: {
        status: 'Estado',
        pending: 'Pendiente',
        closed: 'Cerrada',
      },
      accrualFrequency: 'Devengo',
      startDate: 'F. de inicio',
      expirationDate: 'F. de Expiración',
      capitalized: 'Capitalizado',
      repaid: 'Repagado',
      interestRate: 'Intereses',
      exercisePrice: 'Precio de conversión',
      ratio: 'Ratio de conversion',
      vestingPlan: 'Plan de adquisición',
      shareDetails: 'Detalles de la acción',
      debtDetails: 'Detalles de la deuda',
      optionDetails: 'Detalles de la opción',
      numberOfShares: 'Número de acciones',
      myShares: 'Mis acciones',
      myDebts: 'Mis deudas',
      myOptions: 'Mis opciones',
      convertibles: 'Convertibles',
      totalValue: 'Valor total',
      latestCompanyValuation: 'Última valoración de la empresa',
      totalPotentialValue: 'Valor potencial',
      positionOverview: 'Visión general de la posición',
      totalInvested: 'Total invertido',
      multiplo: 'Múltiplo',
      postMoney: 'Valoración post-money',
      valuation: 'Valoración',
      equityGrowth: 'Crecimiento del capital',
      vestedOptions: 'Opciones adquiridas',
      pendingOptions: 'Opciones pendientes',
      totalOptions: 'Opciones totales',
      milestones: 'Objetivos',
      calendar: 'Calendario',
      simulation: 'Simulación',
    },
    emailStats: {
      email: 'Email',
      status: 'Estado',
      registered: 'Registrado',
      processed: 'Procesado',
      delivered: 'Entregado',
      open: 'Abierto',
      click: 'Clicado',
      error: 'Error',
      reporting: 'Reportes',
      attached: 'Adjunto',
      sendingDate: 'Fecha de envio',
      statusInfo: 'Información del estado',
      timeline: 'Linea temporal',
      errorReason: 'Motivo del error',
      link: 'Enlace',
      response: 'Respuesta',
    },
    dataRoom: {
      dataRoom: 'Documentos',
      download: 'Descargar',
      open: 'Abrir',
      root: 'Raiz',
      file: 'Archivo',
      files: 'Archivos',
      date: 'Fecha',
      creator: 'Creador',
      viewers: 'Vistos',
      seenBy: 'Visto por',
      see: 'Ver',
      avatar: 'Avatar',
      user: 'Usuario',
      rename: 'Renombrar',
      upload: 'Subir contenido',
      fileName: 'Nombre del archivo',
      fileDescription: 'Descripcion del archivo',
      link: 'Enlace',
      selectFile: 'Selecciona un archivo',
      uploadNewVersion: 'Añadir una nueva version',
      versions: 'Versiones',
      previousVersions: 'Versiones anteriores',
      noPrevVersions: 'No hay versiones anteriores',
      actualVersion: 'Version actual',
      createFolder: 'Crear carpeta',
      createNewFolder: 'Crear una nueva carpeta',
      renameFolder: 'Renombrar carpeta',
      editFolder: 'Editar carpeta',
      folderName: 'Nombre de la carpeta',
      emptyFolder: 'Todavia no hay contenido en esta carpeta',
      noFolderPermissions: 'No tienes permisos para ver esta carpeta',
      emptyBookmarks: 'No hay contenido guardado como favorito',
      noFolderSelected: 'No hay ninguna carpeta seleccionada',
      folders: 'Carpetas',
      folder: 'Carpeta',
      content: 'Contenido',
      viewed: 'Visto',
      title: 'Titulo',
      description: 'Descripcion',
      createdBy: 'Creado por',
      updatedBy: 'Modificado por',
      createdAt: 'Creado el',
      updatedAt: 'Actualizado el',
      infoFile: 'Información del archivo',
      fileViews: 'Visualizaciones del archivo',
      details: 'Detalles',
      views: 'Visualizaciones',
      move: 'Mover',
      error: 'Se ha producido un error',
      noPermissions: 'No tienes permisos para realizar esta acción',
      dropInside: 'soltar dentro',
      folderNotEditable: 'No se puede editar esta carpeta',
      cantEditDefaultFolders: 'No se pueden editar carpetas por defecto',
      defaultFolders: {
        captable: 'Captable',
        captable_operations: 'Operaciones',
        captable_shareholders: 'Inversores',
        reporting: 'Reportes',
        fundraise: 'Recaudar fondos',
      },
      search: 'Buscar',
      actual: 'Actual',
      version: 'Versión',
      uploadedBy: 'Subido por',
      uploadedAt: 'Subido el',
      recents: 'Recientes',
      bookmarks: 'Favoritos',
      today: 'Hoy',
      lastWeek: 'Ultima semana',
      lastMonth: 'Ultimo mes',
      previousContent: 'Contenido anterior',
      confirmDeleteFolder: '¿Estas seguro que deseas borrar esta carpeta? Se borraran todas las carpetas y documentos que hay en su interior',
    },
    footer: {
      contact: 'Contacto',
      privacy: 'Política de privacidad',
      cookies: 'Política de cookies',
      terms: 'Terminos y condiciones',
    },
    breadcrumb: {
      home: 'Inicio',
      goBack: 'Volver',
      startup: 'Startup',
      mentorings: 'Mentorías',
      editAccount: 'Editar Cuenta',
      search: 'Buscar',
      users: 'Usuarios',
    },
    mentoringCard: {
      nextMeeting: 'Próxima reunión',
      noNextMeeting: 'Sin planificar',
      lastInteraction: 'Última interacción',
      founderIn: 'Fundador/a en',
    },
    performance: {
      add: 'Añadir',
      addKpi: 'Añadir kpi',
      title: 'Performance',
      kpi: 'KPI',
      kpis: 'KPIs',
      startupKpis: 'Startup KPIs',
      userKpis: 'KPIs created by you in other startups',
      templates: 'Templates',
      createKpi: 'Crear Kpi',
      createTemplate: 'Crear Plantilla',
      category: 'Categoría',
      periodicity: 'Recurrencia',
      positive: 'Positivo',
      scope: 'Ambito',
      unit: 'Unidad',
      minMax: 'Min|Max',
      notes: 'Notas',
      questionDeleteKpi: '¿Estas seguro de borrar el kpi?',
      questionDeleteTemplate: '¿Estas seguro de borrar la plantilla?',
      questionUnlinkKpi: '¿Estas seguro de quitar el kpi?',
      questionDeleteKpiValue: '¿Estas seguro de borrar el valor?',
      questionConfirmChangeTable: '¿Esta seguro que quiere cambiar de tabla? Perderas lo valores que aun no hayas guardado.',
      questionScopeToAll: '¿Esta seguro que quiere cambiar el ambito de kpi a global? El dueño perdera los permisos de edicion.',
      changeScopeToAll: 'Cambiar a ámbito global',
      daily: 'Diario',
      weekly: 'Semanal',
      biweekly: 'Bisemanal',
      monthly: 'Mensual',
      quarterly: 'Trimestral',
      biannual: 'Bianual',
      annual: 'Anual',
      business: 'Negocio',
      financial: 'Financiero',
      esg: 'ESG',
      integer: 'Entero',
      float: 'Decimal',
      currency: 'Divisa',
      percent: 'Porcentaje',
      up: 'Subir',
      down: 'Bajar',
      equal: 'Mantenerse',
      min: 'Mínimo',
      max: 'Máximo',
      infinitePositive: '+∞',
      infiniteNegative: '-∞',
      custom: 'Personalizado',
      fill: 'Rellenar',
      noKpis: 'No hay KPIs',
      addKpis: 'Añadir KPIs',
      orderKpis: 'Ordenar KPIs',
      fillValues: 'Rellenar valores',
      viewValues: 'Ver valores',
      addNewValue: 'Añadir un nuevo valor',
      configuration: 'Configuración',
      autoSave: 'Guardado automático',
      saving: 'Guardando...',
      withoutChanges: 'Sin cambios',
      dataIncomplete: 'Celdas con * los datos están incompletos',
      previous: 'Previo',
      date: 'Fecha',
      lastUpdate: 'Última actualización',
      target: 'Objetivo',
      diffTarget: 'Dif. objetivo',
      value: 'Valor',
      values: 'Valores',
      diffValue: 'Dif. valor',
      questionSetTarget: '¿Quieres definir el objetivo del kpi?',
      questionSetTargetMultiple: '¿Quieres definir el objetivo de cada kpi?',
      selectTemplate: 'Selecciona un template',
      alreadyAdded: 'Ya añadido',
      noResultsFill: 'No se han encontrado valores para rellenar',
      noResults: 'No se han encontrado valores',
      noKPIs: 'No se ha añadido ningun KPI todavía',
      lastValue: 'Ultimo valor',
      newValue: 'Nuevo valor',
      pendingKpis: 'Tienes KPIs sin actualizar',
      pending: 'Pendientes',
      noPending: '¡Enhorabuena, no tienes KPIs pendientes de actualizar!',
      updated: 'Actualizados',
      bookmark: 'Favorito',
      unbookmark: 'Quitar',
      selectKpi: 'Click aqui para seleccionar el KPI',
      select: 'Seleccionar',
      kpiValue: 'Valor del KPI',
      editorPlaceholder: 'Pulsa TAB para insertar',
      order: 'Orden',
      mode: 'Modo',
      chart: 'Grafica',
      table: 'Tabla',
      comparator: 'Comparador',
      emptyKpis: 'Selecciona los kpis a comparar',
      downloadExcel: 'Descargar excel',
      uploadExcel: 'Subir excel',
      actions: 'Acciones',
      confirmChanges: '¿Estas seguro que desea realizar los cambios?',
      successChanges: 'Cambios realizados con exito',
      errorChanges: 'Error al realizar los cambios',
      newValues: 'Val. nuevos',
      editedValues: 'Val. modificados',
      deletedValues: 'Val. eliminados',
      exportExcel: 'Exporta el excel',
      uploadChanges: 'y súbelo con las modificaciones que desees',
      excelErrors: 'Se han detectado los siguientes errores, corrígelos y sube el excel de nuevo',
      excelConfirm: 'A continuación se muestran los cambios detectados en el excel, revisalos y confirma la subida',
      kpiValueErr: 'El KPI %KPI% %ERROR% en la fecha %DATE%',
      errMax: 'tiene un valor por encima del maximo',
      errMin: 'tiene un valor por debajo del minimo',
      errInteger: 'tiene que ser un entero',
      errNumber: 'tiene que ser un numero',
      errKpi: 'Fila %ROW%, el KPI %KPI% no existe',
      back: 'Atrás',
      editKpi: 'Editar kpi',
      ajustValues: 'Los siguientes valores del kpi necesitan ser ajustados',
      errors: {
        value_less_than_min: 'se ajustarán al mínimo',
        value_greater_than_max: 'se ajustarán al máximo',
        value_must_be_an_integer: 'se convertirán en un entero',
        invalid_date_format: 'se moverán a la siguiente fecha si está vacío o se eliminarán',
      },
      kpiRelations: 'Relaciones del kpi',
      relationValues: 'Historicos de valores de la relacion',
      forecast: 'Estimación',
      addNotes: 'Añadir notas',
      removeNotes: 'Eliminar notas',
      updateKpiValue: 'Actualizar valor del KPI',
      showValues: 'Mostrar valores',
      hideValues: 'Ocultar valores',
      showForecast: 'Mostrar estimación',
      hideForecast: 'Ocultar estimación',
      card: 'Tarjeta',
      representKpi: '¿Cómo quieres representarlo?',
      selectAKpi: 'Selecciona un KPI',
      aggregate: 'Agregado',
      endOfPeriod: 'Fin del periodo',
      accumulative: 'Acumulado',
      average: 'Promedio',
      eop: 'FDP',
      acc: 'ACU',
      avg: 'MED',
      emailBot: {
        emailBot: 'Bot correo',
        updateValues: 'Actualizar valores mediante el bot',
        updateValuesQuestion: '¿Como puedo actualizar los valores de mis kpis mediante el bot?',
        updateValuesDesc: 'Para ello, puede enviar un correo que contenga la siguiente informacion',
        address: 'Direccion',
        subject: 'Asunto: Actualizar kpis',
        attachments: 'Adjuntos: Plantilla rellena con los valores a añadir o actualizar',
        downloadTemplate: 'Descargar plantilla',
        requirement: 'Nota: El email debe de ser enviado desde una cuenta con permisos de edicion en la herramienta "Rendimiento"',
      },
      permissions: 'Permisos',
      lessDateErr: 'La fecha de fin tiene que ser superior a la de inicio',
      diffRangeErr: 'El rango de meses comparados debe ser el mismo que el rango base',
      owner: 'Propietario',
    },
    messages: {
      yourMessage: 'Escribe tu mensaje',
      emptyChat: 'No hay mensajes',
      sendMessage: 'Enviar',
      readMore: 'Leer más',
      readLess: 'Leer menos',
      loadMore: 'Cargar más',
      loading: 'Cargando...',
      answer: 'Responder',
      reply: 'Respuesta',
      replies: 'Respuestas',
      comment: 'Comentar',
      cancel: 'Cancelar',
      showReplies: 'Mostrar respuestas',
      hideReplies: 'Ocultar respuestas',
      unread: 'No leido',
      unreads: 'No leidos',
      uploadError: 'Error en la subida del archivo',
      attachFile: 'Adjuntar fichero',
      days: 'dias',
      hours: 'horas',
      minutes: 'min',
      seconds: 'seg',
      justNow: 'ahora mismo',
      new: 'nuevo',
      close: 'Cerrar',
    },
    messagesMenu: {
      startupWall: 'Muro de mensajes',
      startupWallDescription: 'Comunicaciones privadas de la startup',
    },
    captable: {
      title: 'Cap table',
      captables: 'Captables',
      createCaptable: 'Crear captable',
      editCaptable: 'Editar captable',
      setupCaptable: 'Configura tu Cap table',
      operations: 'Operaciones',
      operation: 'Operación',
      shares: 'Acciones',
      debts: 'Deudas',
      options: 'Opciones',
      convertibles: 'Convertibles',
      shareClasses: 'Tipos',
      capital: 'Capital',
      contributedCapital: 'Capital Aportado',
      ownership: 'Total',
      fullyDiluted: 'Dilución',
      fullyDilutedAcronym: 'DL',
      secondary: 'Secundaria',
      secondarySale: 'Venta secundaria',
      changeClasses: 'Cambiar clases',
      reduce: 'Reducir',
      updateValuation: 'Actualizar valoración de la empresa',
      newOperation: 'Nueva operación',
      emptyCaptable: 'No hay ninguna operación en el captable',
      noSharesOperation: 'No hay ninguna operación con acciones en el captable',
      createOperation: 'Crear operación',
      createFirstOperation: 'Crear primera operación',
      publishSomeOperation: 'Publica alguna operación',
      initCaptable: 'Iniciar cap table',
      operationName: 'Nombre de la operación',
      editOperation: 'Editar operación',
      pending: 'Pendientes',
      vested: 'Adquiridas',
      vest: 'Adquirio',
      exercised: 'Ejercidas',
      issueDate: 'F.Emisión',
      captable: 'Cap Table',
      questionDelete: '¿Estas seguro?',
      issueDebt: 'emite deuda',
      receives: 'recibe',
      fromSomeone: 'de',
      toSomeone: 'a',
      change: 'cambia',
      forValueOf: 'Por un valor de',
      madeAValuationOf: 'hizo una valoración de',
      hasBeenA: 'Ha habido un',
      paid: 'Pagado',
      payoutTimeline: 'Pagados %total% (%amount% deuda + %interests% interes) de la deuda %debt%',
      for: 'por',
      of: 'de',
      official: 'Oficial',
      favorite: 'Favorito',
      valuationDiluted: 'Valoración diluida',
      officialConfirm: '¿Estas seguro que quieres convertir este captable en el oficial?',
      favoriteConfirm: '¿Estas seguro que quieres convertir este captable en el favorito?',
      deleteCaptableConfirm: '¿Estas seguro que quieres eliminar este captable?',
      noCaptables: 'No hay captables',
      issue: {
        shares: 'Emitir acciones',
        sharesHelp: 'Emite nuevas acciones para la compañía',
        secondaries: 'Secundaria',
        secondariesHelp: 'Venta de acciones entre inversores',
        changeClasses: 'Cambiar clases',
        changeClassesHelp: 'Cambiar de clase las acciones de un inversor',
        reduce: 'Reducir participaciones',
        reduceHelp: 'Reducir las participaciones de un inversor en la compañía',
        stockSplit: 'Stock split',
        stockSplitHelp: 'Divide las acciones para incrementar el número total',
        valuation: 'Valoración',
        valuationHelp: 'Registra una valoración de la compañía',
        debt: 'Emitir deuda',
        debtHelp: 'Emite nuevos convertibles para la compañía',
        convertDebt: 'Convertir deuda',
        convertDebtHelp: 'Convierte deuda pendiente en acciones',
        payout: 'Pagar deuda',
        payoutHelp: 'Registra el pago parcial o total de una deuda',
        options: 'Emitir opciones',
        issueAssignOptions: 'Emitir nuevas opciones o asignar pendientes',
        optionsHelp: 'Emite un nuevo conjunto de opciones para la compañía',
        vestOptions: 'Conceder opciones',
        vestOptionsHelp: 'Concede opciones asignadas a un inversor',
        exerciseOptions: 'Ejercer opciones',
        exerciseOptionsHelp: 'Ejerce opciones en acciones de la compañia',
        dividend: 'Reparto de dividendos',
        dividendHelp: 'Distribución de dividendos entre los inversores',
        operationName: 'Nombre de la operacion',
      },
      lastOperation: 'Última operación',
      lastConfirmed: 'Última confimada',
      referenceOperationInfo: 'La operación de referencia se utiliza en las operaciones para definir los parámetros de partida. Por ejemplo, el número máximo de acciones que cada inversor puede vender en un secundario o la numeración máxima desde la cual se empieza a repartir. Este se actualizará cada vez que se selecciona una nueva fecha para que la plataforma pueda realizar todas las comprobaciones necesarias y así asegurar la coherencia de los datos.',
      lastIssue: 'Última emisión',
      securities: 'Acciones',
      shareClass: 'Clase',
      shareClassFrom: 'Desde clase',
      shareClassTo: 'Cambiar a Clase',
      shareCode: 'Código',
      amount: 'Valor',
      left: 'restantes',
      pps: 'PPS',
      repurchasePrice: 'Precio recompra',
      numeration: 'Numeración',
      numerations: 'Numeraciones',
      sharesNumeration: 'Númeracion de acciones',
      from: 'Desde',
      to: 'Hasta',
      shareholders: 'Inversores',
      issuingShareholder: 'Inversor emisor',
      shareholder: 'Inversor',
      debt: 'Deuda',
      selectType: 'Selecciona el tipo',
      selectDebt: 'Seleccione una deuda',
      option: 'Opción',
      selectOption: 'Seleccione una opción',
      selectDebtOrOption: 'Seleccione una deuda o una opción',
      convertsTo: 'Convertible a',
      conversionTriggerAmount: 'Trigger amount',
      valuationCap: 'Valuation cap',
      earlyExitMultiple: 'Early exit multiple',
      discount: 'Descuento',
      interestRate: 'Porc.Intereses',
      interest: 'Intereses',
      principal: 'Prestamo',
      accrualFrequency: 'Devengo',
      phantom: 'Phantom shares',
      stockOption: 'Opciones sobre acciones',
      stockOptions: 'Opciones sobre acciones',
      price: 'Precio',
      number: 'Cantidad',
      newShares: 'Nuevas acciones',
      vestingPlan: 'Plan de adquisición',
      vestingStart: 'F. Inicio del plan',
      vestingPlans: 'Planes de adquisición',
      vestedByMilestone: 'Adquiridas al completar un objetivo',
      vestingTimeline: 'Adquisiciones realizadas',
      startDate: 'F. de inicio',
      expirationDate: 'F.Expiración',
      errorEmptyName: 'El campo nombre no puede estar vacio',
      errorConfirmOverDraftOperation: 'No es posible confirmar una operación si no es sobre la última',
      errorDateBeforeLastOperation: 'La fecha debe ser posterior a la fecha de la última operación confirmada.',
      confirmSubmit: '¿Estas seguro de confirmar la operación?',
      confirmSubmitDraft: '¿Estas seguro de guardar como borrador la operación?',
      saveAsDraft: 'Guardar como borrador',
      saveAsSimulation: 'Guardar como simulación',
      confirm: 'Confirmar',
      status: 'Estado',
      closed: 'Cerrada',
      draft: 'Borrador',
      confirmed: 'Confirmada',
      timeline: 'Linea temporal',
      totalCashRaised: 'Dinero total obtenido',
      totalCapitalRaised: 'Total capital obtenido',
      capitalRaised: 'Capital obtenido',
      totalInvestors: 'Total de inversores',
      valuation: 'Valoración',
      selectValuation: 'Selecciona una valoración',
      totalValuation: 'Valoracion total',
      ppsValuation: 'Valoracion PPS',
      ppsLastReport: 'PPS último reporte',
      totalDebtOutstanding: 'Deuda pendiente',
      outstandingInterest: 'Intereses pendientes',
      interestAvg: 'Intereses (media)',
      totalDebtHolders: 'Total deudores',
      outstanding: 'Pendiente',
      capitalized: 'Capitalizado',
      repaid: 'Repagado',
      debtHolders: 'Debtholders',
      syndicates: 'Syndicatos',
      syndicate: 'Syndicato',
      byShareholder: 'Por inversor',
      byRole: 'Por role',
      bySyndicate: 'Por sindicato',
      byShareClass: 'Por tipo de acción',
      noSyndicate: 'Sin sindicato',
      optionsStatus: 'Estado de las opciones',
      createOption: 'Crear opción',
      createShareClass: 'Crear clase de acción',
      showChart: 'Mostrar gráfica',
      showTable: 'Mostrar tabla',
      ppsEvolution: 'Evolución del PPS',
      types: 'Tipos',
      assignOptions: 'Asignar',
      vestOptions: 'Adquirir opciones',
      errorDeleteConfirmedOperation: 'No se puede borrar una operación confirmada si no es la última',
      pay: 'Pagar',
      payouts: 'Pagos',
      payout: 'Pago',
      secondaries: 'Secundarias',
      capitalize: 'Capitalizar',
      transform: 'Transformar',
      repaymentsTimeline: 'Historico de repagos',
      capitalizationsTimeline: 'Historico de capitalizaciones',
      repayDebt: 'Repago deuda',
      unassigned: 'Sin asignar',
      errors: {
        notShareholder: 'Shareholder no seleccionado',
        optionsAssignedLimit: 'Has asignado más opciones de las disponibles',
        invalid_number: 'El número no es válido',
        invalid_pps: 'El PPS no es válido',
        invalid_share_class: 'No se ha seleccionado la clase de acción o no existe',
        invalid_shareholder: 'No se ha seleccionado el inversor o no es válido',
        invalid_numeration: 'La númeración de las acciones es incorrecta',
        invalid_shares: 'Las acciones no son válidas',
        invalid_option: 'Las opciones asignadas no existen',
        invalid_debt: 'La deuda no existe',
        invalid_vesting_plan: 'El plan de adquisición no existe',
        share_assigned_greather_than_issue: 'Se han asignado más acciones de las creadas',
        share_assigned_less_than_issue: 'Se han asignado menos acciones de las creadas',
        assign_greather_than_available: 'Se han asignado más opciones de las disponibles',
        invalid_principal: 'El valor de la deuda no es válido',
        invalid_start_date: 'No ha seleccionado una fecha de inicio',
        invalid_expiration_date: 'No ha seleccionado una fecha de expiración',
        invalid_name: 'No ha rellenado el nombre',
        invalid_type: 'El tipo no es válido',
        invalid_price: 'El precio no es válido',
        invalid_amount: 'La cantidad no es válida',
        payout_amount_greater_than_pending: 'El pago es mayor a la deuda pendiente',
        invalid_sales_number: 'El número de acciones no es válido',
        invalid_sales_shareholder: 'No se ha asignado el shareholder de la venta',
        invalid_sales_numeration: 'La númeracion de acciones de la venta no es válida',
        sales_numeration_out_of_range: 'La numeracion de las acciones está fuera del rango del vendedor',
        invalid_conversion_type: 'El tipo no es válido',
        invalid_conversion_id: 'No ha seleccionado el elemento a convertir',
        numeration_not_consecutive: 'La numeración de acciones no es consecutiva',
        numeration_out_of_range: 'La numeración de acciones está fuera de rango',
        numeration_repeated: 'Hay acciones con numeración repetida',
        invalid_source: 'La operación de origen no es la última operación confimada',
        initializeCaptable: 'Se ha producido el siguiente error en la operacion %OPERATION% para la fecha %DATE%',
        empty_value: 'La celda esta vacia',
        invalid_shareClass: 'La clase de accion no existe',
        origin_not_found: 'No se ha encontrado el origen',
        debt_not_found: 'Deuda no encontrada',
        option_not_found: 'Opcion no encontrada',
        invalidRange: 'Rango invalido',
        operation_above_with_errors: 'No se ha podido confirmar la operación porque alguno de los borradores anteriores tiene errores',
        empty_operation: 'No se puede guardar una operacion vacia',
      },
      column: 'Columna',
      celda: 'Celda',
      downloadTheTemplate: 'Descarga la plantilla',
      inicializeCaptableExcelDescription: 'y rellena las operaciones en la hoja correspondiente. Por cada fecha se creará una nueva operacion',
      assignPoolOptions: 'Asignar opciónes existente',
      pool: 'Pool',
      vote: 'Voto',
      votes: 'Votos',
      votingPercentage: 'Porcentaje de votos',
      viewVoting: 'Ver votación',
      votingDetails: 'Detalles de votación',
      shareholdersNumber: 'Shareholders',
      rolesNumber: 'Roles',
      votesNumber: 'Votos',
      information: 'Información',
      notShareholderVotes: 'No hay ningun inversor con derecho a voto',
      value: 'Valor',
      optionsPool: 'Pool de opciones',
      others: 'Otros',
      new: 'Nuevo',
      previousOperation: 'Operación anterior',
      comparator: 'Comparador',
      selectFirstPayoutType: 'Selecciona primero un tipo de pago',
      conversions: 'Conversiones',
      conversion: 'Conversión',
      convert: 'Convertir',
      exerciseStock: 'Ejercer opciones',
      capitalizeDebt: 'Capitalizar deuda',
      noOperations: 'No has agregado ninguna operación',
      addShareholder: 'Añadir inversor',
      automaticNumeration: 'Numeración automatica',
      preMoneyValuation: 'Valoración pre-money',
      preMoneyValue: 'Pre-money (valor)',
      prorataDistribution: 'Reparto prorata',
      nominalValue: 'Valor nominal',
      sharePremium: 'Prima de emisión',
      noResults: 'No se han encontrado resultados',
      questionStatusToConfirm: '¿Estas seguro de confirmar la operación?',
      questionStatusToDraft: '¿Estas seguro de cambiar a borrador la operación?',
      questionStatusToDraftMultiple: '¿Estas seguro de cambiar a borrador esta operación y todas las posteriores?',
      errorDraftOperation: 'No se puede cambiar a draft una operación si no es la última',
      errorUndraftOperation: 'No se puede confirmar una operación si tiene errores',
      setAsDraft: 'Cambiar a borrador',
      addTimeline: 'Añadir a la linea temporal',
      removeTimeline: 'Eliminar de la linea temporal',
      cannotConfirmOperation: 'La operación ha sido guardada pero no se puede confirmar debido a que tiene errores, por favor reviselos e intentelo de nuevo',
      saveWithErrors: 'La operación se ha guardado correctamente, pero existen errores que deben solucionarse antes de poder confirmarla',
      canNotSave: 'La operación tiene errores y no puede ser guardada',
      saveAgainToValidateChanges: 'Para actualizar los errores guarde de nuevo la operación',
      operationNotFound: 'La operación no existe o ha sido borrada',
      assigned: 'Asignado',
      optionsToVest: 'Opciones a adquirir',
      exercisePrice: 'Precio de conversión',
      ratio: 'Ratio de conversion',
      stockSplit: 'Stock split',
      multiple: 'Múltiplo',
      liquidation: 'Liquidación',
      exit: 'Salida',
      both: 'Ambos',
      participating: 'Participa',
      moreDetails: 'Más detalles',
      table: 'Tabla',
      chart: 'Grafica',
      distribution: 'Distribución',
      nCurrentShares: 'Número de acciones actuales',
      nNewShares: 'Número de acciones nuevas',
      nCurrentOptions: 'Número de opciones asignadas actuales',
      nNewOptions: 'Número de opciones asignadas nuevas',
      nCurrentUnassignedOptions: 'Número de opciones no asignadas actuales',
      nNewUnassignedOptions: 'Número de opciones no asignadas',
      analyst: 'Analista',
      conflictMessage: 'Se han encontrado varios valores que afectan al PPS de la compañia. Selecciona cuál es la valoración correcta para esta fecha.',
      role: 'Rol',
      founder: 'Fundador',
      director: 'Director',
      boardMember: 'Miembro de la Junta',
      investor: 'Inversor',
      employee: 'Empleado',
      secretary: 'Secretario',
      advisor: 'Consejero',
      bank: 'Banco',
      corporate: 'Corporate',
      angelInvestor: 'Angel investor',
      privateEquity: 'Private equity',
      ventureCapital: 'Venture capital',
      friendsAndFamily: 'Amigos y familiares',
      corporateVentureCapital: 'Corporate venture capital',
      shareDetails: 'Detalles de la acción',
      debtDetails: 'Detalles de la deuda',
      optionDetails: 'Detalles de la opción',
      optionsIn: 'Opciones en',
      additionalDocumentation: 'Documentación adicional',
      totalDividend: 'Dividendo total',
      dividends: 'Dividendos',
      custom: 'Custom',
      voteDistribution: 'Distribución de votos',
      addRow: 'Añadir fila',
      sharesDistribution: 'Distribución de acciones',
      statutoryBook: 'Libro de socios',
      total: 'Total',
      transactions: 'Transacciones',
      simulations: 'Simulaciones',
      waterfall: 'Waterfall',
      deleteWaterfall: '¿Esta seguro de que desea eliminar el waterfall?',
      exitValue: 'Valor de salida',
      debtsToRepay: 'Deudas por pagar',
      preferredShares: 'Acciones preferentes',
      preferred: 'Preferentes',
      common: 'Comunes',
      cap: 'Cap',
      className: 'Clase',
      conversionRatio: 'C.Ratio',
      liquidationMultiple: 'L.Multiplo',
      waterfallToCompare: 'Selecciona los waterfalls a comparar',
      emptyOptionAssign: 'Selecciona una opcion para cada inversor',
      full: 'Detallada',
      totalSummary: 'Resumen del total',
      multipleSummary: 'Resumen del multiplo',
      operationsWillBeDeleted: 'Las operaciones se borrarán',
      savedDataMayHaveErrors: 'Los datos guardados podrian contener errores',
      pendingShares: 'Quedan %SHARES% acciones por repartir',
      saveWaterfallForExcel: 'Para descargar el excel tiene que guardar los cambios primero. ¿Desea guardar el waterfall?',
      deleteErasedItem: 'Esta operacion no existe. ¿Desea eliminarla?',
      certificate: 'Certificado',
      uploadExcelTemplates: 'Subir documentación',
      chooseOption: 'Elige una de las siguientes opciones para comenzar a utilizar la herramienta',
      importFrom: 'Importar desde',
      invitePlatform: 'Invita a %PLATFORM% a tu software de Cap table',
      features: {
        operations: 'Operaciones',
        operationsDescription: 'Emite acciones, convertibles, gestiona opciones, ventas de secundarios...',
        summary: 'Resumen',
        summaryDescription: 'Visualiza de forma sencilla quien forma parte del captable',
        timeline: 'Histórico',
        timelineDescription: 'Accede en cualquier momento a estados anteriores',
        simulations: 'Simulaciones',
        simulationsDescription: 'Podrás ver que ocurre ante nuevos escenarios o cual será el waterfall para cada uno de ellos',
        export: 'Exportar',
        exportDescription: 'Exporta toda la información en excel o emite reportes para tus inversores en pdf',
        invite: 'Invitar',
        inviteDescription: 'Da acceso a los inversores para que puedan ver su posición',
      },
      operationNotTheLast: 'ATENCIÓN: Esta operación no es la última',
      confirmMultipleOperations: '¿Estas seguro que quieres confirmar esta operación y todas las anteriores necesarias hasta la ultima confirmada?',
      uploadExcelDescription: 'Adjunta toda la documentación que tengas disponible sobre tu cap table y nosotros nos encargamos de subir esta información de forma estructurada a la plataforma. Incluye, si es posible, un archivo de Excel con el listado de accionistas actuales, detalles de los privilegios de las acciones preferentes, así como los contratos de los convertibles y/o opciones sobre acciones. Si tenemos alguna duda, nos pondremos en contacto contigo.',
      additionalDetailsRequest: '¿Quieres dejarnos mas detalles?',
      questionRemoveCaptableRequest: '¿Esta seguro de eliminar la solicitud para crear el captable?',
      questionRemoveImportCaptableRequest: '¿Esta seguro que desea eliminar la solicitud para importa el captable desde %PAGE%?',
      markCompleted: 'Marcar como completado',
      cancelRequest: 'Cancelar petición',
      importCaptable: {
        login: 'Inicia sesión en tu cuenta de %PAGE%',
        navigateCarta: 'Dirigete a Empresa > Permisos y Roles',
        navigateCapdesk: 'Navegue hasta "Configuración" > "Acceso de usuario"',
        navigateLedgy: 'Haga clic en "Invitar colaborador" en el lado derecho de la plataforma',
        addEmail: 'Agrega team@startups.land como Administrador Legal',
        markCompleted: 'Avísanos que nos has agregado haciendo clic en "Marcar como completado"',
        notification: 'Le haremos saber cuando su información esté lista en startups.land',
      },
      lead: 'Posible cliente',
      appointment: 'Reunión',
      rejected: 'Rechazado',
      done: 'Terminado',
      taxid: 'TAXID',
      editShareholders: 'Editar inversores',
      otherValuesSensitivity: 'Otros valores para análisis de sensibilidad',
      sensitivity: 'Sensibilidad',
      noOtherExitValues: 'No has introducido ningun valor de salida adicional',
      repay: 'Reembolsar',
      subject: 'Asunto',
      message: 'Mensaje',
      user: 'Usuario',
      uploadDocument: 'Subir documento',
      uploadSignature: 'Subir para firmar',
      signatureStatus: 'Estado de la firma',
      uploaded: 'Subido',
      signed: 'Firmado',
      filterByShareholder: 'Seleccionar inversor/es',
      maxCharacters: 'Máximo %NUMBER% carácteres',
      noCaptablePermission: 'No tienes permisos para ver este captable',
      other: 'Otro',
      operationTimeout: 'Esta operación puede demorarse unos minutos',
      emailWhenComplete: 'Recibirá un correo cuando haya terminado la operación',
    },
    fundraise: {
      title: 'Recaudar fondos',
      info: 'Información',
      investor: 'Inversor',
      investors: 'Inversores',
      amount: 'Cantidad',
      postMoney: 'Pre-Money',
      ownership: 'Propiedad',
      investments: 'Inversiones',
      status: 'Estado',
      round: 'Ronda',
      investmentRound: 'Ronda de inversión',
      minTicket: 'Inversión mínima',
      minTicketPhase: 'Inversión mínima',
      maxTicket: 'Inversión máxima',
      maxTicketPhase: 'Inversión máxima',
      newRound: 'Nueva ronda',
      editRound: 'Editar ronda',
      public: 'Publico',
      draft: 'Borrador',
      in_progress: 'En progreso',
      closed: 'Cerrado',
      viewed: 'Visto',
      sent: 'Enviado',
      invested: 'Invertido',
      commitedInvestor: '¿Tienes un inversionista principal comprometido?',
      kpis: 'KPIs',
      currentAllocation: 'Recaudación actual',
      invest: 'Invertir',
      pending: 'Pendiente',
      amountToInvest: 'Cantidad a invertir',
      share: 'Compartir',
      unauthorized: 'No tienes permisos para ver esta recaudación',
      myInvestment: 'Mi inversión',
      addEmail: 'Añadir email',
      invite: 'Invitar',
      message: 'Mensaje',
      deleteFundraise: 'Quieres eliminar esta recaudacion de fondos?',
      deleteInvestment: '¿Quieres dejar de invertir en esta recaudacion de fondos?',
      deleteInvite: '¿Quieres eliminar esta invitación a la recaudacion de fondos?',
      investmentAmount: 'Su cantidad invertida en este fondo de recaudación es de',
      invites: 'Invitaciones',
      preseed: 'PRE-SEED',
      seed: 'SEED',
      early: 'EARLY',
      growth: 'GROWTH',
      expansion: 'EXPANSION',
      enterToAdd: 'Pulsa ↵ para añadir un nuevo email',
      link: 'Enlace',
      linkDescription: 'Comparta este link con los inversores que quieras que tengan acceso a invertir en tu fondo de recaudacion. Haga click en el enlace para copiar al portapapeles',
      inviteDescription: 'Añada el correo de todos los inversores que quieras que tengan acceso a tu fondo de recaudacion',
      textCopied: '¡Texto copiado!',
      commitment: 'Compromiso',
      commitments: 'Compromisos',
      addCommitment: 'AAñadir compromiso de inversión',
      whoCanInvest: 'Quien puede invertir',
      anyoneCanInvest: 'Cualquiera puede invertir',
      byInvitationOnly: 'Solo por invitación',
    },
    funds: {
      title: 'Fondos',
      verification: 'Verificación',
      notVerified: 'Sin verificar',
      team: 'Equipo',
      full: 'Completo',
      confirmDeleteFund: '¿Estas seguro que deseas eliminar este fondo?',
      downloadExcel: 'Descargar excel',
      uploadExcel: 'Subir excel',
      successCreate: 'Se han creado correctamente %SUCCESS% fondos',
      successCreateOne: 'Se ha creado correctamente %SUCCESS% fondo',
      row: 'Fila',
      rowErrors: 'Errores en las siguientes filas',
      send: 'Enviar',
      exportExcel: 'Descarga la plantilla',
      andUpload: 'y súbela',
      emptyName: 'El campo NOMBRE es requerido',
      emptyCountry: 'El campo PAIS es requerido',
    },
    notFound: {
      title: 'Página no encontrada',
      goBack: 'Volver a la home',
    },
    common: {
      all: 'Todos',
      startup: 'Startup',
      mentor: 'Mentor',
      name: 'Nombre',
      description: 'Descripción',
      document: 'Documento',
      documents: 'Documentos',
      send: 'Enviar',
      save: 'Guardar',
      add: 'Añadir',
      new: 'Nuevo',
      create: 'Crear',
      download: 'Descargar',
      upload: 'Subir',
      added: 'Añadido',
      user: 'Usuario',
      users: 'Usuarios',
      view: 'Ver',
      viewMore: 'Ver más',
      viewLess: 'Ver menos',
      duration: 'Duración',
      viewDetail: 'Ver detalle',
      viewDetails: 'Ver detalles',
      edit: 'Editar',
      update: 'Actualizar',
      confirm: 'Confirmar',
      delete: 'Borrar',
      modify: 'Modificar',
      accept: 'Aceptar',
      reject: 'Rechazar',
      cancel: 'Cancelar',
      close: 'Cerrar',
      year: 'Año',
      lastName: 'Apellidos',
      role: 'Rol',
      avatar: 'Avatar',
      invite: 'Invitar',
      email: 'Email',
      loading: 'Cargando...',
      confirmDelete: '¿Estas seguro de borrarlo?',
      checkFormErrors: 'Debes rellenar todos los campos obligatorios',
      genericError: 'Se ha producido un error, revisa que los datos sean correctos e intentalo de nuevo',
      genericResourceNotFound: 'El recurso no existe, no tiene permisos o ha sido borrado',
      error: 'Error',
      errors: 'Errores',
      other: 'Otro',
      status: 'Estado',
      back: 'Atras',
      goBack: 'Volver',
      toolNotFound: 'Herramienta no encontrada',
      permissions: 'Permisos',
      table: 'Tabla',
      chart: 'Gráfica',
      months: {
        1: 'Enero',
        2: 'Febrero',
        3: 'Marzo',
        4: 'Abril',
        5: 'Mayo',
        6: 'Junio',
        7: 'Julio',
        8: 'Agosto',
        9: 'Septiembre',
        10: 'Octubre',
        11: 'Noviembre',
        12: 'Diciembre',
      },
      date: 'Fecha',
      value: 'Valor',
      target: 'Objetivo',
      percentage: 'Porcentaje',
      noData: 'No hay datos',
      noResults: 'No hay resultados',
      currency: 'Divisa',
      notes: 'Anotaciones',
      type: 'Tipo',
      types: 'Tipos',
      yes: 'Si',
      no: 'No',
      to: 'a',
      annual: 'Anual',
      monthly: 'Mensual',
      daily: 'Diario',
      total: 'Total',
      pending: 'Pendiente',
      createdAt: 'F.Creación',
      excel: 'Excel',
      both: 'Ambos',
      report: 'Reporte',
      stop: 'Detener',
      notFound: 'No encontrado',
      actions: 'Acciones',
      auto: 'Automatico',
      input: 'Entrada',
      currencies: {
        currencies: 'Monedas',
        USD: 'Dólar estadounidense ($)',
        EUR: 'Euro (€)',
        JPY: 'Yen (¥)',
        AUD: 'Dólar australiano (A$)',
        CAD: 'Dólar canadiense (C$)',
        GBP: 'Libra esterlina (£)',
        CNY: 'Yuan chino (元/¥)',
        HKD: 'Dólar de Hong Kong (HK$)',
        CHF: 'Franco suizo (CHF)',
        NZD: 'Dólar neozelandés (NZ$)',
      },
      currenciesSymbols: {
        USD: '$',
        EUR: '€',
        JPY: '¥',
        AUD: 'A$',
        CAD: 'C$',
        GBP: '£',
        CNY: '元/¥',
        HKD: 'HK$',
        CHF: 'CHF',
        NZD: 'NZ$',
      },
      time: {
        seconds: 'Segundos',
        minutes: 'Minutos',
        hours: 'Horas',
        days: 'Días',
        weeks: 'Semanas',
        months: 'Meses',
        years: 'Años',
        ago: 'hace %number% %time%',
      },
      language: 'Idioma',
      languages: {
        es: 'Español',
        en: 'Inglés',
        it: 'Italian',
      },
    },
  },
};
