import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Panel from 'components/Panel';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelHeader from 'components/Panel/components/PanelHeader';
import InputSelect from 'components/Inputs/inputSelect';
import OutputValue from 'components/Inputs/outputValue';
import Loading from 'components/Loading';
import Button from 'components/Buttons/Button';
import IOFileLoading from 'components/IOFileLoading/components';
import StartupCaptableDate from 'components/StartupCaptableDate/components';
import EmptyCaptable from 'assets/svg/empty_captable.svg';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import { Download, PlusCircle } from 'lucide-react';
import { Link, useSearchParams } from 'react-router-dom';
import { TOOL_CAP_TABLE } from 'constants/tools';
import { AmplitudeApi } from 'utils/amplitude';
import { formatDate, formatNumber, startupCanEdit } from 'utils/functions';
import { initializeCaptable } from 'routes/Captable/modules/actions';
import { downloadSharesDistributionExcel, getSharesDistribution } from '../modules/actions';
import PopupInfoShares from '../../Securities/components/PopupInfoShares';
import './styles.scss';

const SharesDistribution = (props) => {
  const {
    literals,
    literalsCommon,
    startup,
    match,
    currency,
    captable,
    fetchCaptable,
  } = props;

  const [params] = useSearchParams();
  const captableId = params.get('captable') || null;
  const firstDate = params.get('date');
  const firstGroupBy = params.get('groupBy');

  const [data, setData] = useState();
  const [shareClasses, setShareClasess] = useState([]);
  const [date, setDate] = useState(firstDate);
  const [groupBy, setGroupBy] = useState(firstGroupBy || 'shareholder');
  const [showDetails, setShowDetails] = useState(false);
  const [downloadingExcel, setDownloadingExcel] = useState(false);

  const CAN_EDIT = startupCanEdit(startup, TOOL_CAP_TABLE);

  useEffect(() => {
    if (captable?.selected?.id) {
      AmplitudeApi.logEvent('startup.captable.sharesDistribution.view', {
        captable: captable?.selected?.id,
      });
    }
  }, [captable?.selected?.id]);

  const getData = async (auxDate) => {
    const {
      shareholders, syndicates, shareClasses: auxShareclasses,
    } = await getSharesDistribution(match.params.id, auxDate, { captable: captableId });

    setData({ shareholder: shareholders, syndicate: syndicates });
    setShareClasess(auxShareclasses);
  };

  useEffect(() => {
    if (captable.loaded) {
      const auxDate = date || formatDate(new Date(), { format: 'Y-m-d' });
      setData(null);
      getData(auxDate);
    } else {
      initializeCaptable(fetchCaptable, captable, match.params.id, captableId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captable, date]);

  const handleDownloadExcel = async () => {
    setDownloadingExcel(true);
    await downloadSharesDistributionExcel(match.params.id, captableId, date, 'shares_distribution.xlsx');
    setDownloadingExcel(false);
    AmplitudeApi.logEvent('startup.captable.sharesDistribution.click.downloadButton', {
      captable: captable?.selected?.id,
    });
  };

  const renderRow = (sh) => {
    return (
      <div className={`shares-distribution-table-row ${sh.id === 'total' ? 'total' : ''}`} key={sh.id}>
        { shareClasses.map((sc, i) => (
          <OutputValue key={`${sh.id}-${sc.id}`} className='shares-distribution-table-cell' value={sh.shareClasses[i] ? formatNumber(sh.shareClasses[i], 0, {}) : ''} />
        ))}
        <OutputValue className='shares-distribution-table-cell' value={sh.options ? formatNumber(sh.options, '', {}) : ''} />
        <OutputValue className='shares-distribution-table-cell' value={sh.phantom ? formatNumber(sh.phantom, '', {}) : ''} />
        <OutputValue className='shares-distribution-table-cell' value={sh.ownership ? formatNumber(sh.ownership, '', { dec: 2, symbol: '%' }) : ''} />
        <OutputValue className='shares-distribution-table-cell' value={sh.fullyDiluted ? formatNumber(sh.fullyDiluted, '', { dec: 2, symbol: '%' }) : ''} />
        <OutputValue className='shares-distribution-table-cell' value={sh.capital ? formatNumber(sh.capital, '', { dec: 2, symbol: currency.symbol }) : ''} />
      </div>
    );
  };

  const renderPanel = () => {
    if (!data) {
      return <Loading hide={false} mode='panel' />;
    }

    if (!Object.keys(data[groupBy]).length) {
      return (
        <div className='panel-empty-message full'>
          <img className='arrow-search' src={EmptyCaptable} alt='' />
          <p>{literals.emptyCaptable}</p>
          {
            CAN_EDIT && (
              <Link to={embedView(ROUTE_STARTUP_PATH.setCapTableOperation(match.params.id, captableId, 'new'))}>
                <Button icon={PlusCircle} text={literals.createOperation} />
              </Link>
            )
          }
        </div>
      );
    }

    return (
      <>
        <div className='d-flex justify-content-between my-2'>
          <StartupCaptableDate
            literals={literals}
            startup={startup}
            captable={captable}
            date={date}
            onChange={d => setDate(d)}
          />
          <InputSelect
            className='shares-distribution-select-group'
            preText={literals.lang}
            placeholder={literals.lang}
            onChange={v => setGroupBy(v)}
            value={groupBy}
            options={[
              { id: 'shareholder', name: literals.byShareholder },
              { id: 'syndicate', name: literals.bySyndicate },
            ]}
          />
        </div>

        <div className='shares-distribution-table'>
          <div className='shares-distribution-table-left'>
            <h6 className='shares-distribution-table-cell'>{literals[groupBy]}</h6>
            {Object.keys(data[groupBy]).map(sh => (
              <div
                key={`header-${sh}`}
                className={`shares-distribution-table-cell ${data[groupBy][sh].name === 'Total' ? 'fw-b' : ''}`}
                title={data[groupBy][sh].name}
                onClick={() => setShowDetails({ type: 'shareholder', data: data[groupBy][sh] })}
              >
                {data[groupBy][sh].name}
              </div>
            ))}
          </div>
          <div className='shares-distribution-table-right'>
            <div className='shares-distribution-table-row'>
              {shareClasses.map(sc => (
                <h6 key={`header-${sc.id}`} className='shares-distribution-table-cell'>
                  {sc.name}
                </h6>
              ))}
              <h6 key='header-options' className='shares-distribution-table-cell'>
                {literals.options}
              </h6>
              <h6 key='header-phantom' className='shares-distribution-table-cell'>
                {literals.phantom}
              </h6>
              <h6 key='header-ownership' className='shares-distribution-table-cell'>
                {literals.ownership}
              </h6>
              <h6 key='header-fullyDiluted' className='shares-distribution-table-cell'>
                {literals.fullyDiluted}
              </h6>
              <h6 key='header-capital' className='shares-distribution-table-cell'>
                {literals.capital}
              </h6>
            </div>
            {Object.keys(data[groupBy]).map(sh => (
              renderRow(data[groupBy][sh])
            ))}
          </div>
          { downloadingExcel && <IOFileLoading mode='download' file={{ name: 'shares_distribution.xlsx' }} /> }
        </div>
      </>
    );
  };

  return (
    <Tool className='shares-distribution-wrapper'>
      <Panel>
        <PanelHeader
          title={literals.sharesDistribution}
          actions={data && Object.keys(data[groupBy]).length ? [{
            color: 'secondary',
            text: 'Excel',
            icon: Download,
            onClick: handleDownloadExcel,
          }] : null}
        />
        <PanelBody className='pt-0'>
          { renderPanel() }
        </PanelBody>
      </Panel>
      {
        showDetails && (
          <PopupInfoShares
            {...showDetails}
            date={date}
            literals={literals}
            literalsCommon={literalsCommon}
            match={match}
            currency={currency}
            onClose={() => setShowDetails(false)}
          />
        )
      }
    </Tool>
  );
};

SharesDistribution.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  fetchCaptable: PropTypes.func.isRequired,
};

export default SharesDistribution;
