import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import Popup from 'components/Popup';
import { getFullName } from 'utils/functions';
import { getReportUsers } from 'routes/Reporting/modules/actions';

const UsersPopup = (props) => {
  const {
    literals,
    literalsCommon,
    match,
    id,
    onClose,
  } = props;

  const columnsUser = [
    {
      field: 'avatar', type: 'avatar', width: 45, title: (_, row) => getFullName(row),
    },
    { field: 'firstname', grow: 1, label: literalsCommon.name },
    { field: 'lastname', grow: 1, label: literals.lastName },
  ];

  const fetchUsers = (page, size, search, sort) => {
    return getReportUsers(match.params.id, id, {
      page, size, search, sort,
    });
  };

  return (
    <Popup title={literals.recipients} onClose={onClose}>
      <Table
        key='users-report'
        columns={columnsUser}
        pageSize={10}
        fetch={fetchUsers}
      />
    </Popup>
  );
};

UsersPopup.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UsersPopup;
