export const OPTION_TYPE = {
  PHANTOM: 'phantom',
  STOCK_OPTION: 'stockOption',
};

export const SHAREHOLDER_ROLES = {
  ROLE_ADVISOR: 'advisor',
  ROLE_DIRECTOR: 'director',
  ROLE_EMPLOYEE: 'employee',
  ROLE_FOUNDER: 'founder',
  ROLE_INVESTOR: 'investor',
  ROLE_SECRETARY: 'secretary',
  ROLE_BANK: 'bank',
  ROLE_CORPORATE: 'corporate',
  ROLE_BOARD_MEMBER: 'boardMember',
  ROLE_ANGEL_INVESTOR: 'angelInvestor',
  ROLE_PRIVATE_EQUITY: 'privateEquity',
  ROLE_VENTURE_CAPITAL: 'ventureCapital',
  ROLE_FRIENDS_AND_FAMILY: 'friendsAndFamily',
  ROLE_CORPORATE_VENTURE_CAPITAL: 'corporateVentureCapital',
  ROLE_OTHER: 'other',
};
