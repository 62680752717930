import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import InputSelect from 'components/Inputs/inputSelect';
import InputTextArea from 'components/Inputs/inputTextArea';
import InputText from 'components/Inputs/inputText';
import Button from 'components/Buttons/Button';
import { Trash, GripVertical } from 'lucide-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const PopUpQuestions = (props) => {
  const {
    onClose,
    submit,
    formQuestion,
    literals,
    setFormQuestion,
    langSelected,
    savingQuestion,
    questionError,
    handleLangChange,
    selectIcon,
    langs,
  } = props;

  const optionsMandatory = [
    { id: false, name: literals.negative },
    { id: true, name: literals.afirmative },
  ];
  const optionsTypes = [
    { id: 'text', name: literals.types.text, icon: selectIcon('text') },
    { id: 'textarea', name: literals.types.textarea, icon: selectIcon('textarea') },
    { id: 'select', name: literals.types.select, icon: selectIcon('select') },
    { id: 'radiobutton', name: literals.types.radiobutton, icon: selectIcon('radiobutton') },
    { id: 'checkbox', name: literals.types.checkbox, icon: selectIcon('checkbox') },
    { id: 'list', name: literals.types.list, icon: selectIcon('list') },
    { id: 'document', name: literals.types.document, icon: selectIcon('document') },
    { id: 'date', name: literals.types.date, icon: selectIcon('date') },
    { id: 'number', name: literals.types.number, icon: selectIcon('number') },
  ];

  const optionsLangs = langs.map((elem) => {
    return { id: elem, name: elem };
  });

  const valueLang = (element) => {
    return element[langSelected];
  };

  const handleNewAnswer = () => {
    const newId = `newId:${Math.random()}`;
    const newAnswer = {
      id: newId,
      text: { en: '', es: '' },
      order: formQuestion.answers.length + 1,
      score: 0,
    };
    const aux = { ...formQuestion };
    aux.answers.push(newAnswer);
    setFormQuestion({
      ...aux,
    });
  };

  const handleAnswerChange = (value, event) => {
    const aux = { ...formQuestion };
    aux.answers.forEach((answer) => {
      if (answer.id === event.target.name) {
        // eslint-disable-next-line no-param-reassign
        answer.text[langSelected] = value;
      }
    });
    setFormQuestion({
      ...aux,
    });
  };

  const reorder = (list) => {
    return list.map((elem, index) => ({ ...elem, order: index + 1 }));
  };

  const handleOrderChange = (list, startIndex, endIndex) => {
    const answers = [...list.answers];
    const [removed] = answers.splice(startIndex, 1);
    answers.splice(endIndex, 0, removed);

    return { ...list, answers: reorder(answers) };
  };

  const handlerDeleteAnswer = (event) => {
    const { id } = event.currentTarget;
    setFormQuestion((prevFormQuestion) => {
      const answers = prevFormQuestion.answers.filter(answer => answer.id !== id);
      return { ...prevFormQuestion, answers: reorder(answers) };
    });
  };

  // eslint-disable-next-line consistent-return
  const renderAnswer = (element, index) => {
    if (element) {
      return (
        <Draggable key={element.id} draggableId={element.id} index={index}>
          {draggableProvider => (
            <li
              {...draggableProvider.draggableProps}
              ref={draggableProvider.innerRef}
              {...draggableProvider.dragHandleProps}
            >
              <div className='questions_answer'>
                <GripVertical className='questions_answer-grip' />
                <p className='questions_answer-order'>{index + 1}</p>
                <InputText
                  name={element.id}
                  value={valueLang(element.text)}
                  maxLength={99999}
                  onChange={handleAnswerChange}
                  errors={questionError?.answers}
                />
                <div className='questions_answer-icons'>
                  <div id={element.id} onClick={handlerDeleteAnswer}>
                    <Trash size={16} className='questions_answer-icons_icon trash' />
                  </div>
                </div>
              </div>
            </li>
          )}
        </Draggable>
      );
    }
  };

  const handleTitleChange = (value) => {
    const aux = { ...formQuestion };
    aux.title[langSelected] = value;
    setFormQuestion({
      ...aux,
    });
  };

  const handleTooltipChange = (value) => {
    const aux = { ...formQuestion };
    aux.tooltip[langSelected] = value;
    setFormQuestion({
      ...aux,
    });
  };

  const handlePlaceholderChange = (value) => {
    const aux = { ...formQuestion };
    aux.placeholder[langSelected] = value;
    setFormQuestion({
      ...aux,
    });
  };

  const handleMandatoryChange = (value) => {
    const aux = { ...formQuestion };
    aux.mandatory = value;
    setFormQuestion({
      ...aux,
    });
  };

  const handleTypeChange = (value) => {
    const aux = { ...formQuestion };
    aux.type = value;
    setFormQuestion({
      ...aux,
    });
  };

  return (
    <Popup onClose={onClose}>
      <form onSubmit={submit}>
        {`ID: ${formQuestion.id}`}
        <div className='row'>
          <div className='col-12 col-md-3 offset-md-9'>
            <InputSelect
              preText={literals.lang}
              name='langSelected'
              options={optionsLangs}
              value={langSelected}
              onChange={handleLangChange}
              errors={questionError?.type}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <InputSelect
              preText={literals.type}
              name='type'
              options={optionsTypes}
              value={formQuestion.type}
              onChange={handleTypeChange}
              errors={questionError?.type}
            />
          </div>
          <div className='col-12 col-md-6'>
            <InputSelect
              preText={literals.mandatory}
              name='mandatory'
              options={optionsMandatory}
              value={formQuestion.mandatory}
              onChange={handleMandatoryChange}
              errors={questionError?.mandatory}
            />
          </div>
        </div>
        <InputTextArea
          preText={literals.text}
          name='title'
          value={valueLang(formQuestion.title)}
          maxLength={99999}
          onChange={handleTitleChange}
          errors={questionError?.title}
        />
        <InputTextArea
          preText='tooltip'
          name='tooltip'
          value={valueLang(formQuestion.tooltip)}
          maxLength={99999}
          onChange={handleTooltipChange}
          errors={questionError?.tooltip}
        />
        {
          formQuestion.type !== 'document'
          && formQuestion.type !== 'radiobutton'
          && formQuestion.type !== 'checkbox'
          && formQuestion.type !== 'list'
          && formQuestion.type !== 'date'
          && (
            <InputTextArea
              preText='placeholder'
              name='placeholder'
              value={valueLang(formQuestion.placeholder)}
              maxLength={99999}
              onChange={handlePlaceholderChange}
              errors={questionError?.placeholder}
            />
          )
        }
        {
          formQuestion.type !== 'text'
          && formQuestion.type !== 'textarea'
          && formQuestion.type !== 'document'
          && formQuestion.type !== 'number'
          && formQuestion.type !== 'date'
          && (
            <DragDropContext onDragEnd={(result) => {
              const { source, destination } = result;
              if (!destination || source.index === destination.index) {
                return;
              }
              setFormQuestion(prevFormQuestion => handleOrderChange(prevFormQuestion, source.index, destination.index));
            }}
            >

              <p className='questions_textAnswer'>Respuestas</p>
              <Droppable droppableId='answersList'>
                {droppableProvider => (
                  <ul
                    className='answersList'
                    {...droppableProvider.droppableProps}
                    ref={droppableProvider.innerRef}
                  >
                    {
                      formQuestion.answers && formQuestion.answers.map((answer, index) => renderAnswer(answer, index))
                    }
                    {droppableProvider.placeholder}
                  </ul>
                )}
              </Droppable>
              <div className='AddButton'>
                <Button
                  color='outline-primary'
                  text='ADD OPTION'
                  onClick={() => handleNewAnswer()}
                />
              </div>
            </DragDropContext>
          )
        }
        <div className='buttons'>
          <Button text='Guardar' type='submit' loading={savingQuestion} />
        </div>
      </form>
    </Popup>
  );
};

PopUpQuestions.propTypes = {
  literals: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  formQuestion: PropTypes.object.isRequired,
  setFormQuestion: PropTypes.func.isRequired,
  langSelected: PropTypes.string.isRequired,
  savingQuestion: PropTypes.bool.isRequired,
  questionError: PropTypes.bool.isRequired,
  handleLangChange: PropTypes.func.isRequired,
  langs: PropTypes.array.isRequired,
  selectIcon: PropTypes.func.isRequired,
};

export default PopUpQuestions;
