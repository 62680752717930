import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Button from 'components/Buttons/Button';
import InputFileZone from 'components/Inputs/inputFileZone';
import IOFileLoading from 'components/IOFileLoading/components';
import { ChevronLeft, CloudUpload } from 'lucide-react';
import { literalTemplate } from 'utils/language';
import { createFundMassive, downloadExcelFundsTemplate } from '../modules/actions';

const FundUploadExcelPopup = ({ literals, onClose }) => {
  const initialExcelData = {
    file: null,
    loading: false,
    response: null,
  };
  const [excelData, setExcelData] = useState(initialExcelData);
  const [downloadingExcel, setDownloadingExcel] = useState(false);

  const handleDownloadExcelTemplate = async () => {
    setDownloadingExcel(true);
    await downloadExcelFundsTemplate('funds_template.xlsx');
    setDownloadingExcel(false);
  };

  const renderExcelForm = () => {
    if (excelData.response) {
      const numSuccess = excelData?.response?.success?.length ?? 0;
      const successMessage = literalTemplate(`<span>${numSuccess !== 1 ? literals.successCreate : literals.successCreateOne}</span>`, {
        SUCCESS: `<span class='fw-b'>${numSuccess}</span>`,
      });

      return (
        <div className='massiveExcelResponse'>
          <div dangerouslySetInnerHTML={{ __html: successMessage }} />
          {excelData.response.errors?.length ? (
            <>
              <div className='fw-b mt-3'>{literals.rowErrors}</div>
              <ul>
                {excelData.response.errors.map((error) => {
                  let errorMessage = '';
                  switch (error.error) {
                    case 'empty_required_name':
                      errorMessage = literals.emptyName;
                      break;
                    case 'empty_required_country':
                      errorMessage = literals.emptyCountry;
                      break;
                    default:
                      errorMessage = error.error;
                  }

                  return (
                    <li className='ml-2'>
                      <span className='fw-b'>{`${literals.row} ${error.row}: `}</span>
                      <span>{errorMessage}</span>
                    </li>
                  );
                })}
              </ul>
            </>
          ) : null}
          <div className='buttons mt-3'>
            <Button text='Atras' icon={ChevronLeft} onClick={() => setExcelData(initialExcelData)} />
          </div>
        </div>
      );
    }

    const submitFormExcel = async () => {
      setExcelData({ ...excelData, loading: true, errors: [] });
      const response = await createFundMassive(excelData.file);
      setExcelData({ ...excelData, loading: false, response });
    };

    return (
      <>
        <span className='h6 fw-b link-effect' onClick={handleDownloadExcelTemplate}>{literals.exportExcel}</span>
        <span className='h6 fw-b'>{` ${literals.andUpload}`}</span>
        <InputFileZone
          onChange={file => setExcelData({ ...excelData, file })}
          value={excelData.file}
        />
        <div className='buttons'>
          <Button text={literals.send} icon={CloudUpload} onClick={submitFormExcel} disabled={!excelData.file} loading={excelData.loading} />
        </div>
      </>
    );
  };

  return (
    <Popup onClose={onClose}>
      {renderExcelForm()}
      { downloadingExcel && <IOFileLoading mode='download' file={{ name: 'funds_template.xlsx' }} /> }
    </Popup>
  );
};

FundUploadExcelPopup.propTypes = {
  literals: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FundUploadExcelPopup;
