import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getBrowserPreferences, setBrowserPreferences } from 'utils/browser';
import fetchLogoutAction from './modules/actions';

const Logout = ({ logout }) => {
  useEffect(() => {
    const pushToken = getBrowserPreferences('pushToken');
    if (pushToken) {
      setBrowserPreferences('pushToken', 'logout');
    }
    logout({ pushToken });
  }, [logout]);

  return <div />;
};

const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(fetchLogoutAction, dispatch),
});

Logout.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(Logout);
