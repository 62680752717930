export const DEFAULT_TYPE_GROUP = {
  OWNER: 'owner',
  ADMIN: 'admin',
  USER: 'user',
  SHAREHOLDER: 'shareholder',
  OTHER: 'other',
};

export const PERMISSION_SCOPES = {
  STARTUP: 'startup',
};

export const PERMISSION_LEVELS = {
  EXTERNAL: -1,
  UNAUTHORIZED: 0,
  VIEW: 1,
  EDIT: 2,
  MANAGE: 3,
};

export const PERMISSION_MODES = {
  PUBLIC: 'public',
  RESTRICTED: 'restricted',
  PRIVATE: 'private',
};

export const PERMISSION_EXTERNAL = {
  NONE: null,
  PRIVATE: 'private',
  SHARED: 'shared',
};

export const PERMISSION_TYPES = {
  REPORT: 'report',
  REPORT_TEMPLATE: 'report_template',
  CAPTABLE: 'startup_captable',
  WATERFALL: 'waterfall',
  SHAREHOLDER: 'shareholder',
  SYNDICATE: 'syndicate',
  SHARECLASS: 'share_class',
  VESTING_PLAN: 'vesting_plan',
  KPI: 'kpi',
  KPI_RELATION: 'kpi_relation',
  FUNDRAISE: 'fundraise',
  FOLDER: 'sl_folder',
  DOCUMENT: 'internal_document',
  LINK: 'link',
};

export const PERMISSION_MEMBERS_TYPES = {
  USER: 'user',
  GROUP: 'group',
  TOOL: 'tool',
  PUBLIC: 'public',
};
