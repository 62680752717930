import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import ReactECharts from 'echarts-for-react';
import SkeletonBarChart from 'components/Loading/skeletonBarChart';
import { formatDate, formatNumber } from 'utils/functions';
import './styles.scss';

const PanelPPS = ({ literals, stats = null, currency }) => {
  let graphValuation = null;
  if (stats !== null) {
    graphValuation = {
      categories: stats.aggregations.valuation.map(item => item.date),
      data: stats.aggregations.valuation.map(item => Number(item.value.toFixed(2))),
    };
  }

  const getLineOptions = (categories, value) => {
    return {
      grid: {
        top: '5%', bottom: '30%',
      },
      tooltip: {
        trigger: 'axis',
        valueFormatter: v => formatNumber(v, 0, { symbol: currency.symbol }),
      },
      xAxis: {
        type: 'category',
        data: categories,
        axisLabel: {
          rotate: 60,
          formatter: formatDate,
        },
      },
      yAxis: { type: 'value' },
      series: [{ data: value, type: 'line' }],
    };
  };

  return (
    <Panel>
      <PanelHeader title={literals.ppsEvolution} />
      <PanelBody className='panel-summary-options'>
        { graphValuation
          ? <ReactECharts option={getLineOptions(graphValuation.categories, graphValuation.data)} />
          : <SkeletonBarChart className='mb-4' height='275px' />
        }
      </PanelBody>
    </Panel>
  );
};

PanelPPS.propTypes = {
  literals: PropTypes.object.isRequired,
  stats: PropTypes.object,
  currency: PropTypes.object.isRequired,
};

export default PanelPPS;
