import { connect } from 'react-redux';
import AdminFundraises from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.fundraise,
  literalsCommon: state.i18n.literals.common,
  currency: state.global.currency,
});

export default connect(mapStateToProps)(AdminFundraises);
