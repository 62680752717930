/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel/components';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Table from 'components/Table';
import { getFundraises } from '../modules/actions';
import StartupFundraiseRow from './StartupFundraiseRow';
import './styles.scss';

const MyInvestmentsOpportunities = (props) => {
  const { literals } = props;

  const breadcrumb = [
    { name: literals.title },
    { name: literals.opportunities },
  ];

  return (
    <div className='myInvestments-opportunities'>
      <Panel>
        <PanelHeader breadcrumb={breadcrumb} />
        <PanelBody>
          <Table
            mode='components'
            component={StartupFundraiseRow}
            componentProps={props}
            componentColumns={{
              xs: 1, sm: 1, md: 2, lg: 3, xl: 3,
            }}
            pageSize={12}
            fetch={getFundraises}
          />
        </PanelBody>
      </Panel>
    </div>
  );
};

MyInvestmentsOpportunities.propTypes = {
  literals: PropTypes.object.isRequired,
};

export default MyInvestmentsOpportunities;
