import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import Popup from 'components/Popup';
import Button from 'components/Buttons/Button';
import KpiRelationValues from 'routes/Performance/components/KpiRelationValues';
import { KPI_UNIT } from 'constants/kpis';
import { formatNumber, getTranslation } from 'utils/functions';
import { formatKpiDiff, formatKpiValue } from 'routes/Performance/utils/functions';
import { fetchKpiRelations } from '../modules/actions';

const PopupKpiInfo = (props) => {
  const {
    literals,
    literalsCommon,
    kpi,
    lang,
    currency,
    onClose,
  } = props;

  const [selectedRelation, setSelectedRelation] = useState();
  const [goBack, setGoBack] = useState(true);

  const handleSelectedRelation = async (relation) => {
    setSelectedRelation({ ...relation, kpi: { ...kpi } });
  };

  const getKpiRelations = async (page, size, search, filters) => {
    const kpiRelations = await fetchKpiRelations(kpi.id, {
      page, size, search, filters,
    });

    if (kpiRelations.total === 1) {
      setGoBack(false);
      await handleSelectedRelation(kpiRelations.items[0]);
    }

    return kpiRelations;
  };

  const getKpiSymbol = () => {
    if (kpi.unit === KPI_UNIT.CURRENCY) {
      return currency.symbol;
    }
    if (kpi.unit === KPI_UNIT.PERCENT) {
      return '%';
    }
    return '';
  };

  const renderKpiInfo = () => {
    return (
      <div className='box box-padding mb-4'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <h6 className='fw-b'>{literalsCommon.name}</h6>
            <p>{getTranslation(kpi?.name, lang)}</p>
          </div>
          {selectedRelation && (
            <div className='col-12 col-md-6'>
              <h6 className='fw-b'>{literalsCommon[selectedRelation.scope.type]}</h6>
              <p>{selectedRelation?.name || '-'}</p>
            </div>
          )}
          <div className='col-12'>
            <h6 className='fw-b'>{literalsCommon.description}</h6>
            <p>{getTranslation(kpi?.description, lang)}</p>
          </div>
          <div className='col-12 col-md-4'>
            <h6 className='fw-b'>{literals.periodicity}</h6>
            <p>{literals[kpi.periodicity]}</p>
          </div>
          <div className='col-12 col-md-5'>
            <h6 className='fw-b'>{literals.unit}</h6>
            <p>{`${literals[kpi.unit]} ( ${kpi.min ?? '-∞'} / ${kpi.max ?? '+∞'} )`}</p>
          </div>
          <div className='col-12 col-md-3'>
            <h6 className='fw-b'>{literals.positive}</h6>
            <p>{literals[kpi.positive]}</p>
          </div>
        </div>
      </div>
    );
  };

  const columns = [
    {
      field: 'name', label: literalsCommon.name, grow: 1,
    },
    {
      field: 'scope',
      label: literalsCommon.type,
      width: 80,
      preRender: v => literalsCommon[v.type],
    },
    {
      field: 'value',
      label: literalsCommon.date,
      width: 80,
      preRender: v => v.date || '-',
    },
    {
      field: 'value',
      label: literalsCommon.value,
      width: 80,
      preRender: v => formatNumber(v.actual, 0, { symbol: getKpiSymbol() }),
    },
    {
      field: 'value',
      label: literals.previous,
      width: 80,
      preRender: (v, row) => (
        <span>
          <span className='value'>{formatKpiValue(v.previous, 2, row.kpi, currency)}</span>
          <div>{v.actual ? formatKpiDiff(v.difference) : null}</div>
        </span>
      ),
    },
  ];

  if (selectedRelation) {
    return (
      <Popup title={literals.relationValues} onClose={onClose}>
        { renderKpiInfo() }
        <KpiRelationValues
          literals={literals}
          literalsCommon={literalsCommon}
          relation={selectedRelation}
          currency={currency}
          canEdit
        />
        <div className='buttons'>
          { goBack && (
            <Button
              color='secondary'
              text={literals.back}
              onClick={() => setSelectedRelation(null)}
            />
          )}
          <Button
            text={literalsCommon.close}
            onClick={onClose}
          />
        </div>
      </Popup>
    );
  }

  return (
    <Popup title={literals.kpiRelations} onClose={onClose}>
      { renderKpiInfo() }
      <Table
        className='kpi-relations-table'
        columns={columns}
        pageSize={10}
        fetch={getKpiRelations}
        onClickRow={row => handleSelectedRelation(row)}
      />
    </Popup>
  );
};

PopupKpiInfo.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  kpi: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  currency: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};

export default PopupKpiInfo;
