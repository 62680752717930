import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@mui/material';
import { CloudDownload, CloudUpload } from 'lucide-react';
import './styles.scss';

const IOFileLoading = ({ file, mode = 'upload' }) => {
  const { name } = file;
  return (
    <div className='uploading-file'>
      {mode === 'download' ? <CloudDownload /> : <CloudUpload />}
      <div className='uf-info'>
        <span className='pt-2 pb-2 d-block'>{name}</span>
        <LinearProgress />
      </div>
    </div>
  );
};

IOFileLoading.propTypes = {
  file: PropTypes.object.isRequired,
  mode: PropTypes.string,
};

export default IOFileLoading;
