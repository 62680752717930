import NotesInstance from 'modules/notes/repository';

export function getNote(type, id) {
  return NotesInstance.getNote(type, id);
}

export function postNote(type, id, note) {
  return NotesInstance.postNote(type, id, { note });
}

export function deleteNote(type, id) {
  return NotesInstance.deleteNote(type, id);
}
