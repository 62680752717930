import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Globe, Lock } from 'lucide-react';
import { PERMISSION_MEMBERS_TYPES, PERMISSION_MODES } from 'constants/permissions';
import './styles.scss';
import { literalTemplate } from 'utils/language';
import { connect } from 'react-redux';

const IconLockShared = (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-person-lock permission-icon' viewBox='0 0 16 16'>
    <path d='M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 5.996V14H3s-1 0-1-1 1-4 6-4c.564 0 1.077.038 1.544.107a4.524 4.524 0 0 0-.803.918A10.46 10.46 0 0 0 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h5ZM9 13a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-2Zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1Z' />
  </svg>
);

const IconBuildingShared = (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-building-gear permission-icon' viewBox='0 0 16 16'>
    <path d='M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v6.5a.5.5 0 0 1-1 0V1H3v14h3v-2.5a.5.5 0 0 1 .5-.5H8v4H3a1 1 0 0 1-1-1V1Z' />
    <path d='M4.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm4.386 1.46c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z' />
  </svg>
);

const PermissionLabel = (props) => {
  const {
    permission,
    literals,
  } = props;

  const {
    mode, access, owner,
  } = permission || {};
  const config = useMemo(() => {
    const types = {
      group: 0,
      user: 0,
    };
    let message = null;
    Object.keys(access || {}).forEach((key) => {
      const [type, ...ids] = key.split('_');
      const member = { type, id: ids.join('_') };

      if ([PERMISSION_MEMBERS_TYPES.USER, PERMISSION_MEMBERS_TYPES.GROUP].includes(member.type)) {
        if (!(member.type === PERMISSION_MEMBERS_TYPES.USER && member.id === owner)) {
          types[member.type] += 1;
        }
      }
    });
    let messageType = null;
    switch (mode) {
      case PERMISSION_MODES.PUBLIC:
        messageType = literals.public;
        break;
      case PERMISSION_MODES.PRIVATE:
        messageType = literals.private;
        break;
      default:
        messageType = literals.restricted;
        break;
    }

    if (types.group > 0 && types.user > 0) {
      message = literals.sharedBoth;
    } else if (types.group > 0) {
      message = literals.sharedGroups;
    } else if (types.user > 0) {
      message = literals.sharedUsers;
    }
    return {
      shared: types.group > 0 || types.user > 0,
      message: message ? `${messageType}: ${literalTemplate(message, { users: types.user, groups: types.group })}` : messageType,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderIcon = () => {
    if (config.shared) {
      switch (mode) {
        case PERMISSION_MODES.PUBLIC:
          return <Globe className='permission-icon' />;
        case PERMISSION_MODES.PRIVATE:
          return IconLockShared;
        default:
          return IconBuildingShared;
      }
    } else {
      switch (mode) {
        case PERMISSION_MODES.PUBLIC:
          return <Globe className='permission-icon' />;
        case PERMISSION_MODES.PRIVATE:
          return <Lock className='permission-icon' />;
        default:
          return null;
      }
    }
  };

  if (!config.shared && mode === PERMISSION_MODES.RESTRICTED) {
    return null;
  }

  return permission.level > 0 && (
    <div className='pemission-label' title={config.message}>
      {renderIcon()}
    </div>
  );
};

PermissionLabel.propTypes = {
  permission: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.permissions,
  };
}

export default connect(mapStateToProps)(PermissionLabel);
