import React from 'react';
import PropTypes from 'prop-types';
import { ROUTE_MENTOR_PATH } from 'routes';
import { Home, MessageSquareText, User } from 'lucide-react';
import RenderRow from './RenderRow';

const NavMentor = (props) => {
  const {
    literals,
    toggleMenu,
    user,
    route,
  } = props;

  const navRows = [
    {
      path: ROUTE_MENTOR_PATH.HOME,
      to: ROUTE_MENTOR_PATH.HOME,
      name: literals.summary,
      icon: Home,
      exact: true,
    },
    {
      path: ROUTE_MENTOR_PATH.EDIT_MENTOR,
      to: ROUTE_MENTOR_PATH.setEditMentor(user?.mentor?.id),
      name: literals.profile,
      icon: User,
    },
    {
      path: ROUTE_MENTOR_PATH.MENTORINGS,
      to: ROUTE_MENTOR_PATH.MENTORINGS,
      name: literals.mentorings,
      icon: MessageSquareText,
    },
  ];

  return navRows.map(row => (
    <RenderRow key={row.name} toggleMenu={toggleMenu} route={route} {...row} />
  ));
};

NavMentor.propTypes = {
  user: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

export default NavMentor;
