import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Panel from 'components/Panel/';
import Tool from 'components/Tool';
import PanelTab from 'components/Panel/components/PanelTab';
import Loading from 'components/Loading';
import { formatErrors } from 'utils/functions';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import FormGeneral from './FormGeneral';
import FormEmails from './FormEmails';
import FormForms from './FormForms';
import FormLegal from './FormLegal';
import {
  editPlatform,
  editQuestion,
  createQuestion,
  fetchPlatform,
  fetchQuestions,
} from '../modules/actions';
import './styles.scss';


const Platform = (props) => {
  const {
    literals,
    initPlatform,
    lang,
    LANGS,
    literalsCommon,
  } = props;

  const [platform, setPlatform] = useState(null);
  const [questions, setQuestions] = useState(null);


  const getPlatform = async (edited) => {
    setPlatform(null);
    const responsePlatform = await fetchPlatform();
    if (edited) {
      initPlatform();
    }
    setPlatform(responsePlatform);
    const responseQuestions = await fetchQuestions(0, 9999);
    const fullQuestions = {};
    responseQuestions.items.forEach((question) => {
      fullQuestions[question.id] = question;
    });
    setQuestions(fullQuestions);
  };

  const getQuestions = async () => {
    const responseQuestions = await fetchQuestions(0, 9999);
    const fullQuestions = {};
    responseQuestions.items.forEach((question) => {
      fullQuestions[question.id] = question;
    });
    setQuestions(fullQuestions);
  };

  const savePlatform = async (data) => {
    try {
      await editPlatform(data);
      getPlatform(true);
      return true;
    } catch (e) {
      return formatErrors(e);
    }
  };

  const submitQuestion = async (data) => {
    try {
      if (data.id) {
        await editQuestion(data.id, data);
      } else {
        await createQuestion(data);
      }
      return true;
    } catch (e) {
      return formatErrors(e);
    }
  };

  useEffect(() => {
    getPlatform();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const general = {
    name: platform?.name,
    logo: platform?.images?.logo,
    favicon: platform?.images?.favicon,
    bgPublicDesktop: platform?.images?.bgPublicDesktop,
    bgPublicMobile: platform?.images?.bgPublicMobile,
    colors: platform?.colors,
    analytics: {
      ...platform?.analytics,
      ga4: {
        ...(platform?.analytics?.ga4 || {}),
        credentials: JSON.stringify(platform?.analytics?.ga4?.credentials || {}),
      },
    },
    cmp: platform?.cmp,
    properties: platform?.properties,
    oauth: platform?.oauth,
    emailBot: platform?.emailBot,
    signaturit: platform?.signaturit,
  };

  const mainTabs = [
    { key: 'generals', name: literals.generals },
    { key: 'emails', name: literals.email },
    { key: 'forms', name: literals.forms },
    { key: 'legal', name: literals.legal },
  ];

  return (
    <Tool className='toolAdminPlatform'>
      {
        platform === null ? (
          <Loading hide={false} mode='tool' />
        ) : (
          <Panel margin={0}>
            <PanelHeader
              title={literals.title}
              tabs={mainTabs}
            />
            <PanelBody>
              <PanelTab key='generals'>
                <FormGeneral literals={literals} literalsCommon={literalsCommon} general={general} onSubmit={savePlatform} />
              </PanelTab>
              <PanelTab key='emails'>
                <FormEmails literals={literals} emails={platform.emails} langs={LANGS} onSubmit={savePlatform} />
              </PanelTab>
              <PanelTab key='forms'>
                {
                  questions ? (
                    <FormForms
                      getQuestions={getQuestions}
                      literalsCommon={literalsCommon}
                      literals={literals}
                      forms={platform.forms}
                      properties={platform.properties}
                      questions={questions}
                      langs={LANGS}
                      lang={lang}
                      onSubmitForms={savePlatform}
                      onSubmitQuestion={submitQuestion}
                    />
                  ) : (
                    <Loading mode='tool' />
                  )
                }
              </PanelTab>
              <PanelTab key='legal'>
                <FormLegal literals={literals} legal={platform.legal} langs={LANGS} onSubmit={savePlatform} />
              </PanelTab>
            </PanelBody>
          </Panel>
        )
      }
    </Tool>
  );
};

Platform.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  initPlatform: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  LANGS: PropTypes.array.isRequired,
};

export default Platform;
