import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchFormStartupAction } from 'components/InternalForm/modules/actions';
import { fetchCompanyAction } from '../../Home/modules/actions';
import ViewInfo from '../components';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.onboarding,
    literalsCommon: state.i18n.literals.common,
    startup: state.global.startup,
    company: state.company,
    lang: state.i18n.language,
    forms: state.forms,
    platform: state.global.platform,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCompany: bindActionCreators(fetchCompanyAction, dispatch),
    fetchFormStartup: bindActionCreators(fetchFormStartupAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewInfo);
