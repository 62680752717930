import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel/components';
import Tool from 'components/Tool';
import useDialog from 'components/Dialog/components/useDialog';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import InternalForm from 'components/InternalForm';
import Loading from 'components/Loading';
import roles, { ROLE_ADMIN, ROLE_MENTOR, ROLE_USER } from 'constants/roles';
import { ROUTE_MENTOR_PATH, ROUTE_STARTUP_PATH, ROUTE_ADMIN_PATH } from 'routes';
import { useNavigate } from 'react-router-dom';
import './styles.scss';

function EditInfo({
  user,
  company = null,
  fetchCompany = () => {},
  literals,
  type,
  match,
  editStartup,
  editMentor,
  editInvestor,
}) {
  const { dialog } = useDialog();
  const navigate = useNavigate();

  useEffect(() => {
    if (type === 'startup' && !company?.loading) {
      (async () => {
        if (!company || company.company.id !== match.params.id) {
          await fetchCompany(match.params.id);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, match.params.id]);

  const getAnswers = () => {
    if (type === roles.ROLE_MENTOR) {
      return user.mentor;
    }
    if (type === roles.ROLE_INVESTOR) {
      return user.investor;
    }
    return company.company;
  };

  const goBack = () => {
    if (type === 'startup' && user?.role?.includes(ROLE_USER)) {
      navigate(ROUTE_STARTUP_PATH.setViewStartup(company.company.id));
    } else if (type === 'mentor' && user?.role?.includes(ROLE_MENTOR)) {
      navigate(ROUTE_MENTOR_PATH.HOME);
    } else if (type === 'startup' && user?.role?.includes(ROLE_ADMIN)) {
      navigate(ROUTE_ADMIN_PATH.STARTUPS);
    }
  };

  const updateInfo = async (answers) => {
    if (type === 'startup') {
      await editStartup(match.params.id, answers);
    } else if (type === 'mentor') {
      await editMentor(answers, user);
    } else if (type === 'investor') {
      await editInvestor(answers, user);
    }
    await dialog({
      text: literals.profileUpdated,
      type: 'alert',
    });
    goBack();
  };

  const formButtons = [
    { type: 'submit', onClick: updateInfo, text: literals.save },
    { onClick: goBack, text: literals.cancel, color: 'secondary' },
  ];

  return (
    <Tool>
      <div className='edit-info-wrapper'>
        <Panel>
          <PanelHeader title={literals.editProfile} />
          <PanelBody>
            {
              type === 'startup' && !(company.company.id === match.params.id && company.loaded) ? (
                <Loading hide={false} mode='tool' />
              ) : (
                <InternalForm answers={getAnswers()} type={type} buttons={formButtons} />
              )
            }
          </PanelBody>
        </Panel>
      </div>
    </Tool>
  );
}

EditInfo.propTypes = {
  user: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  company: PropTypes.object,
  fetchCompany: PropTypes.func,
  editStartup: PropTypes.func.isRequired,
  editMentor: PropTypes.func.isRequired,
  editInvestor: PropTypes.func.isRequired,
};

export default EditInfo;
