import React from 'react';
import PropTypes from 'prop-types';
import InputSelect from 'components/Inputs/inputSelect';
import Button from 'components/Buttons/Button';
import { useNavigate } from 'react-router-dom';
import { Home, PlusCircle } from 'lucide-react';
import { getStartupVerifiedIcon } from 'utils/functions';
import { ROUTE_PATH, ROUTE_STARTUP_PATH } from 'routes';

const StartupsSelect = ({
  literals = {},
  user,
  platform,
  startup = null,
}) => {
  const navigate = useNavigate();

  const startups = startup && !user.startups.find(s => s.id === startup.id)
    ? [...user.startups, startup] : user.startups;

  const handleChangeStartup = (selectedStartupId) => {
    if (startups.length) {
      const foundStartup = startups.find(elem => elem.id === selectedStartupId);
      if (foundStartup) {
        navigate(ROUTE_STARTUP_PATH.setHome(selectedStartupId));
      }
    }
  };

  function renderStartupsSelect() {
    if (startup) {
      let buttonAdd = (
        <Button
          text={platform?.properties?.signup?.prehome ? literals.addCompany : literals.prehome}
          icon={platform?.properties?.signup?.prehome ? PlusCircle : Home}
          onClick={() => navigate(ROUTE_PATH.PREHOME)}
        />
      );

      if (startups.length === 1 && !platform?.properties?.signup?.prehome) {
        buttonAdd = null;
      }

      return (
        <InputSelect
          value={startup.id}
          onChange={handleChangeStartup}
          preText={null}
          button={buttonAdd}
          zIndex={10}
          options={startups.map(auxStartup => ({
            id: auxStartup.id,
            name: auxStartup.name,
            iconRight: getStartupVerifiedIcon(auxStartup.verified),
          }))}
        />
      );
    }

    return null;
  }

  return startups ? renderStartupsSelect() : null;
};

StartupsSelect.propTypes = {
  literals: PropTypes.object,
  user: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
  startup: PropTypes.object,
};

export default StartupsSelect;
