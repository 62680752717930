import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import TableComponents from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.table,
  literalsCommon: state.i18n.literals.common,
});

export default withRouter(connect(mapStateToProps)(TableComponents));
