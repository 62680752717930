import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelTab from 'components/Panel/components/PanelTab';
import Button from 'components/Buttons/Button';
import InputEmail from 'components/Inputs/inputEmail';
import InputRichText from 'components/Inputs/inputRichText';
import OutputValue from 'components/Inputs/outputValue';
import OutputErrors from 'components/Inputs/outputErrors';
import { Trash } from 'lucide-react';
import { useCounter } from 'utils/customHooks';
import { postFundraiseInvite } from '../modules/actions';
import './styles.scss';

const FundraiseInvitePopup = (props) => {
  const {
    literals,
    literalsCommon,
    fundraise,
    user,
    onClose,
  } = props;

  const [inviteForm, setInviteForm] = useState({ emails: { 0: '' }, message: '' });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const counter = useCounter(1);
  const lastEmailRef = useRef(null);
  const [textCopied, setTextCopied] = useState(false);

  const emailKeys = Object.keys(inviteForm.emails);

  useEffect(() => {
    if (lastEmailRef.current) {
      lastEmailRef.current.querySelector('input').focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailKeys.length]);

  const handleInvite = async () => {
    setLoading(true);
    try {
      await postFundraiseInvite(fundraise, {
        emails: Object.values(inviteForm.emails),
        message: inviteForm.message,
      });
      onClose();
    } catch (err) {
      setErrors(err.body);
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const auxEmails = inviteForm.emails;
      auxEmails[counter.value] = '';
      setInviteForm(prev => ({ ...prev, emails: auxEmails }));
      counter.increase();
    }
  };

  const copyToClipboard = (text) => {
    try {
      navigator.clipboard.writeText(text);
      setTextCopied(true);
      setTimeout(() => {
        setTextCopied(false);
      }, 3000);
    } catch (e) {
      setTextCopied(false);
    }
  };

  const tabs = [
    { key: 'invites', name: literals.invite },
    { key: 'link', name: literals.link },
  ];

  return (
    <Popup title={literals.invite} onClose={onClose}>
      <Panel>
        <PanelHeader tabs={tabs} />
        <PanelBody>
          <PanelTab key='invites'>
            <div className='mb-3'>{literals.inviteDescription}</div>
            <h6>Emails</h6>
            { emailKeys.map((key, i) => (
              <div key={key} className='invite-emails'>
                <div ref={i === emailKeys.length - 1 ? lastEmailRef : null}>
                  <InputEmail
                    className='mb-2'
                    placeholder={`${literalsCommon.email} (${literals.enterToAdd})`}
                    value={inviteForm.emails[key]}
                    onKeyDown={handleKeyDown}
                    onChange={v => setInviteForm((prev) => {
                      const aux = { ...prev.emails };
                      aux[key] = v;
                      return { ...prev, emails: aux };
                    })}
                  />
                  <Trash
                    className='trash-icon'
                    onClick={() => setInviteForm((prev) => {
                      const auxEmails = { ...prev.emails };
                      if (Object.keys(auxEmails).length > 1) {
                        delete auxEmails[key];
                      }
                      return { ...prev, emails: auxEmails };
                    })}
                  />
                </div>
              </div>
            ))}
            <InputRichText
              preText={literals.message}
              value={inviteForm.message}
              className='mt-4'
              onChange={v => setInviteForm(prev => ({ ...prev, message: v }))}
            />
            <OutputErrors errors={errors} literals={literals} />
            <div className='buttons'>
              <Button
                text={literals.invite}
                onClick={handleInvite}
                loading={loading}
              />
            </div>
          </PanelTab>
          <PanelTab key='link'>
            <div className='mb-3'>{literals.linkDescription}</div>
            <OutputValue
              className='mb-1'
              value={`${window.location.origin}${window.location.pathname}?referral=${user}`}
              onClick={() => copyToClipboard(`${window.location.origin}${window.location.pathname}?referral=${user}`)}
            />
            <div style={{ height: '30px' }}>
              { textCopied && literals.textCopied }
            </div>
          </PanelTab>
        </PanelBody>
      </Panel>
    </Popup>
  );
};

FundraiseInvitePopup.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  fundraise: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FundraiseInvitePopup;
