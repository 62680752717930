
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Button from 'components/Buttons/Button';
import Table from 'components/Table';
import InputText from 'components/Inputs/inputText';
import useDialog from 'components/Dialog/components/useDialog';
import {
  Plus,
  Pencil,
  Trash,
  CaseSensitive,
  File,
  SquareCheckBig,
  CircleCheckBig,
  Text,
  List,
  ArrowDown01,
  Calendar,
  MousePointerClick,
} from 'lucide-react';
import { useCounter } from 'utils/customHooks';
import PopUpQuestions from './PopUpQuestions';
import FormQuestions from './FormQuestions';
import { fetchQuestions, deleteQuestion } from '../modules/actions';

const FormForms = (props) => {
  const {
    literals,
    literalsCommon,
    forms,
    properties,
    onSubmitForms,
    onSubmitQuestion,
    langs,
    lang,
    questions,
    getQuestions,
  } = props;

  const [formsData, setFormsData] = useState({ ...forms });
  const [formQuestion, setFormQuestion] = useState(null);
  const [savingForm, setSavingForm] = useState(false);
  const [savingQuestion, setSavingQuestion] = useState(false);
  const [formsError, setFormsError] = useState(false);
  const [questionError, setQuestionError] = useState(false);
  const [langSelected, setLangSelected] = useState(lang);
  const [addElementForm, setAddElementForm] = useState(false);
  const { dialog } = useDialog();
  const tableRefreshFlag = useCounter(0);

  const [defaultQuestions, setDefaultQuestions] = useState({
    startup: properties.defaultQuestions.startup?.join(', ') || '',
    mentor: properties.defaultQuestions.mentor?.join(', ') || '',
    fund: properties.defaultQuestions.fund?.join(', ') || '',
  });

  const submitForms = async (event) => {
    event.preventDefault();
    const formsDataJson = { ...formsData };

    let formatErrors = false;
    Object.keys(formsDataJson).forEach((key) => {
      try {
        if (typeof formsDataJson[key] === 'string') {
          formsDataJson[key] = JSON.parse(formsDataJson[key]);
        }
      } catch (e) {
        if (formatErrors === false) {
          formatErrors = {};
        }
        formatErrors[key] = ['invalidFormat'];
      }
    });

    if (!formatErrors) {
      if (formsError) {
        setFormsError(formatErrors);
      }
      setSavingForm(true);
      const errors = await onSubmitForms({
        forms: { ...formsDataJson },
        properties: {
          ...properties,
          defaultQuestions: {
            startup: defaultQuestions.startup.replace(/\s/g, '').split(','),
            mentor: defaultQuestions.mentor.replace(/\s/g, '').split(','),
            fund: defaultQuestions.fund.replace(/\s/g, '').split(','),
          },
        },
      });
      setSavingQuestion(false);
      setFormsError(errors);
    } else {
      setFormsError(formatErrors);
    }
  };

  const checkId = () => {
    const aux = { ...formQuestion };
    aux.answers = aux.answers.map((answer) => {
      if (answer.id.includes('newId')) {
        // eslint-disable-next-line no-param-reassign
        answer.id = '';
      }
      return answer;
    });
    setFormQuestion({
      ...aux,
    });
  };

  const checkFields = (form) => {
    let err = '';
    let mandatoryField = ['title', 'answers'];
    let optionalFields = ['placeholder', 'tooltip'];
    if (['text', 'textarea', 'number'].includes(form.type)) {
      mandatoryField = ['title'];
    } else if (['checkbox', 'radiobutton', 'list'].includes(form.type)) {
      optionalFields = ['tooltip'];
    } else if (['document', 'date'].includes(form.type)) {
      mandatoryField = ['title'];
      optionalFields = ['tooltip'];
    }

    Object.keys(form).forEach((key) => {
      if (mandatoryField.includes(key)) {
        if (key === 'answers') {
          Object.entries(form[key]).forEach((answer) => {
            const texts = Object.entries(answer[1].text);
            texts.forEach((text) => {
              if (text[1].length === 0) {
                err = `${literals.theAnswer} ${+answer[0] + 1} ${literals.inLanguage} '${text[0]}' ${literals.isEmpty}`;
              }
            });
          });
        } else {
          const keys = Object.keys(form[key]);
          keys.forEach((l) => {
            const value = form[key];
            if (value[l].length === 0) {
              err = `${literals.theField} ${key} ${literals.inLanguage} '${l}' ${literals.isEmpty}`;
            }
          });
        }
      } else if (optionalFields.includes(key)) {
        const keys = Object.keys(form[key]);
        if (keys.some(l => form[key][l].length > 0)) {
          keys.forEach((l) => {
            if (form[key][l].length === 0) {
              err = `${literals.theField} ${key} ${literals.inLanguage} '${l}' ${literals.isEmpty}`;
            }
          });
        }
      }
    });

    return err;
  };

  const submitQuestion = async (event) => {
    event.preventDefault();
    const formQuestionJson = { ...formQuestion };
    const errors = checkFields(formQuestion);

    let confirm = true;
    if (errors) {
      confirm = await dialog({
        type: 'confirm',
        text: (
          <>
            {errors}
            <br />
            {literals.confirmSave}
          </>
        ),
      });
    }

    if (confirm) {
      checkId();
      Object.keys(formQuestionJson).forEach((key) => {
        try {
          if (typeof formQuestionJson[key] === 'string' && ['{', '['].includes(formQuestionJson[key].charAt(0))) {
            const json = JSON.parse(formQuestionJson[key]);
            formQuestionJson[key] = json;
          }
        } catch (e) {
          console.error(e);
        }
      });
      setSavingQuestion(true);
      const error = await onSubmitQuestion(formQuestionJson);
      getQuestions();
      setSavingQuestion(false);
      setQuestionError(error);
      tableRefreshFlag.increase();
      setFormQuestion(false);
      setLangSelected(lang);
    }
  };

  const popUpClose = () => {
    setFormQuestion(false);
    setLangSelected(lang);
  };

  const popUpFormClose = () => {
    setAddElementForm(false);
  };

  const handleLangChange = (value) => {
    setLangSelected(value);
  };

  const selectAnswers = (element) => {
    const aux = [];
    if (element) {
      for (let i = 0; i < element.length; i += 1) {
        const object = {
          id: element[i].id,
          order: element[i].order,
          score: element[i].order,
          text: { ...element[i].text },
        };
        aux.push(object);
      }
    }
    return aux;
  };

  const selectQuestion = (question) => {
    const aux = {
      id: question.id,
      parent: question.parent,
      title: { ...question.title },
      tooltip: { ...question.tooltip },
      placeholder: { ...question.placeholder },
      type: question.type,
      answers: selectAnswers(question.answers),
      mandatory: question.mandatory,
      order: question.order,
      subquestions: question.subquestions,
      rules: question.rules,
      min: question.min,
      max: question.max,
    };
    setFormQuestion(aux);
  };

  const handleClickDeleteQuestion = async (event) => {
    const confirm = await dialog({
      text: literalsCommon.delete,
      type: 'confirmDanger',
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteQuestion(event.id);
          getQuestions();
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const handleAddElemForm = (row) => {
    const id = row.id;
    const question = questions[id];
    let existe = false;
    const form = formsData[addElementForm];
    for (let index = 0; index < form.length && !existe; index += 1) {
      if (form[index].id === question.id) {
        existe = true;
      }
    }
    if (!existe) {
      const newForm = { ...formsData };
      const newElem = {
        filter: false,
        id: question.id,
        order: form.length + 1,
        public: false,
        type: 'question',
      };
      newForm[addElementForm].push(newElem);
      setFormsData({
        ...newForm,
      });
    }
    setAddElementForm(false);
  };

  const selectIcon = (icon) => {
    let elem;
    switch (icon) {
      case 'text':
        elem = <CaseSensitive size={16} />;
        break;
      case 'textarea':
        elem = <Text size={16} />;
        break;
      case 'select':
        elem = <MousePointerClick size={16} />;
        break;
      case 'radiobutton':
        elem = <CircleCheckBig size={16} />;
        break;
      case 'checkbox':
        elem = <SquareCheckBig size={16} />;
        break;
      case 'list':
        elem = <List size={16} />;
        break;
      case 'document':
        elem = <File size={16} />;
        break;
      case 'date':
        elem = <Calendar size={16} />;
        break;
      case 'number':
        elem = <ArrowDown01 size={16} />;
        break;
      default:
    }
    return elem;
  };

  const selectQuestionForm = (id) => {
    return questions[id] ?? { title: [] };
  };

  const selectIconForm = (id) => {
    const question = selectQuestionForm(id);
    return selectIcon(question.type);
  };

  const selectTitle = (object) => {
    const keys = Object.keys(object);
    let title = '';
    keys.forEach((value) => {
      if (value === lang) {
        title = object[value];
      }
    });
    return title;
  };

  const disabledQuestion = (row) => {
    for (let i = 0; i < formsData[addElementForm].length; i += 1) {
      if (row.id === formsData[addElementForm][i].id) {
        return true;
      }
    }
    return false;
  };

  const newQuestion = {
    id: null,
    parent: null,
    title: {
      en: '',
      es: '',
    },
    tooltip: {
      en: '',
      es: '',
    },
    placeholder: {
      en: '',
      es: '',
    },
    type: 'text',
    answers: [],
    mandatory: false,
    order: 0,
    subquestions: null,
    rules: null,
    min: null,
    max: null,
  };

  const filters = [
    {
      id: 'type',
      type: 'list',
      title: 'Tipo de pregunta',
      options: [
        { id: 'text', name: literals.types.text },
        { id: 'textarea', name: literals.types.textarea },
        { id: 'select', name: literals.types.select },
        { id: 'radiobutton', name: literals.types.radiobutton },
        { id: 'checkbox', name: literals.types.checkbox },
        { id: 'list', name: literals.types.list },
        { id: 'document', name: literals.types.document },
        { id: 'date', name: literals.types.date },
        { id: 'number', name: literals.types.number },
      ],
    },
  ];

  const columns = [
    {
      field: 'type',
      width: 125,
      label: literals.type,
      preRender: type => (
        <div>
          {selectIcon(type)}
          {` ${literals.types[type] ?? type}`}
        </div>
      ),
    },
    {
      field: 'title', label: literals.text, preRender: title => `${selectTitle(title)}`,
    },
    {
      field: 'mandatory',
      label: literals.mandatory,
      preRender: v => (v ? literalsCommon.yes : literalsCommon.no),
      width: 100,
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          text: literalsCommon.modify,
          icon: Pencil,
          onClick: selectQuestion,
        },
        {
          text: literalsCommon.delete,
          icon: Trash,
          onClick: handleClickDeleteQuestion,
        },
      ],
    },
  ];

  const columnsPopUpQuestions = [
    {
      field: 'type',
      width: 100,
      label: literals.type,
      preRender: type => (
        <div>
          {selectIcon(type)}
          {` ${literals.types[type] ?? type}`}
        </div>
      ),
    },
    {
      field: 'title', label: literals.text, preRender: title => `${selectTitle(title)}`,
    },
    {
      field: 'id',
      type: 'button',
      button: {
        text: 'borrar',
        label: '',
        icon: <Plus />,
        onClick: row => handleAddElemForm(row),
        disabled: (id, row) => disabledQuestion(row),
      },
    },
  ];
  return (
    <div className='tabForms'>
      <InputText
        preText={literals.startupCoreQuestions}
        value={defaultQuestions.startup}
        onChange={v => setDefaultQuestions({ ...defaultQuestions, startup: v })}
      />
      <InputText
        preText={literals.mentorCoreQuestions}
        value={defaultQuestions.mentor}
        onChange={v => setDefaultQuestions({ ...defaultQuestions, mentor: v })}
      />
      <InputText
        preText={literals.fundCoreQuestions}
        value={defaultQuestions.fund}
        onChange={v => setDefaultQuestions({ ...defaultQuestions, fund: v })}
      />
      <Table
        filters={filters}
        columns={columns}
        actions={[
          { icon: Plus, text: 'Pregunta', onClick: () => setFormQuestion(newQuestion) },
        ]}
        pageSize={10}
        fetch={fetchQuestions}
        forceFetch={tableRefreshFlag.value}
      />
      <div className='forms'>
        <h3>{literals.forms}</h3>
        <form onSubmit={submitForms}>
          <FormQuestions
            formsData={formsData}
            setAddElementForm={setAddElementForm}
            selectQuestionForm={selectQuestionForm}
            langSelected={langSelected}
            literals={literals}
            selectIconForm={selectIconForm}
            setFormsData={setFormsData}
          />
          <div className='buttons'>
            <Button key='submit' text='Enviar' type='submit' loading={savingForm} />
          </div>
        </form>
      </div>
      {formQuestion && (
        <PopUpQuestions
          onClose={popUpClose}
          submit={submitQuestion}
          formQuestion={formQuestion}
          literals={literals}
          setFormQuestion={setFormQuestion}
          langSelected={langSelected}
          savingQuestion={savingQuestion}
          questionError={questionError}
          handleLangChange={handleLangChange}
          selectIcon={selectIcon}
          langs={langs}
        />
      )}
      {addElementForm && (
        <Popup onClose={popUpFormClose}>
          <Table columns={columnsPopUpQuestions} filters={filters} pageSize={10} fetch={fetchQuestions} />
        </Popup>
      )}
    </div>
  );
};

FormForms.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  forms: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  onSubmitForms: PropTypes.func.isRequired,
  onSubmitQuestion: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  langs: PropTypes.array.isRequired,
  questions: PropTypes.object.isRequired,
  getQuestions: PropTypes.func.isRequired,
};

export default FormForms;
