import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import IOFileLoading from 'components/IOFileLoading';
import { PlusCircle } from 'lucide-react';
import EmptyData from 'assets/svg/empty_data.svg';
import Button from 'components/Buttons/Button';
import useDialog from 'components/Dialog/components/useDialog';
import Document from './Document';
import './styles.scss';

const Documents = ({
  literals,
  literalsCommon,
  user,
  documents = null,
  onSubmit = null,
  onDelete = null,
}) => {
  const refInputFile = useRef(null);
  const [uploading, setUploading] = useState(false);
  const { dialog } = useDialog();

  const submitDocument = async (e) => {
    setUploading(e.target.files[0]);
    await onSubmit(e.target.files[0]);
    setUploading(false);
  };

  const handleDelete = async (id) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literalsCommon.confirmDelete,
    });
    if (confirm && onDelete) {
      await onDelete(id);
    }
  };

  const handleUpload = () => {
    refInputFile.current.click();
  };

  const renderDocuments = () => {
    if (documents && documents.items.length) {
      return documents.items.map(document => (
        <Document
          key={document.id}
          literals={literals}
          literalsCommon={literalsCommon}
          document={document}
          onDelete={onDelete && document.createdBy?.id === user.id ? handleDelete : null}
        />
      ));
    }

    return (
      <li className='panel-empty-message'>
        <img src={EmptyData} alt='' />
        <p>{literals.emptyDocuments}</p>
      </li>
    );
  };

  const renderLoading = (size) => {
    return Array.from(new Array(size)).map((e, i) => (
      <li key={i} className='listItemBox'>
        <Skeleton
          animation='wave'
          height={90}
          style={{ marginBottom: 5 }}
        />
      </li>
    ));
  };

  return (
    <>
      <div className='documents'>
        {
          onSubmit && (
            <div className='panel-fix-rigth-button'>
              <Button
                mode='icon'
                onClick={handleUpload}
                text={literals.uploadDocument}
                icon={PlusCircle}
              />
              <input
                ref={refInputFile}
                type='file'
                name='file'
                onChange={submitDocument}
                style={{
                  visibility: 'hidden',
                  position: 'absolute',
                  top: 0,
                  width: '1px',
                  height: '1px',
                }}
              />
              {
                uploading && <IOFileLoading file={uploading} />
              }
            </div>
          )
        }
        <ul className='documents-list'>
          {
            documents ? (
              renderDocuments()
            ) : (
              renderLoading(5)
            )
          }
        </ul>
      </div>
    </>
  );
};

Documents.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  documents: PropTypes.object,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default Documents;
