import React, { useState, useEffect } from 'react';
import { PatchCheck, PatchCheckFill } from 'react-bootstrap-icons';
import {
  File, FileImage, FileText, FileArchive, FileSpreadsheet,
} from 'lucide-react';
import {
  TOOL_CAP_TABLE,
  TOOL_DATA_ROOM,
  TOOL_FUNDRAISE,
  TOOL_LEVEL_EDIT,
  TOOL_LEVEL_MANAGE,
  TOOL_LEVEL_VIEW,
  TOOL_MY_POSITION,
  TOOL_PERFORMANCE,
  TOOL_PROFILE,
  TOOL_REPORTING,
  TOOL_USERS,
} from 'constants/tools';
import { PERMISSION_LEVELS } from 'constants/permissions';
import { DEFAULT_LANGUAGE, literalTemplate } from './language';

export function getBrowserDateFormat() {
  const DATE_FORMATS = {
    day: 'd', month: 'm', year: 'Y',
  };

  const dateParts = Intl.DateTimeFormat().formatToParts(new Date());
  return dateParts
    .map(part => DATE_FORMATS[part.type] || part.value)
    .join('');
}

export function formatDate(date, options = {}) {
  const format = options.format || window.DATE_FORMAT || 'd/m/Y';
  const time = options.time || false;
  const literals = options.literals || {};

  let formattedDate = date;
  if (!(formattedDate instanceof Date)) {
    if (typeof formattedDate === 'string') {
      formattedDate = new Date(formattedDate);
    } else if (!date) {
      formattedDate = new Date();
    } else {
      formattedDate = new Date(formattedDate * 1000);
    }
  }

  if (format === 'ago') {
    const diff = Math.floor((new Date() - formattedDate) / 1000);
    const timeUnits = [
      { unit: literals.seconds, divisor: 1 },
      { unit: literals.minutes, divisor: 60 },
      { unit: literals.hours, divisor: 3600 },
      { unit: literals.days, divisor: 86400 },
      { unit: literals.weeks, divisor: 604800 },
      { unit: literals.months, divisor: 2592000 },
      { unit: literals.years, divisor: 31536000 },
    ];
    const unit = timeUnits.findLast(u => diff > u.divisor);
    const number = Math.floor(diff / unit.divisor);
    return literalTemplate(literals.ago, { number, time: unit.unit }).toLowerCase();
  }

  const day = String(formattedDate.getDate()).padStart(2, '0');
  const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
  const year = formattedDate.getFullYear();

  const hour = String(formattedDate.getHours()).padStart(2, '0');
  const minutes = String(formattedDate.getMinutes()).padStart(2, '0');

  function getFormatedDate() {
    return format.replace(/[Ymd]/g, (match) => {
      switch (match) {
        case 'Y': return year;
        case 'm': return month;
        case 'd': return day;
        default: return match;
      }
    });
  }

  return getFormatedDate() + (time ? ` ${hour}:${minutes}` : '');
}

export function diffBetweenDates(date1, date2 = new Date()) {
  let fDate1 = date1;
  let fDate2 = date2;
  if (!(fDate1 instanceof Date)) {
    fDate1 = new Date(fDate1 * 1000);
  }

  if (!(fDate2 instanceof Date)) {
    fDate2 = new Date(fDate2 * 1000);
  }

  const diff = fDate2.getTime() - fDate1.getTime();
  const result = {
    d: 0,
    h: 0,
    m: 0,
    s: 0,
  };

  if (diff > 0) {
    result.d = Math.floor(diff / (1000 * 60 * 60 * 24));
    result.h = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    result.m = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    result.s = Math.floor((diff % (1000 * 60)) / 1000);
  }

  return result;
}

export function formatTime(time, unit = 'min', dec = 0, tmpl) {
  let seconds = unit === 'min' ? time * 60 : time;
  if (!seconds || seconds === 0) {
    return '-';
  }

  const days = Math.floor(seconds / 86400);
  seconds %= 86400;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;


  switch (tmpl) {
    case 'hh:mm':
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    default:
      return `
        ${days > 0 ? `${days}d ` : ''}
        ${hours > 0 ? `${hours}h ` : ''}
        ${minutes > 0 ? `${minutes}' ` : ''}
        ${seconds > 0 ? `${seconds.toFixed(dec)}'' ` : ''}
      `;
  }
}

export function getDecimalSeparator() {
  return (['comma', 'period'].includes(window.DECIMAL_SEPARATOR) ? window.DECIMAL_SEPARATOR : 'comma') === 'period' ? '.' : ',';
}

export function formatNumber(number, empty = '-', options = {}) {
  if (number === '' || number === null || typeof (number) === 'undefined' || Number.isNaN(number)) {
    let emptyFormat = empty;
    if (options.symbol) {
      emptyFormat += options.symbol;
    }
    return emptyFormat;
  }
  let auxNumber = Number(number);
  if (options.dec || options.dec === 0) {
    auxNumber = auxNumber.toFixed(options.dec);
  } else {
    auxNumber = auxNumber.toFixed(2);
  }

  const getIntPart = (intPartStr, thousandsSep) => {
    const res = intPartStr.split('');
    for (let idx = intPartStr.length - 3; idx > 0; idx -= 3) {
      res.splice(idx, 0, thousandsSep);
    }
    return res;
  };

  const separator = getDecimalSeparator();

  const splitted = Math.abs(auxNumber).toString().split('.');
  auxNumber = (auxNumber < 0 ? ['-'] : [])
    .concat(getIntPart(splitted[0], separator === ',' ? '.' : ','))
    .concat(splitted[1] ? [separator, splitted[1]] : []).join('');

  if (options.prefix) {
    switch (options.prefix) {
      case 'sign':
        auxNumber = (number >= 0 ? '+' : '') + auxNumber;
        break;
      default:
        auxNumber = options.prefix + auxNumber;
        break;
    }
  }
  if (options.symbol) {
    auxNumber += options.symbol;
  }
  return auxNumber;
}

export const formatLargeNumber = (number, empty = '-', options = {}) => {
  const lookup = [
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find((it) => {
    return number >= it.value;
  });

  return item
    ? (number / item.value).toFixed(options.dec).replace(rx, '$1') + item.symbol
    : formatNumber(number, empty, options);
};

export function formatErrors(response, literals = {}) {
  const errors = {};
  if (response.status === 401) {
    errors.unauthorized = 'unauthorized';
  } else if (response.status >= 500) {
    errors.internal = 'internal';
  } else {
    Object.keys(response.data).forEach((key) => {
      if (!errors[key]) {
        errors[key] = [];
      }
      const error = response.data[key];

      if (error.constructor.name === 'Array') {
        error.forEach((el, j) => {
          error[j].message = literals[error[j].code] ?? error[j].message;
          errors[key].push(error[j]);
        });
      } else {
        error.message = literals[error.code] ?? error.message;
        errors[key].push(error);
      }
    });
  }
  return errors;
}

window.onClickOutRef = () => {};
const onClickBody = (e, parentClass, resolve) => {
  let exec = true;
  const path = e.path || (e.composedPath && e.composedPath());
  if (parentClass && path && path.length) {
    path.forEach((target) => {
      if (!parentClass || (target && typeof target.className === 'string' && target.className.indexOf(parentClass) !== -1)) {
        exec = false;
      }
    });
  }
  if (exec) {
    document.removeEventListener('click', window.onClickOutRef);
    if (resolve) {
      resolve(true);
    }
  }
};

export function onClickOut(parentClass, withFunction) {
  const promise = new Promise((resolve) => {
    setTimeout(() => {
      window.onClickOutRef = e => onClickBody(e, parentClass, resolve);
      document.addEventListener('click', window.onClickOutRef);
    }, 100);
  });

  return withFunction ? [promise, () => document.removeEventListener('click', window.onClickOutRef)] : promise;
}

export function startupUserPermissionLevel(startup, tool) {
  if (startup?.relation?.permissions) {
    const toolLevel = startup?.relation?.permissions[tool];
    if (toolLevel === undefined) {
      return false;
    }
    return toolLevel;
  }
  return false;
}

function startupUserPermission(startup, tool, level, strict = false) {
  if (startup?.relation?.permissions) {
    if (tool) {
      if (tool === TOOL_MY_POSITION) {
        return startup.relation.shareholders.length > 0;
      }
      const toolLevel = startup?.relation?.permissions[tool];
      if (toolLevel === undefined) {
        return false;
      }
      switch (level) {
        case TOOL_LEVEL_VIEW:
          if (!strict) {
            if ([TOOL_CAP_TABLE, TOOL_PERFORMANCE, TOOL_DATA_ROOM, TOOL_PROFILE].includes(tool)) {
              return true;
            }
            if ([TOOL_REPORTING, TOOL_FUNDRAISE].includes(tool)) {
              return toolLevel > PERMISSION_LEVELS.EXTERNAL;
            }
          }
          return toolLevel >= PERMISSION_LEVELS.VIEW;
        case TOOL_LEVEL_EDIT:
          return toolLevel >= PERMISSION_LEVELS.EDIT;
        case TOOL_LEVEL_MANAGE:
          return toolLevel >= PERMISSION_LEVELS.MANAGE;
        default:
          return tool !== TOOL_USERS;
      }
    } else {
      return true;
    }
  }
  return false;
}

export function startupCanView(startup, tool, strict = false) {
  return startupUserPermission(startup, tool, TOOL_LEVEL_VIEW, strict);
}

export function startupCanEdit(startup, tool) {
  return startupUserPermission(startup, tool, TOOL_LEVEL_EDIT);
}

export function startupCanManage(startup, tool) {
  return startupUserPermission(startup, tool, TOOL_LEVEL_MANAGE);
}

export function startupCan(level, startup, tool) {
  return startupUserPermission(startup, tool, level);
}

export const getIconFile = (name) => {
  const ext = name.split('.').pop().toLowerCase();
  switch (ext) {
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
      return <FileImage />;
    case 'zip':
    case 'rar':
      return <FileArchive />;
    case 'xls':
    case 'xlsx':
    case 'csv':
      return <FileSpreadsheet />;
    case 'pdf':
    case 'doc':
    case 'docx':
      return <FileText />;
    default:
      return <File />;
  }
};

export const getStartupVerifiedIcon = (verified) => {
  switch (verified) {
    case 'team':
      return <PatchCheck className='ml-2' />;
    case 'full':
      return <PatchCheckFill className='ml-2' />;
    default:
      return null;
  }
};

export const nl2br = (str) => {
  return `${str}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
};

export function stripTags(str) {
  return str.replace(/(<([^>]+)>)/gi, '').replace('&nbsp;', '');
}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export function setObjectValue(object, property, value) {
  const keys = property.split('.');
  const newObject = { ...object };

  let current = newObject;
  keys.slice(0, -1).forEach((key) => {
    current[key] = current[key] || {};
    current = current[key];
  });

  current[keys[keys.length - 1]] = value;
  return newObject;
}

export function insertObjectInArray(array, object, sort = false) {
  const newArray = [...array];
  const index = array.findIndex(elem => elem.id === object.id);
  if (index === -1) {
    newArray.push(object);
  } else {
    newArray[index] = object;
  }
  if (typeof sort === 'function') {
    newArray.sort(sort);
  }
  return newArray;
}

export function addLinks(message) {
  let finalMessage = message;
  const urls = message.match(/(https?:\/\/)[^ ]+/g) || [];
  urls.forEach((url) => {
    finalMessage = finalMessage.replace(url, `<a target="_blank" rel="nofollow noopener noreferrer" href="${url}">${url}</a>`);
  });
  return finalMessage;
}

export function isEmptyObject(obj) {
  return !obj || Object.keys(obj).length === 0;
}

export function dateAddDays(date, days = 0) {
  if (!date) return null;

  let nextDay = typeof date === 'string' ? date : formatDate(date, { format: 'Y-m-d' });
  nextDay = date.split('-');

  nextDay = new Date(nextDay[0], parseInt(nextDay[1], 10) - 1, nextDay[2]);
  nextDay.setDate(nextDay.getDate() + days);

  nextDay = formatDate(nextDay, { format: 'Y-m-d' });
  return nextDay;
}

export function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function cleanFilename(name) {
  if (name.length <= 50) {
    return name;
  }
  const ext = name.split('.').pop();
  const nameWithoutExt = name.replace(`.${ext}`, '');
  return `${nameWithoutExt.substring(0, 50)}...${ext}`;
}

export function normalizeText(str) {
  return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function capitalizeFirstLetter(string) {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function dictionary(arr, key = 'id') {
  return arr.reduce((res, obj) => {
    res[obj[key]] = obj;
    return res;
  }, {});
}

export function getFullName(user) {
  if (!user) return '';
  if (user.type === 'organization') return user?.firstname || '';
  return `${user?.firstname || ''} ${user?.lastname || ''}`;
}

export function getColorRange(number, start = '', end = '') {
  const startColor = start || window?.PLATFORM_COLORS?.primary;
  const endColor = end || window?.PLATFORM_COLORS?.secondary;

  const isValidHexColor = color => /^#[0-9A-Fa-f]{6}$/.test(color);
  if (!isValidHexColor(startColor) || !isValidHexColor(endColor)) {
    return null;
  }

  if (number < 2) {
    return [startColor];
  }

  const interpolateColor = (c1, c2, f) => {
    const interpolateChannel = (ch1, ch2) => Math.floor(ch1 * (1 - f) + ch2 * f).toString(16).padStart(2, '0');
    const interpolateHex = (hex1, hex2) => interpolateChannel(parseInt(hex1, 16), parseInt(hex2, 16));

    return `#${[1, 3, 5].map(i => interpolateHex(c1.slice(i, i + 2), c2.slice(i, i + 2))).join('')}`;
  };

  return Array.from({ length: number }, (_, i) => interpolateColor(startColor, endColor, i / (number - 1)));
}

export function renderIcon(icon, props = {}) {
  return React.isValidElement(icon)
    ? React.cloneElement(icon, { ...props, ...icon.props })
    : React.createElement(icon, props);
}

export function getTranslation(item, lang) {
  return item?.[lang] || item?.[DEFAULT_LANGUAGE] || '';
}
