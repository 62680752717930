import React from 'react';
import PropTypes from 'prop-types';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import OverrideThemeProps from './config';

function OverrideTheme(props) {
  const { children } = props;
  const propsTheme = createTheme({
    ...OverrideThemeProps,
  });
  return (
    <ThemeProvider theme={propsTheme}>{children}</ThemeProvider>
  );
}

OverrideTheme.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OverrideTheme;
