import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import ButtonsDotsMenu from 'components/Buttons/ButtonsDotsMenu';
import useDialog from 'components/Dialog/components/useDialog';
import {
  ArrowLeft, ChevronDown, ChevronRight,
  Folder, FolderOpen, Clock, Bookmark,
  Pencil, Trash, Move, CircleSlash,
} from 'lucide-react';
import { startupCanEdit, startupCanView } from 'utils/functions';
import { TOOL_DATA_ROOM } from 'constants/tools';
import { Skeleton } from '@mui/material';
import { PERMISSION_LEVELS } from 'constants/permissions';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useLocation, useNavigate } from 'react-router-dom';
import FolderContent from './FolderContent';
import PopupCreateRename from './PopupCreateRename';
import PopupMove from './PopupMove';
import {
  deleteFolder, editFolder, getFolder, getFolderContents,
} from '../modules/actions';
import './styles.scss';


export const getFolderName = (folder, literals) => {
  if (folder.related?.type && !folder.related.id) {
    return literals.defaultFolders[folder.related.type] || folder.name;
  }
  return folder.name;
};

const DataRoom = (props) => {
  const {
    match,
    literals,
    literalsCommon,
    startup,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const rolePath = location.pathname.split('/').filter(p => p !== 'embed')[1];
  const rootFolder = {
    id: `root.${rolePath}.${match.params.id}`,
    name: startup.name,
    parent: null,
    parents: [],
    permission: {
      level: startupCanEdit(startup, TOOL_DATA_ROOM) ? PERMISSION_LEVELS.EDIT : PERMISSION_LEVELS.READ,
    },
  };
  const recentFolder = {
    id: 'recent',
    name: literals.recents,
    parent: null,
    parents: [],
  };
  const bookmarkFolder = {
    id: 'bookmark',
    name: literals.bookmarks,
    parent: null,
    parents: [],
  };

  const hash = location?.hash?.slice(1);

  const [selectedFolder, setSelectedFolder] = useState();
  const [lastActionPush, setLastActionPush] = useState(false);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [folders, setFolders] = useState([]);
  const [showTree, setShowTree] = useState(false);
  const [showRenameFolder, setShowRenameFolder] = useState(false);
  const [showMovePopup, setShowMovePopup] = useState(null);
  const [renamedFolder, setRenamedFolder] = useState();
  const [change, setChange] = useState();
  const [loading, setLoading] = useState('root');
  const [fetching, setFetching] = useState(false);
  const scrollRef = useRef();
  const { dialog } = useDialog();

  const canView = startupCanView(startup, TOOL_DATA_ROOM, true);
  const canEdit = startupCanEdit(startup, TOOL_DATA_ROOM);

  const fetchFolders = async () => {
    try {
      const auxFolders = await getFolderContents(rolePath, match.params.id, `root.${rolePath}.${match.params.id}`, {
        page: 0, size: 0, sort: '-created_at', filters: { 'element.type': 'folder' },
      });
      setFolders(auxFolders.items.map(item => ({ ...item, parents: [rootFolder] })));
    } catch (error) {
      /* */
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!folders.length) {
      fetchFolders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChildsFolder = (newFolder, auxFolders, childs, toggle) => {
    return auxFolders.map((folder) => {
      if (folder.id === newFolder.id) {
        setLoading(false);
        if (folder.childs || !childs) {
          return { ...folder, showChilds: toggle ? !folder.showChilds : true, childs: folder.childs || [] };
        }
        const aux = {
          ...folder,
          childs: childs.map(child => ({
            ...child,
            parents: [...folder.parents, { id: folder.id, name: folder.name }],
          })),
        };
        return { ...aux, showChilds: true };
      }
      if (folder.id === newFolder?.info?.parent) {
        return { ...folder, showChilds: true, childs: handleChildsFolder(newFolder, folder.childs, childs, toggle) };
      }
      return folder.childs
        ? { ...folder, childs: handleChildsFolder(newFolder, folder.childs, childs, toggle) }
        : folder;
    });
  };

  const fetchChildsFolders = async (folder) => {
    return getFolderContents(rolePath, match.params.id, folder.id, {
      page: 0, size: 0, sort: '-created_at', filters: { 'element.type': 'folder' },
    });
  };

  const findFolder = (id, auxFolders) => {
    switch (id) {
      case rootFolder.id:
        return rootFolder;
      case bookmarkFolder.id:
        return bookmarkFolder;
      case recentFolder.id:
        return recentFolder;
      default:
        return auxFolders.map((folder) => {
          if (folder.id === id) {
            return folder;
          }
          return folder.childs ? findFolder(id, folder.childs) : null;
        }).find(elem => !!elem) || null;
    }
  };

  useEffect(() => {
    if (hash) {
      const folder = findFolder(hash, folders);
      if (!selectedFolder || !folder) {
        if ([bookmarkFolder.id, recentFolder.id].includes(folder?.id)) {
          setSelectedFolder(folder);
        } else {
          getFolder(rolePath, match.params.id, hash)
            .then((response) => {
              setSelectedFolder(response);
              setBreadcrumb([...response.path, { id: response.id, name: response.name }]);
            })
            .catch(() => {
              setSelectedFolder(rootFolder);
              setBreadcrumb([]);
            });
        }
      } else {
        setSelectedFolder(folder);
        setBreadcrumb([]);
      }
    } else {
      setLastActionPush(false);
      setSelectedFolder(rootFolder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);

  useEffect(() => {
    if (selectedFolder) {
      if (lastActionPush && selectedFolder.id !== hash) {
        navigate({ hash: selectedFolder.id });
      } else {
        setLastActionPush(true);
      }
      if (selectedFolder.childs || selectedFolder?.stats?.folders === 0) {
        setFolders(prev => handleChildsFolder(selectedFolder, prev, null, false));
      } else if (selectedFolder.id !== recentFolder.id) {
        fetchChildsFolders(selectedFolder).then((childs) => {
          setFolders(prev => handleChildsFolder(selectedFolder, prev, childs.items, false));
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFolder]);

  const removeFolder = (auxFolders, folderDeleted) => {
    return auxFolders.map((folder) => {
      if (folder.id === folderDeleted.parent) {
        return { ...folder, childs: folder.childs.filter(child => child.id !== folderDeleted.id) };
      }
      return folder.childs
        ? { ...folder, childs: removeFolder(folder.childs, folderDeleted) }
        : folder;
    });
  };

  const handleDelete = async (folderDeleted, conf = false) => {
    let confirm = conf;
    if (!confirm) {
      confirm = await dialog({
        type: 'confirmDanger',
        text: literalsCommon.confirmDelete,
      });
    }

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            const response = await deleteFolder(rolePath, match.params.id, folderDeleted.id, conf);
            if (response.deleted) {
              if (folderDeleted.parent === `root.${rolePath}.${match.params.id}`) {
                setFolders(folders.filter(folder => folder.id !== folderDeleted.id));
              } else {
                setFolders(removeFolder(folders, folderDeleted));
              }
              if (selectedFolder?.id === folderDeleted.id || selectedFolder?.parents?.find(parent => parent.id === folderDeleted.id)) {
                setSelectedFolder(rootFolder);
              } else if (selectedFolder?.id === folderDeleted.parent) {
                setChange({ type: 'delete', folder: folderDeleted });
              }
            } else {
              const confirmDelete = await dialog({
                type: 'confirmDanger',
                text: literals.confirmDeleteFolder,
              });

              if (confirmDelete) {
                await handleDelete(folderDeleted, true);
              }
            }
          } catch (error) {
            let errorMessage = '';
            if (error?.status === 401) {
              errorMessage = literals.noPermissions;
            }
            await dialog({
              type: 'error',
              text: (
                <>
                  <strong>{literals.error}</strong>
                  <br />
                  {errorMessage}
                </>
              ),
            });
          }
        },
      });
    }
  };

  const renameFolder = (newFolder, auxFolders) => {
    return auxFolders.map((folder) => {
      if (folder.id === renamedFolder.id) {
        if (selectedFolder?.id === folder.parent) {
          setChange({ type: 'rename', folder: newFolder });
        }
        return { ...folder, ...newFolder };
      }
      return folder.childs
        ? { ...folder, childs: renameFolder(newFolder, folder.childs) }
        : folder;
    });
  };

  const handleRenameFolder = async (folder) => {
    await dialog({
      type: 'loading',
      execute: async () => {
        return editFolder(rolePath, match.params.id, folder.id, { name: folder.name, parent: folder.parent })
          .then(newFolder => setFolders(renameFolder(newFolder, folders)))
          .catch(async (error) => {
            let errorMessage = '';
            if (error?.status === 401) {
              errorMessage = literals.noPermissions;
            }
            await dialog({
              type: 'error',
              text: (
                <>
                  <strong>{literals.error}</strong>
                  <br />
                  {errorMessage}
                </>
              ),
            });
          });
      },
    });
  };

  useEffect(() => {
    if (renamedFolder) {
      handleRenameFolder(renamedFolder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renamedFolder]);


  const moveFolder = (newFolder, prevParent, auxFolders) => {
    return auxFolders.map((folder) => {
      if (folder.id === newFolder.parent) {
        setChange({ type: 'move', folder: newFolder });
        return {
          ...folder,
          childs: folder.childs ? moveFolder(newFolder, prevParent, [...folder.childs, newFolder]) : null,
          stats: { ...folder.stats, folders: folder.stats.folders + 1 },
        };
      }
      if (folder.id === prevParent) {
        return {
          ...folder,
          childs: folder.childs ? moveFolder(newFolder, prevParent, folder.childs).filter(child => child.id !== newFolder.id) : null,
          stats: { ...folder.stats, folders: folder.stats.folders - 1 },
        };
      }
      return folder.childs
        ? { ...folder, childs: moveFolder(newFolder, prevParent, folder.childs) }
        : folder;
    });
  };

  // eslint-disable-next-line consistent-return
  const handleDragEnd = async (result) => {
    const type = result.draggableId.substring(0, result.draggableId.indexOf('-'));
    const originId = result.draggableId.substring(result.draggableId.indexOf('-') + 1, result.draggableId.length);
    const destinantionId = result?.destination?.droppableId?.substring(result?.destination?.droppableId.indexOf('-') + 1, result?.destination?.droppableId?.length)
      || result?.combine?.draggableId.substring(result?.combine?.draggableId.indexOf('-') + 1, result?.combine?.draggableId.length);


    if (originId && destinantionId && originId !== destinantionId) {
      if (type === 'content') {
        setChange({ type: 'moveContent', folder: destinantionId, file: originId });
      } else {
        const folder = findFolder(originId, folders);
        await dialog({
          type: 'loading',
          execute: async () => {
            return editFolder(rolePath, match.params.id, folder.id, { name: folder.name, parent: destinantionId })
              .then((newFolder) => {
                let auxFolders = [...folders];
                if (folder.parent === rootFolder.id) {
                  auxFolders = auxFolders.filter(fldr => newFolder.id !== fldr.id);
                }
                if (newFolder.parent === rootFolder.id) {
                  setChange({ type: 'move', folder: newFolder });
                  auxFolders.push(newFolder);
                }
                setFolders(moveFolder(newFolder, folder.parent, auxFolders));
              })
              .catch(async (error) => {
                let errorMessage = '';
                if (error?.status === 401) {
                  errorMessage = literals.noPermissions;
                }
                await dialog({
                  type: 'error',
                  text: (
                    <>
                      <strong>{literals.error}</strong>
                      <br />
                      {errorMessage}
                    </>
                  ),
                });
              });
          },
        });
      }
    }
  };

  const getFolderActions = (folder) => {
    if (folder?.related?.type) {
      return [
        {
          icon: CircleSlash,
          text: literals.folderNotEditable,
          onClick: () => {},
          disabled: true,
        },
      ];
    }
    return [
      { icon: Pencil, text: literalsCommon.edit, onClick: () => setShowRenameFolder(folder) },
      { icon: Move, text: literals.move, onClick: () => setShowMovePopup(folder) },
      { icon: Trash, text: literalsCommon.delete, onClick: () => handleDelete(folder) },
    ];
  };

  const renderTree = (auxFolders) => {
    return (
      auxFolders.map((folder, i) => (
        <li key={folder.id}>
          <Droppable droppableId={`droppable-${folder.id}`} isCombineEnabled ignoreContainerClipping>
            {(provider, snapshot) => {
              const isDraggingOver = snapshot.isDraggingOver && `folder-${folder.id}` !== snapshot.draggingOverWith;
              return (
                <div ref={provider.innerRef} className={`droppable ${isDraggingOver ? 'droppable-selected' : ''}`}>
                  <Draggable draggableId={`folder-${folder.id}`} index={i}>
                    {
                      (draggableProvider, draggableSnapshot) => (
                        <>
                          <div
                            ref={draggableProvider.innerRef}
                            {...draggableProvider.draggableProps}
                            {...draggableProvider.dragHandleProps}
                            className={`li-container ${folder.id === selectedFolder?.id && 'selected'} ${fetching ? 'disabled' : ''}`}
                            style={{
                              ...draggableProvider.draggableProps.style,
                              transform: draggableSnapshot.isDragging ? draggableProvider.draggableProps.style.transform : '',
                            }}
                          >
                            <span
                              className='d-flex align items-center mr-2'
                              onClick={(e) => {
                                e.stopPropagation();
                                if (folder.childs || folder?.stats?.folders === 0) {
                                  setFolders(prev => handleChildsFolder(folder, prev, null, true));
                                } else {
                                  setLoading(folder.id);
                                  fetchChildsFolders(folder).then((childs) => {
                                    setFolders(prev => handleChildsFolder(folder, prev, childs.items, true));
                                    setLoading(false);
                                  });
                                }
                              }}
                            >
                              {folder.showChilds ? <ChevronDown size={12} /> : <ChevronRight size={12} />}
                            </span>
                            {
                              folder?.stats?.folders > 0 ? (
                                <FolderOpen size={16} className='mr-2' />
                              ) : (
                                <Folder size={16} className='mr-2' />
                              )
                            }
                            <span
                              className='folder-name'
                              title={getFolderName(folder, literals)}
                              onClick={() => {
                                if (!folder.childs) {
                                  setLoading(folder.id);
                                }
                                setSelectedFolder({ ...folder, parents: folder.parents || [rootFolder] });
                              }}
                            >
                              {getFolderName(folder, literals)}
                            </span>
                            {canEdit && (
                              <span className='dots-menu'>
                                <ButtonsDotsMenu
                                  buttons={getFolderActions(folder)}
                                  offset={{ top: 3, left: 30 }}
                                  size={20}
                                />
                              </span>
                            )}
                          </div>
                          {draggableSnapshot.isDragging && (
                            <div className='folder-placeholder'>
                              {
                                folder?.stats?.folders > 0 ? (
                                  <FolderOpen size={16} className='mr-2' />
                                ) : (
                                  <Folder size={16} className='mr-2' />
                                )
                              }
                              {folder.name}
                            </div>
                          )}
                        </>
                      )
                    }
                  </Draggable>
                  <div className={`folder-dropzone ${isDraggingOver ? 'active' : ''}`}>
                    <span className='fc-secondary'>{literals.dropInside}</span>
                    {provider.placeholder}
                  </div>
                </div>
              );
            }}
          </Droppable>
          {loading === folder.id && (
            <div className='pl-2'>
              {[...Array(Math.min(folder.stats.folders, 5))].map((_, index) => (
                <span key={`skeleton-${index}`} className='d-flex align-items-center justify-content-start'>
                  <Skeleton animation='wave' width='17%' height={44} className='mr-2' />
                  <Skeleton animation='wave' width='73%' height={44} />
                </span>
              ))}
            </div>
          )}
          {folder?.childs?.length > 0 && folder.showChilds && (
            <ul className='pl-2'>
              {renderTree(folder.childs)}
            </ul>
          )}
        </li>

      ))
    );
  };

  return (
    <Panel>
      <PanelHeader title={literals.dataRoom} />
      <PanelBody>
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className='dataroom-container'>
            { canView && (
              <div className={`folders-tree simple-scrollbar ${showTree ? 'show' : ''}`}>
                <div className='close-tree' onClick={() => setShowTree(!showTree)}>
                  <span className='arrow-icon'><ArrowLeft size={18} /></span>
                  <Folder strokeWidth={1.5} size={40} />
                </div>
                <div className={`${!showTree ? 'hide' : ''}`}>
                  <h6
                    className={`root cursor-pointer ${selectedFolder?.id === recentFolder.id ? 'selected' : ''} ${fetching && 'disabled'}`}
                    onClick={() => setSelectedFolder(recentFolder)}
                  >
                    <Clock size={16} className='mr-2' />
                    <span className='folder-name' title={recentFolder.name}>{recentFolder.name}</span>
                  </h6>
                  <h6
                    className={`root mb-3 cursor-pointer ${selectedFolder?.id === bookmarkFolder.id ? 'selected' : ''} ${fetching && 'disabled'}`}
                    onClick={() => setSelectedFolder(bookmarkFolder)}
                  >
                    <Bookmark size={16} className='mr-2' />
                    <span className='folder-name' title={bookmarkFolder.name}>{bookmarkFolder.name}</span>
                  </h6>
                  <Droppable droppableId={`droppable-${rootFolder.id}`} isCombineEnabled ignoreContainerClipping>
                    {(provider, snapshot) => {
                      const isDraggingOver = snapshot.isDraggingOver && `folder-${rootFolder.id}` !== snapshot.draggingOverWith;
                      return (
                        <div ref={provider.innerRef} className={`droppable ${isDraggingOver ? 'droppable-selected' : ''}`}>
                          <h6
                            className={`root cursor-pointer ${selectedFolder?.id === rootFolder.id ? 'selected' : ''} ${fetching && 'disabled'}`}
                            onClick={() => setSelectedFolder(rootFolder)}
                          >
                            <Folder size={16} className='mr-2' />
                            <span className='folder-name' title={rootFolder.name}>{rootFolder.name}</span>
                          </h6>
                          <div className={`folder-dropzone ${isDraggingOver ? 'active' : ''}`}>
                            <span className='fc-secondary'>{literals.dropInside}</span>
                            {provider.placeholder}
                          </div>
                        </div>
                      );
                    }}
                  </Droppable>
                  <ul ref={scrollRef} className='container-list simple-scrollbar'>
                    {loading === 'root' ? (
                      <div>
                        {[...Array(5)].map((_, i) => (
                          <span key={i} className='d-flex align-items-center justify-content-start'>
                            <Skeleton animation='wave' width='17%' height={44} className='mr-2' />
                            <Skeleton animation='wave' width='73%' height={44} />
                          </span>
                        ))}
                      </div>
                    ) : (
                      renderTree(folders)
                    )}
                  </ul>
                </div>
              </div>
            )}
            <FolderContent
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
              setShowTree={setShowTree}
              folders={folders}
              setFolders={setFolders}
              change={change}
              fetching={fetching}
              setFetching={setFetching}
              auxBreadcrumb={breadcrumb}
              bookmarks
              canBookmark={canView}
            />
          </div>
        </DragDropContext>

        {showRenameFolder && (
          <PopupCreateRename
            rolePath={rolePath}
            startup={startup}
            folder={showRenameFolder}
            setRenamedFolder={setRenamedFolder}
            literals={literals}
            onClose={() => setShowRenameFolder(false)}
          />
        )}
        {showMovePopup && (
          <PopupMove
            literals={literals}
            elemToMove={showMovePopup}
            onClose={() => setShowMovePopup(false)}
            foldersProp={folders}
            setFoldersProp={setFolders}
            rootFolder={rootFolder}
            match={match}
            actualFolder={selectedFolder}
            setChange={setChange}
          />
        )}
      </PanelBody>
    </Panel>
  );
};

DataRoom.propTypes = {
  match: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
};

export default DataRoom;
