/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import { useParams } from 'react-router-dom';
import { Eye } from 'lucide-react';
import { getCaptableSummaryTable } from './CaptableSummary/modules/actions';
import { getCommonColumns } from './PanelCaptableShareholders';
import PopupInfoShares from './routes/Securities/components/PopupInfoShares';
import './styles.scss';

const PanelCaptableSyndicates = (props) => {
  const {
    literals, literalsCommon, captable, currency, date, type,
  } = props;

  const [showDetails, setShowDetails] = useState(false);
  const [unassigned, setUnassigned] = useState(false);
  const params = useParams();

  const loadSummaryTable = useCallback(date ? async (page, size, search, _, sort) => {
    const rows = await getCaptableSummaryTable(params.id, date, {
      group: type || 'syndicate',
      captable: captable.selected?.id,
      search,
      sort,
    });
    setUnassigned(rows.items.length - 2 >= rows.total);
    return rows;
  } : null, [date]);

  const columns = [
    ...getCommonColumns('syndicate', literals, currency),
    {
      field: 'id',
      type: 'button',
      hidden: id => ['total', 'unassigned', 'other'].includes(id),
      button: {
        icon: Eye,
        text: literalsCommon.view,
        onClick: row => setShowDetails({ type: 'syndicate', data: row }),
      },
    },
  ];

  return (
    <>
      <Table
        className={`captable-summary-table ${unassigned ? 'unassigned' : ''}`}
        columns={columns}
        fetch={loadSummaryTable}
        forceFetch={date}
        onClickRow={row => (!['total', 'unassigned', 'other'].includes(row.id)
          ? setShowDetails({ type: 'shareholder', data: row })
          : null)
        }
      />
      {
        showDetails && (
          <PopupInfoShares
            {...showDetails}
            date={date}
            literals={literals}
            literalsCommon={literalsCommon}
            currency={currency}
            onClose={() => setShowDetails(false)}
          />
        )
      }
    </>
  );
};

PanelCaptableSyndicates.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  date: PropTypes.string,
  canEdit: PropTypes.bool,
  submitOperation: PropTypes.func,
  type: PropTypes.string,
};

PanelCaptableSyndicates.defaultProps = {
  type: '',
  submitOperation: null,
  date: new Date().toISOString().split('T')[0],
  canEdit: false,
};

export default PanelCaptableSyndicates;
