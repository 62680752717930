import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import ToolTab from 'components/Tool/components/ToolTab';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import StartupCaptableDate from 'components/StartupCaptableDate/components';
import FolderContent from 'routes/DataRoom/components/FolderContent';
import { useSearchParams } from 'react-router-dom';
import { ROUTE_STARTUP_PATH } from 'routes';
import { TOOL_CAP_TABLE, TOOL_USERS } from 'constants/tools';
import { useCounter } from 'utils/customHooks';
import { getFolder } from 'routes/DataRoom/modules/actions';
import { startupCanManage } from 'utils/functions';
import { getShareHolder } from '../../../../modules/actions';
import ShareholderPartner from './shareholderPartner';
import ShareholderTransactions from './shareholderTransactions';
import ShareholderUsers from './shareholderUsers';
import ShareholderHistorial from './shareholderHistorial';

const ShareholderInfo = (props) => {
  const {
    literals,
    literalsDataroom,
    literalsCountries,
    literalsCommon,
    literalsShareholder,
    match,
    currency,
    lang,
    startup,
    captable,
  } = props;

  const [shareholder, setShareHolder] = useState(null);
  const [shareholderFolder, setShareholderFolder] = useState(null);
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const canViewUsers = startupCanManage(startup, TOOL_USERS) && startupCanManage(startup, TOOL_CAP_TABLE);
  const refresh = useCounter(0);

  const getShareHolderInfo = async () => {
    await getShareHolder(match.params.id, match.params.shareholder, { captable: captableId })
      .then((sh) => {
        setShareHolder(sh);
        getFolder('startup', match.params.id, sh.folder)
          .then(shFolder => setShareholderFolder(shFolder));
      });
  };

  useEffect(() => {
    setShareHolder(null);
    getShareHolderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh.value, captableId]);

  const breadcrumb = [
    { route: ROUTE_STARTUP_PATH.setCapTable(match.params.id, captableId), name: literals.captable },
    { route: ROUTE_STARTUP_PATH.setCapTableShareHolders(match.params.id, captableId), name: literals.shareholders },
    { name: `${shareholder?.firstname || ''} ${shareholder?.lastname || ''}` },
  ];

  const tabs = [
    { key: 'partner', name: literals.partner },
    { key: 'historial', name: literals.historial },
    { key: 'transactions', name: literals.transactions },
    { key: 'users', name: literals.users, hidden: !canViewUsers },
    { key: 'documents', name: literals.documents },
  ];

  return (
    <Tool tabsStyle='panel' tabs={tabs} breadcrumb={breadcrumb}>
      <StartupCaptableDate startup={startup} captable={captable} noDate />
      <ToolTab key='partner'>
        <ShareholderPartner
          literals={literals}
          literalsCountries={literalsCountries}
          literalsCommon={literalsCommon}
          match={match}
          currency={currency}
          startup={startup}
          shareholder={shareholder}
          refresh={refresh}
          lang={lang}
        />
      </ToolTab>
      <ToolTab key='historial'>
        <ShareholderHistorial
          literals={literals}
          match={match}
          currency={currency}
        />
      </ToolTab>
      <ToolTab key='transactions'>
        <ShareholderTransactions
          literals={literals}
          literalsCommon={literalsCommon}
          match={match}
        />
      </ToolTab>
      {canViewUsers && (
        <ToolTab key='users'>
          <ShareholderUsers
            literals={literals}
            literalsCommon={literalsCommon}
            literalsShareholder={literalsShareholder}
            match={match}
            currency={currency}
            shareholder={shareholder}
            startup={startup}
            lang={lang}
          />
        </ToolTab>
      )}
      <ToolTab key='documents'>
        <Panel>
          <PanelHeader title={literals.documents} />
          <PanelBody>
            <FolderContent
              literals={literalsDataroom}
              literalsCommon={literalsCommon}
              match={match}
              selectedFolder={shareholderFolder}
            />
          </PanelBody>
        </Panel>
      </ToolTab>
    </Tool>
  );
};

ShareholderInfo.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsDataroom: PropTypes.object.isRequired,
  literalsCountries: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  literalsShareholder: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  startup: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
};

export default ShareholderInfo;
