import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputSelect from 'components/Inputs/inputSelect';
import {
  CircleSlash, Eye, Pencil, Crown,
  Archive, Landmark, Briefcase, Settings, Users, User,
  Puzzle, Printer, MessageCircleMore, MessageSquareText,
} from 'lucide-react';
import { connect } from 'react-redux';

const InputPerms = ({
  platform,
  preText = '',
  value,
  literals,
  literalsTools,
  onChange,
  error = null,
  isRequired = true,
  isDisabled = false,
}) => {
  const [defaultPerms, setDefaultPerms] = useState({});

  useEffect(() => {
    const newDefaultPerms = {};
    Object.keys(platform.properties.tools).forEach((tool) => {
      if (platform.properties.tools[tool]) {
        newDefaultPerms[tool] = value[tool] ?? 1;
      }
    });
    setDefaultPerms(newDefaultPerms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const permsIcons = {
    capTable: <Briefcase size={18} />,
    dataRoom: <Archive size={18} />,
    mentoring: <MessageSquareText size={18} />,
    performance: <Settings size={18} />,
    profile: <User size={18} />,
    reporting: <Printer size={18} />,
    users: <Users size={18} />,
    messages: <MessageCircleMore size={18} />,
    services: <Puzzle size={18} />,
    fundraise: <Landmark size={18} />,
  };

  const permissionLevelOptions = [
    { name: literals.noPermission, id: '0', icon: <CircleSlash size={16} /> },
    { name: literals.see, id: '1', icon: <Eye size={16} /> },
    { name: literals.edit, id: '2', icon: <Pencil size={16} /> },
    { name: literals.manage, id: '3', icon: <Crown size={16} /> },
  ];

  const changeAll = (perm) => {
    const aux = {};
    Object.keys(value).forEach((val) => {
      aux[val] = perm;
    });
    onChange(aux);
  };

  return (
    <div className='input_perms'>
      <div className={`pre_text ${error ? 'text_error' : ''}`}>
        <div className='fw-b'>
          {preText}
          {isRequired && <span className='is_required'>*</span>}
        </div>
        {!isDisabled && (
          <div className='mb-2'>
            <span className='change-all' onClick={() => changeAll(0)}>{literals.none}</span>
            <span> / </span>
            <span className='change-all' onClick={() => changeAll(1)}>{literals.see}</span>
            <span> / </span>
            <span className='change-all' onClick={() => changeAll(2)}>{literals.edit}</span>
            <span> / </span>
            <span className='change-all' onClick={() => changeAll(3)}>{literals.manage}</span>
          </div>
        )}
      </div>
      <div className={`perms-list ${error ? 'input_error' : ''}`}>
        {Object.keys(defaultPerms).map(tool => (
          <div key={tool} className='perms-item'>
            <div className='perm-name'>
              {permsIcons[tool] ?? <Settings size={24} /> }
              {literalsTools[tool]}
            </div>
            <InputSelect
              key={tool}
              options={permissionLevelOptions}
              onChange={v => onChange({ ...value, [tool]: +v })}
              value={value[tool] ?? 0}
              isRequired={isRequired && !isDisabled}
              isDisabled={isDisabled}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

InputPerms.propTypes = {
  preText: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  literalsTools: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  platform: PropTypes.object.isRequired,
  error: PropTypes.object,
};

const mapStateToProps = state => ({
  literals: state.i18n.literals.startupUsers,
  literalsTools: state.i18n.literals.admin.platform.tools,
  platform: state.global.platform,
});

export default connect(mapStateToProps)(InputPerms);
