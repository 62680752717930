import { connect } from 'react-redux';
import AdminMentoringsComponents from '../components';

const mapStateToProps = state => ({
  literalsMentoring: state.i18n.literals.mentoring,
  literalsOnboarding: state.i18n.literals.onboarding,
  literalsCommon: state.i18n.literals.common,
  platform: state.global.platform,
  lang: state.i18n.language,
});

export default connect(mapStateToProps)(AdminMentoringsComponents);
