import React from 'react';
import Button from 'components/Buttons/Button';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import useDialog from './useDialog';
import './index.scss';

const DialogModal = ({ literals }) => {
  const { onClick, confirmState } = useDialog();

  const {
    show,
    title,
    type,
    text,
    actions,
  } = confirmState;

  if (!['alert', 'confirm', 'confirmDanger', 'success', 'error', 'custom', 'loading'].includes(type)) {
    return null;
  }

  const renderButtons = () => {
    const buttons = [];
    switch (type) {
      case 'custom':
        actions.forEach((action, i) => {
          buttons.push(<Button key={`custom-${i}`} className='btn-sm' {...action} onClick={() => onClick(action.onClick())} />);
        });
        break;
      case 'alert':
      case 'success':
      case 'error': {
        let color = 'primary';
        if (type === 'success') {
          color = 'success';
        } else if (type === 'error') {
          color = 'danger';
        }
        buttons.push(<Button key='confirm' className='btn-sm' color={color} text={literals.accept} onClick={() => onClick(true)} />);
        break;
      }
      default:
        buttons.push(<Button key='confirm' className='btn-sm' color={type === 'confirmDanger' ? 'danger' : 'primary'} text={literals.confirm} onClick={() => onClick(true)} />);
        buttons.push(<Button key='cancel' className='btn-sm' color='transparent' text={literals.cancel} onClick={() => onClick(false)} />);
        break;
    }

    return (
      <div className='dialog-actions'>
        {buttons.map(button => button)}
      </div>
    );
  };

  return show ? (
    <div className={`dialog dialog-${type}`}>
      <div className='dialog-content'>
        {
          type === 'loading' ? (
            <Loading hide={false} mode='dialog' />
          ) : (
            <>
              { title && (<h4 className='fw-b'>{title}</h4>) }
              { text && (<p>{text}</p>) }
              { renderButtons() }
            </>
          )
        }
      </div>
    </div>
  ) : null;
};

DialogModal.propTypes = {
  literals: PropTypes.object.isRequired,
};


export default DialogModal;
