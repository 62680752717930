import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH, ROUTE_STARTUP_PATH } from 'routes';
import { startupCanEdit } from 'utils/functions';
import { AmplitudeApi } from 'utils/amplitude';
import { TOOL_PROFILE } from 'constants/tools';
import { fetchStartup } from 'routes/Onboarding/modules/actions';

function usePreComponent(props) {
  const {
    startup, match, setGlobalProperties, literalsCommon,
  } = props;
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const { BASE, setEmbed } = ROUTE_STARTUP_PATH;

    if (match?.pathname?.startsWith(BASE) || match?.pathname?.startsWith(setEmbed(BASE))) {
      (async () => {
        if (startup?.id !== match.params.id) {
          try {
            const startupInfo = await fetchStartup(match.params.id);
            if (startupInfo) {
              AmplitudeApi.setGlobalEventProperties({ startup: startupInfo.id });
              setGlobalProperties({
                startup: startupInfo,
                currency: {
                  id: startupInfo.currency,
                  symbol: literalsCommon.currenciesSymbols[startupInfo.currency],
                },
              });
              if (startupInfo?.phantom && startupCanEdit(startupInfo, TOOL_PROFILE)) {
                navigate(ROUTE_STARTUP_PATH.setOnboardingPhantom(match.params.id), { replace: true });
              }
            }
          } catch (err) {
            navigate(ROUTE_PATH.NOT_FOUND, { replace: true });
          }
        }
      })();
    } else if (startup) {
      AmplitudeApi.setGlobalEventProperties({ startup: null });
      setGlobalProperties({
        startup: null,
        currency: { name: 'EUR', symbol: '€' },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.pathname]);

  return {};
}

export default usePreComponent;
