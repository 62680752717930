import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setGlobalPropertiesAction } from 'store/globalState/global';
import ShareholdersSelect from '../components';

const mapStateToProps = (state) => {
  return {
    literalsCommon: state.i18n.literals.common,
    literalsCaptable: state.i18n.literals.captable,
    user: state.session.user,
    shareholders: state.global.investments?.shareholders,
  };
};

const mapDispatchToProps = dispatch => ({
  setGlobalProperties: bindActionCreators(setGlobalPropertiesAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareholdersSelect);
