import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import InputText from 'components/Inputs/inputText';
import InputRichText from 'components/Inputs/inputRichText';
import ButtonsDotsMenu from 'components/Buttons/ButtonsDotsMenu';
import useDialog from 'components/Dialog/components/useDialog';
import { Skeleton } from '@mui/material';
import {
  Clipboard, Award, Trash, Pencil, X,
  Bookmark, PlusCircle, Sheet, ArrowLeft,
} from 'lucide-react';
import { initializeCaptable } from 'routes/Captable/modules/actions';
import { literalTemplate } from 'utils/language';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InputPermission from 'components/Inputs/InputPermission';
import { PERMISSION_LEVELS, PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import { initPermission } from 'utils/functions/initPermissions';
import { TOOL_CAP_TABLE } from 'constants/tools';
import PermissionLabel from 'components/PermissionsPopup/PermissionLabel';
import OutputErrors from 'components/Inputs/outputErrors';
import { startupUserPermissionLevel } from 'utils/functions';
import InputToggle from 'components/Inputs/inputToggle';

const CaptableSelector = React.memo((props) => {
  const {
    literals,
    literalsCommon,
    user,
    captable,
    popup,
    setPopup,
    postStartupCaptable,
    putStartupCaptable,
    markStartupCaptableAsFavorite,
    deleteStartupCaptable,
    match,
    noDate,
    fetchCaptable,
    startup,
    editable,
  } = props;

  const POPUP_CAPTABLE = 'captable';
  const POPUP_CAPTABLE_CREATE = 'captable_create';

  const [params] = useSearchParams();
  const [selectedCaptable, setSelectedCaptable] = useState();
  const [newCaptable, setNewCaptable] = useState({
    name: '', description: '', official: false, favorite: false, valuationDiluted: false,
  });
  const [permission, setPermission] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const { dialog } = useDialog();
  const { canEdit } = permission || {};
  const toolLevel = startupUserPermissionLevel(startup, TOOL_CAP_TABLE);
  const navigate = useNavigate();

  useEffect(() => {
    initializeCaptable(fetchCaptable, captable, startup.id, params.get('captable'));
    if (captable) {
      setSelectedCaptable(captable.selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captable]);

  const handleCreateCaptable = async () => {
    setErrors([]);
    setLoading(true);
    const data = {
      ...newCaptable,
      permission,
    };
    try {
      if (data.id) {
        await putStartupCaptable(match.params.id, data.id, data);
      } else {
        const res = await postStartupCaptable(match.params.id, data);
        navigate({ search: `?captable=${res.id}` });
        initializeCaptable(fetchCaptable, captable, startup.id, res.id);
        setSelectedCaptable(res);
      }
      setLoading(false);
      setPopup(null);
    } catch (error) {
      setErrors(error);
      setLoading(false);
    }
  };

  const handleDeleteCaptable = async (cap) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literals.deleteCaptableConfirm,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            await deleteStartupCaptable(match.params.id, cap);
          } catch (error) {
            await dialog({
              type: 'error',
              text: <OutputErrors errors={error} text />,
            });
          }
        },
      });
    }
  };

  const handleOfficialCaptable = async (cap) => {
    const confirm = await dialog({
      type: 'confirm',
      text: literals.officialConfirm,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            await putStartupCaptable(match.params.id, cap.id, { ...cap, official: true });
          } catch (error) {
            await dialog({
              type: 'error',
              text: <OutputErrors errors={error} text />,
            });
          }
        },
      });
    }
  };

  const handleFavoriteCaptable = async (cap) => {
    const confirm = await dialog({
      type: 'confirm',
      text: literals.favoriteConfirm,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            await markStartupCaptableAsFavorite(match.params.id, cap.id);
          } catch (error) {
            await dialog({
              type: 'error',
              text: <OutputErrors errors={error} text />,
            });
          }
        },
      });
    }
  };

  const renderOperations = (cap) => {
    return (
      <div
        key={cap.id}
        className='row-date'
        onClick={() => {
          navigate({ search: `?captable=${cap.id}` });
          setSelectedCaptable(cap);
          initializeCaptable(fetchCaptable, captable, startup.id, cap.id);
          setPopup(null);
        }}
      >
        <div className={`row-date-left ${cap.id === selectedCaptable?.id ? 'selected' : ''}`}>
          <div className='row-icon'><Clipboard size={14} /></div>
          <h6 className='date-name mb-0 mx-2' title={cap.name}>{cap.name}</h6>
          { cap.official && <Award size={14} /> }
          { cap?.favorite === user.id && <Bookmark size={14} /> }
          <PermissionLabel permission={cap.permission} />
        </div>
        {editable && (
          <div>
            <ButtonsDotsMenu
              key={`menu-document-${document.id}`}
              offset={{ top: -1, left: 24 }}
              buttons={[
                {
                  text: literalsCommon.edit,
                  icon: Pencil,
                  onClick: () => {
                    setNewCaptable(cap);
                    setPermission(initPermission(
                      cap,
                      PERMISSION_SCOPES.STARTUP,
                      startup,
                      TOOL_CAP_TABLE,
                    ));
                    setPopup(POPUP_CAPTABLE_CREATE);
                  },
                },
                {
                  text: literals.official,
                  icon: Award,
                  onClick: () => handleOfficialCaptable(cap),
                  hidden: toolLevel < PERMISSION_LEVELS.EDIT,
                  disabled: cap.official,
                },
                {
                  text: literals.favorite,
                  icon: Bookmark,
                  onClick: () => handleFavoriteCaptable(cap),
                  hidden: toolLevel !== PERMISSION_LEVELS.EXTERNAL,
                  disabled: cap.favorite,
                },
                {
                  text: literalsCommon.delete,
                  icon: Trash,
                  onClick: () => handleDeleteCaptable(cap.id),
                  disabled: cap.official,
                  hidden: cap.permission.level < PERMISSION_LEVELS.EDIT,
                },
              ]}
            />
          </div>
        )}
      </div>
    );
  };

  if (captable.loaded && !captable?.captables?.length) {
    return null;
  }

  return (
    <div className='captable-select'>
      <div className={`captable-select-box ${noDate ? 'noDate' : ''}`} onClick={() => setPopup(POPUP_CAPTABLE)}>
        <Clipboard size={14} color='white' />
        { captable?.loaded ? (
          <span className='d-flex align-items-center'>
            {selectedCaptable ? (
              <>
                <div className='captable-select-box--name'>{ selectedCaptable ? selectedCaptable.name : '' }</div>
                { selectedCaptable?.official && <Award size={14} className='ml-2' /> }
                { selectedCaptable?.favorite === user.id && <Bookmark size={14} className='ml-2' /> }
              </>
            ) : literals.noCaptables}
          </span>
        ) : (
          <Skeleton width='80px' />
        )}
      </div>
      {
        popup === POPUP_CAPTABLE && (
          <div className='captable-popup'>
            <div className='timeline-box-header'>
              <div className='closeOption'>
                <X color='white' size={18} onClick={() => setPopup(null)} />
              </div>
            </div>
            <div className='captable-popup-body'>
              {editable && (
                <div className='buttons mb-2'>
                  <Button
                    icon={Sheet}
                    color='secondary'
                    text={literals.moreDetails}
                    onClick={() => navigate(embedView(ROUTE_STARTUP_PATH.setCapTableCaptables(match.params.id)))}
                  />
                  <Button
                    icon={PlusCircle}
                    text={literalsCommon.create}
                    onClick={() => {
                      setNewCaptable({ name: '', description: '' });
                      setPermission(initPermission(
                        null,
                        PERMISSION_SCOPES.STARTUP,
                        startup,
                        TOOL_CAP_TABLE,
                      ));
                      setPopup(POPUP_CAPTABLE_CREATE);
                    }}
                  />
                </div>
              )}
              {captable?.captables?.length ? (
                <>
                  <div className='timelineSelect'>
                    <div className='timeline-value'>{literals.captables}</div>
                  </div>
                  <div className='dates simple-scrollbar'>
                    {captable.captables.map(renderOperations)}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        )
      }
      {
        popup === POPUP_CAPTABLE_CREATE && (
          <div className='captable-popup'>
            <div className='timeline-box-header'>
              <div className='closeOption'>
                <X color='white' size={18} onClick={() => setPopup(null)} />
              </div>
            </div>
            <div className='captable-popup-body'>
              <InputPermission
                scope={{ type: PERMISSION_SCOPES.STARTUP, id: startup.id }}
                element={{ type: PERMISSION_TYPES.CAPTABLE, id: captable?.id }}
                value={permission}
                onChange={setPermission}
              />
              <InputText
                preText={literalsCommon.name}
                value={newCaptable.name}
                onChange={v => setNewCaptable(prev => ({ ...prev, name: v }))}
                isDisabled={!canEdit}
                postText={literalTemplate(literals.maxCharacters, { NUMBER: 50 })}
              />
              <div className='d-flex justify-content-between'>
                <div>
                  {
                    toolLevel >= PERMISSION_LEVELS.EDIT && (
                      <InputToggle
                        preText={literals.official}
                        value={newCaptable.official}
                        onChange={official => setNewCaptable(prev => ({ ...prev, official }))}
                      />
                    )
                  }
                  {
                    toolLevel === PERMISSION_LEVELS.EXTERNAL && (
                      <InputToggle
                        preText={literals.favorite}
                        value={newCaptable.favorite}
                        onChange={favorite => setNewCaptable(prev => ({ ...prev, favorite }))}
                      />
                    )
                  }
                </div>

                <div>
                  <InputToggle
                    preText={literals.valuationDiluted}
                    value={newCaptable?.valuationDiluted}
                    onChange={valuationDiluted => setNewCaptable(prev => ({ ...prev, valuationDiluted }))}
                    isDisabled={!canEdit}
                  />
                </div>
              </div>

              <InputRichText
                preText={literalsCommon.description}
                value={newCaptable.description}
                onChange={v => setNewCaptable(prev => ({ ...prev, description: v }))}
                isDisabled={!canEdit}
              />
              <OutputErrors errors={errors} />
              {canEdit && (
                <div className='buttons'>
                  <Button
                    color='secondary'
                    icon={ArrowLeft}
                    text={literalsCommon.back}
                    onClick={() => setPopup(POPUP_CAPTABLE)}
                    loading={loading}
                  />
                  <Button
                    icon={newCaptable.id ? Pencil : PlusCircle}
                    text={newCaptable.id ? literalsCommon.edit : literalsCommon.create}
                    onClick={handleCreateCaptable}
                    loading={loading}
                  />
                </div>
              )}
            </div>
            <OutputErrors errors={errors} />
          </div>
        )
      }
    </div>
  );
});

CaptableSelector.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  postStartupCaptable: PropTypes.func.isRequired,
  popup: PropTypes.string,
  setPopup: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  noDate: PropTypes.bool.isRequired,
  editable: PropTypes.bool,
  user: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  putStartupCaptable: PropTypes.func.isRequired,
  markStartupCaptableAsFavorite: PropTypes.func.isRequired,
  deleteStartupCaptable: PropTypes.func.isRequired,
  fetchCaptable: PropTypes.func.isRequired,
};

CaptableSelector.defaultProps = {
  popup: null,
  editable: true,
};

export default CaptableSelector;
