import React from 'react';
import PropTypes from 'prop-types';
import SkeletonDoughnutChart from 'components/Loading/skeletonDoughnutChart';
import ReactECharts from 'echarts-for-react';
import { getColorRange } from 'utils/functions';
import './styles.scss';


const PercentageCircleChart = ({ loading, value }) => {
  const gaugeData = [
    {
      value: (value * 100).toFixed(2),
      detail: {
        valueAnimation: true,
        offsetCenter: ['0%', '0%'],
      },
    },
  ];

  const chartOptions = {
    color: getColorRange(1),
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false,
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            borderWidth: 1,
            borderColor: '#464646',
          },
        },
        axisLine: {
          lineStyle: {
            width: 20,
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
          distance: 50,
        },
        data: gaugeData,
        title: {
          fontSize: 14,
        },
        detail: {
          width: 50,
          height: 14,
          fontSize: 24,
          color: 'inherit',
          formatter: '{value}%',
        },
      },
    ],
  };

  return (
    <div className='d-flex align-items-center' style={{ height: 300 }}>
      {loading
        ? <SkeletonDoughnutChart className='my-0' size={220} />
        : <ReactECharts style={{ width: '100%' }} option={chartOptions} />
      }
    </div>
  );
};

PercentageCircleChart.propTypes = {
  value: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default PercentageCircleChart;
