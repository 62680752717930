import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Portal from 'components/Portal';
import { X } from 'lucide-react';
import './styles.scss';

const Popup = ({
  title = '',
  description = '',
  size = 'medium',
  onClose,
  children = {},
}) => {
  useEffect(() => {
    document.body.classList.add('popup-open');
    return () => {
      document.body.classList.remove('popup-open');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Portal type='popup'>
      <div className={`popup popupSize-${size}`}>
        <div className='popup-container'>
          <div className='popup-head'>
            <h4 className='popup-title fw-b'>{title}</h4>
            <div className='popup-close'>
              <X onClick={onClose} size={20} />
            </div>
          </div>
          <div className='popup-content'>
            { description && (<p className='popup-description'>{description}</p>) }
            {children}
          </div>
        </div>
      </div>
    </Portal>
  );
};

Popup.propTypes = {
  size: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onClose: PropTypes.func.isRequired,
};

export default Popup;
