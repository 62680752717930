import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'utils/withRouter';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import {
  Bookmark, Trash, CirclePlus, Pencil, ChevronRight, Check,
} from 'lucide-react';
import { startupCanManage } from 'utils/functions';
import { TOOL_PERFORMANCE } from 'constants/tools';
import { KPI_AGGREGATION } from 'constants/kpis';
import { useCounter } from 'utils/customHooks';
import useDialog from 'components/Dialog/components/useDialog';
import InputSelect from 'components/Inputs/inputSelect';
import InputRichText from 'components/Inputs/inputRichText';
import InputPermission from 'components/Inputs/InputPermission';
import { PERMISSION_TYPES } from 'constants/permissions';
import { initPermission } from 'utils/functions/initPermissions';
import InputTextArea from 'components/Inputs/inputTextArea';
import OutputValue from 'components/Inputs/outputValue';
import KpiRelationBox from './KpiRelationBox';
import KpiInputDate from './KpiInputDate';
import KpiInputValue from './KpiInputValue';
import KpiRelationValues from './KpiRelationValues';
import PerformanceAddTabCustom from './routes/PerformanceAdd/components/PerformanceAddTabCustom';
import {
  deleteKpiBookmarkAction,
  deleteKpiRelationAction,
  deleteKpiValueAction,
  postKpiBookmarkAction,
  editKpiRelationAction,
  postKpiValueAction,
  putKpiAction,
} from '../modules/actions';

const KpiRelationDetails = (props) => {
  const {
    literals,
    literalsCommon,
    relation,
    match,
    user,
    startup,
    currency,
    editKpiRelation,
    deleteKpiRelation,
    postKpiValue,
    deleteKpiValue,
    postKpiBookmark,
    deleteKpiBookmark,
    onUpdateValues,
    onDeleteRelation,
    putKpi,
    onClose,
    refreshFillTableFlag,
  } = props;

  const defaultNewValue = {
    date: null, forecast: null, value: null, notes: null, haveNotes: false, loading: false,
  };

  const [newValue, setNewValue] = useState(defaultNewValue);
  const [configRelation, setConfigRelation] = useState({
    target: relation.target,
    aggregate: relation.aggregate ?? 'eop',
    permission: initPermission(relation?.id ? relation : null, relation.scope.type, startup, TOOL_PERFORMANCE),
    notes: relation.notes ?? '',
    loading: false,
  });
  const [loadingBookmark, setLoadingBookmark] = useState(false);
  const [editKpi, setEditKpi] = useState(false);
  const kpi = {
    ...relation.kpi,
    permission: initPermission(relation.kpi?.id ? relation.kpi : null, relation.scope.type, startup, TOOL_PERFORMANCE),
  };
  const tableRefreshFlag = useCounter(0);
  const { dialog } = useDialog();
  const valueSymbol = kpi.unit === 'currency' ? currency.symbol : '';

  const handleClickDelete = async () => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literals.questionUnlinkKpi,
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteKpiRelation(kpi.id, 'startup', match.params.id, relation.id);
          if (onDeleteRelation) {
            onDeleteRelation();
          }
        },
      });
    }
  };

  const handleDeleteValue = async (row) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literals.questionDeleteKpiValue,
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteKpiValue(kpi.id, 'startup', match.params.id, relation.id, row.date);
          tableRefreshFlag.increase();
          if (refreshFillTableFlag) {
            refreshFillTableFlag.increase();
          }
          if (onUpdateValues) {
            onUpdateValues();
          }
        },
      });
    }
  };

  const submitConfig = async (ev) => {
    ev.preventDefault();
    setConfigRelation(prev => ({ ...prev, loading: true }));
    const payload = {
      target: configRelation.target !== '' ? parseFloat(configRelation.target) : null,
      aggregate: configRelation.aggregate,
      permission: configRelation.permission,
      notes: configRelation.notes,
    };
    await editKpiRelation(kpi.id, 'startup', match.params.id, relation.id, payload);
    setConfigRelation(prev => ({ ...prev, loading: false }));
    tableRefreshFlag.increase();
    if (refreshFillTableFlag) {
      refreshFillTableFlag.increase();
    }
  };

  const submitNewValue = async (ev) => {
    ev.preventDefault();
    setNewValue({ ...newValue, loading: true });
    let date = typeof newValue.date === 'number' ? newValue.date.toString() : newValue.date;
    date = date.length === 4 ? `${newValue.date}-12` : newValue.date;
    await postKpiValue(kpi.id, 'startup', match.params.id, relation.id, date, {
      value: newValue.value || newValue.value === 0 ? newValue.value : null,
      forecast: newValue.forecast || newValue.forecast === 0 ? newValue.forecast : null,
      notes: newValue.notes,
    });
    tableRefreshFlag.increase();
    if (refreshFillTableFlag) {
      refreshFillTableFlag.increase();
    }
    setNewValue(defaultNewValue);
    if (onUpdateValues) {
      onUpdateValues();
    }
  };

  const { canEdit } = configRelation.permission;
  const { canEdit: canEditKpi } = kpi.permission;
  const canManagePerformance = startupCanManage(startup, TOOL_PERFORMANCE);

  const handleClickBookmark = async (ev, active) => {
    if (canEdit && !loadingBookmark) {
      ev.stopPropagation();
      setLoadingBookmark(true);
      if (active) {
        await postKpiBookmark(kpi.id, 'startup', match.params.id, relation.id);
      } else {
        await deleteKpiBookmark(kpi.id, 'startup', match.params.id, relation.id);
      }
      setLoadingBookmark(false);
    }
  };

  if (editKpi) {
    return (
      <PerformanceAddTabCustom
        match={match}
        startup={startup}
        literals={literals}
        literalsCommon={literalsCommon}
        kpi={editKpi}
        onSubmit={putKpi}
        onClose={() => {
          onClose();
          tableRefreshFlag.increase();
          if (refreshFillTableFlag) {
            refreshFillTableFlag.increase();
          }
        }}
        lang={user.lang}
      />
    );
  }

  return (
    <div className='kpi-relation-details'>
      <div className='krd-columns'>
        <div className='krd-column-left'>
          <KpiRelationBox
            relation={relation}
            lang={user.lang}
            match={match}
            currency={currency}
            postKpiBookmark={canManagePerformance ? postKpiBookmark : null}
            deleteKpiBookmark={canManagePerformance ? deleteKpiBookmark : null}
          />
        </div>
        <div className='krd-column-right'>
          {canEdit ? (
            <>
              {canEditKpi && (
                <Button
                  icon={Pencil}
                  text={literals.kpi}
                  onClick={() => setEditKpi(kpi)}
                />
              )}
              <Button
                icon={Trash}
                text={literalsCommon.delete}
                color='danger'
                onClick={handleClickDelete}
              />
            </>
          ) : null}
          {canManagePerformance && (
            <Button
              icon={<Bookmark fill={relation.bookmark ? 'currentColor' : 'none'} />}
              text={relation.bookmark ? literals.unbookmark : literals.bookmark}
              color='secondary'
              onClick={ev => handleClickBookmark(ev, !relation.bookmark)}
              loading={loadingBookmark}
            />
          )}
        </div>
      </div>
      <div className='krd-info box box-padding'>
        <div className='row'>
          <div className='col-12'>
            <OutputValue
              preText={literalsCommon.description}
              value={kpi.description[user.lang]}
              clean
            />
          </div>
          <div className='col-12 col-md-4'>
            <OutputValue
              preText={literals.periodicity}
              value={literals[kpi.periodicity]}
              clean
            />
          </div>
          <div className='col-12 col-md-4'>
            <OutputValue
              preText={literals.unit}
              value={`${literals[kpi.unit]} ( ${kpi.min ?? '-∞'} / ${kpi.max ?? '+∞'} )`}
              clean
            />
          </div>
          <div className='col-12 col-md-4'>
            <OutputValue
              preText={literals.positive}
              value={literals[kpi.positive]}
              clean
            />
          </div>
        </div>
        <form onSubmit={submitConfig}>
          <div className='row'>
            <div className='col-12 col-md-4'>
              <KpiInputValue
                preText={`${literals.target} ${valueSymbol ? `(${valueSymbol})` : ''}`}
                value={configRelation.target}
                kpi={kpi}
                currency={currency}
                onChange={target => setConfigRelation(prev => ({ ...prev, target }))}
                isDisabled={!canEdit}
              />
            </div>
            <div className='col-12 col-md-4'>
              <InputSelect
                preText={literals.aggregate}
                placeholder={literals.aggregate}
                onChange={aggregate => setConfigRelation(prev => ({ ...prev, aggregate }))}
                value={configRelation.aggregate}
                options={[
                  { id: KPI_AGGREGATION.EOP, name: literals.endOfPeriod },
                  { id: KPI_AGGREGATION.ACC, name: literals.accumulative },
                  { id: KPI_AGGREGATION.AVG, name: literals.average },
                ]}
                isDisabled={!canEdit}
              />
            </div>
            <div className='col-12 col-md-4'>
              <InputPermission
                scope={{ type: relation.scope.type, id: relation.scope.owner }}
                element={{ type: PERMISSION_TYPES.KPI_RELATION, id: relation.id }}
                value={configRelation.permission}
                onChange={permission => setConfigRelation(prev => ({ ...prev, permission }))}
              />
            </div>
            <div className='col-12'>
              <InputTextArea
                preText={literals.notes}
                placeholder={literals.notes}
                value={configRelation.notes}
                onChange={notes => setConfigRelation(prev => ({ ...prev, notes }))}
                isDisabled={!canEdit}
              />
            </div>
          </div>
          {canEdit && (
            <div className='buttons'>
              <Button
                icon={Check}
                text={literalsCommon.send}
                type='submit'
                loading={configRelation.loading}
              />
            </div>
          )}
        </form>
      </div>
      {canEdit && (
        <form className='krd-form box box-padding' onSubmit={submitNewValue}>
          <h5 className='fw-b'>{`${literals.addNewValue} ${valueSymbol}`}</h5>
          <div className='krd-form-fields'>
            <div>
              <KpiInputDate
                preText={literalsCommon.date}
                value={newValue.date}
                kpi={kpi}
                onChange={v => setNewValue({
                  ...newValue,
                  date: v,
                  value: new Date(v) > new Date() ? null : newValue.value,
                })}
              />
            </div>
            <div>
              <KpiInputValue
                value={newValue.forecast}
                preText={literals.forecast}
                kpi={kpi}
                currency={currency}
                onChange={v => setNewValue({ ...newValue, forecast: v })}
              />
            </div>
            <div>
              <KpiInputValue
                value={newValue.value}
                preText={literals.value}
                kpi={kpi}
                currency={currency}
                onChange={v => setNewValue({ ...newValue, value: v })}
                isDisabled={new Date(newValue.date) > new Date()}
              />
            </div>
            <div>
              <Button
                icon={CirclePlus}
                text={literalsCommon.add}
                type='submit'
                disabled={!newValue.date || (newValue.forecast === '' && newValue.value === '')}
                loading={newValue.loading}
              />
            </div>
          </div>
          <div className={`krd-form-notes ${!newValue.haveNotes ? 'collapse-notes' : ''}`}>
            <span className='pl-2' onClick={() => setNewValue({ ...newValue, haveNotes: !newValue.haveNotes })}>
              <ChevronRight size={18} className={`notes-icon ${newValue.haveNotes ? 'open' : ''}`} />
              {!newValue.haveNotes ? literals.addNotes : literals.removeNotes}
            </span>
            <InputRichText
              preText={literals.notes}
              value={newValue.notes}
              onChange={value => setNewValue({ ...newValue, notes: value })}
              minHeight='150px'
            />
          </div>
        </form>
      )}
      <KpiRelationValues
        literals={literals}
        literalsCommon={literalsCommon}
        relation={relation}
        currency={currency}
        onDelete={canEdit ? handleDeleteValue : null}
        forceFetch={tableRefreshFlag.value}
        refreshFillTableFlag={refreshFillTableFlag}
        canEdit={canEdit}
      />
    </div>
  );
};

KpiRelationDetails.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  relation: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  editKpiRelation: PropTypes.func.isRequired,
  deleteKpiRelation: PropTypes.func.isRequired,
  postKpiValue: PropTypes.func.isRequired,
  deleteKpiValue: PropTypes.func.isRequired,
  postKpiBookmark: PropTypes.func.isRequired,
  deleteKpiBookmark: PropTypes.func.isRequired,
  putKpi: PropTypes.func.isRequired,
  onUpdateValues: PropTypes.func,
  onDeleteRelation: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  refreshFillTableFlag: PropTypes.object,
};

KpiRelationDetails.defaultProps = {
  onUpdateValues: null,
  onDeleteRelation: null,
  refreshFillTableFlag: null,
};

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.performance,
    literalsCommon: state.i18n.literals.common,
    performance: state.performance,
    user: state.session.user,
    lang: state.session.user.lang,
    currency: state.global.currency,
  };
}

const mapDispatchToProps = dispatch => ({
  putKpi: bindActionCreators(putKpiAction, dispatch),
  editKpiRelation: bindActionCreators(editKpiRelationAction, dispatch),
  deleteKpiRelation: bindActionCreators(deleteKpiRelationAction, dispatch),
  postKpiValue: bindActionCreators(postKpiValueAction, dispatch),
  deleteKpiValue: bindActionCreators(deleteKpiValueAction, dispatch),
  postKpiBookmark: bindActionCreators(postKpiBookmarkAction, dispatch),
  deleteKpiBookmark: bindActionCreators(deleteKpiBookmarkAction, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KpiRelationDetails));
