import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import { useParams } from 'react-router-dom';
import { formatNumber } from 'utils/functions';
import { Eye } from 'lucide-react';
import { fetchCaptableDebtsCapitalizations } from './routes/Securities/modules/actions';
import './styles.scss';

const PanelDebtsCapitalized = ({
  literals, literalsCommon, currency, captable,
  date, setShowDebtDetails, forceFetch,
}) => {
  const params = useParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDebts = useCallback((date ? (page, size, filters) => {
    return fetchCaptableDebtsCapitalizations(params.id, date, {
      page, size, filters, captable: captable.selected.id,
    });
  } : null), [date]);

  const columnsDebtCapitalized = [
    {
      field: 'relation',
      grow: 2,
      label: literals.shareholder,
      preRender: v => v?.info?.shareholder?.name || '-',
    },
    {
      field: 'relation',
      grow: 2,
      label: literals.debt,
      preRender: v => v?.info?.name || '-',
    },
    {
      field: 'number',
      width: 100,
      label: literals.number,
      preRender: v => `${formatNumber(v, 0, { dec: 2 })}${currency.symbol}`,
    },
    {
      field: 'pps',
      width: 100,
      label: literals.pps,
    },
    {
      field: 'shareClass',
      width: 100,
      label: literals.shareClass,
      preRender: v => v.code || v.name || '-',
    },
    {
      field: 'shares',
      width: 100,
      label: literals.shares,
    },
    {
      field: 'operation',
      width: 100,
      label: literalsCommon.date,
      preRender: v => v.date,
    },
    {
      field: 'id',
      type: 'button',
      button: {
        icon: Eye,
        text: literalsCommon.viewDetail,
        onClick: debt => setShowDebtDetails({
          debt: debt.relation.id, shareholder: debt.relation.info.shareholder,
        }),
      },
    },
  ];

  return (
    <Table
      searcher={false}
      columns={columnsDebtCapitalized}
      fetch={fetchDebts}
      forceFetch={forceFetch}
      onClickRow={debt => setShowDebtDetails({
        debt: debt.relation.id, shareholder: debt.relation.info.shareholder,
      })}
    />
  );
};

PanelDebtsCapitalized.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  date: PropTypes.string,
  forceFetch: PropTypes.number.isRequired,
  setShowDebtDetails: PropTypes.func.isRequired,
};

PanelDebtsCapitalized.defaultProps = {
  date: new Date().toISOString().split('T')[0],
};

export default PanelDebtsCapitalized;
