import React from 'react';
import PropTypes from 'prop-types';
import InputNumber from 'components/Inputs/inputNumber';

const PopupFormShareClassVote = (props) => {
  const {
    vote, onChange, literals, isDisabled,
  } = props;

  return (
    <div className='col-md-9'>
      <InputNumber
        name='value'
        preText={literals.votesPerShare}
        value={vote?.value ? vote?.value : 0}
        minValue={0}
        onChange={value => onChange('value', value)}
        symbol='X'
        isDisabled={isDisabled}
      />
    </div>
  );
};

PopupFormShareClassVote.propTypes = {
  vote: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  literals: PropTypes.object.isRequired,
};

export default PopupFormShareClassVote;
