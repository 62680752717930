import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Avatar from 'components/Avatar';
import Button from 'components/Buttons/Button';
import useDialog from 'components/Dialog/components/useDialog';
import { Plus } from 'lucide-react';
import { getTranslation } from 'utils/functions';
import { createServicesRequest, deleteServicesRequest } from '../modules/actions';

const PopupInfoService = ({
  literals,
  literalsCommon,
  id,
  startup,
  logo = null,
  company,
  description,
  request = null,
  type = null,
  lang,
  handleChangeRequest,
  onClose,
}) => {
  const { dialog } = useDialog();

  const handleCreateServiceRequest = async () => {
    const confirm = await dialog({
      type: 'confirm', text: literals.questionContactManager,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          const response = await createServicesRequest({ service: id, startup });
          handleChangeRequest(response);
          await dialog({
            type: 'alert', text: literals.confirmAnswer,
          });
        },
      });
    }
  };

  const handleCancelServiceRequest = async () => {
    const confirm = await dialog({
      type: 'confirmDanger', text: literals.questionRemoveRequest,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteServicesRequest(request.id);
          handleChangeRequest(null);
          await dialog({
            type: 'alert', text: literals.cancelRequestAnswer,
          });
        },
      });
    }
  };

  return (
    <Popup title={literals.serviceInfo} onClose={onClose}>
      <div className='service-header'>
        <div className='service-info'>
          <Avatar title={getTranslation(company, lang)} src={logo?.path} size={75} />
          <div className='service-info--name'>
            <h5 className='fw-b'>{getTranslation(company, lang)}</h5>
            <span>{getTranslation(type, lang)}</span>
          </div>
        </div>
        { request ? (
          <div className='service-status'>
            <div>
              <span className='fw-b'>{`${literals.status}: `}</span>
              <span>{literals[request.status]}</span>
            </div>
            <Button
              color='danger'
              className='btn-sm mt-2'
              text={literalsCommon.cancel}
              onClick={handleCancelServiceRequest}
            />
          </div>
        ) : (
          <Button
            icon={Plus}
            text={literals.requestInfo}
            onClick={handleCreateServiceRequest}
          />
        )}
      </div>
      <div className='service-info'>
        <h6 className='fw-b'>{literalsCommon.description}</h6>
        <div dangerouslySetInnerHTML={{ __html: getTranslation(description, lang) }} />
      </div>
    </Popup>
  );
};

PopupInfoService.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  startup: PropTypes.string.isRequired,
  logo: PropTypes.object,
  company: PropTypes.object.isRequired,
  description: PropTypes.object.isRequired,
  type: PropTypes.object,
  lang: PropTypes.string.isRequired,
  request: PropTypes.object,
  handleChangeRequest: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupInfoService;
