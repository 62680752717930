import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/functions';
import './styles.scss';

function InputDateTime({
  preText = '',
  postText = '',
  placeholder = '',
  isRequired = false,
  isDisabled = false,
  errors = [],
  value = '',
  maxDate = '',
  minDate = '',
  maxHour = '',
  minHour = '',
  onChange,
  onBlur = () => {},
}) {
  const changeDate = (e) => {
    const auxHour = value ? value.split('T')[1] : '12:00';
    onChange(`${e.target.value}T${auxHour}`);
  };

  const changeHour = (e) => {
    const auxDate = value ? value.split('T')[0] : formatDate(new Date(), { format: 'Y-m-d' });
    onChange(`${auxDate}T${e.target.value}`);
  };

  const handleBlur = () => {
    if (value) {
      const [auxDate, auxHour] = value.split('T');
      if (!auxDate || !auxHour) {
        onChange('');
      }
    }
    onBlur(value);
  };

  return (
    <div className='input_text'>
      <div className='pre_text'>
        {preText}
        {isRequired && <span className='is_required'>*</span>}
      </div>
      <div className='d-flex justify-content-start'>
        <input
          className='input'
          type='date'
          placeholder={placeholder}
          disabled={isDisabled}
          min={minDate}
          max={maxDate}
          value={value.split('T')[0]}
          onChange={e => changeDate(e)}
          onBlur={() => handleBlur(value)}
        />
        <input
          className='input ml-3 w-50'
          type='time'
          placeholder={placeholder}
          disabled={isDisabled}
          min={minHour}
          max={maxHour}
          value={value.split('T')[1] || ''}
          onChange={e => changeHour(e)}
          onBlur={() => handleBlur(value)}
        />
      </div>
      {postText && <div className='post_text' dangerouslySetInnerHTML={{ __html: postText }} />}
      <div className='errors'>
        {errors.map((err, index) => (
          <span key={index}>
            {typeof err === 'object' ? err.message : err}
            <br />
          </span>
        ))}
      </div>
    </div>
  );
}

InputDateTime.propTypes = {
  preText: PropTypes.string,
  postText: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  errors: PropTypes.array,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  maxHour: PropTypes.string,
  minHour: PropTypes.string,
};

export default InputDateTime;
