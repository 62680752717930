import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import InputNumber from 'components/Inputs/inputNumber';
import InputSelect from 'components/Inputs/inputSelect';
import InputText from 'components/Inputs/inputText';
import InputTextArea from 'components/Inputs/inputTextArea';
import OutputErrors from 'components/Inputs/outputErrors';
import { useLang } from 'utils/customHooks';
import {
  KPI_AGGREGATION, KPI_CATEGORY, KPI_PERIODICITY, KPI_TREND, KPI_UNIT,
} from 'constants/kpis';
import { createKpi, editKpi } from '../modules/actions';


const FormKpi = (props) => {
  const {
    onSubmit,
    literals,
    literalsCommon,
    id,
    name: defaultName,
    description: defaultDescription,
    category: defaultCategory,
    unit: defaultUnit,
    periodicity: defaultPeriodicity,
    min: defaultMin,
    max: defaultMax,
    positive: defaultPositive,
    aggregate: defaultAggregate,
  } = props;

  const lang = useLang();
  const [name, setName] = useState(defaultName);
  const [description, setDescription] = useState(defaultDescription);
  const [category, setCategory] = useState(defaultCategory);
  const [unit, setUnit] = useState(defaultUnit);
  const [periodicity, setPeriodicity] = useState(defaultPeriodicity);
  const [min, setMin] = useState(defaultMin);
  const [max, setMax] = useState(defaultMax);
  const [positive, setPositive] = useState(defaultPositive);
  const [aggregate, setAggregate] = useState(defaultAggregate);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const categories = [
    { id: KPI_CATEGORY.BUSINESS, name: literals.business },
    { id: KPI_CATEGORY.FINANCIAL, name: literals.financial },
    { id: KPI_CATEGORY.ESG, name: literals.esg },
  ];
  const units = [
    { id: KPI_UNIT.INTEGER, name: literals.integer },
    { id: KPI_UNIT.FLOAT, name: literals.float },
    { id: KPI_UNIT.CURRENCY, name: literalsCommon.currency },
    { id: KPI_UNIT.PERCENT, name: literals.percent },
  ];
  const trends = [
    { id: KPI_TREND.UP, name: literals.up },
    { id: KPI_TREND.DOWN, name: literals.down },
  ];
  const periodicities = [
    { id: KPI_PERIODICITY.MONTHLY, name: literals.monthly },
    { id: KPI_PERIODICITY.QUARTERLY, name: literals.quarterly },
    { id: KPI_PERIODICITY.ANNUAL, name: literals.annual },
  ];
  const aggregates = [
    { id: KPI_AGGREGATION.EOP, name: literals.endOfPeriod },
    { id: KPI_AGGREGATION.ACC, name: literals.accumulative },
    { id: KPI_AGGREGATION.AVG, name: literals.average },
  ];

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setLoading(true);
    setErrors(false);
    try {
      const data = {
        name,
        description,
        category,
        unit,
        periodicity,
        positive,
        scope: 'startup',
        owner: 'all',
        aggregate,
      };
      if (min !== null && min !== '') {
        data.min = parseFloat(min);
      }
      if (max !== null && max !== '') {
        data.max = parseFloat(max);
      }

      if (id) {
        await editKpi(id, data);
      } else {
        await createKpi(data);
      }
      onSubmit();
    } catch (e) {
      setErrors(e);
    }
    setLoading(false);
  };

  const setLangValue = (prop, value) => {
    switch (prop) {
      case 'name':
        setName(lang.setObjValue(name, value));
        break;
      case 'description':
        setDescription(lang.setObjValue(description, value));
        break;
      default:
        break;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-6 offset-6 col-md-4 offset-md-8'>
          <InputSelect value={lang.value} options={lang.getLangs().map(l => ({ id: l, name: l }))} onChange={lang.change} />
        </div>
        <div className='col-12 col-md-8'>
          <InputText preText={lang.getTitleInput(literalsCommon.name)} value={lang.getObjValue(name)} onChange={v => setLangValue('name', v)} error={errors?.name} />
        </div>
        <div className='col-12 col-md-4'>
          <InputSelect preText={literals.category} value={category} onChange={v => setCategory(v)} options={categories} error={errors?.category} />
        </div>
        <div className='col-12 col-md-12'>
          <InputTextArea preText={lang.getTitleInput(literalsCommon.description)} value={lang.getObjValue(description)} onChange={v => setLangValue('description', v)} error={errors?.description} />
        </div>
        <div className='col-12 col-md-4'>
          <InputSelect preText={literals.periodicity} value={periodicity} onChange={v => setPeriodicity(v)} options={periodicities} error={errors?.periodicity} />
        </div>
        <div className='col-12 col-md-4'>
          <InputSelect preText={literals.positive} value={positive} onChange={v => setPositive(v)} options={trends} error={errors?.positive} />
        </div>
        <div className='col-12 col-md-4'>
          <InputSelect preText={literals.aggregate} value={aggregate} onChange={v => setAggregate(v)} options={aggregates} error={errors?.aggregate} />
        </div>
        <div className='col-12 col-md-4'>
          <InputSelect preText={literals.unit} value={unit} onChange={v => setUnit(v)} options={units} error={errors?.unit} />
        </div>
        <div className='col-12 col-md-4'>
          <InputNumber preText={literals.min} value={min} onChange={v => setMin(v)} placeholder={literals.infiniteNegative} error={errors?.min} />
        </div>
        <div className='col-12 col-md-4'>
          <InputNumber preText={literals.max} value={max} onChange={v => setMax(v)} placeholder={literals.infinitePositive} error={errors?.max} />
        </div>
      </div>
      <OutputErrors literals={literals} errors={errors} />
      <div className='buttons'>
        <Button type='submit' text={literalsCommon.send} loading={loading} />
      </div>
    </form>
  );
};

FormKpi.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  id: PropTypes.string,
  name: PropTypes.object,
  description: PropTypes.object,
  category: PropTypes.string,
  unit: PropTypes.string,
  periodicity: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  positive: PropTypes.string,
  aggregate: PropTypes.string,
};

FormKpi.defaultProps = {
  id: null,
  name: {},
  description: {},
  category: '',
  unit: '',
  periodicity: '',
  min: null,
  max: null,
  positive: '',
  aggregate: null,
};

export default FormKpi;
