import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createRootReducer from './globalState';

const middlewares = [
  thunk,
];

const enhancer = compose(
  applyMiddleware(...middlewares),
);

export default function configureStore(initialState) {
  const store = createStore(
    createRootReducer,
    initialState,
    enhancer,
  );

  return store;
}
