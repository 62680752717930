import useDialog from 'components/Dialog/components/useDialog';
import { ROLE_ADMIN, ROLE_MENTOR, ROLE_USER } from 'constants/roles';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ROUTE_STARTUP_PATH, routes,
  routesAdmin, routesFund,
  routesMentor, routesPosition, routesStartup,
} from 'routes';

export const useSessionRoutes = (sessionChecked, isAuthenticated, user, platform, initPlatform) => {
  const literalsErrors = useSelector(state => state.i18n.literals.outputErrors);
  const [sessionRoutes, setSessionRoutes] = useState([]);
  const { dialog } = useDialog();

  useEffect(() => {
    if (sessionChecked && !isAuthenticated && !platform.loaded && !platform.loading) {
      initPlatform().catch(async () => {
        await dialog({
          type: 'error',
          text: literalsErrors.internal,
        });
      });
    }

    if (sessionChecked && (!isAuthenticated || (isAuthenticated && user.id))) {
      let auxRoutes = [];

      if (isAuthenticated) {
        if (user.role.includes(ROLE_USER)) {
          auxRoutes = auxRoutes.concat(routesStartup);
          auxRoutes = auxRoutes.concat(routesFund);
        }
        if (user.role.includes(ROLE_MENTOR)) {
          auxRoutes = auxRoutes.concat(routesMentor);
        }
        if (user.role.includes(ROLE_ADMIN)) {
          auxRoutes = auxRoutes.concat(routesAdmin);
        }
      }
      auxRoutes = auxRoutes.concat(routesPosition);

      const embededRoutes = auxRoutes
        .filter(route => route.embed)
        .map(route => ({
          ...route,
          path: ROUTE_STARTUP_PATH.setEmbed(route.path),
          header: false,
          aside: false,
        }));

      auxRoutes = auxRoutes.concat(embededRoutes, routes);
      setSessionRoutes(auxRoutes);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, sessionChecked, isAuthenticated]);

  return { sessionRoutes };
};
