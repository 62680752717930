import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const ReportsInstance = ApiFetch();

export const URLS = {
  REPORT_TEMPLATES: 'reports/templates',
  REPORT_TEMPLATE: 'reports/templates/:template',
};

async function deleteReportTemplate(template) {
  const path = preparePath(URLS.REPORT_TEMPLATE, { template });
  return ReportsInstance.delete(path);
}

async function getReportTemplate(template) {
  const path = preparePath(URLS.REPORT_TEMPLATE, { template });
  return ReportsInstance.get(path);
}

async function getReportTemplates(params = {}) {
  const path = preparePath(URLS.REPORT_TEMPLATES, {}, params);
  return ReportsInstance.get(path);
}

async function postReportTemplate(data) {
  return ReportsInstance.post(URLS.REPORT_TEMPLATES, data);
}

async function putReportTemplate(template, data) {
  const path = preparePath(URLS.REPORT_TEMPLATE, { template });
  return ReportsInstance.put(path, data);
}

export default {
  deleteReportTemplate,
  getReportTemplate,
  getReportTemplates,
  postReportTemplate,
  putReportTemplate,
};
