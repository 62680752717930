import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import EmptyChat from 'assets/svg/empty_chat.svg';
import { RotateCw, ChevronDown, ChevronUp } from 'lucide-react';
import { Skeleton } from '@mui/material';
import { addLinks } from 'utils/functions';
import { useStateRef } from 'utils/customHooks';
import { fetchMessages, postMessage, readMessages } from '../modules/actions';
import Message from './Message';
import MessagesForm from './MessagesForm';
import './styles.scss';

const Messages = (props) => {
  const {
    user,
    literals,
    type,
    id,
    mode,
    canReply,
    showReplies,
    readOnly,
    setNumUnreadMessages,
    visible,
  } = props;

  const [messages, setMessages] = useState(null);
  const [pager, setPager] = useState({ number: 0, pos: true });
  const [isFormFocus, setIsFormFocus] = useState({ focus: false, scroll: null });
  const [actualReplyForm, setActualReplyForm] = useState('');
  const [offset, setOffset] = useState([]);
  const [messagesReaded, setMessagesReaded] = useState([]);
  const [newReads, setNewReads] = useState([]);
  const [loading, setLoading] = useState(false);
  const commentsList = useRef(null);
  const [unreadRef, setUnreadRef] = useStateRef(null);
  const [lastMessageRead, setLastMessageRead] = useState(null);
  const [pagerRef, setPagerRef] = useStateRef(null);

  const PAGE_SIZE = mode === 'chat' ? 50 : 10;
  let timer = null;

  useEffect(() => {
    fetchMessages(type, id, 0, PAGE_SIZE)
      .then((response) => {
        const readed = response.items.filter(message => message.isRead).map(message => message.id);
        let numUnread = 0;
        response.items.forEach((message) => {
          if (!message.isRead) {
            numUnread += 1;
          }
          if (message.answers) {
            numUnread += message.answers.filter(answer => !answer.isRead).length;
          }
        });
        setMessagesReaded(readed);
        const newMessages = mode === 'chat' ? { items: response.items.reverse(), total: response.total } : response;
        if (mode === 'chat') {
          const newLastMessageRead = newMessages.items.slice().reverse().find(message => message.isRead);
          setLastMessageRead(newLastMessageRead ? newLastMessageRead.id : null);
        }

        setMessages(newMessages);
        setNumUnreadMessages(numUnread);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, id]);

  useEffect(() => {
    // primera carga posicionarlo en el último mensaje sin leer o al final
    const list = commentsList.current;
    if (mode === 'chat' && list && !loading && unreadRef && pager.number === 0) {
      list.scrollTo({ duration: 0, top: unreadRef.offsetTop });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadRef, loading, pager]);

  useEffect(() => {
    // En el chat al hacer la paginacion mantener el scroll en la misma posicion
    const list = commentsList.current;
    if (list && mode === 'chat' && pagerRef && pager.number !== 0 && !pager.pos) {
      list.scrollTo({ duration: 0, top: pagerRef.offsetTop - 35 });
      setPager(prev => ({ ...prev, pos: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagerRef, pager.number, pager.pos]);

  useEffect(() => {
    // Si estaba al final de la lista mantenerlo al abrir el bocadillo de comentar
    const list = commentsList.current;
    if (list && isFormFocus.focus && isFormFocus.scroll !== null) {
      setTimeout(() => {
        list.scrollTo({ duration: 0, top: isFormFocus.scroll });
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormFocus]);

  useEffect(() => {
    if (mode === 'post' && visible) {
      // eslint-disable-next-line no-use-before-define
      handleScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const insertMessage = (newMessage, messagesList) => {
    if (newMessage.parentId === '') {
      if (mode === 'chat') {
        return [...messagesList, newMessage];
      }
      return [newMessage, ...messagesList];
    }
    return messagesList.map((item) => {
      if (item.id === newMessage.parentId) {
        return { ...item, answers: [...item.answers, newMessage] };
      }
      if (item.answers) {
        return { ...item, answers: insertMessage(newMessage, item.answers) };
      }
      return item;
    });
  };

  const handleScroll = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      const list = commentsList.current;
      if (list) {
        const unreadMessages = offset.filter(elem => !messagesReaded.find(message => message === elem.id) && !newReads.includes(elem.id));
        if (unreadMessages.length) {
          const newRead = [];

          const actScroll = list.clientHeight + list.scrollTop;

          if (mode === 'chat') {
            unreadMessages.forEach((element) => {
              if (element.offsetTop <= actScroll) {
                newRead.push(element.id);
              }
            });
          } else {
            unreadMessages.forEach((element) => {
              if (actScroll >= element.offsetTop) {
                newRead.push(element.id);
              }
            });
          }
          if (newRead.length > 0) {
            setNewReads(prev => [...prev, ...newRead]);
            setNumUnreadMessages(prevUnreaded => prevUnreaded - newRead.length);
            readMessages(type, id, newRead);
          }
        }
      }
    }, 500);
  };

  const loadMoreMessages = async () => {
    setLoading(true);
    setOffset([]);
    fetchMessages(type, id, pager.number + 1, PAGE_SIZE).then((response) => {
      const newItems = mode === 'post'
        ? [...messages.items, ...response.items]
        : [...response.items.reverse(), ...messages.items];

      const newMessages = { items: newItems, total: response.total };
      const readed = newMessages.items.filter(message => message.isRead).map(message => message.id);

      setMessages(newMessages);
      setMessagesReaded(readed);
      setLoading(false);
      setPager(prev => ({ number: prev.number + 1, pos: mode === 'post' }));
    });
  };


  const submitMessage = async (message, parent, attachments) => {
    const parentId = parent || '';
    const list = commentsList.current;
    const finalMessage = addLinks(message);
    return postMessage(type, id, finalMessage, parentId, attachments)
      .then((newMessage) => {
        setMessages((prevMessages) => {
          return {
            items: insertMessage({ ...newMessage, isRead: true }, prevMessages.items),
            total: parentId === '' ? prevMessages.total + 1 : prevMessages.total,
          };
        });
        if (!parentId) {
          setMessagesReaded(prevMessages => [newMessage.id, ...prevMessages]);
        }
        if (mode === 'post') {
          list.scrollTo({ seconds: 0, top: -list.scrollHeight });
        } else {
          list.scrollTo({ seconds: 0, top: list.scrollHeight });
          setLastMessageRead(newMessage.id);
        }
        return true;
      });
  };

  const renderMessages = () => {
    if (messages.items.length > 0) {
      const numMessages = messages.items?.length;
      const allReaded = pager.number > 0 ? true : lastMessageRead === messages.items[mode === 'post' ? 0 : (numMessages - 1)].id;

      const renderUnreadMark = () => {
        return (
          <div className='unread-mark' ref={setUnreadRef}>
            <div className='unread-text'>
              <span className='px-3'>{mode === 'chat' ? <ChevronDown /> : <ChevronUp />}</span>
              {literals.unreads}
              <span className='px-3'>{mode === 'chat' ? <ChevronDown /> : <ChevronUp />}</span>
            </div>
            <hr className='unread-line' />
          </div>
        );
      };

      return (
        <>
          {
            messages.items.map((message, index) => (
              <div key={message.id}>
                {
                  (mode === 'chat' && index % PAGE_SIZE === 0 && ((numMessages - index) / PAGE_SIZE) === pager.number) ? (
                    <div key='page-ref' className='pager-ref' ref={setPagerRef} />
                  ) : null
                }
                {
                  mode === 'chat' && !allReaded && !lastMessageRead && index === 0 ? renderUnreadMark() : null
                }
                <Message
                  key={message.id || `message-${index}`}
                  {...message}
                  literals={literals}
                  canReply={canReply}
                  type={type}
                  messagesId={id}
                  mode={mode}
                  canViewReplies={showReplies}
                  onSubmit={submitMessage}
                  actualReplyForm={actualReplyForm}
                  setActualReplyForm={setActualReplyForm}
                  setOffset={setOffset}
                  userSession={user}
                  setNumUnreadMessages={setNumUnreadMessages}
                />
                {
                  mode === 'chat' && !allReaded && lastMessageRead === message.id ? renderUnreadMark() : null
                }
              </div>
            ))
          }
          {
            mode === 'chat' && allReaded && pager.number === 0 ? (
              <div className='unread-ref' ref={setUnreadRef} />
            ) : null
          }
        </>
      );
    }

    return (
      <li className='panel-empty-message'>
        <img src={EmptyChat} alt='' />
        <p>{literals.emptyChat}</p>
      </li>
    );
  };

  const renderLoading = (size) => {
    return Array.from(new Array(size)).map((e, i) => (
      <div key={i} className='message message-loader'>
        <div>
          <Skeleton
            className='loader-avatar'
            animation='wave'
            variant='circular'
            height={35}
            width={35}
            style={{ marginBottom: 2 }}
          />
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='125px' />
        </div>
        <div className='flex-grow-1'>
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='100%' />
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='100%' />
        </div>
      </div>
    ));
  };

  const handleFocusForm = (focus) => {
    let scroll = null;
    const list = commentsList.current;
    if (focus) {
      const distance = 50;
      if (mode === 'post') {
        scroll = (list.scrollTop >= 0 && list.scrollTop <= distance) ? 0 : null;
      } else {
        const actScroll = list.offsetHeight + list.scrollTop;
        scroll = actScroll >= (list.scrollHeight - distance) ? list.scrollHeight + 100 : null;
      }
    }
    setIsFormFocus({ focus, scroll });
  };

  const renderPager = () => {
    if (!messages || messages.items.length === messages.total) {
      return null;
    }
    return (
      <div className='pages-control'>
        <span className='pages-control-pointer' onClick={loadMoreMessages}>
          <RotateCw size={16} />
          <span className='ml-1'>{!loading ? literals.loadMore : literals.loading}</span>
        </span>
      </div>
    );
  };

  return (
    <div className={`messages ${isFormFocus.focus ? 'writing' : ''}`}>
      {
        !readOnly && mode === 'post' && (
          <MessagesForm
            user={user}
            literals={literals}
            onSubmit={submitMessage}
            render={renderMessages}
            isFocus={isFormFocus.focus}
            setIsFocus={handleFocusForm}
            disabled={actualReplyForm !== ''}
          />
        )
      }
      <div
        onScroll={handleScroll}
        ref={commentsList}
        className={
          `messages-list 
          ${mode === 'post' ? 'messages-list-post' : 'messages-list-chat'} 
          simple-scrollbar`}
      >
        {mode === 'chat' && renderPager()}
        <div>
          { messages ? (renderMessages()) : (renderLoading(5)) }
        </div>
        {mode === 'post' && renderPager()}
      </div>
      {
        !readOnly && mode === 'chat' && (
          <MessagesForm
            user={user}
            literals={literals}
            onSubmit={submitMessage}
            render={renderMessages}
            isFocus={isFormFocus.focus}
            setIsFocus={handleFocusForm}
          />
        )
      }
    </div>
  );
};


Messages.propTypes = {
  user: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  canReply: PropTypes.bool,
  showReplies: PropTypes.bool,
  readOnly: PropTypes.bool,
  setNumUnreadMessages: PropTypes.func,
  visible: PropTypes.bool,
};

Messages.defaultProps = {
  canReply: false,
  showReplies: false,
  readOnly: false,
  setNumUnreadMessages: () => {},
  visible: true,
};

export default Messages;
