import React from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Table from 'components/Table';
import useDialog from 'components/Dialog/components/useDialog';
import BlankTemplate from 'assets/png/blank-template.png';
import { ROUTE_STARTUP_PATH } from 'routes';
import { useCounter } from 'utils/customHooks';
import { PlusCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Panel from 'components/Panel';
import TemplateRow from './TemplateRow';
import { deleteReportTemplate, getReportTemplates } from '../modules/actions';
import './styles.scss';

const ReportingTemplates = (props) => {
  const {
    literals, literalsCommon, match, lang,
  } = props;

  const tableRefreshFlag = useCounter(0);
  const { dialog } = useDialog();


  const handleDeleteTemplate = async (id) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literals.questionDeleteTemplate,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteReportTemplate(id);
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const fetchReportTemplates = async (page, size, search, sort) => {
    return getReportTemplates('startup', match.params.id, page, size, search, sort);
  };

  return (
    <Tool className='reporting-templates-tool'>
      <Panel>
        <PanelHeader breadcrumb={[
          { route: ROUTE_STARTUP_PATH.setReporting(match.params.id), name: literals.title },
          { route: ROUTE_STARTUP_PATH.setReportingTemplates(match.params.id), name: literals.templates },
        ]}
        />
        <PanelBody>
          <div className='templates-container'>
            <div key='blank-template' className='template blank'>
              <Link to={ROUTE_STARTUP_PATH.setReportingAdd(match.params.id, 'new')}>
                <div className='template-header'>
                  <span className='template-name h6' title={literals.blankTemplate}>{literals.blankTemplate}</span>
                </div>
                <div className='image-container'>
                  <img src={BlankTemplate} alt='' />
                </div>
              </Link>
            </div>
            <div className='separator'>
              <span>{literals.or}</span>
            </div>
            <Table
              mode='components'
              component={TemplateRow}
              componentProps={{
                literals, literalsCommon, match, lang, onDelete: handleDeleteTemplate,
              }}
              componentColumns={{
                xs: 1, sm: 2, md: 3, lg: 4, xl: 5,
              }}
              pageSize={10}
              fetch={fetchReportTemplates}
              forceFetch={tableRefreshFlag.value}
              actions={[
                {
                  type: 'Link',
                  icon: PlusCircle,
                  text: literals.newTemplate,
                  to: ROUTE_STARTUP_PATH.setReportingTemplatesAdd(match.params.id, 'new'),
                },
              ]}
            />
          </div>
        </PanelBody>
      </Panel>
    </Tool>
  );
};

ReportingTemplates.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};

export default ReportingTemplates;
