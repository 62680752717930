import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import ButtonsDotsMenu from 'components/Buttons/ButtonsDotsMenu';
import { formatDate, formatTime, getFullName } from 'utils/functions';
import {
  Calendar,
  CalendarCheck,
  Pencil,
  Clock,
  Trash,
} from 'lucide-react';

const Meeting = ({
  literalsCommon,
  meeting,
  onClick = null,
  onEdit = null,
  onDelete = null,
}) => {
  const parseDate = new Date(meeting.date);
  const [date, time] = formatDate(parseDate, { time: true }).split(' ');

  const futureEvent = parseDate.getTime() > new Date().getTime();

  const handleOnClick = () => {
    if (onClick) {
      onClick(meeting);
    }
  };

  return (
    <li key={meeting.id} className='meeting' onClick={handleOnClick}>
      <div className={`meeting-date ${futureEvent ? 'primary' : 'success'}-bg`}>
        <div>
          {futureEvent ? <Calendar size={40} /> : <CalendarCheck size={40} /> }
        </div>
      </div>
      <div className='meeting-info'>
        {onEdit && (
          <div className='meeting-actions'>
            <ButtonsDotsMenu
              offset={{ top: 0, left: 24 }}
              buttons={[
                { icon: Pencil, text: literalsCommon.edit, onClick: onEdit },
                { icon: Trash, text: literalsCommon.delete, onClick: onDelete },
              ]}
            />
          </div>
        )}
        <div className='meeting-time' title={literalsCommon.duration}>
          <div className='fw-b'>
            {`${date} - ${time}`}
          </div>
          <div className='fw-b'>
            <Clock size={13} />
            {formatTime(meeting.time)}
          </div>
        </div>
        <p className='meeting-text'>
          {meeting.title}
        </p>
        {meeting.createdBy && (
          <div className='meeting-user'>
            <div>
              <Avatar
                title={getFullName(meeting.createdBy)}
                src={meeting.createdBy?.avatar?.path}
                size={25}
              />
            </div>
            <div>
              <h6>{getFullName(meeting.createdBy)}</h6>
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

Meeting.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  meeting: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default Meeting;
