import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CapTable from '../components';
import { fetchCaptableAction } from '../modules/actions';
import { submitOperationAction } from '../components/routes/Operation/modules/actions';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.captable,
    literalsCommon: state.i18n.literals.common,
    lang: state.i18n.language,
    captable: state.captable,
    currency: state.global.currency,
    platform: state.global.platform,
    startup: state.global.startup,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCaptable: bindActionCreators(fetchCaptableAction, dispatch),
    submitOperation: bindActionCreators(submitOperationAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CapTable);
