import React from 'react';
import PropTypes from 'prop-types';
import { CircleCheckBig } from 'lucide-react';
import { connect } from 'react-redux';

const ShareholderPermissionsInfo = ({ literals }) => {
  return (
    <div className='permissions-info'>
      <span>{literals.shareholderHaveShares}</span>
      <ul className='pl-4'>
        <li>
          <CircleCheckBig className='mx-2' />
          <span>{`${literals.summary}: ${literals.summaryDescription}`}</span>
        </li>
        <li>
          <CircleCheckBig className='mx-2' />
          <span>{`${literals.myPosition}: ${literals.myPositionDescription}`}</span>
        </li>
        <li>
          <CircleCheckBig className='mx-2' />
          <span>{`${literals.capTable}: ${literals.capTableDescription}`}</span>
        </li>
        <ul>
          <li>
            <CircleCheckBig className='mx-2' />
            <span>{`${literals.capTableSummary}: ${literals.capTableSummaryDescription}`}</span>
          </li>
          <li>
            <CircleCheckBig className='mx-2' />
            <span>{`${literals.capTableSecurities}: ${literals.capTableSecuritiesDescription}`}</span>
          </li>
          <li>
            <CircleCheckBig className='mx-2' />
            <span>{`${literals.capTableOperations}: ${literals.capTableOperationsDescription}`}</span>
          </li>
        </ul>
        <li>
          <CircleCheckBig className='mx-2' />
          <span>{`${literals.performance}: ${literals.performanceDescription}`}</span>
        </li>
        <li>
          <CircleCheckBig className='mx-2' />
          <span>{`${literals.reporting}: ${literals.reportingDescription}`}</span>
        </li>
        <li>
          <CircleCheckBig className='mx-2' />
          <span>{`${literals.dataRoom}: ${literals.dataRoomDescription}`}</span>
        </li>
      </ul>
    </div>
  );
};

ShareholderPermissionsInfo.propTypes = {
  literals: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.shareholder,
  };
}

export default connect(mapStateToProps)(ShareholderPermissionsInfo);
