import { connect } from 'react-redux';
import AdminReportingNewTemplateComponents from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.reporting,
  literalsPerformance: state.i18n.literals.performance,
  literalsCommon: state.i18n.literals.common,
  currency: state.global.currency,
});

export default connect(mapStateToProps)(AdminReportingNewTemplateComponents);
