import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import FooterComponents from '../components';

const mapStateToProps = state => ({
  user: state.session.user,
  platform: state.global.platform,
  literals: state.i18n.literals.footer,
});

export default withRouter(connect(mapStateToProps)(FooterComponents));
