import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputRichText from 'components/Inputs/inputRichText';
import Button from 'components/Buttons/Button';
import { useCounter } from 'utils/customHooks';
import { stripTags } from 'utils/functions';
import { connect } from 'react-redux';
import { deleteNote, getNote, postNote } from './actions';

const Note = (props) => {
  const { type, id, literalsCommon } = props;

  const forceUpdate = useCounter(0);
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState({
    loaded: false,
    existPrev: false,
    note: '',
  });

  useEffect(() => {
    (async () => {
      const prevNote = await getNote(type, id);
      setNote({
        loaded: true,
        existPrev: !!prevNote?.id,
        note: prevNote?.note || '',
      });
      forceUpdate.increase();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePostNote = async () => {
    setLoading(true);
    await postNote(type, id, note.note);
    setNote(prev => ({ ...prev, existPrev: true }));
    setLoading(false);
    forceUpdate.increase();
  };

  const handleDeleteNote = async () => {
    setLoading(true);
    await deleteNote(type, id);
    setNote(prev => ({ ...prev, note: '', existPrev: false }));
    setLoading(false);
    forceUpdate.increase();
  };

  return (
    <div className='notes-container'>
      <InputRichText
        key={`note.${type}.${id}.${forceUpdate.value}`}
        preText={literalsCommon.notes}
        value={note.note}
        onChange={v => setNote(prev => ({ ...prev, note: v }))}
        isDisabled={!note.loaded}
      />
      <div className='buttons'>
        { note.existPrev && (
          <Button
            text={literalsCommon.delete}
            color='danger'
            onClick={handleDeleteNote}
            loading={loading}
          />
        )}
        <Button
          text={note.existPrev ? literalsCommon.edit : literalsCommon.add}
          onClick={handlePostNote}
          loading={loading}
          disabled={!stripTags(note.note).trim()}
        />
      </div>
    </div>
  );
};

Note.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    literalsCommon: state.i18n.literals.common,
  };
};

export default connect(mapStateToProps, {})(Note);
