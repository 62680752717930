import { connect } from 'react-redux';
import ReportingTemplatesAddComponent from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.reporting,
  literalsPerformance: state.i18n.literals.performance,
  literalsCommon: state.i18n.literals.common,
  currency: state.global.currency,
  langs: state.global.platform.langs,
  lang: state.session.user.lang,
  user: state.session.user,
  startup: state.global.startup,
});

export default connect(mapStateToProps)(ReportingTemplatesAddComponent);
