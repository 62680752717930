import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel/components/';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelTab from 'components/Panel/components/PanelTab';
import Loading from 'components/Loading';
import StartupCaptableDate from 'components/StartupCaptableDate/components';
import { useLocation, useSearchParams } from 'react-router-dom';
import { initializeCaptable } from 'routes/Captable/modules/actions';
import { AmplitudeApi } from 'utils/amplitude';
import WaterfallTab from './WaterfallTab';
import SimulationsTab from './SimulationsTab';
import OperationsTab from './OperationsTab';
import './styles.scss';

const CaptableOperations = (props) => {
  const {
    literalsCommon,
    literals,
    match,
    captable,
    fetchCaptable,
    startup,
  } = props;

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;

  useEffect(() => {
    initializeCaptable(fetchCaptable, captable, match.params.id, captableId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    if (captable?.selected?.id) {
      AmplitudeApi.logEvent('startup.transactions.main.view', {
        captable: captable?.selected?.id,
      });
    }
  }, [captable?.selected?.id]);

  const operationsTab = [
    { key: 'operations', name: literals.operations },
    { key: 'simulations', name: literals.simulations },
    { key: 'waterfalls', name: literals.waterfall },
  ];

  if (!captable.loaded) {
    return <Loading hide={false} mode='tool' />;
  }

  return (
    <Panel active='operations'>
      <PanelHeader title={literals.transactions} tabs={operationsTab} hash />
      <PanelBody>
        <StartupCaptableDate startup={startup} captable={captable} noDate />
        <PanelTab key='operations'>
          <OperationsTab {...props} />
        </PanelTab>
        <PanelTab key='waterfalls'>
          <WaterfallTab
            literals={literals}
            literalsCommon={literalsCommon}
            captable={captable}
            match={match}
            fetchCaptable={fetchCaptable}
            startup={startup}
          />
        </PanelTab>
        <PanelTab key='simulations'>
          <SimulationsTab
            literals={literals}
            literalsCommon={literalsCommon}
            captable={captable}
            startup={startup}
            fetchCaptable={fetchCaptable}
          />
        </PanelTab>
      </PanelBody>
    </Panel>
  );
};

CaptableOperations.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  fetchCaptable: PropTypes.func.isRequired,
  editOperationTimeline: PropTypes.func.isRequired,
  editOperationDraft: PropTypes.func.isRequired,
  deleteOperation: PropTypes.func.isRequired,
};

export default CaptableOperations;
