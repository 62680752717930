import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import InputText from 'components/Inputs/inputText';
import InputSelect from 'components/Inputs/inputSelect';
import Button from 'components/Buttons/Button';
import useDialog from 'components/Dialog/components/useDialog';
import OutputErrors from 'components/Inputs/outputErrors';
import { useNavigate } from 'react-router-dom';
import { formatErrors } from 'utils/functions';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import { deleteCaptableSimulation, postCaptableSimulation, putCaptableSimulation } from '../modules/actions';
import './styles.scss';

const SimulationPopup = (props) => {
  const {
    literals,
    literalsCommon,
    simulation,
    captable,
    startup,
    onSubmit,
    onClose,
  } = props;

  const [simulationInfo, setSimulationInfo] = useState(simulation);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const navigate = useNavigate();
  const { dialog } = useDialog();

  const handleCreateSimulation = async () => {
    setLoading(true);
    try {
      const { name, lastConfirmed } = simulationInfo;
      if (!simulation?.id) {
        const newSimulation = await postCaptableSimulation(startup, {
          name, lastConfirmed, captable: captable?.selected?.id || null,
        });
        navigate(embedView(ROUTE_STARTUP_PATH.setCapTableOperation(startup, captable?.selected?.id, 'new', newSimulation.id)));
      } else {
        await putCaptableSimulation(startup, simulation.id, {
          name, lastConfirmed, captable: captable?.selected?.id || null,
        });
      }
      onSubmit();
      onClose();
    } catch (err) {
      setErrors(formatErrors(err));
      setLoading(false);
    }
  };

  const handleDeleteSimulation = async (auxSimulation) => {
    const confirm = await dialog({
      text: literals.deleteSimulation,
      type: 'confirmDanger',
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteCaptableSimulation(startup, auxSimulation);
          onSubmit();
        },
      });
    }
  };


  return (

    <Popup title={literals.createSimulation} onClose={onClose}>
      <div className='row'>
        <div className='col-12 col-md-8'>
          <InputText
            preText={literalsCommon.name}
            placeholder={literalsCommon.name}
            onChange={name => setSimulationInfo(prev => ({ ...prev, name }))}
            value={simulationInfo.name}
            isRequired
          />
        </div>
        <div className='col-12 col-md-4'>
          <InputSelect
            preText={literals.from}
            placeholder={literals.from}
            onChange={lastConfirmed => setSimulationInfo(prev => ({ ...prev, lastConfirmed }))}
            options={captable.operations}
            value={simulationInfo.lastConfirmed}
            isRequired
          />
        </div>
      </div>
      { errors && <OutputErrors errors={errors} />}
      <div className='buttons'>
        {
          simulation.name && (
            <Button
              color='danger'
              text={literalsCommon.delete}
              onClick={handleDeleteSimulation}
              loading={loading}
            />
          )
        }
        <Button
          text={literalsCommon.create}
          onClick={handleCreateSimulation}
          loading={loading}
        />
      </div>
    </Popup>
  );
};

SimulationPopup.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  simulation: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  startup: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SimulationPopup;
