import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel/components/';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Table from 'components/Table';
import useDialog from 'components/Dialog/components/useDialog';
import PermissionLabel from 'components/PermissionsPopup/PermissionLabel';
import { useCounter } from 'utils/customHooks';
import { formatDate, formatNumber } from 'utils/functions';
import { ROUTE_PATH, ROUTE_STARTUP_PATH } from 'routes';
import { useNavigate } from 'react-router-dom';
import { PERMISSION_LEVELS, PERMISSION_SCOPES } from 'constants/permissions';
import {
  Eye, Pencil, PlusCircle, Trash,
} from 'lucide-react';
import { deleteStartupFundraise, getStartupFundraises } from '../modules/actions';
import './styles.scss';

const StartupFundraise = (props) => {
  const {
    literals,
    literalsCommon,
    currency,
    match,
  } = props;

  const tableRefreshFlag = useCounter(0);
  const { dialog } = useDialog();
  const navigate = useNavigate();

  const fetchStartupFundraises = (page, size, search, filters, sort) => {
    return getStartupFundraises(match.params.id, {
      page, size, search, filters, sort,
    });
  };

  const handleDeleteStartupFundraise = async (fundraise) => {
    const confirm = await dialog({
      type: 'confirmDanger', text: literals.deleteFundraise,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteStartupFundraise(match.params.id, fundraise.id);
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const columns = [
    {
      field: 'amount',
      label: literals.amount,
      preRender: (v, row) => (
        <div className='d-flex align-items-center'>
          {formatNumber(v, 0, { symbol: currency.symbol })}
          <PermissionLabel permission={row.permission} />
        </div>
      ),
    },
    {
      field: 'postmoney',
      width: 250,
      label: literals.postMoney,
      preRender: v => formatNumber(v, 0, { symbol: currency.symbol }),
    },
    {
      field: 'ownership',
      label: literals.ownership,
      preRender: v => formatNumber(v, 0, { symbol: '%' }),
    },
    {
      field: 'investments',
      label: literals.investments,
      preRender: v => formatNumber(v, 0, { symbol: currency.symbol }),
    },
    {
      field: 'status',
      label: literals.status,
      preRender: v => literals[v.status],
    },
    {
      field: 'createdAt',
      label: literalsCommon.createdAt,
      preRender: date => formatDate(date, { time: true }),
    },
    {
      field: 'info',
      type: 'menu',
      buttons: [
        {
          text: literalsCommon.view,
          icon: Eye,
          onClick: fundraise => navigate(ROUTE_PATH.setFundraise(fundraise.id)),
        },
        {
          text: literalsCommon.edit,
          icon: Pencil,
          onClick: fundraise => navigate(ROUTE_STARTUP_PATH.setFundraiseAdd(match.params.id, fundraise.id)),
          hidden: (_, row) => row.permission.level < PERMISSION_LEVELS.EDIT,
        },
        {
          type: 'permission',
          scope: { type: PERMISSION_SCOPES.STARTUP, id: match.params.id },
          element: 'startup_fundraise',
        },
        {
          text: literalsCommon.delete,
          icon: Trash,
          onClick: fundraise => handleDeleteStartupFundraise(fundraise),
          hidden: (_, row) => row.permission.level < PERMISSION_LEVELS.EDIT,
        },
      ],
    },
  ];

  return (
    <Panel>
      <PanelHeader title={literals.title} />
      <PanelBody>
        <Table
          columns={columns}
          pageSize={10}
          fetch={fetchStartupFundraises}
          forceFetch={tableRefreshFlag.value}
          onClickRow={fundraise => navigate(ROUTE_PATH.setFundraise(fundraise.id))}
          actions={[
            { text: literals.newRound, icon: PlusCircle, onClick: () => navigate(ROUTE_STARTUP_PATH.setFundraiseAdd(match.params.id, 'new')) },
          ]}
        />
      </PanelBody>
    </Panel>
  );
};

StartupFundraise.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
};

export default StartupFundraise;
