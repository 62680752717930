import React from 'react';
import PropTypes from 'prop-types';
import { ToggleOff, ToggleOn } from 'react-bootstrap-icons';
import './styles.scss';

const InputToggle = ({
  className = '',
  preText = '',
  postText = '',
  isRequired = false,
  isDisabled = false,
  onChange,
  value = false,
  size = 32,
}) => {
  const changeValue = () => {
    if (!isDisabled && onChange) {
      onChange(!value);
    }
  };

  return (
    <div className={`input_toggle ${isDisabled ? 'disabled' : ''} d-flex align-items-center ${className}`}>
      <div className='pre_text m-0'>
        {preText}
        {isRequired && <span className='is_required'>*</span>}
      </div>
      <div>
        <div
          className={`toggle_option p-0 ${value ? 'active' : ''}`}
          onClick={changeValue}
        >
          { value ? <ToggleOn size={size} /> : <ToggleOff size={size} /> }
        </div>
      </div>
      { postText && (<div className='post_text'>{postText}</div>) }
    </div>
  );
};

InputToggle.propTypes = {
  className: PropTypes.string,
  preText: PropTypes.string,
  postText: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  size: PropTypes.number,
};

export default InputToggle;
