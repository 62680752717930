import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import SkeletonDoughnutChart from 'components/Loading/skeletonDoughnutChart';
import ReactECharts from 'echarts-for-react';
import { formatNumber, getColorRange } from 'utils/functions';
import './styles.scss';

const MyPositionOverview = (props) => {
  const {
    literals,
    position,
    currency,
    resource,
    setResource,
    lastPPS,
    loading,
  } = props;

  const chartRef = useRef(null);
  const colorRange = getColorRange(4);

  const optionsValue = position?.options?.number * lastPPS;
  const vestedValue = optionsValue * (position?.options?.vested / position?.options?.number);
  const data = [
    { name: 'shares', value: position?.shares?.number * lastPPS, number: position?.shares?.number },
    { name: 'debts', value: position?.debts?.pending },
    { name: 'vestedOptions', value: vestedValue, number: position?.options?.vested },
    { name: 'pendingOptions', value: optionsValue - vestedValue, number: position?.options?.number - position?.options?.vested },
  ];

  const chartOption = useMemo(() => ({
    tooltip: {
      trigger: 'item',
      confine: true, // Mantener el tooltip dentro del contenedor
      formatter: (params) => {
        return `
          <strong>${literals[params.name]}</strong><br/>
          ${params.data.number ? `
            ${literals.number}: ${formatNumber(params.data.number, '-')}<br/>
          ` : ''}
          ${literals.value}: ${formatNumber(params.value, '-', { symbol: currency.symbol })}
        `;
      },
    },
    grid: { left: '95%', top: '25%', right: '25%' },
    series: [
      {
        color: colorRange,
        name: literals.title,
        type: 'pie',
        radius: ['60%', '90%'],
        label: { show: false },
        itemStyle: {
          borderWidth: 5, // Define el tamaño del borde
          borderColor: 'white', // Define el color del borde
        },
        data,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [position, lastPPS]);

  const total = (position?.shares?.number + position?.options?.number) * lastPPS + position?.debts?.pending;
  const totalCapital = position?.shares?.capital + position?.debts?.pending + position?.options?.value;

  const handleHover = (action, category) => {
    const chart = chartRef.current?.getEchartsInstance();
    if (chart) {
      const legendElement = document.getElementById(`legend-${category}`);
      chart.dispatchAction({ type: action, name: category });
      if (action === 'highlight') {
        chart.dispatchAction({ type: 'showTip', seriesIndex: 0, name: category });
        legendElement?.classList?.add('hover');
      } else {
        chart.dispatchAction({ type: 'hideTip', seriesIndex: 0, name: category });
        legendElement?.classList?.remove('hover');
      }
    }
  };

  return (
    <>
      <h4 className='fw-b'>{literals.positionOverview}</h4>
      {/* <h5>{literals.positionOverviewSubtitle}</h5> */}
      <div className='row'>
        <div className='col-12 col-md-6 col-lg-4'>
          { !loading ? (
            <ReactECharts
              ref={chartRef}
              option={chartOption}
              onEvents={{
                mouseover: params => handleHover('highlight', params.name),
                mouseout: params => handleHover('downplay', params.name),
              }}
              style={{ height: 250 }}
            />
          ) : <SkeletonDoughnutChart />
          }
        </div>
        <div className='col-12 col-md-6 col-lg-3 position-overview--summary'>
          <div className='fc-secondary'>{literals.totalPotentialValue}</div>
          <div className='position-overview--summary-potential'>
            {formatNumber(total, '-', { symbol: currency.symbol })}
          </div>
          <div className='fw-b'>
            <span className='fw-b mr-2'>{`${literals.totalInvested}:`}</span>
            {formatNumber(totalCapital, '-', { symbol: currency.symbol })}
          </div>
          <div className='fw-b'>
            <span className='fw-b mr-2'>{`${literals.multiplo}:`}</span>
            {formatNumber(total / totalCapital, '-', { symbol: 'x' })}
          </div>
        </div>
        <ul className='col-12 col-xl-5 position-overview--legend'>
          {data.filter(item => loading || item.value > 0).map((item, i) => (
            <li
              key={item.name}
              id={`legend-${item.name}`}
              className={resource === item.name ? 'selected' : ''}
              onMouseEnter={() => handleHover('highlight', item.name)}
              onMouseLeave={() => handleHover('downplay', item.name)}
              onClick={() => setResource(item.name)}
            >
              <div className='d-flex align-items-center'>
                <div className='position-overview--legend-circle' style={{ backgroundColor: colorRange[i] }} />
                {literals[item.name]}
              </div>
              <div className='d-flex align-items-center'>
                <div className='d-flex flex-column align-items-end'>
                  <span className='fs-sm'>{formatNumber(item.value, '0', { symbol: currency.symbol })}</span>
                  {formatNumber(item.value * 100 / total, '0', { symbol: '%' })}
                </div>

              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

MyPositionOverview.propTypes = {
  literals: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  setResource: PropTypes.func.isRequired,
  lastPPS: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default MyPositionOverview;
