import StartupsCaptablesInstance from 'modules/startupsCaptables/repository';


export function getVestingPlans(id, page, size, others = {}) {
  return StartupsCaptablesInstance.getStartupVestingPlans(id, { page, size, ...others });
}

export function deleteVestingPlans(id, vestingPlan) {
  return StartupsCaptablesInstance.deleteStartupVestingPlan(id, vestingPlan);
}

export function postVestingPlans(id, data) {
  return StartupsCaptablesInstance.postStartupVestingPlan(id, data);
}

export function putVestingPlans(id, vestingPlan, data) {
  return StartupsCaptablesInstance.putStartupVestingPlan(id, vestingPlan, data);
}

export function getVestingPlan(id, vestingPlan) {
  return StartupsCaptablesInstance.getStartupVestingPlan(id, vestingPlan);
}

export function getVestingPlanMilestones(id, vestingPlan, opts) {
  return StartupsCaptablesInstance.getStartupVestingPlanMilestones(id, vestingPlan, opts);
}

export function getVestingPlanCalendar(id, vestingPlan, opts) {
  return StartupsCaptablesInstance.getStartupVestingPlanCalendar(id, vestingPlan, opts);
}

export function postVestingPlanMilestones(id, vestingPlan, data) {
  return StartupsCaptablesInstance.postStartupVestingPlanMilestones(id, vestingPlan, data);
}

export function fetchCaptableShares(startup, date, params) {
  return StartupsCaptablesInstance.getStartupCaptableShares(startup, date, params);
}

export function fetchCaptableDebts(startup, date, params) {
  return StartupsCaptablesInstance.getStartupCaptableDebts(startup, date, params);
}

export function fetchCaptableOptions(startup, date, params) {
  return StartupsCaptablesInstance.getStartupCaptableOptions(startup, date, params);
}
