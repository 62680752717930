
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import Button from 'components/Buttons/Button';
import { Link } from 'react-router-dom';
import { ROUTE_MENTOR_PATH, ROUTE_STARTUP_PATH } from 'routes';
import { Skeleton } from '@mui/material';
import {
  formatDate, formatTime, getFullName, getStartupVerifiedIcon,
} from 'utils/functions';
import {
  Building,
  CalendarCheck,
  CalendarMinus,
  CheckCircle,
  Clock,
  Hourglass,
  List,
  XCircle,
} from 'lucide-react';

const MentoringRow = (props) => {
  const {
    id,
    view,
    mentor,
    startup,
    status,
    feedback,
    meetings,
    lastInteraction,
    loading,
    onPopUpInfo,
    literals,
  } = props;

  if (loading) {
    return (
      <div key={id} className='box box-padding d-flex align-items-center box-mb'>
        <Skeleton
          className='loader-avatar'
          animation='wave'
          variant='circular'
          height={70}
          width={70}
        />
        <div className='flex-grow-1 p-1 pl-4'>
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='100%' />
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='100%' />
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='100%' />
        </div>
      </div>
    );
  }

  const handleClickFeedback = (e) => {
    if (typeof onPopUpInfo === 'function') {
      e.preventDefault();
      e.stopPropagation();
      onPopUpInfo(props);
    }
  };

  const path = view.type === 'startup' ? ROUTE_STARTUP_PATH.setMentoring(view.id, id) : ROUTE_MENTOR_PATH.setMentoring(id);

  const renderByStatus = () => {
    switch (status) {
      case 'finished':
        if (feedback.reason === 'finish') {
          if (!feedback[view.type]) {
            return (
              <div>
                <Button icon={List} text={literals.giveFeedback} color='outline-primary' onClick={handleClickFeedback} />
              </div>
            );
          }
          return (
            <div>
              <CheckCircle size={24} />
              {literals.feedbackSent}
            </div>
          );
        }
        return (
          <div>
            <XCircle size={24} />
            {literals.canceled}
          </div>
        );
      default:
        return (
          <>
            <div>
              { meetings.next ? (<CalendarCheck size={24} />) : (<CalendarMinus size={24} />)}
              <span>
                {`${literals.nextMeeting}:`}
                <b>{ meetings.next ? formatDate(meetings.next, { time: true }) : '-'}</b>
              </span>
            </div>
            <div>
              <Clock size={24} />
              <span>
                {`${literals.lastInteraction}:`}
                <b>{formatDate(lastInteraction, { time: true })}</b>
              </span>
            </div>
          </>
        );
    }
  };

  let image = '';
  let name = '';
  let info = '';
  const special = {};

  if (view.type === 'startup') {
    image = mentor.avatar?.path;
    name = getFullName(mentor.user);
    info = mentor.headline;
    special.icon = <Building size={24} />;
    special.text = mentor.company;
  } else {
    image = startup.logo?.path;
    name = startup.name;
    info = startup.description;
    special.icon = <Avatar title={name} src='' size={24} />;
    special.text = '';
    special.verified = startup.verified;
  }

  return (
    <Link className='mentoring-row box' data-key={id} to={path}>
      <div className='mentoring-info'>
        <div className='mentoring-avatar'>
          <Avatar title={name} src={image} size={70} />
        </div>
        <div className='mentoring-basic'>
          <h4>
            <Avatar title={name} src={image} size={45} className='mentoring-avatar-mobile' />
            {name}
            {getStartupVerifiedIcon(special.verified)}
          </h4>
          <p>{info}</p>
        </div>
        <div className='mentoring-special'>
          {
            special.text && (
              <>
                <span>{special.text}</span>
                {special.icon}
              </>
            )
          }
        </div>
      </div>
      <div className='mentoring-actions'>
        { renderByStatus() }
        <div>
          <Hourglass size={24} />
          <span>
            {`${literals.consumerHours}:`}
            <b>{formatTime(meetings.time.finished)}</b>
          </span>
        </div>
      </div>
    </Link>
  );
};

MentoringRow.propTypes = {
  id: PropTypes.string.isRequired,
  view: PropTypes.object,
  mentor: PropTypes.object,
  startup: PropTypes.object,
  meetings: PropTypes.object,
  feedback: PropTypes.object,
  status: PropTypes.string,
  lastInteraction: PropTypes.number,
  literals: PropTypes.object.isRequired,
  onPopUpInfo: PropTypes.func,
  loading: PropTypes.bool,
};

MentoringRow.defaultProps = {
  view: {},
  mentor: {},
  startup: {},
  meetings: {},
  feedback: {},
  status: '',
  lastInteraction: 0,
  loading: false,
  onPopUpInfo: null,
};

export default MentoringRow;
