/* eslint-disable no-console */
export const sendMessageToWebview = (type, payload) => {
  const auxMessage = JSON.stringify({ type, payload });
  try {
    if (window?.parent && window.parent !== window?.self) {
      console.debug('sendMessageToWebview: OK', auxMessage);
      window.parent.postMessage(auxMessage, '*');
    } else {
      console.debug('sendMessageToWebview: KO', auxMessage);
      return false;
    }
  } catch (e) {
    console.error(e, 'sendMessageToWebview error');
    return false;
  }

  return true;
};
