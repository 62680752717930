/* eslint-disable object-curly-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-spread */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Panel from 'components/Panel';
import PanelBody from 'components/Panel/components/PanelBody';
import Button from 'components/Buttons/Button';
import InputText from 'components/Inputs/inputText';
import InputDate from 'components/Inputs/inputDate';
import InputRichText from 'components/Inputs/inputRichText';
import InputFile from 'components/Inputs/inputFile';
import OutputErrors from 'components/Inputs/outputErrors';
import Loading from 'components/Loading';
import Breadcrumb from 'components/Breadcrumb';
import useDialog from 'components/Dialog/components/useDialog';
import NotFound from 'routes/NotFound';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import { initializeCaptable } from 'routes/Captable/modules/actions';
import { formatDate } from 'utils/functions';
import { AmplitudeApi } from 'utils/amplitude';
import { canEditCaptable } from 'utils/functions/canEditCaptable';
import { CheckCircle, Hourglass, Info, Trash } from 'lucide-react';
import { fetchOperation, fetchCaptableVariables, fetchStartupOperations } from '../modules/actions';
import { useUpdateValuation } from '../hooks/useUpdateValuation';
import { useSourceOperation } from '../hooks/useSourceOperation';
import OperationsPanel from './OperationsPanel';
import PopupUpdateValuation from './PopupUpdateValuation';
import './styles.scss';

const CaptableOperation = (props) => {
  const {
    startup,
    literals,
    literalsCommon,
    match,
    currency,
    fetchCaptable,
    captable,
    submitOperation,
    deleteOperation,
  } = props;

  const navigate = useNavigate();
  const { dialog } = useDialog();

  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const simulation = searchParams.get('simulation') || null;

  const [operationData, setOperationData] = useState({
    id: match.params.operation === 'new' ? null : match.params.operation,
    name: '',
    date: '',
    notes: '',
    documents: [],
    loaded: match.params.operation === 'new',
    draft: true,
    errors: false,
  });

  const [subOperations, setSubOperations] = useState({
    shares: [],
    debts: [],
    options: [],
    payouts: [],
    secondaries: [],
    changeClasses: [],
    reduces: [],
    conversions: [],
    vests: [],
    stockSplit: null,
    valuation: null,
    dividend: null,
  });

  const [captableVariables, setCapTableVariables] = useState({
    convertibles: { debts: [], options: [] },
    unassigned: { shares: [], debts: [] },
    assigned: { options: [] },
    lastNumeration: 0,
    shares: 0,
    options: 0,
  });

  const [operations, setOperations] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [popupValuation, setPopupValuation] = useState(null);

  const updateValuation = useUpdateValuation(subOperations);
  const sourceOperation = useSourceOperation(operationData.id, operationData.date, operations);
  const lastConfirmed = captable.operations?.[0];
  const canEdit = canEditCaptable(captable) || (!captable?.selected && operationData.draft); // or: Entra sin existir ningun captable
  const editable = canEdit && operationData.draft;

  const updateLastNumeration = (cap = null) => {
    const auxCaptable = cap || captable;
    if (auxCaptable?.loaded || auxCaptable?.captable?.id) {
      let newLastNumeration = auxCaptable.lastNumeration || 0;
      let newTotalShares = auxCaptable?.summary?.shares || 0;
      subOperations.shares.forEach((item) => {
        newLastNumeration = Math.max(newLastNumeration, item?.create?.numeration?.to);
        newTotalShares += item?.create?.number;
      });

      subOperations.conversions.forEach((item) => {
        newLastNumeration = Math.max(newLastNumeration, item?.numeration?.to);
        newTotalShares += item?.shares;
      });

      setCapTableVariables(prev => ({
        ...prev,
        lastNumeration: newLastNumeration,
        shares: newTotalShares,
      }));
    }
  };

  const updateOptionsNumber = (cap = null) => {
    const auxCaptable = cap || captable;
    if (auxCaptable?.loaded || auxCaptable?.captable?.id) {
      let newTotalOptions = auxCaptable?.summary?.options || 0;
      subOperations.options.forEach((item) => {
        newTotalOptions += item?.create?.number || 0;
      });

      setCapTableVariables(prev => ({
        ...prev,
        options: newTotalOptions,
      }));
    }
  };

  useEffect(() => {
    updateLastNumeration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subOperations.shares, subOperations.conversions]);

  useEffect(() => {
    updateOptionsNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subOperations.options]);
  useEffect(() => {
    (async () => {
      if (!sourceOperation?.loading) {
        setLoading(true);
        Promise.all([
          fetchCaptableVariables(startup.id, sourceOperation?.id, captableId),
          initializeCaptable(fetchCaptable, captable, startup.id, captableId, true, sourceOperation?.id || null),
        ]).then(([response, cap]) => {
          setCapTableVariables(prev => ({ ...prev, ...response }));
          updateLastNumeration(cap);
          updateOptionsNumber(cap);
        })
          .catch(() => setLoading(false))
          .finally(() => setLoading(false));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceOperation]);

  const handleUpdateValuation = (type, index, shareId) => {
    setSubOperations(prev => ({
      ...prev,
      shares: prev.shares.map((share, i) => (
        { create: { ...share.create, valuation: type === 'shares' && index === i }, assign: [...share.assign] }
      )),
      secondaries: prev.secondaries.map((scd, i) => {
        const aux = {};
        Object.keys(scd.shares).forEach((share) => {
          aux[share] = { ...scd.shares[share], valuation: type === 'secondaries' && index === i && share === shareId };
        });
        return { ...scd, shares: aux };
      }),
    }));
  };

  const updatingValuationPPS = () => {
    const pps = [];
    subOperations.shares.forEach((share) => {
      if (updateValuation.includes(share.create.id)) {
        pps.push(share.create.nominalValue + share.create.sharePremium);
      }
    });
    subOperations.secondaries.forEach((scd) => {
      Object.keys(scd.shares).forEach((share) => {
        if (updateValuation.includes(scd.id)) {
          pps.push(scd.shares[share].pps);
        }
      });
    });
    return pps.length ? pps.every(elem => elem === pps[0]) : false;
  };

  const formatOperationErrors = (opErrors) => {
    const formatErrors = { total: 0, items: {} };
    if (opErrors && opErrors.length > 0) {
      opErrors.forEach((error) => {
        formatErrors.total += 1;
        if (!formatErrors.items[error.element.attr]) {
          formatErrors.items[error.element.attr] = {};
        }
        if (!formatErrors.items[error.element.attr][error.element.id]) {
          formatErrors.items[error.element.attr][error.element.id] = [];
        }
        formatErrors.items[error.element.attr][error.element.id].push({
          ...error,
          message: literals.errors[error.code] || error.message,
        });
      });
    }
    return formatErrors;
  };

  const loadOperation = async (forceId = null) => {
    if (!operationData.id && !forceId) {
      return operationData;
    }

    try {
      const data = await fetchOperation(startup.id, forceId || operationData.id);
      const newOperationData = {
        id: data.id,
        name: data.name,
        date: data.date,
        notes: data.notes,
        documents: data.documents || [],
        draft: data.draft,
        stats: data.stats,
        loaded: true,
        errors: formatOperationErrors(data.errors),
      };
      setOperationData(newOperationData);

      const oldOptions = {};
      data.options.assign.forEach((assign) => {
        if (assign.old) {
          if (!oldOptions[assign.option]) {
            oldOptions[assign.option] = {
              create: null,
              old: assign.option,
              assign: [],
            };
          }
          oldOptions[assign.option].assign.push(assign);
        }
      });

      setSubOperations({
        shares: data.shares.create.map(item => ({
          create: item,
          assign: data.shares.assign.filter(assign => item.id === assign.shares),
        })),
        options: [...data.options.create.map(item => ({
          create: item,
          assign: data.options.assign.filter(assign => item.id === assign.option),
        })), ...Object.values(oldOptions)],
        debts: data.debts,
        payouts: data.payouts,
        secondaries: data.secondaries.map(secondary => ({
          ...secondary,
          shares: secondary.shares.reduce((acc, secShare) => {
            return { ...acc, [secShare.shareClass]: { ...secShare } };
          }, {}),
        })),
        changeClasses: data.changeClasses.map(changeClass => ({
          ...changeClass,
          shares: changeClass.shares.reduce((acc, chShare) => {
            return { ...acc, [chShare.shareClass]: { ...chShare } };
          }, {}),
        })),
        reduces: data.reduces.map(reduce => ({
          ...reduce,
          shares: reduce.shares.reduce((acc, elem) => {
            return { ...acc, [elem.from]: elem };
          }, {}),
        })),
        conversions: data.conversions,
        vests: data.vests,
        stockSplit: data.stockSplit,
        valuation: data.valuation,
        dividend: data.dividend,
      });
      return newOperationData;
    } catch (e) {
      setNotFound(true);
      await dialog({
        type: 'error',
        text: literals.operationNotFound,
      });
      navigate(embedView(ROUTE_STARTUP_PATH.setCapTableOperations(startup.id, captableId)));
      return false;
    }
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await loadOperation(operationData.id);

      const captableOperations = await fetchStartupOperations(startup.id, {
        page: 0, size: 0, search: '', captable: captableId, simulation,
      }) || null;
      setOperations(captableOperations.items);
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (subOperations.valuation) {
      if (subOperations.valuation.valuationType === 'total') {
        setSubOperations(prev => ({ ...prev, valuation: { ...prev.valuation, pps: Number(prev.value / captableVariables.lastNumeration).toFixed(2) } }));
      } else if (subOperations.valuation.valuationType === 'pps') {
        setSubOperations(prev => ({ ...prev, valuation: { ...prev.valuation, value: Number(prev.pps * captableVariables.lastNumeration).toFixed(2) } }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captableVariables.lastNumeration]);

  const handleValidateDate = async (value) => {
    if (lastConfirmed && value < lastConfirmed.date) {
      setOperationData(prev => ({ ...prev, date: lastConfirmed.date }));
      await dialog({
        type: 'error',
        text: literals.errorDateBeforeLastOperation,
      });
    }
  };

  const handleSubmitOperation = async (isDraft = false) => {
    const amplitudeKey = `startup.transactions.operationDetail.click.${isDraft ? 'saveDraft' : 'confirm'}`;
    const validDate = operationData.date || formatDate(false, { format: 'Y-m-d' });

    if (!operationData.name) {
      await dialog({
        type: 'error',
        text: literals.errorEmptyName,
      });
    } else if (lastConfirmed && validDate < lastConfirmed.date) {
      await dialog({
        type: 'error',
        text: literals.errorDateBeforeLastOperation,
      });
    } else {
      const data = {
        captable: captableId,
        date: validDate,
        name: operationData.name || validDate,
        notes: operationData.notes,
        documents: operationData.documents,
        draft: isDraft,
        timeline: true,
        simulation,
        shares: {
          create: subOperations.shares.map(item => item.create),
          assign: [].concat.apply([], subOperations.shares.map(item => item.assign)),
        },
        debts: subOperations.debts,
        options: {
          create: subOperations.options.map(item => item.create).filter(v => v),
          assign: [].concat.apply([], subOperations.options.map(item => item.assign)),
        },
        payouts: subOperations.payouts,
        secondaries: subOperations.secondaries.map(item => (
          { ...item, shares: Object.values(item.shares) }
        )),
        changeClasses: subOperations.changeClasses,
        reduces: subOperations.reduces.map(item => ({ ...item, shares: Object.values(item.shares) })),
        conversions: subOperations.conversions,
        vests: subOperations.vests,
        stockSplit: subOperations.stockSplit,
        valuation: subOperations?.valuation || null,
        dividend: subOperations.dividend ? { id: subOperations.dividend.id, amount: subOperations.dividend.amount } : null,
      };

      const conflict = updateValuation.length > 1 && !updatingValuationPPS(); // Si hay mas de un pps y son diferentes
      if (!subOperations.valuation && conflict) {
        setPopupValuation({ show: true, isDraft });
      } else {
        if (subOperations.valuation && subOperations.shares.length + subOperations.secondaries.length > 0) {
          handleUpdateValuation('', 0, null);
        }

        let dialogText = isDraft ? literals.confirmSubmitDraft : literals.confirmSubmit;
        if (sourceOperation && sourceOperation?.id !== lastConfirmed?.id && !isDraft) {
          dialogText = (
            <p>
              <span className='fw-b'>{literals.operationNotTheLast}</span>
              <br />
              {literals.confirmMultipleOperations}
            </p>
          );
        }
        const confirm = await dialog({ type: 'confirm', text: dialogText });

        if (confirm) {
          await dialog({
            type: 'loading',
            execute: async () => {
              try {
                const response = await submitOperation(startup.id, data, operationData.id, captableVariables);

                let redirect = true;
                if (response.errors.length > 0) {
                  await dialog({
                    type: data.draft ? 'alert' : 'error',
                    text: data.draft ? literals.saveWithErrors : literals.cannotConfirmOperation,
                  });
                  redirect = data.draft;
                }
                AmplitudeApi.successEvent(amplitudeKey, {
                  captable: captable?.selected?.id, stats: operationData.stats,
                });
                if (redirect) {
                  if (isDraft) {
                    navigate(embedView(ROUTE_STARTUP_PATH.setCapTableOperationsComparator(startup.id, captableId, response.id, simulation)));
                  } else {
                    navigate(embedView(ROUTE_STARTUP_PATH.setCapTable(startup.id, captableId)));
                  }
                } else {
                  setOperationData(prev => ({
                    ...prev,
                    id: response.id,
                    errors: formatOperationErrors(response.errors),
                  }));
                  navigate(embedView(ROUTE_STARTUP_PATH.setCapTableOperation(startup.id, captableId, response.id)));
                  setLoading(true);
                  await loadOperation(response.id);
                  setLoading(false);
                }
              } catch (exception) {
                AmplitudeApi.errorEvent(amplitudeKey, {
                  captable: captable?.selected?.id, stats: operationData.stats,
                });
                await dialog({
                  type: 'error',
                  text: <OutputErrors errors={exception} literals={literals.errors} text />,
                });
              }
            },
          });
        }
      }
    }
  };

  const handleDeleteOperation = async () => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literalsCommon.confirmDelete,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            await deleteOperation(startup.id, operationData.id);
            AmplitudeApi.successEvent('startup.transactions.operationDetail.click.deleteOperation', {
              captable: captable?.selected?.id, stats: operationData.stats,
            });
            navigate(embedView(ROUTE_STARTUP_PATH.setCapTableOperations(startup.id, captableId)));
          } catch (exceptionErrors) {
            AmplitudeApi.errorEvent('startup.transactions.operationDetail.click.deleteOperation', {
              captable: captable?.selected?.id, stats: operationData.stats,
            });
            let message = '';
            switch (exceptionErrors?.error[0]?.code) {
              case 'cannot_delete_operation':
                message = literals.errorDeleteConfirmedOperation;
                break;
              default:
                message = literalsCommon.genericError;
                break;
            }
            await dialog({
              type: 'error',
              text: message,
            });
          }
        },
      });
    }
  };

  if (!operationData.loaded) {
    return <Loading hide={false} mode='tool' />;
  }
  if (notFound) {
    return <NotFound />;
  }

  return (
    <Tool className='operation-wrapper'>
      <Breadcrumb
        routes={[
          { route: ROUTE_STARTUP_PATH.setCapTable(startup.id, captableId), name: literals.captable },
          { route: ROUTE_STARTUP_PATH.setCapTableOperations(startup.id, captableId), name: literals.operations },
          { name: operationData.date ? formatDate(operationData.date) : literalsCommon.new },
        ]}
      />
      <Panel>
        <PanelBody noHeader>
          <div className='row'>
            <div className='col-xs-12 col-md-5'>
              <InputDate
                preText={(
                  <>
                    <span>{literalsCommon.date}</span>
                    {lastConfirmed && (
                      <small>
                        {` (${literals.lastConfirmed}: ${formatDate(lastConfirmed?.date)})`}
                      </small>
                    )}
                  </>
                )}
                postText={sourceOperation?.id && (
                  <div className='reference-operation'>
                    <div className='reference-operation-name'>
                      <Info size={12} className='mr-1' />
                      <span className='fs-sm'>{`Operacion de referencia: ${sourceOperation?.name || lastConfirmed?.name}`}</span>
                    </div>
                    <div className='reference-operation-info'>
                      {literals.referenceOperationInfo}
                    </div>
                  </div>
                )}
                value={operationData.date}
                onChange={v => setOperationData(prev => ({ ...prev, date: v }))}
                onBlur={handleValidateDate}
                isDisabled={!editable}
              />
            </div>
            <div className='col-xs-12 col-md-7'>
              <InputText
                preText={literalsCommon.name}
                value={operationData.name}
                onChange={v => setOperationData(prev => ({ ...prev, name: v }))}
                isDisabled={!editable}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12 col-md-7'>
              <InputRichText
                minHeight='120px'
                preText={literalsCommon.notes}
                value={operationData.notes}
                onChange={v => setOperationData(prev => ({ ...prev, notes: v }))}
                isDisabled={!editable}
              />
            </div>
            <div className='col-xs-12 col-md-5'>
              <InputFile
                preText={literalsCommon.documents}
                value={operationData.documents}
                isPublic={false}
                onChange={v => setOperationData(prev => ({ ...prev, documents: v }))}
                multiple
                isDisabled={!editable}
              />
            </div>
          </div>
        </PanelBody>
      </Panel>
      <OperationsPanel
        literals={literals}
        literalsCommon={literalsCommon}
        match={match}
        currency={currency}
        captable={captable}
        loading={loading || sourceOperation?.loading || !captable.loaded}
        subOperations={subOperations}
        setSubOperations={setSubOperations}
        captableVariables={captableVariables}
        operationData={operationData}
        source={sourceOperation?.id}
        editable={editable}
      />
      {
        editable && (
          <div className='buttons'>
            {
              !simulation && (
                <Button
                  icon={CheckCircle}
                  text={literals.confirm}
                  onClick={() => handleSubmitOperation(false)}
                  loading={loading || !captable.loaded}
                />
              )
            }
            <Button
              icon={Hourglass}
              color='secondary'
              text={simulation ? literals.saveAsSimulation : literals.saveAsDraft}
              onClick={() => handleSubmitOperation(true)}
              loading={loading || !captable.loaded}
            />
            {
              operationData.id && (
                <Button
                  icon={Trash}
                  color='danger'
                  text={literalsCommon.delete}
                  onClick={() => handleDeleteOperation()}
                  loading={loading || !captable.loaded}
                />
              )
            }
          </div>
        )
      }
      {
        popupValuation && (
          <PopupUpdateValuation
            shares={subOperations.shares}
            secondaries={subOperations.secondaries}
            literals={literals}
            literalsCommon={literalsCommon}
            onConfirm={(v, row, shareId) => {
              handleUpdateValuation(v, row, shareId);
            }}
            captable={captable}
            currency={currency}
            convertibles={subOperations.convertibles}
            assigned={subOperations.assigned}
            onClose={() => setPopupValuation(null)}
          />
        )
      }
    </Tool>
  );
};

CaptableOperation.propTypes = {
  startup: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  fetchCaptable: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  submitOperation: PropTypes.func.isRequired,
  deleteOperation: PropTypes.func.isRequired,
};

export default CaptableOperation;
