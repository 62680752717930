import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

const DraggableComponent = ({
  id,
  index,
  className = '',
  children,
}) => {
  return (
    <Draggable draggableId={id} index={index}>
      {draggableProvider => (
        <div
          className={className}
          ref={draggableProvider.innerRef}
          {...draggableProvider.draggableProps}
          {...draggableProvider.dragHandleProps}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};

DraggableComponent.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default DraggableComponent;
