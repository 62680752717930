import Image from '@editorjs/image';
import List from '@editorjs/list';
import Marker from '@editorjs/marker';
import SimpleImage from '@editorjs/simple-image';
import Table from '@editorjs/table';
import Paragraph from '@editorjs/paragraph';
import AttachesTool from '@editorjs/attaches';
import Header from '@editorjs/header';
import { uploadFile } from 'modules/actions';
import { API_BASE_URL } from 'constants/apiConf';

const EDITOR_JS_TOOLS = {
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    config: {
      placeholder: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr.',
    },
  },
  header: Header,
  table: Table,
  list: List,
  image: {
    class: Image,
    config: {
      uploader: {
        uploadByFile(file) {
          return uploadFile(file, false).then((response) => {
            return {
              success: 1,
              file: {
                id: response.id,
                name: response.name,
                url: `${API_BASE_URL}/api/v1${response.path}`,
                title: file.name,
                size: file.size,
              },
            };
          });
        },
      },
    },
  },
  marker: Marker,
  simpleImage: SimpleImage,
  attaches: {
    class: AttachesTool,
    config: {
      uploader: {
        uploadByFile(file) {
          return uploadFile(
            new File([file], file.name.replace(/[^a-zA-Z0-9.-]/g, ''), { type: file.type }), false,
          ).then((response) => {
            return {
              success: 1,
              file: {
                id: response.id,
                name: response.name,
                url: `${API_BASE_URL}/api/v1${response.path}`,
                title: file.name,
                size: file.size,
              },
            };
          });
        },
      },
    },
  },
};

export default EDITOR_JS_TOOLS;
