import AdminInstance from 'modules/admin/repository';

export const getWebhooks = (page, size, search, filters, sort) => {
  return AdminInstance.getWebhooks({
    page, size, search, filters, sort,
  });
};

export const deleteWebhook = (id) => {
  return AdminInstance.deleteWebhook(id);
};
