import React from 'react';
import Collapse from 'components/Collapse/Collapse';
import Button from 'components/Buttons/Button';
import { GripVertical, Trash } from 'lucide-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const FormQuestions = (props) => {
  const {
    formsData,
    setAddElementForm,
    selectQuestionForm,
    langSelected,
    literals,
    selectIconForm,
    setFormsData,
  } = props;

  const reorder = (list) => {
    return list.map((elem, index) => ({ ...elem, order: index + 1 }));
  };

  const handleDeleteform = (event) => {
    const [form, id] = event.currentTarget.id.split(':');
    setFormsData((prevFormsData) => {
      const newFormsData = { ...prevFormsData };
      newFormsData[form] = reorder(newFormsData[form].filter(elem => elem.id !== id));
      return newFormsData;
    });
  };

  const handleOrderChange = (list, startIndex, endIndex, form) => {
    const forms = { ...list };

    const [removed] = forms[form].splice(startIndex, 1);
    forms[form].splice(endIndex, 0, removed);
    forms[form] = reorder(forms[form]);

    return forms;
  };

  const handleCheckChange = (event) => {
    const data = event.target.name.split(':');
    const newForm = { ...formsData };
    const aux = newForm[data[0]];
    for (let index = 0; index < aux.length; index += 1) {
      if (aux[index].id === data[1] && data[2] === 'public') {
        aux[index].public = !aux[index].public;
      } else if (aux[index].id === data[1] && data[2] === 'filter') {
        aux[index].filter = !aux[index].filter;
      }
    }
    newForm[data[0]] = aux;
    setFormsData({
      ...newForm,
    });
  };

  const selectDataForm = (elem) => {
    if (elem) {
      return true;
    }
    return false;
  };

  const renderForms = () => {
    return Object.keys(formsData)
      .sort()
      .map(key => (
        <Collapse key={`collapse_${key}`} title={`${key}:  ${formsData[key].length} campos`} state={false}>
          <DragDropContext onDragEnd={(result) => {
            const { source, destination } = result;
            if (!destination || source.index === destination.index) {
              return;
            }
            setFormsData(prevFormsData => handleOrderChange(prevFormsData, source.index, destination.index, destination.droppableId));
          }}
          >
            <Droppable key={key} droppableId={key}>
              {droppableProvider => (
                <div
                  {...droppableProvider.droppableProps}
                  ref={droppableProvider.innerRef}
                >
                  {formsData[key].map((value, index) => {
                    const question = selectQuestionForm(value.id);
                    return (
                      <Draggable key={value.id} draggableId={value.id} index={index}>
                        {draggableProvider => (
                          <div
                            {...draggableProvider.draggableProps}
                            ref={draggableProvider.innerRef}
                            {...draggableProvider.dragHandleProps}
                            className='rowForm'
                          >
                            <GripVertical className='rowForm_grip-icon' />
                            <div className='rowForm_order'>
                              {value.order}
                            </div>
                            <div className='rowForm_content'>
                              <div className='rowForm_content-title'>
                                {selectIconForm(value.id)}
                                <p>{`${question.title[langSelected]}${question.mandatory ? ' *' : ''}`}</p>
                              </div>
                              <div className='rowForm_content-options'>
                                <div>
                                  <input type='checkbox' name={`${key}:${value.id}:public`} className='checkForm' onChange={handleCheckChange} checked={selectDataForm(value.public)} />
                                  <span>{literals.public}</span>
                                </div>
                                <div>
                                  <input type='checkbox' name={`${key}:${value.id}:filter`} className='checkForm' onChange={handleCheckChange} checked={selectDataForm(value.filter)} />
                                  <span>{literals.filter}</span>
                                </div>
                              </div>
                            </div>
                            <div className='rowForm_icons'>
                              <div id={value.id}>
                                <Trash className='trash' id={`${key}:${value.id}`} onClick={handleDeleteform} />
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {droppableProvider.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className='addButton formBody-show'>
            <Button
              color='outline-primary'
              text='ADD OPTION'
              onClick={() => setAddElementForm(key)}
            />
          </div>
        </Collapse>
      ));
  };

  return renderForms();
};


export default FormQuestions;
