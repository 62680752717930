import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatNumber } from 'utils/functions';
import InputNumber from 'components/Inputs/inputNumber';
import InputText from 'components/Inputs/inputText';
import Loading from 'components/Loading';
import Popup from 'components/Popup';
import Table from 'components/Table/';
import { useParams, useSearchParams } from 'react-router-dom';
import { getShareClass, fetchCaptableShares } from '../modules/actions';

const PopupInfoShareClass = (props) => {
  const {
    onClose,
    id,
    date,
    literals,
    literalsCommon,
    currency,
  } = props;

  const PAGE_SIZE = 10;
  const [data, setData] = useState();
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const params = useParams();

  const getData = async () => {
    const shareClass = await getShareClass(params.id, id);
    setData(shareClass);
  };

  const getShareclassShares = async (page, size) => {
    return fetchCaptableShares(params.id, date, {
      page, size, filters: { shareClass: id }, captable: captableId,
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sharesColumns = [
    {
      field: 'originalOperation',
      label: literalsCommon.date,
      width: 130,
      preRender: v => v.date ?? '-',
    },
    {
      label: `${literals.shares}`,
      preRender: (f, r) => `${formatNumber(r.number, 0)}# ${r.shareholder.name}`,
      grow: 2,
    },
    {
      label: `${literals.capital} (${literals.pps})`,
      preRender: (f, r) => ` ${formatNumber(r.capital, 0, { dec: 2 })}${currency.symbol} (${formatNumber(r.pps, 0)}${currency.symbol})`,
    },
    {
      field: 'numeration',
      label: literals.numeration,
      preRender: v => `${formatNumber(v.from, 0)} - ${formatNumber(v.to, 0)}`,
    },
  ];

  const renderInfo = () => {
    return (
      <div className='box box-padding box-mb'>
        <div className='row'>
          <div className='col-md-3'>
            <InputText preText={literals.type} value={literals[data.type]} isDisabled />
          </div>
          <div className='col-md-6'>
            <InputText preText={literals.name} value={data.name} isDisabled />
          </div>
          <div className='col-md-3'>
            <InputText preText={literals.code} value={data.code} isDisabled />
          </div>
        </div>
        {data.type === 'preferred' && (
          <div>
            {data.liquidity.enabled && (
              <div className='row'>
                <div className='col-md-3'>
                  <InputText preText={literals.liquidity} value={data.liquidity.enabled ? literals.yes : literals.no} isDisabled />
                </div>
                <div className='col-md-5'>
                  <InputNumber preText={literals.seniority} value={data.liquidity.seniority || 0} isDisabled />
                </div>
                <div className='col-md-4'>
                  <InputNumber preText={literals.multiple} value={data.liquidity.multiple || 0} isDisabled />
                </div>
              </div>
            )}
            {data.participation.enabled && (
              <div className='row'>
                <div className='col-md-3'>
                  <InputText preText={literals.participation} value={data.participation.enabled ? literals.yes : literals.no} isDisabled />
                </div>
                <div className='col-md-9'>
                  <InputNumber preText={literals.cap} value={data.participation.cap || 0} isDisabled />
                </div>
              </div>
            )}
            {data.dividend.enabled && (
              <div className='row'>
                <div className='col-md-3'>
                  <InputText preText={literals.dividend} value={data.dividend.enabled ? literals.yes : literals.no} isDisabled />
                </div>
                <div className='col-md-2'>
                  <InputNumber preText={literals.value} value={data.dividend.value || 0} isDisabled />
                </div>
                <div className='col-md-3'>
                  <InputText preText={literals.frequency} value={literals[data.dividend.frequency] || ''} isDisabled />
                </div>
                <div className='col-md-2'>
                  <InputText preText={literals.type} value={literals[data.dividend.type] || ''} isDisabled />
                </div>
                <div className='col-md-2'>
                  <InputText preText={literals.compounding} value={data.dividend.compounding ? literals.yes : literals.no} isDisabled />
                </div>
              </div>
            )}
            {data.convert.enabled && (
              <div className='row'>
                <div className='col-md-3'>
                  <InputText preText={literals.convert} value={data.convert.enabled ? literals.yes : literals.no} isDisabled />
                </div>
                <div className='col-md-5'>
                  <InputNumber preText={literals.ratio} value={data.convert.ratio || 0} isDisabled />
                </div>
                <div className='col-md-4'>
                  <InputNumber preText={literals.price} value={data.convert.price || 0} isDisabled />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <Popup title={literals.InfoShareClass} onClose={onClose} size='large'>
      {data ? (
        <>
          {renderInfo()}
          <div className='box box-padding box-mb'>
            <Table
              searcher={false}
              columns={sharesColumns}
              fetch={getShareclassShares}
              pageSize={PAGE_SIZE}
            />
          </div>
        </>
      ) : (
        <Loading hide={false} mode='panel' />
      )}
    </Popup>
  );
};

const mapStateToProps = state => ({
  literals: state.i18n.literals.shareClass,
  literalsCommon: state.i18n.literals.common,
  currency: state.global.currency,
});

PopupInfoShareClass.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  date: PropTypes.string,
};
PopupInfoShareClass.defaultProps = {
  date: new Date().toISOString().split('T')[0],
};

export default connect(mapStateToProps)(PopupInfoShareClass);
