import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import {
  Route, Navigate, Routes, useLocation,
} from 'react-router-dom';
import { ROUTE_TYPE_REDIRECT, ROUTE_PATH, isEmbed } from 'routes';
import NotFound from 'routes/NotFound';
import RenderView from './RenderView';
import { usePageView } from '../hooks/usePageView';
import { useSessionRoutes } from '../hooks/useSessionRoutes';

function AppRoutes(props) {
  const {
    isAuthenticated,
    user,
    platform,
    setGlobalProperties,
    sessionChecked,
    initPlatform,
  } = props;

  const { sessionRoutes } = useSessionRoutes(sessionChecked, isAuthenticated, user, platform, initPlatform);
  const location = useLocation();
  usePageView(platform);

  if (!sessionChecked || (!isAuthenticated && !platform.loaded) || !sessionRoutes.length) {
    return <Loading hide={false} />;
  }

  return (
    <Routes>
      { sessionRoutes.map((route) => {
        switch (route.type) {
          case ROUTE_TYPE_REDIRECT:
            return (
              <Route
                key={route.path}
                path={route.path}
                element={<Navigate from={route.path} to={route.to} exact={route.exact} />}
              />
            );
          default:
            return (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                element={(
                  <RenderView
                    user={user}
                    setGlobalProperties={setGlobalProperties}
                    route={route}
                    isAuthenticated={isAuthenticated}
                    platform={platform}
                  />
                )}
              />
            );
        }
      })}
      <Route
        path='*'
        element={
          isAuthenticated || isEmbed()
            ? <NotFound />
            : (
              <Navigate to={{
                pathname: ROUTE_PATH.LOGIN,
                state: { from: location.pathname },
                search: location.search,
              }}
              />
            )
        }
      />
    </Routes>
  );
}

AppRoutes.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
  sessionChecked: PropTypes.object.isRequired,
  initPlatform: PropTypes.func.isRequired,
  setGlobalProperties: PropTypes.func.isRequired,
};

export default AppRoutes;
