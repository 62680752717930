import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCaptableAction } from 'routes/Captable/modules/actions';
import { deleteOperationAction, submitOperationAction } from '../modules/actions';
import CaptableOperationComponents from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.captable,
  literalsCommon: state.i18n.literals.common,
  startup: state.global.startup,
  captable: state.captable,
  currency: state.global.currency,
});

const mapDispatchToProps = dispatch => ({
  fetchCaptable: bindActionCreators(fetchCaptableAction, dispatch),
  submitOperation: bindActionCreators(submitOperationAction, dispatch),
  deleteOperation: bindActionCreators(deleteOperationAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaptableOperationComponents);
