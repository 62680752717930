import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import Loading from 'components/Loading';
import InputText from 'components/Inputs/inputText';
import InputDate from 'components/Inputs/inputDate';
import InputNumber from 'components/Inputs/inputNumber';
import InputRichText from 'components/Inputs/inputRichText';
import InputFile from 'components/Inputs/inputFile';
import Separator from 'components/Separator';
import { useSearchParams } from 'react-router-dom';
import { formatDate } from 'utils/functions';
import { fetchCaptableOptions, fetchCaptableShares } from '../modules/actions';
import { fetchShareClasses } from '../../shareClasses/modules/actions';
import ShareholdersDividend from './ShareholdersDividend';
import './styles.scss';

const FormDividend = (props) => {
  const {
    view,
    literals,
    literalsCommon,
    editable,
    currency,
    captable,
    match,
    data,
    date,
    onConfirm,
    onDelete,
  } = props;

  const [id] = useState(data?.id ?? `new.${new Date().getTime()}`);
  const [name, setName] = useState(data?.name);
  const [inputDate, setInputDate] = useState(date);
  const [notes, setNotes] = useState(data?.notes || '');
  const [documents, setDocuments] = useState([]);
  const [shareholders, setShareholders] = useState(data.shareholders || []);
  const [amount, setAmount] = useState(data.amount || 0);
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;

  const getShareholders = async () => {
    const shares = await fetchCaptableShares(match.params.id, formatDate(new Date(), { format: 'Y-m-d' }), { page: 0, size: 0, captable: captableId });
    const shareClasses = await fetchShareClasses(match.params.id, 0, 0);
    const phantom = await fetchCaptableOptions(match.params.id, formatDate(new Date(), { format: 'Y-m-d' }), {
      page: 0, size: 0, group: 'shareholder', filters: { type: 'phantom' }, captable: captableId,
    });

    const receiveDividends = shareClasses.items
      .filter(sc => sc.type === 'common' || sc.dividend.enabled)
      .map(sc => sc.id);

    const auxShareholders = {};
    shares.items.forEach((share) => {
      if (receiveDividends.includes(share?.shareClass?.id)) {
        if (auxShareholders[share.shareholder.id]) {
          auxShareholders[share.shareholder.id] = {
            ...auxShareholders[share.shareholder.id],
            shares: auxShareholders[share.shareholder.id].shares + share.number,
          };
        } else {
          auxShareholders[share.shareholder.id] = { name: share.shareholder.name, shares: share.number };
        }
      }
    });
    phantom.items.forEach((share) => {
      if (share.vested) {
        if (auxShareholders[share.shareholder.id]) {
          auxShareholders[share.shareholder.id] = {
            ...auxShareholders[share.shareholder.id],
            shares: auxShareholders[share.shareholder.id].shares + share.vested,
          };
        } else {
          auxShareholders[share.shareholder.id] = { name: share.shareholder.name, shares: share.vested };
        }
      }
    });

    const totalShares = Object.keys(auxShareholders).reduce((acumulador, actual) => acumulador + auxShareholders[actual].shares, 0);
    setShareholders(Object.keys(auxShareholders).map(sh => ({
      id: sh,
      name: auxShareholders[sh].name,
      ownership: auxShareholders[sh].shares * 100 / totalShares,
      amount: shareholders.find(auxSh => sh === auxSh.id)?.amount || 0,
      custom: shareholders.find(auxSh => sh === auxSh.id)?.custom || false,
    })));
  };

  useEffect(() => {
    getShareholders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetAmounts = () => {
    setShareholders(shs => shs.map(sh => ({
      ...sh,
      amount: sh.ownership * amount / 100,
      custom: false,
    })));
  };

  const handleConfirm = () => {
    if (view === 'shares') {
      onConfirm({
        operationType: 'dividend',
        id,
        date: inputDate,
        name,
        notes,
        documents,
        amount,
        shareholders,
      });
    } else {
      onConfirm({
        operationType: 'dividend',
        id,
        amount,
        shareholders,
      });
    }
  };

  return (
    shareholders ? (
      <>
        {view === 'shares' && (
          <>
            <div className='row'>
              <div className='col-12 col-md-8'>
                <InputText
                  className='m-0'
                  preText={literals.operationName}
                  placeholder={literals.operationName}
                  value={name}
                  onChange={v => setName(v)}
                  isDisabled={!editable}
                />
              </div>
              <div className='col-12 col-md-4'>
                <InputDate
                  className='m-0'
                  preText={literalsCommon.date}
                  value={inputDate}
                  onChange={v => setInputDate(v)}
                  isDisabled={!editable}
                  min={captable?.operations?.length ? captable?.operations[0].date : null}
                />
              </div>
            </div>
            <Separator />
          </>
        )}
        <div className='row mb-3'>
          <div className='col-12 col-sm-8 col-md-5'>
            <InputNumber
              className='m-0'
              preText={literals.amount}
              value={amount}
              onChange={v => setAmount(v)}
              minValue={0}
              isDisabled={!editable}
              symbol={currency.symbol}
              step='any'
              decimals={2}
            />
          </div>
          <div className='col-12 col-sm-2 d-flex align-items-end mt-3 mb-1'>
            <Button text='reset' color='secondary' onClick={resetAmounts} className='w-100' />
          </div>
        </div>
        <div className='row'>
          <div className='col-12 mb-2'>
            <ShareholdersDividend
              literals={literals}
              literalsCommon={literalsCommon}
              currency={currency}
              editable={editable}
              shareholders={shareholders}
              setShareholders={setShareholders}
              amount={amount}
            />
          </div>
        </div>
        {view === 'shares' && (
          <>
            <Separator />
            <div className='row'>
              <div className='col-12 '>
                <InputRichText
                  minHeight='120px'
                  preText={literals.additionalDocumentation}
                  value={notes}
                  onChange={v => setNotes(v)}
                  isDisabled={!editable}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <InputFile
                  preText={literalsCommon.documents}
                  value={documents}
                  isPublic={false}
                  onChange={v => setDocuments(v)}
                  multiple
                  isDisabled={!editable}
                />
              </div>
            </div>
          </>
        )}
        {
          editable && (
            <div className='buttons mt-2'>
              <Button text={literalsCommon.confirm} onClick={handleConfirm} />
              {
                data?.id && (
                  <Button text={literalsCommon.delete} color='danger' onClick={() => onDelete(data.id)} />
                )
              }
            </div>
          )
        }
      </>
    ) : <Loading mode='panel' hide={false} />
  );
};

FormDividend.propTypes = {
  view: PropTypes.string,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  date: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

FormDividend.defaultProps = {
  view: 'operation',
  editable: true,
  onDelete: () => {},
};


export default FormDividend;
