import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelTab from 'components/Panel/components/PanelTab';
import Loading from 'components/Loading';
import EmailStats from 'components/EmailStats';
import { ROUTE_STARTUP_PATH } from 'routes';
import { formatDate } from 'utils/functions';
import { downloadReport, getReport, getReportEmails } from 'routes/Reporting/modules/actions';
import FolderContent from 'routes/DataRoom/components/FolderContent';
import './styles.scss';
import Button from 'components/Buttons/Button';
import { FileText } from 'lucide-react';
import { getReportFilename } from 'routes/Reporting/components';
import IOFileLoading from 'components/IOFileLoading/components';
import UsersPopup from './UsersPopup';

const ReportInfo = (props) => {
  const {
    literals,
    literalsDataroom,
    literalsCommon,
    match,
  } = props;

  const [report, setReport] = useState(null);
  const [showUsersPopup, setShowUsersPopup] = useState(null);
  const [downloading, setDownloading] = useState(false);

  const filename = getReportFilename(report, formatDate);

  const fetchReport = async () => {
    setReport(await getReport(match.params.id, match.params.reporting));
  };

  useEffect(() => {
    fetchReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEmails = async (page, size, search, sort) => {
    return getReportEmails(match.params.id, match.params.reporting, {
      page, size, search, sort,
    });
  };
  const handleDownloadReport = async () => {
    setDownloading(true);
    await downloadReport(match.params.id, match.params.reporting, filename);
    setDownloading(false);
  };

  const breadcrumb = [
    { route: ROUTE_STARTUP_PATH.setReporting(match.params.id), name: literals.title },
    { route: ROUTE_STARTUP_PATH.setReporting(match.params.id), name: report?.subject },
  ];

  const tabs = [
    { key: 'details', name: literals.details },
    { key: 'emails', name: literals.emails },
  ];

  if (report?.folder) {
    tabs.push({ key: 'documents', name: literals.documents });
  }

  return (
    report ? (
      <Panel>
        <PanelHeader breadcrumb={breadcrumb} tabs={tabs} />
        <PanelBody>
          <PanelTab key='details'>
            <div className='reporting-info'>
              {filename}
              <div className='row my-3'>
                <div className='col-12 col-md-6'>
                  <h6 className='fw-b'>{literals.subject}</h6>
                  <p>{report.subject}</p>
                </div>
                <div
                  className='col-12 col-md-3 recipients'
                  onClick={() => setShowUsersPopup(report.id)}
                >
                  <h6 className='fw-b'>{literals.recipients}</h6>
                  <p>{`${report.recipients.length} ${literals.recipients}`}</p>
                </div>
                <div className='col-12 col-md-3'>
                  <h6 className='fw-b'>{literalsCommon.date}</h6>
                  <p>{formatDate(report.createdAt, { time: true })}</p>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='report-visualizer'>
                    <div className='report-visualizer-content'>
                      <div dangerouslySetInnerHTML={{ __html: report.message }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='buttons mt-lb'>
              <Button icon={FileText} text={literals.downloadPdf} onClick={handleDownloadReport} />
            </div>

            {
              showUsersPopup && (
                <UsersPopup
                  literals={literals}
                  literalsCommon={literalsCommon}
                  match={match}
                  id={showUsersPopup}
                  onClose={() => setShowUsersPopup(null)}
                />
              )
            }
            { downloading && <IOFileLoading mode='download' file={{ name: filename }} /> }
          </PanelTab>
          <PanelTab key='documents'>
            <FolderContent
              literals={literalsDataroom}
              literalsCommon={literalsCommon}
              match={match}
              selectedFolder={{ id: report?.folder, name: report.subject }}
              editable={false}
            />
          </PanelTab>
          <PanelTab key='emails'>
            <EmailStats
              stats={report.stats}
              fetchTable={fetchEmails}
            />
          </PanelTab>
        </PanelBody>
      </Panel>
    ) : (
      <Loading mode='panel' hide={false} />
    )
  );
};

ReportInfo.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsDataroom: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default ReportInfo;
