import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadingAction } from '../../../../store/globalState/global';
import UsersComponents from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.adminUsers,
  literalsCommon: state.i18n.literals.common,
  literalsAccount: state.i18n.literals.editAccount,
  user: state.session.user,
});

const mapDispatchToProps = dispatch => ({
  showLoading: bindActionCreators(loadingAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersComponents);
