import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatNumber } from 'utils/functions';
import Popup from 'components/Popup';
import InputText from 'components/Inputs/inputText';
import ToolTab from 'components/Tool/components/ToolTab';
import Tool from 'components/Tool';
import Table from 'components/Table';
import Loading from 'components/Loading';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  getShareHolder, fetchCaptableShares, fetchCaptableDebts, fetchCaptableOptions,
} from '../modules/actions';

const PopupInfoShareholder = (props) => {
  const {
    literals,
    literalsCommon,
    currency,
    id,
    date,
    onClose,
  } = props;

  const PAGE_SIZE = 10;
  const [data, setData] = useState();
  const [captableShares, setCaptableShares] = useState();
  const [captableDebts, setCaptableDebts] = useState();
  const [captableOptions, setCaptableOptions] = useState();
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const params = useParams();

  const getData = async () => {
    const shareholder = await getShareHolder(params.id, id);
    setData(shareholder);
  };

  const getShares = async () => {
    const capShares = await fetchCaptableShares(params.id, date, { filters: { shareholder: id }, captable: captableId });
    setCaptableShares(capShares);
  };

  const getDebts = async () => {
    const capDebts = await fetchCaptableDebts(params.id, date, { filters: { shareholders: id }, captable: captableId });
    setCaptableDebts(capDebts);
  };

  const getOptions = async () => {
    const capOptions = await fetchCaptableOptions(params.id, date, { filters: { shareholder: id }, captable: captableId });
    setCaptableOptions(capOptions);
  };

  const getTabs = () => {
    let tabs = [
      { key: 'shares', name: literals.shares },
      { key: 'debts', name: literals.debts },
      { key: 'options', name: literals.options },
    ];
    if (captableShares.total === 0) {
      tabs = tabs.filter(tab => tab.key !== 'shares');
    }
    if (captableDebts.total === 0) {
      tabs = tabs.filter(tab => tab.key !== 'debts');
    }
    if (captableOptions.total === 0) {
      tabs = tabs.filter(tab => tab.key !== 'options');
    }
    return tabs;
  };

  useEffect(() => {
    getData();
    getShares();
    getDebts();
    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sharesColumns = [
    {
      field: 'originalOperation',
      label: literalsCommon.date,
      width: 130,
      preRender: v => v.date ?? '-',
    },
    {
      label: `${literals.shares}`,
      preRender: (f, r) => `${formatNumber(r.number, 0)}# ${r.shareClass.name}`,
    },
    {
      label: `${literals.capital} (${literals.pps})`,
      preRender: (f, r) => ` ${formatNumber(r.capital, 0, { dec: 2 })}${currency.symbol} (${formatNumber(r.pps, 0)}${currency.symbol})`,
    },
    {
      field: 'numeration',
      label: literals.numeration,
      preRender: v => `${formatNumber(v.from, 0)} - ${formatNumber(v.to, 0)}`,
    },
  ];

  const debtsColumns = [
    {
      type: 'html',
      label: literalsCommon.date,
      width: 130,
      preRender: (f, r) => `${r.startDate ?? '-'}<br/>${r.expirationDate ?? '-'}`,
    },
    {
      field: 'name',
      label: literalsCommon.name,
    },
    {
      type: 'html',
      label: literals.principal,
      preRender: (f, r) => `${literals.principal}: ${formatNumber(r.principal, 0, { dec: 2 })}${currency.symbol}</br>
        ${literals.repaid}: ${formatNumber(r.repaid, 0, { dec: 2 })}${currency.symbol}</br>
        ${literals.capitalized}: ${formatNumber(r.capitalized, 0, { dec: 2 })}${currency.symbol}`,
    },
    {
      field: 'interestRate',
      type: 'html',
      label: literals.interest,
      preRender: (f, r) => `${literals.interest}: ${formatNumber(f, 0, { dec: 2 })}%<br/>
        ${literals.accrualFrequency}: ${r.accrualFrequency}`,
    },
  ];

  const optionsColumns = [
    {
      field: 'name',
      type: 'html',
      label: literalsCommon.name,
      preRender: (f, r) => `${f} <br/> ${literals[r.type]}`,
    },
    {
      field: 'number',
      type: 'html',
      label: literals.number,
      preRender: (f, r) => `${formatNumber(f, 0)} (${formatNumber(r.price, 0)}${currency.symbol})<br/>
        ${literals.vested}: ${formatNumber(r.vested, 0)}<br/>
        ${r.type === 'stockOption' ? `${literals.exercised}: ${formatNumber(r.exercised, 0)}` : ''}`,
    },
    {
      field: 'capital',
      label: literals.capital,
      preRender: v => `${formatNumber(v, 0, { dec: 2 })}${currency.symbol}`,
    },
    {
      field: 'expirationDate',
      label: literals.expirationDate,
      preRender: v => v || '-',
    },
    {
      field: 'vestingPlan',
      label: literals.vestingPlan,
      preRender: v => v.name ?? '-',
    },
  ];

  const renderInfo = () => {
    return (
      <div className='box box-padding box-mb'>
        <div className='row'>
          <div className='col-md-6'>
            <InputText preText={literals.types} value={literals[data.type] || 'Individual'} isDisabled />
          </div>
          <div className='col-md-6'>
            <InputText preText={literals.role} value={literals[data.role] || ''} isDisabled />
          </div>
        </div>
        <div className='row'>
          <div className={`${data.type === 'individual' ? 'col-md-4' : 'col-md-12'}`}>
            <InputText name='firstname' preText={literals.name} value={data.firstname || ''} isDisabled />
          </div>
          { data.type === 'individual' && (
            <div className='col-md-8'>
              <InputText preText={literals.lastname} value={data.lastname || ''} isDisabled />
            </div>
          )}
        </div>
        <div className='row'>
          <div className='col-md-4'>
            <InputText preText={literals.phone} value={data.contactInfo ? data.contactInfo.phone : ''} isDisabled />
          </div>
          <div className='col-md-8'>
            <InputText preText={literalsCommon.email} value={data.contactInfo ? data.contactInfo.email : ''} isDisabled />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Popup title={literals.infoShareholder} onClose={onClose} size='large'>
      {data && captableShares && captableDebts && captableOptions ? (
        <>
          {renderInfo()}
          <div className='box box-padding box-mb'>
            <Tool tabs={getTabs()} tabsJustify='end' tabsStyle='panel'>
              <ToolTab key='shares'>
                <Table
                  searcher={false}
                  columns={sharesColumns}
                  fetch={() => ({ total: captableShares.total, items: captableShares.items })}
                  pageSize={PAGE_SIZE}
                />
              </ToolTab>
              <ToolTab key='debts'>
                <Table
                  searcher={false}
                  columns={debtsColumns}
                  fetch={() => ({ total: captableDebts.total, items: captableDebts.items })}
                  pageSize={PAGE_SIZE}
                />
              </ToolTab>
              <ToolTab key='options'>
                <Table
                  searcher={false}
                  columns={optionsColumns}
                  fetch={() => ({ total: captableOptions.total, items: captableOptions.items })}
                  pageSize={PAGE_SIZE}
                />
              </ToolTab>
            </Tool>
          </div>
        </>
      ) : (
        <Loading hide={false} mode='panel' />
      )}
    </Popup>
  );
};

const mapStateToProps = state => ({
  literals: state.i18n.literals.shareholder,
  literalsCommon: state.i18n.literals.common,
  currency: state.global.currency,
});

PopupInfoShareholder.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  date: PropTypes.string,
};

PopupInfoShareholder.defaultProps = {
  date: new Date().toISOString().split('T')[0],
};

export default connect(mapStateToProps)(PopupInfoShareholder);
