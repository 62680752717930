import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const InputText = ({
  className = '',
  preText = '',
  postText = '',
  placeholder = '',
  isRequired = false,
  isDisabled = false,
  value: propValue = '',
  maxLength = -1,
  error = null,
  onChange,
  onBlur,
  onKeyPress,
  name,
  inputRef,
  leftIcon,
  state = false,
}) => {
  const [localValue, setLocalValue] = useState(state ? propValue : '');

  const changeValue = (e) => {
    if (typeof onChange === 'function') {
      onChange(e.target.value, e);
    }
    if (state) {
      setLocalValue(e.target.value);
    }
  };

  const handleOnBlur = (e) => {
    if (typeof onBlur === 'function') {
      onBlur(e.target.value, e);
    }
  };

  const value = state ? localValue : propValue;

  return (
    <div className={`input_text ${className}`}>
      <div className={`pre_text ${error ? 'text_error' : ''}`}>
        {preText}
        {isRequired && <span className='is_required'>*</span>}
      </div>
      <div className={`input-container ${leftIcon ? 'left-icon' : ''}`}>
        { leftIcon && (
          <div className='left-icon'>
            {React.isValidElement(leftIcon) ? leftIcon : <img src={leftIcon} alt='' />}
          </div>
        )}
        <input
          className={`input ${error ? 'input_error' : ''}`}
          type='text'
          placeholder={placeholder}
          disabled={isDisabled}
          maxLength={maxLength > 0 ? maxLength : 500}
          value={value}
          onChange={changeValue}
          onBlur={handleOnBlur}
          onKeyDown={onKeyPress}
          name={name}
          ref={ref => inputRef && inputRef(ref)}
        />
      </div>
      { postText && (<div className='post_text' dangerouslySetInnerHTML={{ __html: postText }} />) }
    </div>
  );
};

InputText.propTypes = {
  className: PropTypes.string,
  preText: PropTypes.string,
  postText: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  error: PropTypes.object,
  name: PropTypes.string,
  inputRef: PropTypes.func,
  leftIcon: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  state: PropTypes.bool,
};

export default InputText;
