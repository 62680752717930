
import AccountsInstance from 'modules/accounts/repository';
import initialState from '../initialState';

const FETCH_NOTIFICATIONS_INIT = 'FETCH_NOTIFICATIONS_INIT';
const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';
const READ_NOTIFICATION_INIT = 'READ_NOTIFICATION_INIT';
const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS';
const READ_NOTIFICATION_FAILURE = 'READ_NOTIFICATION_FAILURE';
const UNREAD_NOTIFICATION_INIT = 'UNREAD_NOTIFICATION_INIT';
const UNREAD_NOTIFICATION_SUCCESS = 'UNREAD_NOTIFICATION_SUCCESS';
const UNREAD_NOTIFICATION_FAILURE = 'UNREAD_NOTIFICATION_FAILURE';
const DELETE_NOTIFICATION_INIT = 'DELETE_NOTIFICATION_INIT';
const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE';
const ADD_NOTIFICATIONS_SUCCESS = 'ADD_NOTIFICATIONS_SUCCESS';

export default function notificationsReducer(state = initialState.notifications, action) {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_INIT:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        ...action.payload,
      };
    case FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case READ_NOTIFICATION_SUCCESS: {
      const newItems = [...state.items];
      newItems.forEach((item, i) => {
        if (item.id === action.payload) {
          newItems[i].read = new Date().getTime();
        }
      });

      return {
        ...state,
        unread: state.unread - 1,
        items: newItems,
      };
    }
    case UNREAD_NOTIFICATION_SUCCESS: {
      const newItems = [...state.items];

      newItems.forEach((item, i) => {
        if (item.id === action.payload) {
          newItems[i].read = null;
        }
      });

      return {
        ...state,
        unread: state.unread + 1,
        items: newItems,
      };
    }
    case DELETE_NOTIFICATION_SUCCESS: {
      const newItems = [...state.items];

      let wasUnread = false;
      newItems.forEach((item, i) => {
        if (item.id === action.payload) {
          if (!item.read) {
            wasUnread = true;
          }
          newItems.splice(i, 1);
        }
      });
      return {
        ...state,
        unread: state.unread - (wasUnread ? 1 : 0),
        items: newItems,
      };
    }
    case ADD_NOTIFICATIONS_SUCCESS: {
      const newItems = [
        action.payload,
        ...state.items.filter(item => item.id !== action.payload.id),
      ];

      return {
        ...state,
        unread: newItems.reduce((acc, item) => { return acc + (item.read ? 0 : 1); }, 0),
        items: newItems,
      };
    }
    default:
      return state;
  }
}

export function fetchNotificationsInit() {
  return {
    type: FETCH_NOTIFICATIONS_INIT,
  };
}

function fetchNotificationsSuccess(payload) {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload,
  };
}

function fetchNotificationsFailure(error) {
  return {
    type: FETCH_NOTIFICATIONS_FAILURE,
    payload: error,
  };
}

const readNotificationInit = () => ({
  type: READ_NOTIFICATION_INIT,
});

const readNotificationSuccess = payload => ({
  type: READ_NOTIFICATION_SUCCESS,
  payload,
});

const readNotificationFailure = payload => ({
  type: READ_NOTIFICATION_FAILURE,
  payload,
});

const unreadNotificationInit = () => ({
  type: UNREAD_NOTIFICATION_INIT,
});

const unreadNotificationSuccess = payload => ({
  type: UNREAD_NOTIFICATION_SUCCESS,
  payload,
});

const unreadNotificationFailure = payload => ({
  type: UNREAD_NOTIFICATION_FAILURE,
  payload,
});

const deleteNotificationInit = () => ({
  type: DELETE_NOTIFICATION_INIT,
});

const deleteNotificationSuccess = payload => ({
  type: DELETE_NOTIFICATION_SUCCESS,
  payload,
});

const deleteNotificationFailure = payload => ({
  type: DELETE_NOTIFICATION_FAILURE,
  payload,
});

const addNotificationsSuccess = payload => ({
  type: ADD_NOTIFICATIONS_SUCCESS,
  payload,
});


export function fetchNotificationsAction(page = 0, size = 100) {
  return (dispatch) => {
    dispatch(fetchNotificationsInit());

    return AccountsInstance.getNotifications({ page, size })
      .then((response) => {
        dispatch(fetchNotificationsSuccess(response));
      })
      .catch((error) => {
        console.error(error);
        dispatch(fetchNotificationsFailure(error.message));
      });
  };
}

export function readNotificationAction(id) {
  return (dispatch) => {
    dispatch(readNotificationInit());
    dispatch(readNotificationSuccess(id));

    return AccountsInstance.readNotification(id)
      .then(() => {})
      .catch((error) => {
        console.error(error);
        dispatch(readNotificationFailure(error.message));
      });
  };
}

export function unreadNotificationAction(id) {
  return (dispatch) => {
    dispatch(unreadNotificationInit());
    dispatch(unreadNotificationSuccess(id));

    return AccountsInstance.unreadNotification(id)
      .then(() => {})
      .catch((error) => {
        console.error(error);
        dispatch(unreadNotificationFailure(error.message));
      });
  };
}

export function deleteNotificationAction(id) {
  return (dispatch) => {
    dispatch(deleteNotificationInit());
    dispatch(deleteNotificationSuccess(id));

    return AccountsInstance.deleteNotification(id)
      .then(() => {})
      .catch((error) => {
        console.error(error);
        dispatch(deleteNotificationFailure(error.message));
      });
  };
}
export function addNotificationsAction(notification) {
  return (dispatch) => {
    dispatch(addNotificationsSuccess(notification));
  };
}
