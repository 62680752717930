import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import InputText from 'components/Inputs/inputText';
import InputDate from 'components/Inputs/inputDate';
import InputNumber from 'components/Inputs/inputNumber';
import InputRichText from 'components/Inputs/inputRichText';
import InputFile from 'components/Inputs/inputFile';
import OutputValue from 'components/Inputs/outputValue';
import Separator from 'components/Separator';

const FormStockSplit = (props) => {
  const {
    view,
    editable,
    data,
    date,
    literals,
    literalsCommon,
    shares,
    assignedOptions,
    unassignedOptions,
    captable,
    onConfirm,
    onDelete,
  } = props;

  const [id] = useState(data?.id ?? `new.${new Date().getTime()}`);
  const [name, setName] = useState(data?.name);
  const [multiple, setMultiple] = useState(data.multiple ?? 2);
  const [inputDate, setInputDate] = useState(date);
  const [notes, setNotes] = useState(data?.notes);
  const [documents, setDocuments] = useState([]);

  const handleConfirm = () => {
    onConfirm({
      operationType: 'stockSplit',
      id,
      multiple,
      name,
      date: inputDate,
      notes,
      documents,
    });
  };

  const currentShares = shares.reduce((acc, share) => acc + Math.round(share.number), 0);
  const newShares = shares.reduce((acc, share) => acc + Math.round(share.number * multiple), 0);
  const currentAssignedOptions = assignedOptions.reduce((acc, option) => acc + Math.round(option.number), 0);
  const newAssignedOptions = assignedOptions.reduce((acc, option) => acc + Math.round(option.number * multiple), 0);
  const currentUnassignedOptions = unassignedOptions.reduce((acc, option) => acc + Math.round(option.number), 0);
  const newUnassignedOptions = unassignedOptions.reduce((acc, option) => acc + Math.round(option.number * multiple), 0);

  return (
    <>
      <div className='operation-form-shares'>
        <div className='mb-lb'>
          {view === 'shares' && (
            <>
              <div className='row'>
                <div className='col-12 col-md-8'>
                  <InputText
                    className='m-0'
                    preText={literals.operationName}
                    placeholder={literals.operationName}
                    value={name}
                    onChange={v => setName(v)}
                    isDisabled={!editable}
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <InputDate
                    className='m-0'
                    preText={literalsCommon.date}
                    value={inputDate}
                    onChange={v => setInputDate(v)}
                    isDisabled={!editable}
                    min={captable?.operations?.length ? captable?.operations[0].date : null}
                  />
                </div>
              </div>
              <Separator />
            </>
          )}
          <div className='row'>
            <div className='col-12 col-md-4'>
              <InputNumber
                preText={literals.multiple}
                value={multiple}
                onChange={v => setMultiple(v)}
                isDisabled={!editable}
                symbol='X'
              />
            </div>
            <div className='col-12 col-md-4'>
              <OutputValue
                preText={literals.nCurrentShares}
                value={currentShares}
              />
            </div>

            <div className='col-12 col-md-4'>
              <OutputValue
                preText={literals.nNewShares}
                value={newShares}
              />
            </div>

          </div>
          <div className='row'>
            <div className='col-12 col-md-4 offset-md-4'>
              <OutputValue
                preText={literals.nCurrentOptions}
                value={currentAssignedOptions}
              />
            </div>
            <div className='col-12 col-md-4'>
              <OutputValue
                preText={literals.nNewOptions}
                value={newAssignedOptions}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-4 offset-md-4'>
              <OutputValue
                preText={literals.nCurrentUnassignedOptions}
                value={currentUnassignedOptions}
              />
            </div>
            <div className='col-12 col-md-4'>
              <OutputValue
                preText={literals.nNewUnassignedOptions}
                value={newUnassignedOptions}
              />
            </div>
          </div>
          {view === 'shares' && (
            <>
              <Separator />
              <div className='row'>
                <div className='col-12 '>
                  <InputRichText
                    minHeight='120px'
                    preText={literals.additionalDocumentation}
                    value={notes}
                    onChange={v => setNotes(v)}
                    isDisabled={!editable}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <InputFile
                    preText={literalsCommon.documents}
                    value={documents}
                    isPublic={false}
                    onChange={v => setDocuments(v)}
                    multiple
                    isDisabled={!editable}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {
          editable && (
            <div className='buttons'>
              <Button text={literalsCommon.confirm} onClick={handleConfirm} />
              {
                data?.id && (
                  <Button text={literalsCommon.delete} color='danger' onClick={() => onDelete(data.id)} />
                )
              }
            </div>
          )
        }
      </div>
    </>
  );
};

FormStockSplit.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  view: PropTypes.string,
  captable: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  data: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  shares: PropTypes.array,
  assignedOptions: PropTypes.array,
  unassignedOptions: PropTypes.array,
};

FormStockSplit.defaultProps = {
  view: 'operation',
  editable: true,
  onDelete: () => { },
  shares: null,
  assignedOptions: null,
  unassignedOptions: null,
};

export default FormStockSplit;
