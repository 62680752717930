import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Panel from 'components/Panel/components/';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Table from 'components/Table';
import useDialog from 'components/Dialog/components/useDialog';
import StartupCaptableDate from 'components/StartupCaptableDate/components';
import IOFileLoading from 'components/IOFileLoading/components';
import {
  PersonPlus, Pencil, Trash, Eye, Check, Dash, Cash, People, ListOl, ArrowLeftRight, Briefcase, CloudDownload,
} from 'react-bootstrap-icons';
import { useCounter } from 'utils/customHooks';
import { formatDate } from 'utils/functions';
import { Navigate, useSearchParams } from 'react-router-dom';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import { PERMISSION_LEVELS, PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import PermissionLabel from 'components/PermissionsPopup/PermissionLabel';
import PopupFormShareClasses from './PopupFormShareClass';
import PopupInfoShareClasses from './PopupInfoShareClass';
import PopupUnifyShareClasses from './PopupUnifyShareClasses';
import {
  fetchShareClasses, deleteShareClass, fetchCaptableShares, downloadExcelShareClasses,
} from '../modules/actions';

const ShareClasses = (props) => {
  const {
    literals,
    literalsCommon,
    literalsShareClass,
    match,
    startup,
    captable,
  } = props;

  const tableRefreshFlag = useCounter(0);
  const [elemSelected, setElemSelected] = useState(null);
  const [showDataRow, setShowDataRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [unifyPopup, setUnifyPopup] = useState(false);
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const { dialog } = useDialog();

  const getShareClasses = (page, size, search, filters, sort) => {
    return fetchShareClasses(match.params.id, page, size, { search, filters, sort });
  };

  const deleteClass = async (shareClass) => {
    await dialog({
      type: 'loading',
      execute: async () => {
        const capShares = await fetchCaptableShares(
          match.params.id,
          formatDate(new Date(), { format: 'Y-m-d' }),
          { filters: { shareClass }, captable: captableId },
        );
        if (capShares.total > 0) {
          await dialog({
            type: 'error',
            text: literals.deleteClassError,
          });
        } else {
          const confirm = await dialog({
            text: literals.deleteClass,
            type: 'confirm',
          });
          if (confirm) {
            await dialog({
              type: 'loading',
              execute: async () => {
                await deleteShareClass(match.params.id, shareClass);
                tableRefreshFlag.increase();
              },
            });
          }
        }
      },
    });
  };


  const handleDownloadExcel = async () => {
    setDownloadingExcel(true);
    await downloadExcelShareClasses(startup.id);
    setDownloadingExcel(false);
  };

  const columns = [
    {
      field: 'name',
      label: literals.name,
      grow: 4,
      sortable: true,
      preRender: (v, row) => (
        <>
          {v}
          <div className='cell-keywords ml-1'>
            <div key={`code-${row.id}`}>{row.code || '-'}</div>
          </div>
          <PermissionLabel permission={row.permission} />
        </>
      ),
    },
    {
      field: 'type',
      label: literals.type,
    },
    {
      field: 'vote',
      label: literalsShareClass.vote,
      icon: Briefcase,
      preRender: v => (v?.enabled ? <Check width={18} /> : <Dash width={18} />),
      width: 30,
    },
    {
      field: 'liquidity',
      label: literalsShareClass.liquidity,
      icon: ListOl,
      preRender: v => (v?.enabled ? <Check width={18} /> : <Dash width={18} />),
      width: 30,
    },
    {
      field: 'participation',
      label: literalsShareClass.participation,
      icon: People,
      preRender: v => (v?.enabled ? <Check width={18} /> : <Dash width={18} />),
      width: 30,
    },
    {
      field: 'dividend',
      label: literalsShareClass.dividend,
      icon: Cash,
      preRender: v => (v?.enabled ? <Check width={18} /> : <Dash width={18} />),
      width: 30,
    },
    {
      field: 'convert',
      label: literalsShareClass.convert,
      icon: ArrowLeftRight,
      preRender: v => (v?.enabled ? <Check width={18} /> : <Dash width={18} />),
      width: 30,
    },
    {
      id: 'created_at',
      field: 'createdAt',
      label: literalsCommon.createdAt,
      preRender: elem => formatDate(elem),
      width: 100,
      sortable: true,
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Eye,
          text: literalsCommon.view,
          onClick: shareClass => setShowDataRow(shareClass.id),
        },
        {
          icon: Pencil,
          text: literalsCommon.modify,
          onClick: shareClass => setElemSelected(shareClass),
          hidden: (_, row) => row.permission.level < PERMISSION_LEVELS.EDIT,
        },
        {
          type: 'permission',
          scope: { type: PERMISSION_SCOPES.STARTUP, id: startup.id },
          element: PERMISSION_TYPES.SHARECLASS,
        },
        {
          icon: Trash,
          text: literalsCommon.delete,
          onClick: shareClass => deleteClass(shareClass.id),
          hidden: (_, row) => row.permission.level < PERMISSION_LEVELS.EDIT,
        },
      ],
    },
  ];

  const onSubmit = () => {
    tableRefreshFlag.increase();
  };

  if (startup.role === 'shareholder') {
    return <Navigate to={embedView(ROUTE_STARTUP_PATH.setCapTable(match.params.id, captableId))} />;
  }

  const tableMassiveActions = [
    { icon: People, text: literals.unifyShareClasses, onClick: () => setUnifyPopup(selectedRows) },
  ];

  return (
    <Tool>
      <Panel>
        <PanelHeader title={literals.shareClasses} />
        <PanelBody>
          <StartupCaptableDate startup={startup} captable={captable} noDate />
          <Table
            columns={columns}
            fetch={getShareClasses}
            pageSize={10}
            forceFetch={tableRefreshFlag.value}
            actions={[
              { icon: PersonPlus, text: literals.createClass, onClick: () => setElemSelected({}) },
              { icon: CloudDownload, text: literals.downloadExcel, onClick: () => handleDownloadExcel(true) },
            ]}
            onClickRow={row => setShowDataRow(row.id)}
            selectRows={selectedRows}
            onSelectRows={setSelectedRows}
            selectFields={['id', 'name', 'type', 'createdAt']}
            rowSelection={tableMassiveActions}
          />
          {showDataRow && <PopupInfoShareClasses id={showDataRow} onClose={() => setShowDataRow(null)} />}
          {elemSelected && <PopupFormShareClasses shareClass={elemSelected} onClose={() => setElemSelected(false)} onSubmit={onSubmit} />}
          {unifyPopup && (
            <PopupUnifyShareClasses
              literals={literals}
              literalsCommon={literalsCommon}
              startup={match.params.id}
              shareClasses={selectedRows}
              onSubmit={() => {
                setSelectedRows([]);
                tableRefreshFlag.increase();
              }}
              onClose={() => setUnifyPopup(false)}
            />
          )}
          { downloadingExcel && <IOFileLoading mode='download' file={{ name: 'shareClasses.xlsx' }} /> }
        </PanelBody>
      </Panel>
    </Tool>
  );
};

ShareClasses.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  literalsShareClass: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
};

export default ShareClasses;
