import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelBody from 'components/Panel/components/PanelBody';
import Avatar from 'components/Avatar';
import { FILES_BASE_URL } from 'constants/apiConf';
import { getStartupVerifiedIcon, getTranslation } from 'utils/functions';
import './styles.scss';

function ProfileCard({ data, lang, button = null }) {
  let profileExtra = data.extra ?? {};
  profileExtra = Object.keys(data.extra).map((key) => {
    return {
      id: key,
      ...data.extra[key],
    };
  });

  return (
    <Panel>
      <PanelBody noHeader>
        <div className='profile-card-info'>
          <Avatar
            title={data.title}
            src={data.avatar?.path}
            size={120}
            className='profile-card-avatar'
          />
          <div className='profile-card-body simple-scrollbar'>
            <h5 className='profile-card-title fw-b'>
              {data.title}
              {getStartupVerifiedIcon(data.verified)}
            </h5>
            <p className='profile-card-description'>{data.description.length >= 150 ? `${data.description.substring(0, 147)}...` : data.description}</p>
            <div className='profile-card-details'>
              {
                profileExtra.map((question) => {
                  if (question.value) {
                    let value = '';
                    let isDocument = false;
                    if (Array.isArray(question.value)) {
                      value = [];
                      question.value.forEach((answer) => {
                        value.push(getTranslation(answer, lang));
                      });
                      value = value.join(', ');
                    } else if (typeof question.value === 'object') {
                      if (question.value?.path) {
                        isDocument = true;
                        value = question.value;
                      } else {
                        value = getTranslation(question.value, lang);
                      }
                    } else {
                      value = question.value;
                    }
                    if (typeof value === 'string' && value.length >= 100) {
                      value = `${value.substring(0, 97)}...`;
                    }
                    return (
                      <div key={question.id}>
                        <h6 className='fw-b profile-card-details-title'>{getTranslation(question.title, lang)}</h6>
                        <p className='profile-card-details-description'>
                          {
                            isDocument ? (
                              <a href={FILES_BASE_URL + value.path} target='_blank' rel='noopener noreferrer'>{value.name}</a>
                            ) : (value)
                          }
                        </p>
                      </div>
                    );
                  }
                  return null;
                })
              }
            </div>
          </div>
          {button && <div className='buttons'>{button}</div>}
        </div>
      </PanelBody>
    </Panel>
  );
}

ProfileCard.propTypes = {
  data: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  button: PropTypes.any,
};

export default ProfileCard;
