import React from 'react';
import PropTypes from 'prop-types';
import { ROUTE_ADMIN_PATH } from 'routes';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loadingAction } from 'store/globalState/global';
import { stopImpersonate } from '../modules/actions';
import './styles.scss';

const ImpersonateBar = ({
  literals, literalsCommon, user,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleStop = async () => {
    await dispatch(loadingAction(true));
    await stopImpersonate();
    await dispatch(loadingAction(false));
    navigate(ROUTE_ADMIN_PATH.USERS, { replace: true });
  };

  return (
    <div className='impersonate-bar'>
      <span>{literals.youAreImpersonating}</span>
      <span className='impersonate-bar--email pl-1'>{`(${user.email})`}</span>
      <div
        className='impersonate-bar--button'
        onClick={handleStop}
      >
        {literalsCommon.stop}
      </div>
    </div>
  );
};

ImpersonateBar.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default ImpersonateBar;
