/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Button from 'components/Buttons/Button';
import InputText from 'components/Inputs/inputText';
import InputRichText from 'components/Inputs/inputRichText';
import useDialog from 'components/Dialog/components/useDialog';
import InputPermission from 'components/Inputs/InputPermission';
import { initPermission } from 'utils/functions/initPermissions';
import { PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import { TOOL_DATA_ROOM } from 'constants/tools';
import OutputErrors from 'components/Inputs/outputErrors';
import { formatErrors } from 'utils/functions';
import { editDocument, editLink } from '../modules/actions';

const PopupEditFile = (props) => {
  const {
    literals,
    literalsCommon,
    startup,
    content,
    setContent,
    onClose,
  } = props;

  const isFile = content._type === 'document';

  const nameSplit = content.name.split('.');
  const extension = `.${nameSplit.pop()}`;
  const name = nameSplit.join('.');

  const [title, setTitle] = useState(isFile ? name : content.name);
  const [description, setDescription] = useState(content.description);
  const [link, setLink] = useState(content.path);
  const [loading, setLoading] = useState(false);
  const { dialog } = useDialog();

  const [permission, setPermission] = useState(initPermission(
    content,
    PERMISSION_SCOPES.STARTUP,
    startup,
    TOOL_DATA_ROOM,
  ));

  const handleError = async (error) => {
    await dialog({
      type: 'error',
      text: <OutputErrors errors={formatErrors(error)} text />,
    });
  };

  const handleEdit = async () => {
    setLoading(true);
    try {
      let editedFile = false;
      if (content._type === 'document') {
        editedFile = await editDocument(content.id, {
          file: null,
          folder: content.parent,
          name: title + extension,
          description,
          return: 'object',
          permission: JSON.stringify(permission),
        });
      } else {
        editedFile = await editLink(content.id, {
          name: title,
          description,
          path: link.trim(),
          folder: content._folder.id,
          permission,
        });
      }
      setContent(prev => ({
        items: prev.items.map(cont => (cont.id === content.id
          ? editedFile
          : cont
        )),
        total: prev.total,
      }));
      onClose();
    } catch (error) {
      await handleError(error);
      setLoading(false);
    }
  };

  return (
    <Popup
      title={`${literalsCommon.edit} ${isFile ? literals.file : literals.link}`}
      onClose={onClose}
    >
      <div className='row'>
        <div className='col-12'>
          <InputText
            preText={literals.title}
            placeholder={literals.title}
            onChange={v => setTitle(v)}
            value={title}
            isRequired
          />
          {isFile && <span className='ext'>{extension}</span>}
        </div>
      </div>
      {!isFile && (
        <InputText
          preText={literals.link}
          placeholder={literals.link}
          onChange={v => setLink(v)}
          value={link}
          isRequired
        />
      )}
      <InputPermission
        scope={{ type: PERMISSION_SCOPES.STARTUP, id: startup.id }}
        element={{ type: PERMISSION_TYPES.DOCUMENT, id: content?.id }}
        value={permission}
        onChange={setPermission}
      />
      <InputRichText
        preText={literals.description}
        placeholder={literals.description}
        onChange={v => setDescription(v)}
        value={description}
        minHeight='120px'
      />

      <div className='d-flex justify-content-end'>
        <Button
          onClick={handleEdit}
          text={literalsCommon.edit}
          disabled={!title}
          loading={loading}
        />
      </div>
    </Popup>
  );
};

PopupEditFile.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  setContent: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupEditFile;
