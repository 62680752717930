/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-did-update-set-state */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const InputEmail = ({
  preText = '',
  postText = '',
  placeholder = '',
  isRequired = false,
  isDisabled = false,
  value = '',
  maxLength = -1,
  onChange,
  onKeyDown = null,
  errors = [],
  name = null,
  className = '',
}) => {
  const [text, setText] = useState('');

  useEffect(() => {
    setText(value);
  }, [value]);

  const changeValue = (e) => {
    setText(e.target.value);
    if (onChange) onChange(e.target.value, e);
  };

  return (
    <div className={`input_text ${className}`}>
      {preText && (
        <div className='pre_text'>
          {preText}
          {isRequired && <span className='is_required'>*</span>}
        </div>
      )}
      <input
        className={`input ${errors.length > 0 ? 'input_error' : ''}`}
        type='email'
        placeholder={placeholder}
        disabled={isDisabled}
        maxLength={maxLength > 0 ? maxLength : 500}
        value={text}
        onChange={changeValue}
        onKeyDown={onKeyDown ? (e => onKeyDown(e)) : null}
        name={name}
      />
      { postText && (<div className='post_text' dangerouslySetInnerHTML={{ __html: postText }} />) }
      <div className='errors'>
        {errors.map((err, index) => (
          <span key={index}>
            {typeof err === 'object' ? err.message : err}
            <br />
          </span>
        ))}
      </div>
    </div>
  );
};

InputEmail.propTypes = {
  preText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  postText: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  errors: PropTypes.array,
  name: PropTypes.string,
  className: PropTypes.string,
};

export default InputEmail;
