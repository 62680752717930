import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initPlatformAction } from 'store/globalState/global';
import SupportsComponents from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.admin.support,
  lang: state.i18n.language,
  LANGS: state.global.platform.langs,
});

function mapDispatchToProps(dispatch) {
  return {
    initPlatform: bindActionCreators(initPlatformAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportsComponents);
