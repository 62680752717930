/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import InputNumber from 'components/Inputs/inputNumber';
import OutputValue from 'components/Inputs/outputValue';
import { formatNumber } from 'utils/functions';

const FormReduceTable = ({
  literals,
  currency,
  shareClassShares = null,
  reduceShares,
  setReduceShares,
  editable,
}) => {
  const handleReduceShare = (from, shareholder, shares) => {
    const aux = { ...reduceShares };
    if (shares > 0) {
      aux[from] = { from, shareholder, shares };
    } else {
      delete aux[from];
    }
    setReduceShares(aux);
  };

  if (!shareClassShares) {
    return <Loading mode='panel' hide={false} />;
  }

  return (
    <>
      <div className='shares-assigments my-4'>
        <div className='shares-assigments-table'>
          <div className='sh-header reduce-row'>
            <div className='cell-sh'><span>{literals.shareholder}</span></div>
            <div><span>{literals.shares}</span></div>
            <div><span>{literals.pps}</span></div>
            <div><span>{literals.numeration}</span></div>
            <div><span>{literals.reduce}</span></div>
          </div>

          <ul>
            {
              shareClassShares.items.map(({
                id, shareholder, number, pps, numeration,
              }) => (
                <li key={id} className='li-shareholder reduce-row'>
                  <div className='cell-sh'>
                    <OutputValue className='mb-0' value={shareholder.name} />
                  </div>
                  <div>
                    <OutputValue className='mb-0' value={formatNumber(number, 0)} />
                  </div>
                  <div>
                    <OutputValue className='mb-0' value={formatNumber(pps, 0, { symbol: currency.symbol })} />
                  </div>
                  <div>
                    <OutputValue className='mb-0' value={`${formatNumber(numeration?.from, 0)} - ${formatNumber(numeration?.to, 0)}`} />
                  </div>
                  <div>
                    <InputNumber
                      className='mb-0'
                      minValue={0}
                      maxValue={number}
                      value={reduceShares?.[numeration?.from]?.shares || 0}
                      onChange={v => handleReduceShare(numeration?.from, shareholder.id, v)}
                      isDisabled={!editable}
                      decimals={2}
                    />
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </>
  );
};

FormReduceTable.propTypes = {
  literals: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  shareClassShares: PropTypes.object,
  reduceShares: PropTypes.object.isRequired,
  setReduceShares: PropTypes.func.isRequired,
};

export default FormReduceTable;
