import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const NotesInstance = ApiFetch();

export const URLS = {
  NOTES: 'notes',
  NOTE: 'notes/:type/:id',
};

async function deleteNote(type, id) {
  const path = preparePath(URLS.NOTE, { type, id });
  return NotesInstance.delete(path);
}

async function getNote(type, id) {
  const path = preparePath(URLS.NOTE, { type, id });
  return NotesInstance.get(path);
}

async function postNote(type, id, data) {
  const path = preparePath(URLS.NOTE, { type, id });
  return NotesInstance.post(path, data);
}

export default {
  deleteNote,
  getNote,
  postNote,
};
