import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadingAction } from '../../../store/globalState/global';
import OnboardingComponents from '../components';
import { fetchCompanyAction } from '../../Home/modules/actions';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.onboarding,
    literalsCommon: state.i18n.literals.common,
    user: state.session.user,
    lang: state.i18n.language,
    startup: state.company,
    platform: state.global.platform,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showLoading: bindActionCreators(loadingAction, dispatch),
    fetchCompany: bindActionCreators(fetchCompanyAction, dispatch),
    fetchFund: bindActionCreators(fetchCompanyAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingComponents);
