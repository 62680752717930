import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import InternalForm from 'components/InternalForm';
import useDialog from 'components/Dialog/components/useDialog';
import { Pencil, PlusCircle, Trash } from 'lucide-react';
import { createFund, deleteFund, editFund } from '../modules/actions';

const FundPopup = (props) => {
  const {
    literals,
    literalsCommon,
    fund,
    onSubmit,
    onClose,
  } = props;

  const [loading, setLoading] = useState(false);
  const { dialog } = useDialog();

  const handleCreateFund = async (fundForm) => {
    setLoading(true);
    try {
      if (fund.id) {
        await editFund(fund.id, fundForm);
      } else {
        await createFund(fundForm);
      }
      onSubmit();
      onClose();
    } catch {
      setLoading(false);
    }
  };

  const handleDeleteFund = async () => {
    const confirm = await dialog({
      type: 'confirmDanger', text: literals.confirmDeleteFund,
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteFund(fund.id);
          onSubmit();
          onClose();
        },
      });
    }
  };

  const buttons = [{
    icon: fund.id ? Pencil : PlusCircle,
    text: fund.id ? literalsCommon.edit : literalsCommon.create,
    type: 'submit',
    onClick: handleCreateFund,
    loading,
  }];

  if (fund.id) {
    buttons.unshift({
      color: 'danger',
      icon: Trash,
      text: literalsCommon.delete,
      onClick: handleDeleteFund,
      loading,
    });
  }

  return (
    <Popup onClose={onClose}>
      <InternalForm
        type='fund'
        answers={fund}
        buttons={buttons}
      />
    </Popup>
  );
};

FundPopup.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  fund: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FundPopup;
