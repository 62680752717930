/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import {
  AlertOctagon, Eye, Pencil, Trash,
} from 'lucide-react';
import { formatNumber, isEmptyObject } from 'utils/functions';

const OperationRow = (props) => {
  const {
    type,
    item,
    index,
    exist,
    onClick,
    onDelete,
    errors,
    captable,
    currency,
    literals,
    literalsCommon,
    convertibles,
    unassigned,
    assigned,
    editable,
  } = props;

  const TYPE_OBJECT = ['shares', 'options'].includes(type);

  const formatErrors = () => {
    let auxErrors = [];
    if (!isEmptyObject(errors)) {
      if (TYPE_OBJECT) {
        if (item.create?.id && errors[`${type}_create`] && errors[`${type}_create`][item.create.id]) {
          auxErrors = [...auxErrors, ...errors[`${type}_create`][item.create.id]];
        }
        item.assign.forEach((assign) => {
          if (errors[`${type}_assign`] && errors[`${type}_assign`][assign.id]) {
            auxErrors = [...auxErrors, ...errors[`${type}_assign`][assign.id]];
          }
        });
      } else if (errors[type] && errors[type][item.id]) {
        auxErrors = [...auxErrors, ...errors[type][item.id]];
      }
    }
    if (!exist) {
      auxErrors = [{ code: 'invalid_source', message: literals.errors.invalid_source }];
    }

    return auxErrors;
  };

  const itemErrors = formatErrors(errors);

  const getShareholderName = (sid) => {
    if (sid) {
      const obj = captable.shareholders.find(sc => sc.id === sid);
      return obj ? obj.name : '-';
    }
    return '-';
  };

  const getShareClassName = (sid) => {
    if (sid) {
      const obj = captable.shareClasses.find(sc => sc.id === sid);
      return obj ? (obj.code || obj.name) : '-';
    }
    return '-';
  };

  const renderTitle = () => {
    switch (type) {
      case 'shares':
        return `${literals.issue.shares}: ${item.create?.number || 0} ${getShareClassName(item.create?.shareClass)}`;
      case 'debts':
        return `${literals.issue.debt}: ${formatNumber(item.principal, 0, { dec: 2, symbol: currency.symbol })}`;
      case 'options':
        if (item.old) {
          const oldOption = unassigned?.options?.filter(op => op.option === item.old)[0];
          return `${literals.assignPoolOptions}: ${oldOption?.name || '-'}`;
        }
        return `${literals.issue.options}: ${formatNumber(item.create?.number || 0)} ${item.create?.type || '-'}`;
      case 'payouts':
        return `${literals.issue.payout}: ${formatNumber(item.amount, 0, { dec: 2 })}${currency.symbol}`;
      case 'secondaries':
        return `${literals.issue.secondaries}: ${getShareholderName(item.shareholder)}`;
      case 'changeClasses':
        return `${literals.issue.changeClasses}: ${getShareholderName(item.shareholder)}`;
      case 'reduces':
        return `${literals.issue.reduce}: ${getShareClassName(item.shareClass)}`;
      case 'conversions':
        return `${item.relation.type === 'debt' ? literals.capitalizeDebt : literals.exerciseStock}: ${getShareholderName(item?.shareholder) || '-'}`;
      case 'vests':
        return `${literals.vestOptions}: ${getShareholderName(item?.shareholder) || '-'}`;
      case 'stockSplit':
        return `${literals.stockSplit}: ${item.multiple}X`;
      case 'valuation':
        return `${literals.valuation}: ${formatNumber(Number(item?.valuation), 0, { dec: 2, symbol: currency.symbol })}`;
      case 'dividend':
        return `${literals.issue.dividend}: ${formatNumber(item?.amount, 0, { symbol: currency.symbol })}`;
      default:
        return null;
    }
  };

  const renderShareInfo = () => {
    const { create: share } = item;

    const assignedTo = [];
    let totalAssigned = 0;
    item.assign.forEach((assign) => {
      const assignPercent = (assign.number * 100) / share.number;
      assignedTo.push(`${getShareholderName(assign.shareholder)} (${formatNumber(assignPercent)}%)`);
      totalAssigned += assignPercent;
    });

    return (
      <>
        <div>
          <h6>{literals.shareClass}</h6>
          <span className='fw-sb'>{getShareClassName(share.shareClass)}</span>
        </div>
        <div>
          <h6>{literals.number}</h6>
          <span className='fw-sb'>{formatNumber(share.number, '-')}</span>
        </div>
        <div>
          <h6>{literals.pps}</h6>
          <span className='fw-sb'>{formatNumber(share.pps, 0, { dec: 2, symbol: currency.symbol })}</span>
        </div>
        <div>
          <h6>{literalsCommon.total}</h6>
          <span className='fw-sb'>{formatNumber(share.pps * share.number, 0, { dec: 2, symbol: currency.symbol })}</span>
        </div>
        <div>
          <h6>{literals.numeration}</h6>
          <span className='fw-sb'>
            {share.numeration?.from
              ? `${formatNumber(share.numeration?.from)} - ${formatNumber(share.numeration?.to, '---')}`
              : 'Auto'
            }
          </span>
        </div>
        <div title={assignedTo.join('; ')}>
          <h6>{literals.shareholders}</h6>
          <span className='fw-sb'>{item.assign.length}</span>
        </div>
        <div>
          <h6>{literals.assigned}</h6>
          <span className='fw-sb'>{formatNumber(totalAssigned, 0, { dec: 2, symbol: '%' })}</span>
        </div>
      </>
    );
  };

  const renderDebtsInfo = () => {
    return (
      <>
        <div>
          <h6>{literals.shareholder}</h6>
          <span className='fw-sb'>{getShareholderName(item.shareholder)}</span>
        </div>
        <div>
          <h6>{literalsCommon.name}</h6>
          <span className='fw-sb'>{item.name || '-'}</span>
        </div>
        <div>
          <h6>{literals.principal}</h6>
          <span className='fw-sb'>{formatNumber(item.principal, 0, { dec: 2, symbol: currency.symbol })}</span>
        </div>
        <div>
          <h6>{literals.interestRate}</h6>
          <span className='fw-sb'>{formatNumber(item.interestRate, 0, { dec: 2, symbol: '%' })}</span>
        </div>
        <div>
          <h6>{literalsCommon.date}</h6>
          <span className='fw-sb'>{`${item.startDate || '---'} - ${item.expirationDate || '---'}`}</span>
        </div>
      </>
    );
  };

  const renderOptionsInfo = () => {
    const option = item.create || unassigned?.options?.filter(op => op.option === item.old)[0];
    const assignedTo = [];
    let totalAssigned = 0;
    item.assign.forEach((assign) => {
      const assignPercent = (assign.number * 100) / option?.number;
      assignedTo.push(`${getShareholderName(assign.shareholder)} (${formatNumber(assignPercent)}%)`);
      totalAssigned += assignPercent;
    });

    return (
      <>
        <div>
          <h6>{literals.pool}</h6>
          <span className='fw-sb'>{(item.create ? literalsCommon.new : option?.name) || '-'}</span>
        </div>
        <div>
          <h6>{literalsCommon.type}</h6>
          <span className='fw-sb'>{option?.type ? literals[option?.type] : '-'}</span>
        </div>
        <div>
          <h6>{literalsCommon.name}</h6>
          <span className='fw-sb'>{option?.name || '-'}</span>
        </div>
        <div>
          <h6>{literals.number}</h6>
          <span className='fw-sb'>{formatNumber(option?.number, '-')}</span>
        </div>
        <div>
          <h6>{literals.price}</h6>
          <span className='fw-sb'>{formatNumber(option?.price, '-', { dec: 2, symbol: currency.symbol })}</span>
        </div>
        {
          option?.type === 'stockOption' && (
            <>
              <div>
                <h6>{literals.exercisePrice}</h6>
                <span className='fw-sb'>{formatNumber(option?.exercisePrice, '-', { dec: 2, symbol: currency.symbol })}</span>
              </div>
              <div>
                <h6>{literals.ratio}</h6>
                <span className='fw-sb'>{formatNumber(option?.ratio, '-', { symbol: 'X' })}</span>
              </div>
            </>
          )
        }
        <div title={assignedTo.join('; ')}>
          <h6>{literals.shareholders}</h6>
          <span className='fw-sb'>{item.assign.length}</span>
        </div>
        <div>
          <h6>{literals.assigned}</h6>
          <span className='fw-sb'>{formatNumber(totalAssigned, 0, { dec: 2, symbol: '%' })}</span>
        </div>
      </>
    );
  };

  const renderPayoutsInfo = () => {
    const payout = {};
    if (item.relation?.id) {
      switch (item.relation?.type) {
        case 'debt': {
          payout.type = literals.debt;
          const debt = item.relation.info ? item.relation.info : convertibles.debts.find(d => d.debt === item.relation.id);
          if (debt) {
            payout.name = debt.name;
            payout.shareholder = debt.shareholder;
          }
        }
          break;
        default:
          break;
      }
    }

    return (
      <>
        <div>
          <h6>{literalsCommon.type}</h6>
          <span className='fw-sb'>{payout?.type || '-'}</span>
        </div>
        <div>
          <h6>{literalsCommon.name}</h6>
          <span className='fw-sb'>{payout?.name || '-'}</span>
        </div>
        <div>
          <h6>{literals.shareholder}</h6>
          <span className='fw-sb'>{payout?.shareholder?.name || '-'}</span>
        </div>
        <div>
          <h6>{literals.amount}</h6>
          <span className='fw-sb'>{formatNumber(item.amount, '-', { dec: 2, symbol: currency.symbol })}</span>
        </div>
        <div>
          <h6>{literals.interest}</h6>
          <span className='fw-sb'>{formatNumber(item.interests, '-', { dec: 2, symbol: currency.symbol })}</span>
        </div>
      </>
    );
  };

  const renderSecondariesInfo = () => {
    const secondary = {
      number: 0,
      shareClasses: [],
      pps: [],
      shareholders: [],
      assigned: [],
      total: 0,
    };

    Object.keys(item.shares).forEach((shareClass) => {
      const shareClassName = getShareClassName(shareClass);
      secondary.shareClasses.push(shareClassName);
      secondary.pps.push(formatNumber(item.shares[shareClass].pps, 0, { dec: 2, symbol: currency.symbol }));
      item.shares[shareClass].sales.forEach((sh) => {
        if (!secondary.shareholders.includes(sh.shareholder || 'unassigned')) {
          secondary.shareholders.push(sh.shareholder || 'unassigned');
        }
        secondary.number += sh.number;
        secondary.assigned.push(`${getShareholderName(sh.shareholder)} (${formatNumber(sh.number)} ${shareClassName})`);
        secondary.total += sh.number * item.shares[shareClass].pps;
      });
    });

    return (
      <>
        <div>
          <h6>{literals.shareClass}</h6>
          <span className='fw-sb'>{secondary.shareClasses.join('; ') || '-'}</span>
        </div>
        <div>
          <h6>{literals.number}</h6>
          <span className='fw-sb'>{formatNumber(secondary.number, '-')}</span>
        </div>
        <div>
          <h6>{literals.pps}</h6>
          <span className='fw-sb'>{secondary.pps.join('; ') || '-'}</span>
        </div>
        <div title={secondary.assigned.join('; ')}>
          <h6>{literals.shareholders}</h6>
          <span className='fw-sb'>{formatNumber(secondary.shareholders.length, '-')}</span>
        </div>
        <div>
          <h6>{literalsCommon.total}</h6>
          <span className='fw-sb'>{formatNumber(secondary.total, '-', { dec: 2, symbol: currency.symbol })}</span>
        </div>
      </>
    );
  };

  const renderchangeClassesInfo = () => {
    const operation = {
      number: 0,
      shareClasses: [],
      shareClassesTo: [],
      assigned: [],
    };

    Object.keys(item.shares).forEach((shareClass) => {
      operation.shareClasses.push(getShareClassName(shareClass));
      item.shares[shareClass].changes.forEach((sh) => {
        operation.number += sh.number;
        operation.shareClassesTo.push(getShareClassName(sh.shareClass));
      });
    });

    return (
      <>
        <div>
          <h6>{literals.shareClassFrom}</h6>
          <span className='fw-sb'>{operation.shareClasses.join('; ') || '-'}</span>
        </div>
        <div>
          <h6>{literals.shareClassTo}</h6>
          <span className='fw-sb'>{operation.shareClassesTo.join('; ') || '-'}</span>
        </div>
        <div>
          <h6>{literals.number}</h6>
          <span className='fw-sb'>{formatNumber(operation.number, '-')}</span>
        </div>
      </>
    );
  };

  const renderReduceInfo = () => {
    const totalShares = Object.values(item.shares).reduce((total, valor) => total + valor.shares, 0);
    return (
      <>
        <div>
          <h6>{literals.shareholders}</h6>
          <span className='fw-sb'>{Object.values(item.shares).length}</span>
        </div>
        <div>
          <h6>{literals.shares}</h6>
          <span className='fw-sb'>{formatNumber(totalShares, '-')}</span>
        </div>
        <div>
          <h6>{literals.repurchasePrice}</h6>
          <span className='fw-sb'>{formatNumber(item.repurchasePPS, '-')}</span>
        </div>
      </>
    );
  };

  const renderConversionsInfo = () => {
    let convertible = null;
    if (item.relation?.id) {
      switch (item.relation.type) {
        case 'debt':
          convertible = convertibles.debts.find(conv => conv.debt === item.relation.id) || {};
          break;
        case 'option':
          convertible = convertibles.options.find(conv => conv.assignment === item.relation.id) || {};
          break;
        default:
          break;
      }
    }

    return (
      <>
        <div>
          <h6>{literalsCommon.type}</h6>
          <span className='fw-sb'>{item.relation.type ? literals[item.relation.type] : '-'}</span>
        </div>
        <div>
          <h6>{literalsCommon.name}</h6>
          <span className='fw-sb'>{convertible.name || '-'}</span>
        </div>
        <div>
          <h6>{item.relation?.type === 'debt' ? literals.amount : literals.stockOptions}</h6>
          <span className='fw-sb'>{formatNumber(item.number, 0, { dec: 2, symbol: item.relation?.type === 'debt' ? currency.symbol : null }) || '-'}</span>
        </div>
        <div>
          <h6>{literals.shares}</h6>
          <span className='fw-sb'>{formatNumber(item.shares, 0)}</span>
        </div>
        <div>
          <h6>{literals.pps}</h6>
          <span className='fw-sb'>{formatNumber(item.pps, 0, { dec: 2, symbol: currency.symbol })}</span>
        </div>
        <div>
          <h6>{literals.shareClass}</h6>
          <span className='fw-sb'>{getShareClassName(item.shareClass)}</span>
        </div>
        <div>
          <h6>{literals.numeration}</h6>
          <span className='fw-sb'>
            {item.numeration?.from
              ? `${formatNumber(item.numeration?.from)} - ${formatNumber(item.numeration?.to, '---')}`
              : 'Auto'
            }
          </span>
        </div>
      </>
    );
  };

  const renderVestInfo = () => {
    const option = item.assignment ? assigned.options.find(opt => opt.assignment === item.assignment) : {};

    return (
      <>
        <div>
          <h6>{literals.shareholder}</h6>
          <span className='fw-sb'>{getShareholderName(item.shareholder)}</span>
        </div>
        <div>
          <h6>{literalsCommon.name}</h6>
          <span className='fw-sb'>{option?.name || '-'}</span>
        </div>
        <div>
          <h6>{literals.optionsToVest}</h6>
          <span className='fw-sb'>{formatNumber(item.number)}</span>
        </div>
        <div>
          <h6>{literalsCommon.type}</h6>
          <span className='fw-sb'>{option?.type ? literals[option.type] : '-'}</span>
        </div>
      </>
    );
  };

  const renderValuationInfo = () => {
    return (
      <>
        <div>
          <h6>{literals.analyst}</h6>
          <span className='fw-sb'>{item?.analyst || '-'}</span>
        </div>
      </>
    );
  };

  const renderDividendInfo = () => {
    return (
      <>
        <div>
          <h6>{literals.shareholders}</h6>
          <span className='fw-sb'>{item?.shareholders?.length || '-'}</span>
        </div>
        <div>
          <h6>{literals.custom}</h6>
          <span className='fw-sb'>{item?.shareholders?.some(sh => sh.custom) ? literalsCommon.yes : literalsCommon.no}</span>
        </div>
      </>
    );
  };

  const renderInfo = () => {
    switch (type) {
      case 'shares':
        return renderShareInfo();
      case 'debts':
        return renderDebtsInfo();
      case 'options':
        return renderOptionsInfo();
      case 'payouts':
        return renderPayoutsInfo();
      case 'secondaries':
        return renderSecondariesInfo();
      case 'changeClasses':
        return renderchangeClassesInfo();
      case 'reduces':
        return renderReduceInfo();
      case 'conversions':
        return renderConversionsInfo();
      case 'vests':
        return renderVestInfo();
      case 'valuation':
        return renderValuationInfo();
      case 'dividend':
        return renderDividendInfo();
      default:
        return null;
    }
  };

  const renderErrors = () => {
    return (
      itemErrors.length > 0 ? (
        <div className='operation-errors'>
          <span>{`${itemErrors.length} ${itemErrors.length > 1 ? literalsCommon.errors : literalsCommon.error}!`}</span>
          <p className='fs-small fw-b'>{itemErrors.map(e => e.message).join('; ')}</p>
        </div>
      ) : null
    );
  };

  const handleOnClick = async () => {
    if (!exist) {
      onDelete(item.id, type);
    } else if (onClick) {
      onClick(type, index);
    }
  };

  return (
    <li onClick={handleOnClick} className={`${itemErrors.length > 0 ? 'contain-errors' : ''}`}>
      <div className='operation-info'>
        <h5>
          {itemErrors.length > 0 ? <AlertOctagon /> : null}
          {renderTitle()}
        </h5>
        <div className='operation-detail'>{renderInfo()}</div>
        { renderErrors() }
      </div>
      {
        onClick ? (
          <div>
            {!exist ? <Trash size={18} className='row-icon' /> : (
              editable ? <Pencil size={18} className='row-icon' /> : <Eye size={18} className='row-icon' />
            )}
          </div>
        ) : null
      }
    </li>
  );
};

OperationRow.propTypes = {
  type: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  exist: PropTypes.bool,
  errors: PropTypes.object,
  onClick: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  convertibles: PropTypes.object.isRequired,
  unassigned: PropTypes.object.isRequired,
  assigned: PropTypes.object.isRequired,
  editable: PropTypes.bool,
};

OperationRow.defaultProps = {
  onClick: null,
  editable: false,
  exist: true,
  errors: null,
};

export default OperationRow;
