import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputNumber from 'components/Inputs/inputNumber';
import Button from 'components/Buttons/Button';
import {
  cloneObject,
  formatDate,
  formatNumber,
  isEmptyObject,
} from 'utils/functions';
import InputSelect from 'components/Inputs/inputSelect';
import OutputValue from 'components/Inputs/outputValue';
import Loading from 'components/Loading';
import InputDate from 'components/Inputs/inputDate';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchCaptableVariables } from '../modules/actions';

const defaultPayout = {
  relation: {
    type: null,
    id: null,
  },
  amount: null,
  interests: null,
};

const FormPayouts = (props) => {
  const {
    type,
    editable,
    data,
    dateOrID,
    currency,
    captable,
    shareholder,
    literals,
    literalsCommon,
    convertibles: auxConvertibles,
    onConfirm,
    onDelete,
  } = props;

  const [id] = useState(data?.id ?? `new.${new Date().getTime()}`);
  const [inputDate, setInputDate] = useState();
  const [payout, setPayout] = useState(!isEmptyObject(data) ? { ...data } : cloneObject(defaultPayout));
  const [convertibles, setConvertibles] = useState(auxConvertibles);
  const [convertible, setConvertible] = useState(auxConvertibles);
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const { id: startup } = useParams();

  const fetchConvertibles = async () => {
    const auxFilters = {};
    if (shareholder) {
      auxFilters.shareholder = shareholder;
    }
    const response = await fetchCaptableVariables(startup, dateOrID, captableId, auxFilters, { convertibles: true, unassigned: false });
    setConvertibles(response.convertibles);
  };

  const findConvertible = (convType, convId) => {
    let newConvertible = null;
    if (convId) {
      switch (convType) {
        case 'debt':
          newConvertible = convertibles.debts.find(conv => conv.debt === convId);
          break;
        default:
          break;
      }
    }
    return newConvertible;
  };

  useEffect(() => {
    const load = async () => {
      if (!convertibles) {
        await fetchConvertibles();
      }
      if (payout?.relation?.type && payout?.relation?.id && !convertible) {
        const newConvertible = findConvertible(payout.relation.type, payout.relation.id);
        setConvertible(newConvertible);
      }
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!convertibles || (payout.relation?.id && !convertible)) {
    return <Loading hide={false} mode='panel' />;
  }

  const submitForm = async (e) => {
    e.preventDefault();
    const params = {
      id,
      relation: payout.relation,
      amount: payout.amount !== '' ? parseFloat(payout.amount) : null,
      interests: payout.interests !== '' ? parseFloat(payout.interests) : null,
    };
    if (type === 'operation') {
      onConfirm({ id, ...params });
    } else {
      onConfirm({
        type: 'payouts',
        date: inputDate,
        name: convertible?.name,
        shareholder: convertible.shareholder?.id,
        data: { id, ...params },
      });
    }
  };

  const handleChangeRelation = (attr, value) => {
    const newPayout = { ...payout };

    if (attr === 'type') {
      newPayout.relation.type = value;
      newPayout.relation.id = null;
    } else {
      newPayout.relation.id = value;
      setConvertible(findConvertible(newPayout.relation.type, newPayout.relation.id));
    }
    setPayout(newPayout);
  };

  const total = parseFloat(payout.interests) + parseFloat(payout.amount);
  let max = null;
  const selectRelation = {
    preText: literalsCommon.type,
    options: [],
  };

  switch (payout.relation.type) {
    case 'debt':
      selectRelation.preText = literals.debt;
      selectRelation.options = convertibles.debts.map(debt => ({
        id: debt.debt,
        name: `${debt.name} - ${debt.shareholder?.name || '-'} (${formatNumber(debt.pending, 0, { dec: 2 })}${currency.symbol})`,
      }));
      max = convertible?.pending;
      break;
    default:
      break;
  }

  let pending = '';
  if (max) {
    pending = formatNumber(max - (payout.amount ? parseFloat(payout.amount) : 0), 0, { dec: 2 });
    pending = ` (${pending}${currency.symbol} ${literalsCommon.pending})`;
  }

  return (
    <form onSubmit={submitForm}>
      <div className='row'>
        {
          type !== 'operation' && (
            <div className='col-12 col-md-4'>
              <InputDate
                preText={literalsCommon.date}
                value={inputDate}
                onChange={v => setInputDate(v)}
                isDisabled={!editable}
                min={captable?.operations?.length ? captable?.operations[0].date : null}
              />
            </div>
          )
        }
        {
          type === 'operation' && (
            <div className='col-12 col-md-4'>
              <InputSelect
                options={[
                  { id: 'debt', name: literals.debt },
                ]}
                preText={literalsCommon.type}
                value={payout.relation.type}
                onChange={v => handleChangeRelation('type', v)}
                isDisabled={!editable}
              />
            </div>
          )
        }
        <div className='col-12 col-md-8'>
          {
            payout.relation.type && (
              <InputSelect
                {...selectRelation}
                value={payout.relation.id}
                onChange={v => handleChangeRelation('id', v)}
                isDisabled={!editable || selectRelation.options.length === 0}
              />
            )
          }
        </div>
      </div>
      <div className='box box-padding box-mb '>
        {
          payout.relation.id ? (
            <div className='row'>
              <div className='col-12 col-md-4'>
                <InputNumber
                  preText={`${literals.amount}${pending}`}
                  value={payout.amount}
                  maxValue={max}
                  onChange={v => setPayout(prev => ({ ...prev, amount: v }))}
                  isDisabled={!editable}
                  symbol={currency.symbol}
                  step='any'
                  decimals={2}
                />
              </div>
              <div className='col-12 col-md-4'>
                <InputNumber
                  preText={literals.interest}
                  value={payout.interests}
                  onChange={v => setPayout(prev => ({ ...prev, interests: v }))}
                  isDisabled={!editable}
                  symbol={currency.symbol}
                  decimals={2}
                />
              </div>
              <div className='col-12 col-md-4'>
                <OutputValue preText={literalsCommon.total} value={formatNumber(total, 0, { dec: 2 })} symbol={currency.symbol} />
              </div>
            </div>
          ) : (
            <h5 className='fw-b m-2 text-center'>{literals.selectFirstPayoutType}</h5>
          )
        }
      </div>
      {
        editable && (
          <div className='buttons'>
            <Button text={literalsCommon.confirm} type='submit' />
            {
              type === 'operation' && data.id && (
                <Button text={literalsCommon.delete} color='danger' onClick={() => onDelete(id)} />
              )
            }
          </div>
        )
      }
    </form>
  );
};

FormPayouts.propTypes = {
  type: PropTypes.string,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  shareholder: PropTypes.string,
  editable: PropTypes.bool,
  data: PropTypes.object,
  dateOrID: PropTypes.string,
  match: PropTypes.object.isRequired,
  convertibles: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

FormPayouts.defaultProps = {
  type: 'operation',
  editable: true,
  dateOrID: formatDate(new Date(), { format: 'Y-m-d' }),
  data: {},
  shareholder: null,
  convertibles: null,
};

export default FormPayouts;
