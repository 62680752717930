import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Meetings from 'components/Meetings';
import Panel from 'components/Panel';
import Documents from 'components/Documents';
import Button from 'components/Buttons/Button';
import ProfileCard from 'components/ProfileCard';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import StatCard from 'components/Statistics/StatCard';
import Loading from 'components/Loading';
import useDialog from 'components/Dialog/components/useDialog';
import Tool from 'components/Tool';
import EmptyStreet from 'assets/svg/empty_street.svg';
import EmptyData from 'assets/svg/empty_data.svg';
import { formatTime } from 'utils/functions';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTE_MENTOR_PATH } from 'routes';
import { fetchMentoringsSummary } from 'routes/Mentorings/modules/actions';
import { Building, Clock, Pencil } from 'lucide-react';


const HomeMentor = ({
  literals, user, fetchMentor,
}) => {
  const [mentoringSummary, setMentoringsSummary] = useState({ loading: false, data: null });
  const { dialog } = useDialog();
  const navigate = useNavigate();

  async function loadMentoringsSummary() {
    setMentoringsSummary({ ...mentoringSummary, loading: true });
    const response = await fetchMentoringsSummary('mentor', user.mentor.id);
    setMentoringsSummary({ data: response, loading: false });

    if (response?.hours && user.mentor.hours && (response.meetingsTime?.scheduled || response.meetingsTime?.finished)) {
      const mentorTime = user.mentor.hours * 60;
      if (response.meetingsTime.scheduled + response.meetingsTime.finished >= mentorTime) {
        const confirm = await dialog({
          text: response.meetingsTime.finished >= mentorTime ? literals.mentorMoreHours : literals.mentorMoreHoursSoon,
          type: 'custom',
          actions: [
            {
              text: literals.mentorMoreHoursProfile,
              onClick: () => { return true; },
            },
            {
              text: literals.mentorMoreHoursCancel,
              color: 'transparent',
              onClick: () => { return false; },
            },
          ],
        });

        if (confirm) {
          navigate({
            pathname: ROUTE_MENTOR_PATH.setEditMentor(user.mentor.id),
          });
        }
      }
    }
  }

  const initializeHome = async () => {
    await fetchMentor();
    await loadMentoringsSummary();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    initializeHome();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderMentorDetails = () => {
    const mentorData = {
      title: `${user.mentor.user.firstname} ${user.mentor.user.lastname}`,
      avatar: user.mentor.avatar,
      description: user.mentor.headline,
      extra: user.mentor.extra,
    };

    return (
      <ProfileCard
        data={mentorData}
        button={<Link to={ROUTE_MENTOR_PATH.setEditMentor(user.mentor.id)}><Button icon={Pencil} text={literals.editCompany} /></Link>}
      />
    );
  };

  const renderStats = () => {
    const loading = !mentoringSummary || !mentoringSummary.data;
    let totalTime = null;
    if (mentoringSummary.data) {
      if (mentoringSummary.data?.meetingsTime?.scheduled) {
        totalTime += mentoringSummary.data?.meetingsTime?.scheduled;
      }
      if (mentoringSummary.data?.meetingsTime?.finished) {
        totalTime += mentoringSummary.data?.meetingsTime?.finished;
      }
      if (user.mentor.hours) {
        totalTime = (user.mentor.hours * 60) - totalTime;
        totalTime = `${formatTime(totalTime)} / ${user.mentor.hours}h`;
      } else {
        totalTime = formatTime(totalTime);
      }
    }
    return (
      <div className='row'>
        <div className='col-12 col-md-6'>
          <StatCard
            value={mentoringSummary.data?.status?.accepted}
            title={literals.mentoredCompanies}
            loading={loading}
            icon={Building}
          />
        </div>
        <div className='col-12 col-md-6'>
          <StatCard
            value={totalTime}
            title={user?.mentor?.hours ? literals.donatedHoursLeft : literals.donatedHours}
            loading={loading}
            icon={Clock}
          />
        </div>
      </div>
    );
  };

  const renderMeetings = () => {
    let meetings = <Meetings meetings={null} view='summaryMentor' />;

    if (mentoringSummary.data && mentoringSummary.data.meetings) {
      if (mentoringSummary.data.meetings.length > 0) {
        meetings = {
          total: mentoringSummary.data.meetings.length,
          items: mentoringSummary.data.meetings,
        };
        meetings = <Meetings meetings={meetings} view='summaryMentor' />;
      } else {
        meetings = (
          <div className='panel-empty-message m-0'>
            <img src={EmptyStreet} alt='' />
            <p>{literals.eventsEmpty}</p>
          </div>
        );
      }
    }
    return (
      <Panel>
        <PanelHeader title={literals.nextMeetings} />
        <PanelBody>
          {meetings}
        </PanelBody>
      </Panel>
    );
  };

  const renderDocuments = () => {
    let documents = <Documents documents={null} />;

    if (mentoringSummary.data && mentoringSummary.data.documents) {
      if (mentoringSummary.data.documents.length > 0) {
        documents = {
          total: mentoringSummary.data.documents.length,
          items: mentoringSummary.data.documents,
        };
        documents = <Documents documents={documents} />;
      } else {
        documents = (
          <div className='panel-empty-message m-0'>
            <img src={EmptyData} alt='' />
            <p>{literals.documentsEmpty}</p>
          </div>
        );
      }
    }

    return (
      <Panel>
        <PanelHeader title={literals.lastDocuments} />
        <PanelBody>
          {documents}
        </PanelBody>
      </Panel>
    );
  };

  if (!user || !user.mentor || !user.mentor.headline || user.mentor.headline === '') {
    return <Loading hide={false} mode='tool' />;
  }
  return (
    <Tool className='home-wrapper home-mentor-wrapper'>
      <div className='home-viewport'>
        <div className='home-left-section'>
          {renderMentorDetails()}
        </div>
        <div className='home-right-section'>
          <div className='col-stats'>
            {renderStats()}
          </div>
          <div className='col-double'>
            <div className='col-meetings'>
              {renderMeetings()}
            </div>
            <div className='col-documents'>
              {renderDocuments()}
            </div>
          </div>
        </div>
      </div>
    </Tool>
  );
};
HomeMentor.propTypes = {
  literals: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  fetchMentor: PropTypes.func.isRequired,
};

export default HomeMentor;
