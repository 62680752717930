import {
  getSessionSuccess,
  getSessionError,
  getUserSessionSuccess,
  getUserSessionError,
  invalidSession,
} from './actions';
import reducer from '.';

let instance;
const USER_SESSION = 'USER-SESSION';
const USER_DATA = 'USER_DATA';

export class SessionService {
  constructor(store, options) {
    instance = this;
    SessionService.setOptions(store, options);
    return instance;
  }

  static setOptions(store, validateSession) {
    instance.store = store;
    instance.validateSession = validateSession;
    instance.storage = window.localStorage;
  }

  static async initSessionService(store, options) {
    return new Promise((resolve, reject) => {
      try {
        instance = new SessionService(store, options);
        SessionService.refreshFromLocalStorage();
        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  static invalidateSession() {
    instance.store.dispatch(invalidSession());
    SessionService.deleteSession();
    SessionService.deleteUser();
  }

  static attemptLoadUser() {
    instance.store.dispatch(getSessionSuccess());
    return SessionService.loadUser().then((user) => {
      instance.store.dispatch(getUserSessionSuccess(user));
    }).catch(() => {
      instance.store.dispatch(getUserSessionError());
    });
  }

  static refreshFromLocalStorage() {
    return SessionService.loadSession()
      .then(() => {
        const value = instance.validateSession(instance.store);

        if (value instanceof Promise) {
          return value.then((valid) => {
            if (!valid) throw new Error('Session is invalid');
            return this.attemptLoadUser();
          }).catch(() => {
            this.invalidateSession();
          });
        }
        if (!value) {
          this.invalidateSession();
          return null;
        }
        return this.attemptLoadUser();
      })
      .catch(() => {
        instance.store.dispatch(getSessionError());
      });
  }

  static saveSession(session) {
    return new Promise((resolve) => {
      try {
        instance.storage.setItem(USER_SESSION, JSON.stringify(session));
        instance.store.dispatch(getSessionSuccess());
        resolve();
      } catch (e) {
        instance.store.dispatch(getSessionSuccess());
        resolve();
      }
    });
  }

  static loadSession() {
    return new Promise((resolve, reject) => {
      try {
        const currentSession = JSON.parse(instance.storage.getItem(USER_SESSION));
        if (currentSession) {
          resolve(currentSession);
        }
        throw new Error();
      } catch (err) {
        reject(err);
      }
    });
  }

  static deleteSession() {
    return new Promise((resolve) => {
      instance.storage.removeItem(USER_SESSION);
      instance.store.dispatch(getSessionError());
      delete instance[USER_SESSION];
      resolve();
    });
  }

  static saveUser(user) {
    return new Promise((resolve) => {
      try {
        instance.storage.setItem(USER_DATA, JSON.stringify(user));
        instance.store.dispatch(getUserSessionSuccess(user));
        resolve();
      } catch (e) {
        instance.store.dispatch(getUserSessionSuccess(user));
        resolve();
      }
    });
  }

  static loadUser() {
    return new Promise((resolve, reject) => {
      try {
        const currentUser = JSON.parse(instance.storage.getItem(USER_DATA));
        if (currentUser) {
          resolve(currentUser);
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  static deleteUser() {
    return new Promise((resolve) => {
      instance.storage.removeItem(USER_DATA);
      instance.store.dispatch(getUserSessionError());
      delete instance[USER_DATA];
      resolve();
    });
  }
}

export const sessionReducer = reducer;
