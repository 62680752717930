import AdminInstance from 'modules/admin/repository';

export function fetchPlatform() {
  return AdminInstance.getPlatform();
}

export function fetchQuestions(page, size, search, filters) {
  return AdminInstance.getQuestions({
    page,
    size,
    search,
    filters,
  });
}

export function editPlatform(fields) {
  return AdminInstance.putPlatform(fields);
}

export function editQuestion(id, data) {
  return AdminInstance.putQuestion(id, data);
}

export function deleteQuestion(id) {
  return AdminInstance.deleteQuestion(id);
}

export function createQuestion(data) {
  return AdminInstance.postQuestions(data);
}

export function sendNotificationsSummary() {
  return AdminInstance.sendNotificationsSummary();
}

export function editPlatformToolPermissions(tool, data) {
  return AdminInstance.editPlatformToolPermissions(tool, data);
}

export const fetchInternalMails = (page, size, search, filters, sort) => {
  return AdminInstance.getInternalMails({
    page, size, search, filters, sort,
  });
};
