import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import SharePopup from 'components/RequestAccess/components/SharePopup';
import { UserPlus } from 'lucide-react';
import { useSelector } from 'react-redux';
import { AmplitudeApi } from 'utils/amplitude';
import './styles.scss';

const InviteFounderButton = ({ literalsCommon, startup }) => {
  const literals = useSelector(state => state.i18n.literals.onboarding);
  const [showPopup, setShowPopup] = useState(false);
  return (
    <div className='invite-founder-wrapper'>
      <Button
        icon={UserPlus}
        color='secondary'
        text={literals.inviteFounder}
        className='invite-founder-button'
        onClick={() => {
          AmplitudeApi.successEvent('startup.summary.main.click.founderInvite');
          setShowPopup(true);
        }}
      />
      {
        showPopup && (
          <SharePopup
            literals={literals}
            literalsCommon={literalsCommon}
            startup={startup}
            onClose={() => setShowPopup(false)}
          />
        )
      }
    </div>
  );
};

InviteFounderButton.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
};

export default InviteFounderButton;
