import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import { Clock, Building } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ROUTE_MENTOR_PATH, ROUTE_STARTUP_PATH } from 'routes';
import { formatDate, formatTime, getFullName } from 'utils/functions';
import './styles.scss';

function MentoringCard({ data, literals, view }) {
  const link = view.type === 'startup'
    ? ROUTE_STARTUP_PATH.setMentoring(view.id, data.id)
    : ROUTE_MENTOR_PATH.setMentoring(data.id);

  return (
    <Link to={link} className='mentor-card-wrapper'>
      <div className='mentor-card-left-section'>
        <Avatar
          title={getFullName(data.mentor.user)}
          src={data.mentor.user.avatar?.path}
          size={77}
        />
        <div className='mentor-hours fw-b'>{formatTime(data.meetings.time.finished).split(' ')[0]}</div>
      </div>
      <div className='mentor-card-right-section'>
        <h5 className='mentor-name fw-sb'>{getFullName(data.mentor.user)}</h5>
        <p className='mentor-description'>
          {
            view.type === 'startup' && data.mentor?.company ? (
              <>
                <Building size={18} />
                {data.mentor.company}
              </>
            ) : null
          }
        </p>
        <div className='next-meeting-wrapper'>
          <Clock size={18} />
          <p className='next-meeting-title fw-sb'>{literals.lastInteraction}</p>
        </div>
        <p className='next-meeting-info'>{ formatDate(data.lastInteraction, { time: true }) }</p>
      </div>
    </Link>
  );
}

MentoringCard.propTypes = {
  data: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
};

export default MentoringCard;
