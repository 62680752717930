import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import './styles.scss';

const StatCard = ({
  value = '0',
  tooltip = '',
  diff = null,
  title = null,
  subtitle = null,
  icon = null,
  loading = false,
  className = '',
  valueSize = '',
  onClick = null,
}) => {
  return (
    <div
      className={`stat-card box ${className} ${icon ? 'with-icon' : ''} ${onClick ? 'pointer' : ''}`}
      onClick={onClick}
    >
      <h3 className={`fw-b stat-card-value ${valueSize}`} title={tooltip || value}>
        {
          loading ? <Skeleton animation='wave' width='42px' height='29px' /> : value
        }
      </h3>
      {
        diff !== null && (
          <span className='stat-card-diff'>{diff}</span>
        )
      }
      {
        title && (
          <p className='stat-card-title fw-sb'>{title}</p>
        )
      }
      {
        subtitle && (
          <p className='stat-card-subtitle'>{subtitle}</p>
        )
      }
      {
        icon && (
          <div className='stat-card-icon'>
            <div>
              {React.isValidElement(icon) ? icon : React.createElement(icon, {})}
            </div>
          </div>
        )
      }
    </div>
  );
};

StatCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.any,
  value: PropTypes.any,
  diff: PropTypes.any,
  icon: PropTypes.object,
  loading: PropTypes.bool,
  valueSize: PropTypes.string,
  onClick: PropTypes.func,
};

export default StatCard;
