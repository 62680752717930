import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Panel from 'components/Panel/components/';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelTab from 'components/Panel/components/PanelTab';
import StartupCaptableDate from 'components/StartupCaptableDate/components';
import { AmplitudeApi } from 'utils/amplitude';
import ShareholdersTab from './ShareholdersTab';
import SyndicatesTab from './SyndicatesTab';

const Shareholders = (props) => {
  const {
    user,
    literalsCommon,
    literals,
    literalsShareholder,
    match,
    lang,
    startup,
    captable,
  } = props;

  const tabs = [
    { key: 'shareholders', name: literals.shareholders },
    { key: 'syndicates', name: literals.syndicates },
  ];

  useEffect(() => {
    if (captable?.selected?.id) {
      AmplitudeApi.logEvent('startup.investors.main.view', {
        captable: captable?.selected?.id,
      });
    }
  }, [captable?.selected?.id]);

  return (
    <Tool>
      <Panel active='shareholders'>
        <PanelHeader title={literals.shareholders} tabs={tabs} />
        <PanelBody>
          <StartupCaptableDate startup={startup} captable={captable} noDate />
          <PanelTab key='shareholders'>
            <ShareholdersTab
              literals={literals}
              literalsCommon={literalsCommon}
              literalsShareholder={literalsShareholder}
              startup={startup}
              captable={captable?.selected?.id}
              match={match}
              lang={lang}
            />
          </PanelTab>
          <PanelTab key='syndicates'>
            <SyndicatesTab
              literals={literals}
              literalsCommon={literalsCommon}
              literalsShareholder={literalsShareholder}
              user={user}
              startup={startup}
              match={match}
              lang={lang}
            />
          </PanelTab>
        </PanelBody>
      </Panel>
    </Tool>
  );
};

Shareholders.propTypes = {
  user: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  literalsShareholder: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  startup: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
};

export default Shareholders;
