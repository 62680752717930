import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import DownloadingFile from 'assets/svg/downloading-file.svg';
import DownloadFailed from 'assets/svg/download-failed.svg';
import { Link, useLocation } from 'react-router-dom';
import { ROUTE_PATH } from 'routes';
import { downloadFile } from '../modules/actions';
import './styles.scss';

const STATUS = {
  DOWNLOADING: 'DOWNLOADING',
  UNAUTHORIZED: 'UNAUTHORIZED',
  NOT_FOUND: 'NOT_FOUND',
  GENERIC_ERROR: 'GENERIC_ERROR',
  DONE: 'DONE',
};

const DownloadFile = (props) => {
  const {
    literals,
    isAuthenticated,
    match,
  } = props;

  const [status, setStatus] = useState(STATUS.DOWNLOADING);
  const location = useLocation();

  const getDocument = async () => {
    try {
      await downloadFile(match.params.hash, match.params.filename, location?.search);
      setStatus(STATUS.DONE);
    } catch (error) {
      if (error.message === 'Unauthorized') {
        setStatus(STATUS.UNAUTHORIZED);
      } else if (error.message === 'Not Found') {
        setStatus(STATUS.NOT_FOUND);
      } else {
        setStatus(STATUS.GENERIC_ERROR);
      }
    }
  };

  useEffect(() => {
    getDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDownloadingFile = () => {
    let header = null;
    let imgSrc = DownloadFailed;

    switch (status) {
      case STATUS.UNAUTHORIZED:
        header = literals.noPermissions;
        break;
      case STATUS.NOT_FOUND:
        header = literals.noExists;
        break;
      case STATUS.GENERIC_ERROR:
        header = literals.genericError;
        break;
      case STATUS.DONE:
        header = literals.downloadedFile;
        imgSrc = DownloadingFile;
        break;
      default:
        header = literals.downloadingFile;
        imgSrc = DownloadingFile;
        break;
    }

    header = `${header} (${match.params.filename})`;

    return (
      <div className='downloading-file'>
        <h4 className='fw-b text-center mb-3'>{header}</h4>
        <img src={imgSrc} alt={header} />
        {![STATUS.DOWNLOADING, STATUS.DONE].includes(status) && !isAuthenticated && (
          <Link to={{ pathname: ROUTE_PATH.LOGIN, state: { from: location.pathname } }}>
            <Button text={literals.login} />
          </Link>
        )}
      </div>
    );
  };

  return (
    <div className='form-wrapper'>
      <div className='form-box box'>
        {renderDownloadingFile()}
      </div>
    </div>
  );
};

DownloadFile.propTypes = {
  literals: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};

export default DownloadFile;
