import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import KpiChartTable from '../../../../components/KpiChartTable';

const SummaryStartupPerformance = (props) => {
  const {
    lang,
    literalsPerformance,
    literalsCommon,
    currency,
    startup,
    external,
  } = props;

  return (
    <div className='summary-performance'>
      <Panel>
        <PanelHeader title={literalsPerformance.title} />
        <PanelBody>
          <KpiChartTable
            lang={lang}
            literalsPerformance={literalsPerformance}
            literalsCommon={literalsCommon}
            currency={currency}
            startup={startup}
            bookmark={!external}
          />
        </PanelBody>
      </Panel>
    </div>
  );
};

SummaryStartupPerformance.propTypes = {
  literalsPerformance: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  startup: PropTypes.object.isRequired,
  external: PropTypes.bool.isRequired,
};

export default SummaryStartupPerformance;
