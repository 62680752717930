import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelTab from 'components/Panel/components/PanelTab';
import Avatar from 'components/Avatar';
import Button from 'components/Buttons/Button';
import OutputValue from 'components/Inputs/outputValue';
import StatCard from 'components/Statistics/StatCard';
import Table from 'components/Table';
import Loading from 'components/Loading';
import IOFileLoading from 'components/IOFileLoading/components';
import EmptyParticipation from 'assets/svg/empty_participation.svg';
import { useSearchParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { TOOL_CAP_TABLE, TOOL_USERS } from 'constants/tools';
import {
  formatDate, formatNumber, getFullName, startupCanEdit, startupCanView,
} from 'utils/functions';
import { Pencil, CloudDownload } from 'lucide-react';
import { fetchStartupUsers } from 'routes/StartupUsers/modules/actions';
import { fetchCaptableShares, fetchCaptableDebts, fetchCaptableOptions } from '../../../../modules/actions';
import { downloadReportShareholder } from '../modules/actions';
import PopupFormShareholder from '../../../PopupFormShareholder';
import PopupShareholderShares from './PopupShareholderShares';
import PopupShareholderDebts from './PopupShareholderDebts.';
import PopupShareholderOptions from './PopupShareholderOptions';
import './styles.scss';

const ShareholderPartner = (props) => {
  const {
    literals,
    literalsCountries,
    literalsCommon,
    match,
    shareholder,
    currency,
    startup,
    lang,
    refresh,
  } = props;

  const [stats, setStats] = useState(false);
  const [shares, setShares] = useState();
  const [debts, setDebts] = useState();
  const [options, setOptions] = useState();
  const [bindedUsers, setBindedUsers] = useState([]);
  const [sharesPopup, setSharesPopup] = useState(false);
  const [debtsPopup, setDebtsPopup] = useState(false);
  const [optionsPopup, setOptionsPopup] = useState(false);
  const [modifyPopup, setModifyPopup] = useState(false);
  const [downloadingReport, setDownloadingReport] = useState(false);
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const canViewUsers = startupCanView(startup, TOOL_USERS);
  const canEdit = startupCanEdit(startup, TOOL_CAP_TABLE);

  useEffect(() => {
    setStats(false);
    setShares(null);

    const getUsers = () => {
      if (canViewUsers) {
        return fetchStartupUsers(match.params.id, {
          page: 0,
          size: 10,
          filters: { shareholders: [match.params.shareholder] },
        });
      }
      return Promise.resolve({ items: [] });
    };

    Promise.all([
      fetchCaptableShares(match.params.id, formatDate(new Date(), { format: 'Y-m-d' }), {
        page: 0,
        size: 0,
        filters: { shareholder: match.params.shareholder },
        captable: captableId,
      }),
      fetchCaptableDebts(match.params.id, formatDate(new Date(), { format: 'Y-m-d' }), {
        page: 0,
        size: 0,
        filters: { shareholder: match.params.shareholder },
        captable: captableId,
      }),
      fetchCaptableOptions(match.params.id, formatDate(new Date(), { format: 'Y-m-d' }), {
        page: 0,
        size: 0,
        filters: { shareholder: match.params.shareholder },
        captable: captableId,
      }),
      getUsers(),
    ]).then(([auxShares, auxDebts, auxOptions, auxUsers]) => {
      setShares(auxShares);
      setDebts(auxDebts);
      setOptions(auxOptions);
      setBindedUsers(auxUsers.items);
      if (shareholder) {
        (async () => {
          const auxStats = {
            shares: 0,
            value: 0,
            ownership: 0,
            fullyDiluted: 0,
          };
          auxShares.items.forEach((item) => {
            auxStats.shares += item.number;
            auxStats.value += item.value;
            auxStats.ownership += item.ownership;
            auxStats.fullyDiluted += item.fullyDiluted;
          });
          setStats(auxStats);
        })();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareholder, captableId]);

  const handleDownloadReport = async () => {
    setDownloadingReport(true);
    await downloadReportShareholder(match.params.id, shareholder?.id, `${getFullName(shareholder)}_report.pdf`);
    setDownloadingReport(false);
  };

  const columnsShares = [
    {
      field: 'numeration',
      grow: 1,
      label: literals.numeration,
      preRender: v => `${formatNumber(v.from, 0)} - ${formatNumber(v.to, 0)}`,
    },
    {
      field: 'number',
      type: 'html',
      grow: 2,
      label: literals.shares,
      preRender: (v, row) => `
        ${formatNumber(v, 0)}<br/>
        <span class='fs-sm'>${row.shareClass.name}</span>`,
    },
    {
      field: 'value',
      type: 'html',
      label: `${literals.value} / ${literals.capital}`,
      grow: 2,
      preRender: (v, row) => `
        ${formatNumber(v, 0)}${currency.symbol}<br/>
        <span class='fs-sm'>${formatNumber(row.capital, 0, { dec: 2 })}${currency.symbol}</span>`,
    },
  ];

  const columnsDebts = [
    {
      field: 'name',
      grow: 1,
      label: literals.name,
    },
    {
      field: 'pending',
      width: 100,
      label: literals.pending,
      preRender: (v, row) => (
        <>
          <span>{`${formatNumber(v, 0, { dec: 2 })}${currency.symbol} /`}</span>
          <br />
          <span>{`${formatNumber(row.principal, 0, { dec: 2 })}${currency.symbol}`}</span>
        </>
      ),
    },
    {
      field: 'interestRate',
      type: 'html',
      width: 80,
      label: literals.interestRate,
      preRender: v => `${formatNumber(v, 0, { dec: 2 })}%`,
    },
    {
      field: 'startDate',
      width: 150,
      label: literalsCommon.date,
      preRender: (v, row) => (
        <>
          <span>{`${literals.from}: ${v ? formatDate(v) : '-'}`}</span>
          <br />
          <span>{`${literals.to}: ${row.expirationDate ? formatDate(row.expirationDate) : '-'}`}</span>
        </>
      ),
    },
  ];

  const columnsOptions = [
    {
      field: 'name',
      growth: 1,
      label: literalsCommon.name,
    },
    {
      field: 'type',
      type: 'html',
      width: 50,
      preRender: v => `<span class='cell-keywords'>
        <div>${v === 'phantom' ? 'PS' : 'SO'}</div>
      </span>`,
    },
    {
      field: 'number',
      width: 125,
      label: literals.number,
      preRender: v => `${formatNumber(v, 0)}`,
    },
    {
      field: 'capital',
      width: 100,
      label: literals.capital,
      preRender: v => `${formatNumber(v, 0, { dec: 2 })}${currency.symbol}`,
    },
    {
      field: 'assigned',
      width: 100,
      label: literals.vested,
      preRender: (v, row) => `${formatNumber(v, 0)} / ${formatNumber(row.vested, 0)} / ${row.type === 'phantom' ? '-' : formatNumber(row.exercised, 0)} `,
    },
  ];

  const renderTable = () => {
    if (!shares || !debts || !options) {
      return (
        <Panel>
          <Loading hide={false} mode='panel' />
        </Panel>
      );
    }
    if (!shares.total && !debts.total && !options.total) {
      return (
        <Panel>
          <PanelBody>
            <li className='panel-empty-message'>
              <img src={EmptyParticipation} alt={literals.noParticipation} />
              <span className='mt-4'>{literals.noParticipation}</span>
            </li>
          </PanelBody>
        </Panel>
      );
    }

    const tabs = [
      { key: 'shares', name: literals.shares, hide: !shares.total },
      { key: 'debts', name: literals.debts, hide: !debts.total },
      { key: 'options', name: literals.options, hide: !options.total },
    ];

    return (
      <Panel key='profile-tabs'>
        <PanelHeader
          tabs={tabs}
          actions={[
            {
              color: 'secondary',
              icon: CloudDownload,
              text: literals.certificate,
              onClick: handleDownloadReport,
            },
          ]}
          justify='between'
        />
        <PanelBody>

          <PanelTab key='shares'>
            <Table
              searcher={false}
              columns={columnsShares}
              fetch={() => shares}
              onClickRow={row => setSharesPopup(row)}
            />
          </PanelTab>

          <PanelTab key='debts'>
            <Table
              searcher={false}
              columns={columnsDebts}
              fetch={() => debts}
              onClickRow={row => setDebtsPopup(row)}
            />
          </PanelTab>

          <PanelTab key='options'>
            <Table
              searcher={false}
              columns={columnsOptions}
              fetch={() => options}
              onClickRow={row => setOptionsPopup(row)}
            />
          </PanelTab>

        </PanelBody>
      </Panel>
    );
  };

  return (
    <>
      <div className='row'>
        <div className='col-12 col-md-5'>
          <Panel>
            <PanelBody>
              {shareholder ? (
                <div className='header'>
                  <Avatar
                    title={getFullName(shareholder)}
                    src={shareholder?.avatar?.path}
                    size={75}
                  />
                  <div>
                    <div className='header-info'>
                      <h5 className='fw-b mb-0'>{getFullName(shareholder)}</h5>
                      <div className='d-flex'>
                        <span className='cell-keywords mr-2'>
                          <div>{literals[shareholder.role]}</div>
                        </span>
                        <span className='cell-keywords'>
                          <div>{literals[shareholder?.type]}</div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='header mb-3'>
                  <Skeleton animation='wave' variant='circular' width={75} height={75} />
                  <div>
                    <div className='header-info'>
                      <Skeleton animation='wave' width={200} />
                      <Skeleton animation='wave' width={100} />
                    </div>
                  </div>
                </div>
              )}
              <div className='row other-info'>
                <div className='col-12 col-xl-6'>
                  <OutputValue
                    preText={literals.phone}
                    value={shareholder?.contactInfo?.phone || '-'}
                  />
                </div>
                {canViewUsers && (
                  <div className='col-12 col-xl-6'>
                    <OutputValue
                      preText={literalsCommon.email}
                      value={bindedUsers.map(auxUser => auxUser.email).join(', ') || '-'}
                    />
                  </div>
                )}
                <div className='col-12 col-xl-6'>
                  <OutputValue
                    preText={literals.country}
                    value={literalsCountries[shareholder?.country] || '-'}
                  />
                </div>
                <div className='col-12 col-xl-6'>
                  <OutputValue
                    preText={literals.taxid}
                    value={shareholder?.taxId || '-'}
                  />
                </div>
              </div>
              {canEdit && (
                <div className='d-flex justify-content-end mt-2'>
                  <Button icon={Pencil} text={literalsCommon.edit} color='secondary' onClick={() => setModifyPopup(shareholder)} />
                </div>
              )}
            </PanelBody>
          </Panel>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <StatCard title={literals.shares} value={formatNumber(stats.shares, 0)} loading={!stats} />
            </div>
            <div className='col-12 col-md-6'>
              <StatCard title={literals.value} value={`${formatNumber(stats.value, 0)}${currency.symbol}`} loading={!stats} />
            </div>
            <div className='col-12 col-md-6'>
              <StatCard title={literals.ownership} value={`${formatNumber(stats.ownership, 0, { dec: 2 })}%`} loading={!stats} />
            </div>
            <div className='col-12 col-md-6'>
              <StatCard title={literals.fullyDiluted} value={`${formatNumber(stats.fullyDiluted, 0, { dec: 2 })}%`} loading={!stats} />
            </div>
          </div>
        </div>
        <div className='col-12 col-md-7 captable-shareholder'>
          {renderTable()}
        </div>
        {modifyPopup && (
          <PopupFormShareholder
            onClose={() => setModifyPopup(false)}
            shareholder={modifyPopup}
            onSubmit={() => refresh.increase()}
            lang={lang}
          />
        )}
        {sharesPopup && (
          <PopupShareholderShares
            literals={literals}
            literalsCommon={literalsCommon}
            currency={currency}
            match={match}
            onClose={() => setSharesPopup(false)}
            row={sharesPopup}
          />
        )}
      </div>
      {debtsPopup && (
        <PopupShareholderDebts
          literals={literals}
          literalsCommon={literalsCommon}
          currency={currency}
          match={match}
          onClose={() => setDebtsPopup(false)}
          row={debtsPopup}
        />
      )}
      {optionsPopup && (
        <PopupShareholderOptions
          literals={literals}
          literalsCommon={literalsCommon}
          currency={currency}
          match={match}
          onClose={() => setOptionsPopup(false)}
          row={optionsPopup}
        />
      )}
      { downloadingReport && <IOFileLoading mode='download' file={{ name: `${shareholder.id}_report.pdf` }} /> }
    </>
  );
};

ShareholderPartner.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  shareholder: PropTypes.object,
  startup: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  literalsCountries: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  refresh: PropTypes.func.isRequired,

};

ShareholderPartner.defaultProps = {
  shareholder: null,
};

export default ShareholderPartner;
