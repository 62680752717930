import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import SortKpis from 'components/SortKpis';
import Button from 'components/Buttons/Button';

const OrderKpisPopup = ({
  literals,
  literalsCommon,
  startup,
  lang,
  relations = [],
  onClose,
  orderKpis,
}) => {
  const [loading, setLoading] = useState(false);
  const [orderedKpis, setOrderedKpis] = useState(relations.map(rel => ({
    ...rel.kpi, id: rel.id, permission: rel.permission,
  })));

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const orderedRelations = orderedKpis.map(kpi => kpi.id);
      await orderKpis(startup, orderedRelations);
      onClose();
    } catch {
      setLoading(false);
    }
  };

  return (
    <Popup title={literals.orderKpis} onClose={onClose}>
      <SortKpis
        literals={literals}
        literalsCommon={literalsCommon}
        lang={lang}
        kpis={orderedKpis}
        setKpis={setOrderedKpis}
      />
      <div className='buttons'>
        <Button
          text={literalsCommon.send}
          onClick={handleSubmit}
          loading={loading}
        />
      </div>
    </Popup>
  );
};

OrderKpisPopup.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  startup: PropTypes.string.isRequired,
  relations: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  orderKpis: PropTypes.func.isRequired,
};

export default OrderKpisPopup;
