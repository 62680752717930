/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import Pagination from 'components/Pagination';
import ButtonDropdown from 'components/Buttons/ButtonDropdown';
import InputDate from 'components/Inputs/inputDate';
import InputText from 'components/Inputs/inputText';
import InputNumber from 'components/Inputs/inputNumber';
import InputSelect from 'components/Inputs/inputSelect';
import InputMultiSelect from 'components/Inputs/inputMultiSelect';
import EmptyStreet from 'assets/svg/empty_street.svg';
import WarningImage from 'assets/svg/warning.svg';
import Searcher from 'components/Searcher';
import { literalTemplate } from 'utils/language';
import { dictionary, formatErrors, formatNumber } from 'utils/functions';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Skeleton } from '@mui/material';
import {
  GripVertical, PlusCircle, Eraser, Filter,
  ChevronDown, ChevronUp, ToggleRight, ToggleLeft,
} from 'lucide-react';
import PermissionPopup from 'components/PermissionsPopup/PermissionPopup';
import { useWidth } from 'utils/customHooks';
import TableRow from './TableRow';
import DropOptionsIcon from './DropOptionsIcon';
import PopupConfigTableColumns from './PopupConfigTableColumns';
import { getTable } from '../modules/actions';
import './styles.scss';

const Table = (props) => {
  const {
    className,
    literals,
    literalsCommon,
    mode,
    searcher,
    filters,
    actions,
    rowSelection,
    columns: auxColumns,
    component,
    componentProps,
    componentColumns,
    emptyImage,
    emptyMessage,
    fetch,
    onFetch,
    pageSize,
    forceFetch,
    forceFetchAuto,
    fetchLocal,
    onClickRow,
    selectRows,
    selectFields,
    onSelectRows,
    onDrag,
    externalFilterValue,
    configColumns,
    rowDetails,
  } = props;

  const defaultValues = () => {
    return filters.reduce((prev, curr) => {
      const aux = { ...prev };
      if (curr.default) {
        aux[curr.id] = curr.default;
      }
      return aux;
    }, {});
  };

  const originalColumns = auxColumns.map((col, i) => ({ ...col, order: i, hide: false }));
  const [rows, setRows] = useState(null);
  const [savedColumns, setSavedColumns] = useState({ columns: originalColumns, edited: false, loaded: false });
  const [search, setSearch] = useState('');
  const [filtersValue, setFiltersValue] = useState(defaultValues());
  const [loading, setLoading] = useState({ fetch: true, column: false });
  const [actualPage, setActualPage] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedRows, setSelectedRows] = useState(selectRows);
  const [sortedColumn, setSortedColumn] = useState({ name: '', sort: '' });
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [allowedDrag, setAllowedDrag] = useState({ onDrag: !!onDrag });
  const [showConfigColumns, setShowConfigColumns] = useState(false);
  const [showPermissionPopup, setShowPermissionPopup] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const columns = configColumns ? savedColumns : { columns: originalColumns };
  const scrollRef = useRef();
  const width = useWidth();

  const isLoading = Object.values(loading).some(l => l);

  useEffect(() => {
    if (!savedColumns.edited) {
      setSavedColumns({ columns: originalColumns, edited: false, loaded: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auxColumns]);

  useEffect(() => {
    if (fetch && configColumns && !savedColumns.loaded) {
      (async () => {
        setLoading(prev => ({ ...prev, column: true }));
        const newSavedColumns = await getTable(configColumns, originalColumns);
        if (newSavedColumns) {
          const auxCols = dictionary(originalColumns.map(col => ({ ...col, id: col.id || col.field })));
          setSavedColumns({
            columns: newSavedColumns.filter(col => [auxCols[col.id]?.id, auxCols[col.id]?.field].includes(col.id)),
            edited: true,
            loaded: true,
          });
        } else {
          setSavedColumns({ columns: originalColumns, edited: false, loaded: true });
        }
        setLoading(prev => ({ ...prev, column: false }));
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, configColumns]);

  useEffect(() => {
    if (onSelectRows) {
      onSelectRows(selectedRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  useEffect(() => {
    setSelectedRows(selectRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRows]);

  const getRowSelectFields = (row) => {
    const auxRow = {};
    selectFields.forEach((att) => {
      const splitedAtt = typeof att === 'object' ? att.field.split('.') : att.split('.');
      const val = splitedAtt.reduce((prev, curr) => prev[curr], row);
      const key = typeof att === 'object' ? att?.name || att[att.length - 1] : att;
      auxRow[key] = val;
    });
    return auxRow;
  };

  const handleSelectRow = (row) => {
    setSelectedRows((prev) => {
      const newSelectedRows = [...prev];
      if (selectFields) {
        if (!selectedRows.find(auxRow => auxRow.id === row.id)) {
          newSelectedRows.push(getRowSelectFields(row));
        }
      } else if (!selectedRows.includes(row.id)) {
        newSelectedRows.push(row.id);
      }
      return newSelectedRows;
    });
  };

  const handleUnselectRow = (row) => {
    if (selectFields) {
      setSelectedRows(prev => prev.filter(auxRow => auxRow.id !== row.id));
    } else {
      setSelectedRows(prev => prev.filter(id => id !== row.id));
    }
  };

  const selectAllRows = async () => {
    setLoading(prev => ({ ...prev, fetch: true }));
    const { items } = await fetch(0, 0, search, filtersValue, '', selectFields ? selectFields.join(',') : 'id');
    setSelectedRows(items);
    setLoading(prev => ({ ...prev, fetch: false }));
  };

  const selectPageRows = () => {
    rows.items.forEach((item) => {
      handleSelectRow(item);
    });
    setLoading(prev => ({ ...prev, fetch: false }));
  };

  const sortTable = async (column) => {
    const columnName = column?.id || column.field;
    let sort = '+';
    if (sortedColumn && sortedColumn.name === columnName) {
      if (sortedColumn.sort === '') {
        sort = '+';
      } else if (sortedColumn.sort === '+') {
        sort = '-';
      } else {
        sort = '';
      }
    }
    setSortedColumn({ name: columnName, sort });
    setLoading(prev => ({ ...prev, fetch: true }));
    const response = await fetch(actualPage, pageSize, search, filtersValue, sort ? `${sort}${columnName}` : '');
    setRows(response);
    setLoading(prev => ({ ...prev, fetch: false }));
    setAllowedDrag(prev => ({ ...prev, sort: !sort }));
  };

  const fetchPage = async (page = 0, auxFilters = null) => {
    if (!fetchLocal) {
      setLoading(prev => ({ ...prev, fetch: true }));
    }
    if (fetch) {
      if (fetchError) {
        setFetchError(false);
      }
      try {
        const response = await fetch(page, pageSize, search, auxFilters || filtersValue, sortedColumn.sort ? `${sortedColumn.sort}${sortedColumn.name}` : '');
        setRows(response);
        if (configColumns) {
          setSavedColumns(prev => ({
            ...prev,
            columns: prev.columns.map(col => ({
              ...col,
              hide: col.hide || (response && typeof col.hidden === 'function' && col.hidden(response.items)),
            })),
          }));
        }
        setLoading(prev => ({ ...prev, fetch: false }));
        if (typeof onFetch === 'function') {
          onFetch(response);
        }
      } catch (error) {
        const auxError = (error?.response && error.statusText) ? formatErrors(error) : error;
        setFetchError(auxError?.unauthorized ? literals.errorFetchingUnauthorized : literals.errorFetchingGeneric);
        setRows({ items: [], total: 0 });
        setLoading(prev => ({ ...prev, fetch: false }));
      }
    }
    setAllowedDrag(prev => ({
      ...prev,
      search: !search,
      filters: !Object.keys(filtersValue).length,
    }));
  };

  useEffect(() => {
    fetchPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (externalFilterValue) {
        setFiltersValue(externalFilterValue);
        fetchPage(0, externalFilterValue);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalFilterValue]);

  const onChangeFilterValue = (id, value, key = null) => {
    const auxfiltersValue = { ...filtersValue };

    if (Array.isArray(id)) {
      id.forEach((singleId) => {
        onChangeFilterValue(singleId, value, key);
      });
    } else if (key) {
      if (!value && value !== 0) {
        if (auxfiltersValue[id]) {
          delete auxfiltersValue[id][key];
          if (!Object.keys(auxfiltersValue[id]).length) {
            delete auxfiltersValue[id];
          }
        }
      } else {
        auxfiltersValue[id] = { ...(auxfiltersValue[id] || {}), [key]: value };
      }
    } else if ((!value && value !== 0) || (Array.isArray(value) && !value.length)) {
      delete auxfiltersValue[id];
    } else {
      auxfiltersValue[id] = value;
    }
    setFiltersValue(auxfiltersValue);
  };

  const cleanFilters = () => {
    setFiltersValue(defaultValues());
  };

  const applyFilters = () => {
    fetchPage(0);
    if (actualPage !== 0) {
      setActualPage(0);
    }
    setShowFilters(false);
  };

  const handleValidateRange = async (filter, val, key) => {
    const auxfiltersValue = { ...filtersValue };
    if (val) {
      const other = key === 'gte' ? 'lte' : 'gte';
      if ((key === 'gte' && val > auxfiltersValue[filter][other])
        || (key === 'lte' && val < auxfiltersValue[filter][other])) {
        auxfiltersValue[filter][key] = auxfiltersValue[filter][other];

        setFiltersValue(auxfiltersValue);
      }
    }
  };

  const handleOpenPermissions = (row, cfg) => {
    setShowPermissionPopup({
      title: cfg.title,
      element: { ...cfg.element, id: row.id },
      scope: cfg.scope,
    });
  };

  const handleClosePermissionPopup = (edited) => {
    setShowPermissionPopup(false);
    if (edited) {
      fetchPage(actualPage);
    }
  };

  const renderActions = () => {
    if (actions.length === 1) {
      const action = actions[0];
      if (action.type === 'Link') {
        return (
          <Link to={action.to}>
            <Button {...action} />
          </Link>
        );
      }
      return (<Button {...action} />);
    }
    return (<ButtonDropdown text={literals.actions} buttons={actions} />);
  };

  const renderRowSelection = () => {
    return (<ButtonDropdown text={`${literals.rowsAction} (${selectedRows.length})`} buttons={rowSelection} color='secondary' />);
  };

  const renderFilters = () => {
    if (!searcher && (!filters || !filters.length)) {
      return null;
    }
    const printFilter = (filter) => {
      switch (filter.type) {
        case 'text':
          return (
            <InputText
              preText={filter.title}
              placeholder={`${literals.search}...`}
              value={filtersValue[filter.id]?.search}
              onChange={v => onChangeFilterValue(filter.id, v, 'search')}
            />
          );
        case 'selectUnique':
          return (
            <InputSelect
              preText={filter.title}
              placeholder={filter.placeholder}
              value={filtersValue[filter.id]}
              options={filter.options}
              onChange={v => onChangeFilterValue(filter.id, v)}
              loading={filter.loading}
            />
          );
        case 'list':
        case 'select':
        case 'radiobutton':
        case 'checkbox':
          return (
            <InputMultiSelect
              preText={filter.title}
              value={filtersValue[filter.id]}
              options={filter.options}
              mode={filter?.mode}
              onChange={v => onChangeFilterValue(filter.id, v)}
            />
          );
        case 'number':
          return (
            <InputNumber
              preText={filter.title}
              value={filtersValue[filter.id]}
              onChange={v => onChangeFilterValue(filter.id, v)}
            />
          );
        case 'numberRange':
          return (
            <>
              <div className='d-flex'>
                <InputNumber
                  preText={filter.title}
                  value={filtersValue[filter.id]?.gte}
                  onChange={v => onChangeFilterValue(filter.id, v, 'gte')}
                  onBlur={v => handleValidateRange(filter.id, v, 'gte')}
                />
                <InputNumber
                  preText='&nbsp;'
                  className='pl-2'
                  value={filtersValue[filter.id]?.lte}
                  onChange={v => onChangeFilterValue(filter.id, v, 'lte')}
                  onBlur={v => handleValidateRange(filter.id, v, 'lte')}
                />
              </div>
            </>
          );
        case 'date':
          return (
            <InputDate
              preText={filter.title}
              value={filtersValue[filter.id]}
              onChange={v => onChangeFilterValue(filter.id, v)}
            />
          );
        case 'dateRange':
          return (
            <div className='filters-range'>
              <InputDate
                className='range'
                preText={filter.title}
                value={filtersValue[filter.id]?.gte}
                onChange={v => onChangeFilterValue(filter.id, v, 'gte')}
                max={filtersValue[filter.id]?.lte}
                onBlur={v => handleValidateRange(filter.id, v, 'gte')}
              />
              <InputDate
                className='range'
                preText='&nbsp;'
                value={filtersValue[filter.id]?.lte}
                onChange={v => onChangeFilterValue(filter.id, v, 'lte')}
                min={filtersValue[filter.id]?.gte}
                onBlur={v => handleValidateRange(filter.id, v, 'lte')}
              />
            </div>
          );
        default:
          return '';
      }
    };

    const numFilters = Object.keys(filtersValue).length;
    return (
      <div className='table-top'>
        <div className='table-bar'>
          <div className='table-filters-basic'>
            {
              searcher ? (
                <Searcher
                  className='table-searcher'
                  value={search}
                  onChange={setSearch}
                  onSearch={applyFilters}
                  haveFilters={numFilters > 0}
                  onFilters={filters?.length ? () => setShowFilters(!showFilters) : null}
                />
              ) : (
                <div className='input_text mb-0'>
                  <div className='table-filter-submit mb-0'>
                    {filters && filters.length > 0 && (
                      <Button
                        icon={Filter}
                        text={`${numFilters > 0 ? `${numFilters} ` : ''}${literals.filters}`}
                        color='outline-primary'
                        className='mr-1 dropdown-toggle'
                        style={{ minWidth: 'auto' }}
                        onClick={() => setShowFilters(!showFilters)}
                      />
                    )}
                  </div>
                </div>
              )
            }
          </div>
          {
            ((selectedRows.length && rowSelection?.length) || actions?.length) ? (
              <div className='actions-container'>
                {
                  (selectedRows.length && rowSelection && rowSelection.length) ? (
                    <div className='table-actions'>
                      { renderRowSelection() }
                    </div>
                  ) : null
                }
                {
                  (actions && actions.length) ? (
                    <div className='table-actions'>
                      { renderActions() }
                    </div>
                  ) : null
                }
              </div>
            ) : null
          }
        </div>
        {filters && filters.length > 0 && (
          <div className={`table-filters-advanced box ${!showFilters ? 'hidden' : ''}`}>
            <div className='row'>
              {
                filters.map((item, i) => {
                  const htmlFilter = printFilter(item);
                  if (!htmlFilter) {
                    return null;
                  }
                  return (
                    <div
                      key={i}
                      className={`col-sm-${item?.width?.sm || '12'} col-md-${item?.width?.md || '6'} col-lg-${item?.width?.lg || '4'}`}
                    >
                      {htmlFilter}
                    </div>
                  );
                })
              }
            </div>
            <div className='d-flex justify-content-end'>
              <Button
                icon={Eraser}
                text={literals.cleanFilters}
                color='secondary'
                onClick={cleanFilters}
                disabled={!numFilters}
              />
              <Button icon={Filter} text={literals.applyFilters} onClick={applyFilters} />
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderHeader = () => {
    let newColumns = [...columns.columns];

    if (rowDetails) {
      newColumns = [{
        field: 'details',
        type: 'details',
        width: 16,
        label: <PlusCircle size={16} />,
      }, ...newColumns];
    }

    if (rowSelection?.length || onSelectRows) {
      newColumns = [{
        field: 'checkbox',
        type: 'checkbox',
        title: 'checkbox',
        width: 18,
        label: <DropOptionsIcon
          buttons={[
            { id: 1, text: literals.selectPage, onClick: selectPageRows },
            { id: 2, text: literals.selectAll, onClick: selectAllRows },
            { id: 3, text: literals.unselectAll, onClick: () => { setSelectedRows([]); } },
          ]}
          isEmpty={selectedRows.length === 0}
          allSelected={rows && rows.total <= selectedRows.length}
          pageSelected={rows && rows.items.every(row => selectedRows.includes(row.id))}

        />,
      }, ...newColumns];
    }

    if (Object.keys(allowedDrag).every(key => allowedDrag[key])) {
      newColumns = [{
        type: 'drag',
        width: 15,
        label: <div className='drag-icon'><GripVertical size={20} /></div>,
      }, ...newColumns];
    }
    const headerRows = newColumns.map((header, index) => {
      const column = { ...header };
      const styles = {};
      let classes = '';
      if (column.type === 'button' || column.type === 'link') {
        column.width = 36;
        classes += ' align-center';
      } else if (column.type === 'menu') {
        column.width = 24;
      } else if (column.type === 'checkbox') {
        column.width = 18;
      }
      if (!column.width) {
        classes += ' fluid';
      }
      if (column.align) {
        classes += ` align-${column.align}`;
      }
      if (column.width) {
        styles.width = column.width;
      } else {
        styles.flex = `${column.grow ?? 1} 0 0px`;
      }
      if (column.minWidth) {
        styles.minWidth = column.minWidth;
      }

      return !column.hide && (
        <div
          key={`${column.field}-${index}`}
          onClick={column.sortable ? () => sortTable(column) : null}
          title={column?.title?.toString() || column?.label?.toString() || null}
          className={`table-header-cell ${classes} ${column.sortable ? 'cursor-pointer' : ''}`}
          style={styles}
        >
          { isLoading
            ? <Skeleton animation='wave' width={90} />
            : (column.type === 'button' && column.button?.icon)
              ? (
                React.isValidElement(column.button.icon) ? column.button.icon : <column.button.icon size={18} />
              ) : (
                <>
                  {
                    column.icon ? (
                      React.isValidElement(column.icon) ? column.icon : <column.icon size={18} />
                    ) : (
                      <div className='table-header-label'>
                        {column.label && column.label.length > 25 ? `${column.label.substring(0, 25)}...` : column.label}
                      </div>
                    )
                  }
                </>
              )
          }
          {column.sortable && (
            [column.field, column?.id].includes(sortedColumn.name) ? (
              <div className='d-flex flex-column pl-2'>
                <ChevronUp fill='currentColor' size={10} className={sortedColumn.sort !== '+' ? 'disabled' : ''} />
                <ChevronDown fill='currentColor' size={10} className={sortedColumn.sort !== '-' ? 'disabled' : ''} />
              </div>
            ) : (
              <div className='d-flex flex-column pl-2'>
                <ChevronUp fill='currentColor' size={10} className='disabled' />
                <ChevronDown fill='currentColor' size={10} className='disabled' />
              </div>
            )
          )}
        </div>
      );
    });

    return <div className='table-header'>{headerRows}</div>;
  };

  const renderRows = () => {
    let newColumns = [...columns.columns];
    if (rowDetails) {
      newColumns = [{ field: 'details', type: 'details', width: 15 }, ...newColumns];
    }
    if (rowSelection?.length || onSelectRows) {
      newColumns = [{ field: 'checkbox', type: 'checkbox', width: 18 }, ...newColumns];
    }
    const isDragAllowed = Object.keys(allowedDrag).every(key => allowedDrag[key]);
    if (isDragAllowed) {
      newColumns = [{ type: 'drag', width: 15 }, ...newColumns];
    }
    if (rows.items?.length > 0) {
      return rows.items.map((row, i) => {
        if (mode === 'rows') {
          const selected = selectFields
            ? !!selectedRows.find(auxRow => auxRow.id === row.id)
            : selectedRows.includes(row.id);

          return (
            <TableRow
              key={row.id}
              literalsCommon={literalsCommon}
              row={row}
              columns={newColumns}
              selected={selected}
              onSelectRow={() => handleSelectRow(row)}
              onUnSelectRow={() => handleUnselectRow(row)}
              onOpenPermissions={cfg => handleOpenPermissions(row, cfg)}
              onClick={onClickRow}
              draggable={isDragAllowed && !loadingOrder}
              scrollRef={scrollRef}
              rowDetails={rowDetails}
            />
          );
        }
        let rowProps = {
          key: row.id,
          ...row,
          ...componentProps,
          _tableRow: { index: i, page: actualPage },
        };
        if (componentProps) {
          rowProps = Object.assign(rowProps, componentProps);
        }
        return React.createElement(component, rowProps);
      });
    }
    if (fetchError) {
      return (
        <div className='no-results-error'>
          <img src={WarningImage} alt={emptyMessage ?? literals.noResults} />
          <p>{fetchError}</p>
        </div>
      );
    }
    return (
      <div className='no-results'>
        {
          emptyImage && (
            <img src={emptyImage} alt={emptyMessage ?? literals.noResults} />
          )
        }
        <p>{emptyMessage ?? literals.noResults}</p>
      </div>
    );
  };

  const renderLoading = () => {
    const numRows = rows !== null && rows.total > 0 && rows.total < pageSize ? rows.total : pageSize;
    if (!numRows) {
      return null;
    }
    if (mode === 'rows') {
      let newColumns = [...columns.columns];
      if (rowDetails) {
        newColumns = [{ field: 'details', type: 'details', width: 15 }, ...newColumns];
      }
      if (rowSelection?.length || onSelectRows) {
        newColumns = [{ field: 'checkbox', type: 'checkbox', width: 18 }, ...newColumns];
      }
      return Array.from(new Array(Math.max(numRows, 1))).map((e, i) => (
        <TableRow
          key={`row-${i}`}
          literalsCommon={literalsCommon}
          row={{ id: i.toString() }}
          columns={newColumns}
          loading
        />
      ));
    }

    return Array.from(new Array(Math.max(numRows, 1))).map((e, i) => React.createElement(component, {
      loading: true,
      key: i.toString(),
      id: i.toString(),
      ...componentProps,
    }));
  };

  const handleChangeActualPage = (page) => {
    setActualPage(page);
    fetchPage(page);
  };

  useEffect(() => {
    if (forceFetch) {
      fetchPage(actualPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceFetch]);

  useEffect(() => {
    if (forceFetchAuto) {
      fetchPage(actualPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, forceFetchAuto]);


  return (
    <div className={`table-component table-mode-${mode} ${className}`}>
      {renderFilters()}
      { configColumns && (
        <div className='config-columns' onClick={() => setShowConfigColumns(true)}>
          {columns.edited
            ? <ToggleRight size={18} className='primary' />
            : <ToggleLeft size={18} />}
          <span>{columns.edited ? literals.edited : literals.columns}</span>
        </div>
      )}
      <div className='table-overflow' ref={scrollRef}>
        <div className='table-fullwidth'>
          {mode === 'rows' && renderHeader()}
          <DragDropContext
            onDragEnd={async ({ draggableId, destination }) => {
              setLoadingOrder(true);
              onDrag(draggableId, destination.index)
                .then(() => setLoadingOrder(false))
                .catch(() => setLoadingOrder(false));
            }}
          >
            <div className={`table-rows listItemBox ${onClickRow || rowDetails ? 'rows-click' : ''}`}>
              <Droppable droppableId='table-rows'>
                {droppableProvider => (
                  <div
                    {...droppableProvider.droppableProps}
                    ref={droppableProvider.innerRef}
                    className={`${componentColumns ? 'grid' : ''}`}
                    style={componentColumns && (isLoading || rows?.total)
                      ? { gridTemplateColumns: `repeat(${componentColumns[width]}, 1fr)` }
                      : {}
                    }
                  >
                    {isLoading ? renderLoading() : renderRows()}
                    {droppableProvider.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        </div>
      </div>
      <div className='table-pagination'>
        {
          rows && rows.total > 0 && (

            <span // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: literalTemplate(`<span>${literals.showingTableResults}</span>`, {
                  FIRST: `<span class='highlight'>${formatNumber((actualPage * pageSize) + 1)}</span>`,
                  LAST: `<span class='highlight'>${formatNumber(Math.min((actualPage + 1) * pageSize, rows.total))}</span>`,
                  TOTAL: `<span class='highlight'>${formatNumber(rows.total)}</span>`,
                }),
              }}
              className='rows-number'
            />
          )
        }
        {rows !== null && rows.total > rows.items?.length && (
          <div className='pagination-select'>
            <Pagination
              pages={Math.ceil(rows.total / pageSize)}
              actualPage={actualPage}
              onChange={handleChangeActualPage}
            />
            <InputSelect
              options={[...Array(Math.ceil(rows.total / pageSize)).keys()].map((page) => {
                return {
                  name: page + 1,
                  id: page,
                };
              })}
              value={actualPage}
              onChange={handleChangeActualPage}
            />
          </div>
        )}
      </div>
      {
        showConfigColumns && (
          <PopupConfigTableColumns
            literals={literals}
            originalColumns={originalColumns}
            columns={columns}
            setColumns={setSavedColumns}
            tableName={configColumns}
            onClose={() => setShowConfigColumns(false)}
          />
        )
      }
      {showPermissionPopup && (
        <PermissionPopup
          title={showPermissionPopup.title}
          scope={showPermissionPopup.scope}
          element={showPermissionPopup.element}
          onClose={handleClosePermissionPopup}
        />
      )}
    </div>
  );
};

Table.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  searcher: PropTypes.bool,
  className: PropTypes.string,
  filters: PropTypes.array,
  actions: PropTypes.array,
  rowSelection: PropTypes.array,
  mode: PropTypes.string,
  columns: PropTypes.array,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  componentProps: PropTypes.object,
  componentColumns: PropTypes.object,
  fetch: PropTypes.func,
  pageSize: PropTypes.number,
  onFetch: PropTypes.func,
  forceFetch: PropTypes.any,
  forceFetchAuto: PropTypes.bool,
  fetchLocal: PropTypes.bool,
  onClickRow: PropTypes.func,
  emptyImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.bool]),
  emptyMessage: PropTypes.string,
  selectRows: PropTypes.array,
  onSelectRows: PropTypes.func,
  configColumns: PropTypes.string,
  rowDetails: PropTypes.func,
  selectFields: PropTypes.array,
  onDrag: PropTypes.func,
  externalFilterValue: PropTypes.object,

};

Table.defaultProps = {
  className: '',
  mode: 'rows',
  searcher: true,
  filters: [],
  actions: [],
  rowSelection: [],
  columns: [],
  component: null,
  componentProps: null,
  componentColumns: null,
  pageSize: 10,
  forceFetch: 0,
  forceFetchAuto: false,
  fetchLocal: false,
  fetch: null,
  onFetch: null,
  onClickRow: null,
  emptyImage: EmptyStreet,
  emptyMessage: null,
  selectRows: [],
  onSelectRows: null,
  rowDetails: null,
  configColumns: '',
  selectFields: null,
  onDrag: null,
  externalFilterValue: null,
};

export default Table;
