import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel/components/';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Table from 'components/Table';
import useDialog from 'components/Dialog/components/useDialog';
import { Eye, Trash } from 'lucide-react';
import { useCounter } from 'utils/customHooks';
import { formatDate } from 'utils/functions';
import { deleteWebhook, getWebhooks } from '../modules/actions';
import WebhookDetails from './webhookDetails';
import './styles.scss';

const AdminWebhooks = ({ literals, literalsCommon }) => {
  const [detailsPopup, setDetailsPopup] = useState(false);
  const { dialog } = useDialog();
  const tableRefreshFlag = useCounter(0);

  const handleDeleteWebhook = async (webhook) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literalsCommon.confirmDelete,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteWebhook(webhook.id);
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const filters = [
    {
      id: 'type',
      type: 'list',
      title: literalsCommon.type,
      options: [
        { id: 'captable_operation', name: literals.captable_operation },
      ],
    },
    {
      id: 'status',
      type: 'list',
      title: literalsCommon.status,
      options: [
        { id: 'success', name: literals.success },
        { id: 'error', name: literals.error },
        { id: 'sent', name: literals.sent },
      ],
    },
  ];

  const columns = [
    {
      field: 'type',
      label: literalsCommon.type,
      preRender: type => literals[type],
      width: 200,

    },
    {
      field: 'to',
      label: literals.sentTo,
    },
    {
      field: 'status',
      label: literalsCommon.status,
      preRender: status => (
        <div className='cell-keywords'>
          <div>{literals[status]}</div>
        </div>
      ),
      width: 100,
    },
    {
      field: 'createdAt',
      label: literalsCommon.createdAt,
      preRender: date => formatDate(date, { time: true }),
      width: 120,
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Eye,
          text: literalsCommon.view,
          onClick: setDetailsPopup,
        },
        {
          icon: Trash,
          text: literalsCommon.delete,
          onClick: handleDeleteWebhook,
        },
      ],
    },
  ];

  return (
    <Panel>
      <PanelHeader title={literals.title} />
      <PanelBody>
        <Table
          searcher={false}
          columns={columns}
          filters={filters}
          onClickRow={setDetailsPopup}
          fetch={getWebhooks}
          forceFetch={tableRefreshFlag.value}
        />
      </PanelBody>
      { detailsPopup && (
        <WebhookDetails
          literals={literals}
          literalsCommon={literalsCommon}
          webhook={detailsPopup}
          onClose={() => setDetailsPopup(false)}
        />
      )}
    </Panel>
  );
};

AdminWebhooks.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
};

export default AdminWebhooks;
