import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MentoringComponents from '../components';
import {
  fetchMentoringAction,
  // fetchMentoringMessagesAction,
  createMentoringMessageAction,
  fetchMentoringMeetingsAction,
  createMentoringMeetingAction,
  editMentoringMeetingAction,
  deleteMentoringMeetingAction,
  createMentoringDocumentAction,
  createMentoringReportAction,
  editMentoringReportAction,
  deleteMentoringReportAction,
  deleteMentoringDocumentAction,
} from '../modules/actions';

import { loadingAction } from '../../../store/globalState/global';

function mapStateToProps(state) {
  return {
    user: state.session.user,
    literals: state.i18n.literals.mentoring,
    mentoring: state.mentoring,
    startup: state.global.startup,
  };
}

const mapDispatchToProps = dispatch => ({
  fetchMentoring: bindActionCreators(fetchMentoringAction, dispatch),
  // fetchMentoringMessages: bindActionCreators(fetchMentoringMessagesAction, dispatch),
  createMentoringMessage: bindActionCreators(createMentoringMessageAction, dispatch),
  fetchMentoringMeetings: bindActionCreators(fetchMentoringMeetingsAction, dispatch),
  createMentoringMeeting: bindActionCreators(createMentoringMeetingAction, dispatch),
  editMentoringMeeting: bindActionCreators(editMentoringMeetingAction, dispatch),
  deleteMentoringMeeting: bindActionCreators(deleteMentoringMeetingAction, dispatch),
  createMentoringDocument: bindActionCreators(createMentoringDocumentAction, dispatch),
  deleteMentoringDocument: bindActionCreators(deleteMentoringDocumentAction, dispatch),
  createMentoringReport: bindActionCreators(createMentoringReportAction, dispatch),
  editMentoringReport: bindActionCreators(editMentoringReportAction, dispatch),
  deleteMentoringReport: bindActionCreators(deleteMentoringReportAction, dispatch),
  showLoading: bindActionCreators(loadingAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MentoringComponents);
