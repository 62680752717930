import React from 'react';
// import { ROUTE_PATH } from '..';
import './styles.scss';
import { ROUTE_PATH } from 'routes';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from 'components/Buttons/Button';

const NotFound = () => {
  const literals = useSelector(state => state.i18n.literals.notFound);
  const isEmbed = window?.location?.pathname?.startsWith('/embed/');

  return (
    <div className='NotFound'>
      <div>
        <h1>404</h1>
        <div className='content'>
          <h2>{literals.title}</h2>
        </div>
        { !isEmbed && (
          <p>
            <Link to={ROUTE_PATH.BASEROUTE}>
              <Button text={literals.goBack} />
            </Link>
          </p>
        )}
      </div>
    </div>
  );
};

export default NotFound;
