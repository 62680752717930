import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import Tool from 'components/Tool';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import useDialog from 'components/Dialog/components/useDialog';
import { useCounter } from 'utils/customHooks';
import { Pencil, Plus, Trash } from 'lucide-react';
import { ROUTE_ADMIN_PATH } from 'routes';
import { getTranslation } from 'utils/functions';
import { deleteReportTemplate, getReportTemplates } from '../modules/actions';
import './styles.scss';

const AdminReporting = (props) => {
  const {
    literals,
    literalsCommon,
    lang,
  } = props;

  const { dialog } = useDialog();
  const tableRefreshFlag = useCounter(0);

  const fetchReportTemplates = async (page, size, search, filters, sort) => {
    return getReportTemplates(page, size, search, filters, sort);
  };

  const handleDeleteTemplate = async (id) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literals.questionDeleteTemplate,
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteReportTemplate(id);
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const columns = [
    {
      field: 'name',
      grow: 2,
      label: literalsCommon.name,
      preRender: name => getTranslation(name, lang),
    },
    {
      field: 'scope',
      label: literals.scope,
      preRender: scope => `${literalsCommon[scope.type]}${scope.owner ? ` | ${scope.owner}` : ''}`,
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          type: 'Link',
          icon: Pencil,
          text: literalsCommon.edit,
          to: id => ROUTE_ADMIN_PATH.setReportingTemplate(id),
        },
        {
          icon: Trash,
          text: literalsCommon.delete,
          onClick: row => handleDeleteTemplate(row.id),
        },
      ],
    },
  ];

  const filters = [
    {
      id: 'scope.owner',
      type: 'selectUnique',
      title: literals.owner,
      options: [
        { id: 'all', name: 'Admin' },
        { id: 'not:all', name: 'Startup' },
      ],
    },
  ];

  return (
    <Tool>
      <Panel margin={0} clean>
        <PanelHeader title={literals.title} />
        <PanelBody>
          <Table
            key='table_reporting_templates'
            columns={columns}
            filters={filters}
            pageSize={10}
            fetch={fetchReportTemplates}
            forceFetch={tableRefreshFlag.value}
            actions={[
              {
                type: 'Link',
                icon: Plus,
                text: literals.newTemplate,
                to: ROUTE_ADMIN_PATH.setReportingTemplate('new'),
              },
            ]}
          />
        </PanelBody>
      </Panel>
    </Tool>
  );
};

AdminReporting.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};

export default AdminReporting;
