import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import SocialIcon from 'components/SocialIcon';
import { Skeleton } from '@mui/material';
import { ROUTE_PATH } from 'routes';
import { useNavigate } from 'react-router-dom';
import { STARTUP_RELATION_STATUS } from 'constants/startup';
import { formatNumber, getStartupVerifiedIcon } from 'utils/functions';
import { MapPin } from 'lucide-react';
import './styles.scss';

const StartupFundraiseRow = ({
  id = null,
  round = null,
  investment = null,
  referral = null,
  startup = {},
  loading = false,
  literals,
  literalsOnBoarding,
  literalsCommon,
}) => {
  const navigate = useNavigate();

  const {
    startupId,
    name,
    description,
    logo,
    verified,
    legalName,
    country,
    city,
    url,
    social,
    relation,
  } = startup;

  if (loading) {
    return (
      <div key={startupId}>
        <div className='startup loading'>
          <div className='company_header'>
            <Skeleton animation='wave' variant='circular' width={60} height={60} />
            <div className='company_info'>
              <span className='company_name'>
                <Skeleton animation='wave' width={150} height={30} />
              </span>
            </div>
          </div>
          <div>
            <Skeleton animation='wave' height={20} />
            <Skeleton animation='wave' height={20} />
          </div>
          <div className='d-flex'>
            <Skeleton animation='wave' width={50} height={15} />
            <Skeleton animation='wave' width={50} height={15} className='ml-2' />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div key={id} onClick={() => navigate(ROUTE_PATH.setFundraise(id))}>
      <div className={`startup ${relation?.status === STARTUP_RELATION_STATUS.ACCEPTED ? STARTUP_RELATION_STATUS.ACCEPTED : STARTUP_RELATION_STATUS.PENDING}`}>
        <div className='d-flex justify-content-between'>
          <div className='company_header'>
            <Avatar title={name} src={logo?.path} size={50} />
            <div className='company_info'>
              <span className='company_name'>
                {name}
                {getStartupVerifiedIcon(verified)}
              </span>
              {legalName && (
                <span className='company_legal_name'>{legalName}</span>
              )}
              {(country || city) && (
                <span className='company_country'>
                  <MapPin size={12} />
                  {[city, literalsOnBoarding.countries[country]].filter(Boolean).join(', ')}
                </span>
              )}
            </div>
          </div>
          {
            investment && (
              <div className='investment-amount'>
                {/* <PiggyBank /> */}
                <span>{formatNumber(investment.amount, 0, { symbol: literalsCommon.currenciesSymbols[startup.currency] })}</span>
              </div>
            )
          }
        </div>
        { (url || (social && Object.values(social).some(Boolean))) && (
          <div className='company_social'>
            {url && <SocialIcon type='url' link={url} />}
            {Object.keys(social || {})
              .sort((a, b) => (b === 'other' ? -1 : 1))
              .map(s => social[s] && <SocialIcon key={s} type={s} link={social[s]} />)
            }
          </div>
        )}
        <div className='company_description'>{description}</div>
        <div className='company_fundraise'>
          <div className='company_fundraise_seed'>
            <span>{`${literals.round}:`}</span>
            <span className='round-keyword'>{literals[round]}</span>
          </div>
          { referral && (
            <span className='fs-sm mt-1'>{`${literals.referredBy} ${referral.firstname} ${referral.lastname}`}</span>
          )}
        </div>
      </div>
    </div>
  );
};

StartupFundraiseRow.propTypes = {
  id: PropTypes.string,
  round: PropTypes.string,
  investment: PropTypes.object,
  referral: PropTypes.object,
  startup: PropTypes.object,
  loading: PropTypes.bool,
  literals: PropTypes.object.isRequired,
  literalsOnBoarding: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
};

export default StartupFundraiseRow;
