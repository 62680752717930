import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './pagination.scss';

const Pagination = ({
  actualPage,
  onChange,
  pages,
  pageRange: propPageRange = 2,
}) => {
  const [page, setPage] = useState(actualPage);
  const [pageRange, setPageRange] = useState(propPageRange);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setPageRange(1);
      } else {
        setPageRange(propPageRange);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propPageRange]);

  const changePage = (newPage) => {
    if (newPage >= 0 && newPage < pages) {
      setPage(newPage);
      onChange(newPage);
    }
  };

  const generatePageLink = pageNumber => (
    <li
      key={pageNumber}
      className={`page-item ${pageNumber === page ? 'active' : ''}`}
      onClick={() => changePage(pageNumber)}
    >
      <span className='page-link'>{pageNumber + 1}</span>
    </li>
  );

  const generatePageLinks = (start, end) => (
    Array.from({ length: end - start + 1 }, (_, index) => (
      generatePageLink(start + index)
    ))
  );

  const generateEllipsis = () => (
    <li key='ellipsis' className='page-item'>
      <span className='page-link'>...</span>
    </li>
  );

  const generatePages = () => {
    if (pages <= 1) {
      return null;
    }

    if (pages <= 5) {
      return generatePageLinks(0, pages - 1);
    }

    if (page <= pageRange) {
      return [
        ...generatePageLinks(0, pageRange * 2),
        generateEllipsis(),
        generatePageLink(pages - 1),
      ];
    }

    if (page >= pages - 1 - pageRange) {
      return [
        generatePageLink(0),
        generateEllipsis(),
        ...generatePageLinks(pages - 1 - pageRange * 2, pages - 1),
      ];
    }

    return [
      generatePageLink(0),
      generateEllipsis(),
      ...generatePageLinks(page - pageRange, page + pageRange),
      generateEllipsis(),
      generatePageLink(pages - 1),
    ];
  };

  return (
    <ul key={page} className='pagination-grid'>
      <li
        key='prev'
        className={`page-item first ${page === 0 ? 'disabled' : ''}`}
        onClick={() => changePage(page - 1)}
      >
        <span className='page-link'>&lt;</span>
      </li>
      {generatePages()}
      <li
        key='next'
        className={`page-item last ${page === pages - 1 ? 'disabled' : ''}`}
        onClick={() => changePage(page + 1)}
      >
        <span className='page-link'>&gt;</span>
      </li>
    </ul>
  );
};

Pagination.propTypes = {
  actualPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  pages: PropTypes.number.isRequired,
  pageRange: PropTypes.number,
};

export default Pagination;
