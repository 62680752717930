import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LeftMenu from 'components/LeftMenu';
import Footer from 'components/Footer';
import Loading from 'components/Loading';
import ImpersonateBar from 'components/ImpersonateBar';
import TopBar from 'components/TopBar';
import EmbedTopBar from 'components/EmbedTopBar';
import Unauthorized from 'routes/Unauthorized';
import { ROUTE_PATH, ROUTE_STARTUP_PATH } from 'routes';
import { Navigate, useLocation, useMatch } from 'react-router-dom';
import { startupCan } from 'utils/functions';
import { useWidth } from 'utils/customHooks';
import { getBrowserPreferences } from 'utils/browser';
import { useSelector } from 'react-redux';
import { TOOL_CAP_TABLE, TOOL_INVESTMENTS, TOOL_PERFORMANCE } from 'constants/tools';
import usePreComponent from '../hooks/usePreComponent';
import InviteFounderButton from './InviteFounderButton';

const RenderView = (props) => {
  const {
    user,
    setGlobalProperties,
    route,
    isAuthenticated,
    platform,
  } = props;

  const topBarLiterals = useSelector(state => state.i18n.literals.topBar);
  const leftMenuLiterals = useSelector(state => state.i18n.literals.leftMenu);
  const literalsCommon = useSelector(state => state.i18n.literals.common);
  const startup = useSelector(state => state.global.startup);
  const companies = useSelector(state => state.companies);

  const [showAside, setShowAside] = useState(getBrowserPreferences('menuVisible', false));
  const match = useMatch(route.path);
  const location = useLocation();
  const width = useWidth();

  usePreComponent({
    startup, match, setGlobalProperties, literalsCommon,
  });

  if (!route.ignoreSession && !isAuthenticated) {
    return <Navigate to={ROUTE_PATH.LOGIN} />;
  }

  const handleCloseAside = () => {
    if (['xs', 'sm'].includes(width)) {
      setShowAside(false);
    }
  };

  const { BASE, setEmbed } = ROUTE_STARTUP_PATH;
  if ((match?.pathname?.startsWith(BASE) || match?.pathname?.startsWith(setEmbed(BASE)))
    && match.params.id !== (startup?.id || startup?.externalId)) {
    return <Loading hide={false} />;
  }

  if (route.permission && match.params && match.params.id && !startupCan(route.permission.level, startup, route.permission.tool)) {
    return <Unauthorized />;
  }
  const isEmbed = route?.path?.startsWith('/embed');
  return (
    <>
      {user.impersonate && (
        <ImpersonateBar
          literals={topBarLiterals}
          literalsCommon={literalsCommon}
          user={user}
        />
      )}
      {route.header ? (
        <TopBar
          literals={topBarLiterals}
          showAside={showAside}
          numberOfNotifications={17}
          user={user}
          leftmenuDisabled={!route.aside}
          companies={companies}
          toggleMenu={() => setShowAside(prev => !prev)}
          pathId={match?.params?.id}
          width={width}
          match={match}
        />
      ) : (
        isEmbed && (
          <>
            <EmbedTopBar
              literals={leftMenuLiterals}
              startup={startup}
              user={user}
              type={route.embed}
            />
            {
              !startup?.founder && [TOOL_CAP_TABLE, TOOL_PERFORMANCE].includes(route.embed) && (
                <InviteFounderButton
                  literalsCommon={literalsCommon}
                  startup={startup}
                />
              )
            }
          </>
        )
      )}
      {route.aside && (
        <LeftMenu
          showAside={showAside}
          toggleMenu={handleCloseAside}
          platform={platform}
          route={route}
        />
      )}
      <div className={`main device-${width} ${!route.aside ? 'noAside' : ''} ${route.aside && !showAside ? 'hideAside' : ''} ${!route.header && ![TOOL_CAP_TABLE, TOOL_INVESTMENTS].includes(route.embed) ? 'noHeader' : ''} ${[TOOL_CAP_TABLE, TOOL_PERFORMANCE].includes(route.embed) ? 'invite-founder' : ''} ${user.impersonate ? 'impersonate' : ''} ${isEmbed ? 'embed' : ''} ${route.className ?? ''}`}>
        <route.component
          embed={isEmbed}
          match={match}
          location={location}
          route={route.path}
          {...route.props}
        />
      </div>
      { !isEmbed && <Footer /> }
    </>
  );
};

RenderView.propTypes = {
  topBarLiterals: PropTypes.object.isRequired,
  leftMenuLiterals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  companies: PropTypes.object.isRequired,
  startup: PropTypes.object,
  setGlobalProperties: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  showAside: PropTypes.bool.isRequired,
  platform: PropTypes.object.isRequired,
  expandAside: PropTypes.func.isRequired,
};

RenderView.defaultProps = {
  startup: null,
};

export default RenderView;
