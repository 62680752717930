import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Loading from 'components/Loading';
import Button from 'components/Buttons/Button';
import IOFileLoading from 'components/IOFileLoading/components';
import EmptyCaptable from 'assets/svg/empty_captable.svg';
import InputSelect from 'components/Inputs/inputSelect';
import StartupCaptableDate from 'components/StartupCaptableDate/components';
import { Download, PlusCircle } from 'lucide-react';
import { Link, useSearchParams } from 'react-router-dom';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import { AmplitudeApi } from 'utils/amplitude';
import { formatDate, formatNumber } from 'utils/functions';
import { downloadStatutoryBookExcel, getStatutoryBook } from '../modules/actions';
import { initializeCaptable } from '../../../../modules/actions';
import './styles.scss';

const StatutoryBooks = (props) => {
  const {
    literals, match, captable, fetchCaptable, startup,
  } = props;

  const [params] = useSearchParams();
  const captableId = params.get('captable') || null;
  const firstDate = params.get('date') || formatDate(new Date(), { format: 'Y-m-d' });
  const firstGroupBy = params.get('groupBy');

  const [data, setData] = useState({});
  const [operations, setOperations] = useState([]);
  const [date, setDate] = useState(firstDate);
  const [groupBy, setGroupBy] = useState(firstGroupBy || 'shareholder');
  const [loading, setLoading] = useState(true);
  const [downloadingExcel, setDownloadingExcel] = useState(false);

  useEffect(() => {
    if (captable?.selected?.id) {
      AmplitudeApi.logEvent('startup.captable.statutoryBook.view', {
        captable: captable?.selected?.id,
      });
    }
  }, [captable?.selected?.id]);

  const fetchOperations = async (auxDate) => {
    const {
      shareholders, syndicates, operations: auxOperations,
    } = await getStatutoryBook(match.params.id, auxDate, { captable: captableId });
    setData(groupBy === 'shareholder' ? shareholders : syndicates);
    setOperations(auxOperations);
    setLoading(false);
  };

  useEffect(() => {
    if (captable.loaded) {
      setLoading(true);
      setData({});
      setOperations([]);
      fetchOperations(date);
    } else {
      initializeCaptable(fetchCaptable, captable, match.params.id, captableId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captable, date, groupBy]);

  const getDiff = (actual, prev) => {
    if (prev) {
      return actual.number - prev.number ? formatNumber(actual.number - prev.number, '', { dec: 2 }) : '';
    }
    return actual.number > 0 ? formatNumber(actual.number, '', { dec: 2 }) : '';
  };

  const handleDownloadExcel = async () => {
    setDownloadingExcel(true);
    await downloadStatutoryBookExcel(match.params.id, captableId, date, 'statutory_book.xlsx');
    setDownloadingExcel(false);
    AmplitudeApi.logEvent('startup.captable.statutoryBook.click.downloadButton', {
      captable: captable?.selected?.id,
    });
  };

  if (loading) {
    return (
      <Tool>
        <Panel>
          <PanelHeader title={literals.statutoryBook} />
          <PanelBody>
            <Loading hide={false} mode='panel' />
          </PanelBody>
        </Panel>
      </Tool>
    );
  }

  return (
    <Tool>
      <Panel>
        <PanelHeader title={literals.statutoryBook} />
        <PanelBody className='pt-0'>
          <div className='statutory-book-wrapper'>
            {operations.length ? (
              <>
                <div className='buttons pb-3'>
                  <Button
                    color='secondary'
                    text='Excel'
                    icon={Download}
                    onClick={handleDownloadExcel}
                  />
                </div>
                <div className='d-flex justify-content-between'>
                  <StartupCaptableDate
                    literals={literals}
                    startup={startup}
                    captable={captable}
                    date={date}
                    onChange={d => setDate(d)}
                  />
                  <InputSelect
                    className='statutory-select-group'
                    preText={literals.lang}
                    placeholder={literals.lang}
                    onChange={v => setGroupBy(v)}
                    value={groupBy}
                    options={[
                      { id: 'shareholder', name: literals.byShareholder },
                      { id: 'syndicate', name: literals.bySyndicate },
                    ]}
                    isDisabled={!data}
                  />
                </div>
                <div className='statutory-table'>
                  <div className='statutory-table-left'>
                    <h6 className='statutory-table-cell empty'>{' '}</h6>
                    <h6 className='statutory-table-cell'>{literals.data}</h6>
                    { data.map((sh, i) => (
                      <div
                        key={`header-${i}`}
                        className={`statutory-table-cell ${sh.name === 'Total' ? 'fw-b' : ''}`}
                        title={sh.name}
                      >
                        {sh.name}
                      </div>
                    ))}
                  </div>
                  <div className='statutory-table-right'>
                    <div className='statutory-table-row'>
                      {operations.map(op => (
                        <h6 key={`header-${op.id}`} className='statutory-table-cell large'>
                          <div title={op.name} className='op-name'>{op.name}</div>
                          <div>{formatDate(op.date)}</div>
                        </h6>
                      ))}
                    </div>
                    <div className='statutory-table-row'>
                      {operations.map((_, i) => (
                        <Fragment key={`operation-${i}`}>
                          <h6 className='statutory-table-cell'>{literals.new}</h6>
                          <h6 className='statutory-table-cell'>{literals.total}</h6>
                          <h6 className='statutory-table-cell porc'>%</h6>
                        </Fragment>
                      ))}
                    </div>
                    {data.map((sh, i) => (
                      <div key={`${i}-${sh.name}`} className={`statutory-table-row ${sh.name === 'Total' ? 'total' : ''}`}>
                        {sh.operations.map((shOp, j) => (
                          <div key={`${i}-${sh.name}-${j}`} className='d-flex justify-content-between w-100'>
                            <div className={`statutory-table-cell ${i % 2 === 0 ? 'odd' : ''}`}>
                              {getDiff(shOp, sh.operations[j - 1])}
                            </div>
                            <div className={`statutory-table-cell ${i % 2 === 0 ? 'odd' : ''}`}>
                              {shOp.number > 0 ? formatNumber(shOp.number, '2', { dec: 2 }) : ''}
                            </div>
                            <div className={`statutory-table-cell ${i % 2 === 0 ? 'odd porc' : 'porc'}`}>
                              {shOp.ownership > 0 ? formatNumber(shOp.ownership, '', { dec: 2, symbol: '%' }) : ''}
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
                { downloadingExcel && <IOFileLoading mode='download' file={{ name: 'statutory_book.xlsx' }} /> }
              </>
            ) : (
              <div className='panel-empty-message full'>
                <img className='arrow-search' src={EmptyCaptable} alt='' />
                <p>{literals.noSharesOperation}</p>
                <Link to={embedView(ROUTE_STARTUP_PATH.setCapTableOperation(match.params.id, captableId, 'new'))}>
                  <Button icon={PlusCircle} text={literals.createOperation} />
                </Link>
              </div>
            )}
          </div>
        </PanelBody>
      </Panel>
    </Tool>
  );
};

StatutoryBooks.propTypes = {
  literals: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  fetchCaptable: PropTypes.func.isRequired,
};

export default StatutoryBooks;
