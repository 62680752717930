import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from 'components/Breadcrumb';
import Button from 'components/Buttons/Button';
import ButtonDropdown from 'components/Buttons/ButtonDropdown';
import InputSelect from 'components/Inputs/inputSelect';
import { useLocation, useNavigate } from 'react-router-dom';

function PanelHeader(props) {
  const {
    breadcrumb, title, subtitle, actions, onTab,
    tabs, justify, noBody, actionsFirst, activeTab,
    hash: changeHash,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const handleOnTab = (id) => {
    if (onTab) {
      onTab(id);
      if (changeHash) {
        navigate({ hash: id, search: location.search });
      }
    }
  };

  useEffect(() => {
    if (!activeTab && activeTab !== 0) {
      handleOnTab(tabs.find(tab => !tab.hide)?.key);
    }

    if (changeHash) {
      const hash = location?.hash?.slice(1);
      if (hash) {
        if (tabs?.find(tab => tab.key === hash)) {
          onTab(hash);
        } else {
          onTab(activeTab);
          navigate({ hash: activeTab }, { replace: true });
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTabs = () => {
    return tabs && tabs.length ? (
      <>
        <div className='panel-heading-tabs'>
          {
            tabs.filter(tab => !tab.hide).map((tab, i) => {
              return (
                <div
                  key={tab.key}
                  className={[tab.key, i].includes(activeTab) ? 'active' : ''}
                  onClick={() => handleOnTab(tab.key)}
                >
                  {tab.name}
                </div>
              );
            })
          }
        </div>
        <div className='panel-heading-tabs-selector'>
          <InputSelect
            className='w-100'
            onChange={tab => handleOnTab(tab)}
            value={tabs[activeTab] || activeTab}
            isRequired
            options={tabs.map((tab) => {
              return {
                id: tab.key,
                name: tab.name,
              };
            })}
          />
        </div>
      </>
    ) : null;
  };
  const haveTitle = breadcrumb || title || subtitle;
  const formatTitle = typeof title !== 'string' ? title?.[activeTab] || '' : title;
  const formatSubtitle = typeof subtitle !== 'string' ? subtitle?.[activeTab] || '' : subtitle;
  return (
    <div className={`panel-heading ${justify ? `justify-${justify}` : ''} ${noBody ? 'noBody' : ''}`}>
      {
        haveTitle ? (
          <div className='panel-heading-info'>
            {
              breadcrumb ? (
                <Breadcrumb routes={Array.isArray(breadcrumb) ? breadcrumb : []} />
              ) : (
                title && (<h4 className='fw-b'>{formatTitle}</h4>)
              ) }
            { subtitle && (<p>{formatSubtitle}</p>) }
          </div>
        ) : null
      }
      {
        ((tabs && tabs.length) || (actions && actions.length)) ? (
          <div className={`panel-heading-right ${!haveTitle ? `noTitle justify-${justify}` : ''}`}>
            {!actionsFirst && renderTabs()}
            {
              actions.length > 0 && (
                <div className='panel-heading-actions'>
                  {
                    actions.map((action, i) => {
                      if (action.buttons) {
                        return <ButtonDropdown key={i} {...action} />;
                      }
                      return <Button key={i} {...action} />;
                    })
                  }
                </div>
              )
            }
            {actionsFirst && renderTabs()}
          </div>
        ) : null
      }
    </div>
  );
}

PanelHeader.displayName = 'PanelHeader';
PanelHeader.propTypes = {
  breadcrumb: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.string,
  actions: PropTypes.array,
  tabs: PropTypes.array,
  justify: PropTypes.string,
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onTab: PropTypes.func,
  noBody: PropTypes.bool,
  actionsFirst: PropTypes.bool,
  hash: PropTypes.bool,
};

PanelHeader.defaultProps = {
  breadcrumb: false,
  title: '',
  subtitle: '',
  actions: [],
  tabs: [],
  activeTab: 0,
  justify: '',
  onTab: null,
  noBody: false,
  actionsFirst: false,
  hash: false,
};

export default PanelHeader;
