import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCaptableAction } from 'routes/Captable/modules/actions';
import { deleteOperationAction } from 'routes/Captable/components/routes/Operation/modules/actions';
import { editOperationDraftAction, editOperationTimelineAction } from '../../../../modules/actions';
import CaptableSimulationOperations from '../components';

const mapStateToProps = state => ({
  literalsCommon: state.i18n.literals.common,
  literals: state.i18n.literals.captable,
  captable: state.captable,
  startup: state.global.startup,
});

const mapDispatchToProps = dispatch => ({
  fetchCaptable: bindActionCreators(fetchCaptableAction, dispatch),
  editOperationTimeline: bindActionCreators(editOperationTimelineAction, dispatch),
  editOperationDraft: bindActionCreators(editOperationDraftAction, dispatch),
  deleteOperation: bindActionCreators(deleteOperationAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaptableSimulationOperations);
