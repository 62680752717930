import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import Question from 'components/Question';
import Button from 'components/Buttons/Button';
import useDialog from 'components/Dialog/components/useDialog';
import { useSelector } from 'react-redux';
import { getTranslation } from 'utils/functions';
import {
  createDefaultQuestionsFund, createDefaultQuestionsInvestor,
  createDefaultQuestionsMentor, createDefaultQuestionsStartup,
} from 'constants/defaultQuestionsOnboarding';
import './styles.scss';

export const getForm = (id, form) => {
  const splitId = id.split('.');
  const firstKey = splitId.shift();
  if (splitId.length > 0) {
    return getForm(splitId.join('.'), form[firstKey]);
  }
  return form?.[firstKey];
};

export const setForm = (id, value, form) => {
  const auxForm = { ...form };
  const splitId = id.split('.');
  const firstKey = splitId.shift();
  if (splitId.length > 0) {
    auxForm[firstKey] = setForm(splitId.join('.'), value, auxForm[firstKey]);
  } else {
    auxForm[firstKey] = value;
  }
  return auxForm;
};

const InternalForm = (props) => {
  const {
    lang,
    literals,
    forms,
    type,
    platform,
    answers,
    page,
    size,
    onChange,
    onLoad,
    onSubmit,
    buttons,
    admin,
    formLoading,
    fetchFormMentor,
    fetchFormInvestor,
    fetchFormStartup,
    fetchFormFund,
  } = props;

  const [questions, setQuestions] = useState(null);
  const [formAnswers, setFormAnswers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { dialog } = useDialog();
  const literalsCommon = useSelector(state => state.i18n.literals.common);

  useEffect(() => {
    if (forms[type]) {
      if (!forms[type].loaded && !forms[type].loading) {
        switch (type) {
          case 'startup': fetchFormStartup(); break;
          case 'fund': fetchFormFund(); break;
          case 'mentor': fetchFormMentor(); break;
          case 'investor': fetchFormInvestor(); break;
          default:
            break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQuestionValue = (question, isDefault) => {
    let defaultValue;
    switch (question.type) {
      case 'avatar':
      case 'file':
      case 'document': defaultValue = {}; break;
      case 'list':
      case 'checkbox': defaultValue = []; break;
      case 'number': defaultValue = null; break;
      case 'multiValue': defaultValue = []; break;
      default: defaultValue = '';
    }
    let value = defaultValue;
    if (typeof answers === 'object') {
      if (isDefault) {
        value = getForm(question.id, answers) ?? defaultValue;
      } else if (typeof answers.extra === 'object') {
        value = answers.extra[question.id]?.answer ?? defaultValue;
      }

      if (value === null || value === undefined
        || (Array.isArray(defaultValue) && !Array.isArray(value))
        || (question.type === 'file' && typeof value !== 'object')
        || (question.type === 'document' && typeof value !== 'object')
        || (question.type === 'select' && !['boolean', typeof defaultValue].includes(typeof value))
        || (question.type === 'number' && typeof value !== 'number')) {
        value = defaultValue;
      }
    }

    return value;
  };

  useEffect(() => {
    if (forms[type] && forms[type].loaded) {
      let defaultQuestions = [];
      const platformQuestions = platform.properties.defaultQuestions.startup;
      if (type === 'startup') {
        defaultQuestions = createDefaultQuestionsStartup(literals, literalsCommon, platformQuestions, admin);
        if (!platform.properties.tools.capTable) {
          defaultQuestions = defaultQuestions.filter(q => q.info.id !== 'legalForm');
          if (!platform.properties.tools.performance) {
            defaultQuestions = defaultQuestions.filter(q => q.info.id !== 'currency');
          }
        }
        if (!admin) {
          defaultQuestions = defaultQuestions.filter(q => !q.admin);
        }
      } else if (type === 'fund') {
        const platformQuestionFund = platform.properties.defaultQuestions.fund;
        defaultQuestions = createDefaultQuestionsFund(literals, platformQuestionFund);
      } else if (type === 'mentor') {
        const platformQuestionsMentor = platform.properties.defaultQuestions.mentor;
        defaultQuestions = createDefaultQuestionsMentor(literals, platformQuestionsMentor);
      } else if (type === 'investor') {
        defaultQuestions = createDefaultQuestionsInvestor(literals);
      }
      const allQuestions = [...defaultQuestions, ...forms[type].questions];
      let auxFormAnswers = { extra: {} };
      allQuestions.forEach((question) => {
        if (question.default) {
          if (question.info.inputs) {
            question.info.inputs.forEach((subQuestion) => {
              auxFormAnswers = setForm(subQuestion.id, getQuestionValue(subQuestion, true), auxFormAnswers);
            });
          } else {
            auxFormAnswers = setForm(question.info.id, getQuestionValue(question.info, true), auxFormAnswers);
          }
        } else {
          auxFormAnswers.extra[question.info.id] = getQuestionValue(question.info, false);
        }
      });
      setFormAnswers(auxFormAnswers);
      setQuestions(allQuestions);
      if (typeof onLoad === 'function') {
        onLoad(allQuestions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);

  const getPageQuestions = () => {
    let auxQuestions = [...questions];
    if (size) {
      auxQuestions = auxQuestions.slice(page * size, (page * size) + size);
    }
    return auxQuestions;
  };

  const handleOnChange = (id, value) => {
    let newFormAnswers = { ...formAnswers };
    if (Object.keys(formAnswers).includes(id.split('.')[0])) {
      newFormAnswers = setForm(id, value, newFormAnswers);
    } else {
      newFormAnswers.extra[id] = value;
    }
    setFormAnswers(newFormAnswers);
    if (typeof onChange === 'function') {
      onChange(newFormAnswers);
    }
  };

  const validateSubmit = async () => {
    const auxErrors = {};

    let formatAnswers = {};
    const auxQuestions = getPageQuestions();

    auxQuestions.forEach((question) => {
      let formattedValue = question.default ? getForm(question.info.id, formAnswers) : formAnswers.extra[question.info.id];

      try {
        if (question.info.mandatory && (!formattedValue || (Array.isArray(formattedValue) && formattedValue.length === 0) || (typeof formattedValue === 'object' && !Object.entries(formattedValue).length))) {
          auxErrors[question.info.id] = ['mandatory'];
        }
      } catch (e) {
        console.error(e);
      }

      if (question.info.type === 'file' || question.info.type === 'avatar') {
        if (typeof formattedValue === 'object') {
          formattedValue = formattedValue?.id ?? '';
        } else {
          formattedValue = '';
        }
      } else if (question.info.type === 'number') {
        // eslint-disable-next-line no-restricted-globals
        formattedValue = isNaN(formattedValue) ? null : +formattedValue;
      }

      if (question.default) {
        formatAnswers = setForm(question.info.id, formattedValue, formatAnswers);
      } else {
        if (!formatAnswers.extra) {
          formatAnswers.extra = {};
        }
        formatAnswers.extra[question.info.id] = formattedValue;
      }
    });

    if (Object.keys(auxErrors).length) {
      setErrors(auxErrors);

      await dialog({
        type: 'error',
        text: literalsCommon.checkFormErrors,
      });

      return false;
    }
    if (Object.keys(errors).length) {
      setErrors({});
    }

    return formatAnswers;
  };

  const renderQuestions = () => {
    const auxQuestions = getPageQuestions();

    return auxQuestions.map((question) => {
      if (question.info.inputs) {
        return (
          <div key={question.info.id} className='question-grid'>
            <h6 className='question-grid--title'>{getTranslation(question.info.title, lang)}</h6>
            <div className='question-grid--inputs'>
              {question.info.inputs.map((q) => {
                const { answers: questionAnswers, filterAnswers } = q;
                const newAnswers = filterAnswers ? filterAnswers(questionAnswers, formAnswers) : questionAnswers;
                return (
                  <Question
                    key={q.id}
                    question={{ ...q, answers: newAnswers }}
                    lang={lang}
                    value={getForm(q.id, formAnswers)}
                    onChange={handleOnChange}
                    errors={errors[q.id]}
                    disabled={filterAnswers && !newAnswers.length}
                  />
                );
              })}
            </div>
          </div>
        );
      }
      const { answers: questionAnswers, filterAnswers } = question.info;
      const newAnswers = filterAnswers ? filterAnswers(questionAnswers, formAnswers) : questionAnswers;
      const value = question.default ? getForm(question.info.id, formAnswers) : formAnswers.extra[question.info.id];
      return (
        <Question
          key={question.id}
          question={{ ...question.info, answers: newAnswers }}
          lang={lang}
          value={value}
          onChange={handleOnChange}
          errors={errors[question.info.id]}
          disabled={filterAnswers && !newAnswers.length}
        />
      );
    });
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    const formatAnswers = await validateSubmit();
    if (formatAnswers === false) {
      return false;
    }

    let submitFunc = onSubmit;
    if (buttons && buttons.length) {
      buttons.forEach((button) => {
        if (button.type === 'submit') {
          submitFunc = button.onClick;
        }
      });
    }

    if (typeof submitFunc === 'function') {
      setLoading(true);
      await submitFunc(formatAnswers);
      setLoading(false);
    }
    return true;
  };

  return (
    <form className='internal-form' onSubmit={handleSubmit}>
      {
        questions === null || formLoading ? (
          <Loading hide={false} mode='tool' />
        ) : (
          renderQuestions()
        )
      }
      <div className='buttons'>
        {
          buttons && buttons.length ? (
            buttons.map((button, i) => {
              const btn = { ...button };
              if (btn.type === 'submit') {
                delete btn.onClick;
              }
              return <Button key={`internal-btn-${i}`} {...btn} loading={loading} />;
            })
          ) : (
            <Button text='submit' type='submit' loading={loading} />
          )
        }
      </div>
    </form>
  );
};

InternalForm.propTypes = {
  lang: PropTypes.string.isRequired,
  literals: PropTypes.object.isRequired,
  forms: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
  type: PropTypes.string,
  answers: PropTypes.object,
  formLoading: PropTypes.bool,
  page: PropTypes.number,
  size: PropTypes.number,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onLoad: PropTypes.func,
  fetchFormMentor: PropTypes.func.isRequired,
  fetchFormInvestor: PropTypes.func.isRequired,
  fetchFormStartup: PropTypes.func.isRequired,
  fetchFormFund: PropTypes.func.isRequired,
  buttons: PropTypes.array,
  admin: PropTypes.bool,
};

InternalForm.defaultProps = {
  type: 'startup',
  answers: {},
  formLoading: false,
  onChange: null,
  onSubmit: null,
  onLoad: null,
  page: 0,
  size: 0,
  buttons: [],
  admin: false,
};

export default InternalForm;
