import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Panel from 'components/Panel';
import Button from 'components/Buttons/Button';
import InputTextArea from 'components/Inputs/inputTextArea';
import useDialog from 'components/Dialog/components/useDialog';
import OutputErrors from 'components/Inputs/outputErrors';
import Breadcrumb from 'components/Breadcrumb';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import { ROUTE_PATH } from 'routes';
import { useNavigate } from 'react-router-dom';
import { sendSupportMessage } from '../modules/actions';

function Help({ literals }) {
  const [errors, setErrors] = useState({});
  const [helpInfo, setHelpInfo] = useState('');
  const [loading, setLoading] = useState(false);
  const { dialog } = useDialog();
  const navigate = useNavigate();

  const goBack = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate(ROUTE_PATH.PREHOME);
    }
  };

  const submitHelp = async () => {
    try {
      setLoading(true);
      await sendSupportMessage(helpInfo);
      setHelpInfo('');
      await dialog({
        type: 'success',
        title: literals.sent,
      });
      goBack();
    } catch (error) {
      setErrors(error);
    }
    setLoading(false);
  };

  return (
    <Tool title={literals.help} noAside>
      <Breadcrumb goBack />
      <Panel>
        <PanelHeader title={literals.help} />
        <PanelBody name={literals.help}>
          <div className='help-wrapper'>
            <InputTextArea
              preText={literals.form}
              onChange={v => setHelpInfo(v)}
              value={helpInfo}
              errors={errors?.message}
              minHeight={150}
            />
          </div>
          <OutputErrors errors={errors} />
          <div className='buttons'>
            <Button text={literals.submit} onClick={submitHelp} loading={loading} />
            <Button text={literals.cancel} mode='cancel' onClick={goBack} />
          </div>
        </PanelBody>
      </Panel>
    </Tool>
  );
}

Help.propTypes = {
  literals: PropTypes.object.isRequired,
};

export default Help;
