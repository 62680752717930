import TablesInstance from 'modules/tables/repository';

export function postTable(id, columns) {
  return TablesInstance.postTable(id, { columns });
}

export function getTable(id, originalColumns) {
  return TablesInstance.getTable(id)
    .then((table) => {
      const existingColumnsMap = {};
      table.columns.forEach((col) => {
        existingColumnsMap[col.id] = col;
      });

      const originalColumnsMap = {};
      originalColumns.forEach((col) => {
        originalColumnsMap[col.id || col.field] = col;
      });
      const updatedColumns = table.columns.map(col => ({
        ...originalColumnsMap[col.id || col.field] || {},
        ...col,
      }));

      originalColumns.forEach((origCol) => {
        if (!existingColumnsMap[origCol.id || origCol.field]) {
          updatedColumns.push(origCol);
        }
      });
      return updatedColumns;
    })
    .catch(() => null);
}

export function deleteTable(id) {
  return TablesInstance.deleteTable(id).catch(() => null);
}
