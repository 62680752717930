import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Table from 'components/Table/';
import { formatDate, getFullName } from 'utils/functions';
import { getDocumentViews } from '../modules/actions';

const PopupViewsFile = ({ literals, file, onClose }) => {
  const getViews = (page, size) => {
    return getDocumentViews(file.id, { page, size });
  };

  const columnsViewer = [
    {
      field: 'createdBy',
      type: 'avatar',
      avatar: { type: 'user' },
      width: 45,
      preRender: elem => elem.avatar,
      title: v => getFullName(v),
    },
    {
      field: 'createdBy',
      label: literals.user,
      width: 180,
      preRender: v => getFullName(v),
    },
    {
      field: 'version',
      label: literals.version,
      width: 50,
      // eslint-disable-next-line no-nested-ternary
      preRender: v => (v ? (v.version ? `V${v.version}` : literals.actual) : '-'),
    },
    {
      field: 'version',
      label: literals.file,
      width: 180,
      preRender: v => v?.name || '-',
    },
    {
      field: 'createdAt',
      label: literals.date,
      width: 90,
      title: (elem, row) => formatDate(row.createdAt),
      preRender: elem => formatDate(elem),
    },
  ];

  return (
    <Popup title={`${literals.fileViews} (${file.name})`} onClose={onClose}>
      <Table columns={columnsViewer} pageSize={10} fetch={getViews} />
    </Popup>
  );
};

PopupViewsFile.propTypes = {
  literals: PropTypes.object.isRequired,
  file: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupViewsFile;
