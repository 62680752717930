import React from 'react';
import PropTypes from 'prop-types';
import InputNumber from 'components/Inputs/inputNumber';
import InputSelect from 'components/Inputs/inputSelect';

const PopupFormShareClassDividend = (props) => {
  const {
    dividend, onChange, literals, isDisabled,
  } = props;

  const selectFrecuency = [
    { id: 'day', name: literals.day },
    { id: 'month', name: literals.month },
    { id: 'year', name: literals.year },
  ];

  const selectType = [
    { id: 'cash', name: literals.cash },
    { id: 'stock', name: literals.stock },
  ];

  return (
    <>
      <div className='col-md-3'>
        <InputNumber
          name='value'
          preText={literals.ratio}
          value={dividend.value ? dividend.value : 1}
          minValue={1}
          onChange={value => onChange('value', value)}
          symbol='X'
          isDisabled={isDisabled}
        />
      </div>
      <div className='col-md-3'>
        <InputSelect
          preText={literals.frequency}
          options={selectFrecuency}
          value={dividend.frequency ? dividend.frequency : ''}
          onChange={value => onChange('frequency', value)}
          isDisabled={isDisabled}
        />
      </div>
      <div className='col-md-3'>
        <InputSelect
          preText={literals.type}
          options={selectType}
          value={dividend.type ? dividend.type : ''}
          onChange={value => onChange('type', value)}
          isDisabled={isDisabled}
        />
      </div>
    </>
  );
};

PopupFormShareClassDividend.propTypes = {
  dividend: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  literals: PropTypes.object.isRequired,
  isDisabled: PropTypes.object.isRequired,
};

export default PopupFormShareClassDividend;
