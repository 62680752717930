import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Panel = (props) => {
  const {
    active = null,
    className = '',
    onTabSelect = null,
  } = props;

  const [activeTab, setActiveTab] = useState(active);

  let { children } = props;
  if (React.isValidElement(children)) {
    children = [children];
  }

  const handleOnTab = (index) => {
    setActiveTab(index);
    if (onTabSelect) {
      onTabSelect(index);
    }
  };

  return (
    <div className={`panel ${className || ''}`}>
      {
        children.map((child, i) => {
          if (child?.type?.displayName === 'PanelHeader') {
            return React.cloneElement(child, { key: child.key ?? i, activeTab, onTab: handleOnTab });
          }
          if (child?.type?.displayName === 'PanelBody') {
            return React.cloneElement(child, { key: child.key ?? i, activeTab, onTab: handleOnTab });
          }
          return child;
        })
      }
    </div>
  );
};

Panel.propTypes = {
  active: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  onTabSelect: PropTypes.func,
};

export default Panel;
