export const timezonesConstant = [
  { id: '-12:00', name: '(GMT -12:00)' },
  { id: '-11:00', name: '(GMT -11:00)' },
  { id: '-10:00', name: '(GMT -10:00)' },
  { id: '-09:50', name: '(GMT -9:30)' },
  { id: '-09:00', name: '(GMT -9:00)' },
  { id: '-08:00', name: '(GMT -8:00)' },
  { id: '-07:00', name: '(GMT -7:00)' },
  { id: '-06:00', name: '(GMT -6:00)' },
  { id: '-05:00', name: '(GMT -5:00)' },
  { id: '-04:50', name: '(GMT -4:30)' },
  { id: '-04:00', name: '(GMT -4:00)' },
  { id: '-03:50', name: '(GMT -3:30)' },
  { id: '-03:00', name: '(GMT -3:00)' },
  { id: '-02:00', name: '(GMT -2:00)' },
  { id: '-01:00', name: '(GMT -1:00)' },
  { id: 'Z', name: '(GMT 0)' },
  { id: '+01:00', name: '(GMT +1:00)' },
  { id: '+02:00', name: '(GMT +2:00)' },
  { id: '+03:00', name: '(GMT +3:00)' },
  { id: '+03:50', name: '(GMT +3:30)' },
  { id: '+04:00', name: '(GMT +4:00)' },
  { id: '+04:50', name: '(GMT +4:30)' },
  { id: '+05:00', name: '(GMT +5:00)' },
  { id: '+05:50', name: '(GMT +5:30)' },
  { id: '+05:75', name: '(GMT +5:45)' },
  { id: '+06:00', name: '(GMT +6:00)' },
  { id: '+06:50', name: '(GMT +6:30)' },
  { id: '+07:00', name: '(GMT +7:00)' },
  { id: '+08:00', name: '(GMT +8:00)' },
  { id: '+08:75', name: '(GMT +8:45)' },
  { id: '+09:00', name: '(GMT +9:00)' },
  { id: '+09:50', name: '(GMT +9:30)' },
  { id: '+10:00', name: '(GMT +10:00)' },
  { id: '+10:50', name: '(GMT +10:30)' },
  { id: '+11:00', name: '(GMT +11:00)' },
  { id: '+11:50', name: '(GMT +11:30)' },
  { id: '+12:00', name: '(GMT +12:00)' },
  { id: '+12:75', name: '(GMT +12:45)' },
  { id: '+13:00', name: '(GMT +13:00)' },
  { id: '+14:00', name: '(GMT +14:00)' },
];
