import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Table from 'components/Table';
import { useSearchParams } from 'react-router-dom';
import { useCounter } from 'utils/customHooks';
import { Eye } from 'lucide-react';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import { fetchStartupOperations } from '../modules/actions';

const ShareholderTransactions = (props) => {
  const {
    literals,
    literalsCommon,
    match,
  } = props;

  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const tableFlagRefresh = useCounter();

  useEffect(() => {
    tableFlagRefresh.increase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captableId]);

  const fetchOperations = (page, size) => {
    return fetchStartupOperations(match.params.id, {
      page,
      size,
      filters: { draft: false, shareholder: match.params.shareholder },
      captable: captableId,
    });
  };

  const columns = [
    {
      field: 'name',
      grow: 1,
      label: literals.name,
    },
    {
      field: 'date',
      grow: '50px',
      label: literalsCommon.date,
    },
    {
      field: 'id',
      type: 'link',
      link: {
        to: (id, row) => embedView(ROUTE_STARTUP_PATH.setCapTableOperation(match.params.id, captableId, row.id)),
        icon: <Eye />,
        text: literalsCommon.view,
      },
    },
  ];


  return (
    <Panel>
      <PanelHeader title={literals.historicalOperations} />
      <PanelBody>
        <Table
          searcher={false}
          columns={columns}
          fetch={fetchOperations}
          forceFetch={tableFlagRefresh.value}
        />
      </PanelBody>
    </Panel>
  );
};

ShareholderTransactions.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default ShareholderTransactions;
