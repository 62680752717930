import MentoringsInstance from 'modules/mentorings/repository';
import { formatErrors } from 'utils/functions';

import {
  FETCH_MENTORING,
  FETCH_MENTORING_SUCCESS,
  FETCH_MENTORING_FAILURE,
  // FETCH_MENTORING_MESSAGES,
  // FETCH_MENTORING_MESSAGES_SUCCESS,
  // FETCH_MENTORING_MESSAGES_FAILURE,
  FETCH_MENTORING_MEETINGS,
  FETCH_MENTORING_MEETINGS_SUCCESS,
  FETCH_MENTORING_MEETINGS_FAILURE,
  CREATE_MENTORING_MESSAGE,
  CREATE_MENTORING_MESSAGE_SUCCESS,
  CREATE_MENTORING_MESSAGE_FAILURE,
  CREATE_MENTORING_MEETINGS,
  CREATE_MENTORING_MEETINGS_SUCCESS,
  CREATE_MENTORING_MEETINGS_FAILURE,
  EDIT_MENTORING_MEETINGS,
  EDIT_MENTORING_MEETINGS_SUCCESS,
  EDIT_MENTORING_MEETINGS_FAILURE,
  DELETE_MENTORING_MEETINGS,
  DELETE_MENTORING_MEETINGS_SUCCESS,
  DELETE_MENTORING_MEETINGS_FAILURE,
  FETCH_MENTORING_DOCUMENTS,
  FETCH_MENTORING_DOCUMENTS_SUCCESS,
  FETCH_MENTORING_DOCUMENTS_FAILURE,
  CREATE_MENTORING_DOCUMENTS_SUCCESS,
  CREATE_MENTORING_DOCUMENTS_FAILURE,
  DELETE_MENTORING_DOCUMENTS,
  DELETE_MENTORING_DOCUMENTS_SUCCESS,
  DELETE_MENTORING_DOCUMENTS_FAILURE,
  FETCH_MENTORING_REPORTS,
  FETCH_MENTORING_REPORTS_SUCCESS,
  FETCH_MENTORING_REPORTS_FAILURE,
  CREATE_MENTORING_DOCUMENTS,
  CREATE_MENTORING_REPORTS,
  CREATE_MENTORING_REPORTS_SUCCESS,
  CREATE_MENTORING_REPORTS_FAILURE,
  EDIT_MENTORING_REPORTS,
  EDIT_MENTORING_REPORTS_SUCCESS,
  EDIT_MENTORING_REPORTS_FAILURE,
  DELETE_MENTORING_REPORTS,
  DELETE_MENTORING_REPORTS_SUCCESS,
  DELETE_MENTORING_REPORTS_FAILURE,
} from './types';

export function fetchMentoringInit() {
  return {
    type: FETCH_MENTORING,
  };
}

export function fetchMentoringSuccess(success) {
  return {
    type: FETCH_MENTORING_SUCCESS,
    payload: success,
  };
}

export function fetchMentoringFailure(error) {
  return {
    type: FETCH_MENTORING_FAILURE,
    payload: error,
  };
}

// export const fetchMentoringMessagesInit = () => ({
//   type: FETCH_MENTORING_MESSAGES,
// });

// export const fetchMentoringMessagesSuccess = success => ({
//   type: FETCH_MENTORING_MESSAGES_SUCCESS,
//   payload: success,
// });

// export const fetchMentoringMessagesFailure = error => ({
//   type: FETCH_MENTORING_MESSAGES_FAILURE,
//   payload: error,
// });

export const fetchMentoringMeetingsInit = () => ({
  type: FETCH_MENTORING_MEETINGS,
});

export const fetchMentoringMeetingsSuccess = success => ({
  type: FETCH_MENTORING_MEETINGS_SUCCESS,
  payload: success,
});

export const fetchMentoringMeetingsFailure = error => ({
  type: FETCH_MENTORING_MEETINGS_FAILURE,
  payload: error,
});

export const fetchMentoringDocumentsInit = () => ({
  type: FETCH_MENTORING_DOCUMENTS,
});

export const fetchMentoringDocumentsSuccess = success => ({
  type: FETCH_MENTORING_DOCUMENTS_SUCCESS,
  payload: success,
});

export const fetchMentoringDocumentsFailure = error => ({
  type: FETCH_MENTORING_DOCUMENTS_FAILURE,
  payload: error,
});

export const fetchMentoringReportsInit = () => ({
  type: FETCH_MENTORING_REPORTS,
});

export const fetchMentoringReportsSuccess = success => ({
  type: FETCH_MENTORING_REPORTS_SUCCESS,
  payload: success,
});

export const fetchMentoringReportsFailure = error => ({
  type: FETCH_MENTORING_REPORTS_FAILURE,
  payload: error,
});

export const createMentoringMessageInit = () => ({
  type: CREATE_MENTORING_MESSAGE,
});

export const createMentoringMessageSuccess = success => ({
  type: CREATE_MENTORING_MESSAGE_SUCCESS,
  payload: success,
});

export const createMentoringMessageFailure = () => ({
  type: CREATE_MENTORING_MESSAGE_FAILURE,
});

export const createMentoringMeetingInit = () => ({
  type: CREATE_MENTORING_MEETINGS,
});

export const createMentoringMeetingSuccess = success => ({
  type: CREATE_MENTORING_MEETINGS_SUCCESS,
  payload: success,
});

export const createMentoringMeetingFailure = () => ({
  type: CREATE_MENTORING_MEETINGS_FAILURE,
});

export const editMentoringMeetingInit = () => ({
  type: EDIT_MENTORING_MEETINGS,
});

export const editMentoringMeetingSuccess = success => ({
  type: EDIT_MENTORING_MEETINGS_SUCCESS,
  payload: success,
});

export const editMentoringMeetingFailure = () => ({
  type: EDIT_MENTORING_MEETINGS_FAILURE,
});

export const deleteMentoringMeetingInit = () => ({
  type: DELETE_MENTORING_MEETINGS,
});

export const deleteMentoringMeetingSuccess = id => ({
  type: DELETE_MENTORING_MEETINGS_SUCCESS,
  payload: id,
});

export const deleteMentoringMeetingFailure = () => ({
  type: DELETE_MENTORING_MEETINGS_FAILURE,
});

export const createMentoringDocumentInit = () => ({
  type: CREATE_MENTORING_DOCUMENTS,
});

export const createMentoringDocumentSuccess = success => ({
  type: CREATE_MENTORING_DOCUMENTS_SUCCESS,
  payload: success,
});

export const createMentoringDocumentFailure = () => ({
  type: CREATE_MENTORING_DOCUMENTS_FAILURE,
});

export const deleteMentoringDocumentInit = () => ({
  type: DELETE_MENTORING_DOCUMENTS,
});

export const deleteMentoringDocumentSuccess = success => ({
  type: DELETE_MENTORING_DOCUMENTS_SUCCESS,
  payload: success,
});

export const deleteMentoringDocumentFailure = () => ({
  type: DELETE_MENTORING_DOCUMENTS_FAILURE,
});

export const createMentoringReportInit = () => ({
  type: CREATE_MENTORING_REPORTS,
});

export const createMentoringReportSuccess = success => ({
  type: CREATE_MENTORING_REPORTS_SUCCESS,
  payload: success,
});

export const createMentoringReportFailure = () => ({
  type: CREATE_MENTORING_REPORTS_FAILURE,
});

export const editMentoringReportInit = () => ({
  type: EDIT_MENTORING_REPORTS,
});

export const editMentoringReportSuccess = success => ({
  type: EDIT_MENTORING_REPORTS_SUCCESS,
  payload: success,
});

export const editMentoringReportFailure = () => ({
  type: EDIT_MENTORING_REPORTS_FAILURE,
});

export const deleteMentoringReportInit = () => ({
  type: DELETE_MENTORING_REPORTS,
});

export const deleteMentoringReportSuccess = id => ({
  type: DELETE_MENTORING_REPORTS_SUCCESS,
  payload: id,
});

export const deleteMentoringReportFailure = () => ({
  type: DELETE_MENTORING_REPORTS_FAILURE,
});

// export function fetchMentoringMessagesAction(id, page, size = 25) {
//   return (dispatch) => {
//     dispatch(fetchMentoringMessagesInit());

//     return MentoringsInstance.getMentoringMessages(id, { page, size })
//       .then((messages) => {
//         dispatch(fetchMentoringMessagesSuccess(messages));
//       })
//       .catch((error) => {
//         console.error(error);
//         dispatch(fetchMentoringMessagesFailure(error.message));
//       });
//   };
// }

export function fetchMentoringMeetingsAction(id, page, size = 25) {
  return (dispatch) => {
    dispatch(fetchMentoringMeetingsInit());

    return MentoringsInstance.getMentoringMeetings(id, { page, size })
      .then((events) => {
        dispatch(fetchMentoringMeetingsSuccess(events));
      })
      .catch((error) => {
        console.error(error);
        dispatch(fetchMentoringMeetingsFailure(error.message));
      });
  };
}

export function fetchMentoringDocumentsAction(id, page, size = 25) {
  return (dispatch) => {
    dispatch(fetchMentoringDocumentsInit());

    return MentoringsInstance.getMentoringDocuments(id, { page, size })
      .then((documents) => {
        dispatch(fetchMentoringDocumentsSuccess(documents));
      })
      .catch((error) => {
        console.error(error);
        dispatch(fetchMentoringDocumentsFailure(error.message));
      });
  };
}

export function fetchMentoringReportsAction(id, page, size = 25) {
  return (dispatch) => {
    dispatch(fetchMentoringReportsInit());

    return MentoringsInstance.getMentoringReports(id, { page, size })
      .then((reports) => {
        dispatch(fetchMentoringReportsSuccess(reports));
      })
      .catch((error) => {
        console.error(error);
        dispatch(fetchMentoringReportsFailure(error.message));
      });
  };
}

export function fetchMentoringAction(id, size = 25, reports = false) {
  return (dispatch) => {
    dispatch(fetchMentoringInit());

    return MentoringsInstance.getMentoring(id)
      .then((mentoring) => {
        dispatch(fetchMentoringSuccess(mentoring));
        // dispatch(fetchMentoringMessagesAction(id, 0, size));
        dispatch(fetchMentoringMeetingsAction(id, 0, size));
        dispatch(fetchMentoringDocumentsAction(id, 0, size));
        if (reports) {
          dispatch(fetchMentoringReportsAction(id, 0, size));
        }
      })
      .catch((error) => {
        dispatch(fetchMentoringFailure(error.message));
      });
  };
}

export function createMentoringMessageAction(id, text, parent) {
  return (dispatch) => {
    dispatch(createMentoringMessageInit());

    return MentoringsInstance.createMessage(id, { message: text, parent })
      .then((message) => {
        dispatch(createMentoringMessageSuccess(message));
      })
      .catch((error) => {
        console.error(error);
        dispatch(createMentoringMessageFailure(error.message));
      });
  };
}

export function createMentoringMeetingAction(id, meeting) {
  return (dispatch) => {
    dispatch(createMentoringMeetingInit());

    return MentoringsInstance.createMeeting(id, meeting)
      .then((response) => {
        dispatch(createMentoringMeetingSuccess(response));
      })
      .catch((error) => {
        console.error(error);
        throw formatErrors(error);
      });
  };
}

export function editMentoringMeetingAction(id, meetingId, meeting) {
  return (dispatch) => {
    dispatch(editMentoringMeetingInit());

    return MentoringsInstance.editMeeting(id, meetingId, meeting)
      .then((response) => {
        dispatch(editMentoringMeetingSuccess(response));
      })
      .catch((error) => {
        console.error(error);
        throw formatErrors(error);
      });
  };
}

export function deleteMentoringMeetingAction(id, meetingId) {
  return (dispatch) => {
    dispatch(deleteMentoringMeetingInit());

    return MentoringsInstance.deleteMeeting(id, meetingId)
      .then(() => {
        dispatch(deleteMentoringMeetingSuccess(meetingId));
        return true;
      })
      .catch((error) => {
        console.error(error);
        dispatch(deleteMentoringMeetingFailure(error.message));
      });
  };
}

export function createMentoringDocumentAction(id, file) {
  return (dispatch) => {
    dispatch(createMentoringDocumentInit());

    return MentoringsInstance.createMentoringDocument(id, file)
      .then((response) => {
        dispatch(createMentoringDocumentSuccess(response));
      })
      .catch((error) => {
        console.error(error);
        dispatch(createMentoringDocumentFailure(error.message));
      });
  };
}

export function deleteMentoringDocumentAction(id, documentId) {
  return (dispatch) => {
    dispatch(deleteMentoringDocumentInit());

    return MentoringsInstance.deleteMentoringDocument(id, documentId)
      .then(() => {
        dispatch(deleteMentoringDocumentSuccess(documentId));
        return true;
      })
      .catch((error) => {
        console.error(error);
        dispatch(deleteMentoringDocumentFailure(error.message));
      });
  };
}

export function createMentoringReportAction(id, data) {
  return (dispatch) => {
    dispatch(createMentoringReportInit());

    return MentoringsInstance.createMentoringReport(id, data)
      .then((response) => {
        dispatch(createMentoringReportSuccess(response));
      })
      .catch((error) => {
        console.error(error);
        dispatch(createMentoringReportFailure(error.message));
      });
  };
}

export function editMentoringReportAction(id, meeting, data) {
  return (dispatch) => {
    dispatch(editMentoringReportInit());

    return MentoringsInstance.editMentoringReport(id, meeting, data)
      .then((response) => {
        dispatch(editMentoringReportSuccess(response));
      })
      .catch((error) => {
        console.error(error);
        dispatch(editMentoringReportFailure(error.message));
      });
  };
}

export function deleteMentoringReportAction(id, meeting) {
  return (dispatch) => {
    dispatch(deleteMentoringReportInit());

    return MentoringsInstance.deleteMentoringReport(id, meeting)
      .then(() => {
        dispatch(deleteMentoringReportSuccess(meeting));
        return true;
      })
      .catch((error) => {
        console.error(error);
        dispatch(deleteMentoringReportFailure(error.message));
      });
  };
}
