import { connect } from 'react-redux';
import ReportingAdd from '../components';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.reporting,
    literalsPerformance: state.i18n.literals.performance,
    literalsCommon: state.i18n.literals.common,
    user: state.session.user,
    currency: state.global.currency,
    lang: state.i18n.language,
    startup: state.global.startup,
  };
}

export default connect(mapStateToProps)(ReportingAdd);
