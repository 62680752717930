import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import { formatDate, formatTime } from 'utils/functions';
import './styles.scss';

const ScriptLogDetails = ({
  literals, literalsCommon, script, onClose,
}) => {
  return (
    <Popup title={literals.scriptDetail} onClose={onClose}>
      <div className='row'>
        <div className='col-12 col-md-9 mb-4'>
          <h6>{literalsCommon.name}</h6>
          <span className='fw-sb'>{script.name}</span>
        </div>
        <div className='col-12 col-md-3 mb-4'>
          <h6>{literalsCommon.createdAt}</h6>
          <span className='fw-sb'>{formatDate(script.createdAt, { time: true })}</span>
        </div>
        <div className='col-12 col-md-9 mb-4'>
          <h6>{literals.method}</h6>
          <span className='fw-sb'>{script.method}</span>
        </div>
        <div className='col-12 col-md-3 mb-4'>
          <h6>{literals.runtime}</h6>
          <span className='fw-sb'>{formatTime(script.runtime, 'seconds', 4)}</span>
        </div>
        <div className='col-12 mb-4'>
          <h6>{literals.input}</h6>
          <pre className='fw-sb text-break'>{JSON.stringify(script.input, null, 2)}</pre>
        </div>
        <div className='col-12 mb-4'>
          <h6>{literals.output}</h6>
          <pre className='fw-sb text-break'>{JSON.stringify(script.output, null, 2)}</pre>
        </div>
      </div>
    </Popup>
  );
};

ScriptLogDetails.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  script: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ScriptLogDetails;
