import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Star } from 'lucide-react';
import './styles.scss';

const InputStars = ({
  preText = '',
  postText = '',
  isRequired = false,
  error = null,
  isDisabled = false,
  onChange,
  value: propValue = 0,
  min = 1,
  max = 5,
  name = '',
  size = 24,
}) => {
  const [value, setValue] = useState(propValue);

  const changeValue = (newValue) => {
    if (!isDisabled) {
      setValue(newValue);
      onChange(newValue, { target: name });
    }
  };

  const renderStars = () => {
    const stars = [];
    for (let i = min; i <= max; i += 1) {
      stars.push(
        <div
          key={`star-${i}`}
          className='input_star'
          onClick={() => changeValue(i)}
        >
          <Star
            fill={value >= i ? 'currentColor' : 'none'}
            size={size}
          />
        </div>,
      );
    }
    return stars;
  };

  return (
    <div className='input_stars'>
      <div className={`pre_text ${error ? 'text_error' : ''}`}>
        {preText}
        {isRequired && <span className='is_required'>*</span>}
      </div>
      <div className={`input_stars-list ${isDisabled ? 'disabled' : ''}`}>
        { renderStars() }
      </div>
      { postText && (<div className='post_text' dangerouslySetInnerHTML={{ __html: postText }} />) }
    </div>
  );
};

InputStars.propTypes = {
  preText: PropTypes.string,
  postText: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  error: PropTypes.array,
  name: PropTypes.string,
  size: PropTypes.number,
};

export default InputStars;
