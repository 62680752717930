export const DIMENSIONS = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
};

export const getBrowserPreferences = (prop, defaultValue) => {
  const preferences = JSON.parse(localStorage.getItem('preferences'));
  return preferences && preferences[prop] ? preferences[prop] : defaultValue;
};

export const setBrowserPreferences = (prop, value) => {
  const preferences = JSON.parse(localStorage.getItem('preferences')) || {};
  preferences[prop] = value;
  localStorage.setItem('preferences', JSON.stringify(preferences));
};
