import AccountsInstance from 'modules/accounts/repository';
import { formatErrors } from 'utils/functions';

export function savePushToken(user, token) {
  return AccountsInstance.postPushToken(user, token).then(() => {
    return true;
  }).catch((error) => {
    throw formatErrors(error);
  });
}

export function deletePushToken(user, token) {
  return AccountsInstance.deletePushToken(user, token).then(() => {
    return true;
  }).catch((error) => {
    throw formatErrors(error);
  });
}
