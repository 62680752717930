import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Avatar from 'components/Avatar';
import Button from 'components/Buttons/Button';
import StatCard from 'components/Statistics/StatCard';
import InputStars from 'components/Inputs/inputStars';
import { formatDate } from 'utils/functions';
import { Link } from 'react-router-dom';
import { ROUTE_ADMIN_PATH } from 'routes';
import { Users } from 'lucide-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import './styles.scss';

import 'swiper/css';
import 'swiper/css/pagination';

const PopUpInfo = (props) => {
  const {
    id,
    status,
    createdBy,
    createdAt,
    finishedAt,
    lastInteraction,
    message,
    rejectionMessage,
    feedback,
    feedBackMentor,
    feedBackStartup,
    stats,
    mentor,
    startup,
    onClose,
    literals,
  } = props;

  const renderStats = () => {
    return (
      <>
        <div className='row'>
          <div className='col-12 col-md-4'>
            <StatCard title={literals.infoPopUp.status} valueSize='h4' value={literals.status[feedback.reason || status]} />
          </div>
          <div className='col-12 col-md-4'>
            <StatCard title={literals.infoPopUp.creationDate} valueSize='h4' value={formatDate(createdAt, { time: true })} />
          </div>
          <div className='col-12 col-md-4'>
            {status === 'finished' ? (
              <StatCard title={literals.infoPopUp.finishDate} valueSize='h4' value={formatDate(finishedAt, { time: true })} />
            ) : (
              <StatCard title={literals.lastInteraction} valueSize='h4' value={lastInteraction ? formatDate(lastInteraction, { time: true }) : '-'} />
            )}
          </div>
        </div>
        {!['pending', 'rejected'].includes(status) && (
          <Swiper
            className='swiper-pagination-below'
            slidesPerView={1}
            spaceBetween={50}
            breakpoints={{
              769: { slidesPerView: 4 },
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
          >
            <SwiperSlide>
              <StatCard title={literals.messages} value={stats.messages} />
            </SwiperSlide>
            <SwiperSlide>
              <StatCard title={literals.events} value={stats.meetings} />
            </SwiperSlide>
            <SwiperSlide>
              <StatCard title={literals.documents} value={stats.documents} />
            </SwiperSlide>
            <SwiperSlide>
              <StatCard title={literals.reports} value={stats.reports} />
            </SwiperSlide>
          </Swiper>
        )}
      </>
    );
  };

  const renderHeader = (user) => {
    return (
      <div className='info-header'>
        <div className='info-header-avatar'>
          <Avatar
            title={user.name || user.company}
            src={user.avatar?.path || user.logo?.path}
            size={30}
          />
        </div>
        {user.company && (
          <div className='info-header-title'>
            <h3 className='fw-b'>{user.company}</h3>
          </div>
        )}
        {user.name && (
          <div className='info-header-title'>
            <h3 className='fw-b'>{user.name}</h3>
          </div>
        )}
      </div>
    );
  };

  const renderInfo = (elem, creator) => {
    return (
      <div className='info'>
        <div className='fw-b text-center'>{creator ? literals.infoPopUp.creator : literals.infoPopUp.guest}</div>
        {elem === 'mentor' ? renderHeader(mentor) : renderHeader(startup)}
        <div className='info-body'>
          {creator && (
            <>
              <h6 className='fw-b'>{literals.infoPopUp.invitationMessage}</h6>
              <p>{message}</p>
            </>
          )}
          {status === 'finished' && (
            <>
              {elem === 'mentor' ? (
                <>
                  {feedBackMentor ? (
                    <>
                      <h6 className='fw-b'>{`Feedback  (${formatDate(feedBackMentor.createdAt, { time: true })})`}</h6>
                      <InputStars name='score' size={16} value={feedBackMentor.score} disabled />
                      {feedBackMentor.message}
                    </>
                  ) : (
                    <>
                      <h6 className='fw-b'>Feedback</h6>
                      <span>-</span>
                    </>
                  )}
                </>
              ) : (
                <>
                  {feedBackStartup ? (
                    <>
                      <h6 className='fw-b m-0'>{`Feedback  (${formatDate(feedBackStartup.createdAt, { time: true })})`}</h6>
                      <InputStars name='score' size={16} value={feedBackStartup.score} disabled />
                      {feedBackStartup.message}
                    </>
                  ) : (
                    <>
                      <h6 className='fw-b'>Feedback</h6>
                      <span>-</span>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {status === 'rejected' && !creator && (
            <>
              <h6 className='fw-b'>{literals.infoPopUp.rejectionReason}</h6>
              <p>{rejectionMessage}</p>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <Popup title={literals.infoPopUp.info} onClose={onClose}>
      {renderStats()}
      { createdBy === mentor.user.id ? (
        <>
          {renderInfo('mentor', true)}
          { ['finished', 'rejected'].includes(status) && renderInfo('startup', false)}
        </>
      ) : (
        <>
          {renderInfo('mentor', true)}
          { ['finished', 'rejected'].includes(status) && renderInfo('startup', false)}
        </>
      )}
      <div className='buttons'>
        <Link to={ROUTE_ADMIN_PATH.setMentoring(id)}>
          <Button icon={Users} text={literals.enter} />
        </Link>
      </div>
    </Popup>
  );
};

PopUpInfo.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  createdAt: PropTypes.number.isRequired,
  finishedAt: PropTypes.number,
  lastInteraction: PropTypes.number.isRequired,
  stats: PropTypes.object.isRequired,
  feedback: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  literals: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  mentor: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  feedBackMentor: PropTypes.object,
  feedBackStartup: PropTypes.object,
  rejectionMessage: PropTypes.string,
};

PopUpInfo.defaultProps = {
  finishedAt: null,
  feedBackMentor: null,
  feedBackStartup: null,
  rejectionMessage: null,
};

export default PopUpInfo;
