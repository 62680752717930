import { connect } from 'react-redux';
import DownloadFile from '../components';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.downloadFile,
    isAuthenticated: state.session.authenticated,
  };
}
export default connect(mapStateToProps)(DownloadFile);
