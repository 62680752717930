import AdminInstance from 'modules/admin/repository';

export const getServices = (page, size, search, filters, sort) => {
  return AdminInstance.getServices({
    page, size, search, filters, sort,
  });
};

export const createService = (data) => {
  return AdminInstance.createService(data);
};

export const editService = (service, data) => {
  return AdminInstance.editService(service, data);
};

export const deleteService = (service) => {
  return AdminInstance.deleteService(service);
};

export const createServiceRequests = (data) => {
  return AdminInstance.createServicesRequestsAdmin(data);
};

export const deleteServicesRequest = (id) => {
  return AdminInstance.deleteServicesRequestAdmin(id);
};

export const editServicesRequest = (id, data) => {
  return AdminInstance.editServicesRequest(id, data);
};

export const getServicesRequests = (page, size, search, filters, sort) => {
  return AdminInstance.getServicesRequests({
    page, size, search, filters, sort,
  });
};

export const getServicesRequestsStats = () => {
  return AdminInstance.getServicesRequestsStats();
};
