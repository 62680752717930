import { formatErrors, insertObjectInArray } from 'utils/functions';
import initialState from '../../../store/globalState/initialState';
import {
  CLEAN_CAPTABLE_ERROR,
  CREATE_CAPTABLE_OPERATION_FAILURE,
  CREATE_CAPTABLE_OPERATION_INIT,
  CREATE_CAPTABLE_OPERATION_SUCCESS,
  CREATE_CAPTABLE_SHARECLASS_FAILURE,
  CREATE_CAPTABLE_SHARECLASS_INIT,
  CREATE_CAPTABLE_SHARECLASS_SUCCESS,
  CREATE_CAPTABLE_SHAREHOLDER_FAILURE,
  CREATE_CAPTABLE_SHAREHOLDER_INIT,
  CREATE_CAPTABLE_SHAREHOLDER_SUCCESS,
  CREATE_CAPTABLE_VESTINGPLAN_FAILURE,
  CREATE_CAPTABLE_VESTINGPLAN_INIT,
  CREATE_CAPTABLE_VESTINGPLAN_SUCCESS,
  CREATE_STARTUP_CAPTABLE_SUCCESS,
  DELETE_CAPTABLE_OPERATION_FAILURE,
  DELETE_CAPTABLE_OPERATION_INIT,
  DELETE_CAPTABLE_OPERATION_SUCCESS,
  DELETE_CAPTABLE_SHARECLASS_FAILURE,
  DELETE_CAPTABLE_SHARECLASS_INIT,
  DELETE_CAPTABLE_SHARECLASS_SUCCESS,
  DELETE_CAPTABLE_SHAREHOLDER_FAILURE,
  DELETE_CAPTABLE_SHAREHOLDER_INIT,
  DELETE_CAPTABLE_SHAREHOLDER_SUCCESS,
  DELETE_CAPTABLE_VESTINGPLAN_FAILURE,
  DELETE_CAPTABLE_VESTINGPLAN_INIT,
  DELETE_CAPTABLE_VESTINGPLAN_SUCCESS,
  DELETE_STARTUP_CAPTABLE_SUCCESS,
  EDIT_CAPTABLE_OPERATION_FAILURE,
  EDIT_CAPTABLE_OPERATION_INIT,
  EDIT_CAPTABLE_OPERATION_SUCCESS,
  EDIT_CAPTABLE_SHARECLASS_FAILURE,
  EDIT_CAPTABLE_SHARECLASS_INIT,
  EDIT_CAPTABLE_SHARECLASS_SUCCESS,
  EDIT_CAPTABLE_SHAREHOLDER_FAILURE,
  EDIT_CAPTABLE_SHAREHOLDER_INIT,
  EDIT_CAPTABLE_SHAREHOLDER_SUCCESS,
  EDIT_CAPTABLE_VESTINGPLAN_FAILURE,
  EDIT_CAPTABLE_VESTINGPLAN_INIT,
  EDIT_CAPTABLE_VESTINGPLAN_SUCCESS,
  EDIT_STARTUP_CAPTABLE_SUCCESS,
  FETCH_CAPTABLE_FAILURE,
  FETCH_CAPTABLE_INIT,
  FETCH_CAPTABLE_SUCCESS,
} from './types';

export const fetchCaptableInit = (startup, captable) => ({ type: FETCH_CAPTABLE_INIT, payload: { startup, captable } });
export const fetchCaptableSuccess = success => ({ type: FETCH_CAPTABLE_SUCCESS, payload: success });
export const fetchCaptableFailure = error => ({ type: FETCH_CAPTABLE_FAILURE, payload: error });

export const createCaptableOperationInit = () => ({ type: CREATE_CAPTABLE_OPERATION_INIT });
export const createCaptableOperationSuccess = operation => ({ type: CREATE_CAPTABLE_OPERATION_SUCCESS, payload: operation });
export const createCaptableOperationFailure = error => ({ type: CREATE_CAPTABLE_OPERATION_FAILURE, payload: error });
export const editCaptableOperationInit = () => ({ type: EDIT_CAPTABLE_OPERATION_INIT });
export const editCaptableOperationSuccess = operation => ({ type: EDIT_CAPTABLE_OPERATION_SUCCESS, payload: operation });
export const editCaptableOperationFailure = error => ({ type: EDIT_CAPTABLE_OPERATION_FAILURE, payload: error });
export const deleteCaptableOperationInit = () => ({ type: DELETE_CAPTABLE_OPERATION_INIT });
export const deleteCaptableOperationSuccess = id => ({ type: DELETE_CAPTABLE_OPERATION_SUCCESS, payload: id });
export const deleteCaptableOperationFailure = error => ({ type: DELETE_CAPTABLE_OPERATION_FAILURE, payload: error });

export const createCaptableShareholderInit = () => ({ type: CREATE_CAPTABLE_SHAREHOLDER_INIT });
export const createCaptableShareholderSuccess = shareholder => ({ type: CREATE_CAPTABLE_SHAREHOLDER_SUCCESS, payload: shareholder });
export const createCaptableShareholderFailure = error => ({ type: CREATE_CAPTABLE_SHAREHOLDER_FAILURE, payload: error });
export const editCaptableShareholderInit = () => ({ type: EDIT_CAPTABLE_SHAREHOLDER_INIT });
export const editCaptableShareholderSuccess = shareholder => ({ type: EDIT_CAPTABLE_SHAREHOLDER_SUCCESS, payload: shareholder });
export const editCaptableShareholderFailure = error => ({ type: EDIT_CAPTABLE_SHAREHOLDER_FAILURE, payload: error });
export const deleteCaptableShareholderInit = () => ({ type: DELETE_CAPTABLE_SHAREHOLDER_INIT });
export const deleteCaptableShareholderSuccess = id => ({ type: DELETE_CAPTABLE_SHAREHOLDER_SUCCESS, payload: id });
export const deleteCaptableShareholderFailure = error => ({ type: DELETE_CAPTABLE_SHAREHOLDER_FAILURE, payload: error });

export const createCaptableShareClassInit = () => ({ type: CREATE_CAPTABLE_SHARECLASS_INIT });
export const createCaptableShareClassSuccess = shareClass => ({ type: CREATE_CAPTABLE_SHARECLASS_SUCCESS, payload: shareClass });
export const createCaptableShareClassFailure = error => ({ type: CREATE_CAPTABLE_SHARECLASS_FAILURE, payload: error });
export const editCaptableShareClassInit = () => ({ type: EDIT_CAPTABLE_SHARECLASS_INIT });
export const editCaptableShareClassSuccess = shareClass => ({ type: EDIT_CAPTABLE_SHARECLASS_SUCCESS, payload: shareClass });
export const editCaptableShareClassFailure = error => ({ type: EDIT_CAPTABLE_SHARECLASS_FAILURE, payload: error });
export const deleteCaptableShareClassInit = () => ({ type: DELETE_CAPTABLE_SHARECLASS_INIT });
export const deleteCaptableShareClassSuccess = id => ({ type: DELETE_CAPTABLE_SHARECLASS_SUCCESS, payload: id });
export const deleteCaptableShareClassFailure = error => ({ type: DELETE_CAPTABLE_SHARECLASS_FAILURE, payload: error });

export const createCaptableVestingPlanInit = startup => ({ type: CREATE_CAPTABLE_VESTINGPLAN_INIT, payload: startup });
export const createCaptableVestingPlanSuccess = vestingPlan => ({ type: CREATE_CAPTABLE_VESTINGPLAN_SUCCESS, payload: vestingPlan });
export const createCaptableVestingPlanFailure = error => ({ type: CREATE_CAPTABLE_VESTINGPLAN_FAILURE, payload: error });
export const editCaptableVestingPlanInit = startup => ({ type: EDIT_CAPTABLE_VESTINGPLAN_INIT, payload: startup });
export const editCaptableVestingPlanSuccess = vestingPlan => ({ type: EDIT_CAPTABLE_VESTINGPLAN_SUCCESS, payload: vestingPlan });
export const editCaptableVestingPlanFailure = error => ({ type: EDIT_CAPTABLE_VESTINGPLAN_FAILURE, payload: error });
export const deleteCaptableVestingPlanInit = id => ({ type: DELETE_CAPTABLE_VESTINGPLAN_INIT, payload: id });
export const deleteCaptableVestingPlanSuccess = success => ({ type: DELETE_CAPTABLE_VESTINGPLAN_SUCCESS, payload: success });
export const deleteCaptableVestingPlanFailure = error => ({ type: DELETE_CAPTABLE_VESTINGPLAN_FAILURE, payload: error });

export const createStartupCaptableSuccess = error => ({ type: CREATE_STARTUP_CAPTABLE_SUCCESS, payload: error });
export const editStartupCaptableSuccess = error => ({ type: EDIT_STARTUP_CAPTABLE_SUCCESS, payload: error });
export const deleteStartupCaptableSuccess = error => ({ type: DELETE_STARTUP_CAPTABLE_SUCCESS, payload: error });

export const cleanCaptableError = () => ({ type: CLEAN_CAPTABLE_ERROR, payload: null });

export default function captableReducer(state = initialState.captable, action) {
  switch (action.type) {
    case FETCH_CAPTABLE_INIT: {
      return {
        ...state,
        startup: action.payload.startup,
        captable: action.payload.captable,
        selected: { id: action.payload.captable },
        loading: true,
        loaded: false,
        error: '',
        shareholders: [],
        shareClasses: [],
        vestingPlans: [],
        operations: [],
      };
    }
    case FETCH_CAPTABLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        selected: action.payload.captable || null,
        captables: action.payload.captables,
        shareholders: action.payload.shareholders,
        shareClasses: action.payload.shareClasses,
        vestingPlans: action.payload.vestingPlans,
        operations: action.payload.operations.sort((a, b) => (a.date - b.date)),
        lastNumeration: action.payload.lastNumeration,
        summary: action.payload.summary,
        isEmpty: action.payload.isEmpty || false,
      };
    }
    case CREATE_CAPTABLE_SHAREHOLDER_SUCCESS:
    case EDIT_CAPTABLE_SHAREHOLDER_SUCCESS: {
      if (state.loaded) {
        const newShareholders = insertObjectInArray(state.shareholders, {
          id: action.payload.id,
          name: `${action.payload.firstname}${action.payload.lastname ? ` ${action.payload.lastname}` : ''}`,
        }, (a, b) => (a.name.localeCompare(b.name)));
        return {
          ...state,
          shareholders: newShareholders,
        };
      }
      return state;
    }
    case CREATE_CAPTABLE_SHARECLASS_SUCCESS:
    case EDIT_CAPTABLE_SHARECLASS_SUCCESS: {
      if (state.loaded) {
        const newShareClasses = insertObjectInArray(state.shareClasses, {
          id: action.payload.id,
          type: action.payload.type,
          name: action.payload.name,
        }, (a, b) => (a.name.localeCompare(b.name)));
        return {
          ...state,
          shareClasses: newShareClasses,
        };
      }
      return state;
    }
    case CREATE_CAPTABLE_VESTINGPLAN_SUCCESS:
    case EDIT_CAPTABLE_VESTINGPLAN_SUCCESS: {
      if (state.loaded) {
        const newVestingPlans = insertObjectInArray(state.vestingPlans, {
          id: action.payload.id,
          type: action.payload.type,
          name: action.payload.name,
        }, (a, b) => (a.name.localeCompare(b.name)));
        return {
          ...state,
          vestingPlans: newVestingPlans,
        };
      }
      return state;
    }
    case CREATE_CAPTABLE_OPERATION_SUCCESS:
    case EDIT_CAPTABLE_OPERATION_SUCCESS: {
      if (state.loaded) {
        let newOperations = [...state.operations];
        if (!action.payload.draft) {
          if (action.payload.timeline) {
            newOperations = insertObjectInArray(state.operations, {
              id: action.payload.id,
              name: action.payload.name,
              date: action.payload.date,
            // eslint-disable-next-line no-nested-ternary
            }, (a, b) => (a.date > b.date ? -1 : (a.date < b.date ? 1 : 0)));
          } else {
            newOperations = newOperations.filter(item => item.id !== action.payload.id);
          }
        } else {
          newOperations = newOperations.filter(item => item.id !== action.payload.id);
        }
        return {
          ...state,
          operations: newOperations,
          lastNumeration: action.payload?.lastNumeration || state.lastNumeration,
          summary: {
            ...state.summary,
            shares: action.payload?.shares || state.summary?.shares || 0,
            options: action.payload?.options || state.summary?.options || 0,
          },
        };
      }
      return state;
    }
    case DELETE_CAPTABLE_OPERATION_SUCCESS: {
      if (state.loaded) {
        return {
          ...state,
          operations: state.operations.filter(item => item.id !== action.payload),
        };
      }
      return state;
    }
    case CREATE_STARTUP_CAPTABLE_SUCCESS: {
      if (state.loaded) {
        state.captables.push(action.payload);
      }
      return state;
    }
    case EDIT_STARTUP_CAPTABLE_SUCCESS: {
      return {
        ...state,
        captables: state.loaded
          ? state.captables.map(cap => (cap.id === action.payload.id
            ? action.payload
            : {
              ...cap,
              official: action.payload.official ? false : cap.official,
              favorite: action.payload.favorite ? null : cap.favorite,
            }))
          : state.captables,
        selected: action.payload?.id === state.selected?.id
          ? action.payload
          : state.selected,
      };
    }
    case DELETE_STARTUP_CAPTABLE_SUCCESS: {
      return {
        ...state,
        captables: state.loaded
          ? state.captables.filter(cap => cap.id !== action.payload.id)
          : state.captables,
      };
    }
    case FETCH_CAPTABLE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: formatErrors(action.payload),
      };
    }
    case CLEAN_CAPTABLE_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    default:
      return state;
  }
}
