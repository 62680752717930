/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import IOFileLoading from 'components/IOFileLoading';
import { connect } from 'react-redux';
import { uploadFile } from 'modules/actions';
import { FILES_BASE_URL } from 'constants/apiConf';
import { X } from 'lucide-react';

const inputAvatarBg = (
  <svg
    className='input-file-bg'
    xmlns='http://www.w3.org/2000/svg'
    width='182.456'
    height='181.825'
    viewBox='0 0 182.456 181.825'
  >
    <defs>
      <filter id='NoPath_-_copia_51_' x='0' y='0' width='182.456' height='181.825' filterUnits='userSpaceOnUse'>
        <feOffset dy='3' />
        <feGaussianBlur stdDeviation='3' result='blur' />
        <feFlood floodOpacity='0.161' />
        <feComposite operator='in' in2='blur' />
        <feComposite in='SourceGraphic' />
      </filter>
    </defs>
    <g transform='matrix(1, 0, 0, 1, 0, 0)' filter='url(#NoPath_-_copia_51_)'>
      <path
        id='NoPath_-_copia_51_2'
        data-name='NoPath - copia (51)'
        d='M1767.874,300.988c8.048,14.716,17.422,40.934,10.325,76.239,0,0-3.5,39.356-42.27,53.058s-78.13,0-87.751-5.832-39.064-21.572-30.32-96.2c0,0,3.5-45.479,55.682-53.933,31.927-5.173,56.652.895,71.037,6.385A44.634,44.634,0,0,1,1767.874,300.988Z'
        transform='translate(-1607.26 -266.35)'
        fill='#6147cd'
      />
    </g>
  </svg>
);

const arrowLeft = (
  <svg
    className='arrow-left-file'
    xmlns='http://www.w3.org/2000/svg'
    width='157.305'
    height='157.965'
    viewBox='0 0 157.305 157.965'
  >
    <path
      id='Trazado_1'
      data-name='Trazado 1'
      d='M4.272,75.5c-.461-.474-.692-1.166-1.239-1.542-1.141-.777-2.04.158-2.356,1.275l-.389-.17C-.767,79.186,1.321,83.57,2.56,87.48c1.542,4.857,3.291,9.6,5.063,14.377,1.153,3.108,5.731,4.347,7.528.984a202.185,202.185,0,0,1,12.033-19.6,3.019,3.019,0,0,0,.388-2.744c.146-.267.376-.5.413-.716L28,79.721v-.036a3.14,3.14,0,0,0,.012-1.676,2.092,2.092,0,0,0-.206-.546,2.7,2.7,0,0,0-.741-1.141,2.624,2.624,0,0,0-1.141-.741,2.678,2.678,0,0,0-1.372-.3l-.959.121a2.635,2.635,0,0,0-1.2.644,2.6,2.6,0,0,0-.923,1,1.321,1.321,0,0,0-.061.206,2.366,2.366,0,0,0-.631.571,2.452,2.452,0,0,0-.607,1.166l-.061.206a20.38,20.38,0,0,0-2.926,2.271A116.533,116.533,0,0,1,23.469,60.9a95.474,95.474,0,0,1,21.7-31.364C63.83,11.687,91.32,1.524,117.123,3.163c1.506.1,1.421-2.088,0-2.271-28.158-3.7-56,4.128-77.274,23.325C24.489,38.072,12.456,58.86,10.027,80.1A15.53,15.53,0,0,0,4.272,75.5Z'
      transform='translate(76.679 0) rotate(47)'
      fill='#6147cd'
    />
  </svg>
);

const InputAvatar = ({
  preText = '',
  postText = '',
  placeholder = '',
  isRequired = false,
  isDisabled = false,
  image = null,
  onChange,
  isPublic = true,
  literals,
  value = null,
  error = null,
}) => {
  const [file, setFile] = useState(value);
  const [errorFile, setErrorFile] = useState(error);
  const [uploading, setUploading] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    if (!file && value) {
      setFile(value);
    }
  }, [file, value]);

  const changeValue = async () => {
    const fileData = imageRef.current.files[0];
    setUploading(imageRef.current.files[0]);

    try {
      const fileInfo = await uploadFile(fileData, isPublic);
      setFile(fileInfo);
      onChange(fileInfo);
    } catch (errors) {
      setErrorFile(errors);
    }

    setUploading(false);
  };

  const removeValue = () => {
    setFile(null);
    onChange(null);
  };

  const text = file ? file.name : preText;

  return (
    <div className='input_avatar'>
      <div className='file-wrapper'>
        <div className='pre_text'>{preText}</div>
        {arrowLeft}
        {file && file.id ? (
          <div className='img-wrapper'>
            <label htmlFor='file-upload' className='image-preview-label'>
              <img src={`${FILES_BASE_URL}${file.path}`} alt='preview-file' className='img-fluid input_avatar_img' />
            </label>
            {!isDisabled && (
              <div className='remove-icon'>
                <X size={20} onClick={() => removeValue()} />
              </div>
            )}
          </div>
        ) : (
          <label htmlFor='file-upload' className='custom-file-upload'>
            {inputAvatarBg}
            {image}
            {text}
            {isRequired && <span className='is_required'>*</span>}
          </label>
        )}
      </div>
      <input
        id='file-upload'
        className={`${errorFile ? 'input_error' : ''}`}
        type='file'
        ref={imageRef}
        placeholder={placeholder}
        disabled={isDisabled}
        onChange={changeValue}
      />
      { postText && (<div className='post_text'>{postText}</div>) }
      {errorFile && (
        <div className='errors'>
          <span>
            {literals.uploadFileError}
            <br />
          </span>
        </div>
      )}
      {uploading && <IOFileLoading file={uploading} />}
    </div>
  );
};

InputAvatar.propTypes = {
  preText: PropTypes.string,
  postText: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  image: PropTypes.object,
  isPublic: PropTypes.bool,
  literals: PropTypes.object.isRequired,
  value: PropTypes.object,
  error: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    literals: state.i18n.literals.input,
  };
};

export default connect(mapStateToProps, {})(InputAvatar);
