import AdminInstance from 'modules/admin/repository';
import KpisInstance from 'modules/kpis/repository';
import { formatErrors } from 'utils/functions';

export function createKpi(data) {
  return KpisInstance.createKpi(data)
    .then((kpi) => {
      return kpi;
    })
    .catch((error) => {
      throw formatErrors(error);
    });
}

export function editKpi(id, data) {
  return KpisInstance.editKpi(id, data)
    .then((kpi) => {
      return kpi;
    })
    .catch((error) => {
      throw formatErrors(error);
    });
}

export function deleteKpi(id) {
  return KpisInstance.deleteKpi(id)
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw formatErrors(error);
    });
}

export function fetchKpis(page, size, search, filters) {
  const opts = {
    page,
    size,
    search,
    filters,
    scope: 'startup',
    owner: 'all',
  };
  return KpisInstance.getKpis(opts);
}

export function fetchKpisTemplates(page, size, search, filters) {
  return KpisInstance.getKpisTemplates({
    page,
    size,
    search,
    filters,
  });
}

export function fetchKpiRelation(kpiId, modeltype, modelId) {
  return KpisInstance.getModelKpi(kpiId, modeltype, modelId);
}

export function fetchKpiRelations(kpi, params) {
  return AdminInstance.getAllKpiRelations(kpi, params);
}

export function createTemplate(data) {
  return KpisInstance.createKpisTemplate(data);
}

export function getTemplate(id) {
  return KpisInstance.getKpisTemplate(id);
}

export function editTemplate(id, data) {
  return KpisInstance.editKpisTemplate(id, data);
}

export function deleteTemplate(id) {
  return KpisInstance.deleteKpiTemplate(id);
}
