import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import { bindActionCreators } from 'redux';
import { fetchFormMentorAction, fetchFormStartupAction } from 'components/InternalForm/modules/actions';
import MentoringsSearchComponents from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.mentoring,
  user: state.session.user,
  forms: state.forms,
  startup: state.global.startup,
});

const mapDispatchToProps = dispatch => ({
  fetchFormMentor: bindActionCreators(fetchFormMentorAction, dispatch),
  fetchFormStartup: bindActionCreators(fetchFormStartupAction, dispatch),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MentoringsSearchComponents));
