import ServicesInstance from 'modules/services/repository';

export const getServices = (startup, page, size, search, filters, sort) => {
  return ServicesInstance.getActiveServices({
    startup, page, size, search, filters, sort,
  });
};

export const createServicesRequest = (data) => {
  return ServicesInstance.createServicesRequest(data);
};

export const deleteServicesRequest = (id) => {
  return ServicesInstance.deleteServicesRequest(id);
};
