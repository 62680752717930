import React from 'react';
import SocialIcon from 'components/SocialIcon';
import { Banknote, Image, User } from 'lucide-react';

const getCountries = literals => [
  { order: 0, text: { es: literals.countries.AFG, en: literals.countries.AFG }, id: 'AFG' },
  { order: 1, text: { es: literals.countries.ALA, en: literals.countries.ALA }, id: 'ALA' },
  { order: 2, text: { es: literals.countries.ALB, en: literals.countries.ALB }, id: 'ALB' },
  { order: 3, text: { es: literals.countries.DZA, en: literals.countries.DZA }, id: 'DZA' },
  { order: 4, text: { es: literals.countries.ASM, en: literals.countries.ASM }, id: 'ASM' },
  { order: 5, text: { es: literals.countries.AND, en: literals.countries.AND }, id: 'AND' },
  { order: 6, text: { es: literals.countries.AGO, en: literals.countries.AGO }, id: 'AGO' },
  { order: 7, text: { es: literals.countries.AIA, en: literals.countries.AIA }, id: 'AIA' },
  { order: 8, text: { es: literals.countries.ATA, en: literals.countries.ATA }, id: 'ATA' },
  { order: 9, text: { es: literals.countries.ATG, en: literals.countries.ATG }, id: 'ATG' },
  { order: 10, text: { es: literals.countries.ARG, en: literals.countries.ARG }, id: 'ARG' },
  { order: 11, text: { es: literals.countries.ARM, en: literals.countries.ARM }, id: 'ARM' },
  { order: 12, text: { es: literals.countries.ABW, en: literals.countries.ABW }, id: 'ABW' },
  { order: 13, text: { es: literals.countries.AUS, en: literals.countries.AUS }, id: 'AUS' },
  { order: 14, text: { es: literals.countries.AUT, en: literals.countries.AUT }, id: 'AUT' },
  { order: 15, text: { es: literals.countries.AZE, en: literals.countries.AZE }, id: 'AZE' },
  { order: 16, text: { es: literals.countries.BHS, en: literals.countries.BHS }, id: 'BHS' },
  { order: 17, text: { es: literals.countries.BHR, en: literals.countries.BHR }, id: 'BHR' },
  { order: 18, text: { es: literals.countries.BGD, en: literals.countries.BGD }, id: 'BGD' },
  { order: 19, text: { es: literals.countries.BRB, en: literals.countries.BRB }, id: 'BRB' },
  { order: 20, text: { es: literals.countries.BLR, en: literals.countries.BLR }, id: 'BLR' },
  { order: 21, text: { es: literals.countries.BEL, en: literals.countries.BEL }, id: 'BEL' },
  { order: 22, text: { es: literals.countries.BLZ, en: literals.countries.BLZ }, id: 'BLZ' },
  { order: 23, text: { es: literals.countries.BEN, en: literals.countries.BEN }, id: 'BEN' },
  { order: 24, text: { es: literals.countries.BMU, en: literals.countries.BMU }, id: 'BMU' },
  { order: 25, text: { es: literals.countries.BTN, en: literals.countries.BTN }, id: 'BTN' },
  { order: 26, text: { es: literals.countries.BOL, en: literals.countries.BOL }, id: 'BOL' },
  { order: 27, text: { es: literals.countries.BES, en: literals.countries.BES }, id: 'BES' },
  { order: 28, text: { es: literals.countries.BIH, en: literals.countries.BIH }, id: 'BIH' },
  { order: 29, text: { es: literals.countries.BWA, en: literals.countries.BWA }, id: 'BWA' },
  { order: 30, text: { es: literals.countries.BVT, en: literals.countries.BVT }, id: 'BVT' },
  { order: 31, text: { es: literals.countries.BRA, en: literals.countries.BRA }, id: 'BRA' },
  { order: 32, text: { es: literals.countries.IOT, en: literals.countries.IOT }, id: 'IOT' },
  { order: 33, text: { es: literals.countries.BRN, en: literals.countries.BRN }, id: 'BRN' },
  { order: 34, text: { es: literals.countries.BGR, en: literals.countries.BGR }, id: 'BGR' },
  { order: 35, text: { es: literals.countries.BFA, en: literals.countries.BFA }, id: 'BFA' },
  { order: 36, text: { es: literals.countries.BDI, en: literals.countries.BDI }, id: 'BDI' },
  { order: 37, text: { es: literals.countries.KHM, en: literals.countries.KHM }, id: 'KHM' },
  { order: 38, text: { es: literals.countries.CMR, en: literals.countries.CMR }, id: 'CMR' },
  { order: 39, text: { es: literals.countries.CAN, en: literals.countries.CAN }, id: 'CAN' },
  { order: 40, text: { es: literals.countries.CPV, en: literals.countries.CPV }, id: 'CPV' },
  { order: 41, text: { es: literals.countries.CYM, en: literals.countries.CYM }, id: 'CYM' },
  { order: 42, text: { es: literals.countries.CAF, en: literals.countries.CAF }, id: 'CAF' },
  { order: 43, text: { es: literals.countries.TCD, en: literals.countries.TCD }, id: 'TCD' },
  { order: 44, text: { es: literals.countries.CHL, en: literals.countries.CHL }, id: 'CHL' },
  { order: 45, text: { es: literals.countries.CHN, en: literals.countries.CHN }, id: 'CHN' },
  { order: 46, text: { es: literals.countries.CXR, en: literals.countries.CXR }, id: 'CXR' },
  { order: 47, text: { es: literals.countries.CCK, en: literals.countries.CCK }, id: 'CCK' },
  { order: 48, text: { es: literals.countries.COL, en: literals.countries.COL }, id: 'COL' },
  { order: 49, text: { es: literals.countries.COM, en: literals.countries.COM }, id: 'COM' },
  { order: 50, text: { es: literals.countries.COG, en: literals.countries.COG }, id: 'COG' },
  { order: 51, text: { es: literals.countries.COD, en: literals.countries.COD }, id: 'COD' },
  { order: 52, text: { es: literals.countries.COK, en: literals.countries.COK }, id: 'COK' },
  { order: 53, text: { es: literals.countries.CRI, en: literals.countries.CRI }, id: 'CRI' },
  { order: 54, text: { es: literals.countries.CIV, en: literals.countries.CIV }, id: 'CIV' },
  { order: 55, text: { es: literals.countries.HRV, en: literals.countries.HRV }, id: 'HRV' },
  { order: 56, text: { es: literals.countries.CUB, en: literals.countries.CUB }, id: 'CUB' },
  { order: 57, text: { es: literals.countries.CUW, en: literals.countries.CUW }, id: 'CUW' },
  { order: 58, text: { es: literals.countries.CYP, en: literals.countries.CYP }, id: 'CYP' },
  { order: 59, text: { es: literals.countries.CZE, en: literals.countries.CZE }, id: 'CZE' },
  { order: 60, text: { es: literals.countries.DNK, en: literals.countries.DNK }, id: 'DNK' },
  { order: 61, text: { es: literals.countries.DJI, en: literals.countries.DJI }, id: 'DJI' },
  { order: 62, text: { es: literals.countries.DMA, en: literals.countries.DMA }, id: 'DMA' },
  { order: 63, text: { es: literals.countries.DOM, en: literals.countries.DOM }, id: 'DOM' },
  { order: 64, text: { es: literals.countries.ECU, en: literals.countries.ECU }, id: 'ECU' },
  { order: 65, text: { es: literals.countries.EGY, en: literals.countries.EGY }, id: 'EGY' },
  { order: 66, text: { es: literals.countries.SLV, en: literals.countries.SLV }, id: 'SLV' },
  { order: 67, text: { es: literals.countries.GNQ, en: literals.countries.GNQ }, id: 'GNQ' },
  { order: 68, text: { es: literals.countries.ERI, en: literals.countries.ERI }, id: 'ERI' },
  { order: 69, text: { es: literals.countries.EST, en: literals.countries.EST }, id: 'EST' },
  { order: 70, text: { es: literals.countries.ETH, en: literals.countries.ETH }, id: 'ETH' },
  { order: 71, text: { es: literals.countries.FLK, en: literals.countries.FLK }, id: 'FLK' },
  { order: 72, text: { es: literals.countries.FRO, en: literals.countries.FRO }, id: 'FRO' },
  { order: 73, text: { es: literals.countries.FJI, en: literals.countries.FJI }, id: 'FJI' },
  { order: 74, text: { es: literals.countries.FIN, en: literals.countries.FIN }, id: 'FIN' },
  { order: 75, text: { es: literals.countries.FRA, en: literals.countries.FRA }, id: 'FRA' },
  { order: 76, text: { es: literals.countries.GUF, en: literals.countries.GUF }, id: 'GUF' },
  { order: 77, text: { es: literals.countries.PYF, en: literals.countries.PYF }, id: 'PYF' },
  { order: 78, text: { es: literals.countries.ATF, en: literals.countries.ATF }, id: 'ATF' },
  { order: 79, text: { es: literals.countries.GAB, en: literals.countries.GAB }, id: 'GAB' },
  { order: 80, text: { es: literals.countries.GMB, en: literals.countries.GMB }, id: 'GMB' },
  { order: 81, text: { es: literals.countries.GEO, en: literals.countries.GEO }, id: 'GEO' },
  { order: 82, text: { es: literals.countries.DEU, en: literals.countries.DEU }, id: 'DEU' },
  { order: 83, text: { es: literals.countries.GHA, en: literals.countries.GHA }, id: 'GHA' },
  { order: 84, text: { es: literals.countries.GIB, en: literals.countries.GIB }, id: 'GIB' },
  { order: 85, text: { es: literals.countries.GRC, en: literals.countries.GRC }, id: 'GRC' },
  { order: 86, text: { es: literals.countries.GRL, en: literals.countries.GRL }, id: 'GRL' },
  { order: 87, text: { es: literals.countries.GRD, en: literals.countries.GRD }, id: 'GRD' },
  { order: 88, text: { es: literals.countries.GLP, en: literals.countries.GLP }, id: 'GLP' },
  { order: 89, text: { es: literals.countries.GUM, en: literals.countries.GUM }, id: 'GUM' },
  { order: 90, text: { es: literals.countries.GTM, en: literals.countries.GTM }, id: 'GTM' },
  { order: 91, text: { es: literals.countries.GGY, en: literals.countries.GGY }, id: 'GGY' },
  { order: 92, text: { es: literals.countries.GIN, en: literals.countries.GIN }, id: 'GIN' },
  { order: 93, text: { es: literals.countries.GNB, en: literals.countries.GNB }, id: 'GNB' },
  { order: 94, text: { es: literals.countries.GUY, en: literals.countries.GUY }, id: 'GUY' },
  { order: 95, text: { es: literals.countries.HTI, en: literals.countries.HTI }, id: 'HTI' },
  { order: 96, text: { es: literals.countries.HMD, en: literals.countries.HMD }, id: 'HMD' },
  { order: 97, text: { es: literals.countries.VAT, en: literals.countries.VAT }, id: 'VAT' },
  { order: 98, text: { es: literals.countries.HND, en: literals.countries.HND }, id: 'HND' },
  { order: 99, text: { es: literals.countries.HKG, en: literals.countries.HKG }, id: 'HKG' },
  { order: 100, text: { es: literals.countries.HUN, en: literals.countries.HUN }, id: 'HUN' },
  { order: 101, text: { es: literals.countries.ISL, en: literals.countries.ISL }, id: 'ISL' },
  { order: 102, text: { es: literals.countries.IND, en: literals.countries.IND }, id: 'IND' },
  { order: 103, text: { es: literals.countries.IDN, en: literals.countries.IDN }, id: 'IDN' },
  { order: 104, text: { es: literals.countries.IRN, en: literals.countries.IRN }, id: 'IRN' },
  { order: 105, text: { es: literals.countries.IRQ, en: literals.countries.IRQ }, id: 'IRQ' },
  { order: 106, text: { es: literals.countries.IRL, en: literals.countries.IRL }, id: 'IRL' },
  { order: 107, text: { es: literals.countries.IMN, en: literals.countries.IMN }, id: 'IMN' },
  { order: 108, text: { es: literals.countries.ISR, en: literals.countries.ISR }, id: 'ISR' },
  { order: 109, text: { es: literals.countries.ITA, en: literals.countries.ITA }, id: 'ITA' },
  { order: 110, text: { es: literals.countries.JAM, en: literals.countries.JAM }, id: 'JAM' },
  { order: 111, text: { es: literals.countries.JPN, en: literals.countries.JPN }, id: 'JPN' },
  { order: 112, text: { es: literals.countries.JEY, en: literals.countries.JEY }, id: 'JEY' },
  { order: 113, text: { es: literals.countries.JOR, en: literals.countries.JOR }, id: 'JOR' },
  { order: 114, text: { es: literals.countries.KAZ, en: literals.countries.KAZ }, id: 'KAZ' },
  { order: 115, text: { es: literals.countries.KEN, en: literals.countries.KEN }, id: 'KEN' },
  { order: 116, text: { es: literals.countries.KIR, en: literals.countries.KIR }, id: 'KIR' },
  { order: 117, text: { es: literals.countries.PRK, en: literals.countries.PRK }, id: 'PRK' },
  { order: 118, text: { es: literals.countries.KOR, en: literals.countries.KOR }, id: 'KOR' },
  { order: 119, text: { es: literals.countries.KWT, en: literals.countries.KWT }, id: 'KWT' },
  { order: 120, text: { es: literals.countries.KGZ, en: literals.countries.KGZ }, id: 'KGZ' },
  { order: 121, text: { es: literals.countries.LAO, en: literals.countries.LAO }, id: 'LAO' },
  { order: 122, text: { es: literals.countries.LVA, en: literals.countries.LVA }, id: 'LVA' },
  { order: 123, text: { es: literals.countries.LBN, en: literals.countries.LBN }, id: 'LBN' },
  { order: 124, text: { es: literals.countries.LSO, en: literals.countries.LSO }, id: 'LSO' },
  { order: 125, text: { es: literals.countries.LBR, en: literals.countries.LBR }, id: 'LBR' },
  { order: 126, text: { es: literals.countries.LBY, en: literals.countries.LBY }, id: 'LBY' },
  { order: 127, text: { es: literals.countries.LIE, en: literals.countries.LIE }, id: 'LIE' },
  { order: 128, text: { es: literals.countries.LTU, en: literals.countries.LTU }, id: 'LTU' },
  { order: 129, text: { es: literals.countries.LUX, en: literals.countries.LUX }, id: 'LUX' },
  { order: 130, text: { es: literals.countries.MAC, en: literals.countries.MAC }, id: 'MAC' },
  { order: 131, text: { es: literals.countries.MKD, en: literals.countries.MKD }, id: 'MKD' },
  { order: 132, text: { es: literals.countries.MDG, en: literals.countries.MDG }, id: 'MDG' },
  { order: 133, text: { es: literals.countries.MWI, en: literals.countries.MWI }, id: 'MWI' },
  { order: 134, text: { es: literals.countries.MYS, en: literals.countries.MYS }, id: 'MYS' },
  { order: 135, text: { es: literals.countries.MDV, en: literals.countries.MDV }, id: 'MDV' },
  { order: 136, text: { es: literals.countries.MLI, en: literals.countries.MLI }, id: 'MLI' },
  { order: 137, text: { es: literals.countries.MLT, en: literals.countries.MLT }, id: 'MLT' },
  { order: 138, text: { es: literals.countries.MHL, en: literals.countries.MHL }, id: 'MHL' },
  { order: 139, text: { es: literals.countries.MTQ, en: literals.countries.MTQ }, id: 'MTQ' },
  { order: 140, text: { es: literals.countries.MRT, en: literals.countries.MRT }, id: 'MRT' },
  { order: 141, text: { es: literals.countries.MUS, en: literals.countries.MUS }, id: 'MUS' },
  { order: 142, text: { es: literals.countries.MYT, en: literals.countries.MYT }, id: 'MYT' },
  { order: 143, text: { es: literals.countries.MEX, en: literals.countries.MEX }, id: 'MEX' },
  { order: 144, text: { es: literals.countries.FSM, en: literals.countries.FSM }, id: 'FSM' },
  { order: 145, text: { es: literals.countries.MAR, en: literals.countries.MAR }, id: 'MAR' },
  { order: 146, text: { es: literals.countries.MDA, en: literals.countries.MDA }, id: 'MDA' },
  { order: 147, text: { es: literals.countries.MCO, en: literals.countries.MCO }, id: 'MCO' },
  { order: 148, text: { es: literals.countries.MNG, en: literals.countries.MNG }, id: 'MNG' },
  { order: 149, text: { es: literals.countries.MNE, en: literals.countries.MNE }, id: 'MNE' },
  { order: 150, text: { es: literals.countries.MSR, en: literals.countries.MSR }, id: 'MSR' },
  { order: 151, text: { es: literals.countries.MOZ, en: literals.countries.MOZ }, id: 'MOZ' },
  { order: 152, text: { es: literals.countries.MMR, en: literals.countries.MMR }, id: 'MMR' },
  { order: 153, text: { es: literals.countries.NAM, en: literals.countries.NAM }, id: 'NAM' },
  { order: 154, text: { es: literals.countries.NRU, en: literals.countries.NRU }, id: 'NRU' },
  { order: 155, text: { es: literals.countries.NPL, en: literals.countries.NPL }, id: 'NPL' },
  { order: 156, text: { es: literals.countries.NLD, en: literals.countries.NLD }, id: 'NLD' },
  { order: 157, text: { es: literals.countries.NCL, en: literals.countries.NCL }, id: 'NCL' },
  { order: 158, text: { es: literals.countries.NZL, en: literals.countries.NZL }, id: 'NZL' },
  { order: 159, text: { es: literals.countries.NIC, en: literals.countries.NIC }, id: 'NIC' },
  { order: 160, text: { es: literals.countries.NER, en: literals.countries.NER }, id: 'NER' },
  { order: 161, text: { es: literals.countries.NGA, en: literals.countries.NGA }, id: 'NGA' },
  { order: 162, text: { es: literals.countries.NIU, en: literals.countries.NIU }, id: 'NIU' },
  { order: 163, text: { es: literals.countries.NFK, en: literals.countries.NFK }, id: 'NFK' },
  { order: 164, text: { es: literals.countries.MNP, en: literals.countries.MNP }, id: 'MNP' },
  { order: 165, text: { es: literals.countries.NOR, en: literals.countries.NOR }, id: 'NOR' },
  { order: 166, text: { es: literals.countries.OMN, en: literals.countries.OMN }, id: 'OMN' },
  { order: 167, text: { es: literals.countries.PAK, en: literals.countries.PAK }, id: 'PAK' },
  { order: 168, text: { es: literals.countries.PLW, en: literals.countries.PLW }, id: 'PLW' },
  { order: 169, text: { es: literals.countries.PSE, en: literals.countries.PSE }, id: 'PSE' },
  { order: 170, text: { es: literals.countries.PAN, en: literals.countries.PAN }, id: 'PAN' },
  { order: 171, text: { es: literals.countries.PNG, en: literals.countries.PNG }, id: 'PNG' },
  { order: 172, text: { es: literals.countries.PRY, en: literals.countries.PRY }, id: 'PRY' },
  { order: 173, text: { es: literals.countries.PER, en: literals.countries.PER }, id: 'PER' },
  { order: 174, text: { es: literals.countries.PHL, en: literals.countries.PHL }, id: 'PHL' },
  { order: 175, text: { es: literals.countries.PCN, en: literals.countries.PCN }, id: 'PCN' },
  { order: 176, text: { es: literals.countries.POL, en: literals.countries.POL }, id: 'POL' },
  { order: 177, text: { es: literals.countries.PRT, en: literals.countries.PRT }, id: 'PRT' },
  { order: 178, text: { es: literals.countries.PRI, en: literals.countries.PRI }, id: 'PRI' },
  { order: 179, text: { es: literals.countries.QAT, en: literals.countries.QAT }, id: 'QAT' },
  { order: 180, text: { es: literals.countries.REU, en: literals.countries.REU }, id: 'REU' },
  { order: 181, text: { es: literals.countries.ROU, en: literals.countries.ROU }, id: 'ROU' },
  { order: 182, text: { es: literals.countries.RUS, en: literals.countries.RUS }, id: 'RUS' },
  { order: 183, text: { es: literals.countries.RWA, en: literals.countries.RWA }, id: 'RWA' },
  { order: 184, text: { es: literals.countries.BLM, en: literals.countries.BLM }, id: 'BLM' },
  { order: 185, text: { es: literals.countries.SHN, en: literals.countries.SHN }, id: 'SHN' },
  { order: 186, text: { es: literals.countries.KNA, en: literals.countries.KNA }, id: 'KNA' },
  { order: 187, text: { es: literals.countries.LCA, en: literals.countries.LCA }, id: 'LCA' },
  { order: 188, text: { es: literals.countries.MAF, en: literals.countries.MAF }, id: 'MAF' },
  { order: 189, text: { es: literals.countries.SPM, en: literals.countries.SPM }, id: 'SPM' },
  { order: 190, text: { es: literals.countries.VCT, en: literals.countries.VCT }, id: 'VCT' },
  { order: 191, text: { es: literals.countries.WSM, en: literals.countries.WSM }, id: 'WSM' },
  { order: 192, text: { es: literals.countries.SMR, en: literals.countries.SMR }, id: 'SMR' },
  { order: 193, text: { es: literals.countries.STP, en: literals.countries.STP }, id: 'STP' },
  { order: 194, text: { es: literals.countries.SAU, en: literals.countries.SAU }, id: 'SAU' },
  { order: 195, text: { es: literals.countries.SEN, en: literals.countries.SEN }, id: 'SEN' },
  { order: 196, text: { es: literals.countries.SRB, en: literals.countries.SRB }, id: 'SRB' },
  { order: 197, text: { es: literals.countries.SYC, en: literals.countries.SYC }, id: 'SYC' },
  { order: 198, text: { es: literals.countries.SLE, en: literals.countries.SLE }, id: 'SLE' },
  { order: 199, text: { es: literals.countries.SGP, en: literals.countries.SGP }, id: 'SGP' },
  { order: 200, text: { es: literals.countries.SXM, en: literals.countries.SXM }, id: 'SXM' },
  { order: 201, text: { es: literals.countries.SVK, en: literals.countries.SVK }, id: 'SVK' },
  { order: 202, text: { es: literals.countries.SVN, en: literals.countries.SVN }, id: 'SVN' },
  { order: 203, text: { es: literals.countries.SLB, en: literals.countries.SLB }, id: 'SLB' },
  { order: 204, text: { es: literals.countries.SOM, en: literals.countries.SOM }, id: 'SOM' },
  { order: 205, text: { es: literals.countries.ZAF, en: literals.countries.ZAF }, id: 'ZAF' },
  { order: 206, text: { es: literals.countries.SGS, en: literals.countries.SGS }, id: 'SGS' },
  { order: 207, text: { es: literals.countries.SSD, en: literals.countries.SSD }, id: 'SSD' },
  { order: 208, text: { es: literals.countries.ESP, en: literals.countries.ESP }, id: 'ESP' },
  { order: 209, text: { es: literals.countries.LKA, en: literals.countries.LKA }, id: 'LKA' },
  { order: 210, text: { es: literals.countries.SDN, en: literals.countries.SDN }, id: 'SDN' },
  { order: 211, text: { es: literals.countries.SUR, en: literals.countries.SUR }, id: 'SUR' },
  { order: 212, text: { es: literals.countries.SJM, en: literals.countries.SJM }, id: 'SJM' },
  { order: 213, text: { es: literals.countries.SWZ, en: literals.countries.SWZ }, id: 'SWZ' },
  { order: 214, text: { es: literals.countries.SWE, en: literals.countries.SWE }, id: 'SWE' },
  { order: 215, text: { es: literals.countries.CHE, en: literals.countries.CHE }, id: 'CHE' },
  { order: 216, text: { es: literals.countries.SYR, en: literals.countries.SYR }, id: 'SYR' },
  { order: 217, text: { es: literals.countries.TWN, en: literals.countries.TWN }, id: 'TWN' },
  { order: 218, text: { es: literals.countries.TJK, en: literals.countries.TJK }, id: 'TJK' },
  { order: 219, text: { es: literals.countries.TZA, en: literals.countries.TZA }, id: 'TZA' },
  { order: 220, text: { es: literals.countries.THA, en: literals.countries.THA }, id: 'THA' },
  { order: 221, text: { es: literals.countries.TLS, en: literals.countries.TLS }, id: 'TLS' },
  { order: 222, text: { es: literals.countries.TGO, en: literals.countries.TGO }, id: 'TGO' },
  { order: 223, text: { es: literals.countries.TKL, en: literals.countries.TKL }, id: 'TKL' },
  { order: 224, text: { es: literals.countries.TON, en: literals.countries.TON }, id: 'TON' },
  { order: 225, text: { es: literals.countries.TTO, en: literals.countries.TTO }, id: 'TTO' },
  { order: 226, text: { es: literals.countries.TUN, en: literals.countries.TUN }, id: 'TUN' },
  { order: 227, text: { es: literals.countries.TUR, en: literals.countries.TUR }, id: 'TUR' },
  { order: 228, text: { es: literals.countries.TKM, en: literals.countries.TKM }, id: 'TKM' },
  { order: 229, text: { es: literals.countries.TCA, en: literals.countries.TCA }, id: 'TCA' },
  { order: 230, text: { es: literals.countries.TUV, en: literals.countries.TUV }, id: 'TUV' },
  { order: 231, text: { es: literals.countries.UGA, en: literals.countries.UGA }, id: 'UGA' },
  { order: 232, text: { es: literals.countries.UKR, en: literals.countries.UKR }, id: 'UKR' },
  { order: 233, text: { es: literals.countries.ARE, en: literals.countries.ARE }, id: 'ARE' },
  { order: 234, text: { es: literals.countries.GBR, en: literals.countries.GBR }, id: 'GBR' },
  { order: 235, text: { es: literals.countries.USA, en: literals.countries.USA }, id: 'USA' },
  { order: 236, text: { es: literals.countries.UMI, en: literals.countries.UMI }, id: 'UMI' },
  { order: 237, text: { es: literals.countries.URY, en: literals.countries.URY }, id: 'URY' },
  { order: 238, text: { es: literals.countries.UZB, en: literals.countries.UZB }, id: 'UZB' },
  { order: 239, text: { es: literals.countries.VUT, en: literals.countries.VUT }, id: 'VUT' },
  { order: 240, text: { es: literals.countries.VEN, en: literals.countries.VEN }, id: 'VEN' },
  { order: 241, text: { es: literals.countries.VNM, en: literals.countries.VNM }, id: 'VNM' },
  { order: 242, text: { es: literals.countries.VGB, en: literals.countries.VGB }, id: 'VGB' },
  { order: 243, text: { es: literals.countries.VIR, en: literals.countries.VIR }, id: 'VIR' },
  { order: 244, text: { es: literals.countries.WLF, en: literals.countries.WLF }, id: 'WLF' },
  { order: 245, text: { es: literals.countries.ESH, en: literals.countries.ESH }, id: 'ESH' },
  { order: 246, text: { es: literals.countries.YEM, en: literals.countries.YEM }, id: 'YEM' },
  { order: 247, text: { es: literals.countries.ZMB, en: literals.countries.ZMB }, id: 'ZMB' },
  { order: 248, text: { es: literals.countries.ZWE, en: literals.countries.ZWE }, id: 'ZWE' },
];

const industries = [
  'Health', 'Fintech', 'Food', 'Media', 'Marketing', 'Enterprise Software', 'Transportation', 'Fashion', 'Real Estate',
  'Home Living', 'Education', 'Kids', 'Energy', 'Wellness Beauty', 'Sports', 'Hosting', 'Gaming', 'Travel',
  'Semiconductors', 'Music', 'Event Tech', 'Jobs Recruitment', 'Security', 'Legal', 'Robotics', 'Telecom', 'Dating',
  'Service Provider', 'Engineering And Manufacturing Equipment', 'Space', 'Consumer Electronics', 'Chemicals',
];

const subIndustries = [
  'Health Platform', 'Biotechnology', 'Medical Devices', 'Pharmaceutical', 'Financial Management Solutions',
  'Wealth Management', 'Payments', 'Mortgages & Lending', 'Insurance', 'Crypto And DeFi', 'Banking', 'Regtech',
  'Innovative Food', 'Agritech', 'Food Logistics & Delivery', 'In-Store Retail & Restaurant Tech', 'Kitchen & Cooking Tech',
  'Content Production', 'Publishing', 'Social Media', 'Streaming', 'Adtech', 'CRM & Sales', 'Ecommerce Solutions',
  'Marketing Analytics', 'Logistics & Delivery', 'Mobility', 'Vehicle Production', 'Search, Buy & Rent',
  'Navigation & Mapping', 'Maintenance', 'Autonomous & Sensor Tech', 'Apparel', 'Accessories', 'Luxury', 'Footwear',
  'Construction', 'Real Estate Services', 'Real Estate Software', 'Workspaces', 'Learning Tools And Resources',
  'Education Providers', 'Education Management', 'Clean Energy', 'Energy Efficiency', 'Oil & Gas', 'Waste Solution',
  'Water', 'Energy Providers', 'Energy Storage', 'Sport Platform & Application', 'Sporting Equipment', 'Fitness',
  'Sport League & Club', 'Sport Media', 'Sport Supplements', 'Console & PC Gaming', 'Mobile Gaming', 'Betting & Gambling',
  'Esports', 'Board Games', 'Online Travel Agency', 'Accommodation', 'Booking & Search', 'Travel Analytics & Software',
  'Business Travel', 'Public Safety', 'Cloud & Infrastructure', 'Data Protection', 'Identity & Access',
  'Device Security & Antivirus', 'Consulting',
];


// Send onboarding literals to create correctly
export const createDefaultQuestionsMentor = (literals, platformQuestions) => {
  const defaultQuestions = [
    {
      id: 'defaultQuestion1',
      type: 'question',
      default: true,
      info: {
        id: 'avatar',
        mandatory: false,
        placeholder: { en: literals.avatar, es: literals.avatar },
        title: {
          en: literals.uploadAvatar,
          es: literals.uploadAvatar,
        },
        tooltip: { en: '', es: '' },
        type: 'avatar',
        image: <User size={60} className='file-icon' />,
      },
    },
    {
      id: 'defaultQuestion2',
      type: 'question',
      default: true,
      info: {
        id: 'headline',
        mandatory: true,
        placeholder: { en: literals.headline, es: literals.headline },
        title: {
          en: literals.headline,
          es: literals.headline,
        },
        tooltip: { en: '', es: '' },
        type: 'textarea',
      },
    },
    {
      id: 'defaultQuestion3',
      type: 'question',
      default: true,
      info: {
        id: 'company',
        mandatory: false,
        placeholder: { en: literals.company, es: literals.company },
        title: {
          en: literals.company,
          es: literals.company,
        },
        tooltip: { en: '', es: '' },
        type: 'text',
      },
    },
    {
      id: 'defaultQuestion4',
      type: 'question',
      default: true,
      info: {
        id: 'country',
        mandatory: false,
        placeholder: { en: literals.country, es: literals.country },
        title: {
          en: literals.country,
          es: literals.country,
        },
        tooltip: { en: '', es: '' },
        type: 'select',
        answers: [
          { order: 0, text: { es: literals.countries.ARG, en: literals.countries.ARG }, id: 'ARG' },
          { order: 1, text: { es: literals.countries.BEL, en: literals.countries.BEL }, id: 'BEL' },
          { order: 2, text: { es: literals.countries.BRA, en: literals.countries.BRA }, id: 'BRA' },
          { order: 3, text: { es: literals.countries.COL, en: literals.countries.COL }, id: 'COL' },
          { order: 4, text: { es: literals.countries.DEU, en: literals.countries.DEU }, id: 'DEU' },
          { order: 5, text: { es: literals.countries.FRA, en: literals.countries.FRA }, id: 'FRA' },
          { order: 6, text: { es: literals.countries.GBR, en: literals.countries.GBR }, id: 'GBR' },
          { order: 7, text: { es: literals.countries.ITA, en: literals.countries.ITA }, id: 'ITA' },
          { order: 8, text: { es: literals.countries.ESP, en: literals.countries.ESP }, id: 'ESP' },
          { order: 9, text: { es: literals.countries.USA, en: literals.countries.USA }, id: 'USA' },
        ],
      },
    },
    {
      id: 'defaultQuestion5',
      type: 'question',
      default: true,
      info: {
        id: 'hours',
        mandatory: false,
        placeholder: { en: literals.hours, es: literals.hours },
        title: {
          en: literals.hoursToDonate,
          es: literals.hoursToDonate,
        },
        tooltip: { en: '', es: '' },
        type: 'number',
        min: 0,
        max: 99999,
      },
    },
    {
      id: 'defaultQuestion6',
      type: 'question',
      default: true,
      info: {
        id: 'visible',
        mandatory: false,
        placeholder: { en: literals.mentorVisible, es: literals.mentorVisible },
        title: {
          en: literals.mentorVisible,
          es: literals.mentorVisible,
        },
        tooltip: { en: '', es: '' },
        type: 'select',
        answers: [
          { order: 0, text: { es: literals.yes, en: literals.yes }, id: true },
          { order: 1, text: { es: literals.no, en: literals.no }, id: false },
        ],
      },
    },
  ];

  return defaultQuestions
    .filter(dq => platformQuestions.includes(dq.info.id))
    .map((dq) => {
      const order = platformQuestions.indexOf(dq.info.id) - platformQuestions.length;
      return { ...dq, order };
    })
    .sort((a, b) => a.order - b.order);
};

export const createDefaultQuestionsInvestor = (literals) => {
  const defaultQuestions = [
    {
      id: 'defaultQuestion1',
      type: 'type',
      default: true,
      info: {
        id: 'type',
        mandatory: true,
        placeholder: { en: literals.type, es: literals.type },
        title: {
          en: literals.type,
          es: literals.type,
        },
        tooltip: { en: '', es: '' },
        type: 'select',
        answers: [
          { order: 0, text: { es: literals.venture_capital, en: literals.venture_capital }, id: 'venture_capital' },
          { order: 1, text: { es: literals.funds_of_funds, en: literals.funds_of_funds }, id: 'funds_of_funds' },
          { order: 2, text: { es: literals.debt, en: literals.debt }, id: 'debt' },
          { order: 3, text: { es: literals.other, en: literals.other }, id: 'other' },
        ],
      },
    },
    {
      id: 'defaultQuestion2',
      type: 'question',
      default: true,
      info: {
        id: 'minPerProject',
        mandatory: false,
        placeholder: { en: literals.minPerProject, es: literals.minPerProject },
        title: {
          en: literals.minPerProject,
          es: literals.minPerProject,
        },
        tooltip: { en: '', es: '' },
        type: 'number',
      },
    },
    {
      id: 'defaultQuestion3',
      type: 'question',
      default: true,
      info: {
        id: 'maxPerProject',
        mandatory: false,
        placeholder: { en: literals.maxPerProject, es: literals.maxPerProject },
        title: {
          en: literals.maxPerProject,
          es: literals.maxPerProject,
        },
        tooltip: { en: '', es: '' },
        type: 'number',
      },
    },
    {
      id: 'defaultQuestion4',
      type: 'question',
      default: true,
      info: {
        id: 'phase',
        mandatory: false,
        placeholder: { en: literals.phase, es: literals.phase },
        title: {
          en: literals.phase,
          es: literals.phase,
        },
        tooltip: { en: '', es: '' },
        type: 'list',
        answers: [
          { order: 0, text: { es: literals.preseed, en: literals.pre_seed }, id: 'preseed' },
          { order: 1, text: { es: literals.seed, en: literals.seed }, id: 'seed' },
          { order: 2, text: { es: literals.early, en: literals.early }, id: 'early' },
          { order: 3, text: { es: literals.growth, en: literals.growth }, id: 'growth' },
          { order: 4, text: { es: literals.expansion, en: literals.expansion }, id: 'expansion' },
        ],
      },
    },
  ];

  return defaultQuestions;
  // .filter(dq => platformQuestions.includes(dq.info.id))
  // .map((dq) => {
  //   const order = platformQuestions.indexOf(dq.info.id) - platformQuestions.length;
  //   return { ...dq, order };
  // })
  // .sort((a, b) => a.order - b.order);
};

export const createDefaultQuestionsFund = (literals, platformQuestions) => {
  const defaultQuestions = [
    {
      id: 'defaultQuestion1',
      type: 'question',
      default: true,
      info: {
        id: 'logo',
        mandatory: false,
        placeholder: { en: literals.logo, es: literals.logo },
        title: {
          en: literals.uploadLogo,
          es: literals.uploadLogo,
        },
        tooltip: { en: '', es: '' },
        type: 'avatar',
        image: <Banknote size={60} className='file-icon' />,
      },
    },
    {
      id: 'defaultQuestion2',
      type: 'question',
      default: true,
      info: {
        id: 'name',
        mandatory: true,
        placeholder: { en: literals.name, es: literals.name },
        title: {
          en: literals.name,
          es: literals.name,
        },
        tooltip: { en: '', es: '' },
        type: 'text',
      },
    },
    {
      id: 'defaultQuestion3',
      type: 'question',
      default: true,
      info: {
        id: 'lei',
        mandatory: false,
        placeholder: { en: 'LEI', es: 'LEI' },
        title: {
          en: 'LEI',
          es: 'LEI',
        },
        tooltip: { en: '', es: '' },
        type: 'text',
      },
    },
    {
      id: 'defaultQuestion4',
      type: 'question',
      default: true,
      info: {
        id: 'fundManager',
        mandatory: false,
        placeholder: { en: literals.fundManager, es: literals.fundManager },
        title: {
          en: literals.fundManager,
          es: literals.fundManager,
        },
        tooltip: { en: '', es: '' },
        type: 'text',
      },
    },
    {
      id: 'defaultQuestion5',
      type: 'question',
      default: true,
      info: {
        id: 'country',
        mandatory: true,
        placeholder: { en: literals.country, es: literals.country },
        title: {
          en: literals.country,
          es: literals.country,
        },
        tooltip: { en: '', es: '' },
        type: 'select',
        answers: getCountries(literals),
      },
    },
    {
      id: 'defaultQuestion6',
      type: 'question',
      default: true,
      info: {
        id: 'address',
        mandatory: false,
        placeholder: { en: literals.address, es: literals.address },
        title: {
          en: literals.address,
          es: literals.address,
        },
        tooltip: { en: '', es: '' },
        type: 'text',
      },
    },
    {
      id: 'defaultQuestion7',
      type: 'question',
      default: true,
      info: {
        id: 'investmentPolicy',
        mandatory: false,
        placeholder: { en: literals.investmentPolicy.title, es: literals.investmentPolicy.title },
        title: {
          en: literals.investmentPolicy.title,
          es: literals.investmentPolicy.title,
        },
        tooltip: { en: '', es: '' },
        type: 'list',
        answers: [
          { order: 1, text: { es: literals.investmentPolicy.ventureCapital, en: literals.investmentPolicy.ventureCapital }, id: 'ventureCapital' },
          { order: 2, text: { es: literals.investmentPolicy.privateEquity, en: literals.investmentPolicy.privateEquity }, id: 'privateEquity' },
          { order: 3, text: { es: literals.investmentPolicy.corporateVentureCapital, en: literals.investmentPolicy.corporateVentureCapital }, id: 'corporateVentureCapital' },
          { order: 4, text: { es: literals.investmentPolicy.realEstate, en: literals.investmentPolicy.realEstate }, id: 'realEstate' },
          { order: 5, text: { es: literals.investmentPolicy.privateDebt, en: literals.investmentPolicy.privateDebt }, id: 'privateDebt' },
        ],
      },
    },
    {
      id: 'defaultQuestion8',
      type: 'question',
      default: true,
      info: {
        id: 'institutionType',
        mandatory: false,
        placeholder: { en: literals.institutionType.title, es: literals.institutionType.title },
        title: {
          en: literals.institutionType.title,
          es: literals.institutionType.title,
        },
        tooltip: { en: '', es: '' },
        type: 'select',
        answers: [
          { order: 1, text: { es: literals.institutionType.opened, en: literals.institutionType.opened }, id: 'opened' },
          { order: 2, text: { es: literals.institutionType.closed, en: literals.institutionType.closed }, id: 'closed' },
        ],
      },
    },
    {
      id: 'defaultQuestion9',
      type: 'question',
      default: true,
      info: {
        id: 'subfund',
        mandatory: false,
        placeholder: { en: literals.subfund, es: literals.subfund },
        title: {
          en: literals.subfund,
          es: literals.subfund,
        },
        tooltip: { en: '', es: '' },
        type: 'select',
        answers: [
          { order: 1, text: { es: literals.yes, en: literals.yes }, id: true },
          { order: 2, text: { es: literals.no, en: literals.no }, id: false },
        ],
      },
    },
    {
      id: 'defaultQuestion10',
      type: 'question',
      default: true,
      info: {
        id: 'isin',
        mandatory: false,
        placeholder: { en: 'ISIN', es: 'ISIN' },
        title: {
          en: 'ISIN',
          es: 'ISIN',
        },
        tooltip: { en: '', es: '' },
        type: 'multiValue',
      },
    },
    {
      id: 'defaultQuestion11',
      type: 'question',
      default: true,
      info: {
        id: 'managementCountry',
        mandatory: false,
        placeholder: { en: literals.managementCompanyCountry, es: literals.managementCompanyCountry },
        title: {
          en: literals.managementCompanyCountry,
          es: literals.managementCompanyCountry,
        },
        tooltip: { en: '', es: '' },
        type: 'select',
        answers: getCountries(literals),
      },
    },
    {
      id: 'defaultQuestion12',
      type: 'question',
      default: true,
      info: {
        id: 'managementAddress',
        mandatory: false,
        placeholder: { en: literals.managementCompanyAddress, es: literals.managementCompanyAddress },
        title: {
          en: literals.managementCompanyAddress,
          es: literals.managementCompanyAddress,
        },
        tooltip: { en: '', es: '' },
        type: 'text',
      },
    },
    {
      id: 'defaultQuestion13',
      type: 'question',
      default: true,
      info: {
        id: 'regulator',
        mandatory: false,
        placeholder: { en: literals.fundRegulator, es: literals.fundRegulator },
        title: {
          en: literals.fundRegulator,
          es: literals.fundRegulator,
        },
        tooltip: { en: '', es: '' },
        type: 'text',
      },
    },
  ];

  return defaultQuestions
    .filter(dq => platformQuestions.includes(dq.info.id))
    .map((dq) => {
      const order = platformQuestions.indexOf(dq.info.id) - platformQuestions.length;
      return { ...dq, order };
    })
    .sort((a, b) => a.order - b.order);
};

export const createDefaultQuestionsStartup = (literals, literalsCommon = {}, platformQuestions, admin = false) => {
  const defaultQuestions = [
    {
      id: 'defaultQuestion0',
      type: 'question',
      default: true,
      admin: true,
      info: {
        id: 'externalId',
        mandatory: false,
        title: {
          en: literals.externalId,
          es: literals.externalId,
        },
        placeholder: {
          en: 'XXXXXX-XX-XXXXXXXX-XXXX-XXXXXXX',
          es: 'XXXXXX-XX-XXXXXXXX-XXXX-XXXXXXX',
        },
        tooltip: { en: '', es: '' },
        type: 'text',
      },
    },
    {
      id: 'defaultQuestion1',
      type: 'question',
      default: true,
      info: {
        id: 'logo',
        mandatory: false,
        placeholder: { en: literals.logo, es: literals.logo },
        title: {
          en: literals.uploadLogo,
          es: literals.uploadLogo,
        },
        tooltip: { en: '', es: '' },
        type: 'avatar',
        image: <Image size={60} className='file-icon' />,
      },
    },
    {
      id: 'defaultQuestion2',
      type: 'question',
      default: true,
      info: {
        id: 'name',
        mandatory: true,
        placeholder: { en: literals.startupName, es: literals.startupName },
        title: {
          en: literals.startupName,
          es: literals.startupName,
        },
        tooltip: { en: '', es: '' },
        type: 'text',
      },
    },
    {
      id: 'defaultQuestion3',
      type: 'question',
      default: true,
      info: {
        id: 'description',
        mandatory: true,
        placeholder: { en: literals.description, es: literals.description },
        title: {
          en: literals.description,
          es: literals.description,
        },
        tooltip: { en: '', es: '' },
        type: 'textarea',
      },
    },
    {
      id: 'defaultQuestion4',
      type: 'question',
      default: true,
      info: {
        id: 'country',
        mandatory: false,
        placeholder: { en: literals.country, es: literals.country },
        title: {
          en: literals.country,
          es: literals.country,
        },
        tooltip: { en: '', es: '' },
        type: 'select',
        answers: getCountries(literals),
      },
    },
    {
      id: 'defaultQuestion5',
      type: 'question',
      default: true,
      info: {
        id: 'legalForm',
        mandatory: false,
        placeholder: { en: literals.legalForm, es: literals.legalForm },
        title: { en: literals.legalForm, es: literals.legalForm },
        tooltip: { en: '', es: '' },
        type: 'select',
        answers: [
          { order: 0, text: { es: 'SE', en: 'SE' }, id: 'EU100' },
          { order: 1, text: { es: 'SCE', en: 'SCE' }, id: 'EU200' },
          { order: 2, text: { es: 'EEIG', en: 'EEIG' }, id: 'EU300' },
          { order: 3, text: { es: 'EGTC', en: 'EGTC' }, id: 'EU400' },
          { order: 4, text: { es: 'OG', en: 'OG' }, id: 'AT102' },
          { order: 5, text: { es: 'KG', en: 'KG' }, id: 'AT103' },
          { order: 6, text: { es: 'AG', en: 'AG' }, id: 'AT201' },
          { order: 7, text: { es: 'GmbH', en: 'GmbH' }, id: 'AT202' },
          { order: 8, text: { es: 'GEN', en: 'GEN' }, id: 'AT401' },
          { order: 9, text: { es: 'ASBL/VZW/VoG', en: 'ASBL/VZW/VoG' }, id: 'BE017' },
          { order: 10, text: { es: 'SA/NV/AG', en: 'SA/NV/AG' }, id: 'BE014' },
          { order: 11, text: { es: 'SC/CV/Gen', en: 'SC/CV/Gen' }, id: 'BE706' },
          { order: 12, text: { es: 'SComm/CommV/KommG', en: 'SComm/CommV/KommG' }, id: 'BE612' },
          { order: 13, text: { es: 'SDC/MS/GaR', en: 'SDC/MS/GaR' }, id: 'BE702' },
          { order: 14, text: { es: 'SNC/V.O.F./OHG', en: 'SNC/V.O.F./OHG' }, id: 'BE011' },
          { order: 15, text: { es: 'SPRL/BVBA/PGmbH', en: 'SPRL/BVBA/PGmbH' }, id: 'BE015' },
          { order: 16, text: { es: 'SRL/BV/GmbH', en: 'SRL/BV/GmbH' }, id: 'BE610' },
          { order: 17, text: { es: 'OOD', en: 'OOD' }, id: 'BG102' },
          { order: 18, text: { es: 'EOOD', en: 'EOOD' }, id: 'BG105' },
          { order: 19, text: { es: 'KD', en: 'KD' }, id: 'BG106' },
          { order: 20, text: { es: 'SD/S-ie', en: 'SD/S-ie' }, id: 'BG107' },
          { order: 21, text: { es: 'ET', en: 'ET' }, id: 'BG462' },
          { order: 22, text: { es: 'Coop', en: 'Coop' }, id: 'BG466' },
          { order: 23, text: { es: 'd.o.o.', en: 'd.o.o.' }, id: 'HR02' },
          { order: 24, text: { es: 'k.d.', en: 'k.d.' }, id: 'HR04' },
          { order: 25, text: { es: 'Udruga', en: 'Udruga' }, id: 'HR47' },
          { order: 26, text: { es: 'Zadruga', en: 'Zadruga' }, id: 'HR53' },
          { order: 27, text: { es: 'LTD', en: 'LTD' }, id: 'CY102' },
          { order: 28, text: { es: 'PLC', en: 'PLC' }, id: 'CY103' },
          { order: 29, text: { es: 'P', en: 'P' }, id: 'CY109' },
          { order: 30, text: { es: 'SP', en: 'SP' }, id: 'CY112' },
          { order: 31, text: { es: 'v.o.s.', en: 'v.o.s.' }, id: 'CZ111' },
          { order: 32, text: { es: 's.r.o.', en: 's.r.o.' }, id: 'CZ112' },
          { order: 33, text: { es: 'k.s.', en: 'k.s.' }, id: 'CZ113' },
          { order: 34, text: { es: 'a.s.', en: 'a.s.' }, id: 'CZ121' },
          { order: 35, text: { es: 's.m.b.a.', en: 's.m.b.a.' }, id: 'DK151' },
          { order: 36, text: { es: 'I/S', en: 'I/S' }, id: 'DK30' },
          { order: 37, text: { es: 'K/S', en: 'K/S' }, id: 'DK40' },
          { order: 38, text: { es: 'A/S', en: 'A/S' }, id: 'DK60' },
          { order: 39, text: { es: 'ApS', en: 'ApS' }, id: 'DK80' },
          { order: 40, text: { es: 'AS', en: 'AS' }, id: 'EE101' },
          { order: 41, text: { es: 'OÜ', en: 'OÜ' }, id: 'EE102' },
          { order: 42, text: { es: 'TÜ', en: 'TÜ' }, id: 'EE201' },
          { order: 43, text: { es: 'UÜ', en: 'UÜ' }, id: 'EE202' },
          { order: 44, text: { es: 'MTÜ', en: 'MTÜ' }, id: 'EE203' },
          { order: 45, text: { es: 'ay', en: 'ay' }, id: 'FI10' },
          { order: 46, text: { es: 'ky/kb', en: 'ky/kb' }, id: 'FI11' },
          { order: 47, text: { es: 'oy/oyj/ab/abp', en: 'oy/oyj/ab/abp' }, id: 'FI12' },
          { order: 48, text: { es: 'osk', en: 'osk' }, id: 'FI25' },
          { order: 49, text: { es: 'SLP', en: 'SLP' }, id: 'FR5130' },
          { order: 50, text: { es: 'SARL', en: 'SARL' }, id: 'FR5410' },
          { order: 51, text: { es: 'SA', en: 'SA' }, id: 'FR5660' },
          { order: 52, text: { es: 'OHG', en: 'OHG' }, id: 'DE102' },
          { order: 53, text: { es: 'KG', en: 'KG' }, id: 'DE103' },
          { order: 54, text: { es: 'AG', en: 'AG' }, id: 'DE201' },
          { order: 55, text: { es: 'GmbH', en: 'GmbH' }, id: 'DE206' },
          { order: 56, text: { es: 'eG', en: 'eG' }, id: 'DE301' },
          { order: 57, text: { es: 'OE', en: 'OE' }, id: 'GR902' },
          { order: 58, text: { es: 'EE', en: 'EE' }, id: 'GR903' },
          { order: 59, text: { es: 'SA', en: 'SA' }, id: 'GR905' },
          { order: 60, text: { es: 'EPE', en: 'EPE' }, id: 'GR906' },
          { order: 61, text: { es: 'IKE', en: 'IKE' }, id: 'GR907' },
          { order: 62, text: { es: 'COOP', en: 'COOP' }, id: 'GR910' },
          { order: 63, text: { es: 'Kft.', en: 'Kft.' }, id: 'HU113' },
          { order: 64, text: { es: 'Rt.', en: 'Rt.' }, id: 'HU114' },
          { order: 65, text: { es: 'Kkt.', en: 'Kkt.' }, id: 'HU116' },
          { order: 66, text: { es: 'Bt.', en: 'Bt.' }, id: 'HU117' },
          { order: 67, text: { es: 'Kht.', en: 'Kht.' }, id: 'HU736' },
          { order: 68, text: { es: 'LTD', en: 'LTD' }, id: 'IE01' },
          { order: 69, text: { es: 'PLC', en: 'PLC' }, id: 'IE05' },
          { order: 70, text: { es: 'SMC', en: 'SMC' }, id: 'IE06' },
          { order: 71, text: { es: 'SNC', en: 'SNC' }, id: 'IT201' },
          { order: 72, text: { es: 'SAS', en: 'SAS' }, id: 'IT301' },
          { order: 73, text: { es: 'COOP', en: 'COOP' }, id: 'IT601' },
          { order: 74, text: { es: 'SPA', en: 'SPA' }, id: 'IT701' },
          { order: 75, text: { es: 'SAA', en: 'SAA' }, id: 'IT702' },
          { order: 76, text: { es: 'SRL', en: 'SRL' }, id: 'IT703' },
          { order: 77, text: { es: 'BDR', en: 'BDR' }, id: 'LV807' },
          { order: 78, text: { es: 'GIM', en: 'GIM' }, id: 'LV301' },
          { order: 79, text: { es: 'KB', en: 'KB' }, id: 'LV401' },
          { order: 80, text: { es: 'KS', en: 'KS' }, id: 'LV108' },
          { order: 81, text: { es: 'PS', en: 'PS' }, id: 'LV107' },
          { order: 82, text: { es: 'SIA', en: 'SIA' }, id: 'LV103' },
          { order: 83, text: { es: 'AB', en: 'AB' }, id: 'LT101' },
          { order: 84, text: { es: 'UAB', en: 'UAB' }, id: 'LT102' },
          { order: 85, text: { es: 'KO', en: 'KO' }, id: 'LT103' },
          { order: 86, text: { es: 'TUB', en: 'TUB' }, id: 'LT104' },
          { order: 87, text: { es: 'KUB', en: 'KUB' }, id: 'LT105' },
          { order: 88, text: { es: 'A', en: 'A' }, id: 'LT106' },
          { order: 89, text: { es: 'II', en: 'II' }, id: 'LT109' },
          { order: 90, text: { es: 'EI', en: 'EI' }, id: 'LU01' },
          { order: 91, text: { es: 'SARL', en: 'SARL' }, id: 'LU03' },
          { order: 92, text: { es: 'SECS', en: 'SECS' }, id: 'LU05' },
          { order: 93, text: { es: 'SA', en: 'SA' }, id: 'LU07' },
          { order: 94, text: { es: 'SCOP', en: 'SCOP' }, id: 'LU12' },
          { order: 95, text: { es: 'PCOMM', en: 'PCOMM' }, id: 'MT020' },
          { order: 96, text: { es: 'P', en: 'P' }, id: 'MT021' },
          { order: 97, text: { es: 'LTD', en: 'LTD' }, id: 'MT030' },
          { order: 98, text: { es: 'VO', en: 'VO' }, id: 'MT040' },
          { order: 99, text: { es: 'PLC', en: 'PLC' }, id: 'MT060' },
          { order: 100, text: { es: 'COOP', en: 'COOP' }, id: 'MT080' },
          { order: 101, text: { es: 'BV', en: 'BV' }, id: 'NL101' },
          { order: 102, text: { es: 'NV', en: 'NV' }, id: 'NL102' },
          { order: 103, text: { es: 'Coöperatie', en: 'Coöperatie' }, id: 'NL201' },
          { order: 104, text: { es: 'sp. z o.o.', en: 'sp. z o.o.' }, id: 'PLA117' },
          { order: 105, text: { es: 'sp.k.', en: 'sp.k.' }, id: 'PLA120' },
          { order: 106, text: { es: 'spółdzielnie', en: 'spółdzielnie' }, id: 'PLA140' },
          { order: 107, text: { es: 'Assoc', en: 'Assoc' }, id: 'PT221' },
          { order: 108, text: { es: 'Coman', en: 'Coman' }, id: 'PT141' },
          { order: 109, text: { es: 'Coop', en: 'Coop' }, id: 'PT211' },
          { order: 110, text: { es: 'Lda', en: 'Lda' }, id: 'PT121' },
          { order: 111, text: { es: 'SA', en: 'SA' }, id: 'PT131' },
          { order: 112, text: { es: 'SNC', en: 'SNC' }, id: 'PT151' },
          { order: 113, text: { es: 'SUni', en: 'SUni' }, id: 'PT122' },
          { order: 114, text: { es: 'SRL', en: 'SRL' }, id: 'RO102' },
          { order: 115, text: { es: 'SCS', en: 'SCS' }, id: 'RO103' },
          { order: 116, text: { es: 'ONG', en: 'ONG' }, id: 'RO107' },
          { order: 117, text: { es: 'SNC', en: 'SNC' }, id: 'RO108' },
          { order: 118, text: { es: 'v.o.s', en: 'v.o.s' }, id: 'SK111' },
          { order: 119, text: { es: 'k. s.', en: 'k. s.' }, id: 'SK113' },
          { order: 120, text: { es: 'N.O.', en: 'N.O.' }, id: 'SK119' },
          { order: 121, text: { es: 's.r.o.', en: 's.r.o.' }, id: 'SK112' },
          { order: 122, text: { es: 'Družstvo', en: 'Družstvo' }, id: 'SK205' },
          { order: 123, text: { es: 'd.d.', en: 'd.d.' }, id: 'SI104' },
          { order: 124, text: { es: 'd.o.o.', en: 'd.o.o.' }, id: 'SI103' },
          { order: 125, text: { es: 'k.d.', en: 'k.d.' }, id: 'SI102' },
          { order: 126, text: { es: 'z.o.o.', en: 'z.o.o.' }, id: 'SI112' },
          { order: 127, text: { es: 'zadruga', en: 'zadruga' }, id: 'SI719' },
          { order: 128, text: { es: 'S COOP', en: 'S COOP' }, id: 'ESC14' },
          { order: 129, text: { es: 'S. COM. P.A.', en: 'S. COM. P.A.' }, id: 'ESC13' },
          { order: 130, text: { es: 'SA', en: 'SA' }, id: 'ESC04' },
          { order: 131, text: { es: 'SAU', en: 'SAU' }, id: 'ESC05' },
          { order: 132, text: { es: 'SC/SRC', en: 'SC/SRC' }, id: 'ESC11' },
          { order: 133, text: { es: 'SL/SRL', en: 'SL/SRL' }, id: 'ESC07' },
          { order: 134, text: { es: 'HB, KB', en: 'HB, KB' }, id: 'SE31' },
          { order: 135, text: { es: 'corp', en: 'corp' }, id: 'GB100' },
          { order: 136, text: { es: 'coop', en: 'coop' }, id: 'GB200' },
          { order: 137, text: { es: 'GP', en: 'GP' }, id: 'GB300' },
          { order: 138, text: { es: 'LLP', en: 'LLP' }, id: 'GB400' },
          { order: 139, text: { es: 'LP', en: 'LP' }, id: 'GB500' },
          { order: 140, text: { es: 'LLC', en: 'LLC' }, id: 'GB600' },
        ],
        filterAnswers: (answers, formAnswers) => {
          const filteredIds = {
            AUT: ['AT102', 'AT103', 'AT201, AT202', 'AT401'],
            BEL: ['BE017', 'BE014', 'BE706', 'BE612', 'BE702', 'BE011', 'BE015', 'BE610'],
            BGR: ['BG102', 'BG105', 'BG106', 'BG107', 'BG462', 'BG466'],
            HRV: ['HR02', 'HR04', 'HR47', 'HR53'],
            CYP: ['CY102', 'CY103', 'CY109', 'CY112'],
            CZE: ['CZ111', 'CZ112', 'CZ113', 'CZ121'],
            DNK: ['DK30', 'DK40', 'DK60', 'DK80', 'DK151'],
            EST: ['EE101', 'EE102', 'EE201', 'EE202', 'EE203'],
            FIN: ['FI10', 'FI11', 'FI12', 'FI25'],
            FRA: ['FR5130', 'FR5410', 'FR5660'],
            DEU: ['DE102', 'DE103', 'DE201', 'DE206', 'DE301'],
            GRC: ['GR902', 'GR903', 'GR905', 'GR906', 'GR907', 'GR910'],
            HUN: ['HU113', 'HU114', 'HU116', 'HU117', 'HU736'],
            IRL: ['IE01', 'IE05', 'IE06'],
            ITA: ['IT201', 'IT301', 'IT601', 'IT701', 'IT702', 'IT703'],
            LVA: ['LV807', 'LV301', 'LV401', 'LV108', 'LV107', 'LV103'],
            LTU: ['LT101', 'LT102', 'LT103', 'LT104', 'LT105', 'LT106', 'LT109'],
            LUX: ['LU01', 'LU03', 'LU05', 'LU07', 'LU12'],
            MLT: ['MT020', 'MT021', 'MT030', 'MT040', 'MT060', 'MT080'],
            NLD: ['NL101', 'NL102', 'NL201'],
            POL: ['PLA117', 'PLA120', 'PLA140'],
            PRT: ['PT221', 'PT141', 'PT211', 'PT121', 'PT131', 'PT151', 'PT122'],
            ROU: ['RO102', 'RO103', 'RO107', 'RO108'],
            SVK: ['SK111', 'SK113', 'SK119', 'SK112', 'SK205'],
            SVN: ['SI104', 'SI103', 'SI102', 'SI112', 'SI719'],
            ESP: ['ESC14', 'ESC13', 'ESC04', 'ESC05', 'ESC11', 'ESC07'],
            SWE: ['SE31'],
            GBR: ['GB100', 'GB200', 'GB300', 'GB400', 'GB500', 'GB600'],
          };
          return filteredIds[formAnswers.country]
            ? answers.filter(answer => filteredIds[formAnswers.country].includes(answer.id))
            : [];
        },
      },
    },
    {
      id: 'defaultQuestion6',
      type: 'question',
      default: true,
      info: {
        id: 'city',
        mandatory: false,
        placeholder: { en: literals.city, es: literals.city },
        title: {
          en: literals.city,
          es: literals.city,
        },
        tooltip: { en: '', es: '' },
        type: 'text',
      },
    },
    {
      id: 'defaultQuestion7',
      type: 'question',
      default: true,
      info: {
        id: 'currency',
        mandatory: true,
        placeholder: { en: literals.mainCurrency, es: literals.mainCurrency },
        title: {
          en: literalsCommon.currency,
          es: literalsCommon.currency,
        },
        tooltip: { en: '', es: '' },
        type: 'select',
        answers: [
          { order: 0, text: { es: literalsCommon.currencies.EUR, en: literalsCommon.currencies.EUR }, id: 'EUR' },
          { order: 1, text: { es: literalsCommon.currencies.USD, en: literalsCommon.currencies.USD }, id: 'USD' },
          { order: 2, text: { es: literalsCommon.currencies.GBP, en: literalsCommon.currencies.GBP }, id: 'GBP' },
          { order: 3, text: { es: literalsCommon.currencies.CHF, en: literalsCommon.currencies.CHF }, id: 'CHF' },
          { order: 4, text: { es: literalsCommon.currencies.CNY, en: literalsCommon.currencies.CNY }, id: 'CNY' },
          { order: 5, text: { es: literalsCommon.currencies.HKD, en: literalsCommon.currencies.HKD }, id: 'HKD' },
          { order: 6, text: { es: literalsCommon.currencies.JPY, en: literalsCommon.currencies.JPY }, id: 'JPY' },
          { order: 7, text: { es: literalsCommon.currencies.AUD, en: literalsCommon.currencies.AUD }, id: 'AUD' },
        ],
      },
    },
    {
      id: 'defaultQuestion8',
      type: 'question',
      default: true,
      info: {
        id: 'taxId',
        mandatory: false,
        placeholder: { en: literals.taxid, es: literals.taxid },
        title: {
          en: literals.taxid,
          es: literals.taxid,
        },
        tooltip: { en: '', es: '' },
        type: 'text',
      },
    },
    {
      id: 'defaultQuestion9',
      type: 'question',
      default: true,
      info: {
        id: 'url',
        mandatory: false,
        icon: <SocialIcon type='url' />,
        placeholder: { en: literals.url, es: literals.url },
        title: {
          en: literals.url,
          es: literals.url,
        },
        tooltip: { en: '', es: '' },
        type: 'text',
      },
    },
    {
      id: 'defaultQuestion10',
      type: 'question',
      default: true,
      info: {
        id: 'constitutionDate',
        mandatory: false,
        placeholder: { en: literals.constitutionDate, es: literals.constitutionDate },
        title: {
          en: literals.constitutionDate,
          es: literals.constitutionDate,
        },
        tooltip: { en: '', es: '' },
        type: 'date',
      },
    },
    {
      id: 'defaultQuestion11',
      type: 'question',
      default: true,
      info: {
        id: 'legalName',
        mandatory: false,
        placeholder: { en: literals.legalName, es: literals.legalName },
        title: {
          en: literals.legalName,
          es: literals.legalName,
        },
        tooltip: { en: '', es: '' },
        type: 'text',
      },
    },
    {
      id: 'defaultQuestion12',
      type: 'question',
      default: true,
      info: {
        id: 'social',
        title: { en: 'Other useful links', es: 'Otros enlaces de interes' },
        inputs: [
          {
            id: 'social.crunchbase',
            icon: <SocialIcon type='crunchbase' />,
            placeholder: { en: 'Crunchbase', es: 'Crunchbase' },
            tooltip: { en: '', es: '' },
            type: 'text',
            mandatory: false,
          },
          {
            id: 'social.linkedin',
            icon: <SocialIcon type='linkedin' />,
            placeholder: { en: 'Linkedin', es: 'Linkedin' },
            tooltip: { en: '', es: '' },
            type: 'text',
            mandatory: false,
          },
          {
            id: 'social.facebook',
            icon: <SocialIcon type='facebook' />,
            placeholder: { en: 'Facebook', es: 'Facebook' },
            tooltip: { en: '', es: '' },
            type: 'text',
            mandatory: false,
          },
          {
            id: 'social.twitter',
            icon: <SocialIcon type='twitter' />,
            placeholder: { en: 'Twitter', es: 'Twitter' },
            tooltip: { en: '', es: '' },
            type: 'text',
            mandatory: false,
          },
          {
            id: 'social.other',
            icon: <SocialIcon type='other' />,
            placeholder: { en: literalsCommon.other, es: literalsCommon.other },
            tooltip: { en: '', es: '' },
            type: 'text',
            mandatory: false,
          },
        ],
      },
    },
    {
      id: 'defaultQuestion13',
      type: 'question',
      default: true,
      info: {
        id: 'growthStage',
        mandatory: false,
        placeholder: { en: literals.growthStage, es: literals.growthStage },
        title: {
          en: literals.growthStage,
          es: literals.growthStage,
        },
        tooltip: { en: '', es: '' },
        type: 'select',
        answers: [
          { order: 0, text: { es: literals.seed, en: literals.seed }, id: 'Seed' },
          { order: 1, text: { es: literals.earlyGrowth, en: literals.earlyGrowth }, id: 'Early Growth' },
          { order: 2, text: { es: literals.lateGrowth, en: literals.lateGrowth }, id: 'Late Growth' },
          { order: 3, text: { es: literals.mature, en: literals.mature }, id: 'Mature' },
        ],
      },
    },
    {
      id: 'defaultQuestion14',
      type: 'question',
      default: true,
      info: {
        id: 'industries',
        mandatory: false,
        placeholder: { en: literals.industries, es: literals.industries },
        title: {
          en: literals.industries,
          es: literals.industries,
        },
        tooltip: { en: '', es: '' },
        type: 'list',
        answers: industries.map((industry, i) => {
          const literal = literals[industry.replace(/\s+/g, '').replace(/^(.)(.*)$/, (_, firstChar, rest) => firstChar.toLowerCase() + rest)];
          return { order: i, text: { es: literal, en: literal }, id: industry };
        }),
      },
    },
    {
      id: 'defaultQuestion15',
      type: 'question',
      default: true,
      info: {
        id: 'subIndustries',
        mandatory: false,
        placeholder: { en: literals.subindustries, es: literals.subindustries },
        title: {
          en: literals.subindustries,
          es: literals.subindustries,
        },
        tooltip: { en: '', es: '' },
        type: 'list',
        answers: subIndustries.map((subIndustry, i) => {
          let aux = subIndustry.replace(/[\s,-]+/g, '').replace(/&/g, 'And');
          aux = aux.charAt(0).toLowerCase() + aux.slice(1);
          const literal = literals[aux];
          return { order: i, text: { es: literal, en: literal }, id: subIndustry };
        }),
      },
    },
    {
      id: 'defaultQuestion16',
      type: 'question',
      default: true,
      info: {
        id: 'clientFocus',
        mandatory: false,
        placeholder: { en: literals.clientFocus, es: literals.clientFocus },
        title: {
          en: literals.clientFocus,
          es: literals.clientFocus,
        },
        tooltip: { en: '', es: '' },
        type: 'list',
        answers: [
          { order: 0, text: { es: literals.b2b, en: literals.b2b }, id: 'b2b' },
          { order: 1, text: { es: literals.b2c, en: literals.b2c }, id: 'b2c' },
        ],
      },
    },
    {
      id: 'defaultQuestion17',
      type: 'question',
      default: true,
      info: {
        id: 'incomeStreams',
        mandatory: false,
        placeholder: { en: literals.incomeStreams, es: literals.incomeStreams },
        title: {
          en: literals.incomeStreams,
          es: literals.incomeStreams,
        },
        tooltip: { en: '', es: '' },
        type: 'list',
        answers: [
          { order: 0, text: { es: literals.subscription, en: literals.subscription }, id: 'Subscription' },
          { order: 1, text: { es: literals.advertising, en: literals.advertising }, id: 'Advertising' },
          { order: 2, text: { es: literals.commission, en: literals.commission }, id: 'Commission' },
          { order: 3, text: { es: literals.sellingOwnInventory, en: literals.sellingOwnInventory }, id: 'Selling own inventory' },
        ],
      },
    },
    {
      id: 'defaultQuestion18',
      type: 'question',
      default: true,
      info: {
        id: 'revenues',
        mandatory: false,
        placeholder: { en: literals.revenues, es: literals.revenues },
        title: {
          en: literals.revenues,
          es: literals.revenues,
        },
        tooltip: { en: '', es: '' },
        type: 'list',
        answers: [
          { order: 0, text: { es: literals.saas, en: literals.saas }, id: 'SaaS' },
          { order: 1, text: { es: literals.marketplaces, en: literals.marketplaces }, id: 'Marketplace & ecommerce' },
          { order: 2, text: { es: literals.manufacturing, en: literals.manufacturing }, id: 'Manufacturing (Physical Products)' },
        ],
      },
    },
  ];

  return defaultQuestions
    .filter(dq => platformQuestions.includes(dq.info.id.split('.')[0]) || (admin && dq.admin))
    .map((dq) => {
      const auxId = dq.info.id.split('.');
      const order = platformQuestions.indexOf(auxId[0]) - platformQuestions.length;
      return { ...dq, order };
    })
    .sort((a, b) => a.order - b.order);
};
