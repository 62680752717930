import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelTab from 'components/Panel/components/PanelTab';
import InputText from 'components/Inputs/inputText';
import InputFileZone from 'components/Inputs/inputFileZone';
import InputEmail from 'components/Inputs/inputEmail';
import InputSelect from 'components/Inputs/inputSelect';
import Button from 'components/Buttons/Button';
import IOFileLoading from 'components/IOFileLoading/components';
import { ChevronLeft, CloudUpload } from 'lucide-react';
import { formatErrors } from 'utils/functions';
import { EN_LANGUAGE, ES_LANGUAGE, literalTemplate } from 'utils/language';
import { createUser, createUsersMassive, downloadExcelUsersTemplate } from '../modules/actions';

const initialManualData = {
  firstname: '',
  lastname: '',
  email: '',
  role: '',
  lang: '',
};

const initialExcelData = {
  form: {
    file: null,
    checkName: true,
    sendEmail: false,
  },
  loading: false,
  response: null,
};

const FormUsers = (props) => {
  const {
    literals, literalsAccount, tableRefreshFlag, onClose,
  } = props;

  const [loading, setLoading] = useState(false);
  const [editError, setEditError] = useState(false);
  const [manualData, setManualData] = useState(initialManualData);
  const [excelData, setExcelData] = useState(initialExcelData);
  const [downloadingExcel, setDownloadingExcel] = useState(false);

  const optionsRole = [
    { id: 'user', name: literalsAccount.roles.user },
    { id: 'mentor', name: literalsAccount.roles.mentor },
    { id: 'admin', name: literalsAccount.roles.admin },
  ];
  const optionsLang = [
    { id: ES_LANGUAGE, name: literalsAccount.languages.es },
    { id: EN_LANGUAGE, name: literalsAccount.languages.en },
  ];

  const handleOnChange = (property, value) => {
    setManualData({ ...manualData, [property]: value });
  };

  const submitUser = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await createUser(manualData);
      setLoading(false);
      onClose();
      tableRefreshFlag.increase();
      setManualData(initialManualData);
    } catch (error) {
      setLoading(false);
      setEditError(formatErrors(error));
    }
    return true;
  };

  const onFileUpload = (file) => {
    setExcelData({
      ...excelData, form: { ...excelData.form, file },
    });
  };

  const submitFormExcel = async () => {
    setExcelData({ ...excelData, loading: true });
    const response = await createUsersMassive(excelData.form.file);
    setExcelData({ ...excelData, loading: false, response });
  };

  const handleDownloadExcelTemplate = async () => {
    setDownloadingExcel(true);
    await downloadExcelUsersTemplate('Users_template.xlsx');
    setDownloadingExcel(false);
  };

  const renderExcelForm = () => {
    if (excelData.response) {
      const errors = excelData.response.filter(res => res.code !== 200);
      const numSuccess = excelData.response.length - errors.length;
      const successMessage = literalTemplate(`<span>${numSuccess !== 1 ? literals.successCreate : literals.successCreateOne}</span>`, {
        SUCCESS: `<span class='fw-b'>${numSuccess}</span>`,
      });

      return (
        <>
          <div dangerouslySetInnerHTML={{ __html: successMessage }} />
          <div className='fw-b mt-3'>{literals.rowErrors}</div>
          <ul>
            {errors.map((error) => {
              const fieldError = Object.keys(error.body)[0];
              const errorCode = Array.isArray(error.body[fieldError])
                ? error.body[fieldError][0].code
                : error.body[fieldError].code;

              let errorMessage = '';
              switch (errorCode) {
                case 'required_error':
                  errorMessage = literalTemplate(literals.requiredField, { FIELD: fieldError });
                  break;
                case 'exists_error':
                  errorMessage = literals.existUserErr;
                  break;
                default:
                  errorMessage = error.body[fieldError].message;
              }

              return (
                <li className='ml-2'>
                  <span className='fw-b'>{`${literals.row} ${error.row}: `}</span>
                  <span>{errorMessage}</span>
                </li>
              );
            })}
          </ul>
          <div className='buttons mt-3'>
            <Button text='Atras' icon={ChevronLeft} onClick={() => setExcelData(initialExcelData)} />
          </div>
        </>
      );
    }

    return (
      <>
        <div className='row'>
          <div className='col-12'>
            <span className='h6 fw-b link-effect' onClick={handleDownloadExcelTemplate}>{literals.exportExcel}</span>
            <span className='h6 fw-b'>{` ${literals.andUpload}`}</span>
            <InputFileZone onChange={onFileUpload} value={excelData.form.file} />
          </div>
        </div>
        <div className='buttons'>
          <Button
            text={literals.send}
            icon={CloudUpload}
            onClick={submitFormExcel}
            disabled={!excelData.form.file}
            loading={excelData.loading}
          />
        </div>
      </>
    );
  };

  return (
    <Popup title={literals.createUser} onClose={() => onClose(false)} size='large'>
      <Panel>
        <PanelHeader tabs={[{ key: 'manual', name: 'Manual' }, { key: 'excel', name: 'Excel' }]} />
        <PanelBody>
          <PanelTab key='manual'>
            <form onSubmit={submitUser}>
              <div className='row'>
                <div className='col-md-5'>
                  <InputText
                    name='firstname'
                    preText={literalsAccount.name}
                    onChange={v => handleOnChange('firstname', v)}
                    value={manualData.firstname}
                    errors={editError?.firstname}
                  />
                </div>
                <div className='col-md-7'>
                  <InputText
                    name='lastname'
                    preText={literalsAccount.lastname}
                    onChange={v => handleOnChange('lastname', v)}
                    value={manualData.lastname}
                    errors={editError?.lastname}
                  />
                </div>
                <div className='col-6'>
                  <InputEmail
                    name='email'
                    preText={literalsAccount.email}
                    onChange={v => handleOnChange('email', v)}
                    value={manualData.email}
                    errors={editError?.email}
                  />
                </div>
                <div className='col-3'>
                  <InputSelect
                    name='role'
                    preText={literalsAccount.role}
                    options={optionsRole}
                    onChange={v => handleOnChange('role', v)}
                    value={manualData.role}
                    errors={editError?.role}
                  />
                </div>
                <div className='col-3'>
                  <InputSelect
                    name='lang'
                    preText={literalsAccount.language}
                    options={optionsLang}
                    onChange={v => handleOnChange('lang', v)}
                    value={manualData.lang}
                    errors={editError?.lang}
                  />
                </div>
              </div>
              <div className='buttons'>
                <Button
                  type='submit'
                  icon={CloudUpload}
                  text={literalsAccount.submit}
                  loading={loading}
                  disabled={Object.keys(manualData).some(key => !manualData[key])}
                />
              </div>
            </form>
          </PanelTab>
          <PanelTab key='excel'>
            {renderExcelForm()}
            { downloadingExcel && <IOFileLoading mode='download' file={{ name: 'Users_template.xlsx' }} /> }
          </PanelTab>
        </PanelBody>
      </Panel>
    </Popup>
  );
};

FormUsers.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsAccount: PropTypes.object.isRequired,
  tableRefreshFlag: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FormUsers;
