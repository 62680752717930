import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import Button from 'components/Buttons/Button';
import { literalTemplate } from 'utils/language';
import { ReactComponent as SendRequestIcon } from 'assets/svg/send-request.svg';
import { useNavigate } from 'react-router-dom';
import { fetchStartup } from '../modules/actions';
import { REQUEST_ACCESS, STARTUP_SEARCHER } from './OnboardingSteps';

const RenderStartupInvite = ({
  literals, id, startup, setStartup, setRender, external,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const auxStartup = await fetchStartup(id, external);
        setStartup(auxStartup);
      } catch (e) {
        setRender(STARTUP_SEARCHER);
        navigate({ search: '' }, { replace: true });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return !startup
    ? <Loading mode='panel' hide={false} />
    : (
      <div>
        <h3 className='step-title'>{literals.invitationManageStartup}</h3>
        <p className='step-message'>
          {literalTemplate(literals.invitationManageStartupMessage, {
            STARTUP: startup.name,
          })}
        </p>
        <SendRequestIcon className='step-svg' />
        <div className='buttons'>
          <Button
            text={literals.requestAccess}
            onClick={() => setRender(REQUEST_ACCESS)}
          />
        </div>
      </div>
    );
};

RenderStartupInvite.propTypes = {
  literals: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  startup: PropTypes.object.isRequired,
  setStartup: PropTypes.func.isRequired,
  setRender: PropTypes.func.isRequired,
  external: PropTypes.func.isRequired,
};

export default RenderStartupInvite;
