import AdminInstance from 'modules/admin/repository';

export function fetchPlatform() {
  return AdminInstance.getPlatform();
}

export function editPlatform(fields) {
  return AdminInstance.putPlatform(fields);
}

export function fetchMessages(page, size) {
  return AdminInstance.getSupportMessages({ page, size });
}

export function deleteQuestion(id) {
  return AdminInstance.deleteQuestion(id);
}
