import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import Collapse from 'components/Collapse/Collapse';
import InputRichText from 'components/Inputs/inputRichText';

const FormLegal = (props) => {
  const {
    literals,
    legal,
    onSubmit,
  } = props;

  const [data, setData] = useState(legal);
  const [loading, setLoading] = useState(false);
  const legalPages = ['contact', 'cookies', 'privacy', 'terms'];

  const submitData = (event) => {
    event.preventDefault();
    setLoading(true);
    onSubmit({
      legal: {
        ...data,
      },
    });
  };

  const setAttr = (value, name) => {
    const field = name.split('.');
    const updateData = { ...data };

    if (!updateData[field[0]]) {
      updateData[field[0]] = {};
    }
    updateData[field[0]][field[1]] = value;

    setData(updateData);
  };

  return (
    <div className='tabLegal'>
      <form onSubmit={submitData}>
        {
          legalPages.map(attr => (
            <Collapse key={attr} title={attr} state={false} warning={false}>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <InputRichText preText='ES' minHeight='250px' onChange={v => setAttr(v, `${attr}.es`)} value={data && data[attr] ? data[attr].es : ''} maxLength={0} />
                </div>
                <div className='col-12 col-md-6'>
                  <InputRichText preText='EN' minHeight='250px' onChange={v => setAttr(v, `${attr}.en`)} value={data && data[attr] ? data[attr].en : ''} maxLength={0} />
                </div>
              </div>
            </Collapse>
          ))
        }
        <div className='buttons'>
          <Button text={literals.send} type='submit' loading={loading} />
        </div>
      </form>
    </div>
  );
};

FormLegal.propTypes = {
  literals: PropTypes.object.isRequired,
  legal: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FormLegal;
