import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import StatCard from 'components/Statistics/StatCard';
import ReactECharts from 'echarts-for-react';
import SkeletonBarChart from 'components/Loading/skeletonBarChart';
import { formatNumber } from 'utils/functions';

const SummaryAdminReports = ({
  literals = {},
  reports = null,
}) => {
  const reportsChart = {
    series: [{
      name: literals.reports,
      type: 'line',
      symbol: 'none',
      data: reports?.reportsPerDay?.map(op => op.value) || [],
    }],

    tooltip: {
      trigger: 'axis', valueFormatter: v => formatNumber(v),
    },
    legend: {
      data: [literals.reports],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: reports?.reportsPerDay?.map(op => op.date.substr(5, 6)) || [],
      axisLabel: { rotate: 45 },
    },
    yAxis: {
      type: 'value',
    },
  };

  return (
    <>
      <h4 className='fw-b'>{literals.reports}</h4>
      <div className='admin-summary-mentorings row mb-4'>
        <div className='col-12 col-md-4'>
          <StatCard
            title={literals.reports}
            value={reports?.reports}
            loading={!reports}
          />
          <StatCard
            title={literals.emails}
            value={reports?.emails}
            loading={!reports}
          />
        </div>
        <div className='col-12 col-md-8'>
          <Panel>
            <PanelHeader title={literals.lastMonthActivity} />
            <PanelBody>
              {
                !reports ? (
                  <div className='d-flex align-items-center justify-content-center'>
                    <SkeletonBarChart height='200px' />
                  </div>
                ) : (
                  <ReactECharts option={reportsChart} style={{ height: 222 }} />
                )
              }
            </PanelBody>
          </Panel>
        </div>
      </div>
    </>
  );
};

SummaryAdminReports.propTypes = {
  literals: PropTypes.object.isRequired,
  reports: PropTypes.object,
};

export default SummaryAdminReports;
