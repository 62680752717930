import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const PlatformInstance = ApiFetch();

export const URLS = {
  PLATFORM_INFO: 'platform',
  PLATFORM_LEGAL: 'legal/:type/:lang',
};

async function getPlatformInfo() {
  const path = preparePath(URLS.PLATFORM_INFO);
  return PlatformInstance.get(path);
}

async function getPlatformLegal(type, lang) {
  const path = preparePath(URLS.PLATFORM_LEGAL, { type, lang });
  return PlatformInstance.get(path);
}

export default {
  getPlatformInfo,
  getPlatformLegal,
};
