import initialState from '../../../store/globalState/initialState';
import {
  DELETE_KPI_BOOKMARK_SUCCESS,
  DELETE_KPI_RELATION_SUCCESS,
  DELETE_KPI_VALUE_SUCCESS,
  FETCH_KPIS_RELATIONS_INIT,
  FETCH_KPIS_RELATIONS_SUCCESS,
  POST_KPI_BOOKMARK_SUCCESS,
  POST_KPI_SUCCESS,
  POST_KPI_VALUE_SUCCESS,
  POST_KPI_RELATION_SUCCESS,
  POST_KPIS_RELATIONS_SUCCESS,
  PUT_KPI_SUCCESS,
  POST_KPI_VALUES_SUCCESS,
  EDIT_KPI_RELATION_SUCCESS,
  ORDER_KPIS_RELATIONS_SUCCESS,
} from './types';

export default function performanceReducer(state = initialState.performance, action) {
  switch (action.type) {
    case FETCH_KPIS_RELATIONS_INIT: {
      return {
        ...state,
        scope: `${action.payload.type}-${action.payload.id}`,
        loading: true,
        loaded: false,
        error: '',
      };
    }
    case FETCH_KPIS_RELATIONS_SUCCESS: {
      const lastOrder = action.payload.length + 1;
      return {
        ...state,
        loading: false,
        loaded: true,
        relations: action.payload.map(item => (item.order === null ? { ...item, order: lastOrder } : item)),
        kpisRelated: action.payload.map(r => r.kpi.id),
        error: '',
      };
    }
    case DELETE_KPI_RELATION_SUCCESS: {
      const newRelations = [...state.relations];
      newRelations.forEach((relation, i) => {
        if (relation.id === action.payload) {
          newRelations.splice(i, 1);
        }
      });

      return {
        ...state,
        relations: newRelations,
        kpisRelated: newRelations.map(r => r.kpi.id),
      };
    }
    case POST_KPI_RELATION_SUCCESS:
    case EDIT_KPI_RELATION_SUCCESS: {
      const newRelations = [...state.relations];
      const newRelation = action.payload;
      newRelations.forEach((relation, i) => {
        if (relation.id === newRelation.id) {
          newRelations[i] = newRelation;
        }
      });

      return {
        ...state,
        relations: newRelations,
        kpisRelated: newRelations.map(r => r.kpi.id),
      };
    }
    case POST_KPIS_RELATIONS_SUCCESS: {
      const newRelations = [...state.relations];
      action.payload.forEach((relation) => {
        const exists = newRelations.findIndex(a => a.kpi.id === relation.kpi.id);
        if (exists === -1) {
          newRelations.push(relation);
        } else {
          newRelations[exists] = relation;
        }
      });

      return {
        ...state,
        relations: newRelations,
        kpisRelated: newRelations.map(r => r.kpi.id),
      };
    }
    case POST_KPI_VALUE_SUCCESS:
    case DELETE_KPI_VALUE_SUCCESS: {
      const newRelations = [...state.relations];
      const newRelation = action.payload.relation;
      newRelations.forEach((relation, i) => {
        if (relation.id === newRelation.id) {
          newRelations[i].value = newRelation.value;
        }
      });

      return {
        ...state,
        relations: newRelations,
      };
    }
    case POST_KPI_VALUES_SUCCESS: {
      const newRelations = state.relations.map((relation) => {
        const foundRelation = action.payload.relations.find(newRelation => newRelation.id === relation.id);
        if (foundRelation) {
          return { ...relation, value: foundRelation.value };
        }
        return relation;
      });

      return {
        ...state,
        relations: newRelations,
      };
    }
    case POST_KPI_BOOKMARK_SUCCESS:
    case DELETE_KPI_BOOKMARK_SUCCESS: {
      const newRelations = [...state.relations];
      const newRelation = action.payload;
      newRelations.forEach((relation, i) => {
        if (relation.id === newRelation.id) {
          newRelations[i] = newRelation;
        }
      });

      return {
        ...state,
        relations: newRelations,
      };
    }
    case POST_KPI_SUCCESS: {
      const newRelations = [...state.relations];
      newRelations.push(action.payload);

      return {
        ...state,
        relations: newRelations,
        kpisRelated: newRelations.map(r => r.kpi.id),
      };
    }
    case PUT_KPI_SUCCESS: {
      const newRelations = state.relations.map((relation) => {
        return relation.kpi.id === action.payload.id
          ? { ...relation, kpi: action.payload }
          : relation;
      });

      return {
        ...state, relations: newRelations,
      };
    }
    case ORDER_KPIS_RELATIONS_SUCCESS: {
      const relationsMap = {};
      state.relations.forEach((rel) => { relationsMap[rel.id] = rel; });

      return {
        ...state,
        relations: action.payload.map(id => relationsMap[id]),
      };
    }
    default:
      return state;
  }
}
