import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputSelect from 'components/Inputs/inputSelect';
import Table from 'components/Table';
import Collapse from 'components/Collapse/Collapse';
import InputNumber from 'components/Inputs/inputNumber';
import PopupShareholderDebts from 'routes/Captable/components/routes/shareHolders/components/routes/shareholderInfo/components/PopupShareholderDebts.';
import { fetchLocal } from 'components/Table/components/TableFunctions';
import { formatNumber } from 'utils/functions';
import { useCounter } from 'utils/customHooks';
import { SquareCheck, Eye, Square } from 'lucide-react';
import { getCaptableDebt } from '../modules/actions';
import { overwriteValue } from '.';
import './styles.scss';

const CollapseConvertibles = (props) => {
  const {
    literals,
    literalsCommon,
    match,
    currency,
    data,
    setData,
    isDisabled,
    forceFetch,
  } = props;

  const [debtDetails, setDebtDetails] = useState(false);
  const tableRefreshFlag = useCounter(0);

  const fetchConvertibles = (page, size, search, filters, sort) => {
    return fetchLocal(
      { items: data, total: data.lenght },
      { search: ['shareholder.firstname', 'shareholder.lastname'] },
      page, size, search, filters, sort,
    );
  };

  const getGeneralResult = () => {
    if (data.every(debt => overwriteValue(debt, 'results', debt.results).cap.selected)) {
      return 'cap';
    }
    if (data.every(debt => overwriteValue(debt, 'results', debt.results).discount.selected)) {
      return 'discount';
    }
    if (data.every(debt => overwriteValue(debt, 'results', debt.results).repay.selected)) {
      return 'repay';
    }

    return 'custom';
  };

  const handleConvertible = (id, result) => {
    const aux = data.map((debt) => {
      if (id === 'general' || debt.id === id) {
        const results = {
          discount: { ...debt.results.discount, selected: result === 'discount' },
          cap: { ...debt.results.cap, selected: result === 'cap' },
          repay: { ...debt.results.repay, selected: result === 'repay' },
        };

        return {
          ...debt, results, overwrite: { ...debt.overwrite, results },
        };
      }
      return debt;
    });
    setData(prev => ({ ...prev, debts: aux }));
    tableRefreshFlag.increase();
  };

  const handlePopupDebt = async (row) => {
    setDebtDetails({ loading: true });
    await getCaptableDebt(match.params.id, row.id)
      .then(res => setDebtDetails(res))
      .catch(() => setDebtDetails(false));
  };

  const handleOnBlur = (id, field, value) => {
    setData(prev => ({
      ...prev,
      debts: prev.debts.map((debt) => {
        if (debt.id === id) {
          if (value !== '') {
            return { ...debt, overwrite: { ...debt.overwrite || {}, [field]: value } };
          }
          // eslint-disable-next-line no-param-reassign
          delete debt.overwrite[field];
        }
        return debt;
      }),
    }), true);
  };

  const convertiblesColumns = [
    {
      field: 'shareholder',
      label: literals.shareholder,
      preRender: sh => (sh ? `${sh.firstname} ${sh.lastname || ''}` : 'Undefined'),
      width: 300,
      onClick: handlePopupDebt,
    },
    {
      field: 'pending',
      label: literals.pending,
      preRender: (v, row) => (
        <InputNumber
          placeholder={formatNumber(v)}
          value={overwriteValue(row, 'pending', v)}
          symbol={currency.symbol}
          state
          stateTimeout={600}
          onBlur={nv => handleOnBlur(row.id, 'pending', nv)}
          isDisabled={isDisabled}
        />
      ),
      width: 150,
    },
    {
      field: 'interestRate',
      label: literals.interest,
      preRender: (v, row) => (
        <InputNumber
          placeholder={formatNumber(v)}
          value={overwriteValue(row, 'interestRate', v)}
          symbol='%'
          state
          stateTimeout={600}
          onBlur={nv => handleOnBlur(row.id, 'interestRate', nv)}
          isDisabled={isDisabled}
        />
      ),
      width: 100,
    },
    {
      field: 'lifeSpan',
      label: 'LifeSpan',
      preRender: (v, row) => (
        <InputNumber
          placeholder={formatNumber(v)}
          value={overwriteValue(row, 'lifeSpan', v)}
          state
          stateTimeout={600}
          onBlur={nv => handleOnBlur(row.id, 'lifeSpan', nv)}
          isDisabled={isDisabled}
        />
      ),
      width: 100,
    },
    {
      field: 'discount',
      label: literals.discount,
      preRender: (v, row) => (
        <InputNumber
          placeholder={formatNumber(v)}
          value={overwriteValue(row, 'discount', v)}
          symbol='%'
          state
          stateTimeout={600}
          onBlur={nv => handleOnBlur(row.id, 'discount', nv)}
          isDisabled={isDisabled}
        />
      ),
      width: 90,
    },
    {
      field: 'valuationCap',
      label: literals.cap,
      preRender: (v, row) => (
        <InputNumber
          placeholder={formatNumber(v)}
          value={overwriteValue(row, 'valuationCap', v)}
          symbol={currency.symbol}
          state
          stateTimeout={600}
          onBlur={nv => handleOnBlur(row.id, 'valuationCap', nv)}
          isDisabled={isDisabled}
        />
      ),
      width: 150,
    },
    {
      field: 'convertTo',
      label: literals.convertsTo,
      width: 125,
      preRender: val => val?.name || '-',
    },
    {
      field: 'results',
      label: 'Cap. Result',
      onClick: row => handleConvertible(row.id, 'cap'),
      preRender: (results, row) => (
        <div className='cursor-pointer'>
          { overwriteValue(row, 'results', results).cap.selected ? <SquareCheck size={16} /> : <Square size={16} />}
          <span className='pl-2'>{formatNumber(results.cap.value, 0, { dec: 2 })}</span>
        </div>
      ),
      width: 125,
    },
    {
      field: 'results',
      label: 'Disc. Result',
      onClick: row => (!isDisabled && handleConvertible(row.id, 'discount')),
      preRender: (results, row) => (
        <div className='cursor-pointer'>
          { overwriteValue(row, 'results', results).discount.selected ? <SquareCheck size={16} /> : <Square size={16} />}
          <span className='pl-2'>{formatNumber(results.discount.value, 0, { dec: 2 })}</span>
        </div>
      ),
      width: 125,
    },
    {
      field: 'results',
      label: literals.repay,
      onClick: row => (!isDisabled && handleConvertible(row.id, 'repay')),
      preRender: (results, row) => (
        <div className='cursor-pointer'>
          { overwriteValue(row, 'results', results).repay?.selected ? <SquareCheck size={16} /> : <Square size={16} />}
          <span className='pl-2'>{formatNumber(results.repay?.value, 0, { dec: 2, symbol: currency.symbol })}</span>
        </div>
      ),
      width: 125,
    },
    {
      field: 'id',
      type: 'button',
      button: {
        icon: Eye,
        text: literalsCommon.viewDetail,
        onClick: id => handlePopupDebt(id),
      },
    },
  ];


  return (
    <Collapse
      className='convertible-collapse'
      title={(
        <div className='collapse-header--flex'>
          <h5>{`${literals.convertibles} (${data.length})`}</h5>
          <div onClick={e => e.stopPropagation()}>
            <InputSelect
              onChange={v => handleConvertible('general', v)}
              value={getGeneralResult('debts')}
              isRequired
              options={[
                { id: 'cap', name: literals.cap },
                { id: 'discount', name: literals.discount },
                { id: 'repay', name: literals.repay },
                { id: 'custom', name: literals.custom, hide: true },
              ]}
              isDisabled={isDisabled}
            />
          </div>
        </div>
      )}
      state={false}
    >
      <Table
        key='table_convertibles'
        columns={convertiblesColumns}
        fetch={fetchConvertibles}
        forceFetch={forceFetch + tableRefreshFlag.value}
        fetchLocal
      />
      {debtDetails && (
        <PopupShareholderDebts
          literals={literals}
          literalsCommon={literalsCommon}
          currency={currency}
          match={match}
          onClose={() => setDebtDetails(false)}
          row={debtDetails}
        />
      )}
    </Collapse>
  );
};

CollapseConvertibles.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  forceFetch: PropTypes.bool.isRequired,
};

export default CollapseConvertibles;
