import { connect } from 'react-redux';
import AdminCrons from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.admin.scripts,
  literalsCommon: state.i18n.literals.common,
  lang: state.i18n.language,
});

export default connect(mapStateToProps)(AdminCrons);
