import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import './styles.scss';

const OutputErrors = ({
  errors,
  literals = {},
  className = '',
  text = false,
}) => {
  const { outputErrors: literalsErrors, common: literalsCommon } = useSelector(state => state.i18n.literals);

  if (!errors) {
    return null;
  }

  const listErrors = typeof errors === 'string' ? [errors] : errors;
  const isArray = Array.isArray(listErrors);
  const keys = isArray ? listErrors : Object.keys(listErrors);
  if (keys.length === 0) {
    return null;
  }

  const errorLines = keys.map((itError) => {
    if (itError === 'unauthorized') {
      return literalsErrors.unauthorized;
    }
    if (itError === 'internal') {
      return literalsErrors.internal;
    }

    const error = isArray ? itError : listErrors[itError];
    const errorName = itError.includes('.') ? itError.split('.').slice(-1).pop() : itError;
    const errorText = literals[errorName] ?? literalsCommon[errorName] ?? itError;
    let errorValue = '';

    if (Array.isArray(error)) {
      errorValue = error.map(e => literalsErrors[e.code] ?? literals[e.code] ?? e.message).join(', ');
    } else {
      errorValue = literalsErrors[error.code] ?? error.message;
    }

    const errorLine = `${!isArray ? `${errorText}: ` : ''}${errorValue}`;
    return errorLine;
  });

  return !text ? (
    <div className={`output_errors ${className}`}>
      { errorLines.map((errorLine, i) => (
        <span key={i}>{errorLine}</span>
      )) }
    </div>
  ) : errorLines.join('. ');
};

OutputErrors.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.bool]),
  literals: PropTypes.object,
  className: PropTypes.string,
  text: PropTypes.bool,
};

export default OutputErrors;
