import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const SignatureInstance = ApiFetch();

export const URLS = {
  DOCUMENT_SIGN_STATUS: 'signature/:type/:id',
  POST_DOCUMENT_TO_SIGN: 'signature/:type/:id',
};

async function getDocumentSignStatus(type, id) {
  const path = preparePath(URLS.DOCUMENT_SIGN_STATUS, { type, id });
  return SignatureInstance.get(path);
}

async function postDocumentToSign(type, id, data) {
  const path = preparePath(URLS.POST_DOCUMENT_TO_SIGN, { type, id });
  return SignatureInstance.postForm(path, data);
}

export default {
  getDocumentSignStatus,
  postDocumentToSign,
};
