import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputSelect from 'components/Inputs/inputSelect';
import { setBrowserPreferences } from 'utils/browser';
import PopupCurrencies from './PopupCurrencies';

const CurrencyExchange = (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor' className='bi bi-currency-exchange action-icon' viewBox='0 0 16 16'>
    <path d='M0 5a5 5 0 0 0 4.027 4.905 6.5 6.5 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05q-.001-.07.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.5 3.5 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98q-.004.07-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z' />
  </svg>
);

const CurrenciesSelect = ({
  literalsCommon,
  currency,
  currencies = null,
  setGlobalProperties,
  mode = null,
}) => {
  const [currenciesPopup, setCurrenciesPopup] = useState(false);
  const [loading, setLoading] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  let shouldRenderComponent = mode === 'left-menu' ? windowWidth <= 960 : windowWidth > 960;
  shouldRenderComponent = mode === 'position' ? true : shouldRenderComponent;

  useEffect(() => {
    const actualCurrency = Object.keys(currencies || {}).find(curr => currencies[curr] === 1) || '';
    if (shouldRenderComponent && currency && actualCurrency.toLowerCase() !== currency.toLowerCase()) {
      (async () => {
        setLoading(true);
        setBrowserPreferences('baseCurrency', currency);
        fetch(`https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${currency.toLocaleLowerCase()}.json`)
          .then(async (response) => {
            const data = await response.json();
            const aux = {};
            Object.keys(data[currency.toLocaleLowerCase()])
              .filter(key => Object.keys(literalsCommon.currencies).includes(key.toUpperCase()))
              .forEach((key) => { aux[key.toUpperCase()] = data[currency.toLocaleLowerCase()][key]; });

            setGlobalProperties({
              investments: {
                currencies: aux,
              },
            });
          })
          .finally(() => setLoading(false));
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);

  const handleChangeCurrency = (curr) => {
    if (currencies && currency !== curr) {
      setGlobalProperties({
        investments: {
          currency: curr,
        },
      });
    }
  };

  return shouldRenderComponent && (
    <div className='currency-select'>
      <InputSelect
        value={currency}
        onChange={handleChangeCurrency}
        preText={null}
        zIndex={13}
        isDisabled={loading}
        options={Object.keys(currencies || {}).map(curr => ({
          id: curr,
          name: literalsCommon.currencies[curr],
        }))}
      />
      <div onClick={() => setCurrenciesPopup(true)}>
        {CurrencyExchange}
      </div>
      { currenciesPopup && (
        <PopupCurrencies
          literalsCommon={literalsCommon}
          currencies={currencies}
          currency={currency}
          onClose={() => setCurrenciesPopup(false)}
          onChange={(newCurrencies) => {
            setGlobalProperties({
              investments: { currencies: newCurrencies },
            });
            setCurrenciesPopup(false);
          }}
        />
      )}
    </div>
  );
};

CurrenciesSelect.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  currencies: PropTypes.object,
  setGlobalProperties: PropTypes.func.isRequired,
  mode: PropTypes.string,
};

export default CurrenciesSelect;
