import { connect } from 'react-redux';
import ShareClassesComponents from '../components';

const mapStateToProps = state => ({
  literalsCommon: state.i18n.literals.common,
  literals: state.i18n.literals.shareClass,
  literalsShareClass: state.i18n.literals.shareClass,
  user: state.session.user,
  startup: state.global.startup,
  captable: state.captable,
});

export default connect(mapStateToProps)(ShareClassesComponents);
