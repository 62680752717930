import StartupsReportsInstance from 'modules/startupsReports/repository';
import { apiDownloadFile } from 'modules/actions';
import { API_REPORT_PDF } from 'modules/apiEndpoints';
import { formatErrors } from 'utils/functions';

export function getReports(id, opts) {
  return StartupsReportsInstance.getStartupReports(id, opts);
}

export function postReport(id, data) {
  return StartupsReportsInstance.postStartupReport(id, data)
    .then(response => response)
    .catch((error) => {
      throw formatErrors(error);
    });
}

export function putReport(id, report, data) {
  return StartupsReportsInstance.putStartupReport(id, report, data)
    .then(response => response)
    .catch((error) => {
      throw formatErrors(error);
    });
}

export function getReport(id, report) {
  return StartupsReportsInstance.getStartupReport(id, report);
}

export function getReportEmails(id, opts) {
  return StartupsReportsInstance.getStartupReportEmails(id, opts);
}

export function deleteReport(id, report) {
  return StartupsReportsInstance.deleteStartupReport(id, report);
}

export function getReportUsers(id, report, opts) {
  return StartupsReportsInstance.getStartupReportUsers(id, report, opts);
}

export function sendReportTest(id, data) {
  return StartupsReportsInstance.sendStartupReportTest(id, data)
    .then(response => response)
    .catch((error) => {
      throw formatErrors(error);
    });
}

export function fetchDocuments(id, page, size) {
  return StartupsReportsInstance.getStartupDocuments(id, { page, size });
}

export function fetchPreview(id, content, type) {
  return StartupsReportsInstance.getStartupReportPreview(id, { content, type });
}

export function downloadReport(startup, report, name = 'report.pdf') {
  return apiDownloadFile(API_REPORT_PDF(startup, report), name);
}
