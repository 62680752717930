import initialState from '../../../store/globalState/initialState';

import {
  FETCH_STARTUP_QUESTIONS_INIT,
  FETCH_STARTUP_QUESTIONS_FAILURE,
  FETCH_STARTUP_QUESTIONS_SUCCESS,
  FETCH_MENTOR_QUESTIONS_INIT,
  FETCH_MENTOR_QUESTIONS_SUCCESS,
  FETCH_MENTOR_QUESTIONS_FAILURE,
  FETCH_INVESTOR_QUESTIONS_SUCCESS,
  FETCH_FUND_QUESTIONS_INIT,
} from './types';

export default function InternalFormReducer(state = initialState.forms, action) {
  switch (action.type) {
    case FETCH_MENTOR_QUESTIONS_INIT:
      return {
        ...state,
        mentor: {
          ...state.mentor,
          loading: true,
          loaded: false,
          questions: [],
        },
      };
    case FETCH_STARTUP_QUESTIONS_INIT:
      return {
        ...state,
        startup: {
          ...state.mentor,
          loading: true,
          loaded: false,
          questions: [],
        },
      };
    case FETCH_FUND_QUESTIONS_INIT:
      return {
        ...state,
        fund: {
          ...state.fund,
          loading: false,
          loaded: true,
          questions: [],
        },
      };
    case FETCH_MENTOR_QUESTIONS_SUCCESS:
      return {
        ...state,
        mentor: {
          ...state.mentor,
          loaded: true,
          loading: false,
          questions: action.payload,
        },
      };
    case FETCH_STARTUP_QUESTIONS_SUCCESS:
      return {
        ...state,
        startup: {
          ...state.startup,
          loaded: true,
          loading: false,
          questions: action.payload,
        },
      };
    case FETCH_MENTOR_QUESTIONS_FAILURE:
      return {
        ...state,
        mentor: {
          ...state.startup,
          error: action.payload,
        },
      };
    case FETCH_STARTUP_QUESTIONS_FAILURE:
      return {
        ...state,
        startup: {
          ...state.startup,
          error: action.payload,
        },
      };
    case FETCH_INVESTOR_QUESTIONS_SUCCESS:
      return {
        ...state,
        investor: {
          ...state.mentor,
          loaded: true,
          loading: false,
          questions: [],
        },
      };
    default:
      return state;
  }
}
