import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import InputDate from 'components/Inputs/inputDate';
import InputSelect from 'components/Inputs/inputSelect';
import InputMonth from 'components/Inputs/inputMonth';
import { formatDate } from 'utils/functions';
import { KPI_PERIODICITY } from 'constants/kpis';

const KpiInputDate = ({
  kpi,
  value = null,
  onChange,
  preText = '',
  isDisabled = false,
}) => {
  const [actualYear, actualMonth] = formatDate(new Date(), { format: 'Y-m-d' }).split('-');

  const lastQuarter = (month, year) => {
    if (month < 3) {
      return `${+year - 1}-12`;
    }
    if (month < 6) {
      return `${year}-03`;
    }
    if (month < 9) {
      return `${year}-06`;
    }
    if (month < 12) {
      return `${year}-09`;
    }
    return `${year}-${month}`;
  };

  /* Por defecto sale la fecha actual si no se le pasa ninguna */
  useEffect(() => {
    if (!value) {
      let today = '';
      switch (kpi.periodicity) {
        case KPI_PERIODICITY.MONTHLY:
        case 'biannual':
          today = `${actualYear}-${actualMonth}`;
          break;
        case KPI_PERIODICITY.QUARTERLY:
          today = lastQuarter(actualMonth, actualYear);
          break;
        case KPI_PERIODICITY.ANNUAL:
          today = actualMonth === '12' ? actualYear : (+actualYear - 1).toString();
          break;
        default: {
          today = new Date().toISOString().split('T')[0];
        }
      }
      onChange(today);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpi, value]);

  const getOptionsYears = () => {
    const options = [];
    // eslint-disable-next-line no-plusplus
    for (let year = +actualYear - 1; year >= 1989; --year) {
      options.push({ id: year, name: year });
    }
    return options;
  };

  switch (kpi.periodicity) {
    case KPI_PERIODICITY.MONTHLY:
    case KPI_PERIODICITY.QUARTERLY:
    case 'biannual': {
      let step = 1;
      if (kpi.periodicity === KPI_PERIODICITY.QUARTERLY) {
        step = 3;
      } else if (kpi.periodicity === 'biannual') {
        step = 6;
      }
      return (
        <InputMonth
          preText={preText}
          value={value || ''}
          onChange={v => onChange(v)}
          min='1989-12'
          step={step}
          isDisabled={isDisabled}
        />
      );
    }
    case KPI_PERIODICITY.ANNUAL: {
      return (<InputSelect preText={preText} value={value} onChange={onChange} options={getOptionsYears(kpi.periodicity)} />);
    }
    default: {
      const maxDate = new Date().toISOString().split('T')[0];
      return (<InputDate preText={preText} value={value} onChange={onChange} max={maxDate} />);
    }
  }
};

KpiInputDate.propTypes = {
  preText: PropTypes.string.isRequired,
  kpi: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default KpiInputDate;
