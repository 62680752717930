/* eslint-disable react/destructuring-assignment */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import {
  Asterisk, Building, Info, UserPlus,
} from 'lucide-react';
import { Skeleton } from '@mui/material';
import Button from 'components/Buttons/Button';
import InputTextArea from 'components/Inputs/inputTextArea';
import OutputErrors from 'components/Inputs/outputErrors';
import { getFullName } from 'utils/functions';

const MentoringSearchRow = (props) => {
  const {
    id,
    view,
    loading,
    onInvite,
    onMoreInfo,
    literals,
    _tableRow,
    _searchScore: searchScore,
  } = props;

  const [showFormInvite, setShowFormInvite] = useState(false);
  const [inviteMessage, setInviteMessage] = useState('');
  const [inviteError, setInviteError] = useState({ message: false, others: null });
  const [inviteLoading, setInviteLoading] = useState(false);

  if (loading) {
    return (
      <div key={id} className='box box-padding d-flex align-items-center box-mb'>
        <Skeleton
          animation='wave'
          variant='circular'
          height={70}
          width={70}
        />
        <div className='flex-grow-1 p-1 pl-4'>
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='100%' />
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='100%' />
          <Skeleton animation='wave' style={{ marginBottom: 2 }} width='100%' />
        </div>
      </div>
    );
  }
  const handleSubmitInvite = async (e) => {
    e.preventDefault();
    if (inviteMessage) {
      setInviteLoading(true);
      setInviteError(false);
      if (typeof onInvite === 'function') {
        const response = await onInvite(id, inviteMessage);
        setInviteLoading(false);
        if (response === true) {
          setShowFormInvite(false);
        } else {
          setInviteError({ others: response });
        }
      }
    } else {
      setInviteError({ message: true });
    }
  };

  const handleClickMoreInfo = () => {
    onMoreInfo(id);
  };

  let image = '';
  let name = '';
  let info = '';
  let phantom = false;
  const special = {};

  if (view.type === 'startup') {
    image = props.avatar?.path;
    name = getFullName(props.user);
    info = props.headline;
    special.icon = <Building size={22} />;
    special.text = props.company;
  } else {
    image = props.logo?.path;
    name = props.name;
    info = props.description;
    special.icon = <Avatar title={name} src='' size={24} />;
    special.text = '';
    phantom = props.phantom;
  }

  return (
    <div className='mentoring-row box' data-key={id}>
      <div className='mentoring-info'>
        <div className='mentoring-avatar'>
          <Avatar title={name} src={image} size={70} />
        </div>
        <div className='mentoring-basic'>
          <h4>
            <Avatar title={name} src={image} size={45} className='mentoring-avatar-mobile' />
            {name}
          </h4>
          <p>{info}</p>
        </div>
        <div className='mentoring-special'>
          {
            special.text && (
              <>
                <span>{special.text}</span>
                {special.icon}
              </>
            )
          }
        </div>
      </div>
      <div className='mentoring-actions'>
        {
          _tableRow.page === 0 && _tableRow.index < 5 && searchScore > 2 && (
            <div className='mentoring-recommended' data-score={searchScore}>
              <Asterisk size={16} />
              {literals.recommended}
            </div>
          )
        }
        <div className='mentorings-actions-buttons'>
          <Button icon={Info} text={literals.moreInfo} color='outline-primary' onClick={handleClickMoreInfo} />
          {
            phantom ? (
              <Button disabled icon={UserPlus} text={literals.soon} />
            ) : (
              <Button icon={UserPlus} text={showFormInvite ? literals.cancel : literals.invite} onClick={() => setShowFormInvite(!showFormInvite)} />
            )
          }
        </div>
      </div>
      {
        showFormInvite && (
          <div className='mentoring-invite'>
            <form onSubmit={handleSubmitInvite}>
              <InputTextArea
                preText={view.type === 'startup' ? literals.howCanTheMentorHelpYou : literals.whatCanYouContribute}
                value={inviteMessage}
                onChange={v => setInviteMessage(v)}
                error={inviteError?.message}
              />
              <OutputErrors errors={inviteError?.others} literals={literals} />
              <div className='buttons'>
                <Button type='submit' text={literals.letsGo} loading={inviteLoading} />
              </div>
            </form>
          </div>
        )
      }
    </div>
  );
};

MentoringSearchRow.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  view: PropTypes.object.isRequired,
  avatar: PropTypes.object,
  logo: PropTypes.object,
  user: PropTypes.object,
  headline: PropTypes.string,
  company: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  phantom: PropTypes.bool,
  literals: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  onInvite: PropTypes.func,
  onMoreInfo: PropTypes.func,
  _searchScore: PropTypes.number,
  _tableRow: PropTypes.object,
};

MentoringSearchRow.defaultProps = {
  loading: false,
  avatar: null,
  logo: null,
  user: null,
  headline: '',
  company: '',
  name: '',
  description: '',
  phantom: false,
  onInvite: null,
  onMoreInfo: null,
  _searchScore: 0,
  _tableRow: null,
};

export default MentoringSearchRow;
