import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initPlatformAction } from 'store/globalState/global';
import PlatformComponents from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.admin.platform,
  literalsCommon: state.i18n.literals.common,
  lang: state.i18n.language,
  LANGS: state.global.platform.langs,
});

function mapDispatchToProps(dispatch) {
  return {
    initPlatform: bindActionCreators(initPlatformAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlatformComponents);
