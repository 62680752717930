/* eslint-disable no-param-reassign */
import axios from 'axios';
import { API_BASE_URL, RETURN_TYPES, STATUS_CODE } from 'constants/apiConf';

const ApiFetch = (baseURL) => {
  if (!baseURL) {
    // eslint-disable-next-line no-param-reassign
    baseURL = `${API_BASE_URL}/api/v1/`;
  }
  const instance = axios.create({
    baseURL,
    withCredentials: true,
  });

  instance.interceptors.request.use((config) => {
    config.headers.Accept = 'text/html; charset=UTF-8';
    return config;
  });

  instance.interceptors.response.use(
    (res) => {
      const response = {
        status: res?.status || STATUS_CODE.OK,
        statusText: res?.statusText || 'OK',
        headers: res?.headers || {},
        data: res?.data || {},
      };
      if (res.config.return === RETURN_TYPES.RESPONSE) {
        return response;
      }
      return response.data;
    },
    (err) => {
      const response = {
        status: err?.response?.status || STATUS_CODE.INTERNAL_SERVER_ERROR,
        data: err?.response?.data || {},
      };
      return Promise.reject(response);
    },
  );

  return instance;
};

export default ApiFetch;
