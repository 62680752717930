import AdminInstance from 'modules/admin/repository';
import { apiDownloadFile } from 'modules/actions';
import { API_ADMIN_FUNDS_EXCEL, API_ADMIN_FUNDS_EXCEL_TEMPLATE } from 'modules/apiEndpoints';

export const getFunds = (page, size, search, filters, sort) => {
  return AdminInstance.getAdminFunds({
    page, size, search, filters, sort,
  });
};

export const createFund = (fund) => {
  return AdminInstance.postAdminFund({ ...fund });
};

export const editFund = (id, fund) => {
  return AdminInstance.putAdminFund(id, { ...fund });
};

export const deleteFund = (fund) => {
  return AdminInstance.deleteAdminFund(fund);
};

export const createFundMassive = (file) => {
  return AdminInstance.createFundMassive(file);
};

export const downloadExcelFunds = async (filename) => {
  return apiDownloadFile(API_ADMIN_FUNDS_EXCEL, filename);
};

export const downloadExcelFundsTemplate = (filename) => {
  return apiDownloadFile(API_ADMIN_FUNDS_EXCEL_TEMPLATE, filename);
};
