import ReportsTemplatesInstance from 'modules/reportsTemplates/repository';
import { formatErrors } from 'utils/functions';

export function getReportTemplates(scope, owner, page, size, search, sort) {
  return ReportsTemplatesInstance.getReportTemplates({
    scope, owner, page, size, search, sort,
  });
}

export function createReportTemplate(data) {
  return ReportsTemplatesInstance.postReportTemplate(data);
}

export function getReportTemplate(id) {
  return ReportsTemplatesInstance.getReportTemplate(id)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw formatErrors(error);
    });
}

export function editReportTemplate(id, data) {
  return ReportsTemplatesInstance.putReportTemplate(id, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw formatErrors(error);
    });
}

export function deleteReportTemplate(id) {
  return ReportsTemplatesInstance.deleteReportTemplate(id)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw formatErrors(error);
    });
}
