import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import useDialog from 'components/Dialog/components/useDialog';
import Popup from 'components/Popup';
import InputText from 'components/Inputs/inputText';
import InputRichText from 'components/Inputs/inputRichText';
import InputToggle from 'components/Inputs/inputToggle';
import InputPermission from 'components/Inputs/InputPermission';
import OutputErrors from 'components/Inputs/outputErrors';
import { literalTemplate } from 'utils/language';
import { Pencil, PlusCircle, Trash } from 'lucide-react';
import { initPermission } from 'utils/functions/initPermissions';
import { PERMISSION_LEVELS, PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import { TOOL_CAP_TABLE } from 'constants/tools';


const EditCaptablePopup = (props) => {
  const {
    literals,
    literalsCommon,
    match,
    captable,
    tableRefreshFlag,
    startup,
    onClose,
    postStartupCaptable,
    putStartupCaptable,
    deleteStartupCaptable,
  } = props;

  const startupId = startup.id;
  const [newCaptable, setNewCaptable] = useState(captable);
  const [permission, setPermission] = useState(initPermission(
    captable,
    PERMISSION_SCOPES.STARTUP,
    startup,
    TOOL_CAP_TABLE,
  ));
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const { dialog } = useDialog();
  const { canEdit } = permission;

  const handleCreateCaptable = async () => {
    setErrors([]);
    setLoading(true);
    const data = {
      ...newCaptable,
      permission,
    };

    try {
      if (newCaptable.id) {
        await putStartupCaptable(startupId, newCaptable.id, data);
      } else {
        await postStartupCaptable(startupId, data);
      }
      tableRefreshFlag.increase();
      onClose(false);
    } catch (error) {
      setErrors(error);
      setLoading(false);
    }
  };

  const handleDeleteCaptable = async (cap) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literals.deleteCaptableConfirm,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            await deleteStartupCaptable(match.params.id, cap);
            tableRefreshFlag.increase();
            onClose();
          } catch (error) {
            await dialog({
              type: 'error',
              text: <OutputErrors errors={error} text />,
            });
          }
        },
      });
    }
  };

  return (
    <Popup title={newCaptable.id ? literals.editCaptable : literals.createCaptable} onClose={onClose}>
      <div className='row'>
        <div className='col-12 col-md-8'>
          <InputText
            preText={literalsCommon.name}
            value={newCaptable.name}
            onChange={v => setNewCaptable(prev => ({ ...prev, name: v }))}
            isDisabled={!canEdit}
            postText={literalTemplate(literals.maxCharacters, { NUMBER: 50 })}
          />
        </div>
        <div className='col-12 col-md-4'>
          <InputPermission
            scope={{ type: PERMISSION_SCOPES.STARTUP, id: startupId }}
            element={{ type: PERMISSION_TYPES.CAPTABLE, id: captable?.id }}
            value={permission}
            onChange={setPermission}
          />
        </div>
      </div>
      {
        permission.toolLevel >= PERMISSION_LEVELS.EDIT && (
          <InputToggle
            preText={literals.official}
            value={newCaptable.official}
            onChange={official => setNewCaptable(prev => ({ ...prev, official }))}
          />
        )
      }
      {
        permission.toolLevel === PERMISSION_LEVELS.EXTERNAL && (
          <InputToggle
            preText={literals.favorite}
            value={newCaptable.favorite}
            onChange={favorite => setNewCaptable(prev => ({ ...prev, favorite }))}
          />
        )
      }
      <InputRichText
        preText={literalsCommon.description}
        value={newCaptable.description}
        onChange={v => setNewCaptable(prev => ({ ...prev, description: v }))}
        isDisabled={!canEdit}
      />
      <OutputErrors className='mb-2' errors={errors} />
      {canEdit && (
        <div className='buttons'>
          {
            newCaptable.id && (
              <Button
                icon={Trash}
                color='danger'
                text={literalsCommon.delete}
                onClick={() => handleDeleteCaptable(newCaptable.id)}
                loading={loading}
              />
            )
          }
          <Button
            icon={newCaptable.id ? Pencil : PlusCircle}
            text={newCaptable.id ? literalsCommon.edit : literalsCommon.create}
            onClick={handleCreateCaptable}
            loading={loading}
          />
        </div>
      )}
    </Popup>
  );
};

EditCaptablePopup.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  tableRefreshFlag: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  postStartupCaptable: PropTypes.func.isRequired,
  putStartupCaptable: PropTypes.func.isRequired,
  deleteStartupCaptable: PropTypes.func.isRequired,
};

export default EditCaptablePopup;
