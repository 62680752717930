import StartupsInstance from 'modules/startups/repository';

export async function fetchUserStartups(page = 0, size = 0, search = '', filters = {}, sort = '-created_at') {
  return StartupsInstance.startupsGet({
    page, size, search, filters, sort,
  });
}

export function changeInviteStatus(id, user, status) {
  return StartupsInstance.changeStartupInviteStatus(id, user, status);
}
