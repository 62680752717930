import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import InputDate from 'components/Inputs/inputDate';
import Table from 'components/Table';
import Button from 'components/Buttons/Button';
import OutputValue from 'components/Inputs/outputValue';
import OutputErrors from 'components/Inputs/outputErrors';
import { fetchLocal } from 'components/Table/components/TableFunctions';
import { useSelector } from 'react-redux';
import { dictionary, formatErrors, formatNumber } from 'utils/functions';
import { getVestingPlanMilestones, postVestingPlanMilestones } from '../modules/actions';
import './styles.scss';

const PopupVestMilestones = (props) => {
  const {
    id,
    startup,
    captable,
    onClose,
    literals,
    literalsCommon,
  } = props;

  const [milestones, setMilestones] = useState([]);
  const [achievedMilestones, setAchievedMilestones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [data, setData] = useState({
    options: null,
    shareholders: null,
    isloaded: false,
  });

  const shareholders = dictionary(useSelector(state => state.captable.shareholders) || []);

  const columns = [
    {
      field: 'name',
      label: literals.milestones,
    },
    {
      field: 'shareholder',
      label: literals.shareholder,
      preRender: v => shareholders[v].name,
    },
    {
      field: 'percentage',
      label: literals.percentage,
      preRender: v => formatNumber(v, '-', { symbol: '%' }),
    },
    {
      field: 'option',
      label: literals.option,
      preRender: ({ name }) => name,
    },
    {
      field: 'date',
      label: literalsCommon.date,
      classes: 'pt-0 pb-0 ',
      preRender: (v, row) => {
        const isSelected = achievedMilestones.includes(row.id);
        return isSelected ? (
          <InputDate
            className='mb-0'
            value={milestones[row.id].date}
            onChange={(date) => {
              setMilestones(prev => ({ ...prev, [row.id]: { ...row, date } }));
            }}
          />
        ) : <OutputValue className='w-100 mb-0' value='' />;
      },
    },
  ];

  const filters = [
    {
      id: 'option',
      type: 'selectUnique',
      title: 'Seleccione una opcion',
      loading: !data.isloaded,
      options: [
        { id: null, name: literalsCommon.all },
        ...Object.keys(data?.options || {}).map(option => ({
          id: option, name: data.options[option].name,
        })),
      ],
    },
    {
      id: 'shareholder',
      type: 'selectUnique',
      title: 'Seleccione un shareholder',
      loading: !data.isloaded,
      options: [
        { id: null, name: literalsCommon.all },
        ...Object.keys(data?.shareholders || {}).map(shId => ({
          id: shId, name: data.shareholders[shId].name,
        })),
      ],
    },
  ];

  const fetchMiletones = async (page, size, _, auxFilters) => {
    const auxMilestones = await getVestingPlanMilestones(startup, id, {
      captable,
      shareholder: auxFilters?.shareholder || null,
      option: auxFilters?.option || null,
    });
    setMilestones(auxMilestones.items.reduce((prev, item) => {
      const aux = { ...prev };
      aux[item.id] = item;
      return aux;
    }, {}));

    if (!data.isloaded) {
      const auxOptions = {};
      const auxShareholders = {};
      auxMilestones.items.forEach((cal) => {
        auxOptions[cal.option.id] = cal.option;
        auxShareholders[cal.shareholder] = shareholders[cal.shareholder];
      });

      setData({
        options: auxOptions,
        shareholders: auxShareholders,
        isloaded: true,
      });
    }
    return fetchLocal(auxMilestones, null, page, size);
  };

  const postMilestones = async () => {
    try {
      setLoading(true);
      const auxMilestones = achievedMilestones.map(aux => milestones[aux]);
      await postVestingPlanMilestones(startup, id, {
        captable, milestones: auxMilestones,
      });
      onClose();
    } catch (e) {
      setLoading(false);
      setErrors(formatErrors(e));
    }
  };

  return (
    <Popup size='large' title={literals.confirmMilestones} onClose={onClose}>
      <Table
        searcher={false}
        filters={filters}
        columns={columns}
        fetch={fetchMiletones}
        selectRows={achievedMilestones}
        onSelectRows={setAchievedMilestones}
      />
      <OutputErrors errors={errors} literals={literals} />
      <div className='buttons'>
        <Button
          text={literalsCommon.confirm}
          onClick={postMilestones}
          loading={loading}
          disabled={!achievedMilestones.length}
        />
      </div>
    </Popup>
  );
};

PopupVestMilestones.propTypes = {
  id: PropTypes.string.isRequired,
  startup: PropTypes.string.isRequired,
  captable: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
};

export default PopupVestMilestones;
