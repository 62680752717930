import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import InputDate from 'components/Inputs/inputDate';
import StatCard from 'components/Statistics/StatCard';
import Table from 'components/Table';
import ReactECharts from 'echarts-for-react';
import SkeletonBarChart from 'components/Loading/skeletonBarChart';
import {
  formatDate, formatNumber, formatTime, getFullName,
} from 'utils/functions';
import {
  Clock, Eye, Hourglass, User,
} from 'lucide-react';
import {
  fetchAnalyticsPages, fetchAnalyticsStartups, fetchAnalyticsSummary, fetchAnalyticsTools, fetchAnalyticsUsers,
} from '../modules/actions';
import './styles.scss';

const AdminAnalytics = ({ literals }) => {
  const actualDate = new Date();

  const [analytics, setAnalytics] = useState();
  const [interval, setInterval] = useState({
    from: formatDate(new Date(actualDate.getFullYear(), actualDate.getMonth() - 1, actualDate.getDate()), { format: 'Y-m-d' }),
    to: formatDate(actualDate, { format: 'Y-m-d' }),
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const auxAnalytics = await fetchAnalyticsSummary(interval.from, interval.to);
      setAnalytics(auxAnalytics);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval]);

  const generateChart = (auxLiterals, auxSeries) => {
    if (loading) {
      return (
        <div className='d-flex align-items-center justify-content-center mt-4 mb-5'>
          <SkeletonBarChart height='200px' />
        </div>
      );
    }

    const chart = {
      series: auxSeries.map((serie, i) => (
        {
          name: auxLiterals[i],
          type: 'line',
          data: Object.values((analytics?.[serie]) || []),
        }
      )),
      tooltip: {
        trigger: 'axis', valueFormatter: v => formatNumber(v),
      },
      legend: {
        data: auxLiterals,
      },
      grid: {
        left: '5%', right: '5%', bottom: '0%', containLabel: true,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: Object.keys(analytics?.[auxSeries[0]] || []).map((date) => {
          const auxDate = `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
          return formatDate(auxDate);
        }),
        axisLabel: { rotate: 60 },
      },
      yAxis: {
        type: 'value',
      },
    };

    return <ReactECharts option={chart} style={{ height: 300 }} />;
  };

  const getColumns = (type) => {
    let columns = [
      {
        field: 'name',
        label: literals[type],
      },
      {
        field: 'screenPageViews',
        label: literals.views,
        sortable: true,
        width: 100,
      },
      {
        field: 'activeUsers',
        label: literals.users,
        sortable: true,
        width: 100,
      },
      {
        field: 'sessions',
        label: literals.sessions,
        sortable: true,
        width: 100,
      },
      {
        field: 'averageSession',
        label: literals.averageSession,
        sortable: true,
        width: 150,
        preRender: v => formatTime(v, 'seconds'),
      },
    ];

    if (type === 'startup') {
      columns.unshift({
        field: 'logo',
        type: 'avatar',
        title: (_, row) => row.name,
        width: 45,
      });
    }

    if (type === 'user') {
      columns = columns.filter(col => !['name', 'users', 'sessions'].includes(col.field));
      columns.unshift({
        field: 'avatar',
        type: 'avatar',
        width: 45,
        title: (_, row) => getFullName(row),
      },
      {
        field: 'firstname',
        label: literals[type],
        preRender: (_, row) => getFullName(row),
      });
    }

    return columns;
  };

  return (
    <Tool className='toolAdminAnalytics'>
      <div className='date-range'>
        <InputDate value={interval.from} onChange={v => setInterval(prev => ({ ...prev, from: v }))} />
        <InputDate value={interval.to} onChange={v => setInterval(prev => ({ ...prev, to: v }))} />
      </div>

      <div className='row'>
        <div className='col-12 col-md-6 col-lg-3'>
          <StatCard icon={Eye} title={literals.views} value={formatNumber(analytics?.screenPageViews)} loading={loading} />
        </div>
        <div className='col-12 col-md-6 col-lg-3'>
          <StatCard icon={User} title={literals.users} value={formatNumber(analytics?.activeUsers)} loading={loading} />
        </div>
        <div className='col-12 col-md-6 col-lg-3'>
          <StatCard icon={Clock} title={literals.sessions} value={formatNumber(analytics?.sessions)} loading={loading} />
        </div>
        <div className='col-12 col-md-6 col-lg-3'>
          <StatCard icon={Hourglass} title={literals.averageSession} value={formatTime(analytics?.averageSessionDuration, 'seconds')} loading={loading} />
        </div>
      </div>

      <Panel>
        <PanelHeader title={literals.chartsPerDay} />
        <PanelBody>
          <div className='row'>
            <div className='col-12 col-md-6'>
              { generateChart([literals.views], ['screenPageViewsPerDate']) }
            </div>
            <div className='col-12 col-md-6'>
              { generateChart([literals.users, literals.sessions], ['activeUsersPerDate', 'sessionsPerDate']) }
            </div>
          </div>
        </PanelBody>
      </Panel>

      <Panel>
        <PanelHeader title={literals.mostVisitedStartups} />
        <PanelBody>
          <Table
            pageSize={10}
            columns={getColumns('startup')}
            fetch={(page, size, search, filters, sort) => (
              fetchAnalyticsStartups(page, size, search, filters, sort || null, interval.from, interval.to)
            )}
          />
        </PanelBody>
      </Panel>
      <Panel>
        <PanelHeader title={literals.mostUsedTools} />
        <PanelBody>
          <Table
            pageSize={10}
            columns={getColumns('tool')}
            fetch={(page, size, search, filters, sort) => (
              fetchAnalyticsTools(page, size, search, filters, sort || null, interval.from, interval.to)
            )}
          />
        </PanelBody>
      </Panel>
      <Panel>
        <PanelHeader title={literals.mostVisitedPages} />
        <PanelBody>
          <Table
            pageSize={10}
            columns={getColumns('page')}
            fetch={(page, size, search, filters, sort) => (
              fetchAnalyticsPages(page, size, search, filters, sort || null, interval.from, interval.to)
            )}
          />
        </PanelBody>
      </Panel>
      <Panel>
        <PanelHeader title={literals.mostActiveUsers} />
        <PanelBody>
          <Table
            pageSize={10}
            columns={getColumns('user')}
            fetch={(page, size, search, filters, sort) => (
              fetchAnalyticsUsers(page, size, search, filters, sort || null, interval.from, interval.to)
            )}
          />
        </PanelBody>
      </Panel>
    </Tool>
  );
};

AdminAnalytics.propTypes = {
  literals: PropTypes.object.isRequired,
};

export default AdminAnalytics;
