import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import Button from 'components/Buttons/Button';
import InputDate from 'components/Inputs/inputDate';
import InputNumber from 'components/Inputs/inputNumber';
import InputSelect from 'components/Inputs/inputSelect';
import Alert from 'components/Alert';
import {
  cloneObject, formatDate, formatNumber,
} from 'utils/functions';
import { fetchCaptableVariables } from '../modules/actions';
import PopupInfoVestingsPlans from '../../vestingPlans/components/PopupInfoVestingPlan';

const defaultVest = {
  number: 1,
  shareholder: null,
  option: null,
  assignment: null,
};

const FormVests = (props) => {
  const {
    type,
    optionsType,
    data,
    dateOrID,
    literals,
    literalsCommon,
    captable,
    captableId,
    match,
    assigned: auxAssigned,
    shareholder,
    currency,
    onConfirm,
    onDelete,
    editable,
  } = props;

  const [id] = useState(data?.id ?? `new.${new Date().getTime()}`);
  const [inputDate, setInputDate] = useState();
  const [vest, setVest] = useState({ ...(cloneObject(defaultVest)), ...(data || {}) });
  const [assigned, setAssigned] = useState(auxAssigned);
  const [option, setOption] = useState(null);
  const [vestingPlanPopup, setVestingPlanPopup] = useState(false);

  const fetchConvertibles = async () => {
    const auxFilters = {};
    if (shareholder) {
      auxFilters.shareholder = shareholder;
    }
    if (optionsType) {
      auxFilters.type = optionsType;
    }
    const response = await fetchCaptableVariables(match.params.id, dateOrID, captableId, auxFilters, { assigned: true });
    setAssigned(response.assigned);
  };

  const findOption = (assignmentId) => {
    let newOption = null;
    if (assignmentId) {
      newOption = assigned.options.find(conv => conv.assignment === assignmentId);
    }
    return newOption;
  };

  useEffect(() => {
    const load = async () => {
      if (!assigned) {
        await fetchConvertibles();
      }
      if (vest.assignment && !option) {
        const newConvertible = findOption(vest.assignment);
        newConvertible.vestingPlan = newConvertible.vestingPlan?.id
          ? captable.vestingPlans.find(vp => vp.id === newConvertible.vestingPlan?.id)
          : null;
        setOption(newConvertible);
      }
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!assigned || (vest.assignment && !option)) {
    return <Loading hide={false} mode='panel' />;
  }

  const submitForm = async (e) => {
    e.preventDefault();
    if (type === 'operation') {
      onConfirm({ id, ...vest });
    } else {
      onConfirm({
        date: inputDate,
        type: 'vests',
        name: option?.name,
        shareholder: option?.shareholder?.id,
        data: { id, ...vest },
      });
    }
  };

  const renderOptionInfo = () => {
    return (
      <>
        <div className='row'>
          <div className='col-12 col-md-5'>
            <h6 className='fw-b'>{literals.shareholder}</h6>
            <p>{option.shareholder?.name || '-'}</p>
          </div>
          <div className='col-12 col-md-5'>
            <h6 className='fw-b'>{literalsCommon.name}</h6>
            <p>{option.name || '-'}</p>
          </div>
          <div className='col-12 col-md-2'>
            <h6 className='fw-b'>{literalsCommon.type}</h6>
            <p>{option.type ? literals[option.type] : '-'}</p>
          </div>
          <div className='col-12 col-md-4'>
            <h6 className='fw-b'>{literals.number}</h6>
            <p>{option.number || '-'}</p>
          </div>
          <div className='col-12 col-md-4'>
            <h6 className='fw-b'>{literals.price}</h6>
            <p>{formatNumber(option.price, 0, { dec: 2, symbol: currency.symbol })}</p>
          </div>
          <div className='col-12 col-md-4'>
            <h6 className='fw-b'>{literals.capital}</h6>
            <p>{formatNumber(option.capital, 0, { dec: 2, symbol: currency.symbol })}</p>
          </div>
          <div className='col-12 col-md-4'>
            <h6 className='fw-b'>{literals.assigned}</h6>
            <p>{option.assigned || '-' }</p>
          </div>
          <div className='col-12 col-md-4'>
            <h6 className='fw-b'>{literals.vested}</h6>
            <p>{option.vested || '-' }</p>
          </div>
          <div className='col-12 col-md-4'>
            <h6 className='fw-b'>{literals.exercised}</h6>
            <p>{option.exercised || '-' }</p>
          </div>
          <div className='col-12 col-md-3'>
            <h6 className='fw-b'>{literals.expirationDate}</h6>
            <p>{option.expirationDate || '-'}</p>
          </div>
          <div className='col-12 col-md-3'>
            <h6 className='fw-b'>{literals.vestingPlan}</h6>
            <p style={{ cursor: 'pointer' }} onClick={() => setVestingPlanPopup(option?.vestingPlan?.id)}>
              {option?.vestingPlan?.name || '-'}
            </p>
          </div>
        </div>
      </>
    );
  };

  const handleChangeOption = (assignmentId) => {
    const newVest = cloneObject(defaultVest);
    const newOption = findOption(assignmentId);
    if (newOption) {
      newVest.assignment = newOption.assignment;
      newVest.shareholder = newOption.shareholder?.id;
      newVest.option = newOption.option;
      newVest.number = newOption.assign;
    }
    setOption(newOption);
    setVest(newVest);
  };

  const handleDeleteForm = () => {
    onDelete(id);
  };

  return (
    <form onSubmit={submitForm}>
      {
        assigned ? (
          <>
            <div className='row'>
              {type !== 'operation' ? (
                <>
                  <div className='col-12 col-md-4'>
                    <InputDate
                      preText={literalsCommon.date}
                      value={inputDate}
                      onChange={v => setInputDate(v)}
                      isDisabled={!editable}
                      min={captable?.operations?.length ? captable?.operations[0].date : null}
                    />
                  </div>
                  <div className='col-12 col-md-8'>
                    <InputSelect
                      preText={literals.selectOption}
                      value={vest?.assignment}
                      onChange={v => handleChangeOption(v)}
                      options={assigned.options.map(item => ({
                        id: item.assignment,
                        name: `${item.name} ${item.assigned} (${item.date})`,
                      }))}
                      isDisabled={!editable}
                    />
                  </div>
                </>
              ) : (
                <div className='col-12 col-md-12'>
                  <InputSelect
                    preText={literals.selectOption}
                    value={vest?.assignment}
                    onChange={v => handleChangeOption(v)}
                    options={assigned.options.map(item => ({
                      id: item.assignment,
                      name: `${item.shareholder?.name} - ${item.name} ${item.assigned} (${item.type ? literals[item.type] : '-'} ${item.date})`,
                    }))}
                    isDisabled={!editable}
                  />
                </div>
              )}
            </div>
            { vest.milestone && (
              <Alert
                type='info'
                text={(
                  <>
                    {`${literals.vestedByMilestone}: `}
                    { option.vestingPlan.milestones && (
                      option.vestingPlan.milestones?.find(mil => mil.id === vest.milestone)?.title || ''
                    )}
                  </>
                )}
              />
            )}
            <div className='box box-padding box-mb '>
              {option ? (
                <>
                  <h5 className='fw-b mb-0'>
                    {`${option.name} ${option.date}`}
                  </h5>
                  <hr />
                  {renderOptionInfo()}
                  <div className='row'>
                    <div className='col-12'>
                      <InputNumber
                        placeholder='0'
                        preText={literals.optionsToVest}
                        value={vest.number}
                        onChange={v => setVest(prev => ({ ...prev, number: parseInt(v, 10) }))}
                        maxValue={option?.assigned}
                        isDisabled={!editable}
                        decimals={0}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <h5 className='fw-b m-2 text-center'>{literals.selectOption}</h5>
              )}
            </div>
            { editable && (
              <div className='buttons'>
                <Button type='submit' text={literalsCommon.confirm} />
                { type === 'operation' && data.id && (
                  <Button
                    type='button'
                    color='danger'
                    className='mr-3'
                    text={literalsCommon.delete}
                    onClick={handleDeleteForm}
                  />
                )}
              </div>
            )}
          </>
        ) : (
          <Loading hide={false} mode='panel' />
        )
      }
      {
        (vestingPlanPopup) && (
          <PopupInfoVestingsPlans startup={match.params.id} onClose={() => setVestingPlanPopup(null)} id={vestingPlanPopup} />
        )
      }
    </form>
  );
};

FormVests.propTypes = {
  data: PropTypes.object,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  captableId: PropTypes.string,
  match: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  type: PropTypes.string,
  optionsType: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  editable: PropTypes.bool,
  dateOrID: PropTypes.string,
  assigned: PropTypes.array,
  shareholder: PropTypes.string,
};

FormVests.defaultProps = {
  type: '',
  optionsType: '',
  data: null,
  editable: true,
  captableId: null,
  dateOrID: formatDate(new Date(), { format: 'Y-m-d' }),
  onDelete: null,
  assigned: null,
  shareholder: null,
};

export default FormVests;
