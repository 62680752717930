import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import useDialog from 'components/Dialog/components/useDialog';
import { ROUTE_MENTOR_PATH, ROUTE_PATH, ROUTE_STARTUP_PATH } from 'routes';
import { Navigate, useNavigate } from 'react-router-dom';
import { ROLE_MENTOR, ROLE_USER } from 'constants/roles';
import { sendMessageToWebview } from 'utils/sendMessageToWebview';
import { WEBIEW_MESSAGE } from 'constants/webview';
import { createFund, editFund } from 'components/FundSearcher/modules/actions';
import {
  startupCreateAction, mentorCreateAction, editStartup, getFund,
} from '../modules/actions';
import OnboardingSteps from './OnboardingSteps';
import './styles.scss';

const OnboardingIntro = (props) => {
  const {
    literals,
    literalsCommon,
    user,
    type,
    match,
    startup,
    fetchCompany,
    platform,
    showLoading,
    embed,
  } = props;

  const [fund, setFund] = useState({});
  const [loading, setLoading] = useState(!!match?.params?.id);
  const { dialog } = useDialog();
  const navigate = useNavigate();

  const fetchInfo = async () => {
    if (match?.params?.id) {
      setLoading(true);
      if (type === 'fund') {
        const response = await getFund(match.params.id);
        setFund(response);
      } else {
        await fetchCompany(match.params.id);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinishStartupOnboarding = async (data) => {
    showLoading(true);
    let startupId = false;
    if (match.params.id) {
      await editStartup(match.params.id, data);
      startupId = match.params.id;
    } else {
      const response = await startupCreateAction(data);
      startupId = response.id;
    }
    const startupData = await fetchCompany(startupId);
    if (embed) {
      sendMessageToWebview(WEBIEW_MESSAGE.NEW_STARTUP, startupData);
    } else {
      navigate({ pathname: ROUTE_STARTUP_PATH.setHome(startupId) });
    }
    showLoading(false);
  };

  const onFinishFundOnboarding = async (data) => {
    showLoading(true);
    try {
      if (match.params.id) {
        await editFund(match.params.id, data);
      } else {
        const response = await createFund(data);

        if (embed) {
          showLoading(true);
          sendMessageToWebview(WEBIEW_MESSAGE.NEW_FUND, {
            id: response.id,
            name: response.name,
            logo: response.logo,
            verified: response.verified,
          });
        } else {
          navigate({ pathname: ROUTE_PATH.PREHOME });
        }
      }
    } catch (error) {
      let errMsg = 'Error';
      if (error.body.error.code === 'lie_already_exist') {
        errMsg = literals.lieAlreadyExist;
      } else if (error.body.error.code === 'lie_already_exist_verified') {
        errMsg = literals.lieAlreadyExistVerified;
      }
      await dialog({ type: 'error', text: errMsg });
    } finally {
      showLoading(false);
    }
  };

  const onFinishMentorOnboarding = async (data) => {
    showLoading(true);
    await mentorCreateAction(data);
    navigate({ pathname: ROUTE_MENTOR_PATH.HOME });
    showLoading(false);
  };

  if (loading) {
    return <Loading hide={false} />;
  }

  const onboardingProps = {
    platform,
    literals,
    literalsCommon,
    view: false,
    match,
    startups: user.startups,
    onSubmitForm: false,
    answers: false,
    embed,
  };

  if (type === 'startup' && user?.role?.includes(ROLE_USER)) {
    onboardingProps.view = 'startup';
    onboardingProps.onSubmitForm = onFinishStartupOnboarding;
    onboardingProps.answers = match.params.id ? startup?.company ?? {} : {};
  }

  if (type === 'fund' && user?.role?.includes(ROLE_USER)) {
    onboardingProps.view = 'fund';
    onboardingProps.onSubmitForm = onFinishFundOnboarding;
    onboardingProps.answers = fund;
  }

  if (type === 'mentor' && user?.role?.includes(ROLE_MENTOR)) {
    onboardingProps.view = 'mentor';
    onboardingProps.onSubmitForm = onFinishMentorOnboarding;
    onboardingProps.answers = {
      avatar: user.mentor.avatar,
      headline: user.mentor.headline,
      company: user.mentor.company,
      country: user.mentor.country,
      hours: user.mentor.hours,
      visible: user.mentor.visible,
      extra: user.mentor?.extra ?? {},
    };
  }

  if (onboardingProps.view && (type !== 'startup' || !startup.company.id || startup?.company.relation)) {
    return <OnboardingSteps {...onboardingProps} />;
  }

  return <Navigate to={ROUTE_PATH.PREHOME} />;
};

OnboardingIntro.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  match: PropTypes.object,
  fetchCompany: PropTypes.func.isRequired,
  startup: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
  showLoading: PropTypes.func.isRequired,
  embed: PropTypes.bool,
};

OnboardingIntro.defaultProps = {
  match: {},
  embed: false,
};
export default OnboardingIntro;
