import DocumentsInstance from 'modules/documents/repository';
import { formatErrors } from 'utils/functions';

export async function uploadFile(file, _public) {
  try {
    const data = await DocumentsInstance.postDocument(file, _public);
    return data;
  } catch (error) {
    throw formatErrors(error);
  }
}

export async function apiDownloadFile(endpoint, filename, external) {
  const downloadFile = (response) => {
    if (response.ok) {
      response.blob().then((blob) => {
        const ObjectUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = ObjectUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        return true;
      });
    } else {
      throw new Error(response.statusText);
    }
  };

  if (external) {
    return fetch(endpoint).then(downloadFile);
  }

  const Url = new URL(`${window.MAIN_API_URL}${endpoint}`);
  return fetch(Url, { method: 'GET', credentials: 'include' })
    .then(downloadFile);
}
