import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchKpiRelationsAction, postKpiAction, postKpisRelationsAction } from 'routes/Performance/modules/actions';

import PerformanceStartupsComparatorComponents from '../components';

function mapStateToProps(state) {
  return {
    lang: state.session.user.lang,
    performance: state.performance,
    literals: state.i18n.literals.performance,
    literalsInvestment: state.i18n.literals.myInvestments,
    literalsCommon: state.i18n.literals.common,
    currency: state.global.currency,
    startups: state.session.user.startups,
    shareholders: state.global?.investments?.shareholders,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchKpiRelations: bindActionCreators(fetchKpiRelationsAction, dispatch),
    postKpi: bindActionCreators(postKpiAction, dispatch),
    postKpisRelations: bindActionCreators(postKpisRelationsAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceStartupsComparatorComponents);
