import AccountsInstance from 'modules/accounts/repository';
import { SessionService } from 'modules/session/sessionService';
import { formatErrors } from 'utils/functions';
import { setPlatformLanguageAction } from 'components/LanguageSelector/modules/actions';
import {
  loadingAction,
} from '../../../store/globalState/global';

import {
  FETCH_SIGNUP_INIT,
  FETCH_SIGNUP_SUCCESS,
  FETCH_SIGNUP_FAILURE,
} from './types';

export function fetchSignupInit() {
  return {
    type: FETCH_SIGNUP_INIT,
  };
}

export function fetchSignupSuccess(success) {
  return {
    type: FETCH_SIGNUP_SUCCESS,
    payload: success,
  };
}

export function fetchSignupFailure(error) {
  return {
    type: FETCH_SIGNUP_FAILURE,
    payload: error,
  };
}

export function signUp(data) {
  return AccountsInstance
    .signupUser(data)
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw formatErrors(error);
    });
}

export function fetchSocialSignupAction(data) {
  return (dispatch) => {
    dispatch(loadingAction(true));
    dispatch(fetchSignupInit());

    return AccountsInstance.signupUser(data)
      .then((user) => {
        dispatch(setPlatformLanguageAction(user.lang.toLowerCase(), user.platform?.properties?.literals));
        const { id } = user;
        SessionService.saveSession({ token: id })
          .then(() => {
            SessionService.saveUser(user)
              .then(() => {
                dispatch(loadingAction(false));
              });
          });
        return user;
      })
      .catch(() => {
        dispatch(fetchSignupFailure(true));
        dispatch(loadingAction(false));
        return false;
      });
  };
}
