import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Button from 'components/Buttons/Button';
import InputRichText from 'components/Inputs/inputRichText';
import { ChevronRight, CirclePlus } from 'lucide-react';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import { bindActionCreators } from 'redux';
import { postKpiValueAction } from '../modules/actions';
import KpiInputDate from './KpiInputDate';
import KpiInputValue from './KpiInputValue';

const KpiValueEditPopup = ({
  literals,
  literalsCommon,
  currency,
  match,
  kpi,
  relation,
  kpiValue,
  onClose,
  postKpiValue,
  refreshTable = () => {},
  onDelete = () => {},
  canEdit,
}) => {
  const [kpiValueForm, setKpiValueForm] = useState({
    forecast: kpiValue.forecast,
    value: kpiValue.value,
    notes: kpiValue.notes,
    haveNotes: !!kpiValue.notes,
    loading: false,
  });

  const isFutureValue = new Date(kpiValueForm.date) > new Date();
  const handleUpdateKpiValue = (e) => {
    e.preventDefault();
    setKpiValueForm(prev => ({ ...prev, loading: true }));
    postKpiValue(kpi.id, 'startup', match.params.id, relation.id, kpiValue.date, {
      value: kpiValueForm.value || null,
      forecast: kpiValueForm.forecast || null,
      notes: kpiValueForm.haveNotes ? kpiValueForm.notes : null,
    }).then(() => {
      refreshTable();
      onClose();
    });
  };

  return (
    <Popup title={literals.updateKpiValue} onClose={onClose}>
      <form className='edit-kpi-value' onSubmit={handleUpdateKpiValue}>
        <div className='row'>
          <div className='col-4'>
            <KpiInputDate
              preText={literalsCommon.date}
              value={kpiValue.date}
              kpi={kpi}
              onChange={() => {}}
              isDisabled
            />
          </div>
          <div className='col-4'>
            <KpiInputValue
              value={kpiValueForm.forecast}
              preText={literals.forecast}
              kpi={kpi}
              currency={currency}
              onChange={v => setKpiValueForm({ ...kpiValueForm, forecast: v })}
              isDisabled={!canEdit}
            />
          </div>
          <div className='col-4'>
            <KpiInputValue
              value={kpiValueForm.value}
              preText={literals.value}
              kpi={kpi}
              currency={currency}
              onChange={v => setKpiValueForm({ ...kpiValueForm, value: v })}
              isDisabled={isFutureValue || !canEdit}
            />
          </div>
        </div>
        <div className={`krd-form-notes ${!kpiValueForm.haveNotes && canEdit ? 'collapse-notes' : ''}`}>
          { canEdit && (
            <span className='pl-2' onClick={() => setKpiValueForm(prev => ({ ...prev, haveNotes: !kpiValueForm.haveNotes }))}>
              <ChevronRight className={`notes-icon ${kpiValueForm.haveNotes ? 'open' : ''}`} />
              {!kpiValueForm.haveNotes ? literals.addNotes : literals.removeNotes}
            </span>
          )}
          <InputRichText
            className='mt-4'
            preText={literalsCommon.notes}
            value={kpiValueForm?.notes || '-'}
            onChange={value => setKpiValueForm({ ...kpiValueForm, notes: value })}
            minHeight='150px'
            isDisabled={!canEdit}
          />
        </div>
        { canEdit && (
          <div className='buttons'>
            <Button
              color='danger'
              text={literalsCommon.delete}
              onClick={() => { onDelete(kpiValue); onClose(); }}
              loading={kpiValueForm.loading}
            />
            <Button
              icon={CirclePlus}
              text={literalsCommon.update}
              type='submit'
              disabled={!(kpiValueForm.forecast || kpiValueForm.forecast === 0)
                && !(kpiValueForm.value || kpiValueForm.value === 0)}
              loading={kpiValueForm.loading}
            />
          </div>
        )}
      </form>
    </Popup>
  );
};

KpiValueEditPopup.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  kpiValue: PropTypes.object.isRequired,
  kpi: PropTypes.object.isRequired,
  relation: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  postKpiValue: PropTypes.func.isRequired,
  refreshTable: PropTypes.func,
  onDelete: PropTypes.func,
  canEdit: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    literals: state.i18n.literals.performance,
    literalsCommon: state.i18n.literals.common,
    currency: state.global.currency,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    postKpiValue: bindActionCreators(postKpiValueAction, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KpiValueEditPopup));
