import React from 'react';
import PropTypes from 'prop-types';

class PanelBody extends React.Component {
  static displayName = 'PanelBody';

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    noHeader: PropTypes.bool,
    className: PropTypes.string,
    onTab: PropTypes.func,
  };

  static defaultProps = {
    activeTab: 0,
    noHeader: false,
    className: '',
    onTab: null,
  };

  render() {
    const {
      activeTab,
      noHeader,
      className,
      onTab,
    } = this.props;

    let { children } = this.props;
    children = Array.isArray(children) ? children.flat(1) : children;

    if (children?.type === React.Fragment) {
      children = children.props.children;
    }
    if (React.isValidElement(children)) {
      children = [children];
    }

    const handleArrayChildren = () => {
      const filteredChildren = children.filter((child, i) => {
        return child && !(child?.type?.displayName === 'PanelTab' && ![child.key, i].includes(activeTab));
      });

      if (!filteredChildren.length && activeTab !== 0) {
        onTab(0);
      }

      return filteredChildren;
    };

    return (
      <div className={`panel-body simple-scrollbar ${className} ${noHeader ? 'noHeader' : ''}`}>
        { Array.isArray(children) ? handleArrayChildren() : children }
      </div>
    );
  }
}

export default PanelBody;
