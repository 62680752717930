import React from 'react';
import PropTypes from 'prop-types';
import ButtonsDotsMenu from 'components/Buttons/ButtonsDotsMenu';
import { Pencil, Trash } from 'lucide-react';
import { Skeleton } from '@mui/material';
import { ROUTE_STARTUP_PATH } from 'routes';
import { Link, useNavigate } from 'react-router-dom';
import { PERMISSION_LEVELS } from 'constants/permissions';
import { getTranslation } from 'utils/functions';
import './styles.scss';

const TemplateRow = ({
  loading = false,
  id,
  name = null,
  image = null,
  scope = null,
  permission = null,
  match,
  literals,
  literalsCommon,
  lang,
  onDelete,
}) => {
  const navigate = useNavigate();

  if (loading) {
    return (
      <div key={id} className='template'>
        <Link to={{
          pathname: ROUTE_STARTUP_PATH.setReportingAdd(match.params.id, 'new'),
          search: `?tmpl=${id}`,
        }}
        >
          <div className='d-flex justify-content-between'>
            <Skeleton animation='wave' style={{ marginBottom: 2 }} width='50%' />
          </div>
          <div className='image-container'>
            <div className='no-image' />
          </div>
        </Link>
      </div>
    );
  }

  const canEdit = permission && permission.level >= PERMISSION_LEVELS.EDIT;

  return (
    <div key={id} className='template'>
      <Link to={{
        pathname: ROUTE_STARTUP_PATH.setReportingAdd(match.params.id, 'new'),
        search: `?tmpl=${id}`,
      }}
      >
        <div className='template-header'>
          <span className='template-name h6' title={getTranslation(name, lang)}>{getTranslation(name, lang)}</span>
          {canEdit && scope && scope.owner === match.params.id && (
            <ButtonsDotsMenu
              buttons={[
                {
                  icon: Pencil,
                  text: literalsCommon.edit,
                  onClick: () => navigate(ROUTE_STARTUP_PATH.setReportingTemplatesAdd(match.params.id, id)),
                },
                {
                  icon: Trash,
                  text: literalsCommon.delete,
                  onClick: () => onDelete(id),
                },
              ]}
            />
          )}
        </div>
        <div className='image-container'>
          {image ? (
            <img src={image.path} alt='' />
          ) : (
            <div className='no-image'>
              {literals.noImage}
            </div>
          )}
        </div>
      </Link>
    </div>
  );
};

TemplateRow.propTypes = {
  loading: PropTypes.bool,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  permission: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  scope: PropTypes.object,
  name: PropTypes.object,
  image: PropTypes.object,
};

export default TemplateRow;
