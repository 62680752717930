import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputSelect from 'components/Inputs/inputSelect';
import PopupEditShareholder from 'routes/MyPosition/components/PopupEditShareholders';
import { useNavigate } from 'react-router-dom';
import { Settings } from 'lucide-react';
import { getShareholderPositionResource } from 'routes/MyPosition/modules/actions';
import './styles.scss';

const ShareholdersSelect = (props) => {
  const {
    literalsCommon,
    literalsCaptable,
    user,
    shareholders,
    positionShareholders,
    setGlobalProperties,
    mode,
    taxIds,
    startup,
    setShareholders,
  } = props;

  const [allShareholders, setAllShareholders] = useState([]);
  const [editShareholdersPopup, setEditShareholdersPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (mode === 'position') {
      (async () => {
        const auxShareholders = await getShareholderPositionResource(
          startup, '', 'shareholders', { page: 0, size: 0 },
        );

        setAllShareholders(auxShareholders.items.map(sh => ({
          id: sh.id,
          name: `${sh.firstname} ${sh?.lastname || ''}`,
          taxId: sh.taxId,
        })));


        if (auxShareholders.total) {
          if (taxIds.length) {
            const aux = auxShareholders.items
              .filter(sh => taxIds.includes(sh.taxId) || taxIds.includes(sh.id))
              .map(sh => sh.id);
            setShareholders(aux);
          } else {
            setShareholders(auxShareholders.items.map(sh => sh.id));
            navigate({ search: '' }, { replace: true });
          }
        } else {
          setShareholders(null);
        }
      })();
    } else {
      setAllShareholders(user.shareholders.map(sh => ({
        id: sh.taxId || sh.id,
        name: `${sh.firstname} ${sh?.lastname || ''}`,
      })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeShareholder = (selectedShareholders) => {
    if (mode === 'position') {
      setShareholders(selectedShareholders);
      const auxTaxIds = [];
      allShareholders.forEach((sh) => {
        if (selectedShareholders.includes(sh.id) && sh.taxId) {
          auxTaxIds.push(sh.taxId);
        }
      });
      navigate({ search: auxTaxIds.length ? `?taxId=${auxTaxIds.join()}` : '' }, { replace: true });
    } else {
      setGlobalProperties({
        investments: {
          shareholders: selectedShareholders,
        },
      });
    }
  };

  return (
    <div className='shareholders-select'>
      <InputSelect
        name={literalsCaptable.shareholders}
        value={mode === 'position' ? positionShareholders : shareholders}
        onChange={handleChangeShareholder}
        preText={null}
        zIndex={13}
        options={allShareholders}
        placeholder={literalsCaptable.filterByShareholder}
        multiple
      />
      <Settings className='action-icon' onClick={() => setEditShareholdersPopup(true)} />
      {editShareholdersPopup && (
        <PopupEditShareholder
          literals={literalsCaptable}
          literalsCommon={literalsCommon}
          startup={startup}
          onClose={() => setEditShareholdersPopup(false)}
        />
      )}
    </div>
  );
};


ShareholdersSelect.propTypes = {
  literalsCaptable: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  shareholders: PropTypes.array,
  setGlobalProperties: PropTypes.func.isRequired,
  mode: PropTypes.string,
  taxIds: PropTypes.array,
  startup: PropTypes.string,
  setShareholders: PropTypes.func,
  positionShareholders: PropTypes.array,
};

ShareholdersSelect.defaultProps = {
  mode: '',
  taxIds: [],
  startup: '',
  positionShareholders: [],
  shareholders: null,
  setShareholders: null,
};

export default ShareholdersSelect;
