import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const FoldersInstance = ApiFetch();

export const URLS = {
  CREATE_FOLDER: 'folders/:type/:id',
  DELETE_BOOKMARK_CONTENT: 'folders/:type/:id/bookmark',
  DELETE_FOLDER: 'folders/:type/:id/:folder',
  EDIT_FOLDER: 'folders/:type/:id/:folder',
  GET_BOOKMARK_FOLDER: 'folders/:type/:id/bookmark',
  GET_FOLDER: 'folders/:type/:id/:folder',
  GET_FOLDER_CONTENTS: 'folders/:type/:id/:folder/contents',
  GET_RECENT_FOLDER: 'folders/:type/:id/recent',
  POST_BOOKMARK_CONTENT: 'folders/:type/:id/bookmark',
};

async function createFolder(type, id, data) {
  const path = preparePath(URLS.CREATE_FOLDER, { type, id });
  return FoldersInstance.post(path, data);
}

async function deleteBookmarkContent(type, id, data) {
  const path = preparePath(URLS.DELETE_BOOKMARK_CONTENT, { type, id });
  return FoldersInstance.delete(path, { data });
}

async function deleteFolder(type, id, folder, params) {
  const path = preparePath(URLS.DELETE_FOLDER, { type, id, folder }, params);
  return FoldersInstance.delete(path);
}

async function editFolder(type, id, folder, data) {
  const path = preparePath(URLS.EDIT_FOLDER, { type, id, folder });
  return FoldersInstance.put(path, data);
}

async function getBookmarkFolder(type, id, opts) {
  const path = preparePath(URLS.GET_BOOKMARK_FOLDER, { type, id }, opts);
  return FoldersInstance.get(path);
}

async function getFolder(type, id, folder) {
  const path = preparePath(URLS.GET_FOLDER, { type, id, folder });
  return FoldersInstance.get(path);
}

async function getFolderContents(type, id, folder, params = {}) {
  const path = preparePath(URLS.GET_FOLDER_CONTENTS, { type, id, folder }, params);
  return FoldersInstance.get(path);
}

async function getRecentFolder(type, id, params = {}) {
  const path = preparePath(URLS.GET_RECENT_FOLDER, { type, id }, params);
  return FoldersInstance.get(path);
}

async function postBookmarkContent(type, id, data) {
  const path = preparePath(URLS.POST_BOOKMARK_CONTENT, { type, id });
  return FoldersInstance.post(path, data);
}

export default {
  createFolder,
  deleteBookmarkContent,
  deleteFolder,
  editFolder,
  getBookmarkFolder,
  getFolder,
  getFolderContents,
  getRecentFolder,
  postBookmarkContent,
};
