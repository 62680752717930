/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelBody from 'components/Panel/components/PanelBody';
import StartupCaptableDate from 'components/StartupCaptableDate/components';
import useDialog from 'components/Dialog/components/useDialog';
import SetUpCaptable from 'assets/svg/set_up_captable.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { postStartupCaptable } from 'components/StartupCaptableDate/modules/actions';
import { Skeleton } from '@mui/material';
import { ArrowRight } from 'lucide-react';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import { initPermission } from 'utils/functions/initPermissions';
import { PERMISSION_MODES, PERMISSION_SCOPES } from 'constants/permissions';
import { TOOL_CAP_TABLE } from 'constants/tools';
import { startupCanManage } from 'utils/functions';
import {
  createStartupCaptableService, deleteStartupCaptableService, getStartupCaptableService, initializeCaptable,
} from '../../routes/Captable/modules/actions';
import UploadDocumentsPopup from './UploadDocumentsPopup';
import PermissionsPopup from './PermissionsPopup';
import ImportExcelPopup from './ImportExcelPopup';

const EmptyCaptable = (props) => {
  const {
    literals,
    literalsCommon,
    match,
    captable,
    startup,
    fetchCaptable,
  } = props;

  const [uploadDocumentsPopup, setUploadDocumentsPopup] = useState(false);
  const [importExcelPopup, setImportExcelPopup] = useState(false);
  const [permissionsPopup, setPermissionsPopup] = useState(false);
  const [prevUploadRequest, setPrevUploadRequest] = useState(null);
  const [prevImportRequest, setPrevImportRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [newCaptableId, setNewCaptableId] = useState(null);
  const { dialog } = useDialog();
  const { canEdit } = initPermission(captable.selected, PERMISSION_SCOPES.STARTUP, startup, TOOL_CAP_TABLE);
  const canManageTool = startupCanManage(startup, TOOL_CAP_TABLE);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const features = ['operations', 'summary', 'timeline', 'simulations', 'export', 'invite'];
  const captableId = searchParams.get('captable') || null;

  useEffect(() => {
    if (canEdit) {
      Promise.all([
        getStartupCaptableService(match.params.id, { service: 'upload_captable', extra: captableId }),
        getStartupCaptableService(match.params.id, { service: 'import_captable', extra: captableId }),
      ]).then(([uploadCaptable, importCaptable]) => {
        setPrevUploadRequest(uploadCaptable.id ? uploadCaptable : null);
        setPrevImportRequest(importCaptable.id ? importCaptable : null);
        setLoading(false);
      }).catch(() => setLoading(false));
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getActualCaptable = async () => {
    let actualCaptable = captableId;
    if (!captable.captables.length) {
      if (newCaptableId) {
        return newCaptableId;
      }
      const newCaptable = await dialog({
        type: 'loading',
        execute: async () => {
          const responseB = await postStartupCaptable(match.params.id, {
            name: startup.name,
            description: '',
            permission: {
              mode: canManageTool ? PERMISSION_MODES.RESTRICTED : PERMISSION_MODES.PRIVATE,
            },
          });
          return responseB;
        },
      });
      actualCaptable = newCaptable?.id;
      setNewCaptableId(newCaptable?.id);
    } else if (!captableId) {
      actualCaptable = captable.captables[0].id;
    }
    return actualCaptable;
  };

  const handleGoOperations = async () => {
    const actualCaptable = await getActualCaptable();
    navigate(embedView(ROUTE_STARTUP_PATH.setCapTableOperations(match.params.id, actualCaptable)));
  };

  const handleNewOperation = async () => {
    const actualCaptable = await getActualCaptable();
    navigate(embedView(ROUTE_STARTUP_PATH.setCapTableOperation(match.params.id, actualCaptable, 'new')));
  };

  const handleCreateRequest = async (request) => {
    const actualCaptable = await getActualCaptable();
    setSending(true);
    try {
      const response = await createStartupCaptableService(match.params.id, {
        ...request,
        extra: actualCaptable,
      });
      if (request.service === 'upload_captable') {
        setPrevUploadRequest(response);
        setUploadDocumentsPopup(false);
      } else {
        setPrevImportRequest(response);
        setPermissionsPopup(false);
      }
    } catch (e) {
      console.error(e);
    }
    setSending(false);
  };

  const handleDeleteRequest = async (service) => {
    try {
      const confirm = await dialog({
        type: 'confirmDanger', text: literals.questionRemoveCaptableRequest,
      });
      if (confirm) {
        setSending(true);
        await dialog({
          type: 'loading',
          execute: async () => {
            await deleteStartupCaptableService(match.params.id, { service, extra: captableId });
            if (service === 'upload_captable') {
              setPrevUploadRequest(null);
              setUploadDocumentsPopup(false);
            } else {
              setPrevImportRequest(null);
              setPermissionsPopup(false);
            }
            setSending(false);
          },
        });
      }
    } catch {
      setSending(false);
    }
  };

  return (
    <>
      <StartupCaptableDate startup={startup} captable={captable} noDate />
      <Panel>
        <PanelBody noHeader>
          <div className='setup-captable'>
            { canEdit ? (
              <div className='setup-captable--message'>
                <h3 className='fw-b'>{literals.setupCaptable}</h3>
                <img src={SetUpCaptable} alt='' />
                <div>{literals.chooseOption}</div>
              </div>
            ) : (
              <div className='setup-captable--message'>
                <img src={SetUpCaptable} alt='' />
                <div className='text-center'>{literals.emptyCaptable}</div>
              </div>
            )}
            {loading ? (
              <ul className='setup-captable--actions'>
                <li><Skeleton width='300px' /></li>
                <li><Skeleton width='300px' /></li>
                {canEdit && (
                  <>
                    <li><Skeleton width='300px' /></li>
                    <li><Skeleton width='300px' /></li>
                  </>
                )}
              </ul>
            ) : (
              <>
                {canEdit && (
                  <ul className='setup-captable--actions'>
                    {!captable.isEmpty ? (
                      <li onClick={handleGoOperations}>
                        <span>{literals.publishSomeOperation}</span>
                        <ArrowRight />
                      </li>
                    ) : (
                      <li onClick={handleNewOperation}>
                        <span>{literals.createFirstOperation}</span>
                        <ArrowRight />
                      </li>
                    )}
                    {canManageTool && (
                      <>
                        <li onClick={() => setUploadDocumentsPopup(true)}>
                          <div className='d-flex align-items-center'>
                            <span>{literals.uploadExcelTemplates}</span>
                            {prevUploadRequest && <span className='keyword'>{literals[prevUploadRequest.status]}</span>}
                          </div>
                          <ArrowRight />
                        </li>
                        <li onClick={() => setPermissionsPopup(true)}>
                          <div className='d-flex align-items-center'>
                            <span>{`${literals.importFrom} Carta/Capdesk/Ledgy`}</span>
                            {prevImportRequest && <span className='keyword'>{literals[prevImportRequest.status]}</span>}
                          </div>
                          <ArrowRight />
                        </li>
                      </>
                    )}
                    <li onClick={() => setImportExcelPopup(true)}>
                      <div className='d-flex align-items-center'>
                        <span>{`${literals.importFrom} excel`}</span>
                      </div>
                      <ArrowRight />
                    </li>
                  </ul>
                )}
              </>
            )}
          </div>
          { uploadDocumentsPopup && (
            <UploadDocumentsPopup
              {...props}
              request={prevUploadRequest}
              loading={sending}
              onSubmit={handleCreateRequest}
              onDelete={handleDeleteRequest}
              onClose={() => setUploadDocumentsPopup(false)}
            />
          )}
          { permissionsPopup && (
            <PermissionsPopup
              {...props}
              request={prevImportRequest}
              loading={sending}
              onSubmit={handleCreateRequest}
              onDelete={handleDeleteRequest}
              onClose={() => setPermissionsPopup(false)}
            />
          )}
          {
            importExcelPopup && (
              <ImportExcelPopup
                literals={literals}
                literalsCommon={literalsCommon}
                startup={startup.id}
                captable={captable.selected?.id}
                onClose={() => setImportExcelPopup(false)}
                onSubmit={id => initializeCaptable(fetchCaptable, captable, startup.id, id, true)}
              />
            )
          }
        </PanelBody>
      </Panel>
      <div className='captable-features'>
        {
          features.map(feature => (
            <div key={feature} className='box box-padding'>
              <h6 className='fw-b'>{literals.features[feature]}</h6>
              <span>{literals.features[`${feature}Description`]}</span>
            </div>
          ))
        }
      </div>
    </>
  );
};

EmptyCaptable.propTypes = {
  match: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  fetchCaptable: PropTypes.func.isRequired,
};

export default EmptyCaptable;
