import StartupsInstance from 'modules/startups/repository';
import { formatErrors } from 'utils/functions';


export function addUserToStartup(id, data) {
  return StartupsInstance.startupAddUser(id, data)
    .then((user) => {
      return user;
    })
    .catch((error) => {
      throw formatErrors(error);
    });
}

export function editUserStartup(id, user, data) {
  return StartupsInstance
    .startupEditUser(id, user, data)
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw formatErrors(error);
    });
}
