import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Loading from 'components/Loading';
import InputText from 'components/Inputs/inputText';
import InputSelect from 'components/Inputs/inputSelect';
import OutputErrors from 'components/Inputs/outputErrors';
import Button from 'components/Buttons/Button';
import Alert from 'components/Alert';
import InputPermission from 'components/Inputs/InputPermission';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useSearchParams } from 'react-router-dom';
import { formatDate } from 'utils/functions';
import { PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import { TOOL_CAP_TABLE } from 'constants/tools';
import { initPermission } from 'utils/functions/initPermissions';
import { AmplitudeApi } from 'utils/amplitude';
import { postVestingPlanAction, putVestingPlanAction } from 'routes/Captable/modules/actions';
import { fetchCaptableOptions } from '../modules/actions';
import PopupFormVestingPlanBasic from './PopupFormVestingPlanBasic';
import PopupFormVestingPlanTime from './PopupFormVestingPlanTime';
import PopupFormVestingPlanMilestones from './PopupFormVestingPlanMilestones';
import './styles.scss';

const defaultVestingPlan = {
  type: '',
  name: '',
  basic: {
    frequency: 'month',
    period: 0,
    cliff: 0,
    intervals: 0,
  },
  periods: [{ frequency: 'month' }],
  milestones: [{}],
  notes: '',
  documents: [],
};

const PopupFormVestingPlan = (props) => {
  const {
    startup,
    vestingPlan,
    onClose,
    onSubmit,
    literals,
    literalsCommon,
    postVestingPlan,
    putVestingPlan,
  } = props;

  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;

  const [data, setData] = useState(vestingPlan?.id ? vestingPlan : defaultVestingPlan);

  const [totalPercentage, setTotalPercentage] = useState(0);
  const [haveOptions, setHaveOptions] = useState();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const [permission, setPermission] = useState(initPermission(
    vestingPlan?.id ? vestingPlan : null,
    PERMISSION_SCOPES.STARTUP,
    startup,
    TOOL_CAP_TABLE,
  ));
  const { canEdit } = permission;

  const typeOptions = [
    { id: 'basic', name: literals.basic },
    { id: 'time', name: literals.time },
    { id: 'milestones', name: literals.milestones },
    { id: 'hybrid', name: literals.hybrid },
  ];

  useEffect(() => {
    (async () => {
      if (data.id) {
        setLoading('fetching');
        const actualDate = formatDate(new Date(), { format: 'Y-m-d' });
        const opts = { filters: { 'vesting_plan.id': data.id }, captable: captableId };
        const capOptions = await fetchCaptableOptions(startup.id, actualDate, opts);
        setHaveOptions(capOptions.total > 0);
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePercentage = (array, type) => {
    let percentage = 0;
    if (array.periods.length > 0 && (type === 'time' || type === 'hybrid')) {
      array.periods.forEach((object) => { percentage += object.percentage || 0; });
    }
    if (array.milestones.length > 0 && (type === 'milestones' || type === 'hybrid')) {
      array.milestones.forEach((object) => { percentage += object.percentage || 0; });
    }
    setTotalPercentage(percentage);
  };

  const onChange = (type, atribute, index, value) => {
    const aux = { ...data, basic: { ...data.basic } };
    const numericAtributes = ['periods', 'cliff', 'intervals', 'lenght', 'percentage'];
    const formatValue = numericAtributes.includes(atribute) ? parseInt(value, 10) : value;

    switch (type) {
      case 'basic':
        aux.basic[atribute] = formatValue;
        break;
      default:
        if (atribute === 'delete') {
          aux[type].splice(index, 1);
        } else if (atribute === 'add') {
          aux[type].push({ frequency: 'month' });
        } else {
          aux[type][index][atribute] = formatValue;
          changePercentage(aux, aux.type);
        }
    }

    setData(aux);
  };

  const submit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const obj = { ...data, permission };
    if (obj.type !== 'basic') {
      obj.basic = null;
    }

    if (obj.type !== 'hybrid') {
      if (obj.type !== 'periods') {
        obj.periods = [];
      }
      if (obj.type !== 'milestones') {
        obj.milestones = [];
      }
    }
    setLoading('saving');
    try {
      const response = await ((obj.id) ? putVestingPlan(startup.id, obj) : postVestingPlan(startup.id, obj));
      if (typeof onSubmit === 'function') {
        onSubmit(response);
      }
      AmplitudeApi.successEvent('startup.captable.summary.options.click.newVesting');
      onClose();
    } catch (exception) {
      AmplitudeApi.errorEvent('startup.captable.summary.options.click.newVesting');
      setErrors(exception);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popup size='large' title={literals.formVestingsPlans} onClose={onClose}>
      <div className='popUpVestingPlan'>
        {data.id && haveOptions && (
          <Alert type='warning' text={literals.modifyWarning} />
        )}
        {!vestingPlan?.id || loading !== 'fetching' ? (
          <form onSubmit={submit}>
            <div className='row'>
              <div className='col-md-3'>
                <InputSelect
                  preText={literals.type}
                  options={typeOptions}
                  value={data.type ? data.type : ''}
                  onChange={(type) => {
                    setData(prev => ({ ...prev, type }));
                    changePercentage(data, type);
                  }}
                  isDisabled={!canEdit || haveOptions}
                  isRequired
                />
              </div>
              <div className='col-md-4'>
                <InputText
                  name='planName'
                  preText={literalsCommon.name}
                  value={data.name ? data.name : ''}
                  onChange={name => setData(prev => ({ ...prev, name }))}
                  isDisabled={!canEdit}
                  isRequired
                />
              </div>
              <div className='col-md-5'>
                <InputPermission
                  scope={{ type: PERMISSION_SCOPES.STARTUP, id: startup.id }}
                  element={{ type: PERMISSION_TYPES.VESTING_PLAN, id: vestingPlan?.id }}
                  value={permission}
                  onChange={setPermission}
                />
              </div>
            </div>
            {data.type === 'basic' && (
              <PopupFormVestingPlanBasic
                data={data}
                onChange={(atribute, index, value) => onChange('basic', atribute, index, value)}
                literals={literals}
                isDisabled={!canEdit || haveOptions}
              />
            )}

            {(data.type === 'time' || data.type === 'hybrid') && (
              <PopupFormVestingPlanTime
                data={data}
                onChange={(atribute, index, value) => onChange('periods', atribute, index, value)}
                literals={literals}
                totalPercentage={totalPercentage}
                isDisabled={!canEdit || haveOptions}

              />
            )}

            {(data.type === 'milestones' || data.type === 'hybrid') && (
              <PopupFormVestingPlanMilestones
                data={data}
                onChange={(atribute, index, value) => onChange('milestones', atribute, index, value)}
                literals={literals}
                totalPercentage={totalPercentage}
                isDisabled={!canEdit || haveOptions}
              />
            )}

            {(data.type === 'milestones' || data.type === 'hybrid' || data.type === 'time')
              && (
                <div className='row mt-3'>
                  <div className='col-md-4 vestingPlan-percentage'>
                    <InputText
                      preText={literals.overallPercentage}
                      name={literals.overallPercentage}
                      value={`${totalPercentage}%`}
                      isDisabled
                    />
                  </div>
                </div>
              )}
            <OutputErrors literals={literals} errors={errors} />
            {canEdit && (
              <div className='buttons'>
                <Button text={literals.save} type='submit' loading={loading === 'saving'} />
              </div>
            )}
          </form>
        ) : (
          <Loading hide={false} mode='panel' />
        )}
      </div>
    </Popup>
  );
};

PopupFormVestingPlan.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  vestingPlan: PropTypes.object,
  startup: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  postVestingPlan: PropTypes.func.isRequired,
  putVestingPlan: PropTypes.func.isRequired,
};

PopupFormVestingPlan.defaultProps = {
  vestingPlan: null,
};

const mapStateToProps = state => ({
  literals: state.i18n.literals.vestingsPlans,
  literalsCommon: state.i18n.literals.common,
  startup: state.global.startup,
});

const mapDispatchToProps = dispatch => ({
  postVestingPlan: bindActionCreators(postVestingPlanAction, dispatch),
  putVestingPlan: bindActionCreators(putVestingPlanAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupFormVestingPlan);
