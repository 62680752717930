import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Table from 'components/Table';
import IOFileLoading from 'components/IOFileLoading/components';
import useDialog from 'components/Dialog/components/useDialog';
import StartupCaptableDate from 'components/StartupCaptableDate/components';
import PermissionLabel from 'components/PermissionsPopup/PermissionLabel';
import EmptyCaptable from 'assets/svg/empty_captable.svg';
import { formatDate } from 'utils/functions';
import { useCounter } from 'utils/customHooks';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import {
  Download, Eye, LineChart, PlusCircle, Trash,
} from 'lucide-react';
import { initializeCaptable } from 'routes/Captable/modules/actions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PERMISSION_LEVELS, PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import {
  getCaptableWaterfalls,
  deleteCaptableWaterfall,
  downloadWaterfallExcel,
  deleteMassiveWaterfalls,
} from '../modules/actions';
import './styles.scss';

const Waterfall = (props) => {
  const {
    literals,
    literalsCommon,
    match,
    captable,
    fetchCaptable,
    startup,
  } = props;

  const [selectedRows, setSelectedRows] = useState([]);
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const tableRefreshFlag = useCounter(0);
  const navigate = useNavigate();
  const { dialog } = useDialog();

  useEffect(() => {
    initializeCaptable(fetchCaptable, captable, match.params.id, captableId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchWaterfall = (page, size, search, filters, sort, fields) => {
    return getCaptableWaterfalls(match.params.id, {
      page, size, search, filters, sort, fields, captable: captableId,
    });
  };

  const deleteWaterfall = async (waterfall) => {
    const confirm = await dialog({
      text: literals.deleteWaterfall,
      type: 'confirmDanger',
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          if (typeof waterfall === 'string') {
            await deleteCaptableWaterfall(match.params.id, waterfall);
          } else {
            await deleteMassiveWaterfalls(match.params.id, waterfall);
          }
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const handleDownloadExcel = async (waterfall) => {
    setDownloadingExcel(true);
    await downloadWaterfallExcel(match.params.id, waterfall.id, `waterfall_${waterfall.name}.xlsx`);
    setDownloadingExcel(false);
  };

  const columns = [
    {
      field: 'name',
      label: literalsCommon.name,
      preRender: (v, row) => {
        let description = row.description ? row.description.replace(/(<([^>]+)>)/gi, '') : '';
        description = description.length > 150 ? `${description.substr(0, 150)}...` : description;
        return (
          <div>
            {v}
            <PermissionLabel permission={row.permission} />
            {description && (
              <>
                <br />
                <p className='fc-secondary fs-small d-none d-sm-block mb-0'>
                  {description}
                </p>
              </>
            )}
          </div>
        );
      },
      grow: 3,
    },
    {
      field: 'operation',
      label: literals.operation,
      preRender: v => `${v?.name} (${formatDate(v?.date, { format: 'Y-m-d' })})`,
      grow: 1,
    },
    {
      field: 'createdAt',
      label: literalsCommon.createdAt,
      preRender: v => formatDate(v, { format: 'Y-m-d' }),
      width: 120,
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Eye,
          text: literalsCommon.view,
          onClick: row => navigate(embedView(ROUTE_STARTUP_PATH.setCaptableWaterfallAdd(match.params.id, row.id, captableId))),
        },
        {
          icon: LineChart,
          text: literals.comparator,
          onClick: row => navigate({
            pathname: navigate(embedView(ROUTE_STARTUP_PATH.setCaptableWaterfallComparator(match.params.id, captableId))),
            search: `?ids=${row.id}`,
          }),
        },
        {
          icon: Download,
          text: 'Excel',
          onClick: row => handleDownloadExcel(row),
        },
        {
          type: 'permission',
          scope: { type: PERMISSION_SCOPES.STARTUP, id: startup.id },
          element: PERMISSION_TYPES.WATERFALL,
        },
        {
          icon: Trash,
          text: literalsCommon.delete,
          onClick: row => deleteWaterfall(row.id),
          hidden: (_, row) => row.permission.level < PERMISSION_LEVELS.EDIT,
        },
      ],
    },
  ];

  return (
    <Panel>
      <PanelHeader title={literals.waterfall} />
      <PanelBody>
        <StartupCaptableDate startup={startup} captable={captable} noDate />
        {
          !captable.loading && !captable?.operations?.length ? (
            <div className='panel-empty-message full'>
              <img src={EmptyCaptable} alt='' />
              <p>{literals.emptyCaptable}</p>
            </div>
          ) : (
            <Table
              key={`waterfalls-table${captable.loading ? '-loading' : ''}`}
              className='waterfalls-table'
              columns={columns}
              fetch={!captable.loading ? fetchWaterfall : null}
              onClickRow={row => navigate(embedView(ROUTE_STARTUP_PATH.setCaptableWaterfallAdd(match.params.id, row.id, captableId)))}
              forceFetch={tableRefreshFlag.value}
              selectRows={selectedRows}
              onSelectRows={setSelectedRows}
              actions={[
                {
                  icon: PlusCircle,
                  text: literalsCommon.new,
                  onClick: () => navigate(embedView(ROUTE_STARTUP_PATH.setCaptableWaterfallAdd(match.params.id, 'new', captableId))),
                },
                {
                  icon: LineChart,
                  text: literals.comparator,
                  onClick: () => navigate(embedView(ROUTE_STARTUP_PATH.setCaptableWaterfallComparator(match.params.id, captableId))),
                },
              ]}
              rowSelection={[
                {
                  icon: Trash,
                  text: literalsCommon.delete,
                  onClick: () => deleteWaterfall(selectedRows),
                },
                {
                  icon: LineChart,
                  text: literals.comparator,
                  onClick: () => navigate({
                    pathname: navigate(embedView(ROUTE_STARTUP_PATH.setCaptableWaterfallComparator(match.params.id, captableId))),
                    search: `?ids=${selectedRows.join(',')}`,
                  }),
                },
              ]}
            />
          )
        }
        { downloadingExcel && <IOFileLoading mode='download' file={{ name: 'waterfall.xlsx' }} /> }
      </PanelBody>
    </Panel>
  );
};

Waterfall.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  fetchCaptable: PropTypes.func.isRequired,
};

export default Waterfall;
