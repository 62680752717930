import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import ButtonsDotsMenu from 'components/Buttons/ButtonsDotsMenu';
import PersonLock from 'components/BootstrapIcons/PersonLock';
import { Link } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import {
  Square, SquareCheck, PlusCircle,
  CircleMinus, GripVertical, X,
} from 'lucide-react';

const TableRow = (props) => {
  const {
    literalsCommon,
    columns,
    row,
    loading,
    onClick,
    selected,
    onSelectRow,
    onUnSelectRow,
    draggable,
    scrollRef,
    onOpenPermissions,
    rowDetails,
  } = props;

  const [showDetails, setShowDetails] = useState(false);
  const [width, setWidth] = useState(false);

  const handleResize = () => {
    if (scrollRef.current) {
      setWidth(scrollRef.current.offsetWidth);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (rowDetails) {
      handleResize();

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickRow = (column = null) => {
    if (!loading && column?.type !== 'menu') {
      if (typeof column?.onClick === 'function') {
        column.onClick(row);
      } else if (typeof onClick === 'function') {
        onClick(row);
      } else if (rowDetails) {
        setShowDetails(prev => !prev);
      }
    }
  };

  const renderColumn = (data, index, options) => {
    const column = { ...data };
    let content = row ? row[column.field] : null;
    let title = '';
    let rowTitle = '';

    if (!loading && row) {
      if (typeof column.title === 'function') {
        title = column.title(content, row);
      }
      if (typeof column.preRender === 'function') {
        content = column.preRender(content, row);
      }
      if (row?.name) {
        rowTitle = row.name;
      }
      if (row?.firstname || row?.lastname) {
        rowTitle = `${row.firstname || ''} ${row.lastname || ''}`.trim();
      }
    }
    switch (column.type) {
      case 'checkbox': {
        const checkboxSize = 18;
        if (loading) {
          content = (
            <div className='d-flex justify-content-center' style={{ width: checkboxSize }}>
              <Skeleton variant='rectangular' animation='wave' width={16} height={16} />
            </div>
          );
        } else {
          content = !selected
            ? <Square size={checkboxSize} onClick={(e) => { e.stopPropagation(); onSelectRow(); }} />
            : <SquareCheck size={checkboxSize} onClick={(e) => { e.stopPropagation(); onUnSelectRow(); }} />;
        }
        break;
      }
      case 'details': {
        const detailsSize = column.width ?? 36;
        if (loading) {
          content = (
            <div className='d-flex justify-content-center' style={{ width: detailsSize }}>
              <Skeleton variant='rectangular' animation='wave' width={16} height={16} />
            </div>
          );
        } else {
          column.onClick = () => setShowDetails(!showDetails);
          content = showDetails
            ? <CircleMinus width={detailsSize} />
            : <PlusCircle width={detailsSize} />;
        }
        break;
      }
      case 'drag': {
        const dragSize = column.width ?? 15;
        if (loading) {
          content = (
            <div className='d-flex justify-content-center' style={{ width: dragSize }}>
              <Skeleton variant='rectangular' animation='wave' width={16} height={16} />
            </div>
          );
        } else {
          content = (
            <div {...options?.drag} className={`drag-icon ${!draggable ? 'loading' : ''}`}>
              <GripVertical size={20} />
            </div>
          );
        }
        break;
      }
      case 'avatar': {
        const avatarSize = column.width ?? 35;
        if (loading) {
          content = <Skeleton style={{ borderRadius: '35%' }} variant='rectangular' animation='wave' width={avatarSize} height={avatarSize} />;
        } else {
          content = (
            <Avatar
              title={title || ''}
              src={content?.path}
              size={avatarSize}
              className='shadowNone'
            />
          );
        }
        break;
      }
      case 'menu':
        column.width = 44;
        if (column.buttons && column.buttons.length) {
          let hidden = false;
          if (typeof column.hidden === 'function') {
            hidden = column.hidden(content, row);
          }

          if (loading) {
            content = <Skeleton animation='wave' width='100%' />;
          } else if (hidden) {
            content = null;
          } else {
            const buttons = column.buttons.map(a => ({ ...a }));
            buttons.forEach((button, i) => {
              if (typeof button.icon === 'function') {
                buttons[i].icon = button.icon(content, row);
              }
              if (typeof button.text === 'function') {
                buttons[i].text = button.text(content, row);
              }
              if (typeof button.disabled === 'function') {
                buttons[i].disabled = button.disabled(content, row);
              }
              if (typeof button.onClick === 'function') {
                buttons[i].onClick = () => column.buttons[i].onClick(row);
              }
              if (typeof button.hidden === 'function') {
                buttons[i].hidden = column.buttons[i].hidden(content, row);
              }
              if (button.type === 'Link') {
                if (typeof buttons[i].to === 'function') {
                  buttons[i].to = buttons[i].to(content, row);
                }
              }
              if (button.type === 'a') {
                if (typeof buttons[i].href === 'function') {
                  buttons[i].href = buttons[i].href(content, row);
                }
              }
              if (button.type === 'permission') {
                buttons[i].text = literalsCommon.permissions;
                buttons[i].icon = PersonLock;
                buttons[i].onClick = () => onOpenPermissions({
                  title: rowTitle,
                  scope: button.scope,
                  element: {
                    type: button.element,
                    id: null,
                  },
                });
              }
            });
            content = (
              <ButtonsDotsMenu buttons={buttons} offset={{ left: 24 }} scrollRef={scrollRef} />
            );
          }
        }
        break;
      case 'button':
        column.width = 36;

        if (loading) {
          content = <Skeleton animation='wave' width='100%' />;
        } else {
          let hidden = false;
          if (typeof column.hidden === 'function') {
            hidden = column.hidden(content, row);
          }

          content = !hidden ? (
            <button
              className='table-row-button'
              type='button'
              onClick={(e) => { e.stopPropagation(); column.button.onClick(row); }}
              title={column.button.text || ''}
              disabled={column.button.disabled === true || (typeof column.button.disabled === 'function' && column.button.disabled(content, row))}
            >
              { typeof column.button.icon === 'function' ? (
                <>{column.button.icon(row)}</>
              ) : (
                <>
                  {React.isValidElement(column.button.icon) ? (column.button.icon) : <column.button.icon />}
                </>
              )}
            </button>
          ) : null;
        }
        break;
      case 'link':
        column.width = 36;
        if (loading) {
          content = <Skeleton animation='wave' width='100%' />;
        } else {
          const linkTo = (typeof column.link.to === 'function') ? column.link.to(content, row) : column.link.to;
          content = (
            <Link target='_blank' to={linkTo} className='table-row-button' title={column.link.text || ''}>
              {column.link.icon}
            </Link>
          );
        }
        break;
      // case 'a':
      //   column.width = 36;
      //   if (loading) {
      //     content = <Skeleton animation='wave' width='100%' />;
      //   } else {
      //     console.log(column.a.href());
      //     content = (
      //       <a href={column.a.href} target={column.a.target}>{column.a.text}</a>
      //     );
      //   }
      //   break;
      default:
        if (loading) {
          content = <Skeleton animation='wave' width='100%' />;
        }
        break;
    }
    const styles = {};
    let classes = '';
    if (!column.width) {
      classes += ' fluid';
    }
    if (column.align) {
      classes += ` align-${column.align}`;
    }
    if (column.type === 'menu') {
      classes += ' button-menu';
    }
    if (column.width) {
      styles.width = column.width;
      classes += ' wfixed';
    } else {
      styles.flex = `${column.grow ?? 1} 0 0px`;
    }
    if (column.minWidth) {
      styles.minWidth = column.minWidth;
    }
    if (typeof content === 'string' && !title) {
      title = content.replace(/(<([^>]+)>)/gi, '');
    }
    return !column.hide && (
      <div
        key={`${row.id}-${index}`}
        className={`table-cell${classes ?? ''} ${column.classes || ''}`}
        style={styles}
        onClick={() => handleClickRow(column)}
      >
        <div
          className={`cell-content ${column.onClick ? 'cursor-pointer' : ''}`}
          title={title || ''}
        >
          {
            (column.type === 'html' && !loading) ? (
              // eslint-disable-next-line react/no-danger
              <div dangerouslySetInnerHTML={{ __html: content }} />
            ) : content
          }
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className={`table-row  ${selected && 'selected'}`}>
        {columns.map((column, i) => renderColumn(column, i))}
      </div>
    );
  }

  return (
    <>
      { draggable ? (
        <Draggable key={row.id} draggableId={row.id} index={row.order} isDragDisabled={!draggable}>
          {draggableProvider => (
            <div
              {...draggableProvider.draggableProps}
              ref={draggableProvider.innerRef}
              className={`rowForm table-row ${selected && 'selected'}`}
            >
              {columns.map((column, i) => (column.type === 'drag'
                ? renderColumn(column, i, { drag: draggableProvider.dragHandleProps })
                : renderColumn(column, i)))}
            </div>
          )}
        </Draggable>
      ) : (
        <div className={`table-row  ${selected && 'selected'}`}>
          {columns.map((column, i) => renderColumn(column, i))}
        </div>
      )}
      { rowDetails && showDetails && (
        <div className='table-row details p-3' style={{ width }}>
          <div className='d-flex justify-content-end'>
            <X size={18} onClick={() => setShowDetails(false)} />
          </div>
          {rowDetails(row)}
        </div>
      )}
    </>
  );
};

TableRow.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  row: PropTypes.object,
  loading: PropTypes.bool,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onUnSelectRow: PropTypes.func,
  scrollRef: PropTypes.object,
  rowDetails: PropTypes.func,
  draggable: PropTypes.bool,
  onOpenPermissions: PropTypes.func,

};

TableRow.defaultProps = {
  row: null,
  onClick: null,
  loading: false,
  selected: false,
  scrollRef: null,
  onSelectRow: () => {},
  onUnSelectRow: () => {},
  onOpenPermissions: () => {},
  rowDetails: null,
  draggable: false,
};

export default TableRow;
