import React from 'react';
import PropTypes from 'prop-types';
import ReactECharts from 'echarts-for-react';
import SkeletonBarChart from 'components/Loading/skeletonBarChart';
import { formatNumber, getColorRange, getTranslation } from 'utils/functions';

const PerformanceComparatorChart = ({
  literals,
  literalsCommon,
  lang,
  show,
  isMonthly,
  graphData,
  dates,
  loading,
  interval,
}) => {
  const generateSeries = (namePrefix, style = {}) => graphData.map((kpi) => {
    const values = style.prev ? kpi.prev[style.source] : kpi[style.source];
    const auxDates = style.prev ? dates.prev : dates.actual;
    return ({
      type: 'line',
      name: `${namePrefix}${getTranslation(kpi.name, lang) || kpi.name}`,
      data: auxDates.map(month => values.find(val => val.x === month)?.y || null),
      symbol: 'circle',
      symbolSize: style.symbolSize || 12,
      lineStyle: {
        width: style.lineWidth || 4,
        type: style.lineType || 'solid',
      },
      showSymbol: true,
      connectNulls: true,
    });
  });

  if (loading) {
    return <SkeletonBarChart height='340px' />;
  }

  const series = [];

  if ((show.value && isMonthly) || !isMonthly) {
    series.push(...generateSeries('', { source: 'values' }));

    if (interval.comparator.startDate) {
      series.push(...generateSeries('Prev. ', { source: 'values', prev: true, lineType: 'dashed' }));
    }
  }

  if (show.forecast && isMonthly) {
    series.push(...generateSeries(`${literals.forecast} - `, { source: 'forecast' }));

    if (interval.comparator.startDate) {
      series.push(
        ...generateSeries(`Prev. ${literals.forecast} - `, { source: 'forecast', prev: true, lineType: 'dashed' }),
      );
    }
  }

  return (
    <ReactECharts
      key={`chart-${graphData.length}-${show.value}-${show.forecast}-${interval.startDate}-${interval.endDate}`}
      className='ml-5'
      option={{
        color: getColorRange(series.length),
        tooltip: {
          trigger: 'axis',
          valueFormatter: v => formatNumber(v, '-'),
        },
        grid: {
          containLabel: true,
          top: 10,
          bottom: 25,
          left: 'left',
        },
        responsive: true,
        legend: { show: false, display: false },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: dates.actual,
          axisLabel: {
            fontSize: '15px',
            formatter(value) {
              const [auxYear, month] = value.split('-');
              return `${literalsCommon.months[+month].slice(0, 3)} '${auxYear.slice(-2)}`;
            },
          },
        },
        yAxis: { type: 'value' },
        series,
      }}
      style={{ height: '400px' }}
    />
  );
};

PerformanceComparatorChart.propTypes = {
  lang: PropTypes.string.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  show: PropTypes.object.isRequired,
  isMonthly: PropTypes.bool.isRequired,
  graphData: PropTypes.array.isRequired,
  dates: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  interval: PropTypes.object.isRequired,
};

export default PerformanceComparatorChart;
