import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Button from 'components/Buttons/Button';
import KpiInputValue from 'routes/Performance/components/KpiInputValue';
import Separator from 'components/Separator';
import InputSelect from 'components/Inputs/inputSelect';
import InputPermission from 'components/Inputs/InputPermission';
import InputTextArea from 'components/Inputs/inputTextArea';
import { getTranslation } from 'utils/functions';
import { initPermission } from 'utils/functions/initPermissions';
import { KPI_AGGREGATION } from 'constants/kpis';
import { TOOL_PERFORMANCE } from 'constants/tools';
import { PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';

const PopupAddKpis = (props) => {
  const {
    literals,
    literalsCommon,
    lang,
    match,
    startup,
    currency,
    kpis,
    postKpisRelations,
    onClose,
  } = props;

  const aggregates = [
    { id: KPI_AGGREGATION.EOP, name: literals.endOfPeriod },
    { id: KPI_AGGREGATION.ACC, name: literals.accumulative },
    { id: KPI_AGGREGATION.AVG, name: literals.average },
  ];
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const aux = {};
    kpis.forEach((kpi) => {
      aux[kpi.id] = {
        id: kpi.id,
        target: null,
        aggregate: kpi.aggregate,
        notes: '',
        permission: initPermission(null, PERMISSION_SCOPES.STARTUP, startup, TOOL_PERFORMANCE),
      };
    });
    setForm(aux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setKpiTarget = (id, field, value) => {
    setForm(prev => ({ ...prev, [id]: { ...prev[id], [field]: value || null } }));
  };

  const submitKpis = async (ev) => {
    ev.preventDefault();
    setLoading(true);

    const params = Object.keys(form).map(kpi => ({
      id: kpi,
      target: form[kpi].target,
      aggregate: form[kpi].aggregate,
      notes: form[kpi].notes,
      permission: form[kpi].permission,
    }));
    await postKpisRelations('startup', match.params.id, params);
    onClose();
  };

  if (form === null) {
    return null;
  }

  return (
    <Popup onClose={onClose} title={literals.kpis}>
      <form onSubmit={submitKpis}>
        <div className='kpis-add-list'>
          {
            kpis.map((kpi, i) => (
              <div key={kpi.id}>
                <h6 className='fw-b'>{getTranslation(kpi.name, lang)}</h6>
                <div className='row'>
                  <div className='col-12 col-md-6'>
                    <KpiInputValue
                      preText={literals.target}
                      kpi={kpi}
                      value={form[kpi.id]?.target || ''}
                      currency={currency}
                      onChange={v => setKpiTarget(kpi.id, 'target', v)}
                    />
                  </div>
                  <div className='col-12 col-md-6'>
                    <InputSelect
                      preText={literals.aggregate}
                      options={aggregates}
                      placeholder={literals.aggregate}
                      onChange={v => setKpiTarget(kpi.id, 'aggregate', v)}
                      value={form[kpi.id]?.aggregate}
                      isRequired
                    />
                  </div>
                  <div className='col-12 col-md-6'>
                    <InputPermission
                      scope={{ type: PERMISSION_SCOPES.STARTUP, id: startup.id }}
                      element={{ type: PERMISSION_TYPES.KPI_RELATION, id: null }}
                      value={form[kpi.id]?.permission}
                      onChange={v => setKpiTarget(kpi.id, 'permission', v)}
                    />
                  </div>
                  <div className='col-12'>
                    <InputTextArea
                      preText={literals.notes}
                      placeholder={literals.notes}
                      value={form[kpi.id]?.notes}
                      onChange={v => setKpiTarget(kpi.id, 'notes', v)}
                      minHeight={50}
                    />
                  </div>
                </div>
                { i < kpis.length - 1 && <Separator /> }
              </div>
            ))
          }
        </div>
        <div className='buttons'>
          <Button text={literalsCommon.send} type='submit' loading={loading} />
        </div>
      </form>
    </Popup>
  );
};

PopupAddKpis.propTypes = {
  currency: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  kpis: PropTypes.array.isRequired,
  postKpisRelations: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupAddKpis;
