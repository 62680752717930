import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/functions';

const MeetingInfo = (props) => {
  const {
    meeting,
    literals,
  } = props;

  const parseDate = new Date(meeting.date);

  return (
    <div className='meeting-info'>
      <h6 className='fw-b'>{literals.meetingForm.title}</h6>
      <p className='output_value'>{meeting.title}</p>

      <h6 className='fw-b'>{literals.meetingForm.description}</h6>
      <div className='output_value' dangerouslySetInnerHTML={{ __html: meeting.description ? meeting.description : '-' }} />

      <div className='row'>
        <div className='col-12 col-md-6'>
          <h6 className='fw-b'>{literals.meetingForm.date}</h6>
          <p className='output_value'>{formatDate(parseDate, { time: true })}</p>
        </div>

        <div className='col-12 col-md-6'>
          <h6 className='fw-b'>{literals.meetingForm.time}</h6>
          <p className='output_value'>{meeting.time}</p>
        </div>
      </div>
    </div>
  );
};

MeetingInfo.propTypes = {
  literals: PropTypes.object.isRequired,
  meeting: PropTypes.object.isRequired,
};

export default MeetingInfo;
