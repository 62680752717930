import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Table from 'components/Table/';
import Button from 'components/Buttons/Button';
import InputText from 'components/Inputs/inputText';
import InputRichText from 'components/Inputs/inputRichText';
import OutputValue from 'components/Inputs/outputValue';
import Loading from 'components/Loading';
import { getFullName } from 'utils/functions';
import { Plus } from 'lucide-react';
import InputPermission from 'components/Inputs/InputPermission';
import { PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import { initPermission } from 'utils/functions/initPermissions';
import { TOOL_CAP_TABLE } from 'constants/tools';
import {
  getShareHolders, getSyndicates, postSyndicate, putSyndicate,
} from '../modules/actions';

const PopupCreateSyndicate = (props) => {
  const {
    literals,
    literalsCommon,
    syndicate,
    startup,
    onClose,
    tableRefreshFlag,
  } = props;

  const [loading, setLoading] = useState(false);
  const [showShareholders, setShowShareholder] = useState(false);
  const [syndicates, setSyndicates] = useState();
  const [syndicateForm, setSyndicateForm] = useState({
    name: syndicate.name ?? '',
    description: syndicate.description ?? '',
    shareholders: syndicate.shareholders ?? [],
  });
  const [permission, setPermission] = useState({});
  const { canEdit } = permission;

  // Get all syndicates
  useEffect(() => {
    (async () => {
      const auxSindicates = await getSyndicates(startup.id, 0, 0);
      setSyndicates(auxSindicates.items);
    })();
    setPermission(initPermission(syndicate?.id ? syndicate : null, PERMISSION_SCOPES.STARTUP, startup, TOOL_CAP_TABLE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createSyndicate = async () => {
    setLoading(true);
    const data = {
      ...syndicateForm,
      permission,
    };
    try {
      if (syndicate.id) {
        await putSyndicate(startup.id, syndicate.id, data);
      } else {
        await postSyndicate(startup.id, data);
      }
      tableRefreshFlag.increase();
      onClose();
    } catch {
      setLoading(false);
    }
  };

  const fetchShareholders = (page, size, search, filters, sort) => {
    return getShareHolders(startup.id, page, size, { search, filters, sort: sort || '+firstname' });
  };

  const columns = [
    {
      field: 'avatar', width: 45, title: (_, row) => getFullName(row), type: 'avatar',
    },
    {
      label: literalsCommon.name,
      preRender: (_, row) => getFullName(row),
      sortable: true,
    },
    {
      field: 'syndicate',
      label: literals.syndicate,
      preRender: v => (v ? (syndicates.find(s => s.id === v)?.name || '-') : '-'),
    },
  ];

  const renderPopup = () => {
    if (showShareholders) {
      if (!syndicates) {
        return <Loading mode='panel' hide={false} />;
      }

      return (
        <>
          <Table
            columns={columns}
            fetch={fetchShareholders}
            selectRows={syndicateForm.shareholders}
            onSelectRows={v => setSyndicateForm(prev => ({ ...prev, shareholders: v }))}
            pageSize={5}
          />
          <div className='mt-3 d-flex justify-content-end'>
            <Button
              text={`seleccionar (${syndicateForm.shareholders.length})`}
              onClick={() => setShowShareholder(false)}
            />
          </div>
        </>
      );
    }

    return (
      <>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <InputText
              preText={literalsCommon.name}
              placeholder={literals.name}
              onChange={v => setSyndicateForm(prev => ({ ...prev, name: v }))}
              value={syndicateForm.name}
              isDisabled={!canEdit}
              isRequired
            />
          </div>

          <div className='col-12 col-md-6'>
            <div className='inputUsers-box' onClick={() => (canEdit && setShowShareholder(true))}>
              <OutputValue
                preText={literals.shareholders}
                value={`${syndicateForm.shareholders.length} ${literals.shareholders}`}
              />
              <span className='inputUsers-button'>
                <Button disabled={!canEdit} className='btn-icon-square' icon={Plus} />
              </span>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <InputPermission
              scope={{ type: PERMISSION_SCOPES.STARTUP, id: startup.id }}
              element={{ type: PERMISSION_TYPES.SYNDICATE, id: syndicate.id }}
              value={permission}
              onChange={setPermission}
            />
          </div>
        </div>
        <InputRichText
          preText={literalsCommon.description}
          placeholder={literals.description}
          onChange={v => setSyndicateForm(prev => ({ ...prev, description: v }))}
          value={syndicateForm.description}
          minHeight={180}
          isDisabled={!canEdit}
        />
        {canEdit && (
          <div className='buttons'>
            <Button
              text={syndicate.id ? literalsCommon.edit : literalsCommon.create}
              onClick={createSyndicate}
              disabled={!syndicateForm.name}
              loading={loading}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <Popup
      title={syndicate.id ? `${literals.editSyndicate} ${syndicate.name ?? ''}` : literals.createSyndicate}
      onClose={onClose}
      size='large'
    >
      {renderPopup()}
    </Popup>
  );
};

PopupCreateSyndicate.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  syndicate: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  tableRefreshFlag: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupCreateSyndicate;
