import { PERMISSION_LEVELS, PERMISSION_MODES, PERMISSION_SCOPES } from 'constants/permissions';
import { startupUserPermissionLevel } from 'utils/functions';

export const initPermission = (object, scope, context, tool, toolLevel) => {
  let permission = {
    access: {},
    level: PERMISSION_LEVELS.MANAGE,
    mode: 'restricted',
    external: null,
    owner: null,
    toolLevel: PERMISSION_LEVELS.MANAGE,
  };

  if (toolLevel !== undefined) {
    permission.toolLevel = toolLevel;
  } else if (scope === PERMISSION_SCOPES.STARTUP) {
    if (object?.permission?.toolLevel !== undefined) {
      permission.toolLevel = object.permission.toolLevel;
    } else {
      if (!context || !tool) {
        throw new Error('initPermission: context and tool are required');
      }
      permission.toolLevel = startupUserPermissionLevel(context, tool);
    }
  } else {
    permission.toolLevel = PERMISSION_LEVELS.UNAUTHORIZED;
  }

  if (object?.permission && typeof object.permission === 'object') {
    permission = {
      ...permission,
      ...object.permission,
    };
  } else {
    permission.owner = window.USER_ID;
    if (permission.toolLevel < PERMISSION_LEVELS.MANAGE) {
      permission.mode = PERMISSION_MODES.PRIVATE;
    }
  }

  permission.canEdit = permission.level >= PERMISSION_LEVELS.EDIT;
  permission.canShare = permission.level === PERMISSION_LEVELS.MANAGE && (permission.toolLevel === PERMISSION_LEVELS.MANAGE || (permission.owner !== window.USER_ID));

  return permission;
};
