import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCaptableAction } from 'routes/Captable/modules/actions';
import { deleteOperationAction } from '../../Operation/modules/actions';
import CaptableOperationsComponents from '../components';
import { editOperationDraftAction, editOperationTimelineAction } from '../modules/actions';

const mapStateToProps = state => ({
  literalsCommon: state.i18n.literals.common,
  literals: state.i18n.literals.captable,
  user: state.session.user,
  captable: state.captable,
  currency: state.global.currency,
  startup: state.global.startup,
});

const mapDispatchToProps = dispatch => ({
  fetchCaptable: bindActionCreators(fetchCaptableAction, dispatch),
  editOperationTimeline: bindActionCreators(editOperationTimelineAction, dispatch),
  editOperationDraft: bindActionCreators(editOperationDraftAction, dispatch),
  deleteOperation: bindActionCreators(deleteOperationAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaptableOperationsComponents);
