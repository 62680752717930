import { AmplitudeApi } from 'utils/amplitude';
import { setPlatformLanguageAction } from 'components/LanguageSelector/modules/actions';
import { initPlatformAction } from 'store/globalState/global';
import { Identify } from '@amplitude/analytics-browser';
import { SessionService } from 'modules/session/sessionService';
import AccountsInstance from 'modules/accounts/repository';

function updateSessionUser(user) {
  window.USER_ID = user?.id;
  window.DECIMAL_SEPARATOR = user?.decimalSeparator || 'period';
  SessionService.saveSession({ token: user?.id })
    .then(() => SessionService.saveUser(user));
}

export default function validateSession(store) {
  return AccountsInstance.getSession()
    .then((user) => {
      const platform = { ...user.platform };
      const searchParams = new URLSearchParams(window.location?.search);
      const lang = searchParams.get('lang') || user.lang;
      let colors = searchParams.get('colors') || null;
      if (colors) {
        colors = JSON.parse(decodeURIComponent(colors));
        platform.colors.primary = colors.primary || platform.colors.primary;
        platform.colors.secondary = colors.secondary || platform.colors.secondary;
      }

      window.PLATFORM_COLORS = platform.colors;
      updateSessionUser(user);
      store.dispatch(setPlatformLanguageAction(lang, platform?.properties?.literals));
      store.dispatch(initPlatformAction(platform));

      let userVcland = searchParams.get('amplitudeFields') || null;
      if (userVcland) {
        userVcland = JSON.parse(decodeURIComponent(userVcland));
      }

      const identifyEvent = new Identify();
      if (userVcland) {
        identifyEvent.set('userName', userVcland.userName);
        identifyEvent.set('userType', userVcland.userType);
        identifyEvent.set('instance', userVcland.instance);
        identifyEvent.set('isInternal', userVcland.isInternal);
        identifyEvent.set('slandUser', {
          id: user.id,
          userName: `${user.firstname} ${user.lastname}`,
          instance: window.location.hostname,
        });
      } else {
        identifyEvent.set('userName', `${user.firstname} ${user.lastname}`);
        identifyEvent.set('instance', window.location.hostname);
      }

      AmplitudeApi.setUserId(userVcland?.userId || user?.id);
      AmplitudeApi.identify(identifyEvent);
      return true;
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
}
