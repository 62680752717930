import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import AccessDenied from 'assets/svg/access-denied.svg';
import { startupCanView } from 'utils/functions';
import { Navigate } from 'react-router-dom';
import { ROUTE_STARTUP_PATH } from 'routes';
import {
  TOOL_CAP_TABLE,
  TOOL_MENTORINGS,
  TOOL_PERFORMANCE,
  TOOL_PROFILE,
  TOOL_REPORTING,
  TOOL_MY_POSITION,
  TOOL_DATA_ROOM,
  TOOL_USERS,
} from 'constants/tools';

import './stylesStartup.scss';
import Panel from 'components/Panel';
import PanelBody from 'components/Panel/components/PanelBody';
import StartupPanel from 'components/StartupPanel';
import Alert from 'components/Alert';
import OutputErrors from 'components/Inputs/outputErrors';
import useDialog from 'components/Dialog/components/useDialog';
import RequestAccess from 'components/RequestAccess';
import Popup from 'components/Popup';
import { joinToStartup, leaveStartup } from 'routes/Onboarding/modules/actions';
import SummaryStartupPerformance from './startup/SummaryStartupPerformance';
import SummaryStartupMentorings from './startup/SummaryStartupMentorings';
import SummaryStartupCapTable from './startup/SummaryStartupCapTable';
import SummaryStartupReporting from './startup/SummaryStartupReporting';
import SummaryStartupMyPosition from './startup/SummaryStartupMyPosition';
import SummaryStartupDataroom from './startup/SummaryStartupDataroom';

export const tools = [
  TOOL_PROFILE,
  // TOOL_MY_POSITION,
  TOOL_CAP_TABLE,
  TOOL_PERFORMANCE,
  TOOL_MENTORINGS,
  TOOL_REPORTING,
  TOOL_DATA_ROOM,
  TOOL_USERS,
];

const HomeStartup = (props) => {
  const {
    literals,
    match,
    lang,
    startup,
    setGlobalProperties,
    currency,
  } = props;

  const [someRendered, setSomeRendered] = useState(false);
  const [requestAccessPopup, setRequestAccessPopup] = useState(false);
  const numTools = tools.filter(tool => startupCanView(startup, tool) && tool !== 'profile');
  const { dialog } = useDialog();

  const MESSAGE_OWNER_EXIST = 'messageOwnerExist';
  const MESSAGE_REQUIRE_VALIDATION = 'requireValidation';

  const SummaryComponents = {
    [TOOL_PROFILE]: {
      component: StartupPanel,
      props: {},
    },
    [TOOL_CAP_TABLE]: {
      component: SummaryStartupCapTable,
      props: {},
    },
    [TOOL_PERFORMANCE]: {
      component: SummaryStartupPerformance,
      props: {
        external: !startup.relation?.id,
      },
    },
    [TOOL_DATA_ROOM]: {
      component: SummaryStartupDataroom,
      props: {},
    },
    [TOOL_MENTORINGS]: {
      component: SummaryStartupMentorings,
      props: {},
    },
    [TOOL_REPORTING]: {
      component: SummaryStartupReporting,
      props: {},
    },
    [TOOL_MY_POSITION]: {
      component: SummaryStartupMyPosition,
      props: {},
    },
  };

  if (numTools.length === 1) {
    switch (numTools[0]) {
      case TOOL_PROFILE:
        return <Navigate to={ROUTE_STARTUP_PATH.setEditStartup(match.params.id)} />;
      case TOOL_MY_POSITION:
        return <Navigate to={ROUTE_STARTUP_PATH.setMyPosition(match.params.id)} />;
      case TOOL_CAP_TABLE:
        return <Navigate to={ROUTE_STARTUP_PATH.setCapTable(match.params.id)} />;
      case TOOL_PERFORMANCE:
        return <Navigate to={ROUTE_STARTUP_PATH.setPerformance(match.params.id)} />;
      case TOOL_MENTORINGS:
        return <Navigate to={ROUTE_STARTUP_PATH.setMentorings(match.params.id)} />;
      case TOOL_REPORTING:
        return <Navigate to={ROUTE_STARTUP_PATH.setReporting(match.params.id)} />;
      case TOOL_DATA_ROOM:
        return <Navigate to={ROUTE_STARTUP_PATH.setDataRoom(match.params.id)} />;
      case TOOL_USERS:
        return <Navigate to={ROUTE_STARTUP_PATH.setUsersStartup(match.params.id)} />;
      default:
        return null;
    }
  }

  const handleChangeStartupRelation = (prop, value) => {
    setGlobalProperties({
      currency,
      startup: {
        ...startup,
        relation: { ...startup.relation, [prop]: value },
      },
    });
  };

  const handleJoinToStartup = async (id, external, guest = false) => {
    if (guest) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            await joinToStartup(id, external, guest);
            if (guest) {
              handleChangeStartupRelation('guest', guest);
            } else {
              handleChangeStartupRelation('status', 'pending');
              await dialog({
                type: 'success',
                text: literals.requestSubmittedSuccessfully,
              });
            }
          } catch (error) {
            await dialog({
              type: 'error',
              text: <OutputErrors errors={error} text />,
            });
          }
        },
      });
    } else {
      setRequestAccessPopup(true);
    }
  };

  const handleLeaveStartup = async (id) => {
    await dialog({
      type: 'loading',
      execute: async () => {
        try {
          await leaveStartup(id);
          handleChangeStartupRelation('guest', false);
        } catch (error) {
          await dialog({
            type: 'error',
            text: <OutputErrors errors={error} text />,
          });
        }
      },
    });
  };

  const buttons = [];
  if (startup.relation.status !== 'pending') {
    buttons.push({
      text: literals.requestAccess,
      onClick: () => handleJoinToStartup(startup.id, false),
    });
  }
  if (!startup.relation.guest) {
    buttons.push({
      text: literals.addToPrehome,
      color: 'secondary',
      onClick: () => handleJoinToStartup(startup.id, false, true),
    });
  } else {
    buttons.push({
      text: literals.removeFromPrehome,
      color: 'secondary',
      onClick: () => handleLeaveStartup(startup.id),
    });
  }

  const canViewProfileAndDataroom = startupCanView(startup, TOOL_PROFILE) && startupCanView(startup, TOOL_DATA_ROOM);
  const RenderComponent = (item, tool) => {
    const { component, props: itemProps } = item;
    return React.createElement(component, {
      key: tool, startup, ...props, ...itemProps, lang,
    });
  };

  return (
    <Tool className='home-startup'>
      { !startup.relation?.id && (
        <Alert
          type='info'
          text={`${literals.startupGuestInfo} ${startup.relation?.status === 'pending' ? literals.pendingApprovalRequest : ''}`}
          buttons={buttons}
        />
      )}
      <div className='home-startup-panels'>
        {
          tools.map((tool) => {
            if (typeof SummaryComponents[tool] !== 'undefined' && startupCanView(startup, tool)) {
              if (!someRendered) {
                setSomeRendered(true);
              }
              if (tool === TOOL_PROFILE && canViewProfileAndDataroom) {
                if (startupCanView(startup, TOOL_PROFILE) && startupCanView(startup, TOOL_DATA_ROOM)) {
                  return (
                    <div key='profile-dataroom' className='row'>
                      <div className='col-12 col-md-6'>
                        {RenderComponent(SummaryComponents[TOOL_PROFILE], TOOL_PROFILE)}
                      </div>
                      <div className='col-12 col-md-6'>
                        {RenderComponent(SummaryComponents[TOOL_DATA_ROOM], TOOL_PROFILE)}
                      </div>
                    </div>
                  );
                }
              } else if (tool === TOOL_DATA_ROOM && canViewProfileAndDataroom) {
                return null;
              }

              return RenderComponent(SummaryComponents[tool], tool);
            }
            return null;
          })
        }
        {
          !someRendered && (
            <Panel>
              <PanelBody>
                <li className='panel-empty-home'>
                  <img src={AccessDenied} alt='' />
                  <p>{literals.noTools}</p>
                </li>
              </PanelBody>
            </Panel>
          )
        }
      </div>
      {
        requestAccessPopup && (
          <Popup title={literals.requestAccess} onClose={() => setRequestAccessPopup(false)}>
            <div className='requestAccess-wrapper'>
              <div className='requestAccess-next-step'>
                <div className='requestAccess-form-wrapper'>
                  <RequestAccess
                    mode='homeStartup'
                    startup={startup}
                    render={requestAccessPopup}
                    setRender={v => setRequestAccessPopup(() => (
                      [MESSAGE_OWNER_EXIST, MESSAGE_REQUIRE_VALIDATION].includes(v) ? v : false
                    ))}
                  />
                </div>
              </div>
            </div>

          </Popup>
        )
      }
    </Tool>
  );
};

HomeStartup.propTypes = {
  literals: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  setGlobalProperties: PropTypes.func.isRequired,
};

export default HomeStartup;
