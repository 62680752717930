import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelTab from 'components/Panel/components/PanelTab';
import Button from 'components/Buttons/Button';
import Loading from 'components/Loading';
import { literalTemplate } from 'utils/language';

const CARTA = 'carta';
const CAPDESK = 'capdesk';
const LEDGY = 'ledgy';

const PermissionsPopup = ({
  literals,
  platform,
  loading = null,
  request = null,
  onSubmit,
  onDelete,
  onClose,
}) => {
  const [activeTab, setActiveTab] = useState(request?.message || CARTA);

  const handleSubmit = async () => {
    onSubmit({
      service: 'import_captable',
      message: activeTab,
    });
  };

  const handleDelete = async () => {
    onDelete('import_captable');
  };

  const tabs = [
    { key: CARTA, name: CARTA.toUpperCase() },
    { key: CAPDESK, name: CAPDESK.toUpperCase() },
    { key: LEDGY, name: LEDGY.toUpperCase() },
  ];

  const renderTab = () => {
    let navigate = '';
    switch (activeTab) {
      case CARTA:
        navigate = literals.importCaptable.navigateCarta;
        break;
      case CAPDESK:
        navigate = literals.importCaptable.navigateCapdesk;
        break;
      case LEDGY:
        navigate = literals.importCaptable.navigateLedgy;
        break;
      default:
    }
    return (
      <>
        <ol>
          <li>{literalTemplate(literals.importCaptable.login, { PAGE: activeTab.toUpperCase() })}</li>
          <li>{navigate}</li>
          <li>{literals.importCaptable.addEmail}</li>
          <li>{literals.importCaptable.markCompleted}</li>
          <li>{literals.importCaptable.notification}</li>
        </ol>
        <div className='buttons mt-3'>
          <Button
            text={request ? literals.cancelRequest : literals.markCompleted}
            color={request ? 'danger' : 'primary'}
            onClick={request ? handleDelete : handleSubmit}
            loading={loading}
          />
        </div>
      </>
    );
  };

  return (
    <Popup title={literalTemplate(literals.invitePlatform, { PLATFORM: platform.name })} onClose={onClose}>
      <Panel active={activeTab} onTabSelect={setActiveTab}>
        <PanelHeader
          tabs={tabs}
          justify='between'
          activeTab={activeTab}
        />
        <PanelBody className='captable-import'>
          { loading === 'fetching' ? (
            <Loading mode='panel' hide={false} />
          ) : (
            tabs.map(tab => (
              <PanelTab key={tab.key}>
                {renderTab()}
              </PanelTab>
            ))
          )}
        </PanelBody>
      </Panel>
    </Popup>
  );
};

PermissionsPopup.propTypes = {
  platform: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  request: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PermissionsPopup;
