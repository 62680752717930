import React from 'react';
import PropTypes from 'prop-types';
import { FILES_BASE_URL } from 'constants/apiConf';
import './styles.scss';

const Avatar = (props) => {
  const {
    title = '',
    src = '',
    size = 30,
    className = '',
  } = props;

  const imgSrc = src?.startsWith('http')
    ? src
    : `${FILES_BASE_URL}${src}`;

  return (
    <div
      title={title}
      className={`avatar ${src ? '' : 'default'} ${className}`}
      style={{
        minWidth: size, width: size, height: size, fontSize: size,
      }}
    >
      { src
        ? <img src={imgSrc} alt='avatar' />
        : <div>{title ? title.charAt(0).toUpperCase() : ''}</div>
      }
    </div>
  );
};

Avatar.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
};

export default Avatar;
