/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { uploadFile } from 'modules/actions';
import { FILES_BASE_URL } from 'constants/apiConf';
import IOFileLoading from 'components/IOFileLoading';
import useDialog from 'components/Dialog/components/useDialog';
import './styles.scss';
import { X } from 'lucide-react';
import { cleanFilename, getIconFile } from 'utils/functions';
import { Link } from 'react-router-dom';

const InputFile = ({
  preText = '',
  postText = '',
  placeholder = '',
  isRequired = false,
  isDisabled = false,
  onChange,
  isPublic = false,
  value = null,
  error = null,
  multiple = false,
}) => {
  const [uploadError, setUploadError] = useState(false);
  const [uploading, setUploading] = useState(false);
  const fileRef = useRef(null);
  const { dialog } = useDialog();

  useEffect(() => {
    if (uploadError) {
      const showDialog = async () => {
        await dialog({
          type: 'error',
          text: 'The file failed to upload',
        });
        setUploadError(false);
      };
      showDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadError]);

  const changeValue = async () => {
    const fileData = fileRef.current.files[0];
    setUploading(fileRef.current.files[0]);
    if (uploadError) {
      setUploadError(false);
    }
    try {
      const fileInfo = await uploadFile(fileData, isPublic);
      onChange(multiple ? [...value, fileInfo] : fileInfo);
    } catch (errors) {
      setUploadError(errors);
    }
    setUploading(false);
  };

  const cleanValue = (e, i) => {
    e.preventDefault();
    e.stopPropagation();
    if (multiple) {
      const newValue = [...value];
      newValue.splice(i, 1);
      onChange(newValue);
    } else {
      onChange(null);
    }
  };

  const renderFile = (file, i) => {
    return (
      <Link key={file.id} title={file.name} className='file-info' to={`${FILES_BASE_URL}${file.path}`} target='_blank'>
        <div>
          { getIconFile(file.name) }
          {cleanFilename(file.name)}
        </div>
        {
          !isDisabled && (<X className='delete' size={18} onClick={e => cleanValue(e, i)} />)
        }
      </Link>
    );
  };

  return (
    <div className='input_file'>
      <div className={`pre_text ${error ? 'text_error' : ''}`}>
        {preText}
        {isRequired && <span className='is_required'>*</span>}
      </div>
      <div className='input_file-wrapper'>
        <input
          id='file-upload'
          className={`input ${error ? 'input_error' : ''}`}
          type='file'
          ref={fileRef}
          placeholder={placeholder}
          disabled={isDisabled || uploading}
          onChange={changeValue}
        />
        {
          multiple ? (
            (value && value.length > 0) && value.map((document, i) => renderFile(document, i))
          ) : (
            (value && value.id) && (renderFile(value, 0))
          )
        }
        { postText && (<div className='post_text' dangerouslySetInnerHTML={{ __html: postText }} />) }
      </div>
      {
        uploading && <IOFileLoading file={uploading} />
      }
    </div>
  );
};

InputFile.propTypes = {
  preText: PropTypes.string,
  postText: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  isPublic: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  error: PropTypes.object,
  multiple: PropTypes.bool,
};

export default InputFile;
