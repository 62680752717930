import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Table from 'components/Table';
import Button from 'components/Buttons/Button';
import { Eye } from 'lucide-react';
import { formatDate } from 'utils/functions';
import { fetchInternalMails } from '../modules/actions';

const EmailBotPopup = (props) => {
  const {
    literals,
    literalsCommon,
    onClose,
  } = props;

  const [emailDetails, setEmailDetails] = useState(false);

  const columns = [
    {
      field: 'createdAt',
      label: literalsCommon.date,
      width: 120,
      title: v => formatDate(v, { time: true }),
      preRender: v => formatDate(v),
    },
    {
      field: 'subject',
      label: literals.emails.subject,
    },
    {
      field: 'status',
      width: 180,
      label: literalsCommon.status,
    },
    {
      type: 'button',
      button: {
        text: literalsCommon.view,
        icon: Eye,
        onClick: row => setEmailDetails(row),
      },
    },
  ];


  return (
    <Popup title={emailDetails ? literals.emailDetails : literals.botEmails} onClose={onClose}>
      {!emailDetails ? (
        <Table
          columns={columns}
          fetch={fetchInternalMails}
        />
      ) : (
        <>
          <div className='d-flex justify-content-between'>
            <div>
              <h6 className='fw-b'>{literalsCommon.date}</h6>
              <div className='mb-3'>{formatDate(emailDetails.createdAt)}</div>
            </div>
            <div>
              <h6 className='fw-b'>{literalsCommon.date}</h6>
              <div className='mb-3'>{formatDate(emailDetails.createdAt)}</div>
            </div>
          </div>

          <h6 className='fw-b'>{literals.emails.sender}</h6>
          <div className='mb-3'>{emailDetails.sender}</div>

          <h6 className='fw-b'>{literalsCommon.status}</h6>
          <div className='mb-3'>{emailDetails.status}</div>

          <h6 className='fw-b'>{literals.log}</h6>
          <div dangerouslySetInnerHTML={{ __html: emailDetails.log }} />
          <div className='buttons'>
            <Button
              color='secondary'
              text={literalsCommon.back}
              onClick={() => setEmailDetails(false)}
            />
          </div>
        </>
      )}
    </Popup>
  );
};

EmailBotPopup.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EmailBotPopup;
