import FormsInstance from 'modules/forms/repository';
import {
  FETCH_STARTUP_QUESTIONS_INIT,
  FETCH_STARTUP_QUESTIONS_FAILURE,
  FETCH_STARTUP_QUESTIONS_SUCCESS,
  FETCH_MENTOR_QUESTIONS_INIT,
  FETCH_MENTOR_QUESTIONS_SUCCESS,
  FETCH_MENTOR_QUESTIONS_FAILURE,
  FETCH_INVESTOR_QUESTIONS_SUCCESS,
  FETCH_FUND_QUESTIONS_INIT,
} from './types';

export function fetchFormStartupAction() {
  return (dispatch) => {
    dispatch({
      type: FETCH_STARTUP_QUESTIONS_INIT,
    });
    return FormsInstance
      .getFormStartups()
      .then((response) => {
        dispatch({
          type: FETCH_STARTUP_QUESTIONS_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_STARTUP_QUESTIONS_FAILURE,
          payload: err,
        });
      });
  };
}

export function fetchFormMentorAction() {
  return (dispatch) => {
    dispatch({
      type: FETCH_MENTOR_QUESTIONS_INIT,
    });
    return FormsInstance
      .getFormMentor()
      .then((response) => {
        dispatch({
          type: FETCH_MENTOR_QUESTIONS_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_MENTOR_QUESTIONS_FAILURE,
          payload: err,
        });
      });
  };
}

export function fetchFormInvestorAction() {
  return (dispatch) => {
    dispatch({
      type: FETCH_INVESTOR_QUESTIONS_SUCCESS,
    });
    // return FormsInstance
    //   .getFormMentor()
    //   .then((response) => {
    //     dispatch({
    //       type: FETCH_MENTOR_QUESTIONS_SUCCESS,
    //       payload: response,
    //     });
    //   })
    //   .catch((err) => {
    //     dispatch({
    //       type: FETCH_MENTOR_QUESTIONS_FAILURE,
    //       payload: err,
    //     });
    //   });
  };
}

export function fetchFormFundAction() {
  return (dispatch) => {
    dispatch({
      type: FETCH_FUND_QUESTIONS_INIT,
    });
    // return FormsInstance
    //   .getFormFund()
    //   .then((response) => {
    //     dispatch({
    //       type: FETCH_FUND_QUESTIONS_SUCCESS,
    //       payload: response,
    //     });
    //   });
  };
}
