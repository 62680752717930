/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Table from 'components/Table';
import StatCard from 'components/Statistics/StatCard';
import UploadDocumentPopup from 'components/Signature/UploadDocumentPopup';
import DocumentStatusPopup from 'components/Signature/DocumentStatusPopup';
import { useSearchParams } from 'react-router-dom';
import { formatNumber } from 'utils/functions';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import {
  Download, Eye, LineChart, Upload,
} from 'lucide-react';
import { useCounter } from 'utils/customHooks';
import { useSelector } from 'react-redux';
import { fetchCaptableOptions } from '../modules/actions';
import PopupShareholderOptions from '../../shareHolders/components/routes/shareholderInfo/components/PopupShareholderOptions';

const PopupInfoOptions = (props) => {
  const {
    id,
    name,
    type,
    date,
    literals,
    literalsCommon,
    onClose,
    startup,
    currency,
  } = props;

  const [stats, setStats] = useState(false);
  const [options, setOptions] = useState(false);
  const [optionDetails, setOptionDetails] = useState(false);
  const [uploadDocument, setUploadDocument] = useState(false);
  const [signatureStatus, setSignatureStatus] = useState(false);
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const tableRefreshFlag = useCounter(0);
  const UNASSIGNED = id === 'unassigned';
  const platform = useSelector(state => state.global.platform);

  useEffect(() => {
    (async () => {
      const data = await fetchCaptableOptions(startup.id, date, {
        page: 0,
        size: 0,
        filters: {
          shareholder: UNASSIGNED ? null : id,
          type,
        },
        captable: captableId,
      });

      const auxStats = {
        capital: 0,
        number: 0,
        assigned: 0,
        vested: 0,
        exercised: 0,
      };
      data.items.forEach((item) => {
        auxStats.capital += item.capital;
        auxStats.number += item.number;
        auxStats.assigned += item.assigned;
        auxStats.vested += item.vested;
        auxStats.exercised += item.exercised;
      });

      data.items.sort((a, b) => (
        b.originalOperation.date.localeCompare(a.originalOperation.date)
      ));

      setOptions(data);
      setStats(auxStats);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let columns = [
    {
      field: 'name',
      growth: 1,
      label: literalsCommon.name,
    },
    {
      field: 'signature',
      width: 125,
      label: literalsCommon.document,
      preRender: doc => (doc
        ? (doc.signed ? literals.signed : literals.uploaded)
        : '-'),
    },
    {
      field: 'number',
      width: 125,
      label: literals.number,
      preRender: (v, row) => `${formatNumber(v, 0)} (${formatNumber(row.price, 0)}${currency.symbol})`,
    },
    {
      field: 'capital',
      width: 100,
      label: literals.capital,
      preRender: v => `${formatNumber(v, 0, { dec: 2 })}${currency.symbol}`,
    },
    {
      field: 'vested',
      width: 100,
      label: literals.vested,
      preRender: v => `${formatNumber(v, 0)}`,
    },
    {
      field: 'exercised',
      width: 100,
      label: literals.exercised,
      preRender: v => `${formatNumber(v, 0)}`,
    },
    {
      field: 'expirationDate',
      width: 125,
      label: literals.expirationDate,
      preRender: v => v || '-',
    },
    {
      field: 'vestingPlan',
      width: 125,
      label: literals.vestingPlan,
      preRender: v => v?.name ?? '-',
    },
    {
      field: 'originalOperation',
      width: 100,
      label: literals.operation,
      preRender: v => <span title={v.name}>{v.date}</span>,
    },
    {
      field: 'originalOperation',
      type: 'menu',
      buttons: [
        {
          icon: Eye,
          text: literalsCommon.view,
          onClick: row => setOptionDetails(row),
        },
        {
          icon: LineChart,
          text: literals.operation,
          to: operation => embedView(ROUTE_STARTUP_PATH.setCapTableOperation(startup.id, captableId, operation.id)),
          type: 'Link',
        },
        {
          icon: Upload,
          text: literals.uploadSignature,
          onClick: row => setUploadDocument(row),
          hidden: (_, row) => !platform.signaturit || UNASSIGNED || row.signature,
        },
        {
          icon: Download,
          text: literals.signatureStatus,
          onClick: row => setSignatureStatus(row.id),
          hidden: (_, row) => !platform.signaturit || UNASSIGNED || !row.signature,
        },
      ],
    },
  ];

  const removeColumns = [];

  if (type === 'phantom') {
    removeColumns.push('exercised');
  }

  if (UNASSIGNED) {
    removeColumns.push('exercised');
    removeColumns.push('vested');
  }

  if (UNASSIGNED || !platform.signaturit) {
    removeColumns.push('signature');
  }

  if (removeColumns.length) {
    columns = columns.filter(column => !removeColumns.includes(column.field));
  }

  const colWidth = type === 'phantom' ? 4 : 3;

  return (
    <Popup title={UNASSIGNED ? literals.unassigned : name} onClose={onClose} size='large'>
      <div className='row'>
        <div className={`col-12 col-md-${colWidth}`}>
          <StatCard title={literals.capital} value={`${formatNumber(stats.capital, 0)}${currency.symbol}`} loading={!stats} />
        </div>
        <div className={`col-12 col-md-${colWidth}`}>
          <StatCard title={literals.assigned} value={formatNumber(stats.assigned, 0)} loading={!stats} />
        </div>
        <div className={`col-12 col-md-${colWidth}`}>
          <StatCard title={literals.vested} value={`${formatNumber(stats.vested, 0)}`} loading={!stats} />
        </div>
        {
          type !== 'phantom' && (
            <div className='col-12 col-md-3'>
              <StatCard title={literals.exercised} value={`${formatNumber(stats.exercised, 0)}`} loading={!stats} />
            </div>
          )
        }
      </div>
      {options ? (
        <Table
          key='table-options-details'
          searcher={false}
          columns={columns}
          fetch={() => ({ items: options.items, total: options.total })}
          forceFetch={tableRefreshFlag.value}
          onClickRow={op => setOptionDetails(op)}
        />
      ) : (
        <Table
          searcher={false}
          columns={columns}
          fetch={null}
        />
      )}
      {optionDetails && (
        <PopupShareholderOptions
          literals={literals}
          literalsCommon={literalsCommon}
          currency={currency}
          onClose={() => setOptionDetails(false)}
          row={optionDetails}
        />
      )}
      {uploadDocument && (
        <UploadDocumentPopup
          type='captableOption'
          id={uploadDocument.id}
          name={uploadDocument.shareholder.name}
          startup={startup}
          shareholder={uploadDocument.shareholder.id}
          onClose={() => {
            setUploadDocument(false);
            tableRefreshFlag.increase();
          }}
        />
      )}
      {signatureStatus && (
        <DocumentStatusPopup
          type='captableOption'
          id={signatureStatus}
          onClose={() => setSignatureStatus(false)}
        />
      )}
    </Popup>
  );
};

PopupInfoOptions.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  type: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  startup: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
};

PopupInfoOptions.defaultProps = {
  name: '-',
};

export default PopupInfoOptions;
