import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PerformanceComponents from '../components';
import {
  deleteKpiBookmarkAction,
  fetchKpiRelationsAction,
  orderKpisAction,
  postKpiBookmarkAction,
  postKpiValuesAction,
  putKpiAction,
} from '../modules/actions';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.performance,
    literalsCommon: state.i18n.literals.common,
    performance: state.performance,
    platform: state.global.platform,
    user: state.session.user,
    startup: state.global.startup,
    lang: state.session.user.lang,
    currency: state.global.currency,
  };
}

const mapDispatchToProps = dispatch => ({
  putKpi: bindActionCreators(putKpiAction, dispatch),
  fetchKpiRelations: bindActionCreators(fetchKpiRelationsAction, dispatch),
  postKpiBookmark: bindActionCreators(postKpiBookmarkAction, dispatch),
  deleteKpiBookmark: bindActionCreators(deleteKpiBookmarkAction, dispatch),
  postKpiValues: bindActionCreators(postKpiValuesAction, dispatch),
  orderKpis: bindActionCreators(orderKpisAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceComponents);
