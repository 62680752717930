
import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import {
  Calendar, MessageCircle, FileUp, FileText, Users, Eye, Trash,
} from 'lucide-react';
import { formatDate, getFullName } from 'utils/functions';
import { ROUTE_ADMIN_PATH } from 'routes';
import { useCounter } from 'utils/customHooks';
import { fetchMentorings } from '../modules/actions';
import { statusTag } from './functions';

const ListMentorings = ({
  literalsMentoring, view, setPopUpInfo, onDelete,
}) => {
  const tableRefreshFlag = useCounter(0);

  const getMentorings = (page, size, search, filters) => {
    const filter = {
      [view.type]: view.data.id,
      search,
      ...filters,
    };
    return fetchMentorings(page, size, filter);
  };

  const renderAvatar = (row) => {
    if (view.type === 'startup') {
      return row.mentor.avatar;
    }
    return row.startup.logo;
  };

  const renderName = (row) => {
    if (view.type === 'startup') {
      return getFullName(row.mentor.user);
    }
    return row.startup.name;
  };

  const filters = [];

  const columns = [
    {
      field: 'id',
      type: 'avatar',
      label: (view.type === 'startup' ? 'Mentor' : 'Startup'),
      width: 45,
      title: (_, row) => renderName(row),
      preRender: (id, row) => renderAvatar(row),
    },
    {
      field: 'id', width: 150, label: '', preRender: (_, row) => renderName(row),
    },
    {
      field: 'startedBy', label: 'Started By',
    },
    {
      field: 'status', label: 'Status', preRender: status => statusTag(literalsMentoring, status), align: 'right',
    },
    {
      field: 'stats',
      label: <MessageCircle size={16} />,
      title: literalsMentoring.numberMessages,
      preRender: stats => stats.messages,
      width: 30,
      align: 'center',
    },
    {
      field: 'stats',
      label: <Calendar size={16} />,
      title: literalsMentoring.numberEvents,
      preRender: stats => stats.meetings,
      width: 30,
      align: 'center',
    },
    {
      field: 'stats',
      label: <FileUp size={16} />,
      title: literalsMentoring.numberDocuments,
      preRender: stats => stats.documents,
      width: 30,
      align: 'center',
    },
    {
      field: 'stats',
      label: <FileText size={16} />,
      title: literalsMentoring.numberReports,
      preRender: stats => stats.reports,
      width: 30,
      align: 'center',
    },
    {
      field: 'createdAt', width: 150, label: 'Created at', preRender: createdAt => (formatDate(createdAt, { time: true })),
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Eye,
          text: 'Info',
          onClick: row => setPopUpInfo(row),
        },
        {
          icon: Users,
          text: 'Entrar',
          to: id => ROUTE_ADMIN_PATH.setMentoring(id),
          type: 'Link',
        },
        {
          icon: Trash,
          text: 'Borrar',
          onClick: async (row) => {
            await onDelete(row.id);
            tableRefreshFlag.increase();
          },
        },
      ],
    },
  ];

  return (
    <div>
      <Table
        filters={filters}
        columns={columns}
        pageSize={10}
        fetch={getMentorings}
        forceFetch={tableRefreshFlag.value}
      />
    </div>
  );
};

ListMentorings.propTypes = {
  literalsMentoring: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  setPopUpInfo: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ListMentorings;
