import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import { formatDate, getFullName, stripTags } from 'utils/functions';
import Avatar from 'components/Avatar';
import { Pencil, PlusCircle, Trash } from 'lucide-react';
import useDialog from 'components/Dialog/components/useDialog';
import Button from 'components/Buttons/Button';
import EmptyData from 'assets/svg/empty_data.svg';
import ButtonsDotsMenu from 'components/Buttons/ButtonsDotsMenu';
import Popup from 'components/Popup';
import MentoringFormReport from './MentoringFormReport';

const MentoringReports = (props) => {
  const {
    literals,
    reports,
    onSubmit,
    onDelete,
  } = props;

  const [editReport, setEditReport] = useState(false);
  const [viewReport, setViewReport] = useState(false);
  const { dialog } = useDialog();

  const submitReport = async (id, form) => {
    await onSubmit(id, form);
    setEditReport(false);
  };

  const onDeleteConfirm = async (id) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literals.deleteReport,
    });
    if (confirm && onDelete) {
      onDelete(id);
    }
  };

  const renderReports = () => {
    if (reports.items.length) {
      return reports.items.map((report) => {
        const reportText = stripTags(report.text);
        return (
          <li className='mentoring-report box' key={report.id} onClick={() => setViewReport(report)}>
            {
              onDelete && (
                <div className='mentoring-report-actions'>
                  <ButtonsDotsMenu
                    buttons={[
                      { icon: Pencil, text: literals.edit, onClick: () => setEditReport(report) },
                      { icon: Trash, text: literals.delete, onClick: () => onDeleteConfirm(report.id) },
                    ]}
                  />
                </div>
              )
            }
            <h6 className='fw-b'>{formatDate(report.createdAt, { time: true })}</h6>
            <p>{reportText.length > 200 ? `${reportText.substr(0, 197)}...` : reportText}</p>
            <div className='d-flex align-items-center justify-content-end'>
              <Avatar
                title={getFullName(report.createdBy)}
                src={report.createdBy?.avatar?.path}
                size={25}
              />
              <h6 className='fw-b mb-0'>{getFullName(report.createdBy)}</h6>
            </div>
          </li>
        );
      });
    }

    return (
      <li className='panel-empty-message'>
        <img src={EmptyData} alt='' />
        <p>{literals.emptyReports}</p>
      </li>
    );
  };

  const renderLoading = (size) => {
    return Array.from(new Array(size)).map((e, i) => (
      <li className='listItemBox' key={i}>
        <Skeleton
          animation='wave'
          height={90}
          style={{ marginBottom: 5 }}
        />
      </li>
    ));
  };

  return (
    <>
      <div className='mentoring-reports'>
        {
          onSubmit && (
            <div className='panel-fix-rigth-button'>
              <Button
                mode='icon'
                onClick={() => setEditReport({})}
                text={literals.createReport}
                icon={PlusCircle}
              />
            </div>
          )
        }
        <ul className='mentoring-reports-list'>
          {
            reports ? (
              renderReports()
            ) : (
              renderLoading(5)
            )
          }
        </ul>
        {
          editReport !== false && (
            <MentoringFormReport
              onClose={() => setEditReport(false)}
              literals={literals}
              report={editReport}
              onSubmit={submitReport}
            />
          )
        }
        {
          viewReport !== false && (
            <Popup
              onClose={() => setViewReport(false)}
              size='large'
            >
              <h6 className='fw-b'>{`${viewReport.createdBy.firstname} - ${viewReport.createdBy.lastname} ${formatDate(viewReport.createdAt, { time: true })}`}</h6>
              <div className='output_value richTextHtml' dangerouslySetInnerHTML={{ __html: viewReport.text }} />
            </Popup>
          )
        }
      </div>
    </>
  );
};

MentoringReports.propTypes = {
  literals: PropTypes.object.isRequired,
  reports: PropTypes.object,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
};

MentoringReports.defaultProps = {
  reports: null,
  onSubmit: null,
  onDelete: null,
};

export default MentoringReports;
