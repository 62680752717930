import AccountsInstance from 'modules/accounts/repository';
import { SessionService } from 'modules/session/sessionService';
import { loadingAction } from '../../../store/globalState/global';

import {
  FETCH_SIGNUP_INIT,
  FETCH_SIGNUP_SUCCESS,
  FETCH_SIGNUP_FAILURE,
} from './types';

export function fetchSignupInit() {
  return {
    type: FETCH_SIGNUP_INIT,
  };
}

export function fetchSignupSuccess(success) {
  return {
    type: FETCH_SIGNUP_SUCCESS,
    payload: success,
  };
}

export function fetchSignupFailure(error) {
  return {
    type: FETCH_SIGNUP_FAILURE,
    payload: error,
  };
}

export default function fetchSignupAction(event, {
  firstname, lastname, email, lang,
}) {
  event.preventDefault();

  const signupForm = {
    firstname,
    lastname,
    email,
    lang,
  };

  return (dispatch) => {
    dispatch(loadingAction(true));
    dispatch(fetchSignupInit());
    return new Promise((resolve) => {
      SessionService.saveSession({ token: '123456789' })
        .then(() => {
          SessionService.saveUser(signupForm)
            .then(() => {
              dispatch(fetchSignupSuccess(signupForm));
              dispatch(loadingAction(false));
              resolve(true);
            });
        });
    });
  };
}

export function savePassword(data) {
  return AccountsInstance.savePasswordUser(data);
}
