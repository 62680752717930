import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel/components';
import PanelTab from 'components/Panel/components/PanelTab';
import Tool from 'components/Tool';
import {
  deleteMentoring,
  fetchMentoringsSummary,
  saveMentoringFeedback,
  saveMentoringStatus,
} from 'routes/Mentorings/modules/actions';

import { PlusCircle } from 'lucide-react';
import Loading from 'components/Loading';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Table from 'components/Table';
import Button from 'components/Buttons/Button';
import Alert from 'components/Alert';
import PopupProfile from 'components/PopupProfile';
import SearchPeople from 'assets/svg/search_people.svg';
import ArrowSearchMentorings from 'assets/svg/arrowSearchMentorings.svg';
import MentoringFormFeedback from 'routes/Mentoring/components/MentoringFormFeedback';
import { useCounter } from 'utils/customHooks';
import { ROUTE_MENTOR_PATH, ROUTE_STARTUP_PATH } from 'routes';
import { startupCanEdit } from 'utils/functions';
import { TOOL_MENTORINGS } from 'constants/tools';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fetchMentorings } from '../modules/actions';
import MentoringRow from './MentoringRow';
import MentoringInvitationRow from './MentoringInvitationRow';
import './styles.scss';

const Mentorings = (props) => {
  const {
    match,
    user,
    startup,
    literals,
  } = props;

  const [mentoringsSummary, setMentoringsSummary] = useState(false);
  const [formFeedback, setFormFeedback] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const tableRefreshFlag = useCounter(0);
  const location = useLocation();
  const navigate = useNavigate();

  const view = {
    type: location.pathname.startsWith('/startup') ? 'startup' : 'mentor',
    id: location.pathname.startsWith('/startup') ? match.params.id : user.mentor.id,
  };

  async function loadMentoringsSummary() {
    setMentoringsSummary(false);
    const response = await fetchMentoringsSummary(view.type, view.id);
    setMentoringsSummary(response);
  }

  const fetchMyMentorings = async (page, size, search) => {
    return fetchMentorings(view.type, view.id, {
      page,
      size,
      search,
      status: 'accepted,finished',
    });
  };

  const fetchPendingMentorings = async (page, size, search, filtersValue) => {
    let filterStatus = 'pending,rejected';
    if (filtersValue.status && filtersValue.status.length === 1) {
      filterStatus = filtersValue.status[0];
    }
    return fetchMentorings(view.type, view.id, {
      page,
      size,
      status: filterStatus,
    });
  };

  useEffect(() => {
    if (!mentoringsSummary) {
      loadMentoringsSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentoringsSummary]);

  const handleCloseFormFeedback = () => {
    setFormFeedback(false);
  };

  const handleSubmitFormFeedback = async (id, feedback) => {
    await saveMentoringFeedback(id, feedback);
    handleCloseFormFeedback();
    tableRefreshFlag.increase();
  };

  const handleSubmitStatus = async (id, status, message) => {
    if (status === 'cancel') {
      await deleteMentoring(id);
    } else {
      await saveMentoringStatus(id, status, message);
    }

    if (status === 'accepted') {
      if (view.type === 'startup') {
        navigate({ pathname: ROUTE_STARTUP_PATH.setMentoring(view.id, id) });
      } else {
        navigate({ pathname: ROUTE_MENTOR_PATH.setMentoring(id) });
      }
    } else {
      const newMentoringSummary = { ...mentoringsSummary };
      newMentoringSummary.status.pending -= 1;
      if (Object.keys(newMentoringSummary.status).includes(status)) {
        newMentoringSummary.status[status] += 1;
      }
      setMentoringsSummary(newMentoringSummary);
      tableRefreshFlag.increase();
    }
    return true;
  };

  const componentProps = {
    view,
    lang: user.lang,
    literals,
  };

  const mainTabs = [
    { key: 'companies', name: literals.yourMentorings },
    { key: 'invitations', name: `${mentoringsSummary?.status?.pending ?? ''} ${literals.pending}` },
  ];

  const totalMentorings = mentoringsSummary?.status?.accepted + mentoringsSummary?.status?.finished;
  const canEdit = view.type !== 'startup' || startupCanEdit(startup, TOOL_MENTORINGS);

  const tableSearchAction = [];
  if (canEdit) {
    tableSearchAction.push({
      type: 'Link',
      icon: PlusCircle,
      text: view.type === 'startup' ? literals.seekingMentors : literals.seekingStartups,
      to: view.type === 'startup' ? ROUTE_STARTUP_PATH.setMentoringsSearch(view.id) : ROUTE_MENTOR_PATH.MENTORINGS_SEARCH,
    });
  }

  const filters = [
    {
      id: 'status',
      type: 'list',
      title: literals.mentoringStatus,
      options: [
        { id: 'pending', name: literals.status.pending },
        { id: 'rejected', name: literals.status.rejected },
      ],
    },
  ];

  return (
    <Tool className='mentorings-wrapper'>
      <Panel>
        <PanelHeader
          title={literals.title}
          breadcrumb
          tabs={mainTabs}
        />
        <PanelBody>
          {
            mentoringsSummary ? (
              <>
                <PanelTab key='companies'>
                  {
                    totalMentorings > 0 ? (
                      <Table
                        mode='components'
                        component={MentoringRow}
                        componentProps={{ ...componentProps, onPopUpInfo: setFormFeedback }}
                        pageSize={10}
                        fetch={fetchMyMentorings}
                        forceFetch={tableRefreshFlag.value}
                        actions={tableSearchAction}
                      />
                    ) : (
                      <>
                        {
                          canEdit && (
                            <div className='panel-actions-right'>
                              <Link to={view.type === 'startup' ? ROUTE_STARTUP_PATH.setMentoringsSearch(view.id) : ROUTE_MENTOR_PATH.MENTORINGS_SEARCH}>
                                <Button icon={PlusCircle} text={view.type === 'startup' ? literals.seekingMentors : literals.seekingStartups} />
                              </Link>
                            </div>
                          )
                        }
                        <div className='mentorings-empty'>
                          <img className='arrow-search' src={ArrowSearchMentorings} alt='' />
                          <img src={SearchPeople} alt='' />
                          <h4>{view.type === 'startup' ? literals.startMentoringsMentors : literals.startMentoringsStartups }</h4>
                        </div>
                      </>
                    )
                  }
                  {
                    formFeedback && (
                      <MentoringFormFeedback
                        literals={literals}
                        mentoring={formFeedback}
                        onClose={handleCloseFormFeedback}
                        onSubmit={handleSubmitFormFeedback}
                      />
                    )
                  }
                </PanelTab>
                <PanelTab key='invitations'>
                  {
                    view.type === 'mentor' && user.mentor?.visible === false ? (
                      <Link to={ROUTE_MENTOR_PATH.EDIT_MENTOR}>
                        <Alert
                          type='warning'
                          text={literals.mentorNotVisible}
                        />
                      </Link>
                    ) : null
                  }
                  <Table
                    filters={filters}
                    mode='components'
                    component={MentoringInvitationRow}
                    componentProps={{ ...componentProps, onChangeStatus: handleSubmitStatus, canEdit }}
                    pageSize={10}
                    fetch={fetchPendingMentorings}
                    forceFetch={tableRefreshFlag.value}
                  />
                </PanelTab>
                {
                  showMoreInfo && (
                    <PopupProfile
                      type={view.type === 'startup' ? 'mentor' : 'startup'}
                      id={showMoreInfo}
                      onClose={() => setShowMoreInfo(false)}
                    />
                  )
                }
              </>
            ) : (
              <Loading hide={false} mode='tool' />
            )
          }
        </PanelBody>
      </Panel>
    </Tool>
  );
};

Mentorings.propTypes = {
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  startup: PropTypes.object,
  literals: PropTypes.object.isRequired,
};

Mentorings.defaultProps = {
  startup: null,
};

export default Mentorings;
