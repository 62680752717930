import React from 'react';
import PropTypes from 'prop-types';
import { ROLE_USER } from 'constants/roles';
import HomeStartup from './homeStartup';
import HomeMentor from './homeMentor';
import HomeAdmin from './homeAdmin';
import './styles.scss';

const Home = ({
  literals,
  user,
  lang,
  startup = null,
  companies,
  company,
  fetchCompany,
  fetchCaptable,
  match,
  literalsOnboarding,
  literalsAdmin,
  literalsPerformance,
  literalsReporting,
  literalsMyPosition,
  literalsCaptable,
  literalsDataroom,
  literalsCommon,
  fetchMentor,
  platform,
  currency,
  captable,
  setGlobalProperties,
}) => {
  return (
    <>
      {user?.role.includes(ROLE_USER) && (
        <HomeStartup
          literals={literals}
          literalsOnboarding={literalsOnboarding}
          literalsPerformance={literalsPerformance}
          literalsReporting={literalsReporting}
          literalsMyPosition={literalsMyPosition}
          literalsCaptable={literalsCaptable}
          literalsDataroom={literalsDataroom}
          literalsCommon={literalsCommon}
          companies={companies}
          company={company}
          fetchCompany={fetchCompany}
          fetchCaptable={fetchCaptable}
          match={match}
          lang={lang}
          startup={startup}
          platform={platform}
          currency={currency}
          captable={captable}
          setGlobalProperties={setGlobalProperties}
        />
      )}
      {user?.role?.length === 1 && user?.role[0] === 'mentor' && (
        <HomeMentor
          literals={literals}
          user={user}
          literalsOnboarding={literalsOnboarding}
          fetchMentor={fetchMentor}
        />
      )}
      {user?.role?.length === 1 && user?.role[0] === 'admin' && (
        <HomeAdmin platform={platform} literalsAdmin={literalsAdmin} />
      )}
    </>
  );
};

Home.propTypes = {
  literals: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  startup: PropTypes.object,
  companies: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  fetchCompany: PropTypes.func.isRequired,
  fetchCaptable: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  literalsOnboarding: PropTypes.object.isRequired,
  literalsAdmin: PropTypes.object.isRequired,
  literalsPerformance: PropTypes.object.isRequired,
  literalsReporting: PropTypes.object.isRequired,
  literalsMyPosition: PropTypes.object.isRequired,
  literalsCaptable: PropTypes.object.isRequired,
  literalsDataroom: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  fetchMentor: PropTypes.func.isRequired,
  platform: PropTypes.object.isRequired,
  setGlobalProperties: PropTypes.func.isRequired,
};

export default Home;
