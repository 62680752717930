import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel/components';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Button from 'components/Buttons/Button';
import Table from 'components/Table';
import useDialog from 'components/Dialog/components/useDialog';
import OutputErrors from 'components/Inputs/outputErrors';
import PermissionLabel from 'components/PermissionsPopup/PermissionLabel';
import { useNavigate } from 'react-router-dom';
import { useCounter } from 'utils/customHooks';
import { TOOL_CAP_TABLE } from 'constants/tools';
import { formatDate, formatNumber, startupUserPermissionLevel } from 'utils/functions';
import {
  Award, Bookmark, Pencil, PlusCircle, Trash,
} from 'lucide-react';
import { PERMISSION_LEVELS, PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import { getStartupCaptables } from '../modules/actions';
import EditCaptablePopup from './EditCaptablePopup';


const CaptableCaptables = (props) => {
  const {
    literals,
    literalsCommon,
    match,
    user,
    currency,
    startup,
    postStartupCaptable,
    putStartupCaptable,
    markStartupCaptableAsFavorite,
    deleteStartupCaptable,
  } = props;

  const defaultCaptable = { name: '', description: '', official: false };

  const [showForm, setShowForm] = useState(false);
  const tableRefreshFlag = useCounter(0);
  const { dialog } = useDialog();
  const navigate = useNavigate();
  const toolLevel = startupUserPermissionLevel(startup, TOOL_CAP_TABLE);

  const fetchStartupCaptables = (page, size, search, filters, sort) => {
    return getStartupCaptables(match.params.id, {
      page, size, search, filters, sort, summary: true,
    });
  };

  const handleDeleteCaptable = async (cap) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literals.deleteCaptableConfirm,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            await deleteStartupCaptable(match.params.id, cap);
            tableRefreshFlag.increase();
          } catch (error) {
            await dialog({
              type: 'error',
              text: <OutputErrors errors={error} text />,
            });
          }
        },
      });
    }
  };

  const handleOfficialCaptable = async (cap) => {
    const confirm = await dialog({
      type: 'confirm',
      text: literals.officialConfirm,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            await putStartupCaptable(match.params.id, cap.id, { ...cap, official: true });
            tableRefreshFlag.increase();
          } catch (error) {
            await dialog({
              type: 'error',
              text: <OutputErrors errors={error} text />,
            });
          }
        },
      });
    }
  };

  const handleFavoriteCaptable = async (cap) => {
    const confirm = await dialog({
      type: 'confirm',
      text: literals.favoriteConfirm,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            await markStartupCaptableAsFavorite(match.params.id, cap.id);
            tableRefreshFlag.increase();
          } catch (error) {
            await dialog({
              type: 'error',
              text: <OutputErrors errors={error} text />,
            });
          }
        },
      });
    }
  };

  const columns = [
    {
      field: 'name',
      label: literalsCommon.name,
      preRender: (name, row) => (
        <div className='d-flex align-items-center'>
          <span>{name}</span>
          { row.official && <Award size={18} className='ml-2' /> }
          { row.favorite === user.id && <Bookmark size={18} className='ml-2' /> }
          <PermissionLabel permission={row.permission} />
        </div>
      ),
    },
    {
      field: 'summary',
      label: literals.lastIssue,
      width: 120,
      preRender: ({ lastEquity }) => formatDate(lastEquity),
    },
    {
      field: 'summary',
      label: literals.shares,
      preRender: ({ shares }) => formatNumber(shares, 0),
    },
    {
      field: 'summary',
      label: literals.debts,
      preRender: ({ debt }) => formatNumber(debt, 0, { symbol: currency.symbol }),
    },
    {
      field: 'summary',
      label: literals.stockOptions,
      preRender: ({ stockOptions }) => formatNumber(stockOptions, 0),
    },
    {
      field: 'summary',
      label: literals.phantom,
      preRender: ({ phantoms }) => formatNumber(phantoms, 0),
    },
    {
      field: 'summary',
      label: literals.shareholders,
      width: 100,
      preRender: ({ shareholders }) => formatNumber(shareholders, 0),
    },
    {
      field: 'summary',
      label: literals.debtHolders,
      width: 100,
      preRender: ({ debtholders }) => formatNumber(debtholders, 0),
    },
    {
      field: 'summary',
      label: literals.pps,
      width: 120,
      preRender: ({ pps }) => formatNumber(pps, 0, { symbol: currency.symbol }),
    },
    {
      field: 'summary',
      label: literals.valuation,
      preRender: ({ valuation }) => formatNumber(valuation, 0, { symbol: currency.symbol }),
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Pencil,
          text: literalsCommon.edit,
          onClick: setShowForm,
        },
        {
          icon: Award,
          text: literals.official,
          onClick: handleOfficialCaptable,
          disabled: (_, row) => row.official,
          hidden: toolLevel < PERMISSION_LEVELS.EDIT,
        },
        {
          icon: Bookmark,
          text: literals.favorite,
          onClick: handleFavoriteCaptable,
          disabled: (_, row) => row.favorite,
          hidden: toolLevel !== PERMISSION_LEVELS.EXTERNAL,
        },
        {
          type: 'permission',
          scope: { type: PERMISSION_SCOPES.STARTUP, id: startup.id },
          element: PERMISSION_TYPES.CAPTABLE,
        },
        {
          icon: Trash,
          text: literalsCommon.delete,
          onClick: row => handleDeleteCaptable(row.id),
          disabled: (_, row) => row.official,
          hidden: (_, row) => row.permission.level < PERMISSION_LEVELS.EDIT,
        },
      ],
    },
  ];

  return (
    <Panel>
      <PanelHeader title={literals.captables} />
      <PanelBody>
        <Table
          columns={columns}
          fetch={fetchStartupCaptables}
          forceFetch={tableRefreshFlag.value}
          onClickRow={row => setShowForm(row)}
          actions={[{
            icon: PlusCircle,
            text: literalsCommon.create,
            onClick: () => setShowForm(defaultCaptable),
          }]}
        />
        <div className='buttons mt-4'>
          <Button
            color='secondary'
            text={literalsCommon.goBack}
            onClick={() => navigate(-1)}
          />
        </div>
        {
          showForm && (
            <EditCaptablePopup
              literals={literals}
              literalsCommon={literalsCommon}
              match={match}
              captable={showForm}
              startup={startup}
              tableRefreshFlag={tableRefreshFlag}
              postStartupCaptable={postStartupCaptable}
              putStartupCaptable={putStartupCaptable}
              deleteStartupCaptable={deleteStartupCaptable}
              onClose={() => setShowForm(false)}
            />
          )
        }
      </PanelBody>
    </Panel>
  );
};

CaptableCaptables.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  postStartupCaptable: PropTypes.func.isRequired,
  putStartupCaptable: PropTypes.func.isRequired,
  markStartupCaptableAsFavorite: PropTypes.func.isRequired,
  deleteStartupCaptable: PropTypes.func.isRequired,
};

export default CaptableCaptables;
