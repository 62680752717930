import StartupsCaptablesInstance from 'modules/startupsCaptables/repository';
import { apiDownloadFile } from 'modules/actions';
import {
  API_CAPTABLE_REPORT, API_SHARES_DISTRIBUTION_EXCEL, API_STARTUP_CAPTABLE, API_STATUTORY_BOOK_EXCEL,
} from 'modules/apiEndpoints';
import { formatErrors } from 'utils/functions';

export function fetchCaptableShares(startup, date, params) {
  return StartupsCaptablesInstance.getStartupCaptableShares(startup, date, params);
}

export function fetchCaptableDebts(startup, date, params) {
  return StartupsCaptablesInstance.getStartupCaptableDebts(startup, date, params);
}

export function fetchCaptableDebtsCapitalizations(startup, date, params) {
  return StartupsCaptablesInstance.getStartupCaptableDebtsCapitalizations(startup, date, params);
}

export function fetchStartupCaptableDebtsRepayments(startup, date, params) {
  return StartupsCaptablesInstance.getStartupCaptableDebtsRepayments(startup, date, params);
}

export function fetchCaptableOptions(startup, date, params) {
  return StartupsCaptablesInstance.getStartupCaptableOptions(startup, date, params);
}

export function fetchCaptableSummary(startup, type, date, opts) {
  return StartupsCaptablesInstance.getStartupCaptableSummary(startup, type, date, opts);
}

export function postOptionsVested(startup, option, date, params) {
  return StartupsCaptablesInstance.postStartupCaptableOptionVest(startup, option, date, params).then((response) => {
    return response;
  }).catch((error) => {
    throw formatErrors(error);
  });
}

export function deleteOptionsVested(startup, option, date) {
  return StartupsCaptablesInstance.deleteStartupCaptableOptionVest(startup, option, date).then((response) => {
    return response;
  }).catch((error) => {
    throw formatErrors(error);
  });
}

export function downloadCaptableExcel(id, captable, date = null) {
  return apiDownloadFile(API_STARTUP_CAPTABLE(id, captable, date), 'captable.xlsx');
}

export function downloadSharesDistributionExcel(id, captable, date) {
  return apiDownloadFile(API_SHARES_DISTRIBUTION_EXCEL(id, captable, date), 'shares_distribution.xlsx');
}

export function downloadStatutoryBookExcel(id, captable, date) {
  return apiDownloadFile(API_STATUTORY_BOOK_EXCEL(id, captable, date), 'statutory_book.xlsx');
}

export function downloadCaptableReport(id, captable, date, name = false) {
  return apiDownloadFile(API_CAPTABLE_REPORT(id, captable, date), `${name || 'captable_report'}.pdf`);
}
