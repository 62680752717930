import React from 'react';
import PropTypes from 'prop-types';
import URLIcon from 'assets/svg/URLIcon.svg';
import Crunchbase from 'assets/svg/crunchbase.svg';
import Facebook from 'assets/svg/facebook.svg';
import Linkedin from 'assets/svg/linkedin.svg';
import Twitter from 'assets/svg/twitter.svg';
import Other from 'assets/svg/otherSocialIcon.svg';
import './styles.scss';

const ICONS = {
  url: URLIcon,
  facebook: Facebook,
  twitter: Twitter,
  linkedin: Linkedin,
  crunchbase: Crunchbase,
  other: Other,
};

const SocialIcon = ({ type, link = '', size = 16 }) => {
  const Icon = ICONS[type] || null;

  const renderIcon = () => (Icon
    ? <img src={Icon} alt={`${type}-icon`} style={{ width: size, height: size }} />
    : <span />
  );

  return link ? (
    <a
      title={link}
      href={link.startsWith('https://') ? link : `https://${link}`}
      target='_blank'
      rel='noopener noreferrer'
      onClick={e => e.stopPropagation()}
    >
      {renderIcon()}
    </a>
  ) : renderIcon();
};

SocialIcon.propTypes = {
  type: PropTypes.string.isRequired,
  link: PropTypes.string,
  size: PropTypes.number,
};

export default SocialIcon;
