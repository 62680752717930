import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Button from 'components/Buttons/Button';
import InputDate from 'components/Inputs/inputDate';
import { formatDate, getTranslation } from 'utils/functions';
import { fetchKpiRelations } from 'routes/Performance/modules/actions';
import { SquareCheck, Search, Square } from 'lucide-react';
import { KPI_UNIT } from 'constants/kpis';
import SimpleTable from './SimpleTable';
import SimpleSelect from './SimpleSelect';
import './styles.scss';

const KPISummary = ({
  literals, literalsCommon, match, lang, currency, data, setData,
}) => {
  const [showPopup, setShowPopup] = useState(!data);
  const [selectedRow, setSelectedRow] = useState({ ...data, type: data.type || 'card' });

  const formatKpiValue = (v, unit) => `${v.toLocaleString()}${unit === KPI_UNIT.CURRENCY ? currency.symbol : ''}`;

  const fetchPerformanceKpis = async (page, size) => {
    return fetchKpiRelations('startup', match.params.id, page, size);
  };

  const columns = [
    {
      field: 'kpi',
      preRender: kpi => (selectedRow.id === kpi.id ? <SquareCheck size={18} /> : <Square size={18} />),
      width: 17,
    },
    {
      field: 'kpi',
      label: literals.kpi,
      preRender: kpi => getTranslation(kpi?.name, lang),
    },
    {
      field: 'value',
      label: literals.value,
      width: 100,
      preRender: (value, row) => (
        <>
          { value.actual ? formatKpiValue(value.actual, row.kpi.unit) : '-' }
          <br />
          <span className='fs-xs'>
            { value.previous ? formatKpiValue(value.previous, row.kpi.unit) : '-' }
          </span>
        </>
      ),
    },
    {
      field: 'target',
      label: literals.target,
      preRender: target => (target ? target.toLocaleString() : '-'),
      width: 75,
    },
    {
      field: 'value',
      label: literals.date,
      preRender: value => value.date ?? '-',
      width: 75,
    },
  ];

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  return (
    <div className='kpi-summary'>
      <div onClick={handleOpenPopup} className='kpi-summary-box simple-box'>
        <div className='simple-box-content'>
          {!selectedRow.relationId ? (
            <>
              <span className='fw-b'>{`${literals.kpiValue} | `}</span>
              <span style={{ fontStyle: 'italic' }}>{literals.selectKpi}</span>
              <Search className='ml-2' />
            </>
          ) : (
            <>
              <div>
                <span className='fw-b'>{`${literals.kpiValue} | `}</span>
                <span>{getTranslation(selectedRow?.name, lang)}</span>
              </div>
              {
                selectedRow.type === 'chart' ? (
                  <div>
                    <span className='chart fc-secondary'>
                      {`
                        ${literals[selectedRow.type]}
                        (${formatDate(selectedRow.from)} - ${formatDate(selectedRow.to)})
                      `}

                    </span>
                  </div>
                ) : null
              }
            </>
          )}
        </div>
      </div>
      {showPopup && (
        <Popup title={literals.kpi} onClose={() => setShowPopup(false)}>
          <h6 className='fw-b'>{literals.selectAKpi}</h6>
          <SimpleTable
            key='kpis-table'
            searcher={false}
            columns={columns}
            pageSize={5}
            fetch={fetchPerformanceKpis}
            selectedRow={selectedRow.relationId}
            onClickRow={row => setSelectedRow(prev => ({
              ...prev,
              relationId: row.id,
              id: row.kpi.id,
              name: row.kpi.name,
              value: row.value,
            }))}
          />
          <h6 className='mt-3 fw-b'>{literals.representKpi}</h6>
          <div className='row mb-0 '>
            <div className='col-12 col-md-4'>
              <SimpleSelect
                onChange={v => setSelectedRow(prev => ({ ...prev, type: v }))}
                value={selectedRow.type}
                options={[
                  { id: 'chart', name: literals.chart },
                  { id: 'card', name: literals.card },
                ]}
                isRequired
              />
            </div>
            {
              selectedRow.type === 'chart' ? (
                <>
                  <div className='col-12 col-md-4'>
                    <InputDate value={selectedRow.from} onChange={v => setSelectedRow(prev => ({ ...prev, from: v }))} />
                  </div>
                  <div className='col-12 col-md-4'>
                    <InputDate value={selectedRow.to} onChange={v => setSelectedRow(prev => ({ ...prev, to: v }))} />
                  </div>
                </>
              ) : null
            }
          </div>
          <div className='buttons'>
            <Button
              text={literalsCommon.save}
              onClick={() => {
                setData(selectedRow);
                setShowPopup(false);
              }}
              disabled={!selectedRow.id || (selectedRow.type === 'chart' && ['from', 'to'].some(k => !selectedRow[k]))}
            />
          </div>
        </Popup>
      )}
    </div>
  );
};

KPISummary.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  currency: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
};


export default KPISummary;
