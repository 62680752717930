import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelTab from 'components/Panel/components/PanelTab';
import Table from 'components/Table';
import Popup from 'components/Popup';
import useDialog from 'components/Dialog/components/useDialog';
import { getTranslation } from 'utils/functions';
import { useCounter } from 'utils/customHooks';
import {
  Eye, Pencil, Plus, Trash, ChevronDown, ChevronUp, ChevronRight, Earth,
} from 'lucide-react';
import {
  deleteKpi, fetchKpis, fetchKpisTemplates, getTemplate, deleteTemplate, editKpi,
} from '../modules/actions';
import FormKpi from './FormKpi';
import FormKpiTemplate from './FormKpiTemplate';
import PopupKpiInfo from './PopupKpiInfo';
import './styles.scss';

export const trendIcon = (type) => {
  switch (type) {
    case 'up': {
      return <ChevronUp size={18} />;
    }
    case 'equal': {
      return <ChevronRight size={18} />;
    }
    default:
      return <ChevronDown size={18} />;
  }
};

const AdminPerformance = (props) => {
  const {
    lang,
    literals,
    literalsCommon,
    currency,
  } = props;

  const tableRefreshFlag = useCounter(0);
  const [editKpiPopup, setEditKpiPopup] = useState(false);
  const [editKpiTemplate, setEditKpiTemplate] = useState(false);
  const [showKpiInfo, setShowKpiInfo] = useState(null);
  const { dialog } = useDialog();

  const handleDeleteKpi = async (kpi) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literals.questionDeleteKpi,
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteKpi(kpi.id);
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const handleDeleteTemplate = async (id) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literals.questionDeleteTemplate,
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteTemplate(id);
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const handleScopeToAll = async (kpi) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literals.questionScopeToAll,
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            const data = { ...kpi, scope: kpi.scope.type, owner: 'all' };
            await editKpi(kpi.id, data);
          } catch (e) {
            await dialog({
              type: 'confirmDanger',
              text: literalsCommon.genericError,
            });
          }
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const selectKpisTemplate = async (row) => {
    if (row.kpis.length) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await getTemplate(row.id).then(data => setEditKpiTemplate(data));
        },
      });
    } else {
      setEditKpiTemplate(row);
    }
  };

  const columnsKpis = [
    {
      field: 'name', label: literalsCommon.name, preRender: name => getTranslation(name, lang), grow: 2,
    },
    { field: 'category', label: literals.category, preRender: v => literals[v] },
    { field: 'periodicity', label: literals.periodicity, preRender: v => literals[v] },
    {
      field: 'positive', label: literals.positive, preRender: positive => trendIcon(positive), width: 75, align: 'center',
    },
    { field: 'scope', label: literals.scope, preRender: scope => `${literalsCommon[scope.type]}${scope.owner ? ` | ${scope.owner}` : ''}` },
    { field: 'unit', label: literals.unit, preRender: v => literals[v] },
    { field: 'min', label: literals.minMax, preRender: (min, row) => (`${row.min ?? '-'} | ${row.max ?? '-'}`) },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Eye,
          text: literalsCommon.view,
          onClick: row => setShowKpiInfo(row),
        },
        {
          icon: Pencil,
          text: literalsCommon.edit,
          onClick: (row) => { setEditKpiPopup(row); },
        },
        {
          icon: Earth,
          text: literals.changeScopeToAll,
          onClick: handleScopeToAll,
          hidden: (id, { scope }) => scope.owner === 'all',
        },
        {
          icon: Trash,
          text: literalsCommon.delete,
          onClick: row => handleDeleteKpi(row),
        },
      ],
    },
  ];

  const columnsTemplates = [
    {
      field: 'name', grow: 2, label: literalsCommon.name, preRender: name => getTranslation(name, lang),
    },
    {
      field: 'scope', width: 50, label: literals.scope, preRender: scope => `${literalsCommon[scope.type]}${scope.owner ? ` | ${scope.owner}` : ''}`,
    },
    {
      field: 'kpis', label: literals.kpis, preRender: kpis => kpis.length, width: 30,
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Pencil,
          text: literals.add,
          onClick: row => selectKpisTemplate(row),
        },
        {
          icon: Trash,
          text: literalsCommon.delete,
          onClick: row => handleDeleteTemplate(row.id),
        },
      ],
    },
  ];

  const onSubmit = () => {
    setEditKpiPopup(false);
    setEditKpiTemplate(false);
    tableRefreshFlag.increase();
  };

  const filters = [
    {
      id: 'scope.owner',
      type: 'selectUnique',
      title: literals.owner,
      options: [
        { id: 'all', name: 'Admin' },
        { id: 'not:all', name: 'Startup' },
      ],
    },
  ];

  return (
    <Tool>
      <Panel margin={0} clean>
        <PanelHeader
          title={literals.title}
          tabs={[
            { key: 'kpis', name: literals.kpis },
            { key: 'templates', name: literals.templates },
          ]}
        />
        <PanelBody>
          <PanelTab key='kpis'>
            <Table
              key='table_kpis'
              columns={columnsKpis}
              filters={filters}
              pageSize={10}
              fetch={fetchKpis}
              forceFetch={tableRefreshFlag.value}
              onClickRow={row => setShowKpiInfo(row)}
              actions={[
                { icon: Plus, text: literals.createKpi, onClick: () => setEditKpiPopup({}) },
              ]}
            />
          </PanelTab>
          <PanelTab key='templates'>
            <Table
              key='table_templates'
              columns={columnsTemplates}
              pageSize={10}
              fetch={fetchKpisTemplates}
              forceFetch={tableRefreshFlag.value}
              onClickRow={row => selectKpisTemplate(row)}
              actions={[
                { icon: Plus, text: literals.createTemplate, onClick: () => setEditKpiTemplate({ kpis: [] }) },
              ]}
            />
          </PanelTab>
        </PanelBody>
      </Panel>
      {
        editKpiPopup && (
          <Popup size='large' onClose={() => setEditKpiPopup(false)}>
            <FormKpi {...editKpiPopup} onSubmit={onSubmit} literals={literals} literalsCommon={literalsCommon} />
          </Popup>
        )
      }
      {
        editKpiTemplate && (
          <Popup title={literals.createTemplate} onClose={() => setEditKpiTemplate(false)}>
            <FormKpiTemplate {...editKpiTemplate} literals={literals} literalsCommon={literalsCommon} onSubmit={onSubmit} />
          </Popup>
        )
      }
      {
        showKpiInfo && (
          <PopupKpiInfo
            literals={literals}
            literalsCommon={literalsCommon}
            kpi={showKpiInfo}
            lang={lang}
            currency={currency}
            onClose={() => setShowKpiInfo(null)}
          />
        )
      }
    </Tool>
  );
};

AdminPerformance.propTypes = {
  lang: PropTypes.string.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
};

export default AdminPerformance;
