import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelTab from 'components/Panel/components/PanelTab';
import { useCounter } from 'utils/customHooks';
import { canEditCaptable } from 'utils/functions/canEditCaptable';
import PanelDebtsOutstanding from './PanelDebtsOutstanding';
import './styles.scss';
import PanelDebtsCapitalized from './PanelDebtsCapitalized';
import PanelDebtsRepaid from './PanelDebtsRepaid';
import PopupInfoDebts from './routes/Securities/components/PopupInfoDebts';

const PanelDebt = (props) => {
  const {
    literals, literalsCommon, currency, captable, date, submitOperation,
  } = props;

  const [showDebtDetails, setShowDebtDetails] = useState(false);
  const tableRefreshFlag = useCounter(0);

  useEffect(() => {
    if (date) {
      tableRefreshFlag.increase();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);


  let canEdit = canEditCaptable(captable);
  if (date) {
    canEdit = canEdit && captable?.operations[0]?.date === date;
  }

  const tabs = [
    { key: 'outstanding', name: literals.outstanding },
    { key: 'capitalized', name: literals.capitalized },
    { key: 'repaid', name: literals.repaid },
  ];

  const commonProps = {
    literals,
    literalsCommon,
    currency,
    captable,
    date,
    canEdit,
    forceFetch: tableRefreshFlag.value,
    setShowDebtDetails,
  };
  return (
    <Panel>
      <PanelHeader title={literals.debts} tabs={tabs} />
      <PanelBody>
        <PanelTab key='outstanding'>
          <PanelDebtsOutstanding
            {...commonProps}
            submitOperation={submitOperation}
          />
        </PanelTab>
        <PanelTab key='capitalized'>
          <PanelDebtsCapitalized {...commonProps} />
        </PanelTab>
        <PanelTab key='repaid'>
          <PanelDebtsRepaid {...commonProps} />
        </PanelTab>
      </PanelBody>
      { showDebtDetails && (
        <PopupInfoDebts
          literals={literals}
          literalsCommon={literalsCommon}
          currency={currency}
          captable={captable}
          date={date}
          onClose={() => setShowDebtDetails(false)}
          shareholder={showDebtDetails.shareholder}
          debt={showDebtDetails.debt}
        />
      )}
    </Panel>
  );
};

PanelDebt.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  date: PropTypes.string,
  submitOperation: PropTypes.func.isRequired,
};

PanelDebt.defaultProps = {
  date: new Date().toISOString().split('T')[0],
};

export default PanelDebt;
