import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputDate from 'components/Inputs/inputDate';
import { X, Calendar } from 'lucide-react';
import { Skeleton } from '@mui/material';

const DateSelector = ({
  date = null,
  literals,
  popup = null,
  setPopup,
  captable,
  onChange = () => {},
}) => {
  const POPUP_TIMELINE = 'timeline';

  const [operation, setOperation] = useState(captable?.operations?.[0] || false);

  const handleChange = (newOperation) => {
    setOperation(newOperation);
    setPopup(null);
    if (typeof onChange === 'function') {
      onChange(newOperation.date);
    }
  };

  useEffect(() => {
    if (date && date !== operation.date) {
      setOperation(captable.operations.find(op => op.date === date) || { date });
    } else if (!date && captable?.operations?.length) {
      onChange(captable.operations[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    onChange(captable?.operations?.[0]?.date || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captable.selected]);

  const renderOperations = (auxOperation) => {
    return (
      <div
        key={auxOperation.id}
        className='row-date'
        onClick={() => handleChange(auxOperation)}
      >
        <div className={`row-date-left ${auxOperation.date === operation.date ? 'selected' : ''}`}>
          <div className='row-icon'><Calendar /></div>
          <div className='row-content'>
            <h6 className='date-name' title={auxOperation.name}>{auxOperation.name}</h6>
            <p className='date-value fs-small'>{auxOperation.date}</p>
          </div>
        </div>
      </div>
    );
  };

  const name = operation.name?.length > 15 ? `${operation.name.slice(0, 15)}...` : operation.name;
  const renderText = operation
    ? `${name ? `${name} - ` : ''} ${operation.date}`
    : '';

  return (
    <div className='operation-timeline'>
      <div className='timelineButton-box' onClick={() => setPopup(POPUP_TIMELINE)}>
        <Calendar />
        { captable.loaded
          ? <span>{renderText}</span>
          : <Skeleton width='80px' />
        }
      </div>
      {
        popup === POPUP_TIMELINE && (
          <div className='timeline-box'>
            <div className='timeline-box-header'>
              <div className='closeOption'>
                <X size={18} color='white' onClick={() => setPopup(null)} />
              </div>
            </div>
            <div className='timeline-box-body'>
              <div className='dateSelect'>
                <InputDate
                  preText='Date'
                  value={date}
                  onChange={handleChange}
                />
              </div>
              <div className='timelineSelect'>
                <div className='timeline-value'>{literals.timeline}</div>
              </div>
              <div className='dates simple-scrollbar'>
                {captable.operations.map(renderOperations)}
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

DateSelector.propTypes = {
  literals: PropTypes.object.isRequired,
  date: PropTypes.string,
  captable: PropTypes.object.isRequired,
  popup: PropTypes.string,
  setPopup: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

export default DateSelector;
