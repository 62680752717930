import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import StatCard from 'components/Statistics/StatCard';
import StartupCaptableDate from 'components/StartupCaptableDate/components';
import { useSearchParams } from 'react-router-dom';
import { formatNumber, getColorRange } from 'utils/functions';
import { AmplitudeApi } from 'utils/amplitude';
import { canEditCaptable } from 'utils/functions/canEditCaptable';
import { initializeCaptable } from '../modules/actions';
import { fetchCaptableSummary } from './routes/Securities/modules/actions';
import EmptyCaptable from '../../../components/EmptyCaptable/EmptyCaptable';
import PanelShareholder from './PanelShareholder';
import PanelShareClass from './PanelShareClass';
import PanelOptions from './PanelOptions';
import PanelPPS from './PanelPPS';
import PanelCaptable from './PanelCaptable';
import PanelDebt from './PanelDebts';
import './styles.scss';

export const getPieOptions = (data, literals) => {
  const newData = data.sort((a, b) => b.value - a.value).slice(0, 5);
  const others = {
    name: literals.others,
    value: newData.reduce((acumulador, actual) => acumulador - actual.value, 100).toFixed(2),
  };
  if (others.value > 0.02) {
    newData.push(others);
  }

  return {
    color: getColorRange(newData.length),
    tooltip: {
      trigger: 'item',
      valueFormatter: value => `${value}%`,
    },
    series: [
      {
        type: 'pie',
        radius: ['50%', '70%'],
        data: newData,
        label: {
          alignTo: 'edge',
          formatter: '{name|{b}}\n{percent|{c}%}',
          edgeDistance: 20,
          fontSize: 14,
          fontWeight: 'bold',
          rich: {
            percent: {
              fontSize: 12,
              color: '#999',
            },
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };
};

const CapTable = (props) => {
  const {
    match,
    captable,
    fetchCaptable,
    literals,
    literalsCommon,
    currency,
    startup,
    platform,
    submitOperation,
  } = props;

  const [date, setDate] = useState(null);
  const [stats, setStats] = useState(null);
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;

  useEffect(() => {
    initializeCaptable(fetchCaptable, captable, match.params.id, captableId);
    if (captable.loaded && !date && captable.operations.length > 0) {
      setDate(captable.operations[0].date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captable, captableId]);

  useEffect(() => {
    if (captable?.selected?.id) {
      AmplitudeApi.logEvent('startup.captable.summary.view', {
        captable: captable?.selected?.id,
      });
    }
  }, [captable?.selected?.id]);

  useEffect(() => {
    if (date) {
      (async () => {
        setStats(null);
        const response = await fetchCaptableSummary(match.params.id, 'all', date, { captable: captableId });
        setStats(response);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, captable?.selected?.valuationDiluted]);

  if (captable.loaded && !captable?.operations?.length) {
    return (
      <EmptyCaptable
        literals={literals}
        literalsCommon={literalsCommon}
        match={match}
        startup={startup}
        captable={captable}
        platform={platform}
        fetchCaptable={fetchCaptable}
      />
    );
  }

  let canEdit = canEditCaptable(captable);
  if (date) {
    canEdit = canEdit && captable?.operations[0]?.date === date;
  }

  return (
    <Tool>
      <StartupCaptableDate literals={literals} startup={startup} captable={captable} date={date} onChange={setDate} />
      <div className='row mt-3'>
        <div className='col-12 col-md-4'>
          <StatCard title={literals.totalCapitalRaised} value={formatNumber(stats?.capital, '-', { symbol: currency.symbol, dec: 2 })} loading={!stats} />
        </div>
        <div className='col-12 col-md-4'>
          <StatCard title={literals.totalInvestors} value={formatNumber(stats?.shareholders)} loading={!stats} />
        </div>
        <div className='col-12 col-md-4'>
          <StatCard title={literals.valuation} value={formatNumber(stats?.valuation, '-', { symbol: currency.symbol, dec: 2 })} loading={!stats} />
        </div>
        <div className='col-12 col-md-4'>
          <StatCard
            title={literals.ppsLastReport}
            value={formatNumber(stats?.pps, '-', { symbol: currency.symbol, dec: 2 })}
            tooltip={formatNumber(stats?.pps, '-', { symbol: currency.symbol, dec: 8 })}
            loading={!stats}
          />
        </div>
        <div className='col-12 col-md-4'>
          <StatCard title={literals.totalDebtOutstanding} value={formatNumber(stats?.debt, '-', { symbol: currency.symbol, dec: 2 })} loading={!stats} />
        </div>
        <div className='col-12 col-md-4'>
          <StatCard title={literals.debtHolders} value={formatNumber(stats?.debtholders)} loading={!stats} />
        </div>

        <div className='col-12 col-md-6'>
          <PanelShareholder
            literals={literals}
            literalsCommon={literalsCommon}
            stats={stats}
            currency={currency}
            date={date}
          />
        </div>
        <div className='col-12 col-md-6'>
          <PanelShareClass
            stats={stats}
            literals={literals}
            literalsCommon={literalsCommon}
            captable={captable?.selected?.id}
            canEdit={canEdit}
          />
        </div>
        <div className='col-12 col-md-6'>
          <PanelOptions
            stats={stats}
            literals={literals}
            literalsCommon={literalsCommon}
            currency={currency}
            startup={startup}
            date={date}
            captable={captable}
            canEdit={canEdit}
            submitOperation={submitOperation}
          />
        </div>
        <div className='col-12 col-md-6'>
          <PanelPPS
            stats={stats}
            literals={literals}
            currency={currency}
          />
        </div>
        <div className='col-12'>
          <PanelCaptable
            literals={literals}
            literalsCommon={literalsCommon}
            currency={currency}
            date={date}
            captable={captable}
            submitOperation={submitOperation}
          />
        </div>
        <div className='col-12'>
          <PanelDebt
            literals={literals}
            literalsCommon={literalsCommon}
            currency={currency}
            date={date}
            captable={captable}
            submitOperation={submitOperation}
          />
        </div>
      </div>
    </Tool>
  );
};

CapTable.propTypes = {
  match: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
  fetchCaptable: PropTypes.func.isRequired,
  submitOperation: PropTypes.func.isRequired,
};

export default CapTable;
