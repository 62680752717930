import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import ReactECharts from 'echarts-for-react';
import SkeletonDoughnutChart from 'components/Loading/skeletonDoughnutChart';
import PopupInfoShareholder from './routes/shareHolders/components/PopupInfoShareholder';
import { getPieOptions } from '.';
import './styles.scss';

const PanelShareholder = ({
  literals,
  literalsCommon,
  stats = null,
  currency,
  date = new Date().toISOString().split('T')[0],
}) => {
  const [showData, setShowData] = useState();
  let graphByShareholders = null;
  if (stats !== null) {
    graphByShareholders = stats.aggregations.sharesByShareholder.map(item => ({ id: item.id, name: item.name, value: Number(item.value.toFixed(2)) }));
  }

  const handleOnClickChart = useCallback(({ data }) => setShowData(data.id), []);
  return (
    <Panel>
      <PanelHeader title={literals.byShareholder} />
      <PanelBody className='panel-summary-shareholder'>
        { graphByShareholders
          ? (
            <ReactECharts
              option={getPieOptions(graphByShareholders, literals)}
              onEvents={{ click: handleOnClickChart }}
            />
          )
          : <SkeletonDoughnutChart className='mt-3' size={220} />
        }
      </PanelBody>
      {
        showData && (
          <PopupInfoShareholder
            literals={literals}
            literalsCommon={literalsCommon}
            currency={currency}
            id={showData}
            date={date}
            onClose={() => setShowData()}
          />
        )
      }
    </Panel>
  );
};

PanelShareholder.propTypes = {
  literals: PropTypes.object.isRequired,
  stats: PropTypes.object,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  date: PropTypes.string,
};

export default PanelShareholder;
