import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchKpiRelationsAction, postKpiValueAction, deleteKpiValueAction } from 'routes/Performance/modules/actions';
import PerformancePending from '../components';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.performance,
    literalsCommon: state.i18n.literals.common,
    lang: state.session.user.lang,
    performance: state.performance,
    currency: state.global.currency,
    startup: state.global.startup,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchKpiRelations: bindActionCreators(fetchKpiRelationsAction, dispatch),
    postKpiValue: bindActionCreators(postKpiValueAction, dispatch),
    deleteKpiValue: bindActionCreators(deleteKpiValueAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformancePending);
