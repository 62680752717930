import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Table from 'components/Table';
import useDialog from 'components/Dialog/components/useDialog';
import { formatDate, formatNumber, getFullName } from 'utils/functions';
import { useCounter } from 'utils/customHooks';
import {
  Eye, Pencil, CirclePlus, Trash,
} from 'lucide-react';
import { PERMISSION_LEVELS } from 'constants/permissions';
import { useNavigate } from 'react-router-dom';
import { deleteFundraiseInvestment, getFundraiseInvestments } from '../modules/actions';
import FundraiseInvestmentDetailsPopup from './FundraiseInvestmentDetailsPopup';
import FundraiseInvestPopup from './FundraiseInvestPopup';
import './styles.scss';

const FundraiseInvestorsTab = ({
  literals,
  literalsCommon,
  fundraise = null,
  setFundraise,
  match,
  loading,
  symbol,
  permission = null,
  user,
}) => {
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [investmentPopup, setInvestmentPopup] = useState(false);
  const tableRefreshFlag = useCounter(0);
  const { dialog } = useDialog();
  const navigate = useNavigate();

  if (!loading && permission?.level < PERMISSION_LEVELS.VIEW) {
    navigate({ hash: 'info' }, { replace: true });
  }

  const fetchFundraiseInvestments = () => {
    return getFundraiseInvestments(match.params.id);
  };

  const handleDeleteInvest = async (investment) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literals.deleteInvestment,
    });
    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteFundraiseInvestment(investment.fundraise, investment.id);
          if (fundraise) {
            const investments = fundraise.investments - investment.amount;
            const isMyInvestment = investment?.investor?.id === user.id;
            setFundraise({ ...fundraise, investments, investment: !isMyInvestment ? fundraise.investment : null });
          }
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const columns = [
    {
      field: 'investor',
      type: 'avatar',
      width: 45,
      preRender: investor => investor?.avatar,
      title: (investor, row) => (investor ? getFullName(investor) : row.name),
    },
    {
      field: 'investor',
      label: literalsCommon.name,
      preRender: (investor, row) => (investor ? getFullName(investor) : row.name),
    },
    {
      field: 'notes',
      label: literalsCommon.notes,
      type: 'html',
      width: 350,
      preRender: notes => `<div class='investment-notes'>${notes}</div>`,
    },
    {
      field: 'amount',
      label: literals.amount,
      width: 120,
      preRender: amount => formatNumber(amount, 0, { symbol }),
    },
    {
      field: 'createdAt',
      label: literalsCommon.createdAt,
      width: 120,
      preRender: date => formatDate(date),
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Eye,
          text: literalsCommon.view,
          onClick: row => setShowDetailsPopup(row),
        },
        {
          icon: Pencil,
          text: literalsCommon.edit,
          onClick: row => setInvestmentPopup(row),
          hidden: (_, row) => row.investor,
        },
        {
          icon: Trash,
          text: literalsCommon.delete,
          onClick: row => handleDeleteInvest(row),
        },
      ],
    },
  ];


  return (
    <Panel>
      <PanelHeader title={literals.commitments} />
      <PanelBody>
        <Table
          columns={columns}
          fetch={fetchFundraiseInvestments}
          forceFetch={tableRefreshFlag.value}
          onClickRow={row => setShowDetailsPopup(row)}
          actions={[{
            icon: CirclePlus,
            text: literals.commitment,
            onClick: () => setInvestmentPopup(true),
          }]}
        />
        {
          showDetailsPopup && (
            <FundraiseInvestmentDetailsPopup
              literals={literals}
              literalsCommon={literalsCommon}
              investment={showDetailsPopup}
              symbol={symbol}
              onClose={() => setShowDetailsPopup(false)}
            />
          )
        }
        { investmentPopup && (
          <FundraiseInvestPopup
            literals={literals}
            literalsCommon={literalsCommon}
            fundraise={fundraise}
            setFundraise={setFundraise}
            symbol={symbol}
            investment={investmentPopup?.id ? investmentPopup : null}
            manual
            onSubmit={tableRefreshFlag.increase}
            onClose={() => setInvestmentPopup(false)}
          />
        )}
      </PanelBody>
    </Panel>
  );
};

FundraiseInvestorsTab.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  permission: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  symbol: PropTypes.string.isRequired,
  fundraise: PropTypes.object,
  setFundraise: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default FundraiseInvestorsTab;
