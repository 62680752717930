import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ROUTE_ADMIN_PATH } from 'routes';
import {
  Home, LineChart, Settings, Users, Briefcase,
  Banknote, Landmark, MessageSquareText, BarChart3,
  Printer, Puzzle, BookOpen, Info, Mail,
} from 'lucide-react';
import RenderRow from './RenderRow';

const NavAdmin = (props) => {
  const {
    literals, platform, toggleMenu, route,
  } = props;

  const [subMenu, setSubMenu] = useState('');
  const handleShowSubmenu = (submenu) => {
    setSubMenu(prev => (prev === submenu ? null : submenu));
  };

  const {
    HOME, PLATFORM, USERS, ANALYTICS, STARTUPS, FUND,
    FUNDRAISE, MENTORINGS, PERFORMANCE, REPORTING, SERVICES,
    WEBHOOKS, SCRIPTS, CRONS, SUPPORT, WZ_EMAIL_LOGS,
  } = ROUTE_ADMIN_PATH;

  const navRows = [
    {
      path: HOME,
      to: HOME,
      name: literals.summary,
      icon: Home,
      exact: true,
    },
    {
      canView: !!platform?.analytics?.ga4?.property,
      path: ANALYTICS,
      to: ANALYTICS,
      name: literals.analytics,
      icon: LineChart,
    },
    {
      path: PLATFORM,
      to: PLATFORM,
      name: literals.platform,
      icon: Settings,
    },
    {
      path: USERS,
      to: USERS,
      name: literals.userAccess,
      icon: Users,
    },
    {
      path: STARTUPS,
      to: STARTUPS,
      name: literals.companies,
      icon: Briefcase,
    },
    {
      path: FUND,
      to: FUND,
      name: literals.funds,
      icon: Banknote,
      exact: true,
    },
    {
      path: FUNDRAISE,
      to: FUNDRAISE,
      name: literals.fundraise,
      icon: Landmark,
    },
    {
      path: MENTORINGS,
      to: MENTORINGS,
      name: literals.mentorings,
      icon: MessageSquareText,
    },
    {
      path: PERFORMANCE,
      to: PERFORMANCE,
      name: literals.performance,
      icon: BarChart3,
    },
    {
      path: REPORTING,
      to: REPORTING,
      name: literals.reporting,
      icon: Printer,
    },
    {
      path: SERVICES,
      to: SERVICES,
      name: literals.services,
      icon: Puzzle,
    },
    {
      path: [WEBHOOKS, SCRIPTS, CRONS],
      name: literals.logs,
      icon: BookOpen,
      subMenu: [
        { path: WEBHOOKS, to: WEBHOOKS, name: literals.webhooks },
        { path: SCRIPTS, to: SCRIPTS, name: literals.scripts },
        { path: CRONS, to: CRONS, name: literals.crons },
      ],
      showSubmenu: subMenu === 'logs',
      handleShowSubmenu: () => handleShowSubmenu('logs'),
    },
    {
      path: SUPPORT,
      to: SUPPORT,
      name: literals.help,
      icon: Info,
    },
    {
      name: literals.emailLogs,
      to: WZ_EMAIL_LOGS,
      icon: Mail,
    },
  ];

  return navRows.map(row => (
    <RenderRow key={row.name} toggleMenu={toggleMenu} route={route} {...row} />
  ));
};

NavAdmin.propTypes = {
  literals: PropTypes.object.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  platform: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

export default NavAdmin;
