const { useEffect, useState } = require('react');

export const useSourceOperation = (id, date, operations) => {
  const [sourceOperation, setSourceOperation] = useState({ loading: true });

  useEffect(() => {
    const timer = setTimeout(() => {
      if (operations !== null) {
        let source = null;
        if (operations.length) {
          source = operations.find(op => op.id !== id && (!date || op.date <= date)) || null;
        }

        if (source !== sourceOperation) {
          setSourceOperation(source);
        }
      }
    }, 1000);
    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, operations]);

  return sourceOperation;
};
