import React from 'react';
import PropTypes from 'prop-types';
import DialogModal from 'components/Dialog/components';
import Loading from 'components/Loading';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './AppRoutes';

function App(props) {
  const {
    isAuthenticated,
    user,
    platform,
    setGlobalProperties,
    sessionChecked,
    initPlatform,
    loading,
    dialogLiterals,
  } = props;

  return (
    <>
      <Loading hide={!loading} />
      <DialogModal literals={dialogLiterals} />
      <BrowserRouter>
        <AppRoutes
          sessionChecked={sessionChecked}
          initPlatform={initPlatform}
          isAuthenticated={isAuthenticated}
          user={user}
          platform={platform}
          setGlobalProperties={setGlobalProperties}
        />
      </BrowserRouter>
    </>
  );
}

App.propTypes = {
  dialogLiterals: PropTypes.object.isRequired,
  sessionChecked: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  platform: PropTypes.object.isRequired,
  initPlatform: PropTypes.func.isRequired,
  setGlobalProperties: PropTypes.func.isRequired,
};

export default App;
