import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import ToolTab from 'components/Tool/components/ToolTab';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Loading from 'components/Loading';
import { initializeKpiRelations } from 'routes/Performance/modules/actions';
import { ROUTE_STARTUP_PATH } from 'routes';
import PerformanceAddTabKpis from './PerformanceAddTabKpis';
import PerformanceAddTabTemplates from './PerformanceAddTabTemplates';
import PerformanceAddTabCustom from './PerformanceAddTabCustom';
import './styles.scss';

const PerformanceAdd = (props) => {
  const {
    lang,
    literals,
    literalsCommon,
    currency,
    match,
    startup,
    performance,
    fetchKpiRelations,
    postKpi,
    postKpisRelations,
  } = props;

  useEffect(() => {
    initializeKpiRelations(fetchKpiRelations, performance, 'startup', match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadcrumb = [
    { route: ROUTE_STARTUP_PATH.setPerformance(match.params.id), name: literals.title },
    { route: ROUTE_STARTUP_PATH.setPerformanceAdd(match.params.id), name: literals.add },
  ];

  const tabs = [
    { key: 'templates', name: literals.templates },
    { key: 'kpis', name: literals.kpis },
    { key: 'custom', name: literals.custom },
  ];

  if (!performance.loaded) {
    return <Loading mode='tool' hide={false} />;
  }

  return (
    <Tool className='performanceAdd-wrapper' tabs={tabs} breadcrumb={breadcrumb}>
      <ToolTab key='templates'>
        <PerformanceAddTabTemplates
          literals={literals}
          literalsCommon={literalsCommon}
          lang={lang}
          currency={currency}
          match={match}
          performance={performance}
          postKpisRelations={postKpisRelations}
          startup={startup}
        />
      </ToolTab>
      <ToolTab key='kpis'>
        <PerformanceAddTabKpis
          literals={literals}
          literalsCommon={literalsCommon}
          lang={lang}
          currency={currency}
          match={match}
          startup={startup}
          performance={performance}
          postKpi={postKpi}
          postKpisRelations={postKpisRelations}
        />
      </ToolTab>
      <ToolTab key='custom'>
        <Panel>
          <PanelHeader title={literals.kpis} />
          <PanelBody>
            <PerformanceAddTabCustom
              match={match}
              onSubmit={postKpi}
              literals={literals}
              literalsCommon={literalsCommon}
              startup={startup}
              lang={lang}
            />
          </PanelBody>
        </Panel>
      </ToolTab>
    </Tool>
  );
};

PerformanceAdd.propTypes = {
  lang: PropTypes.string.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  performance: PropTypes.object.isRequired,
  fetchKpiRelations: PropTypes.func.isRequired,
  postKpi: PropTypes.func.isRequired,
  postKpisRelations: PropTypes.func.isRequired,
  startup: PropTypes.object.isRequired,
};

export default PerformanceAdd;
