import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import InputTextArea from 'components/Inputs/inputTextArea';
import Button from 'components/Buttons/Button';
import InputRadio from 'components/Inputs/inputRadio';
import InputStars from 'components/Inputs/inputStars';

const MentoringFormFeedback = (props) => {
  const {
    literals,
    mentoring,
    onClose,
    onSubmit,
  } = props;

  const [reason, setReason] = useState(mentoring.feedback.reason ?? 'finish');
  const [messageCancel, setMessageCancel] = useState('');
  const [score, setScore] = useState(1);
  const [messageFinish, setMessageFinish] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const submitFeedback = (event) => {
    event.preventDefault();
    const params = {
      reason,
      message: reason === 'cancel' ? messageCancel : messageFinish,
      score,
    };

    const submitErrors = {};
    if (!params.reason) {
      submitErrors.reason = true;
    }
    if (!params.message) {
      submitErrors.message = true;
    }
    if (!params.score) {
      submitErrors.score = true;
    }

    setErrors(submitErrors);
    if (Object.keys(submitErrors).length === 0) {
      setLoading(true);
      onSubmit(mentoring.id, params);
    }
  };

  const reasonOptions = [
    { id: 'cancel', name: literals.feedbackForm.cancel },
    { id: 'finish', name: literals.feedbackForm.finish },
  ];

  return (
    <Popup title={literals.feedbackForm.title} description={literals.feedbackForm.description} onClose={onClose}>
      <form onSubmit={submitFeedback}>
        {
          mentoring.feedback.reason === null && (
            <InputRadio name='reason' preText={literals.feedbackForm.reason} isRequired options={reasonOptions} onChange={v => setReason(v)} value={reason} error={errors?.reason} />
          )
        }
        {
          reason === 'cancel' && (
            <InputTextArea name='messageCancel' preText={literals.feedbackForm.cancelReason} isRequired onChange={v => setMessageCancel(v)} value={messageCancel} error={errors?.message} />
          )
        }
        {
          reason === 'finish' && (
            <>
              <InputStars name='score' preText={literals.feedbackForm.score} isRequired onChange={v => setScore(v)} value={score} error={errors?.score} />
              <InputTextArea name='messageFinish' preText={literals.feedbackForm.finishComment} isRequired onChange={v => setMessageFinish(v)} value={messageFinish} error={errors?.message} />
            </>
          )
        }
        <div className='buttons'>
          <Button type='submit' text={literals.feedbackForm.send} loading={loading} />
        </div>
      </form>
    </Popup>
  );
};

MentoringFormFeedback.propTypes = {
  literals: PropTypes.object.isRequired,
  mentoring: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default MentoringFormFeedback;
