import StartupsInstance from 'modules/startups/repository';
import { formatErrors } from 'utils/functions';


export function fetchStartupUsers(id, opts) {
  return StartupsInstance
    .getStartupUsers(id, opts)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw formatErrors(error);
    });
}


export function deleteUserFromStartup(id, user) {
  return StartupsInstance
    .deleteAddUser(id, user)
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw formatErrors(error);
    });
}
