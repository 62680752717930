import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import Tool from 'components/Tool';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelTab from 'components/Panel/components/PanelTab';
import useDialog from 'components/Dialog/components/useDialog';
import { getFullName, startupCanEdit } from 'utils/functions';
import { useCounter } from 'utils/customHooks';
import { TOOL_USERS } from 'constants/tools';
import { STARTUP_ROLE_OWNER } from 'constants/roles';
import { STARTUP_RELATION_STATUS } from 'constants/startup';
import { changeInviteStatus } from 'routes/PreHome/modules/actions';
import {
  UserPlus,
  UserMinus,
  CircleCheck,
  CircleX,
  Mail,
  Pencil,
} from 'lucide-react';
import StartupUsersForm from 'components/InviteUsers/StartupUserForm';
import PopupFormShareholder from 'routes/Captable/components/routes/shareHolders/components/PopupFormShareholder';
import { fetchStartupUsers, deleteUserFromStartup } from '../modules/actions';
import './styles.scss';

function StartupUsers(props) {
  const {
    literals, literalsCommon, match, user, startup,
  } = props;

  const pageSize = 20;
  const [showFormUser, setShowFormUser] = useState(false);
  const [newShareholderPopup, setNewShareholderPopup] = useState(false);
  const tableRefreshFlag = useCounter(0);
  const { dialog } = useDialog();
  const canEdit = startupCanEdit(startup, TOOL_USERS);

  const fetchUsers = (page, size, search) => {
    return fetchStartupUsers(match.params.id, {
      page,
      size,
      search,
      filters: {
        status: STARTUP_RELATION_STATUS.ACCEPTED,
      },
    });
  };

  const fetchRequests = (page, size, search) => {
    return fetchStartupUsers(match.params.id, {
      page,
      size,
      search,
      filters: { status: STARTUP_RELATION_STATUS.PENDING },
    });
  };

  const onRemoveUser = async (data) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: data.id === user.id ? literals.leaveStartupQuestion : literals.questionRemoveUser,
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await deleteUserFromStartup(match.params.id, data.id);
          tableRefreshFlag.increase();
        },
      });
    }
  };

  const changeUserStatus = async (auxUser, status) => {
    let confirm = true;
    if (status === STARTUP_RELATION_STATUS.REJECTED) {
      confirm = await dialog({
        type: 'confirmDanger',
        text: auxUser.startedBy === 'startup' ? literals.questionCancelInviteUser : literals.questionRejectUser,
      });
    }

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          if (status === STARTUP_RELATION_STATUS.ACCEPTED) {
            setShowFormUser({ ...auxUser, changeStatus: status });
          } else {
            await changeInviteStatus(match.params.id, auxUser.id, status);
            tableRefreshFlag.increase();
          }
        },
      });
    }
  };

  const onSubmitUser = async (response, isNew) => {
    if (showFormUser.changeStatus) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await changeInviteStatus(match.params.id, showFormUser.id, showFormUser.changeStatus);
        },
      });
      await dialog({
        type: 'success',
        text: literals.acceptUserSuccess,
      });
    }
    tableRefreshFlag.increase();
    setShowFormUser(false);
    if (isNew) {
      await dialog({
        type: 'success',
        text: literals.inviteUserSuccess,
      });
    }

    if (response.type === 'shareholder') {
      setNewShareholderPopup(response);
    }
  };

  const columns = [
    {
      field: 'avatar',
      type: 'avatar',
      width: 45,
      label: '',
      title: (_, row) => getFullName(row),
    },
    { field: 'firstname', grow: 1, label: literals.name },
    { field: 'lastname', grow: 3, label: literals.lastname },
    { field: 'email', grow: 5, label: literals.email },
    {
      field: 'type',
      width: 100,
      label: literals.type,
      preRender: v => literals.types[v] || v,
    },
  ];

  const usersColumns = [...columns];
  const pendingsColumns = [...columns, {
    field: 'startedBy',
    width: 100,
    preRender: v => (
      <div className='cell-keywords m-0 p-0'>
        <div className='d-flex align-items-center m-0'>
          { v === 'startup'
            ? <UserPlus width={12} className='mr-1' />
            : <Mail width={12} className='mr-1' />
          }
          { v === 'startup' ? literals.invite : literals.request}
        </div>
      </div>
    ),
  }];

  const tableActionsAdmins = [];

  if (canEdit) {
    tableActionsAdmins.push({
      icon: UserPlus,
      text: literals.inviteUser,
      onClick: () => setShowFormUser(true),
    });

    usersColumns.push({
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Pencil,
          text: literalsCommon.edit,
          onClick: row => setShowFormUser(row),
        },
        {
          icon: UserMinus,
          text: id => (user.id === id ? literals.leaveStartup : literals.removeUser),
          onClick: onRemoveUser,
          disabled: (_, row) => row.type === STARTUP_ROLE_OWNER,
        },
      ],
    });

    pendingsColumns.push({
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Pencil,
          text: literalsCommon.edit,
          onClick: row => setShowFormUser(row),
        },
        {
          icon: CircleCheck,
          text: literalsCommon.accept,
          onClick: row => changeUserStatus(row, STARTUP_RELATION_STATUS.ACCEPTED),
          hidden: (_, row) => row.startedBy === 'startup',
        },
        {
          icon: CircleX,
          text: literalsCommon.cancel,
          onClick: row => changeUserStatus(row, STARTUP_RELATION_STATUS.REJECTED),
          hidden: (_, row) => row.startedBy === 'user',
        },
        {
          icon: CircleX,
          text: literalsCommon.reject,
          onClick: row => changeUserStatus(row, STARTUP_RELATION_STATUS.REJECTED),
          hidden: (_, row) => row.startedBy === 'startup',
        },
      ],
    });
  }

  return (
    <Tool title={literals.userDatabase} actionIcon={<UserPlus />}>
      <Panel>
        <PanelHeader
          title={literals.userDatabase}
          tabs={[
            { key: 'users', name: literals.users },
            { key: 'pendings', name: literals.pendings },
          ]}
        />
        <PanelBody>
          <PanelTab key='users'>
            <Table
              columns={usersColumns}
              fetch={fetchUsers}
              pageSize={pageSize}
              forceFetch={tableRefreshFlag.value}
              actions={tableActionsAdmins}
            />
          </PanelTab>
          <PanelTab key='pendings'>
            <Table
              columns={pendingsColumns}
              fetch={fetchRequests}
              pageSize={pageSize}
              forceFetch={tableRefreshFlag.value}
            />
          </PanelTab>
        </PanelBody>
      </Panel>
      {showFormUser && (
        <StartupUsersForm
          popup
          literals={literals}
          startup={match.params.id}
          user={showFormUser === true ? {} : showFormUser}
          onSubmit={onSubmitUser}
          onClose={() => setShowFormUser(false)}
        />
      )}
      {newShareholderPopup && (
        <PopupFormShareholder
          shareholder={{
            firstname: newShareholderPopup.firstname,
            lastname: newShareholderPopup.lastname,
            users: [{ ...newShareholderPopup, type: 'existing' }],
          }}
          onClose={() => setNewShareholderPopup(false)}
        />
      )}
    </Tool>
  );
}

StartupUsers.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
};

export default StartupUsers;
