import { connect } from 'react-redux';
import ReportingInfo from '../components';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.reporting,
    literalsDataroom: state.i18n.literals.dataRoom,
    literalsCommon: state.i18n.literals.common,
  };
}

export default connect(mapStateToProps)(ReportingInfo);
