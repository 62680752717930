import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import InputText from 'components/Inputs/inputText';
import InputRichText from 'components/Inputs/inputRichText';
import InputDateTime from 'components/Inputs/inputDateTime';
import InputSelect from 'components/Inputs/inputSelect';
import OutputErrors from 'components/Inputs/outputErrors';
import useDialog from 'components/Dialog/components/useDialog';
import { timezonesConstant } from 'constants/selectOptions';
import { CalendarCheck, Calendar, Clock } from 'lucide-react';

const MeetingForm = (props) => {
  const {
    user,
    meeting,
    literals,
    onSubmit,
  } = props;

  const getTimeZone = () => {
    const offset = new Date().getTimezoneOffset();
    const o = Math.abs(offset);
    return `${(offset < 0 ? '+' : '-')}${(`00${Math.floor(o / 60)}`).slice(-2)}:${(`00${(o % 60)}`).slice(-2)}`;
  };

  let meetingDate = '';
  let meetingTimezone = getTimeZone();

  if (meeting.date) {
    const meetingDateAux = meeting.date.split(':00.000');
    meetingDate = meetingDateAux[0];
    meetingTimezone = meetingDateAux[1];
  }

  const [title, setTitle] = useState(meeting.title || '');
  const [description, setDescription] = useState(meeting.description || '');
  const [date, setDate] = useState(meetingDate || '');
  const [time, setTime] = useState(meeting.time || '');
  const [timezone, setTimezone] = useState(meetingTimezone);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const { dialog } = useDialog();

  const times = [];
  for (let i = 5; i < 241; i += 5) {
    times.push({ id: i, name: i });
  }

  const submitMeeting = (e) => {
    e.preventDefault();

    const data = {
      title, description, date, time,
    };

    if (data.title && data.date && data.time) {
      data.date += `:00.000${timezone}`;
      setLoading(true);
      onSubmit(meeting.id, data).catch(async () => {
        setLoading(false);
        await dialog({
          type: 'error',
          text: <span className='fw-b'>{literals.meetingForm.submitError}</span>,
        });
      });
    } else {
      const submitErrors = {};
      if (!data.title) {
        submitErrors.title = { code: 'required_error' };
      }
      if (!data.date) {
        submitErrors.date = { code: 'required_error' };
      }
      if (!data.time) {
        submitErrors.time = { code: 'required_error' };
      }
      setErrors(submitErrors);
    }
  };

  return (
    <form className='meeting-form' onSubmit={submitMeeting}>
      {
        user.mentor && (
          <div className='d-flex justify-content-end align-items-center mb-4'>
            <span className='px-2'>{literals.yourHours}</span>
            <div className='hoursIndicator'>
              <div title={literals.scheduledHours}>
                <Calendar size={14} />
                {user.meetingsTime.scheduled}
              </div>
              <div title={literals.finishedHours}>
                <CalendarCheck size={14} />
                {user.meetingsTime.finished}
              </div>
              <div title={literals.donatedHours}>
                <Clock size={14} />
                {user.mentor.hours}
              </div>
            </div>
          </div>
        )
      }
      <InputText preText={literals.meetingForm.title} value={title} onChange={v => setTitle(v)} isRequired errors={errors?.title} />
      <InputRichText preText={literals.meetingForm.description} value={description} onChange={v => setDescription(v)} />
      <div className='row'>
        <div className='col-md-5'>
          <InputDateTime
            preText={literals.meetingForm.date}
            value={date}
            onChange={v => setDate(v)}
            isRequired
          />
        </div>
        <div className='col-md-3'>
          <InputSelect
            preText={literals.meetingForm.timezone}
            options={timezonesConstant}
            value={timezone}
            onChange={v => setTimezone(v)}
            isRequired
          />
        </div>
        <div className='col-md-4'>
          <InputSelect
            preText={literals.meetingForm.time}
            options={times}
            value={time}
            onChange={v => setTime(v)}
            errors={errors?.time}
            isRequired
          />
        </div>
      </div>
      <OutputErrors literals={literals.meetingForm} errors={errors} />
      <div className='buttons'>
        <Button loading={loading} text={literals.meetingForm.send} type='submit' />
      </div>
    </form>
  );
};

MeetingForm.propTypes = {
  user: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  meeting: PropTypes.object,
};

MeetingForm.defaultProps = {
  meeting: {},
};

export default MeetingForm;
