import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const ServicesInstance = ApiFetch();

export const URLS = {
  SERVICES_REQUESTS: 'services/requests',
  SERVICES_REQUEST: 'services/requests/:id',
  ACTIVE_SERVICES: 'services',
};

async function createServicesRequest(data) {
  return ServicesInstance.post(URLS.SERVICES_REQUESTS, data);
}

async function deleteServicesRequest(id) {
  const path = preparePath(URLS.SERVICES_REQUEST, { id });
  return ServicesInstance.delete(path);
}

async function getActiveServices(params = {}) {
  const path = preparePath(URLS.ACTIVE_SERVICES, {}, params);
  return ServicesInstance.get(path);
}

export default {
  createServicesRequest,
  deleteServicesRequest,
  getActiveServices,
};
