import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import { getColorRange, getTranslation } from 'utils/functions';
import { KPI_PERIODICITY } from 'constants/kpis';
import { formatKpiValue } from 'routes/Performance/utils/functions';

const PerformanceComparatorTable = (props) => {
  const {
    show,
    isMonthly,
    graphData,
    dates,
    lang,
    literals,
    literalsCommon,
    loading,
    interval,
    tableType,
    currency = null,
  } = props;

  const printKpiValue = (kpi, date, type, comparator = false) => {
    if (comparator) {
      const dateIndex = dates.actual.findIndex(d => d === date);
      // eslint-disable-next-line no-param-reassign
      date = dates.prev[dateIndex];
    }
    const kpiValue = comparator ? kpi.prev.data?.[date] : kpi.data?.[date];
    let isValid = true;

    const value = kpiValue?.[type] || kpiValue?.[type] === 0
      ? formatKpiValue(kpiValue?.[type], kpi, null, currency)
      : '';

    if (type === 'value' && !isMonthly && isValid) {
      const kpiPeriodicity = kpi.periodicity;
      if (kpiValue?.aggregate?.valid) {
        isValid = kpiValue?.aggregate.valid;
      } else if (kpiPeriodicity === KPI_PERIODICITY.QUARTERLY) {
        isValid = (tableType === KPI_PERIODICITY.QUARTERLY && kpiValue?.aggregate?.count === 1)
          || (tableType === KPI_PERIODICITY.ANNUAL && kpiValue?.aggregate?.count === 4);
      } else if (kpiPeriodicity === KPI_PERIODICITY.MONTHLY) {
        isValid = (tableType === KPI_PERIODICITY.QUARTERLY && kpiValue?.aggregate?.count === 3)
          || (tableType === KPI_PERIODICITY.ANNUAL && kpiValue?.aggregate?.count === 12);
      }
    }

    return value ? `${value}${!isValid ? '*' : ''}` : '-';
  };

  const renderHeader = () => {
    return dates.actual.map((date) => {
      let header = `${literalsCommon.months[+date.slice(-2)]} '${date.slice(2, 4)}`;
      if (tableType === KPI_PERIODICITY.ANNUAL) {
        header = date.slice(0, 4);
      } else if (tableType === KPI_PERIODICITY.QUARTERLY) {
        header = `${literals.quarterly.charAt(0)}${+date.slice(-2) / 3} '${date.slice(2, 4)}`;
      }
      return <h6 key={`header-${date}`} className='kpi-table-cell'>{header}</h6>;
    });
  };

  const renderRow = (kpi) => {
    return (
      <div key={kpi.id} className='kpi-table-row'>
        {
          dates.actual.map(date => (
            <div key={`${kpi.id}-${date}`} className='d-flex flex-column w-100'>
              {((show.value && isMonthly) || (!isMonthly)) && (
                <div className='kpi-table-cell disabled'>
                  <div>{printKpiValue(kpi, date, 'value')}</div>
                  { interval.comparator.startDate && <div className='fs-sm sc-secondary'>{printKpiValue(kpi, date, 'value', true)}</div> }
                </div>
              )}
              {show.forecast && isMonthly && (
                <div className='kpi-table-cell disabled'>
                  <div>{printKpiValue(kpi, date, 'forecast')}</div>
                  { interval.comparator.startDate && <div className='fs-sm sc-secondary'>{printKpiValue(kpi, date, 'forecast', true)}</div> }
                </div>
              )}

            </div>
          ))
        }
      </div>
    );
  };

  const renderTable = () => {
    if (loading) {
      return <Loading mode='panel' hide={false} />;
    }

    const graphColors = getColorRange(2);
    return (
      <div>
        <div className='kpi-table'>
          <div className='kpi-table-left'>
            <h6 className='kpi-table-cell'>KPI</h6>
            {graphData.map(kpi => (
              <div
                key={`header-${kpi.id}`}
                className={`kpi-table-cell ${isMonthly ? KPI_PERIODICITY.MONTHLY : ''} ${show.forecast && show.value ? 'xl' : ''}`}
                title={getTranslation(kpi?.name, lang) || kpi.name}
              >
                <div className='kpi-name'>{getTranslation(kpi?.name, lang) || kpi.name}</div>
                { isMonthly ? (
                  <div className='kpi-tag'>
                    {show.value && <div className='kpi--circle' style={{ background: graphColors[0] }} />}
                    {show.forecast && <div className='kpi--circle' style={{ background: graphColors[1] }} />}
                  </div>
                ) : (
                  <div className='kpi-aggregate'>
                    <div>
                      {literals[kpi.aggregate]}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className='kpi-table-right'>
            <div className='kpi-table-row'>{renderHeader()}</div>
            {graphData.map(kpi => renderRow(kpi))}
          </div>
        </div>
        {!isMonthly && <div className='fs-sm font-italic text-right mt-2'>{literals.dataIncomplete}</div>}
      </div>
    );
  };

  return renderTable();
};

PerformanceComparatorTable.propTypes = {
  lang: PropTypes.string.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  show: PropTypes.object.isRequired,
  isMonthly: PropTypes.bool.isRequired,
  graphData: PropTypes.array.isRequired,
  dates: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  interval: PropTypes.object.isRequired,
  tableType: PropTypes.string.isRequired,
  currency: PropTypes.object,
};

export default PerformanceComparatorTable;
