import StartupsCaptablesInstance from 'modules/startupsCaptables/repository';
import { formatErrors } from 'utils/functions';
import { apiDownloadFile } from 'modules/actions';
import { API_IMPORT_CAPTABLE_EXCEL } from 'modules/apiEndpoints';
import {
  createCaptableShareClassFailure,
  createCaptableShareClassInit,
  createCaptableShareClassSuccess,
  createCaptableShareholderFailure,
  createCaptableShareholderInit,
  createCaptableShareholderSuccess,
  createCaptableVestingPlanFailure,
  createCaptableVestingPlanInit,
  createCaptableVestingPlanSuccess,
  deleteCaptableShareClassFailure,
  deleteCaptableShareClassInit,
  deleteCaptableShareClassSuccess,
  deleteCaptableShareholderFailure,
  deleteCaptableShareholderInit,
  deleteCaptableShareholderSuccess,
  deleteCaptableVestingPlanFailure,
  deleteCaptableVestingPlanInit,
  deleteCaptableVestingPlanSuccess,
  editCaptableShareClassFailure,
  editCaptableShareClassInit,
  editCaptableShareClassSuccess,
  editCaptableShareholderFailure,
  editCaptableShareholderInit,
  editCaptableShareholderSuccess,
  editCaptableVestingPlanFailure,
  editCaptableVestingPlanInit,
  editCaptableVestingPlanSuccess,
  fetchCaptableFailure,
  fetchCaptableInit, fetchCaptableSuccess,
} from '.';


export async function initializeCaptable(fetch, captable, startup, captableId = null, force = false, operation = null) {
  return new Promise((resolve) => {
    if (!captable?.error && ((!captable.loading && !captable.loaded) || force || (captable.startup !== startup) || (captable.captable !== captableId))) {
      fetch(startup, captableId, operation).then((response) => {
        resolve(response);
      });
    } else {
      resolve(captable);
    }
  });
}

export const fetchCaptableAction = (startup, captable = null, operation = null) => async (dispatch) => {
  dispatch(fetchCaptableInit(startup, captable));
  return StartupsCaptablesInstance.getStartupCaptableVariables(startup, { captable, operation }).then((data) => {
    dispatch(fetchCaptableSuccess(data));
    return data;
  }).catch((error) => {
    dispatch(fetchCaptableFailure(error));
    return false;
  });
};

export function postShareholderAction(id, data) {
  return (dispatch) => {
    dispatch(createCaptableShareholderInit());
    return StartupsCaptablesInstance.postStartupShareholder(id, data).then((response) => {
      dispatch(createCaptableShareholderSuccess(response));
      return response;
    }).catch((error) => {
      const formatError = formatErrors(error);
      dispatch(createCaptableShareholderFailure(formatError));
      throw formatError;
    });
  };
}

export function putShareholderAction(id, data) {
  return (dispatch) => {
    dispatch(editCaptableShareholderInit());
    return StartupsCaptablesInstance.putStartupShareholder(id, data.id, data).then((response) => {
      dispatch(editCaptableShareholderSuccess(response));
      return response;
    }).catch((error) => {
      const formatError = formatErrors(error);
      dispatch(editCaptableShareholderFailure(formatError));
      throw formatError;
    });
  };
}

export function deleteShareholderAction(id, shareholder) {
  return (dispatch) => {
    dispatch(deleteCaptableShareholderInit());
    return StartupsCaptablesInstance.deleteStartupShareholder(id, shareholder).then(() => {
      dispatch(deleteCaptableShareholderSuccess(shareholder));
      return shareholder;
    }).catch((error) => {
      const formatError = formatErrors(error);
      dispatch(deleteCaptableShareholderFailure(formatError));
      throw formatError;
    });
  };
}

export function postShareClassAction(id, data) {
  return (dispatch) => {
    dispatch(createCaptableShareClassInit());
    return StartupsCaptablesInstance.postStartupShareClass(id, data).then((response) => {
      dispatch(createCaptableShareClassSuccess(response));
      return response;
    }).catch((error) => {
      const formatError = formatErrors(error);
      dispatch(createCaptableShareClassFailure(formatError));
      throw formatError;
    });
  };
}

export function putShareClassAction(id, data) {
  return (dispatch) => {
    dispatch(editCaptableShareClassInit());
    return StartupsCaptablesInstance.putStartupShareClass(id, data.id, data).then((response) => {
      dispatch(editCaptableShareClassSuccess(response));
      return response;
    }).catch((error) => {
      const formatError = formatErrors(error);
      dispatch(editCaptableShareClassFailure(formatError));
      throw formatError;
    });
  };
}

export function deleteShareClassAction(id, shareClass) {
  return (dispatch) => {
    dispatch(deleteCaptableShareClassInit());
    return StartupsCaptablesInstance.deleteStartupShareClass(id, shareClass).then(() => {
      dispatch(deleteCaptableShareClassSuccess(shareClass));
      return shareClass;
    }).catch((error) => {
      const formatError = formatErrors(error);
      dispatch(deleteCaptableShareClassFailure(formatError));
      throw formatError;
    });
  };
}

export function postVestingPlanAction(id, data) {
  return (dispatch) => {
    dispatch(createCaptableVestingPlanInit());
    return StartupsCaptablesInstance.postStartupVestingPlan(id, data).then((response) => {
      dispatch(createCaptableVestingPlanSuccess(response));
      return response;
    }).catch((error) => {
      const formatError = formatErrors(error);
      dispatch(createCaptableVestingPlanFailure(formatError));
      throw formatError;
    });
  };
}

export function putVestingPlanAction(id, data) {
  return (dispatch) => {
    dispatch(editCaptableVestingPlanInit());
    return StartupsCaptablesInstance.putStartupVestingPlan(id, data.id, data).then((response) => {
      dispatch(editCaptableVestingPlanSuccess(response));
      return response;
    }).catch((error) => {
      const formatError = formatErrors(error);
      dispatch(editCaptableVestingPlanFailure(formatError));
      throw formatError;
    });
  };
}

export function deleteVestingPlanAction(id, vestingPlan) {
  return (dispatch) => {
    dispatch(deleteCaptableVestingPlanInit());
    return StartupsCaptablesInstance.deleteStartupVestingPlan(id, vestingPlan).then(() => {
      dispatch(deleteCaptableVestingPlanSuccess(vestingPlan));
      return vestingPlan;
    }).catch((error) => {
      const formatError = formatErrors(error);
      dispatch(deleteCaptableVestingPlanFailure(formatError));
      throw formatError;
    });
  };
}

export function createStartupCaptableService(id, data) {
  return StartupsCaptablesInstance.createStartupCaptableService(id, data);
}

export function deleteStartupCaptableService(id, data) {
  return StartupsCaptablesInstance.deleteStartupCaptableService(id, data);
}

export function getStartupCaptableService(id, data) {
  return StartupsCaptablesInstance.getStartupCaptableService(id, data);
}

export function downloadImportExcelTemplate(id) {
  return apiDownloadFile(API_IMPORT_CAPTABLE_EXCEL(id), 'captable_template');
}

export function inicializeCaptableExcel(startup, captable, file) {
  return StartupsCaptablesInstance.inicializeCaptableExcel(startup, captable || 'null', file)
    .catch((errors) => {
      throw formatErrors(errors);
    });
}
