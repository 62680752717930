import StartupsInstance from 'modules/startups/repository';
import AccountsInstance from 'modules/accounts/repository';
import AdminInstance from 'modules/admin/repository';

import { SessionService } from 'modules/session/sessionService';
import { loadingAction } from '../../../store/globalState/global';
import { FETCH_COMPANY, FETCH_COMPANY_SUCCESS, FETCH_COMPANY_FAILURE } from './types';

export function fetchCompanyInit() {
  return {
    type: FETCH_COMPANY,
  };
}

export function fetchCompanySuccess(success) {
  return {
    type: FETCH_COMPANY_SUCCESS,
    payload: success,
  };
}

export function fetchCompanyFailure(error) {
  return {
    type: FETCH_COMPANY_FAILURE,
    payload: error,
  };
}

export function fetchCompanyAction(id) {
  return (dispatch) => {
    dispatch(fetchCompanyInit());

    return StartupsInstance
      .getStartupInfo(id)
      .then((company) => {
        dispatch(fetchCompanySuccess(company));
        return company;
      })
      .catch((error) => {
        console.error(error);
        dispatch(fetchCompanyFailure(error.message));
        return false;
      });
  };
}

function updateSessionUser(user) {
  const { id } = user;
  window.USER_ID = id;
  SessionService.saveSession({ token: id }).then(() => {
    SessionService.saveUser(user);
  });
}

export function fetchMentor() {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AccountsInstance.getSession()
      .then((user) => {
        dispatch(loadingAction(false));
        updateSessionUser(user);
        return true;
      })
      .catch((err) => {
        dispatch(loadingAction(false));

        console.error(err);
        return false;
      });
  };
}

export function getAdminSummary() {
  return AdminInstance.getAdminSummary();
}

export function getAdminMentoringsSummary() {
  return AdminInstance.getAdminMentoringsSummary();
}

export function getAdminCaptableSummary() {
  return AdminInstance.getAdminCaptableSummary();
}

export function getAdminKpisSummary() {
  return AdminInstance.getAdminKpisSummary();
}

export function getAdminReportsSummary() {
  return AdminInstance.getAdminReportsSummary();
}

export function getAdminDataroomSummary() {
  return AdminInstance.getAdminDataroomSummary();
}
