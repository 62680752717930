import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import InputMultiValues from 'components/Inputs/inputMultiValues';
import Button from 'components/Buttons/Button';
import IOFileLoading from 'components/IOFileLoading/components';
import StartupCaptableDate from 'components/StartupCaptableDate/components';
import { Download } from 'lucide-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTE_STARTUP_PATH } from 'routes';
import { getCaptableWaterfalls, getCaptableWaterfall, downloadExcelWaterfallComparator } from '../modules/actions';
import WaterfallComparatorValues from './waterfallComparatorValues';
import WaterfallComparatorTable from './waterfallComparatorTable';
import './styles.scss';

const WaterfallComparator = (props) => {
  const {
    literals,
    currency,
    match,
    captable,
    startup,
  } = props;

  const navigate = useNavigate();
  const [waterfalls, setWaterfalls] = useState([]);
  const [selectWaterfalls, setSelectWaterfalls] = useState({ });
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const ids = searchParams.get('ids')?.split(',') || [];
  const [loading, setLoading] = useState(ids);

  const breadcrumb = [
    { route: ROUTE_STARTUP_PATH.setCaptableWaterfall(match.params.id, captableId), name: literals.waterfall },
    { name: literals.comparator },
  ];

  const handleSelectWaterfalls = async (waterfallsId) => {
    const aux = { };
    let news = waterfallsId.length;
    waterfallsId.forEach((w) => {
      if (selectWaterfalls[w]) {
        aux[w] = selectWaterfalls[w];
        news -= 1;
      }
    });

    setLoading(news);
    await waterfallsId.reduce(async (previousPromise, waterfall) => {
      await previousPromise;
      if (!selectWaterfalls[waterfall]) {
        await getCaptableWaterfall(match.params.id, waterfall, { captable: captableId })
          .then((response) => { aux[waterfall] = response; });
      }
      return Promise.resolve();
    }, Promise.resolve());

    setSelectWaterfalls(aux);
    const queryString = new URLSearchParams();
    if (Object.keys(aux).length) {
      queryString.set('ids', Object.keys(aux).join(','));
    }
    if (captableId) {
      queryString.set('captable', captableId);
    }
    navigate({ search: queryString.toString() }, { replace: true });
    setLoading(false);
  };

  useEffect(() => {
    setSelectWaterfalls([]);
    (async () => {
      const auxWaterfalls = await getCaptableWaterfalls(match.params.id, {
        page: 0, size: 0, captable: captableId,
      });
      setWaterfalls(auxWaterfalls.items);

      if (ids) {
        handleSelectWaterfalls(ids);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captableId]);

  const handleDownloadExcel = async () => {
    setDownloadingExcel(true);
    await downloadExcelWaterfallComparator(match.params.id, Object.keys(selectWaterfalls).join(','), 'waterfall_comparator.xlsx');
    setDownloadingExcel(false);
  };

  return (
    <Panel className='waterfall-comparator'>
      <PanelHeader breadcrumb={breadcrumb} />
      <PanelBody>
        <StartupCaptableDate startup={startup} captable={captable} noDate />
        <InputMultiValues
          preText={literals.waterfallToCompare}
          placeholder={literals.waterfall}
          value={Object.keys(selectWaterfalls)}
          onChange={handleSelectWaterfalls}
          options={waterfalls.map(waterfall => (
            { name: waterfall.name, id: waterfall.id }
          ))}
        />

        <WaterfallComparatorValues
          match={match}
          loading={loading}
          selectWaterfalls={selectWaterfalls}
          setSelectWaterfalls={setSelectWaterfalls}
        />

        <WaterfallComparatorTable
          literals={literals}
          currency={currency}
          waterfalls={selectWaterfalls}
          loading={loading}
        />

        <div className='buttons'>
          <Button
            color='secondary'
            text='Excel'
            icon={Download}
            onClick={handleDownloadExcel}
          />
        </div>
        { downloadingExcel && <IOFileLoading mode='download' file={{ name: 'waterfall_comparator.xlsx' }} /> }
      </PanelBody>
    </Panel>
  );
};

WaterfallComparator.propTypes = {
  literals: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
};

export default WaterfallComparator;
