import StartupsInstance from 'modules/startups/repository';
import MentoringsInstance from 'modules/mentorings/repository';

export function fetchMentorProfile(id) {
  return MentoringsInstance.getMentoringMentor(id);
}

export function fetchStartupProfile(id) {
  return StartupsInstance.getStartupInfo(id);
}
