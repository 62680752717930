import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import Avatar from 'components/Avatar';
import InputTextArea from 'components/Inputs/inputTextArea';
import ButtonUploadFile from 'components/ButtonUploadFile';
import { Send, Paperclip, X } from 'lucide-react';
import { FILES_BASE_URL } from 'constants/apiConf';
import { getFullName, onClickOut } from 'utils/functions';
import { uploadFile } from 'modules/actions';

const MessagesForm = ({
  literals,
  onSubmit = null,
  id = null,
  showReplies = null,
  isReply = false,
  isFocus = false,
  setIsFocus = () => {},
  disabled = false,
  user = null,
}) => {
  const [form, setForm] = useState({ text: '', parent: id, loading: false });
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { text, parent, loading } = form;

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (text.trim().length > 0) {
      setForm({ ...form, loading: true });
      const attachments = attachedFiles.map(file => file.id);
      await onSubmit(text, parent, attachments);
      setForm({ text: '', parent: id, loading: false });
      setAttachedFiles([]);
    }

    if (showReplies) {
      showReplies();
    }

    setIsFocus(false);
  };

  const removeFile = (file) => {
    setAttachedFiles(prev => prev.filter(prevFile => prevFile.id !== file.id));
  };

  const renderAttached = () => {
    return attachedFiles.map((file) => {
      return (
        <li key={file.id} className='tag'>
          <a href={FILES_BASE_URL + file.path} className='file-name'>
            {file.name.length < 30 ? file.name : `${file.name.substr(0, 30)}...`}
          </a>
          <X size={20} className='remove' onClick={() => removeFile(file)} />
        </li>
      );
    });
  };

  const handleFocusIn = () => {
    setIsFocus(true);
    onClickOut('new-message').then(() => {
      setIsFocus(false);
    });
  };

  return (
    <form className={`new-message ${isFocus || isReply ? 'new-message-focus' : ''} ${isReply && 'background'} ${loading || uploading ? 'loading' : ''}`} onSubmit={handleSubmit}>
      <div className='input'>
        <InputTextArea
          className='simple-scrollbar'
          placeholder={literals.yourMessage}
          value={text}
          maxLength={0}
          onChange={value => setForm({ ...form, text: value })}
          onFocus={handleFocusIn}
          isDisabled={loading || uploading || disabled}
        />
        <ul className='px-2'>
          {renderAttached()}
        </ul>
      </div>
      <div className='avatar-img'>
        <Avatar
          title={getFullName(user)}
          src={user?.avatar?.path}
          size={40}
        />
      </div>
      <div className='send-button'>
        <Button type='submit' loading={loading || uploading} disabled={disabled} title={literals.sendMessage} text={<Send size={18} />} />
      </div>
      <ButtonUploadFile literals={literals} className='attach-button' onSubmit={uploadFile} setAttachedFiles={setAttachedFiles} setLoading={setUploading}>
        <Paperclip title={literals.attachFile} size={18} />
      </ButtonUploadFile>
    </form>
  );
};

MessagesForm.propTypes = {
  literals: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  id: PropTypes.string,
  showReplies: PropTypes.func,
  isReply: PropTypes.bool,
  isFocus: PropTypes.bool,
  setIsFocus: PropTypes.func,
  disabled: PropTypes.bool,
  user: PropTypes.object,
};

export default MessagesForm;
