import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Loading from 'components/Loading';
import { getStartupVerifiedIcon } from 'utils/functions';
import { switchStartupVerified } from '../modules/actions';

const PopupVerifyStartup = (props) => {
  const {
    literals,
    startup,
    tableRefreshFlag,
    onClose,
  } = props;

  const [loading, setLoading] = useState(false);

  const switchVerified = async (type) => {
    setLoading(true);
    await switchStartupVerified(startup, type);
    tableRefreshFlag.increase();
    onClose();
  };

  return (
    <Popup title={literals.verify} onClose={onClose} size='small'>
      {loading
        ? <Loading mode='panel' hide={false} />
        : (
          <>
            <h6 className='fw-b mb-3'>{literals.chooseStartupVerify}</h6>
            <ul className='startupPlans-list'>
              <div className='box box-padding' onClick={() => switchVerified('non')}>
                <h6 className='fw-b'>{literals.notVerified}</h6>
                <span className='fs-sm'>{literals.notVerifiedDescription}</span>
              </div>
              <div className='box box-padding' onClick={() => switchVerified('team')}>
                <div className='d-flex'>
                  <h6 className='fw-b'>{literals.team}</h6>
                  {getStartupVerifiedIcon('team')}
                </div>
                <span className='fs-sm'>{literals.team}</span>
              </div>
              <div className='box box-padding' onClick={() => switchVerified('full')}>
                <div className='d-flex'>
                  <h6 className='fw-b'>{literals.full}</h6>
                  {getStartupVerifiedIcon('full')}
                </div>
                <span className='fs-sm'>{literals.full}</span>
              </div>
            </ul>
          </>
        )
      }
    </Popup>
  );
};

PopupVerifyStartup.propTypes = {
  literals: PropTypes.object.isRequired,
  startup: PropTypes.string.isRequired,
  tableRefreshFlag: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupVerifyStartup;
