import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Table from 'components/Table';
import InputText from 'components/Inputs/inputText';
import Button from 'components/Buttons/Button';
import Loading from 'components/Loading';
import { editInvestmentsShareholders, getInvestmentsShareholders } from 'routes/MyInvestments/modules/actions';
import './styles.scss';

const PopupEditShareholder = (props) => {
  const {
    literals,
    literalsCommon,
    startup,
    onEdit,
    onClose,
  } = props;

  const [loading, setLoading] = useState(false);
  const [shareholdersForm, setShareholdersForm] = useState();

  useEffect(() => {
    (async () => {
      let auxShareholders = await getInvestmentsShareholders();
      if (startup) {
        auxShareholders = auxShareholders.filter(sh => sh?.startup?.id === startup);
      }
      setShareholdersForm(auxShareholders.reduce((acc, curr) => {
        acc[curr.id] = { ...curr };
        return acc;
      }, {}));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const auxShareholders = Object.values(shareholdersForm).map(({
      id, taxId, firstname, lastname,
    }) => ({
      id, taxId, firstname, lastname,
    }));

    try {
      setLoading(true);
      const editedShareholders = await editInvestmentsShareholders(auxShareholders);
      if (onEdit) {
        onEdit(editedShareholders);
      }
      onClose();
    } catch (err) {
      setLoading(false);
    }
  };

  const handleChangeValue = (id, field, value) => {
    setShareholdersForm((prev) => {
      const aux = { ...prev };
      aux[id][field] = value;
      return aux;
    });
  };

  const columns = [
    {
      field: 'taxId',
      label: literals.taxid,
      width: 150,
      preRender: (taxId, row) => (
        <InputText
          placeholder={literals.taxid}
          onChange={v => handleChangeValue(row.id, 'taxId', v)}
          value={taxId || ''}
        />
      ),
    },
    {
      field: 'firstname',
      label: literalsCommon.name,
      grow: 1,
      preRender: (firstname, row) => (
        <InputText
          placeholder={literalsCommon.name}
          onChange={v => handleChangeValue(row.id, 'firstname', v)}
          value={firstname || ''}
        />
      ),
    },
    {
      field: 'lastname',
      label: literalsCommon.lastName,
      grow: 1,
      preRender: (lastname, row) => (row.type === 'individual' ? (
        <InputText
          placeholder={literalsCommon.lastName}
          onChange={v => handleChangeValue(row.id, 'lastname', v)}
          value={lastname || ''}
        />
      ) : null),
    },
  ];

  if (!startup) {
    columns.unshift(
      {
        field: 'startup',
        type: 'avatar',
        width: 45,
        label: 'Logo',
        preRender: v => v.logo,
        title: v => v.name,
      },
      {
        field: 'startup',
        label: literalsCommon.startup,
        width: 200,
        preRender: v => v.name,
      },
    );
  }

  const renderTable = () => {
    return (
      <>
        <Table
          className='edit-shareholders-table'
          columns={columns}
          searcher={false}
          fetch={() => ({ items: Object.values(shareholdersForm), total: Object.values(shareholdersForm).length })}
        />
        <div className='buttons'>
          <Button
            text={literalsCommon.save}
            onClick={handleSubmit}
            loading={loading}
          />
        </div>
      </>
    );
  };

  return (
    <Popup title={literals.editShareholders} size='large' onClose={onClose}>
      {!shareholdersForm
        ? <Loading mode='panel' hide={false} />
        : renderTable()
      }
    </Popup>
  );
};

PopupEditShareholder.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  startup: PropTypes.string,
  onEdit: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

PopupEditShareholder.defaultProps = {
  startup: null,
  onEdit: null,
};

export default PopupEditShareholder;
