import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import RequestAccessComponent from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.onboarding,
  literalsCommon: state.i18n.literals.common,
});

export default withRouter(connect(mapStateToProps)(RequestAccessComponent));
