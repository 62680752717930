/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  cleanFilename, formatDate, getIconFile, getTranslation,
} from 'utils/functions';
import { Link } from 'react-router-dom';
import { FILES_BASE_URL } from 'constants/apiConf';
import { Check } from 'lucide-react';
import './styles.scss';

const RenderOutput = ({
  type, value, symbol, answers, lang,
}) => {
  switch (type) {
    case 'text':
    case 'number':
      return (
        <div className={`output_value mb-0 ${symbol ? 'symbol' : ''}`}>
          <span className='value'>{value}</span>
          {symbol ? <span className='output_symbol'>{symbol}</span> : null}
        </div>
      );
    case 'textarea':
      return (
        <div className='output_value mb-0' style={{ minHeight: '150px' }}>
          {value}
        </div>
      );
    case 'select':
      return (
        <div className='output_value mb-0'>
          <span className='value'>{getTranslation(answers.find(answer => value === answer.id)?.text, lang)}</span>
        </div>
      );
    case 'date':
      return (
        <div className='output_value mb-0'>
          <span>{formatDate(value)}</span>
        </div>
      );
    case 'list':
    case 'checkbox':
      return (
        <div>
          {value.map(elem => (
            <span key={elem} className='output_keyword'>{getTranslation(answers.find(answer => answer.id === elem)?.text, lang)}</span>
          ))}
        </div>
      );
    case 'radiobutton':
      return (
        <div className='input_radio'>
          <div className='input_option'>
            <div className={`radio-custom ${value ? 'checked' : ''}`}><Check /></div>
          </div>
        </div>
      );
    case 'file':
    case 'document':
      return (
        <div>
          {value?.path && (
            <Link title={value.name} className='output_keyword' to={{ pathname: `${FILES_BASE_URL}${value.path}` }} target='_blank'>
              {getIconFile(value.name)}
              <span>{cleanFilename(value.name)}</span>
            </Link>
          )}
        </div>
      );
    case 'html':
      // eslint-disable-next-line react/no-danger
      return <p className='output_value mb-0' dangerouslySetInnerHTML={{ __html: value }} />;
    default:
      return null;
  }
};

const OutputValue = ({
  preText = '',
  className = '',
  onClick = null,
  clean = false,
  symbol = '',
  type = 'text',
  value = null,
  answers = [],
  lang = '',
}) => (
  <div
    className={`output_wrapper ${className} ${onClick ? 'pointer' : ''} ${clean ? 'clean' : ''}`}
    onClick={onClick}
  >
    {preText && <h6>{preText}</h6>}
    <RenderOutput
      type={type}
      value={value}
      symbol={symbol}
      answers={answers}
      lang={lang}
    />
  </div>
);

OutputValue.propTypes = {
  className: PropTypes.string,
  preText: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'textarea', 'select', 'date', 'list', 'checkbox', 'radiobutton', 'file', 'document', 'html']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]),
  symbol: PropTypes.string,
  lang: PropTypes.string,
  answers: PropTypes.array,
  onClick: PropTypes.func,
  clean: PropTypes.bool,
};

export default OutputValue;
