import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initPlatformAction, loadingAction, setGlobalPropertiesAction } from 'store/globalState/global';
import AppComponents from '../components';

const mapStateToProps = state => ({
  dialogLiterals: state.i18n.literals.dialog,
  sessionChecked: state.session.checked,
  isAuthenticated: state.session.authenticated,
  user: state.session.user,
  session: state.session,
  loading: state.global.loading,
  company: state.company,
  platform: state.global.platform,
});

const mapDispatchToProps = dispatch => ({
  loadingAction: bindActionCreators(loadingAction, dispatch),
  initPlatform: bindActionCreators(initPlatformAction, dispatch),
  setGlobalProperties: bindActionCreators(setGlobalPropertiesAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppComponents);
