import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import InternalForm from 'components/InternalForm';
import StartupSearcher from 'components/StartupSearcher';
import FundSearcher from 'components/FundSearcher';
import RequestAccess from 'components/RequestAccess';
import { Link, useSearchParams } from 'react-router-dom';
import { ROUTE_PATH } from 'routes';
import { ArrowLeft, DoorClosed } from 'lucide-react';
import { WEBIEW_MESSAGE } from 'constants/webview';
import { sendMessageToWebview } from 'utils/sendMessageToWebview';
import ProgressBar from './ProgressBar';
import RenderStartupInvite from './RenderStartupInvite';

export const FRONT_PAGE = 'frontPage';
export const STARTUP_SEARCHER = 'startupSearcher';
export const FUND_SEARCHER = 'fundSearcher';
export const FORM = 'form';
export const REQUEST_ACCESS = 'request_access';
export const MESSAGE_OWNER_EXIST = 'messageOwnerExist';
export const MESSAGE_REQUIRE_VALIDATION = 'requireValidation';
export const STARTUP_INVITE = 'startup_invite';

function OnboardingSteps(props) {
  const {
    platform,
    literals,
    view,
    answers: oldAnswers,
    onSubmitForm,
    match,
    startups,
    embed,
  } = props;

  const [searchParams] = useSearchParams();
  const startupParam = searchParams.get('startup');
  const external = searchParams.get('external');

  let defaultRender = FRONT_PAGE;
  if (view === 'startup') {
    if (startupParam) {
      defaultRender = STARTUP_INVITE;
    } else if (startups.length && !match.params?.id) {
      defaultRender = STARTUP_SEARCHER;
    }
  }

  if (embed) {
    if (view === 'startup') {
      defaultRender = STARTUP_SEARCHER;
    } else {
      defaultRender = FUND_SEARCHER;
    }
  }

  if (match.params.id) {
    defaultRender = FORM;
  }

  // eslint-disable-next-line no-nested-ternary
  const [render, setRender] = useState(defaultRender);
  const [formNumSteps, setFormNumSteps] = useState(0);
  const [formStepNumber, setFormStepNumber] = useState(0);
  const [answers, setAnswers] = useState(oldAnswers);
  const [startup, setStartup] = useState();
  const [fund, setFund] = useState();
  const [loading, setLoading] = useState(false);
  const FORM_STEP_QUESTIONS = 5;

  const getInfo = (type) => {
    const dataMap = {
      startup: {
        title: literals.companyData,
        subtitle: literals.subtitle,
      },
      fund: {
        title: literals.fundData,
        subtitle: literals.subtitleFund,
      },
      default: {
        title: literals.mentorData,
        subtitle: literals.subtitleMentor,
      },
    };

    const data = dataMap[view] || dataMap.default;
    return data[type] || '';
  };

  const checkStartup = async () => {
    if (startup.id === 'other') {
      setAnswers({ ...answers, name: startup.name });
      setRender(FORM);
    } else {
      setRender(REQUEST_ACCESS);
    }
  };

  const changeFormStep = (forward, stepAnswers) => {
    if (forward) {
      const newAnswers = { ...answers };
      Object.keys(stepAnswers).forEach((key) => {
        if (typeof stepAnswers[key] === 'object' && !(stepAnswers[key] instanceof Array)) {
          newAnswers[key] = { ...answers[key], ...stepAnswers[key] };
        } else {
          newAnswers[key] = stepAnswers[key];
        }
      });
      setAnswers(newAnswers);
      if (formStepNumber === (formNumSteps - 1)) {
        onSubmitForm(newAnswers);
      } else {
        setFormStepNumber(formStepNumber + 1);
      }
    } else {
      setFormStepNumber(formStepNumber - 1);
    }
  };

  const renderFirstStep = () => {
    return (
      <div className='onboarding-step onboarding-step-bg'>
        <h1>{`${literals.welcome} ${platform.name}`}</h1>
        <h3>{getInfo('subtitle')}</h3>
        <Button
          color='secondary'
          className='onBgColor'
          text={literals.letsStart}
          onClick={() => setRender(() => {
            switch (view) {
              case 'startup':
                return !match.params?.id ? STARTUP_SEARCHER : FORM;
              case 'fund':
                return !match.params?.id ? FUND_SEARCHER : FORM;
              default:
                return FORM;
            }
          })}
        />
      </div>
    );
  };

  const onLoadForm = (questions) => {
    setFormNumSteps(Math.ceil(questions.length / FORM_STEP_QUESTIONS));
  };

  const renderFormStep = () => {
    const buttons = [];
    if (formStepNumber > 0 || (['startup', 'fund'].includes(view) && !match.params?.id)) {
      buttons.push({
        key: 'back',
        text: literals.previous,
        color: 'secondary',
        onClick: !formStepNumber
          ? () => setRender(view === 'startup' ? STARTUP_SEARCHER : FUND_SEARCHER)
          : (ev) => { ev.preventDefault(); changeFormStep(false); },
      });
    }
    buttons.push({
      key: 'submit',
      type: 'submit',
      text: formStepNumber === (formNumSteps - 1) ? literals.start : literals.next,
      onClick: a => changeFormStep(true, a),
    });

    return (
      <>
        {
          formNumSteps ? (
            <ProgressBar stepNumber={formStepNumber + 1} numberOfSteps={formNumSteps} />
          ) : null
        }
        <InternalForm
          type={view}
          answers={answers}
          page={formStepNumber}
          size={FORM_STEP_QUESTIONS}
          buttons={buttons}
          onLoad={onLoadForm}
        />
      </>
    );
  };

  const renderFundStep = () => {
    return (
      <>
        <p className='step-message'>{literals.searchFund}</p>
        <FundSearcher
          preText={literals.name}
          placeholder={literals.writeFundName}
          value={fund}
          onChange={setFund}
          isDisabled={loading}
          isRequired
        />
        <div className='buttons'>
          <Button
            text={literals.next}
            onClick={() => {
              if (fund.id !== 'other') {
                if (embed) {
                  setLoading(true);
                  sendMessageToWebview(WEBIEW_MESSAGE.NEW_FUND, fund);
                }
              } else {
                setAnswers({ ...answers, name: fund.name });
                setRender(FORM);
              }
            }}
            loading={loading}
            disabled={!fund}
          />
        </div>
      </>
    );
  };

  const renderStartupStep = () => {
    return (
      <>
        <p className='step-message'>{literals.searchStartup}</p>
        <StartupSearcher
          preText={literals.name}
          placeholder={literals.writeStartupName}
          value={startup}
          onChange={setStartup}
          isDisabled={loading}
          isRequired
        />
        <div className='buttons'>
          <Button
            text={literals.next}
            onClick={checkStartup}
            loading={loading}
            disabled={!startup}
          />
        </div>
      </>
    );
  };

  return (
    <div className={`onBoarding ${render === FRONT_PAGE ? 'firstStep' : ''}`}>
      {!embed && (
        <div className='onboarding-header'>
          <Link to={ROUTE_PATH.PREHOME} className='closeOnboarding'>
            <ArrowLeft />
          </Link>
          <Link to={ROUTE_PATH.LOGOUT} className='onboarding-header--logout'>
            <span>{literals.logout}</span>
            <DoorClosed size={20} />
          </Link>
        </div>
      )}

      <div className='onboarding-wrapper'>
        {render === FRONT_PAGE
          ? renderFirstStep()
          : (
            <div className='onboarding-next-step'>
              {!embed && (
                <div className='onboarding-step'>
                  <h1 className='h2'>{getInfo('title')}</h1>
                  <h3 className='h4'>{getInfo('subtitle')}</h3>
                </div>
              )}
              <div className='onboarding-form-wrapper'>
                {render === STARTUP_SEARCHER && renderStartupStep()}
                {render === FUND_SEARCHER && renderFundStep()}
                {render === FORM && renderFormStep()}
                {render === STARTUP_INVITE && (
                  <RenderStartupInvite
                    literals={literals}
                    id={startupParam}
                    render={render}
                    setRender={setRender}
                    startup={startup}
                    setStartup={setStartup}
                    external={external === '1'}
                  />
                )}
                {[REQUEST_ACCESS, MESSAGE_OWNER_EXIST, MESSAGE_REQUIRE_VALIDATION].includes(render) && (
                  <RequestAccess
                    startup={startup}
                    render={render}
                    setRender={setRender}
                    embed={embed}
                  />
                )}
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

OnboardingSteps.propTypes = {
  match: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  view: PropTypes.string.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  startups: PropTypes.array.isRequired,
  answers: PropTypes.object,
  embed: PropTypes.bool,
};

OnboardingSteps.defaultProps = {
  answers: null,
  embed: false,
};

export default OnboardingSteps;
