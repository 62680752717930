import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import StatCard from 'components/Statistics/StatCard';
import ReactECharts from 'echarts-for-react';
import SkeletonBarChart from 'components/Loading/skeletonBarChart';
import { formatNumber } from 'utils/functions';

const SummaryAdminDataroom = ({ literals, dataroom = null }) => {
  const dataroomChart = {
    series: [{
      name: literals.folders,
      type: 'line',
      symbol: 'none',
      data: dataroom?.foldersPerDay?.map(op => op.value) || [],
    }, {
      name: literals.documents,
      type: 'line',
      symbol: 'none',
      data: dataroom?.filesPerDay?.map(w => w.value) || [],
    }],

    tooltip: {
      trigger: 'axis', valueFormatter: v => formatNumber(v),
    },
    legend: {
      data: [literals.folders, literals.documents],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: dataroom?.foldersPerDay?.map(op => op.date.substr(5, 6)) || [],
      axisLabel: { rotate: 45 },
    },
    yAxis: {
      type: 'value',
    },
  };

  return (
    <>
      <h4 className='fw-b'>{literals.dataroom}</h4>
      <div className='admin-summary-mentorings row mb-4'>
        <div className='col-12 col-md-4'>
          <StatCard
            title={literals.folders}
            value={dataroom?.folders}
            loading={!dataroom}
          />
          <StatCard
            title={literals.documents}
            value={dataroom?.documents}
            loading={!dataroom}
          />
        </div>
        <div className='col-12 col-md-8'>
          <Panel>
            <PanelHeader title={literals.lastMonthActivity} />
            <PanelBody>
              {
                !dataroom ? (
                  <div className='d-flex align-items-center justify-content-center'>
                    <SkeletonBarChart height='200px' />
                  </div>
                ) : (
                  <ReactECharts option={dataroomChart} style={{ height: 222 }} />
                )
              }
            </PanelBody>
          </Panel>
        </div>
      </div>
    </>
  );
};

SummaryAdminDataroom.propTypes = {
  literals: PropTypes.object.isRequired,
  dataroom: PropTypes.object,
};

export default SummaryAdminDataroom;
