export const primary = '#2196F3';
export const secondary = '#F50057';

export const white = '#FFFFFF';
export const black = '#373737';

export const gray = '#E0E0E0';
export const darkGray = '#646464';
export const mediumGray = '#979797';
export const lightGray = '#CACACA';

export const bgDarkGray = '#F2F2F2';
export const bgLightGray = '#F6F6F6';

export const orange = '#FE8622';
export const darkOrange = '#FE622D';
export const flatOrange = '#FC4236';
export const red = '#FF2400';
export const purple = '#B71B46';
export const green = '#2ECC71';

export const colorGraphUp = '#88E927';
export const colorGraphDown = '#E70E31';

export const graphColors = ['#673AB7', '#4CAF50', '#2196F3', '#00BCD4', '#CDDC39', '#795548', '#FF9800', '#673AB7', '#F44336', '#8BC34A', '#607D8B'];

export default {
  primary,
  secondary,
  white,
  black,
  gray,
  darkGray,
  mediumGray,
  lightGray,
  bgDarkGray,
  bgLightGray,
  orange,
  darkOrange,
  flatOrange,
  red,
  purple,
  green,
};
