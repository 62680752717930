import initialState from '../../../store/globalState/initialState';

import {
  FETCH_MENTORING,
  FETCH_MENTORING_SUCCESS,
  FETCH_MENTORING_FAILURE,
  // FETCH_MENTORING_MESSAGES,
  // FETCH_MENTORING_MESSAGES_SUCCESS,
  // FETCH_MENTORING_MESSAGES_FAILURE,
  // CREATE_MENTORING_MESSAGE_SUCCESS,
  FETCH_MENTORING_MEETINGS,
  FETCH_MENTORING_MEETINGS_SUCCESS,
  FETCH_MENTORING_MEETINGS_FAILURE,
  CREATE_MENTORING_MEETINGS_SUCCESS,
  EDIT_MENTORING_MEETINGS_SUCCESS,
  DELETE_MENTORING_MEETINGS_SUCCESS,
  FETCH_MENTORING_DOCUMENTS,
  FETCH_MENTORING_DOCUMENTS_SUCCESS,
  FETCH_MENTORING_DOCUMENTS_FAILURE,
  CREATE_MENTORING_DOCUMENTS_SUCCESS,
  FETCH_MENTORING_REPORTS,
  FETCH_MENTORING_REPORTS_SUCCESS,
  FETCH_MENTORING_REPORTS_FAILURE,
  CREATE_MENTORING_REPORTS_SUCCESS,
  EDIT_MENTORING_REPORTS_SUCCESS,
  DELETE_MENTORING_REPORTS_SUCCESS,
  DELETE_MENTORING_DOCUMENTS_SUCCESS,
} from './types';

export default function mentoringReducer(state = initialState.mentoring, action) {
  switch (action.type) {
    case FETCH_MENTORING:
      return {
        ...state,
        loading: true,
        mentoring: {},
        messages: null,
        meetings: null,
        documents: null,
        reports: null,
        error: '',
      };
    case FETCH_MENTORING_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        mentoring: action.payload,
      };
    case FETCH_MENTORING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // case FETCH_MENTORING_MESSAGES:
    case FETCH_MENTORING_MEETINGS:
    case FETCH_MENTORING_DOCUMENTS:
    case FETCH_MENTORING_REPORTS:
      return {
        ...state,
        loading: true,
      };
    // case FETCH_MENTORING_MESSAGES_FAILURE:
    case FETCH_MENTORING_MEETINGS_FAILURE:
    case FETCH_MENTORING_DOCUMENTS_FAILURE:
    case FETCH_MENTORING_REPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // case FETCH_MENTORING_MESSAGES_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     messages: action.payload,
    //   };
    case FETCH_MENTORING_MEETINGS_SUCCESS: {
      const newMeetings = [...action.payload.items];

      return {
        ...state,
        loading: false,
        meetings: {
          total: action.payload,
          items: newMeetings,
        },
      };
    }
    // case CREATE_MENTORING_MESSAGE_SUCCESS:
    //   return {
    //     ...state,
    //     messages: {
    //       ...state.messages,
    //       total: incrementOne(action.payload, state.messages.total),
    //       items: insertMessage(action.payload, state.messages.items),
    //     },
    //   };

    case CREATE_MENTORING_MEETINGS_SUCCESS: {
      const newMeetings = [action.payload, ...state.meetings.items];

      return {
        ...state,
        meetings: {
          ...state.meetings,
          total: state.meetings.total + 1,
          items: newMeetings,
        },
      };
    }

    case EDIT_MENTORING_MEETINGS_SUCCESS: {
      const newMeetings = [...state.meetings.items];

      newMeetings.forEach((meeting, i) => {
        if (meeting.id === action.payload.id) {
          newMeetings[i] = action.payload;
        }
      });

      newMeetings.sort((a, b) => (new Date(a.date).getTime() - new Date(b.date).getTime()));

      return {
        ...state,
        meetings: {
          ...state.meetings,
          items: newMeetings,
        },
      };
    }
    case DELETE_MENTORING_MEETINGS_SUCCESS: {
      const newMeetings = [...state.meetings.items];

      newMeetings.forEach((meeting, i) => {
        if (meeting.id === action.payload) {
          newMeetings.splice(i, 1);
        }
      });

      newMeetings.sort((a, b) => (new Date(a.date).getTime() - new Date(b.date).getTime()));

      return {
        ...state,
        meetings: {
          ...state.meetings,
          items: newMeetings,
        },
      };
    }
    case FETCH_MENTORING_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: action.payload,
      };
    case CREATE_MENTORING_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: {
          ...state.documents,
          total: state.documents.total + 1,
          items: [action.payload, ...state.documents.items],
        },
      };
    case DELETE_MENTORING_DOCUMENTS_SUCCESS: {
      const newDocuments = [...state.documents.items];

      newDocuments.forEach((document, i) => {
        if (document.id === action.payload) {
          newDocuments.splice(i, 1);
        }
      });

      return {
        ...state,
        documents: {
          ...state.documents,
          items: newDocuments,
        },
      };
    }
    case FETCH_MENTORING_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        reports: action.payload,
      };
    case CREATE_MENTORING_REPORTS_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          total: state.reports.total + 1,
          items: [action.payload, ...state.reports.items],
        },
      };
    case EDIT_MENTORING_REPORTS_SUCCESS: {
      const newReports = [...state.reports.items];

      newReports.forEach((report, i) => {
        if (report.id === action.payload.id) {
          newReports[i] = action.payload;
        }
      });

      return {
        ...state,
        reports: {
          ...state.reports,
          items: newReports,
        },
      };
    }
    case DELETE_MENTORING_REPORTS_SUCCESS: {
      const newReports = [...state.reports.items];

      newReports.forEach((report, i) => {
        if (report.id === action.payload) {
          newReports.splice(i, 1);
        }
      });

      newReports.sort((a, b) => (new Date(a.date).getTime() - new Date(b.date).getTime()));

      return {
        ...state,
        reports: {
          ...state.reports,
          items: newReports,
        },
      };
    }
    default:
      return state;
  }
}
