import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel/components/';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Table from 'components/Table';
import { Eye } from 'lucide-react';
import { useCounter } from 'utils/customHooks';
import { formatDate, formatTime } from 'utils/functions';
import { getScriptLogs } from '../modules/actions';
import ScriptLogDetails from './scriptLogDetails';
import './styles.scss';

const AdminScripts = ({ literals, literalsCommon }) => {
  const [detailsPopup, setDetailsPopup] = useState(false);
  const tableRefreshFlag = useCounter(0);

  const columns = [
    {
      field: 'name',
      label: literalsCommon.name,

    },
    {
      field: 'runtime',
      label: literals.runtime,
      preRender: date => formatTime(date, 'seconds', 4),
      width: 120,
    },
    {
      field: 'createdAt',
      label: literalsCommon.createdAt,
      preRender: date => formatDate(date, { time: true }),
      width: 120,
    },
    {
      field: 'id',
      type: 'menu',
      buttons: [
        {
          icon: Eye,
          text: literalsCommon.view,
          onClick: setDetailsPopup,
        },
      ],
    },
  ];

  return (
    <Panel>
      <PanelHeader title={literals.title} />
      <PanelBody>
        <Table
          columns={columns}
          onClickRow={setDetailsPopup}
          fetch={getScriptLogs}
          forceFetch={tableRefreshFlag.value}
        />
      </PanelBody>
      { detailsPopup && (
        <ScriptLogDetails
          literals={literals}
          literalsCommon={literalsCommon}
          script={detailsPopup}
          onClose={() => setDetailsPopup(false)}
        />
      )}
    </Panel>
  );
};

AdminScripts.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
};

export default AdminScripts;
