import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import InputNumber from 'components/Inputs/inputNumber';
import InputText from 'components/Inputs/inputText';
import Loading from 'components/Loading';
import Popup from 'components/Popup';
import Table from 'components/Table';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { formatDate, formatNumber } from 'utils/functions';
import { fetchCaptableOptions, getVestingPlan } from '../modules/actions';
import PopupVestMilestones from './PopupVestMilestones';
import './styles.scss';
import PopupVestCalendar from './PopupVestCalendar';

const PopupInfoVestingPlan = (props) => {
  const {
    id,
    onClose,
    literals,
    literalsCommon,
    date,
    startup,
    currency,
  } = props;

  const PAGE_SIZE = 10;
  const [data, setData] = useState();
  const [captableOptions, setCaptableOptions] = useState();
  const [milestonesPopup, setMilestonesPopup] = useState(false);
  const [calendarPopup, setCalendarPopup] = useState(false);
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;

  const getData = async () => {
    const vestingPlan = typeof id === 'string'
      ? await getVestingPlan(startup, id)
      : id;
    setData(vestingPlan);
  };

  const getOptions = async () => {
    const auxId = id?.id || id;
    const capOptions = await fetchCaptableOptions(startup, date, {
      filters: { 'vesting_plan.id': auxId }, captable: captableId,
    });
    setCaptableOptions(capOptions);
  };

  useEffect(() => {
    getData();
    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPercentage = () => {
    let percentage = 0;
    if (data.periods.length > 0 && (data.type === 'time' || data.type === 'hybrid')) {
      data.periods.forEach((object) => { percentage += object.percentage; });
    }
    if (data.milestones.length > 0 && (data.type === 'milestones' || data.type === 'hybrid')) {
      data.milestones.forEach((object) => { percentage += object.percentage; });
    }
    return percentage;
  };

  const optionsColumns = [
    {
      field: 'name',
      type: 'html',
      label: literalsCommon.name,
      grow: 2,
      preRender: (f, r) => `${f} <br/>
        ${literals[r.type]}`,
    },
    {
      field: 'number',
      type: 'html',
      label: literals.number,
      preRender: (f, r) => `${formatNumber(f, 0)} (${formatNumber(r.price, 0)}${currency.symbol})<br/>
        ${literals.vested}: ${formatNumber(r.vested, 0)}<br/>
        ${r.type === 'stockOption' ? `${literals.exercised}: ${formatNumber(r.exercised, 0)}` : ''}`,
    },
    {
      field: 'capital',
      label: literals.capital,
      preRender: v => `${formatNumber(v, 0, { dec: 2 })}${currency.symbol}`,
    },
    {
      field: 'vestingPlan',
      label: literals.startDate,
      preRender: v => (v?.startDate ? formatDate(v.startDate) : '-'),
    },
    {
      field: 'expirationDate',
      label: literals.expirationDate,
      preRender: v => (v ? formatDate(v) : '-'),
    },
  ];

  const renderInfo = () => {
    if (!data) return <Loading mode='panel' hide={false} />;

    return (
      <>
        <div className='box box-padding box-mb'>
          <div className='row'>
            <div className='col-md-4'>
              <InputText preText={literals.type} value={literals[data.type] || ''} isDisabled />
            </div>
            { data.type && (
              <div className='col-md-8'>
                <InputText preText={literalsCommon.name} value={data.name || ''} isDisabled />
              </div>
            )}
          </div>
          {data.type === 'basic' && (
            <div className='row'>
              <div className='col-md-4'>
                <InputNumber preText={literals.period} value={data.basic.period || 0} decimals={0} isDisabled />
              </div>
              <div className='col-md-4'>
                <InputNumber preText={literals.cliff} value={data.basic.cliff || 0} decimals={0} isDisabled />
              </div>
              <div className='col-md-4'>
                <InputNumber preText={literals.intervals} value={data.basic.intervals || 0} decimals={0} isDisabled />
              </div>
            </div>
          )}
          {(data.type === 'time' || data.type === 'hybrid') && (
            <>
              <div className='vestingPlan-time'>
                <h5 className='mb-0'>{literals.periods}</h5>
                <hr />
                <div className='time-box'>
                  {data.periods.map((elem, i) => (
                    <div key={`period-${i}`} className='row row-elem'>
                      <div className='col-md-2'>
                        <InputNumber preText={literals.length} value={elem.length || 0} decimals={0} isDisabled />
                      </div>
                      <div className='col'>
                        <InputText preText={literals.frequency} value={literals[elem.frequency] || ''} decimals={0} isDisabled />
                      </div>
                      <div className='col-md-4'>
                        <InputText preText={literals.schedule} value={literals[elem.schedule] || ''} decimals={0} isDisabled />
                      </div>
                      <div className='col-md-3'>
                        <InputNumber preText={literals.percentage} value={elem.percentage || 0} decimals={0} isDisabled />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          {(data.type === 'milestones' || data.type === 'hybrid') && (
            <div className='vestingPlan-milestone'>
              <h5 className='mb-0'>{literals.milestones}</h5>
              <hr />
              <div className='milestone-box'>
                {data.milestones.map((elem, i) => (
                  <div key={`milestone-${i}`} className='row row-elem'>
                    <div className='col-md-8'>
                      <InputText preText={literals.title} value={elem.title || ''} isDisabled />
                    </div>
                    <div className='col'>
                      <InputNumber preText={literals.percentage} value={elem.percentage || 0} isDisabled />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {(data.type === 'milestones' || data.type === 'hybrid' || data.type === 'time') && (
            <div className='row'>
              <div className='col-md-4 vestingPlan-percentage'>
                <InputText preText={literals.overallPercentage} value={`${getPercentage()}%`} isDisabled />
              </div>
            </div>
          )}
        </div>
        <div className='buttons'>
          {['basic', 'periods', 'hybrid'].includes(data.type) && typeof id !== 'string' && (
            <Button
              color='secondary'
              text={literals.showCalendar}
              onClick={() => setCalendarPopup(true)}
            />
          )}
          {['milestones', 'hybrid'].includes(data.type) && typeof id !== 'string' && (
            <Button
              text={literals.confirmMilestones}
              onClick={() => setMilestonesPopup(true)}
            />
          )}
        </div>

      </>
    );
  };

  return (
    <Popup size='large' title={literals.infoVestingsPlans} onClose={onClose}>
      <div className='popUpVestingPlan'>
        {renderInfo()}
        {data && captableOptions ? (
          <div className='box box-padding box-mb'>
            <Table
              searcher={false}
              columns={optionsColumns}
              fetch={() => ({ total: captableOptions.total, items: captableOptions.items })}
              pageSize={PAGE_SIZE}
            />
          </div>
        ) : (
          <Loading hide={false} mode='panel' />
        )}
      </div>

      {milestonesPopup && (
        <PopupVestMilestones
          id={data.id}
          startup={startup}
          captable={captableId}
          literals={literals}
          literalsCommon={literalsCommon}
          onClose={() => setMilestonesPopup(false)}
        />
      )}
      {calendarPopup && (
        <PopupVestCalendar
          id={data.id}
          startup={startup}
          captable={captableId}
          literals={literals}
          literalsCommon={literalsCommon}
          onClose={() => setCalendarPopup(false)}
        />
      )}
    </Popup>
  );
};

PopupInfoVestingPlan.propTypes = {
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  startup: PropTypes.string.isRequired,
  currency: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  date: PropTypes.string,
};

PopupInfoVestingPlan.defaultProps = {
  date: new Date().toISOString().split('T')[0],
};

const mapStateToProps = state => ({
  literals: state.i18n.literals.vestingsPlans,
  literalsCommon: state.i18n.literals.common,
  currency: state.global.currency,
});

export default connect(mapStateToProps)(PopupInfoVestingPlan);
