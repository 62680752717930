import { useDispatch, useSelector } from 'react-redux';
import { closeDialogAction, showDialogAction } from '../../../store/globalState/global';

let resolveCallback;
function useDialog() {
  const dispatch = useDispatch();
  const confirmState = useSelector(state => state.global.dialog);

  const closeDialog = (action) => {
    dispatch(closeDialogAction());
    document.body.classList.remove('popup-open');
    resolveCallback(action);
  };

  const onClick = (action) => {
    closeDialog(action);
  };

  const executeLoading = async (func) => {
    const response = await func();
    closeDialog(response);
  };

  const dialog = (config) => {
    dispatch(showDialogAction(config));
    document.body.classList.add('popup-open');
    if (config.type === 'loading' && typeof config.execute === 'function') {
      executeLoading(config.execute);
    }
    return new Promise((res) => {
      resolveCallback = res;
    });
  };

  return {
    dialog,
    onClick,
    confirmState,
  };
}

export default useDialog;
