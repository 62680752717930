import { connect } from 'react-redux';
import WaterfallComparator from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.captable,
  literalsCommon: state.i18n.literals.common,
  user: state.session.user,
  currency: state.global.currency,
  startup: state.global.startup,
  captable: state.captable,
});
export default connect(mapStateToProps)(WaterfallComparator);
