import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import ButtonsDotsMenu from 'components/Buttons/ButtonsDotsMenu';
import useDialog from 'components/Dialog/components/useDialog';
import { ROUTE_PATH } from 'routes';
import { ChevronDown, Download, Trash } from 'lucide-react';
import { formatDate, getFullName, getIconFile } from 'utils/functions';
import { deleteDocument } from '../modules/actions';

const PrevVersionsTimeline = ({
  literals,
  literalsCommon,
  file,
  setContent,
  setFile = null,
}) => {
  const [showMore, setShowMore] = useState(false);
  const { dialog } = useDialog();

  const handleDeleteVersion = async (version) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literalsCommon.confirmDelete,
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            const doc = await deleteDocument(file.id, { v: version });
            if (setContent) {
              setContent(prev => ({
                items: prev.items.map(item => (item.id === doc.id ? doc : item)),
                total: prev.total,
              }));
            }
            if (setFile) {
              setFile(doc);
            }
          } catch (error) {
            let errorMessage = '';
            if (error?.status === 401) {
              errorMessage = literals.noPermissions;
            }
            await dialog({
              type: 'error',
              text: (
                <>
                  <strong>{literals.error}</strong>
                  <br />
                  {errorMessage}
                </>
              ),
            });
          }
        },
      });
    }
  };

  const versions = file.previousVersions.map((version, i) => {
    const vUploadedAt = version.uploadedAt || file.createdAt;
    const vUploadedBy = version?.uploadedBy || file.createdBy;
    return (
      <a
        key={version.document}
        className='file-previous-version'
        href={ROUTE_PATH.setDownloadFile(file.hash, version.name, false, `?v=${version.document}`)}
        target='_blank'
        rel='noopener noreferrer'
      >
        <div className='d-flex align-items-center'>
          <div className='file-version--icon'>{getIconFile(version.name)}</div>
          <div className='file-version--content'>
            <h6 className='file-version--name' title={version.name}>{`v${i + 1}.${version.name}`}</h6>
            <p className='mb-0 fs-small fc-secondary'>{formatDate(vUploadedAt, { time: true })}</p>
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <Avatar
            title={getFullName(vUploadedBy)}
            src={version.uploadedBy?.avatar?.path}
            size={35}
            className='mx-2'
          />
          <ButtonsDotsMenu
            size={20}
            offset={{ top: 2, left: 20 }}
            buttons={[
              { icon: Download, text: literalsCommon.download, onClick: () => window.open(ROUTE_PATH.setDownloadFile(file.hash, version.name, false, `?v=${version.document}`)) },
              { icon: Trash, text: literalsCommon.delete, onClick: () => handleDeleteVersion(version.document) },
            ]}
          />
        </div>
      </a>
    );
  });

  const maxHeight = file.previousVersions.length * 62;
  return (
    <div className='file-previous-versions'>
      <div
        className={`file-previous-versions--timeline ${showMore ? 'open' : ''}`}
        style={showMore ? { maxHeight, transition: maxHeight * 0.2 } : {}}
      >
        <h6 className='fw-b'>{literals.previousVersions}</h6>
        { file.previousVersions.length
          ? versions.reverse()
          : <span className='fc-secondary ml-3'>{literals.noPrevVersions}</span>
        }
      </div>
      {file.previousVersions.length > 3 ? (
        <span
          onClick={() => setShowMore(!showMore)}
          className='file-previous-versions--showMore'
        >
          <span>{literalsCommon.viewMore}</span>
          <ChevronDown size={14} className={showMore ? 'rotateSvg' : ''} />
        </span>
      ) : null}
    </div>
  );
};

PrevVersionsTimeline.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  file: PropTypes.object.isRequired,
  setContent: PropTypes.func.isRequired,
  setFile: PropTypes.func,
};

export default PrevVersionsTimeline;
