export const FETCH_STARTUP_QUESTIONS_INIT = 'FETCH_STARTUP_QUESTIONS_INIT';
export const FETCH_STARTUP_QUESTIONS_SUCCESS = 'FETCH_STARTUP_QUESTIONS_SUCCESS';
export const FETCH_STARTUP_QUESTIONS_FAILURE = 'FETCH_STARTUP_QUESTIONS_FAILURE';

export const FETCH_MENTOR_QUESTIONS_INIT = 'FETCH_MENTOR_QUESTIONS_INIT';
export const FETCH_MENTOR_QUESTIONS_SUCCESS = 'FETCH_MENTOR_QUESTIONS_SUCCESS';
export const FETCH_MENTOR_QUESTIONS_FAILURE = 'FETCH_MENTOR_QUESTIONS_FAILURE';

export const FETCH_INVESTOR_QUESTIONS_SUCCESS = 'FETCH_INVESTOR_QUESTIONS_SUCCESS';

export const FETCH_FUND_QUESTIONS_INIT = 'FETCH_FUND_QUESTIONS_INIT';
export const FETCH_FUND_QUESTIONS_SUCCESS = 'FETCH_FUND_QUESTIONS_SUCCESS';
