import { fetchFormStartupAction } from 'components/InternalForm/modules/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MyPosition from '../components';

function mapStateToProps(state) {
  return {
    literals: state.i18n.literals.myPosition,
    literalsCommon: state.i18n.literals.common,
    literalsCaptable: state.i18n.literals.captable,
    literalsOnboarding: state.i18n.literals.onboarding,
    user: state.session.user,
    currency: state.global.currency,
    lang: state.i18n.language,
    forms: state.forms,
    platform: state.global.platform,
    startup: state.global.startup,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchFormStartup: bindActionCreators(fetchFormStartupAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyPosition);
