import StartupsCaptablesInstance from 'modules/startupsCaptables/repository';
import { apiDownloadFile } from 'modules/actions';
import { API_WATERFALL_EXCEL } from 'modules/apiEndpoints';

export const getCaptableWaterfalls = (id, opts) => {
  return StartupsCaptablesInstance.getStartupCaptableWaterfalls(id, opts);
};

export const deleteCaptableWaterfall = (id, waterfall) => {
  return StartupsCaptablesInstance.deleteStartupCaptableWaterfalls(id, waterfall);
};

export const deleteMassiveWaterfalls = (id, waterfalls) => {
  return StartupsCaptablesInstance.massiveDeleteStartupCaptableWaterfalls(id, { ids: waterfalls });
};

export function downloadWaterfallExcel(id, waterfall, filename = 'waterfall.xlsx') {
  return apiDownloadFile(API_WATERFALL_EXCEL(id, waterfall), filename);
}
