/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import InputText from 'components/Inputs/inputText';
import Button from 'components/Buttons/Button';
import useDialog from 'components/Dialog/components/useDialog';
import Alert from 'components/Alert';
import InputPermission from 'components/Inputs/InputPermission';
import { TOOL_DATA_ROOM } from 'constants/tools';
import { initPermission } from 'utils/functions/initPermissions';
import { PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import { createFolder } from '../modules/actions';
import { getFolderName } from '.';

const PopupCreateRename = ({
  startup,
  literals,
  rolePath,
  parent = null,
  onClose,
  setFolders,
  setSubfolders = () => {},
  setRenamedFolder,
  folder,
}) => {
  const editFolder = typeof folder === 'object';
  const DEFAULT_FOLDER = editFolder && folder?.related?.type;
  const parentId = parent?.id || `root.${rolePath}.${startup.id}`;

  const canEditParent = parent.permission.level > 1;

  const [elemName, setElemName] = useState(DEFAULT_FOLDER ? getFolderName(folder, literals) : (folder?.name || ''));
  const [permission, setPermission] = useState(initPermission(
    folder?.id ? folder : (canEditParent ? parent : null),
    PERMISSION_SCOPES.STARTUP,
    startup,
    TOOL_DATA_ROOM,
  ));
  const [loading, setLoading] = useState(false);
  const { dialog } = useDialog();

  const createNewFolder = (auxFolders, newFolder) => {
    return auxFolders.map((auxFolder) => {
      if (auxFolder.id === parentId) {
        return {
          ...auxFolder,
          stats: { ...auxFolder.stats, auxFolders: auxFolder.stats.auxFolders + 1 },
          childs: auxFolder.childs ? [newFolder, ...auxFolder.childs] : null,
        };
      }
      return auxFolder.childs
        ? { ...auxFolder, childs: createNewFolder(auxFolder.childs, newFolder) }
        : auxFolder;
    });
  };

  const handleCreateFolder = async () => {
    await createFolder(rolePath, startup.id, {
      name: elemName, parent: parentId, permission,
    })
      .then((newFolder) => {
        if (parentId !== `root.${rolePath}.${startup.id}`) {
          setFolders(prev => createNewFolder(prev, newFolder));
        } else {
          setFolders(prev => [newFolder, ...prev]);
        }
        setSubfolders(prev => [newFolder, ...prev]);
        onClose();
      })
      .catch(async (error) => {
        let errorMessage = '';
        if (error?.status === 401) {
          errorMessage = literals.noPermissions;
        }
        await dialog({
          type: 'error',
          text: (
            <>
              <strong>{literals.error}</strong>
              <br />
              {errorMessage}
            </>
          ),
        });
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    setLoading(true);
    if (editFolder) {
      setRenamedFolder({ ...folder, name: elemName, permission });
      onClose();
    } else {
      handleCreateFolder();
    }
  };

  return (
    <Popup
      title={editFolder ? literals.editFolder : literals.createNewFolder}
      onClose={onClose}
    >
      {
        DEFAULT_FOLDER ? (
          <Alert type='warning' text={literals.cantEditDefaultFolders} />
        ) : null
      }
      <div className='row'>
        <div className='col-12 col-md-7'>
          <InputText
            preText={literals.folder}
            placeholder={literals.folder}
            onChange={v => setElemName(v)}
            value={elemName}
            isDisabled={DEFAULT_FOLDER}
            isRequired
          />
        </div>
        <div className='col-12 col-md-5'>
          <InputPermission
            scope={{ type: PERMISSION_SCOPES.STARTUP, id: startup.id }}
            element={{ type: PERMISSION_TYPES.FOLDER, id: folder?.id }}
            value={permission}
            onChange={setPermission}
          />
        </div>
      </div>
      {
        !DEFAULT_FOLDER ? (
          <div className='d-flex justify-content-end'>
            <Button
              onClick={handleSubmit}
              text={editFolder ? literals.editFolder : literals.createFolder}
              disabled={!elemName}
              loading={loading}
            />
          </div>
        ) : null
      }
    </Popup>
  );
};

PopupCreateRename.propTypes = {
  startup: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  rolePath: PropTypes.string.isRequired,
  parent: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  setFolders: PropTypes.func.isRequired,
  setSubfolders: PropTypes.func,
  setRenamedFolder: PropTypes.func.isRequired,
  folder: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
};

export default PopupCreateRename;
