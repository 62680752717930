import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import {
  MessageCircle, Asterisk, RotateCw, CornerDownRight,
} from 'lucide-react';
import { getFullName, nl2br } from 'utils/functions';
import { FILES_BASE_URL } from 'constants/apiConf';
import { readMessages } from '../modules/actions';
import MessagesForm from './MessagesForm';

const Message = (props) => {
  const {
    id,
    user,
    level,
    message,
    parentId,
    isRead,
    fDate,
    literals,
    canReply,
    type,
    messagesId,
    mode,
    canViewReplies,
    onSubmit,
    answers,
    attachments,
    actualReplyForm,
    setActualReplyForm,
    isReply,
    setOffset,
    userSession,
    setNumUnreadMessages,
  } = props;

  const [readMore, setReadMore] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [repliesNumber, setRepliesNumber] = useState(5);
  const messageRef = useRef();
  const formRef = useRef();
  const limitChars = 150;

  const unreadAnswersNum = answers ? answers.filter(answer => !answer.isRead).length : null;

  let formatedMessage = message;
  if (!readMore && formatedMessage.length > limitChars) {
    formatedMessage = `${message.substr(0, limitChars)}...`;
  }
  formatedMessage = nl2br(formatedMessage);

  useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  });

  useEffect(() => {
    if (messageRef.current) {
      const { offsetTop, offsetHeight } = messageRef.current;
      if (setOffset) {
        setOffset((prevValue) => {
          if (isRead) {
            return prevValue;
          }
          return [...prevValue, { id, offset: offsetTop + offsetHeight + 100, offsetTop }];
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toogleFormHandler = () => {
    if (actualReplyForm === id) {
      setActualReplyForm('');
    } else {
      setActualReplyForm(id);
    }
  };

  const showRepliesHandler = () => {
    setShowReplies(!showReplies);
    const unreadAnswers = answers.filter(answer => !answer.isRead);
    const unreadAnswersId = unreadAnswers.map(answer => answer.id);
    if (unreadAnswersId.length > 0) {
      readMessages(type, messagesId, unreadAnswersId);
      if (setNumUnreadMessages) {
        setNumUnreadMessages(prevValue => prevValue - unreadAnswersId.length);
      }
    }
  };

  const sendReplyAction = () => {
    setActualReplyForm('');
    setShowReplies(true);
  };

  const renderAttachedFiles = () => {
    return attachments.map(file => (
      <a key={file.id} href={FILES_BASE_URL + file.path} className='tag'>
        {file.name.length < 30 ? file.name : `${file.name.substr(0, 30)}...`}
      </a>
    ));
  };

  const renderAnswers = () => {
    const reverseAnswers = [...answers].reverse();
    const lastAnswers = reverseAnswers.slice(0, repliesNumber);
    lastAnswers.reverse();

    return lastAnswers.map(answer => (
      <Message
        key={answer.id}
        {...answer}
        type={type}
        messagesId={messagesId}
        literals={literals}
        mode={mode}
        canReply={canReply}
        canViewReplies={canViewReplies}
        onSubmit={onSubmit}
        actualReplyForm={actualReplyForm}
        setActualReplyForm={setActualReplyForm}
        isReply
      />
    ));
  };

  const formatDiffDate = (diff) => {
    const phrase = [];
    if (diff.d > 0) {
      if (diff.d > 3) {
        phrase.push(`${diff.d} ${literals.days}`);
      } else {
        phrase.push(`${diff.h + (diff.d * 24)} ${literals.hours}`);
      }
    } else if (diff.h > 0) {
      phrase.push(`${diff.h} ${literals.hours}`);
    } else if (diff.m > 0) {
      phrase.push(`${diff.m} ${literals.minutes}`);
    } else if (diff.s >= 30) {
      phrase.push(`${diff.s} ${literals.seconds}`);
    } else {
      phrase.push(literals.justNow);
    }
    return phrase.join(' ');
  };

  return (
    <div className={`message ${isReply ? 'message-reply' : ''}`} key={id} ref={messageRef}>
      <div className='message-header'>
        <div className={`${isReply && !isRead && 'unread'}`} />
        <Avatar
          title={getFullName(user)}
          src={user?.avatar?.path}
          size={30}
          className='mr-2'
        />
        <div>
          <h6 className='fw-b m-0'>{getFullName(user)}</h6>
          <div className='date fs-sm' title={fDate.format}>{ mode === 'post' ? fDate.format : formatDiffDate(fDate.diff)}</div>
        </div>
      </div>
      <div className='message-body'>
        <p className='message-text' dangerouslySetInnerHTML={{ __html: formatedMessage }} />
        {
          message.length > limitChars && (
            <span className='read-more'>
              <span onClick={() => setReadMore(!readMore)}>
                { readMore ? literals.readLess : literals.readMore }
              </span>
            </span>
          )
        }
        <div className='attached-files'>
          {renderAttachedFiles()}
        </div>

        { !isReply && mode === 'post' && (
          <div className={`horizontal-bar ${actualReplyForm === id ? 'light' : ''}`} />
        )
        }
        {mode === 'post' && (
          <div className='buttons'>
            {!parentId && !isRead ? (
              <span className='not-read'>
                <Asterisk size={20} />
                {literals.new}
              </span>
            ) : null}
            {
              canViewReplies && answers && answers.length > 0 && (
                <span className='show-replies' onClick={showRepliesHandler}>
                  {
                    showReplies ? (literals.close) : (
                      <span>
                        {literals.replies}
                        {unreadAnswersNum > 0 && (` (${unreadAnswersNum} ${literals.new})`)}
                      </span>
                    )
                  }
                  <span className='show-replies-number'>{answers.length}</span>
                  <MessageCircle size={16} fill='currentColor' />
                </span>
              )
            }
            {
              level < 2 ? (
                <span className={`reply ${!canReply && 'reply-disabled'}`} onClick={() => toogleFormHandler()}>
                  {actualReplyForm === id
                    ? literals.cancel
                    : `${isReply ? literals.answer : literals.comment}`
                  }
                  <MessageCircle size={16} />
                </span>
              ) : null
            }
          </div>
        )}
      </div>
      {
        actualReplyForm === id && (
          <div className='form-container' ref={formRef}>
            <MessagesForm
              id={id}
              user={userSession}
              literals={literals}
              onSubmit={onSubmit}
              showReplies={sendReplyAction}
              isReply
            />
          </div>
        )
      }
      {
        showReplies && (
          <>
            {
              answers.length > repliesNumber && (
                <div className='load-more mb-2' onClick={() => setRepliesNumber(prevValue => prevValue + 5)}>
                  <RotateCw size={16} className='mr-1' />
                  {literals.loadMore}
                </div>
              )
            }
            <ul className={`messages ${parentId === '' && 'reply-container-background'}`}>
              {parentId !== '' && (
                <div className='replies-number'>
                  <CornerDownRight size={16} />
                  {`${answers.length} ${answers.length > 1 ? literals.replies : literals.reply}`}
                </div>
              )}
              {canViewReplies && renderAnswers()}
            </ul>
          </>
        )
      }
    </div>
  );
};

Message.propTypes = {
  id: PropTypes.string.isRequired,
  literals: PropTypes.object.isRequired,
  user: PropTypes.object,
  level: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  fDate: PropTypes.object.isRequired,
  canReply: PropTypes.bool.isRequired,
  canViewReplies: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  answers: PropTypes.array.isRequired,
  attachments: PropTypes.array.isRequired,
  actualReplyForm: PropTypes.string.isRequired,
  setActualReplyForm: PropTypes.func.isRequired,
  isRead: PropTypes.bool.isRequired,
  isReply: PropTypes.bool,
  parentId: PropTypes.string,
  setOffset: PropTypes.func,
  type: PropTypes.string,
  messagesId: PropTypes.string,
  mode: PropTypes.string,
  userSession: PropTypes.object,
  setNumUnreadMessages: PropTypes.func,
};

Message.defaultProps = {
  onSubmit: null,
  isReply: false,
  user: null,
  parentId: '',
  setOffset: null,
  type: '',
  messagesId: '',
  mode: '',
  userSession: null,
  setNumUnreadMessages: null,
};

export default Message;
