import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import CaptableSummary from 'routes/Captable/components/CaptableSummary/CaptableSummary';
import Loading from 'components/Loading';
import Tool from 'components/Tool';
import ToolTab from 'components/Tool/components/ToolTab';
import Table from 'components/Table';
import StartupCaptableDate from 'components/StartupCaptableDate/components';
import EmptyStreet from 'assets/svg/empty_street.svg';
import { useSearchParams } from 'react-router-dom';
import { fetchStartupOperations } from 'routes/Captable/components/routes/Operations/modules/actions';
import { fetchStartup } from 'routes/Onboarding/modules/actions';


const PopupInfoCaptable = (props) => {
  const {
    literalsCommon,
    literals,
    startup,
    onClose,
    currency,
    captable,
  } = props;

  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [startupInfo, setStartupInfo] = useState(null);
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;

  useEffect(() => {
    (async () => {
      const aux = await fetchStartup(startup);
      setStartupInfo(aux);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnStats = (opStats) => {
    const {
      assignShares,
      issueShares,
      issueDebt,
      issueOptions,
      assignOptions,
      payouts,
      secondaries,
      conversions,
    } = opStats;

    return (
      <ul className='operation-actions-stats'>
        <li title={literals.shares} className={assignShares || issueShares ? 'active' : ''}>SH</li>
        <li title={literals.debt} className={issueDebt ? 'active' : ''}>DB</li>
        <li title={literals.options} className={assignOptions || issueOptions ? 'active' : ''}>OP</li>
        <li title={literals.payouts} className={payouts ? 'active' : ''}>PO</li>
        <li title={literals.secondaries} className={secondaries ? 'active' : ''}>SE</li>
        <li title={literals.conversions} className={conversions ? 'active' : ''}>CO</li>
      </ul>
    );
  };

  const fetchOperations = (page, size, search, filters) => {
    return fetchStartupOperations(startup, {
      page,
      size,
      search,
      filters,
      captable: captableId,
    });
  };

  const columns = [
    {
      field: 'date',
      width: 90,
      label: literalsCommon.date,
    },
    {
      field: 'name',
      grow: 3,
      label: literalsCommon.name,
      type: 'html',
      preRender: (v, row) => {
        let notes = row.notes ? row.notes.replace(/(<([^>]+)>)/gi, '') : '';
        notes = notes.length > 150 ? `${notes.substr(0, 150)}...` : notes;
        return `${v}${notes ? `<br><p class="fc-secondary fs-small d-none d-sm-block mb-0">${notes}</p>` : ''}`;
      },
    },
    {
      field: 'stats',
      width: 235,
      label: '',
      preRender: s => columnStats(s),
    },
    {
      field: 'draft',
      width: 100,
      label: literals.status,
      preRender: v => (v ? literals.draft : literals.confirmed),
    },
    {
      type: 'menu',
      buttons: [],
    },
  ];

  return (
    <Popup title='Info Captable' onClose={onClose} size='large'>
      {!loading ? (
        <>
          <StartupCaptableDate captable={captable} startup={startupInfo} date={date} onChange={setDate} editable={false} />
          {captable.loaded ? (
            <>
              {captable.operations.length > 0 ? (
                <Tool
                  tabsStyle='panel'
                  tabs={[
                    { key: 'summary', name: 'Summary' },
                    { key: 'operations', name: 'Operations' },
                  ]}
                >
                  <ToolTab key='summary'>
                    <CaptableSummary
                      literals={literals}
                      literalsCommon={literalsCommon}
                      startup={startup}
                      date={date}
                      currency={currency}
                    />
                  </ToolTab>
                  <ToolTab key='operations'>
                    <Table
                      searcher={false}
                      columns={columns}
                      fetch={fetchOperations}
                      pageSize={10}
                    />
                  </ToolTab>
                </Tool>
              ) : (
                <div className='no-results'>
                  <img src={EmptyStreet} alt={literals.noResults} />
                  <p>{literals.noResults}</p>
                </div>
              )}
            </>
          ) : (
            <Loading hide={false} mode='panel' />
          )}
        </>
      ) : (
        <Loading hide={false} mode='panel' />
      )}
    </Popup>
  );
};

PopupInfoCaptable.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  startup: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  currency: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
};

export default PopupInfoCaptable;
