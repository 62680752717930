import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel/components/';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import InputPermission from 'components/Inputs/InputPermission';
import InputSelect from 'components/Inputs/inputSelect';
import InputNumber from 'components/Inputs/inputNumber';
import InputFile from 'components/Inputs/inputFile';
import InputRadio from 'components/Inputs/inputRadio';
import InputKPIs from 'routes/StartupFundraise/components/routes/StartupFundraiseAdd/components/inputKpis';
import InputRichText from 'components/Inputs/inputRichText';
import Button from 'components/Buttons/Button';
import Loading from 'components/Loading';
import useDialog from 'components/Dialog/components/useDialog';
import OutputValue from 'components/Inputs/outputValue';
import OutputErrors from 'components/Inputs/outputErrors';
import { Pencil, PlusCircle } from 'lucide-react';
import { ROUTE_PATH, ROUTE_STARTUP_PATH } from 'routes';
import { useNavigate } from 'react-router-dom';
import { initPermission } from 'utils/functions/initPermissions';
import { TOOL_FUNDRAISE } from 'constants/tools';
import { PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import {
  createStartupFundraise, deleteStartupFundraise, editStartupFundraise, getStartupFundraise,
} from '../modules/actions';
import './styles.scss';

const StartupFundraiseAdd = (props) => {
  const {
    literals,
    literalsPerformance,
    literalsCommon,
    match,
    currency,
    lang,
    startup,
  } = props;

  const defaultFundraise = {
    amount: '',
    postmoney: '',
    round: '',
    kpis: [],
    documents: [],
    minInvestment: '',
    maxInvestment: '',
    description: '',
    status: '',
    leadInvestor: false,
    public: false,
    permission: initPermission(null, PERMISSION_SCOPES.STARTUP, startup, TOOL_FUNDRAISE),
  };

  const [fundraiseForm, setFundraiseForm] = useState(defaultFundraise);
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);
  const { dialog } = useDialog();
  const navigate = useNavigate();

  useEffect(() => {
    if (match.params.fundraise !== 'new') {
      setLoading(true);
      (async () => {
        const fundraise = await getStartupFundraise(match.params.id, match.params.fundraise);
        fundraise.kpis = fundraise.kpis.map(({ id, relation }) => ({ id: relation, kpi: id }));
        fundraise.status = fundraise.status.status;
        fundraise.permission = initPermission(fundraise, PERMISSION_SCOPES.STARTUP, startup, TOOL_FUNDRAISE);
        setFundraiseForm(fundraise);
        setLoading(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteStartupFundraise = async () => {
    const confirm = await dialog({
      type: 'confirmDanger', text: literals.deleteFundraise,
    });
    if (confirm) {
      setLoading(true);
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            await deleteStartupFundraise(match.params.id, fundraiseForm.id);
            navigate(ROUTE_STARTUP_PATH.setFundraise(match.params.id));
          } catch {
            setLoading(false);
          }
        },
      });
    }
  };

  const handleCreateStartupFundraise = async (e) => {
    e.preventDefault();
    const data = {
      ...fundraiseForm,
      kpis: fundraiseForm.kpis.map(({ kpi }) => (typeof kpi === 'string' ? kpi : kpi.id)),
    };

    await dialog({
      type: 'loading',
      execute: async () => {
        let newFundraise;
        try {
          if (fundraiseForm.id) {
            newFundraise = await editStartupFundraise(match.params.id, fundraiseForm.id, data);
          } else {
            newFundraise = await createStartupFundraise(match.params.id, data);
          }
          navigate(ROUTE_PATH.setFundraise(newFundraise.id));
        } catch (err) {
          setErrors(err.body);
        }
      },
    });
  };

  const breadcrumb = [
    { route: ROUTE_STARTUP_PATH.setFundraise(match.params.id), name: literals.title },
    { name: fundraiseForm.id ? literals.editRound : literals.newRound },
  ];

  return (
    <Panel>
      <PanelHeader breadcrumb={breadcrumb} />
      <PanelBody>
        { loading
          ? <Loading mode='panel' hide={false} />
          : (
            <form className='create-fundraise' onSubmit={handleCreateStartupFundraise}>
              <div className='row status-row'>
                <div className='col-12 col-sm-4'>
                  <InputPermission
                    scope={{ type: 'startup', id: match.params.id }}
                    element={{ type: PERMISSION_TYPES.FUNDRAISE, id: null }}
                    value={fundraiseForm.permission}
                    onChange={permission => setFundraiseForm(prev => ({ ...prev, permission }))}
                  />
                </div>
                <div className='col-12 col-sm-4'>
                  <InputSelect
                    preText={literals.whoCanInvest}
                    value={fundraiseForm.public}
                    onChange={v => setFundraiseForm(prev => ({ ...prev, public: v }))}
                    options={[
                      { id: true, name: literals.anyoneCanInvest },
                      { id: false, name: literals.byInvitationOnly },
                    ]}
                    isRequired
                  />
                </div>
                <div className='col-12 col-sm-4'>
                  <InputSelect
                    preText={literals.status}
                    value={fundraiseForm.status}
                    onChange={v => setFundraiseForm(prev => ({ ...prev, status: v }))}
                    options={[
                      { id: 'draft', name: literals.draft },
                      { id: 'in_progress', name: literals.in_progress },
                      { id: 'closed', name: literals.closed },
                    ]}
                    isRequired
                  />
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <InputNumber
                    preText={literals.amount}
                    value={fundraiseForm.amount}
                    onChange={v => setFundraiseForm(prev => ({ ...prev, amount: v }))}
                    symbol={currency.symbol}
                    isRequired
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <InputNumber
                    preText={literals.postMoney}
                    value={fundraiseForm.postmoney}
                    onChange={v => setFundraiseForm(prev => ({ ...prev, postmoney: v }))}
                    symbol={currency.symbol}
                    isRequired
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <OutputValue
                    preText={literals.ownership}
                    value={(fundraiseForm.amount * 100 / (fundraiseForm.amount + fundraiseForm.postmoney)) || 0}
                    symbol='%'
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <InputSelect
                    preText={literals.investmentRound}
                    placeholder={literals.investmentRound}
                    value={fundraiseForm.round}
                    onChange={v => setFundraiseForm(prev => ({ ...prev, round: v }))}
                    options={[
                      { id: 'pre-seed', name: literals.preseed },
                      { id: 'seed', name: literals.seed },
                      { id: 'early', name: literals.early },
                      { id: 'growth', name: literals.growth },
                      { id: 'expansion', name: literals.expansion },
                    ]}
                    isRequired
                  />
                </div>
              </div>
              <InputFile
                preText={literalsCommon.documents}
                value={fundraiseForm.documents}
                onChange={v => setFundraiseForm(prev => ({ ...prev, documents: v }))}
                isPublic
                multiple
              />
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <InputNumber
                    preText={literals.minTicketPhase}
                    value={fundraiseForm.minInvestment}
                    onChange={v => setFundraiseForm(prev => ({ ...prev, minInvestment: v }))}
                    symbol={currency.symbol}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <InputNumber
                    preText={literals.maxTicketPhase}
                    value={fundraiseForm.maxInvestment}
                    onChange={v => setFundraiseForm(prev => ({ ...prev, maxInvestment: v }))}
                    symbol={currency.symbol}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <InputRadio
                    preText={literals.commitedInvestor}
                    onChange={v => setFundraiseForm(prev => ({ ...prev, leadInvestor: v === 'true' }))}
                    value={fundraiseForm.leadInvestor}
                    options={[
                      { id: true, name: literalsCommon.yes },
                      { id: false, name: literalsCommon.no },
                    ]}
                    isRequired
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <InputKPIs
                    literals={literalsPerformance}
                    currency={currency}
                    match={match}
                    lang={lang}
                    preText={literals.kpis}
                    kpis={fundraiseForm.kpis}
                    setKpis={v => setFundraiseForm(prev => ({ ...prev, kpis: v }))}
                  />
                </div>
              </div>

              <InputRichText
                preText={literalsCommon.description}
                value={fundraiseForm.description}
                onChange={v => setFundraiseForm(prev => ({ ...prev, description: v }))}
                minHeight='150px'
              />
              <OutputErrors literals={literals} errors={errors} />
              <div className='buttons'>
                {fundraiseForm.id && (
                  <Button
                    color='danger'
                    text={literalsCommon.delete}
                    onClick={handleDeleteStartupFundraise}
                    loading={loading}
                  />
                )}
                <Button
                  icon={fundraiseForm.id ? Pencil : PlusCircle}
                  text={fundraiseForm.id ? literalsCommon.update : literalsCommon.save}
                  loading={loading}
                  type='submit'
                />
              </div>
            </form>
          )}
      </PanelBody>
    </Panel>
  );
};

StartupFundraiseAdd.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  literalsPerformance: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  startup: PropTypes.object.isRequired,
};

export default StartupFundraiseAdd;
