import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const LinksInstance = ApiFetch();

export const URLS = {
  LINKS: 'links',
  LINK: 'links/:id',
};

async function getLink(id) {
  const path = preparePath(URLS.LINK, { id });
  return LinksInstance.get(path);
}

async function postLink(data) {
  return LinksInstance.post(URLS.LINKS, data);
}

async function editLink(id, data) {
  const path = preparePath(URLS.LINK, { id });
  return LinksInstance.put(path, data);
}

async function deleteLink(id) {
  const path = preparePath(URLS.LINK, { id });
  return LinksInstance.delete(path);
}

export default {
  getLink,
  postLink,
  editLink,
  deleteLink,
};
