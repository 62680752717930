import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import OutputValue from 'components/Inputs/outputValue';
import InputRichText from 'components/Inputs/inputRichText';
import { formatNumber, getFullName } from 'utils/functions';
import './styles.scss';

const FundraiseInvestmentDetailsPopup = (props) => {
  const {
    literals,
    literalsCommon,
    investment,
    symbol,
    onClose,
  } = props;


  return (
    <Popup title={literals.invest} onClose={onClose}>
      <OutputValue
        preText={literals.investor}
        value={investment?.investor ? getFullName(investment.investor) : investment.name}
      />
      <OutputValue
        preText={literals.amountToInvest}
        value={formatNumber(investment.amount, 0)}
        symbol={symbol}
      />
      <InputRichText
        preText={literalsCommon.notes}
        value={investment.notes}
        onChange={() => {}}
        isDisabled
      />
    </Popup>
  );
};

FundraiseInvestmentDetailsPopup.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  investment: PropTypes.object.isRequired,
  symbol: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FundraiseInvestmentDetailsPopup;
