import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import { formatNumber, getTranslation } from 'utils/functions';
import { formatKpiDiff } from 'routes/Performance/utils/functions';
import { KPI_UNIT } from 'constants/kpis';
import './styles.scss';

const KPITable = ({
  literals,
  symbol,
  kpis = null,
  lang,
}) => {
  const getDiffValue = (value, ref, kpi) => {
    let diff = ref && value ? ((ref - value) * 100 / value) : 0;
    diff = kpi.positive === 'up' ? diff : -diff;
    return (
      <div>
        <span>{formatNumber(value, '-', { symbol: kpi.unit === KPI_UNIT.CURRENCY ? symbol : '' })}</span>
        <div>{ref && value ? formatKpiDiff(diff) : null}</div>
      </div>
    );
  };

  const columns = [
    {
      field: 'kpi',
      label: literals.kpi,
      preRender: kpi => getTranslation(kpi.name, lang),
    },
    {
      field: 'value',
      label: literals.lastValue,
      width: 120,
      preRender: value => (
        <div>
          <span className='mr-3'>{value.date || '-'}</span>
        </div>
      ),
    },
    {
      field: 'value',
      label: literals.value,
      width: 120,
      preRender: (value, row) => (
        `${value.actual ? `${value.actual.toLocaleString()}${row.kpi.unit === KPI_UNIT.CURRENCY ? symbol : ''}` : '-'}`
      ),
    },
    {
      field: 'value',
      label: literals.previous,
      width: 120,
      preRender: (value, row) => getDiffValue(value.previous, value.actual, row.kpi),
    },
    {
      field: 'value',
      label: literals.forecast,
      width: 120,
      preRender: (value, row) => getDiffValue(value.forecast, value.actual, row.kpi),
    },
    {
      field: 'target',
      label: literals.target,
      width: 120,
      preRender: (target, row) => getDiffValue(target, row.value.actual, row.kpi),
    },
  ];

  return (
    <Table
      key={`table_kpi${!kpis ? '_loading' : ''}`}
      className='table_kpi'
      searcher={false}
      columns={columns}
      fetch={kpis ? () => ({ items: kpis, total: kpis.length }) : null}
    />
  );
};

KPITable.propTypes = {
  literals: PropTypes.object.isRequired,
  kpis: PropTypes.array,
  lang: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
};

export default KPITable;
