import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import ButtonsDotsMenu from 'components/Buttons/ButtonsDotsMenu';
import { formatDate, getFullName, getIconFile } from 'utils/functions';
import { FILES_BASE_URL } from 'constants/apiConf';
import { Download, Trash } from 'lucide-react';

const Document = ({
  document,
  onDelete = null,
  literalsCommon,
}) => {
  const actions = [
    {
      icon: Download,
      type: 'a',
      href: FILES_BASE_URL + document.path,
      target: '_blank',
      text: literalsCommon.download,
    },
  ];

  if (onDelete) {
    actions.push({ icon: Trash, text: literalsCommon.delete, onClick: () => onDelete(document.id) });
  }

  return (
    <li key={document.id} className='document box'>
      <a href={FILES_BASE_URL + document.path} target='_blank' rel='noopener noreferrer'>
        <div className='document-info'>
          <span className='document-date'>{formatDate(document.createdAt, { time: true })}</span>
          {
            onDelete && (
              <div className='document-actions'>
                <ButtonsDotsMenu
                  key={`menu-document-${document.id}`}
                  buttons={actions}
                />
              </div>
            )
          }
        </div>
        <div className='document-name'>
          {getIconFile(document.path)}
          <h6 className='fw-b'>{document.name}</h6>
        </div>
        {
          document.createdBy && (
            <div className='document-author'>
              <Avatar
                title={getFullName(document.createdBy)}
                src={document.createdBy?.avatar?.path}
                size={25}
              />
              <h6>{getFullName(document.createdBy)}</h6>
            </div>
          )
        }
      </a>
    </li>
  );
};

Document.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
};

export default Document;
