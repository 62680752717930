export const FETCH_COMPANY = 'FETCH_COMPANY';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_FAILURE = 'FETCH_COMPANY_FAILURE';

export const FETCH_MENTORS_INIT = 'FETCH_MENTORS_INIT';
export const FETCH_MENTORS_SUCCESS = 'FETCH_MENTORS_SUCCESS';
export const FETCH_MENTORS_FAILURE = 'FETCH_MENTORS_FAILURE';

export const EDIT_COMPANY_INIT = 'EDIT_COMPANY_INIT';
export const EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS';
export const EDIT_COMPANY_FAILURE = 'EDIT_COMPANY_FAILURE';

export const EDIT_MENTOR_INIT = 'EDIT_MENTOR_INIT';
export const EDIT_MENTOR_SUCCESS = 'EDIT_MENTOR_SUCCESS';
export const EDIT_MENTOR_FAILURE = 'EDIT_MENTOR_FAILURE';
