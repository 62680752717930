/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import InputFileZone from 'components/Inputs/inputFileZone';
import useDialog from 'components/Dialog/components/useDialog';
import Avatar from 'components/Avatar';
import IOFileLoading from 'components/IOFileLoading/components';
import ButtonsDotsMenu from 'components/Buttons/ButtonsDotsMenu';
import { Download, Trash } from 'lucide-react';
import { ROUTE_PATH } from 'routes';
import { formatDate, getFullName, getIconFile } from 'utils/functions';
import { deleteDocument, editDocument } from '../modules/actions';
import PrevVersionsTimeline from './PrevVersionsTimeline';

const PopupFileVersions = (props) => {
  const {
    literals,
    literalsCommon,
    content,
    setContent,
    onClose,
  } = props;

  const [file, setFile] = useState(content);
  const [loading, setLoading] = useState(null);
  const { dialog } = useDialog();

  const handleError = async (error) => {
    let errorMessage = '';
    if (error?.status === 401) {
      errorMessage = literals.noPermissions;
    }
    await dialog({
      type: 'error',
      text: (
        <>
          <strong>{literals.error}</strong>
          <br />
          {errorMessage}
        </>
      ),
    });
  };

  const handleSubmit = async (v) => {
    setLoading(v);
    const name = file.name.replace(/\.[^/.]+$/, '');
    const ext = v.name.match(/\.([^.]+)$/)[1];

    try {
      const doc = await editDocument(file.id, {
        file: v,
        folder: file.parent,
        name: `${name}.${ext}`,
        description: file.description,
        _return: 'object',
      });
      setContent(prev => ({
        items: prev.items.map(item => (item.id === doc.id ? doc : item)),
        total: prev.total,
      }));
      setFile(doc);
    } catch (error) {
      await handleError(error);
    }
    setLoading(null);
  };

  const handleDeleteVersion = async (version) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literalsCommon.confirmDelete,
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            const doc = await deleteDocument(file.id, { v: version });
            setContent(prev => ({
              items: prev.items.map(item => (item.id === doc.id ? doc : item)),
              total: prev.total,
            }));
            setFile(doc);
          } catch (error) {
            handleError(error);
          }
        },
      });
    }
  };

  const uploadedAt = file.uploadedAt || file.createdAt;
  const uploadedBy = file.uploadedBy || file.createdBy;

  return (
    <Popup title={literals.versions} onClose={onClose}>
      <InputFileZone
        key={`input-file-${loading}`}
        value={loading}
        preText={literals.uploadNewVersion}
        placeholder={literals.upload}
        onChange={handleSubmit}
        isDisabled={loading}
      />
      <h6 className='fw-b'>{literals.actualVersion}</h6>
      <a
        className='file-actual-version'
        href={ROUTE_PATH.setDownloadFile(file.hash, file.name)}
        target='_blank'
        rel='noopener noreferrer'
      >
        <div className='file-info'>
          <div className='d-flex'>
            {getIconFile(file.name)}
            <div className='d-flex flex-column'>
              <span className='file-name'>{file.name}</span>
              <span className='mb-0 fs-small fc-secondary'>{formatDate(uploadedAt, { time: true })}</span>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <Avatar
            title={getFullName(uploadedBy)}
            src={uploadedBy?.avatar?.path}
            size={35}
            className='mx-2'
          />
          <ButtonsDotsMenu
            size={20}
            offset={{ top: 1, left: 20 }}
            buttons={[
              { icon: Download, text: literalsCommon.download, onClick: () => window.open(ROUTE_PATH.setDownloadFile(file.hash, file.name)) },
              { icon: Trash, text: literalsCommon.delete, onClick: () => handleDeleteVersion(file.document) },
            ]}
          />
        </div>
      </a>
      <PrevVersionsTimeline
        literals={literals}
        literalsCommon={literalsCommon}
        file={file}
        setFile={setFile}
        setContent={setContent}
      />
      { loading && <IOFileLoading file={{ name: loading.name }} />}
    </Popup>
  );
};

PopupFileVersions.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  setContent: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupFileVersions;
