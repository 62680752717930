import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import Table from 'components/Table';
import EmptyGraph from 'assets/svg/empty_graph.svg';
import KpiRelationValuesGraph from 'routes/Performance/components/KpiRelationValuesGraph';
import { formatNumber, getTranslation, startupCanEdit } from 'utils/functions';
import { TOOL_PERFORMANCE } from 'constants/tools';
import { PlusCircle, LineChart, EyeOff } from 'lucide-react';
import { formatKpiValue } from 'routes/Performance/utils/functions';
import { useCounter } from 'utils/customHooks';
import { KPI_PERIODICITY } from 'constants/kpis';
import { initPermission } from 'utils/functions/initPermissions';
import { PERMISSION_SCOPES } from 'constants/permissions';
import { fetchKpiSummaryTable } from 'routes/Performance/modules/actions';
import { ROUTE_STARTUP_PATH } from 'routes';
import { Link } from 'react-router-dom';
import './styles.scss';

const KpiChartTable = (props) => {
  const {
    literalsPerformance,
    literalsCommon,
    currency,
    currencyExchange,
    startup,
    bookmark,
    pageSize,
    table,
    lang,
  } = props;

  const actualDate = new Date();
  const actualYear = actualDate.getFullYear();
  const actualQuarter = Math.ceil((actualDate.getMonth() + 1) / 3);
  const previousQuarter = actualQuarter === 1 ? 4 : actualQuarter - 1;
  const previousYear = actualQuarter === 1 ? actualYear - 1 : actualYear;
  const tableRefreshFlag = useCounter(0);

  const [graphKpiRelation, setGraphKpiRelation] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [isEmpty, setIsEmpty] = useState(false);

  const fetchPerformanceKpis = async (page, size) => {
    const opts = {
      page, size, bookmark, table, currencyExchange,
    };
    const auxRelations = await fetchKpiSummaryTable('startup', startup.id, opts);

    if (!auxRelations.total) {
      setIsEmpty(true);
    }
    return auxRelations;
  };

  const handleGraph = (row) => {
    const perms = initPermission(row, PERMISSION_SCOPES.STARTUP, startup, TOOL_PERFORMANCE);
    setCanEdit(perms.canEdit);
    setGraphKpiRelation(row.id !== graphKpiRelation?.id ? row : null);
  };

  const renderValue = (agg, row, type = '') => {
    if (!agg) {
      return '-';
    }
    const periodicity = row?.relation?.kpi?.periodicity;
    let isValid = agg?.valid;

    if (!isValid) {
      if (periodicity === KPI_PERIODICITY.ANNUAL) {
        isValid = agg?.count === 1;
      } else if (periodicity === KPI_PERIODICITY.QUARTERLY) {
        isValid = type === 'quarter' ? agg?.count === 1 : agg?.count === 4;
      } else {
        isValid = type === 'quarter' ? agg?.count === 3 : agg?.count === 12;
      }
    }

    return `${formatKpiValue(agg.value, row?.relation?.kpi, {}, currency)} ${!isValid ? '*' : ''}`;
  };

  const columns = [
    {
      field: 'id',
      width: 20,
      preRender: v => (graphKpiRelation?.id === v ? <EyeOff size={18} /> : <LineChart size={18} />),
    },
    {
      field: 'name',
      label: literalsCommon.name,
      preRender: v => getTranslation(v, lang),
    },
    {
      field: `Q${previousYear - 1}`,
      label: `${literalsPerformance.quarterly.charAt(0) + previousQuarter} ${previousYear - 1}`,
      width: 110,
      preRender: (agg, row) => renderValue(agg, row, 'quarter'),
    },
    {
      field: `Q${previousYear}`,
      label: `${literalsPerformance.quarterly.charAt(0) + previousQuarter} ${previousYear}`,
      width: 110,
      preRender: (agg, row) => renderValue(agg, row, 'quarter'),
    },
    {
      field: 'VarYoY',
      label: 'Var YoY',
      width: 110,
      preRender: v => formatNumber(v, '-', { symbol: v ? '%' : '' }),
    },
  ];

  columns.splice(2, 0, ...Array.from({ length: 3 }, (_, index) => ({
    field: `${actualYear - 2 + index}-12`,
    label: actualYear - 2 + index,
    width: 110,
    hidden: rows => rows.every(row => !row[`${actualYear - 5 + index}-12`]),
    preRender: renderValue,
  })));

  return isEmpty ? (
    <div className='panel-empty-message m-0'>
      <img src={EmptyGraph} alt={literalsPerformance.noKPIs} />
      <p>{literalsPerformance.noKPIs}</p>
      {startupCanEdit(startup, TOOL_PERFORMANCE) && (
        <Link to={ROUTE_STARTUP_PATH.setPerformance(startup.id)}>
          <Button color='secondary' icon={PlusCircle} text={literalsPerformance.addKpis} />
        </Link>
      )}
    </div>
  ) : (
    <div className='sp-columns sm-flex-column'>
      {graphKpiRelation && (
        <div className='performance-graph'>
          <h6 className='fw-b'>{graphKpiRelation.name}</h6>
          <KpiRelationValuesGraph
            relation={graphKpiRelation.relation || {}}
            height={275}
            literals={literalsPerformance}
            literalsCommon={literalsCommon}
            currency={currency}
            symbolSize={0}
            canEdit={canEdit}
          />
        </div>
      )}
      <div className='performance-table'>
        <div className='fs-sm font-italic text-right'>{literalsPerformance.dataIncomplete}</div>
        <Table
          columns={columns}
          searcher={false}
          pageSize={pageSize}
          fetch={fetchPerformanceKpis}
          forceFetch={tableRefreshFlag.value}
          onClickRow={handleGraph}
        />
      </div>
    </div>
  );
};

KpiChartTable.propTypes = {
  literalsPerformance: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  currencyExchange: PropTypes.number,
  startup: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  bookmark: PropTypes.bool,
  pageSize: PropTypes.number,
  table: PropTypes.string,
};

KpiChartTable.defaultProps = {
  bookmark: true,
  pageSize: 4,
  table: null,
  currencyExchange: null,
};

export default KpiChartTable;
