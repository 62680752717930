import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Table from 'components/Table';
import ReactECharts from 'echarts-for-react';
import SkeletonDoughnutChart from 'components/Loading/skeletonDoughnutChart';
import useDialog from 'components/Dialog/components/useDialog';
import IOFileLoading from 'components/IOFileLoading/components';
import {
  Eye, Trash, Pencil, Check, Minus, Merge,
  Briefcase, List, Users, Banknote, ArrowLeftRight,
  PlusCircle, Download, LineChart, Sheet,
} from 'lucide-react';
import { useParams } from 'react-router-dom';
import PermissionLabel from 'components/PermissionsPopup/PermissionLabel';
import { PERMISSION_LEVELS, PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import { useCounter } from 'utils/customHooks';
import { formatDate } from 'utils/functions';
import { AmplitudeApi } from 'utils/amplitude';
import { getPieOptions } from '.';
import { deleteShareClass, downloadExcelShareClasses, fetchShareClasses } from './routes/shareClasses/modules/actions';
import { fetchCaptableShares } from './routes/Securities/modules/actions';
import PopupInfoShareClasses from './routes/shareClasses/components/PopupInfoShareClass';
import PopupFormShareClass from './routes/shareClasses/components/PopupFormShareClass';
import PopupUnifyShareClasses from './routes/shareClasses/components/PopupUnifyShareClasses';
import './styles.scss';

const PanelShareClass = ({
  literals, literalsCommon, stats, captable, canEdit,
}) => {
  const params = useParams();
  const tableRefreshFlag = useCounter(0);
  const [viewTable, setViewTable] = useState(false);
  const [createPopup, setCreatePopup] = useState(false);
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const [showDataRow, setShowDataRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [unifyPopup, setUnifyPopup] = useState(false);
  const { dialog } = useDialog();

  let graphByShareClasses = null;
  if (stats !== null) {
    graphByShareClasses = stats.aggregations.sharesByShareClass.map(item => ({
      id: item.id, name: item.name, value: Number(item.value.toFixed(2)),
    }));
  }

  const handleOnClickChart = useCallback(({ data }) => setShowDataRow(data.id), []);
  const renderGraph = () => (
    graphByShareClasses
      ? (
        <ReactECharts
          option={getPieOptions(graphByShareClasses, literals)}
          onEvents={{ click: handleOnClickChart }}
        />
      )
      : <SkeletonDoughnutChart className='mt-3' size={220} />
  );

  const renderTable = () => {
    const deleteClass = async (shareClass) => {
      await dialog({
        type: 'loading',
        execute: async () => {
          const capShares = await fetchCaptableShares(
            params.id,
            formatDate(new Date(), { format: 'Y-m-d' }),
            { filters: { shareClass }, captable },
          );
          if (capShares.total > 0) {
            await dialog({
              type: 'error',
              text: literals.deleteClassError,
            });
          } else {
            const confirm = await dialog({
              text: literals.deleteClass,
              type: 'confirm',
            });
            if (confirm) {
              await dialog({
                type: 'loading',
                execute: async () => {
                  await deleteShareClass(params.id, shareClass);
                  tableRefreshFlag.increase();
                },
              });
            }
          }
        },
      });
    };

    const columns = [
      {
        field: 'name',
        label: literalsCommon.name,
        width: 200,
        sortable: true,
        preRender: (v, row) => (
          <div className='d-flex align-items-center'>
            <div className='shareclass-name'>{v}</div>
            <PermissionLabel permission={row.permission} />
          </div>
        ),
      },
      {
        field: 'code',
        label: literals.code,
        width: 60,
        preRender: (v, row) => (
          <div className='cell-keywords'>
            <div key={`code-${row.id}`}>{v || '-'}</div>
          </div>
        ),
      },
      {
        field: 'type',
        label: literalsCommon.type,
        width: 70,
      },
      {
        field: 'vote',
        label: literals.vote,
        icon: Briefcase,
        preRender: v => (v?.enabled ? <Check size={12} /> : <Minus size={12} />),
        width: 15,
      },
      {
        field: 'liquidity',
        label: literals.liquidity,
        icon: List,
        preRender: v => (v?.enabled ? <Check size={12} /> : <Minus size={12} />),
        width: 15,
      },
      {
        field: 'participation',
        label: literals.participation,
        icon: Users,
        preRender: v => (v?.enabled ? <Check size={12} /> : <Minus size={12} />),
        width: 15,
      },
      {
        field: 'dividend',
        label: literals.dividend,
        icon: Banknote,
        preRender: v => (v?.enabled ? <Check size={12} /> : <Minus size={12} />),
        width: 15,
      },
      {
        field: 'convert',
        label: literals.convert,
        icon: ArrowLeftRight,
        preRender: v => (v?.enabled ? <Check size={12} /> : <Minus size={12} />),
        width: 30,
      },
      {
        id: 'created_at',
        field: 'createdAt',
        label: literalsCommon.createdAt,
        preRender: elem => formatDate(elem),
        width: 90,
        sortable: true,
      },
      {
        field: 'id',
        type: 'menu',
        buttons: [
          {
            icon: Eye,
            text: literalsCommon.view,
            onClick: shareClass => setShowDataRow(shareClass.id),
          },
          {
            icon: Pencil,
            text: literalsCommon.modify,
            onClick: shareClass => setCreatePopup(shareClass),
            hidden: (_, row) => row.permission.level < PERMISSION_LEVELS.EDIT,
          },
          {
            type: 'permission',
            scope: { type: PERMISSION_SCOPES.STARTUP, id: params.id },
            element: PERMISSION_TYPES.SHARECLASS,
          },
          {
            icon: Trash,
            text: literalsCommon.delete,
            onClick: shareClass => deleteClass(shareClass.id),
            hidden: (_, row) => row.permission.level < PERMISSION_LEVELS.EDIT,
          },
        ],
      },
    ];

    const getShareClasses = (page, size, search, filters, sort) => {
      return fetchShareClasses(params.id, page, size, { search, filters, sort });
    };

    return (
      <Table
        searcher={false}
        columns={columns}
        fetch={getShareClasses}
        pageSize={4}
        forceFetch={tableRefreshFlag.value}
        onClickRow={row => setShowDataRow(row.id)}
        selectRows={selectedRows}
        onSelectRows={setSelectedRows}
        selectFields={['id', 'name', 'type', 'createdAt']}
      />
    );
  };

  const handleDownloadExcel = async () => {
    setDownloadingExcel(true);
    await downloadExcelShareClasses(params.id);
    setDownloadingExcel(false);
    AmplitudeApi.logEvent('startup.captable.summary.shareClass.click.downloadButton', { captable });
  };

  return (
    <Panel>
      <PanelHeader title={literals.byShareClass} />
      <PanelBody className='panel-summary-shareClass'>
        <div className='chart-panel-actions'>
          { viewTable && selectedRows.length > 1 ? <Merge title={literals.unifyPopup} size={22} onClick={() => setUnifyPopup(selectedRows)} /> : null }
          { canEdit && (
            <div title={literals.createShareClass}>
              <PlusCircle size={22} onClick={() => setCreatePopup('new')} />
            </div>
          ) }
          <div title={literalsCommon.download}>
            <Download size={22} onClick={() => handleDownloadExcel('')} />
          </div>
          <div title={viewTable ? literals.showChart : literals.showTable}>
            {viewTable
              ? <LineChart size={22} onClick={() => setViewTable(false)} />
              : <Sheet size={22} onClick={() => setViewTable(true)} />
            }
          </div>
        </div>
        {viewTable ? renderTable() : renderGraph()}
      </PanelBody>
      {
        createPopup && (
          <PopupFormShareClass
            captable={captable}
            shareClass={createPopup !== 'new' ? createPopup : {}}
            onClose={() => setCreatePopup(false)}
            onSubmit={tableRefreshFlag.increase}
          />
        )
      }
      {showDataRow && <PopupInfoShareClasses id={showDataRow} onClose={() => setShowDataRow(null)} />}
      {unifyPopup && (
        <PopupUnifyShareClasses
          literals={literals}
          literalsCommon={literalsCommon}
          startup={params.id}
          shareClasses={selectedRows}
          onSubmit={() => {
            setSelectedRows([]);
            tableRefreshFlag.increase();
          }}
          onClose={() => setUnifyPopup(false)}
        />
      )}
      { downloadingExcel && <IOFileLoading mode='download' file={{ name: 'shareClasses.xlsx' }} /> }
    </Panel>
  );
};

PanelShareClass.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  stats: PropTypes.object,
  captable: PropTypes.string,
  canEdit: PropTypes.bool.isRequired,
};

PanelShareClass.defaultProps = {
  stats: null,
  captable: null,
};


export default PanelShareClass;
