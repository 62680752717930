import { useMediaQuery, useTheme } from '@mui/material';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';

export const useCounter = (initialCount = 0) => {
  const [count, setCount] = useState(initialCount);
  return {
    value: count,
    increase: () => setCount(prevCount => prevCount + 1),
    decrease: () => setCount(prevCount => prevCount - 1),
    reset: () => setCount(initialCount),
  };
};

export const useLang = () => {
  const platformLangs = useSelector(state => state.global.platform.langs);
  const [lang, setLang] = useState(platformLangs[0]);
  return {
    value: lang,
    change: v => setLang(v),
    getLangs: () => platformLangs,
    getTitleInput: title => `${title} (${lang})`,
    getObjValue: obj => obj[lang],
    setObjValue: (obj, value) => ({ ...obj, [lang]: value }),
    validateLangs: (obj, props) => {
      const errors = {};
      props.forEach((prop) => {
        const propLangs = Object.keys(obj[prop]);
        if (propLangs.length === platformLangs.length) {
          propLangs.forEach((itLang) => {
            if (!obj[prop][itLang]) {
              errors[prop] = 'fillAllLangs';
            }
          });
        } else {
          errors[prop] = 'fillAllLangs';
        }
      });
      return !Object.keys(errors).length ? true : errors;
    },
  };
};

export const useStateRef = (processNode) => {
  const [node, setNode] = useState(null);
  const setRef = useCallback((newNode) => {
    setNode(newNode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processNode]);
  return [node, setRef];
};

export const useContainerDimensions = (myRef) => {
  const getDimensions = () => ({
    width: myRef.current.offsetWidth,
    height: myRef.current.offsetHeight,
  });

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myRef]);

  return dimensions;
};

export const useRect = (active, scrollRef = null) => {
  const ref = useRef(null);
  const [rect, setRect] = useState();
  const [isVisible, setIsVisible] = useState(true);

  const set = () => {
    const elementRect = ref.current?.getBoundingClientRect();
    if (elementRect) {
      setRect(elementRect);
      const hasRef = elementRect.top || elementRect.left;
      if (scrollRef?.current) {
        const parentRect = scrollRef.current.getBoundingClientRect();
        setIsVisible(hasRef
          && elementRect.top >= parentRect.top + 1
          && elementRect.bottom <= parentRect.bottom + 1
          && elementRect.left >= parentRect.left + 1
          && elementRect.right <= parentRect.right + 1);
      } else {
        setIsVisible(hasRef);
      }
    }
  };

  const useEffectInEvent = (event, useCapture = false) => {
    useEffect(() => {
      if (active) {
        set();
        window.addEventListener(event, set, useCapture);
      }
      return () => window.removeEventListener(event, set, useCapture);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active]);
  };

  useEffectInEvent('resize');
  useEffectInEvent('scroll', true);
  useEffectInEvent('transitionend', true);

  return [rect, ref, isVisible];
};

export const useWidth = () => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};

export function useIsWidthUp(breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(breakpoint));
}

export function useIsWidthDown(breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(breakpoint));
}
