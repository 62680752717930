import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from 'components/Collapse/Collapse';
import Panel from 'components/Panel';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelHeader from 'components/Panel/components/PanelHeader';
import Loading from 'components/Loading';
import SelectTemplate from 'assets/svg/select-template.svg';
import { fetchKpiTemplate, fetchKpiTemplates } from 'routes/Performance/modules/actions';
import { CircleCheck, File, FileCheck } from 'lucide-react';
import { getTranslation } from 'utils/functions';
import PopupAddKpis from './PopupAddKpis';

const PerformanceAddTabTemplates = (props) => {
  const {
    literals,
    literalsCommon,
    currency,
    performance,
    lang,
    postKpisRelations,
    match,
    startup,
  } = props;

  const [templates, setTemplates] = useState({ loading: true, items: [] });
  const [template, setTemplate] = useState({ loading: false, data: null, kpis: null });
  const [showRelatePopup, setShowRelatePopup] = useState(false);

  const fetchTemplates = async () => {
    const response = await fetchKpiTemplates(0, 0, '', { sort: `name.${lang}` });
    setTemplates({ loading: false, items: response.items });
  };

  const fetchTemplate = async (tmpl) => {
    setTemplate({ data: tmpl, loading: true, kpis: null });
    const response = await fetchKpiTemplate(tmpl.id);

    const kpis = [];
    const kpisRelated = [];
    response.kpis.forEach((kpi) => {
      if (performance.kpisRelated.indexOf(kpi.id) === -1) {
        kpis.push(kpi);
      } else {
        kpisRelated.push(kpi);
      }
    });

    setTemplate({ data: tmpl, loading: false, kpis: [...kpis, ...kpisRelated] });
  };

  const addTemplate = async () => {
    setShowRelatePopup(true);
  };

  useEffect(() => {
    fetchTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderKpis = () => {
    return (
      <div className='kpi-template-details'>
        {
          template.kpis.map((kpi) => {
            const related = performance.kpisRelated.indexOf(kpi.id) !== -1;
            return (
              <div key={kpi.id} className={`${related ? 'used' : ''} box box-padding`}>
                <h5 className='fw-b'>
                  {
                    related && (
                      <div className='ktd-related'>
                        <CircleCheck size={16} />
                        {literals.alreadyAdded}
                      </div>
                    )
                  }
                  {getTranslation(kpi.name, lang)}
                </h5>
                <p className='fc-secondary'>
                  {`${literals[kpi.category]}, ${literals[kpi.periodicity]}, ${literals[kpi.positive]}, ${literals[kpi.unit]}`}
                </p>
                <h6 className='fw-b'>{literalsCommon.description}</h6>
                <p className='ktd-description'>
                  {getTranslation(kpi.description, lang)}
                </p>
              </div>
            );
          })
        }
      </div>
    );
  };

  if (templates.loading) {
    return <Loading hide={false} mode='tool' />;
  }

  let numNewTemplates = 0;
  if (!template.loading && template.data) {
    template.kpis.forEach((kpi) => {
      if (performance.kpisRelated.indexOf(kpi.id) === -1) {
        numNewTemplates += 1;
      }
    });
  }

  return (
    <>
      <div className='tab-templates sm-flex-column'>
        <div className='tab-templates-left'>
          <Panel>
            <PanelBody>
              <Collapse title={literals.templates} state>
                <ul>
                  {
                    templates.items.map(tmpl => (
                      <li key={tmpl.id} onClick={() => fetchTemplate(tmpl)} className={tmpl.id === template?.data?.id ? 'active' : ''}>
                        {
                          tmpl.kpis.every(r => performance.kpisRelated.includes(r)) ? (
                            <FileCheck size={24} />
                          ) : (
                            <File size={24} />
                          )
                        }
                        {getTranslation(tmpl.name, lang)}
                      </li>
                    ))
                  }
                </ul>
              </Collapse>
            </PanelBody>
          </Panel>
        </div>
        <div className='tab-templates-right'>
          <Panel>
            <PanelHeader
              title={template.data ? getTranslation(template.data.name, lang) : literals.selectTemplate}
              subtitle={template.data ? getTranslation(template.data.description, lang) : ''}
              actions={template.data ? [
                {
                  text: numNewTemplates > 0 ? `${literals.add} (${numNewTemplates})` : literals.add,
                  onClick: addTemplate,
                  disabled: numNewTemplates === 0,
                }] : []}
            />
            <PanelBody>
              {
                template.loading ? (
                  <Loading hide={false} mode='panel' />
                ) : (
                  <>
                    { template.data ? renderKpis() : (
                      <div className='noTemplate'>
                        <img src={SelectTemplate} alt='' />
                      </div>
                    )}
                  </>
                )
              }
            </PanelBody>
          </Panel>
        </div>
      </div>
      {
        showRelatePopup && (
          <PopupAddKpis
            currency={currency}
            literals={literals}
            literalsCommon={literalsCommon}
            lang={lang}
            match={match}
            kpis={template.kpis.filter(kpi => performance.kpisRelated.indexOf(kpi.id) === -1)}
            onClose={() => setShowRelatePopup(false)}
            postKpisRelations={postKpisRelations}
            startup={startup}
          />
        )
      }
    </>
  );
};

PerformanceAddTabTemplates.propTypes = {
  match: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  performance: PropTypes.object.isRequired,
  postKpisRelations: PropTypes.func.isRequired,
  startup: PropTypes.object.isRequired,
};

export default PerformanceAddTabTemplates;
