import AccountsInstance from 'modules/accounts/repository';
import { SessionService } from 'modules/session/sessionService';
import { formatErrors } from 'utils/functions';

export function editProfile(id, data) {
  return AccountsInstance.editProfile(id, { ...data, avatar: data.avatar?.id })
    .then(() => {
      SessionService.loadUser()
        .then((user) => {
          SessionService.saveUser({
            ...user,
            avatar: data.avatar,
            firstname: data.firstname,
            lastname: data.lastname,
            lang: data.lang,
            decimalSeparator: data.decimalSeparator,
          });
          return true;
        });
    })
    .catch((err) => {
      throw formatErrors(err);
    });
}

export function editPassword(id, data) {
  return AccountsInstance.editPassword(id, data)
    .then(() => {
      return true;
    })
    .catch((err) => {
      throw formatErrors(err);
    });
}
