/* eslint-disable react/jsx-one-expression-per-line */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import Portal from 'components/Portal';
import {
  ArrowDown, Search, X, Eye,
} from 'lucide-react';
import { connect } from 'react-redux';
import { useRect } from 'utils/customHooks';
import InputText from './inputText';
import './styles.scss';

function InputMultiSelect(props) {
  const {
    literals,
    options,
    value = [],
    onChange,
    preText = '',
    postText = '',
    isRequired = false,
    isDisabled = false,
    error = null,
    button = null,
    mode = 'select',
  } = props;

  const [showOptions, setShowOptions] = useState(false);
  const [search, setSearch] = useState({ value: '', normalized: '' });
  const [focus, setFocus] = useState(value ? { index: options.findIndex(val => val.id === value), id: value } : null);
  const [bbox, ref, visible] = useRect(showOptions);

  const divRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current && showOptions) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOptions]);


  const handleShowOptions = () => {
    if (!isDisabled) {
      setShowOptions(!showOptions);
      setSearch({ value: '', normalized: '' });
    }
  };

  const selectValue = (event) => {
    const id = event.id || event.target.getAttribute('data-id');
    const newValue = [...value];

    const addValue = (val) => {
      const foundValue = options.find(elem => String(elem.id) === id);
      newValue.push(foundValue?.id ?? val);
    };

    if (Array.isArray(id)) {
      id.forEach(addValue);
    } else if (id != null) {
      addValue(id);
    }

    onChange(newValue);
    setSearch({ value: '', normalized: '' });
    setShowOptions(false);
  };

  const selectFocusValue = (val) => {
    if (val && val.index >= 0) {
      const foundValue = options.find(elem => elem.id === val.id);
      if (foundValue && !value.includes(val.id)) {
        const newValue = [...value, val.id];
        onChange(newValue);
      }
    }
    setSearch({ value: '', normalized: '' });
    setShowOptions(false);
  };

  const removeValue = (id) => {
    const newValue = [...value];
    const handleRemoveValue = (val) => {
      const foundValue = newValue.indexOf(val);
      if (foundValue !== -1) {
        newValue.splice(foundValue, 1);
      }
    };

    if (Array.isArray(id)) {
      id.forEach(handleRemoveValue);
    } else {
      handleRemoveValue(id);
    }
    onChange(newValue);
  };

  const normalizeText = (str) => {
    return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  function findLastIndex() {
    const count = options.length - 1;
    const index = options.slice().reverse().findIndex(
      (elem, i) => i > count - focus.index && normalizeText(elem.name).startsWith(search.normalized) && !value.includes(elem.id),
    );
    const finalIndex = index >= 0 ? count - index : index;
    return finalIndex;
  }

  const handleKeyPress = useCallback((e) => {
    if (e.keyCode === 40) {
      if (!focus) {
        setFocus({ index: 0, id: options[0]?.id });
      } else if (focus.index < options.length - 1) {
        setFocus((prev) => {
          const newElem = options.findIndex((elem, i) => i > prev.index && normalizeText(elem.name).startsWith(search.normalized) && !value.includes(elem.id));
          return newElem !== -1 ? { index: newElem, id: options[newElem]?.id } : prev;
        });
      }
    } else if (e.keyCode === 38) {
      if (!focus) {
        setFocus({ index: 0, id: options[0]?.id });
      } else if (focus.index > 0) {
        setFocus((prev) => {
          const newIndex = findLastIndex();
          return newIndex !== -1 ? { index: newIndex, id: options[newIndex]?.id } : prev;
        });
      }
    } else if (e.keyCode === 13 && focus) {
      e.preventDefault();
      selectFocusValue(focus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus, search]);

  useEffect(() => {
    if (showOptions) {
      if (divRef && divRef.current) {
        divRef.current.parentNode.scrollTop = divRef.current.offsetTop - 100;
      }
      window.addEventListener('keydown', handleKeyPress, false);
      return () => {
        window.removeEventListener('keydown', handleKeyPress, false);
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOptions, handleKeyPress]);


  const renderOptions = () => {
    const items = [];
    options.forEach((elem) => {
      if (!search.normalized.length || normalizeText(elem.name).startsWith(search.normalized)) {
        if (!value || !value.includes(elem.id)) {
          items.push(
            (
              <div
                ref={focus?.id === elem.id ? divRef : null}
                className={`input-select-item ${focus?.id === elem.id ? 'selected focus' : ''}`}
                data-id={elem.id}
                key={elem.id}
                onClick={selectValue}
              >
                {elem.name}
              </div>
            ),
          );
        }
      }
    });

    if (button) {
      items.push((<div className='input-select-button' onClick={handleShowOptions}>{button}</div>));
    }

    if (items.length) {
      return items;
    }

    return (
      <div className='input-select-item is-empty text-center font-italic'>{literals.noOptions}</div>
    );
  };

  const renderSelectedItems = () => {
    return options.map((option) => {
      const chosen = Array.isArray(option.id)
        ? option.id.every(item => value.find(selectedItem => selectedItem === item)) || null
        : value.find(selectedItem => selectedItem === option.id);

      if (chosen || chosen === false) {
        return (
          <span
            className='input-select-option-selected'
            key={option.id}
            onClick={() => removeValue(option.id)}
          >
            <span>{option.name}</span>
            <X size={12} className='ml-2 mt-1' />
          </span>
        );
      }

      return mode === 'click'
        ? (
          <span
            className='input-select-option-selected not-selected'
            key={option.id}
            onClick={() => selectValue(option)}
          >
            <span>{option.name}</span>
            <Eye size={12} className='ml-2 mt-1' />
          </span>
        ) : '';
    });
  };

  const styles = { visible };
  if (bbox) {
    styles.top = bbox.bottom;
    styles.left = bbox.left;
    styles.width = bbox.width;
  }

  return (
    <div className={`input-select-wrapper ${isDisabled ? 'disabled' : ''}`}>
      {preText && (
        <div className={`pre_text ${error ? 'text_error' : ''}`}>
          {preText}
          {isRequired && <span className='is_required'>*</span>}
        </div>
      )}
      {mode !== 'click' && (
        <>
          <div ref={ref} className={`input-select-select ${showOptions ? 'show' : ''} ${error ? 'input_error' : ''}`} onClick={handleShowOptions}>
            <div className='selected-item'>
              {preText}
              <ArrowDown size={17} className={`arrow-input-select ${showOptions ? 'rotate' : ''}`} />
            </div>
          </div>
          {showOptions && (
            <Portal styles={styles} onClose={() => setShowOptions(false)}>
              <div className={`input-select-item-options simple-scrollbar show ${preText ? 'extra-top' : ''}`}>
                {options.length > 5 && (
                  <div className='input-select-searcher-wrapper'>
                    <div className='input-select-searcher'>
                      <InputText
                        value={search.value}
                        onChange={v => setSearch({ value: v, normalized: normalizeText(v) })}
                        inputRef={(v) => { inputRef.current = v; }}
                      />
                      <Search className='search-icon' size={16} />
                    </div>
                  </div>
                )}
                {renderOptions()}
              </div>
            </Portal>
          )}
        </>
      )}
      {
        value?.length || mode === 'click' ? (
          <div className='selected-items'>{renderSelectedItems()}</div>
        ) : null
      }
      { postText && (<div className='post_text' dangerouslySetInnerHTML={{ __html: postText }} />) }
    </div>
  );
}

InputMultiSelect.propTypes = {
  literals: PropTypes.object.isRequired,
  preText: PropTypes.string, // Texto situado antes del input
  postText: PropTypes.string, // Texto situado despues del input
  isRequired: PropTypes.bool, // Indica si es obligatorio (visualmente nada más)
  isDisabled: PropTypes.bool, // Indica si el input está deshabilitado
  options: PropTypes.array.isRequired, // Opciones a escoger en el select
  onChange: PropTypes.func.isRequired, // Qué se hace cuando se cambia la selección
  error: PropTypes.array,
  value: PropTypes.array,
  button: PropTypes.object,
  mode: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    literals: state.i18n.literals.input,
  };
};


export default connect(mapStateToProps, {})(InputMultiSelect);
