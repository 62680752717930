/* eslint-disable no-undef */
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

let app = false;
if (typeof FIREBASE_CONFIG === 'object') {
  app = initializeApp(FIREBASE_CONFIG);
}

export const messaging = app ? getMessaging(app) : null;
