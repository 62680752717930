import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import OutputErrors from 'components/Inputs/outputErrors';
import InputToggle from 'components/Inputs/inputToggle';
import Button from 'components/Buttons/Button';
import { GripVertical } from 'lucide-react';
import { capitalizeFirstLetter } from 'utils/functions';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { deleteTable, postTable } from '../modules/actions';

const PopupConfigTableColumns = (props) => {
  const {
    literals,
    tableName,
    originalColumns,
    columns,
    setColumns,
    onClose,
  } = props;

  const [auxColumns, setAuxColumns] = useState(columns.columns);
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);

  const handleOrder = async (id, order) => {
    if (order >= 0) {
      setAuxColumns((prev) => {
        const moved = prev[id];
        const aux = prev.filter(col => +col.order !== +id);
        aux.splice(order, 0, moved);
        return aux.map((col, i) => ({ ...col, order: i }));
      });
    }
  };

  const handleVisible = (visible, index) => {
    setAuxColumns(prev => prev.map(col => (index === col.order
      ? { ...col, hide: !visible }
      : col
    )));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await postTable(tableName, auxColumns.map(col => ({
        id: col.id || col.field,
        order: col.order,
        hide: col.hide,
      })));
      setColumns({ columns: auxColumns, edited: true });
      onClose();
    } catch (err) {
      setErrors(err);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    await deleteTable(tableName);
    setColumns({ columns: originalColumns, edited: false });
    onClose();
  };

  const renderRows = () => {
    return auxColumns
      .filter(col => col.type !== 'menu')
      .map(col => (
        <Draggable key={col.order.toString()} draggableId={col.order.toString()} index={col.order}>
          {draggableProvider => (
            <div
              {...draggableProvider.draggableProps}
              {...draggableProvider.dragHandleProps}
              ref={draggableProvider.innerRef}
              className='config-columns-popup'
            >
              <div className='d-flex align-items-center'>
                <GripVertical size={18} className='mr-2' />
                <span>{col.label || capitalizeFirstLetter(col.id || col.field) }</span>
              </div>
              <div>
                <InputToggle
                  className='mb-0'
                  value={!col.hide}
                  onChange={v => handleVisible(v, col.order)}
                />
              </div>
            </div>
          )}
        </Draggable>
      ));
  };

  return (
    <Popup title={literals.configColumns} onClose={onClose} size='small'>
      <DragDropContext onDragEnd={async ({ draggableId, destination }) => {
        handleOrder(draggableId, destination?.index);
      }}
      >
        <Droppable droppableId='columns-rows'>
          {droppableProvider => (
            <div
              {...droppableProvider.droppableProps}
              ref={droppableProvider.innerRef}
            >
              { renderRows() }
              {droppableProvider.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <OutputErrors errors={errors} />
      <div className='buttons mt-4'>
        { columns.edited && (
          <Button
            text={literals.reset}
            color='secondary'
            onClick={handleDelete}
            loading={loading}
          />
        )}
        <Button
          text={literals.confirm}
          onClick={handleSubmit}
          loading={loading}
        />
      </div>
    </Popup>
  );
};

PopupConfigTableColumns.propTypes = {
  literals: PropTypes.object.isRequired,
  originalColumns: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  tableName: PropTypes.string.isRequired,
  setColumns: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupConfigTableColumns;
