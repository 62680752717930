import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Loading from 'components/Loading';
import Table from 'components/Table';
import Avatar from 'components/Avatar';
import Popup from 'components/Popup';
import { formatDate, getFullName } from 'utils/functions';
import { useCounter } from 'utils/customHooks';
import { Eye } from 'lucide-react';
import { fetchPlatform, fetchMessages } from '../modules/actions';
import './styles.scss';


const Support = (props) => {
  const {
    literals,
    initPlatform,
    // lang,
    // LANGS,
  } = props;
  const [platform, setPlatform] = useState(null);
  const [viewQuestion, setViewQuestion] = useState(null);
  const tableRefreshFlag = useCounter(0);

  const getPlatform = async (edited) => {
    setPlatform(null);
    const responsePlatform = await fetchPlatform();
    if (edited) {
      initPlatform();
    }
    setPlatform(responsePlatform);
  };

  useEffect(() => {
    getPlatform();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatMessage = (message) => {
    let result = message;
    if (result.length >= 50) {
      result = result.slice(0, 47).concat('...');
    }
    return result;
  };

  const vieQuestionHandler = (event) => {
    setViewQuestion(event);
  };
  const renderQuestion = () => {
    return (
      <div className='question'>
        <div className='question-header'>
          <div className='question-header-avatar'>
            <Avatar
              title={getFullName(viewQuestion.createdBy)}
              src={viewQuestion.createdBy?.avatar?.path}
              size={50}
            />
          </div>
          <div className='question-header-name'>
            {/* utilizar un literal */}
            <p>{getFullName(viewQuestion.createdBy)}</p>
          </div>
        </div>
        <div className='question-body'>
          <h6>{literals.date}</h6>
          <p>{formatDate(viewQuestion.createdAt, { time: true })}</p>
          <h6>{literals.message}</h6>
          <p>{viewQuestion.message}</p>
        </div>
      </div>
    );
  };
  const columns = [
    {
      key: 'createdBy_avatar', field: 'createdBy', type: 'avatar', title: data => getFullName(data), width: 45, label: 'Avatar', preRender: data => data?.avatar,
    },
    {
      key: 'createdBy_firstname', field: 'createdBy', label: literals.firstname, grow: 1, preRender: data => data?.firstname,
    },
    {
      key: 'createdBy_lastname', field: 'createdBy', label: literals.lastname, grow: 2, preRender: data => data?.lastname,
    },
    {
      field: 'message', label: literals.message, grow: 3, preRender: message => formatMessage(message),
    },
    {
      field: 'createdAt', label: literals.date, width: 150, preRender: data => formatDate(data, { time: true }),
    },
    {
      field: 'Ver', type: 'button', button: { text: 'ver', icon: <Eye />, onClick: vieQuestionHandler },
    },
  ];

  const filters = [];
  return (
    <Tool className='toolAdminPlatform'>
      {
        platform === null ? (
          <Loading hide={false} mode='tool' />
        ) : (
          <Panel margin={0}>
            <PanelHeader
              title={literals.title}
            />
            <PanelBody>
              <Table filters={filters} columns={columns} pageSize={10} fetch={fetchMessages} forceFetch={tableRefreshFlag.value} />
              { viewQuestion
              && (
                <Popup title={literals.question} onClose={() => setViewQuestion(null)}>
                  {renderQuestion()}
                </Popup>
              )}
            </PanelBody>
          </Panel>
        )
      }
    </Tool>
  );
};


Support.propTypes = {
  literals: PropTypes.object.isRequired,
  initPlatform: PropTypes.func.isRequired,
  // lang: PropTypes.string.isRequired,
  // LANGS: PropTypes.array.isRequired,
};

export default Support;
