/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import InputNumber from 'components/Inputs/inputNumber';
import OutputValue from 'components/Inputs/outputValue';
import { formatNumber } from 'utils/functions';

const ShareholdersDividend = ({
  literals,
  currency,
  editable = true,
  shareholders,
  setShareholders,
  amount,
}) => {
  const calcNewValues = (shs = shareholders, id = '') => {
    let auxAmount = amount;
    let auxPercentage = 100;
    shs
      .filter(sh => sh.custom && sh.amount !== '')
      .forEach((sh) => {
        if (sh.id !== id) {
          auxAmount -= sh.amount;
          auxPercentage -= sh.ownership;
        }
      });

    return { amount: Number(auxAmount), percentage: auxPercentage };
  };

  const updateDividend = (shs = shareholders) => {
    const newValues = calcNewValues(shs);
    setShareholders(shs.map((sh) => {
      return {
        ...sh,
        amount: sh.custom
          ? sh.amount
          : (sh.ownership * newValues.amount / newValues.percentage).toFixed(2),
      };
    }));
  };

  useEffect(() => {
    updateDividend();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  const handleChange = (shareholder, value) => {
    setShareholders(prev => prev.map((auxSh) => {
      if (auxSh.id === shareholder.id) {
        return { ...shareholder, amount: value };
      }
      return auxSh;
    }));
  };

  const handleBlur = async (sh) => {
    const newValues = calcNewValues();
    if (sh.amount === '') {
      const newShareholders = shareholders.map((auxShareholder) => {
        if (sh.id === auxShareholder.id) {
          return {
            ...auxShareholder,
            amount: (auxShareholder.ownership * newValues.amount / newValues.percentage).toFixed(2),
            custom: false,
          };
        }
        return auxShareholder;
      });
      setShareholders(newShareholders);
      updateDividend(newShareholders);
    } else if (sh.custom) {
      updateDividend();
    } else if ((sh.ownership * newValues.amount / newValues.percentage).toFixed(2) !== sh.amount) {
      const newShareholders = shareholders.map((auxShareholder) => {
        if (auxShareholder.id === sh.id) {
          return { ...auxShareholder, amount: sh.amount, custom: true };
        }
        return auxShareholder;
      });
      setShareholders(newShareholders);
      updateDividend(newShareholders);
    }
  };

  return (
    <>
      <div className='shares-assigments'>
        <div className='shares-assigments-table'>
          <div className='sh-header dividend'>
            <div className='cell-sh'><span>{literals.shareholder}</span></div>
            <div><span>{literals.ownership}</span></div>
            <div><span>{literals.dividends}</span></div>
          </div>

          <ul>
            {
              shareholders.map(sh => (
                <li key={`dividend-sh-${sh.id}`} className='li-shareholder dividend'>
                  <div className='cell-sh'>
                    <OutputValue
                      className='mb-0'
                      value={sh.name}
                    />
                  </div>
                  <div>
                    <OutputValue
                      className='mb-0'
                      value={formatNumber(sh.ownership, 0, { dec: 2, symbol: '%' })}
                    />
                  </div>
                  <div>
                    <InputNumber
                      className='mb-0'
                      minValue={0}
                      maxValue={calcNewValues(shareholders, sh.id).amount}
                      preText={literals.dividends}
                      value={sh.amount}
                      onChange={v => handleChange(sh, v)}
                      onBlur={() => handleBlur(sh)}
                      symbol={`${sh.custom ? '*' : ''}${currency.symbol}`}
                      isDisabled={!editable}
                      decimals={2}
                    />
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </>
  );
};

ShareholdersDividend.propTypes = {
  literals: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  shareholders: PropTypes.array.isRequired,
  setShareholders: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
};

export default ShareholdersDividend;
