import InvestmentsInstance from 'modules/investments/repository';
import KpisInstance from 'modules/kpis/repository';
import { apiDownloadFile } from 'modules/actions';
import { API_KPIS_EXCEL } from 'modules/apiEndpoints';
import {
  FETCH_KPIS_RELATIONS_INIT,
  FETCH_KPIS_RELATIONS_FAILURE,
  FETCH_KPIS_RELATIONS_SUCCESS,
  DELETE_KPI_RELATION_INIT,
  DELETE_KPI_RELATION_SUCCESS,
  DELETE_KPI_RELATION_FAILURE,
  POST_KPI_VALUE_INIT,
  POST_KPI_VALUE_SUCCESS,
  POST_KPI_VALUE_FAILURE,
  POST_KPI_VALUES_INIT,
  POST_KPI_VALUES_SUCCESS,
  POST_KPI_VALUES_FAILURE,
  DELETE_KPI_VALUE_INIT,
  DELETE_KPI_VALUE_SUCCESS,
  DELETE_KPI_VALUE_FAILURE,
  POST_KPI_BOOKMARK_INIT,
  POST_KPI_BOOKMARK_SUCCESS,
  POST_KPI_BOOKMARK_FAILURE,
  DELETE_KPI_BOOKMARK_INIT,
  DELETE_KPI_BOOKMARK_SUCCESS,
  DELETE_KPI_BOOKMARK_FAILURE,
  POST_KPI_INIT,
  POST_KPI_SUCCESS,
  POST_KPI_FAILURE,
  PUT_KPI_INIT,
  PUT_KPI_SUCCESS,
  POST_KPI_RELATION_INIT,
  POST_KPI_RELATION_SUCCESS,
  POST_KPI_RELATION_FAILURE,
  POST_KPIS_RELATIONS_INIT,
  POST_KPIS_RELATIONS_SUCCESS,
  POST_KPIS_RELATIONS_FAILURE,
  EDIT_KPI_RELATION_INIT,
  EDIT_KPI_RELATION_SUCCESS,
  EDIT_KPI_RELATION_FAILURE,
  ORDER_KPIS_RELATIONS_SUCCESS,
} from './types';

export const fetchKpiRelationsInit = scope => ({ type: FETCH_KPIS_RELATIONS_INIT, payload: scope });
export const fetchKpiRelationsSuccess = success => ({ type: FETCH_KPIS_RELATIONS_SUCCESS, payload: success });
export const fetchKpiRelationsFailure = error => ({ type: FETCH_KPIS_RELATIONS_FAILURE, payload: error });

export const postKpiInit = () => ({ type: DELETE_KPI_RELATION_INIT });
export const postKpiSuccess = success => ({ type: DELETE_KPI_RELATION_SUCCESS, payload: success });
export const postKpiFailure = error => ({ type: DELETE_KPI_RELATION_FAILURE, payload: error });

export const postKpiRelationInit = () => ({ type: POST_KPI_RELATION_INIT });
export const postKpiRelationSuccess = success => ({ type: POST_KPI_RELATION_SUCCESS, payload: success });
export const postKpiRelationFailure = error => ({ type: POST_KPI_RELATION_FAILURE, payload: error });

export const editKpiRelationInit = () => ({ type: EDIT_KPI_RELATION_INIT });
export const editKpiRelationSuccess = success => ({ type: EDIT_KPI_RELATION_SUCCESS, payload: success });
export const editKpiRelationFailure = error => ({ type: EDIT_KPI_RELATION_FAILURE, payload: error });

export const postKpisRelationsInit = () => ({ type: POST_KPIS_RELATIONS_INIT });
export const postKpisRelationsSuccess = success => ({ type: POST_KPIS_RELATIONS_SUCCESS, payload: success });
export const postKpisRelationsFailure = error => ({ type: POST_KPIS_RELATIONS_FAILURE, payload: error });

export const deleteKpiRelationInit = () => ({ type: DELETE_KPI_RELATION_INIT });
export const deleteKpiRelationSuccess = success => ({ type: DELETE_KPI_RELATION_SUCCESS, payload: success });
export const deleteKpiRelationFailure = error => ({ type: DELETE_KPI_RELATION_FAILURE, payload: error });

export const postKpiValueInit = () => ({ type: POST_KPI_VALUE_INIT });
export const postKpiValueSuccess = success => ({ type: POST_KPI_VALUE_SUCCESS, payload: success });
export const postKpiValueFailure = error => ({ type: POST_KPI_VALUE_FAILURE, payload: error });

export const postKpiValuesInit = () => ({ type: POST_KPI_VALUES_INIT });
export const postKpiValuesSuccess = success => ({ type: POST_KPI_VALUES_SUCCESS, payload: success });
export const postKpiValuesFailure = error => ({ type: POST_KPI_VALUES_FAILURE, payload: error });

export const deleteKpiValueInit = () => ({ type: DELETE_KPI_VALUE_INIT });
export const deleteKpiValueSuccess = success => ({ type: DELETE_KPI_VALUE_SUCCESS, payload: success });
export const deleteKpiValueFailure = error => ({ type: DELETE_KPI_VALUE_FAILURE, payload: error });

export const postKpiBookmarkInit = () => ({ type: POST_KPI_BOOKMARK_INIT });
export const postKpiBookmarkSuccess = success => ({ type: POST_KPI_BOOKMARK_SUCCESS, payload: success });
export const postKpiBookmarkFailure = error => ({ type: POST_KPI_BOOKMARK_FAILURE, payload: error });

export const deleteKpiBookmarkInit = () => ({ type: DELETE_KPI_BOOKMARK_INIT });
export const deleteKpiBookmarkSuccess = success => ({ type: DELETE_KPI_BOOKMARK_SUCCESS, payload: success });
export const deleteKpiBookmarkFailure = error => ({ type: DELETE_KPI_BOOKMARK_FAILURE, payload: error });

export const orderKpiRelationsSuccess = success => ({ type: ORDER_KPIS_RELATIONS_SUCCESS, payload: success });


export function initializeKpiRelations(fetch, performance, scopeType, scopeOwner) {
  if ((!performance.loading && !performance.loaded) || (performance.scope !== `${scopeType}-${scopeOwner}`)) {
    fetch(scopeType, scopeOwner);
  }
}

export function fetchKpiRelationsAction(scopeType, scopeOwner) {
  return (dispatch) => {
    dispatch(fetchKpiRelationsInit({ type: scopeType, id: scopeOwner }));

    return KpisInstance.getKpisRelations(scopeType, scopeOwner, { page: 0, size: 0 })
      .then((relations) => {
        dispatch(fetchKpiRelationsSuccess(relations.items));
      })
      .catch((error) => {
        console.error(error);
        dispatch(fetchKpiRelationsFailure(error.message));
      });
  };
}

export function fetchKpiRelations(scopeType, scopeOwner, page, size, filters) {
  return KpisInstance.getKpisRelations(scopeType, scopeOwner, { page, size, filters });
}

export function fetchKpiSummaryTable(scopeType, scopeOwner, opts) {
  return KpisInstance.getKpisSummaryTable(scopeType, scopeOwner, opts);
}

export function fetchKpiRelation(kpiId, scopeType, scopeOwner) {
  return KpisInstance.getKpisRelation(kpiId, scopeType, scopeOwner);
}

export function fetchKpis(page, size, search, filters, scope, owner) {
  return KpisInstance.getKpis({
    page,
    size,
    search,
    scope,
    filters,
    owner,
  });
}

export function postKpiAction(params) {
  return (dispatch) => {
    dispatch({
      type: POST_KPI_INIT,
    });

    return KpisInstance.createKpi(params)
      .then((response) => {
        dispatch({
          type: POST_KPI_SUCCESS,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: POST_KPI_FAILURE,
          payload: error.message,
        });
        return false;
      });
  };
}

export function putKpiAction(kpi, params) {
  return (dispatch) => {
    dispatch({ type: PUT_KPI_INIT });

    return KpisInstance.editKpi(kpi, params)
      .then((response) => {
        dispatch({
          type: PUT_KPI_SUCCESS, payload: response,
        });

        return response;
      })
      .catch(error => error.body);
  };
}

export function editKpiRelationAction(kpi, scopeType, scopeOwner, relation, data) {
  return (dispatch) => {
    dispatch(editKpiRelationInit());

    return KpisInstance.editKpiRelation(kpi, scopeType, scopeOwner, relation, data)
      .then((response) => {
        dispatch(editKpiRelationSuccess(response));
      })
      .catch((error) => {
        console.error(error);
        dispatch(editKpiRelationFailure(error.message));
      });
  };
}

export function postKpiRelationAction(kpi, scopeType, scopeOwner, data) {
  return (dispatch) => {
    dispatch(postKpiRelationInit());

    return KpisInstance.createKpiRelation(kpi, scopeType, scopeOwner, data)
      .then((relation) => {
        dispatch(postKpiRelationSuccess(relation));
      })
      .catch((error) => {
        console.error(error);
        dispatch(postKpiRelationFailure(error.message));
      });
  };
}

export function postKpiRelation(kpi, scopeType, scopeOwner, data) {
  return KpisInstance.createKpiRelation(kpi, scopeType, scopeOwner, data);
}

export function postKpisRelationsAction(scopeType, scopeOwner, kpis) {
  return (dispatch) => {
    dispatch(postKpisRelationsInit());
    return KpisInstance.createKpisRelations(scopeType, scopeOwner, { kpis })
      .then((relations) => {
        dispatch(postKpisRelationsSuccess(relations));
      })
      .catch((error) => {
        console.error(error);
        dispatch(postKpisRelationsFailure(error.message));
      });
  };
}


export function deleteKpiRelationAction(kpi, scopeType, scopeOwner, relation) {
  return (dispatch) => {
    dispatch(deleteKpiRelationInit());

    return KpisInstance.deleteKpiRelation(kpi, scopeType, scopeOwner, relation)
      .then(() => {
        dispatch(deleteKpiRelationSuccess(relation));
      })
      .catch((error) => {
        console.error(error);
        dispatch(deleteKpiRelationFailure(error.message));
      });
  };
}

export function postKpiValueAction(kpi, scopeType, scopeOwner, relation, date, params) {
  return (dispatch) => {
    dispatch(postKpiValueInit());

    return KpisInstance.createKpiRelationDateValue(kpi, scopeType, scopeOwner, relation, date, params)
      .then((response) => {
        dispatch(postKpiValueSuccess(response));
        return true;
      })
      .catch((error) => {
        console.error(error);
        dispatch(postKpiValueFailure(error.message));
        return false;
      });
  };
}


export function postKpiValuesAction(modelType, modelId, relations) {
  return (dispatch) => {
    dispatch(postKpiValuesInit());

    return KpisInstance.createKpisValues(modelType, modelId, { relations })
      .then((response) => {
        dispatch(postKpiValuesSuccess(response));
      })
      .catch((error) => {
        console.error(error);
        dispatch(postKpiValuesFailure(error.message));
      });
  };
}

export function deleteKpiValueAction(id, scopeType, scopeOwner, relation, date) {
  return (dispatch) => {
    dispatch(deleteKpiValueInit());

    return KpisInstance.deleteKpiRelationDateValue(id, scopeType, scopeOwner, relation, date)
      .then((response) => {
        dispatch(deleteKpiValueSuccess(response));
        return true;
      })
      .catch((error) => {
        console.error(error);
        dispatch(deleteKpiValueFailure(error.message));
        return false;
      });
  };
}

export function postKpiBookmarkAction(kpi, scopeType, scopeOwner, relation) {
  return (dispatch) => {
    dispatch(postKpiBookmarkInit());

    return KpisInstance.bookmarkKpiRelation(kpi, scopeType, scopeOwner, relation)
      .then((kpiRelation) => {
        dispatch(postKpiBookmarkSuccess(kpiRelation));
      })
      .catch((error) => {
        console.error(error);
        dispatch(postKpiBookmarkFailure(error.message));
      });
  };
}

export function deleteKpiBookmarkAction(kpi, scopeType, scopeOwner, relation) {
  return (dispatch) => {
    dispatch(deleteKpiBookmarkInit());

    return KpisInstance.unbookmarkKpiRelation(kpi, scopeType, scopeOwner, relation)
      .then((kpiRelation) => {
        dispatch(deleteKpiBookmarkSuccess(kpiRelation));
      })
      .catch((error) => {
        console.error(error);
        dispatch(deleteKpiBookmarkFailure(error.message));
      });
  };
}

export function fetchModelKpiValues(kpi, scopeType, scopeOwner, relation, page, size, filters) {
  return KpisInstance.getKpiRelationValues(kpi, scopeType, scopeOwner, relation, {
    page,
    size,
    sort: '-date',
    ...filters,
  });
}
export function exportModelKpiTable(type, id) {
  return KpisInstance.exportModelKpisToExcel(type, id);
}

export function fetchKpisValues(scopeType, scopeOwner, filters) {
  return KpisInstance.getKpisValues(scopeType, scopeOwner, filters);
}

export function getKpiStartupsComparator(kpi, filters) {
  return InvestmentsInstance.getKpiStartupsComparator(kpi, filters);
}

export function fetchKpiTemplates(page, size, search = '', opts = {}) {
  return KpisInstance.getKpisTemplates({
    page,
    size,
    search,
    ...opts,
  });
}

export function fetchKpiTemplate(id) {
  return KpisInstance.getKpisTemplate(id);
}

export function downloadExcelKpis(scopeType, scopeOwner, name, mode = '') {
  const filename = `KPIs_${name}${mode ? `_${mode}` : ''}.xlsx`;
  return apiDownloadFile(API_KPIS_EXCEL(scopeType, scopeOwner, mode), filename);
}

export function processModelKpisExcel(scopeType, scopeOwner, file) {
  return KpisInstance.uploadKpisExcel(scopeType, scopeOwner, file);
}

export function createKpisMultipleValues(modelType, modelId, data) {
  return KpisInstance.createKpisMultipleValues(modelType, modelId, data);
}

export function orderKpisAction(startup, relations) {
  return (dispatch) => {
    return KpisInstance.orderKpiRelation('startup', startup, { relations })
      .then(() => {
        dispatch(orderKpiRelationsSuccess(relations));
      });
  };
}
