import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import MentoringsComponents from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.mentoring,
  literalsOnboarding: state.i18n.literals.onboarding,
  literalsMentoring: state.i18n.literals.mentoring,
  user: state.session.user,
  startup: state.global.startup,
});

const mapDispatchToProps = () => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MentoringsComponents));
