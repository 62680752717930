import PlatfomrsInstance from 'modules/platforms/repository';
import { setPlatformLanguage } from 'components/LanguageSelector/modules/actions';
import { getBrowserDateFormat } from 'utils/functions';
import { FILES_BASE_URL } from 'constants/apiConf';
import ReactGA from 'react-ga4';
import initialState from './initialState';

const LOADING = 'LOADING';
const RESET_STORE = 'RESET_STORE';
const FETCH_PLATFORM_INIT = 'FETCH_PLATFORM_INIT';
const FETCH_PLATFORM_SUCCESS = 'FETCH_PLATFORM_SUCCESS';
const SET_GLOBAL_PROPERTIES = 'SET_GLOBAL_PROPERTIES';
export const SHOW_DIALOG = 'SHOW_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const SHOW_POPOVER = 'OPEN_POPOVER';
export const CLOSE_POPOVER = 'CLOSE_POPOVER';

// ACTIONS
function loading(payload) {
  return {
    type: LOADING,
    payload,
  };
}

function addScriptToHead(properties, attributes) {
  const existingScript = document.querySelector(`script[src="${properties.src}"]`);
  if (!existingScript) {
    const newTag = document.createElement('script');
    const scriptSet = document.getElementsByTagName('script')[0];
    Object.keys(properties).forEach((key) => {
      newTag[key] = properties[key];
    });
    if (attributes) {
      Object.keys(attributes).forEach((key) => {
        newTag.setAttribute(key, attributes[key]);
      });
    }
    scriptSet.parentNode.insertBefore(newTag, scriptSet);
  }
}

function initPlatform(payload) {
  window.DATE_FORMAT = getBrowserDateFormat();

  if (payload?.colors?.primary) {
    document.querySelector('meta[name="theme-color"]').setAttribute('content', payload.colors.primary);
    document.querySelector(':root').style.setProperty('--primary-color', payload.colors.primary);
    document.querySelector(':root').style.setProperty('--primary-light-color', `${payload.colors.primary}15`);
    document.querySelector(':root').style.setProperty('--primary-color-15', `${payload.colors.primary}15`);
    document.querySelector(':root').style.setProperty('--primary-color-25', `${payload.colors.primary}25`);
    document.querySelector(':root').style.setProperty('--primary-color-50', `${payload.colors.primary}50`);
    document.querySelector(':root').style.setProperty('--primary-color-75', `${payload.colors.primary}75`);
    window.PLATFORM_COLORS = payload.colors;
  }

  if (payload?.cmp?.oneTrust) {
    addScriptToHead({
      src: `https://cdn.cookielaw.org/consent/${payload.cmp.oneTrust}/OtAutoBlock.js`,
    });
    addScriptToHead({
      src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      charset: 'UTF-8',
    }, {
      'data-domain-script': payload.cmp.oneTrust,
    });
  }

  if (payload?.analytics?.ga4?.measurement) {
    ReactGA.initialize(payload.analytics.ga4.measurement, { debug: true });
  }

  if (payload?.name) {
    document.title = payload.name;
  }
  if (payload?.images?.favicon?.path) {
    document.querySelector("link[rel~='icon']").href = `${FILES_BASE_URL}${payload.images.favicon.path}`;
  }
  return {
    type: FETCH_PLATFORM_SUCCESS,
    payload,
  };
}

function resetStore() {
  return {
    type: RESET_STORE,
  };
}

const fetchPlatformInit = () => ({
  type: FETCH_PLATFORM_INIT,
});

// ACTION CREATORS
export function loadingAction(action) {
  return (dispatch) => {
    dispatch(loading(action));
  };
}

export function initPlatformAction(payload) {
  if (!payload) {
    return (dispatch) => {
      dispatch(fetchPlatformInit());
      return PlatfomrsInstance.getPlatformInfo().then((response) => {
        dispatch(setPlatformLanguage(false, response?.properties?.literals));
        dispatch(initPlatform(response));
      });
    };
  }
  return (dispatch) => {
    dispatch(initPlatform(payload));
  };
}

export function setGlobalPropertiesAction(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_GLOBAL_PROPERTIES,
      payload,
    });
  };
}

export function resetStoreAction() {
  return (dispatch) => {
    dispatch(resetStore());
  };
}

function showDialog(payload) {
  return {
    type: SHOW_DIALOG,
    payload,
  };
}

export function showDialogAction(action) {
  return (dispatch) => {
    dispatch(showDialog(action));
  };
}

function closeDialog() {
  return {
    type: CLOSE_DIALOG,
  };
}

export function closeDialogAction() {
  return (dispatch) => {
    dispatch(closeDialog());
  };
}

export function closePopoverAction() {
  return (dispatch) => {
    dispatch({ type: CLOSE_POPOVER });
  };
}

export function showPopoverAction() {
  return (dispatch) => {
    dispatch({ type: SHOW_POPOVER });
  };
}

// REDUCER
export default function globalReducer(state = initialState.global, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case RESET_STORE:
      return initialState;

    case SHOW_DIALOG:
      return {
        ...state,
        dialog: {
          show: true,
          ...action.payload,
        },
      };
    case SHOW_POPOVER:
      return {
        ...state,
        modal: {
          show: true,
          ...action.payload,
        },
      };

    case CLOSE_DIALOG:
      return {
        ...state,
        dialog: {
          ...initialState.global.dialog,
        },
      };
    case CLOSE_POPOVER:
      return {
        ...state,
        modal: {
          ...initialState.global.modal,
        },
      };

    case FETCH_PLATFORM_INIT:
      return {
        ...state,
        platform: {
          ...state.platform,
          loading: true,
        },
      };
    case FETCH_PLATFORM_SUCCESS:
      return {
        ...state,
        loading: false,
        platform: {
          loaded: true,
          loading: false,
          ...action.payload,
        },
      };
    case SET_GLOBAL_PROPERTIES: {
      return {
        ...state,
        currency: action.payload?.currency || null,
        startup: action.payload?.startup || null,
        investments: {
          shareholders: action.payload?.investments?.shareholders || state?.investments?.shareholders,
          currency: action.payload?.investments?.currency || state?.investments?.currency,
          currencies: action.payload?.investments?.currencies || state?.investments?.currencies,
        },
      };
    }
    default:
      return state;
  }
}
