import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteShareholderAction } from 'routes/Captable/modules/actions';
import ShareholdersComponents from '../components';

const mapStateToProps = state => ({
  literalsCommon: state.i18n.literals.common,
  literals: state.i18n.literals.shareholder,
  literalsShareholder: state.i18n.literals.shareholder,
  user: state.session.user,
  lang: state.i18n.language,
  startup: state.global.startup,
  captable: state.captable,
});

const mapDispatchToProps = dispatch => ({
  deleteShareholder: bindActionCreators(deleteShareholderAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareholdersComponents);
