import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const PermissionsInstance = ApiFetch();

export const URLS = {
  ADD_USER_TO_GROUP: 'permissions/:scopeType/:scopeId/groups/:id/user/:user',
  CREATE_PERMISSION_GROUP: 'permissions/:scopeType/:scopeId/groups',
  DELETE_PERMISSION_GROUP: 'permissions/:scopeType/:scopeId/groups/:id',
  EDIT_ELEMENT_PERMISSIONS: 'permissions/:scopeType/:scopeId/elements/:elementType/:elementId',
  EDIT_PERMISSION_GROUP: 'permissions/:scopeType/:scopeId/groups/:id',
  GET_ELEMENT_PERMISSIONS: 'permissions/:scopeType/:scopeId/elements/:elementType/:elementId',
  GET_PERMISSION_GROUP: 'permissions/:scopeType/:scopeId/groups/:id',
  GET_PERMISSION_GROUPS: 'permissions/:scopeType/:scopeId/groups',
  GET_SCOPE_MEMBERS: 'permissions/:scopeType/:scopeId/members',
  GET_SCOPE_MEMBERS_PERMISSIONS: 'permissions/:scopeType/:scopeId/members/permissions',
  REMOVE_USER_FROM_GROUP: 'permissions/:scopeType/:scopeId/groups/:id/user/:user',
};

async function addUserToGroup(scopeType, scopeId, groupId, userId) {
  const path = preparePath(URLS.ADD_USER_TO_GROUP, {
    scopeType, scopeId, id: groupId, user: userId,
  });
  return PermissionsInstance.post(path);
}

async function createPermissionGroup(scopeType, scopeId, data) {
  const path = preparePath(URLS.CREATE_PERMISSION_GROUP, { scopeType, scopeId });
  return PermissionsInstance.post(path, data);
}

async function deletePermissionGroup(scopeType, scopeId, groupId) {
  const path = preparePath(URLS.DELETE_PERMISSION_GROUP, { scopeType, scopeId, id: groupId });
  return PermissionsInstance.delete(path);
}

async function editElementPermissions(scopeType, scopeId, elementType, elementId, data) {
  const path = preparePath(URLS.EDIT_ELEMENT_PERMISSIONS, {
    scopeType, scopeId, elementType, elementId,
  });
  return PermissionsInstance.put(path, data);
}

async function editPermissionGroup(scopeType, scopeId, groupId, data) {
  const path = preparePath(URLS.EDIT_PERMISSION_GROUP, { scopeType, scopeId, id: groupId });
  return PermissionsInstance.put(path, data);
}

async function getElementPermissions(scopeType, scopeId, elementType, elementId) {
  const path = preparePath(URLS.GET_ELEMENT_PERMISSIONS, {
    scopeType, scopeId, elementType, elementId,
  });
  return PermissionsInstance.get(path);
}

async function getPermissionGroup(scopeType, scopeId, groupId) {
  const path = preparePath(URLS.GET_PERMISSION_GROUP, { scopeType, scopeId, id: groupId });
  return PermissionsInstance.get(path);
}

async function getPermissionGroups(scopeType, scopeId, params = {}) {
  const path = preparePath(URLS.GET_PERMISSION_GROUPS, { scopeType, scopeId }, params);
  return PermissionsInstance.get(path);
}

async function getScopeMembers(scopeType, scopeId, params = {}) {
  const path = preparePath(URLS.GET_SCOPE_MEMBERS, { scopeType, scopeId }, params);
  return PermissionsInstance.get(path);
}

async function getScopeMembersPermissions(scopeType, scopeId, params = {}) {
  const path = preparePath(URLS.GET_SCOPE_MEMBERS_PERMISSIONS, { scopeType, scopeId }, params);
  return PermissionsInstance.get(path);
}

async function removeUserFromGroup(scopeType, scopeId, groupId, userId) {
  const path = preparePath(URLS.REMOVE_USER_FROM_GROUP, {
    scopeType, scopeId, id: groupId, user: userId,
  });
  return PermissionsInstance.delete(path);
}

export default {
  addUserToGroup,
  createPermissionGroup,
  deletePermissionGroup,
  editElementPermissions,
  editPermissionGroup,
  getElementPermissions,
  getPermissionGroup,
  getPermissionGroups,
  getScopeMembers,
  getScopeMembersPermissions,
  removeUserFromGroup,
};
