import React from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Panel from 'components/Panel/components/';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelTab from 'components/Panel/components/PanelTab';
import ServicesTab from './ServicesTab';
import RequestTab from './RequestTab';
import './styles.scss';

const Services = (props) => {
  const { literals } = props;
  const tabs = [
    { key: 'services', name: literals.title },
    { key: 'requests', name: literals.requests },
  ];

  return (
    <Tool>
      <Panel>
        <PanelHeader title={literals.title} tabs={tabs} />
        <PanelBody>
          <PanelTab key='services'>
            <ServicesTab {...props} />
          </PanelTab>
          <PanelTab key='requests'>
            <RequestTab {...props} />
          </PanelTab>
        </PanelBody>
      </Panel>
    </Tool>
  );
};

Services.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};

export default Services;
