import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const StartupsCaptablesInstance = ApiFetch();

export const URLS = {
  BIND_SHAREHOLDER_TO_SYNDICATE: 'startups/:id/captable/syndicates/:syndicate/bindShareholder/:shareholder',
  BIND_USER_TO_SHAREHOLDER: 'startups/:id/captable/shareholders/:shareholder/bindUser/:user',
  CREATE_STARTUP_CAPTABLE_SERVICE: 'startups/:id/captable/services/requests',
  DELETE_STARTUP_CAPTABLE: 'startups/:id/captables/:captable',
  DELETE_STARTUP_CAPTABLE_SERVICE: 'startups/:id/captable/services/requests',
  DELETE_STARTUP_CAPTABLE_WATERFALLS: 'startups/:id/captable/waterfalls/:waterfall',
  DELETE_STARTUP_CAPTABLE_SIMULATION: 'startups/:id/captable/simulations/:simulation',
  DELETE_STARTUP_OPERATION: 'startups/:id/captable/operations/:operation',
  DELETE_STARTUP_SHARE_CLASS: 'startups/:id/captable/shareClasses/:shareClass',
  DELETE_STARTUP_SHAREHOLDER: 'startups/:id/captable/shareholders/:shareholder',
  DELETE_STARTUP_SYNDICATE: 'startups/:id/captable/syndicates/:syndicate',
  DELETE_STARTUP_VESTING_PLAN: 'startups/:id/captable/vestingPlans/:vestingPlan',
  DUPLICATE_STARTUP_CAPTABLE: 'startups/:id/captables/:captable/duplicate',
  EDIT_STARTUP_SYNDICATE: 'startups/:id/captable/syndicates/:syndicate',
  GET_STARTUP_CAPTABLE: 'startups/:id/captables/:captable',
  GET_STARTUP_CAPTABLE_DEBT: 'startups/:id/captable/debts/:debt/info',
  GET_STARTUP_CAPTABLE_DEBTS: 'startups/:id/captable/debts/:date',
  GET_STARTUP_CAPTABLE_DEBTS_CAPITALIZATIONS: 'startups/:id/captable/debts/:date/capitalizations',
  GET_STARTUP_CAPTABLE_DEBTS_REPAYMENTS: 'startups/:id/captable/debts/:date/repayments',
  GET_STARTUP_CAPTABLE_OPTION: 'startups/:id/captable/options/:option/info',
  GET_STARTUP_CAPTABLE_OPTIONS: 'startups/:id/captable/options/:date',
  GET_STARTUP_CAPTABLE_SERVICE: 'startups/:id/captable/services/requests',
  GET_STARTUP_CAPTABLE_SHARE: 'startups/:id/captable/shares/:share/info',
  GET_STARTUP_CAPTABLE_SHARES: 'startups/:id/captable/shares/:date',
  GET_STARTUP_CAPTABLE_SHARES_DISTRIBUTION: 'startups/:id/captable/shares/sharesDistribution/:date',
  GET_STARTUP_CAPTABLE_STATUTORY_BOOK: 'startups/:id/captable/shares/statutoryBook/:date',
  GET_STARTUP_CAPTABLE_SUMMARY: 'startups/:id/captable/summary/:type/:date',
  GET_STARTUP_CAPTABLE_SUMMARY_TABLE: 'startups/:id/captable/summary/table/:date',
  GET_STARTUP_CAPTABLE_VARIABLES: 'startups/:id/captable',
  GET_STARTUP_CAPTABLE_WATERFALL: 'startups/:id/captable/waterfalls/:waterfall',
  GET_STARTUP_CAPTABLE_WATERFALLS: 'startups/:id/captable/waterfalls',
  GET_STARTUP_CAPTABLE_SIMULATION: 'startups/:id/captable/simulations/:simulation',
  GET_STARTUP_CAPTABLE_SIMULATIONS: 'startups/:id/captable/simulations',
  GET_STARTUP_CAPTABLES: 'startups/:id/captables',
  GET_STARTUP_OPERATION: 'startups/:id/captable/operations/:operation',
  GET_STARTUP_OPERATIONS: 'startups/:id/captable/operations',
  GET_STARTUP_SHARE_CLASS: 'startups/:id/captable/shareClasses/:shareClass',
  GET_STARTUP_SHARE_CLASSES: 'startups/:id/captable/shareClasses',
  GET_STARTUP_SHAREHOLDER: 'startups/:id/captable/shareholders/:shareholder',
  GET_STARTUP_SHAREHOLDER_EXCEL_TEMPLATE: 'startups/:id/captable/shareholders/excel/template',
  GET_STARTUP_SHAREHOLDER_POSITION: 'startups/:id/captable/position',
  GET_STARTUP_SHAREHOLDER_POSITION_RESOURCE: 'startups/:id/captable/position/:resource',
  GET_STARTUP_SHAREHOLDERS: 'startups/:id/captable/shareholders',
  GET_STARTUP_SYNDICATE: 'startups/:id/captable/syndicates/:syndicate',
  GET_STARTUP_SYNDICATE_SHAREHOLDERS: 'startups/:id/captable/syndicates/:syndicate/shareholders',
  GET_STARTUP_SYNDICATES: 'startups/:id/captable/syndicates',
  GET_STARTUP_VESTING_PLAN: 'startups/:id/captable/vestingPlans/:vestingPlan',
  GET_STARTUP_VESTING_PLANS: 'startups/:id/captable/vestingPlans',
  GET_STARTUP_VESTING_PLAN_MILESTONES: 'startups/:id/captable/vestingPlans/:vestingPlan/milestones',
  GET_STARTUP_VESTING_PLAN_CALENDAR: 'startups/:id/captable/vestingPlans/:vestingPlan/calendar',
  INITIALIZE_CAPTABLE_EXCEL: 'startups/:id/captables/:captable/excel',
  MARK_STARTUP_CAPTABLE_AS_FAVORITE: 'startups/:id/captables/:captable/favorite',
  MASSIVE_DELETE_STARTUP_CAPTABLE_WATERFALLS: 'startups/:id/captable/waterfalls',
  POST_DUPLICATE_OPERATION: 'startups/:id/captable/operations/:operation/duplicate',
  POST_STARTUP_CAPTABLE: 'startups/:id/captables',
  POST_STARTUP_CAPTABLE_WATERFALLS: 'startups/:id/captable/waterfalls',
  POST_STARTUP_CAPTABLE_SIMULATIONS: 'startups/:id/captable/simulations',
  POST_STARTUP_OPERATION: 'startups/:id/captable/operations',
  POST_STARTUP_SHARE_CLASS: 'startups/:id/captable/shareClasses',
  POST_STARTUP_SHAREHOLDER: 'startups/:id/captable/shareholders',
  POST_STARTUP_SHAREHOLDER_EXCEL: 'startups/:id/captable/shareholders/excel',
  POST_STARTUP_SYNDICATE: 'startups/:id/captable/syndicates',
  POST_STARTUP_VESTING_PLAN: 'startups/:id/captable/vestingPlans',
  PUT_STARTUP_CAPTABLE: 'startups/:id/captables/:captable',
  PUT_STARTUP_CAPTABLE_WATERFALLS: 'startups/:id/captable/waterfalls/:waterfall',
  PUT_STARTUP_CAPTABLE_SIMULATIONS: 'startups/:id/captable/simulations/:simulation',
  PUT_STARTUP_OPERATION: 'startups/:id/captable/operations/:operation',
  PUT_STARTUP_OPERATION_DRAFT: 'startups/:id/captable/operations/:operation/draft',
  PUT_STARTUP_OPERATION_TIMELINE: 'startups/:id/captable/operations/:operation/timeline',
  PUT_STARTUP_SHARE_CLASS: 'startups/:id/captable/shareClasses/:shareClass',
  PUT_STARTUP_SHAREHOLDER: 'startups/:id/captable/shareholders/:shareholder',
  PUT_STARTUP_VESTING_PLAN: 'startups/:id/captable/vestingPlans/:vestingPlan',
  UNBIND_SHAREHOLDER_TO_SYNDICATE: 'startups/:id/captable/syndicates/:syndicate/bindShareholder/:shareholder',
  UNBIND_USER_TO_SHAREHOLDER: 'startups/:id/captable/shareholders/:shareholder/bindUser/:user',
  UNIFY_SHARE_CLASSES: 'startups/:id/captable/shareClasses/:shareclass/unify',
  UNIFY_SHAREHOLDERS: 'startups/:id/captable/shareholders/:shareholder/unify',
};

async function bindShareholderToSyndicate(id, syndicate, shareholder) {
  const path = preparePath(URLS.BIND_SHAREHOLDER_TO_SYNDICATE, { id, syndicate, shareholder });
  return StartupsCaptablesInstance.post(path);
}

async function bindUserToShareholder(id, shareholder, user) {
  const path = preparePath(URLS.BIND_USER_TO_SHAREHOLDER, { id, shareholder, user });
  return StartupsCaptablesInstance.post(path);
}

async function createStartupCaptableService(id, data) {
  const path = preparePath(URLS.CREATE_STARTUP_CAPTABLE_SERVICE, { id });
  return StartupsCaptablesInstance.post(path, data);
}

async function deleteStartupCaptable(id, captable) {
  const path = preparePath(URLS.DELETE_STARTUP_CAPTABLE, { id, captable });
  return StartupsCaptablesInstance.delete(path);
}

async function deleteStartupCaptableService(id, params = {}) {
  const path = preparePath(URLS.DELETE_STARTUP_CAPTABLE_SERVICE, { id }, params);
  return StartupsCaptablesInstance.delete(path);
}

async function deleteStartupCaptableWaterfalls(id, waterfall) {
  const path = preparePath(URLS.DELETE_STARTUP_CAPTABLE_WATERFALLS, { id, waterfall });
  return StartupsCaptablesInstance.delete(path);
}

async function deleteStartupCaptableSimulation(id, simulation) {
  const path = preparePath(URLS.DELETE_STARTUP_CAPTABLE_SIMULATION, { id, simulation });
  return StartupsCaptablesInstance.delete(path);
}

async function deleteStartupOperation(id, operation) {
  const path = preparePath(URLS.DELETE_STARTUP_OPERATION, { id, operation });
  return StartupsCaptablesInstance.delete(path);
}

async function deleteStartupShareClass(id, shareClass) {
  const path = preparePath(URLS.DELETE_STARTUP_SHARE_CLASS, { id, shareClass });
  return StartupsCaptablesInstance.delete(path);
}

async function deleteStartupShareholder(id, shareholder) {
  const path = preparePath(URLS.DELETE_STARTUP_SHAREHOLDER, { id, shareholder });
  return StartupsCaptablesInstance.delete(path);
}

async function deleteStartupSyndicate(id, syndicate) {
  const path = preparePath(URLS.DELETE_STARTUP_SYNDICATE, { id, syndicate });
  return StartupsCaptablesInstance.delete(path);
}

async function deleteStartupVestingPlan(id, vestingPlan) {
  const path = preparePath(URLS.DELETE_STARTUP_VESTING_PLAN, { id, vestingPlan });
  return StartupsCaptablesInstance.delete(path);
}

async function duplicateStartupCaptable(id, captable, data) {
  const path = preparePath(URLS.DUPLICATE_STARTUP_CAPTABLE, { id, captable });
  return StartupsCaptablesInstance.post(path, data);
}

async function editStartupSyndicate(id, syndicate, data) {
  const path = preparePath(URLS.EDIT_STARTUP_SYNDICATE, { id, syndicate });
  return StartupsCaptablesInstance.put(path, data);
}

async function getStartupCaptable(id, captable) {
  const path = preparePath(URLS.GET_STARTUP_CAPTABLE, { id, captable });
  return StartupsCaptablesInstance.get(path);
}

async function getStartupCaptableDebt(id, debt) {
  const path = preparePath(URLS.GET_STARTUP_CAPTABLE_DEBT, { id, debt });
  return StartupsCaptablesInstance.get(path);
}

async function getStartupCaptableDebts(id, date, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_CAPTABLE_DEBTS, { id, date }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupCaptableDebtsCapitalizations(id, date, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_CAPTABLE_DEBTS_CAPITALIZATIONS, { id, date }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupCaptableDebtsRepayments(id, date, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_CAPTABLE_DEBTS_REPAYMENTS, { id, date }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupCaptableOption(id, option) {
  const path = preparePath(URLS.GET_STARTUP_CAPTABLE_OPTION, { id, option });
  return StartupsCaptablesInstance.get(path);
}

async function getStartupCaptableOptions(id, date, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_CAPTABLE_OPTIONS, { id, date }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupCaptableService(id, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_CAPTABLE_SERVICE, { id }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupCaptableShare(id, share) {
  const path = preparePath(URLS.GET_STARTUP_CAPTABLE_SHARE, { id, share });
  return StartupsCaptablesInstance.get(path);
}

async function getStartupCaptableShares(id, date, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_CAPTABLE_SHARES, { id, date }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupCaptableSharesDistribution(id, date, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_CAPTABLE_SHARES_DISTRIBUTION, { id, date }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupCaptableStatutoryBook(id, date, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_CAPTABLE_STATUTORY_BOOK, { id, date }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupCaptableSummary(id, type, date, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_CAPTABLE_SUMMARY, { id, type, date }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupCaptableSummaryTable(id, date, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_CAPTABLE_SUMMARY_TABLE, { id, date }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupCaptableVariables(id, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_CAPTABLE_VARIABLES, { id }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupCaptableWaterfall(id, waterfall, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_CAPTABLE_WATERFALL, { id, waterfall }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupCaptableWaterfalls(id, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_CAPTABLE_WATERFALLS, { id }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupCaptableSimulation(id, simulation, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_CAPTABLE_SIMULATION, { id, simulation }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupCaptableSimulations(id, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_CAPTABLE_SIMULATIONS, { id }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupCaptables(id, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_CAPTABLES, { id }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupOperation(id, operation) {
  const path = preparePath(URLS.GET_STARTUP_OPERATION, { id, operation });
  return StartupsCaptablesInstance.get(path);
}

async function getStartupOperations(id, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_OPERATIONS, { id }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupShareClass(id, shareClass) {
  const path = preparePath(URLS.GET_STARTUP_SHARE_CLASS, { id, shareClass });
  return StartupsCaptablesInstance.get(path);
}

async function getStartupShareClasses(id, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_SHARE_CLASSES, { id }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupShareholder(id, shareholder, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_SHAREHOLDER, { id, shareholder }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupShareholderExcelTemplate(id) {
  const path = preparePath(URLS.GET_STARTUP_SHAREHOLDER_EXCEL_TEMPLATE, { id });
  return StartupsCaptablesInstance.get(path);
}

async function getStartupShareholderPosition(id, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_SHAREHOLDER_POSITION, { id }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupShareholderPositionResource(id, resource, shareholders, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_SHAREHOLDER_POSITION_RESOURCE, { id, resource }, {
    shareholders, ...params,
  });
  return StartupsCaptablesInstance.get(path);
}

async function getStartupShareholders(id, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_SHAREHOLDERS, { id }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupSyndicate(id, syndicate) {
  const path = preparePath(URLS.GET_STARTUP_SYNDICATE, { id, syndicate });
  return StartupsCaptablesInstance.get(path);
}

async function getStartupSyndicateShareholders(id, syndicate) {
  const path = preparePath(URLS.GET_STARTUP_SYNDICATE_SHAREHOLDERS, { id, syndicate });
  return StartupsCaptablesInstance.get(path);
}

async function getStartupSyndicates(id, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_SYNDICATES, { id }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupVestingPlan(id, vestingPlan) {
  const path = preparePath(URLS.GET_STARTUP_VESTING_PLAN, { id, vestingPlan });
  return StartupsCaptablesInstance.get(path);
}

async function getStartupVestingPlans(id, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_VESTING_PLANS, { id }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupVestingPlanMilestones(id, vestingPlan, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_VESTING_PLAN_MILESTONES, { id, vestingPlan }, params);
  return StartupsCaptablesInstance.get(path);
}

async function getStartupVestingPlanCalendar(id, vestingPlan, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_VESTING_PLAN_CALENDAR, { id, vestingPlan }, params);
  return StartupsCaptablesInstance.get(path);
}

async function inicializeCaptableExcel(id, captable, file) {
  const path = preparePath(URLS.INITIALIZE_CAPTABLE_EXCEL, { id, captable });
  return StartupsCaptablesInstance.postForm(path, { file });
}

async function markStartupCaptableAsFavorite(id, captable) {
  const path = preparePath(URLS.MARK_STARTUP_CAPTABLE_AS_FAVORITE, { id, captable });
  return StartupsCaptablesInstance.post(path);
}

async function massiveDeleteStartupCaptableWaterfalls(id, data) {
  const path = preparePath(URLS.MASSIVE_DELETE_STARTUP_CAPTABLE_WATERFALLS, { id });
  return StartupsCaptablesInstance.delete(path, { data });
}

async function postDuplicateOperation(id, operation) {
  const path = preparePath(URLS.POST_DUPLICATE_OPERATION, { id, operation });
  return StartupsCaptablesInstance.post(path);
}

async function postStartupCaptable(id, data) {
  const path = preparePath(URLS.POST_STARTUP_CAPTABLE, { id });
  return StartupsCaptablesInstance.post(path, data);
}

async function postStartupCaptableWaterfalls(id, data) {
  const path = preparePath(URLS.POST_STARTUP_CAPTABLE_WATERFALLS, { id });
  return StartupsCaptablesInstance.post(path, data);
}

async function postStartupCaptableSimulations(id, data) {
  const path = preparePath(URLS.POST_STARTUP_CAPTABLE_SIMULATIONS, { id });
  return StartupsCaptablesInstance.post(path, data);
}

async function putStartupCaptableSimulations(id, simulation, data) {
  const path = preparePath(URLS.PUT_STARTUP_CAPTABLE_SIMULATIONS, { id, simulation });
  return StartupsCaptablesInstance.put(path, data);
}

async function postStartupOperation(id, data) {
  const path = preparePath(URLS.POST_STARTUP_OPERATION, { id });
  return StartupsCaptablesInstance.post(path, data);
}

async function postStartupShareClass(id, data) {
  const path = preparePath(URLS.POST_STARTUP_SHARE_CLASS, { id });
  return StartupsCaptablesInstance.post(path, data);
}

async function postStartupShareholder(id, data) {
  const path = preparePath(URLS.POST_STARTUP_SHAREHOLDER, { id });
  return StartupsCaptablesInstance.post(path, data);
}

async function postStartupShareholderExcel(id, file) {
  const path = preparePath(URLS.POST_STARTUP_SHAREHOLDER_EXCEL, { id });
  return StartupsCaptablesInstance.postForm(path, { file });
}

async function postStartupSyndicate(id, data) {
  const path = preparePath(URLS.POST_STARTUP_SYNDICATE, { id });
  return StartupsCaptablesInstance.post(path, data);
}

async function postStartupVestingPlan(id, data) {
  const path = preparePath(URLS.POST_STARTUP_VESTING_PLAN, { id });
  return StartupsCaptablesInstance.post(path, data);
}

async function postStartupVestingPlanMilestones(id, vestingPlan, data) {
  const path = preparePath(URLS.GET_STARTUP_VESTING_PLAN_MILESTONES, { id, vestingPlan });
  return StartupsCaptablesInstance.post(path, data);
}

async function putStartupCaptable(id, captable, data) {
  const path = preparePath(URLS.PUT_STARTUP_CAPTABLE, { id, captable });
  return StartupsCaptablesInstance.put(path, data);
}

async function putStartupCaptableWaterfalls(id, waterfall, data) {
  const path = preparePath(URLS.PUT_STARTUP_CAPTABLE_WATERFALLS, { id, waterfall });
  return StartupsCaptablesInstance.put(path, data);
}

async function putStartupOperation(id, operation, data) {
  const path = preparePath(URLS.PUT_STARTUP_OPERATION, { id, operation });
  return StartupsCaptablesInstance.put(path, data);
}

async function putStartupOperationDraft(id, operation, data) {
  const path = preparePath(URLS.PUT_STARTUP_OPERATION_DRAFT, { id, operation });
  return StartupsCaptablesInstance.put(path, data);
}

async function putStartupOperationTimeline(id, operation, data) {
  const path = preparePath(URLS.PUT_STARTUP_OPERATION_TIMELINE, { id, operation });
  return StartupsCaptablesInstance.put(path, data);
}

async function putStartupShareClass(id, shareClass, data) {
  const path = preparePath(URLS.PUT_STARTUP_SHARE_CLASS, { id, shareClass });
  return StartupsCaptablesInstance.put(path, data);
}

async function putStartupShareholder(id, shareholder, data) {
  const path = preparePath(URLS.PUT_STARTUP_SHAREHOLDER, { id, shareholder });
  return StartupsCaptablesInstance.put(path, data);
}

async function putStartupVestingPlan(id, vestingPlan, data) {
  const path = preparePath(URLS.PUT_STARTUP_VESTING_PLAN, { id, vestingPlan });
  return StartupsCaptablesInstance.put(path, data);
}

async function unbindShareholderToSyndicate(id, syndicate, shareholder) {
  const path = preparePath(URLS.UNBIND_SHAREHOLDER_TO_SYNDICATE, { id, syndicate, shareholder });
  return StartupsCaptablesInstance.delete(path);
}

async function unbindUserToShareholder(id, shareholder, user) {
  const path = preparePath(URLS.UNBIND_USER_TO_SHAREHOLDER, { id, shareholder, user });
  return StartupsCaptablesInstance.delete(path);
}

async function unifyShareClasses(id, shareclass, data) {
  const path = preparePath(URLS.UNIFY_SHARE_CLASSES, { id, shareclass });
  return StartupsCaptablesInstance.post(path, data);
}

async function unifyShareholders(id, shareholder, data) {
  const path = preparePath(URLS.UNIFY_SHAREHOLDERS, { id, shareholder });
  return StartupsCaptablesInstance.post(path, data);
}

export default {
  bindShareholderToSyndicate,
  bindUserToShareholder,
  createStartupCaptableService,
  deleteStartupCaptable,
  deleteStartupCaptableService,
  deleteStartupCaptableWaterfalls,
  deleteStartupCaptableSimulation,
  deleteStartupOperation,
  deleteStartupShareClass,
  deleteStartupShareholder,
  deleteStartupSyndicate,
  deleteStartupVestingPlan,
  duplicateStartupCaptable,
  editStartupSyndicate,
  getStartupCaptable,
  getStartupCaptableDebt,
  getStartupCaptableDebts,
  getStartupCaptableDebtsCapitalizations,
  getStartupCaptableDebtsRepayments,
  getStartupCaptableOption,
  getStartupCaptableOptions,
  getStartupCaptableService,
  getStartupCaptableShare,
  getStartupCaptableShares,
  getStartupCaptableSharesDistribution,
  getStartupCaptableStatutoryBook,
  getStartupCaptableSummary,
  getStartupCaptableSummaryTable,
  getStartupCaptableVariables,
  getStartupCaptableWaterfall,
  getStartupCaptableWaterfalls,
  getStartupCaptableSimulation,
  getStartupCaptableSimulations,
  getStartupCaptables,
  getStartupOperation,
  getStartupOperations,
  getStartupShareClass,
  getStartupShareClasses,
  getStartupShareholder,
  getStartupShareholderExcelTemplate,
  getStartupShareholderPosition,
  getStartupShareholderPositionResource,
  getStartupShareholders,
  getStartupSyndicate,
  getStartupSyndicateShareholders,
  getStartupSyndicates,
  getStartupVestingPlan,
  getStartupVestingPlans,
  getStartupVestingPlanMilestones,
  getStartupVestingPlanCalendar,
  inicializeCaptableExcel,
  markStartupCaptableAsFavorite,
  massiveDeleteStartupCaptableWaterfalls,
  postDuplicateOperation,
  postStartupCaptable,
  postStartupCaptableWaterfalls,
  postStartupCaptableSimulations,
  postStartupOperation,
  postStartupShareClass,
  postStartupShareholder,
  postStartupShareholderExcel,
  postStartupSyndicate,
  postStartupVestingPlan,
  postStartupVestingPlanMilestones,
  putStartupCaptable,
  putStartupCaptableWaterfalls,
  putStartupCaptableSimulations,
  putStartupOperation,
  putStartupOperationDraft,
  putStartupOperationTimeline,
  putStartupShareClass,
  putStartupShareholder,
  putStartupVestingPlan,
  unbindShareholderToSyndicate,
  unbindUserToShareholder,
  unifyShareClasses,
  unifyShareholders,
};
