/* eslint-disable no-nested-ternary */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from 'utils/functions';
import { Skeleton } from '@mui/material';
import { Pencil, Check } from 'lucide-react';
import InputNumber from './inputNumber';
import './styles.scss';

const InputSlider = ({
  className = '',
  preText = '',
  postText = '',
  isRequired = false,
  isDisabled = false,
  value = '',
  onChange,
  minValue = 0,
  maxValue = 100,
  step = 1,
  symbol = '',
  loading = false,
  editable = false,
}) => {
  const [editing, setEditing] = useState(false);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);
  const rangeValue = useRef(null);

  const getPercent = useCallback(
    auxValue => Math.min(Math.round(((auxValue - minValue) / (maxValue - minValue)) * 100), 100),
    [minValue, maxValue],
  );

  useEffect(() => {
    if (typeof value !== 'number') {
      if (maxValRef.current) {
        const minPercent = getPercent(value.min);
        const maxPercent = getPercent(+maxValRef.current.value);

        if (range.current) {
          range.current.style.left = `${minPercent}%`;
          range.current.style.width = `${maxPercent - minPercent}%`;
        }
        if (rangeValue.current) {
          rangeValue.current.style.left = `${minPercent}%`;
          rangeValue.current.style.width = `${maxPercent - minPercent}%`;
        }
      }
    } else {
      const percent = getPercent(value);
      if (range.current) {
        range.current.style.width = `${percent > 0 ? percent : 0}%`;
      }
      if (rangeValue.current) {
        rangeValue.current.style.width = `${percent > 0 ? percent : 0}%`;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, value?.min, getPercent]);

  useEffect(() => {
    if (typeof value !== 'number' && minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(value.max);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
      if (rangeValue.current) {
        rangeValue.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value?.max, getPercent]);

  return (
    <div className={`input_slider ${className}`} onClick={e => e.stopPropagation()}>
      <div className='pre_text'>
        {preText}
        {isRequired && <span className='is_required'>*</span>}
      </div>
      <div className='w-100'>
        <input
          type='range'
          min={minValue}
          max={maxValue}
          value={typeof value !== 'number' ? value?.min : value}
          step={step}
          ref={minValRef}
          onChange={(e) => {
            if (typeof value !== 'number') {
              onChange({ min: Math.min(+e.target.value, value.max), max: value.max }, e);
            } else {
              onChange(+e.target.value, e);
            }
          }}
          className='thumb'
          disabled={isDisabled}
        />

        { typeof value !== 'number' ? (
          <input
            type='range'
            min={minValue}
            max={maxValue}
            value={value.max}
            step={step}
            ref={maxValRef}
            onChange={(e) => {
              onChange({ min: value.min, max: Math.max(+e.target.value, value.min) }, e);
            }}
            className='thumb'
            disabled={isDisabled}
          />
        ) : null}

        <div className={`slider ${loading ? 'loading' : ''}`}>
          <div className='slider__min_max'>
            {!loading ? (
              <>
                <span>{formatNumber(minValue)}</span>
                <span>{formatNumber(maxValue)}</span>
              </>
            ) : (
              <>
                <Skeleton animation='wave' width={20} />
                <Skeleton animation='wave' width={20} />
              </>
            )
            }
          </div>
          <div className='slider__track' />
          <div ref={range} className={`slider__range ${typeof value !== 'number' ? 'interval' : ''}`} />
          <div ref={rangeValue} className='slider__values'>
            <span className={typeof value !== 'number' ? 'min-value' : (editing === false ? 'value' : 'input-value')}>
              {editing === false
                ? formatNumber(value?.min || value, 0, { symbol })
                : (
                  <InputNumber
                    value={editing}
                    onChange={setEditing}
                    symbol={symbol}
                  />
                )
              }
              { editable && (
                <>
                  { editing !== false
                    ? <Check className='ml-1' size={14} onClick={() => { onChange(editing); setEditing(false); }} />
                    : <Pencil className='ml-1' size={14} onClick={() => setEditing(value)} /> }
                </>
              )}
            </span>
            { typeof value !== 'number' && <span className={`value ${value.min === value.max ? 'hide' : ''}`}>{`${value.max} ${symbol}`}</span> }
          </div>
        </div>
      </div>
      { /* eslint-disable-next-line react/no-danger */ }
      { postText && (<div className='post_text' dangerouslySetInnerHTML={{ __html: postText }} />) }
    </div>
  );
};

InputSlider.propTypes = {
  className: PropTypes.string,
  preText: PropTypes.string,
  postText: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  step: PropTypes.number,
  symbol: PropTypes.string,
  loading: PropTypes.bool,
  editable: PropTypes.bool,
};

export default InputSlider;
