import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Button from 'components/Buttons/Button';
import Note from 'components/Note';
import InputSlider from 'components/Inputs/inputSlider';
import ShareholdersSelect from 'components/ShareholdersSelect';
import IOFileLoading from 'components/IOFileLoading/components';
import Table from 'components/Table';
import Avatar from 'components/Avatar';
import { CloudDownload } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';
import { formatDate, formatNumber } from 'utils/functions';
import { Skeleton } from '@mui/material';
import { downloadReportShareholder, getShareholderPosition } from '../modules/actions';
import MyPositionOverview from './PositionOverview';
import MyPositionResources from './PositionResources';
import MyPositionValuation from './PositionValuation';
import './styles.scss';

const MyPosition = (props) => {
  const {
    literals,
    literalsCommon,
    literalsCaptable,
    startup,
    currency,
  } = props;

  const [shareholders, setShareholders] = useState([]);
  const [position, setPosition] = useState();
  const [resource, setResource] = useState('shares');
  const [loading, setLoading] = useState(true);
  const [lastValuation, setLastValuation] = useState({ real: 0, new: 0, pps: 0 });
  const [downloadingReport, setDownloadingReport] = useState(false);

  const [searchParams] = useSearchParams();
  const taxIds = searchParams?.get('taxId')?.split(',') || [];

  useEffect(() => {
    if (shareholders && startup.id) {
      (async () => {
        setLoading(true);
        const auxPosition = await getShareholderPosition(startup.id, { shareholders: shareholders.join(',') });
        setPosition(auxPosition);
        const { valuation, pps } = auxPosition.valuation[auxPosition.valuation.length - 1];
        setLastValuation({ real: valuation, new: valuation, pps });
        setLoading(false);
      })();
    }
  }, [shareholders, startup.id]);

  const handleDownloadReport = async () => {
    setDownloadingReport(true);
    const date = formatDate(null, { format: 'dmY' });
    const shareholderName = shareholders.length > 1 ? 'reports' : 'shareholder';
    const filename = [date, shareholderName, startup.name].join('_');
    const ext = shareholders.length > 1 ? 'zip' : 'pdf';
    await downloadReportShareholder(startup.id, shareholders, `${filename}.${ext}`.replace(/\s/g, ''));
    setDownloadingReport(false);
  };

  const columns = [
    {
      width: 35, title: () => startup?.name, preRender: () => <Avatar title={startup.name} src={startup?.logo?.path} />,
    },
    {
      label: literalsCommon.name, preRender: () => startup?.name,
    },
    {
      field: 'shares',
      label: literals.entryDate,
      preRender: v => (v?.entryDate ? formatDate(v.entryDate) : '-'),
      width: 120,
    },
    {
      field: 'shares',
      label: literalsCaptable.ownership,
      preRender: v => formatNumber(v?.ownership, '-', { symbol: '%', dec: 2 }),
      width: 120,
    },
    {
      field: 'shares',
      label: literalsCaptable.fullyDilutedAcronym,
      title: literalsCaptable.fullyDiluted,
      preRender: v => formatNumber(v?.fullyDiluted, '-', { symbol: '%', dec: 2 }),
      width: 120,
    },
    {
      field: 'shares',
      label: 'Votes',
      preRender: v => formatNumber(v.votes, '-', { symbol: '%', dec: 2 }),
      width: 120,
    },
    {
      field: 'shares',
      label: 'Shares',
      preRender: v => (<span title={`${v.votes}% ${literalsCaptable.vote}`}>{formatNumber(v.number, '-')}</span>),
      width: 120,
    },
    {
      field: 'shares',
      label: 'C.Shares',
      preRender: v => v.capital,
      width: 120,
    },
    {
      field: 'debts',
      label: 'C.Debts',
      preRender: v => v.pending,
      width: 120,
    },
    {
      field: 'options',
      label: literalsCaptable.options,
      title: `${literalsCaptable.stockOption} | ${literalsCaptable.phantom}`,
      preRender: v => `${formatNumber(v.number, '-')}`,
      width: 110,
    },
  ];

  return (
    <Tool className='myPosition-wrapper'>
      <div className='row flex-lg-row-reverse'>
        {shareholders && (
          <div className='col-12 col-lg-4 shareholders-list-wrapper mb-5'>
            <Button
              color='secondary'
              icon={CloudDownload}
              text={literalsCaptable.certificate}
              onClick={handleDownloadReport}
            />
            <ShareholdersSelect
              mode='position'
              taxIds={taxIds}
              startup={startup.id}
              positionShareholders={shareholders}
              setShareholders={setShareholders}
            />
          </div>
        )}
        <div className='col-12 col-lg-8 mb-3'>
          <div className='d-flex justify-content-center align-items-center'>
            <span className='position-valuation--title'>{`${literals.latestCompanyValuation}:`}</span>
            <span className='position-valuation--value'>
              {!loading
                ? formatNumber(lastValuation.real, '-', { symbol: currency.symbol })
                : <Skeleton width={100} height={30} />
              }
            </span>
          </div>
          <InputSlider
            minValue={lastValuation.real / 10}
            maxValue={lastValuation.real * 10}
            value={lastValuation.new}
            onChange={v => setLastValuation(prev => ({ ...prev, new: v }))}
            symbol={currency.symbol}
            loading={loading}
            editable
          />
        </div>
      </div>
      <Table
        className='mb-5'
        key={loading}
        searcher={false}
        columns={columns}
        fetch={!loading ? () => ({ items: [position], total: 0 }) : null}
        pageSize={1}
      />
      <div className='row'>
        <div className='col-12 col-xl-7 mb-5'>
          <MyPositionOverview
            literals={literals}
            position={position}
            lastPPS={(lastValuation.new / lastValuation.real) * lastValuation.pps}
            currency={currency}
            resource={resource}
            setResource={setResource}
            loading={loading}
          />
        </div>
        <div className='col-12 col-xl-5 mt-3 mb-5'>
          <MyPositionValuation
            literals={literals}
            valuations={position?.valuation || []}
            lastValuation={lastValuation.new}
            lastPPS={(lastValuation.new / lastValuation.real) * lastValuation.pps}
            currency={currency}
            loading={loading}
          />
        </div>
      </div>
      <MyPositionResources
        literals={literals}
        literalsCommon={literalsCommon}
        position={position}
        resource={['vestedOptions', 'pendingOptions'].includes(resource) ? 'options' : resource}
        shareholders={shareholders.join(',')}
        startup={startup.id}
        currency={currency}
        loading={loading}
      />
      <Panel>
        <PanelHeader title={literalsCommon.notes} />
        <PanelBody>
          <Note type='startup' id={startup.id} />
        </PanelBody>
      </Panel>
      { downloadingReport && (
        <IOFileLoading
          mode='download'
          file={{ name: `${shareholders}_report.pdf` }}
        />
      ) }
    </Tool>
  );
};

MyPosition.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  literalsCaptable: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
};

export default MyPosition;
