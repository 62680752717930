import StartupsCaptablesInstance from 'modules/startupsCaptables/repository';
import { apiDownloadFile } from 'modules/actions';
import { API_SHAREHOLDERS_EXCEL, API_SHAREHOLDERS_EXCEL_TEMPLATE } from 'modules/apiEndpoints';
import { formatErrors } from 'utils/functions';

export function getShareHolders(id, page, size, others = {}) {
  return StartupsCaptablesInstance.getStartupShareholders(id, { page, size, ...others });
}

export function putShareholders(id, data) {
  return StartupsCaptablesInstance.putStartupShareholder(id, data.id, data);
}

export function postShareholders(id, data) {
  return StartupsCaptablesInstance.postStartupShareholder(id, data);
}

export function getShareHolder(id, shareholder, params = {}) {
  return StartupsCaptablesInstance.getStartupShareholder(id, shareholder, params);
}

export function deleteShareholder(id, shareholder) {
  return StartupsCaptablesInstance.deleteStartupShareholder(id, shareholder).then((response) => {
    return response;
  }).catch((error) => {
    throw formatErrors(error);
  });
}

export function fetchCaptableShares(startup, date, params) {
  return StartupsCaptablesInstance.getStartupCaptableShares(startup, date, params);
}

export function fetchCaptableDebts(startup, date, params) {
  return StartupsCaptablesInstance.getStartupCaptableDebts(startup, date, params);
}

export function fetchCaptableOptions(startup, date, params) {
  return StartupsCaptablesInstance.getStartupCaptableOptions(startup, date, params);
}

export function uploadExcelShareholders(startup, file) {
  return StartupsCaptablesInstance.postStartupShareholderExcel(startup, file).then((data) => {
    return data;
  }).catch((errors) => {
    throw formatErrors(errors);
  });
}

export function downloadExcelTemplate(id) {
  return apiDownloadFile(API_SHAREHOLDERS_EXCEL_TEMPLATE(id), 'shareholders_template.xlsx');
}

export function bindUserToShareholder(id, shareholder, user) {
  return StartupsCaptablesInstance.bindUserToShareholder(id, shareholder, user);
}

export function unbindUserToShareholder(id, shareholder, user) {
  return StartupsCaptablesInstance.unbindUserToShareholder(id, shareholder, user);
}

export function getFolder(type, id, folder) {
  return StartupsCaptablesInstance.getFolder(type, id, folder);
}

// Syndicates
export function getSyndicates(id, page, size, others = {}) {
  return StartupsCaptablesInstance.getStartupSyndicates(id, { page, size, ...others });
}

export function getSyndicate(id, syndicate) {
  return StartupsCaptablesInstance.getStartupSyndicate(id, syndicate);
}

export function postSyndicate(id, data) {
  return StartupsCaptablesInstance.postStartupSyndicate(id, data);
}

export function putSyndicate(id, syndicate, data) {
  return StartupsCaptablesInstance.editStartupSyndicate(id, syndicate, data);
}

export function deleteSyndicate(id, syndicate) {
  return StartupsCaptablesInstance.deleteStartupSyndicate(id, syndicate)
    .then(response => response)
    .catch((error) => { throw formatErrors(error); });
}

export function getSyndicateShareHolders(id, syndicate, page, size, others = {}) {
  return StartupsCaptablesInstance.getStartupSyndicateShareholders(id, syndicate, { page, size, ...others });
}

export function bindShareholderToSyndicate(id, syndicate, shareholder) {
  return StartupsCaptablesInstance.bindShareholderToSyndicate(id, syndicate, shareholder);
}

export function unbindShareholderToSyndicate(id, syndicate, shareholder) {
  return StartupsCaptablesInstance.unbindShareholderToSyndicate(id, syndicate, shareholder);
}

export function unifyShareholders(id, shareholder, shareholders) {
  return StartupsCaptablesInstance.unifyShareholders(id, shareholder, { shareholders });
}

export const downloadExcelShareholder = (startup) => {
  return apiDownloadFile(API_SHAREHOLDERS_EXCEL(startup), 'shareholder.xlsx');
};
