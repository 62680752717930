import ApiFetch from 'utils/apiFetch';
import { preparePath } from 'utils/preparePath';

const StartupsReportsInstance = ApiFetch();

export const URLS = {
  DELETE_STARTUP_REPORT: 'startups/:id/reports/:report',
  GET_STARTUP_REPORT: 'startups/:id/reports/:report',
  GET_STARTUP_REPORT_EMAILS: 'startups/:id/reports/:report/emails',
  GET_STARTUP_REPORT_PREVIEW: 'startups/:id/reports/preview',
  GET_STARTUP_REPORT_USERS: 'startups/:id/reports/:report/users',
  GET_STARTUP_REPORTS: 'startups/:id/reports',
  POST_STARTUP_REPORT: 'startups/:id/reports',
  PUT_STARTUP_REPORT: 'startups/:id/reports/:report',
  SEND_STARTUP_REPORT_TEST: 'startups/:id/reports/test',
};

async function deleteStartupReport(id, report) {
  const path = preparePath(URLS.DELETE_STARTUP_REPORT, { id, report });
  return StartupsReportsInstance.delete(path);
}

async function getStartupReport(id, report) {
  const path = preparePath(URLS.GET_STARTUP_REPORT, { id, report });
  return StartupsReportsInstance.get(path);
}

async function getStartupReportEmails(id, report, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_REPORT_EMAILS, { id, report }, params);
  return StartupsReportsInstance.get(path);
}

async function getStartupReportPreview(id, data) {
  const path = preparePath(URLS.GET_STARTUP_REPORT_PREVIEW, { id });
  return StartupsReportsInstance.post(path, data);
}

async function getStartupReportUsers(id, report, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_REPORT_USERS, { id, report }, params);
  return StartupsReportsInstance.get(path);
}

async function getStartupReports(id, params = {}) {
  const path = preparePath(URLS.GET_STARTUP_REPORTS, { id }, params);
  return StartupsReportsInstance.get(path);
}

async function postStartupReport(id, data) {
  const path = preparePath(URLS.POST_STARTUP_REPORT, { id });
  return StartupsReportsInstance.post(path, data);
}

async function putStartupReport(id, report, data) {
  const path = preparePath(URLS.PUT_STARTUP_REPORT, { id, report });
  return StartupsReportsInstance.put(path, data);
}

async function sendStartupReportTest(id, data) {
  const path = preparePath(URLS.SEND_STARTUP_REPORT_TEST, { id });
  return StartupsReportsInstance.post(path, data);
}

export default {
  deleteStartupReport,
  getStartupReport,
  getStartupReportEmails,
  getStartupReportPreview,
  getStartupReportUsers,
  getStartupReports,
  postStartupReport,
  putStartupReport,
  sendStartupReportTest,
};
