import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Loading from 'components/Loading';
import { formatDate, formatNumber } from 'utils/functions';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import './styles.scss';

const PopupShareholderOptions = (props) => {
  const {
    literals,
    literalsCommon,
    currency,
    row,
    onClose,
  } = props;

  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const params = useParams();

  if (row.loading) {
    return (
      <Popup title={literals.optionDetails} onClose={onClose}>
        <Loading mode='panel' hide={false} />
      </Popup>
    );
  }

  return (
    <Popup title={literals.optionDetails} onClose={onClose}>
      <table className='table-captable-details'>
        <tbody>
          <tr>
            <td>{literalsCommon.date}</td>
            <td>{formatDate(row.originalOperation.date)}</td>
          </tr>
          <tr>
            <td>{literals.operation}</td>
            <td><Link to={embedView(ROUTE_STARTUP_PATH.setCapTableOperation(params.id, captableId, row.operation.id))}>{row.operation.name}</Link></td>
          </tr>
          <tr>
            <td>{literals.shareholder}</td>
            <td>{row?.shareholder?.name || '-'}</td>
          </tr>
          <tr>
            <td>{literalsCommon.name}</td>
            <td>{row.name}</td>
          </tr>
          <tr>
            <td>{literalsCommon.type}</td>
            <td>{literals[row.type]}</td>
          </tr>
          <tr>
            <td>{literals.expirationDate}</td>
            <td>{row.expirationDate ? formatDate(row.expirationDate) : '-'}</td>
          </tr>
          <tr>
            <td>{literals.number}</td>
            <td>{row.number}</td>
          </tr>
          <tr>
            <td>{literals.contributedCapital}</td>
            <td>{`${formatNumber(row.capital, 0)}${currency.symbol}`}</td>
          </tr>
          <tr>
            <td>{literals.exercisePrice}</td>
            <td>{`${formatNumber(row.exercisePrice, 0)}${currency.symbol}`}</td>
          </tr>
          <tr>
            <td>{literals.ratio}</td>
            <td>{formatNumber(row.ratio, '-', { symbol: 'X' })}</td>
          </tr>
          <tr>
            <td>{literals.assigned}</td>
            <td>{row.assigned}</td>
          </tr>
          <tr>
            <td>{literals.vested}</td>
            <td>
              {row.vested}
              <br />
              {row.vests.map(vest => (
                <Link to={embedView(ROUTE_STARTUP_PATH.setCapTableOperation(params.id, captableId, vest.operation))}>
                  <div>{`- ${vest.date} (${vest.number})`}</div>
                </Link>
              ))}
            </td>
          </tr>
          { row.type === 'stockOption' && (
            <tr>
              <td>{literals.exercised}</td>
              <td>
                {row.exercised}
                <br />
                {row.exercises.map(exercise => (
                  <Link to={embedView(ROUTE_STARTUP_PATH.setCapTableOperation(params.id, captableId, exercise.operation))}>
                    <div>{`- ${exercise.amount} ${literals.optionsIn.toLowerCase()} ${exercise.shares}  ${literals.shares.toLowerCase()}`}</div>
                  </Link>
                ))}
              </td>
            </tr>
          )}
          <tr>
            <td>{literals.vestingPlan}</td>
            <td>{row.vestingPlan?.name || '-'}</td>
          </tr>
          <tr>
            <td>{literals.fullyDiluted}</td>
            <td>{`${formatNumber(row.fullyDiluted, 0, { dec: 2 })}%`}</td>
          </tr>
        </tbody>
      </table>
    </Popup>
  );
};

PopupShareholderOptions.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopupShareholderOptions;
