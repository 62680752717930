import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { ROUTE_STARTUP_PATH } from 'routes';
import { captableSubMenu } from 'components/LeftMenu/components/navStartup';

const NavCaptable = (props) => {
  const {
    literals,
    startup,
  } = props;

  const location = useLocation();
  const { setEmbed } = ROUTE_STARTUP_PATH;
  const isActualPath = route => location.pathname === setEmbed(route);

  return captableSubMenu(literals, startup).map(route => (
    <Link
      key={route.path}
      className={`embed-nav-item ${isActualPath(route.to) ? 'active' : ''} ${route.hide ? 'hide' : ''}`}
      to={setEmbed(route.to)}
    >
      {route.name}
    </Link>
  ));
};

NavCaptable.propTypes = {
  literals: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default NavCaptable;
