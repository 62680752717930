import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StatCard from 'components/Statistics/StatCard';
import {
  Briefcase, Users, User, SquareUser,
} from 'lucide-react';

import {
  getAdminSummary, getAdminMentoringsSummary, getAdminDataroomSummary,
  getAdminCaptableSummary, getAdminReportsSummary, getAdminKpisSummary,
} from '../modules/actions';

import SummaryAdminMentorings from './admin/SummaryAdminMentorings';
import SummaryAdminCapTable from './admin/SummaryAdminCapTable';
import SummaryAdminPerformance from './admin/SummaryAdminPerformance';
import SummaryAdminReports from './admin/SummaryAdminReports';
import SummaryAdminDataroom from './admin/SummaryAdminDataroom';

const HomeAdmin = ({ literalsAdmin, platform }) => {
  const [summaries, setSummaries] = useState();
  const tools = platform.properties.tools;

  useEffect(() => {
    Promise.all([
      getAdminSummary(),
      tools.mentoring ? getAdminMentoringsSummary() : null,
      tools.performance ? getAdminKpisSummary() : null,
      tools.capTable ? getAdminCaptableSummary() : null,
      tools.reporting ? getAdminReportsSummary() : null,
      tools.dataRoom ? getAdminDataroomSummary() : null,
    ]).then(([summary, mentorings, kpis, captable, reports, dataroom]) => {
      setSummaries({
        ...summary, mentorings, kpis, captable, reports, dataroom,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='row mb-4'>
        <div className='col-12 col-md-3'>
          <StatCard title={literalsAdmin.users} icon={Users} value={summaries?.users?.total} loading={!summaries} />
        </div>

        <div className='col-12 col-md-3'>
          <StatCard title={literalsAdmin.entrepreneurs} icon={User} value={summaries?.users?.user} loading={!summaries} />
        </div>

        <div className='col-12 col-md-3'>
          <StatCard title={literalsAdmin.mentors} icon={SquareUser} value={summaries?.users?.mentor} loading={!summaries} />
        </div>

        <div className='col-12 col-md-3'>
          <StatCard title={literalsAdmin.startups} icon={Briefcase} value={summaries?.startups} loading={!summaries} />
        </div>
      </div>

      { tools.capTable && <SummaryAdminCapTable literals={literalsAdmin} captable={summaries?.captable} /> }
      { tools.mentoring && <SummaryAdminMentorings literals={literalsAdmin} mentorings={summaries?.mentorings} /> }
      { tools.performance && <SummaryAdminPerformance literals={literalsAdmin} performance={summaries?.kpis} /> }
      { tools.reporting && <SummaryAdminReports literals={literalsAdmin} reports={summaries?.reports} /> }
      { tools.dataRoom && <SummaryAdminDataroom literals={literalsAdmin} dataroom={summaries?.dataroom} /> }
    </>
  );
};

HomeAdmin.propTypes = {
  literalsAdmin: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
};

export default HomeAdmin;
