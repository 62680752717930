import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Button from 'components/Buttons/Button';
import InputText from 'components/Inputs/inputText';
import InputRichText from 'components/Inputs/inputRichText';
import InputFileZone from 'components/Inputs/inputFileZone';
import InputSelect from 'components/Inputs/inputSelect';
import InputEmail from 'components/Inputs/inputEmail';
import { Upload } from 'lucide-react';
import { connect } from 'react-redux';
import { fetchStartupUsers } from 'routes/StartupUsers/modules/actions';
import { startupCanView } from 'utils/functions';
import { TOOL_USERS } from 'constants/tools';
import { postDocumentToSign } from './modules/actions';
import './styles.scss';

const UploadDocumentPopup = (props) => {
  const {
    literals,
    literalsCommon,
    type,
    id,
    name,
    shareholder,
    startup,
    onClose,
  } = props;

  const [users, setUsers] = useState(false);
  const [email, setEmail] = useState({ user: '', custom: '' });
  const [loading, setLoading] = useState(false);
  const [uploadForm, setUploadForm] = useState({
    subject: '', message: '', file: null, signatureZone: 'bottom-right',
  });

  useEffect(() => {
    if (startupCanView(startup, TOOL_USERS)) {
      (async () => {
        const auxUsers = await fetchStartupUsers(startup.id, {
          filters: { shareholders: [shareholder] },
          size: 100,
        });
        setUsers(auxUsers.items);
      })();
    } else {
      setUsers([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderOptions = () => {
    return users
      ? [...users, { email: 'custom', firstname: literalsCommon.other }]
        .map(user => ({ id: user.email, name: `${user.firstname} ${user.lastname || ''}` }))
      : [];
  };

  const handleUploadDocument = async () => {
    setLoading(true);
    await postDocumentToSign(type, id, {
      ...uploadForm,
      name,
      email: email.user === 'custom' ? email.custom : email.user,
    });
    onClose();
  };

  return (
    <Popup title={literals.uploadDocumentToBeSigned} onClose={onClose}>
      <InputText
        preText={literals.subject}
        placeholder={literals.subject}
        onChange={v => setUploadForm(prev => ({ ...prev, subject: v }))}
        value={uploadForm.subject}
        isRequired
      />
      <InputRichText
        preText={literals.message}
        placeholder={literals.message}
        onChange={v => setUploadForm(prev => ({ ...prev, message: v }))}
        value={uploadForm.message}
        isRequired
      />

      <div className='row upload-document-sign'>
        <div className='col-12 col-md-6'>
          <InputFileZone
            preText={literalsCommon.document}
            value={uploadForm.file}
            onChange={v => setUploadForm(prev => ({ ...prev, file: v }))}
            isRequired
          />
        </div>
        <div className='col-12 col-md-6'>
          <InputSelect
            preText={literals.signatureZone}
            value={uploadForm.signatureZone}
            onChange={v => setUploadForm(prev => ({ ...prev, signatureZone: v }))}
            options={[
              { id: 'top-left', name: literals.topLeft },
              { id: 'top-right', name: literals.topRight },
              { id: 'bottom-left', name: literals.bottomLeft },
              { id: 'bottom-right', name: literals.bottomRight },
            ]}
            isDisabled={!users}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-md-5'>
          <InputSelect
            preText={literals.sendTo}
            value={email.user}
            onChange={v => setEmail({ custom: '', user: v })}
            options={renderOptions()}
            isDisabled={!users}
          />
        </div>
        { email.user === 'custom' && (
          <div className='col-12 col-md-7'>
            <InputEmail
              preText={literalsCommon.email}
              placeholder={literalsCommon.email}
              value={email.custom}
              onChange={v => setEmail({ custom: v, user: 'custom' })}
            />
          </div>
        )}
      </div>
      <div className='buttons'>
        <Button
          text={literalsCommon.upload}
          icon={Upload}
          onClick={handleUploadDocument}
          loading={loading}
          disabled={!Object.keys(uploadForm).every(key => uploadForm[key])
            || !email.user || (email.user === 'custom' && !email.custom)}
        />
      </div>
    </Popup>
  );
};

UploadDocumentPopup.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  shareholder: PropTypes.string.isRequired,
  startup: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    literals: state.i18n.literals.signature,
    literalsCommon: state.i18n.literals.common,
  };
};


export default connect(mapStateToProps, {})(UploadDocumentPopup);
