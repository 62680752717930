import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import MessagesComponents from '../components';

const mapStateToProps = state => (
  {
    literals: state.i18n.literals.messages,
    user: state.session.user,
  }
);

export default withRouter(connect(mapStateToProps)(MessagesComponents));
