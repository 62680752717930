import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Portal from 'components/Portal';
import { useRect } from 'utils/customHooks';
import { Menu } from 'lucide-react';
import { TOOL_CAP_TABLE, TOOL_INVESTMENTS } from 'constants/tools';
import NavCaptable from './navCaptable';
import NavPosition from './navPosition';
import './styles.scss';


const EmbedTopBar = (props) => {
  const {
    literals,
    startup,
    user,
    type,
  } = props;

  const [showOptions, setShowOptions] = useState(false);
  const [bbox, ref] = useRect(showOptions);

  useEffect(() => {
    document.body.classList.add('embed');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const styles = { visible: true, zIndex: 12 };
  if (bbox) {
    styles.top = bbox.bottom - 10;
    styles.left = bbox.left - 235;
  }

  if (window.innerWidth < 600) {
    styles.top = 95;
    styles.left = 'auto';
    styles.right = 15;
  }

  const renderList = () => {
    switch (type) {
      case TOOL_CAP_TABLE:
        return <NavCaptable literals={literals} startup={startup} />;
      case TOOL_INVESTMENTS:
        return <NavPosition literals={literals} />;
      default:
        return null;
    }
  };

  const nav = renderList();

  return (nav && (
    <nav className={`topbar topbar-embed ${user.impersonate ? 'impersonate' : ''}`}>
      <div className='embed-topbar-section' ref={ref}>
        <Menu className='menu-icon' onClick={() => setShowOptions(!showOptions)} />
        {nav}

        {showOptions && (
          <Portal styles={styles} onClose={() => setShowOptions(false)}>
            <div className='submenu-popup'>
              {nav}
            </div>
          </Portal>
        )}
      </div>
    </nav>
  ));
};


EmbedTopBar.propTypes = {
  literals: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default EmbedTopBar;
