import AccountsInstance from 'modules/accounts/repository';
import { SessionService } from 'modules/session/sessionService';
import { loadingAction } from 'store/globalState/global';
import {
  FETCH_LOGOUT_INIT,
  FETCH_LOGOUT_SUCCESS,
  FETCH_LOGOUT_FAILURE,
} from './types';


export function fetchLogoutInit() {
  return {
    type: FETCH_LOGOUT_INIT,
  };
}

export function fetchLogoutSuccess() {
  return {
    type: FETCH_LOGOUT_SUCCESS,
  };
}

export function fetchLogoutFailure(error) {
  return {
    type: FETCH_LOGOUT_FAILURE,
    payload: error,
  };
}

export default function fetchLogoutAction(params) {
  return async (dispatch) => {
    dispatch(fetchLogoutInit());
    dispatch(loadingAction(true));

    return AccountsInstance.deleteSession({ data: params }).then(() => {
      dispatch(loadingAction(false));
      window.USER_ID = null;
      SessionService.deleteUser();
      SessionService.deleteSession()
        .then(() => {
          dispatch(fetchLogoutSuccess());
        })
        .catch((error) => {
          dispatch(fetchLogoutFailure(error));
        });
    }).catch(() => {
      dispatch(loadingAction(false));
      window.USER_ID = null;
      SessionService.deleteUser();
      SessionService.deleteSession()
        .then(() => {
          dispatch(fetchLogoutSuccess());
        })
        .catch((e) => {
          dispatch(fetchLogoutFailure(e));
        });
    });
  };
}
