import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setGlobalPropertiesAction } from 'store/globalState/global';
import CurrenciesSelect from '../components';

const mapStateToProps = (state) => {
  return {
    literalsCommon: state.i18n.literals.common,
    user: state.session.user,
    currency: state.global.investments?.currency,
    currencies: state.global.investments?.currencies,
  };
};

const mapDispatchToProps = dispatch => ({
  setGlobalProperties: bindActionCreators(setGlobalPropertiesAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrenciesSelect);
