import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Messages from 'components/Messages';
import { startupCanEdit } from 'utils/functions';
import { TOOL_MESSAGES } from 'constants/tools';

const MessagesMenu = (props) => {
  const {
    show,
    literals,
    startup,
    setUnreadMessages,
  } = props;

  const canEdit = startupCanEdit(startup, TOOL_MESSAGES);
  const canPublish = canEdit && startup && ['owner', 'user'].includes(startup?.relation?.type);

  return startup && (
    <div className={`messages-menu ${show ? 'show' : ''}`}>
      <div className='messages-menu-header'>
        <h5 className='fw-b'>{literals.startupWall}</h5>
        <p>{literals.startupWallDescription}</p>
      </div>
      <div className='messages-menu-wrapper'>
        <Messages
          type='startup'
          id={startup.id}
          mode='post'
          readOnly={!canPublish}
          canReply={canEdit}
          showReplies
          setNumUnreadMessages={setUnreadMessages}
          visible={show}
        />
      </div>
    </div>
  );
};

MessagesMenu.propTypes = {
  show: PropTypes.bool.isRequired,
  literals: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  setUnreadMessages: PropTypes.func.isRequired,
};

export default MessagesMenu;
