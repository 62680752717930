import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/Panel/components/';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import Loading from 'components/Loading';
import StartupCaptableDate from 'components/StartupCaptableDate/components';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { initializeCaptable } from 'routes/Captable/modules/actions';
import { ROUTE_STARTUP_PATH } from 'routes';
import OperationsTab from '../../../OperationsTab';
import './styles.scss';
import { getCaptableSimulation } from '../../../../modules/actions';

const CaptableSimulationOperations = (props) => {
  const {
    literals,
    captable,
    fetchCaptable,
    startup,
  } = props;

  const params = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;

  const [simulation, setSimulation] = useState(false);


  useEffect(() => {
    initializeCaptable(fetchCaptable, captable, startup.id, captableId);
    (async () => {
      const auxSimulation = await getCaptableSimulation(startup.id, params.simulation);
      setSimulation(auxSimulation);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  if (!captable.loaded) {
    return <Loading hide={false} mode='tool' />;
  }

  const breadcrumb = [
    { name: literals.transactions },
    { name: literals.simulations, route: ROUTE_STARTUP_PATH.setCapTableSimulations(startup.id, captableId) },
    { name: simulation?.name || '' },
  ];

  return (
    <Panel active='operations'>
      <PanelHeader breadcrumb={breadcrumb} />
      <PanelBody>
        <StartupCaptableDate startup={startup} captable={captable} noDate />
        <OperationsTab
          simulation={params.simulation}
          {...props}
        />
      </PanelBody>
    </Panel>
  );
};

CaptableSimulationOperations.propTypes = {
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  fetchCaptable: PropTypes.func.isRequired,
  editOperationTimeline: PropTypes.func.isRequired,
  editOperationDraft: PropTypes.func.isRequired,
  deleteOperation: PropTypes.func.isRequired,
};

export default CaptableSimulationOperations;
