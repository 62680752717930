import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import InputNumber from 'components/Inputs/inputNumber';
import InputSelect from 'components/Inputs/inputSelect';
import InputToggle from 'components/Inputs/inputToggle';
import Button from 'components/Buttons/Button';
import OutputValue from 'components/Inputs/outputValue';
import useDialog from 'components/Dialog/components/useDialog';
import PermissionLabel from 'components/PermissionsPopup/PermissionLabel';
import {
  CheckCircle, ChevronLeft, ChevronRight, CircleCheck, Eye, Pencil,
} from 'lucide-react';
import { Skeleton } from '@mui/material';
import { fetchKpisValues, initializeKpiRelations } from 'routes/Performance/modules/actions';
import { useCounter } from 'utils/customHooks';
import { KPI_PERIODICITY, KPI_UNIT } from 'constants/kpis';
import { PERMISSION_LEVELS } from 'constants/permissions';
import { formatNumber, getColorRange, getTranslation } from 'utils/functions';
import { formatKpiValue } from '../utils/functions';
import './styles.scss';

const PerformanceFill = (props) => {
  const {
    literals,
    literalsCommon,
    match,
    startup,
    currency,
    performance,
    lang,
    fetchKpiRelations,
    postKpiValues,
    category,
    setDetailKpiRelation,
    mainRefreshFlag = 0,
  } = props;

  const [editCell, setEditCell] = useState(false);
  const [tableType, setTableType] = useState(KPI_PERIODICITY.MONTHLY);
  const [year, setYear] = useState(new Date().getFullYear());
  const [yearValues, setYearValues] = useState({});
  const [showRow, setShowRow] = useState({ value: true, forecast: false });
  const [loadingValues, setLoadingValues] = useState(false);
  const [presaveValues, setPresaveValues] = useState({});
  const [saving, setSaving] = useState(false);
  const tableRef = useRef();
  const { dialog } = useDialog();

  const tableRefreshFlag = useCounter(0);
  const isMonthly = tableType === KPI_PERIODICITY.MONTHLY;
  const isQuarterly = tableType === KPI_PERIODICITY.QUARTERLY;
  const isAnnual = tableType === KPI_PERIODICITY.ANNUAL;

  const numColumns = isMonthly || isQuarterly ? 12 : 6;
  const cellWidth = useRef('auto');

  useEffect(() => {
    initializeKpiRelations(fetchKpiRelations, performance, 'startup', match.params.id);

    const resize = () => {
      if (tableRef?.current?.getBoundingClientRect()?.width) {
        cellWidth.current = tableRef?.current?.getBoundingClientRect()?.width / numColumns;
      }
    };

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mainRefreshFlag) {
      tableRefreshFlag.increase();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainRefreshFlag]);

  const relations = useMemo(() => {
    return [...performance.relations]
      .filter(rel => (category === 'all' || rel.kpi.category === category));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performance, category]);

  useEffect(() => {
    (async () => {
      setLoadingValues(true);
      let fromYear = year;
      if (tableType === KPI_PERIODICITY.QUARTERLY) {
        fromYear = year - 3;
      } else if (tableType === KPI_PERIODICITY.ANNUAL) {
        fromYear = year - 6;
      }

      const values = await fetchKpisValues('startup', match.params.id, {
        periodicity: tableType,
        from: `${fromYear}-01-01`,
        to: `${year}-12-31`,
      });

      const newYearValues = {};
      values.items.forEach((item) => {
        if (!newYearValues[item.relation]) {
          newYearValues[item.relation] = {};
        }
        newYearValues[item.relation][item.date] = isMonthly ? {
          value: item.value,
          forecast: item.forecast,
          notes: item.notes,
        } : item.aggregate;
      });

      setYearValues(newYearValues);
      setLoadingValues(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRefreshFlag.value]);

  const isValueSetted = (value) => {
    return value || value === 0 || value === null;
  };

  const handleAggregateValue = (kpiRelation, date) => {
    const aggregate = yearValues?.[kpiRelation.id]?.[date];
    const value = aggregate?.value || aggregate?.value === 0
      ? formatKpiValue(aggregate?.value, kpiRelation.kpi, null, currency)
      : '';

    let isValid = aggregate?.valid || false;
    if (!isValid) {
      const { QUARTERLY, MONTHLY } = KPI_PERIODICITY;
      const count = aggregate?.count;
      const kpiPeriodicity = kpiRelation.kpi.periodicity;
      if (kpiPeriodicity === QUARTERLY) {
        isValid = (isQuarterly && count === 1) || (isAnnual && count === 4);
      } else if (kpiPeriodicity === MONTHLY) {
        isValid = (isQuarterly && count === 3) || (isAnnual && count === 12);
      }
    }

    return `${value}${value && !isValid ? '*' : ''}`;
  };

  const getDates = () => {
    if (isMonthly) {
      return Array.from({ length: 12 }, (_, i) => `${year}-${(i + 1).toString().padStart(2, '0')}`);
    }
    if (tableType === KPI_PERIODICITY.QUARTERLY) {
      const dates = [];
      [year - 2, year - 1, year].forEach((y) => {
        ['03', '06', '09', '12'].forEach(m => dates.push(`${y}-${m}`));
      });
      return dates;
    }
    if (tableType === KPI_PERIODICITY.ANNUAL) {
      return Array.from({ length: 6 }, (_, i) => `${year - 5 + i}-12`);
    }
    return [];
  };

  const handlePresaveValues = (type, kpi, relation, date, v) => {
    let value = v !== '' ? parseFloat(v) : null;

    if (value) {
      if (kpi.unit === KPI_UNIT.INTEGER) {
        value = Math.floor(value);
      }
      if (kpi.min && !Number.isNaN(kpi.min) && value < parseFloat(kpi.min)) {
        value = kpi.min;
      }

      if (kpi.max && !Number.isNaN(kpi.max) && value > parseFloat(kpi.max)) {
        value = kpi.max;
      }
    }

    setPresaveValues((prev) => {
      const auxPresaveValues = { ...prev };
      if (isValueSetted(auxPresaveValues?.[relation]?.[date]?.[type])
        && (yearValues?.[relation]?.[date]?.[type] || null) === value) {
        delete auxPresaveValues[relation][date][type];
      } else {
        auxPresaveValues[relation] = auxPresaveValues[relation] || {};
        auxPresaveValues[relation][date] = auxPresaveValues[relation][date] || {};
        auxPresaveValues[relation][date][type] = value;
      }

      return auxPresaveValues;
    });
  };

  const savePendingValues = async () => {
    setSaving(true);
    await postKpiValues('startup', match.params.id, presaveValues);
    tableRefreshFlag.increase();
    setPresaveValues({});
    setSaving(false);
  };

  const handleVisibleRow = (type) => {
    if (type === 'value') {
      setShowRow({
        value: !showRow.value,
        forecast: showRow.value ? true : showRow.forecast,
      });
    } else {
      setShowRow({
        forecast: !showRow.forecast,
        value: showRow.forecast ? true : showRow.value,
      });
    }
  };

  const handleChangeYear = (y) => {
    setLoadingValues(true);
    setYear(Math.max(2000, Math.min(2099, +y)));
    tableRefreshFlag.increase();
  };

  const handleChangeTable = async (v) => {
    const pending = Object.keys(presaveValues).length;
    const confirm = !pending || await dialog({
      type: 'confirmDanger',
      text: literals.questionConfirmChangeTable,
    });

    if (confirm) {
      setLoadingValues(true);
      setTableType(v);
      setPresaveValues({});
      tableRefreshFlag.increase();
    }
  };

  const renderHeader = () => {
    return getDates().map((date) => {
      let header = literalsCommon.months[+date.slice(-2)];
      if (tableType === KPI_PERIODICITY.ANNUAL) {
        header = date.slice(0, 4);
      } else if (tableType === KPI_PERIODICITY.QUARTERLY) {
        header = `${literals.quarterly.charAt(0)}${+date.slice(-2) / 3}' ${date.slice(0, 4)}`;
      }
      return (
        <h6
          key={`header-${date}`}
          className='kpi-table-cell'
          style={{ width: cellWidth.current || 'auto', minWidth: '130px' }}
        >
          {header}
        </h6>
      );
    });
  };

  const renderCell = (kpiRelation, date) => {
    const presaveValue = presaveValues?.[kpiRelation.id]?.[date]?.value;
    const presaveForecast = presaveValues?.[kpiRelation.id]?.[date]?.forecast;
    const value = isValueSetted(presaveValue) ? presaveValue : yearValues?.[kpiRelation.id]?.[date]?.value;
    const forecast = isValueSetted(presaveForecast) ? presaveForecast : yearValues?.[kpiRelation.id]?.[date]?.forecast;

    let symbol = '';
    if (kpiRelation.kpi.unit === KPI_UNIT.CURRENCY) {
      symbol = literalsCommon.currenciesSymbols[startup.currency];
    } else if (kpiRelation.kpi.unit === KPI_UNIT.PERCENT) {
      symbol = '%';
    }

    if (isMonthly) {
      if (!editCell) {
        return (
          <>
            {showRow.value && (
              <OutputValue
                className='kpi-table-cell value'
                style={{ width: cellWidth.current }}
                symbol={value ? symbol : ''}
                value={formatNumber(+value, '', { dec: 2 }) || null}
              />
            )}
            {showRow.forecast && (
              <OutputValue
                className='kpi-table-cell value'
                style={{ width: cellWidth.current }}
                symbol={forecast ? symbol : ''}
                value={formatNumber(+forecast, '', { dec: 2 }) || null}
              />
            )}
          </>
        );
      }

      const enableMonths = {
        annual: [12],
        quarterly: [3, 6, 9, 12],
        monthly: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      };

      const kpiMonths = enableMonths[kpiRelation.kpi.periodicity];
      const canEdit = kpiRelation.permission.level >= PERMISSION_LEVELS.EDIT;
      const disabled = !canEdit || !kpiMonths.includes(+date.split('-')[1]) || tableType !== KPI_PERIODICITY.MONTHLY;
      const disabledValue = disabled || new Date(date) > new Date();

      return (
        <>
          {showRow.value && (
            <InputNumber
              key={`cell-${kpiRelation.id}-${date}-value`}
              className={`kpi-table-cell value ${isValueSetted(presaveValue) ? 'presave' : ''}`}
              value={value === null ? '' : value}
              placeholder={disabledValue ? '' : '-'}
              onChange={v => handlePresaveValues('value', kpiRelation.kpi, kpiRelation.id, date, v)}
              isDisabled={disabledValue}
              minValue={kpiRelation.kpi.min}
              maxValue={kpiRelation.kpi.max}
              symbol={![null, undefined].includes(value) ? symbol : ''}
            />
          )}
          {showRow.forecast && (
            <InputNumber
              key={`cell-${kpiRelation.id}-${date}-forecast`}
              className={`kpi-table-cell value ${isValueSetted(presaveForecast) ? 'presave' : ''}`}
              value={forecast === null ? '' : forecast}
              placeholder={disabled ? '' : '-'}
              onChange={v => handlePresaveValues('forecast', kpiRelation.kpi, kpiRelation.id, date, v)}
              isDisabled={disabled}
              minValue={kpiRelation.kpi.min}
              maxValue={kpiRelation.kpi.max}
              symbol={![null, undefined].includes(forecast) ? symbol : ''}
            />
          )}
        </>
      );
    }

    return (
      <OutputValue
        className='kpi-table-cell value'
        style={{ width: cellWidth.current }}
        value={handleAggregateValue(kpiRelation, date)}
      />
    );
  };

  const renderRow = (kpiRelation) => {
    const dates = getDates();


    return (
      <div className='kpi-table-row' key={`row-${kpiRelation.id}`}>
        {
          dates.map((date) => {
            if (loadingValues) {
              return (
                <div key={`${kpiRelation.kpi.id}.${date}-loading`} style={{ width: cellWidth.current, minWidth: '130px' }}>
                  {((showRow.value && isMonthly) || !isMonthly) && (
                    <div className='kpi-table-cell' key={`${kpiRelation.kpi.id}.${date}.value-loading`}>
                      <Skeleton animation='wave' height={54} />
                    </div>
                  )}
                  {showRow.forecast && isMonthly && (
                    <div className='kpi-table-cell' key={`${kpiRelation.kpi.id}.${date}.forecast-loading`}>
                      <Skeleton animation='wave' height={54} />
                    </div>
                  )}
                </div>
              );
            }

            return (
              <div key={`cell-${kpiRelation.id}-${date}`} style={{ width: cellWidth.current, minWidth: '130px' }}>
                {renderCell(kpiRelation, date)}
              </div>
            );
          })
        }
      </div>
    );
  };

  const renderPager = (position) => {
    return (
      <div className='kpi-pager-header'>
        <div className={`kpi-pager kp-${position}`}>
          <InputSelect
            className='w-25 m-0'
            onChange={handleChangeTable}
            value={tableType}
            options={[
              { id: KPI_PERIODICITY.MONTHLY, name: literals.monthly },
              { id: KPI_PERIODICITY.QUARTERLY, name: literals.quarterly },
              { id: KPI_PERIODICITY.ANNUAL, name: literals.annual },
            ]}
            isDisabled={saving}
          />
          <div className='kp-year'>
            <ChevronLeft size={16} onClick={() => handleChangeYear(year - 1)} />
            { isMonthly
              ? <InputNumber placeholder='YYYY' value={year} onBlur={v => handleChangeYear(v)} state raw />
              : <OutputValue value={`${isQuarterly ? year - 2 : year - 5} - ${year}`} />
            }
            <ChevronRight size={16} onClick={() => handleChangeYear(year + 1)} />
          </div>
          {isMonthly && (
            <div className='toggle-edit'>
              <Eye />
              <InputToggle
                value={editCell}
                onChange={setEditCell}
                size={26}
              />
              <Pencil />
            </div>
          )}
        </div>
      </div>
    );
  };

  if (!performance.loaded) {
    return <Loading hide={false} mode='tool' />;
  }

  const graphColors = getColorRange(2);
  return (
    performance.loaded ? (
      <div className='performanceFill-wrapper'>
        <>
          {renderPager('top')}
          { isMonthly && (
            <div className='performance-legend'>
              <span className='performance-legend-item'>
                <div className='performance-legend--circle' style={{ background: graphColors[0] }} />
                <span
                  className={`performance-legend--name ${!showRow.value ? 'disabled' : ''}`}
                  onClick={() => handleVisibleRow('value')}
                >
                  {literals.value}
                </span>
              </span>
              <span className='performance-legend-item'>
                <div className='performance-legend--circle' style={{ background: graphColors[1] }} />
                <span
                  className={`performance-legend--name ${!showRow.forecast ? 'disabled' : ''}`}
                  onClick={() => handleVisibleRow('forecast')}
                >
                  {literals.forecast}
                </span>
              </span>
            </div>
          )}
          <div className='kpi-table'>
            <div className='kpi-table-left'>
              <h6 className='kpi-table-cell'>KPI</h6>
              {relations.map(kpiRelation => (
                <div key={kpiRelation.id} className={`kpi-row-header ${isMonthly ? KPI_PERIODICITY.MONTHLY : ''}`}>
                  <div
                    key={`header-${kpiRelation.id}`}
                    className={`kpi-table-cell ${showRow.value && showRow.forecast ? 'xl' : ''}`}
                    title={getTranslation(kpiRelation.kpi.name, lang)}
                    onClick={() => setDetailKpiRelation(kpiRelation.id)}
                  >
                    <div className='kpi-name'>{getTranslation(kpiRelation.kpi.name, lang)}</div>
                    <PermissionLabel permission={kpiRelation.permission} />
                  </div>
                  { isMonthly ? (
                    <div className={`kpi-row-tag ${showRow.value && showRow.forecast ? 'xl' : ''}`}>
                      { showRow.value && <span title={literals.value} style={{ backgroundColor: graphColors[0] }} /> }
                      { showRow.forecast && <span title={literals.forecast} style={{ backgroundColor: graphColors[1] }} /> }
                    </div>
                  ) : (
                    <div className='kpi-aggregate'>
                      <div>
                        {literals[kpiRelation.aggregate]}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className='kpi-table-right' ref={tableRef}>
              <div className='kpi-table-row'>{renderHeader()}</div>
              {relations.map(kpiRelation => (
                renderRow(kpiRelation)
              ))}
            </div>
          </div>
          <div className='buttons mt-3'>
            { isMonthly ? (
              <Button
                icon={Object.keys(presaveValues).length ? CheckCircle : CircleCheck}
                // eslint-disable-next-line no-nested-ternary
                text={saving
                  ? literals.saving
                  : (Object.keys(presaveValues).length ? literalsCommon.save : literals.withoutChanges)}
                onClick={savePendingValues}
                loading={saving}
                disabled={!Object.keys(presaveValues).length}
              />
            ) : (
              <div className='fs-sm font-italic'>{literals.dataIncomplete}</div>
            )}
          </div>
        </>
      </div>
    ) : (
      <Loading hide={false} mode='tool' />
    )
  );
};

PerformanceFill.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  performance: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  fetchKpiRelations: PropTypes.func.isRequired,
  postKpiValues: PropTypes.func.isRequired,
  currency: PropTypes.object.isRequired,
  category: PropTypes.string.isRequired,
  setDetailKpiRelation: PropTypes.func.isRequired,
  mainRefreshFlag: PropTypes.number,
};

export default PerformanceFill;
