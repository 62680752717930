import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import useDialog from 'components/Dialog/components/useDialog';
import IOFileLoading from 'components/IOFileLoading/components';
import PermissionLabel from 'components/PermissionsPopup/PermissionLabel';
import { useNavigate } from 'react-router-dom';
import { ROUTE_STARTUP_PATH, embedView } from 'routes';
import { useCounter } from 'utils/customHooks';
import {
  UserPlus, Pencil, Trash, Eye, CloudUpload,
  Users, UserMinus, CloudDownload, File,
} from 'lucide-react';
import {
  formatDate, formatNumber, getFullName, startupCanManage,
} from 'utils/functions';
import { AmplitudeApi } from 'utils/amplitude';
import { TOOL_CAP_TABLE, TOOL_USERS } from 'constants/tools';
import { PERMISSION_LEVELS, PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import { downloadReportShareholder } from 'routes/MyPosition/modules/actions';
import {
  getShareHolders, deleteShareholder, unbindShareholderToSyndicate, downloadExcelShareholder,
} from '../modules/actions';
import PopupFormShareholder from './PopupFormShareholder';
import PopupInfoShareholder from './PopupInfoShareholder';
import PopupBulkShareholders from './PopupBulkShareholders';
import PopupInviteShareholder from './PopupInviteShareholder';
import PopupUnifyShareholders from './PopupUnifyShareholders';

const ShareholdersTab = (props) => {
  const {
    startup,
    captable,
    literalsCommon,
    literals,
    literalsShareholder,
    match,
    lang,
    getShareholdersProp,
  } = props;

  const [showRow, setShowRow] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [bulkExcel, setBulkExcel] = useState(null);
  const [inviteShareholder, setInviteShareholder] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [unifyPopup, setUnifyPopup] = useState(false);
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const tableRefreshFlag = useCounter(0);

  const canViewUsers = startupCanManage(startup, TOOL_USERS) && startupCanManage(startup, TOOL_CAP_TABLE);
  const navigate = useNavigate();
  const { dialog } = useDialog();

  useEffect(() => {
    if (captable) {
      tableRefreshFlag.increase();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captable]);

  const fetchShareholders = useCallback((page, size, search, filters, sort) => {
    if (getShareholdersProp) {
      return getShareholdersProp(page, size, search, filters, sort);
    }
    return getShareHolders(match.params.id, page, size, {
      search, filters, sort: sort || '+firstname', captable,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteElem = async (elem) => {
    const confirm = await dialog({
      text: literals.deleteShareholder, type: 'confirm',
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          try {
            await deleteShareholder(match.params.id, elem);
            tableRefreshFlag.increase();
          } catch (errors) {
            await dialog({
              text: literals.cannotDeleteShareholder, type: 'error',
            });
          }
        },
      });
    }
  };

  const onUnbindSyndicate = async (shareholder) => {
    const confirm = await dialog({
      type: 'confirmDanger',
      text: literals.unbindSyndicateWarning,
    });

    if (confirm) {
      await dialog({
        type: 'loading',
        execute: async () => {
          await unbindShareholderToSyndicate(match.params.id, shareholder.syndicate, shareholder.id)
            .then(() => tableRefreshFlag.increase())
            .catch(async () => {
              await dialog({
                type: 'error',
                text: literals.unbindingError,
              });
            });
        },
      });
    }
  };

  const handleDownloadExcel = async () => {
    setDownloadingExcel('shareholders.xlsx');
    await downloadExcelShareholder(startup.id);
    setDownloadingExcel(false);
  };

  const handleDownloadReport = async (shareholders) => {
    const filename = shareholders.length > 1 ? 'reports.zip' : 'report.pdf';
    setDownloadingExcel(filename);
    await downloadReportShareholder(match.params.id, shareholders, filename);
    setDownloadingExcel(false);
  };

  const columns = [
    {
      field: 'avatar',
      width: 45,
      type: 'avatar',
      label: '',
      title: (_, row) => getFullName(row),
    },
    {
      field: 'firstname',
      grow: 3,
      label: literalsCommon.name,
      preRender: (_, row) => (
        <>
          {getFullName(row)}
          <PermissionLabel permission={row.permission} />
        </>
      ),
      sortable: true,
    },
    {
      field: 'captables',
      label: literalsShareholder.captable,
      preRender: captables => (captables ? captables.map(cap => (
        <div key={`role-${cap.id}`} className='cell-keywords'>
          <div>{cap.name.substring(0, 3)}</div>
        </div>
      )) : null),
      hidden: row => row.every(sh => !sh.captables),
    },
    {
      field: 'role',
      label: literalsCommon.role,
      width: 150,
      preRender: (v, row) => (
        <div className='cell-keywords'>
          <div key={`role-${row.id}`}>{literalsShareholder[v]}</div>
        </div>
      ),
    },
    {
      field: 'position',
      label: literalsShareholder.ownership,
      width: 100,
      preRender: v => (v?.ownership ? formatNumber(v?.ownership?.nonFullyDiluted, 0, { dec: 2, symbol: '%' }) : '-'),
    },
    {
      field: 'position',
      label: literalsShareholder.fullyDiluted,
      width: 100,
      preRender: v => (v?.ownership ? formatNumber(v?.ownership?.fullyDiluted, 0, { dec: 2, symbol: '%' }) : '-'),
    },
    {
      field: 'users',
      width: 50,
      icon: Users,
      preRender: v => v?.length || '-',
      hidden: () => !canViewUsers,
    },
    {
      id: 'created_at',
      field: 'createdAt',
      label: literalsCommon.createdAt,
      preRender: elem => formatDate(elem),
      width: 100,
      sortable: true,
    },
  ];

  columns.push({
    field: 'id',
    type: 'menu',
    buttons: [
      {
        icon: Eye,
        text: literalsCommon.view,
        onClick: shareholder => navigate(embedView(ROUTE_STARTUP_PATH.setCapTableShareHolderInfo(match.params.id, captable, shareholder.id))),
      },
      {
        icon: Pencil,
        text: literalsCommon.modify,
        onClick: shareholder => setEditRow(shareholder),
        hidden: (_, shareholder) => shareholder.permission.level < PERMISSION_LEVELS.EDIT,
      },
      {
        type: 'permission',
        scope: { type: PERMISSION_SCOPES.STARTUP, id: startup.id },
        element: PERMISSION_TYPES.SHAREHOLDER,
      },
      {
        icon: UserMinus,
        text: literals.unbindSyndicate,
        onClick: shareholder => onUnbindSyndicate(shareholder),
        hidden: (_, shareholder) => !shareholder.syndicate || shareholder.permission.level < PERMISSION_LEVELS.EDIT,
      },
      {
        icon: UserPlus,
        text: literals.inviteUser,
        onClick: shareholder => setInviteShareholder(shareholder),
        hidden: (_, shareholder) => !canViewUsers || shareholder.user,
      },
      {
        icon: Trash,
        text: literalsCommon.delete,
        onClick: shareholder => deleteElem(shareholder.id),
        hidden: (_, row) => row.permission.level < PERMISSION_LEVELS.EDIT,
      },
    ],
  });

  const tableActions = [
    {
      icon: UserPlus,
      text: literalsCommon.create,
      onClick: () => {
        setEditRow({});
        AmplitudeApi.logEvent('startup.investors.main.click.createInvestor', { captable });
      },
    },
    {
      icon: CloudUpload,
      text: literals.bulkShareholders,
      onClick: () => {
        setBulkExcel(true);
        AmplitudeApi.logEvent('startup.investors.main.click.massiveUpload', { captable });
      },
    },
    {
      icon: CloudDownload,
      text: literals.downloadExcel,
      onClick: () => {
        handleDownloadExcel(true);
        AmplitudeApi.logEvent('startup.investors.main.click.downloadInvestors', { captable });
      },
    },
  ];

  const tableMassiveActions = [
    { icon: Users, text: literals.unifyShareholders, onClick: () => setUnifyPopup(selectedRows) },
    { icon: File, text: literals.certificate, onClick: () => handleDownloadReport(selectedRows.map(sh => sh.id)) },
  ];

  return (
    <>
      <Table
        columns={columns}
        fetch={fetchShareholders}
        pageSize={10}
        actions={!getShareholdersProp ? tableActions : null}
        forceFetch={tableRefreshFlag.value}
        onClickRow={row => navigate(embedView(ROUTE_STARTUP_PATH.setCapTableShareHolderInfo(match.params.id, captable, row.id)))}
        selectRows={selectedRows}
        onSelectRows={setSelectedRows}
        selectFields={['id', 'avatar', 'firstname', 'lastname', 'role']}
        rowSelection={tableMassiveActions}
      />

      {showRow && (
        <PopupInfoShareholder
          id={showRow}
          onClose={() => setShowRow(false)}
        />
      )}

      {editRow && (
        <PopupFormShareholder
          onClose={() => setEditRow(false)}
          shareholder={editRow}
          onSubmit={() => tableRefreshFlag.increase()}
          lang={lang}
        />
      )}

      {bulkExcel && (
        <PopupBulkShareholders
          match={match}
          literals={literals}
          onClose={() => setBulkExcel(false)}
          onSubmit={() => { setBulkExcel(false); tableRefreshFlag.increase(); }}
        />
      )}

      {inviteShareholder && (
        <PopupInviteShareholder
          title={inviteShareholder.firstname}
          literals={literals}
          literalsCommon={literalsCommon}
          match={match}
          shareholder={inviteShareholder}
          tableRefreshFlag={tableRefreshFlag}
          lang={lang}
          onClose={() => setInviteShareholder(null)}
        />
      )}

      {unifyPopup && (
        <PopupUnifyShareholders
          literals={literals}
          literalsCommon={literalsCommon}
          startup={match.params.id}
          shareholders={selectedRows}
          onSubmit={() => {
            setSelectedRows([]);
            tableRefreshFlag.increase();
          }}
          onClose={() => setUnifyPopup(false)}
        />
      )}
      { downloadingExcel && <IOFileLoading mode='download' file={{ name: downloadingExcel }} /> }
    </>
  );
};

ShareholdersTab.propTypes = {
  startup: PropTypes.object.isRequired,
  captable: PropTypes.string.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  literalsShareholder: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  getShareholdersProp: PropTypes.func,
};

ShareholdersTab.defaultProps = {
  getShareholdersProp: null,
};

export default ShareholdersTab;
