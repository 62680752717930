import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import Button from 'components/Buttons/Button';
import InputText from 'components/Inputs/inputText';
import InputDate from 'components/Inputs/inputDate';
import InputNumber from 'components/Inputs/inputNumber';
import InputSelect from 'components/Inputs/inputSelect';
import InputFile from 'components/Inputs/inputFile';
import InputRichText from 'components/Inputs/inputRichText';
import OutputValue from 'components/Inputs/outputValue';
import Separator from 'components/Separator';
import { useParams, useSearchParams } from 'react-router-dom';
import { SquareCheck, Square } from 'lucide-react';
import { formatDate, formatNumber } from 'utils/functions';
import Alert from 'components/Alert';
import { fetchCaptableShares } from '../modules/actions';
import PopupFormShareholder from '../../shareHolders/components/PopupFormShareholder';
import ShareholdersAssignments from './ShareholdersAssignments';

const FormSecondary = (props) => {
  const {
    type,
    data,
    dateOrID,
    id,
    literals,
    literalsCommon,
    captable,
    currency,
    onConfirm,
    onDelete,
    editable,
  } = props;

  const [ident] = useState(data.id ?? `new.${new Date().getTime()}`);
  const [name, setName] = useState(data?.name);
  const [shareholders, setShareholders] = useState();
  const [inputDate, setInputDate] = useState(null);
  const [issuingShareholder, setIssuingShareholder] = useState(id || data?.shareholder);
  const [selectedShares, setSelectedShares] = useState(data?.shares || {});
  const [shareholderShares, setShareholderShares] = useState([]);
  const [popupShareholder, setPopupShareholder] = useState(null);
  const [notes, setNotes] = useState(data?.notes);
  const [documents, setDocuments] = useState([]);
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;
  const params = useParams();

  const getShares = async () => {
    if (issuingShareholder) {
      const response = await fetchCaptableShares(
        params.id, dateOrID || formatDate(new Date(), { format: 'Y-m-d' }),
        { size: 0, filters: { shareholder: issuingShareholder }, captable: captableId },
      );

      let shShares = [];
      response.items.forEach((item) => {
        let shShare = shShares[item.shareClass.id];
        if (!shShare) {
          shShare = {
            count: 0,
            capital: 0,
            pps: 0,
            shareClass: item.shareClass.id,
            number: 0,
            numeration: [],
            from: 0,
            to: 0,
            info: item.shareClass,
          };
        }
        shShare.count += 1;
        shShare.capital += item.capital;
        shShare.number += item.number;
        shShare.pps += item.number * item.pps;
        shShare.numeration.push(item.numeration);
        if (!shShare.from || item.numeration.from < shShare.from) {
          shShare.from = item.numeration.from;
        }
        if (!shShare.to || item.numeration.to < shShare.to) {
          shShare.to = item.numeration.to;
        }
        shShares[item.shareClass.id] = shShare;
      });

      shShares = Object.values(shShares);

      shShares.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.pps /= item.number;
      });

      setShareholderShares(shShares);
    }
  };

  const getShareholders = async () => {
    const shareHolders = await fetchCaptableShares(
      params.id, dateOrID || formatDate(new Date(), { format: 'Y-m-d' }),
      { group: 'shareholder', size: 0, captable: captableId },
    );
    setShareholders(shareHolders.items.map(sh => (
      {
        id: sh.shareholder.id,
        name: `${sh.shareholder.name} (${formatNumber(sh.number)} ${literals.shares})`,
      })));
  };

  useEffect(() => {
    getShareholders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getShares();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issuingShareholder]);

  const handleSelectedShares = (item) => {
    const newSelectedShares = { ...selectedShares };
    if (newSelectedShares[item.shareClass]) {
      delete (newSelectedShares[item.shareClass]);
    } else {
      newSelectedShares[item.shareClass] = {
        shareClass: item.shareClass,
        valuation: false,
        number: item.number,
        pps: 1,
        sales: [],
      };
    }
    setSelectedShares(newSelectedShares);
  };

  const handleShareholders = (share, shareholder, index) => {
    setSelectedShares((prev) => {
      const aux = { ...prev };
      aux[share].sales[index] = { ...aux[share].sales[index], shareholder };
      return aux;
    });
  };

  const handleChangeSelectedValue = (itemId, attr, value) => {
    setSelectedShares((prev) => {
      const aux = { ...prev };
      aux[itemId] = { ...aux[itemId], [attr]: value };
      return aux;
    });
  };

  const submitForm = async (e) => {
    Object.keys(selectedShares).forEach((item) => {
      selectedShares[item].pps = selectedShares[item].pps ? parseFloat(selectedShares[item].pps) : 0;
    });

    e.preventDefault();
    if (type === 'operation') {
      onConfirm({
        id: ident,
        shareholder: issuingShareholder,
        shares: selectedShares,
      });
    } else {
      onConfirm({
        operationType: 'secondaries',
        data: {
          id: ident,
          shareholder: issuingShareholder,
          shares: selectedShares,
        },
        name,
        date: inputDate,
        notes,
        documents,
        shareholder: issuingShareholder,
      });
    }
  };

  const handleDeleteForm = () => {
    onDelete(ident);
  };

  const numerations = {};
  shareholderShares?.forEach((share) => {
    numerations[share.info.id] = share.numeration;
  });

  let errorRange = null;
  Object.values(selectedShares).forEach((share) => {
    share.sales.forEach((sale) => {
      if (sale.number > 0 && sale.numeration?.from > 0) {
        const findNumeration = numerations[share.shareClass]?.find(s => (
          sale.numeration.from >= s.from
          && sale.numeration.to <= s.to
        ));
        if (!findNumeration) {
          errorRange = { shareClass: share.shareClass, numeration: sale.numeration };
        }
      }
    });
  });

  return (
    <form onSubmit={submitForm}>
      {type !== 'operation' && (
        <>
          <div className='row'>
            <div className='col-12 col-md-8'>
              <InputText
                className='m-0'
                preText={literals.operationName}
                placeholder={literals.operationName}
                value={name}
                onChange={v => setName(v)}
                isDisabled={!editable}
              />
            </div>
            <div className='col-12 col-md-4'>
              <InputDate
                className='m-0'
                preText={literalsCommon.date}
                value={inputDate}
                onChange={v => setInputDate(v)}
                isDisabled={!editable}
                min={captable?.operations?.length ? captable?.operations[0].date : null}
              />
            </div>
          </div>
          <Separator />
        </>
      )}
      <div className={`row ${type === 'operation' && 'row mb-4'}`}>
        {type !== 'operation' ? (
          <>
            <div className='col-12 col-md-6'>
              <OutputValue
                preText={literals.shareholder}
                value={captable.shareholders.find(item => item.id === issuingShareholder)?.name || '-'}
              />
            </div>
          </>
        ) : (
          <>
            {shareholders ? (
              <div className='col-12 col-md-6'>
                <InputSelect
                  options={shareholders}
                  className='mb-md-0'
                  preText={literals.issuingShareholder}
                  value={issuingShareholder}
                  onChange={(v) => {
                    setIssuingShareholder(v);
                    setShareholderShares(null);
                    setSelectedShares({});
                  }}
                  isDisabled={!editable}
                />
              </div>
            ) : (
              <Loading hide={false} mode='panel' />
            )}
          </>
        )}
      </div>
      {
        shareholderShares ? (
          shareholderShares.map(item => (
            <div key={item.shareClass} className='box box-padding box-mb '>
              <div
                className='d-flex justify-content-start align-items-center'
                onClick={editable ? () => handleSelectedShares(item) : null}
                style={editable ? { cursor: 'pointer' } : {}}
              >
                {selectedShares[item.shareClass] ? (<SquareCheck size={18} />) : (<Square size={18} />)}
                <h5 className='fw-b mb-0 pl-3'>
                  {`${formatNumber(item.number)}# ${item.info.name} (${item.info.code || '-'})`}
                </h5>
              </div>
              {
                selectedShares[item.shareClass] ? (
                  <>
                    <hr />
                    <div className='row mb-2'>
                      <div className='col-12 col-md-4'>
                        <h6 className='fw-b'>{literals.number}</h6>
                        <p>{formatNumber(item.number) || '-'}</p>
                      </div>
                      <div className='col-12 col-md-4'>
                        <h6 className='fw-b'>{literals.capital}</h6>
                        <p>{formatNumber(item.capital) || '-'}</p>
                      </div>
                      <div className='col-12 col-md-4'>
                        <h6 className='fw-b'>{literals.pps}</h6>
                        <p>{`${item.count > 1 ? '~' : ''}${formatNumber(item.pps, 0, { symbol: currency.symbol })}`}</p>
                      </div>
                      <div className='col-12'>
                        <h6 className='fw-b'>{item.count > 1 ? literals.numerations : literals.numeration}</h6>
                        <p>
                          {item.numeration && item.numeration.map(num => (`${formatNumber(num.from)} - ${formatNumber(num.to)}`)).join(',')}
                        </p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12 col-md-4'>
                        <InputNumber
                          preText={literals.number}
                          value={selectedShares[item.shareClass].number}
                          onChange={v => handleChangeSelectedValue(item.shareClass, 'number', parseInt(v, 10))}
                          isDisabled={!editable}
                          maxValue={item.number}
                          minValue={1}
                          decimals={0}
                        />
                      </div>
                      <div className='col-12 col-md-4'>
                        <InputNumber
                          preText={literals.pps}
                          value={selectedShares[item.shareClass].pps}
                          onChange={v => handleChangeSelectedValue(item.shareClass, 'pps', v)}
                          isDisabled={!editable}
                          minValue={0}
                          decimals={10}
                          symbol={currency.symbol}
                          step='any'
                        />
                      </div>
                      <div className='col-12 col-md-4'>
                        <InputSelect
                          preText={literals.updateValuation}
                          value={selectedShares[item.shareClass].valuation}
                          onChange={v => handleChangeSelectedValue(item.shareClass, 'valuation', v)}
                          options={[
                            { id: true, name: literalsCommon.yes },
                            { id: false, name: literalsCommon.no },
                          ]}
                          isDisabled={!editable}
                        />
                      </div>
                    </div>
                    <ShareholdersAssignments
                      mode={{ type: 'shares', subType: 'secondaries' }}
                      id={item.shareClass}
                      number={selectedShares[item.shareClass].number}
                      numeration={item.numeration[0]}
                      pps={parseFloat(selectedShares[item.shareClass].pps)}
                      captable={captable}
                      currency={currency}
                      literals={literals}
                      literalsCommon={literalsCommon}
                      assignments={selectedShares[item.shareClass].sales}
                      onChange={(v) => {
                        setSelectedShares((prev) => {
                          const aux = { ...prev };
                          aux[item.shareClass].sales = v;
                          return aux;
                        });
                      }}
                      editable={editable}
                    />
                    {
                      errorRange?.shareClass === item.shareClass && (
                        <Alert
                          type='danger'
                          text={`${literals.errors.invalidRange}: ${errorRange.numeration.from} - ${errorRange.numeration.to}`}
                          className='mt-4'
                        />
                      )
                    }
                  </>
                ) : (null)
              }
            </div>
          ))
        ) : (
          <>
            {type !== 'operation' || (type === 'operation' && issuingShareholder)
              ? (<Loading hide={false} mode='panel' />)
              : ''
            }
          </>
        )
      }
      {type !== 'operation' && (
        <>
          <Separator />
          <div className='row'>
            <div className='col-12 '>
              <InputRichText
                minHeight='120px'
                preText={literals.additionalDocumentation}
                value={notes}
                onChange={v => setNotes(v)}
                isDisabled={!editable}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <InputFile
                preText={literalsCommon.documents}
                value={documents}
                isPublic={false}
                onChange={v => setDocuments(v)}
                multiple
                isDisabled={!editable}
              />
            </div>
          </div>
        </>
      )}
      { editable && (
        <div className='buttons'>
          <Button type='submit' text={literalsCommon.confirm} />
          { type === 'operation' && data.id && (
            <Button
              type='button'
              color='danger'
              className='mr-3'
              text={literalsCommon.delete}
              onClick={handleDeleteForm}
            />
          )}
        </div>
      )}
      {
        popupShareholder && (
          <PopupFormShareholder
            onClose={() => setPopupShareholder(false)}
            onSubmit={sh => handleShareholders(popupShareholder.share, sh.id, popupShareholder.index)}
          />
        )}
    </form>
  );
};

FormSecondary.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  captable: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  type: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  dateOrID: PropTypes.string,
};

FormSecondary.defaultProps = {
  id: '',
  type: '',
  data: {},
  editable: true,
  dateOrID: formatDate(new Date(), { format: 'Y-m-d' }),
};

export default FormSecondary;
