import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { initializeAmplitude } from 'utils/amplitude';
import { SessionService } from 'modules/session/sessionService';
import validateSession from 'utils/validateSession';
import App from './routes/App';
import initialState from './store/globalState/initialState';
import configureStore from './store/configureStore';
import OverrideTheme from './components/OverrideTheme';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/index.scss';

initializeAmplitude();

Sentry.init({
  dsn: window.SENTRY_DSN,
  environment: window.SENTRY_ENVIRONMENT,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: window.SENTRY_SAMPLE_RATE || 0,
});

if (module.hot) {
  module.hot.accept();
}

const store = configureStore(initialState);
SessionService.initSessionService(store, validateSession)
  .then(() => {
    SessionService.loadSession().catch(validateSession);
  });

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <OverrideTheme>
      <App />
    </OverrideTheme>
  </Provider>,
);

// unregister();
// registerServiceWorker();
