import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import Table from 'components/Table';
import { fetchLocal } from 'components/Table/components/TableFunctions';
import { useSelector } from 'react-redux';
import { dictionary, formatDate, formatNumber } from 'utils/functions';
import { Check, Hourglass } from 'lucide-react';
import { getVestingPlanCalendar } from '../modules/actions';
import './styles.scss';

const PopupVestCalendar = (props) => {
  const {
    id,
    startup,
    captable,
    onClose,
    literals,
    literalsCommon,
  } = props;

  const actualDate = formatDate(new Date(), { format: 'Y-m-d' });
  const shareholders = dictionary(useSelector(state => state.captable.shareholders) || []);
  const literalsCaptable = useSelector(state => state.i18n.literals.captable);
  const [data, setData] = useState({
    options: null,
    shareholders: null,
    isloaded: false,
  });

  const columns = [

    {
      field: 'date',
      label: literalsCommon.date,
      width: 120,
      preRender: v => formatDate(v),
    },
    {
      field: 'shareholder',
      label: literals.shareholder,
      preRender: v => shareholders[v].name,
    },
    {
      field: 'option',
      label: literalsCaptable.option,
      preRender: ({ name }) => name,
    },
    {
      field: 'percentage',
      label: literals.percentage,
      width: 100,
      preRender: v => formatNumber(v, '-', { symbol: '%' }),
    },
    {
      field: 'date',
      label: literalsCommon.status,
      align: 'center',
      width: 100,
      preRender: v => (
        <div className='operation-status'>
          <div className={`timeline ${actualDate.localeCompare(v) > 0 ? 'confirmed' : ''}`}>
            {React.createElement(actualDate.localeCompare(v) < 0 ? Hourglass : Check, { size: 11 })}
          </div>
        </div>
      ),
    },
  ];

  const filters = [
    {
      id: 'option',
      type: 'selectUnique',
      title: 'Seleccione una opcion',
      loading: !data.isloaded,
      options: [
        { id: null, name: literalsCommon.all },
        ...Object.keys(data?.options || {}).map(option => ({
          id: option, name: data.options[option].name,
        })),
      ],
    },
    {
      id: 'shareholder',
      type: 'selectUnique',
      title: 'Seleccione un shareholder',
      loading: !data.isloaded,
      options: [
        { id: null, name: literalsCommon.all },
        ...Object.keys(data?.shareholders || {}).map(shId => ({
          id: shId, name: data.shareholders[shId].name,
        })),
      ],
    },
    {
      id: 'date',
      type: 'dateRange',
      title: 'Fechas',
    },
  ];

  const fetchCalendar = async (page, size, _, auxFilters) => {
    const auxCalendar = await getVestingPlanCalendar(startup, id, {
      captable,
      shareholder: auxFilters?.shareholder || null,
      option: auxFilters?.option || null,
      from: auxFilters?.date?.gte || null,
      to: auxFilters?.date?.lte || null,
    });

    if (!data.isloaded) {
      const auxOptions = {};
      const auxShareholders = {};
      auxCalendar.items.forEach((cal) => {
        auxOptions[cal.option.id] = cal.option;
        auxShareholders[cal.shareholder] = shareholders[cal.shareholder];
      });

      setData({
        options: auxOptions,
        shareholders: auxShareholders,
        isloaded: true,
      });
    }
    return fetchLocal(auxCalendar, null, page, size);
  };

  return (
    <Popup size='large' title={literals.vestingPlanCalendar} onClose={onClose}>
      <Table
        searcher={false}
        filters={filters}
        columns={columns}
        fetch={fetchCalendar}
      />
    </Popup>
  );
};

PopupVestCalendar.propTypes = {
  id: PropTypes.string.isRequired,
  startup: PropTypes.string.isRequired,
  captable: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
};

export default PopupVestCalendar;
