import React from 'react';
import PropTypes from 'prop-types';
import ButtonsDotsMenu from 'components/Buttons/ButtonsDotsMenu';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/functions';
import { getNotificationMessage, getNotificationRedirect } from './Notifications';

const Notification = (props) => {
  const {
    user,
    literals,
    literalsCommon,
    notification,
    onRead,
    scrollRef,
    buttons,
  } = props;

  const goTo = getNotificationRedirect(notification, user);
  const message = getNotificationMessage(notification, literals);

  return (
    <li key={notification.id} className='notification'>
      <div className={`notification-content ${notification.read ? 'read' : ''}`}>
        <Link to={goTo} onClick={() => onRead(notification.id)}>
          <p>{message}</p>
        </Link>
        <span className='notification-date fs-sm' title={formatDate(notification.createdAt, { time: true })}>
          {formatDate(notification.createdAt, { format: 'ago', literals: literalsCommon.time })}
        </span>
      </div>
      <div className='notification-actions'>
        <ButtonsDotsMenu
          buttons={buttons}
          offset={{ left: 24 }}
          scrollRef={scrollRef}
        />
      </div>
    </li>
  );
};

Notification.propTypes = {
  user: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  onRead: PropTypes.func.isRequired,
  scrollRef: PropTypes.object.isRequired,
  buttons: PropTypes.array.isRequired,
};

export default Notification;
