import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getShareholderPosition } from 'routes/MyPosition/modules/actions';
import Panel from 'components/Panel';
import PanelBody from 'components/Panel/components/PanelBody';
import EmptyParticipation from 'assets/svg/empty_participation.svg';
import StatCard from 'components/Statistics/StatCard';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelTab from 'components/Panel/components/PanelTab';
import SkeletonBarChart from 'components/Loading/skeletonBarChart';
import ReactECharts from 'echarts-for-react';
import { useSearchParams } from 'react-router-dom';
import { formatNumber, getColorRange } from 'utils/functions';
import { Skeleton } from '@mui/material';
import './styles.scss';

const ShareholderHistorial = (props) => {
  const {
    literals,
    match,
    currency,
  } = props;

  const [stats, setStats] = useState(false);
  const [graphData, setGraphData] = useState(false);
  const [searchParams] = useSearchParams();
  const captableId = searchParams.get('captable') || null;

  useEffect(() => {
    (async () => {
      getShareholderPosition(match.params.id, match.params.shareholder, { captable: captableId })
        .then((auxStats) => {
          setStats(auxStats);
          const sharesGraphData = {
            date: [],
            fullyDiluted: [],
            ownership: [],
            number: [],
            value: [],
            votes: [],
          };
          auxStats.shares.historic.forEach((elem) => {
            sharesGraphData.date.push(elem.date);
            sharesGraphData.fullyDiluted.push(elem.fullyDiluted.toFixed(2));
            sharesGraphData.ownership.push(elem.ownership.toFixed(2));
            sharesGraphData.number.push(elem.number);
            sharesGraphData.value.push(elem.value);
            sharesGraphData.votes.push(elem.votes);
          });

          const debtsGraphData = {
            date: [],
            principal: [],
            capitalized: [],
            repaid: [],
            pending: [],
          };
          auxStats.debts.historic.forEach((elem) => {
            debtsGraphData.date.push(elem.date);
            debtsGraphData.principal.push(elem.principal);
            debtsGraphData.capitalized.push(elem.capitalized);
            debtsGraphData.repaid.push(elem.repaid);
            debtsGraphData.pending.push(elem.pending);
          });

          const optionsGraphData = {
            date: [],
            number: [],
            assigned: [],
            vested: [],
            exercised: [],
          };
          auxStats.options.historic.forEach((elem) => {
            optionsGraphData.date.push(elem.date);
            optionsGraphData.number.push(elem.number);
            optionsGraphData.assigned.push(elem.assigned);
            optionsGraphData.vested.push(elem.vested);
            optionsGraphData.exercised.push(elem.exercised);
          });

          setGraphData({ sharesGraphData, debtsGraphData, optionsGraphData });
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.shareholder, captableId]);

  const sharesGraph = {
    color: getColorRange(2),
    tooltip: {
      trigger: 'axis',
      valueFormatter: v => formatNumber(v),
    },
    legend: {
      data: [literals.value, literals.numberOfShares],
    },
    xAxis: {
      type: 'category',
      data: graphData.sharesGraphData?.date,
      axisLabel: { rotate: 60 },
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: literals.value,
        data: graphData.sharesGraphData?.value,
        type: 'line',
        tooltip: {
          valueFormatter: v => `${formatNumber(v)}${currency.symbol}`,
        },
      },
      {
        name: literals.numberOfShares,
        data: graphData.sharesGraphData?.number,
        type: 'line',
      },
    ],
  };

  const sharesGraphPercentage = {
    color: getColorRange(3),
    tooltip: {
      trigger: 'axis',
      valueFormatter: v => `${formatNumber(v)}%`,
    },
    legend: {
      data: [literals.ownership, literals.fullyDiluted, literals.vote],
    },
    xAxis: {
      type: 'category',
      data: graphData.sharesGraphData?.date,
      axisLabel: { rotate: 60 },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value}%',
      },
    },
    series: [
      {
        name: literals.ownership,
        data: graphData.sharesGraphData?.ownership,
        type: 'line',
      },
      {
        name: literals.fullyDiluted,
        data: graphData.sharesGraphData?.fullyDiluted,
        type: 'line',
      },
      {
        name: literals.vote,
        data: graphData.sharesGraphData?.votes,
        type: 'line',
      },
    ],
  };

  const debtsGraph = {
    color: getColorRange(3),
    tooltip: {
      trigger: 'axis',
      valueFormatter: v => formatNumber(v),
    },
    legend: {
      data: [literals.pending, literals.capitalized, literals.repaid],
    },
    xAxis: {
      type: 'category',
      data: graphData.debtsGraphData?.date,
      axisLabel: { rotate: 60 },
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: literals.pending,
        data: graphData.debtsGraphData?.pending,
        type: 'line',
      },
      {
        name: literals.capitalized,
        data: graphData.debtsGraphData?.capitalized,
        type: 'line',
      },
      {
        name: literals.repaid,
        data: graphData.debtsGraphData?.repaid,
        type: 'line',
      },
    ],
  };

  const optionsGraph = {
    color: getColorRange(3),
    tooltip: {
      trigger: 'axis',
      valueFormatter: v => formatNumber(v),
    },
    legend: {
      data: [literals.assigned, literals.vested, literals.exercised],
    },
    xAxis: {
      type: 'category',
      data: graphData.optionsGraphData?.date,
      axisLabel: { rotate: 60 },
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: literals.assigned,
        data: graphData.optionsGraphData?.assigned,
        type: 'line',
      },
      {
        name: literals.vested,
        data: graphData.optionsGraphData?.vested,
        type: 'line',
      },
      {
        name: literals.exercised,
        data: graphData.optionsGraphData?.exercised,
        type: 'line',
      },
    ],
  };

  if (!stats) {
    return (
      <Panel>
        <PanelBody noHeader>
          <Skeleton animation='wave' className='mb-2' height={24} width={100} />
          <div className='share-stats'>
            <StatCard loading className='statCard' />
            <StatCard loading className='statCard' />
            <StatCard loading className='statCard' />
            <StatCard loading className='statCard' />
            <StatCard loading className='statCard' />
          </div>
          <Panel>
            <PanelHeader title={<Skeleton animation='wave' height={24} width={250} />} />
            <div style={{ height: '345px' }}>
              <SkeletonBarChart height='250px' />
            </div>
          </Panel>
          <Skeleton animation='wave' className='mt-5 mb-2' height={24} width={80} />
          <div className='row'>
            <div className='col-12 col-md-4'>
              <StatCard loading className='statCard' />
            </div>
            <div className='col-12 col-md-4'>
              <StatCard loading className='statCard' />
            </div>
            <div className='col-12 col-md-4'>
              <StatCard loading className='statCard' />
            </div>
          </div>
          <Panel>
            <PanelHeader title={<Skeleton animation='wave' height={24} width={250} />} />
            <div style={{ height: '345px' }}>
              <SkeletonBarChart height='250px' />
            </div>
          </Panel>
          <Skeleton animation='wave' className='mt-5 mb-2' height={24} width={100} />
          <div className='row'>
            <div className='col-12 col-md-4'>
              <StatCard loading className='statCard' />
            </div>
            <div className='col-12 col-md-4'>
              <StatCard loading className='statCard' />
            </div>
            <div className='col-12 col-md-4'>
              <StatCard loading className='statCard' />
            </div>
          </div>
          <Panel>
            <PanelHeader title={<Skeleton animation='wave' height={24} width={250} />} />
            <div style={{ height: '345px' }}>
              <SkeletonBarChart height='250px' />
            </div>
          </Panel>
        </PanelBody>
      </Panel>
    );
  }

  return (
    <Panel>
      <PanelBody noHeader>
        {stats.shares.number || stats.debts.total || stats.options.number ? (
          <>
            {
              stats.shares.historic.length ? (
                <>
                  <h4 className='fw-b'>{literals.shares}</h4>
                  {stats?.shares?.number ? (
                    <div className='share-stats'>
                      <StatCard title={literals.shares} value={formatNumber(stats?.shares?.number, 0)} loading={!stats} />
                      <StatCard title={literals.value} value={`${formatNumber(stats?.shares?.value, 0)}${currency.symbol}`} loading={!stats} />
                      <StatCard title={literals.ownership} value={`${formatNumber(stats?.shares?.ownership, 0, { dec: 2 })}%`} loading={!stats} />
                      <StatCard title={literals.fullyDiluted} value={`${formatNumber(stats?.shares?.fullyDiluted, 0, { dec: 2 })}%`} loading={!stats} />
                      <StatCard title={literals.vote} value={`${formatNumber(stats?.shares?.votes, 0, { dec: 2 })}%`} loading={!stats} />
                    </div>
                  ) : null}
                  <Panel>
                    <PanelHeader
                      title={literals.investmentEvolution}
                      tabs={[{ key: 'value', name: literals.value },
                        { key: 'ownership', name: literals.ownership }]}
                    />
                    <PanelBody>
                      <PanelTab key='value'>
                        <ReactECharts option={sharesGraph} />
                      </PanelTab>
                      <PanelTab key='ownership'>
                        <ReactECharts option={sharesGraphPercentage} />
                      </PanelTab>
                    </PanelBody>
                  </Panel>
                </>
              ) : null
            }
            {
              stats.debts.historic.length ? (
                <>
                  <h4 className='fw-b mt-5'>{literals.debts}</h4>
                  {stats?.debts?.total ? (
                    <div className='row'>
                      <div className='col-12 col-md-4'>
                        <StatCard title={literals.principal} value={`${formatNumber(stats?.debts?.total, 0)}${currency.symbol}`} loading={!stats} />
                      </div>
                      <div className='col-12 col-md-4'>
                        <StatCard title={literals.pending} value={`${formatNumber(stats?.debts?.pending, 0)}${currency.symbol}`} loading={!stats} />
                      </div>
                      <div className='col-12 col-md-4'>
                        <StatCard title={literals.interest} value={`${formatNumber(stats?.debts?.interest, 0)}`} loading={!stats} />
                      </div>
                    </div>
                  ) : null}
                  <Panel>
                    <PanelHeader title={literals.debtsEvolution} />
                    <PanelBody>
                      <ReactECharts option={debtsGraph} />
                    </PanelBody>
                  </Panel>
                </>
              ) : null
            }
            {
              stats.options.historic.length ? (
                <>
                  <h4 className='fw-b mt-5'>{literals.options}</h4>
                  {stats?.options?.vested ? (
                    <div className='row'>
                      <div className='col-12 col-md-4'>
                        <StatCard title={literals.assigned} value={`${formatNumber(stats?.options?.assigned, 0)}`} loading={!stats} />
                      </div>
                      <div className='col-12 col-md-4'>
                        <StatCard title={literals.vested} value={`${formatNumber(stats?.options?.vested, 0)}`} loading={!stats} />
                      </div>
                      <div className='col-12 col-md-4'>
                        <StatCard title={literals.exercised} value={`${formatNumber(stats?.options?.exercised, 0)}`} loading={!stats} />
                      </div>
                    </div>
                  ) : null}
                  <Panel>
                    <PanelHeader title={literals.optionsEvolution} />
                    <PanelBody>
                      <ReactECharts option={optionsGraph} />
                    </PanelBody>
                  </Panel>
                </>
              ) : null
            }
          </>
        ) : (
          <div className='panel-empty-message'>
            <img src={EmptyParticipation} alt={literals.noParticipation} />
            <span className='mt-4'>{literals.noParticipation}</span>
          </div>
        )}
      </PanelBody>
    </Panel>
  );
};

ShareholderHistorial.propTypes = {
  literals: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
};

export default ShareholderHistorial;
