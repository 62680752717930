import AccountsInstance from 'modules/accounts/repository';
import { formatErrors } from 'utils/functions';

export function sendSupportMessage(message) {
  return AccountsInstance.sendSupportMessage({ message })
    .then(() => {
      return true;
    })
    .catch((err) => {
      throw formatErrors(err);
    });
}
