import MentoringsInstance from 'modules/mentorings/repository';
import AdminInstance from 'modules/admin/repository';
import { apiDownloadFile } from 'modules/actions';
import { API_ADMIN_MENTORINGS_EXCEL } from 'modules/apiEndpoints';

export function fetchMentorings(page, size, filters) {
  return MentoringsInstance.getMentorings({
    page,
    size,
    ...filters,
  });
}

export function fetchMentors(page, size, filters) {
  return AdminInstance.getMentoringsMentors({
    page,
    size,
    ...filters,
  });
}

export function fetchStartups(page, size, filters) {
  return AdminInstance.getMentoringsStartups({
    page,
    size,
    ...filters,
  });
}

export function putStartupGroup(startup, group) {
  return AdminInstance.setStartupGroup(startup, group);
}

export function putMentorGroup(mentor, group) {
  return AdminInstance.setMentorGroup(mentor, group);
}

export function deleteMentoring(id) {
  return MentoringsInstance.deleteMentoring(id);
}

export function fetchFeedBack(id, feedback) {
  return MentoringsInstance.getFeedback(id, feedback);
}

export function downloadExcelMentorings(filename) {
  return apiDownloadFile(API_ADMIN_MENTORINGS_EXCEL, filename);
}
