import StartupsCaptablesInstance from 'modules/startupsCaptables/repository';
import { apiDownloadFile } from 'modules/actions';
import { API_SHAREHOLDER_REPORT } from 'modules/apiEndpoints';
import { formatErrors } from 'utils/functions';

export function fetchStartupOperations(id, opts) {
  return StartupsCaptablesInstance
    .getStartupOperations(id, opts)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw formatErrors(error);
    });
}

export function downloadReportShareholder(startup, shareholder, filename) {
  return apiDownloadFile(API_SHAREHOLDER_REPORT(startup, shareholder), filename);
}
