/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Buttons/Button';
import InputPassword from 'components/Inputs/inputPassword';
import OutputErrors from 'components/Inputs/outputErrors';
import useDialog from 'components/Dialog/components/useDialog';
import Dots from 'assets/svg/dots.svg';
import { FILES_BASE_URL } from 'constants/apiConf';
import { ROUTE_PATH } from 'routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { savePassword } from '../modules/actions';
import './styles.scss';

const SetPassword = ({ literals, platform }) => {
  const { dialog } = useDialog();
  const [password, setPassword] = useState({ p1: '', p2: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleChangeInput = (name, value) => {
    setPassword({ ...password, [name]: value });
  };

  const checkIsDisabled = () => {
    return password.p1 === '' || password.p2 === '';
  };

  const onPasswordSuccess = async () => {
    await dialog({
      type: 'alert',
      title: literals.password,
      text: literals.passwordSuccess,
    });
    navigate({
      pathname: ROUTE_PATH.LOGIN,
      state: location.state,
      search: location.search,
    });
  };

  const submitPassword = (e) => {
    e.preventDefault();
    setError(false);

    if (password.p1 === password.p2) {
      const path = window.location.pathname.split('/');
      const body = {
        password: password.p1,
        token: path[path.length - 1],
      };
      setLoading(true);

      savePassword(body)
        .then(() => {
          onPasswordSuccess();
        })
        .catch((errors) => {
          setLoading(false);
          if (errors.status === 401) {
            setError([literals.invalidToken]);
          } else if (errors?.body?.password?.code === 'equal_error') {
            setError([literals.passwordEqualError]);
          } else {
            setError([literals.passwordRules]);
          }
        });
    } else {
      setError([literals.nonRepeatedPassword]);
    }
    return '';
  };

  return (
    <>
      <div className='form-wrapper'>
        <div className='form-box box'>
          {
            platform.images.logo?.path && (
              <img className='form-logo' src={FILES_BASE_URL + platform.images.logo.path} alt={platform.name} />
            )
          }
          <div className='form-header'>
            <h4 className='fw-b'>{literals.title}</h4>
            <p>{literals.description}</p>
          </div>
          <form onSubmit={submitPassword} className='sign_up_password'>
            <div className='form'>
              <InputPassword
                preText={literals.password}
                placeholder={literals.password}
                isRequired
                value={password.p1}
                onChange={value => handleChangeInput('p1', value)}
              />
              <InputPassword
                preText={literals.repeatPassword}
                placeholder={literals.repeatPassword}
                isRequired
                value={password.p2}
                onChange={value => handleChangeInput('p2', value)}
              />
            </div>
            <OutputErrors literals={literals} errors={error} />
            <div className='form-actions'>
              <Button text={literals.register} disabled={checkIsDisabled()} loading={loading} type='submit' />
            </div>
          </form>
        </div>
      </div>
      <img className='background-dots' src={Dots} alt='dots' />
    </>
  );
};

SetPassword.propTypes = {
  literals: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
};

export default SetPassword;
