import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import PanelComponents from '../components';

const mapStateToProps = (/* state */) => (
  { }
);

const mapDispatchToProps = (/* dispatch */) => (
  { }
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PanelComponents));
