import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tool from 'components/Tool';
import Panel from 'components/Panel/components';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import PanelTab from 'components/Panel/components/PanelTab';
import Messages from 'components/Messages';
import Meetings from 'components/Meetings';
import Documents from 'components/Documents';
import Button from 'components/Buttons/Button';
import PopupProfile from 'components/PopupProfile';
import Breadcrumb from 'components/Breadcrumb';
import ProfileCard from 'components/ProfileCard';
import Loading from 'components/Loading';
import { startupCanEdit } from 'utils/functions';
import { ROLE_ADMIN, ROLE_MENTOR, ROLE_USER } from 'constants/roles';
import { TOOL_MENTORINGS } from 'constants/tools';
import { ROUTE_ADMIN_PATH, ROUTE_MENTOR_PATH, ROUTE_STARTUP_PATH } from 'routes';
import { useNavigate } from 'react-router-dom';
import { Eye, XCircle } from 'lucide-react';
import { saveMentoringStatus, saveMentoringFeedback } from 'routes/Mentorings/modules/actions';
import MentoringFormFeedback from './MentoringFormFeedback';
import MentoringReports from './MentoringReports';
import './styles.scss';

const Mentoring = (props) => {
  const {
    user,
    startup,
    match,
    literals,
    type,
    mentoring: mentoringState,
    fetchMentoring,
    fetchMentoringMeetings,
    createMentoringMeeting,
    editMentoringMeeting,
    deleteMentoringMeeting,
    createMentoringDocument,
    deleteMentoringDocument,
    createMentoringReport,
    editMentoringReport,
    deleteMentoringReport,
    showLoading,
  } = props;

  const mentoringId = match.params.mentoring;
  const pageSize = 5;

  const {
    mentoring,
    meetings,
    documents,
    reports,
  } = mentoringState;

  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [popupFinish, setPopupFinish] = useState(false);
  const isAdmin = user?.role?.includes(ROLE_ADMIN);
  const navigate = useNavigate();

  useEffect(() => {
    fetchMentoring(mentoringId, pageSize, type !== 'startup');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentoringId]);

  if (!mentoring || !mentoring.id) {
    return <Loading hide={false} mode='tool' />;
  }

  let DISABLE_ACTIONS = !mentoring || !mentoring.id || mentoring.status === 'finished';
  if (type === 'startup' && !startupCanEdit(startup, TOOL_MENTORINGS)) {
    DISABLE_ACTIONS = true;
  }

  const finishMentoring = async (id, feedback) => {
    showLoading(true);
    await saveMentoringStatus(id, 'finished');
    await saveMentoringFeedback(id, feedback);
    showLoading(false);

    if (type === 'startup' && (user?.role?.includes(ROLE_USER) || user?.role?.includes(ROLE_ADMIN))) {
      navigate(ROUTE_STARTUP_PATH.setMentorings(match.params.id));
    } else if (type === 'mentor' && user?.role?.includes(ROLE_MENTOR)) {
      navigate(ROUTE_MENTOR_PATH.MENTORINGS);
    }
  };

  const submitDocument = DISABLE_ACTIONS ? null : (file) => {
    return createMentoringDocument(mentoring.id, file);
  };

  const deleteDocument = DISABLE_ACTIONS ? null : async (documentId) => {
    showLoading(true);
    const response = await deleteMentoringDocument(mentoring.id, documentId);
    showLoading(false);
    return response;
  };

  const submitMeeting = DISABLE_ACTIONS ? null : (id, info) => {
    if (id) {
      return editMentoringMeeting(mentoring.id, id, info);
    }
    return createMentoringMeeting(mentoring.id, info);
  };

  const submitReport = DISABLE_ACTIONS ? null : (id, info) => {
    if (id) {
      return editMentoringReport(mentoring.id, id, info);
    }
    return createMentoringReport(mentoring.id, info);
  };

  const deleteMeeting = async (id) => {
    showLoading(true);
    await deleteMentoringMeeting(mentoring.id, id);
    showLoading(false);
  };

  const deleteReport = async (id) => {
    showLoading(true);
    await deleteMentoringReport(mentoring.id, id);
    showLoading(false);
  };

  const loadMoreMeetings = (page) => {
    fetchMentoringMeetings(mentoring.id, { page, size: pageSize });
  };

  const viewMoreInfo = () => {
    if (type === 'startup') {
      setShowMoreInfo(mentoring.mentor);
    } else {
      setShowMoreInfo(mentoring.startup);
    }
  };

  const breadcrumb = [];
  const profileData = {
    avatar: '',
    title: '',
    description: '',
    type: '',
    extra: {},
  };
  if (type === 'admin') {
    breadcrumb.push({
      route: ROUTE_ADMIN_PATH.setMentoring(mentoring.id),
      name: `${mentoring.mentor.user.firstname} ${mentoring.mentor.user.lastname} & ${mentoring.startup.name}`,
    });
  } else if (type === 'startup') {
    profileData.type = 'mentor';
    profileData.avatar = mentoring.mentor.avatar;
    profileData.title = `${mentoring.mentor.user.firstname} ${mentoring.mentor.user.lastname}`;
    profileData.description = mentoring.mentor.headline;
    profileData.extra = mentoring.mentor.extra;
    breadcrumb.push({ route: ROUTE_STARTUP_PATH.setMentorings(mentoring.startup.id), name: literals.mentorings });
    breadcrumb.push({ route: ROUTE_STARTUP_PATH.setMentoring(mentoring.startup.id, mentoring.id), name: profileData.title });
  } else {
    profileData.type = 'startup';
    profileData.avatar = mentoring.startup.logo;
    profileData.title = mentoring.startup.name;
    profileData.description = mentoring.startup.description;
    profileData.extra = mentoring.startup.extra;
    profileData.verified = mentoring.startup.verified;
    breadcrumb.push({ route: ROUTE_MENTOR_PATH.MENTORINGS, name: literals.mentorings });
    breadcrumb.push({ route: ROUTE_MENTOR_PATH.setMentoring(mentoring.id), name: profileData.title });
  }

  const tabsRight = [
    { key: 'meetings', name: literals.events },
    { key: 'documents', name: literals.documents },
  ];

  if (type !== 'startup') {
    tabsRight.push({ key: 'reports', name: literals.reports });
  }

  return (
    <>
      <Tool className='mentoring-wrapper'>
        { breadcrumb.length && (<Breadcrumb routes={breadcrumb} />) }
        <div className='mentoring-cols'>
          {
            !isAdmin && (
              <div className='col-profile'>
                <ProfileCard
                  data={profileData}
                  button={<Button icon={Eye} onClick={viewMoreInfo} text={literals.moreInfo} />}
                />
              </div>
            )
          }
          <div className='col-panels'>
            <div className='col-panel-left'>
              <Panel>
                <PanelHeader title={literals.messages} />
                <PanelBody>
                  <Messages
                    type='mentoring'
                    id={mentoring.id}
                    mode='chat'
                    readOnly={DISABLE_ACTIONS}
                  />
                </PanelBody>
              </Panel>
            </div>
            <div className='col-panel-right'>
              <Panel>
                <PanelHeader tabs={tabsRight} />
                <PanelBody>
                  <PanelTab key='meetings'>
                    <Meetings
                      meetings={meetings}
                      onSubmit={submitMeeting}
                      onDelete={deleteMeeting}
                      onPage={loadMoreMeetings}
                    />
                  </PanelTab>
                  <PanelTab key='documents'>
                    <Documents
                      documents={documents}
                      onSubmit={submitDocument}
                      onDelete={deleteDocument}
                    />
                  </PanelTab>
                  {type !== 'startup' && (
                    <PanelTab key='reports'>
                      <MentoringReports
                        reports={reports}
                        onSubmit={submitReport}
                        onDelete={deleteReport}
                        literals={literals}
                      />
                    </PanelTab>
                  )}
                </PanelBody>
              </Panel>
            </div>
          </div>
        </div>
        {!DISABLE_ACTIONS && (
          <div className='buttons mt-3'>
            <Button
              icon={XCircle}
              color='danger'
              text={literals.finish}
              title={literals.finishButtonTitle}
              onClick={() => setPopupFinish(true)}
            />
          </div>
        )}
        {
          showMoreInfo && (
            <PopupProfile
              type={type === 'startup' ? 'mentor' : 'startup'}
              data={showMoreInfo}
              onClose={() => setShowMoreInfo(false)}
            />
          )
        }
      </Tool>
      {popupFinish && (
        <MentoringFormFeedback
          mentoring={mentoring}
          literals={literals}
          onClose={() => setPopupFinish(false)}
          onSubmit={finishMentoring}
        />
      )}
    </>
  );
};

Mentoring.propTypes = {
  user: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  fetchMentoring: PropTypes.func.isRequired,
  createMentoringReport: PropTypes.func.isRequired,
  editMentoringReport: PropTypes.func.isRequired,
  deleteMentoringReport: PropTypes.func.isRequired,
  showLoading: PropTypes.func.isRequired,
  fetchMentoringMeetings: PropTypes.func.isRequired,
  createMentoringMeeting: PropTypes.func.isRequired,
  editMentoringMeeting: PropTypes.func.isRequired,
  deleteMentoringMeeting: PropTypes.func.isRequired,
  createMentoringDocument: PropTypes.func.isRequired,
  deleteMentoringDocument: PropTypes.func.isRequired,
  mentoring: PropTypes.object.isRequired,
  startup: PropTypes.object,
};

Mentoring.defaultProps = {
  startup: null,
};

export default Mentoring;
