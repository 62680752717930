import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

export const usePageView = (platform) => {
  const location = useLocation();

  useEffect(() => {
    if (platform?.analytics?.ga4?.measurement) {
      (() => {
        const { pathname } = location;
        const segments = pathname.split('/');
        const user = window.USER_ID;
        const sendObject = {
          hitType: 'pageview',
          page: pathname,
          user,
          userId: user,
        };

        if (['startup', 'mentor', 'admin'].includes(segments[1])) {
          if (segments[1] === 'startup') {
            sendObject.startup = segments[2];
            sendObject.tool = segments[3];
            sendObject.subtool = segments[4];
          }
        } else {
          sendObject.tool = segments[1];
          sendObject.subtool = segments[2];
        }
        ReactGA.send(sendObject);
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
};
