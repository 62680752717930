import React from 'react';
import PropTypes from 'prop-types';
import { ToggleOff, ToggleOn } from 'react-bootstrap-icons';
import './styles.scss';

const InputToggles = ({
  preText = '',
  postText = '',
  options = [],
  isRequired = false,
  isDisabled = false,
  onChange,
  value = {},
  error = null,
}) => {
  const changeValue = (option) => {
    if (!isDisabled && onChange) {
      onChange({ ...value, [option]: !value[option] });
    }
  };

  return (
    <div className={`input_toggle ${isDisabled ? 'disabled' : ''}`}>
      <div className={`pre_text ${error ? 'text_error' : ''}`}>
        {preText}
        {isRequired && <span className='is_required'>*</span>}
      </div>
      <div className={error ? 'input_error' : ''}>
        {options.map(elem => (
          <div
            key={elem.id}
            className={`toggle_option ${value[elem.id] ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`}
            onClick={() => changeValue(elem.id)}
          >
            {value[elem.id] ? <ToggleOn size={32} /> : <ToggleOff size={32} />}
            {elem.label}
          </div>
        ))}
      </div>
      { postText && (<div className='post_text'>{postText}</div>) }
    </div>
  );
};

InputToggles.propTypes = {
  preText: PropTypes.string,
  postText: PropTypes.string,
  options: PropTypes.array,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  error: PropTypes.object,
};

export default InputToggles;
