import React from 'react';
import PropTypes from 'prop-types';
import InputNumber from 'components/Inputs/inputNumber';
import InputSelect from 'components/Inputs/inputSelect';

const PopupFormVestingPlanBasic = ({
  data, onChange, literals, isDisabled,
}) => {
  // const optionsFrecuency = [
  //   { id: 'day', name: literals.day },
  //   { id: 'month', name: literals.month },
  //   { id: 'year', name: literals.year },
  // ];
  const optionsSchedule = [
    { id: 'start', name: literals.start },
    { id: 'exact', name: literals.exact },
    { id: 'end', name: literals.end },
  ];

  return (
    <div className='vestingPlan-time'>
      <h5 className='mb-0'>{literals.basic}</h5>
      <hr />
      <div className='row'>
        {/* <div className='col-md-3'>
          <InputSelect
            className='mb-md-0'
            preText={literals.frequency}
            value={data.basic.frequency ? data.basic.frequency : ''}
            options={optionsFrecuency}
            onChange={value => onChange('frequency', 0, value)}
            isDisabled={isDisabled}
            isRequired
            decimals={0}
          />
        </div> */}
        <div className='col-md-3'>
          <InputSelect
            className='mb-md-0'
            preText={literals.schedule}
            value={data.basic.schedule ? data.basic.schedule : ''}
            options={optionsSchedule}
            onChange={value => onChange('schedule', 0, value)}
            isDisabled={isDisabled}
            isRequired
          />
        </div>
        <div className='col-md-3'>
          <InputNumber
            name='period'
            preText={literals.period}
            value={data.basic.period ? data.basic.period : 0}
            minValue={0}
            onChange={value => onChange('period', 0, value)}
            isDisabled={isDisabled}
            decimals={0}
            isRequired
          />
        </div>
        <div className='col-md-3'>
          <InputNumber
            name='cliff'
            preText={literals.cliff}
            value={data.basic.cliff ? data.basic.cliff : 0}
            minValue={0}
            maxValue={data.basic.cliff ? data.basic.period : 12}
            onChange={value => onChange('cliff', 0, value)}
            isDisabled={isDisabled}
            decimals={0}
            isRequired
          />
        </div>
        <div className='col-md-3'>
          <InputNumber
            name='intervals'
            preText={literals.intervals}
            value={data.basic.intervals ? data.basic.intervals : 0}
            maxValue={data.basic.period && data.basic.cliff ? data.basic.period - data.basic.cliff : 12}
            minValue={0}
            onChange={value => onChange('intervals', 0, value)}
            isDisabled={isDisabled}
            decimals={0}
            isRequired
          />
        </div>
      </div>
    </div>
  );
};

PopupFormVestingPlanBasic.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  literals: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default PopupFormVestingPlanBasic;
