import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import InputFile from 'components/Inputs/inputFile';
import InputRichText from 'components/Inputs/inputRichText';
import Button from 'components/Buttons/Button';

const UploadDocumentsPopup = ({
  literals,
  literalsCommon,
  request = null,
  loading = false,
  onSubmit,
  onDelete,
  onClose,
}) => {
  const [documents, setDocuments] = useState(request?.documents || []);
  const [message, setMessage] = useState(request?.message || '');

  const handleSubmitRequest = async () => {
    onSubmit({
      service: 'upload_captable',
      documents,
      message,
    });
  };

  const handleDeleteRequest = async () => {
    onDelete('upload_captable');
  };

  return (
    <Popup
      title={literals.uploadExcelTemplates}
      description={literals.uploadExcelDescription}
      onClose={onClose}
    >
      <InputFile
        preText={literalsCommon.documents}
        value={documents}
        isPublic={false}
        onChange={v => setDocuments(v)}
        multiple
      />
      <InputRichText
        preText={literals.additionalDetailsRequest}
        value={message}
        onChange={setMessage}
        minHeight='150px'
      />
      <div className='buttons'>
        { request && (
          <Button
            text={literalsCommon.delete}
            color='danger'
            onClick={handleDeleteRequest}
            loading={loading}
          />
        )}
        <Button
          text={literalsCommon.send}
          onClick={handleSubmitRequest}
          loading={loading}
        />
      </div>
    </Popup>
  );
};

UploadDocumentsPopup.propTypes = {
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  request: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UploadDocumentsPopup;
