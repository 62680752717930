import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import Tool from 'components/Tool';
import Panel from 'components/Panel';
import PanelHeader from 'components/Panel/components/PanelHeader';
import PanelBody from 'components/Panel/components/PanelBody';
import InputMultiValues from 'components/Inputs/inputMultiValues';
import InputSelect from 'components/Inputs/inputSelect';
import OutputValue from 'components/Inputs/outputValue';
import EmptyGraph from 'assets/svg/empty_graph.svg';
import { ROUTE_STARTUP_PATH } from 'routes';
import { fetchKpisValues, initializeKpiRelations } from 'routes/Performance/modules/actions';
import { LineChart, Sheet } from 'lucide-react';
import { KPI_PERIODICITY } from 'constants/kpis';
import { getTranslation } from 'utils/functions';
import InputMonthRange from './inputMonthRange';
import PerformanceComparatorChart from './PerformanceComparatorChart';
import PerformanceComparatorTable from './PerformanceComparatorTable';
import './styles.scss';

const PerformanceComparator = (props) => {
  const {
    lang,
    literals,
    literalsCommon,
    match,
    currency,
    performance,
    fetchKpiRelations,
  } = props;

  const actualDate = new Date();
  const [selectKpis, setSelectKpis] = useState([]);
  const [dates, setDates] = useState({ actual: [], prev: [] });
  const [mode, setMode] = useState('chart');
  const [tableType, setTableType] = useState(KPI_PERIODICITY.MONTHLY);
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState({ value: true, forecast: true });
  const [interval, setInterval] = useState({
    startDate: `${actualDate.getFullYear() - 1}-${String(actualDate.getMonth() + 1).padStart(2, '0')}`,
    endDate: `${actualDate.getFullYear()}-${String(actualDate.getMonth() + 1).padStart(2, '0')}`,
    comparator: { startDate: null, endDate: null },
  });

  const isMonthly = tableType === KPI_PERIODICITY.MONTHLY;
  const commonProps = {
    show,
    isMonthly,
    graphData,
    dates,
    lang,
    literals,
    literalsCommon,
    loading,
    interval,
    tableType,
    currency,
  };

  useEffect(() => {
    initializeKpiRelations(fetchKpiRelations, performance, 'startup', match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const relations = useMemo(() => {
    return [...(performance.relations || [])].sort((a, b) => {
      const aName = getTranslation(a.kpi.name, lang);
      const bName = getTranslation(b.kpi.name, lang);
      return aName.localeCompare(bName);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performance]);

  const getDates = (comparator = false) => {
    const { startDate, endDate } = comparator ? interval.comparator : interval;
    const [fromYear, fromMonth] = startDate.split('-').map(Number);
    const [toYear, toMonth] = endDate.split('-').map(Number);

    let months = Array.from(
      { length: (toYear - fromYear) * 12 + (toMonth - fromMonth) + 1 },
      (_, i) => {
        const year = Math.floor((i + fromMonth - 1) / 12) + fromYear;
        const month = ((i + fromMonth - 1) % 12) + 1;
        const paddedMonth = String(month).padStart(2, '0');
        return `${year}-${paddedMonth}`;
      },
    );

    if (tableType === KPI_PERIODICITY.QUARTERLY) {
      months = months.filter(month => [3, 6, 9, 12].includes(+month.split('-')[1]));
    } else if (tableType === KPI_PERIODICITY.ANNUAL) {
      months = months.filter(month => month.split('-')[1] === '12');
    }

    setDates(prev => ({ ...prev, [comparator ? 'prev' : 'actual']: months }));
  };


  const fetchValues = async () => {
    if (selectKpis.length) {
      setLoading(true);
      const values = await fetchKpisValues('startup', match.params.id, {
        periodicity: tableType,
        from: `${interval.startDate}-01`,
        to: `${interval.endDate}-01`,
        kpis: selectKpis.join(','),
      });

      let compareValues = null;
      if (interval.comparator.startDate && interval.comparator.endDate) {
        compareValues = await fetchKpisValues('startup', match.params.id, {
          periodicity: tableType,
          from: `${interval.comparator.startDate}-01`,
          to: `${interval.comparator.endDate}-01`,
          kpis: selectKpis.join(','),
        });
      }

      const kpis = {};
      selectKpis.forEach((kpi) => {
        const relation = relations.find(rel => rel.kpi.id === kpi);
        kpis[kpi] = {
          id: relation.kpi.id,
          name: relation.kpi.name,
          periodicity: relation.kpi.periodicity,
          aggregate: relation.aggregate,
          unit: relation.kpi.unit,
          data: {},
          values: [],
          forecast: [],
          prev: {
            data: {}, values: [], forecast: [],
          },
        };
      });

      values.items.forEach((kpiValue) => {
        const auxValue = isMonthly ? kpiValue.value : kpiValue.aggregate.value;
        kpis[kpiValue.kpi].data[kpiValue.date] = { ...kpiValue, value: auxValue };
        kpis[kpiValue.kpi].values.push({ x: kpiValue.date, y: auxValue });
        if (tableType === KPI_PERIODICITY.MONTHLY) {
          kpis[kpiValue.kpi].forecast.push({ x: kpiValue.date, y: kpiValue.forecast });
        }
      });

      if (compareValues) {
        compareValues.items.forEach((kpiValue) => {
          const auxValue = isMonthly ? kpiValue.value : kpiValue.aggregate.value;
          kpis[kpiValue.kpi].prev.data[kpiValue.date] = { ...kpiValue, value: auxValue };
          kpis[kpiValue.kpi].prev.values.push({ x: kpiValue.date, y: auxValue });
          if (tableType === KPI_PERIODICITY.MONTHLY) {
            kpis[kpiValue.kpi].prev.forecast.push({ x: kpiValue.date, y: kpiValue.forecast });
          }
        });
        getDates(true);
      }
      setGraphData(Object.values(kpis));
      setLoading(false);
    } else {
      setGraphData([]);
    }
    getDates();
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      fetchValues();
    }, 500);
    return () => clearTimeout(delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval]);

  useEffect(() => {
    fetchValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectKpis, tableType]);


  const breadcrumb = [
    { route: ROUTE_STARTUP_PATH.setPerformance(match.params.id), name: literals.title },
    { route: ROUTE_STARTUP_PATH.setPerformanceComparator(match.params.id), name: literals.comparator },
  ];

  const handleVisibleRow = (type) => {
    if (type === 'value') {
      setShow({
        value: !show.value,
        forecast: show.value || show.forecast,
      });
    } else {
      setShow({
        forecast: !show.forecast,
        value: show.forecast || show.value,
      });
    }
  };

  if (!performance.loaded) {
    return <Loading mode='tool' hide={false} />;
  }

  return (
    <Tool className='PerformanceComparator-wrapper'>
      <Panel>
        <PanelHeader breadcrumb={breadcrumb} />
        <PanelBody>
          {relations.length ? (
            <>
              <div className='krg-header'>
                <div className='krg-mode'>
                  <div onClick={() => setMode('chart')} className={mode === 'chart' ? 'active' : ''}><LineChart size={18} /></div>
                  <div onClick={() => setMode('table')} className={mode === 'table' ? 'active' : ''}><Sheet size={18} /></div>
                </div>
                <div className='krg-dates'>
                  <InputMonthRange value={interval} onChange={setInterval} literals={literals} literalsCommon={literalsCommon} />
                </div>
              </div>

              <InputMultiValues
                className='mb-4'
                preText={literals.kpi}
                placeholder={literals.kpi}
                value={selectKpis}
                options={relations.map(rel => (
                  { name: getTranslation(rel.kpi.name, lang), id: rel.kpi.id }
                ))}
                onChange={v => setSelectKpis(v)}
              />

              { selectKpis.length ? (
                <>
                  <div className='d-flex justify-content-between align-items-center mb-4'>
                    <InputSelect
                      className='w-25 m-0'
                      onChange={v => setTableType(v)}
                      value={tableType}
                      options={[
                        { id: KPI_PERIODICITY.MONTHLY, name: literals.monthly },
                        { id: KPI_PERIODICITY.QUARTERLY, name: literals.quarterly },
                        { id: KPI_PERIODICITY.ANNUAL, name: literals.annual },
                      ]}
                    />
                    { isMonthly && (
                      <div className='performance-Comparator-legend'>
                        <span className='performance-legend-item' onClick={() => handleVisibleRow('value')}>
                          <OutputValue className='mb-0' type='radiobutton' value={show.value} />
                          <span
                            className={`performance-legend--name ${!show.value ? 'disabled' : ''}`}
                          >
                            {literals.value}
                          </span>
                        </span>
                        <span className='performance-legend-item' onClick={() => handleVisibleRow('forecast')}>
                          <OutputValue className='mb-0' type='radiobutton' value={show.forecast} />
                          <span
                            className={`performance-legend--name ${!show.forecast ? 'disabled' : ''}`}
                          >
                            {literals.forecast}
                          </span>
                        </span>
                      </div>
                    )}
                  </div>
                  { mode === 'chart'
                    ? <PerformanceComparatorChart {...commonProps} />
                    : <PerformanceComparatorTable {...commonProps} />
                  }
                </>
              ) : (
                <div className='panel-empty-message'>
                  <img src={EmptyGraph} alt={literals.emptyKpis} />
                  <p>{literals.emptyKpis}</p>
                </div>
              )}
            </>
          ) : (
            <div className='panel-empty-message'>
              <img src={EmptyGraph} alt={literals.noKpis} />
              <p>{literals.noKpis}</p>
            </div>
          )}
        </PanelBody>
      </Panel>
    </Tool>
  );
};

PerformanceComparator.propTypes = {
  lang: PropTypes.string.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  performance: PropTypes.object.isRequired,
  fetchKpiRelations: PropTypes.func.isRequired,
  currency: PropTypes.object.isRequired,
};

export default PerformanceComparator;
