import React, { useEffect, useRef, useState } from 'react';
import Portal from 'components/Portal';
import PropTypes from 'prop-types';
import useDialog from 'components/Dialog/components/useDialog';
import EmptyNotifications from 'assets/svg/empty_notifications.svg';
import { ToggleOff, ToggleOn } from 'react-bootstrap-icons';
import {
  Bell, Eye, EyeOff, Trash,
} from 'lucide-react';
import { useRect } from 'utils/customHooks';
import { literalTemplate } from 'utils/language';
import { getBrowserPreferences, setBrowserPreferences } from 'utils/browser';
import { ROUTE_MENTOR_PATH, ROUTE_PATH, ROUTE_STARTUP_PATH } from 'routes';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from './firebase';
import { deletePushToken, savePushToken } from '../container/actions';
import NotificationItem from './Notification';
import 'react-toastify/dist/ReactToastify.css';

export const getNotificationRedirect = (notification, user) => {
  switch (notification.tool) {
    case 'mentoring':
      switch (notification.action) {
        case 'mentoringInviteMentor':
          return ROUTE_MENTOR_PATH.MENTORINGS;
        case 'mentoringInviteStartup':
          return ROUTE_STARTUP_PATH.setMentorings(notification.data.startup);
        default:
          if (user.role.includes('user')) {
            return ROUTE_STARTUP_PATH.setMentoring(notification.data.startup, notification.data.id);
          }
          return ROUTE_MENTOR_PATH.setMentoring(notification.data.id);
      }
    case 'startup':
      switch (notification.action) {
        case 'invite':
          return ROUTE_PATH.PREHOME;
        default:
          return ROUTE_STARTUP_PATH.setHome(notification.data.id);
      }
    default:
      return ROUTE_PATH.PREHOME;
  }
};

export const getNotificationMessage = (notification, literals) => {
  let message = '';
  if (literals.notifications[notification.tool] && literals.notifications[notification.tool][notification.action]) {
    message = literals.notifications[notification.tool][notification.action];
  } else {
    message = `${notification.tool}.${notification.action}`;
  }

  message = literalTemplate(message, notification.data);

  if (notification.data?.count > 1) {
    message = `${message} (${notification.data.count})`;
  }
  return message;
};

const pushNotificationSupported = () => {
  return 'serviceWorker' in navigator && 'PushManager' in window;
};

const askUserPermission = async () => {
  return Notification.requestPermission();
};

const vapidKey = {
  // eslint-disable-next-line no-undef
  vapidKey: typeof FIREBASE_VAPID_KEY === 'string' ? FIREBASE_VAPID_KEY : null,
};

const Notifications = (props) => {
  const {
    data,
    user,
    literals,
    literalsCommon,
    notifications,
    fetchNotifications,
    addNotification,
    readNotification,
    unreadNotification,
    deleteNotification,
  } = props;

  const { dialog } = useDialog();
  const [panelNotifications, setPanelNotifications] = useState({ show: false, tab: 'unread' });
  const [userToken, setUserToken] = useState(getBrowserPreferences('pushToken'));
  const [bbox, ref] = useRect(panelNotifications.show);
  const scrollRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (!notifications.loading && !notifications.loaded) {
      fetchNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  useEffect(() => {
    if (messaging) {
      onMessage(messaging, (message) => {
        if (message.data) {
          const newNotification = message.data;
          newNotification.data = JSON.parse(newNotification.data);
          newNotification.createdAt = parseInt(newNotification.createdAt, 10);
          newNotification.updatedAt = parseInt(newNotification.updatedAt, 10);

          toast(getNotificationMessage(newNotification, literals), {
            className: 'toast-message',
            progressClassName: 'custom-progress-bar',
            onClick: () => {
              const goTo = getNotificationRedirect(newNotification, user);
              if (goTo) {
                navigate(goTo);
              }
            },
          });

          addNotification(newNotification);
        }
      });
    }

    if (userToken) {
      getToken(messaging, vapidKey).then((token) => {
        if (userToken !== token) {
          if (userToken !== 'logout') {
            deletePushToken(user.id, userToken);
          }
          savePushToken(user.id, token);
          setUserToken(token);
          setBrowserPreferences('pushToken', token);
        }
      }).catch(() => {
        setUserToken(null);
        setBrowserPreferences('pushToken', null);
        if (userToken !== 'logout') {
          deletePushToken(user.id, userToken);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenList = () => {
    setPanelNotifications({ show: !panelNotifications.show, tab: 'unread' });
  };

  const handleSubscription = async () => {
    if (!userToken) {
      askUserPermission().then(async (permission) => {
        if (permission === 'granted') {
          getToken(messaging, vapidKey).then((token) => {
            setUserToken(token);
            setBrowserPreferences('pushToken', token);

            savePushToken(user.id, token).then(() => {
              dialog({
                type: 'success',
                text: (
                  <>
                    <strong>{literals.notifications.notificationsActivated}</strong>
                    <br />
                    {literals.notifications.notificationsActivatedMessage}
                  </>
                ),
              });
            });
          }).catch(() => {
            dialog({
              type: 'error',
              text: literals.notifications.acceptPermissions,
            });
          });
        } else {
          dialog({
            type: 'error',
            text: literals.notifications.acceptPermissions,
          });
        }
      });
    } else {
      setUserToken(false);
      setBrowserPreferences('pushToken', null);
      getToken(messaging, vapidKey).then((token) => {
        deletePushToken(user.id, token).then(() => {
          dialog({
            type: 'alert',
            text: (
              <>
                <strong>{literals.notifications.notificationsDeactivated}</strong>
                <br />
                {literals.notifications.notificationsDeactivatedMessage}
              </>
            ),
          });
        });
      });
    }
  };

  const renderNotificationList = () => {
    const items = panelNotifications.tab === 'unread' ? notifications.items.filter(item => !item.read) : notifications.items;

    if (items.length) {
      return (
        <ul ref={scrollRef} className='notifications-list simple-scrollbar'>
          {
            items.map((notification) => {
              if (panelNotifications.tab === 'unread' && notification.read) {
                return null;
              }
              return (
                <NotificationItem
                  key={notification.id}
                  user={user}
                  notification={notification}
                  literals={literals}
                  literalsCommon={literalsCommon}
                  onRead={readNotification}
                  scrollRef={scrollRef}
                  buttons={[{
                    icon: EyeOff,
                    text: literals.markAsUnread,
                    onClick: () => unreadNotification(notification.id),
                    hidden: !notification.read,
                  },
                  {
                    icon: Eye,
                    text: literals.markAsRead,
                    onClick: () => readNotification(notification.id),
                    hidden: notification.read,
                  },
                  {
                    icon: Trash,
                    text: literals.delete,
                    onClick: () => deleteNotification(notification.id),
                  }]}
                />
              );
            })
          }
        </ul>
      );
    }

    return (
      <ul className='notifications-list m-2'>
        <div className='panel-empty-notifications'>
          <img src={EmptyNotifications} alt='' />
        </div>
      </ul>
    );
  };

  const styles = { visible: true, zIndex: 12 };
  if (bbox) {
    styles.top = bbox.bottom - 10;
    styles.left = bbox.left - 335;
  }
  if (window.innerWidth < 600) {
    styles.top = 95;
    styles.right = 15;
    styles.left = 'auto';
  }

  return (
    <div ref={ref} className={`topbar-action ${panelNotifications.show ? 'active' : ''}`}>
      <div className='topbar-icon' onClick={handleOpenList}>
        <Bell size={35} />
        {
          data.unread > 0 && (
            <span className='topbar-icon-number'>{data.unread > 99 ? '+99' : data.unread}</span>
          )
        }
      </div>
      {
        panelNotifications.show && (
          <Portal type='popup' styles={styles} onClose={() => setPanelNotifications(prev => ({ ...prev, show: false }))}>

            <div className='topbar-popup topbar-notifications'>
              <div className='notifications-filter'>
                <span
                  className={panelNotifications.tab === 'unread' ? 'active' : ''}
                  onClick={() => setPanelNotifications({ ...panelNotifications, tab: 'unread' })}
                >
                  {literals.notifications.unread}
                </span>
                <span
                  className={panelNotifications.tab === 'all' ? 'active' : ''}
                  onClick={() => setPanelNotifications({ ...panelNotifications, tab: 'all' })}
                >
                  {literals.notifications.all}
                </span>
              </div>
              { renderNotificationList() }
              {
                messaging && pushNotificationSupported ? (
                  <div
                    className={`toggle_option ${userToken && userToken !== 'logout' ? 'active' : ''}`}
                    onClick={handleSubscription}
                  >
                    <span>{literals.pushNotifications}</span>
                    {
                      userToken && userToken !== 'logout' ? <ToggleOn size={32} /> : <ToggleOff size={32} />
                    }
                  </div>
                ) : null
              }
            </div>
          </Portal>
        )
      }
      <ToastContainer />
    </div>
  );
};

Notifications.propTypes = {
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  notifications: PropTypes.object.isRequired,
  fetchNotifications: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  readNotification: PropTypes.func.isRequired,
  unreadNotification: PropTypes.func.isRequired,
  deleteNotification: PropTypes.func.isRequired,
};

export default Notifications;
