import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Portal from 'components/Portal';
import { renderIcon } from 'utils/functions';
import { useRect } from 'utils/customHooks';
import { Link } from 'react-router-dom';
import { EllipsisVertical } from 'lucide-react';
import './styles.scss';

const ButtonsDotsMenu = (props) => {
  const {
    size = 24,
    offset = { top: 0, left: 0 },
    buttons = [],
    scrollRef = null,
  } = props;

  const [showMenu, setShowMenu] = useState(false);
  const [bbox, ref, visible] = useRect(showMenu, scrollRef);
  const childRef = useRef();

  const handleClickButton = (e, button = null) => {
    e.stopPropagation();
    e.preventDefault();
    setShowMenu(prev => !prev);
    if (!button?.disabled && typeof button?.onClick === 'function') {
      button.onClick();
    }
  };

  const childHeight = childRef?.current?.clientHeight;
  const styles = { visible };
  let childOffPage = false;
  if (bbox) {
    styles.top = bbox.top + (offset.top ?? 0);
    childOffPage = childHeight && styles.top + childHeight > window.innerHeight;
    if (childOffPage) {
      styles.top -= childHeight - 40;
    }
    styles.left = bbox.left + (offset.left ?? 0);
  }
  if (offset.zIndex) {
    styles.zIndex = offset.zIndex;
  }

  return (
    <div ref={ref} className={`buttons-dots ${showMenu ? 'show' : ''}`}>
      <EllipsisVertical size={size} className='bt-open' onClick={handleClickButton} />
      { showMenu && buttons.length && (
        <Portal styles={styles} onClose={() => setShowMenu(false)}>
          <div ref={childRef} className='buttons-dots-menu'>
            <EllipsisVertical size={size} className={`bt-close ${childOffPage ? 'bottom' : ''}`} onClick={handleClickButton} />
            <ul className='buttons-dots-list'>
              {
                buttons.map((button, i) => {
                  if (button.hidden) {
                    return null;
                  }
                  if (button.type === 'Link') {
                    return (
                      <li className={button.disabled ? 'disabled' : ''} key={i}>
                        <Link title={button.text} className={button.disabled ? 'disabled' : ''} key={i} to={button.to}>
                          {renderIcon(button.icon, { size: 15 })}
                          {button.text}
                        </Link>
                      </li>
                    );
                  }
                  if (button.type === 'a') {
                    return (
                      <li className={button.disabled ? 'disabled' : ''} key={i}>
                        {renderIcon(button.icon, { size: 15 })}
                        <a href={button.href} target={button.target} rel='noopener noreferrer'>{button.text}</a>
                      </li>
                    );
                  }
                  return (
                    <li className={button.disabled ? 'disabled' : ''} key={i} onClick={e => handleClickButton(e, button)}>
                      {renderIcon(button.icon, { size: 15 })}
                      {button.text}
                    </li>
                  );
                })
              }
            </ul>
          </div>
        </Portal>
      )}
    </div>
  );
};

ButtonsDotsMenu.propTypes = {
  buttons: PropTypes.array,
  offset: PropTypes.object,
  size: PropTypes.number,
  scrollRef: PropTypes.object,
};

export default ButtonsDotsMenu;
