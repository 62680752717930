import { connect } from 'react-redux';
import VestingsPlansComponents from '../components';

const mapStateToProps = state => ({
  literalsCommon: state.i18n.literals.common,
  literals: state.i18n.literals.vestingsPlans,
  startup: state.global.startup,
  captable: state.captable,
});

export default connect(mapStateToProps)(VestingsPlansComponents);
