import { connect } from 'react-redux';
import AdminPerformanceComponents from '../components';

const mapStateToProps = state => ({
  literals: state.i18n.literals.performance,
  literalsCommon: state.i18n.literals.common,
  lang: state.session.user.lang,
  langs: state.global.platform.langs,
  currency: state.global.currency,
});

export default connect(mapStateToProps)(AdminPerformanceComponents);
